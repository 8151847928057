
import {
    Create as CreateAction, CreateMenu, CreateHelp
} from './create';
import {
    CreateProfile as CreateProfileAction, CreateProfileMenu, CreateProfileHelp
} from './create-profile';
import {
    Edit as EditAction, // EditUITitle, EditActionButtons, 
    EditMenu, EditHelp
} from './edit';
import {
    Save as SaveAction, SaveMenu, SaveHelp
} from './save';
import {
    Delete as DeleteAction, DeleteMenu, DeleteHelp
} from './delete';
import {
    GoToProfileMenu, GoToProfileHelp, GoToProfileUI
} from './goto-profile';
import {
    EngagementRequest, EngagementRequestMenu, EngagementRequestHelp
} from './engagement-request';
import {
    BroadcastMVT, BroadcastMVTMenu, BroadcastMVTHelp
} from './broadcast-mvt';
import {
    MintMVnfT, MintMVnfTMenu, MintMVnfTHelp
} from './mint-mvnft';

export const getEngagementActionPackages = (actionProps: any) => {
    return {
        // back: BackAction,
        create: {
            ui: (<CreateAction {...actionProps} />),
            menu: (<CreateMenu {...actionProps} />),
            uiTitle: (null),
            actionButtons: (null),
            help: (<CreateHelp {...actionProps} />),
        },
        createProfile: {
            ui: (<CreateProfileAction {...actionProps} />),
            menu: (<CreateProfileMenu {...actionProps} />),
            uiTitle: (null),
            actionButtons: (null),
            help: (<CreateProfileHelp {...actionProps} />),
        },
        save: {
            ui: (<SaveAction {...actionProps} />),
            menu: (<SaveMenu {...actionProps} />),
            uiTitle: (null),
            actionButtons: (null),
            help: (<SaveHelp {...actionProps} />)
        },
        delete: {
            ui: (<DeleteAction {...actionProps} />),
            menu: (<DeleteMenu {...actionProps} />),
            uiTitle: (null),
            actionButtons: (null),
            help: (<DeleteHelp {...actionProps} />)
        },
        edit: {
            ui: (<EditAction {...actionProps} />),
            menu: (<EditMenu {...actionProps} />),
            uiTitle: (null),
            actionButtons: (null),
            help: (<EditHelp {...actionProps} />)
        },
        goToProfile: {
            ui: (<GoToProfileUI {...actionProps} />),
            menu: (<GoToProfileMenu {...actionProps} />),
            uiTitle: (null),
            actionButtons: (null),
            help: (<GoToProfileHelp {...actionProps} />),
        },
        engagementRequest: {
            ui: (<EngagementRequest {...actionProps} />),
            menu: (<EngagementRequestMenu {...actionProps} />),
            uiTitle: (null),
            actionButtons: (null),
            help: (<EngagementRequestHelp {...actionProps} />),
        },
        broadcastMVT: {
            ui: (<BroadcastMVT {...actionProps} />),
            menu: (<BroadcastMVTMenu {...actionProps} />),
            uiTitle: (null),
            actionButtons: (null),
            help: (<BroadcastMVTHelp {...actionProps} />)
        },
        mintNFT: {
            ui: (<MintMVnfT {...actionProps} />),
            menu: (<MintMVnfTMenu {...actionProps} />),
            uiTitle: (null),
            actionButtons: (null),
            help: (<MintMVnfTHelp {...actionProps} />)
        },
        // permissions: PermissionsAction,
        // structure: StructureAction,
        // view: ViewAction
    };
}
