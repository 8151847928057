import React, { Fragment, useState } from 'react';

import { styled } from '@mui/material/styles';

import UrlParser from 'url-parse';

import { useTheme } from '@mui/material/styles';

import { SketchPicker } from 'react-color';

import * as filestack from 'filestack-js';
import { PickerOptions } from 'filestack-js';
import { find } from 'underscore';

import {
    Box, Paper, Typography, IconButton, Avatar, FormControl, TextField, InputLabel, Menu, MenuItem, Tooltip,
    Accordion, AccordionDetails, AccordionSummary, Icon, 
 } from '@mui/material';

import { useUdicciContext } from 'src/context/udicci-context';
import { udicciStyles } from 'src/theme/shared-styles';

import UploadFileIcon from '@mui/icons-material/CloudUpload';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import ManageAnalyticMeasurements from 'src/components/manage-analytics-measurements'
// import { AnalyticsSharp } from '@mui/icons-material';

const PaperContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    display: 'flow-root',
    padding: '8px',
    minWidth: '100px',
}));

const ConfigureProfileSettings: React.FC<any> = () => {
    const udicciHelpers = useUdicciHelpers();

    const [uiFlashState, flashUI] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [showSocialIconPicker, toggleSocialIconPicker] = useState<boolean>(false);
    const [showBackgroundImagePicker, toggleBackgroundImagePicker] = useState<boolean>(false);
    const [showSplashImagePicker, toggleSplashImagePicker] = useState<boolean>(false);
    const [colorMenuAnchorEl, setColorMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    // console.log('%c ConfigureProfileSettings selectedSetting: %O', 'color: maroon;', selectedSetting);

    const menuIsOpen = Boolean(colorMenuAnchorEl);
    // console.log('ConfigureProfileSettings menuIsOpen: %O', menuIsOpen);
    // console.log('ConfigureProfileSettings showSocialIconPicker: %O', showSocialIconPicker);
    // console.log('ConfigureProfileSettings showBackgroundImagePicker: %O', showBackgroundImagePicker);
    // console.log('ConfigureProfileSettings showSplashImagePicker: %O', showSplashImagePicker);

    let currentUrl = window.location.href;
    let url = UrlParser(currentUrl, true);
    // console.log('%c ConfigureProfileSettings url: %O', 'color: maroon;', url);

    let { pathname, hash } = url;
    // console.log('%c ConfigureProfileSettings pathname: %O', 'color: maroon;', pathname);

    const udicciContext = useUdicciContext();
    // console.log('%c ConfigureProfileSettings udicciContext: %O', 'color: red;', udicciContext);
    let { udicci } = udicciContext.state;
    let { currentUser, selectedProfile, udicciFileStackApiKey } = udicci;
    // console.log('%c ConfigureProfileSettings selectedProfile: %O', 'color: maroon;', selectedProfile);
    // *************************************************************************************
    // ********  FileStack Option
    let profileSocialIcon: string = '';
    let profileBackgroundImage: string = '';
    let profileSplashImage: string = '';
    let profileBackgroundColor: string = '#fff';
    let profile: any = null;
    let profileSettings: any = null;
    let profileType: any = null;
    if (selectedProfile && selectedProfile.data) {
        profile = selectedProfile.data;
        if (profile.SocialIcon) profileSocialIcon = profile.SocialIcon;
        if (profile.BackgroundImage) profileBackgroundImage = profile.BackgroundImage;
        if (profile.PageBackgroundColor) profileBackgroundColor = profile.PageBackgroundColor;
        if (!profile.jsonProfileSettingsJson && profile.ProfileSettingsJson) {
            try {
                selectedProfile.data.jsonProfileSettingsJson = JSON.parse(selectedProfile.data.ProfileSettingsJson);
            } catch (e: any) {

            }
        }
        if (profile.jsonProfileSettingsJson) profileSettings = profile.jsonProfileSettingsJson;
        if (profileSettings && profileSettings.SplashImage) profileSplashImage = profileSettings.SplashImage;
        if (profile.ProfileType) profileType = profile.ProfileType;
    }
    // console.log('%c ConfigureProfileSettings profileBackgroundColor: %O', 'color: maroon;', profileBackgroundColor);
    // console.log('%c ConfigureProfileSettings profileSettings: %O', 'color: maroon;', profileSettings);
    // console.log('%c ConfigureProfileSettings profileType: %O', 'color: maroon;', profileType);

    let useFileStackOption = 'useUdicciShared';
    if (profileSettings.useFileStackOption)
        useFileStackOption = profileSettings.useFileStackOption;
    // console.log('%c ConfigureProfileSettings useFileStackOption: %O', 'color: red;', useFileStackOption);
    let profileFileStackApiKey = '';
    if (profileSettings && profileSettings.fileStackApiKey) {
        useFileStackOption = 'useProfile';
        profileFileStackApiKey = profileSettings.fileStackApiKey;
    } else if (useFileStackOption === 'useUdicciShared') {
        profileFileStackApiKey = udicciFileStackApiKey;
    }
    // console.log('%c ConfigureProfileSettings profileFileStackApiKey: %O', 'color: red;', profileFileStackApiKey);
    // ********  FileStack Option
    // *************************************************************************************
    const requestFailed = (details: any) => {
        // console.log('requestFailed details: %O', details);
    };

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const fileSuccess = (details: any, fieldName: string = '') => {
        // console.log('fileSuccess fieldName: %O', fieldName);
        if (!selectedProfile)
            return false;
        if (!profile)
            return false;
        if (selectedProfile.isSaving)
            return false;

        // console.log('fileSuccess fieldName: %O', fieldName);
        // console.log('fileSuccess details: %O', details);
        // let filesFailed: any[] = (details && details.filesFailed ? details.filesFailed : []);
        // console.log('fileSuccess filesFailed: %O', filesFailed);
        let filesUploaded: any[] = (details && details.filesUploaded ? details.filesUploaded : []);
        // console.log('fileSuccess filesUploaded: %O', filesUploaded);
        let uploadedFile: any = (filesUploaded.length > 0 ? filesUploaded[0] : null);
        // console.log('fileSuccess uploadedFile: %O', uploadedFile);
        let uploadedFileUrl: string = (uploadedFile && uploadedFile.url ? uploadedFile.url : '');
        // console.log('fileSuccess uploadedFileUrl: %O', uploadedFileUrl);
        // console.log('fileSuccess selectedProfile: %O', selectedProfile);
        let hasChange: boolean = false;
        let settings: any = {};
        if (selectedProfile.data.jsonProfileSettingsJson) Object.assign(settings, selectedProfile.data.jsonProfileSettingsJson);
        if (fieldName === 'SplashImage') {
            if (!settings) settings = {};
            settings[fieldName] = uploadedFileUrl;
            // console.log('fileSuccess settings: %O', settings);
            selectedProfile.data.jsonProfileSettingsJson = settings;
            hasChange = true;
            try {
                selectedProfile.data.ProfileSettingsJson = JSON.stringify(selectedProfile.data.jsonProfileSettingsJson);
            } catch (e: any) {

            }
            hasChange = true;
        } else {
            if (uploadedFile && selectedProfile.data[fieldName] !== uploadedFileUrl) {
                selectedProfile.data[fieldName] = uploadedFileUrl;
                hasChange = true;
            }
        }
        if (hasChange) {
            selectedProfile.isDirty = true;
            // console.log('fileSuccess fieldName: %O', fieldName);
            // console.log('fileSuccess settings: %O', settings);
            if (fieldName === 'SplashImage') {
                udicci.onChangeProfile('jsonProfileSettingsJson', settings);
            } else {
                udicci.onChangeProfile(fieldName, uploadedFileUrl);
            }
        }
        // console.log('fileSuccess selectedProfile: %O', selectedProfile);
        if (fieldName === 'SocialIcon')
            toggleSocialIconPicker(false);
        if (fieldName === 'BackgroundImage')
            toggleBackgroundImagePicker(false);
        if (fieldName === 'SplashImage')
            toggleSplashImagePicker(false);
    };

    const handleChangeColor = (fieldName: string, color: any) => {
        // console.log('%c handleChangeColor fieldName: %O', 'color: maroon;', fieldName);
        // console.log('%c handleChangeColor color: %O', 'color: maroon;', color);
        let colorConverted: string = udicciHelpers.convertColorToString(color.rgb, '#fff');
        udicci.onChangeProfile(fieldName, colorConverted);
        flashUI(!uiFlashState);
    };

    const changeProfile = (fieldName: string, evt: any) => {
        // console.log('%c changeProfile fieldName: %O', 'color: red;', fieldName);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        udicci.onChangeProfile(fieldName, newValue);
    }

    const removeProfileSetting = (fieldName: string) => {
        // console.log('%c removeProfileSetting fieldName: %O', 'color: red;', fieldName);
        // console.log('%c removeProfileSetting udicciContext: %O', 'color: red;', udicciContext);
        // console.log('%c removeProfileSetting selectedProfile: %O', 'color: red;', selectedProfile);
        if (!selectedProfile) return false;

        let hasChange: boolean = false;
        let settings: any = {};
        if (selectedProfile.data.jsonProfileSettingsJson) Object.assign(settings, selectedProfile.data.jsonProfileSettingsJson);
        if (fieldName === 'SplashImage') {
            if (!settings) settings = {};
            if (settings[fieldName] !== '') {
                settings[fieldName] = '';
                // console.log('fileSuccess settings: %O', settings);
                selectedProfile.data.jsonProfileSettingsJson = settings;
                hasChange = true;
                try {
                    selectedProfile.data.ProfileSettingsJson = JSON.stringify(selectedProfile.data.jsonProfileSettingsJson);
                } catch (e: any) {

                }
                hasChange = true;
            }
        } else {
            if (selectedProfile.data[fieldName] !== '') {
                selectedProfile.data[fieldName] = '';
                hasChange = true;
            }
        }
        if (hasChange) {
            selectedProfile.isDirty = true;
            // console.log('fileSuccess fieldName: %O', fieldName);
            // console.log('fileSuccess settings: %O', settings);
            if (fieldName === 'SplashImage') {
                udicci.onChangeProfile('jsonProfileSettingsJson', settings);
            } else {
                udicci.onChangeProfile(fieldName, '');
            }
        }

        flashUI(!uiFlashState);
    }

    const handleClickColor = (event: React.MouseEvent<HTMLElement>, colorIdentifier: any) => {
        // console.log('%c handleClickColor colorIdentifier: %O', 'color: maroon;', colorIdentifier);
        setColorMenuAnchorEl(event.currentTarget);
        flashUI(!uiFlashState);
    };

    const handleCloseColorMenu = () => {
        setColorMenuAnchorEl(null);
        flashUI(!uiFlashState);
    };

    const getColorElement = (settingDisplayName: string, currentColor: any, defaultColor: string = '#fff', showSettingName: boolean | undefined = false) => {
        // console.log('%c getColorElement settingDisplayName: %O', 'color: blue;', settingDisplayName);
        // console.log('%c getColorElement currentColor: %O', 'color: blue;', currentColor);

        let colorValue: string = udicciHelpers.convertColorToString(currentColor, defaultColor);
        // console.log('%c getColorElement colorValue: %O', 'color: blue;', colorValue);

        let chosenColorElementSettings: any = {
            borderRadius: '8px',
            border: '1px solid black',
            width: '17px',
            height: '17px',
        }

        let chooseColorIconButtonSettings: any = {
            margin: '3px',
            marginTop: '0px',
            padding: '0px',
            float: 'left',
            minWidth: '15px',
            minHeight: '15px',
            background: 'unset',
        }

        let colorChoiceElement: any = null;
        // console.log('%c getColorElement menuIsOpen: %O', 'color: blue;', menuIsOpen);
        if (menuIsOpen) {    
            colorChoiceElement = (
                <Menu anchorEl={colorMenuAnchorEl}
                      id="color-choice-menu"
                      open={menuIsOpen}
                      onClose={handleCloseColorMenu}
                      sx={{ padding: '0px', paddingTop: '0px', paddingBottom: '0px' }}
                      MenuListProps={{ sx: { padding: '0px', paddingTop: '0px', paddingBottom: '0px' } }}
                >
                    <MenuItem sx={{ padding: '0px' }}>
                        <SketchPicker color={currentColor} onChangeComplete={((color: any) => handleChangeColor(settingDisplayName, color))} />
                    </MenuItem>
                </Menu>
            );
        }

        let settingNameDisplayElement: any = null;
        if (showSettingName) {
            settingNameDisplayElement = (
                <Typography variant="overFieldLabel" component="div"> {settingDisplayName} </Typography>
            );
        }

        let colorIdentifier: any = { settingDisplayName };
        return (
            <div style={{ padding: '8px', float: 'left' }}>
                <Tooltip title={"Click to Change Page Background Color"}>
                    <IconButton onClick={(evt: any) => handleClickColor(evt, colorIdentifier)}
                                size="small"
                                color="secondary"
                                sx={chooseColorIconButtonSettings}
                                aria-controls={menuIsOpen ? 'color-choice-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={menuIsOpen ? 'true' : undefined}
                    >
                        <Avatar sx={{ background: colorValue, ...chosenColorElementSettings }}>&nbsp;</Avatar>
                    </IconButton>
                </Tooltip>
                <div style={{float: 'left', display: 'flow-root', cursor: 'pointer',  marginLeft: '4px'}} onClick={(evt: any) => handleClickColor(evt, colorIdentifier)}>
                    {settingNameDisplayElement}
                    <Typography variant="caption" component="div">click to change color</Typography>
                </div>
                {colorChoiceElement}
            </div>
        );
    };

    const updateProfileSettings = (updatedProfileSettings: any) => {
        // console.log('%c updateProfileSettings updatedProfileSettings: %O', 'color: red;', updatedProfileSettings);

        if (!selectedProfile) return false;

        let hasChange: boolean = false;
        let settings: any = {};
        if (selectedProfile.data.jsonProfileSettingsJson) Object.assign(settings, selectedProfile.data.jsonProfileSettingsJson);
        if (updatedProfileSettings) {
            if (!settings) settings = {};
            Object.assign(settings, updatedProfileSettings);

            selectedProfile.data.jsonProfileSettingsJson = settings;
            hasChange = true;
            try {
                selectedProfile.data.ProfileSettingsJson = JSON.stringify(selectedProfile.data.jsonProfileSettingsJson);
            } catch (e: any) {

            }
        }
        // console.log('%c updateProfileSettings hasChange: %O', 'color: red;', hasChange);
        if (hasChange) {
            selectedProfile.isDirty = true;
            // console.log('updateProfileSettings settings: %O', settings);
            udicci.onChangeProfile('jsonProfileSettingsJson', settings);
        }

        flashUI(!uiFlashState);
    }

    let fileTags: any = { platform: 'Udicci.IT', filename: (file: any) => file.name };
    if (profile && profile.DisplayName) fileTags.profile = profile.DisplayName;
    if (profile && profile.ProfileUrl) fileTags.url = profile.ProfileUrl;
    if (pathname) fileTags.pathname = pathname;
    if (hash) fileTags.hash = hash;

    let currentPickerName: string = '';
    if (showSocialIconPicker) currentPickerName = 'SocialIcon';
    if (showBackgroundImagePicker) currentPickerName = 'BackgroundImage';
    if (showSplashImagePicker) currentPickerName = 'SplashImage';
    const client = filestack.init(profileFileStackApiKey);
    const options: PickerOptions = {
        accept: ['image/*'],
        fromSources: ["local_file_system","url","googlephotos","googledrive","instagram","facebook"],
        uploadConfig: {
            retry: 5,
            timeout: 60000,
            tags: fileTags
        },
        onFileUploadFailed: requestFailed,
        onUploadDone: (details: any) => fileSuccess(details, currentPickerName),
        // onClose: () => { toggleSocialIconPicker(false); toggleBackgroundImagePicker(false); toggleSplashImagePicker(false); }
    };

    const openFilePicker = (picker: string, evt: any) => {
        // console.log('openFilePicker picker: %O', picker);
        // console.log('client.picker options: %O', options);
        client.picker(options).open();
        if (picker === 'SocialIcon') toggleSocialIconPicker(true);
        if (picker === 'BackgroundImage') toggleBackgroundImagePicker(true);
        if (picker === 'SplashImage') toggleSplashImagePicker(true);
    };

    let avatarSx: any = {
        margin: '8px',
        float: 'left',
    };
    let imageManagementElements: any = null;
    if (currentUser && udicci.specialUsers.indexOf(currentUser.UserName.toLowerCase()) >= 0) {
        let socialIconAvatar: any = null;
        let hasSocialIcon: boolean = false;
        if (profileFileStackApiKey && profileSocialIcon) {
            socialIconAvatar = (
                <Avatar src={profileSocialIcon} sx={avatarSx}
                        component="span"
                        variant='rounded'
                        onClick={(evt: any) => toggleSocialIconPicker(true)}
                />
            );
            hasSocialIcon = true;
        } else {
            socialIconAvatar = ( <Typography variant="caption" component="div" sx={{ float: 'left', marginTop: '16px', marginLeft: '16px' }}>not set</Typography> );
        }
        // classes={{ img: udicciClasses.profileSocialIcon }}
        // sx={udicciClasses.acceptingSocialIcon}
        let backgroundImageAvatar: any = null;
        let hasBackgroundImage: boolean = false;
        if (profileFileStackApiKey && profileBackgroundImage) {
            backgroundImageAvatar = (
                <Avatar src={profileBackgroundImage} sx={avatarSx}
                        component="span"
                        variant='rounded'
                        onClick={(evt: any) => toggleBackgroundImagePicker(true)}
                />
            );
            hasBackgroundImage = true;
        } else {
            backgroundImageAvatar = ( <Typography variant="caption" component="div" sx={{ float: 'left', marginTop: '16px', marginLeft: '16px' }}>not set</Typography> );
        }

        let splashImageAvatar: any = null;
        let hasSplashImage: boolean = false;
        if (profileFileStackApiKey && profileSplashImage) {
            splashImageAvatar = (
                <Avatar src={profileSplashImage} sx={avatarSx}
                        component="span"
                        variant='rounded'
                        onClick={(evt: any) => toggleSplashImagePicker(true)}
                />
            );
            hasSplashImage = true;
        } else {
            splashImageAvatar = ( <Typography variant="caption" component="span" sx={{ float: 'left', marginTop: '16px', marginLeft: '16px' }}>not set</Typography> );
        }
    
        imageManagementElements = (
            <Box sx={{ display: 'flow-root' }}>
                <PaperContainer elevation={3}>
                    <Box sx={{ marginRight: '8px', marginTop: '2px', float: 'left' }}>
                        <Typography variant="subtitle2" component="div">Social Icon</Typography>
                        <IconButton size="small" onClick={(evt: any) => openFilePicker('SocialIcon', evt)}>
                            <UploadFileIcon />
                        </IconButton>
                        <IconButton size="small" color="error" disabled={!hasSocialIcon} onClick={(evt: any) => { removeProfileSetting('SocialIcon'); }}>
                            <Icon>remove</Icon>
                        </IconButton>
                    </Box>
                    {socialIconAvatar}
                </PaperContainer>
                <PaperContainer elevation={3}>
                    <Box sx={{ marginRight: '8px', marginTop: '2px', float: 'left' }}>
                        <Typography variant="subtitle2" component="div">Background</Typography>
                        <IconButton size="small" onClick={(evt: any) => openFilePicker('BackgroundImage', evt)}>
                            <UploadFileIcon />
                        </IconButton>
                        <IconButton size="small" color="error" disabled={!hasBackgroundImage} onClick={(evt: any) => { removeProfileSetting('BackgroundImage'); }}>
                            <Icon>remove</Icon>
                        </IconButton>
                    </Box>
                    {backgroundImageAvatar}
                </PaperContainer>
                <PaperContainer elevation={3}>
                    <Box sx={{ marginRight: '8px', marginTop: '2px', float: 'left' }}>
                        <Typography variant="subtitle2" component="div">Splash</Typography>
                        <IconButton size="small" onClick={(evt: any) => openFilePicker('SplashImage', evt)}>
                            <UploadFileIcon />
                        </IconButton>
                        <IconButton size="small" color="error" disabled={!hasSplashImage} onClick={(evt: any) => { removeProfileSetting('SplashImage'); }}>
                            <Icon>remove</Icon>
                        </IconButton>
                    </Box>
                    {splashImageAvatar}
                </PaperContainer>
                <PaperContainer elevation={3}>
                    <Typography variant="subtitle2" component="div">Background Color</Typography>
                    <Box sx={{ marginTop: '8px' }}>
                        {getColorElement('PageBackgroundColor', profileBackgroundColor, '#fff')}
                    </Box>
                </PaperContainer>
            </Box>
        );
    }

    let missionBioSectionElement: any = (
        <Accordion expanded={expanded === 'details'} onChange={handleChange('details')}>
            <AccordionSummary expandIcon={<Icon color="primary">expand_more</Icon>} id={"details.header"}>
                <Typography variant="clickableSubTitle2" component="div">Mission &amp; Bio</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '8px' }}>
                <Box>
                    <Box sx={{ display: 'flow-root' }}>
                        <FormControl fullWidth>
                            <Typography variant="overFieldLabel">Mission Statement</Typography>
                            <TextField id="profile.MissionStatement"
                                       variant={'outlined'}
                                       multiline={true} minRows={3} maxRows={15}
                                       value={(profile !== null ? profile.MissionStatement : '')}
                                       onChange={changeProfile.bind(this, 'MissionStatement')}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flow-root', marginTop: '8px' }}>
                        <FormControl fullWidth>
                            <Typography variant="overFieldLabel">Biography</Typography>
                            <TextField id="profile.Biography"
                                       variant={'outlined'}
                                       multiline={true} minRows={3} maxRows={15}
                                       value={(profile !== null ? profile.Biography : '')}
                                       onChange={changeProfile.bind(this, 'Biography')}
                            />
                        </FormControl>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );

    let imagesSectionElement: any = (
        <Accordion expanded={expanded === 'images'} onChange={handleChange('images')}>
            <AccordionSummary expandIcon={<Icon color="primary">expand_more</Icon>} id={"images.header"}>
                <Typography variant="clickableSubTitle2" component="div">Images &amp; Background</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '8px' }}>
                <Box>
                    {imageManagementElements}
                </Box>
            </AccordionDetails>
        </Accordion>
    );

    let portalUrl: string = '';
    let secondaryPortalUrl: string = '';
    if (profileType && profileType.DefaultUrl) {
        portalUrl = 'https://' + profileType.DefaultUrl.trim();
        if (profileType.DefaultUrl.indexOf('www.') >= 0) {
            secondaryPortalUrl = 'https://' + profileType.DefaultUrl.replace('www.', '');
        } else {
            secondaryPortalUrl = 'https://www.' + profileType.DefaultUrl;
        }
    } else if (pathname) {
        portalUrl = pathname.trim();
    } else if (pathname) {
        portalUrl = 'https://udicci.com';
        secondaryPortalUrl = 'https://www.udicci.com';
    }

    if (portalUrl.endsWith('/') === true) portalUrl = portalUrl.substring(0, portalUrl.length - 2);
    if (secondaryPortalUrl.endsWith('/') === true) secondaryPortalUrl = secondaryPortalUrl.substring(0, secondaryPortalUrl.length - 2);
    portalUrl += profile.ProfileUrl;
    secondaryPortalUrl += profile.ProfileUrl;

    var analytics: any = (profileSettings && profileSettings.analytics ? profileSettings.analytics : null);
    // console.log('%c ConfigureProfileSettings analytics: %O', 'color: maroon;', analytics);
    var analyticsDataStreams: any[] = (analytics && analytics.dataStreams ? analytics.dataStreams : []);
    // console.log('%c ConfigureProfileSettings analyticsDataStreams: %O', 'color: maroon;', analyticsDataStreams);

    let defaultPortaUrlDataStream: any = null;
    if (analyticsDataStreams && analyticsDataStreams.length > 0) {
        defaultPortaUrlDataStream = find(analyticsDataStreams, (ds: any) => { return ds.url === portalUrl });
    }

    let dsMessageElement: any = null;
    let dsAnalyticsHeaderElement: any = null;
    if (!defaultPortaUrlDataStream) {
        dsMessageElement = (
            <Fragment>
                <Typography variant="caption" component="div">
                    Finally, you will need to add a Data Stream for each URL that you wish to monitor.  
                    For this Portal, you can setup the following Data Stream as a default if you have no domain.
                </Typography>
                <Typography variant="subtitle1" component="div" sx={{ marginLeft: '8px' }}>
                    {portalUrl}
                </Typography>
                {/* <Typography variant="subtitle2" component="div" sx={{ marginLeft: '8px' }}>
                    {secondaryPortalUrl}
                </Typography> */}
            </Fragment>
        );
        dsAnalyticsHeaderElement = (
            <Typography variant="caption" component="div">
                Add each Data Stream Measurement ID that you wish to track below.
            </Typography>
        );
    } else {
        if (analyticsDataStreams.length > 0) {
            dsAnalyticsHeaderElement = (
                <Typography variant="caption" component="div">
                    Manage your Data Streams that you wish to track below.
                </Typography>
            );
        } else {
            dsAnalyticsHeaderElement = (
                <Typography variant="caption" component="div">
                    Add each Data Stream Measurement ID that you wish to track below.
                </Typography>
            );
        }
    }

    let analyticsSectionElement: any = (
        <Accordion expanded={expanded === 'analytics'} onChange={handleChange('analytics')}>
            <AccordionSummary expandIcon={<Icon color="primary">expand_more</Icon>} id={"analytics.header"}>
                <Typography variant="clickableSubTitle2" component="div">Analytics</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '8px' }}>
                <Box>
                    <Typography variant="caption" component="div">
                        Use this section to setup Google Analytics for your Portal.
                    </Typography>
                    <Typography variant="caption" component="div">
                        To setup Google Analytics, you first need to setup your own Google Analytics account.  Visit their web page at 
                        https://analytics.google.com to setup your account.
                    </Typography>
                    <Typography variant="caption" component="div">
                        Once you have your account setup, be sure to setup Analytics Tracking specifically for your Portal, and then
                        add your Udicci Portal as a Property.
                    </Typography>
                    {dsMessageElement}
                </Box>
                <Box sx={{ margin: '8px' }}>
                    {dsAnalyticsHeaderElement}
                    <ManageAnalyticMeasurements profileSettings={profileSettings} onUpdateProfileSettings={updateProfileSettings} />
                </Box>
            </AccordionDetails>
        </Accordion>
    );

    let displayElement = (
        <Box>
            <Box sx={{ marginTop: '8px' }}>
                <FormControl fullWidth>
                    <Typography variant="overFieldLabel">Display Name</Typography>
                    <TextField id="profile.DisplayName"
                               variant={'outlined'}
                               value={(profile !== null ? profile.DisplayName : '')}
                               onChange={changeProfile.bind(this, 'DisplayName')}
                    />
                </FormControl>
            </Box>
            <Box sx={{ marginTop: '8px' }}>
                <FormControl fullWidth>
                    <Typography variant="overFieldLabel">Headline</Typography>
                    <TextField id="profile.Headline"
                                variant={'outlined'}
                                value={(profile !== null ? profile.Headline : '')}
                                onChange={changeProfile.bind(this, 'Headline')}
                    />
                </FormControl>
            </Box>
            <Box sx={{ marginTop: '32px' }}>
                {missionBioSectionElement}
                {imagesSectionElement}
                {analyticsSectionElement}
            </Box>
        </Box>
    );
    return displayElement;
};

export default ConfigureProfileSettings;
