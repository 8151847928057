import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/HowToReg';

const ClassRegistrationsProcessor = dynamic(() => import('src/components/class-registrations-util'));
const ClassRegistrationsProcessorAdmin = dynamic(() => import('src/admin/class-registrations-processor-admin'));

const classRegistrationsProcessorPlugin: UdicciPlugin = {
    Renderer: (data: any) => ( <ClassRegistrationsProcessor {...data} /> ),
    id: 'class.registration.processor',
    title: 'Class Registration Processor',
    description: 'Display the Class Registration Processor form.',
    version: 1,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <ClassRegistrationsProcessorAdmin {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c classRegistrationsProcessorPlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c classRegistrationsProcessorPlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
    },
    category: UdicciPluginCategory.Development
};
export default classRegistrationsProcessorPlugin;