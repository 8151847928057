import UdicciLogo from 'src/images/Udicci.png';

import { Box, Paper, Typography, Icon, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useUdicciContext } from 'src/context/udicci-context';
import { useFocusedContext } from './focused-context';

import { sampleChatsForOpenAI } from 'src/context/udicci-data-samples';

export const IntroductionContent: React.FC<any> = (props) => {
    const focus = useFocusedContext();

    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;
    let { selectedProfile, currentUser } = udicci;

    let sectionHeaderSx: any = {};
    let sectionHeaderIconSx: any = {};
    if (focus.isMobile) {
        sectionHeaderSx.marginTop = '12px';
        sectionHeaderSx.padding = '2px';
        sectionHeaderIconSx.display = 'initial';
        sectionHeaderIconSx.marginRight = '8px';
    }

    let introGridProps: any = {
        container: true,
        spacing: 3,
        direction: (focus.isMobile ? 'column' : 'row'),
        justifyContent: 'center',
        alignItems: 'stretch',
        marginTop: 0,
    };
    let sectionContentSx: any = { padding: '4px', height: '100%' };

    const PaperSection = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        ...sectionContentSx,
    }));

    let sectionElevation: any = 7;
    let sampleChatCount: number = (sampleChatsForOpenAI && sampleChatsForOpenAI.length > 4 ? sampleChatsForOpenAI.length: 0);
    let example1Text: string = (sampleChatCount > 4 ? sampleChatsForOpenAI[1].prompt : "Explan something to me in simple terms");
    let example2Text: string = (sampleChatCount > 4 ? sampleChatsForOpenAI[2].prompt : "What should I get my son for his 18th birthday?");
    let example3Text: string = (sampleChatCount > 4 ? sampleChatsForOpenAI[3].prompt : "How do I make a website with a contact form?");
    let examplesHeaderContent: any = (
        <Box sx={sectionHeaderSx}>
            <Typography variant="subtitle1" component="span">Examples</Typography>
        </Box>
    );
    let example1: any = (
        <PaperSection elevation={sectionElevation} sx={sectionContentSx}>
            <Typography variant="caption"> "{example1Text}" </Typography>
        </PaperSection>
    );
    let example2: any = (
        <PaperSection elevation={sectionElevation} sx={sectionContentSx}>
            <Typography variant="caption"> "{example2Text}" </Typography>
        </PaperSection>
    );
    let example3: any = (
        <PaperSection elevation={sectionElevation} sx={sectionContentSx}>
            <Typography variant="caption">
                "{example3Text}"
            </Typography>
        </PaperSection>
    );

    let capabilitiesHeaderContent: any = (
        <Box sx={sectionHeaderSx}>
            <Typography variant="subtitle1" component="span">Capabilities</Typography>
        </Box>
    );
    let capability1: any = (
        <PaperSection elevation={sectionElevation} sx={sectionContentSx}>
            <Typography variant="caption">Users can Generate AI responses which meet </Typography>
            <Typography variant="caption">everyday needs, solves problems, and can help others.</Typography>
        </PaperSection>
    );
    let capability2: any = (
        <PaperSection elevation={sectionElevation} sx={sectionContentSx}>
            <Typography variant="caption">Produce detailed datasets from the AI generated responses </Typography>
            <Typography variant="caption">into secured, manageable and actionable data </Typography>
            <Typography variant="caption">efficiently and effectively.</Typography>
        </PaperSection>
    );
    let capability3: any = (
        <PaperSection elevation={sectionElevation} sx={sectionContentSx}>
            <Typography variant="caption">Create dashboards for a Clean and Focused User Experience. </Typography>
            <Typography variant="body2">All Done In Minutes!</Typography>
        </PaperSection>
    );

    let limitationsHeaderContent: any = (
        <Box sx={sectionHeaderSx}>
            <Typography variant="subtitle1" component="span">Limitations</Typography>
        </Box>
    );
    let limitation1: any = (
        <PaperSection elevation={sectionElevation} sx={sectionContentSx}>
            <Typography variant="caption" component="div">Our platform is currently in Beta testing.  There are still some 'Quirks'.</Typography>
            <Typography variant="caption" component="div">And we are still new to AI ourselves!</Typography>
        </PaperSection>
    );
    let limitation2: any = (
        <PaperSection elevation={sectionElevation} sx={sectionContentSx}>
            <Typography variant="caption">User Experience and User Interface are lacking the professional 'perfectionist' touch that we have all come to know so well, and expect.</Typography>
        </PaperSection>
    );
    let limitation3: any = (
        <PaperSection elevation={sectionElevation} sx={sectionContentSx}>
            <Typography variant="caption">AI generated information may be incorrect (occassionally), or potentially contain harmful or biased content.</Typography>
        </PaperSection>
    );

    let gridHeaderItemProps: any = { item: true, xs: 12, md: 4, sx: { paddingTop: '20px !important' } };
    let gridItemProps: any = { item: true, xs: 12, md: 4, sx: { paddingTop: '12px !important' } };
    if (!focus.isMobile) {
        // gridHeaderItemProps.sx.paddingTop = '';
        delete gridHeaderItemProps.sx['paddingTop'];
        delete gridItemProps.sx['paddingTop'];
    }

    let introductionGridElement: any = null;
    if (focus.isMobile) {
        introductionGridElement = (
            <Grid {...introGridProps}>
                <Grid {...gridHeaderItemProps}> {examplesHeaderContent} </Grid>
                <Grid {...gridItemProps}> {example1} </Grid>
                <Grid {...gridItemProps}> {example2} </Grid>
                <Grid {...gridItemProps}> {example3} </Grid>

                <Grid {...gridHeaderItemProps}> {capabilitiesHeaderContent} </Grid>
                <Grid {...gridItemProps}> {capability1} </Grid>
                <Grid {...gridItemProps}> {capability2} </Grid>
                <Grid {...gridItemProps}> {capability3} </Grid>

                <Grid {...gridHeaderItemProps}> {limitationsHeaderContent} </Grid>
                <Grid {...gridItemProps}> {limitation1} </Grid>
                <Grid {...gridItemProps}> {limitation2} </Grid>
                <Grid {...gridItemProps}> {limitation3} </Grid>
            </Grid>
        );
    } else {
        introductionGridElement = (
            <Grid {...introGridProps}>
                <Grid {...gridHeaderItemProps}> {examplesHeaderContent} </Grid>
                <Grid {...gridHeaderItemProps}> {capabilitiesHeaderContent} </Grid>
                <Grid {...gridHeaderItemProps}> {limitationsHeaderContent} </Grid>

                <Grid {...gridItemProps}> {example1} </Grid>
                <Grid {...gridItemProps}> {capability1} </Grid>
                <Grid {...gridItemProps}> {limitation1} </Grid>

                <Grid {...gridItemProps}> {example2} </Grid>
                <Grid {...gridItemProps}> {capability2} </Grid>
                <Grid {...gridItemProps}> {limitation2} </Grid>

                <Grid {...gridItemProps}> {example3} </Grid>
                <Grid {...gridItemProps}> {capability3} </Grid>
                <Grid {...gridItemProps}> {limitation3} </Grid>
            </Grid>
        );
    }

    var profile = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    var profileSocialIcon = (profile && profile.SocialIcon ? profile.SocialIcon : udicci.defaultSocialIcon);

    let logoDisplayElement: any = (<img src={(profileSocialIcon ? profileSocialIcon : UdicciLogo)} alt="Udicci" style={{ maxWidth: '177px' }} />);

    let introductionContainerSx: any = {
        padding: '32px',
        paddingTop: '20px',
        textAlign: 'center',
        position: 'absolute',
        maxWidth: '750px',
        marginTop: '40px',
        top: 5,
        bottom: 120,
        left: 0,
        right: 0,
        overflowY: 'auto',
    };
    if (!focus.isMobile) {
        introductionContainerSx.marginTop = '20px';
        introductionContainerSx.marginLeft = 'auto';
        introductionContainerSx.marginRight = 'auto';
        introductionContainerSx.left = udicci.standardMenuDrawerWidth;
        introductionContainerSx.width = { md: `calc(100% - ${udicci.standardMenuDrawerWidth}px)` };
    }

    let sxSubTitleSection: any = null;
    let sxSubTitleGroupedContent: any = null;
    sxSubTitleSection = { marginTop: '8px' };
    sxSubTitleGroupedContent = { marginTop: '2px' };

    let subTitleDefaultContent: any = null;
    if (currentUser && currentUser.UdicciUserId > 0) {
        if (profile.Headline) {
            subTitleDefaultContent = (
                <Box sx={sxSubTitleSection}>
                    <Typography variant="caption" component="div" sx={sxSubTitleGroupedContent}>
                        {profile.Headline}
                    </Typography>
                </Box>
            );
        }
    } else {
        subTitleDefaultContent = (
            <Box sx={sxSubTitleSection}>
                <Typography variant="caption" component="div" sx={sxSubTitleGroupedContent}>
                    Udicci Has Been Prepared For A Time Such As This!
                </Typography>
                <Typography variant="caption" component="div" sx={sxSubTitleGroupedContent}>
                    AI-connected Platforms can help in everyday life.
                </Typography>
                <Typography variant="caption" component="div" sx={sxSubTitleGroupedContent}>
                    Udicci.IT is a dynamic low-code platform that has been AI-Enabled.
                </Typography>
                <Typography variant="caption" component="div" sx={sxSubTitleGroupedContent}>
                    The ChatGPT integration in Udicci.IT can help you in your everyday life!
                </Typography>
            </Box>
        );
    }

    let subTitle: any = (
        <Box>
            <Typography variant="subtitle1" component="div" sx={sxSubTitleGroupedContent}>
                {selectedProfile?.data.DisplayName}
            </Typography>
            {subTitleDefaultContent}
        </Box>
    );
    const PaperContainer = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        ...introductionContainerSx,
    }));

    return (
        <Box sx={{ padding: '8px', flexGrow: 1, width: { md: `calc(100% - ${udicci.standardMenuDrawerWidth}px)` } }}>
            <PaperContainer>
                {logoDisplayElement}
                {subTitle}
                {introductionGridElement}
            </PaperContainer>
        </Box>
    );
};
