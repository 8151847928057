import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/RecentActors';

const Onboarding = dynamic(() => import('src/components/onboarding'));
const OnboardingAdmin = dynamic(() => import('src/admin/onboarding-admin'));

const onboardingPlugin: UdicciPlugin = {
    Renderer: (data: any) => ( <Onboarding {...data} /> ),
    id: 'udicci.onboarding',
    title: 'Udicci Onboarding',
    description: 'Display the Udicci Onboarding component.',
    version: 1,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <OnboardingAdmin {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c onboardingPlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c onboardingPlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
    },
    category: UdicciPluginCategory.Special
};
export default onboardingPlugin;