import React, { Fragment, useState } from 'react';

import { forEach } from 'underscore';

import {
    Box, Typography, Table,  TableBody,  TableCell,  TableContainer,  TableHead,  TableRow,  Paper, 
    Checkbox, IconButton, Icon, TextField, Grid,
 } from '@mui/material';

import { useUdicciContext } from 'src/context/udicci-context';

interface DataStream {
    url: string;
    measurementId: string;
    active: boolean;
}

const ManageAnalyticMeasurements: React.FC<any> = (props) => {
    // console.log('%c ManageAnalyticMeasurements props: %O', 'color: maroon;', props);

    const udicciContext = useUdicciContext();
    // console.log('%c ManageAnalyticMeasurements udicciContext: %O', 'color: red;', udicciContext);
    let { udicci } = udicciContext.state;

    var profileSettings: any = (props && props.profileSettings ? props.profileSettings : null);
    // console.log('%c ManageAnalyticMeasurements profileSettings: %O', 'color: maroon;', profileSettings);
    var analytics: any = (profileSettings && profileSettings.analytics ? profileSettings.analytics : null);
    // console.log('%c ManageAnalyticMeasurements analytics: %O', 'color: maroon;', analytics);
    var analyticsDataStreams: any[] = (analytics && analytics.dataStreams ? analytics.dataStreams : []);
    // console.log('%c ManageAnalyticMeasurements analyticsDataStreams: %O', 'color: maroon;', analyticsDataStreams);

    const [dataStreams, setDataStreams] = useState<DataStream[]>(analyticsDataStreams);
    const [selectedDataStream, setDataStream] = useState<number>(-1);
    // console.log('%c ManageAnalyticMeasurements selectedDataStream: %O', 'color: maroon;', selectedDataStream);

    const addMeasurement = () => {
        setDataStreams([...dataStreams, { url: '', measurementId: '', active: true }]);
        setDataStream(dataStreams.length);
    };

    const changeValue = (fieldName: string, evt: any) => {
        // console.log('%c changeValue fieldName: %O', 'color: red;', fieldName);
        if (selectedDataStream < 0) return false;

        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c changeValue newValue: %O', 'color: maroon;', newValue);
        const updatedDataStreams: any[] = [...dataStreams];
        updatedDataStreams[selectedDataStream][fieldName] = newValue;
        setDataStreams(updatedDataStreams);
    }

    const deleteMeasurement = (index: number) => {
        const updatedDataStreams: any[] = [...dataStreams];
        updatedDataStreams.splice(index, 1);
        setDataStreams(updatedDataStreams);
    };

    const toggleActive = (index: number) => {
        const updatedDataStreams: any[] = [...dataStreams];
        updatedDataStreams[index].active = !updatedDataStreams[index].active;
        setDataStreams(updatedDataStreams);
    };

    const saveAnalytics = () => {
        // console.log('%c saveAnalytics profileSettings: %O', 'color: maroon;', profileSettings);
        // console.log('%c saveAnalytics analytics: %O', 'color: maroon;', analytics);

        if (!profileSettings) profileSettings = {};
        if (!analytics) analytics = {};

        analyticsDataStreams = dataStreams;
        analytics.dataStreams = analyticsDataStreams;
        profileSettings.analytics = analytics;
        if (props && props.onUpdateProfileSettings) props.onUpdateProfileSettings(profileSettings);
    };

    let manageElement: any = null;
    if (udicci.isMobileDevice()) {
        // grid display on mobile devices
        let gridElements: any[] = [];
        if (dataStreams && dataStreams.length > 0) {
            forEach(dataStreams, (ds: DataStream, index: number) => {
                let urlElement: any = null;
                let measurementIdElement: any = null;
                let activeElement: any = null;
                let buttonsElement: any = null;

                if (selectedDataStream >= 0 && selectedDataStream === index) {
                    urlElement = (
                        <TextField id="data.stream.url" fullWidth
                                variant="standard"
                                margin={'dense'}
                                value={ds.url}
                                onChange={(evt: any) => changeValue('url', evt)} />
                    );
                    measurementIdElement = (
                        <TextField id="data.stream.measurementId" fullWidth
                                variant="standard"
                                margin={'dense'}
                                value={ds.measurementId}
                                onChange={(evt: any) => changeValue('measurementId', evt)} />
                    );
                    activeElement = (
                        <Checkbox checked={ds.active} onChange={() => toggleActive(index)} />
                    );
                    buttonsElement = (
                        <Fragment>
                            <IconButton color="secondary" size="small" onClick={(evt: any) => setDataStream(-1)}><Icon fontSize="small">close</Icon></IconButton>
                        </Fragment>
                    );
                } else {
                    urlElement = (
                        <Typography variant="subtitle2">{ds.url}</Typography>
                    );
                    measurementIdElement = (
                        <Typography variant="body2">{ds.measurementId}</Typography>
                    );
                    activeElement = (
                        <Typography variant="caption">{(ds.active ? 'active' : 'not active')}</Typography>
                    );
                    buttonsElement = (
                        <Fragment>
                            <IconButton color="secondary" size="small" onClick={(evt: any) => deleteMeasurement(index)}><Icon fontSize="small">remove</Icon></IconButton>
                            <IconButton color="secondary" size="small" onClick={(evt: any) => setDataStream(index)}><Icon fontSize="small">edit</Icon></IconButton>
                        </Fragment>
                    );
                }

                gridElements.push(
                    <Grid item key={'data.stream.item. ' + index.toString() + '.' + ds.measurementId}>
                        <Box> {urlElement} </Box>
                        <Box>{measurementIdElement}</Box>
                        <Box>{activeElement}</Box>
                        <Box>{buttonsElement}</Box>
                    </Grid>
                )
            });
            manageElement = (
                <Grid container spacing={1}>
                    {gridElements}
                </Grid>
            );
        } else {
            manageElement = (
                <Box>
                    <Typography variant="caption">
                        No Analytics Data Streams have been configured for this Portal.
                    </Typography>
                    <IconButton color="secondary" size="small" onClick={addMeasurement}><Icon fontSize="small">add</Icon></IconButton>
                </Box>
            );
        }
    } else {
        // table display on desktop devices
        let tableHead: any = (
            <TableHead>
                <TableRow>
                    <TableCell>URL</TableCell>
                    <TableCell align="center">Measurement ID</TableCell>
                    <TableCell align="center">Active</TableCell>
                    <TableCell align="right">
                        <IconButton color="secondary" size="small" onClick={saveAnalytics}><Icon fontSize="small">save</Icon></IconButton>
                        <IconButton color="secondary" size="small" onClick={addMeasurement}><Icon fontSize="small">add</Icon></IconButton>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
        let tableBody: any = null;
        if (dataStreams && dataStreams.length > 0) {
            tableBody = (
                <TableBody>
                    {dataStreams.map((ds: DataStream, index: number) => {
                        let urlElement: any = null;
                        let measurementIdElement: any = null;
                        let activeElement: any = null;
                        let buttonsElement: any = null;
    
                        if (selectedDataStream >= 0 && selectedDataStream === index) {
                            urlElement = (
                                <TextField id="data.stream.url" fullWidth
                                        variant="standard"
                                        margin={'dense'}
                                        value={ds.url}
                                        onChange={(evt: any) => changeValue('url', evt)} />
                            );
                            measurementIdElement = (
                                <TextField id="data.stream.measurementId" fullWidth
                                        variant="standard"
                                        margin={'dense'}
                                        value={ds.measurementId}
                                        onChange={(evt: any) => changeValue('measurementId', evt)} />
                            );
                            activeElement = (
                                <Checkbox checked={ds.active} onChange={() => toggleActive(index)} />
                            );
                            buttonsElement = (
                                <Fragment>
                                    <IconButton color="secondary" size="small" onClick={(evt: any) => setDataStream(-1)}><Icon fontSize="small">close</Icon></IconButton>
                                </Fragment>
                            );
                        } else {
                            urlElement = (
                                <Typography variant="subtitle2">{ds.url}</Typography>
                            );
                            measurementIdElement = (
                                <Typography variant="body2">{ds.measurementId}</Typography>
                            );
                            activeElement = (
                                <Typography variant="caption">{(ds.active ? 'active' : 'not active')}</Typography>
                            );
                            buttonsElement = (
                                <Fragment>
                                    <IconButton color="secondary" size="small" onClick={(evt: any) => deleteMeasurement(index)}><Icon fontSize="small">remove</Icon></IconButton>
                                    <IconButton color="secondary" size="small" onClick={(evt: any) => setDataStream(index)}><Icon fontSize="small">edit</Icon></IconButton>
                                </Fragment>
                            );
                        }
    
                        return (
                            <TableRow key={'data.stream.row. ' + index.toString() + '.' + ds.measurementId}>
                                <TableCell component="th" scope="row"> {urlElement} </TableCell>
                                <TableCell align="center">{measurementIdElement}</TableCell>
                                <TableCell align="center">{activeElement}</TableCell>
                                <TableCell align="right">{buttonsElement}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            );
        } else {
            tableBody = (
                <TableBody>
                    <TableRow>
                        <TableCell align="center" colSpan={4}>
                            No Analytics Data Streams have been configured for this Portal.
                            <IconButton color="secondary" size="small" onClick={addMeasurement}><Icon fontSize="small">add</Icon></IconButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            );
        }
    
        manageElement = (
            <TableContainer component={Paper}>
                <Table>
                    {tableHead}
                    {tableBody}
                </Table>
            </TableContainer>
        );
    }

    return manageElement;
};

export default ManageAnalyticMeasurements;
