import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/HowToReg';

const VideoIndexing = dynamic(() => import('src/components/video-indexing'));
const VideoIndexingAdmin = dynamic(() => import('src/admin/video-indexing-admin'));

const videoIndexingPlugin: UdicciPlugin = {
    Renderer: (data: any) => ( <VideoIndexing {...data} /> ),
    id: 'videoIndexing',
    title: 'Video Indexing',
    description: 'Video Indexing Tool.',
    version: 1,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <VideoIndexingAdmin {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c videoIndexingPlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c videoIndexingPlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
    },
    category: UdicciPluginCategory.Custom
};
export default videoIndexingPlugin;