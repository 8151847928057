
import { Fragment, useState, useContext, useEffect } from 'react';

import { useTheme } from '@mui/material/styles';

import { forEach, sortBy, filter } from 'underscore';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Switch from '@mui/material/Switch';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import AttachFile from '@mui/icons-material/AttachFile';
import SecureSetup from '@mui/icons-material/PhonelinkSetup';
import Secured from '@mui/icons-material/VpnLock';

import { UdicciContext } from 'src/context/udicci-context';
import { AttachmentFile } from 'src/classes/udicci-types';
// import Attachments from './attachments';
import Perspectives from './perspectives';

import { udicciStyles } from 'src/theme/shared-styles';

import DefaultSocialIcon from 'src/images/Ulysses Selfie Portrait.png'

export default function ChainStoryBit(this: any, props: any) {
    // console.log('%c ChainStoryBit props: %O', 'color: maroon;', props);

    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const udicciContext = useContext(UdicciContext);
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    let { minimized } = props;
    // console.log('%c ChainStoryBit minimized: %O', 'color: maroon;', minimized);
    // console.log('%c ChainStoryBit openInForm: %O', 'color: maroon;', openInForm);

    let originalMediaBit: any = (props && props.mediaBit ? props.mediaBit : null);
    // console.log('%c ChainStoryBit originalMediaBit: %O', 'color: maroon;', originalMediaBit);

    let { data, udicci } = udicciContext.state;
    // console.log('%c ChainStoryBit data: %O', 'color: maroon;', data);
    // console.log('%c ChainStoryBit udicci: %O', 'color: maroon;', udicci);

    // const recordContext = useContext(RecordContext);
    // console.log('%c ChainStories recordContext: %O', 'color: red;', recordContext);

    // let contextMediaBit: any = (recordContext && recordContext.state && recordContext.state.record ? recordContext.state.record : null);
    // console.log('%c ChainStoryBit contextMediaBit: %O', 'color: maroon;', contextMediaBit);
    // console.log('%c ChainStories factory: %O', 'color: maroon;', factory);

    const [mediaBit, setMediaBit] = useState<any>(originalMediaBit);
    // console.log('%c ChainStoryBit mediaBit: %O', 'color: maroon;', mediaBit);
    const [anchorBitEl, setAnchorBitEl] = useState<null | HTMLElement>(null);
    const [showAttachments, setShowAttachments] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [removeMediaBit, setRemoveMediaBit] = useState<boolean>(false);
    const [showBlockchain, setShowBlockchain] = useState<boolean>(false);
    const [fullScreenAttachment, setFullScreenAttachment] = useState<any | null>(null);
    // const [activeStep, setActiveStep] = useState(0);
    const [forcedUpdateState, forceUpdate] = useState<boolean>(false);
    // console.log('%c ChainStoryBit showBlockchain: %O', 'color: maroon;', showBlockchain);

    let { selectedProfile, currentUser } = udicci;
    // console.log('%c ChainStoryBit data: %O', 'color: maroon;', data);
    // console.log('%c ChainStoryBit selectedProfile: %O', 'color: maroon;', selectedProfile);
    // console.log('%c ChainStoryBit currentUser: %O', 'color: maroon;', currentUser);

    let relationships: any = null;
    if (data && mediaBit) {
        let mediatorContext = data.find((x: any) => x.mediator === 'Mediation Agreements' );
        // console.log('%c mediatorContext: %O', 'color: blue;', mediatorContext);
        if (mediatorContext && mediatorContext.records) relationships = mediatorContext.records;
    }
    // console.log('%c relationships: %O', 'color: blue;', relationships);

    const handleBitMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorBitEl(event.currentTarget);
    };

    const handleBitMenuClose = () => {
        setAnchorBitEl(null);
    };

    const toggleAttachments = () => {
        setShowAttachments(!showAttachments);
    };

    // const confirmAttachments = (attachmentFiles: AttachmentFile[]) => {
    //     // console.log('%c confirmAttachments attachmentFiles: %O', 'color: green;', attachmentFiles);
    //     // console.log('%c confirmAttachments mediaBit: %O', 'color: green;', mediaBit);
    //     if (mediaBit) mediaBit.Attachments = attachmentFiles;
    //     setMediaBit(mediaBit);
    //     setShowAttachments(!showAttachments);
    // };

    const removeMediaBitRecord = () => {
        // console.log('%c removeMediaBitRecord mediaBit: %O', 'color: green;', mediaBit);
        setAnchorBitEl(null);
        setRemoveMediaBit(true);
    };

    const closeRemoveMediaBit = () => {
        setRemoveMediaBit(false);
    };

    const confirmRemoveMediaBit = () => {
        setRemoveMediaBit(false);
        if (props && props.requestRemove) props.requestRemove(mediaBit);
    };
    // console.log('%c mediaBits: %O', 'color: green;', mediaBits);

    const showAttachmentFulLScreen = (attachment: any) => {
        // console.log('%c showAttachmentFulLScreen attachment: %O', 'color: blue;', attachment);
        setFullScreenAttachment(attachment);
    };

    const closeAttachmentFullScreen = () => {
        setFullScreenAttachment(null);
    };

    const showBlockchainDialog = () => {
        // console.log('%c showBlockchainDialog showBlockchain: %O', 'color: blue;', showBlockchain);
        setAnchorBitEl(null);
        setShowBlockchain(true);
    };

    const closeBlockchainDialog = () => {
        setShowBlockchain(false);
    };

    const sharePerspective = (perspectives: any[]) => {
        // console.log('%c sharePerspective perspectives: %O', 'color: green;', perspectives);
        // console.log('%c sharePerspective mediaBit: %O', 'color: green;', mediaBit);
        if (mediaBit && mediaBit.data) mediaBit.data.Perspectives = perspectives;
        setMediaBit(mediaBit);
    };

    // const handleToggleBlockchain = () => {
    //     setShowBlockchain(!showBlockchain);
    // };

    const changeRecordValue = (udicciContext: any | null, fieldName: any | null, evt: any) => {
        // console.log('%c changeRecordValue fieldName: %O', 'color: red;', fieldName);
        // console.log('%c changeRecordValue evt: %O', 'color: red;', evt);
        // console.log('%c udicciContext: %O', 'color: red;', udicciContext);
    
        let { data } = udicciContext.state;
        // console.log('%c changeRecordValue data: %O', 'color: maroon;', data);
        // console.log('%c changeRecordValue udicci: %O', 'color: maroon;', udicci);

        let structure: any = null;
        if (data && mediaBit) {
            let mediatorContext = data.find((x: any) => x.mediator === mediaBit?.udicciMediator );
            // console.log('%c changeRecordValue mediatorContext: %O', 'color: red;', mediatorContext);
            if (mediatorContext && mediatorContext.structure) structure = mediatorContext.structure;
        }
        // console.log('%c changeRecordValue structure: %O', 'color: green;', structure);

        // convert fieldName to UdicciMediatorField object
        let fields: any = null;
        if (structure) {
            fields = (structure.UdicciMediatorFields ? structure.UdicciMediatorFields : null);
        }
        // console.log('%c changeRecordValue fields: %O', 'color: maroon;', fields);
    
        let field: any = null;
        if (fields && fields.length > 0) {
            field = fields.find((x: any) => x.JsonFieldName === fieldName );
        }
        // console.log('%c changeRecordValue field: %O', 'color: maroon;', field);
    
        if (field) {
            // console.log('%c changeRecordValue evt: %O', 'color: red;', evt);
            // console.log('%c changeRecordValue field: %O', 'color: red;', field);
            // console.log('%c changeRecordValue mediaBit: %O', 'color: red;', mediaBit);

            let trgt = evt.target;
            let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
            // console.log('%c changeRecordValue newValue: %O', 'color: red;', newValue);

            if (!mediaBit) return false;

            let fieldJsonKey = (field && field.JsonFieldName ? field.JsonFieldName : field.Name)
            // console.log('%c changeRecordValue fieldJsonKey: %O', 'color: red;', fieldJsonKey);

            if (mediaBit) {
                let recordData = (mediaBit.data ? mediaBit.data : {});
                // console.log('%c changeRecordValue recordData: %O', 'color: red;', recordData);
                let tf = (mediaBit.keys && mediaBit.keys.title ? mediaBit.keys.title : '');
                let df = (mediaBit.keys && mediaBit.keys.description ? mediaBit.keys.description : '');
                // console.log('%c changeRecordValue recordData: %O', 'color: red;', recordData);
                if (recordData[fieldJsonKey] !== newValue) {
                    recordData[fieldJsonKey] = newValue;
                    // console.log('%c changeRecordValue recordData: %O', 'color: darkorange;', recordData);
                    mediaBit.data = recordData;
                    mediaBit.isDirty = true;
                }
                if (fieldJsonKey === tf) mediaBit.title = recordData[fieldJsonKey];
                if (fieldJsonKey === df) mediaBit.description = recordData[fieldJsonKey];

                if (!mediaBit.permissions && mediaBit.recordId <= 0) {
                    let newPermissions: any = {
                        CanView: true, CanAdd: true, CanEdit: true, CanDelete: true, CanDuplicate: true, CanProvision: true
                    };
                    mediaBit.permissions = newPermissions;
                }
                // console.log('%c changeRecordValue mediaBit: %O', 'color: darkorange;', mediaBit);
                setMediaBit(mediaBit);
                forceUpdate(!forcedUpdateState);
            }
        }
    };

    const editMediaBit = () => {
        // console.log('%c mediaBit: %O', 'color: blue;', mediaBit);
        setEdit(true);
        setAnchorBitEl(null);
        // setRecordFocus(mediaBit);
    };

    const handleSelectMediaBit = () => {
        // console.log('%c handleSelectMediaBit mediaBit: %O', 'color: darkgray;', mediaBit);
    };

    const hideRecordForm = (evt: any) => {
        // console.log('%c hideRecordForm mediaBit: %O', 'color: darkgray;', mediaBit);
        setEdit(false);
        // setRecordFocus(null);
        if (mediaBit && mediaBit.recordId <= 0) {
            if (props && props.requestRemove) props.requestRemove(mediaBit);
        }
    };

    const saveRecord = (evt: any) => {
        // console.log('%c saveRecord mediaBit: %O', 'color: maroon;', mediaBit);
        // console.log('%c saveRecord contextMediaBit: %O', 'color: maroon;', contextMediaBit);
        if (!mediaBit) return false;
        if (props && props.requestUpdate) props.requestUpdate(mediaBit);
    };

    const changeBlockchainSetting = (evt: any) => {
        // console.log('%c changeBlockchainSetting evt: %O', 'color: maroon;', evt);
        // console.log('%c changeBlockchainSetting mediaBit: %O', 'color: maroon;', mediaBit);
        if (!mediaBit) return false;

        let trgt = evt.target;
        let fieldName = (trgt && trgt.name ? trgt.name : '');
        // console.log('%c changeBlockchainSetting fieldName: %O', 'color: maroon;', fieldName);
        if (!fieldName) return false;

        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c changeBlockchainSetting newValue: %O', 'color: maroon;', newValue);

        // if (fieldName === 'transactionSignators') {
        //     let signatorRelationships: any[] = [];
        //     // console.log('%c changeBlockchainSetting relationships: %O', 'color: red;', relationships);
        //     if (relationships && relationships.length > 0) {
        //         signatorRelationships = filter(relationships, function(r) {
        //             let fnd = newValue.indexOf(r.data.AcceptingProfileId);
        //             // console.log('%c fnd: %O', 'color: red;', fnd);
        //             return (fnd >= 0 ? true : false);
        //         });
        //     }
        //     // console.log('%c signatorRelationships: %O', 'color: orange;', signatorRelationships);
        // }

        let ledgerSettings = (mediaBit.ledgerSettings ? mediaBit.ledgerSettings : {});
        // console.log('%c changeBlockchainSetting ledgerSettings: %O', 'color: maroon;', ledgerSettings);

        ledgerSettings[fieldName] = newValue;
        mediaBit.ledgerSettings = ledgerSettings;
        mediaBit.isDirty = true;
        // console.log('%c changeBlockchainSetting mediaBit: %O', 'color: maroon;', mediaBit);
        setMediaBit(mediaBit);
        if (props && props.requestUpdate) props.requestUpdate(mediaBit);
        forceUpdate(!forcedUpdateState);
    };

    useEffect(() => {
        // Update the document title using the browser API
        if (mediaBit && mediaBit.recordId <= 0) setEdit(true);
    }, [ mediaBit ]);

    let creatorDisplayName = '';
    if (selectedProfile){
        creatorDisplayName = (selectedProfile.data.DisplayName ? selectedProfile.data.DisplayName : '');
    }
    // console.log('%c ChainStoryBit mediaBit: %O', 'color: maroon;', mediaBit);

    let fullScreenAttachmentElement: any = null;
    // console.log('%c fullScreenAttachment: %O', 'color: orange;', fullScreenAttachment);
    if (fullScreenAttachment !== null) {
        let fileThumbnail = (fullScreenAttachment.fileThumbnail ? fullScreenAttachment.fileThumbnail : null);
        // console.log('%c fileThumbnail: %O', 'color: orange;', fileThumbnail);
        if (fileThumbnail && fileThumbnail.url) {
            let imageTag = (
                <img src={fileThumbnail.url}
                     title={fileThumbnail.filename}
                     alt={fileThumbnail.filename}
                />
            );
            fullScreenAttachmentElement = (
                <Dialog open={true} onClose={closeAttachmentFullScreen} maxWidth={'lg'}>
                    <DialogContent> {imageTag} </DialogContent>
                    <DialogActions>
                        <Box sx={udicciClasses.grow} />
                        <Button size="small" color="secondary" onClick={closeAttachmentFullScreen}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }
    }

    // console.log('%c showBlockchain: %O', 'color: orange;', showBlockchain);
    let showBlockchainDialogElement: any = (showBlockchain === true ? getBlockchainForm() : null);
    // console.log('%c showBlockchainDialogElement: %O', 'color: orange;', showBlockchainDialogElement);

    let removeMediaBitDialog: any = null;
    if (removeMediaBit) {
        let mbTitle = (mediaBit && mediaBit.data && mediaBit.data.Title ? mediaBit.data.Title : '-');
        // console.log('%c mbTitle: %O', 'color: orange;', mbTitle);

        let dialogTitleEl = (
            <Fragment>
                <Typography variant="subtitle2" component="span">Remove </Typography>
                <Typography variant="subtitle1" component="span">
                    {mbTitle}
                </Typography>
                <Typography variant="subtitle2" component="span"> media bit?</Typography>
            </Fragment>
        );
        removeMediaBitDialog = (
            <Dialog
                open={true}
                onClose={closeRemoveMediaBit}
                aria-labelledby="remove-media-bit-dialog-title"
                aria-describedby="remove-media-bit-dialog-message"
            >
                <DialogTitle id="remove-media-bit-dialog-title">{dialogTitleEl}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="remove-media-bit-dialog-message">
                        <Typography variant="body2">
                            This action will delete this record, attachments and perspectives from all feeds.
                        </Typography>
                        <Typography variant="caption">
                            Only you can determine if you are sure of this action.  Are you sure?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeRemoveMediaBit} color="primary">
                        Nevermind
                    </Button>
                    <Button onClick={confirmRemoveMediaBit} color="primary" autoFocus>
                        Remove Media Bit
                    </Button>
                </DialogActions>
            </Dialog>
        );        
    }

    if (edit) {
        return (
            <Fragment>
                { fullScreenAttachmentElement }
                { showBlockchainDialogElement }
                { removeMediaBitDialog }
                { getMediaBitForm() }
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                { fullScreenAttachmentElement }
                { showBlockchainDialogElement }
                { removeMediaBitDialog }
                { getMediaBitDisplay() }
            </Fragment>
        );
    }

    function getMediaBitForm() {
        let titleElement: any = null;
        let subtitleElement: any = null;
        let titleFormElement: any = null;
        let narrativeFormElement: any = null;
        let fileUploadFormElement: any = null;
        let imageThumbsElement: any = null;
        if (mediaBit) {
            // console.log('%c mediaBit: %O', 'color: green;', mediaBit);
            let attachments: any = (mediaBit ? mediaBit.Attachments : null);
            // console.log('%c attachments: %O', 'color: green;', attachments);

            let secureIconButton: any = (
                <IconButton size="small"
                    color="info"
                    edge="end"
                    component="span"
                    onClick={showBlockchainDialog}
                    sx={udicciClasses.headerButton}>
                    <SecureSetup sx={udicciClasses.headerButtonIcon} />
                </IconButton>
            );

            let attachmentsIconButton: any = null;
            if (showAttachments) {
                attachmentsIconButton = (
                    <IconButton size="small"
                        color="primary"
                        edge="end"
                        component="span"
                        onClick={toggleAttachments}
                        sx={udicciClasses.headerButton}>
                        <AttachFile sx={udicciClasses.headerButtonIcon} />
                    </IconButton>
                );
            } else {
                attachmentsIconButton = (
                    <IconButton size="small"
                        color="info"
                        edge="end"
                        component="span"
                        onClick={toggleAttachments}
                        sx={udicciClasses.headerButton}>
                        <AttachFile sx={udicciClasses.headerButtonIcon} />
                    </IconButton>
                );
            }
            titleElement = (
                <Box sx={{ padding: '8px' }}>
                    {attachmentsIconButton}
                    {secureIconButton}
                    <Typography variant="subtitle1">
                        Media Bits.
                    </Typography>
                </Box>
            );
            let fieldLabelTitle = 'Title';
            let controlPropsTitle: any = {};
            controlPropsTitle.fullWidth = true;
            controlPropsTitle.sx = { margin: '8px' };
            // console.log('%c controlPropsTitle: %O', 'color: orange;', controlPropsTitle);

            let inputPropsTitle: any = {};
            inputPropsTitle.id = 'media.bit.title.0';
            inputPropsTitle.type = 'text';
            inputPropsTitle.autoFocus = true;
            inputPropsTitle.value = (mediaBit.data && mediaBit.data.Name ? mediaBit.data.Name : '');
            inputPropsTitle.onChange = (evt: any) => { changeRecordValue(udicciContext, 'Name', evt); };
            titleFormElement = (
                <FormControl {...controlPropsTitle}>
                    <Typography variant="overFieldLabel">{fieldLabelTitle}</Typography>
                    <Input {...inputPropsTitle} />
                </FormControl>
            );

            let fieldLabelNarrative = 'Narrative';
            let controlPropsNarrative: any = {};
            controlPropsNarrative.fullWidth = true;
            controlPropsNarrative.sx = { margin: '8px' };
            // console.log('%c controlPropsNarrative: %O', 'color: orange;', controlPropsNarrative);

            let inputPropsNarrative: any = {};
            inputPropsNarrative.id = 'media.bit.narrative.0';
            inputPropsNarrative.type = 'text';
            inputPropsNarrative.value = (mediaBit.data ? mediaBit.data.HtmlContent : '');
            inputPropsNarrative.onChange = (evt: any) => { changeRecordValue(udicciContext, 'HtmlContent', evt); };
            inputPropsNarrative.multiline = true;
            inputPropsNarrative.rows = 2;
            inputPropsNarrative.rowsMax = 15;
            narrativeFormElement = (
                <FormControl {...controlPropsNarrative}>
                    <Typography variant="overFieldLabel">{fieldLabelNarrative}</Typography>
                    <Input {...inputPropsNarrative} />
                </FormControl>
            );

            if (showAttachments) {
                let imageThumbs: any[] = [];
                if (attachments && attachments.length > 0) {
                    for (let [idx, attchmnt] of Object.entries<any>(attachments)) {
                        // console.log('%c attchmnt: %O', 'color: hotpink;', attchmnt);
                        let fileThumbnail = (attchmnt.fileThumbnail ? attchmnt.fileThumbnail : null);
                        // console.log('%c fileThumbnail: %O', 'color: orange;', fileThumbnail);
                        if (fileThumbnail && fileThumbnail.url && idx !== '') {
                            let thumbKey = 'image.thumb.' + attchmnt.id;
                            let imageThumbTag = (
                                <img src={fileThumbnail.url} title={fileThumbnail.filename} alt={fileThumbnail.filename} />
                            );
                            imageThumbs.push(
                                <Paper elevation={0}
                                        component="span"
                                        key={thumbKey}
                                        onClick={(evt: any) => showAttachmentFulLScreen(attchmnt)}
                                        sx={udicciClasses.attachmentThumbContainer}
                                >
                                    {imageThumbTag}
                                </Paper>
                            );
                        }
                    }
                }

                if (imageThumbs.length > 0) {
                    imageThumbsElement = (
                        <Box sx={udicciClasses.cardContent}>
                            {imageThumbs}
                        </Box>
                    );
                } else {
                    imageThumbsElement = (
                        <Box sx={udicciClasses.cardContent}>
                            <Typography variant="caption">No attachments to show.</Typography>
                        </Box>
                    );
                }
            }

            // if (showAttachments) {
            //     fileUploadFormElement = (
            //         <Attachments onAttach={confirmAttachments} onCancel={toggleAttachments} attachments={attachments} />
            //     );
            // }
        }
        // console.log('%c titleElement: %O', 'color: orange;', titleElement);
        // console.log('%c narrativeFormElement: %O', 'color: orange;', narrativeFormElement);

        let buttonsElement: any = null;
        if (!showAttachments) {
            buttonsElement = (
                <Box sx={udicciClasses.buttonCardContent}>
                    <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" alignContent="center">
                        <Grid item xs={12} md={6}>
                            <Button fullWidth onClick={hideRecordForm} color="secondary" variant="contained">
                                Close Form
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button fullWidth onClick={saveRecord} color="primary" variant="contained">
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            );
        }

        // let storyId = 0;
        // let storySelectionItemsElement: any = null;
        // let storySelectionElement = (
        //     <FormControl fullWidth>
        //         <InputLabel id="media-bit-story-label">Story</InputLabel>
        //         <Select
        //             labelId="media-bit-story-label"
        //             id="media-bit-story"
        //             name="story"
        //             value={storyId}
        //             onChange={changeBlockchainSetting}
        //             input={<Input id="media-bit-story-select" />}
        //         >
        //             {storySelectionItemsElement}
        //         </Select>
        //     </FormControl>
        // );

        let mediaBitForm = (
            <Paper elevation={9} sx={udicciClasses.standardCardContentForm}>
                <Box sx={{ padding: '8px' }}>
                    {titleElement}
                    {subtitleElement}
                    {titleFormElement}
                    {narrativeFormElement}
                    {fileUploadFormElement}
                    {imageThumbsElement}
                </Box>
                {buttonsElement}
            </Paper>
        );
        return mediaBitForm;
    }

    function getMediaBitDisplay() {
        // console.log('%c mediaBit: %O', 'color: orange;', mediaBit);
        // let bitId = (mediaBit.recordId ? mediaBit.recordId : 0);
        // console.log('%c bitId: %O', 'color: orange;', bitId);
        let bitTitle = (mediaBit.data.Name ? mediaBit.data.Name : '');
        let bitNarrative = (mediaBit.data.HtmlContent ? mediaBit.data.HtmlContent : '');
        let bitAttachments: AttachmentFile[] = (mediaBit.Attachments ? mediaBit.Attachments : []);
        // console.log('%c bitAttachments: %O', 'color: orange;', bitAttachments);
        let creatorAvatarElement: any = null;
        let displayNameElement: any = null;
        let titleElement: any = null;
        let narrativeElement: any = null;
        let attachmentsElement: any = null;
        let perspectivesElement: any = null;

        let userSocialIcon = (currentUser && currentUser.mySocialIcon ? currentUser.mySocialIcon : null);
        // console.log('%c ChainStoryBit userSocialIcon: %O', 'color: blue;', userSocialIcon);
    
        if (userSocialIcon) {
            creatorAvatarElement = (
                <Avatar src={userSocialIcon}
                        sx={udicciClasses.creatorSocialIcon}
                />
            );
        }

        if (creatorDisplayName) {
            displayNameElement = (
                <Typography variant="body2" sx={udicciClasses.creatorDisplayName}>
                    {creatorDisplayName}
                </Typography>
            );
        }

        if (bitTitle) {
            titleElement = (
                <Typography variant="subtitle2" component="div"> {bitTitle} </Typography>
            );
        }

        if (bitNarrative && !minimized) {
            narrativeElement = (
                <Typography variant="body2" component="div" sx={udicciClasses.narrative}>
                    {bitNarrative}
                </Typography>
            );
        }

        if (bitAttachments && bitAttachments.length > 0) {
            let imageThumbs: any[] = [];
            if (minimized) {
                let attchmnt = bitAttachments[0];
                let fileThumbnail = (attchmnt.fileThumbnail ? attchmnt.fileThumbnail : null);
                // console.log('%c fileThumbnail: %O', 'color: orange;', fileThumbnail);
                if (fileThumbnail && fileThumbnail.url) {
                    let thumbKey = 'image.thumb.' + attchmnt.id;
                    let imageThumbTag = (
                        <img src={fileThumbnail.url} title={fileThumbnail.filename} alt={fileThumbnail.filename} />
                    );
                    imageThumbs.push(
                        <Paper elevation={0}
                               component="span"
                               key={thumbKey}
                               onClick={(evt: any) => showAttachmentFulLScreen(attchmnt)}
                               sx={udicciClasses.attachmentThumbContainer}
                        >
                            {imageThumbTag}
                        </Paper>
                    );
                }
            } else {
                for (let [idx, attchmnt] of Object.entries<any>(bitAttachments)) {
                    // console.log('%c idx: %O', 'color: hotpink;', idx);
                    // console.log('%c attchmnt: %O', 'color: hotpink;', attchmnt);
                    let fileThumbnail = (attchmnt.fileThumbnail ? attchmnt.fileThumbnail : null);
                    // console.log('%c fileThumbnail: %O', 'color: orange;', fileThumbnail);
                    if (fileThumbnail && fileThumbnail.url && idx !== '') {
                        let thumbKey = 'image.thumb.' + attchmnt.id;
                        let imageThumbTag = (
                            <img src={fileThumbnail.url} title={fileThumbnail.filename} alt={fileThumbnail.filename} />
                        );
                        imageThumbs.push(
                            <Paper elevation={0}
                                   component="span"
                                   key={thumbKey}
                                   onClick={(evt: any) => showAttachmentFulLScreen(attchmnt)}
                                   sx={udicciClasses.attachmentThumbContainer}
                            >
                                {imageThumbTag}
                            </Paper>
                        );
                    }
                }
            }

            if (imageThumbs.length > 0) {
                attachmentsElement = (
                    <Box sx={udicciClasses.cardContent}>
                        {imageThumbs}
                    </Box>
                );
            }
        }

        let mediaBitMenuElement: any = null;
        mediaBitMenuElement = (
            <Menu
                id="media-bit-menu"
                anchorEl={anchorBitEl}
                open={Boolean(anchorBitEl)}
                onClose={handleBitMenuClose}
            >
                <MenuItem onClick={(evt: any) => { editMediaBit(); } }>
                    <EditIcon sx={udicciClasses.footerButtonIcon} />
                    Edit Bit
                </MenuItem>
                <MenuItem onClick={showBlockchainDialog}>
                    <Secured sx={udicciClasses.footerButtonIcon} />
                    Secure Bit
                </MenuItem>
                <Divider />
                <MenuItem onClick={(evt: any) => removeMediaBitRecord()}>
                    <RemoveIcon sx={udicciClasses.footerButtonIcon} />
                    Remove Bit
                </MenuItem>
            </Menu>
        );

        let editMediaBitIconElement: any = null;
        if (!minimized) {
            editMediaBitIconElement = (
                <Fragment>
                    <IconButton size="small"
                        color="primary"
                        edge="end"
                        aria-controls="media-bit-menu"
                        aria-haspopup="true"
                        onClick={handleBitMenuClick}
                        component="span"
                        sx={udicciClasses.headerButton}>
                        <MoreHorizIcon sx={udicciClasses.headerButtonIcon} />
                    </IconButton>
                    {mediaBitMenuElement}
                </Fragment>
            );
            // editMediaBitIconElement = (
            //     <IconButton size="small"
            //         color="primary"
            //         edge="end"
            //         component="span"
            //         onClick={ (evt: any) => { editMediaBit(mediaBit); } }
            //         sx={udicciClasses.headerButton}>
            //         <MoreHorizIcon sx={udicciClasses.headerButtonIcon} />
            //     </IconButton>
            // );
        }

        if (currentUser) {
            perspectivesElement = (
                <Box sx={{ marginTop: '8px' }}>
                    <Divider />
                    <Box sx={{ margin: '8px', marginTop: '16px' }}>
                        <Perspectives record={mediaBit} onShare={sharePerspective} perspectives={(mediaBit && mediaBit.perspectives ? mediaBit.perspectives : [])} />
                    </Box>
                </Box>
            );
        }

        let rval: any = null;
        if (minimized) {
            rval = (
                <Paper sx={udicciClasses.previewCardContent} elevation={0} onClick={((evt) => handleSelectMediaBit())}>
                    {editMediaBitIconElement}
                    {titleElement}
                    {narrativeElement}
                    {attachmentsElement}
                </Paper>
            );
        } else {
            rval = (
                <Card classes={{ root: 'card' }} raised={false}>
                    <CardHeader title={displayNameElement} 
                                avatar={creatorAvatarElement}
                                action={editMediaBitIconElement} 
                                classes={{
                                    root: 'cardHeader',
                                    avatar: 'creatorSocialIconRoot'
                                }}
                    />
                    <CardContent classes={{ root: 'cardContent' }}>
                        {titleElement}
                        {narrativeElement}
                        {attachmentsElement}
                        {perspectivesElement}
                    </CardContent>
                </Card>
            );
        }

        // let reKey = 'media.bit.' + bitId.toString();
        return (<Fragment>{rval}</Fragment>);
    }

    // function getSteps() {
    //     return ['Setup', 'Build', 'Review', 'Broadcast'];
    // }

    // function getStepContent(step: number) {
    //     switch (step) {
    //         case 0:
    //         return 'Select campaign settings...';
    //         case 1:
    //         return 'What is an ad group anyways?';
    //         case 2:
    //         return 'This is the bit I really care about!';
    //         default:
    //         return 'Unknown step';
    //     }
    // }
      
    function getBlockchainForm() {
        let ls = (mediaBit.ledgerSettings ? mediaBit.ledgerSettings : {});
        // console.log('%c getBlockchainForm ledgerSettings: %O', 'color: blue;', ls);

        // let titleElement: any = null;
        // let subtitleElement: any = null;
        // let titleFormElement: any = null;
        // let narrativeFormElement: any = null;
        // let fileUploadFormElement: any = null;
        // let imageThumbsElement: any = null;
        if (mediaBit) {
            // console.log('%c mediaBit: %O', 'color: green;', mediaBit);

            // titleElement = (
            //     <Box>
            //         <Typography variant="subtitle1">
            //             Media Bits.
            //         </Typography>
            //     </Box>
            // );
            // let fieldLabelTitle = 'Title';
            let controlPropsTitle: any = {};
            controlPropsTitle.fullWidth = true;
            // console.log('%c controlPropsTitle: %O', 'color: orange;', controlPropsTitle);

            let inputPropsTitle: any = {};
            inputPropsTitle.id = 'media.bit.title.0';
            inputPropsTitle.type = 'text';
            inputPropsTitle.autoFocus = true;
            inputPropsTitle.value = (mediaBit.data ? mediaBit.data.Title : '');
            inputPropsTitle.onChange = (evt: any) => { changeRecordValue(udicciContext, 'Title', evt); };
            // titleFormElement = (
            //     <FormControl {...controlPropsTitle}>
            //         <InputLabel htmlFor={inputPropsTitle.id}>{fieldLabelTitle}</InputLabel>
            //         <Input {...inputPropsTitle} />
            //     </FormControl>
            // );

            // let fieldLabelNarrative = 'Narrative';
            let controlPropsNarrative: any = {};
            controlPropsNarrative.fullWidth = true;
            // console.log('%c controlPropsNarrative: %O', 'color: orange;', controlPropsNarrative);

            let inputPropsNarrative: any = {};
            inputPropsNarrative.id = 'media.bit.narrative.0';
            inputPropsNarrative.type = 'text';
            inputPropsNarrative.value = (mediaBit.data ? mediaBit.data.Narrative : '');
            inputPropsNarrative.onChange = (evt: any) => { changeRecordValue(udicciContext, 'Narrative', evt); };
            inputPropsNarrative.multiline = true;
            inputPropsNarrative.rows = 2;
            inputPropsNarrative.rowsMax = 15;
            // narrativeFormElement = (
            //     <FormControl {...controlPropsNarrative}>
            //         <InputLabel htmlFor={inputPropsNarrative.id}>{fieldLabelNarrative}</InputLabel>
            //         <Input {...inputPropsNarrative} />
            //     </FormControl>
            // );
        }
        // console.log('%c titleElement: %O', 'color: orange;', titleElement);
        // console.log('%c narrativeFormElement: %O', 'color: orange;', narrativeFormElement);

        let isFungible: boolean = true;
        if (ls && ls.fungible === false) isFungible = false;

        let signators = (ls && ls.transactionSignators ? ls.transactionSignators : []);
        // console.log('%c getBlockchainForm signators: %O', 'color: blue;', signators);

        let smartContractType: string = (isFungible ? 'Mutual Value Token' : 'Non-Fungible Token');
        let smartContractTypeSwitch = (
            <Switch checked={isFungible}
                    onChange={changeBlockchainSetting}
                    name="fungible"
                    color="secondary" />
        );
        let smartContractTypeLabelElement = (
            <Typography variant="body2">
                {smartContractType}
            </Typography>
        );

        let smartContractTypeElement = (
            <Box sx={{ padding: '8px' }}>
                <FormControlLabel control={smartContractTypeSwitch} label={smartContractTypeLabelElement} />            
            </Box>
        );

        let relationshipElements: any[] = [];
        if (relationships && relationships.length > 0) {
            relationships = filter(relationships, function(r) { return r.data.ProviderProfileId === selectedProfile?.recordId; } );
            // console.log('%c relationships: %O', 'color: orange;', relationships);
        }

        let agreements = sortBy(relationships, function(a) { return a.data.AcceptingDisplayName; })
        forEach(agreements, function(da: any, i: number) {
            let dad = (da.data ? da.data : {} );
            let pt = (dad.AcceptingProfileType ? dad.AcceptingProfileType : '' );
            if (pt.toLowerCase() === '.me') {
                let dn = (dad.AcceptingDisplayName ? dad.AcceptingDisplayName : '' );
                let si = (dad.AcceptingSocialIcon ? dad.AcceptingSocialIcon : '' );
                if (!dn) dn = dad.Title;

                let socialIconAvatar: any = null;
                if (si) {
                    socialIconAvatar = (
                        <ListItemAvatar>
                            <Avatar src={si} sx={udicciClasses.acceptingSocialIcon} />
                        </ListItemAvatar>
                    );
                } else {
                    // man profile:     https://cdn.filestackcontent.com/4lqee0dTfSYbPCTO46aE
                    // Ulysses selfie:  DefaultSocialIcon
                    socialIconAvatar = (
                        <ListItemAvatar>
                            <Avatar src={DefaultSocialIcon}
                                    sx={udicciClasses.acceptingSocialIcon}
                                    component='span'
                                    variant="square"
                            />
                            {/* <Avatar src={'https://cdn.filestackcontent.com/4lqee0dTfSYbPCTO46aE'} sx={udicciClasses.acceptingSocialIcon} /> */}
                        </ListItemAvatar>
                    );
                }

                let adnElement: any = null;
                if (dn) {
                    adnElement = (
                        <Typography variant="body2" align="left">
                            {dn}
                        </Typography>
                    );
                }

                let reKey = 'relationship.' + da.recordId;
                relationshipElements.push(
                    <MenuItem key={reKey} value={da.data.AcceptingProfileId}>
                        {socialIconAvatar}
                        {adnElement}
                    </MenuItem>
                );
            }
        });

        let signatorsSelectionElement = (
            <Box sx={{ padding: '8px' }}>
                <FormControl fullWidth>
                    <InputLabel id="transaction-signators-label">Signators</InputLabel>
                    <Select
                        labelId="transaction-signators-label"
                        id="transaction-signators"
                        name="transactionSignators"
                        multiple
                        value={signators}
                        onChange={changeBlockchainSetting}
                        input={<Input id="transaction-signator-chips" />}
                        renderValue={(selected) => {
                            // console.log('%c selected: %O', 'color: blue;', selected);
                            return (
                                <Box sx={udicciClasses.chips}>
                                    {(selected as number[]).map((acceptingProfileId) => {
                                        // console.log('%c acceptingProfileId: %O', 'color: blue;', acceptingProfileId);
                                        let da = relationships.find((x: any) => x.data.AcceptingProfileId === acceptingProfileId);
                                        // console.log('%c da: %O', 'color: blue;', da);
                                        let adn = (da && da.data.AcceptingDisplayName ? da.data.AcceptingDisplayName : da.data.Title);

                                        let si = (da && da.data.AcceptingSocialIcon ? da.data.AcceptingSocialIcon : null);
                                        // console.log('%c ChainStoryBit userSocialIcon: %O', 'color: blue;', userSocialIcon);

                                        let signatorSocialIconElement: any = null;
                                        if (si) {
                                            signatorSocialIconElement = (
                                                <Avatar src={si} sx={udicciClasses.acceptingSocialIcon} />
                                            );
                                        } else {
                                            signatorSocialIconElement = (
                                                <ListItemAvatar>
                                                    <Avatar src={DefaultSocialIcon}
                                                            sx={udicciClasses.acceptingSocialIcon}
                                                            component='span'
                                                            variant="square"
                                                    />
                                                    {/* <Avatar src={'https://cdn.filestackcontent.com/4lqee0dTfSYbPCTO46aE'} sx={udicciClasses.acceptingSocialIcon} /> */}
                                                </ListItemAvatar>
                                            );
                                        }
                                
                                        return (
                                            <Chip key={'chip.' + acceptingProfileId}
                                                label={adn}
                                                avatar={signatorSocialIconElement}
                                                sx={udicciClasses.chip}
                                                color="primary"
                                            />
                                        )
                                    })}
                                </Box>
                            );
                        }}
                    >
                        {relationshipElements}
                    </Select>
                </FormControl>
            </Box>
        );

        let headerElement = (
            <Typography variant="subtitle1">
                Secure Data on Blockchain Ledger
            </Typography>
        );

        // const steps = getSteps();
        let bcTransProcessStepper: any = null;
        // bcTransProcessStepper = (
        //     <Stepper activeStep={activeStep} alternativeLabel>
        //         {steps.map((label) => (
        //             <Step key={label}>
        //                 <StepLabel>{label}</StepLabel>
        //             </Step>
        //         ))}
        //     </Stepper>
        // );
        let blockchainDialogElement = (
            <Dialog open={true} onClose={closeBlockchainDialog} maxWidth={'lg'}>
                <DialogTitle> {headerElement} </DialogTitle>
                <DialogContent>
                    {bcTransProcessStepper}
                    {smartContractTypeElement}
                    {signatorsSelectionElement}
                </DialogContent>
                <DialogActions>
                    <Box sx={udicciClasses.grow} />
                    <Button size="small" color="secondary" onClick={closeBlockchainDialog}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );

        return blockchainDialogElement;
    }
}
