import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/ConnectWithoutContact';

const EngagementForm = dynamic(() => import('src/components/engagement-form'));
const EngageFormAdmin = dynamic(() => import('src/admin/engage-form-admin'));

const engageFormPlugin: UdicciPlugin = {
    Renderer: (data: any) => ( <EngagementForm {...data} /> ),
    id: 'udicci.engage.form',
    title: 'Udicci Engage Form',
    description: 'Display the Udicci Engagement Form component.',
    version: 1,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <EngageFormAdmin {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c engageFormPlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c engageFormPlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
    },
    category: UdicciPluginCategory.Special
};
export default engageFormPlugin;