
import { find, values } from 'underscore';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// import { udicciStyles } from 'src/theme/shared-styles';

export const CreateMenu: React.FC<any> = ({ record, engagementAction, onClick }) => {
    // console.log('%c CreateMenu engagementAction: %O', 'color: purple;', engagementAction);
    // const theme = useTheme();
    // const udicciClasses = udicciStyles(theme);

    let udicciMediator: string = (record.udicciMediator ? record.udicciMediator : '');
    let permissions: any = (record.permissions ? record.permissions : null);

    let eaAccess = (engagementAction.access ? engagementAction.access : null);
    let eaMediators = (eaAccess && eaAccess.mediators ? eaAccess.mediators : []);

    const triggerEngagementAction = (ea: any) => {
        if (onClick) onClick(ea);
    };

    let mediatorActions = find(values(eaMediators), function(m: any) {
        return m.name === udicciMediator;
    });
    // console.log('%c mediatorActions: %O', 'color: blue;', mediatorActions);
    let requiredPermissions = (mediatorActions && mediatorActions.requiredPermissions ? mediatorActions.requiredPermissions : null);
    // console.log('%c requiredPermissions: %O', 'color: blue;', requiredPermissions);

    let allowed = (mediatorActions !== undefined ? true : false);
    if (requiredPermissions && requiredPermissions.length > 0) {
        allowed = false;
        let fp = requiredPermissions[0];
        let permValue = permissions[fp.permission];
        if (fp.required && permValue === true) allowed = true;
        if (!fp.required) allowed = true;
    }
    // console.log('%c allowed: %O', 'color: blue;', allowed);

    let rval: any = null;
    if (allowed) {
        // console.log('%c ea: %O', 'color: blue;', ea);
        let enagementMenuText = '';
        if (engagementAction && engagementAction.label && engagementAction.label.text) {
            enagementMenuText = engagementAction.label.text;
        } else if (engagementAction && engagementAction.name) {
            enagementMenuText = engagementAction.name;
        } else {
            enagementMenuText = engagementAction.action;
        }

        rval = (
            <Button size="small"
                    onClick={(evt: any) => triggerEngagementAction(engagementAction)}
                    variant="text"
            >
                <Typography variant="body2" component="span">
                    {enagementMenuText}
                </Typography>
                <Typography variant="body2" component="span">
                    .
                </Typography>
            </Button>
        );
    }

    return rval;
};
