import { deepOrange, green } from '@mui/material/colors';
import { Zoom, Fab, Avatar, useTheme, Icon } from '@mui/material';
import UDAIC_SOCIAL_ICON from 'src/images/udaic-social-icon.jpeg';
import { useFocusedContext } from './focused-context';

export const FloatingAIButton: React.FC<any> = (props) => {
    const focus = useFocusedContext();
    const theme = useTheme();

    let udaicSocialIconElement: any = null;
    // let bgColor: string = '';
    let normBorderColor: string = '';
    let hovrBorderColor: string = '';
    if (focus.aiAssistantOn) {
        // normBorderColor = green[700];
        // hovrBorderColor = deepOrange[700];
        // udaicSocialIconElement = (<Icon>center_focus_strong</Icon>);
        return null;
    } else {
        normBorderColor = deepOrange[700];
        hovrBorderColor = green[700];
        udaicSocialIconElement = (<Avatar alt={"Request an assist from AI."} src={UDAIC_SOCIAL_ICON} />);
    }
    const fabRequestAssistStyle: any = {
        // color: 'common.white',
        bgcolor: 'common.white',
        border: '1px solid ' + normBorderColor,
        '&:hover': {
            bgcolor: 'common.white',
            border: '1px solid ' + hovrBorderColor,
        },
        position: 'absolute',
        bottom: 16,
        right: 16,
    };

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    let fabElement: any = (
        <Zoom in={true} timeout={transitionDuration} unmountOnExit>
            <Fab sx={fabRequestAssistStyle} aria-label={'AI Assist'} color={'inherit'} onClick={focus.requestAIAssistance}>
                {udaicSocialIconElement}
            </Fab>
        </Zoom>
    );

    return fabElement;
};
