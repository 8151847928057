import { useState } from 'react';

import Pluralize from 'pluralize';

import {
    Box, Paper, Grid, List, ListItem, ListItemText, ListItemIcon, Typography, Checkbox, Select,
    Button, Accordion, AccordionSummary, AccordionDetails, Icon, Badge, MenuItem, FormControl
} from '@mui/material';

import useUdicciRecord from 'src/hooks/useUdicciRecord';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

import { getUdicciData } from 'src/context/udicci-context';
import { UdicciRecord } from 'src/classes/udicci-record';

import { find, filter, pluck, difference, unique, forEach, without } from 'underscore';

const RecordParentSelection: React.FC<any> = (props) => {
    // console.log('%c RecordParentSelection props: %O', 'color: purple;', props);

    let udicciHelpers: any = useUdicciHelpers();
    let { udicciRecord, changeRecordValue, socialSolution, saveRecord } = useUdicciRecord(props.record);
    // console.log('%c RecordParentSelection udicciRecord: %O', 'color: purple;', udicciRecord);
    // console.log('%c RecordParentSelection socialSolution: %O', 'color: purple;', socialSolution);

    let { parentData, selectedParentId, selectedParentMediator, lum } = props;
    // console.log('%c RecordParentSelection parentData: %O', 'color: purple;', parentData);
    // console.log('%c RecordParentSelection selectedParentId: %O', 'color: purple;', selectedParentId);
    // console.log('%c RecordParentSelection selectedParentMediator: %O', 'color: purple;', selectedParentMediator);
    // console.log('%c RecordParentSelection lum: %O', 'color: purple;', lum);

    const [expandedAccordion, setExpandedAccordion] = useState<boolean>(false);
    const [checkedRecords, setCheckedRecords] = useState<readonly number[]>([]);
    const [left, setLeft] = useState<readonly number[]>([]);
    const [right, setRight] = useState<readonly number[]>([]);

    let maxAssociationsToLeft: number = (lum && lum.MaxAssociationsToLeft ? lum.MaxAssociationsToLeft : 1);
    // console.log('%c maxAssociationsToLeft: %O', 'color: maroon;', maxAssociationsToLeft);
    let parentRecordId: number = (selectedParentId ? selectedParentId : 0);
    // console.log('%c parentRecordId: %O', 'color: maroon;', parentRecordId);
    let recordId: number = (udicciRecord.recordId ? udicciRecord.recordId : 0);
    // console.log('%c recordId: %O', 'color: maroon;', recordId);
    let recContext: any = (udicciRecord.context ? udicciRecord.context : null);
    // console.log('%c RecordParentSelection recContext: %O', 'color: purple;', recContext);
    let relationshipChanges: any = (recContext && recContext.RelationshipChanges ? recContext.RelationshipChanges : null);
    // console.log('%c RecordParentSelection relationshipChanges: %O', 'color: purple;', relationshipChanges);
    if (relationshipChanges && relationshipChanges.length > 0) {
        let relc: any = relationshipChanges.find((x: any) => {
            let rval: boolean = false;
            if (x.RelatedMediator === selectedParentMediator) {
                if (x.RecordId === udicciRecord.recordId && x.RecordMediator === udicciRecord.udicciMediator) {
                    rval = true;
                }
            }
            return rval;
        });
        // console.log('%c RecordParentSelection relc: %O', 'color: purple;', relc);
        if (relc) parentRecordId = relc.RelatedRecordId;
    }
    // console.log('%c parentRecordId: %O', 'color: maroon;', parentRecordId);

    let selectedParentMediatorSingular: string = (selectedParentMediator ? Pluralize.singular(selectedParentMediator) : selectedParentMediator);

    let lastResult: any = null;
    let parCtxData: any = null;

    let parentContext: any = udicciHelpers.getMediatorContext(selectedParentMediator);
    // console.log('%c RecordParentSelection parentContext: %O', 'color: purple;', parentContext);
    if (parentContext) lastResult = parentContext.lastResult;
    let parLinkedData: any = (parentContext && parentContext.linkedData ? parentContext.linkedData : null);

    if (!parentData || (parentData && parentData.length <= 0)) {
        if (parentContext && !parentContext.lastResult) {
            getUdicciData({ mediator: selectedParentMediator, socialSolutionId: socialSolution?.socialSolution?.recordId });
        }

        parCtxData = (parentContext && parentContext.records ? parentContext.records : null);
        // console.log('%c RecordParentSelection parCtxData: %O', 'color: purple;', parCtxData);
        if (parCtxData) parentData = parCtxData;
    }
    // console.log('%c RecordParentSelection parentData: %O', 'color: purple;', parentData);
    // console.log('%c RecordParentSelection parLinkedData: %O', 'color: purple;', parLinkedData);
    // console.log('%c RecordParentSelection lastResult: %O', 'color: purple;', lastResult);

    const handleToggle = (value: number) => () => {
        const currentIndex = checkedRecords.indexOf(value);
        const newChecked = [...checkedRecords];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        setCheckedRecords(newChecked);
    };

    const handleAllRight = () => {
        if (left && left.length > 0) {
            setRight(right.concat(left));
        } else {
            var allParentRecordIds: number[] = pluck(parCtxData, 'recordId');
            // console.log('%c handleAllLeft allParentRecordIds: %O', 'color: orange;', allParentRecordIds);
            setRight(unique(right.concat(allParentRecordIds)));
        }
        setLeft([]);
    };

    const handleCheckedRight = (newRight: number[], newLeft: number[], newChecked: number[]) => {
        setRight(newRight);
        setLeft(newLeft);
        setCheckedRecords(newChecked);
    };

    const handleCheckedLeft = (newLeft: number[], newRight: number[], newChecked: number[]) => {
        setLeft(newLeft);
        setRight(newRight);
        setCheckedRecords(newChecked);
    };

    const handleAllLeft = () => {
        // console.log('%c handleAllLeft parCtxData: %O', 'color: green;', parCtxData);
        if (right && right.length > 0) {
            setLeft(left.concat(right));
        } else {
            var allParentRecordIds: number[] = pluck(parCtxData, 'recordId');
            // console.log('%c handleAllLeft allParentRecordIds: %O', 'color: orange;', allParentRecordIds);
            setLeft(unique(left.concat(allParentRecordIds)));
        }
        setRight([]);
    };

    const handleChangeValue = (evt: any, otherData: any) => {
        // console.log('%c handleChangeValue evt: %O', 'color: maroon;', evt);
        // console.log('%c handleChangeValue otherData: %O', 'color: maroon;', otherData);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);

        let fld: any = {
            parent: {
                recordId: recordId,
                mediator: selectedParentMediator,
                currentValue: parentRecordId,
                value: newValue
            }
        };
        if (props.changeRecordValue) {
            props.changeRecordValue(fld, evt);
        } else {
            changeRecordValue(fld, evt);
        }
    };

    const requestRelationshipUpdate = (relatedMediatorRelationship: string, relatedMediatorName: string, relatedRecordId: number, changeAction: string | undefined = 'Add') => {
        if (!relatedMediatorRelationship) return false;
        if (!relatedMediatorName) return false;

        let rslt: boolean = false;
        if (udicciRecord.requestRelationshipUpdate) {
            rslt = udicciRecord.requestRelationshipUpdate(relatedMediatorRelationship, relatedMediatorName, relatedRecordId, changeAction);
        }
        return rslt;
    };

    const applyParentChanges = () => {
        // console.log('%c applyParentChanges udicciRecord: %O', 'color: maroon;', udicciRecord);
        // console.log('%c applyParentChanges selectedParentMediator: %O', 'color: maroon;', selectedParentMediator);
        // console.log('%c applyParentChanges parentRecordId: %O', 'color: maroon;', parentRecordId);
        // console.log('%c applyParentChanges left: %O', 'color: maroon;', left);
        // console.log('%c applyParentChanges right: %O', 'color: maroon;', right);

        // console.log('%c applyParentChanges left: %O', 'color: orange;', left);
        // console.log('%c applyParentChanges right: %O', 'color: orange;', right);
        var linkedChildRecordIds: number[] = pluck(parentData, 'recordId');
        // console.log('%c applyParentChanges linkedChildRecordIds: %O', 'color: orange;', linkedChildRecordIds);

        let missingIdsToRemove: number[] = unique(left);
        // console.log('%c RecordChildrenDisplay missingIdsToRemove: %O', 'color: orange;', missingIdsToRemove);
        let missingIdsToAdd: number[] = unique(right);
        // console.log('%c RecordChildrenDisplay missingIdsToAdd: %O', 'color: orange;', missingIdsToAdd);

        let removeIds: number[] = udicciHelpers.intersection(missingIdsToRemove, linkedChildRecordIds);
        // console.log('%c applyParentChanges removeIds: %O', 'color: orange;', removeIds);
        let addIds: number[] = without(missingIdsToAdd, ...linkedChildRecordIds);
        // console.log('%c applyParentChanges addIds: %O', 'color: orange;', addIds);

        let unsuccessful: number = 0;
        if (addIds.length > 0) {
            forEach(addIds, (id: number, idx: number) => {
                let rslt: boolean = requestRelationshipUpdate('parent', selectedParentMediator, id);
                if (!rslt) unsuccessful++;
            });
        }

        if (removeIds.length > 0) {
            forEach(removeIds, (id: number, idx: number) => {
                let rslt: boolean = requestRelationshipUpdate('parent', selectedParentMediator, id, 'Remove');
                if (!rslt) unsuccessful++;
            });
        }

        // console.log('%c applyParentChanges unsuccessful: %O', 'color: red; font-weight: bold;', unsuccessful);
        // console.log('%c applyParentChanges udicciRecord: %O', 'color: red; font-weight: bold;', udicciRecord);
        if (unsuccessful <= 0 && udicciRecord.isDirty) {
            if (props.updateFormRecord) {
                props.updateFormRecord(udicciRecord);
            // } else {
            //     saveRecord({ onSaveCompleted: onSaveCompleted });
            }
        }
    };

    // const onSaveCompleted = (response: any) => {
    //     // console.log('%c onSaveCompleted response: %O', 'color: blue;', response);
    //     // refreshChildData(true);
    // };

    const handleChangeAccordion = () => {
        let expandedAccordionUpdated: boolean = !expandedAccordion;
        if (expandedAccordionUpdated) {
            // console.log('%c RecordParentSelection handleChangeAccordion selectedChildMediator: %O', 'color: red;', selectedChildMediator);
            // console.log('%c RecordParentSelection handleChangeAccordion udicciRecord.udicciMediator: %O', 'color: red;', udicciRecord.udicciMediator);
            // console.log('%c RecordParentSelection handleChangeAccordion udicciRecord: %O', 'color: red;', udicciRecord);
            // console.log('%c RecordParentSelection handleChangeAccordion lum: %O', 'color: red;', lum);
            // console.log('%c RecordParentSelection handleChangeAccordion childData: %O', 'color: red;', childData);
            // console.log('%c RecordParentSelection handleChangeAccordion childContext: %O', 'color: red;', childContext);
            // console.log('%c RecordParentSelection handleChangeAccordion childLinkedData: %O', 'color: red;', childLinkedData);
            // let lumData: any = childLinkedData.find((x: any) => {
            //     let rval: boolean = false;
            //     if (x.LeftUdicciMediatorName === udicciRecord.udicciMediator && x.RightUdicciMediatorName === selectedChildMediator) {
            //         rval = true;
            //     }
            //     return rval;
            // });
            // console.log('%c RecordParentSelection handleChangeAccordion lumData: %O', 'color: purple;', lumData);
            // check for childData that is related to the parent record
            // if (!childCtxData || childCtxData.length <= 0) refreshChildData();
        }
        setExpandedAccordion(expandedAccordionUpdated);
    };

    let assignedParentRecordIds: number[] = [];
    if (parLinkedData) {
        let lum: any = find(parLinkedData, function(pld: any) {
            let parentCheck: boolean = (pld.LeftUdicciMediatorName === selectedParentMediator ? true : false);
            let childCheck: boolean = (pld.RightUdicciMediatorName === udicciRecord.udicciMediator ? true : false);
            return (parentCheck && childCheck);
        });
        // console.log('%c RecordParentSelection lum: %O', 'color: red;', lum);

        let lumData: any = (lum && lum.Data ? lum.Data : null);
        // console.log('%c RecordParentSelection lumData: %O', 'color: red;', lumData);
        if (lumData && lumData.length > 0) {
            let filteredLumData: any[] = filter(lumData, function(ld: any) {
                return ld.RightId === udicciRecord.recordId;
            });
            // console.log('%c RecordParentSelection filteredLumData: %O', 'color: red;', filteredLumData);
            if (filteredLumData.length > 0) {
                parentRecordId = filteredLumData[0].LeftId;
                assignedParentRecordIds = pluck(filteredLumData, 'LeftId');
            }
        }
    }
    // console.log('%c getParentElements parentRecordId: %O', 'color: purple;', parentRecordId);
    // console.log('%c assignedParentRecordIds: %O', 'color: purple;', assignedParentRecordIds);

    let sortedParentData: any[] | null = null;
    if (parentData) {
        sortedParentData = parentData.sort((a: any,b: any) => {
            if (a.record && b.record) {
                if (a.record.title < b.record.title) return -1;  // ascending
                if (a.record.title > b.record.title) return 1;  // descending
            } else if (a.recordId !== undefined && b.recordId !== undefined) {
                if (a.title < b.title) return -1;  // ascending
                if (a.title > b.title) return 1;  // descending
            }
            return 0 //default return value (no sorting)
        });
    }
    // console.log('%c sortedParentData: %O', 'color: purple;', sortedParentData);

    let parentSelectionLabel: any = null;
    let parentSelections: any[] = [];
    if (!lastResult) {
        if (parentRecordId > 0) parentRecordId = 0;
        parentSelections.push(<MenuItem key={'parent.rec.selections.loading'} value={0}> loading {selectedParentMediator} ... </MenuItem>);
    } else {
        parentSelectionLabel = (
            <Typography component="div" variant="clickable">Select {selectedParentMediatorSingular}</Typography>
        );
        parentSelections.push(<MenuItem key={'parent.rec.not.selected'} value={0}> Select {selectedParentMediatorSingular} </MenuItem>);
    }
    if (sortedParentData) {
        for (let [idx, pd] of Object.entries<any>(sortedParentData)) {
            // console.log('%c pd: %O', 'color: hotpink;', pd);
            let rec: any = (pd.record ? pd.record : pd);
            parentSelections.push(<MenuItem key={'parent.rec.' + rec.recordId + '.' + idx} value={rec.recordId}> {rec.title} </MenuItem>);
        }
    }

    let selectParentRecordElement: any = null;
    if (maxAssociationsToLeft === -1) {
        // console.log('%c assignedParentRecordIds: %O', 'color: orange;', assignedParentRecordIds);
        var allParentRecordIds: number[] = pluck(parCtxData, 'recordId');
        // console.log('%c allParentRecordIds: %O', 'color: orange;', allParentRecordIds);
        let unassignedRecordIds: number[] = unique(difference(allParentRecordIds, assignedParentRecordIds));
        // console.log('%c unassignedRecordIds: %O', 'color: orange;', unassignedRecordIds);

        let allParentDataSorted: any[] = [];
        if (parCtxData && parCtxData.length > 0) {
            allParentDataSorted = parCtxData.sort((a: any,b: any) => {
                if (a.record && b.record) {
                    if (a.record.title < b.record.title) return -1;  // ascending
                    if (a.record.title > b.record.title) return 1;  // descending
                } else if (a.recordId !== undefined && b.recordId !== undefined) {
                    if (a.title < b.title) return -1;  // ascending
                    if (a.title > b.title) return 1;  // descending
                }
                return 0 //default return value (no sorting)
            });
        }
        // console.log('%c allParentDataSorted: %O', 'color: orange;', allParentDataSorted);

        let recordsNotLinkedElements: any[] = [];
        let recordsLinkedElements: any[] = [];
        let leftIds: number[] = [];
        let rightIds: number[] = [];
        forEach(allParentDataSorted, (parentRecord: UdicciRecord, idxParentRecord: number) => {
            // console.log('%c parentRecord: %O', 'color: green;', parentRecord);

            let isLinked: boolean = false;
            // let isLinkedSameAsCurrent: boolean = false;
            if (right && right.length > 0) {
                isLinked = (right.indexOf(parentRecord.recordId) >= 0 ? true : isLinked);
            } else {
                isLinked = (assignedParentRecordIds.indexOf(parentRecord.recordId) >= 0 ? true : isLinked);
            }
            // console.log('%c isLinked: %O', 'color: green;', isLinked);

            let notLinked: boolean = false;
            if (left && left.length > 0) {
                notLinked = (left.indexOf(parentRecord.recordId) >= 0 ? true : notLinked);
                if (isLinked && notLinked) isLinked = false;
            } else {
                notLinked = (unassignedRecordIds.indexOf(parentRecord.recordId) >= 0 ? true : notLinked);
            }
            // console.log('%c notLinked: %O', 'color: green;', notLinked);

            let itemKey: string = 'parent.record.list.item.' + parentRecord.recordId.toString();
            if (isLinked) {
                itemKey += '.linked';
            } else {
                itemKey += '.unassigned';
            }
            let listElement: any = (
                <ListItem
                    key={parentRecord.recordId}
                    role="listitem"
                    button
                    onClick={handleToggle(parentRecord.recordId)}
                >
                    <ListItemIcon>
                        <Checkbox
                            checked={checkedRecords.indexOf(parentRecord.recordId) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': 'transfer.' + itemKey }}
                        />
                    </ListItemIcon>
                    <ListItemText id={'transfer.' + itemKey} primary={parentRecord.title} />
                </ListItem>
            );
            if (isLinked) {
                recordsLinkedElements.push(listElement);
                rightIds.push(parentRecord.recordId);
            } else {
                recordsNotLinkedElements.push(listElement);
                leftIds.push(parentRecord.recordId);
            }
        });

        let unassignedListElement: any = (
            <Paper sx={{ height: 350, overflow: 'auto' }}>
                <List dense component="div" role="list">
                    {recordsNotLinkedElements}
                </List>
            </Paper>
        );

        let linkedListElement: any = (
            <Paper sx={{ height: 350, overflow: 'auto' }}>
                <List dense component="div" role="list">
                    {recordsLinkedElements}
                </List>
            </Paper>
        );

        const _leftChecked = udicciHelpers.intersection(checkedRecords, leftIds);
        const _rightChecked = udicciHelpers.intersection(checkedRecords, rightIds);

        let badgeProps: any = {
            showZero: true,
            color: "secondary",
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            sx: {
                paddingRight: '16px', // move the count further out to the right to avoid overlap
                marginBottom: '-24px', // move the count so that it overlaps the list of records ---- cool for now
            }
        };
        selectParentRecordElement = (
            <Box sx={{ display: 'flow-root' }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={4}>
                        <Badge badgeContent={leftIds.length} {...badgeProps}>
                            <Typography component="div" variant="caption">Select an existing {selectedParentMediatorSingular}.</Typography>
                        </Badge>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={4}>
                    <Badge badgeContent={rightIds.length} {...badgeProps}>
                            <Typography component="div" variant="caption">Linked {selectedParentMediator}.</Typography>
                        </Badge>
                    </Grid>
                    <Grid item xs={4}>{unassignedListElement}</Grid>
                    <Grid item xs={1}>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllRight}
                                disabled={leftIds.length === 0}
                                aria-label="move all right"
                            >
                                ≫
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={(evt: any) => handleCheckedRight(rightIds.concat(_leftChecked), udicciHelpers.not(leftIds, _leftChecked), udicciHelpers.not(checkedRecords, _leftChecked))}
                                disabled={_leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={(evt: any) => handleCheckedLeft(leftIds.concat(_rightChecked), udicciHelpers.not(rightIds, _rightChecked), udicciHelpers.not(checkedRecords, _rightChecked))}
                                disabled={_rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllLeft}
                                disabled={rightIds.length === 0}
                                aria-label="move all left"
                            >
                                ≪
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>{linkedListElement}</Grid>
                    <Grid item xs={9} alignContent="right">
                        <Box sx={{ display: 'flow-root', marginRight: '8px', textAlign: 'right' }}>
                            <Button variant="outlined" size="small" aria-label="apply changes" onClick={applyParentChanges}>
                                Apply Changes
                            </Button>
                            {/* <Button variant="outlined" size="small" aria-label="reset lists" onClick={resetChildrenLists}>
                                Reset Lists
                            </Button> */}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    } else {
        selectParentRecordElement = (
            <FormControl fullWidth>
                {parentSelectionLabel}
                <Select value={parentRecordId} onChange={handleChangeValue} disabled={(!parLinkedData ? true : false)}
                        inputProps={{ name: 'parent-record-' + recordId, id: 'select-parent-record-' + recordId, }}>
                    {parentSelections}
                </Select>
            </FormControl>
        );
    }

    let accordionSummarySettings: any = {
        expandIcon: (<Icon color="secondary">expand_more</Icon>),
        sx: { padding: '4px', minHeight: '16px' },
    };
    let accordionDetailsSettings: any = {
        sx: { padding: '4px', minHeight: '16px' },
    };

    let parentRecordSelectorElement: any = (
        <Accordion expanded={expandedAccordion} onChange={() => handleChangeAccordion()}>
            <AccordionSummary {...accordionSummarySettings}>
                <Box sx={{ display: 'flex', marginLeft: '16px' }}>
                    <Typography component="span" variant="clickable" sx={{ marginLeft: '8px' }}>Related {selectedParentMediator}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails {...accordionDetailsSettings}>
                {selectParentRecordElement}
            </AccordionDetails>
        </Accordion>
    );

    return (
        <Box>
            {parentRecordSelectorElement}
        </Box>
    );
}

export { RecordParentSelection };
