
import React, { useState } from 'react';

import {
    Box, Paper, Divider, Typography, TextField, FormControlLabel, Checkbox,
    FormControl, InputLabel, Select, MenuItem, Button
} from '@mui/material';

import { useUdicciContext } from 'src/context/udicci-context'
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

const MediatorFieldForm: React.FC<any> = (props) => {
    // console.log('%c MediatorFieldForm props: %O', 'color: red;', props);

    let { field, structure, onClose, onChange } = props;

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();

    let { udicci } = udicciContext.state;

    let fieldId: number = (field.UdicciMediatorFieldId ? field.UdicciMediatorFieldId : 0);
    let fieldIsLocked: boolean = (fieldId > 0 ? true : false);

    const [uiState, flashUI] = useState<boolean>(false);

    const onFieldValueChanged = (fieldName: string, evt: any) => {
        // console.log('%c onFieldValueChanged mediator: %O', 'color: maroon;', mediator);
        // console.log('%c onFieldValueChanged fieldName: %O', 'color: maroon;', fieldName);
        let trgt: any = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c onFieldValueChanged newValue: %O', 'color: maroon;', newValue);

        field[fieldName] = newValue;

        field.isDirty = true;
        if (onChange) onChange(field);
        flashUI(!uiState);
    };

    let fieldVariableTypes: any[] | null = udicciHelpers.preloadMediatorData('Field Variable Types', udicci.socialSolutionUdicciIT, false, udicci.ulyssesDConstantineProfileId);
    // console.log('%c MediatorFieldForm fieldVariableTypes: %O', 'color: maroon;', fieldVariableTypes);

    let nameElement: any = (
        <Box>
            <Typography variant="overFieldLabel">Field Name</Typography>
            <TextField fullWidth
                       value={field.Name}
                       onChange={(evt: any) => onFieldValueChanged('Name', evt)}
                       margin="none"
                       variant='outlined'
            />
        </Box>
    );

    let displayNameElement: any = (
        <Box>
            <Typography variant="overFieldLabel">Field Label</Typography>
            <TextField fullWidth
                    value={field.DisplayName}
                    onChange={(evt: any) => onFieldValueChanged('DisplayName', evt)}
                    margin="none"
                    variant='outlined'
            />
        </Box>
    );

    let overviewElement: any = (
        <Box>
            <Typography variant="overFieldLabel">Field Overview</Typography>
            <TextField fullWidth multiline maxRows={7} minRows={3}
                    value={field.Overview}
                    onChange={(evt: any) => onFieldValueChanged('Overview', evt)}
                    margin="none"
                    variant='outlined'
            />
        </Box>
    );

    let isRequiredElement: any = (
        <Box>
            <FormControlLabel
                label={(<Typography variant="overFieldLabel">Required</Typography>)}
                control={
                    <Checkbox checked={field.IsRequired}
                            disabled={fieldIsLocked}
                            onChange={(evt: any) => onFieldValueChanged('IsRequired', evt)}
                            color="primary"
                    />
                }
            />
        </Box>
    );

    let isNullableElement: any = (
        <Box>
            <FormControlLabel
                label={(<Typography variant="overFieldLabel">Nullable</Typography>)}
                control={
                    <Checkbox checked={field.IsNullable}
                            disabled={fieldIsLocked}
                            onChange={(evt: any) => onFieldValueChanged('IsNullable', evt)}
                            color="primary"
                    />
                }
            />
        </Box>
    );

    let isSearchableElement: any = (
        <Box>
            <FormControlLabel
                label={(<Typography variant="overFieldLabel">Searchable</Typography>)}
                control={
                    <Checkbox checked={field.IsSearchable}
                            onChange={(evt: any) => onFieldValueChanged('IsSearchable', evt)}
                            color="primary"
                    />
                }
            />
        </Box>
    );

    let displayInListElement: any = (
        <Box>
            <FormControlLabel
                label={(<Typography variant="overFieldLabel">Display In List</Typography>)}
                control={
                    <Checkbox checked={field.DisplayInList}
                            onChange={(evt: any) => onFieldValueChanged('DisplayInList', evt)}
                            color="primary"
                    />
                }
            />
        </Box>
    );

    let selectFieldTypes: any[] = [];
    if (fieldVariableTypes && fieldVariableTypes.length > 0) {
        fieldVariableTypes = fieldVariableTypes.sort((a,b) => (a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : ((b.title.toLowerCase() > a.title.toLowerCase()) ? -1 : 0));
        Object.entries(fieldVariableTypes).forEach(([idxDataType, dataType]) => {
            // console.log('dataType: %O', dataType);
            selectFieldTypes.push(
                <MenuItem key={'select.data.type.' + dataType.recordId} value={dataType.title}>
                    {dataType.title}
                </MenuItem>
            );
        });
    }
    // console.log('selectFieldTypes: %O', selectFieldTypes);

    let dataTypeSelectionElement: any = (
        <Box>
            <Typography variant="overFieldLabel" gutterBottom>Select Data Type</Typography>
            <Select value={field.DataType} fullWidth disabled={fieldIsLocked} onChange={(evt: any) => onFieldValueChanged('DataType', evt)}>
                <MenuItem disabled value="">
                    <em>Select Data Type</em>
                </MenuItem>
                {selectFieldTypes}
            </Select>
        </Box>
    );


    // let numberInputProps: any = { inputComponent: CustomNumberInput };
    let maximumLengthElement: any = (
        <Box>
            <Typography variant="overFieldLabel">Maximum Length</Typography>
            <TextField fullWidth
                        type={"number"}
                        value={field.MaximumLength}
                        disabled={fieldIsLocked}
                        InputLabelProps={{ shrink: true }}
                        onChange={(evt: any) => onFieldValueChanged('MaximumLength', evt)}
                        margin="none"
                        variant='outlined'
            />
        </Box>
    );

    let minimumLengthElement: any = (
        <Box>
            <Typography variant="overFieldLabel">Minimum Length</Typography>
            <TextField fullWidth
                        type={"number"}
                        value={field.MinimumLength}
                        disabled={fieldIsLocked}
                        InputLabelProps={{ shrink: true }}
                        onChange={(evt: any) => onFieldValueChanged('MinimumLength', evt)}
                        margin="none"
                        variant='outlined'
            />
        </Box>
    );

    let uniqueQualitySelector: any = (
        <Box>
            <Typography variant="overFieldLabel" gutterBottom>Uniqueness Rule</Typography>
            <Select fullWidth
                    value={field.UniqueQuality}
                    onChange={(evt: any) => onFieldValueChanged('UniqueQuality', evt)}
            >
                <MenuItem value={0}>Not Unique</MenuItem>
                <MenuItem value={1}>Platform Unique</MenuItem>
                <MenuItem value={2}>Unique by Profile</MenuItem>
                <MenuItem value={3}>Unique by Solution</MenuItem>
                <MenuItem value={4}>Unique by User</MenuItem>
            </Select>
        </Box>
    );

    let isDirty: boolean = (field && field.isDirty ? true : false);
    let formButtonsElement: any = (
        <Box sx={{ margin: '8px' }}>
            <Button onClick={(evt: any) => onClose()} variant="contained" color="info" sx={{ margin: '4px' }}>
                Cancel
            </Button>
            <Button onClick={(evt: any) => onClose()} variant="contained" color="success" disabled={!isDirty} sx={{ margin: '4px' }}>
                Save Changes
            </Button>
        </Box>
    );

    return (
        <Box>
            <Typography variant="subtitle1" color='primary' gutterBottom>
                Udicci Mediator Field
            </Typography>
            <Box>
                {nameElement}
                {displayNameElement}
                {overviewElement}
                <Box sx={{ display: 'flex' }}>
                    {isRequiredElement}
                    {isNullableElement}
                    {isSearchableElement}
                    {displayInListElement}
                </Box>
                {dataTypeSelectionElement}
                {maximumLengthElement}
                {minimumLengthElement}
                {uniqueQualitySelector}
            </Box>
            {formButtonsElement}
        </Box>
    );
}

export default MediatorFieldForm;
