
import { useEffect, useCallback, useState } from "react"

import { getProfileSetting, setProfile } from 'src/context/udicci-context';
import { UdicciRecord, UdicciPermissions } from 'src/classes/udicci-record';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

export default function useUdicciProfile( profile: UdicciRecord | null | undefined ) {
    // console.log('%c useUdicciProfile profile: %O', 'color: red;', profile);

    const udicciHelpers = useUdicciHelpers();

    const [ udicciProfile, setUdicciProfile ] = useState<UdicciRecord | null | undefined>(profile);
    // console.log('%c useUdicciProfile udicciProfile: %O', 'color: red;', udicciProfile);

    useEffect(() => {
        var cur: UdicciRecord | null = (udicciProfile ? udicciProfile : null);
        if (profile && (!cur || (cur && cur.recordId === profile.recordId))) {
            setUdicciProfile(profile);
        }
    }, [profile,udicciProfile]);

    const set = useCallback((profile: UdicciRecord) => {
        var cur: UdicciRecord | null = (udicciProfile ? udicciProfile : null);
        if (profile && (!cur || (cur && cur.recordId === profile.recordId))) {
            setUdicciProfile(profile);
        }
    }, [udicciProfile]);

    const getSetting = (mediatorName: string, socialSolutionId: number, settingName: string = 'all') => {
        // console.log('%c getSetting udicciProfile: %O', 'color: brown;', udicciProfile);
        return getProfileSetting(mediatorName, socialSolutionId, settingName);
    }

    const getSocialSolutionSettings = (socialSolutionId: number) => {
        // console.log('%c getSocialSolutionSettings socialSolutionId: %O', 'color: brown;', socialSolutionId);
        // console.log('%c getSocialSolutionSettings udicciProfile: %O', 'color: brown;', udicciProfile);
        if (!udicciProfile) return false;

        var profileData = (udicciProfile && udicciProfile.data ? udicciProfile.data : null);
        var profileSettings: any = (profileData && profileData.jsonProfileSettingsJson ? profileData.jsonProfileSettingsJson : null);
        var sss: any = (profileSettings && profileSettings.socialSolutionSettings ? profileSettings.socialSolutionSettings : null);
        var pssSettings: any = (sss && sss[socialSolutionId] ? sss[socialSolutionId] : null);
        return pssSettings;
    }

    const setSocialSolutionSettings = (socialSolutionId: number, settings: any) => {
        // console.log('%c setSocialSolutionSettings socialSolutionId: %O', 'color: brown;', socialSolutionId);
        // console.log('%c setSocialSolutionSettings settings: %O', 'color: brown;', settings);
        // console.log('%c setSocialSolutionSettings udicciProfile: %O', 'color: brown;', udicciProfile);
        if (!udicciProfile) return false;

        var profileData = (udicciProfile && udicciProfile.data ? udicciProfile.data : null);
        // console.log('%c setSocialSolutionSettings profileData: %O', 'color: maroon;', profileData);
        var profileSettings: any = (profileData && profileData.jsonProfileSettingsJson ? profileData.jsonProfileSettingsJson : null);
        // console.log('%c setSocialSolutionSettings profileSettings: %O', 'color: maroon;', profileSettings);
        var sss: any = (profileSettings && profileSettings.socialSolutionSettings ? profileSettings.socialSolutionSettings : null);
        // console.log('%c setSocialSolutionSettings socialSolutionSettings: %O', 'color: maroon;', sss);
        var pssSettings: any = (sss && sss[socialSolutionId] ? sss[socialSolutionId] : {});
        Object.assign(pssSettings, settings);
        // console.log('%c setSocialSolutionSettings pssSettings (after assign): %O', 'color: blue;', pssSettings);

        if (!sss) sss = {};
        sss[socialSolutionId] = pssSettings;
        if (!profileSettings) profileSettings = {};
        profileSettings.socialSolutionSettings = sss;
        profileData.jsonProfileSettingsJson = profileSettings;
        // console.log('%c setSocialSolutionSettings profileSettings: %O', 'color: maroon;', profileSettings);
        if (profileSettings) {
            profileData.ProfileSettingsJson = JSON.stringify(profileSettings);
        } else {
            profileData.ProfileSettingsJson = "";
        }
        udicciProfile.data = profileData;
        udicciProfile.isDirty = true;
        // console.log('%c setSocialSolutionSettings udicciProfile: %O', 'color: maroon;', udicciProfile);
        setProfile(udicciProfile);
    }

    const changePermission = (settings: any) => {
        // console.log('%c useUdicciProfile changePermission settings: %O', 'color: red;', settings);

        var removePermType: boolean = (settings && settings.remove === true ? true : false);
        // console.log('%c useUdicciProfile changePermission removePermType: %O', 'color: red;', removePermType);
        var permType: string = (settings && settings.permissionType ? settings.permissionType : 'default');
        // console.log('%c useUdicciProfile changePermission permType: %O', 'color: red;', permType);
        var perm: string = (settings && settings.permission ? settings.permission : 'view');
        // console.log('%c useUdicciProfile changePermission perm: %O', 'color: red;', perm);
        var socialSolutionId: number = (settings && settings.socialSolutionId ? settings.socialSolutionId : 0);
        // console.log('%c useUdicciProfile changePermission socialSolutionId: %O', 'color: red;', socialSolutionId);
        // var feature: any = (settings && settings.feature ? settings.feature : null);
        var mediator: any = (settings && settings.mediator ? settings.mediator : null);
        // console.log('%c useUdicciProfile changePermission mediator: %O', 'color: red;', mediator);
        var mediatorName: string = (mediator && mediator.name ? mediator.name : '');
        // console.log('%c useUdicciProfile changePermission mediatorName: %O', 'color: red;', mediatorName);
        var role: any = (settings && settings.role ? settings.role : null);

        if (!socialSolutionId) return false;
        if (!permType) return false;
        if (permType && !perm) return false;

        var pssSettings: any = getSocialSolutionSettings(socialSolutionId);
        if (!pssSettings) pssSettings = {};

        var roleId: number = (role && role.recordId ? role.recordId : 0);
        var roleOverrides: any = (pssSettings.RoleOverrides ? pssSettings.RoleOverrides : {});
        var roleOverride: any = (roleId && roleOverrides[roleId] ? roleOverrides[roleId] : null);

        if (removePermType === true) {
            if (mediator) {
                // console.log('%c useUdicciProfile changePermission mediator: %O', 'color: red;', mediator);
                // var medPublicOvrds: any = (newPublicPerms.overrides ? newPublicPerms.overrides : null);
                // var medPublicOvrdsPerms: UdicciPermissions | null = (medPublicOvrds && medPublicOvrds[mediatorName] ? medPublicOvrds[mediatorName] : null);
                // var newMediatorPerms: UdicciPermissions = udicciHelpers.initializePermissions();
                // if (medPublicOvrdsPerms) {
                //     newMediatorPerms.CanView = (medPublicOvrdsPerms.CanView !== undefined ? medPublicOvrdsPerms.CanView : false);
                //     newMediatorPerms.CanAdd = (medPublicOvrdsPerms.CanAdd !== undefined ? medPublicOvrdsPerms.CanAdd : false);
                //     newMediatorPerms.CanEdit = (medPublicOvrdsPerms.CanEdit !== undefined ? medPublicOvrdsPerms.CanEdit : false);
                //     newMediatorPerms.CanEditIfOwner = (medPublicOvrdsPerms.CanEditIfOwner !== undefined ? medPublicOvrdsPerms.CanEditIfOwner : false);
                //     newMediatorPerms.CanDelete = (medPublicOvrdsPerms.CanDelete !== undefined ? medPublicOvrdsPerms.CanDelete : false);
                //     newMediatorPerms.CanDeleteIfOwner = (medPublicOvrdsPerms.CanDeleteIfOwner !== undefined ? medPublicOvrdsPerms.CanDeleteIfOwner : false);
                // }
                // if (perm === 'view') newMediatorPerms.CanView = !newMediatorPerms.CanView;
                // if (perm === 'add') newMediatorPerms.CanAdd = !newMediatorPerms.CanAdd;
                // if (perm === 'edit') newMediatorPerms.CanEdit = !newMediatorPerms.CanEdit;
                // if (perm === 'delete') newMediatorPerms.CanDelete = !newMediatorPerms.CanDelete;
                // if (!medPublicOvrds) medPublicOvrds = {};
                // medPublicOvrds[mediatorName] = newMediatorPerms;
                // newPublicPerms.overrides = medPublicOvrds;
            } else {
                if (permType === 'public') pssSettings.public = null;
                if (permType === 'default') pssSettings.default = null;
                if (permType === 'role') {
                    if (roleId) {
                        if (roleOverride) delete roleOverrides[roleId];
                        pssSettings.RoleOverrides = roleOverrides;
                    }
                }
            }
        } else {
            if (permType === 'public') {
                var publicPermissions: UdicciPermissions | null = (pssSettings.public ? pssSettings.public : null);
                var newPublicPerms: UdicciPermissions = udicciHelpers.initializePermissions();
                if (publicPermissions) {
                    newPublicPerms.CanView = (publicPermissions.CanView !== undefined ? publicPermissions.CanView : false);
                    newPublicPerms.CanAdd = (publicPermissions.CanAdd !== undefined ? publicPermissions.CanAdd : false);
                    newPublicPerms.CanEdit = (publicPermissions.CanEdit !== undefined ? publicPermissions.CanEdit : false);
                    newPublicPerms.CanEditIfOwner = (publicPermissions.CanEditIfOwner !== undefined ? publicPermissions.CanEditIfOwner : false);
                    newPublicPerms.CanDelete = (publicPermissions.CanDelete !== undefined ? publicPermissions.CanDelete : false);
                    newPublicPerms.CanDeleteIfOwner = (publicPermissions.CanDeleteIfOwner !== undefined ? publicPermissions.CanDeleteIfOwner : false);
                    newPublicPerms.overrides = (publicPermissions.overrides !== undefined ? publicPermissions.overrides : {});
                }

                if (mediator) {
                    var publicOvrds: any = (newPublicPerms.overrides ? newPublicPerms.overrides : null);
                    var publicOvrdsPerms: UdicciPermissions | null = (publicOvrds && publicOvrds[mediatorName] ? publicOvrds[mediatorName] : null);
                    var newPublicMediatorPerms: UdicciPermissions = udicciHelpers.initializePermissions();
                    if (publicOvrdsPerms) {
                        newPublicMediatorPerms.CanView = (publicOvrdsPerms.CanView !== undefined ? publicOvrdsPerms.CanView : false);
                        newPublicMediatorPerms.CanAdd = (publicOvrdsPerms.CanAdd !== undefined ? publicOvrdsPerms.CanAdd : false);
                        newPublicMediatorPerms.CanEdit = (publicOvrdsPerms.CanEdit !== undefined ? publicOvrdsPerms.CanEdit : false);
                        newPublicMediatorPerms.CanEditIfOwner = (publicOvrdsPerms.CanEditIfOwner !== undefined ? publicOvrdsPerms.CanEditIfOwner : false);
                        newPublicMediatorPerms.CanDelete = (publicOvrdsPerms.CanDelete !== undefined ? publicOvrdsPerms.CanDelete : false);
                        newPublicMediatorPerms.CanDeleteIfOwner = (publicOvrdsPerms.CanDeleteIfOwner !== undefined ? publicOvrdsPerms.CanDeleteIfOwner : false);
                    }
                    if (perm === 'view') newPublicMediatorPerms.CanView = !newPublicMediatorPerms.CanView;
                    if (perm === 'add') newPublicMediatorPerms.CanAdd = !newPublicMediatorPerms.CanAdd;
                    if (perm === 'edit') newPublicMediatorPerms.CanEdit = !newPublicMediatorPerms.CanEdit;
                    if (perm === 'delete') newPublicMediatorPerms.CanDelete = !newPublicMediatorPerms.CanDelete;
                    if (!publicOvrds) publicOvrds = {};
                    publicOvrds[mediatorName] = newPublicMediatorPerms;
                    newPublicPerms.overrides = publicOvrds;
                } else {
                    if (perm === 'view') newPublicPerms.CanView = !newPublicPerms.CanView;
                    if (perm === 'add') newPublicPerms.CanAdd = !newPublicPerms.CanAdd;
                    if (perm === 'edit') newPublicPerms.CanEdit = !newPublicPerms.CanEdit;
                    if (perm === 'delete') newPublicPerms.CanDelete = !newPublicPerms.CanDelete;
                }

                pssSettings.public = newPublicPerms;
            }
    
            if (permType === 'default') {
                var defaultPermissions: UdicciPermissions = (pssSettings.default ? pssSettings.default : udicciHelpers.initializePermissions());
                var newDefaultPerms: UdicciPermissions = udicciHelpers.initializePermissions();
                if (defaultPermissions) {
                    newDefaultPerms.CanView = (defaultPermissions.CanView !== undefined ? defaultPermissions.CanView : false);
                    newDefaultPerms.CanAdd = (defaultPermissions.CanAdd !== undefined ? defaultPermissions.CanAdd : false);
                    newDefaultPerms.CanEdit = (defaultPermissions.CanEdit !== undefined ? defaultPermissions.CanEdit : false);
                    newDefaultPerms.CanEditIfOwner = (defaultPermissions.CanEditIfOwner !== undefined ? defaultPermissions.CanEditIfOwner : false);
                    newDefaultPerms.CanDelete = (defaultPermissions.CanDelete !== undefined ? defaultPermissions.CanDelete : false);
                    newDefaultPerms.CanDeleteIfOwner = (defaultPermissions.CanDeleteIfOwner !== undefined ? defaultPermissions.CanDeleteIfOwner : false);
                    newDefaultPerms.overrides = (defaultPermissions.overrides !== undefined ? defaultPermissions.overrides : {});
                }

                if (mediator) {
                    var defaultOvrds: any = (newDefaultPerms.overrides ? newDefaultPerms.overrides : null);
                    var defaultOvrdsPerms: UdicciPermissions | null = (defaultOvrds && defaultOvrds[mediatorName] ? defaultOvrds[mediatorName] : null);
                    var newDefaultMediatorPerms: UdicciPermissions = udicciHelpers.initializePermissions();
                    if (defaultOvrdsPerms) {
                        newDefaultMediatorPerms.CanView = (defaultOvrdsPerms.CanView !== undefined ? defaultOvrdsPerms.CanView : false);
                        newDefaultMediatorPerms.CanAdd = (defaultOvrdsPerms.CanAdd !== undefined ? defaultOvrdsPerms.CanAdd : false);
                        newDefaultMediatorPerms.CanEdit = (defaultOvrdsPerms.CanEdit !== undefined ? defaultOvrdsPerms.CanEdit : false);
                        newDefaultMediatorPerms.CanEditIfOwner = (defaultOvrdsPerms.CanEditIfOwner !== undefined ? defaultOvrdsPerms.CanEditIfOwner : false);
                        newDefaultMediatorPerms.CanDelete = (defaultOvrdsPerms.CanDelete !== undefined ? defaultOvrdsPerms.CanDelete : false);
                        newDefaultMediatorPerms.CanDeleteIfOwner = (defaultOvrdsPerms.CanDeleteIfOwner !== undefined ? defaultOvrdsPerms.CanDeleteIfOwner : false);
                    }
                    if (perm === 'view') newDefaultMediatorPerms.CanView = !newDefaultMediatorPerms.CanView;
                    if (perm === 'add') newDefaultMediatorPerms.CanAdd = !newDefaultMediatorPerms.CanAdd;
                    if (perm === 'edit') newDefaultMediatorPerms.CanEdit = !newDefaultMediatorPerms.CanEdit;
                    if (perm === 'delete') newDefaultMediatorPerms.CanDelete = !newDefaultMediatorPerms.CanDelete;
                    if (!defaultOvrds) defaultOvrds = {};
                    defaultOvrds[mediatorName] = newDefaultMediatorPerms;
                    newDefaultPerms.overrides = defaultOvrds;
                } else {
                    if (perm === 'view') newDefaultPerms.CanView = !newDefaultPerms.CanView;
                    if (perm === 'add') newDefaultPerms.CanAdd = !newDefaultPerms.CanAdd;
                    if (perm === 'edit') newDefaultPerms.CanEdit = !newDefaultPerms.CanEdit;
                    if (perm === 'delete') newDefaultPerms.CanDelete = !newDefaultPerms.CanDelete;
                }

                pssSettings.default = newDefaultPerms;
            }

            if (permType === 'role') {
                if (roleId) {
                    var newRolePerms: UdicciPermissions = udicciHelpers.initializePermissions();
                    if (roleOverride) {
                        newRolePerms.CanView = (roleOverride.CanView !== undefined ? roleOverride.CanView : false);
                        newRolePerms.CanAdd = (roleOverride.CanAdd !== undefined ? roleOverride.CanAdd : false);
                        newRolePerms.CanEdit = (roleOverride.CanEdit !== undefined ? roleOverride.CanEdit : false);
                        newRolePerms.CanEditIfOwner = (roleOverride.CanEditIfOwner !== undefined ? roleOverride.CanEditIfOwner : false);
                        newRolePerms.CanDelete = (roleOverride.CanDelete !== undefined ? roleOverride.CanDelete : false);
                        newRolePerms.CanDeleteIfOwner = (roleOverride.CanDeleteIfOwner !== undefined ? roleOverride.CanDeleteIfOwner : false);
                        newRolePerms.overrides = (roleOverride.overrides !== undefined ? roleOverride.overrides : {});
                    }

                    if (mediator) {
                        var roleOvrds: any = (newRolePerms.overrides ? newRolePerms.overrides : null);
                        var roleOvrdsPerms: UdicciPermissions | null = (roleOvrds && roleOvrds[mediatorName] ? roleOvrds[mediatorName] : null);
                        var newRoleMediatorPerms: UdicciPermissions = udicciHelpers.initializePermissions();
                        if (roleOvrdsPerms) {
                            newRoleMediatorPerms.CanView = (roleOvrdsPerms.CanView !== undefined ? roleOvrdsPerms.CanView : false);
                            newRoleMediatorPerms.CanAdd = (roleOvrdsPerms.CanAdd !== undefined ? roleOvrdsPerms.CanAdd : false);
                            newRoleMediatorPerms.CanEdit = (roleOvrdsPerms.CanEdit !== undefined ? roleOvrdsPerms.CanEdit : false);
                            newRoleMediatorPerms.CanEditIfOwner = (roleOvrdsPerms.CanEditIfOwner !== undefined ? roleOvrdsPerms.CanEditIfOwner : false);
                            newRoleMediatorPerms.CanDelete = (roleOvrdsPerms.CanDelete !== undefined ? roleOvrdsPerms.CanDelete : false);
                            newRoleMediatorPerms.CanDeleteIfOwner = (roleOvrdsPerms.CanDeleteIfOwner !== undefined ? roleOvrdsPerms.CanDeleteIfOwner : false);
                        }
                        if (perm === 'view') newRoleMediatorPerms.CanView = !newRoleMediatorPerms.CanView;
                        if (perm === 'add') newRoleMediatorPerms.CanAdd = !newRoleMediatorPerms.CanAdd;
                        if (perm === 'edit') newRoleMediatorPerms.CanEdit = !newRoleMediatorPerms.CanEdit;
                        if (perm === 'delete') newRoleMediatorPerms.CanDelete = !newRoleMediatorPerms.CanDelete;
                        if (!roleOvrds) roleOvrds = {};
                        roleOvrds[mediatorName] = newRoleMediatorPerms;
                        newRolePerms.overrides = roleOvrds;
                    } else {
                        if (perm === 'view') newRolePerms.CanView = !newRolePerms.CanView;
                        if (perm === 'add') newRolePerms.CanAdd = !newRolePerms.CanAdd;
                        if (perm === 'edit') newRolePerms.CanEdit = !newRolePerms.CanEdit;
                        if (perm === 'delete') newRolePerms.CanDelete = !newRolePerms.CanDelete;
                    }

                    roleOverrides[roleId] = newRolePerms;
                    pssSettings.RoleOverrides = roleOverrides;
                }
            }
        }

        // console.log('%c changePermission pssSettings: %O', 'color: red;', pssSettings);
        setSocialSolutionSettings(socialSolutionId, pssSettings);
    }

    return {
        set, getSetting, getSocialSolutionSettings, changePermission,
        udicciProfile
    };
}

/**
 * 
 * Usage:
 * 
 */
