// import { Fragment } from 'react';

import { find, filter, values } from 'underscore';

import dynamic from 'next/dynamic';

import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import ListIcon from '@mui/icons-material/ListAlt';

import { useUdicciContext } from 'src/context/udicci-context'
import { UdicciListContextStateType } from 'src/classes/udicci-types';
import { UdicciListContextProvider, subscribe as subscribeToListContext, unsubscribe as cancelListContextSubscription } from 'src/context/udicci-list-context';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

import { UdicciPluginComponent, UdicciPluginProps, udicciPluginComponentstate } from 'src/ui/udicci-ui-library';

const UdicciList = dynamic(() => import('src/components/udicci-list'));
const UdicciListAdmin = dynamic(() => import('src/admin/udicci-list-admin'));

const udicciListPlugin: UdicciPlugin = {
    Renderer: (props: any) => (<UdicciListPluginComponent {...props} />),
    id: 'udicci.list',
    title: 'List of Records',
    description: 'Display Social Solution data as a list of records.  Choose the information to show, set filter and sorting options, select engagement actions and setup custom layouts.',
    version: 1,
    icon: (<ListIcon />),
    controls: { type: 'admin', Component: (props: any) => (<UdicciListAdmin {...props} />) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c udicciListPlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c udicciListPlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
    },
    category: UdicciPluginCategory.Data
};
export default udicciListPlugin;

const UdicciListPluginComponent = (props: UdicciPluginProps) => {
    const udicciContext = useUdicciContext();
    // console.log('%c UdicciListPluginComponent udicciContext: %O', 'color: magenta;', udicciContext);
    const udicciHelpers = useUdicciHelpers();
    return (<UdicciListComponent udicciContext={udicciContext} udicciHelpers={udicciHelpers} {...props} />);
}

// UdicciListComponent extends the UdicciPluginComponent
class UdicciListComponent extends UdicciPluginComponent<UdicciPluginProps, udicciPluginComponentstate> {
    constructor(props: any, state: any) {
        super(props);
        // this.developerLog('%c UdicciListComponent constructor props: %O', 'color: red;', props);
        // this.developerLog('%c UdicciListComponent constructor state: %O', 'color: red;', state);

        let componentUID: string = 'udicci.list.' + this.props.udicciHelpers.generateUID();
        this.state = {
            uid: componentUID,
            CanView: false,
        };
    }

    componentDidUpdate(newProps: any, newState: any) {
        super.componentDidUpdate(newProps, newState);

        // this.developerLog('%c UdicciListComponent componentDidUpdate newProps: %O', 'color: black;', newProps);
        // this.developerLog('%c UdicciListComponent componentDidUpdate newState: %O', 'color: black;', newState);
        // this.developerLog('%c UdicciListComponent componentDidUpdate this.state: %O', 'color: black;', this.state);
    }

    componentWillUnmount() {
        // this.developerLog('%c UdicciListComponent componentWillUnmount this.state: %O', 'color: black;', this.state);
        if (this.state && this.state.uid) cancelListContextSubscription(this.state.uid);
    }

    componentDidMount() {
        // this.developerLog('%c UdicciListComponent componentDidMount this.props: %O', 'color: black;', this.props);
        // this.developerLog('%c UdicciListComponent componentDidMount this.state: %O', 'color: black;', this.state);
        // this.developerLog('%c UdicciListComponent componentDidMount this.context: %O', 'color: black;', this.context);
        super.componentDidMount();

        var propData: any = (this.props && this.props.data ? this.props.data : null);
        // console.log('%c UdicciListComponent propData: %O', 'color: maroon;', propData);
        // var pane: any = (this.props && this.props.pane ? this.props.pane : null);
        // console.log('%c UdicciListComponent pane: %O', 'color: maroon;', pane);
        var listSettings: any = (propData && propData.listSettings ? propData.listSettings : null);
        // console.log('%c UdicciListComponent listSettings: %O', 'color: maroon;', listSettings);
        var socialSolution: any = (listSettings && listSettings.socialSolution ? listSettings.socialSolution : null);
        // console.log('%c UdicciListComponent socialSolution: %O', 'color: maroon;', socialSolution);
        // var feature: any = (listSettings && listSettings.feature ? listSettings.feature : null);
        // console.log('%c UdicciListComponent feature: %O', 'color: maroon;', feature);
        var mediator: any = (listSettings && listSettings.mediator ? listSettings.mediator : null);
        // console.log('%c UdicciListComponent mediator: %O', 'color: maroon;', mediator);

        let subscriptionNotes: any = {
            mediator: {
                id: (mediator && mediator.id ? mediator.id : 0),
                name: (mediator && mediator.name ? mediator.name : '')
            },
            socialSolution: {
                id: (socialSolution && socialSolution.id ? socialSolution.id : 0),
                name: (socialSolution && socialSolution.name ? socialSolution.name : '')
            }
        };
        subscribeToListContext(this.state.uid, this.contextUpdated.bind(this), subscriptionNotes);

        if (this.state && this.state.isLoaded) {
            this.setState({ CanView: true });
        }
    }

    contextUpdated(state: any, payload: any) {
        // console.log('%c UdicciListComponent contextUpdated state: %O', 'color: hotpink;', state);
        // console.log('%c UdicciListComponent contextUpdated payload: %O', 'color: hotpink;', payload);
        // let updateData: any = (state && state.data ? state.data : null);
        // console.log('%c UdicciListComponent contextUpdated updateData: %O', 'color: hotpink;', updateData);
        // setTimeout(() => { setRefreshingData(false); }, 1000);
        // this.setState({ CanView: true });
        this.forceUpdate();
    };

    render() {
        // this.developerLog('%c UdicciListComponent render this.props: %O', 'color: black;', this.props);
        // this.developerLog('%c UdicciListComponent render this.state: %O', 'color: black;', this.state);

        return (<UdicciListWrapper {...this.props} uid={this.state.uid} />);
    }
}

const UdicciListWrapper: React.FC<any> = (props: any) => {
    // console.log('%c UdicciListWrapper props: %O', 'color: maroon;', props);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    var propData: any = (props && props.data ? props.data : null);
    // console.log('%c UdicciListWrapper propData: %O', 'color: maroon;', propData);
    var pane: any = (props && props.pane ? props.pane : null);
    // console.log('%c UdicciListWrapper pane: %O', 'color: maroon;', pane);
    var listSettings: any = (propData && propData.listSettings ? propData.listSettings : null);
    // console.log('%c UdicciListWrapper listSettings: %O', 'color: maroon;', listSettings);
    var socialSolution: any = (listSettings && listSettings.socialSolution ? listSettings.socialSolution : null);
    // console.log('%c UdicciListWrapper socialSolution: %O', 'color: maroon;', socialSolution);
    var feature: any = (listSettings && listSettings.feature ? listSettings.feature : null);
    // console.log('%c UdicciListWrapper feature: %O', 'color: maroon;', feature);
    var mediator: any = (listSettings && listSettings.mediator ? listSettings.mediator : null);
    // console.log('%c UdicciListWrapper mediator: %O', 'color: maroon;', mediator);

    var selectedFeatureId: number = (feature && feature.id ? feature.id : 0);
    // console.log('%c UdicciListWrapper selectedFeatureId: %O', 'color: maroon;', selectedFeatureId);

    const { udicci, data } = udicciContext.state;
    // console.log('%c UdicciListWrapper udicci: %O', 'color: maroon;', udicci);
    // console.log('%c UdicciListWrapper data: %O', 'color: maroon;', data);

    const { selectedProfile, currentUser } = udicci;
    var profile = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    // console.log('%c UdicciListWrapper profile: %O', 'color: maroon;', profile);
    var udicciUserRole = (profile && profile.UdicciUserRole ? profile.UdicciUserRole : null);
    // console.log('%c udicciUserRole: %O', 'color: purple; font-weight: bold;', udicciUserRole);
    var udicciUserRoleId = (udicciUserRole && udicciUserRole.UdicciRecordId ? udicciUserRole.UdicciRecordId : 0);
    // console.log('%c UdicciListWrapper udicciUserRoleId: %O', 'color: purple; font-weight: bold;', udicciUserRoleId);

    var profileSolutions = (profile && profile.SocialSolutions ? profile.SocialSolutions : null);
    // console.log('%c profileSolutions: %O', 'color: purple; font-weight: bold;', profileSolutions);
    var profileSettings = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
    // console.log('%c profileSettings: %O', 'color: hotpink;', profileSettings);

    var includedSocialSolutions: any = {};
    // var socialSolutionsMenuElement: any = null;
    if (profileSettings && profileSettings.socialSolutionSettings) {
        var sss: any = profileSettings.socialSolutionSettings;
        // console.log('%c UdicciListWrapper socialSolutionSettings: %O', 'color: maroon;', sss);
        if (sss) {
            for (let [id, setting] of Object.entries<any>(sss)) {
                // console.log('%c UdicciListWrapper id: %O', 'color: green;', id);
                // console.log('%c UdicciListWrapper setting: %O', 'color: green;', setting);

                var publicPermissions = (setting.public ? setting.public : null);
                // console.log('%c UdicciListWrapper publicPermissions: %O', 'color: maroon;', publicPermissions);
                var defaultPermissions = (setting.default ? setting.default : null);
                // console.log('%c UdicciListWrapper defaultPermissions: %O', 'color: maroon;', defaultPermissions);

                var permissions: any | null = null;
                if (currentUser && currentUser.UdicciUserId > 0) {
                    permissions = defaultPermissions;
                    // check for current user role overrides
                } else {
                    permissions = publicPermissions;
                }
                // console.log('%c UdicciListWrapper permissions: %O', 'color: blue;', permissions);

                if (currentUser && currentUser.UdicciUserId > 0) {
                    var roleOverrides = (setting.RoleOverrides ? setting.RoleOverrides : null);
                    // console.log('%c UdicciListWrapper roleOverrides: %O', 'color: maroon;', roleOverrides);
                    var userRoleOverride = (udicciUserRoleId > 0 && roleOverrides && roleOverrides[udicciUserRoleId] ? roleOverrides[udicciUserRoleId] : null);
                    // console.log('%c UdicciListWrapper userRoleOverride: %O', 'color: maroon;', userRoleOverride);
                    if (userRoleOverride) {
                        // console.log('%c UdicciListWrapper userRoleOverride: %O', 'color: green;', userRoleOverride);
                        permissions = userRoleOverride;

                        if (userRoleOverride.overrides) {
                            for (var [medName, medPerms] of Object.entries<any>(userRoleOverride.overrides)) {
                                // console.log('%c UdicciListWrapper medName: %O', 'color: green;', medName);
                                // console.log('%c UdicciListWrapper medPerms: %O', 'color: green;', medPerms);
                                if (!medName) continue;

                                if (medPerms && medPerms.CanView !== undefined) permissions.CanView = medPerms.CanView;
                                if (medPerms && medPerms.CanAdd !== undefined) permissions.CanAdd = medPerms.CanAdd;
                            }
                        }
                    }
                }

                // console.log('%c UdicciListWrapper permissions: %O', 'color: blue;', permissions);
                if (permissions && permissions.CanView) {
                    // console.log('%c UdicciListWrapper permissions: %O', 'color: green; font-weight: bold;', permissions);
                    if (!includedSocialSolutions[id]) includedSocialSolutions[id] = {};

                    var ss: any | null = null;
                    if (profileSolutions) {
                        ss = profileSolutions.find((x: any) => x.recordId.toString() === id.toString());
                    }
                    // console.log('%c ss: %O', 'color: green; font-weight: bold;', ss);

                    if (ss) {
                        if (!ss.permissions) ss.permissions = permissions;
                        includedSocialSolutions[id].permissions = permissions;
                        includedSocialSolutions[id].setting = setting;
                        includedSocialSolutions[id].solution = ss;
                        // ssCount++;
                    }
                }
            }
        }
        // console.log('%c UdicciListWrapper ssCount: %O', 'color: blue;', ssCount);
    }
    // console.log('%c UdicciListWrapper includedSocialSolutions: %O', 'color: blue;', includedSocialSolutions);

    var selSS: any = null;
    if (includedSocialSolutions && socialSolution) {
        selSS = find(includedSocialSolutions, function(ss: any) {
            // console.log('%c ss: %O', 'color: blue;', ss);
            return (ss.solution && ss.solution.recordId === socialSolution.id);
        });
    }
    // console.log('%c selSS: %O', 'color: purple; font-weight: bold;', selSS);
    if (!selSS) {
        return (
            <Stack sx={{ color: 'grey.500', margin: '8px' }} spacing={1}>
                <LinearProgress color="inherit" />
                <LinearProgress color="inherit" />
                <LinearProgress color="inherit" />
            </Stack>            
        );
    }

    if (selSS) {
        let ss: any = (listSettings.socialSolution ? listSettings.socialSolution : {});
        Object.assign(ss, selSS);
        listSettings.socialSolution = ss;
    }

    var selFtre: any = null;
    // var ssRecordId = (selSS.solution.recordId ? selSS.solution.recordId : 0);
    var ssData = (selSS.solution.data ? selSS.solution.data : null);
    // console.log('%c ssData: %O', 'color: hotpink;', ssData);
    var ssSettings = (ssData.jsonSettingsJson ? ssData.jsonSettingsJson : null);
    // console.log('%c ssSettings: %O', 'color: hotpink;', ssSettings);

    var features = (ssData && ssData.Features ? ssData.Features : null);
    // console.log('%c features: %O', 'color: hotpink;', features);
    var mediators = (ssSettings && ssSettings.mediators ? ssSettings.mediators : null);
    // console.log('%c mediators: %O', 'color: hotpink;', mediators);

    if (mediators !== null && features && features.length > 0) {
        features.forEach(function(ftre: any, idx: number) {
            // console.log('%c ftre %s: %O', 'color: blue;', ftre.Name, ftre);
            // console.log('%c idx: %O', 'color: blue;', idx);
            var featureMediators = filter(values(mediators), function(med: any) {
                // console.log('%c med %s: %O', 'color: blue;', med.name, med);
                var isDefaultFeature: boolean = (med.feature === ftre.Name ? true : false);
                var otherFeatureMediator: any = null;
                if (med.features && med.features.length > 0) {
                    otherFeatureMediator = find(med.features, function(ftrMed: any) {
                        return ftrMed.name === ftre.Name;
                    });
                }
                // console.log('%c isDefaultFeature: %O', 'color: red;', isDefaultFeature);
                // console.log('%c otherFeatureMediator: %O', 'color: red;', otherFeatureMediator);
                return isDefaultFeature || otherFeatureMediator;
            });
            // // console.log('%c featureMediators: %O', 'color: blue;', featureMediators);
            if (featureMediators) {
                // console.log('%c featureMediators: %O', 'color: hotpink;', featureMediators);
                features[idx].Mediators = featureMediators;
                if (featureMediators.length > 0) {
                    if (!selFtre && selectedFeatureId === ftre.UdicciRecordId) {
                        selFtre = ftre;
                    }
                }
            }
        });
    }

    if (!selFtre) {
        return (
            <Stack sx={{ color: 'grey.500', margin: '8px' }} spacing={1}>
                <LinearProgress color="success" variant="determinate" value={100} />
                <LinearProgress color="inherit" />
                <LinearProgress color="inherit" />
            </Stack>            
        );
    }
    if (!mediator) {
        return (
            <Stack sx={{ color: 'grey.500', margin: '8px' }} spacing={1}>
                <LinearProgress color="success" variant="determinate" value={100} />
                <LinearProgress color="success" variant="determinate" value={100} />
                <LinearProgress color="inherit" />
            </Stack>            
        );
    }

    var listData: any = null;
    if ((data && mediator)) {
        var mediatorContext = data.find((x: any) => x.mediator === mediator.name );
        // console.log('%c UdicciListWrapper mediatorContext: %O', 'color: maroon;', mediatorContext);
        if (mediatorContext && mediatorContext.records) listData = mediatorContext.records;
    }
    // console.log('%c UdicciListWrapper listData: %O', 'color: maroon;', listData);

    // var socialSolutionId: number = (selSS.solution.recordId ? selSS.solution.recordId : 0);
    // var mediatorId: number = (mediator && mediator.id ? mediator.id : 0);
    var contextValue: UdicciListContextStateType = {
        uid: 'udicci.list.' + udicciHelpers.generateUID(),
        data: listData,
        socialSolution: {
            id: selSS.solution.recordId,
            name: selSS.solution.title,
            more: selSS
        },
        feature: {
            id: selFtre.UdicciRecordId,
            name: selFtre.Name,
            more: selFtre
        },
        mediator: {
            id: mediator.id,
            name: mediator.name,
            label: (mediator && mediator.label ? mediator.label : mediator.name)
        },
        settings: listSettings,
        record: null,
        breadcrumbs: null,
        onLoadBreadcrumb: null,
        onCloseBreadcrumb: null,
        onCloseAllBreadcrumbs: null,
        onRefresh: null,
        onAddRecord: null,
        onEditRecord: null,
        onEditContextRecord: null,
        onDeleteRecord: null,
        onEngageRecord: null,
        lastFetchResultDate: null
    };
    // console.log('%c contextValue: %O', 'color: purple; font-weight: bold;', contextValue);

    let contextProviderKey = props.uid + '.context'; // + socialSolutionId.toString() + '.' + mediatorId.toString();
    var contextProviderProps: any = {
        value: contextValue,
        socialSolution: selSS,
        feature: selFtre,
        contextKey: contextProviderKey,
        pane: pane,
    };
    // console.log('%c contextProviderProps: %O', 'color: purple; font-weight: bold;', contextProviderProps);

    return (
        <UdicciListContextProvider {...contextProviderProps}>
            <UdicciList uid={props.uid} pane={pane} listSettings={listSettings} />
        </UdicciListContextProvider>
    );
}
