import { forEach, find } from 'underscore';

// Udicci Helper Classes

export type UdicciPermissions = {
    CanView: boolean;
    CanAdd: boolean;
    CanEdit: boolean;
    CanDelete: boolean;
    CanDuplicate: boolean;
    CanProvision: boolean;
    CanEditIfOwner?: boolean;
    CanDeleteIfOwner?: boolean;
    InternalReferenceOnly?: boolean;
    LimitedView?: boolean;
    ViewFields? : any
    overrides? : any
}

export class UdicciRecord {
    recordId: number = 0;
    udicciMediator: string = '';
    permissions: UdicciPermissions | null = null;
    keys: any = null;
    title: string = '';
    description: string = '';
    data: any = null;
    isDirty: boolean = false;
    isSaving: boolean = false;
    perspectives?: any[] | null | undefined;
    attachments?: any[] | null | undefined;
    context?: any = null;

    constructor(mediator: string, recordData: any | null, structure: any | null, permissions: any | null = null) {
        // console.log('%c UdicciRecord constructor mediator: %O', 'color: darkorange;', mediator);
        // console.log('%c UdicciRecord constructor recordData: %O', 'color: darkorange;', recordData);
        // console.log('%c UdicciRecord constructor structure: %O', 'color: darkorange;', structure);
        // console.log('%c UdicciRecord constructor permissions: %O', 'color: darkorange;', permissions);

        // console.log('%c recordData: %O', 'color: darkorange;', recordData);
        if (recordData && recordData.recordId && recordData.udicciMediator) {
            // console.log('%c recordData: %O', 'color: green;', recordData);
            this.recordId = recordData.recordId;
            this.udicciMediator = recordData.udicciMediator;
            this.keys = recordData.keys;
            this.data = recordData.data;
            this.title = recordData.title;
            this.description = recordData.description;
            this.isDirty = false;
            this.isSaving = false;
            this.permissions = null;
        } else {
            // console.log('%c mediator: %O', 'color: maroon;', mediator);
            if (mediator) this.udicciMediator = mediator;

            if (recordData) {
                if (recordData.UdicciRecordId) this.recordId = recordData.UdicciRecordId;
                this.data = recordData;
            } else {
                this.data = {};
            }
            // console.log('%c this.data: %O', 'color: maroon;', this.data);

            if (this.data && this.data.UdicciRecordId === undefined || this.data.UdicciRecordId === null) {
                this.data.UdicciRecordId = 0;
            }

            if (structure) {
                var fields = (structure && structure.UdicciMediatorFields ? structure.UdicciMediatorFields : []);
                // console.log('%c fields: %O', 'color: maroon;', fields);
                var listFields: any = null;
                if (fields && fields.length > 0) {
                    listFields = fields.filter(function(fld: any) { return fld.DisplayInList === true });
                }

                var keys = { title: '', description: '' };
                var titleField: any = null;
                var descriptionField: any = null;
                if (listFields && listFields.length > 0) {
                    titleField = listFields[0];
                    descriptionField = (listFields.length > 1 ? listFields[1] : null);
    
                    keys.title = titleField.JsonFieldName;
                    keys.description = (descriptionField ? descriptionField.JsonFieldName : '');
                }
    
                if (!keys.description) {
                    descriptionField = (fields.length > 1 ? fields[1] : null);
                    keys.description = (descriptionField ? descriptionField.JsonFieldName : '');
                }
    
                if (!keys.title) {
                    titleField = (fields.length > 0 ? fields[0] : null);
                    keys.title = (titleField ? titleField.JsonFieldName : '');
                }
    
                var titleKey = (keys && keys.title ? keys.title : "");
                var descriptionKey = (keys && keys.description ? keys.description : "");
    
                var profileTitle = (recordData ? recordData[titleKey] : "");
                var profileDescription = (recordData ? recordData[descriptionKey] : "");
    
                this.keys = keys;
                this.title = profileTitle;
                this.description = profileDescription;
    
                if (fields && this.data) {
                    // console.log('%c fields: %O', 'color: maroon;', fields);
                    // console.log('%c this.data: %O', 'color: maroon;', this.data);
                    if (fields.length > 0) {
                        for (var fieldRecord of Object.entries<any>(fields)) {
                            // console.log('%c fieldRecord: %O', 'color: red; font-weight: bold;', fieldRecord);
                            var fld = fieldRecord[1];
                            // console.log('%c fld: %O', 'color: hotpink;', fld);

                            const { JsonFieldName } = fld;
                            // console.log('%c JsonFieldName: %O', 'color: hotpink;', JsonFieldName);
                            const fldValue = this.data[JsonFieldName];
                            // console.log('%c fldValue: %O', 'color: hotpink;', fldValue);

                            if (fldValue === undefined) {
                                if (fld.DefaultValue) {
                                    this.data[JsonFieldName] = fld.DefaultValue;
                                } else {
                                    this.data[JsonFieldName] = '';
                                }
                            }

                            if (fld.DataType === 'Json') {
                                if (fldValue) {
                                    var jsonFieldValue: any = null;
                                    try {
                                        jsonFieldValue = JSON.parse(fldValue);
                                    } catch (e: any) {
                                        jsonFieldValue = null;
                                    }
                                    this.data['json' + JsonFieldName] = jsonFieldValue;
                                } else {
                                    this.data['json' + JsonFieldName] = null;
                                }
                            }

                            if (fld.DataType === 'DateTime') {
                                if (fldValue) {
                                    var dateFieldValue: any = null;
                                    try {
                                        dateFieldValue = new Date(fldValue);
                                    } catch (e: any) {
                                        dateFieldValue = null;
                                    }
                                    this.data['dt' + JsonFieldName] = dateFieldValue;
                                } else {
                                    this.data['dt' + JsonFieldName] = null;
                                }
                            }
                        }
                    }
                }

                // do a final check from the record data
                if (recordData) {
                    for (var recField of Object.entries<any>(recordData)) {
                        // console.log('%c recField: %O', 'color: red; font-weight: bold;', recField);

                        var jsonKey = recField[0];
                        var recFieldVal = recField[1];
                        if (this.data[jsonKey] !== recFieldVal) {
                            this.data[jsonKey] = recFieldVal;
                        }
                    }
                }

                if (this.data.DateCreated) {
                    var dateCreatedFieldValue: any = null;
                    try {
                        dateCreatedFieldValue = new Date(this.data.DateCreated);
                    } catch (e: any) {
                        dateCreatedFieldValue = null;
                    }
                    this.data.dtDateCreated = dateCreatedFieldValue;
                } else {
                    this.data.dtDateCreated = null;
                }

                if (this.data.ModifiedDate) {
                    var dateModifiedFieldValue: any = null;
                    try {
                        dateModifiedFieldValue = new Date(this.data.ModifiedDate);
                    } catch (e: any) {
                        dateModifiedFieldValue = null;
                    }
                    this.data.dtModifiedDate = dateModifiedFieldValue;
                } else {
                    this.data.dtModifiedDate = null;
                }

                if (this.data.DeletedDate) {
                    var dateDeletedFieldValue: any = null;
                    try {
                        dateDeletedFieldValue = new Date(this.data.DeletedDate);
                    } catch (e: any) {
                        dateDeletedFieldValue = null;
                    }
                    this.data.dtDeletedDate = dateDeletedFieldValue;
                } else {
                    this.data.dtDeletedDate = null;
                }

                if (this.data.MediatedRecords) {
                    var jsonMediatedRecordsValue: any = null;
                    try {
                        jsonMediatedRecordsValue = JSON.parse(this.data.MediatedRecords);
                    } catch (e: any) {
                        jsonMediatedRecordsValue = null;
                    }
                    this.data.jsonMediatedRecords = jsonMediatedRecordsValue;
                } else {
                    this.data.jsonMediatedRecords = null;
                }

                if (this.data.MediationSource) {
                    var jsonMediationSourceValue: any = null;
                    try {
                        jsonMediationSourceValue = JSON.parse(this.data.MediationSource);
                    } catch (e: any) {
                        jsonMediationSourceValue = null;
                    }
                    this.data.jsonMediationSource = jsonMediationSourceValue;
                } else {
                    this.data.jsonMediationSource = null;
                }
            }
        }

        if (this.data && typeof(this.data.UdicciRecordId) !== 'number') {
            this.data.UdicciRecordId = 0;
        }

        // console.log('%c permissions: %O', 'color: darkorange;', permissions);
        var newPermissions: UdicciPermissions = {
            CanAdd: false,
            CanView: false,
            CanEdit: false,
            CanDelete: false,
            CanDuplicate: false,
            CanProvision: false
        };

        if (permissions !== null) {
            newPermissions.CanAdd = (permissions.CanAdd ? true : false);
            newPermissions.CanView = (permissions.CanView ? true : false);
            newPermissions.CanEdit = (permissions.CanEdit ? true : false);
            newPermissions.CanDelete = (permissions.CanDelete ? true : false);
            newPermissions.CanDuplicate = (permissions.CanDuplicate ? true : false);
            newPermissions.CanProvision = (permissions.CanProvision ? true : false);
            // console.log('%c newPermissions: %O', 'color: red;', newPermissions);
            this.permissions = newPermissions;
        } else {
            if (this.recordId <= 0) {
                newPermissions.CanAdd = true;
                newPermissions.CanView = true;
                newPermissions.CanEdit = true;
                newPermissions.CanDelete = true;
                newPermissions.CanDuplicate = true;
                newPermissions.CanProvision = true;
                // console.log('%c newPermissions: %O', 'color: red;', newPermissions);
                this.permissions = newPermissions;
            }
        }
    }

    setKeyFieldValues? = () => {
        // console.log('%c setKeyFieldValues this: %O', 'color: blue;', this);
        if (this.keys && this.keys.title && this.data) {
            // console.log('%c setKeyFieldValues title this.data[%s]: %O', 'color: green;', this.keys.title, this.data[this.keys.title]);
            this.title = (this.data[this.keys.title] !== undefined ? this.data[this.keys.title] : '');
            // console.log('%c setKeyFieldValues this.title: %O', 'color: red;', this.title);
        }
        if (this.keys && this.keys.description && this.data) {
            // console.log('%c setKeyFieldValues description this.data[%s]: %O', 'color: green;', this.keys.description, this.data[this.keys.description]);
            this.description = (this.data[this.keys.description] !== undefined ? this.data[this.keys.description] : '');
            // console.log('%c setKeyFieldValues this.description: %O', 'color: red;', this.description);
        }
    }

    requestRelationshipUpdate? = (relatedMediatorRelationship: string, relatedMediatorName: string, relatedRecordId: number, changeAction: string | undefined = 'Add') => {
        if (!relatedMediatorRelationship) return false;
        if (!relatedMediatorName) return false;
        // relatedRecordId can be 0 or an ID of the related record

        var relationshipChangesJson: any[] = [];
        if (this.context && this.context.RelationshipChanges && this.context.RelationshipChanges.length > 0) {
            relationshipChangesJson = this.context.RelationshipChanges;
        }
        var relChangeJson: any = {
            RecordMediator: this.udicciMediator,
            RecordId: (this.recordId ? this.recordId : 0),
            RelatedMediator: relatedMediatorName,
            RelatedRecordId: relatedRecordId,
            Priority: -1
        };
        if (relatedMediatorRelationship.toLowerCase() === 'parent') {
            relChangeJson[changeAction] = 'Parent';
        } else if (relatedMediatorRelationship.toLowerCase() === 'child') {
            relChangeJson[changeAction] = 'Child';
        }
        // console.log('%c requestRelationshipUpdate relChangeJson: %O', 'color: green;', relChangeJson);

        let hasUpdate: boolean = false;
        if (relChangeJson) {
            let relChg: any = find(relationshipChangesJson, (rc: any) => {
                let sameRecordMediator: boolean = rc.RecordMediator === relChangeJson.RecordMediator;
                let sameRecordId: boolean = rc.RecordId === relChangeJson.RecordId;
                let sameRelatedMediator: boolean = rc.RecordMediator === relChangeJson.RelatedMediator;
                let sameRelatedRecordId: boolean = rc.RecordId === relChangeJson.RelatedRecordId;
                return sameRecordMediator && sameRecordId && sameRelatedMediator && sameRelatedRecordId;
            });
            // console.log('%c requestRelationshipUpdate relChg: %O', 'color: green;', relChg);
            if (!relChg) {
                relationshipChangesJson.push(relChangeJson);
                hasUpdate = true;
            } else if (relChg && relChg.Add) {
                // console.log('%c requestRelationshipUpdate relChg.Add: %O', 'color: brown;', relChg);
            } else if (relChg && relChg.Remove) {
                // console.log('%c requestRelationshipUpdate relChg.Remove: %O', 'color: brown;', relChg);
            }
        }
        // console.log('%c requestRelationshipUpdate relationshipChangesJson: %O', 'color: green;', relationshipChangesJson);

        var recContext: any = (this.context ? this.context : null);
        // console.log('%c requestRelationshipUpdate relationshipChangesJson: %O', 'color: red;', relationshipChangesJson);
        if (hasUpdate) {
            if (!recContext) recContext = {};
            recContext.RelationshipChanges = relationshipChangesJson;
            this.isDirty = true;
        }
        // console.log('%c requestRelationshipUpdate recContext: %O', 'color: maroon;', recContext);
        this.context = recContext;

        return true;
    }

    cancelRelationshipUpdate? = (relatedMediatorRelationship: string, relatedMediatorName: string, relatedRecordId: number) => {
        if (!relatedMediatorRelationship) return false;
        if (!relatedMediatorName) return false;

        var relationshipChangesJson: any[] = [];
        if (this.context && this.context.RelationshipChanges && this.context.RelationshipChanges.length > 0) {
            relationshipChangesJson = this.context.RelationshipChanges;
        }

        let hasUpdate: boolean = false;
        forEach(relationshipChangesJson, (rc: any, rcIndex: number) => {
            let sameRecordMediator: boolean = rc.RecordMediator.toString().equals(this.udicciMediator);
            let sameRecordId: boolean = rc.RecordId.toString().equals(this.recordId);
            let sameRelatedMediator: boolean = rc.RecordMediator.toString().equals(relatedMediatorName);
            let sameRelatedRecordId: boolean = rc.RecordId.toString().equals(relatedRecordId);
            if (sameRecordMediator && sameRecordId && sameRelatedMediator && sameRelatedRecordId) {
                relationshipChangesJson = relationshipChangesJson.splice(rcIndex, 1);
                hasUpdate = true;
            }
        });
        // console.log('%c cancelRelationshipUpdate relationshipChangesJson: %O', 'color: green;', relationshipChangesJson);

        var recContext: any = (this.context ? this.context : null);
        // console.log('%c cancelRelationshipUpdate relationshipChangesJson: %O', 'color: red;', relationshipChangesJson);
        if (hasUpdate) {
            if (!recContext) recContext = {};
            recContext.RelationshipChanges = relationshipChangesJson;
            this.isDirty = true;
        }
        // console.log('%c cancelRelationshipUpdate recContext: %O', 'color: maroon;', recContext);
        this.context = recContext;
    }
}

export const checkConditionAgainstRecord = (record: UdicciRecord, conditions: any, mediatorContext: any = null) => {
    // console.log('%c UdicciRecord checkConditionAgainstRecord record %s: %O', 'color: purple; font-weight: bold;', record.title, record);
    // console.log('%c UdicciRecord checkConditionAgainstRecord conditions: %O', 'color: purple; font-weight: bold;', conditions);
    // console.log('%c UdicciRecord checkConditionAgainstRecord mediatorContext: %O', 'color: purple; font-weight: bold;', mediatorContext);
    var resultingConditionIsTrue: boolean = false;
    if (record && conditions) {
        var compareRecord: any = record;
        // console.log('%c checkConditionAgainstRecord compareRecord: %O', 'color: purple; font-weight: bold;', compareRecord);

        var conditionsIsArray: any = Array.isArray(conditions);
        // console.log('%c checkConditionAgainstRecord conditionsIsArray: %O', 'color: purple; font-weight: bold;', conditionsIsArray);

        if (conditionsIsArray) {
            // console.log('%c checkConditionAgainstRecord record: %O', 'color: purple; font-weight: bold;', record);
            // console.log('%c checkConditionAgainstRecord conditions: %O', 'color: purple; font-weight: bold;', conditions);
            // console.log('%c checkConditionAgainstRecord conditionsIsArray: %O', 'color: purple; font-weight: bold;', conditionsIsArray);
            var allConditionsSucceeded = true;
            var currentConditionGroup = '';

            var structure: any = (mediatorContext && mediatorContext.structure ? mediatorContext.structure : null);
            // console.log('%c checkConditionAgainstRecord structure: %O', 'color: purple; font-weight: bold;', structure);

            var fields: any = (structure && structure.UdicciMediatorFields ? structure.UdicciMediatorFields : null);
            // console.log('%c checkConditionAgainstRecord fields: %O', 'color: purple; font-weight: bold;', fields);
            var virtualFields: any = (structure && structure.VirtualMediatorFields ? structure.VirtualMediatorFields : null);
            // console.log('%c checkConditionAgainstRecord virtualFields: %O', 'color: purple; font-weight: bold;', virtualFields);

            forEach(conditions, function(condition: any, i: number) {
                // console.log('condition: %O', condition);
                var conditionJsonKey = (condition.jsonKey ? condition.jsonKey : '');
                // console.log('conditionJsonKey: %O', conditionJsonKey);
                var conditionJsonPath = (condition.jsonPath ? condition.jsonPath : '');
                // console.log('conditionJsonPath: %O', conditionJsonPath);
                var conditionBinder = (condition.conditionBinder ? condition.conditionBinder : 'And');
                // console.log('conditionBinder: %O', conditionBinder);

                var dataType: string = 'String';
                if (fields && fields.length > 0) {
                    var conditionField: any = fields.find((x: any) => x.JsonFieldName === conditionJsonKey );
                    // console.log('%c conditionField: %O', 'color: maroon;', conditionField);
                    if (!conditionField && virtualFields && virtualFields.length > 0) {
                        conditionField = virtualFields.find((x: any) => x.JsonFieldName === conditionJsonKey );
                        // console.log('%c (VF) conditionField: %O', 'color: maroon;', conditionField);
                    }

                    if (conditionField) dataType = conditionField.DataType;
                }
                // console.log('dataType: %O', dataType);

                // var dataType = (filterSetting.dataType ? filterSetting.dataType : 'String');
                var conditionToCheck = (condition.conditionToCheck ? condition.conditionToCheck : '');
                // console.log('conditionToCheck: %O', conditionToCheck);
                var valueToCompare = condition.valueToCompare;
                // console.log('valueToCompare: %O', valueToCompare);

                var conditionGroup = (condition.conditionGroup ? condition.conditionGroup : '');
                // console.log('conditionGroup: %O', conditionGroup);
                // console.log('conditionGroup: %O', conditionGroup);
                // console.log('currentConditionGroup: %O', currentConditionGroup);
                if (conditionGroup && conditionGroup !== currentConditionGroup) {
                    currentConditionGroup = conditionGroup;
                    allConditionsSucceeded = true;
                // } else if (!conditionGroup) {
                //     allConditionsSucceeded = true;
                }
                // console.log('checkConditionAgainstRecord currentConditionGroup: %O', currentConditionGroup);
                // console.log('checkConditionAgainstRecord allConditionsSucceeded: %O', allConditionsSucceeded);

                var recordValue: any = null;
                if (dataType === 'Json' && conditionJsonPath) {
                    var conditionPathParts: string[] = [];
                    if (conditionJsonPath.indexOf('.') > 0) {
                        conditionPathParts = conditionJsonPath.split('.');
                    } else {
                        conditionPathParts.push(conditionJsonPath);
                    }
                    var curVal: any = record.data['json' + conditionJsonKey];
                    // console.log('checkConditionAgainstRecord curVal before: %O', curVal);
                    if (curVal) {
                        conditionPathParts.forEach((nextKey: string) => {
                            var newVal: any = (curVal && curVal[nextKey] ? curVal[nextKey] : null);
                            if (newVal && typeof(newVal) === 'object') {
                                Object.assign(curVal, newVal);
                            } else {
                                curVal = newVal;
                            }
                        });
                    }
                    // console.log('checkConditionAgainstRecord curVal after: %O', curVal);
                    recordValue = curVal;
                } else {
                    recordValue = record.data[conditionJsonKey];
                }
                // console.log('checkConditionAgainstRecord recordValue: %O', recordValue);

                var conditionIsTrue = false;
                switch (conditionToCheck) {
                    case 'is.equal.to':
                        if (recordValue === valueToCompare) conditionIsTrue = true;
                        break;
                    case 'not.equal.to':
                        if (recordValue !== valueToCompare) conditionIsTrue = true;
                        break;
                    case 'is.empty':
                        if (recordValue && recordValue.toString().length <= 0) conditionIsTrue = true;
                        break;
                    case 'not.empty':
                        if (recordValue && recordValue.toString().length > 0) conditionIsTrue = true;
                        break;
                    case 'is.null':
                        if (recordValue === null) conditionIsTrue = true;
                        break;
                    case 'not.null':
                        if (recordValue !== null) conditionIsTrue = true;
                        break;
                    case 'contains':
                        // console.log('recordValue: %O', recordValue);
                        // console.log('valueToCompare: %O', valueToCompare);
                        if (recordValue) {
                            var rslt = recordValue.toString().indexOf(valueToCompare);
                            // console.log('%c filterSettings: %O', 'color: red;', filterSettings);
                            if (rslt >= 0) {
                                // console.log('rslt: %O', rslt);
                                conditionIsTrue = true;
                                // console.log('conditionIsTrue: %O', conditionIsTrue);
                            }
                        }
                        // console.log('conditionIsTrue: %O', conditionIsTrue);
                        break;
                    case 'does.not.contain':
                        if (recordValue && recordValue.toString().indexOf(valueToCompare) < 0) conditionIsTrue = true;
                        break;
                    case 'starts.with':
                        if (recordValue && recordValue.toString().indexOf(valueToCompare) === 0) conditionIsTrue = true;
                        break;
                    case 'does.not.start.with':
                        if (recordValue && recordValue.toString().indexOf(valueToCompare) !== 0) conditionIsTrue = true;
                        break;
                    case 'ends.with':
                        if (recordValue && recordValue.toString().endsWith(valueToCompare)) conditionIsTrue = true;
                        break;
                    case 'does.not.end.with':
                        if (recordValue && !recordValue.toString().endsWith(valueToCompare)) conditionIsTrue = true;
                        break;
                    case 'is.greater.than':
                        break;
                    case 'is.greater.than.or.equal.to':
                        break;
                    case 'is.less.than':
                        break;
                    case 'is.less.than.or.equal.to':
                        break;
                    case 'between':
                        break;
                }

                if (conditionBinder === 'Or') {
                    if (conditionIsTrue === true || allConditionsSucceeded === true) {
                        allConditionsSucceeded = true;
                    } else {
                        allConditionsSucceeded = false;
                    }
                } else { // And
                    if (conditionIsTrue !== true) {
                        allConditionsSucceeded = false;
                    }
                }
            });
            // console.log('allConditionsSucceeded: %O', allConditionsSucceeded);
            if (allConditionsSucceeded) resultingConditionIsTrue = allConditionsSucceeded;
        } else {
            var conditionsField = (conditions.field !== undefined ? conditions.field : '');
            if (conditionsField === 'udicciRecordId') conditionsField = 'recordId';
            // console.log('%c checkConditionAgainstRecord conditionsField: %O', 'color: purple;', conditionsField);
            var conditionsComparison = (conditions.comparison !== undefined ? conditions.comparison : 'Equals');
            var conditionsValue = (conditions.value !== undefined ? conditions.value : null);
            // console.log('%c checkConditionAgainstRecord conditionsValue: %O', 'color: purple;', conditionsValue);

            var conditionsComparisons = [];
            if (conditionsComparison.indexOf(',') > 0) {
                conditionsComparisons = conditionsComparison.split(',');
            } else {
                conditionsComparisons.push(conditionsComparison);
            }
            // console.log('%c checkConditionAgainstRecord conditionsComparisons: %O', 'color: purple;', conditionsComparisons);

            forEach(conditionsComparisons, function(comparison: string, i: number) {
                // console.log('%c checkConditionAgainstRecord comparison: %O', 'color: purple;', comparison);
                var recordValue: string = '';
                if (compareRecord[conditionsField] !== undefined) {
                    recordValue = compareRecord[conditionsField];
                } else if (compareRecord.data && compareRecord.data[conditionsField] !== undefined) {
                    recordValue = compareRecord.data[conditionsField];
                }

                var conditionMet = performRecordValueComparison(comparison, recordValue, conditionsValue);
                // console.log('%c conditionMet: %O', 'color: blue;', conditionMet);
                if (conditionMet) resultingConditionIsTrue = true;
            });
        }
    } else {
        resultingConditionIsTrue = true;
    }

    // console.log('%c checkConditionAgainstRecord resultingConditionIsTrue: %O', 'color: purple;', resultingConditionIsTrue);
    return resultingConditionIsTrue;
}

export const performRecordValueComparison = (comparison: string, recordValue: any, compareValue: any) => {
    // console.log('%c performRecordValueComparison comparison: %O', 'color: purple;', comparison);
    // console.log('%c performRecordValueComparison recordValue: %O', 'color: purple;', recordValue);
    // console.log('%c performRecordValueComparison compareValue: %O', 'color: purple;', compareValue);
    var valueMeetsCriteria = false;

    switch(comparison) {
        case 'Equals':
            valueMeetsCriteria = (recordValue === compareValue ? true : false);
            break;
        case 'Less Than':
            valueMeetsCriteria = (recordValue < compareValue ? true : false);
            break;
        case 'Greater Than':
            valueMeetsCriteria = (recordValue > compareValue ? true : false);
            break;
        case 'Not Equal':
            valueMeetsCriteria = (recordValue !== compareValue ? true : false);
            break;
        default:
            break;
    }

    // console.log('%c performRecordValueComparison valueMeetsCriteria: %O', 'color: purple;', valueMeetsCriteria);
    return valueMeetsCriteria;
}

export class ProfileResponse {
    profile: UdicciRecord | null = null;
    socialSolutions: UdicciRecord[] | null = null;
    profilePorta: object | null = null;
    structures: object | null = null;
}

export class SocialSolutionSettings {
    MenuIcon: string = '';
    engagementActions: object[] | null = null;
    features: object[] | null = null;
    mediators: object | null = null;
    structures: object | null = null;
}
