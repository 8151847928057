import React, { useState, Fragment } from 'react';

import { styled, useTheme, ThemeProvider, Theme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

import { forEach, find } from 'underscore';

import { useUdicciContext, getDesignMode } from 'src/context/udicci-context'
import { udicciPluginComponents } from 'src/plugins/udicciPlugins';
import { UdicciPlugin } from 'src/classes/udicci-types';

import {
    Box, Paper, Typography, IconButton, FormControl, Input, InputLabel, Select, Divider, 
    MenuItem, Tabs, Tab, Button, FormControlLabel, Checkbox, Icon, Snackbar, Backdrop
} from '@mui/material';

import {
    Settings as SettingsIcon,
    Close as CloseSettingsIcon,
    Save as SaveIcon,
    DeleteForever as RemovePaneIcon,
    KeyboardArrowUp as MoveUpIcon,
    KeyboardArrowDown as MoveDownIcon,
    // ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';

import AccessSettingsAdmin from 'src/admin/access-settings';
import DesignSettingsAdmin from 'src/admin/design-settings';

import PaneDesigner from './pane-designer';
import { udicciStyles } from 'src/theme/shared-styles';
import useUdicciHelpers, { TabPanel } from 'src/hooks/useUdicciHelpers';
import { configure } from '@testing-library/react';
// import useTimeout from 'src/hooks/useTimeout';

const PaneContainer = styled('div')(({ theme }) => ({
    // borderBottom: '0.7px dashed #132274',
    // borderRadius: theme.spacing(1),
    background: 'rgba(0, 0, 0, 0.1)',
    // padding: theme.spacing(1),
    pageBreakInside: 'auto',
    display: 'flow-root',
}));

const PaneIssueContainer = styled(Paper)(({ theme }) => ({
    // borderBottom: '0.7px dashed #132274',
    // borderRadius: theme.spacing(1),
    // background: 'rgba(0, 0, 0, 0.1)',
    // padding: theme.spacing(1),
    display: 'flow-root',
    cursor: 'pointer',
    '&:hover': {
        // bgcolor: 'common.white',
        border: '1px solid green',
    },
}));

const PortaPane: React.FC<any> = (props: any) => {
    // console.log('%c PortaPane props: %O', 'color: maroon;', props);
    let pane: any = (props.pane ? props.pane : null);
    // console.log('%c PortaPane pane: %O', 'color: maroon;', pane);
    let onCopyPane: any = (props.onCopyPane ? props.onCopyPane : null);
    // console.log('%c PortaPane onCopyPane: %O', 'color: maroon;', onCopyPane);
    let showPortalConfig: boolean = (props.showPortalConfig ? true : false);
    // console.log('%c PortaPane showPortalConfig: %O', 'color: maroon;', showPortalConfig);
    let configurePorta: boolean = (props.configurePorta ? true : false);
    // console.log('%c PortaPane configurePorta: %O', 'color: maroon;', configurePorta);
    let configureSolutions: boolean = (props.configureSolutions ? true : false);
    // console.log('%c PortaPane configureSolutions: %O', 'color: maroon;', configureSolutions);
    let includePaneWhenPrinting: boolean = true;
    if (pane && pane.includeInPrint !== undefined) {
        if (pane.includeInPrint === false) includePaneWhenPrinting = false;
    }
    let pgSettings: any = (pane && pane.grid ? pane.grid : null);
    let pgxs: number = (pgSettings && pgSettings.xs !== undefined && pgSettings.xs !== null ? pgSettings.xs : 12);
    let pgsm: number = (pgSettings && pgSettings.sm !== undefined && pgSettings.sm !== null ? pgSettings.sm : 12);
    let pgmd: number = (pgSettings && pgSettings.md !== undefined && pgSettings.md !== null ? pgSettings.md : 12);
    let pglg: number = (pgSettings && pgSettings.lg !== undefined && pgSettings.lg !== null ? pgSettings.lg : 12);
    let pgxl: number = (pgSettings && pgSettings.xl !== undefined && pgSettings.xl !== null ? pgSettings.xl : 12);

    let selectedPaneIdx: number = (props.selectedPaneIdx !== undefined ? props.selectedPaneIdx : -1);
    // console.log('%c PortaPane selectedPaneIdx: %O', 'color: maroon;', selectedPaneIdx);
    let paneIdx: number = (props.paneIdx !== undefined && props.paneIdx !== null ? props.paneIdx : -1);
    // console.log('%c PortaPane paneIdx: %O', 'color: maroon;', paneIdx);
    let paneIsSelectedPane: boolean = (selectedPaneIdx >= 0 && selectedPaneIdx === paneIdx ? true : false);
    // console.log('%c PortaPane paneIsSelectedPane: %O', 'color: maroon;', paneIsSelectedPane);
    let paneNeedsToBeSetup: boolean = (pane && pane.name ? false : true);
    // console.log('%c PortaPane paneNeedsToBeSetup: %O', 'color: maroon;', paneNeedsToBeSetup);

    const [value, setValue] = useState<number>(((paneNeedsToBeSetup) ? 1 : 0));

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const udicciHelpers = useUdicciHelpers();
    const theme = useTheme();
    // console.log('%c PortaPane theme: %O', 'color: maroon;', theme);
    const udicciClasses = udicciStyles(theme);
    let dm: any = getDesignMode();
    // console.log('%c PortaPane dm: %O', 'color: maroon;', dm);

    const udicciContext = useUdicciContext();
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    let { udicci } = udicciContext.state;
    // console.log('%c PortaPane udicci: %O', 'color: maroon;', udicci);

    let { selectedProfile, selectedPorta, platformIssueReport } = udicci;
    // console.log('%c PortaPane selectedProfile: %O', 'color: green;', selectedProfile);
    // console.log('%c PortaPane selectedPorta: %O', 'color: green;', selectedPorta);
    // console.log('%c PortaPane platformIssueReport: %O', 'color: green;', platformIssueReport);

    let paneContentDesign: any = udicciHelpers.getPaneContentDesign(pane);
    // console.log('%c paneContentDesign: %O', 'color: red;', paneContentDesign);

    let content: any[] = (paneContentDesign && paneContentDesign.content && paneContentDesign.content.length > 0 ? paneContentDesign.content : []);
    // console.log('%c PortaPane content: %O', 'color: red;', content);
    let paneHasContent: boolean = (content && content.length > 0 ? true : false);
    // console.log('%c PortaPane paneHasContent: %O', 'color: maroon;', paneHasContent);

    const [configurePane, setConfigurePane] = useState<any>((paneIsSelectedPane || paneNeedsToBeSetup || !paneHasContent) ? pane : null);
    // const [headerMenuShown, toggleHeaderMenu] = useState<boolean>((paneIsSelectedPane || paneNeedsToBeSetup ? true : false));
    const [showPaneExpandedSettings, setShowPaneExpandedSettings] = useState<boolean>((paneNeedsToBeSetup || !paneHasContent) ? true : false);
    const [uiState, flashUI] = useState<boolean>(false);
    const [paneMessage, setPaneMessage] = useState<string>('');
    // console.log('%c PortaPane paneMessage: %O', 'color: maroon;', paneMessage);
    // console.log('%c PortaPane configurePane: %O', 'color: maroon;', configurePane);
    // console.log('%c PortaPane showPaneExpandedSettings: %O', 'color: maroon;', showPaneExpandedSettings);

    const isPrinting = useMediaQuery("print");
    // console.log('%c isPrinting: %O', 'color: maroon;', isPrinting);
    // console.log('%c includePaneWhenPrinting: %O', 'color: maroon;', includePaneWhenPrinting);
    if (isPrinting && !includePaneWhenPrinting) return null;

    let profile = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    var profileSettings = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
    // console.log('%c Porta profileSettings: %O', 'color: maroon;', profileSettings);
    var portalSetting: any = (profileSettings && profileSettings.portal ? profileSettings.portal : null);
    // console.log('%c Porta portalSetting: %O', 'color: maroon;', portalSetting);
    var portalDesignSetting: any = (portalSetting && portalSetting.design ? portalSetting.design : null);
    // console.log('%c Porta portalDesignSetting: %O', 'color: maroon;', portalDesignSetting);

    let settingsJson: any = null;
    if (selectedPorta && selectedPorta.SettingsJson) {
        settingsJson = selectedPorta.SettingsJson;
    }
    // console.log('%c PortaPane settingsJson: %O', 'color: maroon;', settingsJson);
    let panes: any[] = (settingsJson && settingsJson.panes ? settingsJson.panes : []);
    // console.log('%c PortaPane panes: %O', 'color: maroon;', panes);

    let portaDesign: any = (settingsJson && settingsJson.design ? settingsJson.design : null);
    // console.log('%c PortaPane portaDesign: %O', 'color: blue;', portaDesign);
    let portaDesignSettings: any = (portaDesign ? portaDesign : portalDesignSetting);
    // console.log('%c PortaPane portaDesignSettings: %O', 'color: blue;', portaDesignSettings);
    let portaPaneInner: any = (portaDesignSettings && portaDesignSettings.inner ? portaDesignSettings.inner : null);
    // console.log('%c PortaPane portaPaneInner: %O', 'color: blue;', portaPaneInner);

    let accessSettings: any = (pane && pane.access ? pane.access : null);
    // console.log('%c PortaPane accessSettings: %O', 'color: maroon;', accessSettings);
    let paneDesignSettings: any = (pane && pane.design ? pane.design : null);
    // console.log('%c PortaPane paneDesignSettings: %O', 'color: maroon;', paneDesignSettings);
    // let accessRoles: any = (accessSettings && accessSettings.roles ? accessSettings.roles : null);
    // console.log('%c PortaPane accessRoles: %O', 'color: maroon;', accessRoles);
    let paneInner: any = (paneDesignSettings && paneDesignSettings.inner ? paneDesignSettings.inner : portaPaneInner);
    // console.log('%c PortaPane paneInner: %O', 'color: maroon;', paneInner);

    let paneInnerBackground: any = (paneInner && paneInner.backgroundColor ? paneInner.backgroundColor : null);
    let paneInnerBorderColor: any = (paneInner && paneInner.borderColor ? paneInner.borderColor : null);
    let paneInnerBorderStyle: any = (paneInner && paneInner.borderStyle ? paneInner.borderStyle : null);
    let paneInnerBorderRadius: any = (paneInner && paneInner.borderRadius ? paneInner.borderRadius : null);
    let paneInnerBorderWidth: any = (paneInner && paneInner.borderWidth !== undefined ? paneInner.borderWidth : 1);
    let paneInnerMargin: any = (paneInner && paneInner.margin !== undefined ? paneInner.margin : 1);
    let paneInnerPadding: any = (paneInner && paneInner.padding !== undefined ? paneInner.padding : 1);

    const configurePaneContentDesign = () => {
        // console.log('%c PortaPane configurePaneContentDesign configurePane: %O', 'color: maroon;', configurePane);
        // console.log('%c PortaPane configurePaneContentDesign paneIdx: %O', 'color: maroon;', paneIdx);
        // console.log('%c PortaPane configurePaneContentDesign pane: %O', 'color: maroon;', pane);
        setConfigurePane(pane);
        if (props && props.configurePane) props.configurePane(paneIdx);
    };

    const closePaneContentDesign = () => {
        // console.log('%c PortaPane closePaneContentDesign configurePane: %O', 'color: maroon;', configurePane);
        setConfigurePane(null);
        setShowPaneExpandedSettings(false);
        if (props && props.configurePane) props.configurePane(-1);
    };

    // const updatePaneContentDesign = (updatedContent: any) => {
    //     // console.log('%c PortaPane updatePaneContentDesign configurePane: %O', 'color: maroon;', configurePane);
    //     // console.log('%c PortaPane updatePaneContentDesign updatedContent: %O', 'color: maroon;', updatedContent);
    //     configurePane.contentDesign = updatedContent;
    //     setConfigurePane(configurePane);
    //     flashUI(!uiState);
    // };

    const updatePaneContent = (updatedContent: any) => {
        // console.log('%c PortaPane updatePaneContent configurePane: %O', 'color: maroon;', configurePane);
        // console.log('%c PortaPane updatePaneContent updatedContent: %O', 'color: maroon;', updatedContent);

        configurePane.content = updatedContent;
        setConfigurePane(configurePane);
        flashUI(!uiState);
    };

    const updatePaneName = (evt: any) => {
        // console.log('%c PortaPane updatePaneName pane: %O', 'color: maroon;', pane);
        // console.log('%c PortaPane updatePaneName evt: %O', 'color: maroon;', evt);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c PortaPane updatePaneName newValue: %O', 'color: maroon;', newValue);
        configurePane.name = newValue;
        setConfigurePane(configurePane);
        flashUI(!uiState);
    };

    const updatePaneSetting = (evt: any, settingName: string = '', subSettingName: string = '') => {
        // console.log('%c PortaPane updatePaneSetting selectedPorta: %O', 'color: maroon;', selectedPorta);
        // console.log('%c PortaPane updatePaneSetting settingName: %O', 'color: maroon;', settingName);
        // console.log('%c PortaPane updatePaneSetting subSettingName: %O', 'color: maroon;', subSettingName);
        // console.log('%c PortaPane updatePaneSetting evt: %O', 'color: maroon;', evt);

        if (settingName === 'access') {
            // evt is not a standard event, but an onChange response from the AccessSettingsAdmin component
            // if (props && props.onChange) props.onChange({ [event.target.name]: event.target.checked });
            // event.target.name === Role Id
            // event.target.checked === Updated Toggle State (indicating pane is only viewable by those that are toggled into the on position)
            if (evt) {
                if (subSettingName) {
                    if (!configurePane[settingName]) configurePane[settingName]= {};
                    let curSettings: any = (configurePane[settingName][subSettingName] ? configurePane[settingName][subSettingName] : {});
                    Object.assign(curSettings, evt);
                    configurePane[settingName][subSettingName] = curSettings;
                } else {
                    let curSettings: any = (configurePane[settingName] ? configurePane[settingName] : {});
                    Object.assign(curSettings, evt);
                    configurePane[settingName] = curSettings;
                }
            }
        } else if (settingName === 'design') {
            // evt is not a standard event, but an onChange response from the DesignSettingsAdmin component
            // if (props && props.onChange) props.onChange({ [settingName]: [value] });
            if (evt) {
                if (subSettingName) {
                    if (!configurePane[settingName]) configurePane[settingName]= {};
                    let curSettings: any = (configurePane[settingName][subSettingName] ? configurePane[settingName][subSettingName] : {});
                    Object.assign(curSettings, evt);
                    configurePane[settingName][subSettingName] = curSettings;
                } else {
                    let curSettings: any = (configurePane[settingName] ? configurePane[settingName] : {});
                    Object.assign(curSettings, evt);
                    configurePane[settingName] = curSettings;
                }
            }
        } else {
            let trgt = evt.target;
            let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
            // console.log('%c PortaPane updatePaneName newValue: %O', 'color: maroon;', newValue);
    
            if (subSettingName) {
                if (!configurePane[settingName]) configurePane[settingName]= {};
                configurePane[settingName][subSettingName] = newValue;
            } else {
                configurePane[settingName] = newValue;
            }
        }

        setConfigurePane(configurePane);
        flashUI(!uiState);
    };

    const savePaneSettings = () => {
        // console.log('%c PortaPane savePaneSettings configurePane: %O', 'color: maroon;', configurePane);
        // console.log('%c PortaPane savePaneSettings selectedPorta: %O', 'color: maroon;', selectedPorta);

        let settings: any = null;
        if (selectedPorta && selectedPorta.SettingsJson) {
            settings = selectedPorta.SettingsJson;
        } else {
            settings = {};
        }
        // console.log('%c PortaPane savePaneSettings settings: %O', 'color: maroon;', settings);

        let portaPanes: any[] = (settings.panes ? settings.panes : []);
        // console.log('%c PPortaPaneorta portaPanes: %O', 'color: maroon;', portaPanes);
        let fnd: boolean = false;
        forEach(portaPanes, function(p: any, i: number) {
            // console.log('%c p: %O', 'color: purple;', p);
            if (configurePane !== null && p.name === pane.name) {
                portaPanes[i] = configurePane;
                fnd = true;
            }
        });
        // console.log('%c PortaPane savePaneSettings fnd: %O', 'color: maroon;', fnd);

        // console.log('%c PortaPane savePaneSettings settings: %O', 'color: blue;', settings);
        if (fnd && selectedPorta) {
            settings.panes = portaPanes;
            selectedPorta.SettingsJson = settings;
            selectedPorta.IsDirty = true;
            udicci.updatePorta(selectedPorta);
            // setConfigurePane(null);
            // if (props && props.configurePane) props.configurePane(-1);
            // flashUI(!uiState);
        }
    };

    const overridePaneDesign = (evt: any) => {
        let settings: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : {});
        let portaPanes: any[] = (settings.panes ? settings.panes : []);
        let fnd: boolean = false;
        forEach(portaPanes, function(p: any, i: number) {
            // console.log('%c p: %O', 'color: purple;', p);
            if (pane !== null && p.name === pane.name) {
                portaPanes[i].design = { inner: {}, outer: {} };
                fnd = true;
            }
        });
        if (fnd && selectedPorta) {
            settings.panes = portaPanes;
            selectedPorta.SettingsJson = settings;
            selectedPorta.IsDirty = true;
            udicci.updatePorta(selectedPorta);
        }
    };

    const removePaneDesignOverride = (evt: any) => {
        let settings: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : {});
        let portaPanes: any[] = (settings.panes ? settings.panes : []);
        let fnd: boolean = false;
        forEach(portaPanes, function(p: any, i: number) {
            // console.log('%c p: %O', 'color: purple;', p);
            if (pane !== null && p.name === pane.name) {
                portaPanes[i].design = null;
                fnd = true;
            }
        });
        if (fnd && selectedPorta) {
            settings.panes = portaPanes;
            selectedPorta.SettingsJson = settings;
            selectedPorta.IsDirty = true;
            udicci.updatePorta(selectedPorta);
        }
    };

    const makePaneDesignPortaOverride = (evt: any) => {
        let settings: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : {});
        let portaPanes: any[] = (settings.panes ? settings.panes : []);
        let fnd: boolean = false;
        forEach(portaPanes, function(p: any, i: number) {
            // console.log('%c p: %O', 'color: purple;', p);
            if (pane !== null && p.name === pane.name) {
                settings.design = portaPanes[i].design;
                fnd = true;
            }
        });
        if (fnd && selectedPorta) {
            settings.panes = portaPanes;
            selectedPorta.SettingsJson = settings;
            selectedPorta.IsDirty = true;
            udicci.updatePorta(selectedPorta);
        }
    };

    const addPanePlugin = () => {
        // console.log('%c addPanePlugin paneContentDesign: %O', 'color: maroon;', paneContentDesign);
        // console.log('%c addPanePlugin content: %O', 'color: maroon;', content);

        if (!content) content = [];
        let currentNewPluginFound: any = find(content, (c: any) => { return (c.plugin && !c.plugin.id ? true : false); });
        // console.log('%c addPanePlugin currentNewPluginFound: %O', 'color: maroon;', currentNewPluginFound);
        let newPaneIndex: number = -1;
        let newPanePlugin: any = null;
        if (!currentNewPluginFound) {
            newPanePlugin = {
                data: { default: null },
                id: udicci.generateUID(),
                plugin: { id: '', version: null },
                size: 12,
                visible: true,
                wrapper: null
            };
            content.push(newPanePlugin);
            newPaneIndex = content.length - 1;
            // console.log('%c addPanePlugin content: %O', 'color: maroon;', content);
            updatePaneContent(content);
        }
        // console.log('%c addPanePlugin paneIdx: %O', 'color: red;', paneIdx);
        setConfigurePane(pane);
        if (props && props.configurePane) props.configurePane(paneIdx);
        setShowPaneExpandedSettings(true);
        flashUI(!uiState);
    };

    const removePane = () => {
        let settings: any = null;
        if (selectedPorta && selectedPorta.SettingsJson) {
            settings = selectedPorta.SettingsJson;
        } else {
            settings = {};
        }
        // console.log('%c PortaPane removePane settings: %O', 'color: maroon;', settings);

        let portaPanes: any[] = (settings.panes ? settings.panes : []);
        // console.log('%c PPortaPaneorta portaPanes: %O', 'color: maroon;', portaPanes);
        let updatedPortaPanes: any[] = [];
        forEach(portaPanes, function(p: any, i: number) {
            // console.log('%c p: %O', 'color: purple;', p);
            if (pane === null || (pane !== null && p.name !== pane.name)) {
                updatedPortaPanes.push(p);
            }
        });
        // console.log('%c PortaPane removePane updatedPortaPanes: %O', 'color: maroon;', updatedPortaPanes);

        settings.panes = updatedPortaPanes;

        // console.log('%c PortaPane removePane settings: %O', 'color: blue;', settings);
        if (selectedPorta) {
            selectedPorta.SettingsJson = settings;
            selectedPorta.IsDirty = true;
            udicci.updatePorta(selectedPorta);
            if (props && props.configurePane) props.configurePane(-1);
            flashUI(!uiState);
        }
    };

    const movePane = (direction: string = 'down') => {
        let settings: any = null;
        if (selectedPorta && selectedPorta.SettingsJson) {
            settings = selectedPorta.SettingsJson;
        } else {
            settings = {};
        }
        // console.log('%c PortaPane movePane settings: %O', 'color: maroon;', settings);

        let portaPanes: any[] = (settings.panes ? settings.panes : []);
        // console.log('%c PortaPane movePane portaPanes: %O', 'color: maroon;', portaPanes);

        // console.log('%c PortaPane movePane paneIdx: %O', 'color: maroon;', paneIdx);
        let targetPaneIndex = paneIdx;
        if (direction.toString().toLowerCase() === 'up') targetPaneIndex--;
        if (direction.toString().toLowerCase() === 'down') targetPaneIndex++;

        portaPanes = udicciHelpers.swapArrayElements(portaPanes, paneIdx, targetPaneIndex);
        // console.log('%c PortaPane movePane portaPanes: %O', 'color: maroon;', portaPanes);

        settings.panes = portaPanes;

        // console.log('%c PortaPane movePane settings: %O', 'color: blue;', settings);
        if (selectedPorta) {
            selectedPorta.SettingsJson = settings;
            selectedPorta.IsDirty = true;
            udicci.updatePorta(selectedPorta);
            if (props && props.configurePane) props.configurePane(targetPaneIndex);
        }
    };

    const copyPane = () => {
        // console.log('%c PortaPane copyPane pane: %O', 'color: blue;', pane);

        let paneCopiedAsString: string = '';
        if (pane) {
            let paneToCopy: any = {};
            Object.assign(paneToCopy, pane);
            // console.log('%c PortaPane copyPane paneToCopy: %O', 'color: blue;', paneToCopy);
            try {
                paneCopiedAsString = JSON.stringify(paneToCopy);
            } catch (err: any) {

            }
        }
        if (paneCopiedAsString.length > 0) {
            sessionStorage.setItem('udicci.copied.pane', paneCopiedAsString);
            setPaneMessage('Pane Copied.  Select the "Paste" icon to Add the Pane to the Selected Porta.  You can change to a different Porta before you paste the Pane.');
            onCopyPane();
        }
    };

    const handleClosePaneMessage = (event: React.SyntheticEvent | Event, reason?: string) => {
        // if (reason === 'clickaway') return;
        setPaneMessage('');
    };

    const clickPaneContentInDesignMode = (evt: any) => {
        // console.log('%c clickPaneContentInDesignMode evt: %O', 'color: grey;', evt);
        // console.log('%c clickPaneContentInDesignMode dm: %O', 'color: grey;', dm);
        if (dm && dm === 'on') {
            if (evt) {
                if (evt.preventDefault) evt.preventDefault();
                if (evt.stopPropagation) evt.stopPropagation();
            }
            setConfigurePane(pane);
            if (props && props.configurePane) props.configurePane(paneIdx);
            flashUI(!uiState);
        }
    };

    const togglePaneAsIssueReportPane = (evt: any) => {
        // console.log('%c togglePaneAsIssueReportPane evt: %O', 'color: maroon;', evt);
        // console.log('%c togglePaneAsIssueReportPane pane: %O', 'color: maroon;', pane);
        // console.log('%c togglePaneAsIssueReportPane platformIssueReport: %O', 'color: maroon;', platformIssueReport);
        if (!platformIssueReport) return false;

        evt.stopPropagation();

        let updatedIssueReport: any = {};
        Object.assign(updatedIssueReport, platformIssueReport);
        // console.log('%c togglePaneAsIssueReportPane updatedIssueReport: %O', 'color: maroon;', updatedIssueReport);

        if (updatedIssueReport.pane && updatedIssueReport.pane.idx === paneIdx) {
            // already selected ... deselect it
            updatedIssueReport.pane = null;
        } else {
            if (!updatedIssueReport.pane) updatedIssueReport.pane = {};
            updatedIssueReport.pane.idx = paneIdx;
            updatedIssueReport.pane.name = (pane.name ? pane.name : '');
            if (pane.content && pane.content.length > 0) {
                let panePlugins: any[] = [];
                forEach(pane.content, (contentItem: any, idx: number) => {
                    // console.log('%c contentItem: %O', 'color: maroon;', contentItem);
                    let contentPlugin: any = (contentItem.plugin ? contentItem.plugin : null);
                    // console.log('%c contentPlugin: %O', 'color: maroon;', contentPlugin);
                    if (contentPlugin) {
                        let cplgn: UdicciPlugin | undefined = find(udicciPluginComponents, (up: UdicciPlugin) => { return up.id === contentPlugin.id; });
                        // console.log('%c Content Item Plugin: %O', 'color: purple;', cplgn);
                        if (cplgn) {
                            panePlugins.push({
                                id: cplgn.id,
                                title: cplgn.title,
                                version: cplgn.version,
                                description: cplgn.description,
                                hideInMenu: cplgn.hideInMenu,
                            });
                        }
                    }
                });
                // console.log('%c panePlugins: %O', 'color: maroon;', panePlugins);
                updatedIssueReport.pane.plugins = panePlugins;
            } else {
                if (pane.displayComponent) updatedIssueReport.pane.displayComponent = pane.displayComponent;
                updatedIssueReport.pane.plugins = 'There are no plugins setup, Pane will display ".Me" plugin by default.';
            }
        }

        udicci.setPlatformIssueReport(updatedIssueReport);
    };

    // console.log('%c configurePane: %O', 'color: blue; font-weight: bold;', configurePane);
    // console.log('%c paneIsSelectedPane: %O', 'color: blue; font-weight: bold;', paneIsSelectedPane);

    let paneContainerStyleOverride: any = undefined;
    let paneHeaderElement: any = null;
    let copyPastePaneIconButton: any = null;
    let removePaneIconButton: any = null;
    if (dm && dm === 'on' && !configureSolutions) {
        let configurePaneIconButton: any = null;
        let configurePaneSettingsIconButton: any = null;
        let addPluginButton: any = null;
        let iconSaveButton: any = null;

        const rightAlignedButtonTheme: Theme = udicciHelpers.getUdicciThemeTemplate('MuiIconButton', null);
        // console.log('%c rightAlignedButtonTheme: %O', 'color: blue; font-weight: bold;', rightAlignedButtonTheme);

        if (configurePane && paneIsSelectedPane) {
            configurePaneIconButton = (
                <IconButton color="default" aria-label="Close Pane Configuration" size="small" onClick={(evt: any) => closePaneContentDesign()} title={'Close Configuration for Pane: ' + pane.name}>
                    <CloseSettingsIcon />
                </IconButton>
            );

            if (showPaneExpandedSettings) {
                configurePaneSettingsIconButton = (        
                    <IconButton color="default" aria-label="Hide More Pane Settings" size="small" onClick={(evt: any) => setShowPaneExpandedSettings(false)} title={'Hide Settings for Pane: ' + pane.name}>
                        <Icon>unfold_less</Icon>
                    </IconButton>
                );
            } else {
                configurePaneSettingsIconButton = (        
                    <IconButton color="default" aria-label="Show More Pane Settings" size="small" onClick={(evt: any) => setShowPaneExpandedSettings(true)} title={'Show Settings for Pane: ' + pane.name}>
                        <Icon>unfold_more</Icon>
                    </IconButton>
                );
            }

            addPluginButton = (
                <IconButton color="success" sx={{ float: 'right', marginLeft: '8px' }} aria-label="Add Pane Plugin" size="small" disabled={(pane && pane.name && pane.name.length > 2 ? false : true)}
                            onClick={(evt: any) => addPanePlugin()} title={'Add a Plugin to Pane: ' + pane.name}>
                    <Icon>add</Icon>
                </IconButton>
            );

            iconSaveButton = (
                <IconButton color="primary" sx={{ float: 'right', marginLeft: '8px' }} aria-label="Apply Pane Changes to Porta" size="small" disabled={(pane && pane.name && pane.name.length > 2 ? false : true)}
                            onClick={(evt: any) => savePaneSettings()} title={'Apply Pane Changes to Porta: ' + pane.name}>
                    <SaveIcon />
                </IconButton>
            );
        } else {
            configurePaneIconButton = (
                <IconButton color="default" aria-label="Configure Pane" size="small" onClick={(evt: any) => configurePaneContentDesign()} title={'Configure Pane: ' + pane.name}>
                    <SettingsIcon />
                </IconButton>
            );
        }

        let paneToPaste: any = udicciHelpers.getPastedPane();
        // console.log('%c paneToPaste: %O', 'color: blue; font-weight: bold;', paneToPaste);
        copyPastePaneIconButton = (
            <IconButton color="success" aria-label="Copy Pane" size="small" onClick={(evt: any) => copyPane()} title={'Copy Pane: ' + pane.name}>
                <Icon>content_copy</Icon>
            </IconButton>
        );

        let paneMessageElement: any = null;
        if (paneMessage.length > 0) {
            paneMessageElement = (
                <Snackbar open={true} autoHideDuration={7777} onClose={handleClosePaneMessage} message={paneMessage} />
            );
        }

        let paneNameElement: any = null;
        let paneHeaderTitleElement: any = null;
        let paneHeaderContentElement: any = null;
        let moveUpIcon: any = null;
        let moveDownIcon: any = null;
        if (configurePane && paneIsSelectedPane) {
            let moveUpIconProps = {
                onClick: (evt: any) => movePane('up'),
                disabled: ((paneIdx > 0 && pane && pane.name && pane.name.length > 2) ? false : true)
            };
            moveUpIcon = (
                <IconButton {...moveUpIconProps} size="small" color="default" title={'Move ' + pane.name + ' Up'}>
                    <MoveUpIcon />
                </IconButton>
            );

            let moveDownIconProps = {
                onClick: (evt: any) => movePane('down'),
                disabled: ((paneIdx < panes.length - 1 && pane && pane.name && pane.name.length > 2) ? false : true)
            };
            moveDownIcon = (
                <IconButton {...moveDownIconProps} size="small" color="default" title={'Move ' + pane.name + ' Down'}>
                    <MoveDownIcon />
                </IconButton>
            );

            paneContainerStyleOverride = {
                display: 'flow-root',
                borderWidth: '4px',
                borderStyle: 'dotted',
                borderColor: 'orange',
            };

            let xsBreakpointSelections: any[] = udicciHelpers.getCommonSelectionListItems('grid.breakpoints', 'porta.pane.xs');
            let smBreakpointSelections: any[] = udicciHelpers.getCommonSelectionListItems('grid.breakpoints', 'porta.pane.sm');
            let mdBreakpointSelections: any[] = udicciHelpers.getCommonSelectionListItems('grid.breakpoints', 'porta.pane.md');
            let lgBreakpointSelections: any[] = udicciHelpers.getCommonSelectionListItems('grid.breakpoints', 'porta.pane.lg');
            let xlBreakpointSelections: any[] = udicciHelpers.getCommonSelectionListItems('grid.breakpoints', 'porta.pane.xl');
            let paneFormElement: any = (
                <div style={{ padding: '8px', display: 'contents' }}>
                    <div style={{ padding: '8px', display: 'flow-root' }}>
                        <FormControl>
                            <Typography variant="caption">
                                Pane Name
                            </Typography>
                            <Input id="pane.name"
                                   type={'text'}
                                   value={(pane && pane.name ? pane.name : '')}
                                   onChange={updatePaneName} />
                        </FormControl>
                    </div>
                    <div style={{ padding: '8px', display: 'flow-root' }}>
                        <FormControlLabel disableTypography={true}
                            label={(
                                <Typography variant="caption">
                                    Include In Print
                                </Typography>
                            )}
                            control={<Checkbox checked={(pane && pane.includeInPrint ? true : false)}
                                id="pane.includeInPrint"
                                onChange={(evt: any) => updatePaneSetting(evt, 'includeInPrint', '')}
                             />} />

                        <FormControlLabel disableTypography={true}
                            label={(
                                <Typography variant="caption">
                                    Remove Containers
                                </Typography>
                            )}
                            control={<Checkbox checked={(pane && pane.removeContainers ? true : false)}
                                        id="pane.removeContainers"
                                        onChange={(evt: any) => updatePaneSetting(evt, 'removeContainers', '')}
                                     />} 
                            />
                    </div>
                    <div style={{ display: 'flow-root' }}>
                        <div style={{ padding: '8px', float: 'left' }}>
                            <div>
                                <InputLabel>
                                    <Typography variant="caption">xs</Typography>
                                </InputLabel>
                                <Select value={pgxs} onChange={(evt: any) => updatePaneSetting(evt, 'grid', 'xs')}>
                                    {xsBreakpointSelections}
                                </Select>
                            </div>
                        </div>
                        <div style={{ padding: '8px', float: 'left' }}>
                            <div>
                                <InputLabel>
                                    <Typography variant="caption">sm</Typography>
                                </InputLabel>
                                <Select value={pgsm} onChange={(evt: any) => updatePaneSetting(evt, 'grid', 'sm')}>
                                    {smBreakpointSelections}
                                </Select>
                            </div>
                        </div>
                        <div style={{ padding: '8px', float: 'left' }}>
                            <div>
                                <InputLabel>
                                    <Typography variant="caption">md</Typography>
                                </InputLabel>
                                <Select value={pgmd} onChange={(evt: any) => updatePaneSetting(evt, 'grid', 'md')}>
                                    {mdBreakpointSelections}
                                </Select>
                            </div>
                        </div>
                        <div style={{ padding: '8px', float: 'left' }}>
                            <div>
                                <InputLabel>
                                    <Typography variant="caption">lg</Typography>
                                </InputLabel>
                                <Select value={pglg} onChange={(evt: any) => updatePaneSetting(evt, 'grid', 'lg')}>
                                    {lgBreakpointSelections}
                                </Select>
                            </div>
                        </div>
                        <div style={{ padding: '8px', float: 'left' }}>
                            <div>
                                <InputLabel>
                                    <Typography variant="caption">xl</Typography>
                                </InputLabel>
                                <Select value={pgxl} onChange={(evt: any) => updatePaneSetting(evt, 'grid', 'xl')}>
                                    {xlBreakpointSelections}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
            );

            let makePortaDesignButtonElement: any = null;
            let designButtonElement: any = null;
            let designPaneElement: any = null;
            if (value === 3) {
                if (paneDesignSettings) {
                    makePortaDesignButtonElement = (
                        <Button fullWidth onClick={makePaneDesignPortaOverride} color="info" variant="contained">
                            Make this the Porta Design
                        </Button>
                    );
                    designButtonElement = (
                        <Button onClick={removePaneDesignOverride} color="secondary" variant="contained">
                            Remove Pane Design Override
                        </Button>
                    );
                    designPaneElement = (
                        <DesignSettingsAdmin currentSettings={paneDesignSettings} onChange={(update: any, subSettingName: string = '') => updatePaneSetting(update, 'design', subSettingName)} />
                    );
                } else {
                    designButtonElement = (
                        <Button onClick={overridePaneDesign} color="primary" variant="contained">
                            Override Pane Design
                        </Button>
                    );
                }
            }

            let paneDesignSettingsTabElement: any = null;
            paneDesignSettingsTabElement = (
                <Tab label={<Typography variant="clickableSubTitle2">Design Settings</Typography>} id="pane-config-tab-3" />
            );

            paneNameElement = (
                <Fragment>
                    <Typography component="span" variant="caption">Configure Pane </Typography>
                    <Typography component="span" variant="subtitle2"> {pane.name}</Typography>
                </Fragment>
            );

            if (showPaneExpandedSettings) {
                let tabSettings: any = {
                    allowScrollButtonsMobile: true,
                    onChange: handleChange,
                    ariaLabel: 'Pane Configuration Settings',
                    value: (value >= 0 ? value : false),
                    variant: 'fullWidth',
                    textColor: 'primary',
                };
                let paneSettingsTabElement: any = (
                    <Box sx={{ borderColor: 'divider' }}>
                        <Tabs {...tabSettings}>
                            <Tab label={<Typography variant="clickableSubTitle2">Help</Typography>} id="pane-config-tab-0" />
                            <Tab label={<Typography variant="clickableSubTitle2">Pane Setup</Typography>} id="pane-config-tab-1" />
                            <Tab label={<Typography variant="clickableSubTitle2">Access Settings</Typography>} id="pane-config-tab-2" />
                            {paneDesignSettingsTabElement}
                        </Tabs>
                    </Box>
                );

                paneHeaderContentElement = (
                    <Box sx={{ width: '100%', clear: 'both', background: 'rgba(0, 0, 0, 0.07)', margin: '8px' }}>
                        {paneSettingsTabElement}
                        <Divider variant="fullWidth" sx={{ color: 'lime' }} flexItem={true} />
                        <TabPanel value={value} index={0}>
                            <Typography variant="caption">
                                Add pane design plugins below, or click on a plugin to manage it.
                            </Typography>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {paneFormElement}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <AccessSettingsAdmin allowRoleMenuOverride={false} 
                                                currentSettings={accessSettings} 
                                                onChangeAccessSetting={(update: any, subSettingName: string = '') => updatePaneSetting(update, 'access', subSettingName)}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            {designPaneElement}
                            {makePortaDesignButtonElement}
                            {designButtonElement}
                        </TabPanel>
                    </Box>
                );
            }
        } else {
            paneNameElement = (
                <Fragment>
                    <Typography component="span" variant="caption">Configure Pane </Typography>
                    <Typography component="span" variant="subtitle2"> {pane.name}</Typography>
                </Fragment>
            );
        }

        removePaneIconButton = (
            <IconButton color="warning" aria-label="Remove Pane" size="small" onClick={(evt: any) => removePane()} title={'Remove this Pane: ' + pane.name}>
                <RemovePaneIcon />
            </IconButton>
        );

        let otherIconButtons: any = null;
        if (showPaneExpandedSettings) {
            otherIconButtons = (
                <Fragment>
                    {copyPastePaneIconButton}
                    {removePaneIconButton}
                    {moveUpIcon}
                    {moveDownIcon}
                </Fragment>
            );
        }

        let boxSx: any = {
            display: 'flow-root',
            float: 'right',
            // padding: '4px',
            margin: '8px',
            marginTop: '0px',
            borderRadius: '16px',
            border: '1px solid #555',
            background: 'rgb(150 150 150 / 30%)',
        };

        paneHeaderElement = (
            <PaneContainer>
                <Box sx={{ padding: '8px' }}>
                    <Box sx={boxSx}>
                        <ThemeProvider theme={rightAlignedButtonTheme}>
                            {configurePaneIconButton}
                            {iconSaveButton}
                            {addPluginButton}
                            {otherIconButtons}
                            {configurePaneSettingsIconButton}
                        </ThemeProvider>
                    </Box>
                    {paneNameElement}
                    {paneHeaderTitleElement}
                </Box>
                {paneHeaderContentElement}
                {paneMessageElement}
            </PaneContainer>
        );
    }

    if (!paneContainerStyleOverride) paneContainerStyleOverride = { display: 'flow-root' };
    if (paneInnerBackground || paneInnerBorderColor || paneInnerBorderStyle || paneInnerBorderRadius) {
        if (paneInnerBackground) {
            let bgColor: string = `rgba(${ paneInnerBackground.r }, ${ paneInnerBackground.g }, ${ paneInnerBackground.b }, ${ paneInnerBackground.a })`;
            paneContainerStyleOverride.backgroundColor = bgColor;
        }
        if (paneInnerBorderColor) {
            let borderColor: string = `rgba(${ paneInnerBorderColor.r }, ${ paneInnerBorderColor.g }, ${ paneInnerBorderColor.b }, ${ paneInnerBorderColor.a })`;
            paneContainerStyleOverride.borderColor = borderColor;
        }
        if (paneInnerBorderStyle) {
            paneContainerStyleOverride.borderStyle = paneInnerBorderStyle;
        }
        if (paneInnerBorderRadius) {
            paneContainerStyleOverride.borderRadius = paneInnerBorderRadius;
        }
    }
    paneContainerStyleOverride.borderWidth = paneInnerBorderWidth + 'px';
    paneContainerStyleOverride.margin = paneInnerMargin + 'px';
    paneContainerStyleOverride.padding = paneInnerPadding + 'px';
    // console.log('%c PortaPane paneContainerStyleOverride: %O', 'color: maroon;', paneContainerStyleOverride);

    let paneContentElement: any = null;
    if ((selectedPaneIdx < 0 || paneIsSelectedPane) && !configureSolutions) {
        if (!configurePorta) {
            let paneDesignerProps: any = {};
            Object.assign(paneDesignerProps, props);
            paneDesignerProps.pane = pane;
            paneDesignerProps.configure = configurePane;
            // paneDesignerProps.onChange = updatePaneContentDesign;
            paneDesignerProps.onChangeContent = updatePaneContent;
            paneDesignerProps.onClickPaneContentInDesignMode = clickPaneContentInDesignMode;

            // console.log('%c pane: %O', 'color: grey;', pane);
            let contentPlugins: any[] = (paneContentDesign && paneContentDesign.content && paneContentDesign.content.length > 0 ? paneContentDesign.content : []);
            // console.log('%c contentPlugins: %O', 'color: grey;', contentPlugins);
            let usablePlugins: number = 0;
            let hasNewPlugin: boolean = false;
            forEach(contentPlugins, function(p: any, i: number) {
                // console.log('%c p: %O', 'color: purple;', p);

                if (p.plugin.id) {
                    let udicciPlugin: UdicciPlugin | undefined = find(udicciPluginComponents, (up: UdicciPlugin) => { return up.id === p.plugin.id; });
                    // console.log('%c udicciPlugin: %O', 'color: purple;', udicciPlugin);
                    if (udicciPlugin) usablePlugins++;
                } else {
                    hasNewPlugin = true;
                }
            });
            // console.log('%c usablePlugins: %O', 'color: purple;', usablePlugins);
            // console.log('%c hasNewPlugin: %O', 'color: purple;', hasNewPlugin);

            let paneDesignerElement: any = (<PaneDesigner {...paneDesignerProps} />);
            // console.log('%c paneDesignerElement: %O', 'color: grey;', paneDesignerElement);
            if ((dm && dm === 'on')) {
                if ((hasNewPlugin || usablePlugins > 0) || paneIsSelectedPane) {
                    if (pane.removeContainers) {
                        paneContentElement = paneDesignerElement;
                    } else {
                        paneContentElement = (
                            <PaneContainer style={paneContainerStyleOverride}>
                                {paneDesignerElement}
                            </PaneContainer>
                        );
                    }
                } else {
                    paneContentElement = (
                        <PaneContainer style={paneContainerStyleOverride}>
                            <Typography component="span" variant="clickableSubTitle2">
                                There are no plugins assigned to this pane yet.
                            </Typography>
                        </PaneContainer>
                    );
                }
            } else if (hasNewPlugin || usablePlugins > 0) {
                if (pane.removeContainers) {
                    paneContentElement = paneDesignerElement;
                } else {
                    paneContentElement = (
                        <PaneContainer style={paneContainerStyleOverride}>
                            {paneDesignerElement}
                        </PaneContainer>
                    );
                }
            }
        }
    }

    let portaPaneElement: any = null;
    // console.log('%c paneHeaderElement: %O', 'color: grey;', paneHeaderElement);
    // console.log('%c paneContentElement: %O', 'color: grey;', paneContentElement);
    if ((paneHeaderElement || paneContentElement) && !configureSolutions) {
        if (paneHeaderElement) {
            portaPaneElement = (
                <div>
                    {paneHeaderElement}
                    {paneContentElement}
                </div>
            );
        } else {
            portaPaneElement = (<Fragment>{paneContentElement}</Fragment>);
        }
    }

    if (platformIssueReport) {
        let overrideSx: any = {};
        // console.log('%c paneContentElement: %O', 'color: grey;', paneContentElement);
        if (platformIssueReport.pane && platformIssueReport.pane.idx.toString() === paneIdx.toString()) {
            overrideSx.border = '2px solid lime';
            overrideSx['&:hover'] = {};
            overrideSx['&:hover'].border = '2px solid orange';
        }
        return (<PaneIssueContainer onClick={togglePaneAsIssueReportPane} sx={overrideSx}>{portaPaneElement}</PaneIssueContainer>);
    } else {
        return (<Fragment>{portaPaneElement}</Fragment>);
    }
}

export default PortaPane;
