
import React, { Fragment, useState } from "react"

import { useTheme } from '@mui/material/styles';

// import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker } from '@mui/x-date-pickers';  // MuiPickersUtilsProvider, 
import { format, parseISO } from 'date-fns';

import { Box, Typography, Icon, IconButton, FormControlLabel, InputLabel, Checkbox, TextField, Tooltip } from '@mui/material';

import { UdicciRecord } from 'src/classes/udicci-record';
import { formatStringForDisplay } from 'src/context/udicci-context';

import { UnfoldMore as UnfoldMoreIcon, UnfoldLess as UnfoldLessIcon, } from '@mui/icons-material';

import useLongText from 'src/hooks/useLongText';
import useUdicciConstants from "src/hooks/useUdicciConstants";
import useUdicciMediator from "src/hooks/useUdicciMediator";

import { udicciStyles } from 'src/theme/shared-styles';

import FieldDisplay from "src/components/field-display";

export default function useUdicciRecordField( record: UdicciRecord, field: any, changeRecordValue: any, settings: any = null ) {
    // console.log('%c useUdicciRecordField record: %O', 'color: red;', record);
    // console.log('%c useUdicciRecordField field %s: %O', 'color: red;', field.Name, field);
    // console.log('%c useUdicciRecordField changeRecordValue: %O', 'color: red;', changeRecordValue);
    // console.log('%c useUdicciRecordField settings: %O', 'color: red;', settings);
    // overflowAtOneLine

    let theme = useTheme();
    let udicciClasses = udicciStyles(theme);
    let udicciConstants = useUdicciConstants();

    var defaultShowTrimmed: boolean = true;
    var defaultLongTextLength: number = 150;

    if (settings) {
        if (settings.showTrimmed !== undefined && settings.showTrimmed !== null) {
            defaultShowTrimmed = Boolean(settings.showTrimmed);
        }
        if (settings.cutoffLength !== undefined && settings.cutoffLength !== null) {
            defaultLongTextLength = settings.cutoffLength;
        }
    }

    const overflowAtOneLine = (settings && settings.overflowAtOneLine ? true : false);
    // console.log('%c overflowAtOneLine: %O', 'color: maroon;', overflowAtOneLine);

    var recData = record.data;
    // console.log('%c useUdicciRecordField recData: %O', 'color: blue;', recData);
    var fieldValue = (recData && recData && field && field.JsonFieldName && recData[field.JsonFieldName] ? recData[field.JsonFieldName] : '');
    // console.log('%c useUdicciRecordField fieldValue: %O', 'color: blue;', fieldValue);

    let um = useUdicciMediator(record.udicciMediator);
    var { getField } = um;

    let {
        more, less, canBeExpanded, isExpanded, getTrimmedValue, displayValue
    } = useLongText(fieldValue, defaultShowTrimmed, defaultLongTextLength);

    const getFieldElement = (fieldProps: any = {}) => {
        // console.log('%c useUdicciRecordField getFieldElement record: %O', 'color: blue;', record);
        // console.log('%c useUdicciRecordField getFieldElement fieldProps: %O', 'color: blue;', fieldProps);

        var field: any = getField(fieldProps.fieldJsonKey);
        // console.log('%c useUdicciRecord getFieldElement field: %O', 'color: blue;', field);
        var displayProps: any = {
            variant: (fieldProps && fieldProps.variant ? fieldProps.variant : 'body1'),
            color: (fieldProps && fieldProps.color ? fieldProps.color : 'textPrimary'),
            gutterBottom: (fieldProps && fieldProps.gutterBottom ? true : false),
            classes: { root: udicciClasses.itemTitleContainer }
        };
        if (fieldProps.useHook === undefined) fieldProps.useHook = true;
        if (fieldProps.readonly === undefined) fieldProps.readonly = true;
        if (fieldProps.field === undefined) fieldProps.field = field;
        if (fieldProps.record === undefined) fieldProps.record = record;
        if (fieldProps.displayProps === undefined) fieldProps.displayProps = displayProps;
        if (fieldProps.onChange === undefined) fieldProps.onChange = (evt: any) => changeRecordValue(field, evt);
        // console.log('%c useUdicciRecord getFieldElement fieldProps: %O', 'color: blue;', fieldProps);
        // return (<FieldDisplay useHook={true} readonly={true} field={field} record={record} displaySettings={displayProps} />);
        return (<FieldDisplay {...fieldProps} />);
    };

    const getListElement = (listProps: any) => {
        // console.log('%c useUdicciRecordField getListElement record: %O', 'color: blue;', record);
        // console.log('%c useUdicciRecordField getListElement listProps: %O', 'color: blue;', listProps);
        // console.log('%c useUdicciRecordField getListElement field: %O', 'color: blue;', field);
        // console.log('%c useUdicciRecordField getListElement displayValue: %O', 'color: blue;', displayValue);

        var displaySettings = (listProps && listProps.displaySettings ? listProps.displaySettings : listProps);
        // console.log('%c getListElement displaySettings: %O', 'color: maroon;', displaySettings);

        var displayProps: any = {
            component: (displaySettings && displaySettings.component ? displaySettings.component : 'div'),
            variant: (displaySettings && displaySettings.variant ? displaySettings.variant : 'body1'),
            // color: (displaySettings && displaySettings.color ? displaySettings.color : 'textPrimary'),
            gutterBottom: (displaySettings && displaySettings.gutterBottom ? true : false),
            // classes: { root: udicciClasses.itemTitleContainer }
        };
        // console.log('%c useUdicciRecordField getListElement displayProps: %O', 'color: blue;', displayProps);

        var toggleLongTextViewElement: any = null;
        let expandable: boolean = canBeExpanded();
        let expanded: boolean = isExpanded();
        if (expandable) {
            if (expanded) {
                toggleLongTextViewElement = (
                    <IconButton size="small" aria-label="Show Less" onClick={(evt: any) => more()} sx={{ float: 'right' }}>
                        <UnfoldLessIcon fontSize="small" />
                    </IconButton>
                );
            } else {
                toggleLongTextViewElement = (
                    <IconButton size="small" aria-label="Show More" onClick={(evt: any) => less()} sx={{ float: 'right' }}>
                        <UnfoldMoreIcon fontSize="small" />
                    </IconButton>
                );
            }
        }

        let itemDisplayText: string = '';
        if (displayValue) {
            // console.log('%c useUdicciRecordField getListElement displayValue: %O', 'color: blue;', displayValue);
            if (field.DataType === 'DateTime') {
                itemDisplayText = format(parseISO(displayValue), "MM-dd-yyyy")
            } else {
                itemDisplayText = displayValue;
            }
        } else {
            // console.log('%c useUdicciRecordField getListElement listProps: %O', 'color: blue;', listProps);
            // console.log('%c useUdicciRecordField getListElement listProps.fieldJsonKey: %O', 'color: blue;', listProps.fieldJsonKey);
            // console.log('%c useUdicciRecordField getListElement record: %O', 'color: blue;', record);
            if (listProps && listProps.fieldJsonKey && record && record.data && record.data[listProps.fieldJsonKey] !== displayValue) {
                itemDisplayText = record.data[listProps.fieldJsonKey];
                // console.log('%c useUdicciRecordField getListElement itemDisplayText: %O', 'color: blue;', itemDisplayText);
            }
        }

        var recordListElement: any = null;
        if (listProps.toggleFields) {
            var recordListItemProps: any = {
                // sx: (listProps.fieldToggled ? udicciClasses.fieldContainerToggledOn : udicciClasses.fieldContainerToggledOff),
                onClick: (listProps.onToggleField ? ((evt: any) => listProps.onToggleField(field)) : null)
            };
            recordListElement = (
                <Box {...recordListItemProps}>
                    {toggleLongTextViewElement}
                    <Typography {...displayProps}> {formatStringForDisplay(itemDisplayText, (expanded ? false : overflowAtOneLine))} </Typography>
                </Box>
            );
        } else {
            recordListElement = (
                <Fragment>
                    {toggleLongTextViewElement}
                    <Typography {...displayProps}> {formatStringForDisplay(itemDisplayText, expanded ? false : overflowAtOneLine)} </Typography>
                </Fragment>
            );
        }

        return recordListElement;
    };

    const getFieldFormElement = (fieldProps: any) => {
        // console.log('%c getFieldFormElement fieldProps: %O', 'color: green;', fieldProps);
        // console.log('%c getFieldFormElement field: %O', 'color: green;', field);

        var layout: any = (fieldProps && fieldProps.layout ? fieldProps.layout : null);
        // console.log('%c getFieldFormElement layout: %O', 'color: green;', layout);
        var layoutSettings: any = (layout && layout.settings ? layout.settings : null);
        // console.log('%c getFieldFormElement layoutSettings: %O', 'color: green;', layoutSettings);

        var readonly: boolean = (fieldProps && fieldProps.readonly ? true : false);
        var showHelp: boolean = (fieldProps && fieldProps.showHelp ? true : false);
        var isSaving: boolean = (record && record.isSaving ? true : false);

        var recordPermissions: any = (record && record.permissions ? record.permissions : null);
        // console.log('%c getFieldFormElement recordPermissions: %O', 'color: maroon;', recordPermissions);

        var fieldDataType: string = (field && field.DataType ? field.DataType : 'String');
        // console.log('%c getFieldFormElement fieldDataType: %O', 'color: blue;', fieldDataType);
        var fieldJsonKey: string = (field && field.JsonFieldName ? field.JsonFieldName : field.Name);
        // console.log('%c getFieldFormElement fieldJsonKey: %O', 'color: blue;', fieldJsonKey);
        var fieldOverview: string = (field && field.Overview ? field.Overview : '');
        // console.log('%c getFieldFormElement fieldOverview: %O', 'color: blue;', fieldOverview);

        var fieldLabel: string = (field && field.DisplayName ? field.DisplayName : field.Name);
        // console.log('%c getFieldFormElement fieldLabel: %O', 'color: blue;', fieldLabel);
        var fieldLabelVariant: any = "leftFieldLabel";
        // console.log('%c getFieldFormElement fieldLabelVariant: %O', 'color: blue;', fieldLabelVariant);

        var disabled: boolean = false;
        let canChange: boolean = (recordPermissions && ((record.recordId > 0 && recordPermissions.CanEdit) || (record.recordId <= 0 && recordPermissions.CanAdd)) ? true : false);

        if (layoutSettings) {
            // console.log('%c layoutSettings: %O', 'color: cyan;', layoutSettings);
            if (layoutSettings.readonly !== undefined && layoutSettings.readonly !== null) {
                readonly = layoutSettings.readonly;
            }
            if (layoutSettings.label) fieldLabel = layoutSettings.label;
            if (layoutSettings.labelVariant) fieldLabelVariant = layoutSettings.labelVariant;
        }

        var inputProps: any = {};
        inputProps.id = fieldJsonKey;
        inputProps.disabled = (isSaving ? true : false);

        var changeHandler: any | null = null;
        if (fieldProps.onChange && canChange) {
            changeHandler = (evt: any) => fieldProps.onChange(fieldJsonKey, evt, null);
        }

        if (!readonly && canChange) {
            if (fieldValue && fieldDataType === 'DateTime') {
                // date fields typically get converted automatically
                var datetimeValue = new Date(fieldValue);
                // console.log('%c datetimeValue: %O', 'color: purple;', datetimeValue);
                if (datetimeValue) fieldValue = datetimeValue;
                changeHandler = (newDate: any) => changeRecordValue(fieldJsonKey, null, newDate);
            }

            inputProps.type = 'text';
            inputProps.onChange = changeHandler;
            inputProps.multiline = (udicciConstants.TextAreaFields.indexOf(fieldDataType) >= 0 ? true : false);
            if (inputProps.multiline) {
                inputProps.variant = 'outlined';
                inputProps.minRows = 3;
                inputProps.maxRows = 15;
            } else if (udicciConstants.StringFields.indexOf(fieldDataType)) {
                inputProps.variant = 'outlined';
                inputProps.multiline = true;
                inputProps.minRows = 1;
                inputProps.maxRows = 5;
            }
            // console.log('%c getFieldFormElement inputProps: %O', 'color: orange;', inputProps);
        } else if (recordPermissions && recordPermissions.CanView) {
            inputProps.component = 'div';
            inputProps.variant = 'body2';
            // inputProps.color = 'primary';
        }
        inputProps.fullWidth = true;
        inputProps.value = fieldValue;
        // console.log('%c getFieldFormElement inputProps: %O', 'color: blue;', inputProps);

        if (layoutSettings) {
            // console.log('%c layoutSettings: %O', 'color: cyan;', layoutSettings);
            if (layoutSettings.readonly !== undefined && layoutSettings.readonly !== null) {
                readonly = layoutSettings.readonly;
            }
            if (layoutSettings.multiline !== undefined && layoutSettings.multiline !== null) {
                inputProps.multiline = layoutSettings.multiline;
            }
            if (inputProps.multiline) {
                if (layoutSettings.minRows) inputProps.minRows = layoutSettings.minRows;
                if (layoutSettings.maxRows) inputProps.maxRows = layoutSettings.maxRows;
            }
            if (layoutSettings.label) fieldLabel = layoutSettings.label;
            if (layoutSettings.size) inputProps.size = layoutSettings.size;
            if (layoutSettings.variant) inputProps.variant = layoutSettings.variant;
            if (layoutSettings.placeholder) inputProps.placeholder = layoutSettings.placeholder;
            // console.log('%c getFieldFormElement inputProps: %O', 'color: blue;', inputProps);
        }

        var fieldContainerSettings: any = { margin: '8px', padding: '8px' };
        var fieldHelpElement: any = null;
        if (showHelp) {
            var fieldMaxSize: number = (field && field.MaxSize ? field.MaxSize : 0);
            // console.log('%c getFieldFormElement fieldMaxSize: %O', 'color: blue;', fieldMaxSize);
            var fieldMaximumLength: number = (field && field.MaximumLength ? field.MaximumLength : 0);
            // console.log('%c getFieldFormElement fieldMaximumLength: %O', 'color: blue;', fieldMaximumLength);
            var fieldMinimumLength: number = (field && field.MinimumLength ? field.MinimumLength : 0);
            // console.log('%c getFieldFormElement fieldMinimumLength: %O', 'color: blue;', fieldMinimumLength);
            var fieldIsRequired: boolean = (field && field.IsRequired ? field.IsRequired : false);
            // console.log('%c getFieldFormElement fieldIsRequired: %O', 'color: blue;', fieldIsRequired);

            let minSizeElement: any = null;
            let maxSizeElement: any = null;
            let requiredElement: any = null;

            if (fieldMinimumLength > 0 && fieldMaximumLength > 0) {
                minSizeElement = (
                    <Typography variant="caption" component="div" noWrap={true}>
                        {'Values must be at least ' + fieldMinimumLength.toString() + ' characters long.'}
                    </Typography>
                );
                maxSizeElement = (
                    <Typography variant="caption" component="div" noWrap={true}>
                        {'Values cannot exceed ' + fieldMaximumLength.toString() + ' characters.'}
                    </Typography>
                );
            } else if (fieldMinimumLength > 0) {
                minSizeElement = (
                    <Typography variant="caption" component="div" noWrap={true}>
                        {'Values must be at least ' + fieldMinimumLength.toString() + ' characters long.'}
                    </Typography>
                );
            } else if (fieldMaximumLength > 0) {
                maxSizeElement = (
                    <Typography variant="caption" component="div" noWrap={true}>
                        {'Values cannot exceed ' + fieldMaximumLength.toString() + ' characters.'}
                    </Typography>
                );
            }

            if (fieldIsRequired) {
                requiredElement = (
                    <Typography variant="caption" component="div">
                        {(fieldIsRequired ? 'This Is A Required Field.' : 'Not required.')}
                    </Typography>
                );
            }

            let toolTipElement: any = (
                <Fragment>
                    <Typography variant="caption" component="div">
                        This field accepts {fieldDataType} values.
                    </Typography>
                    {minSizeElement}
                    {maxSizeElement}
                    {requiredElement}
                </Fragment>
            );

            fieldHelpElement = (
                <Box sx={{ marginBottom: '4px', borderRadius: '8px', padding: '8px', background: 'rgba(255, 255, 255, 0.25)' }}>
                    <Box sx={{ float: 'right', display: 'flex' }}>
                        <Tooltip title={toolTipElement}>
                            <Icon fontSize="small" sx={{ cursor: 'help' }}>psychology</Icon>
                        </Tooltip>
                    </Box>
                    <Typography variant="caption" component="span">
                        Field Overview: 
                    </Typography>
                    <Typography variant="subtitle1" component="span" sx={{ marginLeft: '4px' }}>
                        {fieldLabel}
                    </Typography>
                    <Typography variant="body2" component="div">
                        {fieldOverview}
                    </Typography>
                </Box>
            );
        }
        var fieldInputElement: any = null;
        if (fieldDataType === 'DateTime') {
            var dateLabelElement: any = null;
            if (!showHelp) dateLabelElement = (<label>{fieldLabel}</label>);
            fieldInputElement = (
                <div style={fieldContainerSettings}>
                    <Box>
                        {fieldHelpElement}
                        <DateTimePicker label={dateLabelElement}
                                        renderInput={(props: any) => <TextField color="secondary" {...props} />}
                                        inputFormat={"MM/dd/yyyy h:mm a"}
                                        value={fieldValue}
                                        disabled={disabled}
                                        onChange={changeHandler}
                        />
                    </Box>
                </div>
            );
        } else if (fieldDataType === 'Boolean') {
            let chkboxElement: any = null;
            let chkbox: any = (
                <Checkbox checked={Boolean(fieldValue)}
                          onChange={changeHandler}
                          color="primary"
                />
            );
            if (!showHelp) {
                let chkboxLabel: any = (
                    <Typography variant={fieldLabelVariant}>
                        {fieldLabel}
                    </Typography>
                );
                chkboxElement = (<FormControlLabel control={chkbox} label={chkboxLabel} /> );
            } else {
                chkboxElement = chkbox;
            }
            fieldInputElement = (
                <div style={fieldContainerSettings}>
                    {fieldHelpElement}
                    {chkboxElement}
                </div>
            );
        } else {
            var fieldLabelElement: any = null;
            if (!showHelp) {
                fieldLabelElement = (<InputLabel htmlFor={fieldJsonKey}>
                    <Typography variant={fieldLabelVariant} component="div">
                        {fieldLabel}
                    </Typography>
                </InputLabel>);
            }
            fieldInputElement = (
                <div style={fieldContainerSettings}>
                    {fieldLabelElement}
                    {fieldHelpElement}
                    <TextField {...inputProps} />
                </div>
            );
        }

        return fieldInputElement;
    }

    return { getListElement, getFieldElement, getFieldFormElement };
}

/**
 * 
 * Usage:
 * 
 */
