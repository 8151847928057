
import { Fragment } from "react"
import PropTypes from 'prop-types';

import { useTheme, createTheme, Theme } from '@mui/material/styles';

import UrlParser from 'url-parse';

import Pluralize from 'pluralize';

import { keys, values, forEach, find, filter, map } from 'underscore';

import { Box, MenuItem, Typography } from '@mui/material';

import { useUdicciContext, sendPreparedRequest, updateRecord, getUdicciData } from 'src/context/udicci-context';
import { UdicciPermissions, UdicciRecord } from 'src/classes/udicci-record';
import { GetRecordContextRequest, UdicciFilterSetting, UdicciFilter } from 'src/interfaces/udicci-request-interfaces';
import useUdicciConstants from 'src/hooks/useUdicciConstants';
import { UdicciUserDetails } from 'src/classes/udicci-types';

export const UdicciPermissionsInitialized: UdicciPermissions = {
    CanView: false,
    CanAdd: false,
    CanEdit: false,
    CanDelete: false,
    CanDuplicate: false,
    CanProvision: false,
    CanDeleteIfOwner: false,
    CanEditIfOwner: false,
    InternalReferenceOnly: false,
    LimitedView: false,
    ViewFields: null,
    overrides: null
};

export const StandardNamesLookup: any = {
    typography: {
        'leftFieldLabel': { id: 'leftFieldLabel', title: 'Left Field Label', category: 'Labels', description: 'Text that is setup to display a Label for a field and displayed on the left side of the field.' },
        'overFieldLabel': { id: 'overFieldLabel', title: 'Over Field Label', category: 'Labels', description: 'Text that is setup to display a Label for a field and displayed over the field.' },
        'errorMessage': { id: 'errorMessage', title: 'Error Message Text', category: 'Error', description: 'Text that is setup to display an error message.' },
        'clickable': { id: 'clickable', title: 'Clickable Text', category: 'Clickable', description: 'Text that is setup to display text that can be clicked on by the user.' },
        'helpText': { id: 'helpText', title: 'Help Text', category: 'Help', description: 'Text that is setup to display as Help Content' },
        'subtitle1': { id: 'subtitle1', title: 'Top Tier Sub-Title', category: 'Headers', description: 'Text that is setup to display Top Level SubTitle.' },
        'clickableSubTitle1': { id: 'clickableSubTitle1', title: 'Clickable Top Tier Sub-Title', category: 'Clickable', description: 'Text that is setup to display Clickable Top Level SubTitle.' },
        'subtitle2': { id: 'subtitle2', title: 'Second Tier Sub-Title', category: 'Headers', description: 'Text that is setup to display Second Tier Sub-Title.' },
        'clickableSubTitle2': { id: 'clickableSubTitle2', title: 'Clickable Second Tier Sub-Title', category: 'Clickable', description: 'Text that is setup to display Clickable Second Tier Sub-Title content.' },
        'primaryColor': { id: 'primaryColor', title: 'Primary Color', category: 'Text', description: 'Text that is setup to display Primary content.' },
        'secondaryColor': { id: 'secondaryColor', title: 'Secondary Color', category: 'Text', description: 'Text that is setup to display Secondary content.' },
        'body1': { id: 'body1', title: 'Top Tier Body Text', category: 'Text', description: 'Text that is setup to display Top Tier Body Text content.' },
        'body2': { id: 'body2', title: 'Second Tier Body Text', category: 'Text', description: 'Text that is setup to display Second Tier Body Text content.' },
        'caption': { id: 'caption', title: 'Caption', category: 'Help', description: 'Text that is setup to display as caption content.' },
        'infoColor': { id: 'infoColor', title: 'Informational Text', category: 'Text', description: 'Text that is setup to display as informational content.' },
        'successColor': { id: 'successColor', title: 'Success', category: 'Text', description: 'Text that is setup to display content which represents Success.' },
        'text': { id: 'text', title: 'General Text', category: 'Text', description: 'Text that is setup to display general text content.' },
        'errorColor': { id: 'errorColor', title: 'General Error Text', category: 'Error', description: 'Text that is setup to display an error message.' },
        'warningColor': { id: 'warningColor', title: 'Warning Text', category: 'Help', description: 'Text that is setup to display any Warnings.' },
        'h1': { id: 'h1', title: 'Header Level 1', category: 'Headers', description: 'Text that is setup to display Header Level 1 content.' },
        'h2': { id: 'h2', title: 'Header Level 2', category: 'Headers', description: 'Text that is setup to display Header Level 2 content.' },
        'h3': { id: 'h3', title: 'Header Level 3', category: 'Headers', description: 'Text that is setup to display Header Level 3 content.' },
        'h4': { id: 'h4', title: 'Header Level 4', category: 'Headers', description: 'Text that is setup to display Header Level 4 content.' },
        'h5': { id: 'h5', title: 'Header Level 5', category: 'Headers', description: 'Text that is setup to display Header Level 5 content.' },
        'h6': { id: 'h6', title: 'Header Level 6', category: 'Headers', description: 'Text that is setup to display Header Level 6 content.' },
        'button': { id: 'button', title: 'Text displayed as a Button', category: 'Button', description: 'Text that is setup to display a button.' },
        'overline': { id: 'overline', title: 'Overlined Text', category: 'Text', description: 'Text that is setup to display text with an overline.' },
    },
    palette: {
        'primary': { id: 'primary', title: 'Primary Button', category: 'Regular', description: 'Button that is setup to display as a Primary Color button.' },
        'secondary': { id: 'secondary', title: 'Secondary Button', category: 'Regular', description: 'Button that is setup to display as a Secondary Color button. ' },
        'info': { id: 'info', title: 'Info Button', category: 'Regular', description: 'Button that is setup to display as a Info Color button. ' },
        'error': { id: 'error', title: 'Error Button', category: 'Regular', description: 'Button that is setup to display as a Error Color button. ' },
        'warning': { id: 'warning', title: 'Warning Button', category: 'Regular', description: 'Button that is setup to display as a Warning Color button. ' },
        'success': { id: 'success', title: 'Success Button', category: 'Action', description: 'Button that is setup to display as a Success Color button. ' },
        'cancelButton': { id: 'cancelButton', title: 'Cancel Button', category: 'Action', description: 'Button that is setup to display as a Cancel Color button. ' },
        'loginButton': { id: 'loginButton', title: 'Login Button', category: 'Action', description: 'Button that is setup to display as a Login Color button. ' },
        'saveButton': { id: 'saveButton', title: 'Save Button', category: 'Record', description: 'Button that is setup to display as a Save Color button. ' },
        'editButton': { id: 'editButton', title: 'Edit Button', category: 'Record', description: 'Button that is setup to display as a Edit Color button. ' },
        'deleteButton': { id: 'deleteButton', title: 'Delete Button', category: 'Record', description: 'Button that is setup to display as a Delete Color button. ' },
        'moveButton': { id: 'moveButton', title: 'Move Button', category: 'Record', description: 'Button that is setup to display as a Move Color button. ' },
        'engagementButton1': { id: 'engagementButton1', title: 'Primary Engagement Button', category: 'Engagement', description: 'Button that is setup to display a Primary Engagement Button.' },
        'engagementButton2': { id: 'engagementButton2', title: 'Secondary Engagement Button', category: 'Engagement', description: 'Button that is setup to display a Secondary Engagement Button.' },
        'engagementButton3': { id: 'engagementButton3', title: 'Additional Engagement Button', category: 'Engagement', description: 'Button that is setup to display an Additional Engagement Button.' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
        // 'overFieldLabel': { id: 'overFieldLabel', title: '', category: '', description: 'Text that is setup as ' },
    },
};

export default function useUdicciHelpers() {
    const udicciContext = useUdicciContext();
    const udicciConstants = useUdicciConstants();
    const theme = useTheme();

    var { udicci, data } = udicciContext.state;
    var { currentUser, selectedProfile } = udicci;

    const getWeekNumber = (dateValue: Date) => {
        dateValue.setHours(0, 0, 0, 0);
        // January 4 is always in week 1.
        var week1: Date = new Date(dateValue.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        let weekNumber: number = (1 + Math.round(((dateValue.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7));
        return weekNumber; 
    }
    
    const getMediatorContext = (mediatorName: string) => {
        // console.log('%c useUdicciHelpers getMediatorContext data: %O', 'color: maroon;', data);
        var mediatorContext = data.find((x: any) => x.mediator === mediatorName );
        // console.log('%c useUdicciHelpers getMediatorContext mediatorContext: %O', 'color: maroon;', mediatorContext);
        return mediatorContext;
    };

    const getMediatorStructure = (mediatorName: string) => {
        var mediatorContext: any = getMediatorContext(mediatorName);
        var mediatorStructure = (mediatorContext && mediatorContext.structure ? mediatorContext.structure : null);
        // console.log('%c useUdicciHelpers mediatorStructure: %O', 'color: maroon;', mediatorStructure);
        return mediatorStructure;
    };

    const getMediatorData = (mediatorName: string) => {
        var mediatorContext: any = getMediatorContext(mediatorName);
        var mediatorRecords = (mediatorContext && mediatorContext.records ? mediatorContext.records : null);
        // console.log('%c useUdicciHelpers mediatorRecords: %O', 'color: maroon;', mediatorRecords);
        return mediatorRecords;
    };

    const getMediatorDataForParent = (mediatorName: string, parentMediatorName: string, parentRecordId: number) => {
        var mediatorContext: any = getMediatorContext(mediatorName);
        // console.log('%c getMediatorDataForParent mediatorContext: %O', 'color: hotpink;', mediatorContext);
        // var parentMediatorContext: any = getMediatorContext(parentMediatorName);
        var mediatorRecords = (mediatorContext && mediatorContext.records ? mediatorContext.records : null);
        // console.log('%c getMediatorDataForParent mediatorRecords: %O', 'color: hotpink;', mediatorRecords);
        let linkedData: any[] = (mediatorContext.linkedData ? mediatorContext.linkedData : []);
        let lum: any = find(linkedData, (lud: any) => {
            return lud.LeftUdicciMediatorName === parentMediatorName && lud.RightUdicciMediatorName === mediatorName;
        });
        // console.log('%c getMediatorDataForParent lum: %O', 'color: hotpink;', lum);

        let filteredData: any[] = [];
        if (lum && lum.Data && lum.Data.length > 0 && mediatorRecords && mediatorRecords.length > 0) {
            let relatedLumData: any[] = filter(lum.Data, (relData: any) => { return relData.LeftId === parentRecordId; });
            // console.log('%c getMediatorDataForParent relatedLumData: %O', 'color: hotpink;', relatedLumData);
            if (relatedLumData.length > 0) {
                let filterIds: number[] = map(relatedLumData, (rld: any) => { return rld.RightId; });
                filteredData = filter(mediatorRecords, (rec: any) => { return (filterIds.indexOf(rec.recordId) >= 0); });
            }
        }
        return filteredData;
    };

    const preloadMediatorData = (mediatorName: string, socialSolutionId: number, forceLoad: boolean = false, profileId: number = 0) => {
        // console.log('%c preloadMediatorData mediatorName: %O', 'color: maroon;', mediatorName);
        // console.log('%c preloadMediatorData socialSolutionId: %O', 'color: maroon;', socialSolutionId);
        // console.log('%c preloadMediatorData forceLoad: %O', 'color: maroon;', forceLoad);
        // console.log('%c preloadMediatorData profileId: %O', 'color: maroon;', profileId);

        var listData: any = null;
        if (data) {
            var mediatorContext = data.find((x: any) => x.mediator === mediatorName );
            // console.log('%c preloadMediatorData mediatorContext: %O', 'color: maroon;', mediatorContext);
            if (mediatorContext && mediatorContext.records) listData = mediatorContext.records;
        }
        // console.log('%c preloadMediatorData listData: %O', 'color: maroon;', listData);

        // console.log('%c preloadMediatorData dataRequested: %O', 'color: maroon;', dataRequested);
        // console.log('%c preloadMediatorData forceLoad: %O', 'color: maroon;', forceLoad);
        if (selectedProfile && (!listData || forceLoad)) {
            if (!profileId && selectedProfile) profileId = selectedProfile.recordId;
            getUdicciData({
                mediator: mediatorName,
                socialSolutionId: socialSolutionId,
                profileId: profileId
            });
        }

        return listData;
    }

    const preloadFilteredMediatorData = (mediatorName: string, socialSolutionId: number, settings: any, forceLoad: boolean = false, profileId: number = 0) => {
        // console.log('%c preloadFilteredMediatorData mediatorName: %O', 'color: maroon;', mediatorName);
        // console.log('%c preloadFilteredMediatorData socialSolutionId: %O', 'color: maroon;', socialSolutionId);
        // console.log('%c preloadFilteredMediatorData settings: %O', 'color: maroon;', settings);
        // console.log('%c preloadFilteredMediatorData forceLoad: %O', 'color: maroon;', forceLoad);
        // console.log('%c preloadFilteredMediatorData profileId: %O', 'color: maroon;', profileId);

        var listData: any = null;
        if (data) {
            var mediatorContext = data.find((x: any) => x.mediator === mediatorName );
            // console.log('%c preloadFilteredMediatorData mediatorContext: %O', 'color: maroon;', mediatorContext);
            if (mediatorContext && mediatorContext.records) listData = mediatorContext.records;
        }
        // console.log('%c preloadFilteredMediatorData listData: %O', 'color: maroon;', listData);

        // console.log('%c preloadFilteredMediatorData dataRequested: %O', 'color: maroon;', dataRequested);
        // console.log('%c preloadFilteredMediatorData forceLoad: %O', 'color: maroon;', forceLoad);
        if (selectedProfile && (!listData || forceLoad)) {
            if (!profileId && selectedProfile) profileId = selectedProfile.recordId;
            getUdicciData({
                mediator: mediatorName,
                socialSolutionId: socialSolutionId,
                profileId: profileId,
                settings
            });
        }

        return listData;
    }

    const getRecordContext = (record: UdicciRecord, socialSolution: UdicciRecord | null = null, contextLevels: any = null) => {
        // console.log('%c getRecordContext record: %O', 'color: blue;', record);
        // console.log('%c getRecordContext socialSolution: %O', 'color: blue;', socialSolution);

        var ssRec: any = socialSolution;
        // console.log('%c getRecordContext ssRec: %O', 'color: blue;', ssRec);
        if (!ssRec && data) {
            var mediatorContext: any = getMediatorContext('Social Solutions');
            if (mediatorContext && mediatorContext.records) {
                ssRec = mediatorContext.records.find((x: any) => x.recordId === record.data.CreatedInSolutionId );
                if (!ssRec) {
                    // get .Me Social Solution as default
                    ssRec = mediatorContext.records.find((x: any) => x.data.Name === udicciConstants.DEFAULT_SOCIALSOLUTION_NAME );
                }
            }
        }
        // console.log('%c ssRec: %O', 'color: red;', ssRec);

        var ssId: number = (ssRec && ssRec.recordId ? ssRec.recordId : 0);
        // console.log('%c ssId: %O', 'color: red;', ssId);

        var requestJson: GetRecordContextRequest = {
            UdicciCommand: 'Get Record Context',
            UdicciMediatorName: record.udicciMediator,
            SelectedUdicciProfileId: (selectedProfile && selectedProfile.recordId ? selectedProfile.recordId : 0),
            UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0),
            SocialSolutionId: ssId,
            UdicciRecordId: record.recordId,
            MaxSteps: 3,
            UseLevels: contextLevels
        };
        // console.log('%c getRecordContext requestJson: %O', 'color: blue;', requestJson);

        var okToContinue = true;
        if (okToContinue) {
            sendPreparedRequest(requestJson, {
                onSuccess: (result: any, request: any, settings: any) => getRecordContextSuccess(record, result, request, settings),
                onError: requestFailed
            });
        }
    };

    const getRecordContextSuccess = (record: UdicciRecord, result: any, request: any, settings: any) => {
        // console.log('%c getRecordContextSuccess record: %O', 'color: blue;', record);
        // console.log('%c getRecordContextSuccess result: %O', 'color: blue;', result);
        // console.log('%c getRecordContextSuccess request: %O', 'color: blue;', request);
        // console.log('%c getRecordContextSuccess settings: %O', 'color: blue;', settings);
        // setContextRetrieved(false);

        var structures: any[] = [];
        if (result && result.structures && result.structures.length > 0) {
            result.structures.forEach(function(structure: any) {
                // console.log('%c structure: %O', 'color: hotpink;', structure);
                structures.push(structure);
            });
        }
        // console.log('%c getRecordContextSuccess structures: %O', 'color: blue;', structures);

        var records: any[] = [];
        if (result && result.records) {
            values(result.records).forEach(function(rec: any) {
                // console.log('%c structure: %O', 'color: hotpink;', structure);
                records.push(rec);
            });
        }
        // console.log('%c getRecordContextSuccess records: %O', 'color: blue;', records);

        var updatedRecordContextData: any = {
            structures: structures,
            records: records
        };
        // console.log('%c getRecordContextSuccess updatedRecordContextData: %O', 'color: blue;', updatedRecordContextData);

        record.context = updatedRecordContextData;
        // console.log('%c getRecordContextSuccess record: %O', 'color: blue;', record);
        updateRecord(record);

        // setRecordContext(updatedRecordContextData);  // udicciRecord context
    };

    const getRecordContextByView = (contextView: any, contextLevels: any = null) => {
        // console.log('%c getRecordContextByView contextView: %O', 'color: blue;', contextView);
        // console.log('%c getRecordContextByView contextLevels: %O', 'color: blue;', contextLevels);
        // console.log('%c getRecordContextByView socialSolution: %O', 'color: blue;', socialSolution);

        if (!contextLevels && contextView && contextView.settings && contextView.settings.contextLevels) {
            contextLevels = contextView.settings.contextLevels;
            // console.log('%c getRecordContextByView contextLevels: %O', 'color: green;', contextLevels);
        }
        var socialSolution: any = (contextView && contextView.socialSolution ? contextView.socialSolution : null);
        // console.log('%c socialSolution: %O', 'color: red;', socialSolution);
        var mediator: any = (contextView && contextView.mediator ? contextView.mediator : null);
        // console.log('%c mediator: %O', 'color: red;', mediator);
        var record: any = (contextView && contextView.record ? contextView.record : null);
        // console.log('%c record: %O', 'color: red;', record);
        var ssId: number = (socialSolution && socialSolution.id ? socialSolution.id : 0);
        // console.log('%c ssId: %O', 'color: red;', ssId);
        var mediatorName: string = (mediator && mediator.name ? mediator.name : '');
        // console.log('%c mediatorName: %O', 'color: red;', mediatorName);
        var recordId: number = (record && record.id ? record.id : 0);
        // console.log('%c recordId: %O', 'color: red;', recordId);
        // var contextLevels: any = (contextView && contextView.levels ? contextView.levels : null);
        // console.log('%c UdicciContextViewWrapper contextLevels: %O', 'color: maroon;', contextLevels);
    
        var requestJson: GetRecordContextRequest = {
            UdicciCommand: 'Get Record Context',
            UdicciMediatorName: mediatorName,
            SelectedUdicciProfileId: (selectedProfile && selectedProfile.recordId ? selectedProfile.recordId : 0),
            UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0),
            SocialSolutionId: ssId,
            UdicciRecordId: recordId,
            MaxSteps: 3,
            UseLevels: contextLevels
        };
        // console.log('%c getRecordContextByView requestJson: %O', 'color: blue;', requestJson);

        var okToContinue = true;
        if (okToContinue) {
            sendPreparedRequest(requestJson, {
                onSuccess: (result: any, request: any, settings: any) => getRecordContextByViewSuccess(contextView, result, request, settings),
                onError: requestFailed
            });
        }
    };

    const getRecordContextByViewSuccess = (contextView: any, result: any, request: any, settings: any) => {
        // console.log('%c getRecordContextByViewSuccess contextView: %O', 'color: blue;', contextView);
        // console.log('%c getRecordContextByViewSuccess result: %O', 'color: blue;', result);
        // console.log('%c getRecordContextByViewSuccess request: %O', 'color: blue;', request);
        // console.log('%c getRecordContextByViewSuccess settings: %O', 'color: blue;', settings);
        // setContextRetrieved(false);

        // var socialSolution: any = (contextView && contextView.socialSolution ? contextView.socialSolution : null);
        // console.log('%c socialSolution: %O', 'color: red;', socialSolution);
        // var mediator: any = (contextView && contextView.mediator ? contextView.mediator : null);
        // console.log('%c mediator: %O', 'color: red;', mediator);
        var record: any = (contextView && contextView.record ? contextView.record : null);
        // console.log('%c record: %O', 'color: red;', record);
        // var ssId: number = (socialSolution && socialSolution.id ? socialSolution.id : 0);
        // // console.log('%c ssId: %O', 'color: red;', ssId);
        // var mediatorName: string = (mediator && mediator.name ? mediator.name : '');
        // // console.log('%c mediatorName: %O', 'color: red;', mediatorName);
        var recordId: number = (record && record.id ? record.id : 0);
        // console.log('%c recordId: %O', 'color: red;', recordId);

        var structures: any[] = [];
        if (result && result.structures && result.structures.length > 0) {
            result.structures.forEach(function(structure: any) {
                // console.log('%c structure: %O', 'color: hotpink;', structure);
                structures.push(structure);
            });
        }
        // console.log('%c recordId structures: %O', 'color: blue;', structures);

        var records: any[] = [];
        var selectedRecord: any = null;
        if (result && result.records) {
            values(result.records).forEach(function(rec: any) {
                // console.log('%c structure: %O', 'color: hotpink;', structure);
                records.push(rec);
                if (rec.recordId === recordId) {
                    selectedRecord = rec;
                }
            });
        }
        // console.log('%c selectedRecord: %O', 'color: blue;', selectedRecord);
        // console.log('%c records: %O', 'color: blue;', records);

        var updatedRecordContextData: any = {
            structures: structures,
            records: records
        };
        // console.log('%c recordId updatedRecordContextData: %O', 'color: blue;', updatedRecordContextData);

        if (selectedRecord) {
            record.context = updatedRecordContextData;
            selectedRecord.record.context = updatedRecordContextData;
            // console.log('%c getRecordContextByViewSuccess record: %O', 'color: blue;', record);
            // console.log('%c getRecordContextByViewSuccess selectedRecord.record: %O', 'color: blue;', selectedRecord.record);
            updateRecord(selectedRecord.record);

            // setRecordContext(updatedRecordContextData);  // udicciRecord context
        }
    };

    const requestFailed = (response: any) => {
        // console.log('requestFailed response: %O', response);
    }

    const getUserEstimatedTimeRemainingInSession = (currentUser: UdicciUserDetails, timeoutCallback: any | undefined) => {
        let expectedTimeout: (any | null) = (currentUser && currentUser.ExpectedTimeout ? currentUser.ExpectedTimeout : null);
        if (expectedTimeout) expectedTimeout = new Date(expectedTimeout);

        let minutesLeft: number = 0;
        let secondsLeft: number = 0;
        let curDate = new Date();
        if (expectedTimeout && expectedTimeout.getTime() < curDate.getTime()) {
            // console.log('%c getUserTimeout User Sessiont has Timed Out: current = %O, expected timeout = %O', 'color: red; font-weight: bold;', curDate.getTime(), expectedTimeout.getTime());
            if (timeoutCallback) timeoutCallback(expectedTimeout);
        } else if (expectedTimeout) {
            var diff = Math.abs(expectedTimeout.getTime() - curDate.getTime()) / 1000;
            minutesLeft = Math.floor(diff / 60) % 60;
            secondsLeft = Math.floor(diff % 60);
        }
        // console.log('%c getUserEstimatedTimeRemainingInSession minutesLeft: %O', 'color: red;', minutesLeft);
        // console.log('%c getUserEstimatedTimeRemainingInSession secondsLeft: %O', 'color: red;', secondsLeft);

        let totalTimeLeft: string = (minutesLeft ? minutesLeft.toString() + ':' : '') + (secondsLeft < 10 ? '0' + secondsLeft.toString() : secondsLeft.toString());
        return { expectedTimeout, totalTimeLeft };
    }

    const initializePermissions = () => {
        return {
            CanView: false,
            CanAdd: false,
            CanEdit: false,
            CanDelete: false,
            CanDuplicate: false,
            CanProvision: false,
            CanDeleteIfOwner: false,
            CanEditIfOwner: false,
            InternalReferenceOnly: false,
            LimitedView: false,
            ViewFields: null,
            overrides: null
        };
    }

    const openUrl = (url: string, target: string = '_self') => {
        // console.log('%c openUrl url: %O', 'color: hotpink;', url);
        var currentUrl = UrlParser(window.location.href, true);
        var { pathname } = currentUrl;
        if (url && url.toLowerCase() !== pathname.toLowerCase()) {
            window.open(url, target);
        }
    }

    const swapArrayElements = (arr: any[], index1: number, index2: number) => {
        // console.log('%c swapArrayElements arr: %O', 'color: blue;', arr);
        // console.log('%c swapArrayElements index1: %O', 'color: blue;', index1);
        // console.log('%c swapArrayElements index2: %O', 'color: blue;', index2);
        if (index1 <= arr.length && index2 <= arr.length) {
            let index1Value = arr[index1];
            let index2Value = arr[index2];
            // console.log('%c swapArrayElements index1Value: %O', 'color: blue;', index1Value);
            // console.log('%c swapArrayElements index2Value: %O', 'color: blue;', index2Value);
            arr[index2] = index1Value;
            arr[index1] = index2Value;
        }

        // console.log('%c swapArrayElements arr: %O', 'color: blue;', arr);
        return arr;
    }

    const getCommonSelectionListItems = (commonName: string, preKeyValue: string = '', detailedView: boolean | undefined = false) => {
        // console.log('%c getCommonSelectionListItems theme: %O', 'color: maroon;', theme);

        let typography: any = (theme && theme.typography ? theme.typography : null);
        // console.log('%c getCommonSelectionListItems typography: %O', 'color: maroon;', typography);

        let palette: any = (theme && theme.palette ? theme.palette : null);
        // console.log('%c getCommonSelectionListItems palette: %O', 'color: maroon;', palette);

        let listItems: any[] = [];
        if (commonName) {
            switch (commonName.toLowerCase()) {
                case 'typography.variant':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "not.set"} value="">not set</MenuItem>);
                    if (typography) {
                        let ignoreKeys: string[] = ['pxToRem', 'htmlFontSize'];
                        forEach(keys(typography), function(typographyKey: string, idxTypographyKey: number) {
                            // console.log('%c typographyKey: %O', 'color: blue;', typographyKey);
                            if (ignoreKeys.indexOf(typographyKey) >= 0) return true;

                            let typographySetting: any = (typography[typographyKey] ? typography[typographyKey] : null);
                            // console.log('%c typographySetting: %O', 'color: blue;', typographySetting);
                            if (typographySetting && typeof(typographySetting) === 'object') {
                                // console.log('%c typographySetting: %O', 'color: purple;', typographySetting);
                                let standardInfo: any = lookupStandardInformation('typography', typographyKey);
                                // console.log('%c standardInfo: %O', 'color: purple;', standardInfo);
                                if (standardInfo) {
                                    // console.log('%c standardInfo: %O', 'color: purple;', standardInfo);
                                    let titleElement: any = (<Typography variant="inherit" component="div">{standardInfo.title}</Typography>);
                                    let descriptionElement: any = null;
                                    if (standardInfo.description && detailedView) {
                                        descriptionElement = (
                                            <Fragment>
                                                <br />
                                                <Typography variant="caption" component="div" sx={{ marginLeft: '4px' }}>{standardInfo.description}</Typography>
                                            </Fragment>
                                        );
                                    }
                                    listItems.push(
                                        <MenuItem key={preKeyValue + commonName.toLowerCase() + standardInfo.id} value={standardInfo.id}>
                                            {titleElement}
                                            {descriptionElement}
                                        </MenuItem>
                                    );
                                } else {
                                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + typographyKey} value={typographyKey}>{typographyKey}</MenuItem>);
                                }
                            }
                        });
                    }

                    if (listItems.length <= 1) {
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "body1"} value="body1">body1</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "body2"} value="body2">body2</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "button"} value="button">button</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "caption"} value="caption">caption</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "h1"} value="h1">h1</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "h2"} value="h2">h2</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "h3"} value="h3">h3</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "h4"} value="h4">h4</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "h5"} value="h5">h5</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "h6"} value="h6">h6</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "inherit"} value="inherit">inherit</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "overline"} value="overline">overline</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "subtitle1"} value="subtitle1">subtitle1</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "subtitle2"} value="subtitle2">subtitle2</MenuItem>);
                    }

                    break;
                case 'button.color':
                    // console.log('%c getCommonSelectionListItems palette: %O', 'color: maroon;', palette);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "not.set"} value="">not set</MenuItem>);
                    if (palette) {
                        let ignoreKeys: string[] = ['pxToRem', 'htmlFontSize'];
                        forEach(keys(palette), function(paletteKey: string, idxPaletteKey: number) {
                            // console.log('%c paletteKey: %O', 'color: blue;', paletteKey);
                            if (ignoreKeys.indexOf(paletteKey) >= 0) return true;

                            let paletteSetting: any = (palette[paletteKey] ? palette[paletteKey] : null);
                            // console.log('%c paletteSetting: %O', 'color: blue;', paletteSetting);
                            if (paletteSetting && typeof(paletteSetting) === 'object') {
                                // console.log('%c paletteSetting: %O', 'color: purple;', paletteSetting);
                                let standardInfo: any = lookupStandardInformation('palette', paletteKey);
                                // console.log('%c standardInfo: %O', 'color: purple;', standardInfo);
                                if (standardInfo) {
                                    // console.log('%c standardInfo: %O', 'color: purple;', standardInfo);
                                    let titleElement: any = (<Typography variant="inherit" component="div">{standardInfo.title}</Typography>);
                                    let descriptionElement: any = null;
                                    if (standardInfo.description) {
                                        descriptionElement = (
                                            <Fragment>
                                                <br />
                                                <Typography variant="caption" component="div" sx={{ marginLeft: '4px' }}>{standardInfo.description}</Typography>
                                            </Fragment>
                                        );
                                    }
                                    listItems.push(
                                        <MenuItem key={preKeyValue + commonName.toLowerCase() + standardInfo.id} value={standardInfo.id}>
                                            {titleElement}
                                            {descriptionElement}
                                        </MenuItem>
                                    );
                                } else {
                                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + paletteKey} value={paletteKey}>{paletteKey}</MenuItem>);
                                }
                            }
                        });
                    }

                    if (listItems.length <= 1) {
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "not.set"} value="">not set</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "primary"} value="primary">Primary</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "secondary"} value="secondary">Secondary</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "success"} value="success">Success</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "error"} value="error">Error</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "info"} value="info">Info</MenuItem>);
                        listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "warning"} value="warning">Warning</MenuItem>);
                    }
                    break;
                case 'button.variant':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "not.set"} value="">not set</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "contained"} value="contained">Contained</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "outlined"} value="outlined">Outlined</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "text"} value="text">Text</MenuItem>);
                    break;
                case 'button.size':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "not.set"} value="">not set</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "small"} value="small">Small</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "medium"} value="medium">Medium</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "large"} value="large">Large</MenuItem>);
                    break;
                case 'button.group.orientation':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "not.set"} value="">not set</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "horizontal"} value="horizontal">Horizontal</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "vertical"} value="vertical">Vertical</MenuItem>);
                    break;
                case 'stack.direction':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "not.set"} value="">not set</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "column"} value="column">column</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "column-reverse"} value="column-reverse">column-reverse</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "row"} value="row">row</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "row-reverse"} value="row-reverse">row-reverse</MenuItem>);
                    break;
                case 'stack.spacing':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "not.set"} value="">not set</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "0"} value="0">0</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "1"} value="1">1</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "2"} value="2">2</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "3"} value="3">3</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "4"} value="4">4</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "5"} value="5">5</MenuItem>);
                    break;
                case 'icon.placement':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "not.set"} value="">not set</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "start"} value="start">Start</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "end"} value="end">End</MenuItem>);
                    break;
                case 'icon.size':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "not.set"} value="">not set</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "small"} value="small">Small</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "medium"} value="medium">Medium</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "large"} value="large">Large</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "inherit"} value="inherit">Inherit</MenuItem>);
                    break;
                case 'icon.color':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "not.set"} value="">not set</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "primary"} value="primary">Primary</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "secondary"} value="secondary">Secondary</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "success"} value="success">Success</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "error"} value="error">Error</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "info"} value="info">Info</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "warning"} value="warning">Warning</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "inherit"} value="inherit">Inherit</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "action"} value="action">Action</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "disabled"} value="disabled">Disabled</MenuItem>);
                    break;
                case 'link.underline':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "not.set"} value="">not set</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "always"} value="always">Always</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "hover"} value="hover">Hover</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + "none"} value="none">None</MenuItem>);
                    break;
                case 'border.radius':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.none'} value={'0px'}> None </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.slight'} value={'4px'}> Slight </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.standard'} value={'8px'}> Standard </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.more'} value={'16px'}> More </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.oval'} value={'32px'}> Ovaltined </MenuItem>);
                    break;
                case 'border.style':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.selection.needed'} value={''}> Select Border Style </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.none'} value={''}> none </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.dotted'} value={'dotted'}> dotted </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.dashed'} value={'dashed'}> dashed </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.solid'} value={'solid'}> solid </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.double'} value={'double'}> double </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.groove'} value={'groove'}> groove </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.ridge'} value={'ridge'}> ridge </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.inset'} value={'inset'}> inset </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.outset'} value={'outset'}> outset </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.hidden'} value={'hidden'}> hidden </MenuItem>);
                    break;
                case 'font.family':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".not.set"} value="">not set</MenuItem>);

                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Sans.Serif.Arial"} value="Arial, Helvetica, sans-serif">Arial</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Sans.Serif.Arial.Black"} value="'Arial Black', Gadget, sans-serif">Arial Black</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Sans.Serif.Comic.Sans"} value="'Comic Sans MS', cursive, sans-serif">Comic Sans MS</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Sans.Serif.Impact"} value="Impact, Charcoal, sans-serif">Impact</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Sans.Serif.Lucida"} value="'Lucida Sans Unicode', 'Lucida Grande', sans-serif">Lucida</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Sans.Serif.Tahoma"} value="Tahoma, Geneva, sans-serif">Tahoma</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Sans.Serif.Trebuchet.MS"} value="'Trebuchet MS', Helvetica, sans-serif">Trebuchet MS</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Sans.Serif.Verdana"} value="Verdana, Geneva, sans-serif">Verdana</MenuItem>);

                    // Serif Fonts
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Serif.Georgia"} value="Georgia, serif">Georgia</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Serif.Palatino.Linotype"} value="'Palatino Linotype', 'Book Antiqua', Palatino, serif">Palatino Linotype</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Serif.Times.New.Roman"} value="'Times New Roman', Times, serif">Times New Roman</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Serif.Roboto"} value="'Roboto', 'Helvetica', 'Arial', sans-serif">Roboto</MenuItem>);

                    // Monospace Fonts
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Monospace.Courier.New"} value="'Courier New', Courier, monospace">Courier New</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Monospace.Lucida.Console"} value="'Lucida Console', Monaco, monospace">Lucida Console</MenuItem>);

                    // Google Fonts
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Google.Josefin"} value="'Josefin Sans', sans-serif">Josefin</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".Google.Indie.Flower"} value="'Indie Flower', cursive">Indie Flower</MenuItem>);

                    break;
                case 'font.size':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".not.set"} value="">not set</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".normal"} value="normal">Normal</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".small"} value="small">Small</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".medium"} value="medium">Medium</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".large"} value="large">Large</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".larger"} value="larger">Larger</MenuItem>);
                    break;
                case 'font.weight':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".not.set"} value="">not set</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".normal"} value="normal">Normal</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bold"} value="bold">Bold</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bolder"} value="bolder">Bolder</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".lighter"} value="lighter">Lighter</MenuItem>);
                    break;

                case 'input.variant':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".not.set"} value="">not set</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".filled"} value="filled">Filled</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".outlined"} value="outlined">Outlined</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".standard"} value="standard">Standard</MenuItem>);
                    break;
                case 'input.size':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".not.set"} value="">not set</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".small"} value="small">Small</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".medium"} value="medium">Medium</MenuItem>);
                    break;

                case 'number.conditions':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".is.greater.than"} value="is.greater.than">is.greater.than</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".is.greater.than.or.equal.to"} value="is.greater.than.or.equal.to">is.greater.than.or.equal.to</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".is.less.than"} value="bold">is.less.than</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".is.less.than.or.equal.to"} value="is.less.than.or.equal.to">is.less.than.or.equal.to</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".between"} value="between">between</MenuItem>);
                    break;
                case 'grid.breakpoints':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bkpt.1"} value={1}> 1 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bkpt.2"} value={2}> 2 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bkpt.3"} value={3}> 3 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bkpt.4"} value={4}> 4 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bkpt.5"} value={5}> 5 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bkpt.6"} value={6}> 6 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bkpt.7"} value={7}> 7 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bkpt.8"} value={8}> 8 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bkpt.9"} value={9}> 9 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bkpt.10"} value={10}> 10 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bkpt.11"} value={11}> 11 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".bkpt.12"} value={12}> 12 </MenuItem>);
                    break;
                case 'grid.direction':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".row"} value={'row'}> row </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".row.reverse"} value={'row-reverse'}> row-reverse </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".column"} value={'column'}> column </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".column.reverse"} value={'column-reverse'}> column-reverse </MenuItem>);
                    break;
                case 'grid.justifycontent':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".flex.start"} value={'flex-start'}> flex-start </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".center"} value={'center'}> center </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".flex.end"} value={'flex-end'}> flex-end </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".space.between"} value={'space-between'}> space-between </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".space.around"} value={'space-around'}> space-around </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".space.evenly"} value={'space-evenly'}> space-evenly </MenuItem>);
                    break;
                case 'grid.alignitems':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".flex.start"} value={'flex-start'}> flex-start </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".center"} value={'center'}> center </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".flex.end"} value={'flex-end'}> flex-end </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".stretch"} value={'stretch'}> stretch </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".baseline"} value={'baseline'}> baseline </MenuItem>);
                    break;
                case 'grid.spacing':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".spacing.0"} value={0}> 0 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".spacing.1"} value={1}> 1 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".spacing.2"} value={2}> 2 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".spacing.3"} value={3}> 3 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".spacing.4"} value={4}> 4 </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".spacing.5"} value={5}> 5 </MenuItem>);
                    break;
                case 'style.cursor':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + '.none'} value={''}> Select Cursor </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".default"} value={'default'}> default </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".pointer"} value={'pointer'}> pointer </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".crosshair"} value={'crosshair'}> crosshair </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".wait"} value={'wait'}> wait </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".initial"} value={'initial'}> initial </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".inherit"} value={'inherit'}> inherit </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".help"} value={'help'}> help </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".none"} value={'none'}> none </MenuItem>);
                    break;
                case 'style.display':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".inline"} value={'inline'}> inline </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".block"} value={'block'}> block </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".contents"} value={'contents'}> contents </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".flex"} value={'flex'}> flex </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".flow.root"} value={'flow-root'}> flow-root </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".grid"} value={'grid'}> grid </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".inline.block"} value={'inline-block'}> inline-block </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".inline.flex"} value={'inline-flex'}> inline-flex </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".inline.grid"} value={'inline-grid'}> inline-grid </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".inline.table"} value={'inline-table'}> inline-table </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".table"} value={'table'}> table </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".initial"} value={'initial'}> initial </MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".inherit"} value={'inherit'}> inherit </MenuItem>);
                    break;
                case 'base.conditions':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".is.equal.to"} value="is.equal.to">Is Equal</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".not.equal.to"} value="not.equal.to">Not Equal To</MenuItem>);
                    break;
                case 'string.conditions':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".empty"} value="">Is Empty</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".is.not.empty"} value="is.not.empty">Is Not Empty</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".contains"} value="contains">Contains</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".does.not.contain"} value="does.not.contain">Does Not Contain</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".starts.with"} value="starts.with">Starts With</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".does.not.start.with"} value="does.not.start.with">Does Not Start With</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".ends.with"} value="ends.with">Ends With</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".does.not.end.with"} value="does.not.end.with">Does Not End With</MenuItem>);
                    break;
                case 'null.conditions':
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".is.null"} value="is.null">Is Null</MenuItem>);
                    listItems.push(<MenuItem key={preKeyValue + commonName.toLowerCase() + ".is.not.null"} value="is.not.null">Is Not Null</MenuItem>);
                    break;
                default:
                    break;
            }
        }
        return listItems;
    };

    const getMediatorFieldListItems = (mediator: any, preKeyValue: string = '', includeVirtualFields: boolean = false) => {
        // console.log('%c getMediatorFieldListItems mediator: %O', 'color: hotpink;', mediator);
        let fieldSelectionElements: any[] = [];
        if (mediator) {
            let mediatorFields: any = (mediator.UdicciMediatorFields ? mediator.UdicciMediatorFields : null);
            let mediatorVirtualFields: any = (mediator.VirtualMediatorFields ? mediator.VirtualMediatorFields : null);
            // let linkedMediators: any = (mediator.LinkedUdicciMediators ? mediator.LinkedUdicciMediators : null);
            // console.log('%c mediatorFields: %O', 'color: maroon;', mediatorFields);
            // console.log('%c mediatorVirtualFields: %O', 'color: maroon;', mediatorVirtualFields);
            // console.log('%c linkedMediators: %O', 'color: maroon;', linkedMediators);

            let keyPrefix: string = preKeyValue + 'udicci.mediator.field';
            fieldSelectionElements.push(
                <MenuItem key={keyPrefix + '.new'} value={''}> Select Field </MenuItem>
            );
    
            if (mediatorFields && mediatorFields.length > 0) {
                mediatorFields.forEach(function(field: any, idx: number) {
                    // console.log('%c field: %O', 'color: blue;', field);
    
                    if (field.UdicciMediatorId <= 0) return true;
                    if (field.DataType === 'Json') return true;
    
                    let fieldSelectionKey = keyPrefix + '.field.' + field.UdicciMediatorFieldId + '.' + idx;
                    fieldSelectionElements.push(
                        <MenuItem key={fieldSelectionKey} value={field.JsonFieldName}> {(field.DisplayName ? field.DisplayName : field.Name)} </MenuItem>
                    );
                });
            }
    
            if (includeVirtualFields && mediatorVirtualFields && mediatorFields.length > 0) {
                mediatorVirtualFields.forEach(function(field: any, idx: number) {
                    // console.log('%c field: %O', 'color: blue;', field);
    
                    // if (field.UdicciMediatorId <= 0) return true;
                    // if (field.DataType === 'Json') return true;
                    if (field.DataType === 'Decimal') return true;
    
                    let fieldSelectionKey = keyPrefix + '.virtual.field.' + field.UdicciMediatorFieldId + '.' + idx;
                    // let fieldTableRowKey = 'table.row.virtual.field.' + field.UdicciMediatorFieldId + '.' + idx;
                    fieldSelectionElements.push(
                        <MenuItem key={fieldSelectionKey} value={field.JsonFieldName}> {field.Name} </MenuItem>
                    );
                });
            }
        }
        return fieldSelectionElements;
    }

    const getPaneContentDesign = (pane: any) => {
        // console.log('%c getPaneContentDesign pane: %O', 'color: hotpink;', pane);
        let plugins: any = null;

        let content: any = (pane && pane.content ? pane.content : null);
        // console.log('%c getPaneContentDesign content: %O', 'color: maroon;', content);

        if (content) {
            plugins = { content: content };
        } else {
            let contentDesign: any = (pane && pane.contentDesign ? pane.contentDesign : null);
            plugins = analyzePaneContentDesign(contentDesign);
        }

        // console.log('%c getPaneContentDesign plugins: %O', 'color: hotpink;', plugins);
        return plugins;
    }

    const getPaneRichTextValue = (plugInData: any) => {
        let data: any = null;
        if (plugInData) {
            // console.log('%c getPaneRichTextValue plugInData: %O', 'color: maroon;', plugInData);
            if (plugInData.draft) data = plugInData.draft;
            if (plugInData.slate && !plugInData.draft) {
                if (!data) data = {};
                if (!data.blocks) data.blocks = [];
                if (!data.entityMap) data.entityMap = {};
                forEach(plugInData.slate, function(itm: any, is: number) {
                    // console.log('%c itm: %O', 'color: purple;', itm);
                    let chldrn: any[] = (itm && itm.children ? itm.children : []);
                    // console.log('%c chldrn: %O', 'color: purple;', chldrn);
                    forEach(chldrn, function(chld: any, ic: number) {
                        // console.log('%c chld: %O', 'color: purple;', chld);
                        let chldText: string = (chld.text ? chld.text : '');
                        // console.log('%c chldText: %O', 'color: purple;', chldText);
                        data.blocks.push({
                            data: {},
                            depth: 0,
                            entityRanges: [],
                            inlineStyleRanges: [],
                            key: generateUID(),
                            text: chldText,
                            type: 'unstyled'
                        });
                    });
                });
            }
        }
        return data;
    }

    const analyzePaneContentDesign = (contentDesign: any) => {
        // console.log('%c analyzePaneContentDesign contentDesign: %O', 'color: hotpink;', contentDesign);
        let plugins: any = {};
        let rows: any[] = (contentDesign && contentDesign.rows ? contentDesign.rows : []);
        processContentDesignRows(rows, plugins);
        // console.log('%c analyzePaneContentDesign plugins: %O', 'color: hotpink;', plugins);
        return plugins;
    }

    const processContentDesignRows = (rows: any[], plugins: any) => {
        // console.log('%c processContentDesignRows rows: %O', 'color: green;', rows);
        // console.log('%c processContentDesignRows plugins: %O', 'color: green;', plugins);
        forEach(rows, (row: any, idxRow: number) => { processContentDesignRow(row, idxRow, plugins); });
    }

    const processContentDesignRow = (row: any, rowIndex: number, plugins: any) => {
        // console.log('%c processContentDesignRow row: %O', 'color: teal;', row);
        // console.log('%c processContentDesignRow rowIndex: %O', 'color: teal;', rowIndex);
        // console.log('%c processContentDesignRow plugins: %O', 'color: teal;', plugins);
        if (!row) return null;
        let cells: any[] = (row.cells ? row.cells : []);
        processContentDesignCells(cells, plugins);
    }

    const processContentDesignCells = (cells: any[], plugins: any) => {
        // console.log('%c processContentDesignCells cells: %O', 'color: navy;', cells);
        // console.log('%c processContentDesignCells plugins: %O', 'color: navy;', plugins);

        forEach(cells, (cell: any, idxCell: number) => { processContentDesignCell(cell, idxCell, plugins); });
    }

    const processContentDesignCell = (cell: any, cellIndex: number, plugins: any) => {
        // console.log('%c processContentDesignCell cell: %O', 'color: maroon;', cell);
        // console.log('%c processContentDesignCell cellIndex: %O', 'color: maroon;', cellIndex);
        // console.log('%c processContentDesignCell plugins: %O', 'color: maroon;', plugins);

        let cellRows: any[] = (cell.rows ? cell.rows : []);
        // console.log('%c cellRows: %O', 'color: red;', cellRows);
        if (cellRows.length > 0) processContentDesignRows(cellRows, plugins);

        getContentDesignPluginFromCell(cell, plugins);
    }

    const getContentDesignPluginFromCell = (cell: any, plugins: any) => {
        // console.log('%c processContentDesignPlugin cell: %O', 'color: orange;', cell);

        let cellId: string = (cell.id ? cell.id : '');
        // console.log('%c processContentDesignPlugin cellId: %O', 'color: orange;', cellId);
        let UdicciPlugin: any = (cell.plugin ? cell.plugin : null);
        let dataI18n: any = (cell.dataI18n ? cell.dataI18n : null);
        if (!UdicciPlugin) return;

        let size: any = (cell.size ? cell.size : null);

        if (!plugins) plugins = { content: [], wrapper: null };
        let pluginsContent: any[] = (plugins.content ? plugins.content : []);
        // console.log('%c pluginsContent: %O', 'color: orange;', pluginsContent);

        let newPanePlugin: any = {
            id: cellId,
            size: size,
            visible: true,
            plugin: UdicciPlugin,
            data: dataI18n,
            wrapper: null
        };

        pluginsContent.push(newPanePlugin)
        plugins.content = pluginsContent;
    }

    const getUdicciThemeTemplate = (templateName: string, options: any): Theme => {
        // console.log('%c getUdicciThemeTemplate cell: %O', 'color: maroon;', cell);

        let settingsComponent: any = {};

        if (!options) options = { styleOverrides: { root: { float: 'right', marginLeft: '8px' } } };
        settingsComponent = options;
        // {
        //     styleOverrides: {
        //         // root: (data: any) => ({
        //         //         ...(data.ownerState.variant === 'contained' && data.ownerState.color === 'primary' && {
        //         //             backgroundColor: '#202020',
        //         //             color: '#fff',
        //         //     }),
        //         // }),
        //     },
        // };

        let settings: any = { MuiIconButton: settingsComponent };
        if (templateName === 'MuiIconButton') {
            settings = { MuiIconButton: settingsComponent };
        }
        if (templateName === 'MuiFab') {
            settings = { MuiFab: settingsComponent };
        }
        if (templateName === 'MuiSpeedDial') {
            settings = { MuiSpeedDial: settingsComponent };
        }

        let themeSettings: any = { components: settings };
        return createTheme(themeSettings);
    }

    const generateUID = () => {
        // generate the UID from two parts to ensure the random number provides enough bits.
        let firstPart: number = (Math.random() * 46656) | 0;
        let secondPart: number = (Math.random() * 46656) | 0;
        let thirdPart: number = (Math.random() * 46656) | 0;
        let strFirstPart: string = ("000" + firstPart.toString(36)).slice(-3);
        let strSecondPart: string = ("000" + secondPart.toString(36)).slice(-3);
        let strThirdPart: string = ("000" + thirdPart.toString(36)).slice(-3);
        return strFirstPart + strSecondPart + strThirdPart;
    }

    const convertColorToString = (color: any, defaultValue: string) => {
        let colorValue: string = (defaultValue ? defaultValue : '#000');
        if (color) {
            if (color.r !== undefined && color.g !== undefined && color.b !== undefined && color.a !== undefined) {
                colorValue = `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`;
            } else if (color.r !== undefined && color.g !== undefined && color.b !== undefined && color.a !== undefined) {
                colorValue = `rgb(${ color.r }, ${ color.g }, ${ color.b })`;
            } else {
                colorValue = color;
            }
        }
        return colorValue;
    };

    const lookupStandardInformation = (root: string, key: string) => {
        // console.log('%c lookupStandardInformation root: %O', 'color: maroon;', root);
        // console.log('%c lookupStandardInformation key: %O', 'color: maroon;', key);
        let rslt: any = (StandardNamesLookup && StandardNamesLookup[root] && StandardNamesLookup[root][key] ? StandardNamesLookup[root][key] : null);
        // console.log('%c lookupStandardInformation rslt: %O', 'color: maroon;', rslt);
        return rslt;
    }

    const getValueFromSettings = (settings: any, variant: string, settingName: string, defaultValue: string | null | undefined = undefined) => {
        // console.log('%c getValueFromSettings settings: %O', 'color: maroon;', settings);
        // console.log('%c getValueFromSettings variant: %O', 'color: maroon;', variant);
        // console.log('%c getValueFromSettings settingName: %O', 'color: maroon;', settingName);
        // console.log('%c getValueFromSettings defaultValue: %O', 'color: maroon;', defaultValue);
        let rslt: any = (settings && settings[settings] && settings[settings][settingName] ? settings[settings][settingName] : null);
        // console.log('%c getValueFromSettings rslt: %O', 'color: maroon;', rslt);
        if (!rslt && settingName === 'color') {
            let colorSettings: any = (settings && settings[variant] && settings[variant].r ? settings[variant] : null);
            // console.log('%c getValueFromSettings colorSettings: %O', 'color: maroon;', colorSettings);
            if (colorSettings) rslt = convertColorToString(colorSettings, '#000');
        }
        // console.log('%c getValueFromSettings rslt: %O', 'color: maroon;', rslt);
        return rslt;
    }

    const humanReadableDuration = (msDuration: number): string => {
        const h = Math.floor(msDuration / 1000 / 60 / 60);
        const m = Math.floor((msDuration / 1000 / 60 / 60 - h) * 60);
        const s = Math.floor(((msDuration / 1000 / 60 / 60 - h) * 60 - m) * 60);
    
        // To get time format 00:00:00
        const seconds: string = s < 10 ? `0${s}` : `${s}`;
        const minutes: string = m < 10 ? `0${m}` : `${m}`;
        const hours: string = h < 10 ? `0${h}` : `${h}`;
    
        return `${hours}:${minutes}:${seconds}`;
    }

    const getPastedPane = () => {
        var paneCopiedAsString: string | null = sessionStorage.getItem('udicci.copied.pane');
        let pastedPane: any = null;
        if (paneCopiedAsString && paneCopiedAsString.length > 0) {
            try {
                pastedPane = JSON.parse(paneCopiedAsString);
            } catch (err: any) {

            }
        }
        // console.log('%c PortaPane getPastedPane pastedPane: %O', 'color: blue;', pastedPane);
        return pastedPane;
    }

    const getColorValue = (ctc: any) => {
        if (typeof(ctc) === 'object') {
            return `rgba(${ ctc.r }, ${ ctc.g }, ${ ctc.b }, ${ ctc.a })`;
        } else {
            return ctc;
        }
    }

    const not = (a: readonly number[], b: readonly number[]) => {
        return a.filter((value) => b.indexOf(value) === -1);
    }
    
    const intersection = (a: readonly number[], b: readonly number[]) => {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    const getAvailableActions = () => {
        return [
            { // view
                command: 'view',
                description: 'View a record.',
                defaultRequiredPermissions: [
                    {
                        permission: 'CanView',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 }
                    }
                ]
            },
            { // edit
                command: 'edit',
                description: 'Edit a record.',
                defaultRequiredPermissions: [
                    {
                        permission: 'CanEdit',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 }
                    }
                ]
            },
            { // save
                command: 'save',
                description: 'Save a record.',
                // showWhen: { field: 'isDirty', comparison: 'Equals', value: true },
                enableWhen: { field: 'isDirty', comparison: 'Equals', value: true },
                defaultRequiredPermissions: [
                    {
                        permission: 'CanAdd',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Less Than,Equal To', value: 0 }
                    },
                    {
                        permission: 'CanEdit',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 }
                    }
                ]
            },
            { // delete
                command: 'delete',
                description: 'Delete a record.',
                showWhen: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 },
                // enableWhen: { field: 'isDirty', comparison: 'Equals', value: true },
                defaultRequiredPermissions: [
                    {
                        permission: 'CanDelete',
                        required: true
                    }
                ]
            },
            { // back
                command: 'back',
                description: 'Return to the previous form or list.',
                // showWhen: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 },
                // enableWhen: { field: 'isDirty', comparison: 'Equals', value: true },
                defaultRequiredPermissions: null
            },
            { // createProfile
                command: 'createProfile',
                description: 'Create a Profile using details from another mediator, in this case limited to People data.',
                // showWhen: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 },
                // enableWhen: { field: 'isDirty', comparison: 'Equals', value: true },
                defaultRequiredPermissions: null,
                settings: {
                    limitToSource: ['People','Organizations'],
                    limitToTarget: 'Udicci Profiles'
                }
            },
            { // goToProfile
                command: 'goToProfile',
                description: 'Go To a Profile using details from the People or Organization Mediated Records record value.',
                defaultRequiredPermissions: null,
                settings: {
                    limitToSource: ['People','Organizations'],
                    limitToTarget: 'Udicci Profiles'
                }
            },
            { // create
                command: 'create',
                description: 'Create a new record in mediator "a" using record values from mediator "b".',
                // showWhen: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 },
                // enableWhen: { field: 'isDirty', comparison: 'Equals', value: true },
                defaultRequiredPermissions: null
            },
            { // permissions
                command: 'permissions',
                description: 'View Record Permissions.',
                defaultRequiredPermissions: [
                    {
                        permission: 'CanEdit',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 }
                    }
                ]
            },
            { // structure
                command: 'structure',
                description: 'View Record Structure.',
                defaultRequiredPermissions: [
                    {
                        permission: 'CanEdit',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 }
                    }
                ]
            },
            { // Card Designer
                command: 'card designer',
                description: 'View Card Designer for this record.',
                defaultRequiredPermissions: [
                    {
                        permission: 'CanEdit',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 }
                    }
                ]
            },
            { // schedule
                command: 'schedule',
                description: 'Schedule a record to show it on the calendar.',
                defaultRequiredPermissions: [
                    {
                        permission: 'CanView',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 }
                    }
                ],
                showWhen: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 },
                settings: {
                    limitToSource: ['Services','Tasks'],
                    limitToTarget: null
                }
            },
            { // purchase
                command: 'purchase',
                description: 'Purchase an item.',
                defaultRequiredPermissions: [
                    {
                        permission: 'CanView',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 }
                    }
                ],
                showWhen: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 },
                settings: {
                    limitToSource: ['Products'],
                    limitToTarget: null
                }
            },
            { // engagementRequest
                command: 'engagementRequest',
                description: 'Engagement Request',
                defaultRequiredPermissions: [
                    {
                        permission: 'CanAdd',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 }
                    }
                ],
                showWhen: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 },
            },
            { // broadcastMVT
                command: 'broadcastMVT',
                description: 'Broadcast Mutual Value Token',
                defaultRequiredPermissions: [
                    {
                        permission: 'CanAdd',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 }
                    }
                ],
                showWhen: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 },
            },
            { // mintNFT
                command: 'mintNFT',
                description: 'Mint NFT Token',
                defaultRequiredPermissions: [
                    {
                        permission: 'CanAdd',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 }
                    }
                ],
                showWhen: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 },
            },
        ];
    }

    const getDefaultActions = () => {
        return [
            {  // edit
                uid: generateUID(),
                command: 'edit',
                componentType: 'span', // 'button' | 'span' | 'anchor' | 'Icon'
                // showWhen: { field: 'isDirty', comparison: 'Equals', value: true },
                enableWhen: null,
                label: {
                    text: 'edit',
                    className: null,
                    styleOverrides: null
                },
                requiredPermissions: [
                    {
                        permission: 'CanEdit',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 }
                    }
                ],
                componentLookAndFeel: {
                    className: null,
                    styleOverrides: null,
                    inverted: false, // Semantic UI setting only (Button, Icon)
                    color: 'blue' // Semantic UI setting only (Button, Icon)
                }
            },
            { // save
                uid: generateUID(),
                command: 'save',
                componentType: 'button', // 'button' | 'span' | 'anchor' | 'Icon'
                // showWhen: { field: 'isDirty', comparison: 'Equals', value: true },
                enableWhen: { field: 'isDirty', comparison: 'Equals', value: true },
                label: {
                    text: 'Save',
                    className: null,
                    styleOverrides: null
                },
                requiredPermissions: [
                    {
                        permission: 'CanAdd',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Less Than,Equal To', value: 0 }
                    },
                    {
                        permission: 'CanEdit',
                        required: true,
                        when: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 }
                    }
                ],
                componentLookAndFeel: {
                    className: null,
                    styleOverrides: null,
                    inverted: false, // Semantic UI setting only (Button, Icon)
                    color: 'blue' // Semantic UI setting only (Button, Icon)
                }
            },
            { // delete
                uid: generateUID(),
                command: 'delete',
                componentType: 'button', // 'button' | 'span' | 'Icon'
                showWhen: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 },
                // enableWhen: { field: 'isDirty', comparison: 'Equals', value: true },
                label: {
                    text: 'Delete',
                    className: null,
                    styleOverrides: null
                },
                requiredPermissions: [
                    {
                        permission: 'CanDelete',
                        required: true
                    }
                ],
                componentLookAndFeel: {
                    className: null,
                    styleOverrides: null,
                    inverted: false, // Semantic UI setting only (Button, Icon)
                    color: 'red' // Semantic UI setting only (Button, Icon)
                }
            },
            { // back
                uid: generateUID(),
                command: 'back',
                componentType: 'button', // 'button' | 'span' | 'Icon'
                // showWhen: { field: 'udicciRecordId', comparison: 'Greater Than', value: 0 },
                // enableWhen: { field: 'isDirty', comparison: 'Equals', value: true },
                label: {
                    text: 'Return to List',
                    className: null,
                    styleOverrides: null
                },
                requiredPermissions: null,
                componentLookAndFeel: {
                    className: null,
                    styleOverrides: null,
                    inverted: false, // Semantic UI setting only (Button, Icon)
                    color: null // Semantic UI setting only (Button, Icon)
                }
            }
        ];
    }

    const getTheContextMediatorNames = (rootStructure: any) => {
        // console.log('%c getTheContextMediatorNames rootStructure: %O', 'color: red;', rootStructure);
        if (!rootStructure) return [];
        let mediatorNames: string[] = [];
        // mediatorNames.push(rootStructure.Name);
        // console.log('%c getTheContextMediatorNames mediatorNames: %O', 'color: red;', mediatorNames);

        let parentMediatorNames: string[] = [];
        parentMediatorNames = getContextMediatorNamesForStructure(rootStructure, parentMediatorNames, 'parent');
        // console.log('%c getTheContextMediatorNames parentMediatorNames: %O', 'color: red;', parentMediatorNames);
        let childMediatorNames: string[] = [];
        childMediatorNames = getContextMediatorNamesForStructure(rootStructure, childMediatorNames, 'child');
        // console.log('%c getTheContextMediatorNames childMediatorNames: %O', 'color: red;', childMediatorNames);
        mediatorNames.unshift(...parentMediatorNames);
        mediatorNames.unshift(rootStructure.Name);
        mediatorNames.unshift(...childMediatorNames);
        // console.log('%c getTheContextMediatorNames mediatorNames: %O', 'color: red;', mediatorNames);
        return mediatorNames;
    };

    const getContextMediatorNamesForStructure = (structure: any, mediatorNames: string[], contextChain: string) => {
        // console.log('%c getContextMediatorNamesForStructure structure: %O', 'color: red;', structure);
        // console.log('%c getContextMediatorNamesForStructure structure: %O', 'color: red;', structure);
        if (!structure) return mediatorNames;

        let structureMediatorName: string = (structure && structure.Name ? structure.Name : '');
        // console.log('%c getContextMediatorNamesForStructure structureMediatorName: %O', 'color: purple;', structureMediatorName);
        let lums: any = (structure && structure.LinkedUdicciMediators ? structure.LinkedUdicciMediators : null);
        // console.log('%c getContextMediatorNamesForStructure lums: %O', 'color: purple;', lums);
        if (!lums || (lums && lums.length <= 0)) return mediatorNames;

        forEach(lums, (lum: any, lumIndex: number) => {
            if (lum.LeftUdicciMediatorName === structureMediatorName && contextChain === 'parent') {
                if (mediatorNames.indexOf(lum.RightUdicciMediatorName) < 0) {
                    mediatorNames.push(lum.RightUdicciMediatorName);
                    // mediatorNames.unshift(lum.RightUdicciMediatorName);

                    let rightLumStructure: any = getMediatorStructure(lum.RightUdicciMediatorName);
                    // console.log('%c rightLumStructure: %O', 'color: hotpink;', rightLumStructure);
                    if (rightLumStructure) mediatorNames = getContextMediatorNamesForStructure(rightLumStructure, mediatorNames, contextChain);
                }
            } else if (lum.RightUdicciMediatorName === structureMediatorName && contextChain === 'child') {
                if (mediatorNames.indexOf(lum.LeftUdicciMediatorName) < 0) {
                    // mediatorNames.push(lum.LeftUdicciMediatorName);
                    mediatorNames.unshift(lum.LeftUdicciMediatorName);

                    let leftLumStructure: any = getMediatorStructure(lum.LeftUdicciMediatorName);
                    // console.log('%c leftLumStructure: %O', 'color: hotpink;', leftLumStructure);
                    if (leftLumStructure) mediatorNames = getContextMediatorNamesForStructure(leftLumStructure, mediatorNames, contextChain);
                }
            }
        });

        return mediatorNames;
    };

    const getContextMediatorsForStructure = (rootStructure: any) => {
        // console.log('%c getContextMediatorsForStructure rootStructure: %O', 'color: red;', rootStructure);
        if (!rootStructure) return [];

        let contextMediators: any[] = [];
        contextMediators = getRelatedContextMediatorsForStructure(rootStructure, contextMediators);
        // console.log('%c getContextMediatorsForStructure contextMediators: %O', 'color: red;', contextMediators);
        return contextMediators;
    };

    const getRelatedContextMediatorsForStructure = (structure: any, contextMediators: any[]) => {
        // console.log('%c getRelatedContextMediatorsForStructure structure: %O', 'color: red;', structure);
        // console.log('%c getRelatedContextMediatorsForStructure contextMediators: %O', 'color: red;', contextMediators);
        if (!structure) return contextMediators;

        let lums: any = (structure && structure.LinkedUdicciMediators ? structure.LinkedUdicciMediators : null);
        // console.log('%c getRelatedContextMediatorsForStructure lums: %O', 'color: purple;', lums);
        if (!lums || (lums && lums.length <= 0)) return contextMediators;

        forEach(lums, (lum: any, lumIndex: number) => {
            // console.log('%c lum: %O', 'color: orange;', lum);
            let pm: string = lum.LeftUdicciMediatorName;
            let cm: string = lum.RightUdicciMediatorName;
            let chk: any = find(contextMediators, (ctxMed: any) => { return ctxMed.name === cm && ctxMed.parent === pm });
            if (!chk) {
                // console.log('%c chk: %O', 'color: orange;', chk);
                let pLbl: string = (lum.MaxAssociationsToLeft === -1 ? pm : Pluralize.singular(pm));
                let lbl: string = (lum.MaxAssociationsToRight === -1 ? cm : Pluralize.singular(cm));
    
                let rel: string = '';
                rel += (lum.MaxAssociationsToLeft === -1 ? 'Many' : 'One');
                rel += ' to ';
                rel += (lum.MaxAssociationsToRight === -1 ? 'Many' : 'One');
    
                let contextMediatorRecord: any = {
                    relationName: lum.Name,
                    relation: rel,
                    parent: pm,
                    parentLabel: pLbl,
                    name: cm,
                    label: lbl,
                };
                contextMediators.push(contextMediatorRecord);

                let lumStructure: any = getMediatorStructure(cm);
                if (lumStructure) contextMediators = getRelatedContextMediatorsForStructure(lumStructure, contextMediators);
            }
        });

        return contextMediators;
    };

    const formatStringForDisplay = (stringValue: string, noWrapper: boolean | undefined = false, overflowAtOneLine: boolean | undefined = false) => {
        // console.log('%c stringValue: %O', 'color: green;', stringValue);
        var rval: any[] = [];
        var workingString: string = stringValue.toString();
        if (workingString && workingString.indexOf('\n') > 0) {
            var lines: string[] = workingString.split('\n');
            if (overflowAtOneLine) {
                var key = 'sv.overflown';
                if (noWrapper) {
                    rval.push(<Fragment key={key}>{lines[0]} ...</Fragment>);
                } else {
                    rval.push(<Fragment key={key}><span>{lines[0]} ...</span><br /></Fragment>);
                }
            } else {
                // going to attempt to trim beginning of string line breaks ... in other words trim them from the start
                let itemHasBeenWritten: boolean = false;
                lines.forEach((item: string, idx: number) => {
                    // console.log('%c item (%s): %O', 'color: green;', item.trim().length.toString(), item);
                    // console.log('%c idx: %O', 'color: green;', idx);
                    // console.log('%c itemHasBeenWritten: %O', 'color: green;', itemHasBeenWritten);
                    if (item.trim().length <= 0 && !itemHasBeenWritten) return true;

                    var key = 'sv.' + idx;
                    if (noWrapper) {
                        rval.push(<Fragment key={key}>{item}</Fragment>);
                        itemHasBeenWritten = true;
                    } else {
                        rval.push(<Fragment key={key}><span>{item}</span><br /></Fragment>);
                        itemHasBeenWritten = true;
                    }
                });
            }
        } else {
            rval.push(workingString);
        }
    
        return rval;
    }

    const getSentencesArrayFromString = (stringValue: string) => {
        var workingString: string = stringValue.toString();
        workingString = workingString.replace(/udicci.it/ig, 'Udicci_IT');

        let sentenceDelim: string = '.';
        let newLineDelim: string = '\n';
    
            var sentences: string[] = [];
        if (workingString && workingString.indexOf(sentenceDelim) > 0) {
            // console.log('%c workingString: %O', 'color: green;', workingString);
            var sentencesWorking: string[] = [];
            workingString.split(".").forEach((x: string) => { sentencesWorking.push(x + sentenceDelim); });
            // console.log('%c sentencesWorking: %O', 'color: green;', sentencesWorking);

            sentencesWorking.forEach((sw: string) => {
                let xw: string = sw.trim();
                if (xw.indexOf(newLineDelim) >= 0) {
                    let allSentenceBlocks: string[] = sw.split(newLineDelim);
                    forEach(allSentenceBlocks, (sentenceBlock: string, idxSentenceBlock: number) => {
                        let cleanSentenceBlock: string = sentenceBlock;
                        cleanSentenceBlock = cleanSentenceBlock.replace('\r', '');
                        cleanSentenceBlock = cleanSentenceBlock.trim();
                        cleanSentenceBlock = (cleanSentenceBlock.indexOf('.') === 0 ? cleanSentenceBlock.substring(1) : cleanSentenceBlock);
                        let nlCount: number = 1;
                        let nlFound: boolean = false;
                        if (idxSentenceBlock > 0) {
                            let prevSentenceBlockIndex: number = sentences.length - 1;
                            let prevSentenceBlock: string = sentences[prevSentenceBlockIndex];
                            if (prevSentenceBlock.indexOf(UDICCI_NEW_LINE_CONSTANT) === 0) {
                                let pnlCount: string = prevSentenceBlock.replace(UDICCI_NEW_LINE_CONSTANT, '');
                                if (pnlCount) nlCount += parseInt(pnlCount, 10);
                                sentences[prevSentenceBlockIndex] = UDICCI_NEW_LINE_CONSTANT + nlCount.toString();
                                nlFound = true;
                            }
                        }
                        if (!nlFound) sentences.push(UDICCI_NEW_LINE_CONSTANT + nlCount.toString());
                        if (cleanSentenceBlock.indexOf('Udicci_IT') >= 0) {
                            cleanSentenceBlock = cleanSentenceBlock.replace(/Udicci_IT/ig, 'Udicci.IT');
                        }
                        if (cleanSentenceBlock && cleanSentenceBlock !== '.') sentences.push(cleanSentenceBlock);
                    });
                } else {
                    if (xw !== '.') sentences.push(xw);
                }
            });
        } else {
            let sentenceDisplay: string = workingString;
            if (sentenceDisplay.indexOf('Udicci_IT') >= 0) sentenceDisplay = sentenceDisplay.replace(/Udicci_IT/ig, 'Udicci.IT');
            sentences.push(sentenceDisplay);
        }

        let sentenceItems: any[] = [];
        forEach(sentences, (s: string, sidx: number) => {
            sentenceItems.push({ sentenceIndex: sidx, sentence: s, uid: generateUID() });
        });
        // console.log('%c sentenceItems: %O', 'color: green;', sentenceItems);
        return sentenceItems;
    };

    return {
        getMediatorContext, generateUID, getValueFromSettings, lookupStandardInformation, getUserEstimatedTimeRemainingInSession,
        getMediatorStructure, getMediatorData, getMediatorDataForParent, getRecordContext, getRecordContextByView,
        initializePermissions, openUrl, preloadMediatorData, preloadFilteredMediatorData, humanReadableDuration, getColorValue,
        swapArrayElements, getCommonSelectionListItems, getPaneContentDesign, getMediatorFieldListItems, 
        getUdicciThemeTemplate, getPaneRichTextValue, convertColorToString, getPastedPane, getTheContextMediatorNames,
        not, intersection, getWeekNumber, getAvailableActions, getDefaultActions, getContextMediatorsForStructure, 
        formatStringForDisplay, getSentencesArrayFromString
    };
}

export const UDICCI_NEW_LINE_CONSTANT: string = '.it.NEWLINE.';

export const TabPanel = (props: any) => {
    const { children, value, index, elevation, ...other } = props;

    let tabPanelSettings = (other ? other : {});
    tabPanelSettings.id = 'tabpanel-' + index;
    tabPanelSettings.role = 'tabpanel';
    tabPanelSettings.hidden = value !== index;
    let ariaLabelBy = 'tab-' + index;
    let displayElement: any = null;
    if (elevation === 0) {
        displayElement = ( <div> {children} </div> );
    } else {
        displayElement = ( <Box p={elevation}> {children} </Box> );
    }
    return (
        <div {...tabPanelSettings} aria-labelledby={ariaLabelBy}>
            {value === index && displayElement}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    elevation: PropTypes.number,
    style: PropTypes.any,
};

TabPanel.defaultProps = {
    elevation: 3,
};

export const VerticalTabPanel = (props: any) => {
    const { children, value, index, ...other } = props;
  
    return (
        <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
        {value === index && (
            <Box sx={{ pl: 3 }}>
                <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
  
/**
 * 
 * Usage:
 * 
 */
