import { Fragment, useContext, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';

import PerspectiveMenuIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import { udicciStyles } from 'src/theme/shared-styles';

import { forEach, filter } from 'underscore';

import { UdicciContext } from 'src/context/udicci-context';

import useUdicciRecord from 'src/hooks/useUdicciRecord';

import { UdicciRecord } from 'src/classes/udicci-record';

const SocialIconContainer = styled('div')(({ theme }) => ({
    float: 'left',
    margin: '8px',
}));

export default function Perspectives(props: any) {
    // console.log('%c Perspectives props: %O', 'color: gold;', props);

    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const udicciContext = useContext(UdicciContext);
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    let { udicci, data } = udicciContext.state;
    // console.log('%c Perspectives data: %O', 'color: blue;', data);
    // console.log('%c Perspectives udicci: %O', 'color: blue;', udicci);

    let {
        // selectedProfile,
        currentUser
    } = udicci;
    // console.log('%c Perspectives selectedProfile: %O', 'color: blue;', selectedProfile);
    // console.log('%c Perspectives currentUser: %O', 'color: blue;', currentUser);

    // let curUserId: number = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
    // console.log('%c Me curUserId: %O', 'color: maroon;', curUserId);

    let userSocialIcon = (currentUser && currentUser.mySocialIcon ? currentUser.mySocialIcon : null);
    // console.log('%c Perspectives userSocialIcon: %O', 'color: blue;', userSocialIcon);

    let { udicciRecord, setRecord, saveRecord, savePerspective, checkForRecordPerspectives, deletePerspective } = useUdicciRecord(props.record);
    // console.log('%c Perspectives udicciRecord: %O', 'color: blue;', udicciRecord);

    let recordMediator = (udicciRecord.udicciMediator ? udicciRecord.udicciMediator : '');
    // console.log('%c recordMediator: %O', 'color: purple;', recordMediator);
    let recordId = (udicciRecord.data ? udicciRecord.recordId : 0);
    // console.log('%c recordId: %O', 'color: purple;', recordId);

    let recordPerspectivesDefined = (udicciRecord && udicciRecord.perspectives ? true : false);
    // console.log('%c recordPerspectivesDefined: %O', 'color: purple;', recordPerspectivesDefined);
    let recordPerspectives = (udicciRecord && udicciRecord.perspectives ? udicciRecord.perspectives : []);
    // console.log('%c recordPerspectives: %O', 'color: purple;', recordPerspectives);
    let recordHasPerspectives = (recordPerspectives.length > 0 ? true : false);
    // console.log('%c recordHasPerspectives: %O', 'color: purple;', recordHasPerspectives);

    const [checkPerspectives, setCheckPerspectives] = useState<boolean>(true);
    const [formPerspective, setFormPerspective] = useState<any>(null);
    const [selectedPerspectiveIndex, setSelectedPerspectiveIndex] = useState<any>(null);
    const [deletePerspectiveId, setDeletePerspectiveId] = useState<number>(-1);
    const [showPerspectiveForm, setShowPerspectiveForm] = useState<boolean>(false);
    const [savingPerspective, setSavingPerspective] = useState<boolean>(false);
    const [formMessage, setFormMessage] = useState<string>('');
    const [openPerspectiveMenu, setOpenPerspectiveMenu] = useState(false);
    const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);
    const [processingDelete, setProcessingDelete] = useState<boolean>(false);
    const [forcedUpdateState, forceUpdate] = useState<boolean>(false);
    // console.log('%c Perspectives showPerspectiveForm: %O', 'color: gold;', showPerspectiveForm);

    // console.log('%c formPerspective: %O', 'color: maroon;', formPerspective);

    function checkForKeyFields(record: UdicciRecord) {
        let tf = (record.keys && record.keys.title ? record.keys.title : '');
        let df = (record.keys && record.keys.description ? record.keys.description : '');
        // console.log('%c checkForKeyFields record: %O', 'color: red;', record);
        if (record.title !== record.data[tf]) record.title = record.data[tf];
        if (record.description !== record.data[df]) record.description = record.data[df];
        return record;
    }

    const changePerspectiveValue = (fieldName: any | null, evt: any) => {
        // console.log('%c changePerspectiveValue fieldName: %O', 'color: red;', fieldName);
        // console.log('%c changePerspectiveValue evt: %O', 'color: red;', evt);
        // console.log('%c changePerspectiveValue recordPerspectives: %O', 'color: red;', recordPerspectives);
        // console.log('%c udicciContext: %O', 'color: red;', udicciContext);
        if (!udicciRecord) return null;

        // let { data } = udicciContext.state;
        // console.log('%c changePerspectiveValue data: %O', 'color: maroon;', data);
        // console.log('%c changePerspectiveValue udicci: %O', 'color: maroon;', udicci);

        let structure: any = null;
        if (data) {
            let mediatorContext = data.find((x: any) => x.mediator === 'Perspectives' );
            // console.log('%c changePerspectiveValue mediatorContext: %O', 'color: red;', mediatorContext);
            if (mediatorContext && mediatorContext.structure) structure = mediatorContext.structure;
        }
        // console.log('%c changePerspectiveValue structure: %O', 'color: green;', structure);

        // // convert fieldName to UdicciMediatorField object
        let fields: any = null;
        if (structure) {
            fields = (structure.UdicciMediatorFields ? structure.UdicciMediatorFields : null);
        }
        // console.log('%c changePerspectiveValue fields: %O', 'color: maroon;', fields);

        let field: any = null;
        if (fields && fields.length > 0) {
            field = fields.find((x: any) => x.JsonFieldName === fieldName );
        }
        // console.log('%c changePerspectiveValue field: %O', 'color: maroon;', field);

        if (field) {
            // console.log('%c changePerspectiveValue evt: %O', 'color: red;', evt);
            // console.log('%c changePerspectiveValue field: %O', 'color: red;', field);
            // console.log('%c changePerspectiveValue mediaBit: %O', 'color: red;', mediaBit);

            let trgt = evt.target;
            let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
            // console.log('%c changePerspectiveValue newValue: %O', 'color: red;', newValue);

            // if (!mediaBit) return false;

            let fieldJsonKey = (field && field.JsonFieldName ? field.JsonFieldName : field.Name)
            // console.log('%c changePerspectiveValue fieldJsonKey: %O', 'color: red;', fieldJsonKey);

            let perspective: any = formPerspective;
            // console.log('%c changePerspectiveValue perspective: %O', 'color: maroon;', perspective);

            let updatedPerspectives = recordPerspectives;
            // console.log('%c Perspectives updatedPerspectives: %O', 'color: gold;', updatedPerspectives);

            let foundRecord: boolean = false;
            let foundRecordInAlternateList: boolean = false;
            let showPerspectiveFormAsLastStep: boolean = false;
            if (!perspective) {
                let newData: any = {};
                newData[fieldJsonKey] = newValue;
                let newPerspective: UdicciRecord = new UdicciRecord('Perspectives', newData, structure);
                // console.log('%c changePerspectiveValue newPerspective: %O', 'color: maroon;', newPerspective);
                newPerspective.data.CreatedByUserId = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
                perspective = newPerspective;
                // console.log('%c changePerspectiveValue perspective: %O', 'color: maroon;', perspective);
                perspective.isDirty = true;
                perspective = checkForKeyFields(perspective);
                updatedPerspectives.push(perspective);
                showPerspectiveFormAsLastStep = true;
            } else {
                forEach(updatedPerspectives, function(p: UdicciRecord, i: number) {
                    // console.log('%c p: %O', 'color: purple;', p);
                    // console.log('%c mediaBit Index: %O', 'color: purple;', i);
                    // console.log('%c perspective: %O', 'color: purple;', perspective);
                    if (perspective !== null && p.recordId === perspective.recordId) {
                        updatedPerspectives[i].data[fieldJsonKey] = newValue;
                        updatedPerspectives[i] = checkForKeyFields(updatedPerspectives[i]);
                        updatedPerspectives[i].isDirty = true;
                        foundRecord = true;
                    }
                });
                // console.log('%c changePerspectiveValue foundRecord: %O', 'color: maroon;', foundRecord);
                // if (!foundRecord && recordPerspectives && recordPerspectives.length > 0) {
                //     forEach(recordPerspectives, function(p: UdicciRecord, i: number) {
                //         // console.log('%c p: %O', 'color: purple;', p);
                //         // console.log('%c mediaBit Index: %O', 'color: purple;', i);
                //         // console.log('%c perspective: %O', 'color: purple;', perspective);
                //         if (perspective !== null && p.recordId === perspective.recordId) {
                //             recordPerspectives[i].data[fieldJsonKey] = newValue;
                //             recordPerspectives[i] = checkForKeyFields(recordPerspectives[i]);
                //             recordPerspectives[i].isDirty = true;
                //             foundRecord = true;
                //             foundRecordInAlternateList = true;
                //         }
                //     });
                // }
            }
            // console.log('%c changePerspectiveValue foundRecord: %O', 'color: maroon;', foundRecord);
            // console.log('%c changePerspectiveValue foundRecordInAlternateList: %O', 'color: maroon;', foundRecordInAlternateList);
            // console.log('%c updatedPerspectives: %O', 'color: purple;', updatedPerspectives);

            udicciRecord.perspectives = updatedPerspectives;
            // setRecord(udicciRecord);

            setFormPerspective(perspective);

            if (showPerspectiveFormAsLastStep) setShowPerspectiveForm(true);
            forceUpdate(!forcedUpdateState);
        }
    };

    const removePerspective = (perspectiveIdToRemove: number | null) => {
        // console.log('%c removePerspective perspectiveIdToRemove: %O', 'color: red;', perspectiveIdToRemove);

        let updatedPerspectives = recordPerspectives;
        // console.log('%c Perspectives updatedPerspectives: %O', 'color: gold;', updatedPerspectives);

        if (updatedPerspectives && updatedPerspectives.length > 0) {
            let newPerspectiveList: any[] = [];
            forEach(updatedPerspectives, function(p: UdicciRecord, i: number) {
                // console.log('%c p: %O', 'color: purple;', p);
                // console.log('%c mediaBit Index: %O', 'color: purple;', i);
                if (p.recordId !== perspectiveIdToRemove)
                    newPerspectiveList.push(p);
            });
            // console.log('%c newPerspectiveList: %O', 'color: purple;', newPerspectiveList);

            udicciRecord.perspectives = updatedPerspectives;
            setRecord(udicciRecord);

            setShowPerspectiveForm(false);
            setFormPerspective(null);
            setDeletePerspectiveId(-1);
            forceUpdate(!forcedUpdateState);
        }
    };

    function cancelPerspectives() {
        // console.log('%c cancelPerspectives props: %O', 'color: red;', props);

        let updatedPerspectives = recordPerspectives;
        // console.log('%c Perspectives updatedPerspectives: %O', 'color: gold;', updatedPerspectives);

        if (updatedPerspectives && updatedPerspectives.length > 0) {
            let newPerspectiveList: any[] = [];
            forEach(updatedPerspectives, function(p: UdicciRecord, i: number) {
                // console.log('%c p: %O', 'color: purple;', p);
                // console.log('%c mediaBit Index: %O', 'color: purple;', i);
                if (p.recordId !== 0)
                    newPerspectiveList.push(p);
            });
            // console.log('%c newPerspectiveList: %O', 'color: purple;', newPerspectiveList);

            udicciRecord.perspectives = updatedPerspectives;
            setRecord(udicciRecord);
        }

        // removePerspective(0);
        setDeletePerspectiveId(-1);
        setShowPerspectiveForm(false);
        setFormPerspective(null);
        if (props && props.onCancel) props.onCancel();
    }

    function sharePerspective(overridePerspective: UdicciRecord | null = null) {
        // console.log('%c sharePerspective props: %O', 'color: blue;', props);
        // console.log('%c sharePerspective formPerspective: %O', 'color: blue;', formPerspective);

        let perspectiveToShare: UdicciRecord | null = (overridePerspective ? overridePerspective : formPerspective);
        // console.log('%c sharePerspective perspectiveToShare: %O', 'color: blue;', perspectiveToShare);

        let updatedPerspectives = recordPerspectives;
        // console.log('%c Perspectives updatedPerspectives: %O', 'color: gold;', updatedPerspectives);

        let foundRecord: boolean = false;
        // let foundRecordInAlternateList: boolean = false;
        if (updatedPerspectives && updatedPerspectives.length > 0) {
            forEach(updatedPerspectives, function(p: UdicciRecord, i: number) {
                // console.log('%c p: %O', 'color: purple;', p);
                if (formPerspective && p.recordId === formPerspective.recordId) {
                    perspectiveToShare = p;
                    foundRecord = true;
                }
            });
            // console.log('%c sharePerspective foundRecord: %O', 'color: maroon;', foundRecord);
        }

        // if (!foundRecord && recordPerspectives && recordPerspectives.length > 0) {
        //     // console.log('%c sharePerspective foundRecord: %O', 'color: maroon;', foundRecord);
        //     forEach(recordPerspectives, function(p: UdicciRecord, i: number) {
        //         // console.log('%c p: %O', 'color: purple;', p);
        //         if (formPerspective && p.recordId === formPerspective.recordId) {
        //             perspectiveToShare = p;
        //             foundRecord = true;
        //             // foundRecordInAlternateList = true;
        //         }
        //     });
        // }
        // console.log('%c sharePerspective perspectiveToShare: %O', 'color: blue;', perspectiveToShare);
        // console.log('%c sharePerspective foundRecord: %O', 'color: maroon;', foundRecord);
        // console.log('%c sharePerspective foundRecordInAlternateList: %O', 'color: maroon;', foundRecordInAlternateList);
        // console.log('%c updatedPerspectives: %O', 'color: purple;', updatedPerspectives);

        if (!perspectiveToShare) return false;
        if (!recordMediator) return false;

        // console.log('%c udicciRecord: %O', 'color: purple;', udicciRecord);
        let recordData = (udicciRecord && udicciRecord.data ? udicciRecord.data : udicciRecord);
        // console.log('%c recordData: %O', 'color: purple;', recordData);

        let perspectiveData = (perspectiveToShare.data ? perspectiveToShare.data : perspectiveToShare);
        // console.log('%c perspectiveData: %O', 'color: purple;', perspectiveData);

        let additionalRequestSettings: any = {
            socialSolutionId: udicci.socialSolutionDefaultMe,
            onSuccess: sharePerspectiveCompleted,
            onError: sharePerspectiveFailed
        };
        if (udicciRecord && perspectiveData.UdicciRecordId <= 0) {
            let relationshipChangesJson = JSON.stringify([{
                "Add": "Parent",
                "RecordMediator": 'Perspectives',
                "RecordId": (perspectiveData.UdicciRecordId ? perspectiveData.UdicciRecordId : 0),
                "RelatedMediator": recordMediator,
                "RelatedRecordId": recordData.UdicciRecordId,
                "Priority": -1
            }]);
            additionalRequestSettings['RelationshipChanges'] = relationshipChangesJson;
        }
        // console.log('%c sharePerspective additionalRequestSettings: %O', 'color: red;', additionalRequestSettings);
        // console.log('%c sharePerspective perspectiveToShare: %O', 'color: red;', perspectiveToShare);

        let okToContinue = false;
        if (okToContinue) {
            setSavingPerspective(true);
            savePerspective(perspectiveToShare, additionalRequestSettings);
        } else {
            // console.log('%c sharePerspective disabled additionalRequestSettings: %O', 'color: red;', additionalRequestSettings);
            // console.log('%c sharePerspective disabled perspectiveToShare: %O', 'color: red;', perspectiveToShare);
            setSavingPerspective(false);
        }
    }

    const sharePerspectiveCompleted = (result: any, request: any, settings: any) => {
        // console.log('%c sharePerspectiveCompleted result: %O', 'color: red;', result);
        // console.log('%c sharePerspectiveCompleted request: %O', 'color: red;', request);
        // console.log('%c sharePerspectiveCompleted settings: %O', 'color: red;', settings);

        setSavingPerspective(false);

        let message: string = (result && result.Message ? result.Message : '');

        if (message) {
            setFormMessage(message);
        } else {
            setFormPerspective(null);
            setShowPerspectiveForm(false);
            setCheckPerspectives(true);
            refreshPerspectives();
        }
    }

    const sharePerspectiveFailed = (result: any) => {
        // console.log('%c sharePerspectiveFailed result: %O', 'color: red;', result);
        setSavingPerspective(false);
    };

    function refreshPerspectives() {
        // console.log('%c refreshPerspectives checkPerspectives: %O', 'color: red;', checkPerspectives);
        if (checkPerspectives) {
            // console.log('%c refreshPerspectives checkPerspectives: %O', 'color: red;', checkPerspectives);
            setCheckPerspectives(false);
            checkForRecordPerspectives();
        }
    }

    const showPerspectiveMenu = (evt: React.MouseEvent<HTMLButtonElement>) => {
        setOpenPerspectiveMenu(true);
        setAnchorMenuEl(evt.currentTarget);
    };

    const hidePerspectiveMenu = (evt: any) => {
        setOpenPerspectiveMenu(false);
        setAnchorMenuEl(null);
    };

    const openPerspectiveForm = (perspective: any, evt: any) => {
        // console.log('%c openPerspectiveForm evt: %O', 'color: red;', evt);
        // console.log('%c openPerspectiveForm perspectiveId: %O', 'color: red;', perspectiveId);
        setOpenPerspectiveMenu(false);
        setAnchorMenuEl(null);
        setShowPerspectiveForm(true);
        setFormPerspective(perspective);
    };

    const deletePerspectiveTrigger = (perspectiveId: number, evt: any) => {
        // console.log('%c deletePerspectiveTrigger perspectiveId: %O', 'color: red;', perspectiveId);
        setOpenPerspectiveMenu(false);
        setAnchorMenuEl(null);
        setDeletePerspectiveId(perspectiveId);
    };

    const confirmDelete = (evt: any) => {
        // console.log('%c confirmDelete udicciRecord: %O', 'color: maroon;', udicciRecord);
        // console.log('%c confirmDelete deletePerspectiveId: %O', 'color: maroon;', deletePerspectiveId);

        // console.log('%c Perspectives recordPerspectives: %O', 'color: blue;', recordPerspectives);
        let updatedPerspectives = recordPerspectives;
        // console.log('%c Perspectives updatedPerspectives: %O', 'color: blue;', updatedPerspectives);

        let perspective: any = null;
        if (updatedPerspectives && updatedPerspectives.length > 0) {
            perspective = updatedPerspectives.find((x: any) => x.recordId === deletePerspectiveId );
        }
        // console.log('%c confirmDelete perspective: %O', 'color: maroon;', perspective);

        if (perspective && perspective.isSaving === false) {
            if (perspective.recordId > 0) {
                setProcessingDelete(true);

                // console.log('%c confirmDelete perspective: %O', 'color: maroon;', perspective);
                deletePerspective(perspective, { onSuccess: deletePerspectiveCompleted });
            } else {
                // remove it from the list of updatedPerspectives
                // console.log('%c confirmDelete updatedPerspectives: %O', 'color: maroon;', updatedPerspectives);
                updatedPerspectives = filter(updatedPerspectives, (p: any) => {
                    return p.recordId.toString() !== deletePerspectiveId.toString();
                });
                // console.log('%c confirmDelete updatedPerspectives: %O', 'color: maroon;', updatedPerspectives);
                udicciRecord.perspectives = updatedPerspectives;
                setRecord(udicciRecord);
                setDeletePerspectiveId(-1);
            }
        }
    }

    const deletePerspectiveCompleted = (result: any, request: any, settings: any) => {
        // console.log('%c deletePerspectiveCompleted result: %O', 'color: maroon;', result);
        // console.log('%c deletePerspectiveCompleted request: %O', 'color: maroon;', request);
        // console.log('%c deletePerspectiveCompleted settings: %O', 'color: maroon;', settings);

        let updatedPerspectives = recordPerspectives;
        // console.log('%c Perspectives updatedPerspectives: %O', 'color: blue;', updatedPerspectives);

        updatedPerspectives = filter(updatedPerspectives, (p: any) => {
            return p.recordId.toString() !== deletePerspectiveId.toString();
        });
        // console.log('%c deletePerspectiveCompleted updatedPerspectives: %O', 'color: maroon;', updatedPerspectives);
        udicciRecord.perspectives = updatedPerspectives;
        setRecord(udicciRecord);
        setDeletePerspectiveId(-1);

        setProcessingDelete(false);
        setCheckPerspectives(true);
        refreshPerspectives();
    }

    // const deletePerspectiveFailed = (result: any) => {
    //     // console.log('%c deletePerspectiveFailed result: %O', 'color: maroon;', result);
    //     setSavingPerspective(false);
    //     setProcessingDelete(false);
    // };

    // console.log('%c Perspectives checkPerspectives: %O', 'color: red;', checkPerspectives);
    if (checkPerspectives) refreshPerspectives();

    let buttonsElement: any = null;
    let showFullForm = false;
    if (showFullForm) {
        buttonsElement = (
            <Fragment>
                <Button onClick={cancelPerspectives} size="small" color="info" variant="outlined">
                    Cancel
                </Button>
                {/* <Button fullWidth 
                        color={"primary"}
                        variant="outlined"
                        onClick={savePerspectives} 
                        disabled={(okToSave ? false : true)}>
                    Update
                </Button> */}
            </Fragment>
        );
    }

    let controlPropsTitle: any = {};
    controlPropsTitle.fullWidth = true;
    // console.log('%c controlPropsTitle: %O', 'color: orange;', controlPropsTitle);

    let pId = (formPerspective && formPerspective.recordId ? formPerspective.recordId : 0);
    let pTitle = (formPerspective && formPerspective.data && formPerspective.data.Title ? formPerspective.data.Title : '');
    let pNarrative = (formPerspective && formPerspective.data && formPerspective.data.TalkingPoints ? formPerspective.data.TalkingPoints : '');

    let inputPropsTitle: any = {};
    inputPropsTitle.type = 'text';
    inputPropsTitle.size = 'small';
    inputPropsTitle.placeholder = 'Share a perspective...';
    inputPropsTitle.variant = "outlined";
    inputPropsTitle.fullWidth = true;
    inputPropsTitle.value = (showPerspectiveForm && pId <= 0 ? pTitle : '');
    inputPropsTitle.onChange = (evt: any) => {changePerspectiveValue('Title', evt)};
    inputPropsTitle.disabled = (savingPerspective ? true : false);
    // console.log('%c inputPropsTitle: %O', 'color: orange;', inputPropsTitle);

    let userSocialIconAvatar: any = null;
    if (userSocialIcon) {
        userSocialIconAvatar = (
            <SocialIconContainer>
                <Avatar src={userSocialIcon}
                        component="span"
                        variant="rounded"
                        classes={{ root: 'creatorSocialIconRoot', img: 'creatorSocialIcon' }}
                />
            </SocialIconContainer>
        );
    }

    let perspectiveSaveFormButton: any = null;
    let perspectiveCancelFormButton: any = null;
    let perspectiveNarrative: any = null;
    if (showPerspectiveForm === true && pId <= 0) {
        // console.log('%c formPerspective: %O', 'color: maroon;', formPerspective);
        let inputPropsNarrative: any = {};
        inputPropsNarrative.type = 'text';
        inputPropsNarrative.size = 'small';
        inputPropsNarrative.fullWidth = true;
        inputPropsNarrative.multiline = true;
        inputPropsNarrative.minRows = 2;
        inputPropsNarrative.maxRows = 15;
        inputPropsNarrative.placeholder = 'Write a narrative for your perspective.';
        inputPropsNarrative.variant = "outlined";
        inputPropsNarrative.value = pNarrative;
        inputPropsNarrative.onChange = (evt: any) => {changePerspectiveValue('TalkingPoints', evt)};
        inputPropsNarrative.disabled = (savingPerspective ? true : false);

        perspectiveNarrative = ( <TextField {...inputPropsNarrative} /> );
        perspectiveCancelFormButton = (
            <Button onClick={(evt: any) => cancelPerspectives()} 
                    color="info" 
                    size="small"
                    variant="outlined" 
                    sx={udicciClasses.perspectiveButton}
                    disabled={(savingPerspective ? true : false)}
            >
                Cancel
            </Button>
        );
        perspectiveSaveFormButton = (
            <Button onClick={(evt: any) => sharePerspective()} 
                    color="secondary" 
                    size="small"
                    variant="outlined" 
                    sx={udicciClasses.perspectiveButton}
                    disabled={(formPerspective && (savingPerspective || !formPerspective.isDirty) ? true : false)}
            >
                Save
            </Button>
        );
    }

    let formMessageElement: any = null;
    if (formMessage && showPerspectiveForm === true) {
        formMessageElement = (
            <Typography variant="errorMessage" component="div">
                {formMessage}
            </Typography>
        );
    }

    let perspectiveMessageElement: any = null;
    let recordPerspectiveElements: any[] = [];
    if (recordHasPerspectives && recordPerspectives.length > 0) {
        // console.log('%c recordPerspectives: %O', 'color: green;', recordPerspectives);
        recordPerspectives.forEach(function(persp: UdicciRecord) {
            // console.log('%c persp: %O', 'color: purple;', persp);
            // console.log('%c mediaBit Index: %O', 'color: purple;', i);
            const perspectiveId = (persp.recordId ? persp.recordId : 0);
            // console.log('%c perspectiveId: %O', 'color: red;', perspectiveId);
            if (!perspectiveId && formPerspective && formPerspective.recordId === 0) return true;

            const perspectiveTitle = (persp.data && persp.data.Title ? persp.data.Title : '');
            const perspectiveNarrative = (persp.data && persp.data.TalkingPoints ? persp.data.TalkingPoints : '');
            const createdByUserId = (persp.data && persp.data.CreatedByUserId ? persp.data.CreatedByUserId : 0);
            // const creatorDisplayName = (persp.data && persp.data.CreatorDisplayName ? persp.data.CreatorDisplayName : '');
            const creatorSocialIcon = (persp.data && persp.data.CreatorSocialIcon ? persp.data.CreatorSocialIcon : '');
            // const strDateCreated = (persp.data && persp.data.DateCreated ? persp.data.DateCreated : '');
            // const dateCreated = new Date(strDateCreated);
            // console.log('%c dateCreated: %O', 'color: purple;', dateCreated);

            let currentUserIsPerspectiveOwner: boolean = false;
            if (currentUser && currentUser.UdicciUserId === createdByUserId) {
                currentUserIsPerspectiveOwner = true;
            }
            let userPerspectiveSocialIconAvatar: any = null;
            if (creatorSocialIcon) {
                userPerspectiveSocialIconAvatar = (
                    <Avatar src={creatorSocialIcon}
                            variant="rounded"
                            component="span"
                            classes={{ root: 'creatorSocialIconRoot', img: 'creatorSocialIcon' }}
                    />
                );
            }

            let isSelectedPerspective: boolean = (formPerspective && formPerspective.recordId > 0 && formPerspective.recordId === perspectiveId ? true : false);
            let isDeletePerspective: boolean = (deletePerspectiveId >= 0 && deletePerspectiveId === perspectiveId ? true : false);
            let showAsDropDown: boolean = false;
            let savePerspectiveButton: any = null;
            let editPerspectiveButton: any = null;
            let deletePerspectiveButton: any = null;
            if ((perspectiveId && currentUserIsPerspectiveOwner) || (persp.permissions && persp.permissions.CanEdit)) {
                let editButtonProps: any = {
                    key: 'edit.perspective.' + perspectiveId.toString(),
                    onClick: (evt: any) => openPerspectiveForm(persp, evt)
                };
                if (showAsDropDown) {
                    editPerspectiveButton = (
                        <MenuItem {...editButtonProps}>
                            <EditIcon aria-label="Edit Perspective" sx={udicciClasses.menuListIcon} />
                            Edit Perspective
                        </MenuItem>
                    );
                } else {
                    editButtonProps.size = "small";
                    editButtonProps.color = "primary";
                    editPerspectiveButton = (
                        <IconButton {...editButtonProps}> <EditIcon aria-label="Edit Perspective" /> </IconButton>
                    );
                }
            }

            if ((perspectiveId && currentUserIsPerspectiveOwner) || (persp.permissions && persp.permissions.CanDelete)) {
                let deleteButtonProps: any = {
                    key: 'delete.perspective.' + perspectiveId.toString(),
                    onClick: (evt: any) => deletePerspectiveTrigger(perspectiveId, evt),
                    disabled: persp.isSaving
                };
                if (showAsDropDown) {
                    deletePerspectiveButton = (
                        <MenuItem {...deleteButtonProps}>
                            <DeleteIcon aria-label="Delete Perspective" sx={udicciClasses.menuListIcon} />
                            Delete Perspective
                        </MenuItem>
                    );
                } else {
                    deleteButtonProps.size = "small";
                    deleteButtonProps.color = "secondary";
                    deletePerspectiveButton = (
                        <IconButton {...deleteButtonProps}> <DeleteIcon aria-label="Delete Perspective" /> </IconButton>
                    );
                }
            }

            if (currentUserIsPerspectiveOwner && persp.isDirty) {
                let saveButtonProps: any = {
                    key: 'save.perspective.' + perspectiveId.toString(),
                    onClick: (evt: any) => sharePerspective(persp),
                    disabled: persp.isSaving
                };
                if (showAsDropDown) {
                    savePerspectiveButton = (
                        <MenuItem {...saveButtonProps}>
                            <EditIcon aria-label="Edit Perspective" sx={udicciClasses.menuListIcon} />
                            Save Perspective
                        </MenuItem>
                    );
                } else {
                    saveButtonProps.size = "small";
                    saveButtonProps.color = "primary";
                    savePerspectiveButton = (
                        <IconButton {...saveButtonProps}> <SaveIcon aria-label="Save Perspective" /> </IconButton>
                    );
                }
            }

            let listItemDropDownMenu: any = null;
            // console.log('%c isDeletePerspective: %O', 'color: purple;', isDeletePerspective);
            // console.log('%c isSelectedPerspective: %O', 'color: purple;', isSelectedPerspective);
            // console.log('%c currentUserIsPerspectiveOwner: %O', 'color: purple;', currentUserIsPerspectiveOwner);
            if (!isDeletePerspective && !isSelectedPerspective && currentUserIsPerspectiveOwner && (editPerspectiveButton || deletePerspectiveButton)) {
                if (showAsDropDown) {
                    listItemDropDownMenu = (
                        <Box sx={udicciClasses.menuContainer}>
                            <IconButton size="small"
                                color="primary"
                                edge="end"
                                aria-controls={openPerspectiveMenu ? 'split-button-menu' : undefined}
                                aria-expanded={openPerspectiveMenu ? 'true' : undefined}
                                aria-haspopup="menu"
                                onClick={showPerspectiveMenu}
                                component="span"
                            >
                                <PerspectiveMenuIcon />
                            </IconButton>
                            <Popper open={openPerspectiveMenu} anchorEl={anchorMenuEl} transition>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                        }}
                                        >
                                        <Paper>
                                            <ClickAwayListener onClickAway={hidePerspectiveMenu}>
                                                <MenuList id="split-button-menu">
                                                    {savePerspectiveButton}
                                                    {editPerspectiveButton}
                                                    {deletePerspectiveButton}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>        
                        </Box>
                    );
                } else {
                    listItemDropDownMenu = (
                        <Box sx={udicciClasses.menuContainer}>
                            {deletePerspectiveButton}
                            {savePerspectiveButton}
                            {editPerspectiveButton}
                        </Box>
                    );
                }
            }

            let titleContentElement: any = null;
            let narrativeContentElement: any = null;
            let buttonsElement: any = null;

            if (isDeletePerspective) {
                titleContentElement = (
                    <Typography variant="subtitle1" component="div">
                        {perspectiveTitle}
                    </Typography>
                );

                narrativeContentElement = (
                    <Typography variant="body2" component="div">
                        Do you want to delete this perspective?
                    </Typography>
                );

                let cancelFormButton = (
                    <Button onClick={(evt: any) => cancelPerspectives()} 
                            color="info" 
                            variant="outlined" 
                            sx={udicciClasses.perspectiveButton}
                            disabled={(savingPerspective || processingDelete ? true : false)}
                    >
                        Cancel
                    </Button>
                );
                let saveFormButton: any = (
                    <Button onClick={confirmDelete} 
                            color="secondary" 
                            variant="outlined" 
                            sx={udicciClasses.perspectiveButton}
                            disabled={(savingPerspective || processingDelete ? true : false)}
                    >
                        {(savingPerspective || processingDelete ? 'deleting ...' : 'Delete')}
                    </Button>
                );
                buttonsElement = (
                    <Box sx={udicciClasses.perspectiveButtonsContainer}>
                        {cancelFormButton}
                        {saveFormButton}
                    </Box>
                );
            } else if (!isSelectedPerspective) {
                titleContentElement = (
                    <Typography variant="subtitle1" component="div">
                        {perspectiveTitle}
                    </Typography>
                );

                narrativeContentElement = (
                    <Typography variant="caption" component="div">
                        {perspectiveNarrative}
                    </Typography>
                );
            } else {
                // console.log('%c persp: %O', 'color: maroon;', persp);
                let pTitle = (persp && persp.data && persp.data.Title ? persp.data.Title : '');
                let pNarrative = (persp && persp.data && persp.data.TalkingPoints ? persp.data.TalkingPoints : '');
            
                let titleProps: any = {};
                titleProps.type = 'text';
                titleProps.fullWidth = true;
                titleProps.placeholder = 'Share a perspective...';
                titleProps.variant = "standard";
                titleProps.InputProps = {
                    classes: {
                        input: udicciClasses.perspectiveComment
                    }
                };
                titleProps.classes = {
                    root: udicciClasses.perspectiveCommentRootInline
                };
                titleProps.value = pTitle;
                titleProps.onChange = (evt: any) => changePerspectiveValue('Title', evt);
                titleProps.disabled = (savingPerspective ? true : false);

                titleContentElement = (
                    <TextField {...titleProps} />
                );
            
                let narrativeProps: any = {};
                narrativeProps.type = 'text';
                narrativeProps.fullWidth = true;
                narrativeProps.multiline = true;
                narrativeProps.minRows = 2;
                narrativeProps.maxRows = 15;
                narrativeProps.placeholder = 'Write a narrative for your perspective.';
                narrativeProps.variant = "outlined";
                narrativeProps.InputProps = {
                    classes: {
                        root: udicciClasses.perspectiveNarrative,
                        input: udicciClasses.perspectiveNarrative,
                    }
                };
                narrativeProps.classes = {
                    root: udicciClasses.perspectiveNarrativeRoot
                };
                narrativeProps.value = pNarrative;
                narrativeProps.onChange = (evt: any) => changePerspectiveValue('TalkingPoints', evt);
                narrativeProps.disabled = (savingPerspective ? true : false);

                narrativeContentElement = (
                    <TextField {...narrativeProps} />
                );
                let cancelFormButton = (
                    <Button onClick={(evt: any) => cancelPerspectives()} 
                            color="info" 
                            variant="outlined" 
                            sx={udicciClasses.perspectiveButton}
                            disabled={(savingPerspective ? true : false)}
                    >
                        Cancel
                    </Button>
                );
                let saveFormButton: any = (
                    <Button onClick={(evt: any) => sharePerspective()} 
                            color="secondary" 
                            variant="outlined" 
                            sx={udicciClasses.perspectiveButton}
                            disabled={(persp && (savingPerspective || !persp.isDirty) ? true : false)}
                    >
                        {(savingPerspective ? 'saving ...' : 'Save')}
                    </Button>
                );
                buttonsElement = (
                    <Box sx={udicciClasses.perspectiveButtonsContainer}>
                        {cancelFormButton}
                        {saveFormButton}
                    </Box>
                );
            }

            let recordPerspectiveProps: any = {
                key: 'record.' + recordId.toString() + '.perspective.' + perspectiveId.toString(),
                sx: udicciClasses.perspectiveItemContainer,
                elevation: 5
            };

            recordPerspectiveElements.push(
                <Paper {...recordPerspectiveProps}>
                    {listItemDropDownMenu}
                    {userPerspectiveSocialIconAvatar}
                    <Box sx={{ display: 'flow-root' }}>
                        {titleContentElement}
                        {narrativeContentElement}
                        {buttonsElement}
                    </Box>
                </Paper>
            );
        });
    } else if (checkPerspectives) { // !recordHasPerspectives && 
        perspectiveMessageElement = (<CircularProgress size={20} />);
    } else if (recordHasPerspectives && recordPerspectives.length <= 0) {
        perspectiveMessageElement = (
            <Typography variant="caption" component="div" style={{textAlign: 'center'}}>
                No Perspectives shared yet
            </Typography>
        );
    }

    let newPerspectiveFormElevation: number = 0;
    // let newPerspectiveFormClass: string = udicciClasses.perspectiveNewPerspectiveContainer;
    let perspectiveButtonsElement: any = null;
    if (showPerspectiveForm && pId <= 0) {
        newPerspectiveFormElevation = 5;
        // newPerspectiveFormClass = udicciClasses.perspectiveNewPerspectiveContainerOpen;
        perspectiveButtonsElement = (
            <Box sx={udicciClasses.perspectiveButtonsContainer}>
                {perspectiveCancelFormButton}
                {perspectiveSaveFormButton}
            </Box>
        );
    }

    let perspectiveFormElement = (
        <Paper elevation={newPerspectiveFormElevation} sx={{ background: 'unset' }}>
            <Paper elevation={0} sx={{ background: 'unset', display: 'flow-root' }}>
                {formMessageElement}
                {userSocialIconAvatar}
                <Box sx={{ display: 'flow-root', padding: '8px' }}>
                    <TextField {...inputPropsTitle} />
                    {perspectiveNarrative}
                    {perspectiveButtonsElement}
                </Box>
            </Paper>
            <Box>
                {perspectiveMessageElement}
                {recordPerspectiveElements}
            </Box>
        </Paper>
    );

    // console.log('%c Perspectives files: %O', 'color: blue;', files);
    return (
        <div id="upload-box">
            {perspectiveFormElement}
            {buttonsElement}
        </div>
    );
}
