import React, { useContext, useState, useCallback } from 'react';

import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { UdicciContext, subscribe as subscribeToUdicci, sendPreparedRequest } from 'src/context/udicci-context';
import { RequestChangePasswordCodeRequest, ValidateChangePasswordCodeRequest, ChangePasswordRequest } from 'src/interfaces/udicci-request-interfaces';
import { udicciStyles } from 'src/theme/shared-styles';


const ChangePasswordForm: React.FC<any> = ({ configuration, onClose }) => {
    // console.log('%c ChangePasswordForm configuration: %O', 'color: green;', configuration);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const udicciContext = useContext(UdicciContext);
    let { udicci } = udicciContext.state;
    // console.log('%c ChangePasswordForm data: %O', 'color: green;', data);
    let { selectedProfile, currentUser } = udicci;
    // console.log('%c ChangePasswordForm selectedProfile: %O', 'color: maroon;', selectedProfile);
    // let mediatorName: string = 'Roles';
    // let linkedData: any = null;
    // let structure: any = null;
    // let permissions: any = null;
    // if (data) {
    //     // let mediatorContext = data.find((x: any) => x.mediator === mediatorName );
    //     // console.log('%c mediatorContext: %O', 'color: green;', mediatorContext);
    //     // if (mediatorContext && mediatorContext.linkedData) linkedData = mediatorContext.linkedData;
    //     // if (mediatorContext && mediatorContext.permissions) permissions = mediatorContext.permissions;
    // }
    // console.log('%c ChangePasswordForm linkedData: %O', 'color: green;', linkedData);
    // console.log('%c ChangePasswordForm structure: %O', 'color: green;', structure);
    const [password, setPassword] = useState<any>(null);
    const [changePasswordCode, setChangePasswordCode] = useState<string>('');
    const [confirmationMessage, setConfirmationMessage] = useState<string>('');
    const [protectedUserEmailAddress, setProtectedUserEmailAddress] = useState<string>('');
    const [changePasswordCodeIsValid, setChangePasswordCodeIsValid] = useState<boolean>(false);
    const [processingRequest, setProcessingRequest] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [forceUpdateState, forceUpdate] = useState<boolean>(false);
    // console.log('%c ChangePasswordForm changePasswordCode: %O', 'color: green;', changePasswordCode);
    // console.log('%c ChangePasswordForm confirmationMessage: %O', 'color: green;', confirmationMessage);
    // console.log('%c ChangePasswordForm protectedUserEmailAddress: %O', 'color: green;', protectedUserEmailAddress);
    // console.log('%c ChangePasswordForm changePasswordCodeIsValid: %O', 'color: green;', changePasswordCodeIsValid);
    // console.log('%c ChangePasswordForm errorMessage: %O', 'color: green;', errorMessage);
    const onUicciUpdate = useCallback((response: any, request: any, settings: any) => {
        // console.log('%c ChangePasswordForm onUicciUpdate response: %O', 'color: red;', response);
        // console.log('%c ChangePasswordForm onUicciUpdate request: %O', 'color: red;', request);
        // console.log('%c ChangePasswordForm onUicciUpdate settings: %O', 'color: red;', settings);
        if (request && request.UdicciCommand === 'Request Change Password Code') {
            // console.log('%c response: %O', 'color: green;', response);
            // console.log('%c request: %O', 'color: green;', request);
            // console.log('%c settings: %O', 'color: green;', settings);
            if (response && response.success === true) {
            } else {
            }
        }
    }, []);

    subscribeToUdicci('portal.config.change.password.form', onUicciUpdate);

    const onPasswordChanged = (fieldName: string, evt: any) => {
        // console.log('%c onPasswordChanged fieldName: %O', 'color: red;', fieldName);
        // console.log('%c onPasswordChanged evt: %O', 'color: red;', evt);
        if (processingRequest)
            return false;
        let updatedPassword: any = (password ? password : { newPassword: '', confirmPassword: '' });
        updatedPassword[fieldName] = evt.target.value;
        setPassword(updatedPassword);
        forceUpdate(!forceUpdateState);
    };

    const cancelChangePassword = (evt: any) => {
        if (processingRequest)
            return false;

        setPassword(null);
        setChangePasswordCodeIsValid(false);
        setChangePasswordCode('');
        setConfirmationMessage('');
        setProtectedUserEmailAddress('');
        setErrorMessage('');
    };

    const changeUserPassword = (evt: any) => {
        if (processingRequest)
            return false;

        if (password) {
            let requestJson: ChangePasswordRequest = {
                UdicciCommand: 'Change User Password',
                UdicciMediatorName: 'Users',
                SelectedUdicciProfileId: (selectedProfile && selectedProfile.recordId ? selectedProfile.recordId : 0),
                SocialSolutionId: udicci.socialSolutionUdicciIT,
                UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0),
                ChangePasswordCode: changePasswordCode,
                NewPassword: password.newPassword,
                ConfirmPassword: password.confirmPassword,
            };
            // console.log('requestJson: %O', requestJson);
            let okToContinue = true;
            if (okToContinue) {
                sendPreparedRequest(requestJson, {
                    onSuccess: changeUserPasswordSuccess,
                    onError: changeUserPasswordFailure
                });
            }
            setProcessingRequest(true);

            setConfirmationMessage('');
            setProtectedUserEmailAddress('');
            setErrorMessage('');
        }
    };

    const changeUserPasswordSuccess = (response: any) => {
        // console.log('changeUserPasswordSuccess response: %O', response);
        if (response && response.ConfirmationMessage) {
            setConfirmationMessage(response.ConfirmationMessage);
        } else {
            setConfirmationMessage('');
        }
        setProcessingRequest(false);

        setPassword(null);
        setChangePasswordCodeIsValid(false);
        setChangePasswordCode('');
        setProtectedUserEmailAddress('');
        setErrorMessage('');
    };

    const changeUserPasswordFailure = (response: any) => {
        // console.log('changeUserPasswordFailure response: %O', response);
        setProcessingRequest(false);
        if (response && response.Message) {
            setErrorMessage(response.Message);
        } else {
            forceUpdate(!forceUpdateState);
        }
    };

    const changePasswordCodeChanged = (evt: any) => {
        // console.log('%c changePasswordCodeChanged fieldName: %O', 'color: red;', fieldName);
        // console.log('%c changePasswordCodeChanged evt: %O', 'color: red;', evt);
        if (processingRequest)
            return false;
        let newChangePasswordCode: string = evt.target.value;
        setChangePasswordCode(newChangePasswordCode);
    };

    const validateChangePasswordCode = (evt: any) => {
        if (processingRequest)
            return false;
        let requestJson: ValidateChangePasswordCodeRequest = {
            UdicciCommand: 'Validate Change Password Code',
            UdicciMediatorName: 'Users',
            SelectedUdicciProfileId: (selectedProfile && selectedProfile.recordId ? selectedProfile.recordId : 0),
            SocialSolutionId: udicci.socialSolutionUdicciIT,
            UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0),
            ChangePasswordCode: changePasswordCode
        };
        // console.log('requestJson: %O', requestJson);
        let okToContinue = true;
        if (okToContinue) {
            sendPreparedRequest(requestJson, {
                onSuccess: validateChangePasswordCodeSuccess,
                onError: validateChangePasswordCodeFailure
            });
        }
        setProcessingRequest(true);

        setConfirmationMessage('');
        setProtectedUserEmailAddress('');
        setErrorMessage('');
    };

    const validateChangePasswordCodeSuccess = (response: any) => {
        // console.log('validateChangePasswordCodeSuccess response: %O', response);
        setProcessingRequest(false);
        if (response && response.ChangePasswordCodeIsValid) {
            setChangePasswordCodeIsValid(response.ChangePasswordCodeIsValid);
        }
        if (response && response.Message) {
            setErrorMessage(response.Message);
        }
    };

    const validateChangePasswordCodeFailure = (response: any) => {
        // console.log('validateChangePasswordCodeFailure response: %O', response);
        setProcessingRequest(false);
        if (response && response.Message) {
            setErrorMessage(response.Message);
        }
    };

    const requestChangePasswordCode = (evt: any) => {
        if (processingRequest)
            return false;
        let requestJson: RequestChangePasswordCodeRequest = {
            UdicciCommand: 'Request Change Password Code',
            UdicciMediatorName: 'Users',
            SelectedUdicciProfileId: (selectedProfile && selectedProfile.recordId ? selectedProfile.recordId : 0),
            SocialSolutionId: udicci.socialSolutionUdicciIT,
            UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0)
        };
        // console.log('requestJson: %O', requestJson);
        let okToContinue = true;
        if (okToContinue) {
            sendPreparedRequest(requestJson, {
                onSuccess: requestChangePasswordCodeSuccess,
                onError: requestChangePasswordCodeFailure
            });
        }
        setProcessingRequest(true);

        setChangePasswordCodeIsValid(false);
        setConfirmationMessage('');
        setProtectedUserEmailAddress('');
        setErrorMessage('');
    };

    const requestChangePasswordCodeSuccess = (response: any) => {
        // console.log('requestChangePasswordCodeSuccess response: %O', response);
        setProcessingRequest(false);
        if (response && response.ConfirmationMessage) {
            setConfirmationMessage(response.ConfirmationMessage);
        }
        if (response && response.ProtectedUserEmailAddress) {
            setProtectedUserEmailAddress(response.ProtectedUserEmailAddress);
        }
        if (response && response.Message) {
            setErrorMessage(response.Message);
        }
    };

    const requestChangePasswordCodeFailure = (response: any) => {
        // console.log('requestChangePasswordCodeFailure response: %O', response);
        setProcessingRequest(false);
        if (response && response.Message) {
            setErrorMessage(response.Message);
        } else {
            forceUpdate(!forceUpdateState);
        }
    };

    let errorMessageElement: any = null;
    if (errorMessage) {
        errorMessageElement = (
            <Typography variant="errorMessage"> {errorMessage} </Typography>
        );
    }

    let closeButton: any = null;
    if (onClose) {
        closeButton = (
            <Button color='info' centerRipple onClick={onClose} disabled={(processingRequest ? true : false)}>
                close
            </Button>
        );
    }

    let confirmationMessageElement: any = null;
    if (confirmationMessage) {
        confirmationMessageElement = (
            <Typography component='div' variant="body1">{confirmationMessage}</Typography>

        );
    }

    let requestCodeResults: any = null;
    let changePasswordFormElement: any = null;
    if (!changePasswordCodeIsValid) {
        let protectedUserEmailAddressElement: any = null;
        if (protectedUserEmailAddress) {
            protectedUserEmailAddressElement = (
                <Box sx={udicciClasses.content}>
                    <Typography component='span' variant="caption">sent to  </Typography>
                    <Typography component='span' variant="body1">{protectedUserEmailAddress}</Typography>
                </Box>
            );
        }
        if (confirmationMessageElement || protectedUserEmailAddressElement) {
            requestCodeResults = (
                <Box sx={{ padding: '16px' }}>
                    {confirmationMessageElement}
                    {protectedUserEmailAddressElement}
                </Box>
            );
        }
    } else if (changePasswordCodeIsValid) {
        let newPassword = (password && password.newPassword ? password.newPassword : '');
        let confirmPassword = (password && password.confirmPassword ? password.confirmPassword : '');
        let savePasswordIsDisabled = ((processingRequest || newPassword === '') || (newPassword !== confirmPassword) ? true : false);

        let newPasswordElement = (
            <TextField label={'New Password'}
                value={newPassword}
                type='password'
                InputLabelProps={{ shrink: true }}
                onChange={(evt: any) => onPasswordChanged('newPassword', evt)}
                margin="normal" />
        );
        let confirmPasswordElement = (
            <TextField label={'Confirm Password'}
                value={confirmPassword}
                type='password'
                InputLabelProps={{ shrink: true }}
                onChange={(evt: any) => onPasswordChanged('confirmPassword', evt)}
                margin="normal" />
        );

        changePasswordFormElement = (
            <Box>
                {confirmationMessageElement}
                {newPasswordElement}
                {confirmPasswordElement}

                <Box sx={udicciClasses.buttonArea}>
                    <Button variant="contained"
                        color="info"
                        aria-label="Cancel"
                        onClick={cancelChangePassword}
                        size="small"
                    >
                        Cancel
                    </Button>
                    <Button variant="contained"
                        color="primary"
                        aria-label="Change Password"
                        onClick={changeUserPassword}
                        disabled={savePasswordIsDisabled}
                        size="small"
                    >
                        Change Password
                    </Button>
                </Box>
            </Box>
        );
    }

    let confirmChangePasswordCodeElement = null;
    let requestCodeContainerElement = null;
    let validateCodeButton = null;
    if (!changePasswordCodeIsValid) {
        let changePasswordCodeElement = (
            <Box>
                <TextField label={'Enter code that was sent to your email'}
                           sx={(udicci.isMobileDevice() ? { width: '100%' } : udicciClasses.textField300)}
                           value={changePasswordCode}
                           InputLabelProps={{ shrink: true }}
                           onChange={changePasswordCodeChanged}
                />
            </Box>
        );

        let validateIsDisabled = (changePasswordCode && changePasswordCode.length === 7 ? false : true);
        validateCodeButton = (
            <Button variant="contained"
                    color="secondary"
                    aria-label="Validate Code"
                    onClick={validateChangePasswordCode}
                    disabled={processingRequest || validateIsDisabled}
            >
                Validate Code
            </Button>
        );

        confirmChangePasswordCodeElement = (
            <Box sx={{ padding: '8px' }}>
                <Typography variant="caption" component="div" sx={{ padding: '8px' }}>
                    If you already have a 7-digit code, please enter and validate it below.
                </Typography>

                {changePasswordCodeElement}
            </Box>
        );

        let requestChangePasswordCodeButton = (
            <Button color="secondary"
                aria-label="Request a code now"
                onClick={requestChangePasswordCode}
                disabled={processingRequest}
            >
                Request a code now
            </Button>
        );

        requestCodeContainerElement = (
            <div>
                <Typography variant="caption">
                    In order to change your password, you must have a 7-digit code.  These codes are
                    generated by the Udicci platform and sent directly to your email.
                </Typography>

                {requestChangePasswordCodeButton}
                {requestCodeResults}
            </div>
        );
    }

    // let addButtonElement: any = (
    //     <IconButton size="small" color="secondary" sx={udicciClasses.rightButton} onClick={addNewRecord}>
    //         <AddIcon />
    //     </IconButton>
    // );
    /**
     * User Profile Editor
     * Change Password.  use previous, change to material
     *  - two-step process.  has email me code to change password.  enter code and change password.
     * Activity Logging Instructions
     *  - Set API Instructions (Select Any/Some or All)
     *      - Select Profile (User/Selected).  Profiles with Relationship to User Profile.  Chips.
     *      - Select Solution. options based on profile(s) selected  (only show overlapping solutions for selected profiles)
     *      - Select Feature.  options based on Solution selected.  this could be optional?
     *      - Select Mediator(s) using Chips based on Solution (or selected feature).
     *      - Select Notification Options
     *          - Auto Acknowledge
     *          - Schedule email -> what time selections:
     *              - Early Riser (between 5:00am and 6:00am)
     *              - Let me have my coffee (between 9:00am and 10:00am)
     *              - In the Afternoon (between 1:00 pm and 2:00pm)
     *              - Checking out for the day (between 4:00pm and 5:00pm)
     *              - Settling in for the night (between 8:00pm and 9:00pm)
     *              - Only on the Weekends (Saturday/Sunday between 10:00am and 11:00am)
     *              - To Start My Week (Monday between 8:00am and 9:00am)
     *              - To Finish Up My Week (Friday between 3:00pm and 4:00pm)
     *              - I need this ASAP (sent immediately)
     */
    let myAccountSectionElement = (
        <Box>
            {errorMessageElement}
            {requestCodeContainerElement}

            {confirmChangePasswordCodeElement}
            {changePasswordFormElement}
            <Box sx={{ marginLeft: '16px' }}>
                {validateCodeButton}
                {closeButton}
            </Box>
        </Box>
    );

    return myAccountSectionElement;
};

export default ChangePasswordForm;
