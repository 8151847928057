
import React, { Fragment } from 'react';

import { Box, Typography, Paper, MenuItem, Select, TextField } from '@mui/material';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext } from 'src/context/udicci-context'

const FieldFilter: React.FC<any> = (props) => {
    let { field, filterSettings, readonly, onUpdateFilterSettings } = props;
    // console.log('%c field: %O', 'color: purple;', field);
    // console.log('%c filterSettings: %O', 'color: purple;', filterSettings);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    let { udicci, data } = udicciContext.state;

    let textAreaFields: string[] = udicci.textAreaFields;
    let numberFields: string[] = udicci.numberFields;
    let codeFields: string[] = udicci.codeFields;
    let dateFields: string[] = udicci.dateFields;
    // console.log('%c dateFields: %O', 'color: red;', dateFields);

    let uid: string = udicciHelpers.generateUID();
    // let jsonFieldName: string = (field.JsonFieldName ? field.JsonFieldName : '');
    let dataType: string = (field.DataType ? field.DataType : 'String');
    let isNullable: boolean = (field.IsNullable ? true : false);

    let isTextAreaFld: boolean = (textAreaFields.indexOf(dataType) >= 0 ? true : false);
    let isNumberFld: boolean = (numberFields.indexOf(dataType) >= 0 ? true : false);
    let isCodeFld: boolean = (codeFields.indexOf(dataType) >= 0 ? true : false);
    let isDateFld: boolean = (dateFields.indexOf(dataType) >= 0 ? true : false);

    let conditionsSettingsElement: any = null;
    let selectConditionValues: any[] = [];

    const updateSetting = (fieldName: any, event: any) => {
        // console.log('%c updateSetting fieldName: %O', 'color: purple;', fieldName);
        let trgt = event.target;
        // console.log('%c trgt: %O', 'color: purple;', trgt);
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c newValue: %O', 'color: purple;', newValue);
        filterSettings[fieldName] = newValue;
        // this.setState({ filterSettings: filterSettings });
        if (onUpdateFilterSettings) onUpdateFilterSettings(filterSettings);
    };

    [ 'Is Equal To', 'Not Equal To' ].map((cdtn) => {
        let cdtnValue = cdtn.toLowerCase().replace(/\s+/g, ".");
        let cdtnKey = 'filter.' + uid + '.' + cdtnValue;
        selectConditionValues.push(
            <MenuItem key={cdtnKey} value={cdtnValue}> {cdtn} </MenuItem>
        );
    });

    if (isTextAreaFld || isCodeFld) {
        [
            'Is Empty','Not Empty',
            'Contains','Does Not Contain',
            'Starts With','Does Not Start With',
            'Ends With','Does Not End With'
        ].map((cdtn) => {
            let cdtnValue = cdtn.toLowerCase().replace(/\s+/g, ".");
            let cdtnKey = 'filter.' + uid + '.' + cdtnValue;
            selectConditionValues.push(
                <MenuItem key={cdtnKey} value={cdtnValue}> {cdtn} </MenuItem>
            );
        });
    }
    if (dataType === 'String') {
        [
            'Is Empty','Not Empty',
            'Contains','Does Not Contain',
            'Starts With','Does Not Start With',
            'Ends With','Does Not End With'
        ].map((cdtn) => {
            let cdtnValue = cdtn.toLowerCase().replace(/\s+/g, ".");
            let cdtnKey = 'filter.' + uid + '.' + cdtnValue;
            selectConditionValues.push(
                <MenuItem key={cdtnKey} value={cdtnValue}> {cdtn} </MenuItem>
            );
        });
    }

    if (isNumberFld || isDateFld) {
        [
            'Is Greater Than','Is Greater Than Or Equal To',
            'Is Less Than','Is Less Than Or Equal To',
            'Between'
        ].map((cdtn) => {
            let cdtnValue = cdtn.toLowerCase().replace(/\s+/g, ".");
            let cdtnKey = 'filter.' + uid + '.' + cdtnValue;
            selectConditionValues.push(
                <MenuItem key={cdtnKey} value={cdtnValue}> {cdtn} </MenuItem>
            );
        });
    }

    if (isNullable) {
        [
            'Is Null','Not Null'
        ].map((cdtn) => {
            let cdtnValue = cdtn.toLowerCase().replace(/\s+/g, ".");
            let cdtnKey = 'filter.' + uid + '.' + cdtnValue;
            selectConditionValues.push(
                <MenuItem key={cdtnKey} value={cdtnValue}> {cdtn} </MenuItem>
            );
        });
    }

    let conditionToCheckValue: string = '';
    if (filterSettings && filterSettings.conditionToCheck) {
        conditionToCheckValue = filterSettings.conditionToCheck;
    }
    if (readonly) {
        let conditionToCheckText = conditionToCheckValue;
        let cdtnValue = conditionToCheckText.toLowerCase().replace(/\.+/g, " ");
        conditionsSettingsElement = (
            <Typography component="span" variant="body2" color="textSecondary">
                {cdtnValue}
            </Typography>
        );
    } else {
        conditionsSettingsElement = (
            <Box>
                <Select value={conditionToCheckValue}
                        id='select-filter-condition-to-check'
                        onChange={(evt: any) => updateSetting('conditionToCheck', evt)}
                >
                    {selectConditionValues}
                </Select>
            </Box>
        );
    }

    let valueToCompareElement: any = null;
    if (conditionToCheckValue) {
        // show the value to compare data entry component
        /**
         * 
         * This probably should be a separate component
         *  - it will need to do quite a bit.  using the data type and condition to check,
         *    the component will need to display the correct 'form element(s)'
         * 
         * for example, Date and DateTime should properly allow selection of 
         * date and/or time.  If the condition to check is 'between', then two date fields
         * will need to be displayed.  (between also applies to number data types too)
         * 
         */
        let valueToCompareValue = '';
        if (filterSettings && filterSettings.valueToCompare !== undefined) {
            valueToCompareValue = filterSettings.valueToCompare;
        }
        let lowValue = '';
        if (filterSettings && filterSettings.lowValue !== undefined) {
            lowValue = filterSettings.lowValue;
        }
        let highValue = '';
        if (filterSettings && filterSettings.highValue !== undefined) {
            highValue = filterSettings.highValue;
        }

        // console.log('%c dataType: %O', 'color: red;', dataType);

        let firstElement = null;
        let dividerElement = null;
        let secondElement = null;
        if (conditionToCheckValue === 'between') {
            if (readonly) {
                firstElement = (
                    <Typography component="span" variant="body1" color="secondary">
                        {lowValue}
                    </Typography>
                );
                dividerElement = (
                    <Typography component="span" variant="caption">
                        and
                    </Typography>
                );
                dividerElement = (
                    <Typography component="span" variant="body1" color="secondary">
                        {highValue}
                    </Typography>
                );
            } else {
                firstElement = (
                    <Box>
                        <TextField fullWidth
                                    label={'Low Value'}
                                    value={lowValue}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(evt: any) => updateSetting('lowValue', evt)}
                                    margin="normal"
                                    variant='outlined'
                        />
                    </Box>
                );
                dividerElement = (
                    <Box>
                        <Typography variant="body1" color='textPrimary' noWrap={false}>
                            and
                        </Typography>
                    </Box>
                );
                secondElement = (
                    <Box>
                        <TextField fullWidth
                                    label={'High Value'}
                                    value={highValue}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(evt: any) => updateSetting('highValue', evt)}
                                    margin="normal"
                                    variant='outlined'
                        />
                    </Box>
                );
            }
        } else if (conditionToCheckValue === 'is.empty' || conditionToCheckValue === 'not.empty') {
            if (readonly) {
                dividerElement = (
                    <Typography component="span" variant="caption">
                        (compares record value to platform defined EMPTY value)
                    </Typography>
                );
            } else {
                dividerElement = (
                    <Box>
                        <Typography variant="caption" noWrap={false}>
                            compares record value to platform defined EMPTY value
                        </Typography>
                    </Box>
                );
            }
        } else if (conditionToCheckValue === 'is.null' || conditionToCheckValue === 'not.null') {
            if (readonly) {
                dividerElement = (
                    <Typography component="span" variant="caption">
                        (compares to platform defined NULL value)
                    </Typography>
                );
            } else {
                dividerElement = (
                    <Box>
                        <Typography variant="caption" noWrap={false}>
                            compares to platform defined NULL value
                        </Typography>
                    </Box>
                );
            }
        } else {
            if (readonly) {
                firstElement = (
                    <Typography component="span" variant="body1" color="secondary">
                        {valueToCompareValue}
                    </Typography>
                );
            } else {
                firstElement = (
                    <Box>
                        <TextField fullWidth
                                   label={'Compare Value'}
                                   value={valueToCompareValue}
                                   InputLabelProps={{ shrink: true }}
                                   onChange={(evt: any) => updateSetting('valueToCompare', evt)}
                                   margin="normal"
                                   variant='outlined'
                        />
                    </Box>
                );
            }
        }
        valueToCompareElement = (
            <Fragment>
                {firstElement}
                {dividerElement}
                {secondElement}
            </Fragment>
        );
    }

    if (readonly) {
        return (
            <React.Fragment>
                {conditionsSettingsElement}
                {valueToCompareElement}
            </React.Fragment>
        );
    } else {
        return (
            <Paper>
                {conditionsSettingsElement}
                {valueToCompareElement}
            </Paper>
        );
    }
}

export default FieldFilter;
