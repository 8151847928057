import { useState } from 'react';

import { Box, Typography, Icon, IconButton, InputAdornment, TextField, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext } from 'src/context/udicci-context';
import { useFocusedContext } from './focused-context';

import { UdicciRecord } from 'src/classes/udicci-record';

const PaperContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
}));

export const PromptAreaContent: React.FC<any> = (props) => {
    const focus = useFocusedContext();

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;
    let { currentUser, selectedPorta } = udicci;
    // console.log('%c PromptAreaContent currentUser: %O', 'color: brown;', currentUser);
    // console.log('%c PromptAreaContent selectedProfile: %O', 'color: brown;', selectedProfile);

    let userId: number = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
    // console.log('%c DisplayThePoint userId: %O', 'color: green;', userId);
    let createdByUserId: number = (selectedPorta && selectedPorta.CreatedByUserId ? selectedPorta.CreatedByUserId : 0);
    // console.log('%c DisplayThePoint createdByUserId: %O', 'color: green;', createdByUserId);

    let readonly: boolean = (userId <= 0 || createdByUserId !== userId ? true : false);
    // console.log('%c DisplayThePoint readonly: %O', 'color: green;', readonly);

    const [tempPrompt, setTempPrompt] = useState<string>('');
    // console.log('%c PromptAreaContent tempPrompt: %O', 'color: brown;', tempPrompt);

    const handleChange = (evt: any) => {
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c handleChange newValue: %O', 'color: maroon;', newValue);
        setTempPrompt(newValue);
    };

    const submitPrompt = (evt: any) => {
        // console.log('%c submitPrompt tempPrompt: %O', 'color: maroon;', tempPrompt);

        // if there is a point in focus, then we are adding a perspective to the point in focus.
        if (focus.point) {
            let newPerspective: UdicciRecord = focus.getNewPerspectiveRecord();
            newPerspective.title = tempPrompt;
            newPerspective.data.Title = tempPrompt;

            newPerspective.isDirty = true;

            let updatedPoint: any = {};
            Object.assign(updatedPoint, focus.point);

            let updatedPointPerspectives: any[] = (updatedPoint.perspectives ? updatedPoint.perspectives : []);
            updatedPointPerspectives.push(newPerspective);
            updatedPoint.perspectives = updatedPointPerspectives;

            updatedPoint.isDirty = true;
    
            // we are already passing the same method "onSetFocus" for onUpdate in other components,
            // but for this component we'll just use the "onSetFocus" method for updates instead of passing onUpdate
            if (focus.setPointToFocus) focus.setPointToFocus(updatedPoint);
        } else { // otherwise, we are adding a new point
            let newPoint: UdicciRecord = focus.getNewPointRecord();
            // console.log('%c submitPrompt newPoint %s: %O', 'color: blue;', newPoint.title, newPoint);
            newPoint.title = tempPrompt;
            newPoint.data.ThePoint = tempPrompt;

            if (!newPoint.context) newPoint.context = {};
            // add a local id for associations
            newPoint.context.local_id = udicciHelpers.generateUID();

            newPoint.isDirty = true;

            if (focus.setPointToFocus) focus.setPointToFocus(newPoint);
        }

        setTempPrompt('');
    };

    let containerProps: any = {
        textAlign: 'center',
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: 110,
        borderTop: 0,
    };
    if (!focus.isMobile) {
        containerProps.width = { sm: `calc(100% - ${udicci.standardMenuDrawerWidth}px)` };
    } else {
        containerProps.left = 0;
        containerProps.borderTop = '1px solid #000;';
    }

    let promptContainerSx: any = { maxWidth: '750px', marginLeft: 'auto', marginRight: 'auto' };

    let sendDisabled: boolean = (tempPrompt.length <= 2 ? true : false);
    let sendPromptIcon: any = (
        <InputAdornment position="end">
            <IconButton color="secondary" size="small" disabled={sendDisabled} onClick={submitPrompt}>
                <Icon fontSize="small">send</Icon>
            </IconButton>
        </InputAdornment>
    );

    let promptPlaceholder: string = 'What is your Point?';
    let version: string = 'Udicci.IT version 23.2.9';
    if (focus.point) promptPlaceholder = 'Add a perspective for this Point.';

    let promptElement: any = null;
    let versionElement: any = null;
    if (udicci.userIsLoggedIn() && !udicci.userHasTimedOut && (!readonly || (userId && focus.point))) {
        promptElement = (
            <Box>
                <TextField fullWidth
                           type="text"
                           value={tempPrompt}
                           placeholder={promptPlaceholder}
                           margin="normal"
                           variant='filled'
                           InputProps={{ endAdornment: sendPromptIcon, }}
                           sx={{ marginTop: '8px' }}
                           onChange={handleChange}
                />
            </Box>
        );
        versionElement = (
            <Box sx={{ display: 'flow-root', padding: '8px' }}>
                <Typography variant="caption" component="div">{version}</Typography>
            </Box>
        );
    } else {
        if (readonly && userId) {
            promptElement = (
                <Box sx={{ paddingTop: '8px' }}>
                    <Typography variant="body2" component="div">Udicci.IT</Typography>
                    <Typography variant="caption" component="div">You do not have permission to add Points to this focus board.</Typography>
                </Box>
            );
        } else {
            promptElement = (
                <Box sx={{ paddingTop: '8px' }}>
                    <Typography variant="body2" component="div">Udicci.IT</Typography>
                    <Typography variant="caption" component="div">Please login to start using AI.</Typography>
                    <Typography variant="caption" component="div">Engage with Us at your favorite Udicci Portal!</Typography>
                </Box>
            );
        }
        versionElement = (
            <Box sx={{ display: 'flow-root', padding: '8px' }}>
                <Typography variant="caption" component="div">{version}</Typography>
            </Box>
        );
    }

    return (
        <Box {...containerProps}>
            <Box {...promptContainerSx}>
                <PaperContainer>
                    {promptElement}
                    {versionElement}
                </PaperContainer>
            </Box>
        </Box>
    );
};
