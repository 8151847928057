import React, { Fragment, useContext, useState } from 'react';

import { useTheme } from '@mui/material/styles';

import { find } from 'underscore';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import CloseIcon from '@mui/icons-material/Close';
import EditRoleIcon from '@mui/icons-material/Edit';
import DeleteRoleIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { UdicciContext } from 'src/context/udicci-context';
import { UdicciRecord } from 'src/classes/udicci-record';
import { udicciStyles } from 'src/theme/shared-styles';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

import useUdicciRecord from 'src/hooks/useUdicciRecord';
import { link } from 'fs';

const ManageProfileRoles: React.FC<any> = () => {
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);
    const udicciHelpers = useUdicciHelpers();

    const udicciContext = useContext(UdicciContext);
    let { data, udicci } = udicciContext.state;

    let { selectedProfile } = udicci;
    // console.log('%c ManageProfileRoles selectedProfile: %O', 'color: brown;', selectedProfile);

    const [selectedRole, setSelectedRole] = useState<any>(null);
    const [actionToPerform, setActionToPerform] = useState<string>('');
    const [forcedUpdateState, forceUpdate] = useState<boolean>(false);

    var roles = udicciHelpers.preloadMediatorData('Roles', udicci.socialSolutionUdicciIT);
    // console.log('%c roles: %O', 'color: maroon;', roles);

    let structure: any = null;
    let permissions: any = null;
    if (data) {
        let mediatorContext = data.find((x: any) => x.mediator === 'Roles');
        // console.log('%c mediatorContext: %O', 'color: maroon;', mediatorContext);
        if (mediatorContext && mediatorContext.linkedData)
            structure = mediatorContext.structure;
        if (mediatorContext && mediatorContext.permissions)
            permissions = mediatorContext.permissions;
    }
    // console.log('%c ManageProfileRoles linkedData: %O', 'color: maroon;', linkedData);
    // console.log('%c ManageProfileRoles structure: %O', 'color: maroon;', structure);

    const addRole = (evt: any) => {
        // console.log('%c addRole evt: %O', 'color: maroon;', evt);
        let newRole = new UdicciRecord('Roles', null, structure, permissions);
        // console.log('%c newRole: %O', 'color: blue;', newRole);
        setSelectedRole(newRole);
        setActionToPerform('add');
    };

    const editRole = (roleToEdit: UdicciRecord, evt: any) => {
        // console.log('%c editRole roleToEdit: %O', 'color: maroon;', roleToEdit);
        setSelectedRole(roleToEdit);
        setActionToPerform('edit');
    };

    const deleteRole = (roleToDelete: UdicciRecord, evt: any) => {
        // console.log('%c deleteRole roleToDelete: %O', 'color: maroon;', roleToDelete);
        setSelectedRole(roleToDelete);
        setActionToPerform('delete');
    };

    const confirmDeleteRole = (evt: any) => {
        // console.log('%c confirmDeleteRole selectedRole: %O', 'color: maroon;', selectedRole);
        if (selectedRole && selectedRole.isSaving === false) {
            selectedRole.isSaving = true;
            // console.log('%c confirmDeleteRole selectedRole: %O', 'color: maroon;', selectedRole);
            setSelectedRole(selectedRole);
            forceUpdate(!forcedUpdateState);

            // console.log('%c confirmDeleteRole selectedRole: %O', 'color: maroon;', selectedRole);
            // deleteContextRecord(udicciContext, selectedRole, {
            //     onSuccess: deleteRoleSuccess,
            //     onError: deleteRoleFailed
            // });
        }
    };

    const closeRole = (evt: any) => {
        // if (selectedRole && selectedRole.isSaving === true)
        //     return false;
        setSelectedRole(null);
        setActionToPerform('');
    };

    const onChangeRole = (fieldName: string, evt: any) => {
        // console.log('%c onChangeRole fieldName: %O', 'color: red;', fieldName);
        // console.log('%c onChangeRole evt: %O', 'color: red;', evt);
        if (selectedRole && selectedRole.data && fieldName) {
            let trgt = evt.target;
            // let targetName = (trgt ? trgt.name : '');
            // console.log('%c onChangeRole targetName: %O', 'color: maroon;', targetName);
            let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
            // console.log('%c onChangeRole newValue: %O', 'color: maroon;', newValue);
            selectedRole.data[fieldName] = newValue;
            // console.log('%c onChangeRole selectedRole: %O', 'color: maroon;', selectedRole);
            selectedRole.isDirty = true;
            // console.log('%c onChangeRole selectedRole: %O', 'color: maroon;', selectedRole);
            setSelectedRole(selectedRole);
            forceUpdate(!forcedUpdateState);
        }
    };

    let addRoleButtonElement: any = (
        <IconButton size="small" color="secondary" sx={udicciClasses.addIconButton} onClick={addRole}>
            <AddIcon />
        </IconButton>
    );

    let overrideDisabled: boolean = (selectedRole ? true : false);

    let addRoleRow: any = null;
    if (actionToPerform === 'add') {
        addRoleRow = (
            <TableRow key={'table.row.role.new'}>
                <TableCell align="left" colSpan={2} classes={{ root: 'cellWithInlineForm' }}>
                    <Paper elevation={4} classes={{ root: 'inlineFormPaper' }}>
                        <Box sx={udicciClasses.content}>
                            <IconButton aria-label="Close" size="small" onClick={(evt: any) => closeRole(evt)} classes={{ root: 'closeIconButton' }}>
                                <CloseIcon />
                            </IconButton>
                            <RoleForm role={selectedRole}
                                      onChangeRole={onChangeRole}
                                      onClose={(evt: any) => closeRole(evt)}
                            />
                        </Box>
                    </Paper>
                </TableCell>
            </TableRow>
        );
    }

    let roleListElement = (
        <TableContainer component={Paper}>
            <Table classes={{ root: 'tableRoot' }}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left"><Typography variant="subtitle1">Role Name</Typography></TableCell>
                        <TableCell align="right">{addRoleButtonElement}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {addRoleRow}
                    {roles && roles.map((role: UdicciRecord) => {
                        let isSelectedRole = (selectedRole && selectedRole.recordId === role.recordId ? true : false);
                        // console.log('%c role: %O', 'color: red;', role);
                        // console.log('%c isSelectedRole: %O', 'color: red;', isSelectedRole);
                        let rd: any = role.data;
                        // let createdBy: string = (role && role.createdBy ? role.createdBy : '');
                        // let createdDate: Date | null = (role && role.createdDate ? role.createdDate : null);
                        let roleId: number = (rd && rd.UdicciRecordId ? rd.UdicciRecordId : 0);
                        let roleName: string = (rd && rd.RoleName ? rd.RoleName : '');
                        // console.log('%c roleAgreements: %O', 'color: red;', roleAgreements);
                        // console.log('%c assignedAgreements: %O', 'color: red;', assignedAgreements);
                        let editRoleButtonElement: any = null;
                        let deleteRoleButtonElement: any = null;
                        let closeRoleButtonElement: any = null;

                        if ((!selectedRole && actionToPerform === '') || (selectedRole && selectedRole.recordId !== roleId)) {
                            editRoleButtonElement = (
                                <IconButton disabled={overrideDisabled} aria-label="View/Edit Role" size="small" onClick={(evt: any) => editRole(role, evt)}>
                                    <EditRoleIcon />
                                </IconButton>
                            );
                            deleteRoleButtonElement = (
                                <IconButton disabled={overrideDisabled} aria-label="Delete History Item" size="small" onClick={(evt: any) => deleteRole(role, evt)}>
                                    <DeleteRoleIcon />
                                </IconButton>
                            );
                        } else {
                            closeRoleButtonElement = (
                                <IconButton aria-label="Close" size="small" onClick={(evt: any) => closeRole(evt)} classes={{ root: 'closeIconButton' }}>
                                    <CloseIcon />
                                </IconButton>
                            );
                        }

                        let commandsElement: any = null;
                        let rowElement: any = null;
                        if (isSelectedRole) {
                            let roleName: any = (selectedRole && selectedRole.data && selectedRole.data.RoleName ? selectedRole.data.RoleName : '');

                            let roleFormElement: any = null;
                            if (actionToPerform === 'delete') {
                                roleFormElement = (
                                    <Fragment>
                                        <Box>
                                            {closeRoleButtonElement}
                                            <Typography variant="body2" component="span">
                                                Are you sure you want to delete the
                                            </Typography>
                                            &nbsp;'
                                            <Typography variant="subtitle2" component="span">
                                                {roleName}
                                            </Typography>
                                            '&nbsp;
                                            <Typography variant="body2" component="span">
                                                role?
                                            </Typography>
                                        </Box>
                                        <Box sx={udicciClasses.root}>
                                            <Avatar variant="square" sx={(selectedRole.isSaving ? udicciClasses.disabledSquare : udicciClasses.ysquare)} onClick={(evt: any) => confirmDeleteRole(evt)}>
                                                Yes
                                            </Avatar>
                                            <Avatar variant="square" sx={(selectedRole.isSaving ? udicciClasses.disabledSquare : udicciClasses.nsquare)} onClick={(evt: any) => closeRole(evt)}>
                                                No
                                            </Avatar>
                                        </Box>
                                    </Fragment>
                                );
                            } else {
                                roleFormElement = (
                                    <Box>
                                        {closeRoleButtonElement}
                                        <RoleForm role={selectedRole}
                                                  onChangeRole={onChangeRole}
                                                  onClose={(evt: any) => closeRole(evt)}
                                        />
                                    </Box>

                                );
                            }

                            commandsElement = (
                                <ButtonGroup size="small">
                                    {editRoleButtonElement}
                                    {deleteRoleButtonElement}
                                </ButtonGroup>
                            );

                            rowElement = (
                                <TableRow key={'table.row.role.' + role.recordId}>
                                    <TableCell align="left" colSpan={2} classes={{ root: 'cellWithInlineForm' }}>
                                        <Paper elevation={4} classes={{ root: 'inlineFormPaper' }}>
                                            {roleFormElement}
                                            {commandsElement}
                                        </Paper>
                                    </TableCell>
                                </TableRow>
                            );
                        } else {
                            commandsElement = (
                                <ButtonGroup size="small">
                                    {editRoleButtonElement}
                                    {deleteRoleButtonElement}
                                </ButtonGroup>
                            );

                            rowElement = (
                                <TableRow key={'table.row.role.' + role.recordId} selected={isSelectedRole}>
                                    <TableCell scope="row" align="left">
                                        <Typography variant="body1" component="span">
                                            {roleName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">{commandsElement}</TableCell>
                                </TableRow>
                            );
                        }

                        return rowElement;
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <Box sx={{ margin: '8px' }}>
            <Typography variant="subtitle1" noWrap> Roles &amp; Permissions </Typography>
            {roleListElement}
        </Box>
    );
};

const RoleForm: React.FC<any> = ({ role, onClose, onChangeRole }) => {
    // console.log('%c RoleForm role: %O', 'color: brown;', role);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useContext(UdicciContext);
    let { udicci } = udicciContext.state;

    let { selectedProfile } = udicci;
    // console.log('%c RoleForm selectedProfile: %O', 'color: brown;', selectedProfile);

    let { udicciRecord, setRecord, saveRecord } = useUdicciRecord(role);
    // console.log('%c RoleForm udicciRecord: %O', 'color: brown;', udicciRecord);

    const [errorMessage, setErrorMessage] = useState<string>('');

    const closeRole = (evt: any) => {
        if (onClose) onClose();
    };

    const changeRoleValue = (fieldName: string, evt: any) => {
        if (onChangeRole) onChangeRole(fieldName, evt);
    };

    const saveRole = (evt: any) => {
        if (!selectedProfile) return false;

        // console.log('%c saveRole udicciRecord: %O', 'color: maroon;', udicciRecord);
        if (udicciRecord && udicciRecord.isDirty) {
            if (!udicciRecord.isSaving) {
                let rd = (udicciRecord && udicciRecord.data ? udicciRecord.data : {});
                // console.log('%c saveRole rd: %O', 'color: maroon;', rd);
                let jsonSettingsJson = (rd.jsonSettingsJson ? rd.jsonSettingsJson : null);
                // console.log('%c saveRole jsonSettingsJson: %O', 'color: maroon;', jsonSettingsJson);
                let settingsJson = '';
                if (jsonSettingsJson) {
                    try {
                        settingsJson = JSON.stringify(jsonSettingsJson);
                    } catch (ex: any) {
                    }
                }

                rd.settingsJson = settingsJson;
                if (udicciRecord.recordId <= 0) rd.CreatedInSolutionId = udicci.socialSolutionUdicciIT;
                udicciRecord.data = rd;

                setRecord(udicciRecord);

                // temporary fix to ensure the role has the correct parent association
                let foundParentProfile: boolean = false;
                if (udicciRecord.data.UdicciRecordId > 0) {
                    let medCtx: any = udicciHelpers.getMediatorContext(udicciRecord.udicciMediator);
                    // console.log('%c saveRole medCtx: %O', 'color: maroon;', medCtx);
                    let linkedData = (medCtx && medCtx.linkedData ? medCtx.linkedData : null);
                    // console.log('%c saveRole linkedData: %O', 'color: maroon;', linkedData);
                    if (linkedData && linkedData.length > 0) {
                        let lum: any = find(linkedData, (ld: any) => { return ld.LeftUdicciMediatorName === 'Udicci Profiles' && ld.RightUdicciMediatorName === 'Roles' });
                        // console.log('%c saveRole lum: %O', 'color: maroon;', lum);
                        let lumData = (lum && lum.Data ? lum.Data : null);
                        // console.log('%c saveRole lumData: %O', 'color: maroon;', lumData);
                        if (lumData && lumData.length > 0) {
                            let linkedRecord: any = find(lumData, (rec: any) => { return rec.RightId === udicciRecord.recordId });
                            // console.log('%c saveRole linkedRecord: %O', 'color: maroon;', linkedRecord);
                            if (linkedRecord) foundParentProfile = true;
                        }
                    }
                }
                // console.log('%c saveRole foundParentProfile: %O', 'color: maroon;', foundParentProfile);

                let saveSettings: any = {
                    socialSolutionId: udicci.socialSolutionUdicciIT
                };
                if (udicciRecord.data.UdicciRecordId <= 0 || !foundParentProfile) {
                    let relationshipChangesJson = JSON.stringify([{
                        "Add": "Parent",
                        "RecordMediator": "Roles",
                        "RecordId": (udicciRecord.data.UdicciRecordId ? udicciRecord.data.UdicciRecordId : 0),
                        "RelatedMediator": "Udicci Profiles",
                        "RelatedRecordId": selectedProfile.data.UdicciRecordId,
                        "Priority": -1
                    }]);
                    saveSettings['RelationshipChanges'] = relationshipChangesJson;
                }
                let okToContinue: boolean = true;
                // console.log('%c saveRole saveSettings: %O', 'color: maroon;', saveSettings);
                if (okToContinue) saveRecord(saveSettings);
            }
        } else {
            if (!udicciRecord)
                setErrorMessage('No role selected.');
            else
                setErrorMessage('The Role has no changes to save.');
        }
    };

    let srd: any = (udicciRecord && udicciRecord.data ? udicciRecord.data : null);
    let isPublicRole = (srd !== null && srd.IsPublicRole ? true : false);
    let isAdminRole = (srd !== null && srd.IsAdministratorRole ? true : false);

    let publicRoleElement: any = null;
    let adminRoleElement: any = null;
    if (!isAdminRole) {
        publicRoleElement = (
            <FormControlLabel
                label={(
                    <Typography variant="caption">
                        Public Role
                    </Typography>
                )}
                control={<Checkbox checked={(srd !== null && srd.IsPublicRole ? true : false)}
                    onChange={(evt: any) => changeRoleValue('IsPublicRole', evt)}
                    color="primary" />} />
        );
    }
    if (!isPublicRole) {
        adminRoleElement = (
            <FormControlLabel
                label={(
                    <Typography variant="caption">
                        Administrator Role
                    </Typography>
                )}
                control={<Checkbox checked={(srd !== null && srd.IsAdministratorRole ? true : false)}
                    onChange={(evt: any) => changeRoleValue('IsAdministratorRole', evt)}
                    color="primary" />} />
        );
    }

    let isSaving = (udicciRecord && udicciRecord.isSaving === true ? true : false);
    let saveButtonDisabled = (!isSaving && udicciRecord && udicciRecord.isDirty === true ? false : true);
    let saveRoleButton = (
        <Button size="small" color="primary" onClick={saveRole} disabled={saveButtonDisabled}>
            {(isSaving ? 'saving...' : 'Save')}
        </Button>
    );
    let cancelButtonDisabled = (isSaving ? true : false);
    let cancelRoleButton = (
        <Button size="small" color="primary" disabled={cancelButtonDisabled} onClick={(evt: any) => closeRole(evt)}>
            Cancel
        </Button>
    );

    let errorMessageElement: any = null;
    if (errorMessage) {
        errorMessageElement = (
            <Typography variant="errorMessage">
                {errorMessage}
            </Typography>
        );
    }

    return (
        <div style={{ padding: '4px' }}>
            {errorMessageElement}
            <div style={{ margin: '4px', padding: '4px' }}>
                <InputLabel htmlFor="role.rolename">
                    <Typography variant="subtitle1" component="div">
                        Role Name
                    </Typography>
                </InputLabel>
                <Input id="role.rolename"
                    type={'text'}
                    fullWidth={true}
                    value={(srd !== null && srd.RoleName ? srd.RoleName : '')}
                    onChange={(evt: any) => changeRoleValue('RoleName', evt)} />
            </div>
            <div style={{ margin: '4px', padding: '4px' }}>
                <InputLabel htmlFor="role.Overview">
                    <Typography variant="caption" component="div">
                        Overview
                    </Typography>
                </InputLabel>
                <Input id="role.Overview" fullWidth={true}
                    type={'text'} multiline={true} minRows={3} maxRows={15}
                    value={(srd !== null && srd.Overview ? srd.Overview : '')}
                    onChange={(evt: any) => changeRoleValue('Overview', evt)} />
            </div>
            <div style={{ margin: '4px', padding: '4px' }}>
                {publicRoleElement}
                {adminRoleElement}
            </div>
            <Box sx={udicciClasses.buttonArea}>
                {cancelRoleButton}
                {saveRoleButton}
            </Box>
        </div>
    );
};

export default ManageProfileRoles;
