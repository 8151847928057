

import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';

import { useUdicciContext, getProfileSetting } from 'src/context/udicci-context';
import { useUdicciListContext } from 'src/context/udicci-list-context';
import FieldDisplay from './field-display';
import useUdicciMediator from 'src/hooks/useUdicciMediator';
import useUdicciRecord from 'src/hooks/useUdicciRecord';

import { udicciStyles } from 'src/theme/shared-styles';

const RecordPreview: React.FC<any> = (props) => {
    // console.log('%c RecordPreview props: %O', 'color: purple;', props);

    const udicciListContext = useUdicciListContext();
    const theme = useTheme();
    // const udicciClasses = udicciStyles(theme);

    // const udicciContext = useUdicciContext();
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);
    // let { udicci } = udicciContext.state;
    // console.log('%c RecordPreview data: %O', 'color: purple;', data);
    // console.log('%c RecordPreview udicci: %O', 'color: purple;', udicci);
    // let { selectedProfile } = udicci;
    // console.log('%c RecordPreview selectedProfile: %O', 'color: purple;', selectedProfile);

    let {
        // engageAction, // setRecord, saveRecord, deleteRecord,
        // getListElement, getEngagementActionElement,
        // getFieldElement, engagementAction, recordContext, contextRetrieved, datasets, 
        // getRecordContext, navigateContext, directJump, socialSolution,
        udicciRecord, getSocialSolutionSetting
    } = useUdicciRecord(props.record);
    // console.log('%c RecordPreview udicciRecord: %O', 'color: purple;', udicciRecord);
    // console.log('%c RecordPreview socialSolution: %O', 'color: purple;', socialSolution);

    const um = useUdicciMediator(udicciRecord.udicciMediator);
    let { udicciMediator } = um;
    // console.log('%c RecordPreview udicciMediator: %O', 'color: purple;', udicciMediator);

    let curUdicciListState = (udicciListContext && udicciListContext.state ? udicciListContext.state : null);
    // console.log('%c RecordPreview curUdicciListState: %O', 'color: maroon;', curUdicciListState);
    let lsSolution: any = (curUdicciListState && curUdicciListState.socialSolution ? curUdicciListState.socialSolution : null);
    // console.log('%c RecordPreview lsSolution: %O', 'color: maroon;', lsSolution);
    let lsFeature: any = (curUdicciListState && curUdicciListState.feature ? curUdicciListState.feature : null);
    // console.log('%c RecordPreview lsFeature: %O', 'color: maroon;', lsFeature);

    let fields: any | null = (udicciMediator.UdicciMediatorFields ? udicciMediator.UdicciMediatorFields : null);
    // console.log('%c RecordPreview fields: %O', 'color: maroon;', fields);

    let recordId = (udicciRecord.recordId ? udicciRecord.recordId : 0);
    // console.log('%c recordId: %O', 'color: maroon;', recordId);
    let lsSolutionId: number = (lsSolution && lsSolution.id ? lsSolution.id : 0);
    // console.log('%c RecordPreview lsSolutionId: %O', 'color: maroon;', lsSolutionId);
    if (!lsSolutionId && udicciRecord.data && udicciRecord.data.CreatedInSolutionId) {
        lsSolutionId = udicciRecord.data.CreatedInSolutionId;
        // console.log('%c RecordPreview lsSolutionId: %O', 'color: red;', lsSolutionId);
    }
    let lsFeatureId: number = (lsFeature && lsFeature.id ? lsFeature.id : 0);
    // console.log('%c RecordPreview lsFeatureId: %O', 'color: maroon;', lsFeatureId);

    let listConfig: any = getProfileSetting(udicciRecord.udicciMediator, lsSolutionId, 'listConfig');
    // console.log('%c RecordPreview listConfig: %O', 'color: purple;', listConfig);
    if (!listConfig) listConfig = getSocialSolutionSetting({ setting: 'listConfig', featureId: lsFeatureId, mediatorName: udicciRecord.udicciMediator });
    // console.log('%c RecordPreview listConfig: %O', 'color: blue;', listConfig);

    let formConfig: any = getProfileSetting(udicciRecord.udicciMediator, lsSolutionId, 'formConfig');
    // console.log('%c RecordPreview formConfig: %O', 'color: purple;', formConfig);
    if (!formConfig) formConfig = getSocialSolutionSetting({ setting: 'formConfig', featureId: lsFeatureId, mediatorName: udicciRecord.udicciMediator });
    // console.log('%c RecordPreview formConfig: %O', 'color: blue;', formConfig);

    let lcflds: any | null = (listConfig && listConfig.fields ? listConfig.fields : null);
    // console.log('%c RecordPreview listConfigFields: %O', 'color: maroon;', lcflds);

    let fieldDisplayElements: any[] = [];
    if (fields && fields.length > 0) {
        fields.forEach(function(field: any) {
            // console.log('%c field: %O', 'color: blue;', field);
            if (field.UdicciMediatorId <= 0) return true;
            if (field.DataType === 'Json') return true;
            let isListConfigField: boolean = (lcflds && lcflds[field.JsonFieldName] !== undefined ? true : false);
            // console.log('%c isListConfigField: %O', 'color: blue;', isListConfigField);
            if (lcflds && !isListConfigField) {
                return true;
            } else if (!field.DisplayInList && !lcflds) return true;

            let listConfigField: any = (lcflds && lcflds[field.JsonFieldName] ? lcflds[field.JsonFieldName] : null);
            // console.log('%c RecordPreview listConfigField: %O', 'color: maroon;', listConfigField);
            let fldlbl: any | null = (listConfigField && listConfigField.label ? listConfigField.label : null);
            // console.log('%c RecordPreview fieldLabel: %O', 'color: maroon;', fldlbl);
            // if (!fldlbl && (listConfigField.variant || listConfigField.color || listConfigField.component)) fldlbl = listConfigField;
        
            let component: string = 'div';
            let variant: string = 'body2';
            let color: string = 'textPrimary';

            if (listConfigField && listConfigField.component) component = listConfigField.component;
            if (fldlbl && fldlbl.component) component = fldlbl.component;

            if (listConfigField && listConfigField.variant) variant = listConfigField.variant;
            if (fldlbl && fldlbl.variant) variant = fldlbl.variant;

            if (listConfigField && listConfigField.color) color = listConfigField.color;
            if (fldlbl && fldlbl.color) color = fldlbl.color;

            let displayElementProps: any = {};
            Object.assign(displayElementProps, props);
            displayElementProps.key = 'record.' + recordId + '.field.' + field.UdicciMediatorFieldId;
            displayElementProps.readonly = true;
            displayElementProps.showTrimmed = false;
            displayElementProps.hideEmptyFields = true;
            displayElementProps.field = field;
            displayElementProps.cutoffLength = 77;

            displayElementProps.displaySettings = {};
            displayElementProps.displaySettings.component = component;
            displayElementProps.displaySettings.variant = variant;
            // displayElementProps.displaySettings.color = color;
            displayElementProps.displaySettings.gutterBottom = false;
            // displayElementProps.sx = udicciClasses.contentElement;
            // console.log('%c displayElementProps: %O', 'color: blue;', displayElementProps);

            fieldDisplayElements.push( <FieldDisplay {...displayElementProps} /> );
        });
    }

    return (
        <Box>
            {fieldDisplayElements}
        </Box>
    );
}

export { RecordPreview };
