// import { Fragment } from 'react';

import { find, filter, values } from 'underscore';

import dynamic from 'next/dynamic';

import { Stack, Box, LinearProgress } from '@mui/material';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/AddToQueue';

import { useUdicciContext } from 'src/context/udicci-context'
import { UdicciListContextStateType } from 'src/classes/udicci-types';
import { UdicciListContextProvider } from 'src/context/udicci-list-context';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

const RecordDisplay = dynamic(() => import('src/components/udicci-record-display'));
const RecordDisplayAdmin = dynamic(() => import('src/admin/record-display-admin'));

const PluginId: string = 'udicci.single.record';
const PluginTitle: string = 'Single Record View';
const PluginDescription: string = 'Display a Single Social Solution Record as readonly or in a form.';
const PluginVersion: number = 1;

const PluginRenderer = (props: any) => {
    // console.log('%c PluginRenderer props: %O', 'color: red;', props);
    if (!props.data) props.data = {};
    props.data.showInline = true;
    // console.log('%c PluginRenderer props.data: %O', 'color: red;', props.data);
    return ( <UdicciSingleRecord {...props} /> );
};

const PluginAdminRenderer = (props: any) => {
    // console.log('%c PluginAdminRenderer props: %O', 'color: red;', props);
    if (!props.data) props.data = {};
    props.data.showInline = true;
    // console.log('%c PluginAdminRenderer props.data: %O', 'color: red;', props.data);
    return ( <RecordDisplayAdmin {...props} /> );
};

const udicciSingleRecordPlugin: UdicciPlugin = {
    Renderer: PluginRenderer,
    id: PluginId,
    title: PluginTitle,
    description: PluginDescription,
    version: PluginVersion,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: PluginAdminRenderer },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c udicciSingleRecordPlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c udicciSingleRecordPlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
     },
    category: UdicciPluginCategory.Data
};
export default udicciSingleRecordPlugin;

const UdicciSingleRecord: React.FC<any> = (props: any) => {
    // console.log('%c UdicciSingleRecord props: %O', 'color: maroon;', props);

    const udicciContext = useUdicciContext();
    const udicciHelpers = useUdicciHelpers();

    let propData: any = (props && props.data ? props.data : null);
    // console.log('%c UdicciSingleRecord propData: %O', 'color: maroon;', propData);
    let recordDisplaySettings: any = (propData && propData.recordDisplay ? propData.recordDisplay : null);
    // console.log('%c UdicciSingleRecord recordDisplaySettings: %O', 'color: maroon;', recordDisplaySettings);
    let socialSolution: any = (recordDisplaySettings && recordDisplaySettings.socialSolution ? recordDisplaySettings.socialSolution : null);
    // console.log('%c UdicciSingleRecord socialSolution: %O', 'color: maroon;', socialSolution);
    let feature: any = (recordDisplaySettings && recordDisplaySettings.feature ? recordDisplaySettings.feature : null);
    // console.log('%c UdicciSingleRecord feature: %O', 'color: maroon;', feature);
    let mediator: any = (recordDisplaySettings && recordDisplaySettings.mediator ? recordDisplaySettings.mediator : null);
    // console.log('%c UdicciSingleRecord mediator: %O', 'color: maroon;', mediator);
    let layout: any = (recordDisplaySettings && recordDisplaySettings.layout ? recordDisplaySettings.layout : null);
    // console.log('%c UdicciSingleRecord layout: %O', 'color: maroon;', layout);
    let contextRecord: any = (recordDisplaySettings && recordDisplaySettings.record ? recordDisplaySettings.record : null);
    // console.log('%c UdicciSingleRecord contextRecord: %O', 'color: maroon;', contextRecord);

    let selectedFeatureId: number = (feature && feature.id ? feature.id : 0);
    // console.log('%c UdicciSingleRecord selectedFeatureId: %O', 'color: maroon;', selectedFeatureId);

    let { udicci, data } = udicciContext.state;
    let { selectedProfile, currentUser } = udicci;
    var profile = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    // console.log('%c UdicciSingleRecord profile: %O', 'color: maroon;', profile);
    var udicciUserRole = (profile && profile.UdicciUserRole ? profile.UdicciUserRole : null);
    // console.log('%c udicciUserRole: %O', 'color: purple; font-weight: bold;', udicciUserRole);
    var udicciUserRoleId = (udicciUserRole && udicciUserRole.UdicciRecordId ? udicciUserRole.UdicciRecordId : 0);
    // console.log('%c UdicciSingleRecord udicciUserRoleId: %O', 'color: purple; font-weight: bold;', udicciUserRoleId);

    var profileSolutions = (profile && profile.SocialSolutions ? profile.SocialSolutions : null);
    // console.log('%c profileSolutions: %O', 'color: purple; font-weight: bold;', profileSolutions);
    let profileSettings = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
    // console.log('%c profileSettings: %O', 'color: hotpink;', profileSettings);

    var includedSocialSolutions: any = {};
    // var socialSolutionsMenuElement: any = null;
    if (profileSettings && profileSettings.socialSolutionSettings) {
        if (currentUser && currentUser.UdicciUserId > 0) {
            var sss: any = profileSettings.socialSolutionSettings;
            // console.log('%c UdicciSingleRecord socialSolutionSettings: %O', 'color: maroon;', sss);
            if (sss) {
                for (let [id, setting] of Object.entries<any>(sss)) {
                    // console.log('%c UdicciSingleRecord id: %O', 'color: green;', id);
                    // console.log('%c UdicciSingleRecord setting: %O', 'color: green;', setting);

                    var publicPermissions = (setting.public ? setting.public : null);
                    // console.log('%c UdicciSingleRecord publicPermissions: %O', 'color: maroon;', publicPermissions);
                    var defaultPermissions = (setting.default ? setting.default : null);
                    // console.log('%c UdicciSingleRecord defaultPermissions: %O', 'color: maroon;', defaultPermissions);

                    var permissions: any | null = null;
                    if (currentUser && currentUser.UdicciUserId > 0) {
                        permissions = defaultPermissions;
                        // check for current user role overrides
                    } else {
                        permissions = publicPermissions;
                    }
                    // console.log('%c UdicciSingleRecord permissions: %O', 'color: blue;', permissions);

                    var roleOverrides = (setting.RoleOverrides ? setting.RoleOverrides : null);
                    // console.log('%c UdicciSingleRecord roleOverrides: %O', 'color: maroon;', roleOverrides);
                    var userRoleOverride = (udicciUserRoleId > 0 && roleOverrides && roleOverrides[udicciUserRoleId] ? roleOverrides[udicciUserRoleId] : null);
                    // console.log('%c UdicciSingleRecord userRoleOverride: %O', 'color: maroon;', userRoleOverride);
                    if (userRoleOverride) {
                        // console.log('%c UdicciSingleRecord userRoleOverride: %O', 'color: green;', userRoleOverride);
                        permissions = userRoleOverride;

                        if (userRoleOverride.overrides) {
                            for (var [medName, medPerms] of Object.entries<any>(userRoleOverride.overrides)) {
                                // console.log('%c UdicciSingleRecord medName: %O', 'color: green;', medName);
                                // console.log('%c UdicciSingleRecord medPerms: %O', 'color: green;', medPerms);
                                if (!medName) continue;

                                if (medPerms && medPerms.CanView !== undefined) permissions.CanView = medPerms.CanView;
                                if (medPerms && medPerms.CanAdd !== undefined) permissions.CanAdd = medPerms.CanAdd;
                            }
                        }
                    }

                    // console.log('%c UdicciSingleRecord permissions: %O', 'color: blue;', permissions);
                    if (permissions && permissions.CanView) {
                        // console.log('%c UdicciSingleRecord permissions: %O', 'color: green; font-weight: bold;', permissions);
                        if (!includedSocialSolutions[id]) includedSocialSolutions[id] = {};

                        var ss: any | null = null;
                        if (profileSolutions) {
                            ss = profileSolutions.find((x: any) => x.recordId.toString() === id.toString());
                        }
                        // console.log('%c ss: %O', 'color: green; font-weight: bold;', ss);

                        if (ss) {
                            if (!ss.permissions) ss.permissions = permissions;
                            includedSocialSolutions[id].permissions = permissions;
                            includedSocialSolutions[id].setting = setting;
                            includedSocialSolutions[id].solution = ss;
                            // ssCount++;
                        }
                    }
                }
            }
            // console.log('%c UdicciSingleRecord ssCount: %O', 'color: blue;', ssCount);
        }
    }
    // console.log('%c UdicciSingleRecord includedSocialSolutions: %O', 'color: blue;', includedSocialSolutions);
    // console.log('%c UdicciSingleRecord socialSolution: %O', 'color: blue;', socialSolution);

    
    // var layoutTitle: any | null = (layout && layout.title !== undefined ? layout.title : null);
    // console.log('%c UdicciListContextProvider layoutTitle: %O', 'color: red;', layoutTitle);
    // var layoutTitleFields: any | null = (layoutTitle && layoutTitle.layoutFields !== undefined ? layoutTitle.layoutFields : null);
    // console.log('%c UdicciListContextProvider layoutTitleFields: %O', 'color: red;', layoutTitleFields);

    let selSS: any = null;
    if (includedSocialSolutions && socialSolution) {
        selSS = find(includedSocialSolutions, function(ss: any) {
            return ss.solution && ss.solution.recordId === socialSolution.id;
        });
    }
    // console.log('%c selSS: %O', 'color: purple; font-weight: bold;', selSS);
    if (!selSS) {
        return (
            <Stack sx={{ color: 'grey.500', margin: '8px' }} spacing={1}>
                <LinearProgress color="inherit" />
                <LinearProgress color="inherit" />
                <LinearProgress color="inherit" />
            </Stack>            
        );
    }

    let selFtre: any = null;
    let ssData = (selSS.solution.data ? selSS.solution.data : null);
    // console.log('%c ssData: %O', 'color: hotpink;', ssData);
    let ssSettings = (ssData.jsonSettingsJson ? ssData.jsonSettingsJson : null);
    // console.log('%c ssSettings: %O', 'color: hotpink;', ssSettings);

    let features = (ssData && ssData.Features ? ssData.Features : null);
    // console.log('%c features: %O', 'color: hotpink;', features);
    let mediators = (ssSettings && ssSettings.mediators ? ssSettings.mediators : null);
    // console.log('%c mediators: %O', 'color: hotpink;', mediators);

    if (mediators !== null && features && features.length > 0) {
        features.forEach(function(ftre: any, idx: number) {
            // console.log('%c ftre %s: %O', 'color: blue;', ftre.Name, ftre);
            // console.log('%c idx: %O', 'color: blue;', idx);
            let featureMediators = filter(values(mediators), function(med: any) {
                // console.log('%c med %s: %O', 'color: blue;', med.name, med);
                let isDefaultFeature: boolean = (med.feature === ftre.Name ? true : false);
                let otherFeatureMediator: any = null;
                if (med.features && med.features.length > 0) {
                    otherFeatureMediator = find(med.features, function(ftrMed: any) {
                        return ftrMed.name === ftre.Name;
                    });
                }
                // console.log('%c isDefaultFeature: %O', 'color: red;', isDefaultFeature);
                // console.log('%c otherFeatureMediator: %O', 'color: red;', otherFeatureMediator);
                return isDefaultFeature || otherFeatureMediator;
            });
            // // console.log('%c featureMediators: %O', 'color: blue;', featureMediators);
            if (featureMediators) {
                // console.log('%c featureMediators: %O', 'color: hotpink;', featureMediators);
                features[idx].Mediators = featureMediators;
                if (featureMediators.length > 0) {
                    if (!selFtre && selectedFeatureId === ftre.UdicciRecordId) {
                        selFtre = ftre;
                    }
                }
            }
        });
    }

    if (!selFtre) {
        return (
            <Stack sx={{ color: 'grey.500', margin: '8px' }} spacing={1}>
                <LinearProgress color="success" variant="determinate" value={100} />
                <LinearProgress color="inherit" />
                <LinearProgress color="inherit" />
            </Stack>            
        );
    }
    if (!mediator) {
        return (
            <Stack sx={{ color: 'grey.500', margin: '8px' }} spacing={1}>
                <LinearProgress color="success" variant="determinate" value={100} />
                <LinearProgress color="success" variant="determinate" value={100} />
                <LinearProgress color="inherit" />
            </Stack>            
        );
    }

    let listData: any = null;
    if ((data && mediator)) {
        // console.log('%c UdicciSingleRecord data: %O', 'color: maroon;', data);
        let mediatorContext = data.find((x: any) => x.mediator === mediator.name );
        // console.log('%c UdicciSingleRecord mediatorContext: %O', 'color: maroon;', mediatorContext);
        if (mediatorContext && mediatorContext.records) listData = mediatorContext.records;
    }
    // console.log('%c UdicciSingleRecord listData: %O', 'color: maroon;', listData);

    let socialSolutionId: number = (selSS.solution.recordId ? selSS.solution.recordId : 0);
    let mediatorId: number = (mediator && mediator.id ? mediator.id : 0);
    let contextValue: UdicciListContextStateType = {
        uid: 'udicci.single.record.' + udicciHelpers.generateUID(),
        data: listData,
        socialSolution: {
            id: selSS.solution.recordId,
            name: selSS.solution.title,
            more: selSS
        },
        feature: {
            id: selFtre.UdicciRecordId,
            name: selFtre.Name,
            more: selFtre
        },
        mediator: {
            id: mediator.id,
            name: mediator.name,
            label: (mediator && mediator.label ? mediator.label : mediator.name)
        },
        settings: {
            layout: layout, 
            contextRecord: contextRecord, 
        },
        record: null,
        breadcrumbs: null,
        onLoadBreadcrumb: null,
        onCloseBreadcrumb: null,
        onCloseAllBreadcrumbs: null,
        onRefresh: null,
        onAddRecord: null,
        onEditRecord: null,
        onEditContextRecord: null,
        onDeleteRecord: null,
        onEngageRecord: null,
        lastFetchResultDate: null
    };
    // console.log('%c contextValue: %O', 'color: purple; font-weight: bold;', contextValue);

    let contextProviderProps: any = {
        value: contextValue,
        key: 'udicci.record.context.' + socialSolutionId.toString() + '.' + mediatorId.toString(),
        socialSolution: selSS,
        feature: selFtre,
    };
    // console.log('%c contextProviderProps: %O', 'color: purple; font-weight: bold;', contextProviderProps);
    // console.log('%c listData: %O', 'color: purple; font-weight: bold;', listData);

    let firstRecord: any = null;
    if (listData && listData.length > 0) {
        firstRecord = listData[0];
    }
    // console.log('%c firstRecord: %O', 'color: hotpink;', firstRecord);

    // let mediatorData: any = null;
    // if (!firstRecord) {
    //     // let mediatorContext = data.find((x: any) => x.mediator === mediator.name );
    //     // console.log('%c SocialSolutions mediatorContext: %O', 'color: maroon;', mediatorContext);
    //     mediatorData = udicciHelpers.preloadMediatorData(mediator.name, socialSolutionId);
    // }
    // // console.log('%c mediatorData: %O', 'color: red;', mediatorData);

    if (firstRecord) {
        let contextViewProps: any = {
            records: listData,
            record: firstRecord,
            settings: {
                layout: layout, 
            },
            showForm: false,
            doNotPreloadData: false,
        };
        // console.log('%c contextViewProps: %O', 'color: purple; font-weight: bold;', contextViewProps);

        return (
            <UdicciListContextProvider {...contextProviderProps}>
                <RecordDisplay {...contextViewProps} />
            </UdicciListContextProvider>
        );
    } else {
        return (<Box>No Record to Show - #UdicciSingleRecord.</Box>);
    }
}
