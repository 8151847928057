import React, { useState, useContext } from 'react';

import { useTheme } from '@mui/material/styles';

// import sha2_256 from 'simple-js-sha2-256';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';

import {
    ArrowRight as ToggleDetailsOn,
    ArrowLeft as ToggleDetailsOff
} from '@mui/icons-material';

import { udicciStyles } from 'src/theme/shared-styles';

import { UdicciContext } from 'src/context/udicci-context';

import FieldDisplay from 'src/components/field-display';


/**
 * NOTES:
 *      This applies to here and to Broadcast MVT (BroadcastMVTUI.tsx)
 *      - these are what is left to be developed for our first release
 *          - Select Hierarchy of date to include in transaction
 *              - Select fields of hierarchical Data to include
 *          - Key Signature Definition
 *              - Select Fields used to create key to open record
 *                  - easy as footprint, or more complex using selected fields
 *              - Choose Role(s) for including in keys.  Could result in 1 require method
 *                  for each Role or a new Blockchain Transaction for each role
 *              - Choose Fields from Mediation Agreement(s) to include in keys.
 *                  - Could result in 1 require method for eeach Role or new Blockchain Transaction
 */

export const MintMVnfTUI: React.FC<any> = ({ record, socialSolution, engagementAction, onClose }) => {
    // console.log('%c MintMVnfTUI record: %O', 'color: blue;', record);
    // console.log('%c MintMVnfTUI socialSolution: %O', 'color: blue;', socialSolution);
    // console.log('%c MintMVnfTUI engagementAction: %O', 'color: blue;', engagementAction);

    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    let defaultTransaction: any = {
        transaction: {
            type: 'MVnfT',
            footprint: '',
            value: 1
        },
        record: {
            udicciMediator: '',
            recordId: 0,
            footprint: '',
            data: {},
        },
        socialSolution: {
            name: '',
            recordId: 0,
        },
        portal: {
            displayName: '',
            profileUrl: '',
            socialIcon: '',
        },
    };
    const [mvtTransaction, setMVnfTTransaction] = useState<any>(defaultTransaction);
    const [showTransactionJson, setShowTransactionJson] = useState<boolean>(false);
    const [forcedUpdateState, forceUpdate] = useState<boolean>(false);

    const udicciContext = useContext(UdicciContext);
    // console.log('%c MintMVnfTUI udicciContext: %O', 'color: red;', udicciContext);

    let recData: any = (record.data ? record.data : {});
    // console.log('%c recData: %O', 'color: purple;', recData);

    const changeMVnfTValueDirect = (evt: any) => {
        // console.log('%c changeMVnfTValueDirect evt: %O', 'color: red;', evt);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        let mvnft: any = mvtTransaction;
        let trans: any = (mvnft && mvnft.transaction ? mvnft.transaction : {});
        trans.value = newValue;
        mvnft.transaction = trans;
        setMVnfTTransaction(mvnft);
        forceUpdate(!forcedUpdateState);
    }

    const handleChangeMVnfTValue = (event: any, newValue: number | number[]) => {
        let mvnft: any = mvtTransaction;
        let trans: any = (mvnft && mvnft.transaction ? mvnft.transaction : {});
        let transValue: number = (trans && trans.value ? trans.value : 1);
        // console.log('%c MintMVnfTUI transValue: %O', 'color: blue;', transValue);
        if (typeof newValue === 'number') {
            transValue = newValue;
        } else if (Array.isArray(newValue) && newValue.length > 0) {
            transValue = newValue[0];
        }
        trans.value = transValue;
        mvnft.transaction = trans;
        setMVnfTTransaction(mvnft);
        forceUpdate(!forcedUpdateState);
    };

    const cancelAction = (evt: any) => {
        // console.log('%c cancelAction actionIsProcessing: %O', 'color: red;', actionIsProcessing);
        if (onClose) onClose();
    };

    const toggleTransactionJson = (evt: any) => {
        // console.log('%c cancelAction actionIsProcessing: %O', 'color: red;', actionIsProcessing);
        setShowTransactionJson(!showTransactionJson);
    };

    const fieldToggled = (field: any) => {
        // console.log('%c fieldToggled field: %O', 'color: red;', field);
        if (!record) return false;
        if (!field) return false;

        // console.log('%c fieldToggled mvtTransaction: %O', 'color: red;', mvtTransaction);
        let mvt: any = mvtTransaction;
        if (!mvt) mvt = defaultTransaction;
        // console.log('%c fieldToggled mvt: %O', 'color: red;', mvt);

        let mvtRecord: any = (mvt.record ? mvt.record : {});
        // console.log('%c fieldToggled mvtRecord: %O', 'color: red;', mvtRecord);
        let mvtRecordData: any = (mvtRecord && mvtRecord.data ? mvtRecord.data : {});
        // console.log('%c fieldToggled mvtRecordData: %O', 'color: red;', mvtRecordData);

        let recFootPrint = (recData && recData.FootPrint ? recData.FootPrint : '');
        // console.log('%c MintMVnfTUI recFootPrint: %O', 'color: red;', recFootPrint);
    
        if (!mvt.transaction.footprint && recFootPrint) 
            mvt.transaction.footprint = recFootPrint;
        if (!mvt.record.footprint && recFootPrint)
            mvt.record.footprint = recFootPrint;
        if (!mvt.record.udicciMediator && record.udicciMediator) 
            mvt.record.udicciMediator = record.udicciMediator;
        if (!mvt.record.recordId && record.recordId) 
            mvt.record.recordId = record.recordId;

        if (mvtRecordData[field.JsonFieldName] !== undefined)
            delete mvtRecordData[field.JsonFieldName];
        else {
            if (recData[field.JsonFieldName] !== undefined)
                mvtRecordData[field.JsonFieldName] = recData[field.JsonFieldName];
            else
                mvtRecordData[field.JsonFieldName] = null;
        }

        mvtRecord.data = mvtRecordData;
        mvt.record = mvtRecord;
        setMVnfTTransaction(mvt);
        forceUpdate(!forcedUpdateState);
    };

    let { udicci, data } = udicciContext.state;
    // console.log('%c MintMVnfTUI data: %O', 'color: maroon;', data);
    // console.log('%c MintMVnfTUI udicci: %O', 'color: maroon;', udicci);

    let BSV_BASE_COST_PER_BYTE = (udicci && udicci.BSV_BASE_COST_PER_BYTE ? udicci.BSV_BASE_COST_PER_BYTE : 1);
    // console.log('%c MintMVnfTUI BSV_BASE_COST_PER_BYTE: %O', 'color: maroon;', BSV_BASE_COST_PER_BYTE);
    let BSV_PER_100_BYTES = (udicci && udicci.BSV_PER_100_BYTES ? udicci.BSV_PER_100_BYTES : 100000);
    // console.log('%c BroadcastMVTUI BSV_PER_100_BYTES: %O', 'color: maroon;', BSV_PER_100_BYTES);

    // console.log('%c MintMVnfTUI targetRecord: %O', 'color: maroon;', targetRecord);
    // console.log('%c MintMVnfTUI targetStructure: %O', 'color: maroon;', targetStructure);

    // let listSettings = (plugInData && plugInData.listSettings ? plugInData.listSettings : null);
    // let feature = (listSettings && listSettings.feature ? listSettings.feature : null);
    // let mediator = (listSettings && listSettings.mediator ? listSettings.mediator : null);
    // console.log('%c MintMVnfTUI mediator: %O', 'color: maroon;', mediator);

    let mediatorName = '';
    if (record.udicciMediator) mediatorName = record.udicciMediator;
    // if (!mediatorName && mediator && mediator.name) mediatorName = mediator.name;
    // console.log('%c MintMVnfTUI mediatorName: %O', 'color: maroon;', mediatorName);

    let mediatorContext = null;
    if (mediatorName) mediatorContext = data.find((x: any) => x.mediator === mediatorName );
    // console.log('%c mediatorContext: %O', 'color: maroon;', mediatorContext);

    let structure = null;
    if (mediatorContext) {
        structure = (mediatorContext.structure ? mediatorContext.structure : null);
    }
    // console.log('%c MintMVnfTUI structure: %O', 'color: maroon;', structure);

    let fields = null;
    let virtualFields = null;
    // let linkedMediators = null;
    if (structure) {
        fields = (structure.UdicciMediatorFields ? structure.UdicciMediatorFields : null);
        virtualFields = (structure.VirtualMediatorFields ? structure.VirtualMediatorFields : null);
        // linkedMediators = (structure.LinkedUdicciMediators ? structure.LinkedUdicciMediators : null);
    }
    // console.log('%c fields: %O', 'color: maroon;', fields);
    // console.log('%c virtualFields: %O', 'color: maroon;', virtualFields);
    // console.log('%c linkedMediators: %O', 'color: maroon;', linkedMediators);

    // let privKey = new bsv.PrivKey().fromRandom();
    // // console.log('%c MintMVnfTUI privKey: %O', 'color: red;', privKey);
    // let pubKey = new bsv.PubKey().fromPrivKey(privKey);
    // // console.log('%c MintMVnfTUI pubKey: %O', 'color: red;', pubKey);
    // let keyPair = new bsv.KeyPair().fromPrivKey(privKey);
    // // console.log('%c MintMVnfTUI keyPair: %O', 'color: red;', keyPair);

    let coreRecord: any = {};
    coreRecord.UdicciRecordId = recData.UdicciRecordId;
    if (fields && fields.length > 0) {
        fields.forEach(function(field: any) {
            // console.log('%c field: %O', 'color: purple;', field);
            coreRecord[field.JsonFieldName] = recData[field.JsonFieldName];
        });
    }
    // console.log('%c MintMVnfTUI coreRecord: %O', 'color: red;', coreRecord);

    if (virtualFields && virtualFields.length > 0) {
        virtualFields.forEach(function(field: any) {
            // console.log('%c field: %O', 'color: purple;', field);
            coreRecord[field.JsonFieldName] = recData[field.JsonFieldName];
        });
    }
    // console.log('%c MintMVnfTUI coreRecord: %O', 'color: red;', coreRecord);

    // let shaData: string = sha2_256(record.recordId.toString());
    // shaData += '.' + sha2_256(coreRecord.CreatedInSolutionId.toString());
    // shaData += '.' + sha2_256(coreRecord.CreatedInUdicciProfileId.toString());
    // shaData += '.' + sha2_256(coreRecord.CreatedByUserId.toString());
    // console.log('%c MintMVnfTUI shaData: %O', 'color: red;', shaData);

    let recFootPrint = (record.data && record.data.FootPrint ? record.data.FootPrint : '');
    // console.log('%c MintMVnfTUI recFootPrint: %O', 'color: red;', recFootPrint);

    if (!mvtTransaction.transaction.footprint && recFootPrint) 
        mvtTransaction.transaction.footprint = recFootPrint;

    if (!mvtTransaction.portal.displayName && record.data.CreatedInDisplayName) 
        mvtTransaction.portal.displayName = record.data.CreatedInDisplayName;
    if (!mvtTransaction.portal.profileUrl && record.data.CreatedInProfileUrl) 
        mvtTransaction.portal.profileUrl = record.data.CreatedInProfileUrl;
    if (!mvtTransaction.portal.socialIcon && record.data.CreatedInSocialIcon) 
        mvtTransaction.portal.socialIcon = record.data.CreatedInSocialIcon;

    if (!mvtTransaction.socialSolution.recordId && socialSolution && socialSolution.recordId) 
        mvtTransaction.socialSolution.recordId = socialSolution.recordId;
    if (!mvtTransaction.socialSolution.name && socialSolution && socialSolution.data.Name) 
        mvtTransaction.socialSolution.name = socialSolution.data.Name;

    let strTransactionData = JSON.stringify(mvtTransaction);
    // console.log('%c MintMVnfTUI strTransactionData: %O', 'color: red;', strTransactionData);

    // let footPrintforUdicciRecord = 'udc-0.0.1-' + shaData;
    // console.log('%c footPrintforUdicciRecord: %O', 'color: red;', footPrintforUdicciRecord);        

    var base64Hash = Buffer.from(strTransactionData).toString("base64");
    // console.log('%c MintMVnfTUI base64Hash: %O', 'color: red;', base64Hash);

    var hash64Unwind = Buffer.from(base64Hash, "base64");
    // console.log('%c MintMVnfTUI hash64Unwind: %O', 'color: red;', hash64Unwind.toString("utf8"));

    // let hash58Unwind = bsv.Hash.sha256.fromString(hash).toBuffer();
    // console.log('%c MintMVnfTUI hash58Unwind: %O', 'color: red;', hash58Unwind);

    // Signing the hash of the message
    // let sig = bsv.Ecdsa.sign(hash, keyPair);
    // console.log('%c MintMVnfTUI signature length: %O', 'color: red;', sig.toString().length);
    // console.log('%c MintMVnfTUI signature: %O', 'color: red;', sig);
    // console.log('%c MintMVnfTUI signature: %O', 'color: red;', sig.toString());

    // let verified = bsv.Ecdsa.verify(hash, sig, pubKey);
    // console.log('%c MintMVnfTUI verified: %O', 'color: red;', verified);

    let dataHashSizeInBytes: number = base64Hash.length;
    // let footprintHashSizeInBytes: number = footPrintforUdicciRecord.length;
    // let totalSizeInBytes: number = dataHashSizeInBytes + footprintHashSizeInBytes;
    let udcCostToWriteTransaction: number = ((dataHashSizeInBytes / 100) * BSV_PER_100_BYTES);
    // console.log('%c MintMVnfTUI udcCostToWriteTransaction: %O', 'color: red;', udcCostToWriteTransaction);
    udcCostToWriteTransaction = Math.round(udcCostToWriteTransaction * 100) / 100;
    let transactionCost: number = Math.round(udcCostToWriteTransaction);
    // console.log('%c MintMVnfTUI transactionCost: %O', 'color: red;', transactionCost);
    let networkFees: number = (Math.round(((transactionCost - udcCostToWriteTransaction) + BSV_BASE_COST_PER_BYTE) * 100) / 100) + BSV_BASE_COST_PER_BYTE;
    // console.log('%c MintMVnfTUI networkFees: %O', 'color: red;', networkFees);
    networkFees = Math.round(networkFees * 100) / 100;
    transactionCost = udcCostToWriteTransaction + networkFees;

    let hasSelectedFields: boolean = false;
    let fieldDisplayElements: any[] = [];
    if (fields && fields.length > 0) {
        fields.forEach(function(field: any) {
            // console.log('%c field: %O', 'color: blue;', field);
            if (field.UdicciMediatorId <= 0) return true;
            if (field.DataType === 'Json') return true;
            let fieldKey = 'record.' + record?.recordId + '.field.' + field.UdicciMediatorFieldId;

            let fldCheck: any = null;
            if (mvtTransaction && mvtTransaction.record && mvtTransaction.record.data) {
                if (mvtTransaction.record.data[field.JsonFieldName])
                    fldCheck = mvtTransaction.record.data[field.JsonFieldName];
            }

            let fieldDisplayProps: any = {
                readonly: true,
                hideEmptyFields: true,
                toggleFields: true,
                fieldToggled: (fldCheck ? true : false),
                onToggleField: (evt: any) => fieldToggled(field),
                record: record,
                key: fieldKey,
                field: field
            };
            fieldDisplayElements.push( <FieldDisplay {...fieldDisplayProps} /> );

            if (fldCheck) hasSelectedFields = true;
        });
    }

    let transactionDisplayHeaderElement: any = (
        <Box sx={udicciClasses.generalArea}>
            <Typography component="div" variant="subtitle1" color="primary" gutterBottom={true}>
                Mint MVnfT
            </Typography>
            <Typography component="div" variant="caption" color="textPrimary" gutterBottom={false}>
                Click on the content that you would like to include in your Mutual Value non-fungible Transaction.
            </Typography>
        </Box>
    );

    // let base64HashElement: any = (
    //     <Fragment>
    //         <Typography component="div" variant="caption" style={{ overflowWrap: 'anywhere' }} gutterBottom={true}>
    //             {base64Hash}
    //         </Typography>
    //     </Fragment>
    // );
    // let footPrintforUdicciRecordElement: any = (
    //     <Fragment>
    //         <Typography sx={footprintStyle} component="div" variant="body2" color="primary" style={{ overflowWrap: 'anywhere' }} gutterBottom={true}>
    //             {footPrintforUdicciRecord}
    //         </Typography>
    //     </Fragment>
    // );

    let transactionJsonDataElement: any = null;
    if (showTransactionJson) {
        transactionJsonDataElement = (
            <Typography component="div" variant="body2" color="textPrimary" style={{ overflowWrap: 'anywhere' }} gutterBottom={true}>
                {hash64Unwind.toString("utf8")}
            </Typography>
        );
    }

    let toggleTransactionJsonButtonElement: any = (
        <IconButton size="small" aria-label="Show/Hide Transaction Json" onClick={toggleTransactionJson}>
            {(showTransactionJson ? (<ToggleDetailsOff />) : (<ToggleDetailsOn />))}
        </IconButton>
    );

    // classes={{
    //     root: udicciClasses.setValueTextArea,
    // }}
    // InputProps={
    //     { classes: {
    //         input: udicciClasses.setValueTextAreaInput
    //     }}
    // }
    let mvnftUDCValueElement = (
        <TextField type="number"
                   value={mvtTransaction.transaction.value}
                   margin="none"
                   size="small"
                   variant="standard"
                   onChange={changeMVnfTValueDirect}
        />
    );

    const marks = [
        {
          value: 1,
          label: '1',
        },
        {
          value: 100,
          label: '100',
        },
        {
            value: 200,
            label: '200',
        },
        {
            value: 300,
            label: '300',
        },
        {
            value: 400,
            label: '400',
        },
        {
            value: 500,
            label: '500',
        },
    ];

    let transactionDisplayElement: any = (
        <Box sx={udicciClasses.generalArea}>
            <Box sx={(hasSelectedFields ? udicciClasses.highlightedArea : udicciClasses.unhighlightedArea)}>
                <Box>  {/*** Field Selection ***/}
                    {fieldDisplayElements}
                </Box>
            </Box>

            <Box sx={udicciClasses.highlightedArea}>  {/*** MVnfT Value ***/}
                <Box sx={udicciClasses.setValueArea}>
                    <Box>
                        <Typography id="mvnft-udc-value-slider" variant="body2" component="span" color="primary">
                            MVnfT Value:
                        </Typography>
                        {mvnftUDCValueElement}
                        <Typography variant="caption" component="span" color="textPrimary">
                            UDC
                        </Typography>
                    </Box>
                    <Slider
                        value={mvtTransaction.transaction.value}
                        marks={marks}
                        min={1}
                        step={1}
                        max={500}
                        onChange={handleChangeMVnfTValue}
                        valueLabelDisplay="auto"
                        aria-labelledby="mvnft-udc-value-slider"
                    />
                </Box>
            </Box>

            <Box sx={udicciClasses.generalArea}>  {/*** Transaction Data ***/}
                <Box>
                    <Typography component="span" variant="caption" color="textSecondary">
                        Transaction Data
                    </Typography>
                    {toggleTransactionJsonButtonElement}
                </Box>
                {transactionJsonDataElement}
            </Box>

            <Box sx={udicciClasses.generalArea}>  {/*** Transaction Data Hash - Base64 ***/}
                <Typography component="div" variant="caption" color="textSecondary">
                    Transaction Data Hash (Base64) Size is 
                </Typography>
                <Typography component="div" variant="body2" color="textPrimary" gutterBottom={true}>
                    {dataHashSizeInBytes} bytes
                </Typography>
            </Box>

            <Box sx={udicciClasses.generalArea}>  {/*** Cost to Mint this Transaction ***/}
                <Typography component="div" variant="caption" color="textSecondary">
                    Your Cost to Mint this Transaction is 
                </Typography>
                <Typography component="div" variant="body2" color="textPrimary">
                    {transactionCost} UDC
                </Typography>
                <Typography component="div" variant="caption" color="textPrimary">
                    {udcCostToWriteTransaction} Broadcast Fees
                </Typography>
                <Typography component="div" variant="caption" color="textPrimary" gutterBottom={true}>
                    {networkFees} Udicci Network Fees
                </Typography>
            </Box>
        </Box>
    );
    /* 
        <Typography component="div" variant="caption">
            Signature Hash:
        </Typography>
        <Typography component="div" variant="body2" color="primary" style={{ overflowWrap: 'anywhere' }} gutterBottom={true}>
            {sig.toString()}
        </Typography> }

        {<Typography component="div" variant="caption">
            Signature Size:
        </Typography>
        <Typography variant="body2" color="primary">
            {sig.toString().length} bytes
        </Typography> 
    */

    let mintDisabled: boolean = true;
    return (
        <Box>
            {transactionDisplayHeaderElement}
            {transactionDisplayElement}
            <Box sx={udicciClasses.buttonContainer}>
                <Button onClick={cancelAction} size="small">Cancel</Button>
                <Button disabled={mintDisabled} onClick={cancelAction} color="secondary" size="small">Mint MVnfT Now</Button>
            </Box>
        </Box>
    );
};
