import { useState, useContext, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { UdicciContext, loadProfileTypes, sendPreparedRequest } from 'src/context/udicci-context';
import { UdicciRecord } from 'src/classes/udicci-record';
import { PotentialMatchRequest } from 'src/interfaces/udicci-request-interfaces';

import { ExecuteActionButton } from './ExecuteActionButton';
import { CreateProfileFormContent } from './CreateProfileFormContent';
import { CreateProfileFormHeader } from './CreateProfileFormHeader';

// import { useStyles } from 'src/components/engagement-actions/create-profile';

const CreateProfileUI: React.FC<any> = ({ record, engagementAction, onClose }) => {
    // console.log('%c CreateProfileUI engagementAction: %O', 'color: purple;', engagementAction);
    const [showPotentialMatches, togglePotentialMatches] = useState(false);
    const [searchForMatchesComplete, setSearchForMatchesComplete] = useState(false);
    const [matchSearchValues, setMatchSearchValues] = useState<string[]>([]);
    const [engagementData, setEngagementData] = useState<any | null>(null);
    const [matchingProfiles, setMatchingProfiles] = useState<any | null>(null);
    // console.log('%c CreateProfileUI engagementData: %O', 'color: maroon;', engagementData);
    // console.log('%c CreateProfileUI searchForMatchesComplete: %O', 'color: maroon;', searchForMatchesComplete);
    // console.log('%c CreateProfileUI matchingProfiles: %O', 'color: maroon;', matchingProfiles);

    const udicciContext = useContext(UdicciContext);
    let { udicci, data } = udicciContext.state;
    // console.log('%c CreateProfileUI udicci: %O', 'color: maroon;', udicci);
    // console.log('%c CreateProfileUI data: %O', 'color: maroon;', data);
    let { currentUser } = udicci;

    useEffect(() => {
        // console.log('%c useEffect udicci.profileTypes: %O', 'color: maroon;', udicci.profileTypes);
        // console.log('%c useEffect engagementData: %O', 'color: maroon;', engagementData);
        // console.log('%c useEffect searchForMatchesComplete: %O', 'color: maroon;', searchForMatchesComplete);
        let hasProfileTypes: boolean = (udicci && udicci.profileTypes && udicci.profileTypes.length > 0 ? true : false);
        if (hasProfileTypes === false) {
            loadProfileTypes();
        }
        if (hasProfileTypes && !engagementData) {
            makeSureWeHaveStructure();
        }
        if (!searchForMatchesComplete && hasProfileTypes && engagementData && createProfile) {
            // console.log('%c useEffect matchingProfiles: %O', 'color: maroon;', matchingProfiles);
            if (!matchingProfiles) checkForMatches();
        }
    });

    let createProfile: any = {};
    if (engagementData && engagementData.createProfile) {
        createProfile = engagementData.createProfile;
    }
    // console.log('%c CreateProfileUI createProfile: %O', 'color: blue;', createProfile);

    const checkForMatchesSuccess = (response: any) => {
        // console.log('%c checkForMatchesSuccess response: %O', 'color: red;', response);
        let { matchingProfiles } = response;
        // console.log('%c checkForMatchesSuccess matchingProfiles: %O', 'color: red;', matchingProfiles);
        // console.log('%c checkForMatchesSuccess matchingUserIds: %O', 'color: red;', matchingUserIds);
        setSearchForMatchesComplete(true);
        setMatchingProfiles( (matchingProfiles ? matchingProfiles : null) );
    };

    const checkForMatchesFailure = (response: any) => {
        // console.log('%c checkForMatchesFailure response: %O', 'color: red;', response);
        let updatedEngagementData: any = {};
        if (engagementData)
            Object.assign(updatedEngagementData, engagementData);

        updatedEngagementData.matchingProfiles = [];
        updatedEngagementData.matchingUserIds = [];
        // console.log('%c checkForMatchesFailure updatedEngagementData: %O', 'color: red;', updatedEngagementData);
        setEngagementData(updatedEngagementData);
    };

    // const updateUdicciContextHandler = useCallback( (response: any) => {
    //     // console.log('%c CreateProfileUI updateUdicciContextHandler response: %O', 'color: red;', response);
    //     if (response && response.UdicciCommand === 'Check For Potential Profile Matches') {
    //         // setSearchForMatchesComplete(true);
    //         // console.log('%c checkForMatchesSuccess engagementData: %O', 'color: maroon;', engagementData);
    //         // let { matchingProfiles, matchingUserIds } = response;
    //         // console.log('%c checkForMatchesSuccess matchingProfiles: %O', 'color: red;', matchingProfiles);
    //         // console.log('%c checkForMatchesSuccess matchingUserIds: %O', 'color: red;', matchingUserIds);
    //         if (response.success) {
    //             checkForMatchesSuccess(response);
    //         } else {
    //             checkForMatchesFailure(response);
    //         }
    //     }
    // }, []);

    // subscribeToUdicci('udicci.create.profile.form.header', updateUdicciContextHandler);

    const checkForMatches = () => {
        if (!engagementData || searchForMatchesComplete) return;

        let engagementRecord = record;
        // console.log('%c engagementRecord: %O', 'color: red;', engagementRecord);
        if (!engagementRecord)
            return;

        let isEngagementRecord = (record && record.recordId === engagementRecord.recordId ? true : false);
        // console.log('%c isEngagementRecord: %O', 'color: red;', isEngagementRecord);
        // console.log('%c matchSearchValues: %O', 'color: blue;', matchSearchValues);
        if (matchSearchValues.length > 0 && isEngagementRecord) {
            // kick off a check to find any possible matches
            setSearchForMatchesComplete(false);
            let requestJson: PotentialMatchRequest = {
                UdicciCommand: 'Check For Potential Profile Matches',
                UdicciMediatorName: 'Udicci Profiles',
                SelectedUdicciProfileId: udicci.ulyssesDConstantineProfileId,
                SearchValues: matchSearchValues,
                SocialSolutionId: udicci.socialSolutionUdicciIT,
                UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0)
            };
            // console.log('%c requestJson: %O', 'color: #ea5508;', requestJson);
            let okToContinue = true;
            if (okToContinue) {
                sendPreparedRequest(requestJson, {
                    onSuccess: checkForMatchesSuccess,
                    onError: checkForMatchesFailure
                });
            }
        }
    };

    const fillTheProfileRecord = (newProfileRecord: UdicciRecord) => {
        // console.log('%c fillTheProfileRecord newProfileRecord: %O', 'color: red;', newProfileRecord);
        let engagementRecord = record;
        // console.log('%c engagementRecord: %O', 'color: red;', engagementRecord);
        if (!engagementRecord)
            return newProfileRecord;

        let searchValues: string[] = [];
        if (engagementRecord && (engagementRecord.udicciMediator === 'People')) {
            let fullName = engagementRecord.data.FirstName;
            if (engagementRecord.data.LastName) {
                if (fullName)
                    fullName += ' ';
                fullName += engagementRecord.data.LastName;
            }
            if (!fullName && engagementRecord.data.Name) {
                fullName = engagementRecord.data.Name;
            }
            // console.log('%c fullName: %O', 'color: blue;', fullName);
            let usableFullName = fullName.replace('.', ' ').replace(',', ' ');
            let nameParts = (usableFullName && usableFullName.indexOf(' ') >= 0 ? usableFullName.split(' ') : []);
            if (nameParts.length <= 0 && usableFullName)
                nameParts.push(usableFullName);
            // console.log('%c nameParts: %O', 'color: blue;', nameParts);
            if (nameParts.length > 0) {
                searchValues = searchValues.concat(nameParts);
            }

            let profileUrl = '/' + fullName.replace(' ', '').replace('.', '').replace(',', '').toLowerCase();
            // console.log('%c profileUrl: %O', 'color: blue;', profileUrl);
            searchValues.push(profileUrl);

            let headline = 'Udicci welcomes ' + fullName;

            newProfileRecord.title = fullName;
            newProfileRecord.description = headline;

            newProfileRecord.data.DisplayName = fullName;
            newProfileRecord.data.Name = fullName;
            newProfileRecord.data.Headline = headline;
            newProfileRecord.data.MissionStatement = 'To Engage For Good';
            newProfileRecord.data.ProfileUrl = profileUrl;
            // console.log('%c newProfileRecord: %O', 'color: blue;', newProfileRecord);
        }

        if (engagementRecord && engagementRecord.udicciMediator === 'Organizations') {
            let orgName = engagementRecord.data.Name;
            // console.log('%c orgName: %O', 'color: blue;', orgName);
            let usableOrgName = orgName.replace('.', ' ').replace(',', ' ');
            let nameParts = (usableOrgName && usableOrgName.indexOf(' ') >= 0 ? usableOrgName.split(' ') : []);
            if (nameParts.length <= 0 && usableOrgName)
                nameParts.push(usableOrgName);
            // console.log('%c nameParts: %O', 'color: blue;', nameParts);
            if (nameParts.length > 0) {
                searchValues = searchValues.concat(nameParts);
            }

            let profileUrl = '/' + usableOrgName.replace(' ', '').replace('.', '').replace(',', '').toLowerCase();
            // console.log('%c profileUrl: %O', 'color: blue;', profileUrl);
            searchValues.push(profileUrl);

            let headline = 'Udicci welcomes ' + orgName;

            newProfileRecord.title = orgName;
            newProfileRecord.description = headline;

            newProfileRecord.data.DisplayName = orgName;
            newProfileRecord.data.Name = orgName;
            newProfileRecord.data.Headline = headline;
            newProfileRecord.data.MissionStatement = 'To Engage For Good';
            newProfileRecord.data.ProfileUrl = profileUrl;
            // console.log('%c newProfileRecord: %O', 'color: blue;', newProfileRecord);
        }

        setMatchSearchValues(searchValues);

        // console.log('%c newProfileRecord: %O', 'color: blue;', newProfileRecord);
        return newProfileRecord;
    };

    const makeSureWeHaveStructure = () => {
        // console.log('%c makeSureWeHaveStructure', 'color: red;');
        if (!udicciContext)
            return null;
        // let { action, engagementData, onUpdateEngagementData } = this.state;
        // console.log('%c makeSureWeHaveStructure action: %O', 'color: maroon;', action);
        // console.log('%c makeSureWeHaveStructure engagementData: %O', 'color: maroon;', engagementData);
        let updatedEngagementData = engagementData;
        // let engagementDataUpdated = false;
        let profilesMediatorName = 'Udicci Profiles';
        let mediatorContext = data.find((x: any) => x.mediator === profilesMediatorName);
        // console.log('%c mediatorContext: %O', 'color: maroon;', mediatorContext);
        let mediatorStructure = (mediatorContext && mediatorContext.structure ? mediatorContext.structure : null);
        // console.log('%c mediatorStructure: %O', 'color: maroon;', mediatorStructure);
        let newProfileRecord = new UdicciRecord(profilesMediatorName, null, mediatorStructure);
        // console.log('%c newProfileRecord: %O', 'color: red;', newProfileRecord);
        if (newProfileRecord) {
            newProfileRecord = fillTheProfileRecord(newProfileRecord);
            // console.log('%c newProfileRecord: %O', 'color: red;', newProfileRecord);
            if (!updatedEngagementData)
                updatedEngagementData = {};
            let createProfileContext = (updatedEngagementData.createProfile ? updatedEngagementData.createProfile : {});
            createProfileContext.profileRecord = newProfileRecord;
            updatedEngagementData.createProfile = createProfileContext;

            let subFormProps: any = {};
            Object.assign(subFormProps, { engagementAction, onClose });
            updatedEngagementData.executeActionButton = (
                <ExecuteActionButton {...subFormProps} />
            );

            updatedEngagementData.secondaryActionButton = null;
        }

        // console.log('%c engagementDataUpdated: %O', 'color: red;', engagementDataUpdated);
        // console.log('%c updatedEngagementData: %O', 'color: red;', updatedEngagementData);
        setEngagementData(updatedEngagementData);
        // if (engagementDataUpdated && onUpdateEngagementData) onUpdateEngagementData(updatedEngagementData);
    };

    let formElements = null;
    if (engagementData) {
        let subFormProps: any = { engagementAction, onClose };
        subFormProps.showPotentialMatches = showPotentialMatches;
        subFormProps.record = record;
        subFormProps.engagementData = engagementData;
        subFormProps.matchingProfiles = matchingProfiles;
        subFormProps.onTogglePotentialMatches = togglePotentialMatches;
        subFormProps.onSetEngagementData = setEngagementData;

        let profileFormHeader = (<CreateProfileFormHeader {...subFormProps} />);

        let profileForm = null;
        if (!showPotentialMatches) {
            profileForm = (<CreateProfileFormContent {...subFormProps} />);
        }

        if (profileFormHeader || profileForm) {
            formElements = (
                <div>
                    {profileFormHeader}
                    {profileForm}
                </div>
            );
        }
    } else {
        formElements = ( <LinearProgress color="primary" /> );
    }

    return formElements;
};

export { CreateProfileUI, ExecuteActionButton };

