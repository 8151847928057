
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { forEach } from 'underscore';

import {
    Box, Typography, Table, TableHead, TableFooter, TableBody, TableRow, TableCell, Tooltip, MenuItem, Divider,
    Icon, IconButton, Button, Select, 
} from '@mui/material';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

const SelectUdicciSourceSort: React.FC<any> = (props) => {
    // const udicciContext = useContext(UdicciContext);
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    const udicciHelpers = useUdicciHelpers();

    let { mediatorName, settings, onSelectionChanged } = props;
    // console.log('%c settings: %O', 'color: purple;', settings);

    const [sortField, setSortField] = useState<any>(null);
    const [remove, setRemove] = useState<boolean>(false);
    const [reorderSortFields, setReorderSortFields] = useState<boolean>(false);
    const [uiState, flashUI] = useState<boolean>(false);
    // console.log('%c sortField: %O', 'color: purple;', sortField);
    // console.log('%c remove: %O', 'color: purple;', remove);

    let sortSettings = (settings && settings.sortSettings ? settings.sortSettings : null);
    // console.log('%c sortSettings: %O', 'color: purple;', sortSettings);

    const addNewSortField = (event: any) => {
        let newSortField: any = {
            displayName: '',
            jsonKey: '',
            sortOrder: 'ASC'
        };
        // console.log('%c addNewSortField newSortField: %O', 'color: purple;', newSortField);
        setSortField(newSortField);
        setRemove(false);
        flashUI(!uiState);
    };

    const editSortField = (field: any, event: any) => {
        // console.log('%c editSortField field: %O', 'color: purple;', field);
        setSortField(field);
        setRemove(false);
        flashUI(!uiState);
    };

    const cancelEditSortField = (event: any) => {
        setSortField(null);
        setRemove(false);
        flashUI(!uiState);
    };

    const removeSortField = (field: any, event: any) => {
        // console.log('%c removeSortField field: %O', 'color: purple;', field);
        setSortField(field);
        setRemove(true);
        flashUI(!uiState);
    };

    const cancelRemoveSortField = (event: any) => {
        // console.log('%c cancelRemoveSortField event: %O', 'color: purple;', event);
        setSortField(null);
        setRemove(false);
        flashUI(!uiState);
    };

    const moveSortField = (sortSettingIndex: number, direction: string, event: any) => {
        // console.log('%c moveSortField sortSettingIndex: %O', 'color: purple;', sortSettingIndex);
        // console.log('%c moveSortField direction: %O', 'color: purple;', direction);

        if (!settings) settings = {};

        if (sortSettings) {
            let sortSettingsIsArray: boolean = Array.isArray(sortSettings);
            // console.log('%c sortSettingsIsArray: %O', 'color: blue;', sortSettingsIsArray);
            if (!sortSettingsIsArray) {
                let newSortSettings: any[] = [];
                Object.entries(sortSettings).forEach(([key, stng]) => {
                    if (key) newSortSettings.push(stng);
                });
                // console.log('%c newSortSettings: %O', 'color: blue;', newSortSettings);
                sortSettings = newSortSettings;
                // console.log('%c sortSettings: %O', 'color: purple;', sortSettings);
            }
        }

        if (sortSettings && sortSettings.length > 0) {
            let checkForEmptyKeySetting: any = sortSettings.find((stng: any) => {
                return ((stng.jsonKey === '') ? true : false);
            });
            // console.log('%c checkForEmptyKeySetting: %O', 'color: red;', checkForEmptyKeySetting);
            if (checkForEmptyKeySetting) {
                let newSortSettings: any[] = [];
                forEach(sortSettings, (stng: any, idx: number) => {
                    if (stng.jsonKey) newSortSettings.push(stng);
                });
                // console.log('%c newSortSettings: %O', 'color: blue;', newSortSettings);
                sortSettings = newSortSettings;
                // console.log('%c sortSettings: %O', 'color: purple;', sortSettings);
            }
        }

        let moveToIndex: number = -1;
        if (sortSettingIndex < (sortSettings.length - 1) && direction.toLowerCase() === 'down') {
            moveToIndex = sortSettingIndex + 1;
        }
        if (sortSettingIndex > 0 && direction.toLowerCase() === 'up') {
            moveToIndex = sortSettingIndex - 1;
        }
        // console.log('%c moveToIndex: %O', 'color: blue;', moveToIndex);

        if (moveToIndex >= 0) {
            sortSettings = udicciHelpers.swapArrayElements(sortSettings, sortSettingIndex, moveToIndex);
            // console.log('%c sortSettings: %O', 'color: blue;', sortSettings);

            settings.sortSettings = sortSettings;

            // console.log('%c settings: %O', 'color: green;', settings);
            if (onSelectionChanged) onSelectionChanged(settings);
            setRemove(false);
        }
    };

    const confirmRemoveSortField = (field: any, event: any) => {
        if (!remove) return false;

        if (!settings) settings = {};

        if (sortSettings) {
            let sortSettingsIsArray: boolean = Array.isArray(sortSettings);
            // console.log('%c sortSettingsIsArray: %O', 'color: blue;', sortSettingsIsArray);
            if (!sortSettingsIsArray) {
                let newSortSettings: any[] = [];
                Object.entries(sortSettings).forEach(([key, stng]) => {
                    if (key) newSortSettings.push(stng);
                });
                // console.log('%c newSortSettings: %O', 'color: blue;', newSortSettings);
                sortSettings = newSortSettings;
                // console.log('%c sortSettings: %O', 'color: purple;', sortSettings);
            }
        }

        if (sortSettings && sortSettings.length > 0) {
            let checkForEmptyKeySetting: any = sortSettings.find((stng: any) => {
                return ((stng.jsonKey === '') ? true : false);
            });
            // console.log('%c checkForEmptyKeySetting: %O', 'color: red;', checkForEmptyKeySetting);
            if (checkForEmptyKeySetting) {
                let newSortSettings: any[] = [];
                forEach(sortSettings, (stng: any, idx: number) => {
                    if (stng.jsonKey) newSortSettings.push(stng);
                });
                // console.log('%c newSortSettings: %O', 'color: blue;', newSortSettings);
                sortSettings = newSortSettings;
                // console.log('%c sortSettings: %O', 'color: purple;', sortSettings);
            }

            let checkForRemoveKeySetting: any = sortSettings.find((stng: any) => {
                return ((stng.jsonKey && stng.jsonKey === sortField.jsonKey) ? true : false);
            });
            // console.log('%c checkForRemoveKeySetting: %O', 'color: red;', checkForRemoveKeySetting);
            if (checkForRemoveKeySetting) {
                let newSortSettings: any[] = [];
                forEach(sortSettings, (stng: any, idx: number) => {
                    if (stng.jsonKey && stng.jsonKey !== sortField.jsonKey) newSortSettings.push(stng);
                });
                // console.log('%c newSortSettings: %O', 'color: blue;', newSortSettings);
                sortSettings = newSortSettings;
                // console.log('%c sortSettings: %O', 'color: purple;', sortSettings);
            }
        }

        settings.sortSettings = sortSettings;

        // console.log('%c settings: %O', 'color: green;', settings);
        if (onSelectionChanged) onSelectionChanged(settings);

        setSortField(null);
        setRemove(false);
        flashUI(!uiState);
    };

    const toggleReorderSortFields = (event: any) => {
        setReorderSortFields(!reorderSortFields);
    };

    const updateSortField = (fieldName: string, event: any) => {
        // console.log('%c updateSortField fieldName: %O', 'color: purple;', fieldName);
        // console.log('%c event: %O', 'color: purple;', event);

        let trgt = event.target;
        // console.log('%c trgt: %O', 'color: purple;', trgt);
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c newValue: %O', 'color: purple;', newValue);

        sortField[fieldName] = newValue;

        if (fieldName === 'jsonKey') {
            // get the display name
            let structure = udicciHelpers.getMediatorStructure(mediatorName);
            // console.log('%c structure: %O', 'color: red;', structure);
            let fields = (structure && structure.UdicciMediatorFields ? structure.UdicciMediatorFields : []);
            // console.log('%c fields: %O', 'color: red;', fields);
            let virtualFields = (structure && structure.VirtualFields ? structure.VirtualFields : null);
            // console.log('%c virtualFields: %O', 'color: red;', virtualFields);

            let currentField: any = fields.find((fld: any) => {
                return ((fld.JsonFieldName === newValue) ? true : false);
            });
            // console.log('%c currentField: %O', 'color: red;', currentField);
            if (!currentField && virtualFields && virtualFields.length > 0) {
                currentField = virtualFields.find((fld: any) => {
                    return ((fld.JsonFieldName === newValue) ? true : false);
                });
                // console.log('%c currentField: %O', 'color: red;', currentField);
            }

            let displayName = sortField.displayName;
            if (currentField && (currentField.DisplayName || currentField.Name)) {
                if (currentField.DisplayName) {
                    displayName = currentField.DisplayName;
                } else if (currentField.DisplayName) {
                    displayName = currentField.DisplayName;
                }
            }
            // console.log('%c displayName: %O', 'color: red;', displayName);
            sortField.displayName = displayName;
        }

        setSortField(sortField);
        flashUI(!uiState);
    };

    const applySortFieldChanges = (event: any) => {
        // console.log('%c event: %O', 'color: purple;', event);

        if (!settings) settings = {};

        // console.log('%c sortSettings: %O', 'color: purple;', sortSettings);
        if (sortSettings) {
            let sortSettingsIsArray: boolean = Array.isArray(sortSettings);
            // console.log('%c sortSettingsIsArray: %O', 'color: blue;', sortSettingsIsArray);
            if (!sortSettingsIsArray) {
                let newSortSettings: any[] = [];
                Object.entries(sortSettings).forEach(([key, stng]) => {
                    if (key) newSortSettings.push(stng);
                });
                // console.log('%c newSortSettings: %O', 'color: blue;', newSortSettings);
                sortSettings = newSortSettings;
                // console.log('%c sortSettings: %O', 'color: purple;', sortSettings);
            }
        }

        if (sortSettings && sortSettings.length > 0) {
            let foundSettingsItem: boolean = false;
            forEach(sortSettings, (stng: any, idx: number) => {
                if (stng.jsonKey && stng.jsonKey === sortField.jsonKey) {
                    sortSettings[idx] = sortField;
                    foundSettingsItem = true;
                }
            });
            // console.log('%c sortSettings: %O', 'color: purple;', sortSettings);
            // console.log('%c foundSettingsItem: %O', 'color: purple;', foundSettingsItem);
            if (!foundSettingsItem) sortSettings.push(sortField);
            // console.log('%c sortSettings: %O', 'color: purple;', sortSettings);

            let checkForEmptyKeySetting: any = sortSettings.find((stng: any) => {
                return ((stng.jsonKey === '') ? true : false);
            });
            // console.log('%c checkForEmptyKeySetting: %O', 'color: red;', checkForEmptyKeySetting);
            if (checkForEmptyKeySetting) {
                let newSortSettings: any[] = [];
                forEach(sortSettings, (stng: any, idx: number) => {
                    if (stng.jsonKey) newSortSettings.push(stng);
                });
                // console.log('%c newSortSettings: %O', 'color: blue;', newSortSettings);
                sortSettings = newSortSettings;
                // console.log('%c sortSettings: %O', 'color: purple;', sortSettings);
            }
        } else {
            if (!sortSettings) sortSettings = [];
            sortSettings.push(sortField);
            // console.log('%c sortSettings: %O', 'color: purple;', sortSettings);
        }

        settings.sortSettings = sortSettings;

        // console.log('%c settings: %O', 'color: green;', settings);
        if (onSelectionChanged) onSelectionChanged(settings);

        setSortField(null);
        flashUI(!uiState);
    };

    let structure = udicciHelpers.getMediatorStructure(mediatorName);
    // console.log('%c structure: %O', 'color: red;', structure);
    let fields = (structure && structure.UdicciMediatorFields ? structure.UdicciMediatorFields : []);
    // console.log('%c fields: %O', 'color: red;', fields);
    let virtualFields = (structure && structure.VirtualFields ? structure.VirtualFields : []);
    // console.log('%c virtualFields: %O', 'color: red;', virtualFields);

    let foundEditSetting = false;
    let sortSettingRows = [];
    if (sortSettings) {
        forEach(sortSettings, (stng: any, idxSortSetting: number) => {
            // console.log('%c sort setting: %O', 'color: purple;', stng);
            if (!fields) return null;
            let jsonKey = stng.jsonKey;
            let fld = fields.find(function(rec: any) {
                return (rec.JsonFieldName === jsonKey)
            });
            // console.log('%c sort fld: %O', 'color: purple;', fld);
            if (!fld) return null;

            let sortOrder = (stng.sortOrder ? stng.sortOrder : 'ASC');
            let sortOrderString = (sortOrder === 'DESC' ? 'Descending' : 'Ascending');

            let editThisRow = false;
            if (sortField && sortField.jsonKey === fld.JsonFieldName) {
                editThisRow = true;
                foundEditSetting = true;
            }
            // console.log('%c editThisRow: %O', 'color: purple;', editThisRow);

            let removeRequestRow = (remove && sortField && sortField.jsonKey === jsonKey ? true : false);
            // console.log('%c removeRequestRow: %O', 'color: purple;', removeRequestRow);

            let sortSettingIndex = idxSortSetting; //parseInt(idxSortSetting, 10);

            let tableRow = null;
            if (removeRequestRow) {
                tableRow = (<TableRow key={'udicci.sort.settings.row.empty'}>
                        <TableCell align="center" colSpan={3}>
                            <Typography variant="caption">
                                Are you sure you want to remove
                            </Typography>
                            &nbsp;
                            <Typography component="span" variant="subtitle1" color="secondary">
                                {sortField.displayName}
                            </Typography>
                            &nbsp;
                            <Typography variant="caption">
                                from the sort options?
                            </Typography>
                            <Button variant="contained" color="primary" onClick={(evt: any) => confirmRemoveSortField(stng, evt)}>
                                Delete
                                <Icon fontSize="small">delete</Icon>
                            </Button>
                            &nbsp;
                            <Button variant="contained" color="info" onClick={(evt: any) => cancelRemoveSortField(evt)}>
                                Cancel
                                <Icon fontSize="small">cancel</Icon>
                            </Button>
                        </TableCell>
                    </TableRow>);
            } else if (editThisRow) {
                tableRow = (<TableRow key={fld.UdicciMediatorFieldId}>
                        <TableCell size="small" style={{ verticalAlign: 'top' }}>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => applySortFieldChanges(evt)}
                                        aria-label={'Save Field Sort Settings'}
                            >
                                <Icon fontSize="small">save</Icon>
                            </IconButton>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => cancelEditSortField(evt)}
                                        aria-label={'Cancel edit Field Sort Settings'}
                            >
                                <Icon fontSize="small">cancel</Icon>
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                            <Select value={sortField.jsonKey}
                                    id='select-sort-field'
                                    onChange={(evt: any) => updateSortField('jsonKey', evt)}
                            >
                                <MenuItem disabled value="">
                                    <em>Select Field</em>
                                </MenuItem>
                                {fields.map((fld: any) => {
                                        // console.log('%c fld: %O', 'color: red;', fld);
                                        return (
                                            <MenuItem key={'sort.field.' + fld.JsonFieldName}
                                                      value={fld.JsonFieldName}
                                            >
                                                {fld.DisplayName}
                                            </MenuItem>
                                        );
                                })}
                                {virtualFields.map((fld: any) => {
                                        // console.log('%c fld: %O', 'color: red;', fld);
                                        return (
                                            <MenuItem key={'sort.field.' + fld.JsonFieldName}
                                                      value={fld.JsonFieldName}
                                            >
                                                {fld.DisplayName}
                                            </MenuItem>
                                        );
                                })}
                            </Select>
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>
                            <Select value={sortField.sortOrder}
                                    id='select-sort-order'
                                    onChange={(evt: any) => updateSortField('sortOrder', evt)}
                            >
                                <MenuItem value="ASC"> Ascending </MenuItem>
                                <MenuItem value="DESC"> Descending </MenuItem>
                            </Select>
                        </TableCell>
                    </TableRow>);
            } else {
                let sortFieldsIcons = null;
                if (reorderSortFields) {
                    sortFieldsIcons = (
                        <React.Fragment>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => moveSortField(sortSettingIndex, 'DOWN', evt)}
                                        aria-label={'Move Field Down'}
                                        disabled={(sortSettingIndex < sortSettings.length - 1 ? false : true)}
                            >
                                <Icon fontSize="small">arrow_drop_down</Icon>
                            </IconButton>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => moveSortField(sortSettingIndex, 'UP', evt)}
                                        aria-label={'Move Field Up'}
                                        disabled={(sortSettingIndex > 0 ? false : true)}
                            >
                                <Icon fontSize="small">arrow_drop_up</Icon>
                            </IconButton>
                        </React.Fragment>
                    );
                } else {
                    sortFieldsIcons = (
                        <React.Fragment>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => editSortField(stng, evt)}
                                        aria-label={'Edit Field Sort Settings'}
                            >
                                <Icon fontSize="small">edit</Icon>
                            </IconButton>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => removeSortField(stng, evt)}
                                        aria-label={'Remove Field from Sort'}
                            >
                                <Icon fontSize="small">remove_circle_outline</Icon>
                            </IconButton>
                        </React.Fragment>
                    );
                }

                tableRow = (<TableRow key={fld.UdicciMediatorFieldId}>
                        <TableCell size="small" style={{ verticalAlign: 'top' }}>
                            {sortFieldsIcons}
                        </TableCell>
                        <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                            <Typography variant="subtitle1" color='primary' noWrap={true}>
                                {fld.Name}
                            </Typography>
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>
                            <Typography variant="body1" color='textPrimary' noWrap={false}>
                                {sortOrderString}
                            </Typography>
                        </TableCell>
                    </TableRow>);
            }
            if (tableRow) sortSettingRows.push(tableRow);
        });
    }

    if (sortField && !foundEditSetting) {
        sortSettingRows.push(<TableRow key={'udicci.sort.settings.row.' + sortField.jsonKey}>
                <TableCell size="small" style={{ verticalAlign: 'top' }}>
                    <IconButton color="primary"
                                size="small"
                                onClick={(evt: any) => applySortFieldChanges(evt)}
                                aria-label={'Save Field Sort Settings'}
                    >
                        <Icon fontSize="small">save</Icon>
                    </IconButton>
                    <IconButton color="primary"
                                size="small"
                                onClick={(evt: any) => cancelEditSortField(evt)}
                                aria-label={'Cancel edit Field Sort Settings'}
                    >
                        <Icon fontSize="small">cancel</Icon>
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                    <Select value={sortField.jsonKey}
                            id='select-sort-field'
                            onChange={(evt: any) => updateSortField('jsonKey', evt)}
                    >
                        <MenuItem disabled value="">
                            <em>Select Field</em>
                        </MenuItem>
                        {fields.map((fld: any) => {
                                // console.log('%c fld: %O', 'color: red;', fld);
                                return (
                                    <MenuItem key={'sort.field.' + fld.JsonFieldName}
                                            value={fld.JsonFieldName}
                                    >
                                        {fld.DisplayName}
                                    </MenuItem>
                                );
                        })}
                        {virtualFields.map((fld: any) => {
                                // console.log('%c fld: %O', 'color: red;', fld);
                                return (
                                    <MenuItem key={'sort.field.' + fld.JsonFieldName}
                                              value={fld.JsonFieldName}
                                    >
                                        {fld.DisplayName}
                                    </MenuItem>
                                );
                        })}
                    </Select>
                </TableCell>
                <TableCell style={{ verticalAlign: 'top' }}>
                    <Select value={sortField.sortOrder}
                            id='select-sort-order'
                            onChange={(evt: any) => updateSortField('sortOrder', evt)}
                    >
                        <MenuItem value="ASC"> Ascending </MenuItem>
                        <MenuItem value="DESC"> Descending </MenuItem>
                    </Select>
                </TableCell>
            </TableRow>);
    } else if (sortSettingRows.length <= 0) {
        sortSettingRows.push(<TableRow key={'udicci.sort.settings.row.empty'}>
                <TableCell align="center" colSpan={3}>
                    <Typography variant="caption">
                        Click on the 
                    </Typography>
                    <IconButton color="primary"
                                size="small"
                                onClick={(evt: any) => addNewSortField(evt)}
                                aria-label={'Add a Field to Sort'}
                    >
                        <Icon fontSize="small">add</Icon>
                    </IconButton>
                    <Typography variant="caption">
                        to start building your list sort order.
                    </Typography>
                </TableCell>
            </TableRow>);
    }

    let enableReorderIconButton: any = null;
    if (sortSettingRows.length > 1) {
        let recorderIconElement: any = null;
        let recorderIconLabel: string = 'Reorder Fields';
        if (reorderSortFields) {
            recorderIconElement = ( <Icon fontSize="small">playlist_add_check</Icon> );
            recorderIconLabel = 'Manage Sort Fields';
        } else {
            recorderIconElement = ( <Icon fontSize="small">reorder</Icon> );
        }
        enableReorderIconButton = (
            <IconButton color="primary"
                        size="small"
                        onClick={(evt: any) => toggleReorderSortFields(evt)}
                        aria-label={recorderIconLabel}
                        title={recorderIconLabel}
            >
                {recorderIconElement}
            </IconButton>
        );
    }

    let sortSettingsElement: any = (
        <Box>
            <Typography component="span" variant="subtitle1" color='primary' noWrap={true}>
                Sort Settings
            </Typography>
            {enableReorderIconButton}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => addNewSortField(evt)}
                                        aria-label={'Add a Field to Sort'}
                            >
                                <Icon fontSize="small">add</Icon>
                            </IconButton>
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Sort Order</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{sortSettingRows}</TableBody>
            </Table>
        </Box>
    );
    return sortSettingsElement;
}

SelectUdicciSourceSort.propTypes = { classes: PropTypes.object.isRequired };
SelectUdicciSourceSort.contextTypes = { udicci: PropTypes.object, portalContext: PropTypes.object }

export default SelectUdicciSourceSort;
