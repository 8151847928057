
import { CreateProfileConfig } from './CreateProfile/CreateProfileConfig';
import { CreateProfileUI, ExecuteActionButton } from './CreateProfile/CreateProfileUI';
import { CreateProfileMenu } from './CreateProfile/CreateProfileMenuItem';
import { CreateProfileHelp } from './CreateProfile/CreateProfileHelp';

const CreateProfile: React.FC<any> = (props) => {
    // console.log('%c CreateProfile props: %O', 'color: purple;', props);

    let configureAction = (props && props.configure === true ? true : false);

    let actionProps: any = {};
    Object.assign(actionProps, props);
    // console.log('%c CreateProfile actionProps: %O', 'color: red;', actionProps);

    let actionElement: any = null;
    if (configureAction) {
        actionElement = (<CreateProfileConfig {...actionProps} />);
    } else {
        actionElement = (<CreateProfileUI {...actionProps} />);
    }
    return actionElement;
}

export { CreateProfile, ExecuteActionButton, CreateProfileMenu, CreateProfileHelp };
