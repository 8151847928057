import { useContext, useState, useEffect } from 'react';

import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import { UdicciContext, keyable, getMediatorStructures } from 'src/context/udicci-context';
import { UdicciRecord } from 'src/classes/udicci-record';

import useUdicciRecord from 'src/hooks/useUdicciRecord';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

import UdicciForm from 'src/components/udicci-form';

import { TaskDetail } from 'src/components/engagement-actions/create';

import { udicciStyles } from 'src/theme/shared-styles';

export const CreateUI: React.FC<any> = (props) => {
    // console.log('%c CreateUI props: %O', 'color: purple;', props);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const udicciContext = useContext(UdicciContext);
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);
    let { udicci } = udicciContext.state;
    // console.log('%c udicci: %O', 'color: red;', udicci);

    const [engagementData, setEngagementData] = useState<any | null>(null);
    // console.log('%c CreateUI engagementData: %O', 'color: maroon;', engagementData);

    useEffect(() => {
        // console.log('%c CreateUI useEffect engagementData: %O', 'color: blue;', engagementData);
        if (!engagementData) applyTransformations();
    });

    let displayRecord: any | null = (props && props.record ? props.record : null);
    // console.log('%c CreateUI displayRecord: %O', 'color: maroon;', displayRecord);
    if (engagementData && engagementData.targetRecord) displayRecord = engagementData.targetRecord;

    let { udicciRecord } = useUdicciRecord(displayRecord);
    let { getMediatorStructure } = useUdicciHelpers();

    let engagedAction: any | null = (props && props.engagementAction ? props.engagementAction : null);
    // console.log('%c CreateUI engagedAction: %O', 'color: maroon;', engagedAction);
    const applyTransformations = () => {
        // console.log('%c applyTransformations: %O', 'color: red;', props);
        // console.log('%c engagementData: %O', 'color: hotpink;', engagementData);
        let create = (engagedAction && engagedAction.create ? engagedAction.create : null);
        // console.log('%c create: %O', 'color: hotpink;', create);
        let sourceMediatorName = (create && create.sourceMediatorName ? create.sourceMediatorName : '');
        let targetMediatorName = (create && create.targetMediatorName ? create.targetMediatorName : '');

        let sourceMediatorStructure = getMediatorStructure(sourceMediatorName);
        // console.log('%c sourceMediatorStructure: %O', 'color: hotpink;', sourceMediatorStructure);
        let sourceFields = (sourceMediatorStructure && sourceMediatorStructure.UdicciMediatorFields ? sourceMediatorStructure.UdicciMediatorFields : null);
        // console.log('%c sourceFields: %O', 'color: hotpink;', sourceFields);
        let targetMediatorStructure = getMediatorStructure(targetMediatorName);
        // console.log('%c targetMediatorStructure: %O', 'color: hotpink;', targetMediatorStructure);
        if (!targetMediatorStructure) {
            let udicciMediatorNames: string[] = [];
            udicciMediatorNames.push(targetMediatorName);
            // console.log('%c udicciMediatorNames: %O', 'color: hotpink;', udicciMediatorNames);
            getMediatorStructures(udicciMediatorNames);
            return false;
        }

        let targetFields = (targetMediatorStructure && targetMediatorStructure.UdicciMediatorFields ? targetMediatorStructure.UdicciMediatorFields : null);
        // console.log('%c targetFields: %O', 'color: hotpink;', targetFields);
        let mediationTasks: any[] = (create && create.mediationTasks ? create.mediationTasks : []);
        // console.log('%c mediationTasks: %O', 'color: hotpink;', mediationTasks);
        let completedTasks: TaskDetail[] = [];
        let targetRecord: any = null;
        if (engagementData && engagementData.targetRecord) {
            targetRecord = engagementData.targetRecord;
        } else {
            targetRecord = new UdicciRecord(targetMediatorName, null, targetMediatorStructure);
        }
        // console.log('%c targetRecord: %O', 'color: hotpink;', targetRecord);
        if (targetRecord && mediationTasks && mediationTasks.length > 0) {
            Object.entries(mediationTasks).forEach(([idxTask, task]) => {
                // console.log('%c task: %O', 'color: hotpink;', task);
                let steps: any[] = (task.steps ? task.steps : []);
                // console.log('%c steps: %O', 'color: hotpink;', steps);
                let stepsCompleted = 0;
                let taskDetails: TaskDetail = {
                    name: task.name,
                    stepsCompleted: stepsCompleted,
                    targetField: {
                        name: '',
                        jsonKey: '',
                        sourceFields: []
                    }
                };
                if (steps && steps.length > 0) {
                    let newRecordValue = '';
                    Object.entries(steps).forEach(([idxStep, step]) => {
                        // console.log('%c step: %O', 'color: hotpink;', step);
                        let stepSettings = (step.settings ? step.settings : null);
                        // console.log('%c stepSettings: %O', 'color: hotpink;', stepSettings);
                        let stepTask = (step.task ? step.task : '');
                        // console.log('%c stepTask: %O', 'color: hotpink;', stepTask);
                        if (stepTask === 'Get Source Value') {
                            let sourceFieldId = (stepSettings && stepSettings.sourceField ? stepSettings.sourceField : null);
                            // console.log('%c sourceFieldId: %O', 'color: hotpink;', sourceFieldId);
                            if (sourceFieldId) {
                                let sourceField: any = sourceFields.find((f: any) => (f.UdicciMediatorFieldId === sourceFieldId ? true : false));
                                // console.log('%c sourceField: %O', 'color: hotpink;', sourceField);
                                if (sourceField) {
                                    let jsonFieldName = (sourceField.JsonFieldName ? sourceField.JsonFieldName : '');
                                    // console.log('%c jsonFieldName: %O', 'color: hotpink;', jsonFieldName);
                                    let fieldValue = null;
                                    if (jsonFieldName && udicciRecord && udicciRecord.data && udicciRecord.data[jsonFieldName] !== undefined) {
                                        fieldValue = udicciRecord.data[jsonFieldName];
                                    }
                                    // console.log('%c fieldValue: %O', 'color: hotpink;', fieldValue);
                                    newRecordValue = (fieldValue ? fieldValue.toString() : '');
                                    taskDetails.targetField.sourceFields.push(sourceField);
                                }
                            }
                        }
                        if (stepTask === 'Append Source Value') {
                            let sourceFieldId = (stepSettings && stepSettings.sourceField ? stepSettings.sourceField : null);
                            // console.log('%c sourceFieldId: %O', 'color: hotpink;', sourceFieldId);
                            if (sourceFieldId) {
                                let sourceField = sourceFields.find((f: any) => (f.UdicciMediatorFieldId === sourceFieldId ? true : false));
                                // console.log('%c sourceField: %O', 'color: hotpink;', sourceField);
                                if (sourceField) {
                                    let jsonFieldName = (sourceField.JsonFieldName ? sourceField.JsonFieldName : '');
                                    // console.log('%c jsonFieldName: %O', 'color: hotpink;', jsonFieldName);
                                    let fieldValue = null;
                                    if (jsonFieldName && udicciRecord && udicciRecord.data && udicciRecord.data[jsonFieldName] !== undefined) {
                                        fieldValue = udicciRecord.data[jsonFieldName];
                                    }
                                    // console.log('%c fieldValue: %O', 'color: hotpink;', fieldValue);
                                    newRecordValue += (fieldValue ? fieldValue.toString() : '');
                                    taskDetails.targetField.sourceFields.push(sourceField);
                                }
                            }
                        }
                        if (stepTask === 'Append Value') {
                            let appendValue = (stepSettings && stepSettings.appendValue ? stepSettings.appendValue : '');
                            // console.log('%c appendValue: %O', 'color: hotpink;', appendValue);
                            newRecordValue += (appendValue ? appendValue.toString() : '');
                        }
                        if (stepTask === 'Assign Value To Target') {
                            let targetFieldId = (stepSettings && stepSettings.targetField ? stepSettings.targetField : null);
                            // console.log('%c targetFieldId: %O', 'color: hotpink;', targetFieldId);
                            if (targetFieldId) {
                                let targetField = targetFields.find((f: any) => (f.UdicciMediatorFieldId === targetFieldId ? true : false));
                                // console.log('%c targetField: %O', 'color: hotpink;', targetField);
                                if (targetField) {
                                    let jsonFieldName = (targetField.JsonFieldName ? targetField.JsonFieldName : '');
                                    targetRecord.data[jsonFieldName] = newRecordValue;

                                    newRecordValue = '';

                                    taskDetails.targetField.name = targetField.DisplayName;
                                    taskDetails.targetField.jsonKey = targetField.JsonFieldName;
                                }
                            }
                        }

                        stepsCompleted++;
                    });
                }

                taskDetails.stepsCompleted = stepsCompleted;
                // console.log('%c taskDetails: %O', 'color: hotpink;', taskDetails);
                completedTasks.push(taskDetails);
            });
            targetRecord.isDirty = true;
            targetRecord.isSaving = false;
        }

        let newEngagementData = {
            sourceMediatorName: sourceMediatorName,
            sourceRecord: udicciRecord,
            sourceMediatorStructure: sourceMediatorStructure,
            targetMediatorName: targetMediatorName,
            targetRecord: targetRecord,
            targetMediatorStructure: targetMediatorStructure,
            completedTasks: completedTasks,
        };
        // console.log('%c newEngagementData: %O', 'color: hotpink;', newEngagementData);
        setEngagementData(newEngagementData);
        // console.log('%c engagementData: %O', 'color: hotpink;', engagementData);
    };

    const getRecordFieldsForForm = () => {
        // console.log('%c getRecordFieldsForForm: %O', 'color: red;', props);
        let targetMediatorStructure = (engagementData && engagementData.targetMediatorStructure ? engagementData.targetMediatorStructure : null);
        // console.log('targetMediatorStructure: %O', targetMediatorStructure);
        let fieldsToShow: any[] = [];
        if (targetMediatorStructure) {
            let codeFields = udicci.codeFields;
            // console.log('%c codeFields: %O', 'color: red;', codeFields);
            if (targetMediatorStructure && targetMediatorStructure.UdicciMediatorFields) {
                let fields = targetMediatorStructure.UdicciMediatorFields;
                // console.log('fields: %O', fields);
                if (fields && fields.length > 0) {
                    fields.forEach(function (fld: any) {
                        // console.log('%c fld: %O', 'color: purple;', fld);
                        if (fld.VirtualField)
                            return true;
                        if (codeFields.indexOf(fld.DataType) >= 0)
                            return true;

                        let showField = {
                            name: fld.JsonFieldName,
                            gutterBottom: false,
                            noWrap: false,
                            showLabels: true,
                            component: 'div',
                            color: 'textPrimary'
                        };
                        // console.log('%c showField: %O', 'color: purple;', showField);
                        fieldsToShow.push(showField);
                    });
                }

            }
        }
        // console.log('fieldsToShow: %O', fieldsToShow);
        return fieldsToShow;
    };

    const cancelAction = (evt: any) => {
        // console.log('%c cancelAction actionIsProcessing: %O', 'color: red;', actionIsProcessing);
        if (props && props.onClose) props.onClose();
    };

    let targetRecord = null;
    if (engagementData && engagementData.targetRecord) {
        targetRecord = engagementData.targetRecord;
    }
    // console.log('%c engagementData: %O', 'color: green;', engagementData);
    // console.log('%c targetRecord: %O', 'color: green;', targetRecord);
    let engageRecordForm = null;
    if (engagementData && targetRecord) {
        // setup the fieldsToShow
        let recordFields = getRecordFieldsForForm();
        // console.log('recordFields: %O', recordFields);
        // console.log('%c record: %O', 'color: purple;', record);
        // console.log('%c udicciRecord: %O', 'color: purple;', udicciRecord);
        let formRecord: any = udicciRecord;
        if (targetRecord && targetRecord.recordId !== formRecord.recordId) {
            formRecord = engagementData.targetRecord;
        }
        // console.log('%c formRecord: %O', 'color: purple;', formRecord);

        let formProps: keyable = {};
        Object.assign(formProps, props);
        formProps.fields = recordFields;
        formProps.record = formRecord;
        formProps.engagementData = engagementData;
        formProps.engagementAction = engagedAction;
        formProps.inline = true;
        // formProps.onChange = changeRecordValue;
        // formProps.onClose = null;
        // console.log('%c CreateUI formProps: %O', 'color: maroon;', formProps);
        let recordFormElement = (<UdicciForm {...formProps} />);

        engageRecordForm = (
            <Box sx={udicciClasses.mainArea}>
                {recordFormElement}
            </Box>
        );
    } else {
        engageRecordForm = (
            <Box sx={udicciClasses.mainArea}>
                <LinearProgress color="primary" />
                <Button onClick={cancelAction}>Cancel</Button>
            </Box>
        );
    }

    return engageRecordForm;
};
