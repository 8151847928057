
const sampleRoutes: any[] = [
    { routeId: 1, totalStops: 18, routeName: 'Trash Pickup Route 1', standardRouteDay: 'Tuesday', service: 'Trash Pickup', driver: 'Fred Jones', routeStartTime: '6:00am' },
    { routeId: 2, totalStops: 17, routeName: 'Trash Pickup Route 2', standardRouteDay: 'Tuesday', service: 'Trash Pickup', driver: 'Ted Franks', routeStartTime: '6:00am' },
    { routeId: 3, totalStops: 23, routeName: 'Trash Pickup Route 3', standardRouteDay: 'Tuesday', service: 'Trash Pickup', driver: 'Jim Smith', routeStartTime: '6:00am' },
    { routeId: 4, totalStops: 19, routeName: 'Trash Pickup Route 4', standardRouteDay: 'Tuesday', service: 'Trash Pickup', driver: 'Jed Munster', routeStartTime: '6:00am' },
    { routeId: 5, totalStops: 28, routeName: 'Trash Pickup Route 5', standardRouteDay: 'Tuesday', service: 'Trash Pickup', driver: 'Dan Mayfield', routeStartTime: '6:00am' },
    { routeId: 6, totalStops: 18, routeName: 'Recycle Pickup Route 1', standardRouteDay: 'Tuesday', service: 'Recycle Pickup', driver: 'Fred Jones', routeStartTime: '1:00pm' },
    { routeId: 7, totalStops: 17, routeName: 'Recycle Pickup Route 2', standardRouteDay: 'Tuesday', service: 'Recycle Pickup', driver: 'Ted Franks', routeStartTime: '1:00pm' },
    { routeId: 8, totalStops: 23, routeName: 'Recycle Pickup Route 3', standardRouteDay: 'Tuesday', service: 'Recycle Pickup', driver: 'Jim Smith', routeStartTime: '1:00pm' },
    { routeId: 9, totalStops: 19, routeName: 'Recycle Pickup Route 4', standardRouteDay: 'Tuesday', service: 'Recycle Pickup', driver: 'Jed Munster', routeStartTime: '1:00pm' },
    { routeId: 10, totalStops: 21, routeName: 'Recycle Pickup Route 5', standardRouteDay: 'Tuesday', service: 'Recycle Pickup', driver: 'Dan Mayfield', routeStartTime: '1:00pm' },
];

const sampleServices: any[] = [
    { serviceId: 1, name: 'Residential Trash Pickup' },
    { serviceId: 2, name: 'Residential Recycle Pickup' },
    { serviceId: 3, name: 'On-Site Trash Container' },
    { serviceId: 4, name: 'On-Site Recycle Container' },
    { serviceId: 5, name: 'Roll-off Container' },
];

const sampleCustomerData: any[] = [
    {
        id: 1, customerName: 'John Doe', active: true, 
        contact: { lastName: 'Doe', firstName: 'John', phone: '315-555-1234', email: 'jd@acme.com' }, 
        serviceAddresses: [
            { streetOne: '101 Main St', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 274, invoiceNumber: '274-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 234.00, datePaid: '8/12/2022', paidInFull: true },
            { invoiceId: 136, invoiceNumber: '136-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 217.00, datePaid: '7/14/2022', paidInFull: true },
            { invoiceId: 102, invoiceNumber: '101-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 287.00, datePaid: '6/22/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 1, stopNumber: 1, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 2, customerName: 'Mary Smith', active: true, 
        contact: { lastName: 'Smith', firstName: 'Mary', phone: '315-555-3210', email: 'mary@me.com' }, 
        serviceAddresses: [
            { streetOne: '157 East Main St', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: false, streetOne: '77 Pleasant St', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' }, 
        invoices: [
            { invoiceId: 278, invoiceNumber: '278-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 234.00, datePaid: '', paidInFull: false },
            { invoiceId: 140, invoiceNumber: '140-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 217.00, datePaid: '7/12/2022', paidInFull: true },
            { invoiceId: 105, invoiceNumber: '105-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 287.00, datePaid: '6/18/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 1, stopNumber: 4, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 3, customerName: 'John Smith', active: false, 
        contact: { lastName: 'Smith', firstName: 'John', phone: '315-555-5741', email: 'john@acmepalmyrabiz.com' }, 
        serviceAddresses: [
            { streetOne: '6498 Country Rd', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 144, invoiceNumber: '144-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 217.44, datePaid: '', paidInFull: false },
            { invoiceId: 109, invoiceNumber: '109-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 217.44, datePaid: '', paidInFull: false },
            { invoiceId: 101, invoiceNumber: '101-11', invoiceDate: '5/5/2022', dueDate: '5/15/2022', invoiceAmount: 217.44, datePaid: '6/5/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 2, stopNumber: 15, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 4, customerName: 'Tom Jones', active: true, 
        contact: { lastName: 'Jones', firstName: 'Tom', phone: '315-555-5741', email: 'john.smith@somehost.com' }, 
        serviceAddresses: [
            { streetOne: '1134 Pond Rd', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 286, invoiceNumber: '286-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 220.15, datePaid: '', paidInFull: false },
            { invoiceId: 152, invoiceNumber: '152-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 227.45, datePaid: '7/15/2022', paidInFull: true },
            { invoiceId: 113, invoiceNumber: '113-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 277.86, datePaid: '6/17/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 2, stopNumber: 7, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 5, customerName: 'Jim Franklin', active: true, 
        contact: { lastName: 'Franklin', firstName: 'Jim', phone: '315-555-8874', email: 'franklinj@someotherhost.com' }, 
        serviceAddresses: [
            { streetOne: '16 Parker Rd', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ],
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 290, invoiceNumber: '290-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 215.31, datePaid: '', paidInFull: false },
            { invoiceId: 156, invoiceNumber: '156-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 280.03, datePaid: '7/30/2022', paidInFull: true },
            { invoiceId: 117, invoiceNumber: '117-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 150.93, datePaid: '6/29/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 3, stopNumber: 18, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 6, customerName: 'Athenia Restaurant', active: true, 
        contact: { lastName: 'Smith', firstName: 'Joe', phone: '315-597-4287', email: 'athenia@athenia.com' }, 
        serviceAddresses: [
            { streetOne: '606 East Main St', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 294, invoiceNumber: '294-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 215.16, datePaid: '8/11/2022', paidInFull: true },
            { invoiceId: 160, invoiceNumber: '160-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 280.82, datePaid: '7/10/2022', paidInFull: true },
            { invoiceId: 121, invoiceNumber: '121-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 150.75, datePaid: '6/11/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 4, stopNumber: 3, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 3, serviceStartDate: '12/1/2021' }, { serviceId: 4, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 7, customerName: 'Kathy Douglass', active: true, 
        contact: { lastName: 'Douglass', firstName: 'Kathy', phone: '315-597-6346', email: 'katdoug@acme.com' }, 
        serviceAddresses: [
            { streetOne: '63413 Smith Road', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 294, invoiceNumber: '294-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 215.16, datePaid: '8/12/2022', paidInFull: true },
            { invoiceId: 160, invoiceNumber: '160-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 280.82, datePaid: '7/10/2022', paidInFull: true },
            { invoiceId: 121, invoiceNumber: '121-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 150.75, datePaid: '6/11/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 5, stopNumber: 18, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 3, serviceStartDate: '6/1/2021' }, { serviceId: 4, serviceStartDate: '6/1/2021' } ], 
    },
    {
        id: 11, customerName: 'John Doe', active: true, 
        contact: { lastName: 'Doe', firstName: 'John', phone: '315-555-1234', email: 'jd@acme.com' }, 
        serviceAddresses: [
            { streetOne: '101 Main St', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 274, invoiceNumber: '274-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 234.00, datePaid: '8/12/2022', paidInFull: true },
            { invoiceId: 136, invoiceNumber: '136-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 217.00, datePaid: '7/14/2022', paidInFull: true },
            { invoiceId: 102, invoiceNumber: '101-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 287.00, datePaid: '6/22/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 1, stopNumber: 1, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 12, customerName: 'Mary Smith', active: true, 
        contact: { lastName: 'Smith', firstName: 'Mary', phone: '315-555-3210', email: 'mary@me.com' }, 
        serviceAddresses: [
            { streetOne: '157 East Main St', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: false, streetOne: '77 Pleasant St', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' }, 
        invoices: [
            { invoiceId: 278, invoiceNumber: '278-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 234.00, datePaid: '', paidInFull: false },
            { invoiceId: 140, invoiceNumber: '140-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 217.00, datePaid: '7/12/2022', paidInFull: true },
            { invoiceId: 105, invoiceNumber: '105-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 287.00, datePaid: '6/18/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 1, stopNumber: 4, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 13, customerName: 'John Smith', active: false, 
        contact: { lastName: 'Smith', firstName: 'John', phone: '315-555-5741', email: 'john@acmepalmyrabiz.com' }, 
        serviceAddresses: [
            { streetOne: '6498 Country Rd', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 144, invoiceNumber: '144-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 217.44, datePaid: '', paidInFull: false },
            { invoiceId: 109, invoiceNumber: '109-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 217.44, datePaid: '', paidInFull: false },
            { invoiceId: 101, invoiceNumber: '101-11', invoiceDate: '5/5/2022', dueDate: '5/15/2022', invoiceAmount: 217.44, datePaid: '6/5/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 2, stopNumber: 15, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 14, customerName: 'Tom Jones', active: true, 
        contact: { lastName: 'Jones', firstName: 'Tom', phone: '315-555-5741', email: 'john.smith@somehost.com' }, 
        serviceAddresses: [
            { streetOne: '1134 Pond Rd', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 286, invoiceNumber: '286-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 220.15, datePaid: '', paidInFull: false },
            { invoiceId: 152, invoiceNumber: '152-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 227.45, datePaid: '7/15/2022', paidInFull: true },
            { invoiceId: 113, invoiceNumber: '113-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 277.86, datePaid: '6/17/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 2, stopNumber: 7, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 15, customerName: 'Jim Franklin', active: true, 
        contact: { lastName: 'Franklin', firstName: 'Jim', phone: '315-555-8874', email: 'franklinj@someotherhost.com' }, 
        serviceAddresses: [
            { streetOne: '16 Parker Rd', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ],
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 290, invoiceNumber: '290-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 215.31, datePaid: '', paidInFull: false },
            { invoiceId: 156, invoiceNumber: '156-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 280.03, datePaid: '7/30/2022', paidInFull: true },
            { invoiceId: 117, invoiceNumber: '117-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 150.93, datePaid: '6/29/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 3, stopNumber: 18, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 16, customerName: 'Athenia Restaurant', active: true, 
        contact: { lastName: 'Smith', firstName: 'Joe', phone: '315-597-4287', email: 'athenia@athenia.com' }, 
        serviceAddresses: [
            { streetOne: '606 East Main St', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 294, invoiceNumber: '294-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 215.16, datePaid: '8/11/2022', paidInFull: true },
            { invoiceId: 160, invoiceNumber: '160-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 280.82, datePaid: '7/10/2022', paidInFull: true },
            { invoiceId: 121, invoiceNumber: '121-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 150.75, datePaid: '6/11/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 4, stopNumber: 3, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 3, serviceStartDate: '12/1/2021' }, { serviceId: 4, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 17, customerName: 'Kathy Douglass', active: true, 
        contact: { lastName: 'Douglass', firstName: 'Kathy', phone: '315-597-6346', email: 'katdoug@acme.com' }, 
        serviceAddresses: [
            { streetOne: '63413 Smith Road', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 294, invoiceNumber: '294-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 215.16, datePaid: '8/12/2022', paidInFull: true },
            { invoiceId: 160, invoiceNumber: '160-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 280.82, datePaid: '7/10/2022', paidInFull: true },
            { invoiceId: 121, invoiceNumber: '121-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 150.75, datePaid: '6/11/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 5, stopNumber: 18, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 3, serviceStartDate: '6/1/2021' }, { serviceId: 4, serviceStartDate: '6/1/2021' } ], 
    },
    {
        id: 21, customerName: 'John Doe', active: true, 
        contact: { lastName: 'Doe', firstName: 'John', phone: '315-555-1234', email: 'jd@acme.com' }, 
        serviceAddresses: [
            { streetOne: '101 Main St', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 274, invoiceNumber: '274-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 234.00, datePaid: '8/12/2022', paidInFull: true },
            { invoiceId: 136, invoiceNumber: '136-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 217.00, datePaid: '7/14/2022', paidInFull: true },
            { invoiceId: 102, invoiceNumber: '101-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 287.00, datePaid: '6/22/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 1, stopNumber: 1, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 22, customerName: 'Mary Smith', active: true, 
        contact: { lastName: 'Smith', firstName: 'Mary', phone: '315-555-3210', email: 'mary@me.com' }, 
        serviceAddresses: [
            { streetOne: '157 East Main St', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: false, streetOne: '77 Pleasant St', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' }, 
        invoices: [
            { invoiceId: 278, invoiceNumber: '278-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 234.00, datePaid: '', paidInFull: false },
            { invoiceId: 140, invoiceNumber: '140-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 217.00, datePaid: '7/12/2022', paidInFull: true },
            { invoiceId: 105, invoiceNumber: '105-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 287.00, datePaid: '6/18/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 1, stopNumber: 4, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 23, customerName: 'John Smith', active: false, 
        contact: { lastName: 'Smith', firstName: 'John', phone: '315-555-5741', email: 'john@acmepalmyrabiz.com' }, 
        serviceAddresses: [
            { streetOne: '6498 Country Rd', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 144, invoiceNumber: '144-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 217.44, datePaid: '', paidInFull: false },
            { invoiceId: 109, invoiceNumber: '109-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 217.44, datePaid: '', paidInFull: false },
            { invoiceId: 101, invoiceNumber: '101-11', invoiceDate: '5/5/2022', dueDate: '5/15/2022', invoiceAmount: 217.44, datePaid: '6/5/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 2, stopNumber: 15, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 24, customerName: 'Tom Jones', active: true, 
        contact: { lastName: 'Jones', firstName: 'Tom', phone: '315-555-5741', email: 'john.smith@somehost.com' }, 
        serviceAddresses: [
            { streetOne: '1134 Pond Rd', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 286, invoiceNumber: '286-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 220.15, datePaid: '', paidInFull: false },
            { invoiceId: 152, invoiceNumber: '152-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 227.45, datePaid: '7/15/2022', paidInFull: true },
            { invoiceId: 113, invoiceNumber: '113-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 277.86, datePaid: '6/17/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 2, stopNumber: 7, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 25, customerName: 'Jim Franklin', active: true, 
        contact: { lastName: 'Franklin', firstName: 'Jim', phone: '315-555-8874', email: 'franklinj@someotherhost.com' }, 
        serviceAddresses: [
            { streetOne: '16 Parker Rd', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ],
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 290, invoiceNumber: '290-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 215.31, datePaid: '', paidInFull: false },
            { invoiceId: 156, invoiceNumber: '156-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 280.03, datePaid: '7/30/2022', paidInFull: true },
            { invoiceId: 117, invoiceNumber: '117-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 150.93, datePaid: '6/29/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 3, stopNumber: 18, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 1, serviceStartDate: '12/1/2021' }, { serviceId: 2, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 26, customerName: 'Athenia Restaurant', active: true, 
        contact: { lastName: 'Smith', firstName: 'Joe', phone: '315-597-4287', email: 'athenia@athenia.com' }, 
        serviceAddresses: [
            { streetOne: '606 East Main St', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 294, invoiceNumber: '294-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 215.16, datePaid: '8/11/2022', paidInFull: true },
            { invoiceId: 160, invoiceNumber: '160-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 280.82, datePaid: '7/10/2022', paidInFull: true },
            { invoiceId: 121, invoiceNumber: '121-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 150.75, datePaid: '6/11/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 4, stopNumber: 3, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 3, serviceStartDate: '12/1/2021' }, { serviceId: 4, serviceStartDate: '12/1/2021' } ], 
    },
    {
        id: 27, customerName: 'Kathy Douglass', active: true, 
        contact: { lastName: 'Douglass', firstName: 'Kathy', phone: '315-597-6346', email: 'katdoug@acme.com' }, 
        serviceAddresses: [
            { streetOne: '63413 Smith Road', streetTwo: '', city: 'Palmyra', state: 'NY', zipCode: '14522' },
        ], 
        billingAddress: { sameAsAddress: true, streetOne: '', streetTwo: '', city: '', state: '', zipCode: '' }, 
        invoices: [
            { invoiceId: 294, invoiceNumber: '294-11', invoiceDate: '8/5/2022', dueDate: '8/15/2022', invoiceAmount: 215.16, datePaid: '8/12/2022', paidInFull: true },
            { invoiceId: 160, invoiceNumber: '160-11', invoiceDate: '7/5/2022', dueDate: '7/15/2022', invoiceAmount: 280.82, datePaid: '7/10/2022', paidInFull: true },
            { invoiceId: 121, invoiceNumber: '121-11', invoiceDate: '6/5/2022', dueDate: '6/15/2022', invoiceAmount: 150.75, datePaid: '6/11/2022', paidInFull: true },
        ],
        routeAssignment: { routeId: 5, stopNumber: 18, lastServiceDate: null }, 
        servicesProvided: [ { serviceId: 3, serviceStartDate: '6/1/2021' }, { serviceId: 4, serviceStartDate: '6/1/2021' } ], 
    },
];

const sampleActivity: any[] = [
    { activityId: 105, userId: 17, customerId: 7, title: 'Service Started: Kathy Douglass', description: 'Service Started for Kathy Douglass', dateOfActivity: '5/25/2021' },
    { activityId: 1106, userId: 17, customerId: 7, title: 'Service Started: Kathy Douglass', description: 'Service Started for Kathy Douglass', dateOfActivity: '5/25/2021' },
    { activityId: 107, userId: 17, customerId: 1, title: 'Payment Received: John Doe', description: 'Payment Received from John Doe', dateOfActivity: '6/3/2021' },
    { activityId: 1108, userId: 17, customerId: 1, title: 'Payment Received: John Doe', description: 'Payment Received from John Doe', dateOfActivity: '6/3/2021' },
    { activityId: 118, userId: 17, customerId: 6, title: 'Service Started: Athenia Restaurant', description: 'Service Started for Athenia Restaurant', dateOfActivity: '6/11/2021' },
    { activityId: 1119, userId: 17, customerId: 6, title: 'Service Started: Athenia Restaurant', description: 'Service Started for Athenia Restaurant', dateOfActivity: '6/11/2021' },
    { activityId: 120, userId: 17, customerId: 7, title: 'Payment Received: Kathy Douglass', description: 'Payment Received from Kathy Douglass', dateOfActivity: '6/12/2021' },
    { activityId: 1121, userId: 17, customerId: 7, title: 'Payment Received: Kathy Douglass', description: 'Payment Received from Kathy Douglass', dateOfActivity: '6/12/2021' },
    { activityId: 122, userId: 18, customerId: 4, title: 'Created Invoice: Tom Jones', description: 'Created Invoice for Tom Jones', dateOfActivity: '8/3/2022' },
    { activityId: 1123, userId: 18, customerId: 4, title: 'Created Invoice: Tom Jones', description: 'Created Invoice for Tom Jones', dateOfActivity: '8/3/2022' },
    { activityId: 132, userId: 17, customerId: 2, title: 'Payment Received: Mary Smith', description: 'Payment Received from Mary Smith', dateOfActivity: '8/5/2022' },
    { activityId: 1133, userId: 17, customerId: 2, title: 'Payment Received: Mary Smith', description: 'Payment Received from Mary Smith', dateOfActivity: '8/5/2022' },
    { activityId: 134, userId: 19, customerId: 3, title: 'Created Invoice: John Smith', description: 'Created Invoice for John Smith', dateOfActivity: '8/9/2022' },
    { activityId: 1135, userId: 19, customerId: 3, title: 'Created Invoice: John Smith', description: 'Created Invoice for John Smith', dateOfActivity: '8/9/2022' },
    { activityId: 143, userId: 23, customerId: 5, title: 'Payment Received: Jim Franklin', description: 'Payment Received from Jim Franklin', dateOfActivity: '8/5/2022' },
    { activityId: 1144, userId: 23, customerId: 5, title: 'Payment Received: Jim Franklin', description: 'Payment Received from Jim Franklin', dateOfActivity: '8/5/2022' },
    { activityId: 234, userId: 18, customerId: 3, title: 'Payment Received: John Smith', description: 'Payment Received from John Smith', dateOfActivity: '8/7/2022' },
    { activityId: 1235, userId: 18, customerId: 3, title: 'Payment Received: John Smith', description: 'Payment Received from John Smith', dateOfActivity: '8/7/2022' },
    { activityId: 256, userId: 18, customerId: 6, title: 'Payment Received: Athenia Restaurant', description: 'Payment Received from Athenia Restaurant', dateOfActivity: '8/11/2022' },
    { activityId: 1257, userId: 18, customerId: 6, title: 'Payment Received: Athenia Restaurant', description: 'Payment Received from Athenia Restaurant', dateOfActivity: '8/11/2022' },
    { activityId: 267, userId: 17, customerId: 1, title: 'Payment Received: John Doe', description: 'Payment Received from John Doe', dateOfActivity: '8/12/2022' },
    { activityId: 1268, userId: 17, customerId: 1, title: 'Payment Received: John Doe', description: 'Payment Received from John Doe', dateOfActivity: '8/12/2022' },
    { activityId: 767, userId: 17, customerId: 7, title: 'Payment Received: Kathy Douglass', description: 'Payment Received from Kathy Douglass', dateOfActivity: '8/12/2022' },
    { activityId: 1768, userId: 17, customerId: 7, title: 'Payment Received: Kathy Douglass', description: 'Payment Received from Kathy Douglass', dateOfActivity: '8/12/2022' },
];

const sampleHelp: any[] = [
    { helpId: 1, url: 'https://youtu.be/_BwCnaB0niY', label: 'Learn how to Manage Customers' },
    { helpId: 2, url: 'https://youtu.be/_BwCnaB0niY', label: 'Learn how to Manage Routes' },
    { helpId: 3, url: 'https://youtu.be/_BwCnaB0niY', label: 'Learn how to Manage Activity' },
    { helpId: 4, url: 'https://youtu.be/_BwCnaB0niY', label: 'Learn how to Create an Invoice' },
    { helpId: 5, url: 'https://youtu.be/_BwCnaB0niY', label: 'Learn how to Take a Payment' },
];

const sampleChatsForOpenAI: any[] = [
    { id: 1, prompt: 'Write a detailed plan to get a new driveway installed for my business.  The driveway is 700 square feet.', label: 'Freedom Tour 2023', isExample: false },
    { id: 2, prompt: 'Write 24 Social Media Posts to be shared over the next 30 days to promote our new product.', label: '24 In A Can', isExample: true },
    { id: 3, prompt: 'Write a training document on how to use the new platform.', label: 'Training Documentation', isExample: true },
    { id: 4, prompt: 'Write a product description and sales assets for promoting the new product.', label: 'New Product Promotion', isExample: true },
    { id: 5, prompt: 'Write a high level overview, detailed design, and requirements document for a new Social Solution for property management.', label: 'New Social Solution Plan', isExample: false },
];

export { sampleRoutes, sampleServices, sampleCustomerData, sampleActivity, sampleHelp, sampleChatsForOpenAI };
