
import { FC, Fragment, useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';

import Pluralize from 'pluralize';

import { values, forEach } from 'underscore';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import { useUdicciContext } from 'src/context/udicci-context';
// import { usePaneContext } from 'src/context/pane-context';
import { UdicciRecord } from 'src/classes/udicci-record';
import { RecordPreview } from './record-preview';
import { RecordParentSelection } from './record-parent-selector';
import UdicciForm from './udicci-form';
// import useUdicciMediator from 'src/hooks/useUdicciMediator';
// import useUdicciRecord from 'src/hooks/useUdicciRecord';
// import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

import {
    ExpandMore as ShowMoreIcon,
    ExpandLess as ShowLessIcon,
    // List as ChangeSelectedRecordIcon,
    ArrowRight as NextIcon,
    ArrowLeft as PreviousIcon,
} from '@mui/icons-material';

import { udicciStyles } from 'src/theme/shared-styles';

import { useRecordContextFactory, RecordContextProvider } from 'src/context/record-context-factory';
// import { RecordContextFactory } from 'src/context/record-context-factory';

export const RecordContextDisplay: FC<any> = (props) => {
    // console.log('%c RecordContextDisplay props: %O', 'color: maroon;', props);
    return (
        <RecordContextProvider {...props}>
            <ValidatedUdicciContextView {...props} />
        </RecordContextProvider>
    );
}

const ValidatedUdicciContextView: FC<any> = (props) => {
    // console.log('%c ValidatedUdicciContextView props: %O', 'color: maroon;', props);
    let record: UdicciRecord = props.record;

    let pData: any = (props && props.data ? props.data : null);
    // console.log('%c ValidatedUdicciContextView pData: %O', 'color: red;', pData);

    let contextView: any = (props && props.contextView ? props.contextView : null);
    // console.log('%c ValidatedUdicciContextView contextView: %O', 'color: red;', contextView);

    let contextData: any = null;
    let contextRecord: any = null;
    if (contextView) {
        contextData = (contextView && contextView.data ? contextView.data : null);
        contextRecord = (contextData && contextData.length > 0 ? contextData[0] : null);
    }
    // console.log('%c ValidatedUdicciContextView contextData: %O', 'color: red;', contextData);
    // console.log('%c ValidatedUdicciContextView contextRecord: %O', 'color: red;', contextRecord);
    if (contextRecord && !record) {
        record = contextRecord;
    }

    if (!record) return null;
    return (<UdicciContextView {...props} />);
}

const UdicciContextView: FC<any> = (props) => {
    // console.log('%c UdicciContextView props: %O', 'color: red;', props);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const udicciContext = useUdicciContext();
    // const paneContext = usePaneContext();

    const recordContextFactory = useRecordContextFactory();
    // console.log('%c UdicciContextView recordContextFactory: %O', 'color: red;', recordContextFactory);

    // let paneContextState: any = (paneContext && paneContext.state ? paneContext.state : null);
    // console.log('%c UdicciContextViewWrapper paneContextState: %O', 'color: maroon;', paneContextState);
    // let contentDesign: any = (paneContextState && paneContextState.contentDesign ? paneContextState.contentDesign : null);
    // console.log('%c UdicciContextViewWrapper contentDesign: %O', 'color: maroon;', contentDesign);

    let { udicci } = udicciContext.state;
    // console.log('%c UdicciContextView udicci: %O', 'color: red;', udicci);

    let { selectedProfile, currentUser, handCashConnect } = udicci;
    // console.log('%c UdicciContextView selectedProfile: %O', 'color: red;', selectedProfile);
    // console.log('%c UdicciContextView currentUser: %O', 'color: red;', currentUser);
    // console.log('%c UdicciContextView selectedSocialSolution: %O', 'color: red;', selectedSocialSolution);
    // console.log('%c UdicciContextView selectedFeature: %O', 'color: red;', selectedFeature);
    // const socialSolutionContext = useUdicciSocialSolution(selectedSocialSolution);

    const [handCashProfile, setHandCashProfile] = useState<any>(null);
    // console.log('%c UdicciContextView handCashProfile: %O', 'color: red;', handCashProfile);

    useEffect(() => {
        // console.log('%c UdicciContextView useEffect rootRecord: %O', 'color: blue;', rootRecord);
        // console.log('%c UdicciContextView useEffect recordContext: %O', 'color: blue;', recordContext);
        // console.log('%c UdicciContextView useEffect rootRecords: %O', 'color: blue;', rootRecords);
        if (!recordContext && rootRecord && rootRecord.context) {
            if (recordContextFactory && recordContextFactory.setRootRecord) {
                recordContextFactory.setRootRecord(rootRecord);
            }
            if (recordContextFactory && recordContextFactory.setRecordContext) {
                recordContextFactory.setRecordContext(rootRecord.context);
            }
        }
        if (recordContext && rootRecords) {
            let rootRecordMediator: string = (rootRecord.udicciMediator ? rootRecord.udicciMediator : '');
            if (!rootRecordMediator && rootRecord.mediator) rootRecordMediator = rootRecord.mediator;
            let dataCheck: any[] = [];
            if (recordContextFactory.getData) {
                dataCheck = recordContextFactory.getData(rootRecordMediator);
            }
            // console.log('%c UdicciContextView dataCheck: %O', 'color: blue;', dataCheck);
            if (recordContextFactory.setData && rootRecords.length !== dataCheck.length) {
                recordContextFactory.setData(rootRecordMediator, rootRecords);
            }
        }
        // getCurrentHandCashProfile();
        // console.log('%c UdicciContextView contextView: %O', 'color: blue;', contextView);
        let needsRecordContext: boolean = ((!recordContext || (rootRecord && !rootRecord.context)) ? true : false);
        let contextViewRecordChanged: boolean = ((contextView && rootRecord && contextView.record && (contextView.record.id !== rootRecord.recordId)) ? true : false);
        if ((needsRecordContext || contextViewRecordChanged) && contextView && recordContextFactory && recordContextFactory.loadRecordContextByView) {
            // console.log('%c UdicciContextView contextView: %O', 'color: green;', contextView);
            let force: boolean = false;
            if (contextViewRecordChanged) {
                contextView.record.id = rootRecord.recordId;
                contextView.record.title = (rootRecord.record ? rootRecord.record.title : (rootRecord.title ? rootRecord.title : ''));
                contextView.record.context = null;
                force = true;
            }
            // console.log('%c UdicciContextView contextView: %O', 'color: green;', contextView);
            recordContextFactory.loadRecordContextByView(contextView, force);
        }
    });

    const addNewLevelRecord = (level: number) => {
        // console.log('%c addNewLevelRecord level: %O', 'color: red;', level);
        if (level <= 0) return false;
        if (datalist) setDatalist('');
        addRecord(level);
        setEditLevel(level);
    };

    const editRecord = (rec: any) => {
        // console.log('%c editRecord rec: %O', 'color: blue;', rec);

        let curRecord: any = null;
        if (editLevel > 1) {
            let ds: any = (displayState[editLevel] ? displayState[editLevel] : null);
            // console.log('%c ds: %O', 'color: blue;', ds);
            curRecord = (ds.selectedRecord ? ds.selectedRecord : null);
        }
        // console.log('%c curRecord: %O', 'color: blue;', curRecord);

        if (!rec && curRecord && curRecord.recordId === 0) {
            removeDisplayRecord(curRecord);
        }

        if (datalist) setDatalist('');
        if (rec && rec.level) {
            setEditLevel(rec.level);
        } else {
            setEditLevel(0);
        }
    };

    const [showTransactionDetails, setShowTransactionDetails] = useState<boolean>(false);
    const [datalist, setDatalist] = useState<string>('');
    const [editLevel, setEditLevel] = useState<number>(0);
    // const [updateState, forceUpdate] = useState<boolean>(false);
    // console.log('%c UdicciContextView datalist: %O', 'color: red;', datalist);
    // console.log('%c UdicciContextView editLevel: %O', 'color: red;', editLevel);

    let pData: any = (props && props.data ? props.data : null);
    // console.log('%c UdicciContextView pData: %O', 'color: red;', pData);
    let contextView: any = (props && props.contextView ? props.contextView : null);
    // console.log('%c UdicciContextView contextView: %O', 'color: red;', contextView);

    let handCashOptions: any = (currentUser && currentUser.HandCashOptions ? currentUser.HandCashOptions : null);
    // console.log('%c handCashOptions: %O', 'color: red;', handCashOptions);

    const goToHandCash = (evt: any) => {
        // console.log('%c editRecord rec: %O', 'color: blue;', rec);
        var url: any = udicci.getRedirectionLoginUrl();
        // console.log('%c UdicciContextView url: %O', 'color: maroon;', url);
        window.location.href = url;
    };

    const getCurrentHandCashProfile = async () => {
        // console.log('%c getCurrentHandCashProfile handCashOptions: %O', 'color: blue;', handCashOptions);
        // console.log('%c getCurrentHandCashProfile handCashProfile: %O', 'color: blue;', handCashProfile);

        if (!handCashOptions || handCashProfile) return false;

        let authToken: string = (handCashOptions && handCashOptions.authToken ? handCashOptions.authToken : '');
        const account = handCashConnect.getAccountFromAuthToken(authToken);

        const currentProfile = await account.profile.getCurrentProfile();
        // console.log('%c getCurrentHandCashProfile currentProfile: %O', 'color: blue;', currentProfile);
        setHandCashProfile(currentProfile);
    };

    const getBlockChainTransaction = async () => {
        // console.log('%c getBlockChainTransaction handCashOptions: %O', 'color: blue;', handCashOptions);
        // console.log('%c getBlockChainTransaction handCashProfile: %O', 'color: blue;', handCashProfile);
        let initialUdicciTransactionIdOnMainNet: string = udicci.initialUdicciTransactionIdOnMainNet;
        const initialUdicciTransactionOnMainNet = await udicci.getBlockChainTransaction(initialUdicciTransactionIdOnMainNet);
        // console.log('%c getBlockChainTransaction initialUdicciTransactionOnMainNet: %O', 'color: blue;', initialUdicciTransactionOnMainNet);
    };

    const broadcastToBSV = async (transactionData: any, evt: any) => {
        // console.log('%c broadcastToBSV handCashOptions: %O', 'color: blue;', handCashOptions);
        // console.log('%c broadcastToBSV transactionData: %O', 'color: blue;', transactionData);
        if (!handCashOptions || !handCashProfile) return false;

        // let authToken: string = (handCashOptions && handCashOptions.authToken ? handCashOptions.authToken : '');
        // const account = handCashConnect.getAccountFromAuthToken(authToken);

        // var permissions = await account.profile.getPermissions();
        // var balance = await account.wallet.getSpendableBalance();
        // // console.log('%c broadcastToBSV permissions: %O', 'color: blue;', permissions);
        // // console.log('%c broadcastToBSV balance: %O', 'color: blue;', balance);

        // // console.log('%c broadcastToBSV handCashProfile: %O', 'color: blue;', handCashProfile);
        // const { publicProfile, privateProfile } = handCashProfile;
        // // console.log('%c broadcastToBSV publicProfile: %O', 'color: blue;', publicProfile);
        // // console.log('%c broadcastToBSV privateProfile: %O', 'color: blue;', privateProfile);

        // var firstUlyssesMessageOnChain: any = {
        //     udicciMediator: 'n/a',
        //     recordId: 0,
        //     data: {
        //         message: 'Kilroy Was Here!',
        //         author: 'Ulysses D. Constantine',
        //         socialIcon: 'https://udicci.me/UlyssesSocialIcon.png',
        //         me: 'https://udicci.me/ulyssesdconstantine',
        //     }
        // };
        // // console.log('%c broadcastToBSV firstUlyssesMessageOnChain: %O', 'color: blue;', firstUlyssesMessageOnChain);
        // let firstUlyssesMessageOnChainMessage: string = JSON.stringify(firstUlyssesMessageOnChain);
        // const messageToBroadcast = Buffer.from(firstUlyssesMessageOnChainMessage).toString('hex');
        // let messageToBroadcastSizeInBytes: number = messageToBroadcast.length;
        // // console.log('%c broadcastToBSV messageToBroadcastSizeInBytes: %O', 'color: red;', messageToBroadcastSizeInBytes);
        // // cost = bytes * 1 === Satoshis cost (round up)
        // let costInSatoshisToBroadcast: number = Math.round(messageToBroadcast.length);
        // // console.log('%c broadcastToBSV costInSatoshisToBroadcast: %O', 'color: red;', costInSatoshisToBroadcast);
        // let commissionInSatoshisToBroadcast: number = costInSatoshisToBroadcast * 0.03;
        // // console.log('%c broadcastToBSV commissionInSatoshisToBroadcast: %O', 'color: red;', commissionInSatoshisToBroadcast);
        // let costInBSVToBroadcast: number = (costInSatoshisToBroadcast + commissionInSatoshisToBroadcast) * 0.00000001;
        // costInBSVToBroadcast = (Math.round(costInBSVToBroadcast * 100000000) / 100000000);
        // // console.log('%c broadcastToBSV costInBSVToBroadcast: %O', 'color: red;', costInBSVToBroadcast);
        // let totalAmountToSend: number = costInBSVToBroadcast;
        // // console.log('%c broadcastToBSV totalAmountToSend: %O', 'color: red;', totalAmountToSend);

        // // const { publicKey, signature } = await account.profile.signData({
        // //     value: messageToBroadcast,
        // //     format: 'hex'
        // // });
        // // console.log('%c broadcastToBSV publicKey: %O', 'color: blue;', publicKey);
        // // console.log('%c broadcastToBSV signature: %O', 'color: blue;', signature);

        // let handCashHandle: string = (publicProfile && publicProfile.handle ? publicProfile.handle : '');

        // // construct the payment
        // const paymentParameters = {
        //     description: 'Udicci.IT Transaction',
        //     payments: [
        //         {
        //             destination: handCashHandle,
        //             currencyCode: 'BSV',
        //             sendAmount: totalAmountToSend,
        //         },
        //     ],
        //     attachment: { format: 'hex', value: messageToBroadcast },
        // };
        // console.log('%c broadcastToBSV paymentParameters (NOT SENT CURRENTLY): %O', 'color: blue;', paymentParameters);

        // const payment = await account.wallet.pay(paymentParameters).catch((err: any) => console.log('%c broadcastToBSV payment error: %O', 'color: blue;', err));
        // console.log('%c broadcastToBSV payment: %O', 'color: red; font-weight: bold;', payment);
    };

    let profile = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    // console.log('%c UdicciContextView profile: %O', 'color: red;', profile);
    let udicciUserRole = (profile && profile.UdicciUserRole ? profile.UdicciUserRole : null);
    // console.log('%c udicciUserRole: %O', 'color: purple; font-weight: bold;', udicciUserRole);

    // let BSV_BASE_COST_PER_BYTE = (udicci && udicci.BSV_BASE_COST_PER_BYTE ? udicci.BSV_BASE_COST_PER_BYTE : 1);
    // console.log('%c UdicciContextView BSV_BASE_COST_PER_BYTE: %O', 'color: maroon;', BSV_BASE_COST_PER_BYTE);
    // let BSV_PER_100_BYTES = (udicci && udicci.BSV_PER_100_BYTES ? udicci.BSV_PER_100_BYTES : 100);
    // console.log('%c UdicciContextView BSV_PER_100_BYTES: %O', 'color: maroon;', BSV_PER_100_BYTES);

    let showForm: boolean = (props && props.showForm ? true : false);

    let mediator: any = (props && props.mediator ? props.mediator : null);
    // console.log('%c UdicciContextView mediator: %O', 'color: red;', mediator);
    let mediatorName: string = (mediator && mediator.name ? mediator.name : '');
    // console.log('%c UdicciContextView mediatorName: %O', 'color: red;', mediatorName);

    let contextData: any = null;
    let contextRecord: any = null;
    let ctxSS: any = null;
    let ctxRecord: any = null;
    let ctxRecordContext: any = null;
    let contextSocialSolution: any = null;
    if (contextView) {
        ctxRecord = (contextView && contextView.record ? contextView.record : null);
        ctxRecordContext = (ctxRecord && ctxRecord.context ? ctxRecord.context : null);
        contextData = (contextView && contextView.data ? contextView.data : null);
        ctxSS = (contextView && contextView.socialSolution ? contextView.socialSolution : null);
        let ctxSSMore: any =  (ctxSS && ctxSS.more ? ctxSS.more : null);
        contextSocialSolution =  (ctxSSMore && ctxSSMore.solution ? ctxSSMore.solution : null);
        contextRecord = (contextData && contextData.length > 0 ? contextData[0] : null);
    }
    // console.log('%c UdicciContextView contextData: %O', 'color: red;', contextData);
    // console.log('%c UdicciContextView contextSocialSolution: %O', 'color: red;', contextSocialSolution);
    // console.log('%c UdicciContextView contextRecord: %O', 'color: red;', contextRecord);
    // console.log('%c UdicciContextView ctxRecord: %O', 'color: red;', ctxRecord);
    // console.log('%c UdicciContextView ctxRecordContext: %O', 'color: red;', ctxRecordContext);

    let newRecord: any = new UdicciRecord(mediatorName, {}, null);
    let rootRecord: any = recordContextFactory.rootRecord;
    // console.log('%c UdicciContextView rootRecord: %O', 'color: red;', rootRecord);
    let recordContext: any = recordContextFactory.recordContext;
    // console.log('%c UdicciContextView recordContext: %O', 'color: red;', recordContext);
    if (!recordContext && contextView) {
        // let ctxRecord: any = (contextView.record ? contextView.record : null);
        // let ctxRecContext: any = (ctxRecord && ctxRecord.context ? ctxRecord.context : null);
        // recordContext = ctxRecContext;
        // console.log('%c UdicciContextView recordContext: %O', 'color: blue;', recordContext);
    }

    let record: UdicciRecord = (props && props.record ? props.record : (rootRecord ? rootRecord : newRecord));
    // console.log('%c UdicciContextView record: %O', 'color: red;', record);
    if (contextRecord) {
        record = contextRecord;
        // console.log('%c UdicciContextView record: %O', 'color: blue;', record);
    }
    // let { udicciRecord } = useUdicciRecord(record);
    // console.log('%c UdicciContextView udicciRecord: %O', 'color: red;', udicciRecord);
    // let contextSocialSolution: any = (ctxSS && ctxSS.socialSolution ? ctxSS.socialSolution : null);

    let datasets: any = recordContextFactory.datasets;
    // console.log('%c UdicciContextView datasets: %O', 'color: red;', datasets);
    let displayState: any = recordContextFactory.displayState;
    // console.log('%c UdicciContextView displayState: %O', 'color: red;', displayState);
    // let getCurrentDisplayContext: any = recordContextFactory.getCurrentDisplayContext;
    // let RecordContextStateUpdate: boolean = (recordContextFactory && recordContextFactory.RecordContextStateUpdate ? true : false);
    // console.log('%c UdicciContextView RecordContextStateUpdate: %O', 'color: red;', RecordContextStateUpdate);
    let rootRecords: any = recordContextFactory.rootRecords;
    // console.log('%c UdicciContextView rootRecords: %O', 'color: red;', rootRecords);
    let navigate: any = recordContextFactory.navigate;
    // let jump: any = recordContextFactory.jump;
    let addRecord: any = recordContextFactory.addRecord;
    let removeDisplayRecord: any = recordContextFactory.removeDisplayRecord;

    let records: UdicciRecord[] | null = (props && props.records ? props.records : null);
    // console.log('%c UdicciContextView records: %O', 'color: red;', records);
    if (!records && props && props.recordSet) {
        records = props.recordSet;
        // console.log('%c UdicciContextView records: %O', 'color: red;', records);
    }

    let recTitleProps: any = {
        variant: 'caption',
        component: 'span',
        noWrap: true,
        sx: udicciClasses.recordTitleLeftSpace
    };

    if (record) {
        let recordId = (record.recordId ? record.recordId : 0);

        if (!recordContext && recordContextFactory && recordContextFactory.loadRecordContextByView) {
            recordContextFactory.loadRecordContextByView(contextView);
        }
        let allDatasets: any[] = [];
        let newRecords: any[] = [];
        if (datasets && datasets[record.udicciMediator]) {
            // need to sort datasets, then get data from each dataset in order at this point.
            let mainContext: any = (datasets[record.udicciMediator] ? datasets[record.udicciMediator] : null);
            // console.log('%c UdicciContextView mainContext: %O', 'color: red;', mainContext);
            let mainContextLevel: number = (mainContext && mainContext.level ? mainContext.level : 1);
            // console.log('%c UdicciContextView mainContextLevel: %O', 'color: red;', mainContextLevel);

            values(datasets).forEach((ds: any) => {
                // console.log('%c UdicciContextView ds: %O', 'color: red;', ds);
                if ((showForm && mainContextLevel > ds.level) || !showForm) {
                    if (ds.data) newRecords.push.apply(newRecords, ds.data);
                    allDatasets.push(ds);
                }
            });
            // console.log('%c UdicciContextView newRecords: %O', 'color: red;', newRecords);
            // records = (medds.data ? medds.data : records);
            // console.log('%c UdicciContextView records: %O', 'color: red;', records);
        }
        if (allDatasets) {
            allDatasets = allDatasets.sort((a: any,b: any) => {
                if (a.level < b.level) return -1;  // ascending
                if (a.level > b.level) return 1;  // descending
                return 0 //default return value (no sorting)
            });
        }
        let sortedRecords: any[] | null = null;
        if (newRecords) {
            if (!sortedRecords) sortedRecords = [];
            sortedRecords = newRecords.sort((a: any,b: any) => {
                if (a.level < b.level) return -1;  // ascending
                if (a.level > b.level) return 1;  // descending
                return 0 //default return value (no sorting)
            });
        } else if (records) {
            if (!sortedRecords) sortedRecords = [];
            sortedRecords = records.sort((a: any,b: any) => {
                if (a.level < b.level) return -1;  // ascending
                if (a.level > b.level) return 1;  // descending
                return 0 //default return value (no sorting)
            });
        }
        // console.log('%c allDatasets: %O', 'color: red;', allDatasets);
        // console.log('%c sortedRecords: %O', 'color: red;', sortedRecords);

        let strTransactionData: string = '';
        let transactionData: any = {};
        try {
            forEach(allDatasets, (ds: any) => {
                transactionData[ds.mediator] = {
                    level: ds.level,
                    mediator: ds.mediator,
                    data: []
                };
                if (ds.data && ds.data.length > 0) {
                    forEach(ds.data, (rec: any) => {
                        var recData = (rec.record && rec.record.data ? rec.record.data : null);
                        if (recData) transactionData[ds.mediator].data.push(recData);
                    })
                }
            })
            strTransactionData = JSON.stringify(transactionData);
        } catch (ex: any) {
            // console.log('%c UdicciContextView ex: %O', 'color: red;', ex);
        }
        // console.log('%c UdicciContextView strTransactionData: %O', 'color: red;', strTransactionData);
        // console.log('%c transactionData: %O', 'color: red;', transactionData);

        // var base64Hash = Buffer.from(strTransactionData).toString("base64");
        // // console.log('%c UdicciContextView base64Hash: %O', 'color: red;', base64Hash);

        // let dataHashSizeInBytes: number = base64Hash.length;
        // // console.log('%c UdicciContextView dataHashSizeInBytes: %O', 'color: red;', dataHashSizeInBytes);
        // // let footprintHashSizeInBytes: number = footPrintforUdicciRecord.length;
        // // let totalSizeInBytes: number = dataHashSizeInBytes + footprintHashSizeInBytes;
        // let bsvCostToWriteTransaction: number = ((dataHashSizeInBytes / 100) * BSV_PER_100_BYTES);
        // // console.log('%c UdicciContextView bsvCostToWriteTransaction: %O', 'color: red;', bsvCostToWriteTransaction);
        // bsvCostToWriteTransaction = Math.round(bsvCostToWriteTransaction * 100) / 100;
        // let networkFees: number = BSV_PER_100_BYTES + BSV_PER_100_BYTES + BSV_PER_100_BYTES;
        // // console.log('%c UdicciContextView networkFees: %O', 'color: red;', networkFees);

        const fieldToggled = (rec: any, field: any) => {
            // console.log('%c fieldToggled rec: %O', 'color: red;', rec);
            // console.log('%c fieldToggled field: %O', 'color: red;', field);
            if (!record) return false;
            if (!field) return false;
        };

        // const toggleRecordList = (udicciMediatorName: string) => {
        //     // console.log('%c toggleRecordList udicciMediatorName: %O', 'color: red;', udicciMediatorName);
        //     if (datalist === udicciMediatorName) {
        //         setDatalist('');
        //     } else {
        //         setDatalist(udicciMediatorName);
        //     }
        // };

        // const navigateToRecord = (udicciMediatorName: string, direction: string) => {
        //     // console.log('%c navigateToRecord udicciMediatorName: %O', 'color: red;', udicciMediatorName);
        //     // console.log('%c navigateToRecord direction: %O', 'color: red;', direction);

        //     // let newRecordFocus: any = navigate(udicciMediatorName, direction);
        //     // console.log('%c navigateToRecord newRecordFocus: %O', 'color: red;', newRecordFocus);

        //     // setDatalist('');
        //     // setContextRecordPreview(newRecordFocus);
        // };

        // const goToRecord = (jumpToRecord: any) => {
        //     // console.log('%c goToRecord jumpToRecord: %O', 'color: red;', jumpToRecord);
        //     if (!jumpToRecord) return false;
        //     setDatalist('');
        //     jump(jumpToRecord);
        // };

        let transactionForm: any = null;
        if (udicciUserRole?.IsAdministratorRole && recordId > 0 && recordContext && editLevel <= 0) {
            if (!showForm && showTransactionDetails) {
                var publicProfile: any = null;
                var privateProfile: any = null;
                if (handCashProfile) {
                    publicProfile = (handCashProfile.publicProfile ? handCashProfile.publicProfile : null);
                    privateProfile = (handCashProfile.privateProfile ? handCashProfile.privateProfile : null);
                }
                // console.log('%c broadcastToBSV publicProfile: %O', 'color: blue;', publicProfile);
                // console.log('%c broadcastToBSV privateProfile: %O', 'color: blue;', privateProfile);

                // let transactionDetailsElement: any = (
                //     <Box>  {/*** Transaction Data Hash - Base64 ***/}
                //         <Typography component="span" variant="caption">
                //             Transaction Data Hash (Base64) Size is 
                //         </Typography>
                //         <Typography component="span" variant="body2">
                //             {dataHashSizeInBytes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} bytes
                //         </Typography>
                //         &nbsp;
                //         <Typography component="span" variant="caption">
                //             ({udicci.formatBytes(dataHashSizeInBytes, 2)})
                //         </Typography>
                //     </Box>
                // );
                // let transactionCostElement: any = (
                //     <Box>  {/*** Cost to Broadcast this Transaction ***/}
                //         <Typography component="div" variant="caption">
                //             Cost to Broadcast this Transaction is 
                //         </Typography>
                //         <Typography component="div" variant="caption">
                //             {bsvCostToWriteTransaction.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Broadcast Fees
                //         </Typography>
                //         <Typography component="div" variant="caption" gutterBottom={true}>
                //             {networkFees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Udicci Network Fees
                //         </Typography>
                //     </Box>
                // );
                let connectWithHandCashButton: any = null;
                let broadcastTransactionButton: any = null;
                let broadcastDisabled: boolean = false;
                if (currentUser && !currentUser.HandCashOptions) {
                    connectWithHandCashButton = (
                        <Button variant="contained" size="small" color="primary" onClick={goToHandCash}>
                            Connect With HandCash
                        </Button>
                    );
                    broadcastDisabled = true;
                } else if (handCashOptions) {
                    let handCashHandle: string = (publicProfile && publicProfile.handle ? publicProfile.handle : '');
                    let handCashAvatar: string = (publicProfile && publicProfile.avatarUrl ? publicProfile.avatarUrl : '');
                    let handCashAvatarElement: any = null;
                    let handCashHandleElement: any = null;
                    if (handCashAvatar) {
                        handCashAvatarElement = ( <img src={handCashAvatar} style={{ maxHeight: 35, margin: '4px', float: 'left' }} /> );
                    }
                    if (handCashHandle) {
                        handCashHandleElement = (
                            <Fragment>
                                <Typography variant="caption" component="span">
                                    Connected to HandCash
                                </Typography>
                                <Typography variant="caption" component="div">
                                    handle: {handCashHandle}
                                </Typography>
                            </Fragment>
                        );
                    }
                    if (!handCashHandleElement) {
                        handCashHandleElement = (
                            <Typography variant="caption" component="div">
                                Connected to HandCash.
                            </Typography>
                        );
                    }

                    connectWithHandCashButton = (
                        <Box sx={udicciClasses.generalContainer}>
                            {handCashAvatarElement}
                            {handCashHandleElement}
                        </Box>
                    );
                }

                if (currentUser) {
                    if (udicci.initialUdicciTransactionIdOnMainNet) {
                        broadcastTransactionButton = (
                            <Button variant="contained" size="small" color="secondary" disabled={broadcastDisabled} onClick={(evt: any) => getBlockChainTransaction()}>
                                Retrieve Transaction
                            </Button>
                        );
                    } else {
                        broadcastTransactionButton = (
                            <Button variant="contained" size="small" color="secondary" disabled={broadcastDisabled} onClick={(evt: any) => broadcastToBSV(transactionData, evt)}>
                                Broadcast Transaction
                            </Button>
                        );
                    }
                } // ()

                // transactionForm = (
                //     <Box sx={udicciClasses.recordContextItemContainerOpen}>
                //         <Box>
                //             <Typography variant="subtitle2" component="span">
                //                 BSV Contract Details.
                //             </Typography>
                //             <IconButton size="small"
                //                         color="primary"
                //                         component="span"
                //                         onClick={(evt: any) => setShowTransactionDetails(false)}
                //                         classes={{ root: 'recordContextButton' }}
                //             >
                //                 <ShowLessIcon classes={{ root: 'previewRecordContextButton' }} />
                //             </IconButton>
                //         </Box>

                //         <Box sx={udicciClasses.generalContainer}>  {/*** Broadcast Transaction Button ***/}
                //             {broadcastTransactionButton}
                //             {connectWithHandCashButton}
                //         </Box>
                //     </Box>
                // );
            // } else if (!showForm) {
            //     transactionForm = (
            //         <Box sx={udicciClasses.recordContextItemContainerOpen}>
            //             <Typography variant="subtitle2" component="span">
            //                 BSV Contract Details.
            //             </Typography>
            //             <IconButton size="small"
            //                         color="primary"
            //                         component="span"
            //                         onClick={(evt: any) => setShowTransactionDetails(true)}
            //                         classes={{ root: 'recordContextButton' }}
            //             >
            //                 <ShowMoreIcon classes={{ root: 'previewRecordContextButton' }} />
            //             </IconButton>
            //         </Box>
            //     );
            }
        }

        let recordContextElement: any = null;
        if (recordId > 0 && recordContext) {
            // let displayedRecordLevels: any = {};
            // let lastLevel: number = 0;
            // for (let [mediatorName, ds] of Object.entries<any>(allDatasets)) {
            //     if (ds.level > lastLevel) lastLevel = ds.level;
            // }
            // console.log('%c lastLevel: %O', 'color: red;', lastLevel);

            let displayLevels: any[] = [];
            if (displayState) {
                // console.log('%c UdicciContextView displayState: %O', 'color: blue;', displayState);
                for (let [displayLevel, ds] of Object.entries<any>(displayState)) {
                    // console.log('%c displayLevel: %O', 'color: blue;', displayLevel);
                    // console.log('%c ds: %O', 'color: blue;', ds);
                    // console.log('%c editLevel: %O', 'color: blue;', editLevel);

                    let isPastEditingLevel: boolean = false;
                    if (editLevel > 0 && ds.level > editLevel) {
                        isPastEditingLevel = true;
                    }
                    // console.log('%c isPastEditingLevel: %O', 'color: blue;', isPastEditingLevel);

                    if (isPastEditingLevel) continue;

                    let selectedIndex: number = (ds.selectedIndex ? ds.selectedIndex : 0);
                    // console.log('%c selectedIndex: %O', 'color: blue;', selectedIndex);
                    let mediator: string = (ds.mediator ? ds.mediator : '');
                    // console.log('%c mediator: %O', 'color: blue;', mediator);
                    let recordCount: number = (ds.recordCount ? ds.recordCount : 0);
                    // console.log('%c recordCount: %O', 'color: blue;', recordCount);
                    let groupRecords: any[] = (ds.groupRecords ? ds.groupRecords : []);
                    // console.log('%c groupRecords: %O', 'color: blue;', groupRecords);
                    let selectedRecord: any = (ds.selectedRecord ? ds.selectedRecord : null);
                    // console.log('%c selectedRecord: %O', 'color: blue;', selectedRecord);
                    // let levelStructure: any = (ds.structure ? ds.structure : null);
                    // console.log('%c levelStructure: %O', 'color: blue;', levelStructure);

                    let recPermissions: any = null;
                    // let recTitle: string = '';
                    if (selectedRecord && selectedIndex >= 0) {
                        recPermissions = selectedRecord.record.permissions;
                        // recTitle = selectedRecord.record.title;
                    }
                    // console.log('%c recPermissions: %O', 'color: blue;', recPermissions);
                    // console.log('%c recTitle: %O', 'color: blue;', recTitle);

                    let parentRecord: any = null;
                    if (ds.level > 1) {
                        let parentDs: any = (displayState[(ds.level - 1)] ? displayState[(ds.level - 1)] : null);
                        // console.log('%c parentDs: %O', 'color: blue;', parentDs);
                        parentRecord = (parentDs.selectedRecord ? parentDs.selectedRecord : null);
                    }
                    // console.log('%c parentRecord: %O', 'color: blue;', parentRecord);

                    let recMediatorNameSingular = (mediator ? Pluralize.singular(mediator) : mediator);
                    // console.log('recMediatorNameSingular: %O', recMediatorNameSingular);
                    // recCount++;

                    // let showingPreview: boolean = false;
                    let isSelectedRecord: boolean = false;
                    let isSelectedRecordParent: boolean = false;

                    let addNewRecordElement: any = null;
                    let canAddMediator: boolean = false; // default to false here please
                    if (ds.level === 1) {
                        canAddMediator = true;
                    } else if (ds.level > 1 && parentRecord && parentRecord.recordId > 0) {
                        canAddMediator = true;
                    }

                    if (canAddMediator && selectedRecord && recPermissions && recPermissions.CanAdd !== undefined) {
                        canAddMediator = recPermissions.CanAdd
                    }
                    if (mediator && canAddMediator) {
                        addNewRecordElement = (
                            <Button size="small"
                                    onClick={(evt: any) => addNewLevelRecord(ds.level)}
                                    variant="text"
                            >
                                <Typography variant="clickable" component="span">
                                    add {recMediatorNameSingular.toLocaleLowerCase()}
                                </Typography>
                                <Typography variant="clickable" component="span">
                                    .
                                </Typography>
                            </Button>
                        );
                    }

                    let parentRecordSelection: any = null;
                    // console.log('%c editLevel: %O', 'color: red;', editLevel);
                    if (editLevel > 0 && (ds.level === (editLevel - 1))) {
                        // console.log('%c isSelectedRecordParent: %O', 'color: purple;', isSelectedRecordParent);
                        // console.log('%c levelRecord: %O', 'color: purple;', levelRecord);
                        // console.log('%c selectedRecord: %O', 'color: purple;', selectedRecord);
                        // console.log('%c groupRecords: %O', 'color: purple;', groupRecords);

                        let childDs: any = (displayState[(ds.level + 1)] ? displayState[(ds.level + 1)] : null);
                        // console.log('%c childDs: %O', 'color: blue;', childDs);
                        let selectedChildRecord: any = (childDs && childDs.selectedRecord ? childDs.selectedRecord : selectedRecord);
                        // console.log('%c selectedChildRecord: %O', 'color: blue;', selectedChildRecord);

                        let parentFormProps: any = {};
                        Object.assign(parentFormProps, props);
                        parentFormProps.record = selectedChildRecord.record;
                        parentFormProps.parentData = groupRecords;
                        parentFormProps.selectedParentId = selectedRecord.recordId;
                        parentFormProps.selectedParentMediator = selectedRecord.mediator;
                        // parentFormProps.onChange = changeRecordValue;
                        // console.log('%c parentFormProps: %O', 'color: purple;', parentFormProps);

                        parentRecordSelection = ( <RecordParentSelection {...parentFormProps} /> );
                    }

                    let headerTitleElement: any = null;
                    let toggleRecordPreviewButton: any = null;
                    let contextRecordPreviewElement: any = null;
                    let viewRecordListElement: any = null;
                    let gotoNextElement: any = null;
                    let gotoPreviousElement: any = null;
                    let recordTitleElement: any = null;

                    if (editLevel <= 0) {
                        let editThisRecordElement: any = null;
                        if (recPermissions && recPermissions.CanEdit) {
                            if (!isSelectedRecord) {
                                editThisRecordElement = (
                                    <Button size="small"
                                            onClick={(evt: any) => editRecord(selectedRecord)}
                                            variant="text"
                                    >
                                        <Typography variant="clickable" component="span">
                                            edit
                                        </Typography>
                                        <Typography variant="clickable" component="span">
                                            .
                                        </Typography>
                                    </Button>
                                );
                            } else {
                                editThisRecordElement = (
                                    <Button size="small"
                                            onClick={(evt: any) => editRecord(null)}
                                            variant="text"
                                    >
                                        <Typography variant="clickable" component="span">
                                            close
                                        </Typography>
                                        <Typography variant="clickable" component="span">
                                            .
                                        </Typography>
                                    </Button>
                                );
                            }
                        }

                        if (!isSelectedRecordParent) {
                            headerTitleElement = (
                                <Box>
                                    <Typography variant="caption" component="span" noWrap>
                                        {((datalist && datalist === mediator) ? mediator : recMediatorNameSingular)}
                                    </Typography>
                                    <Typography variant="caption" component="span">
                                        .
                                    </Typography>
                                    {editThisRecordElement}
                                    {addNewRecordElement}
                                </Box>
                            );
                        }
                    }

                    let isRecordToEdit: boolean = false;
                    // console.log('%c editLevel: %O', 'color: brown;', editLevel);
                    if (selectedRecord && editLevel > 0) {
                        isRecordToEdit = ((editLevel === selectedRecord.level) ? true : false);
                    }
                    // console.log('%c isRecordToEdit: %O', 'color: green;', isRecordToEdit);
                    // console.log('%c selectedRecord: %O', 'color: green;', selectedRecord);
                    if (isRecordToEdit) {
                        // console.log('%c selectedRecord: %O', 'color: green;', selectedRecord);
                        let formProps: any = {};
                        Object.assign(formProps, props);
                        // formProps.fields = recordFields;
                        formProps.record = selectedRecord.record;
                        formProps.inline = true;
                        formProps.onClose = (evt: any) => editRecord(null);
                        // formProps.onChange = changeRecordValue;
                        // console.log('%c formProps: %O', 'color: red;', formProps);

                        contextRecordPreviewElement = (<UdicciForm {...formProps} />);
                    } else if (parentRecordSelection) {
                        contextRecordPreviewElement = parentRecordSelection;
                    } else if (selectedRecord) {
                        contextRecordPreviewElement = (
                            <RecordPreview record={selectedRecord.record}
                                        toggleFields={false}
                                        onToggleField={(fld: any) => fieldToggled(selectedRecord, fld)}
                            />
                        );
                    }

                    // if (parentRecordSelection) {
                    //     recordHeaderElement = (
                    //         <Fragment>
                    //             {toggleRecordPreviewButton}
                    //             {gotoNextElement}
                    //             {gotoPreviousElement}
                    //             {viewRecordListElement}
                    //             {recordTitleElement}
                    //         </Fragment>
                    //     );
                    // } else { 
                    // }

                    if (recordCount > 1) {
                        let gotoNextElementProps: any = {
                            color: ((selectedIndex < (recordCount - 1 )) ? 'primary' : 'default'),
                            disabled: ((selectedIndex >= (recordCount - 1 )) ? true : false),
                            sx: { float: 'right' },
                        };
                        let gotoNextIconProps: any = {
                            fontSize: "small"
                        };
                        let gotoPreviousElementProps: any = {
                            color: (selectedIndex > 0 ? 'primary' : 'default'),
                            disabled: (selectedIndex <= 0 ? true : false),
                            sx: { float: 'right' },
                        };
                        let gotoPreviousIconProps: any = {
                            fontSize: "small"
                        };

                        gotoNextElement = (
                            <IconButton size="small"
                                        component="span"
                                        onClick={(evt: any) => navigate(mediator, 'next')}
                                        {...gotoNextElementProps}
                            >
                                <NextIcon {...gotoNextIconProps} />
                            </IconButton>
                        );

                        gotoPreviousElement = (
                            <IconButton size="small"
                                        component="span"
                                        onClick={(evt: any) => navigate(mediator, 'previous')}
                                        {...gotoPreviousElementProps}
                            >
                                <PreviousIcon {...gotoPreviousIconProps} />
                            </IconButton>
                        );
                    } else {
                        gotoNextElement = (
                            <IconButton size="small"
                                        color="secondary"
                                        component="span"
                                        disabled={true}
                                        sx={{ float: 'right' }}
                            >
                                <NextIcon />
                            </IconButton>
                        );

                        gotoPreviousElement = (
                            <IconButton size="small"
                                        color="secondary"
                                        disabled={true}
                                        component="span"
                                        sx={{ float: 'right' }}
                            >
                                <PreviousIcon />
                            </IconButton>
                        );
                    }

                    let recordHeaderElement: any = null;
                    if (selectedRecord) {
                        recordHeaderElement = (
                            <Fragment>
                                {toggleRecordPreviewButton}
                                {gotoNextElement}
                                {gotoPreviousElement}
                                {viewRecordListElement}
                                {recordTitleElement}
                            </Fragment>
                        );
                    } else {
                        recordHeaderElement = recordTitleElement;
                    }

                    let boxPropsKey: string = 'record.context';
                    if (selectedRecord) {
                        boxPropsKey += '.' + selectedRecord.record.recordId;
                    } else {
                        boxPropsKey += '.' + displayLevel;
                    }
                    let boxProps: any = {
                        key: boxPropsKey,
                        sx: udicciClasses.recordContextItemContainer,
                    };
                    // boxProps.sx = udicciClasses.recordContextItemContainerOpen;

                    displayLevels.push(
                        <Box {...boxProps}>
                            {recordHeaderElement}
                            {headerTitleElement}
                            {contextRecordPreviewElement}
                        </Box>
                    );
                }
            }

            recordContextElement = (
                <Box>
                    {displayLevels}
                    {transactionForm}
                </Box>
            );

            // console.log('%c levelsNavigated: %O', 'color: green;', levelsNavigated);
        } else {
            let recTitle: string = (record && record.title ? record.title : 'record ...');
            recTitle = recTitle.trim();
            if (recTitle.endsWith('.')) recTitle = recTitle.substring(0, recTitle.length - 1);

            recordContextElement = (
                <Box>
                    <CircularProgress size={15} />
                    <Typography {...recTitleProps}>
                        Loading {recTitle}
                    </Typography>
                    <Typography variant="body2" component="span">
                        .
                    </Typography>
                </Box>
            );
        }

        return recordContextElement;
    } else {
        return (
            <Box>
                <CircularProgress size={15} />
                <Typography {...recTitleProps}>
                    Loading
                </Typography>
                <Typography variant="body2" component="span">
                    ...
                </Typography>
            </Box>
        );

    }
}

export default UdicciContextView;
