
import { find, values } from 'underscore';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

// import { udicciStyles } from 'src/theme/shared-styles';

import useUdicciRecord from 'src/hooks/useUdicciRecord';

export const SaveMenu: React.FC<any> = ({ record, engagementAction, onClick }) => {
    // console.log('%c SaveMenu engagementAction: %O', 'color: blue;', engagementAction);
    // const theme = useTheme();
    // const udicciClasses = udicciStyles(theme);

    // console.log('%c SaveMenu udicciRecordContext: %O', 'color: blue;', udicciRecordContext);
    let { udicciRecord, saveRecord } = useUdicciRecord(record);

    let udicciMediator: string = (udicciRecord.udicciMediator ? udicciRecord.udicciMediator : '');
    // console.log('%c SaveMenu udicciMediator: %O', 'color: maroon;', udicciMediator);
    let permissions: any = (udicciRecord.permissions ? udicciRecord.permissions : null);
    // console.log('%c SaveMenu permissions: %O', 'color: maroon;', permissions);

    let eaAccess = (engagementAction.access ? engagementAction.access : null);
    // console.log('%c eaAccess: %O', 'color: blue;', eaAccess);
    let eaMediators = (eaAccess && eaAccess.mediators ? eaAccess.mediators : []);
    // console.log('%c eaMediators: %O', 'color: blue;', eaMediators);

    const triggerEngagementAction = (ea: any) => {
        saveRecord();
    };

    let mediatorActions = find(values(eaMediators), function(m: any) {
        return m.name === udicciMediator;
    });
    // console.log('%c mediatorActions: %O', 'color: blue;', mediatorActions);
    let requiredPermissions = (mediatorActions && mediatorActions.requiredPermissions ? mediatorActions.requiredPermissions : null);
    // console.log('%c requiredPermissions: %O', 'color: blue;', requiredPermissions);

    let allowed = (mediatorActions !== undefined ? true : false);
    if (requiredPermissions && requiredPermissions.length > 0) {
        allowed = false;
        let fp = requiredPermissions[0];
        // console.log('%c fp: %O', 'color: blue;', fp);
        let permValue = permissions[fp.permission];
        // console.log('%c permValue: %O', 'color: blue;', permValue);
        if (fp.required && permValue === true) allowed = true;
        if (!fp.required) allowed = true;
    }
    // console.log('%c allowed: %O', 'color: blue;', allowed);

    let rval: any = null;
    if (allowed) {
        // console.log('%c ea: %O', 'color: blue;', ea);
        let enagementMenuText = '';
        if (engagementAction && engagementAction.label && engagementAction.label.text) {
            enagementMenuText = engagementAction.label.text;
        } else if (engagementAction && engagementAction.name) {
            enagementMenuText = engagementAction.name;
        } else {
            enagementMenuText = engagementAction.action;
        }

        rval = (
            <Button size="small"
                    onClick={(evt: any) => triggerEngagementAction(engagementAction)}
                    variant="text"
                    disabled={(record && record.isSaving ? true : false)}
            >
                {(record && record.isSaving) && (<CircularProgress size={14} />)}
                <Typography variant="body2" component="span">
                    {enagementMenuText}
                </Typography>
                <Typography variant="body2" component="span">
                    .
                </Typography>
            </Button>
        );
    }

    return rval;
};
