
import React, { useState } from 'react';

import {
    Box, Typography, Button, Icon, MenuItem, Select, TextField, FormControl, InputLabel,
    Checkbox, Accordion, AccordionDetails, AccordionSummary, CircularProgress
} from '@mui/material';

import { forEach, values, keys, find } from 'underscore';

import { useUdicciContext } from 'src/context/udicci-context'
import useUdicciHelpers, { UdicciPermissionsInitialized } from 'src/hooks/useUdicciHelpers';

import AssignRoleAccess from 'src/admin/social-solution-management/assign-role-access';
import EngagementActionsConfiguration from 'src/admin/social-solution-management/engagement-actions-configuration';
import SocialSolutionStructureManagement from 'src/admin/social-solution-management/social-solution-structure-management';
import DefaultLayoutSettings from 'src/admin/social-solution-management/default-layout-settings';

const SocialSolutionForm: React.FC<any> = (props: any) => {
    // console.log('%c SocialSolutionForm props: %O', 'color: red;', props);
    let { socialSolution, onChange, onClose } = props;
    // console.log('%c SocialSolutionForm socialSolution: %O', 'color: red;', socialSolution);

    const [expandedAccordion, setExpandedAccordion] = useState<string | false>(false);

    const udicciContext = useUdicciContext();
    // const udicciHelpers = useUdicciHelpers();

    let { udicci } = udicciContext.state;

    // var profileRoles = udicciHelpers.preloadMediatorData('Roles', udicci.socialSolutionUdicciIT);
    // console.log('%c SocialSolutionForm profileRoles: %O', 'color: maroon;', profileRoles);

    let { selectedProfile } = udicci;
    // console.log('%c SocialSolutionForm selectedProfile: %O', 'color: red;', selectedProfile);
    let profile: any = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);

    const getProfileSettings = () => {
        if (profile && !profile.jsonProfileSettingsJson && profile.ProfileSettingsJson) {
            try {
                profile.jsonProfileSettingsJson = JSON.parse(profile.ProfileSettingsJson);
            } catch (e: any) {
    
            }
        }
        let rval: any = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
        // console.log('%c SocialSolutionForm rval: %O', 'color: red;', rval);
        return rval;
    };

    let profileSettings: any = getProfileSettings();
    // console.log('%c SocialSolutionForm profileSettings: %O', 'color: red;', profileSettings);
    let pss: any = (profileSettings && profileSettings.socialSolutionSettings ? profileSettings.socialSolutionSettings : null);
    // console.log('%c SocialSolutionForm pss: %O', 'color: red;', pss);

    const [profileSolutionSettings, setProfileSolutionSettings] = useState<any>(pss);
    // console.log('%c SocialSolutionForm profileSolutionSettings: %O', 'color: red;', profileSolutionSettings);

    // let uid: string = udicciHelpers.generateUID();
    let isDirty: boolean = (socialSolution && socialSolution.isDirty ? true : false);
    let isSaving: boolean = (socialSolution && socialSolution.isSaving ? true : false);
    let ssData: any = (socialSolution && socialSolution.data ? socialSolution.data : null);
    let id: number = (socialSolution && socialSolution.recordId ? socialSolution.recordId : 0);
    let name: string = (ssData && ssData.Name ? ssData.Name : '');
    let purpose: string = (ssData && ssData.Purpose ? ssData.Purpose : '');
    let status: number = (ssData && ssData.Status !== undefined ? ssData.Status : 1);
    let showDuringSignup: boolean = (ssData && ssData.ShowDuringSignup ? true : false);
    let suggested: boolean = (ssData && ssData.Suggested ? true : false);

    let socialSolutionFeatures: any[] = (ssData && ssData.Features ? ssData.Features : null);
    // console.log('%c SocialSolutionForm socialSolutionFeatures: %O', 'color: red;', socialSolutionFeatures);
    let jsonSettingsJson: any = (ssData && ssData.jsonSettingsJson ? ssData.jsonSettingsJson : null);
    // console.log('%c SocialSolutionForm jsonSettingsJson: %O', 'color: red;', jsonSettingsJson);

    let ssop: any = (profileSolutionSettings && profileSolutionSettings[id] ? profileSolutionSettings[id] : null);
    // console.log('%c SocialSolutionForm solutionSettingsOnProfile(ssop): %O', 'color: red;', ssop);
    let roleOverrides: any = (ssop && ssop.RoleOverrides ? ssop.RoleOverrides : null);
    // console.log('%c SocialSolutionForm roleOverrides: %O', 'color: red;', roleOverrides);
    let defaultFeature = (ssop && ssop.defaultFeature ? ssop.defaultFeature : null);
    // console.log('%c defaultFeature: %O', 'color: purple;', defaultFeature);
    let defaultMediator = (ssop && ssop.defaultMediator ? ssop.defaultMediator : null);
    // console.log('%c defaultMediator: %O', 'color: purple;', defaultMediator);

    let primaryIcon: string = (jsonSettingsJson && jsonSettingsJson.PrimaryIcon ? jsonSettingsJson.PrimaryIcon : '');
    let menuIcon: string = (jsonSettingsJson && jsonSettingsJson.MenuIcon ? jsonSettingsJson.MenuIcon : '');
    let udicciOnly: boolean = (jsonSettingsJson && jsonSettingsJson.udicciOnly ? true : false);
    var solutionMediators: any = (jsonSettingsJson && jsonSettingsJson.mediators ? jsonSettingsJson.mediators : null);
    // console.log('%c solutionMediators: %O', 'color: purple;', solutionMediators);
    var solutionEngagementActions: any = (jsonSettingsJson && jsonSettingsJson.engagementActions ? jsonSettingsJson.engagementActions : null);
    // console.log('%c solutionEngagementActions: %O', 'color: purple;', solutionEngagementActions);

    let sortedMediators: any[] = [];
    if (solutionMediators) {
        if (keys(solutionMediators).length > 0) {
            sortedMediators = values(solutionMediators).sort((a: any, b: any) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;  // ascending
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;  // descending
                return 0 //default return value (no sorting)
            });
        }
    }
    // console.log('%c sortedMediators: %O', 'color: purple;', sortedMediators);

    const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedAccordion(isExpanded ? panel : false);
    };

    const onFieldValueChanged = (fieldName: string, evt: any) => {
        let trgt: any = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c handleChangeValue newValue: %O', 'color: maroon;', newValue);

        let ssData: any = null;
        if (socialSolution && socialSolution.data) {
            ssData = socialSolution.data;
        } else if (socialSolution) {
            ssData = socialSolution;
        } else {
            ssData = {};
        }

        if (fieldName.indexOf('.') > 0) {
            let settingName: string = '';
            let subSettingName: string = '';
            let triSettingName: string = '';

            let tsplit: any = fieldName.split('.');
            if (tsplit.length > 0) settingName = tsplit[0];
            if (tsplit.length > 1) subSettingName = tsplit[1];
            if (tsplit.length > 2) triSettingName = tsplit[2];
            // console.log('%c settingName: %O', 'color: maroon;', settingName);
            // console.log('%c subSettingName: %O', 'color: maroon;', subSettingName);
            // console.log('%c triSettingName: %O', 'color: maroon;', triSettingName);

            if (triSettingName) {
                if (!ssData[settingName]) ssData[settingName] = {};
                if (!ssData[settingName][subSettingName]) ssData[settingName][subSettingName] = {};
                ssData[settingName][subSettingName][triSettingName] = newValue;
            } else if (subSettingName) {
                if (!ssData[settingName]) ssData[settingName] = {};
                ssData[settingName][subSettingName] = newValue;
            } else {
                ssData[settingName] = newValue;
            }
        } else {
            ssData[fieldName] = newValue;
        }

        if (socialSolution.data) {
            socialSolution.data = ssData;
            let keys = socialSolution.keys;
            if (fieldName === keys.title) socialSolution.title = newValue;
            if (fieldName === keys.description) socialSolution.description = newValue;
        } else {
            socialSolution = ssData;
        }

        socialSolution.isDirty = true;
        if (onChange) onChange(socialSolution);
    };

    const closeForm = () => {
        if (onClose) onClose();
    };

    const saveSocialSolution = () => {
        if (socialSolution.data.jsonSettingsJson) {
            socialSolution.data.SettingsJson = JSON.stringify(socialSolution.data.jsonSettingsJson);
        } else {
            socialSolution.SettingsJson = JSON.stringify(socialSolution.jsonSettingsJson);
        }
        // console.log('%c SocialSolutionForm saveSocialSolution socialSolution: %O', 'color: blue;', socialSolution);

        let saveRecordSettings: any = { onSuccess: saveSocialSolutionSuccess };
        udicci.saveRecord(socialSolution, udicci.socialSolutionUdicciIT, saveRecordSettings);

        socialSolution.isSaving = true;
        if (onChange) onChange(socialSolution);
    };

    const saveSocialSolutionSuccess = (result: any = null, request: any = null) => {
        // console.log('%c saveSocialSolutionSuccess (result: %O, request: %O)', 'color: red;', result, request);
        socialSolution.isDirty = false;
        socialSolution.isSaving = false;
        if (onChange) onChange(socialSolution);
    }

    const updateSocialSolutionSetting = (fieldName: string, event: any) => {
        // console.log('%c fieldName: %O', 'color: purple;', fieldName);

        let trgt = event.target;
        // console.log('%c trgt: %O', 'color: purple;', trgt);
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c newValue: %O', 'color: purple;', newValue);

        let newVal: any = {};
        if (profileSolutionSettings) Object.assign(newVal, profileSolutionSettings);
        // console.log('%c SocialSolutionForm addNewRole newVal: %O', 'color: red;', newVal);
        let ssSettings: any = (newVal && newVal[socialSolution.recordId] ? newVal[socialSolution.recordId] : null);
        // console.log('%c SocialSolutionForm addNewRole ssSettings: %O', 'color: red;', ssSettings);
        let settings = (socialSolution && socialSolution.data.jsonSettingsJson ? socialSolution.data.jsonSettingsJson : {});
        // console.log('%c settings: %O', 'color: purple;', settings);

        // perform some old school cleanup
        if (settings.features) delete settings['features'];
        if (settings.mediators) {
            forEach(settings.mediators, (mediator: any, mediatorName: string) => {
                // console.log('%c mediator %s: %O', 'color: blue;', mediatorName, mediator);
                if (mediator.isDirty !== undefined) delete settings.mediators[mediatorName]['isDirty'];
            });
        }
        if (settings.interfaceKeys) delete settings['interfaceKeys'];
        // console.log('%c settings: %O', 'color: purple;', settings);

        let selectedFeature = null;
        if (fieldName === 'defaultFeature') {
            if (socialSolutionFeatures) {
                selectedFeature = socialSolutionFeatures.find((ftre: any) =>
                    ((ftre.UdicciRecordId.toString() === newValue.toString()) ? true : false)
                );
            }
        } else {
            if (socialSolutionFeatures) {
                let curDefaultFeature = (ssSettings.defaultFeature ? ssSettings.defaultFeature : null);
                // console.log('%c curDefaultFeature: %O', 'color: purple;', curDefaultFeature);

                if (curDefaultFeature) {
                    selectedFeature = socialSolutionFeatures.find((ftre: any) =>
                        ((ftre.UdicciRecordId.toString() === curDefaultFeature.id.toString()) ? true : false)
                    );
                }
            }
        }
        // console.log('%c selectedFeature: %O', 'color: purple;', selectedFeature);

        if (selectedFeature) {
            ssSettings.defaultFeature = {
                id: selectedFeature.UdicciRecordId,
                name: selectedFeature.Name
            };
        }

        let selectedMediator = null;
        if (fieldName === 'defaultMediator') {
            if (solutionMediators) {
                selectedMediator = values(solutionMediators).find((mdtr: any) =>
                    ((mdtr.id.toString() === newValue.toString()) ? true : false)
                );
            }
        }
        // console.log('%c selectedMediator: %O', 'color: purple;', selectedMediator);

        if (selectedMediator) {
            ssSettings.defaultMediator = {
                id: selectedMediator.id,
                name: (selectedMediator.label ? selectedMediator.label : selectedMediator.name)
            };
        }
        // console.log('%c selectedMediator: %O', 'color: purple;', selectedMediator);
        if (newVal && ssSettings) newVal[socialSolution.recordId] = ssSettings;

        // console.log('%c SocialSolutionForm updateSocialSolutionSetting newVal: %O', 'color: red;', newVal);
        setProfileSolutionSettings(newVal);

        profileSettings.socialSolutionSettings = newVal;

        profile.jsonProfileSettingsJson = profileSettings;
        profile.ProfileSettingsJson = JSON.stringify(profile.jsonProfileSettingsJson);

        if (selectedProfile) {
            selectedProfile.data = profile;
            selectedProfile.isDirty = true;

            udicci.setProfile(selectedProfile);
        }
    }

    const applyRoleChanges = (roleId: number, updates: any) => {
        // console.log('%c SocialSolutionForm applyRoleChanges socialSolution: %O', 'color: red;', socialSolution);
        // console.log('%c SocialSolutionForm applyRoleChanges profileSolutionSettings: %O', 'color: red;', profileSolutionSettings);
        // console.log('%c SocialSolutionForm applyRoleChanges updates: %O', 'color: red;', updates);
        // if (onClose) onClose();
        let newVal: any = {};
        if (profileSolutionSettings) Object.assign(newVal, profileSolutionSettings);

        let ssSettings: any = (newVal && newVal[socialSolution.recordId] ? newVal[socialSolution.recordId] : null);
        // console.log('%c SocialSolutionForm applyRoleChanges ssSettings: %O', 'color: red;', ssSettings);
        if (roleId) {
            if (!ssSettings) ssSettings = {};
            let sssro: any = (ssSettings.RoleOverrides ? ssSettings.RoleOverrides : {});
            // console.log('%c SocialSolutionForm applyRoleChanges ssSettingRoleOverrides: %O', 'color: red;', sssro);
            if (updates) {
                if (!sssro[roleId]) sssro[roleId] = {};
                Object.assign(sssro[roleId], updates);
            } else {
                sssro[roleId] = null;
            }
            ssSettings.RoleOverrides = sssro;
        }

        if (newVal && ssSettings) newVal[socialSolution.recordId] = ssSettings;
        // console.log('%c SocialSolutionForm applyRoleChanges newVal: %O', 'color: red;', newVal);
        setProfileSolutionSettings(newVal);

        profileSettings.socialSolutionSettings = newVal;

        profile.jsonProfileSettingsJson = profileSettings;
        profile.ProfileSettingsJson = JSON.stringify(profile.jsonProfileSettingsJson);

        if (selectedProfile) {
            selectedProfile.data = profile;
            selectedProfile.isDirty = true;

            udicci.setProfile(selectedProfile);
        }
    };

    const addNewRole = (roleId: number) => {
        // console.log('%c SocialSolutionForm addNewRole roleId: %O', 'color: red;', roleId);

        let newVal: any = {};
        if (profileSolutionSettings) Object.assign(newVal, profileSolutionSettings);
        // console.log('%c SocialSolutionForm addNewRole newVal: %O', 'color: red;', newVal);
        let ssSettings: any = (newVal && newVal[socialSolution.recordId] ? newVal[socialSolution.recordId] : null);
        // console.log('%c SocialSolutionForm addNewRole ssSettings: %O', 'color: red;', ssSettings);
        let sssro: any = (ssSettings && ssSettings.RoleOverrides ? ssSettings.RoleOverrides : null);
        // console.log('%c SocialSolutionForm addNewRole ssSettingRoleOverrides: %O', 'color: red;', sssro);
        let ssSettingRoleOvrRd: any = (roleId && sssro && sssro[roleId] ? sssro[roleId] : null);
        // console.log('%c SocialSolutionForm addNewRole ssSettingRoleOvrRd: %O', 'color: red;', ssSettingRoleOvrRd);

        if (!ssSettingRoleOvrRd) {
            if (!sssro) sssro = {};
            sssro[roleId] = {};
            Object.assign(sssro[roleId], UdicciPermissionsInitialized);
        }

        if (sssro) {
            if (!ssSettings) {
                ssSettings = {
                    public: {},
                    default: {},
                    RoleOverrides: sssro,
                    defaultFeature: null,
                    deafultMediator: null,
                };
                Object.assign(ssSettings.public, UdicciPermissionsInitialized);
                Object.assign(ssSettings.default, UdicciPermissionsInitialized);
            } else {
                ssSettings.RoleOverrides = sssro;
            }
        }
        if (newVal && ssSettings) newVal[socialSolution.recordId] = ssSettings;

        // console.log('%c SocialSolutionForm addNewRole newVal: %O', 'color: red;', newVal);
        setProfileSolutionSettings(newVal);
    };

    const removeRole = (roleId: number) => {
        // console.log('%c SocialSolutionForm removeRole roleId: %O', 'color: red;', roleId);

        let newVal: any = {};
        if (profileSolutionSettings) Object.assign(newVal, profileSolutionSettings);
        // console.log('%c SocialSolutionForm removeRole newVal: %O', 'color: red;', newVal);
        let ssSettings: any = (newVal && newVal[socialSolution.recordId] ? newVal[socialSolution.recordId] : null);
        // console.log('%c SocialSolutionForm removeRole ssSettings: %O', 'color: red;', ssSettings);
        let sssro: any = (ssSettings && ssSettings.RoleOverrides ? ssSettings.RoleOverrides : null);
        // console.log('%c SocialSolutionForm removeRole ssSettingRoleOverrides: %O', 'color: red;', sssro);
        let ssSettingRoleOvrRd: any = (roleId && sssro && sssro[roleId] ? sssro[roleId] : null);
        // console.log('%c SocialSolutionForm removeRole ssSettingRoleOvrRd: %O', 'color: red;', ssSettingRoleOvrRd);

        if (ssSettingRoleOvrRd) delete sssro[roleId];
        if (ssSettings && sssro) ssSettings.RoleOverrides = sssro;
        if (newVal && ssSettings) newVal[socialSolution.recordId] = ssSettings;

        // console.log('%c SocialSolutionForm removeRole newVal: %O', 'color: red;', newVal);
        setProfileSolutionSettings(newVal);

        profileSettings.socialSolutionSettings = newVal;

        profile.jsonProfileSettingsJson = profileSettings;
        profile.ProfileSettingsJson = JSON.stringify(profile.jsonProfileSettingsJson);

        if (selectedProfile) {
            selectedProfile.data = profile;
            selectedProfile.isDirty = true;

            udicci.setProfile(selectedProfile);
        }
    };

    const addNewRoleOverride = (roleId: number, mediatorName: string) => {
        // console.log('%c SocialSolutionForm addNewRoleOverride roleId: %O', 'color: red;', roleId);
        // console.log('%c SocialSolutionForm addNewRoleOverride mediatorName: %O', 'color: red;', mediatorName);

        let newVal: any = {};
        if (profileSolutionSettings) Object.assign(newVal, profileSolutionSettings);
        // console.log('%c SocialSolutionForm addNewRoleOverride newVal: %O', 'color: red;', newVal);
        let ssSettings: any = (newVal && newVal[socialSolution.recordId] ? newVal[socialSolution.recordId] : null);
        // console.log('%c SocialSolutionForm addNewRoleOverride ssSettings: %O', 'color: red;', ssSettings);
        let sssro: any = (ssSettings && ssSettings.RoleOverrides ? ssSettings.RoleOverrides : null);
        // console.log('%c SocialSolutionForm addNewRoleOverride ssSettingRoleOverrides: %O', 'color: red;', sssro);
        let ssSettingRoleOvrRd: any = (roleId && sssro && sssro[roleId] ? sssro[roleId] : null);
        // console.log('%c SocialSolutionForm addNewRoleOverride ssSettingRoleOvrRd: %O', 'color: red;', ssSettingRoleOvrRd);

        if (!ssSettingRoleOvrRd) {
            if (!sssro) sssro = {};
            sssro[roleId] = {};
            Object.assign(sssro[roleId], UdicciPermissionsInitialized);
            ssSettingRoleOvrRd = sssro[roleId];
        }

        if (ssSettingRoleOvrRd) {
            let overrides: any = (ssSettingRoleOvrRd.overrides ? ssSettingRoleOvrRd.overrides : {});
            let overrideCheck: any = (overrides[mediatorName] ? overrides[mediatorName] : null);
            if (!overrideCheck) {
                overrides[mediatorName] = {};
                Object.assign(overrides[mediatorName], UdicciPermissionsInitialized);
                ssSettingRoleOvrRd.overrides = overrides;
            }

            sssro[roleId] = ssSettingRoleOvrRd;

            if (!ssSettings) {
                ssSettings = {
                    public: {},
                    default: {},
                    RoleOverrides: sssro,
                    defaultFeature: null,
                    defaultMediator: null,
                };
                Object.assign(ssSettings.public, UdicciPermissionsInitialized);
                Object.assign(ssSettings.default, UdicciPermissionsInitialized);
            } else {
                ssSettings.RoleOverrides = sssro;
            }
        }
        if (newVal && ssSettings) newVal[socialSolution.recordId] = ssSettings;

        // console.log('%c SocialSolutionForm addNewRole newVal: %O', 'color: red;', newVal);
        setProfileSolutionSettings(newVal);
    };

    const removeRoleOverride = (roleId: number, mediatorName: string) => {
        // console.log('%c SocialSolutionForm removeRoleOverride roleId: %O', 'color: red;', roleId);
        // console.log('%c SocialSolutionForm removeRoleOverride mediatorName: %O', 'color: red;', mediatorName);

        let newVal: any = {};
        if (profileSolutionSettings) Object.assign(newVal, profileSolutionSettings);
        // console.log('%c SocialSolutionForm removeRoleOverride newVal: %O', 'color: red;', newVal);
        let ssSettings: any = (newVal && newVal[socialSolution.recordId] ? newVal[socialSolution.recordId] : null);
        // console.log('%c SocialSolutionForm removeRoleOverride ssSettings: %O', 'color: red;', ssSettings);
        let sssro: any = (ssSettings && ssSettings.RoleOverrides ? ssSettings.RoleOverrides : null);
        // console.log('%c SocialSolutionForm removeRoleOverride ssSettingRoleOverrides: %O', 'color: red;', sssro);
        let ssSettingRoleOvrRd: any = (roleId && sssro && sssro[roleId] ? sssro[roleId] : null);
        // console.log('%c SocialSolutionForm removeRoleOverride ssSettingRoleOvrRd: %O', 'color: red;', ssSettingRoleOvrRd);
        let roleOverrides: any = (ssSettingRoleOvrRd && ssSettingRoleOvrRd.overrides ? ssSettingRoleOvrRd.overrides : null);
        // console.log('%c SocialSolutionForm removeRoleOverride roleOverrides: %O', 'color: red;', roleOverrides);
        let overrideToRemove: any = (roleOverrides && roleOverrides[mediatorName] ? roleOverrides[mediatorName] : null);
        // console.log('%c SocialSolutionForm removeRoleOverride roleOverrides: %O', 'color: red;', roleOverrides);

        if (overrideToRemove) {
            delete roleOverrides[mediatorName];
            ssSettingRoleOvrRd.overrides = roleOverrides;
            sssro[roleId] = ssSettingRoleOvrRd;
        }

        if (ssSettings && sssro) ssSettings.RoleOverrides = sssro;
        if (newVal && ssSettings) newVal[socialSolution.recordId] = ssSettings;

        // console.log('%c SocialSolutionForm removeRoleOverride newVal: %O', 'color: red;', newVal);
        setProfileSolutionSettings(newVal);

        profileSettings.socialSolutionSettings = newVal;

        profile.jsonProfileSettingsJson = profileSettings;
        profile.ProfileSettingsJson = JSON.stringify(profile.jsonProfileSettingsJson);

        if (selectedProfile) {
            selectedProfile.data = profile;
            selectedProfile.isDirty = true;

            udicci.setProfile(selectedProfile);
        }
    };

    const applyEngagementActionChanges = () => {
        // console.log('%c *** APPLY ENGAGEMENT ACTION CHANGES NOT ENABLED *** SocialSolutionForm applyEngagementActionChanges socialSolution: %O', 'color: red;', socialSolution);
        if (onClose) onClose();
    };

    let nameElement: any = (
        <TextField fullWidth
                label={'Social Solution Name'}
                value={name}
                onChange={(evt: any) => onFieldValueChanged('Name', evt)}
                margin="normal"
                variant='outlined'
        />
    );

    let purposeElement: any = (
        <TextField fullWidth
                label={'Purpose'}
                value={purpose}
                multiline={true}
                minRows={2}
                maxRows={5}
                onChange={(evt: any) => onFieldValueChanged('Purpose', evt)}
                margin="normal"
                variant='outlined'
        />
    );

    let solutionStatusElement: any = (
        <Box sx={{ margin: '8px' }}>
            <FormControl fullWidth>
                <Typography variant="overFieldLabel">Status</Typography>
                <Select value={status}
                        id='select-list-paging-style'
                        onChange={(evt: any) => onFieldValueChanged('Status', evt)}
                >
                    <MenuItem value={0}>Disabled</MenuItem>
                    <MenuItem value={1}>Enabled</MenuItem>
                    <MenuItem value={2}>Development</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );

    let showDuringSignupElement: any = (
        <Box sx={{ margin: '8px' }}>
            <Typography variant="leftFieldLabel">Show to During Onboarding Setup</Typography>
            <Checkbox checked={showDuringSignup}
                        onChange={(evt: any) => onFieldValueChanged('ShowDuringSignup', evt)}
                        color="primary"
            />
        </Box>
    );

    let suggestedElement: any = (
        <Box sx={{ margin: '8px' }}>
            <Typography variant="leftFieldLabel">Shown in the Suggested list of Social Solutions</Typography>
            <Checkbox checked={suggested}
                        onChange={(evt: any) => onFieldValueChanged('Suggested', evt)}
                        color="primary"
            />
        </Box>
    );

    let udicciOnlyElement: any = (
        <Box sx={{ margin: '8px' }}>
            <Typography variant="leftFieldLabel">Udicci Only</Typography>
            <Checkbox checked={udicciOnly}
                      onChange={(evt: any) => onFieldValueChanged('jsonSettingsJson.udicciOnly', evt)}
                      color="primary"
            />
        </Box>
    );

    let primaryIconElement: any = (
        <Box sx={{ margin: '8px', display: 'flex' }}>
            <TextField label={'Primary Icon'}
                        value={primaryIcon}
                        onChange={(evt: any) => onFieldValueChanged('jsonSettingsJson.PrimaryIcon', evt)}
                        margin="normal"
                        variant='outlined'
            />
        </Box>
    );

    let menuIconElement: any = (
        <Box sx={{ margin: '8px', display: 'flex' }}>
            <TextField label={'Menu Icon'}
                        value={menuIcon}
                        onChange={(evt: any) => onFieldValueChanged('jsonSettingsJson.MenuIcon', evt)}
                        margin="normal"
                        variant='outlined'
            />
        </Box>
    );

    let socialSolutionSettingsForm: any = null;
    if (socialSolutionFeatures && status !== 0) {
        let defaultFeatureValue: number = (defaultFeature && defaultFeature.id ? defaultFeature.id : 0);
        // console.log('%c defaultFeatureValue: %O', 'color: purple;', defaultFeatureValue);
        let defaultFeatureName: string = (defaultFeature && defaultFeature.name ? defaultFeature.name : '');
        // console.log('%c defaultFeatureName: %O', 'color: purple;', defaultFeatureName);

        let selectedFeature: any = null;
        if (defaultFeatureValue) {
            if (socialSolutionFeatures) {
                selectedFeature = socialSolutionFeatures.find((ftre: any) =>
                    ((ftre.UdicciRecordId.toString() === defaultFeatureValue.toString()) ? true : false)
                );
            }
        }
        // console.log('%c selectedFeature: %O', 'color: purple;', selectedFeature);

        let defaultMediatorSelection: any = null;
        if (selectedFeature) {
            if (solutionMediators) {
                let defaultMediatorValue: number = (defaultMediator && defaultMediator.id ? defaultMediator.id : 0);
                // console.log('%c defaultMediatorValue: %O', 'color: purple;', defaultMediatorValue);

                defaultMediatorSelection = (
                    <Box sx={{ margin: '4px', paddingLeft: '4px', paddingRight: '4px' }}>
                        <Typography variant="overFieldLabel" component="div">Default Mediator</Typography>
                        <Typography variant="caption" component="div">
                            <em>Select the Mediator to show when the solution first loads ...</em>                    
                        </Typography>
                        <Select value={defaultMediatorValue} sx={{ minWidth: "250px" }}
                                id='select-social-solution-default-mediator'
                                onChange={(evt: any) => updateSocialSolutionSetting('defaultMediator', evt)}
                        >
                            {sortedMediators.map((mdtr: any) => {
                                let ftre: string = (mdtr.feature ? mdtr.feature : '');
                                // console.log('%c ftre: %O', 'color: red;', ftre);
                                let ftreList: any[] = (mdtr.features ? mdtr.features : []);
                                // console.log('%c ftreList: %O', 'color: red;', ftreList);
                                let isFeatureMediator: boolean = false;
                                if (ftre === defaultFeatureName) isFeatureMediator = true;
                                if (ftreList.length > 0) {
                                    let chk: any = find(ftreList, (f: any) => f.name === defaultFeatureName);
                                    if (chk) isFeatureMediator = true;
                                }
                                // console.log('%c isFeatureMediator: %O', 'color: red;', isFeatureMediator);
                                if (!isFeatureMediator) return true;

                                return (
                                    <MenuItem key={'select.item.feature.' + mdtr.id} value={mdtr.id}>
                                        {(mdtr.label ? mdtr.label : mdtr.name)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>
                );
            }
        }

        let sortedFeatures: any[] = [];
        if (socialSolutionFeatures.length > 0) {
            sortedFeatures = socialSolutionFeatures.sort((a,b) => {
                if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;  // ascending
                if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;  // descending
                return 0 //default return value (no sorting)
            });
        }

        socialSolutionSettingsForm = (
            <Box sx={{ display: 'flex', margin: '4px', marginBottom: '16px' }}>
                <Box sx={{ margin: '4px', paddingLeft: '4px', paddingRight: '4px' }}>
                    <Typography variant="overFieldLabel" component="div">Default Feature</Typography>
                    <Typography variant="caption" component="div">
                        <em>Select the Feature to show when the solution first loads ...</em>                    
                    </Typography>
                    <Select value={defaultFeatureValue} sx={{ minWidth: "250px" }}
                            id='select-social-solution-default-feature'
                            onChange={(evt: any) => updateSocialSolutionSetting('defaultFeature', evt)}
                    >
                        {sortedFeatures.map(feature => {
                            return (
                                <MenuItem key={'select.item.feature.' + feature.UdicciRecordId} value={feature.UdicciRecordId}>
                                    {feature.Name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>
                {defaultMediatorSelection}
            </Box>
        );
    }

    let saveDisabled: boolean = false;
    if (!isDirty || name.length < 2) saveDisabled = true;
    if (isSaving) saveDisabled = true;
    // console.log('%c SocialSolutionForm saveDisabled: %O', 'color: red;', saveDisabled);
    let waitOnSaveElement: any = null;
    if (isSaving) waitOnSaveElement = ( <CircularProgress size={14} /> );

    let socialSolutionElement: any = (
        <Box sx={{ padding: '8px' }}>
            {nameElement}
            {purposeElement}

            <Accordion expanded={expandedAccordion === 'solution.structure'} onChange={handleChangeAccordion('solution.structure')}>
                <AccordionSummary expandIcon={<Icon>expand_more</Icon>} aria-controls="solution.structure.content" id="solution.structure.header">
                    <Icon fontSize="small" sx={{ marginRight: '8px' }}>schema</Icon>
                    <Typography variant="clickableSubTitle2" sx={{ marginLeft: '8px' }}>Structure</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <SocialSolutionStructureManagement configuredMediators={solutionMediators} />
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expandedAccordion === 'solution.access'} onChange={handleChangeAccordion('solution.access')}>
                <AccordionSummary expandIcon={<Icon>expand_more</Icon>} aria-controls="solution.access.content" id="solution.access.header">
                    <Icon fontSize="small" sx={{ marginRight: '8px' }}>vpn_lock</Icon>
                    <Typography variant="clickableSubTitle2" sx={{ marginLeft: '8px' }}>Access</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AssignRoleAccess roleOverrides={roleOverrides}
                                      assignableMediators={solutionMediators}
                                      onApplyChanges={applyRoleChanges}
                                      onAddRole={addNewRole}
                                      onRemoveRole={removeRole}
                                      onAddOverride={addNewRoleOverride}
                                      onRemoveOverride={removeRoleOverride}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expandedAccordion === 'engagement.actions'} onChange={handleChangeAccordion('engagement.actions')}>
                <AccordionSummary expandIcon={<Icon>expand_more</Icon>} aria-controls="engagement.actions.content" id="engagement.actions.header">
                    <Icon fontSize="small" sx={{ marginRight: '8px' }}>api</Icon>
                    <Typography variant="clickableSubTitle2" sx={{ marginLeft: '8px' }}>Engagement</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <EngagementActionsConfiguration engagementActions={solutionEngagementActions}
                                                    onApplyChanges={applyEngagementActionChanges}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expandedAccordion === 'advanced.settings'} onChange={handleChangeAccordion('advanced.settings')}>
                <AccordionSummary expandIcon={<Icon>expand_more</Icon>} aria-controls="advanced.settings.content" id="advanced.settings.header">
                    <Icon fontSize="small" sx={{ marginRight: '8px' }}>tune</Icon>
                    <Typography variant="clickableSubTitle2" sx={{ marginLeft: '8px' }}>Advanced Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {socialSolutionSettingsForm}
                    {solutionStatusElement}
                    <Box sx={{ display: 'flex' }}>
                        {showDuringSignupElement}
                        {suggestedElement}
                        {udicciOnlyElement}
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        {primaryIconElement}
                        {menuIconElement}
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expandedAccordion === 'solution.default.layout'} onChange={handleChangeAccordion('solution.default.layout')}>
                <AccordionSummary expandIcon={<Icon>expand_more</Icon>} aria-controls="solution.default.layout.content" id="solution.default.layout.header">
                    <Icon fontSize="small" sx={{ marginRight: '8px' }}>newspaper</Icon>
                    <Typography variant="clickableSubTitle2" sx={{ marginLeft: '8px' }}>Default Layout Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <DefaultLayoutSettings />
                </AccordionDetails>
            </Accordion>

            <Box sx={{ padding: '8px', textAlign: 'right' }}>
                <Button variant="contained" color="info" size="small" disabled={isSaving} onClick={(evt: any) => closeForm()} sx={{ marginRight: '8px' }}>
                    Close
                </Button>
                <Button variant="contained" color="secondary" size="small" disabled={saveDisabled} onClick={(evt: any) => saveSocialSolution()}>
                    Save
                    {waitOnSaveElement}
                </Button>
            </Box>
        </Box>
    );
    return socialSolutionElement;
}

export default SocialSolutionForm;
