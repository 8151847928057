import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/HowToReg';

const UdicciOpenAISimpleStart = dynamic(() => import('src/components/udicci-it/udicci-openai-simple-start'));
const UdicciOpenAISimpleStartAdmin = dynamic(() => import('src/admin/udicci-openai-simple-start-admin'));

const udicciOpenAISimpleStartPlugin: UdicciPlugin = {
    Renderer: (data: any) => ( <UdicciOpenAISimpleStart {...data} /> ),
    id: 'udicciOpenAISimpleStart',
    title: 'Get .IT Done',
    description: "Udicci's Simple Start to using the OpenAI API Connectivity for building Data Results from a single request that provide purpose and efficiency.",
    version: 1,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <UdicciOpenAISimpleStartAdmin {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c udicciOpenAISimpleStartPlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c udicciOpenAISimpleStartPlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
     },
    category: UdicciPluginCategory.Development
};
export default udicciOpenAISimpleStartPlugin;