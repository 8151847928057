
import { Box, Typography, Icon, IconButton } from '@mui/material';

import { useUdicciContext } from 'src/context/udicci-context';

export const ConfirmLogoff: React.FC<any> = (props) => {
    let { onCancel, onConfirm } = props;

    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;

    let cancelDeleteIconButton: any = (
        <IconButton size="small" onClick={onCancel} sx={{ ml: 2 }}>
            <Icon fontSize="small">close</Icon>
        </IconButton>
    );
    let confirmDeleteIconButton: any = (
        <IconButton size="small" color="success" onClick={onConfirm}>
            <Icon fontSize="small">check</Icon>
        </IconButton>
    );

    let outerContainerSx: any = {
        border: '1px solid #ccc',
        borderTopLeftRadius: '8px',
        padding: '8px',
        borderRight: 0,
        marginRight: 0,
        marginLeft: '8px',
        textAlign: 'center',
        marginTop: '10px',
    };

    let confirmElement: any = null;
    if (udicci.userIsLoggedIn() && !udicci.userHasTimedOut) {
        confirmElement = (
            <Box>
                <Typography variant="caption">Are you sure you want to logoff?</Typography>
                {confirmDeleteIconButton}
                {cancelDeleteIconButton}
            </Box>
        );
    } else if (udicci.userHasTimedOut) {
        confirmElement = (
            <Box>
                <Typography variant="caption">Your session has timed out.  Please login.</Typography>
            </Box>
        );
    }

    return (<Box sx={outerContainerSx}>{confirmElement}</Box>);
};
