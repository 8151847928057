import React, { useState } from 'react';

import { Box, Typography, Button, ButtonGroup, Icon } from '@mui/material';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

import MediatorStructureForm from 'src/admin/social-solution-management/mediator-structure-form';
import MediatorDataImportForm from 'src/admin/social-solution-management/mediator-data-import';

const MediatorConfigurationForm: React.FC<any> = (props: any) => {
    // console.log('%c MediatorConfigForm props: %O', 'color: red;', props);

    const udicciHelpers = useUdicciHelpers();

    let { mediator, onClose } = props;

    const [currentDisplay, setDisplay] = useState<string>('structure');

    let mediatorConfigFormElement: any = null;
    if (currentDisplay === 'structure') {
        mediatorConfigFormElement = ( <MediatorStructureForm {...props} /> );
    }
    if (currentDisplay === 'import') {
        mediatorConfigFormElement = ( <MediatorDataImportForm {...props} /> );
    }

    return (
        <Box sx={{ margin: '8px', padding: '16px', background: 'rgba(255, 255, 255, 0.1)', border: '1px dashed #4C6FB1' }}>
            <Icon fontSize="small" sx={{ float: 'right', cursor: 'pointer' }} onClick={onClose}>close</Icon>
            <Typography variant="subtitle1" component="div">{mediator.name}</Typography>
            <ButtonGroup variant="text" color="primary">
                {/* <Button color={(currentDisplay === 'menu' ? "primary" : "info")} onClick={(evt: any) => setDisplay('menu')}>Menu</Button> */}
                <Button color={(currentDisplay === 'structure' ? "primary" : "info")} onClick={(evt: any) => setDisplay('structure')}>Structure</Button>
                <Button color={(currentDisplay === 'import' ? "primary" : "info")} onClick={(evt: any) => setDisplay('import')}>Import</Button>
            </ButtonGroup>
            {mediatorConfigFormElement}
        </Box>
    );
}

export default MediatorConfigurationForm;
