
import { Theme } from '@mui/material/styles';

import { grey, deepOrange, green, red, lightGreen, orange } from '@mui/material/colors';

export const udicciStyles = (theme: Theme) => {
    return {
        moreButton: {
            marginLeft: 'auto',
            maxHeight: '21px',
            borderRadius: theme.spacing(2),
            textTransform: 'none'
        },
        engageButton: {
            maxHeight: '21px',
            borderRadius: theme.spacing(2),
            background: '#132274'
        },
        engageButtonLoggedIn: {
            maxHeight: '21px',
            borderRadius: theme.spacing(2),
            background: 'rgb(222 113 5 / 77%) !important',
            color: 'rgb(118 255 3 / 90%)',
        },
        loginButton: {
            float: 'right',
            // maxHeight: '21px',
        },
        featureButton: {
            textTransform: 'none'
        },
        userAvatarButton: {
            minWidth: theme.spacing(1),
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0) + ' !important',
            textTransform: 'none',
        },
        userAvatarImage: {
            objectFit: 'scale-down',
        },
        userAvatar: {
            height: '19px',
        },
        avatarButton: {
            // marginTop: theme.spacing(1),
            padding: theme.spacing(0),
        },
        avatarButtonMin: {
            // marginTop: theme.spacing(1),
            padding: theme.spacing(0),
            float: 'left',
            width: '32px',
        },
        avatar: {
            // border: '1px solid gray',
            width: '90px',
            height: '90px',
        },
        avatarMin: {
            // border: '1px solid gray',
            maxWidth: '24px',
            width: 'unset',
            maxHeight: '30px',
            height: 'unset',
        },
        profileAvatar: {
            // border: '1px solid gray',
            width: '100%',
            // height: '90px',
            // height: 'auto', 
            // width: 'inherit',
        },
        profileAvatarMin: {
            // border: '1px solid gray',
            maxWidth: '100px',
            width: 'unset',
            // maxHeight: '30px',
            height: 'unset',
        },
        avatarImage: {
            // maxWidth: '70px',
            // maxHeight: '70px',
            // width: 'unset',
            // height: 'unset',
            objectFit: 'scale-down',
        },
        profileMenuButton: {
            // float: 'right',
            backgroundColor: 'rgb(222 113 5 / 77%) !important',
            maxHeight: '20px',
            maxWidth: '20px',
        },
        profileMenuButtonIcon: {
            color: 'rgb(118 255 3 / 90%)',
        },
        displayName: {
            borderBottom: '2px solid #1cb600',
        },
        displayNameOnline: {
            borderBottom: '2px solid #d0780c',
        },
        hiddenTextarea: {
            position: 'absolute',
            left: '-9999px'
        },
        embeddedContent: {
            // margin: theme.spacing(2),
            margin: theme.spacing(0.5),
        },
        returnToHomeIcon: {
            color: orange[700],
            fontSize: '0.7em',
            marginLeft: '-12px',
            width: '17px !important',
            // transform: theme.rotateY(180deg),
        },
        headerDisplayName: {
            // color: 'rgb(255 255 255 / 77%)',
            // color: 'rgb(118 255 3 / 77%)',
            color: theme.palette.primary.main,
            fontSize: '0.7em',
        },
        headerRoleName: {
            // color: 'rgb(118 255 3 / 77%)',
            // color: orange[700],
            color: theme.palette.secondary.main,
            fontSize: '0.7em',
        },
        socialIconLeftImage: {
            maxWidth: 50,
            maxHeight: 30,
            marginRight: theme.spacing(1),
            width: 'auto',
            height: 'auto',
            [theme.breakpoints.up('md')]: {
                maxWidth: 77,
                maxHeight: 40,
            },
        },
        socialIconLeft: {
            display: 'contents',
            margin: theme.spacing(0.5),
            marginRight: theme.spacing(1),
            borderRadius: 0,
            float: 'left',
        },
        ssButtonGroupRoot: {
            marginRight: '3px',
            maxHeight: '15px',
            [theme.breakpoints.up('md')]: {
                marginRight: theme.spacing(1),
                maxHeight: '21px',
            },
        },
        ssButtonArrowRoot: {
            minWidth: '21px',
            maxHeight: '21px',
            padding: '0',
            background: '#d0780c',
        },
        ssButtonRoot: {
            maxHeight: '18px',
            background: '#fff',
            textTransform: 'none',
            [theme.breakpoints.up('md')]: {
                maxHeight: '21px',
            },
        },
        ssButtonLabel: {
            fontSize: '0.5em',
            [theme.breakpoints.up('md')]: {
                fontSize: '0.9em'
            },
        },
        buttonArea: {
            padding: theme.spacing(1),
            paddingRight: theme.spacing(3),
            marginRight: 'auto',
            textAlign: 'right'
        },
        listItem: {
            padding: theme.spacing(1),
            margin: theme.spacing(1),
        },
        settingsIconButton: {
            float: 'right',
            padding: '14px'
            // marginLeft: theme.spacing(2),
            // cursor: 'pointer'
        },
        formHeaderButtonArea: {
            margin: theme.spacing(1),
            flexGrow: 1,
            display: 'flow-root',
        },
        formHeaderButtonRight: {
            float: 'right',
            marginRight: theme.spacing(1),
            // cursor: 'pointer'
        },
        textButton: {
            marginRight: theme.spacing(1),
        },
        recordContextButton: {
            float: 'right',
        },
        recordListItemContainer: {
            flexGrow: 1,
            overflowY: 'auto',
            overflowX: 'visible',
            borderRadius: theme.spacing(1),
            marginBottom: theme.spacing(0.5),
            padding: theme.spacing(0.5),
        },
        recordListItemButton: {
            marginBottom: theme.spacing(1),
        },
        previewRecordContextButton: {
            color: deepOrange[500],
            backgroundColor: 'none',
            maxHeight: theme.spacing(2),
            maxWidth: theme.spacing(2),
            // margin: theme.spacing(1),
            marginLeft: theme.spacing(1),
            objectFit: 'scale-down',
            float: 'right',
        },
        previewRecordContextButtonDisabled: {
            backgroundColor: 'none',
            maxHeight: theme.spacing(2),
            maxWidth: theme.spacing(2),
            // margin: theme.spacing(1),
            marginLeft: theme.spacing(1),
            objectFit: 'scale-down',
            float: 'right',
        },
        recordContextItemContainer: {
            padding: theme.spacing(1),
            margin: theme.spacing(1),
            display: 'flow-root',
            background: 'rgba(255, 255, 255, 0.1)'
        },
        recordContextItemContainerOpen: {
            marginTop: theme.spacing(0.5),
            padding: theme.spacing(0.5),
            paddingLeft: theme.spacing(1),
            background: '#fff',
            border: '0.7px dashed #132274',
            borderRadius: '8px',
        },
        attachmentFormContainer: {
            marginTop: theme.spacing(1),
            background: 'rgba(255, 255, 255, 0.1)'
        },
        attachmentFormContentContainer: {
            padding: theme.spacing(1),
        },
        attachmentFormButtonContainer: {
            margin: theme.spacing(1),
        },
        perspectiveCommentRoot: {
            marginLeft: theme.spacing(3),
            display: 'flex'
        },
        perspectiveComment: {
            padding: theme.spacing(0.5),
            fontSize: '0.7em',
            display: 'flex'
        },
        perspectiveNarrativeRoot: {
            marginTop: theme.spacing(1),
            display: 'flex'
        },
        perspectiveNarrative: {
            padding: theme.spacing(0.5),
            fontSize: '0.8em',
            display: 'flex',
            // backgroundColor: 'white'
        },
        settingsIcon: {
            // marginTop: theme.spacing(1),
            textAlign: 'right',
            width: 50,
            minWidth: 50,
        },
        formContent: {
            // margin: theme.spacing(1),
            padding: theme.spacing(0.5),
        },
        previewCardContent: {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
            textAlign: 'center',
            cursor: 'pointer'
        },
        blockContent: {
            flexGrow: 1,
            margin: theme.spacing(0.5),
            padding: theme.spacing(0.5),
            border: '1px solid #ccc'
        },
        blockContentAllergies: {
            flexGrow: 1,
            margin: theme.spacing(0.5),
            padding: theme.spacing(0.5),
            border: '1px solid red',
            backgroundColor: '#eee',
        },
        headerButton: {
            float: 'right',
        },
        headerButtonIcon: {
            maxHeight: '17px',
        },
        footerButton: {
            //float: 'left',
        },
        footerButtonIcon: {
            maxHeight: '17px',
        },
        paper: {
            padding: theme.spacing(1),
            // textAlign: 'center',
            // color: theme.palette.text.secondary,
            // whiteSpace: 'nowrap',
            marginBottom: theme.spacing(1),
        },
        attachmentThumbContainer: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(0.2),
            marginRight: theme.spacing(0.2)
        },
        attachmentThumb: {
            maxHeight: '50px',
            // maxWidth: '100px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        attachmentFullScreen: {
            maxHeight: '400px',
            // maxWidth: '100px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        creatorSocialIconRoot: {
            marginRight: theme.spacing(0.2)
        },
        creatorSocialIcon: {
            width: 17,
            height: 17
        },
        narrative: {
            fontSize: '0.7em',
        },
        newMediaBitRoot: {
            marginBottom: theme.spacing(1),
            display: 'flex'
        },
        newMediaBit: {
            padding: theme.spacing(0.5),
            fontSize: '0.9em',
            display: 'flex'
        },
        storiesContainer: {
            marginTop: theme.spacing(0.5),
            padding: theme.spacing(0.5),
            maxHeight: '450px',
            overflowY: 'auto'
        },
        creatorDisplayName: {
            fontSize: '0.7em',
            fontWeight: 'bold'
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        acceptingSocialIcon: {
            marginRight: theme.spacing(0.5),
            float: 'left',
            width: 17,
            height: 17
        },
        sectionContent: {
            margin: theme.spacing(0.5),
            padding: theme.spacing(0.5),
        },
        pageBreakContainer: {
            display: 'block',
            pageBreakAfter: 'always'
        },
        pageBreakParentContainer: {
            display: 'block',
            // pageBreakBefore: 'always'
        },
        headerContent: {
            flexGrow: 1,
            margin: theme.spacing(0.5),
            marginTop: 8,
            padding: theme.spacing(0.5),
            borderTop: '3px solid #eee',
            borderBottom: '1px solid red',
            pageBreakBefore: 'always',
        },
        perspectiveFormContainer: {
            marginTop: theme.spacing(0.5),
            padding: theme.spacing(0.5),
            background: 'none'
        },
        perspectiveListContainer: {
            margin: theme.spacing(1),
            marginTop: theme.spacing(0),
        },
        perspectiveNewPerspectiveContainer: {
            padding: theme.spacing(1),
            background: 'none'
        },
        perspectiveNewPerspectiveContainerOpen: {
            padding: theme.spacing(1),
            marginBottom: theme.spacing(2),
            background: '#fff'
        },
        perspectiveItemContainer: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
            background: 'rgb(255 255 255 / 10%)',
            display: 'flow-root'
        },
        perspectiveButtonsContainer: {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
        },
        perspectiveButton: {
            marginRight: theme.spacing(1),
        },
        perspectiveCommentRootInline: {
            // marginLeft: theme.spacing(3),
            display: 'flex'
        },
        attachmentItemContainer: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
            background: 'rgb(255 255 255 / 10%)',
            display: 'flow-root'
        },
        attachmentButtonsContainer: {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
        },
        menuContainer: {
            marginRight: theme.spacing(0.5),
            marginLeft: theme.spacing(0.5),
            float: 'right'
        },
        menuListIcon: {
            // color: theme.palette.getContrastText(deepOrange[500]),
            // backgroundColor: deepOrange[500],
            color: deepOrange[500],
            backgroundColor: '#fff',
            maxHeight: theme.spacing(2),
            maxWidth: theme.spacing(2),
            padding: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        inlineMenuListIcon: {
            // color: theme.palette.getContrastText(deepOrange[500]),
            // backgroundColor: deepOrange[500],
            // color: deepOrange[500],
            // backgroundColor: '#fff',
            padding: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            width: 20,
            height: 20,
        },
        matchingFootPrint: {
            marginTop: theme.spacing(1),
            color: green[300]
        },
        mismatchedFootPrint: {
            marginTop: theme.spacing(1),
            color: red[300]
        },
        formMenu: {
            // marginRight: theme.spacing(1),
            float: 'right'
        },
        titleContent: {
            marginLeft: theme.spacing(1),
        },
        inlineContentArea: {
            // padding: theme.spacing(2),
            paddingBottom: theme.spacing(0),
            paddingTop: "0px !important",
        },
        buttonContent: {
            padding: theme.spacing(3),
            paddingTop: 0,
            marginBottom: theme.spacing(3),
            // textAlign: 'right',
            display: 'flex',
        },
        button: {
            margin: theme.spacing(2),
        },
        breadcrumbContainer: {
            display: 'flow-root',
        },
        mainBreadcrumb: {
            fontSize: '1.2em',
            [theme.breakpoints.up('md')]: {
                fontSize: '1.3em',
            },
        },
        footerArea: {
            flexGrow: 1,
            padding: theme.spacing(1),
        },
        rightButton: {
            margin: theme.spacing(0),
            marginRight: theme.spacing(0.5),
            float: 'right',
            maxHeight: '20px',
            // [theme.breakpoints.up('md')]: {
            //     margin: theme.spacing(1),
            // },
        },
        iconButtonImage: {
            maxHeight: '15px',
            // [theme.breakpoints.up('md')]: {
            //     margin: theme.spacing(1),
            // },
        },
        table: {
            margin: theme.spacing(1),
            float: 'right'
        },
        menuList: {
            float: 'left',
            marginTop: 0,
            paddingTop: 0,
            marginRight: '8px',
            // maxWidth: '100px',
            height: '100%'
        },
        menuIcon: {
            minWidth: '32px',
            marginRight: '8px'
        },
        logoImage: {
            float: 'left',
            maxHeight: '50px',
            margin: '4px',
            marginRight: '16px',
        },
        headerText: {
            // float: 'left',
            margin: '8px',
            marginLeft: '28px',
            // clear: 'right'
        },
        subheaderText: {
            // float: 'left',
            margin: '8px',
            marginLeft: '28px',
        },
        agreementsContainer: {
            // marginTop: theme.spacing(0.5),
            // padding: theme.spacing(0.5),
            // maxHeight: '450px',
            // overflowY: 'auto',
            background: 'none',
        },
        agreementItem: {
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(1),
            padding: theme.spacing(0.5),
            border: '0.7px dashed #132274',
        },
        profileSocialIcon: {
            objectFit: 'scale-down',
        },
        subContentArea: {
            clear: 'both',
            flexGrow: 1,
            paddingLeft: theme.spacing(1),
        },
        switchAccountButton: {
            margin: theme.spacing(0.5),
            float: 'left',
            width: 15,
            height: 15
        },
        switchAccountButtonIcon: {
            width: '15px !important',
            height: '15px !important',
        },
        relationshipsName: {
            color: '#f57c00',
            fontSize: '0.9em',
        },
        userRelationshipsName: {
            color: '#f57c00',
            textDecorationColor: 'rgb(118 255 3 / 77%)',
            textDecoration: 'underline',
            fontSize: '0.9em',
        },
        relationshipsTitle: {
            // color: 'rgb(118 255 3 / 77%)',
            // color: orange[700],
            fontSize: '0.7em',
        },
        navigateButton: {
            margin: theme.spacing(0.5),
            float: 'right',
            width: 15,
            height: 15
        },
        navigateButtonIcon: {
            width: '15px !important',
            height: '15px !important',
        },
        assignRoleContainer: {
            flexGrow: 1,
            background: '#eee',
            padding: theme.spacing(0.5),
        },
        assignRoleButtonContainer: {
            flexGrow: 1,
            padding: theme.spacing(0.5),
        },
        socialIcon: {
            borderRadius: 0,
            width: 'auto',
            height: 'auto',
            maxWidth: 50,
            maxHeight: 30,
            display: 'block',
            overflow: 'visible',
            [theme.breakpoints.up('md')]: {
                maxWidth: 77,
                maxHeight: 40,
            },
        },
        socialIconImage: {
            maxWidth: 50,
            maxHeight: 30,
            width: 'auto',
            height: 'auto',
            [theme.breakpoints.up('md')]: {
                maxWidth: 77,
                maxHeight: 40,
            }
        },
        errorContentArea: {
            flexGrow: 1,
            padding: theme.spacing(1),
            border: '2px solid #c73d3d',
            background: '#f3f3f3',
            borderRadius: '8px'
            // width: '100%',
        },
        closeIconButton: {
            float: 'right',
            // marginLeft: theme.spacing(2),
            // cursor: 'pointer'
        },
        cellWithInlineForm: {
            padding: theme.spacing(1),
        },
        cellWithoutPad: {
            padding: theme.spacing(0),
        },
        inlineFormPaper: {
            padding: theme.spacing(2),
            background: 'rgba(0, 255, 0,0.07)'
        },
        inlineContentPaper: {
            padding: theme.spacing(2),
            background: 'rgba(221, 221, 221,0.7)'
        },
        ysquare: {
            // color: theme.palette.getContrastText(green[900]),
            backgroundColor: green[900],
            margin: theme.spacing(1),
            cursor: 'pointer',
        },
        nsquare: {
            // color: theme.palette.getContrastText(red[900]),
            backgroundColor: red[900],
            margin: theme.spacing(1),
            cursor: 'pointer',
        },
        disabledSquare: {
            // color: theme.palette.getContrastText(grey[300]),
            backgroundColor: grey[300],
            margin: theme.spacing(1),
        },
        textField300: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(1),
            width: 300,
        },
        tableRoot: {
            // display: 'grid'
        },
        tabsRoot: {
            float: 'left',
            // marginLeft: theme.spacing(2),
            // cursor: 'pointer'
        },
        headerRoot: {
            float: 'left',
            padding: theme.spacing(1),
            // cursor: 'pointer'
        },
        backIconButton: {
            float: 'left',
            marginRight: theme.spacing(1),
            // cursor: 'pointer'
        },
        addIconButton: {
            float: 'right',
            padding: '14px'
            // marginLeft: theme.spacing(2),
            // cursor: 'pointer'
        },
        securityIconButton: {
            float: 'right',
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(1),
            // cursor: 'pointer'
        },
        dialogPaper: {
            overflowY: 'visible',
        },
        dialogTitleRoot: {
            overflow: 'visible',
            padding: theme.spacing(0),
            marginBottom: '20px'
        },
        dialogContentRoot: {
            overflow: 'auto',
            padding: theme.spacing(0),
            paddingBottom: theme.spacing(1),
            marginTop: "-20px",
        },
        longTextToggleIconButton: {
            maxWidth: theme.spacing(2),
            marginLeft: theme.spacing(0.5),
            float: 'right',
        },
        longTextToggleIcon: {
            maxHeight: theme.spacing(2),
            maxWidth: theme.spacing(2),
        },
        recordActionIconButton: {
            // color: theme.palette.getContrastText(deepOrange[500]),
            // backgroundColor: deepOrange[500],
            // color: deepOrange[500],
            maxHeight: theme.spacing(2),
            maxWidth: theme.spacing(2),
            // float: 'left',
            // padding: theme.spacing(1),
            margin: theme.spacing(0.5),
            marginLeft: theme.spacing(1),
            // marginTop: theme.spacing(0),
        },
        createdByTitle: {
            fontSize: '0.6em',
            [theme.breakpoints.up('md')]: {
                fontSize: '1.0em',
            },
        },
        createdDate: {
            fontSize: '0.5em',
            [theme.breakpoints.up('md')]: {
                fontSize: '0.9em',
            },
        },
        selectedRecordContainer: {
            borderRadius: '8px',
            border: '0.7px dashed #132274',
            backgroundColor: '#fff',
            flexGrow: 1,
            padding: theme.spacing(1),
        },
        recordSubContentContainer: {
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            border: '1px solid #3f51b5',
            borderTop: 'none',
            flexGrow: 1,
        },
        recordSecondaryContentContainer: {
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            border: '1px solid #3f51b5',
            flexGrow: 1,
        },
        recordSubContentIconsContainer: {
            marginRight: theme.spacing(2),
            textAlign: 'right',
        },
        recordListItem: {
            // margin: theme.spacing(0.5),
            padding: theme.spacing(1),
            border: '0.7px dashed #132274',
            borderRadius: theme.spacing(1),
        },
        recordListItemDivider: {
            clear: 'both',
            background: 'rgb(211 211 211 / 25%)',
        },
        engagementAvatarContainer: {
            // color: theme.palette.getContrastText(deepOrange[500]),
            // backgroundColor: deepOrange[500],
            // color: deepOrange[500],
            // backgroundColor: 'none',
            // maxHeight: theme.spacing(2),
            // minHeight: theme.spacing(4),
            // minWidth: theme.spacing(4),
            // padding: theme.spacing(1),
            // marginRight: theme.spacing(1)
            float: 'left',
        },
        engagementAvatar: {
            // color: theme.palette.getContrastText(deepOrange[500]),
            // backgroundColor: deepOrange[500],
            color: deepOrange[500],
            backgroundColor: 'none',
            maxHeight: theme.spacing(3),
            maxWidth: theme.spacing(3),
            float: 'left',
            // padding: theme.spacing(1),
            margin: theme.spacing(1),
            marginTop: theme.spacing(0),
            objectFit: 'scale-down',
        },
        itemButtonsContainer: {
            top: theme.spacing(3)
        },
        itemTitleContainer: {
            marginRight: theme.spacing(4),
        },
        contentElement: {
            marginRight: theme.spacing(0.5),
        },
        toolbar: theme.mixins.toolbar,
        portalLayout: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflowY: 'auto',
        },
        portalHeader: {
            backgroundColor: 'rgb(241 241 241 / 90%)',
            height: '100%',
        },
        portalToolbarRoot: {
            display: 'flow-root !important',
            minHeight: '24px !important',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
        portalToolbarContent: {
            flexGrow: 1,
            padding: theme.spacing(0.5),
        },
        menuPopperRoot: {
            zIndex: 9999,
        },
        menuRoot: {
            // background: 'rgb(19 34 116 / 10%)',
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
        },
        menuLink: {
            cursor: 'pointer',
            fontSize: '0.9em',
            minHeight: '32px',
            marginBottom: theme.spacing(0.5),
        },
        menuLinkIcon: {
            marginRight: theme.spacing(1),
            width: '15px !important',
            height: '15px !important',
        },
        fieldContainer: {
            border: 0,
            background: '#fff',
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
        },
        fieldContainerToggledOn: {
            borderLeft: '3px solid ' + orange[700],
            background: '#fff',
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
            cursor: 'pointer',
        },
        fieldContainerToggledOff: {
            border: 0,
            background: '#eee',
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
            cursor: 'pointer',
        },
        setValueTextArea: {
            margin: theme.spacing(0.5),
        },
        setValueTextAreaInput: {
            margin: 0,
            padding: 0,
            width: 50,
            textAlign: 'center',
        },
        fieldArea: {
            flexGrow: 1,
            margin: theme.spacing(0.5),
            padding: theme.spacing(0),
            paddingTop: theme.spacing(1),
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(1),
            },
        },
        textFieldContainer: {
            flexGrow: 1,
            margin: theme.spacing(0.5),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        textField: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
            marginTop: theme.spacing(0),
            width: '100%'
        },
        root: {
            display: 'flex',
        },
        grow: {
            flexGrow: 1,
        },
        growHeight: {
            flexGrow: 1,
            height: '100%',
        },
        main: {
            flexGrow: 1,
            padding: theme.spacing(0),
            height: '100%',
        },
        mainArea: {
            flexGrow: 1,
            width: '100%',
            padding: theme.spacing(1),
            pageBreakInside: 'auto',
        },
        headerContainer: {
            backgroundColor: 'rgb(19 34 116 / 70%) !important'
        },
        headerToolbarRoot: {
            display: 'flow-root !important',
            minHeight: '34px !important',
        },
        headerSpacerToolbar: {
            display: 'flow-root !important',
            minHeight: '32px !important',
        },
        headerArea: {
            flexGrow: 1,
            padding: theme.spacing(0.5),
        },
        contentArea: {
            flexGrow: 1,
            padding: theme.spacing(1),
            paddingBottom: theme.spacing(3),
            // overflowY: 'auto',
            // overflowX: 'visible',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(1),
            margin: 'auto',
        },
        generalArea: {
            flexGrow: 1,
            background: '#fff',
        },
        buttonContainer: {
            flexGrow: 1,
            padding: theme.spacing(1),
        },
        unhighlightedArea: {
            flexGrow: 1,
            background: '#eee',
            border: '1px solid #000',
        },
        highlightedArea: {
            flexGrow: 1,
            background: '#eee',
            border: '1px solid ' + orange[900],
        },
        highlightedWithConfirmedTransactionArea: {
            flexGrow: 1,
            background: '#eee',
            border: '1px solid ' + lightGreen['A400'],
        },
        setValueArea: {
            padding: theme.spacing(3),
            flexGrow: 1,
            background: '#fff',
        },
        generalContainer: {
            padding: theme.spacing(1),
        },
        generalContainerBackground: {
            // background: '#fff',
            borderRadius: '8px',
        },
        containedCardContainer: {
            border: '0.7px dashed #132274',
            borderRadius: theme.spacing(1),
            margin: theme.spacing(1),
            // padding: theme.spacing(0.5),
        },
        formCardContainer: {
            padding: theme.spacing(0.5),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            // background: 'rgb(255 255 255 / 90%)',
            borderRadius: theme.spacing(1),
        },
        socialSolutionsMenuContainer: {
            padding: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            // background: 'rgb(255 255 255 / 90%)',
            borderRadius: theme.spacing(1),
        },
        socialSolutionsListHeaderContainer: {
            padding: theme.spacing(1),
            paddingTop: theme.spacing(0),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            // background: 'rgb(255 255 255 / 90%)',
            borderRadius: theme.spacing(1),
        },
        socialSolutionsListContainer: {
            padding: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            // background: 'rgb(255 255 255 / 90%)',
            borderRadius: theme.spacing(1),
        },
        profileHeaderContainer: {
            // width: '100%',
            textAlign: 'center',
            padding: theme.spacing(0.5)
        },
        profileHeaderContainerMin: {
            width: '100%',
            textAlign: 'left',
        },
        profileContentContainer: {
            padding: '1px',
            // background: 'rgb(255 255 255 / 90%)',
            borderRadius: theme.spacing(1),
        },
        profileHeaderSubContainer: {
            // marginBottom: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            textAlign: 'center',
            // background: '#fff',
            borderRadius: theme.spacing(1),
        },
        profileHeaderSubContainerMin: {
            textAlign: 'left',
            paddingBottom: theme.spacing(1),
            // background: '#fff',
            borderRadius: theme.spacing(1),
            display: 'flow-root',
        },
        profileSubContentContainer: {
            padding: theme.spacing(1),
            margin: theme.spacing(0.5),
            // background: '#fff',
            borderRadius: theme.spacing(1),
            border: '0.7px dashed #132274',
        },
        profileSubContentContainerCentered: {
            padding: theme.spacing(1),
            margin: theme.spacing(0.5),
            textAlign: 'center',
            // background: '#fff',
            borderRadius: theme.spacing(1),
        },
        profileSubSecondaryContentContainer: {
            padding: theme.spacing(1),
            margin: theme.spacing(1),
            textAlign: 'center'
        },
        socialSolutionItemContainer: {
            padding: theme.spacing(1),
            border: '0.7px dashed #132274',
            borderRadius: '8px',
            marginTop: theme.spacing(0.25),
            marginBottom: theme.spacing(0.25),
            cursor: 'pointer'
        },
        generalContentContainerExtraIndent: {
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(3),
            // borderRight: '1px solid #000'
        },
        loginButtonContainer: {
            float: 'right',
            marginRight: theme.spacing(1),
            width: 0
        },
        onboardingContainer: {
            display: 'inline-grid',
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1),
            background: 'rgb(63 81 181 / 7%)'
        },
        avatarButtonContainer: {
            width: 'fit-content',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flow-root',
        },
        avatarButtonContainerMin: {
            // width: '25px',
            padding: theme.spacing(1),
            display: 'flow-root',
            float: 'left'
        },
        buttonRoot: {
            maxHeight: '37px',
            marginTop: 'auto',
            marginBottom: 'auto',
        },
        recordActionButton: {
            marginRight: theme.spacing(0.5),
            minWidth: theme.spacing(6),
            textTransform: 'none',
        },
        highlightTextDisabled: {
            color: '#777',
            textDecorationColor: '#ccc',
            textDecoration: 'underline',
            fontSize: '0.9em'
        },
        highlightTextOrangeLimeUnderline: {
            // color: orange[700],
            // color: theme.palette.secondary.main,
            textDecorationColor: lightGreen['A400'],
            textDecoration: 'underline',
            fontSize: '0.9em',
        },
        highlightTextOrange: {
            color: theme.palette.secondary.main,
            // color: orange[700],
            fontSize: '0.5em',
            [theme.breakpoints.up('md')]: {
                fontSize: '0.9em'
            },
        },
        mediatorName: {
            fontSize: '0.7em',
            [theme.breakpoints.up('md')]: {
                fontSize: '1.2em',
            },
        },
        recordTitle: {
            fontSize: '0.8em',
            [theme.breakpoints.up('md')]: {
                fontSize: '1.3em',
            },
        },
        recordTitleLeftSpace: {
            fontSize: '0.8em',
            marginLeft: theme.spacing(1),
            [theme.breakpoints.up('md')]: {
                fontSize: '1.3em',
            },
        },
        cardHeaderRoot: {
            padding: '3px',
            [theme.breakpoints.up('md')]: {
                paddingTop: '7px',
                paddingBottom: '3px',
            },
        },
        cardWaitingLogoRoot: {
            textAlign: 'center',
            marginTop: theme.spacing(2),
            padding: theme.spacing(2),
        },
        cardWaitingLogoImage: {
            marginLeft: 'auto',
            marginRight: 'auto',
            maxHeight: '111px',
        },
        cardWaitingRoot: {
            textAlign: 'center',
            clear: 'both',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: '8px !important',
        },
        cardWaitingProgress: {
            maxWidth: '30.0em',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: '8px !important',
        },
        cardWaitingMessage: {
            marginLeft: 'auto',
            marginRight: 'auto',
            // paddingTop: '8px !important',
        },
        cardRoot: {
            boxShadow: 'unset',
            backgroundColor: 'unset',
            borderRadius: '8px',
        },
        cardContentRoot: {
            padding: '7px',
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(1),
            },
        },
        card: {
            // display: 'flex',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        cardButton: {
            margin: theme.spacing(1),
            cursor: 'pointer'
        },
        cardHeader: {
            padding: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            background: 'none'
        },
        buttonCardContent: {
            padding: theme.spacing(0),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        standardCardContent: {
            padding: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        standardCardContentForm: {
            padding: theme.spacing(1),
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            // backgroundColor: '#00ff002b',
        },
        cardContent: {
            padding: theme.spacing(1),
            paddingTop: theme.spacing(0.5),
        },
        cardMenuIcon: {
            margin: theme.spacing(0),
            padding: theme.spacing(0),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(0.5),
        },
    }
}
