import React, { useContext } from 'react';

import { styled } from '@mui/material/styles';

import { withUdicciTheme } from './udicci-hoc';
import { UdicciContext, getDesignMode } from 'src/context/udicci-context'

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

const PortalPageLayout = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // overflowY: 'auto',
    pageBreakInside: 'auto',
}));

const PageLayout: React.FC<any> = (props) => {
    // console.log('%c PageLayout props: %O', 'color: red;', props);

    const udicciContext = useContext(UdicciContext);
    // console.log('%c PageLayout udicciContext: %O', 'color: red;', udicciContext);
    // const udicciHelpers = useUdicciHelpers();

    let dm: any = getDesignMode();
    // console.log('%c Porta dm: %O', 'color: maroon;', dm);

    let { children } = props;
    let { udicci } = udicciContext.state;
    // console.log('%c PageLayout data: %O', 'color: maroon;', data);
    // console.log('%c PageLayout udicci: %O', 'color: maroon;', udicci);

    let { selectedProfile } = udicci;
    // console.log('%c PageLayout selectedProfile: %O', 'color: maroon;', selectedProfile);

    let bgColor = '';
    if (selectedProfile && selectedProfile.data && selectedProfile.data.PageBackgroundColor) {
        bgColor = selectedProfile.data.PageBackgroundColor;
    }
    // console.log('%c PageLayout bgColor: %O', 'color: maroon;', bgColor);
    let bgImage = '';
    if (selectedProfile && selectedProfile.data && selectedProfile.data.BackgroundImage) {
        bgImage = selectedProfile.data.BackgroundImage;
    }
    // console.log('%c PageLayout bgImage: %O', 'color: maroon;', bgImage);

    if (bgImage) {
        document.body.style.background = `url(${bgImage}) center center no-repeat fixed`;
        document.body.style.backgroundSize = 'cover';
    } else {
        document.body.style.background = 'none';
    }
    if (bgColor) {
        document.body.style.backgroundColor = bgColor;
    }

    return ( <PortalPageLayout> {children} </PortalPageLayout> );
};

export default withUdicciTheme(PageLayout);