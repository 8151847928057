import * as React from 'react'

import { UdicciRecord, UdicciPermissions } from 'src/classes/udicci-record';
import {
    RecordContextActions,
    RecordContextStateType, 
    ContextSubscriber,
    SubscriptionInstruction
} from 'src/classes/udicci-types';

import {
    useUdicciContext,
    sendPreparedRequest,
    subscribe as subscribeToUdicci
} from 'src/context/udicci-context';

import {
    useUdicciListContext,
    // subscribe as subscribeToListContext
} from 'src/context/udicci-list-context';

import { GetRecordPerspectivesRequest } from 'src/interfaces/udicci-request-interfaces';

const defaultContextValue: RecordContextStateType = {
    record: null,
    settings: null,
    engagementActions: null,
    recordContext: null,
    onFieldValueChanged: null,
    save: null,
    delete: null,
    deletePerspective: null,
    getStructure: null,
    getField: null,
    setRecordContext: null,
    getPerspectives: null,
};

const UdicciRecordContext = React.createContext<{
    state: RecordContextStateType;
    dispatch: React.Dispatch<RecordContextActions>;
}>({
    state: defaultContextValue,
    dispatch: () => null
});

let subscribers: ContextSubscriber[] = [];

const notifySubscribers = (state: any = null) => {
    // console.log('%c notifySubscribers (%O)', classLogStyle, state);
    subscribers.forEach((sub: ContextSubscriber) => {
        // console.log('%c sub: %O', classLogStyle, sub);
        let cb = sub.callback;
        if (cb) cb(state);
    });
}

export const subscribe = (subcriberId: string, cbFunc: Function) => {
    let foundSub = false;
    subscribers.forEach((sub: ContextSubscriber, idx: number) => {
        // console.log('%c sub: %O', 'color: blue;', sub);
        if (sub.subscriber === subcriberId) {
            foundSub = true;
            subscribers[idx].callback = cbFunc;
        }
    });

    if (!foundSub) {
        let newSub: ContextSubscriber = { subscriber: subcriberId, callback: cbFunc }
        subscribers.push(newSub)
    }
}

export const unsubscribe = (subcriberId: string) => {
    let newSubscriberList: ContextSubscriber[] = [];
    subscribers.forEach((sub: ContextSubscriber, idx: number) => {
        // console.log('%c sub: %O', 'color: blue;', sub);
        if (sub.subscriber !== subcriberId) {
            newSubscriberList.push(sub);
        }
    });
    subscribers = newSubscriberList;
}

function udicciRecordProcess(state: any, action: any) {
    // console.log('%c udicciRecordProcess state: %O', 'color: purple; font-weight: bold;', state);
    // console.log('%c udicciRecordProcess action: %O', 'color: purple; font-weight: bold;', action);

    let rval: any = {};
    Object.assign(rval, state);
    if (action && action.payload) {
        let updatedRecord: UdicciRecord | null = (action.payload.record ? action.payload.record : null);
        if (updatedRecord) rval.record = updatedRecord;

        let updatedRecordContext: any | null = (action.payload.recordContext ? action.payload.recordContext : null);
        if (updatedRecordContext) rval.recordContext = updatedRecordContext;
        if (updatedRecordContext) rval.record.context = updatedRecordContext;
    }

    switch (action.type) {
        case RecordContextActions.Refresh: {
            // console.log('%c Refresh action.payload: %O', 'color: purple; font-weight: bold;', action.payload);
            break;
        }
        case RecordContextActions.Add: {
            // console.log('%c Add action.payload: %O', 'color: purple; font-weight: bold;', action.payload);
            break;
        }
        case RecordContextActions.Delete: {
            // console.log('%c Delete action.payload: %O', 'color: purple; font-weight: bold;', action.payload);
            break;
        }
        case RecordContextActions.Save: {
            // console.log('%c Save action.payload: %O', 'color: purple; font-weight: bold;', action.payload);
            break;
        }
        case RecordContextActions.Validate: {
            // console.log('%c Validate action.payload: %O', 'color: purple; font-weight: bold;', action.payload);
            break;
        }
        default: {
            // console.log(`Unhandled record action type: ${action.type}`);
            break;
        }
    }

    // console.log('%c udicciRecordProcess rval: %O', 'color: purple; font-weight: bold;', rval);
    notifySubscribers(rval);
    return rval;
}

function RecordContextProvider(props: any) {
    let propValues: any = (props && props.value ? props.value : null);
    let propRecord: UdicciRecord | null = (propValues && propValues.record ? propValues.record : defaultContextValue.record);
    let propSettings: any | null = (propValues && propValues.settings ? propValues.settings : defaultContextValue.settings);
    let propRecordContext: any | null = (propValues && propValues.recordContext ? propValues.recordContext : defaultContextValue.recordContext);
    // console.log('%c RecordContextProvider propRecordContext: %O', 'color: red;', propRecordContext);

    let contextValues: any = {};
    Object.assign(contextValues, defaultContextValue);
    if (!contextValues.record) contextValues.record = propRecord;
    if (!contextValues.settings) contextValues.settings = propSettings;
    if (!contextValues.recordContext) contextValues.recordContext = propRecordContext;

    const [state, dispatch] = React.useReducer(udicciRecordProcess, contextValues);
    // console.log('%c RecordContextProvider state: %O', 'color: red;', state);

    let uc: any = useUdicciContext();
    let ulc: any = useUdicciListContext();
    let recordId: number = (state && state.record && state.record.recordId ? state.record.recordId : 0);
    let recordMediatorName: string = (state && state.record && state.record.udicciMediator ? state.record.udicciMediator : '');

    let stateUpdate: RecordContextStateType = {
        record: state.record,
        settings: state.settings,
        engagementActions: state.engagementActions,
        recordContext: state.recordContext,
        onFieldValueChanged: (fieldName: any | null, evt: any, dateValue: Date | null = null) => {
            onFieldValueChanged(fieldName, evt, dateValue, state, dispatch, uc, ulc)
        },
        save: (settings: any | null = null) => {
            saveRecord(dispatch, uc, state.record, settings)
        },
        delete: (settings: any | null = null) => {
            deleteRecord(uc, state.record, settings)
        },
        deletePerspective: (perspective: UdicciRecord, settings: any | null = null) => {
            deleteRecord(uc, perspective, settings)
        },
        getStructure: (udicciMediatorName: string = '') => {
            return getMediatorStructure(uc, (udicciMediatorName ? udicciMediatorName : recordMediatorName))
        },
        getField: (fieldName: string, udicciMediatorName: string = '') => {
            return getMediatorStructureField(uc, fieldName, (udicciMediatorName ? udicciMediatorName : recordMediatorName))
        },
        setRecordContext: (recordContext: any | null = null) => {
            setRecordContext(dispatch, recordContext)
        },
        getPerspectives: () => {
            checkForRecordPerspectives(state, dispatch, uc, state.record)
        },
    };

    const value = { state: stateUpdate, dispatch };

    const contextUpdated = (result: any, request: any, details: any) => {
        // console.log('%c RecordContextProvider contextUpdated result: %O', 'color: hotpink;', result);
        // console.log('%c RecordContextProvider contextUpdated request: %O', 'color: hotpink;', request);
        // console.log('%c RecordContextProvider contextUpdated details: %O', 'color: hotpink;', details);
        let requestRecordId: any = (request && request.UdicciRecordId ? request.UdicciRecordId : 0);
        // console.log('%c RecordContextProvider contextUpdated requestRecordId: %O', 'color: hotpink;', requestRecordId);
        if (requestRecordId <= 0) {
            if (result && result.length > 0) {
                let updatedRecord: any = result[0];
                // console.log('%c RecordContextProvider updatedRecord: %O', 'color: red;', updatedRecord);
                value.state.record = updatedRecord;
                // console.log('%c RecordContextProvider value.state: %O', 'color: red;', value.state);
                // let dispatchParams = {
                //     type: RecordContextActions.Refresh,
                //     payload: value.state
                // };
                // console.log('%c changeRecordValue dispatchParams: %O', 'color: red;', dispatchParams);
                // dispatch(dispatchParams);
            }
        } else {
            // console.log('%c RecordContextProvider contextUpdated details: %O', 'color: red;', details);
            if (details && details.UdicciCommand === 'Delete Record' && details.success === true) {
                let curULS = (ulc && ulc.state ? ulc.state : null);
                // console.log('%c ContextForm curUdicciListState: %O', 'color: maroon;', curULS);
                let curULSsettings = (curULS && curULS.settings ? curULS.settings : null);
                // console.log('%c ContextForm curULSsettings: %O', 'color: maroon;', curULSsettings);
                let onCloseBreadcrumb = (curULSsettings && curULSsettings.onCloseBreadcrumb ? curULSsettings.onCloseBreadcrumb : null);
                if (onCloseBreadcrumb) onCloseBreadcrumb();
            }
        }
    };

    let subscriptionInstructions: SubscriptionInstruction = {
        udicciMediator: recordMediatorName,
        recordId: recordId
    };
    // console.log('%c RecordContextProvider subscriptionInstructions: %O', 'color: blue;', subscriptionInstructions);
    subscribeToUdicci('record.context', contextUpdated, subscriptionInstructions);

    return (
        <UdicciRecordContext.Provider value={value}>{props.children}</UdicciRecordContext.Provider>
    );
}

function useUdicciRecordContext() {
    const context = React.useContext(UdicciRecordContext)
    if (context === undefined) {
        throw new Error('useUdicciRecordContext must be used within a RecordContextProvider');
    }
    return context
}

export const onFieldValueChanged = (fieldName: any | null, evt: any, dateValue: Date | null = null, state: any, dispatch: any, udicciContext: any, udicciListContext: any) => {
    // console.log('%c onFieldValueChanged fieldName: %O', 'color: red;', fieldName);
    // console.log('%c onFieldValueChanged evt: %O', 'color: red;', evt);
    // console.log('%c onFieldValueChanged dateValue: %O', 'color: red;', dateValue);
    // console.log('%c onFieldValueChanged state: %O', 'color: red;', state);
    // console.log('%c onFieldValueChanged dispatch: %O', 'color: red;', dispatch);
    let newValue: any = null;
    if (evt && evt.target) {
        let trgt = evt.target;
        newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
    }
    if (dateValue) newValue = dateValue;
    changeRecordValue(fieldName, newValue, state, dispatch, udicciContext, udicciListContext);
}

export const changeRecordValue = (fieldName: any | null, newValue: any, state: any, dispatch: any, udicciContext: any, udicciListContext: any) => {
    // console.log('%c changeRecordValue fieldName: %O', 'color: red;', fieldName);
    // console.log('%c changeRecordValue newValue: %O', 'color: red;', newValue);
    // console.log('%c changeRecordValue recordFactory: %O', 'color: red;', recordFactory);

    // console.log('%c changeRecordValue udicciContext: %O', 'color: red;', udicciContext);
    // console.log('%c changeRecordValue udicciListContext: %O', 'color: red;', udicciListContext);

    let { record } = state;
    // console.log('%c changeRecordValue record: %O', '', record);

    let curUdicciListState = (udicciListContext && udicciListContext.state ? udicciListContext.state : null);
    // console.log('%c changeRecordValue curUdicciListState: %O', 'color: maroon;', curUdicciListState);

    // let lsData: any = (curUdicciListState && curUdicciListState.data ? curUdicciListState.data : null);
    // console.log('%c changeRecordValue lsData: %O', 'color: maroon;', lsData);
    // let lsSolution: any = (curUdicciListState && curUdicciListState.socialSolution ? curUdicciListState.socialSolution : null);
    // console.log('%c changeRecordValue lsSolution: %O', 'color: maroon;', lsSolution);
    let lsMediator: any = (curUdicciListState && curUdicciListState.mediator ? curUdicciListState.mediator : null);
    // console.log('%c changeRecordValue lsMediator: %O', 'color: maroon;', lsMediator);
    let lsStructure: any = (lsMediator && lsMediator.structure ? lsMediator.structure : null);
    // console.log('%c changeRecordValue lsStructure: %O', 'color: maroon;', lsStructure);
    // let lsPermissions: any = (lsMediator && lsMediator.permissions ? lsMediator.permissions : null);
    // console.log('%c changeRecordValue lsPermissions: %O', 'color: maroon;', lsPermissions);

    // // convert fieldName to UdicciMediatorField object
    let fields: any = null;
    if (lsStructure) {
        fields = (lsStructure.UdicciMediatorFields ? lsStructure.UdicciMediatorFields : null);
    }
    // console.log('%c changeRecordValue fields: %O', 'color: maroon;', fields);

    let field: any = null;
    if (fields && fields.length > 0) {
        field = fields.find((x: any) => x.JsonFieldName === fieldName );
    }
    // console.log('%c changeRecordValue field: %O', 'color: maroon;', field);

    // if (field) recordFactory.changeRecordValue(field, newValue);
    let fieldJsonKey = (field && field.JsonFieldName ? field.JsonFieldName : field.Name)
    // console.log('%c changeRecordValue fieldJsonKey: %O', 'color: red;', fieldJsonKey);

    if (record) {
        let recordData = (record.data ? record.data : {});
        // console.log('%c changeRecordValue recordData: %O', 'color: red;', recordData);
        let tf = (record.keys && record.keys.title ? record.keys.title : '');
        let df = (record.keys && record.keys.description ? record.keys.description : '');
        // console.log('%c changeRecordValue recordData: %O', 'color: red;', recordData);
        if (recordData[fieldJsonKey] !== newValue) {
            recordData[fieldJsonKey] = newValue;
            // console.log('%c changeRecordValue recordData: %O', 'color: darkorange;', recordData);
            record.data = recordData;
            record.isDirty = true;
        }
        if (fieldJsonKey === tf) record.title = recordData[fieldJsonKey];
        if (fieldJsonKey === df) record.description = recordData[fieldJsonKey];

        if (!record.permissions && record.recordId <= 0) {
            let newPermissions: UdicciPermissions = {
                CanView: true, CanAdd: true, CanEdit: true, CanDelete: true, CanDuplicate: true, CanProvision: true
            };
            record.permissions = newPermissions;
        }
        // console.log('%c changeRecordValue record: %O', 'color: darkorange;', record);
        state.record = record;
    }

    let dispatchParams = {
        type: RecordContextActions.Refresh,
        payload: state
    };
    // console.log('%c changeRecordValue dispatchParams: %O', 'color: red;', dispatchParams);
    dispatch(dispatchParams);
}

export const saveRecord = (dispatch: any, udicciContext: any | null, record: UdicciRecord, settings: any | null = null) => {
    // console.log('%c saveRecord udicciContext: %O', classLogStyle, udicciContext);
    // console.log('%c saveRecord record: %O', classLogStyle, record);
    // console.log('%c saveRecord settings: %O', classLogStyle, settings);
    if (udicciContext === null) return false;
    if (record === null) return false;
    if (record.isDirty === false) return false;

    let { udicci } = udicciContext.state;
    // console.log('%c saveRecord udicci: %O', 'color: red;', udicci);
    record.isSaving = true;

    let dispatchParams = {
        type: RecordContextActions.Refresh,
        payload: { record: record }
    };
    // console.log('%c UdicciListContextProvider updateUdicciContextHandler dispatchParams: %O', 'color: red;', dispatchParams);
    dispatch(dispatchParams);

    udicci.saveRecord(record, record.data.CreatedInSolutionId, settings);
    // console.log('%c saveRecord record: %O', 'color: red;', record);
}

export const deleteRecord = (udicciContext: any | null, record: UdicciRecord, settings: any | null = null) => {
    // console.log('%c deleteRecord udicciContext: %O', classLogStyle, udicciContext);
    // console.log('%c deleteRecord record: %O', classLogStyle, record);
    // console.log('%c deleteRecord settings: %O', classLogStyle, settings);
    if (udicciContext === null) return false;
    if (record === null) return false;

    let { udicci } = udicciContext.state;
    // console.log('%c deleteRecord udicci: %O', classLogStyle, udicci);
    udicci.deleteRecord(record, record.data.CreatedInSolutionId, settings);
}

export const getMediatorStructure: any = (udicciContext: any | null, udicciMediatorName: string = '') => {
    // console.log('%c getMediatorStructure recordFactory: %O', classLogStyle, recordFactory);

    let { data } = udicciContext?.state;
    // console.log('%c getMediatorStructure data: %O', classLogStyle, data);

    let structure: any = null;
    if (data && udicciMediatorName) {
        let mediatorContext = data.find((x: any) => x.mediator === udicciMediatorName );
        // console.log('%c getMediatorStructure mediatorContext: %O', classLogStyle, mediatorContext);
        if (mediatorContext && mediatorContext.structure) structure = mediatorContext.structure;
    }
    // console.log('%c getMediatorStructure structure: %O', classLogStyle, structure);
    return structure;
}

export const getMediatorStructureField: any = (udicciContext: any | null, fieldName: any | null, udicciMediatorName: string = '') => {
    // console.log('%c getMediatorStructureField fieldName: %O', classLogStyle, fieldName);

    let { data } = udicciContext.state;
    // console.log('%c getMediatorStructureField data: %O', classLogStyle, data);

    let structure: any = null;
    if (data && udicciMediatorName) {
        let mediatorContext = data.find((x: any) => x.mediator === udicciMediatorName );
        if (mediatorContext && mediatorContext.structure) structure = mediatorContext.structure;
    }
    // console.log('%c getMediatorStructureField structure: %O', classLogStyle, structure);

    let fields: any = null;
    if (structure) {
        fields = (structure.UdicciMediatorFields ? structure.UdicciMediatorFields : null);
    }
    // console.log('%c getMediatorStructureField fields: %O', classLogStyle, fields);

    let field: any = null;
    if (fields && fields.length > 0) {
        field = fields.find((x: any) => x.JsonFieldName === fieldName );
    }
    // console.log('%c getMediatorStructureField field: %O', classLogStyle, field);

    return field;
}

export const setRecordContext = (dispatch: any, recordContext: any | null = null) => {
    // console.log('%c setRecordContext udicciContext: %O', classLogStyle, udicciContext);
    // console.log('%c setRecordContext settings: %O', classLogStyle, settings);
    // if (udicciContext === null) return false;

    let dispatchParams = {
        type: RecordContextActions.Refresh,
        payload: { recordContext: recordContext }
    };
    // console.log('%c UdicciListContextProvider updateUdicciContextHandler dispatchParams: %O', 'color: red;', dispatchParams);
    dispatch(dispatchParams);
}

const checkForRecordPerspectives = (state: any, dispatch: any, udicciContext: any | null, record: UdicciRecord) => {
    // console.log('%c checkForRecordPerspectives record: %O', 'color: red;', record);
    let { udicci, currentUser } = udicciContext.state;

    let requestJson: GetRecordPerspectivesRequest = {
        UdicciCommand: 'Get Record Perspectives',
        UdicciMediatorName: 'Perspectives',
        SelectedUdicciProfileId: udicci.ulyssesDConstantineProfileId,
        UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0),
        SocialSolutionId: udicci.socialSolutionDefaultMe,
        RecordId: record.recordId
    };
    // console.log('%c checkForRecordPerspectives requestJson: %O', 'color: blue;', requestJson);

    sendPreparedRequest(requestJson, {
        onSuccess: (result: any, request: any, settings: any) => checkForRecordPerspectivesCompleted(state, dispatch, result, request, settings),
        onError: (result: any) => checkForRecordPerspectivesFailed(state, dispatch, result),
    });
};

const checkForRecordPerspectivesCompleted = (state: any, dispatch: any, result: any, request: any, settings: any) => {
    // console.log('%c checkForRecordPerspectivesCompleted result: %O', 'color: cyan;', result);
    // console.log('%c checkForRecordPerspectivesCompleted request: %O', 'color: cyan;', request);
    // console.log('%c checkForRecordPerspectivesCompleted settings: %O', 'color: cyan;', settings);

    let rec: UdicciRecord = state.record;
    rec.perspectives = result;
    state.record = rec;
    // console.log('%c checkForRecordPerspectivesCompleted state: %O', 'color: cyan;', state);
    let dispatchParams = {
        type: RecordContextActions.Refresh,
        payload: state
    };
    // console.log('%c UdicciListContextProvider updateUdicciContextHandler dispatchParams: %O', 'color: red;', dispatchParams);
    dispatch(dispatchParams);
}

const checkForRecordPerspectivesFailed = (state: any, dispatch: any, result: any) => {
    // console.log('%c checkForRecordPerspectivesFailed result: %O', 'color: red;', result);
};

export {RecordContextProvider, useUdicciRecordContext, RecordContextActions}
