
import { CreateConfig } from './Create/CreateConfig';
import { CreateUI } from './Create/CreateUI';
import { CreateMenu } from './Create/CreateMenuItem';
import { CreateHelp } from './Create/CreateHelp';

export type TargetFieldStructure = {
    name: string;
    jsonKey: string;
    sourceFields: any[];
};

export type TaskDetail = {
    name: string;
    stepsCompleted: number;
    targetField: TargetFieldStructure
};

const Create: React.FC<any> = (props) => {
    // console.log('%c Create props: %O', 'color: purple;', props);

    let configureAction = (props && props.configure === true ? true : false);

    let actionProps: any = {};
    Object.assign(actionProps, props);
    // console.log('%c actionProps: %O', 'color: red;', actionProps);

    let actionElement: any = null;
    if (configureAction) {
        actionElement = (<CreateConfig {...actionProps} />);
    } else {
        actionElement = (<CreateUI {...actionProps} />);
    }
    return actionElement;
}

export { Create, CreateMenu, CreateHelp }
