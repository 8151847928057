
import { Fragment, useState } from 'react';
// import { useTheme } from '@mui/material/styles';

// import Pluralize from 'pluralize';

import {
    Box, Typography, Table, TableHead, TableFooter, TableBody, TableRow, TableCell, Tooltip, Paper, ButtonBase,
    Tab, Button, ButtonGroup, Icon, MenuItem, Select, InputLabel, Input, IconButton, TextField, Chip,
    FormControl, Tabs, AppBar, Autocomplete
} from '@mui/material';

// import useUdicciRecord from 'src/hooks/useUdicciRecord';
import useUdicciHelpers, { TabPanel, UdicciPermissionsInitialized } from 'src/hooks/useUdicciHelpers';
import { useUdicciContext } from 'src/context/udicci-context'
// import { getUdicciData } from 'src/context/udicci-context';
// import { udicciStyles } from 'src/theme/shared-styles';

import { forEach, keys, find } from 'underscore';

import AssignRoleAccess from 'src/admin/social-solution-management/assign-role-access';
import UdicciSourceFilterSelector from 'src/admin/select-udicci-source-filter';
import UdicciSourceSortSelector from 'src/admin/select-udicci-source-sort';
import ConfigureFieldDisplay from 'src/admin/configure-field-display';
import { UdicciRecord } from 'src/classes/udicci-record';

const UdicciFeatureAdmin: React.FC<any> = (props) => {
    // console.log('%c UdicciFeatureAdmin props: %O', 'color: purple;', props);

    // const theme = useTheme();
    // console.log('%c theme: %O', 'color: purple;', theme);
    // const udicciClasses = udicciStyles(theme);

    const [currentDisplay, setDisplay] = useState<string>('');
    const [assignFeatureMediators, setAssignFeatureMediators] = useState<boolean>(false);
    const [changeMediator, setChangeMediator] = useState<any>(null);
    const [mediatorTabIndex, setMediatorTabIndex] = useState<number>(0);
    const [showSortFilter, setShowSortFilter] = useState<string>('');
    const [uiState, flashUI] = useState<boolean>(false);
    // console.log('%c UdicciFeatureAdmin changeMediator: %O', 'color: purple;', changeMediator);

    let { socialSolution, feature } = props;
    // console.log('%c UdicciFeatureAdmin socialSolution: %O', 'color: purple;', socialSolution);
    // console.log('%c UdicciFeatureAdmin feature: %O', 'color: purple;', feature);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();

    let { udicci } = udicciContext.state;
    // console.log('%c UdicciFeatureAdmin udicci: %O', 'color: purple;', udicci);
    // console.log('%c UdicciFeatureAdmin data: %O', 'color: purple;', data);

    let { selectedProfile } = udicci;
    // console.log('%c UdicciFeatureAdmin selectedProfile: %O', 'color: purple; font-weight: bold;', selectedProfile);

    let profile = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    // console.log('%c UdicciFeatureAdmin profile: %O', 'color: purple;', profile);

    // let featuresStructure: any = udicciHelpers.getMediatorStructure('Udicci Features');
    // console.log('%c UdicciFeatureAdmin featuresStructure: %O', 'color: purple;', featuresStructure);

    let profileSettings: any = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
    // console.log('%c UdicciFeatureAdmin profileSettings: %O', 'color: purple;', profileSettings);
    let psss: any = (profileSettings && profileSettings.socialSolutionSettings ? profileSettings.socialSolutionSettings : null);
    let socialSolutionId = (socialSolution && socialSolution.recordId ? socialSolution.recordId : 0);
    let profileSocialSolutionSettings: any = (psss && psss[socialSolutionId] ? psss[socialSolutionId] : null);
    // console.log('%c profileSocialSolutionSettings: %O', 'color: purple;', profileSocialSolutionSettings);

    let roleOverrides: any = (profileSocialSolutionSettings && profileSocialSolutionSettings.RoleOverrides ? profileSocialSolutionSettings.RoleOverrides : null);
    // console.log('%c roleOverrides: %O', 'color: purple;', roleOverrides);

    let socialSolutionSettings: any = (socialSolution && socialSolution.data.jsonSettingsJson ? socialSolution.data.jsonSettingsJson : null);
    // console.log('%c socialSolutionSettings: %O', 'color: purple;', socialSolutionSettings);

    let socialSolutionMediatorSettings: any = (socialSolutionSettings && socialSolutionSettings.mediators ? socialSolutionSettings.mediators : null);
    // console.log('%c socialSolutionMediatorSettings: %O', 'color: purple;', socialSolutionMediatorSettings);

    let profileSolutionMediators: any = (profileSocialSolutionSettings && profileSocialSolutionSettings.mediators ? profileSocialSolutionSettings.mediators : null);
    if (profileSolutionMediators && Array.isArray(profileSolutionMediators)) profileSolutionMediators = null;
    // console.log('%c profileSolutionMediators: %O', 'color: purple;', profileSolutionMediators);

    if (feature && feature.data && !feature.data.jsonMediators && feature.data.Mediators) {
        try {
            feature.data.jsonMediators = JSON.parse(feature.data.Mediators);
        } catch (e: any) {

        }
    }
    // console.log('%c feature: %O', 'color: purple;', feature);

    let isDirty = (feature && feature.isDirty ? true : false);
    let featureId: number = 0;
    let featureName: string = '';
    let featureLabel: string = '';
    let featurePurpose: string = '';
    let assignedRoles: any[] = [];
    if (feature && feature.data) {
        featureId = (feature.data.UdicciRecordId ? feature.data.UdicciRecordId : 0);
        featureName = (feature.data.Name ? feature.data.Name : '');
        featureLabel = (feature.data.Label ? feature.data.Label : '');
        featurePurpose = (feature.data.Description ? feature.data.Description : '');
        assignedRoles = (feature.data.jsonRoles ? feature.data.jsonRoles : []);
    } else if (feature) {
        featureId = (feature.UdicciRecordId ? feature.UdicciRecordId : 0);
        featureName = (feature.Name ? feature.Name : '');
        featureLabel = (feature.Label ? feature.Label : featureName);
        featurePurpose = (feature.Description ? feature.Description : '');
        assignedRoles = (feature.Roles ? feature.Roles : []);
    }
    // console.log('%c assignedRoles: %O', 'color: purple;', assignedRoles);

    let featureMediatorSettings: any[] = [];
    forEach(keys(socialSolutionMediatorSettings), (mediatorName: any) => {
        // console.log('%c mediatorName: %O', 'color: blue;', mediatorName);
        let medSetting: any = socialSolutionMediatorSettings[mediatorName];
        // console.log('%c medSetting: %O', 'color: blue;', medSetting);
        let medFeature: any = (medSetting && medSetting.feature ? medSetting.feature : null);
        // console.log('%c medFeature: %O', 'color: blue;', medFeature);
        let medFeatures: any = (medSetting && medSetting.features ? medSetting.features : null);
        // console.log('%c medFeatures: %O', 'color: blue;', medFeatures);
        if (medFeatures && medFeatures.length > 0) {
            forEach(medFeatures, (ftre: any) => {
                // console.log('%c ftre: %O', 'color: blue;', ftre);
                if (ftre.id === feature.recordId) {
                    featureMediatorSettings.push(medSetting);
                }
            });
        } else if (medFeature === feature.title) {
            featureMediatorSettings.push(medSetting);
        }
    });
    // console.log('%c featureMediatorSettings: %O', 'color: blue;', featureMediatorSettings);

    let allMediators: any = udicciHelpers.preloadMediatorData('Udicci Mediators', udicci.socialSolutionUdicciIT, false);
    // console.log('%c allMediators: %O', 'color: purple;', allMediators);

    const onFieldValueChanged = (fieldName: string, evt: any) => {
        if (fieldName && feature.data) {
            feature.data[fieldName] = evt.target.value;

            let keys = feature.keys;
            if (fieldName === keys.title) feature.title = evt.target.value;
            if (fieldName === keys.description) feature.description = evt.target.value;
        } else {
            feature[fieldName] = evt.target.value;
        }

        feature.isDirty = true;
        flashUI(!uiState);
    };

    const removeFeatureMediator = (mediator: any) => {
        // console.log('%c removeFeatureMediator mediator: %O', 'color: red;', mediator);
        // console.log('%c evt: %O', 'color: purple;', evt);

        if (!mediator) return false;
        if (!socialSolution) return false;

        let settings: any = (socialSolution && socialSolution.data.jsonSettingsJson ? socialSolution.data.jsonSettingsJson : {});
        // console.log('%c settings: %O', 'color: purple;', settings);
        let mediators: any = (settings.mediators ? settings.mediators : {});
        // console.log('%c mediators: %O', 'color: purple;', mediators);
        let mediatorToRemoveFromFeature: any = (mediators[mediator.name] ? mediators[mediator.name] : null);
        // console.log('%c mediatorToRemoveFromFeature: %O', 'color: purple;', mediatorToRemoveFromFeature);
        let ssMediatorFeatures: any[] = (mediatorToRemoveFromFeature && mediatorToRemoveFromFeature.features ? mediatorToRemoveFromFeature.features : []);
        // console.log('%c ssMediatorFeatures: %O', 'color: purple;', ssMediatorFeatures);
        let ssFeature: string = (mediatorToRemoveFromFeature && mediatorToRemoveFromFeature.feature ? mediatorToRemoveFromFeature.feature : '');
        // console.log('%c ssFeature: %O', 'color: purple;', ssFeature);

        if (ssFeature === featureName) mediatorToRemoveFromFeature.feature = '';

        let featureCheck: any = find(ssMediatorFeatures, (ftre: any) => ftre.id === featureId);
        // console.log('%c featureCheck: %O', 'color: purple;', featureCheck);
        if (featureCheck) {
            let newMediators: any[] = [];
            forEach(ssMediatorFeatures, (ftre: any) => {if (ftre.id !== featureId) newMediators.push(ftre)});
            ssMediatorFeatures = newMediators;
        }
        // console.log('%c ssMediatorFeatures: %O', 'color: purple;', ssMediatorFeatures);
        mediatorToRemoveFromFeature.features = ssMediatorFeatures;

        if (!mediator.name && mediators[mediator.name] !== undefined) {
            delete mediators[mediator.name];
        } else if (mediatorToRemoveFromFeature) {
            if (mediatorToRemoveFromFeature.displayGroup !== undefined) delete mediatorToRemoveFromFeature['displayGroup'];
        }

        mediators[mediator.name] = mediatorToRemoveFromFeature;

        // console.log('%c mediators: %O', 'color: purple;', mediators);
        settings.mediators = mediators;
        socialSolution.data.jsonSettingsJson = settings;

        socialSolution.isDirty = true;
        flashUI(!uiState);
    };

    const onListTypeChanged = (evt: any) => {
        if (!changeMediator) return false;

        let mediatorName = (changeMediator.name ? changeMediator.name : '');
        // console.log('%c mediatorName: %O', 'color: orange;', mediatorName);
        if (!mediatorName) return false;

        // console.log('%c profileSolutionMediators: %O', 'color: orange;', profileSolutionMediators);

        let mediatorSettingsOnProfile: any = (profileSolutionMediators && profileSolutionMediators[mediatorName] ? profileSolutionMediators[mediatorName] : null);
        // console.log('%c mediatorSettingsOnProfile: %O', 'color: purple;', mediatorSettingsOnProfile);

        if (!profileSolutionMediators) profileSolutionMediators = {};
        // console.log('%c profileSolutionMediators[%s]: %O', 'color: orange;', mediatorName, profileSolutionMediators[mediatorName]);
        if (!profileSolutionMediators[mediatorName]) profileSolutionMediators[mediatorName] = {};
        if (!profileSolutionMediators[mediatorName].list) {
            // console.log('%c profileSolutionMediators[%s].list: %O', 'color: purple;', mediatorName, profileSolutionMediators[mediatorName].list);
            let solutionSettings = socialSolution.data.jsonSettingsJson;
            // console.log('%c solutionSettings: %O', 'color: red;', solutionSettings);

            let solutionMediators = (solutionSettings && solutionSettings.mediators ? solutionSettings.mediators : null);
            // console.log('%c solutionMediators: %O', 'color: blue;', solutionMediators);
            let selectedSolutionMediator = null;
            if (solutionMediators && solutionMediators[mediatorName]) {
                selectedSolutionMediator = solutionMediators[mediatorName];
            }
            // console.log('%c selectedSolutionMediator: %O', 'color: blue;', selectedSolutionMediator);

            if (selectedSolutionMediator) {
                let solutionMediatorFeatures: any[] = (selectedSolutionMediator.features ? selectedSolutionMediator.features : []);
                // console.log('%c solutionMediatorFeatures: %O', 'color: blue;', solutionMediatorFeatures);

                let featureId: any = 0;
                if (feature && feature.data) {
                    featureId = feature.udicciRecordId;
                } else {
                    featureId = (feature && feature.uid ? feature.uid : 0);
                    if (!featureId && feature && feature.uniqueId) featureId = feature.uniqueId;
                }
                // console.log('%c featureId: %O', 'color: purple;', featureId);

                let selectedFeatureSettings = solutionMediatorFeatures.find((ftr: any) => {
                    let fId = (ftr.uid ? ftr.uid : 0);
                    if (!fId && ftr.uniqueId) fId = ftr.uniqueId;
                    if (!fId && ftr.id) fId = ftr.id;
                    return ((fId === featureId) ? true : false);
                });
                // console.log('%c selectedFeatureSettings: %O', 'color: purple;', selectedFeatureSettings);

                if (!profileSolutionMediators[mediatorName].list && selectedFeatureSettings){
                    if (selectedFeatureSettings.list) profileSolutionMediators[mediatorName].list = selectedFeatureSettings.list;
                }
            }
            // console.log('%c profileSolutionMediators[%s].list: %O', 'color: purple;', mediatorName, profileSolutionMediators[mediatorName].list);

            if (!profileSolutionMediators[mediatorName].list) profileSolutionMediators[mediatorName].list = {};
        }
        profileSolutionMediators[mediatorName].list.type = evt.target.value;

        // console.log('%c profileSolutionMediators: %O', 'color: orange;', profileSolutionMediators);
        profileSocialSolutionSettings.mediators = profileSolutionMediators;
        psss[socialSolution.udicciRecordId] = profileSocialSolutionSettings;
        profileSettings.socialSolutionSettings = psss;
        profile.jsonProfileSettingsJson = profileSettings;
        if (!selectedProfile) {
            let profilesStructure: any = udicciHelpers.getMediatorStructure('Udicci Profiles');
            // console.log('%c profilesStructure: %O', 'color: orange;', profilesStructure);
            selectedProfile = new UdicciRecord('Udicci Profiles', profile, profilesStructure);
        }
        selectedProfile.data = profile;
        selectedProfile.isDirty = true;

        // console.log('%c selectedProfile: %O', 'color: orange;', selectedProfile);
        udicci.setProfile(selectedProfile);

        flashUI(!uiState);
    };

    const onListPagingStyleChanged = (evt: any) => {
        if (!changeMediator) return false;

        let mediatorName = (changeMediator.name ? changeMediator.name : '');
        // console.log('%c mediatorName: %O', 'color: blue;', mediatorName);
        if (!mediatorName) return false;

        if (!profileSolutionMediators) profileSolutionMediators = {};
        // console.log('%c profileSolutionMediators[%s]: %O', 'color: blue;', mediatorName, profileSolutionMediators[mediatorName]);
        if (!profileSolutionMediators[mediatorName]) profileSolutionMediators[mediatorName] = {};
        if (!profileSolutionMediators[mediatorName].list) {
            // console.log('%c profileSolutionMediators[%s].list: %O', 'color: purple;', mediatorName, profileSolutionMediators[mediatorName].list);
            let solutionSettings = socialSolution.data.jsonSettingsJson;
            // console.log('%c solutionSettings: %O', 'color: red;', solutionSettings);
    
            let solutionMediators = (solutionSettings && solutionSettings.mediators ? solutionSettings.mediators : null);
            // console.log('%c solutionMediators: %O', 'color: blue;', solutionMediators);
            let selectedSolutionMediator = null;
            if (solutionMediators && solutionMediators[mediatorName]) {
                selectedSolutionMediator = solutionMediators[mediatorName];
            }
            // console.log('%c selectedSolutionMediator: %O', 'color: blue;', selectedSolutionMediator);

            if (selectedSolutionMediator) {
                let solutionMediatorFeatures = (selectedSolutionMediator.features ? selectedSolutionMediator.features : []);
                // console.log('%c solutionMediatorFeatures: %O', 'color: blue;', solutionMediatorFeatures);

                let featureId = 0;
                if (feature && feature.data) {
                    featureId = feature.udicciRecordId;
                } else {
                    featureId = (feature && feature.uid ? feature.uid : 0);
                    if (!featureId && feature && feature.uniqueId) featureId = feature.uniqueId;
                }
                // console.log('%c featureId: %O', 'color: purple;', featureId);

                let selectedFeatureSettings = solutionMediatorFeatures.find((ftr: any) => {
                    let fId = (ftr.uid ? ftr.uid : 0);
                    if (!fId && ftr.uniqueId) fId = ftr.uniqueId;
                    if (!fId && ftr.id) fId = ftr.id;
                    return ((fId === featureId) ? true : false);
                });
                // console.log('%c selectedFeatureSettings: %O', 'color: purple;', selectedFeatureSettings);

                if (!profileSolutionMediators[mediatorName].list && selectedFeatureSettings){
                    if (selectedFeatureSettings.list) profileSolutionMediators[mediatorName].list = selectedFeatureSettings.list;
                }
            }
            // console.log('%c profileSolutionMediators[%s].list: %O', 'color: purple;', mediatorName, profileSolutionMediators[mediatorName].list);

            if (!profileSolutionMediators[mediatorName].list) profileSolutionMediators[mediatorName].list = {};
        }
        profileSolutionMediators[mediatorName].list.pagingStyle = evt.target.value;

        // console.log('%c profileSolutionMediators: %O', 'color: blue;', profileSolutionMediators);
        profileSocialSolutionSettings.mediators = profileSolutionMediators;
        psss[socialSolution.udicciRecordId] = profileSocialSolutionSettings;
        profileSettings.socialSolutionSettings = psss;
        profile.jsonProfileSettingsJson = profileSettings;
        if (!selectedProfile) {
            let profilesStructure: any = udicciHelpers.getMediatorStructure('Udicci Profiles');
            // console.log('%c profilesStructure: %O', 'color: blue;', profilesStructure);
            selectedProfile = new UdicciRecord('Udicci Profiles', profile, profilesStructure);
        }
        selectedProfile.data = profile;
        selectedProfile.isDirty = true;

        // console.log('%c selectedProfile: %O', 'color: blue;', selectedProfile);
        udicci.setProfile(selectedProfile);
        flashUI(!uiState);
    }

    const onListPageOptionChanged = (fieldName: string, evt: any) => {
        if (!changeMediator) return false;

        let mediatorName: string = (changeMediator.name ? changeMediator.name : '');
        // console.log('%c mediatorName: %O', 'color: blue;', mediatorName);
        if (!mediatorName) return false;

        if (!profileSolutionMediators) profileSolutionMediators = {};
        // console.log('%c profileSolutionMediators[%s]: %O', 'color: purple;', mediatorName, profileSolutionMediators[mediatorName]);
        if (!profileSolutionMediators[mediatorName]) profileSolutionMediators[mediatorName] = {};
        if (!profileSolutionMediators[mediatorName].list) {
            // console.log('%c profileSolutionMediators[%s].list: %O', 'color: purple;', mediatorName, profileSolutionMediators[mediatorName].list);
            let solutionSettings = socialSolution.data.jsonSettingsJson;
            // console.log('%c solutionSettings: %O', 'color: red;', solutionSettings);
    
            let solutionMediators = (solutionSettings && solutionSettings.mediators ? solutionSettings.mediators : null);
            // console.log('%c solutionMediators: %O', 'color: blue;', solutionMediators);
            let selectedSolutionMediator = null;
            if (solutionMediators && solutionMediators[mediatorName]) {
                selectedSolutionMediator = solutionMediators[mediatorName];
            }
            // console.log('%c selectedSolutionMediator: %O', 'color: blue;', selectedSolutionMediator);

            if (selectedSolutionMediator) {
                let solutionMediatorFeatures = (selectedSolutionMediator.features ? selectedSolutionMediator.features : []);
                // console.log('%c solutionMediatorFeatures: %O', 'color: blue;', solutionMediatorFeatures);

                let featureId = 0;
                if (feature && feature.data) {
                    featureId = feature.recordId;
                } else {
                    featureId = (feature && feature.uid ? feature.uid : 0);
                    if (!featureId && feature && feature.uniqueId) featureId = feature.uniqueId;
                }
                // console.log('%c featureId: %O', 'color: purple;', featureId);

                let selectedFeatureSettings = solutionMediatorFeatures.find((ftr: any) => {
                    let fId = (ftr.uid ? ftr.uid : 0);
                    if (!fId && ftr.uniqueId) fId = ftr.uniqueId;
                    if (!fId && ftr.id) fId = ftr.id;
                    return ((fId === featureId) ? true : false);
                });
                // console.log('%c selectedFeatureSettings: %O', 'color: purple;', selectedFeatureSettings);

                if (!profileSolutionMediators[mediatorName].list && selectedFeatureSettings){
                    if (selectedFeatureSettings.list) profileSolutionMediators[mediatorName].list = selectedFeatureSettings.list;
                }
            }
            // console.log('%c profileSolutionMediators[%s].list: %O', 'color: purple;', mediatorName, profileSolutionMediators[mediatorName].list);

            if (!profileSolutionMediators[mediatorName].list) profileSolutionMediators[mediatorName].list = {};
        }
        profileSolutionMediators[mediatorName].list[fieldName] = evt.target.value;

        // console.log('%c profileSolutionMediators: %O', 'color: blue;', profileSolutionMediators);
        profileSocialSolutionSettings.mediators = profileSolutionMediators;
        psss[socialSolution.udicciRecordId] = profileSocialSolutionSettings;
        profileSettings.socialSolutionSettings = psss;
        profile.jsonProfileSettingsJson = profileSettings;
        if (!selectedProfile) {
            let profilesStructure: any = udicciHelpers.getMediatorStructure('Udicci Profiles');
            // console.log('%c profilesStructure: %O', 'color: blue;', profilesStructure);
            selectedProfile = new UdicciRecord('Udicci Profiles', profile, profilesStructure);
        }
        selectedProfile.data = profile;
        selectedProfile.isDirty = true;

        // console.log('%c selectedProfile: %O', 'color: blue;', selectedProfile);
        udicci.setProfile(selectedProfile);
        flashUI(!uiState);
    }

    const handleTabChange = (event: any, value: number) => {
        setMediatorTabIndex(value);
    };

    const selectionChanged = (selections: any) => {
        // console.log('%c selections: %O', 'color: blue;', selections);
        if (!changeMediator) return false;

        let mediatorName = (changeMediator.name ? changeMediator.name : '');
        // console.log('%c mediatorName: %O', 'color: blue;', mediatorName);
        if (!mediatorName) return false;

        // console.log('%c profileSolutionMediators: %O', 'color: blue;', profileSolutionMediators);

        let mediatorSettingsOnProfile: any = (profileSolutionMediators && profileSolutionMediators[mediatorName] ? profileSolutionMediators[mediatorName] : null);
        // console.log('%c mediatorSettingsOnProfile: %O', 'color: purple;', mediatorSettingsOnProfile);

        if (!profileSolutionMediators) profileSolutionMediators = {};
        // console.log('%c profileSolutionMediators[%s]: %O', 'color: blue;', mediatorName, profileSolutionMediators[mediatorName]);
        if (!profileSolutionMediators[mediatorName]) profileSolutionMediators[mediatorName] = {};

        let listSettings: any = (mediatorSettingsOnProfile && mediatorSettingsOnProfile.list ? mediatorSettingsOnProfile.list : null);
        // console.log('%c selectionChanged listSettings: %O', 'color: maroon;', listSettings);

        if (selections) {
            if (selections.fields) {
                if (!listSettings) listSettings = {};
                listSettings.fields = selections.fields;
            }
            if (selections.filterSettings) {
                if (!listSettings) listSettings = {};
                listSettings.defaultFilterSettings = selections.filterSettings;
            }
            if (selections.sortSettings) {
                if (!listSettings) listSettings = {};
                listSettings.sortSettings = selections.sortSettings;
            }
        }
        // console.log('%c listSettings: %O', 'color: green;', listSettings);
        mediatorSettingsOnProfile.list = listSettings;
        profileSolutionMediators[mediatorName] = mediatorSettingsOnProfile;
        profileSocialSolutionSettings.mediators = profileSolutionMediators;

        psss[socialSolutionId] = profileSocialSolutionSettings;
        profileSettings.socialSolutionSettings = psss;
        profile.jsonProfileSettingsJson = profileSettings;
        if (!selectedProfile) {
            let profilesStructure: any = udicciHelpers.getMediatorStructure('Udicci Profiles');
            // console.log('%c profilesStructure: %O', 'color: blue;', profilesStructure);
            selectedProfile = new UdicciRecord('Udicci Profiles', profile, profilesStructure);
        }
        selectedProfile.data = profile;
        selectedProfile.isDirty = true;
        // console.log('%c selectedProfile: %O', 'color: purple;', selectedProfile);
        udicci.setProfile(selectedProfile);
        flashUI(!uiState);
    };

    const onMediatorSelectionChanged = (evt: any, newValue: any | any[] | null) => {
        // console.log('%c newValue: %O', 'color: purple;', newValue);
        // console.log('%c allMediators: %O', 'color: purple;', allMediators);

        if (!socialSolution) return false;

        let settings = (socialSolution && socialSolution.data.jsonSettingsJson ? socialSolution.data.jsonSettingsJson : null);
        // console.log('%c settings: %O', 'color: purple;', settings);

        let mediators = (settings && settings.mediators ? settings.mediators : {});
        // console.log('%c mediators: %O', 'color: purple;', mediators);

        let selectedMediators: any = newValue; //evt.target.value;
        // console.log('%c selectedMediators: %O', 'color: purple;', selectedMediators);

        if (selectedMediators && selectedMediators.length > 0) {
            selectedMediators.forEach((selMed: any) => {
                let mediatorName: string = selMed.label;
                let mediatorToAddToFeature: any = (mediators[mediatorName] ? mediators[mediatorName] : null);
                // console.log('%c mediatorToAddToFeature: %O', 'color: purple;', mediatorToAddToFeature);

                if (mediatorToAddToFeature) {
                    if (mediatorToAddToFeature.displayGroup !== undefined) delete mediatorToAddToFeature['displayGroup'];
                    mediatorToAddToFeature.feature = feature.title;

                    let mediatorFeatures: any[] = (mediatorToAddToFeature.features ? mediatorToAddToFeature.features : []);
                    let chkMediatorFeature: any = mediatorFeatures.find(function(ftr: any) {
                        return (ftr.id === feature.recordId)
                    });
                    // console.log('%c chkMediatorFeature: %O', 'color: purple;', chkMediatorFeature);
                    if (!chkMediatorFeature) mediatorFeatures.push({ id: feature.recordId, name: feature.title });
                    // console.log('%c mediatorFeatures: %O', 'color: purple;', mediatorFeatures);
                    mediatorToAddToFeature.features = mediatorFeatures;
                    // console.log('%c mediatorToAddToFeature: %O', 'color: purple;', mediatorToAddToFeature);

                    mediators[mediatorName] = mediatorToAddToFeature;
                } else {
                    // need to add it to the mediators object
                    let selectedMediator: any = allMediators.find(function(rec: any) {
                        return (rec.title === mediatorName)
                    });
                    // console.log('%c selectedMediator: %O', 'color: purple;', selectedMediator);

                    let medId: number = 0;
                    let medName: string = '';
                    if (selectedMediator) {
                        medId = selectedMediator.recordId;
                        medName = selectedMediator.data.Name;
                    }

                    let curMed: any = (mediators[medName] ? mediators[medName] : null);
                    if (!curMed) {
                        curMed = {
                            id: medId,
                            name: medName,
                            label: medName,
                            feature: feature.title,
                            features: [],
                            enabled: true,
                            listActions: [],
                            permissionOverrides: null
                        }
                    }

                    let mediatorFeatures: any[] = (curMed.features ? curMed.features : []);
                    let chkMediatorFeature: any = mediatorFeatures.find(function(ftr: any) {
                        return (ftr.id === feature.udicciRecordId)
                    });
                    // console.log('%c chkMediatorFeature: %O', 'color: purple;', chkMediatorFeature);
                    if (!chkMediatorFeature) mediatorFeatures.push({ id: feature.recordId, name: feature.title });
                    // console.log('%c mediatorFeatures: %O', 'color: purple;', mediatorFeatures);
                    curMed.features = mediatorFeatures;
                    // console.log('%c curMed: %O', 'color: purple;', curMed);
                    mediators[medName] = curMed;
                }
            });
        }

        settings.mediators = mediators;
        socialSolution.data.jsonSettingsJson = settings;

        socialSolution.isDirty = true;
        // console.log('%c socialSolution: %O', 'color: purple;', socialSolution);
    }

    const applyRoleChanges = (roleId: number, updates: any) => {
        // console.log('%c UdicciFeatureAdmin applyRoleChanges socialSolution: %O', 'color: red;', socialSolution);
        // console.log('%c UdicciFeatureAdmin applyRoleChanges profileSolutionSettings: %O', 'color: red;', profileSolutionSettings);
        // console.log('%c UdicciFeatureAdmin applyRoleChanges updates: %O', 'color: red;', updates);

        // let newVal: any = {};
        // if (profileSolutionSettings) Object.assign(newVal, profileSolutionSettings);

        // let ssSettings: any = (newVal && newVal[socialSolution.recordId] ? newVal[socialSolution.recordId] : null);
        // // console.log('%c SocialSolutionForm applyRoleChanges ssSettings: %O', 'color: red;', ssSettings);
        // if (roleId) {
        //     if (!ssSettings) ssSettings = {};
        //     let sssro: any = (ssSettings.RoleOverrides ? ssSettings.RoleOverrides : {});
        //     // console.log('%c SocialSolutionForm applyRoleChanges ssSettingRoleOverrides: %O', 'color: red;', sssro);
        //     if (updates) {
        //         if (!sssro[roleId]) sssro[roleId] = {};
        //         Object.assign(sssro[roleId], updates);
        //     } else {
        //         sssro[roleId] = null;
        //     }
        //     ssSettings.RoleOverrides = sssro;
        // }

        // if (newVal && ssSettings) newVal[socialSolution.recordId] = ssSettings;
        // // console.log('%c SocialSolutionForm applyRoleChanges newVal: %O', 'color: red;', newVal);
        // setProfileSolutionSettings(newVal);

        // profileSettings.socialSolutionSettings = newVal;

        // profile.jsonProfileSettingsJson = profileSettings;
        // profile.ProfileSettingsJson = JSON.stringify(profile.jsonProfileSettingsJson);

        // if (selectedProfile) {
        //     selectedProfile.data = profile;
        //     selectedProfile.isDirty = true;

        //     udicci.setProfile(selectedProfile);
        // }
    };

    const addNewRole = (roleId: number) => {
        // console.log('%c UdicciFeatureAdmin addNewRole roleId: %O', 'color: red;', roleId);

        // let newVal: any = {};
        // if (profileSolutionSettings) Object.assign(newVal, profileSolutionSettings);
        // // console.log('%c SocialSolutionForm addNewRole newVal: %O', 'color: red;', newVal);
        // let ssSettings: any = (newVal && newVal[socialSolution.recordId] ? newVal[socialSolution.recordId] : null);
        // // console.log('%c SocialSolutionForm addNewRole ssSettings: %O', 'color: red;', ssSettings);
        // let sssro: any = (ssSettings && ssSettings.RoleOverrides ? ssSettings.RoleOverrides : null);
        // // console.log('%c SocialSolutionForm addNewRole ssSettingRoleOverrides: %O', 'color: red;', sssro);
        // let ssSettingRoleOvrRd: any = (roleId && sssro && sssro[roleId] ? sssro[roleId] : null);
        // // console.log('%c SocialSolutionForm addNewRole ssSettingRoleOvrRd: %O', 'color: red;', ssSettingRoleOvrRd);

        // if (!ssSettingRoleOvrRd) {
        //     if (!sssro) sssro = {};
        //     sssro[roleId] = {};
        //     Object.assign(sssro[roleId], UdicciPermissionsInitialized);
        // }

        // if (sssro) {
        //     if (!ssSettings) {
        //         ssSettings = {
        //             public: {},
        //             default: {},
        //             RoleOverrides: sssro,
        //             defaultFeature: null,
        //             deafultMediator: null,
        //         };
        //         Object.assign(ssSettings.public, UdicciPermissionsInitialized);
        //         Object.assign(ssSettings.default, UdicciPermissionsInitialized);
        //     } else {
        //         ssSettings.RoleOverrides = sssro;
        //     }
        // }
        // if (newVal && ssSettings) newVal[socialSolution.recordId] = ssSettings;

        // // console.log('%c SocialSolutionForm addNewRole newVal: %O', 'color: red;', newVal);
        // setProfileSolutionSettings(newVal);
    };

    const removeRole = (roleId: number) => {
        // console.log('%c UdicciFeatureAdmin removeRole roleId: %O', 'color: red;', roleId);

        // let newVal: any = {};
        // if (profileSolutionSettings) Object.assign(newVal, profileSolutionSettings);
        // // console.log('%c SocialSolutionForm removeRole newVal: %O', 'color: red;', newVal);
        // let ssSettings: any = (newVal && newVal[socialSolution.recordId] ? newVal[socialSolution.recordId] : null);
        // // console.log('%c SocialSolutionForm removeRole ssSettings: %O', 'color: red;', ssSettings);
        // let sssro: any = (ssSettings && ssSettings.RoleOverrides ? ssSettings.RoleOverrides : null);
        // // console.log('%c SocialSolutionForm removeRole ssSettingRoleOverrides: %O', 'color: red;', sssro);
        // let ssSettingRoleOvrRd: any = (roleId && sssro && sssro[roleId] ? sssro[roleId] : null);
        // // console.log('%c SocialSolutionForm removeRole ssSettingRoleOvrRd: %O', 'color: red;', ssSettingRoleOvrRd);

        // if (ssSettingRoleOvrRd) delete sssro[roleId];
        // if (ssSettings && sssro) ssSettings.RoleOverrides = sssro;
        // if (newVal && ssSettings) newVal[socialSolution.recordId] = ssSettings;

        // // console.log('%c SocialSolutionForm removeRole newVal: %O', 'color: red;', newVal);
        // setProfileSolutionSettings(newVal);

        // profileSettings.socialSolutionSettings = newVal;

        // profile.jsonProfileSettingsJson = profileSettings;
        // profile.ProfileSettingsJson = JSON.stringify(profile.jsonProfileSettingsJson);

        // if (selectedProfile) {
        //     selectedProfile.data = profile;
        //     selectedProfile.isDirty = true;

        //     udicci.setProfile(selectedProfile);
        // }
    };

    const addNewRoleOverride = (roleId: number, mediatorName: string) => {
        // console.log('%c UdicciFeatureAdmin addNewRoleOverride roleId: %O', 'color: red;', roleId);
        // console.log('%c UdicciFeatureAdmin addNewRoleOverride mediatorName: %O', 'color: red;', mediatorName);

        // let newVal: any = {};
        // if (profileSolutionSettings) Object.assign(newVal, profileSolutionSettings);
        // // console.log('%c SocialSolutionForm addNewRoleOverride newVal: %O', 'color: red;', newVal);
        // let ssSettings: any = (newVal && newVal[socialSolution.recordId] ? newVal[socialSolution.recordId] : null);
        // // console.log('%c SocialSolutionForm addNewRoleOverride ssSettings: %O', 'color: red;', ssSettings);
        // let sssro: any = (ssSettings && ssSettings.RoleOverrides ? ssSettings.RoleOverrides : null);
        // // console.log('%c SocialSolutionForm addNewRoleOverride ssSettingRoleOverrides: %O', 'color: red;', sssro);
        // let ssSettingRoleOvrRd: any = (roleId && sssro && sssro[roleId] ? sssro[roleId] : null);
        // // console.log('%c SocialSolutionForm addNewRoleOverride ssSettingRoleOvrRd: %O', 'color: red;', ssSettingRoleOvrRd);

        // if (!ssSettingRoleOvrRd) {
        //     if (!sssro) sssro = {};
        //     sssro[roleId] = {};
        //     Object.assign(sssro[roleId], UdicciPermissionsInitialized);
        //     ssSettingRoleOvrRd = sssro[roleId];
        // }

        // if (ssSettingRoleOvrRd) {
        //     let overrides: any = (ssSettingRoleOvrRd.overrides ? ssSettingRoleOvrRd.overrides : {});
        //     let overrideCheck: any = (overrides[mediatorName] ? overrides[mediatorName] : null);
        //     if (!overrideCheck) {
        //         overrides[mediatorName] = {};
        //         Object.assign(overrides[mediatorName], UdicciPermissionsInitialized);
        //         ssSettingRoleOvrRd.overrides = overrides;
        //     }

        //     sssro[roleId] = ssSettingRoleOvrRd;

        //     if (!ssSettings) {
        //         ssSettings = {
        //             public: {},
        //             default: {},
        //             RoleOverrides: sssro,
        //             defaultFeature: null,
        //             defaultMediator: null,
        //         };
        //         Object.assign(ssSettings.public, UdicciPermissionsInitialized);
        //         Object.assign(ssSettings.default, UdicciPermissionsInitialized);
        //     } else {
        //         ssSettings.RoleOverrides = sssro;
        //     }
        // }
        // if (newVal && ssSettings) newVal[socialSolution.recordId] = ssSettings;

        // // console.log('%c SocialSolutionForm addNewRole newVal: %O', 'color: red;', newVal);
        // setProfileSolutionSettings(newVal);
    };

    const removeRoleOverride = (roleId: number, mediatorName: string) => {
        // console.log('%c UdicciFeatureAdmin removeRoleOverride roleId: %O', 'color: red;', roleId);
        // console.log('%c UdicciFeatureAdmin removeRoleOverride mediatorName: %O', 'color: red;', mediatorName);

        // let newVal: any = {};
        // if (profileSolutionSettings) Object.assign(newVal, profileSolutionSettings);
        // // console.log('%c SocialSolutionForm removeRoleOverride newVal: %O', 'color: red;', newVal);
        // let ssSettings: any = (newVal && newVal[socialSolution.recordId] ? newVal[socialSolution.recordId] : null);
        // // console.log('%c SocialSolutionForm removeRoleOverride ssSettings: %O', 'color: red;', ssSettings);
        // let sssro: any = (ssSettings && ssSettings.RoleOverrides ? ssSettings.RoleOverrides : null);
        // // console.log('%c SocialSolutionForm removeRoleOverride ssSettingRoleOverrides: %O', 'color: red;', sssro);
        // let ssSettingRoleOvrRd: any = (roleId && sssro && sssro[roleId] ? sssro[roleId] : null);
        // // console.log('%c SocialSolutionForm removeRoleOverride ssSettingRoleOvrRd: %O', 'color: red;', ssSettingRoleOvrRd);
        // let roleOverrides: any = (ssSettingRoleOvrRd && ssSettingRoleOvrRd.overrides ? ssSettingRoleOvrRd.overrides : null);
        // // console.log('%c SocialSolutionForm removeRoleOverride roleOverrides: %O', 'color: red;', roleOverrides);
        // let overrideToRemove: any = (roleOverrides && roleOverrides[mediatorName] ? roleOverrides[mediatorName] : null);
        // // console.log('%c SocialSolutionForm removeRoleOverride roleOverrides: %O', 'color: red;', roleOverrides);

        // if (overrideToRemove) {
        //     delete roleOverrides[mediatorName];
        //     ssSettingRoleOvrRd.overrides = roleOverrides;
        //     sssro[roleId] = ssSettingRoleOvrRd;
        // }

        // if (ssSettings && sssro) ssSettings.RoleOverrides = sssro;
        // if (newVal && ssSettings) newVal[socialSolution.recordId] = ssSettings;

        // // console.log('%c SocialSolutionForm removeRoleOverride newVal: %O', 'color: red;', newVal);
        // setProfileSolutionSettings(newVal);

        // profileSettings.socialSolutionSettings = newVal;

        // profile.jsonProfileSettingsJson = profileSettings;
        // profile.ProfileSettingsJson = JSON.stringify(profile.jsonProfileSettingsJson);

        // if (selectedProfile) {
        //     selectedProfile.data = profile;
        //     selectedProfile.isDirty = true;

        //     udicci.setProfile(selectedProfile);
        // }
    };

    let featureContentElement: any = null;
    switch (currentDisplay) {
        case 'details':
            let nameElement = (
                <TextField fullWidth
                        label={'Feature Name'}
                        value={featureName}
                        InputLabelProps={{ shrink: true }}
                        onChange={(evt: any) => onFieldValueChanged('Name', evt)}
                        margin="normal"
                        variant='outlined'
                />
            );

            let labelElement = (
                <TextField fullWidth
                        label={'Feature Label'}
                        value={featureLabel}
                        InputLabelProps={{ shrink: true }}
                        onChange={(evt: any) => onFieldValueChanged('Label', evt)}
                        margin="normal"
                        variant='outlined'
                />
            );

            let purposeElement = (
                <TextField fullWidth multiline maxRows={7}
                        label={'Feature Purpose'}
                        value={featurePurpose}
                        InputLabelProps={{ shrink: true }}
                        onChange={(evt: any) => onFieldValueChanged('Description', evt)}
                        margin="normal"
                        variant='outlined'
                />
            );

            featureContentElement = (
                <Paper elevation={3} sx={{ padding: '8px' }}>
                    {nameElement}
                    {labelElement}
                    {purposeElement}
                </Paper>
            );
            break;
        case 'mediators':
            let selectedMediators: any[] = [];
            let assignMediatorsElement: any = null;
            let assignedMediatorsElement: any = null;
            let featureMediatorsHeader: any = null;
            let featureMediatorsToggleElement: any = null;
    
            let addFeatureMediatorChip: any = null;
            let featureMediatorsIconElement: any = null;

            if (changeMediator) {
                let closeMediatorButton = (
                    <IconButton color="secondary"
                                onClick={(evt: any) => setChangeMediator(null)}
                                aria-label="Close Mediator"
                                sx={{ float: 'right' }}
                    >
                        <Icon>close</Icon>
                    </IconButton>
                );
                featureMediatorsHeader = (
                    <Fragment>
                        {closeMediatorButton}
                        <Typography variant="subtitle1" color='primary' noWrap={true}>
                            {changeMediator.name}
                        </Typography>
                    </Fragment>
                );

                let mediatorName: string = (changeMediator.name ? changeMediator.name : '');
                // console.log('%c mediatorName: %O', 'color: purple;', mediatorName);
                let mediatorId: number = (changeMediator.id ? changeMediator.id : 0);
                // console.log('%c mediatorId: %O', 'color: purple;', mediatorId);

                let mediatorSettingsOnProfile: any = (profileSolutionMediators && profileSolutionMediators[mediatorName] ? profileSolutionMediators[mediatorName] : null);
                // console.log('%c mediatorSettingsOnProfile: %O', 'color: purple;', mediatorSettingsOnProfile);
                let listSettings: any = (mediatorSettingsOnProfile && mediatorSettingsOnProfile.list ? mediatorSettingsOnProfile.list : null);
                let tabContainer: any = null;
                if (mediatorTabIndex === 0) {
                    let listType: string = '';
                    if (listSettings && listSettings.type) {
                        listType = listSettings.type;
                    }

                    let pagingStyle: string = '';
                    if (listSettings && listSettings.pagingStyle) {
                        pagingStyle = listSettings.pagingStyle;
                    }

                    let pageSize: number = 10;
                    if (listSettings && listSettings.pageSize) {
                        pageSize = listSettings.pageSize;
                    }

                    let nextPageLabel: string = 'Next';
                    if (listSettings && listSettings.nextPageLabel) {
                        nextPageLabel = listSettings.nextPageLabel;
                    }
                    let prevPageLabel: string = 'Previous';
                    if (listSettings && listSettings.prevPageLabel) {
                        prevPageLabel = listSettings.prevPageLabel;
                    }

                    let fieldSettingsDisplayElement: any = null;
                    // console.log('%c showSortFilter: %O', 'color: purple;', showSortFilter);
                    if (showSortFilter) {
                        if (showSortFilter === 'sort') {
                            let sortSettings: any = (listSettings && listSettings.sortSettings ? listSettings.sortSettings : null);
                            // console.log('%c sortSettings: %O', 'color: purple;', sortSettings);
                            let sourceSortSettings: any = {};
                            Object.assign(sourceSortSettings, listSettings);
                            sourceSortSettings.sortSettings = sortSettings;
                            sourceSortSettings.mediator = { id: mediatorId, name: mediatorName };
                            // console.log('%c sourceSortSettings: %O', 'color: purple;', sourceSortSettings);
                            fieldSettingsDisplayElement = (
                                <Paper elevation={3} sx={{ padding: '8px' }}>
                                    <UdicciSourceSortSelector settings={sourceSortSettings}
                                                            mediatorName={mediatorName}
                                                            onSelectionChanged={selectionChanged} />
                                </Paper>
                            );
                        } else {
                            let defaultFilterSettings = (listSettings && listSettings.defaultFilterSettings ? listSettings.defaultFilterSettings : null);
                            // console.log('%c defaultFilterSettings: %O', 'color: purple;', defaultFilterSettings);
                            let sourceFilterSettings: any = {};
                            Object.assign(sourceFilterSettings, listSettings);
                            sourceFilterSettings.filterSettings = defaultFilterSettings;
                            sourceFilterSettings.mediator = { id: mediatorId, name: mediatorName };
                            // console.log('%c sourceFilterSettings: %O', 'color: purple;', sourceFilterSettings);
                            fieldSettingsDisplayElement = (
                                <Paper elevation={3} sx={{ padding: '8px' }}>
                                    <UdicciSourceFilterSelector settings={sourceFilterSettings}
                                                                mediatorName={mediatorName}
                                                                onSelectionChanged={selectionChanged} />
                                </Paper>
                            );
                        }
                    } else {
                        fieldSettingsDisplayElement = (
                            <Paper elevation={3} sx={{ padding: '8px' }}>
                                <ConfigureFieldDisplay options={listSettings}
                                                       mediatorName={mediatorName}
                                                       onChange={selectionChanged}
                                />
                            </Paper>
                        );
                    }

                    let listDisplayFieldsButton: any = (
                        <IconButton color={(showSortFilter === '' ? "default" : "secondary")}
                                    size="small"
                                    onClick={(evt: any) => setShowSortFilter('')}
                                    aria-label={'List Display Fields'}
                        >
                            <Icon fontSize="small">list</Icon>
                        </IconButton>
                    );

                    let sortFieldButton: any = (
                        <IconButton color={(showSortFilter === 'sort' ? "default" : "secondary")}
                                    size="small"
                                    onClick={(evt: any) => setShowSortFilter('sort')}
                                    aria-label={'Default Sort Settings'}
                        >
                            <Icon fontSize="small">sort</Icon>
                        </IconButton>
                    );

                    let filterFieldButton: any = (
                        <IconButton color={(showSortFilter === 'filter' ? "default" : "secondary")}
                                    size="small"
                                    onClick={(evt: any) => setShowSortFilter('filter')}
                                    aria-label={'Default Filter Settings'}
                        >
                            <Icon fontSize="small">filter</Icon>
                        </IconButton>
                    );

                    let saveSettingsToSocialSolutionElement: any = null;
                    // if (profileComparisonToSolution) {
                    //     // console.log('%c profileComparisonToSolution: %O', 'color: purple;', profileComparisonToSolution);
                    //     let solutionSet = profileComparisonToSolution.solutionSet;
                    //     let profileSet = profileComparisonToSolution.profileSet;
                    //     let identical = profileComparisonToSolution.identical;
                    //     // console.log('%c identical: %O', 'color: purple;', identical);
    
                    //     let updateSolutionWithCurrentButton = null;
                    //     if (udicciContext.userIsUlysses() && !identical) {
                    //         updateSolutionWithCurrentButton = (
                    //             <div>
                    //                 <Button variant="outlined"
                    //                         color="primary"
                    //                         onClick={this.updateSolutionWithCurrentProfileSettings.bind(this)}
                    //                 >
                    //                     Update Social Solution with Current Settings
                    //                 </Button>
                    //             </div>
                    //         );
                    //     }
    
                    //     if (!solutionSet) {
                    //         saveSettingsToSocialSolutionElement = (
                    //             <div>
                    //                 <Typography component="div" variant="body2" color='textSecondary'>
                    //                     The Default Social Solution settings for this mediator have not been setup.
                    //                 </Typography>
                    //                 <Typography component="div" variant="body2" color='textSecondary'>
                    //                     To set the current Display Fields, Sort and Filter settings as the Social Solution
                    //                     default settings, click the button below to update the Social Solution.
                    //                 </Typography>
                    //                 {updateSolutionWithCurrentButton}
                    //             </div>
                    //         );
                    //     } else {
                    //         let listIdentical = profileComparisonToSolution.listSettingsComparison.identical;
                    //         if (identical) {
                    //             saveSettingsToSocialSolutionElement = (
                    //                 <div>
                    //                     <Typography component="div" variant="caption">
                    //                         The Default Social Solution settings for this mediator are the same as the 
                    //                         current profile settings.  No action is available unless there are differences.
                    //                     </Typography>
                    //                 </div>
                    //             );
                    //         } else if (listIdentical) {
                    //             let revertToSolutionSettingsButton = null;
                    //             if (profileComparisonToSolution && profileComparisonToSolution.profileSet && profileComparisonToSolution.solutionSet) {
                    //                 let revertToSolutionSettingsIconDisabled = true;
                    //                 let revertToSolutionSettingsIconLabel = 'Revert to Social Solution Default Settings';
                    //                 let listSortSettingsIdentical = profileComparisonToSolution.listSettingsComparison.sortIdentical;
                    //                 // console.log('%c listSortSettingsIdentical: %O', 'color: purple;', listSortSettingsIdentical);
                    //                 if (listSortSettingsIdentical) {
                    //                     revertToSolutionSettingsIconLabel = 'Profile settings are the same as Social Solution settings';
                    //                 } else {
                    //                     revertToSolutionSettingsIconDisabled = false;
                    //                 }
                    //                 let revertToSolutionSettingsIconButton = (
                    //                     <IconButton color="primary" size="small">
                    //                         <RevertToSolutionSettingsIcon fontSize="small" />
                    //                     </IconButton>
                    //                 );
                    //                 revertToSolutionSettingsButton = (
                    //                     <div>
                    //                         <ButtonBase color="primary"
                    //                                     aria-label={revertToSolutionSettingsIconLabel}
                    //                                     title={revertToSolutionSettingsIconLabel}
                    //                                     disabled={revertToSolutionSettingsIconDisabled}
                    //                                     onClick={this.updateProfileWithCurrentSolutionSettings.bind(this, 'all')}
                    //                         >
                    //                             {revertToSolutionSettingsIconButton}
                    //                             Revert all settings to Social Solution Default Settings
                    //                         </ButtonBase>
                    //                     </div>
                    //                 );
                    //             }
    
                    //             saveSettingsToSocialSolutionElement = (
                    //                 <div>
                    //                     <Typography component="div" variant="subtitle2" color="primary">
                    //                         The Default Social Solution settings for this mediator's list 
                    //                         settings are the same as the profile's list settings.  No action
                    //                         is necessary at this time.
                    //                     </Typography>
                    //                     <Typography component="div" variant="caption" color="secondary">
                    //                         Other mediator settings on the Default Social Solution are  
                    //                         different than the profile settings.
                    //                     </Typography>
                    //                     {revertToSolutionSettingsButton}
                    //                     {updateSolutionWithCurrentButton}
                    //                 </div>
                    //             );
                    //         } else if (!listIdentical) {
                    //             let revertToSolutionSettingsButton = null;
                    //             if (profileComparisonToSolution && profileComparisonToSolution.profileSet && profileComparisonToSolution.solutionSet) {
                    //                 let revertToSolutionSettingsIconDisabled = true;
                    //                 let revertToSolutionSettingsIconLabel = 'Revert to Social Solution Default Settings';
                    //                 let listSettingsIdentical = profileComparisonToSolution.listSettingsComparison.identical;
                    //                 // console.log('%c listSettingsIdentical: %O', 'color: purple;', listSettingsIdentical);
                    //                 if (listSettingsIdentical) {
                    //                     revertToSolutionSettingsIconLabel = 'Profile settings are the same as Social Solution settings';
                    //                 } else {
                    //                     revertToSolutionSettingsIconDisabled = false;
                    //                 }
                    //                 revertToSolutionSettingsButton = (
                    //                     <div>
                    //                         <Button color="primary"
                    //                                 variant="outlined"
                    //                                 aria-label={revertToSolutionSettingsIconLabel}
                    //                                 title={revertToSolutionSettingsIconLabel}
                    //                                 disabled={revertToSolutionSettingsIconDisabled}
                    //                                 onClick={this.updateProfileWithCurrentSolutionSettings.bind(this, 'all')}
                    //                         >
                    //                             <RevertToSolutionSettingsIcon fontSize="small" />
                    //                             Revert all settings to Social Solution Default Settings
                    //                         </Button>
                    //                     </div>
                    //                 );
                    //             }
    
                    //             let displayFieldsIdentical = profileComparisonToSolution.listSettingsComparison.displayFieldsIdentical;
                    //             let filterIdentical = profileComparisonToSolution.listSettingsComparison.filterIdentical;
                    //             let sortIdentical = profileComparisonToSolution.listSettingsComparison.sortIdentical;
    
                    //             let displayFieldsIdenticalElement = null;
                    //             if (displayFieldsIdentical) {
                    //                 displayFieldsIdenticalElement = (
                    //                     <Typography component="div" variant="caption" color="primary">
                    //                         The Default Social Solution Displayed Field settings for this mediator's list are 
                    //                         the same as the profile's Displayed Field settings for this Feature and Mediator.
                    //                     </Typography>
                    //                 );
                    //             } else {
                    //                 displayFieldsIdenticalElement = (
                    //                     <Typography component="div" variant="caption" color="secondary">
                    //                         The Displayed Field settings on the Default Social Solution are different 
                    //                         than the profile Displayed Field settings for this Feature and Mediator.
                    //                     </Typography>
                    //                 );
                    //             }
    
                    //             let sortIdenticalElement = null;
                    //             if (sortIdentical) {
                    //                 sortIdenticalElement = (
                    //                     <Typography component="div" variant="caption" color="primary">
                    //                         The Default Social Solution Sort settings for this mediator's list are 
                    //                         the same as the profile's Sort settings for this Feature and Mediator.
                    //                     </Typography>
                    //                 );
                    //             } else {
                    //                 sortIdenticalElement = (
                    //                     <Typography component="div" variant="caption" color="secondary">
                    //                         The Sort settings on the Default Social Solution are different 
                    //                         than the profile Sort settings for this Feature and Mediator.
                    //                     </Typography>
                    //                 );
                    //             }
    
                    //             let filterIdenticalElement = null;
                    //             if (filterIdentical) {
                    //                 filterIdenticalElement = (
                    //                     <Typography component="div" variant="caption" color="primary">
                    //                         The Default Social Solution Filter settings for this mediator's list are 
                    //                         the same as the profile's Filter settings for this Feature and Mediator.
                    //                     </Typography>
                    //                 );
                    //             } else {
                    //                 filterIdenticalElement = (
                    //                     <Typography component="div" variant="caption" color="secondary">
                    //                         The Filter settings on the Default Social Solution are different 
                    //                         than the profile Filter settings for this Feature and Mediator.
                    //                     </Typography>
                    //                 );
                    //             }
    
                    //             saveSettingsToSocialSolutionElement = (
                    //                 <div>
                    //                     {displayFieldsIdenticalElement}
                    //                     {sortIdenticalElement}
                    //                     {filterIdenticalElement}
                    //                     {revertToSolutionSettingsButton}
                    //                     {updateSolutionWithCurrentButton}
                    //                 </div>
                    //             );
                    //         }
                    //     }
                    // }

                    let listDisplayOptions = [
                        { label: 'Default', value: '' },
                        { label: 'List', value: 'List' },
                        { label: 'Card Grid', value: 'Card Grid' },
                        { label: 'Table', value: 'Table' }
                    ];

                    let pagingOptionsElement: any = null;
                    let numberInputProps: any = {};
                    // let numberInputProps: any = { inputComponent: StandardNumberInput };
                    if (pagingStyle === 'Continuous') {
                        pagingOptionsElement = (
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ margin: '8px' }}>
                                    <InputLabel>Page Size</InputLabel>
                                    <TextField fullWidth
                                               type="number"
                                               value={pageSize}
                                               InputProps={numberInputProps}
                                               InputLabelProps={{ shrink: true }}
                                               onChange={(evt: any) => onListPageOptionChanged('pageSize', evt)}
                                               margin="normal"
                                               variant='outlined'
                                    />
                                </Box>
                                <Box sx={{ margin: '8px' }}>
                                    <InputLabel>Next Page Button</InputLabel>
                                    <TextField fullWidth
                                               value={nextPageLabel}
                                               onChange={(evt: any) => onListPageOptionChanged('nextPageLabel', evt)}
                                               margin="normal"
                                               variant='outlined'
                                    />
                                </Box>
                            </Box>
                        );
                    }
                    if (pagingStyle === 'Paged') {
                        pagingOptionsElement = (
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ margin: '8px' }}>
                                    <InputLabel htmlFor="select-list-paging-style">Page Size</InputLabel>
                                    <TextField type="number"
                                               value={pageSize}
                                               InputProps={numberInputProps}
                                               InputLabelProps={{ shrink: true }}
                                               onChange={(evt: any) => onListPageOptionChanged('pageSize', evt)}
                                               margin="normal"
                                               variant='outlined'
                                    />
                                </Box>
                                <Box sx={{ margin: '8px' }}>
                                    <InputLabel>Next Page Button Label</InputLabel>
                                    <TextField value={nextPageLabel}
                                               onChange={(evt: any) => onListPageOptionChanged('nextPageLabel', evt)}
                                               margin="normal"
                                               variant='outlined'
                                    />
                                </Box>
                                <Box sx={{ margin: '8px' }}>
                                    <InputLabel>Previous Page Button Label</InputLabel>
                                    <TextField value={prevPageLabel}
                                               onChange={(evt: any) => onListPageOptionChanged('prevPageLabel', evt)}
                                               margin="normal"
                                               variant='outlined'
                                    />
                                </Box>
                            </Box>
                        );
                    }
                    if (pagingStyle === 'Individual') {
                        pagingOptionsElement = (
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ margin: '8px' }}>
                                    <Typography variant="caption" color="textPrimary" component="span">Page Size</Typography>
                                    &nbsp;
                                    <Typography variant="subtitle2" color="primary" component="span">1</Typography>
                                </Box>
                            </Box>
                        );
                    }

                    let listOptionsTabContent = (
                        <Box sx={{ margin: '8px' }}>
                            <Box sx={{ margin: '8px' }}>
                                <FormControl fullWidth>
                                    <Typography variant="overFieldLabel">Select List Type</Typography>
                                    <Select value={listType}
                                            id='select-list-type'
                                            onChange={(evt: any) => onListTypeChanged(evt)}
                                    >
                                        <MenuItem disabled value="">
                                            <em>Select Type of List</em>
                                        </MenuItem>
                                        {listDisplayOptions.map(listTypeOption => {
                                            // console.log('%c listTypeOption: %O', 'color: red;', listTypeOption);
                                            return (
                                                <MenuItem key={'select.list.display.' + listTypeOption.value}
                                                        value={listTypeOption.value}
                                                >
                                                    {listTypeOption.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ margin: '8px' }}>
                                <FormControl fullWidth>
                                    <Typography variant="overFieldLabel">Select List Paging Style</Typography>
                                    <Select value={pagingStyle}
                                            id='select-list-paging-style'
                                            onChange={(evt: any) => onListPagingStyleChanged(evt)}
                                    >
                                        <MenuItem disabled value=""><em>Select Style of Paging</em></MenuItem>
                                        <MenuItem value="Continuous">Continuous</MenuItem>
                                        <MenuItem value="Paged">Paged</MenuItem>
                                        <MenuItem value="Individual">Individual</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            {pagingOptionsElement}
                            <Box>
                                {listDisplayFieldsButton}
                                {sortFieldButton}
                                {filterFieldButton}
                            </Box>
                            {fieldSettingsDisplayElement}
                            {saveSettingsToSocialSolutionElement}
                        </Box>
                    );

                    tabContainer = (<TabPanel>{listOptionsTabContent}</TabPanel>);
                } else if (mediatorTabIndex === 1) {
                    let formOptionsTabContent = (
                        <div>
                            This is going to be the form options content.
                        </div>
                    );
        
                    tabContainer = (<TabPanel>{formOptionsTabContent}</TabPanel>);
                }

                assignedMediatorsElement = (
                    <Box>
                        <AppBar position="static" sx={{ background: '#eee' }}>
                            <Tabs value={mediatorTabIndex}
                                  onChange={(evt: any, value: number) => handleTabChange(evt, value)}
                                  indicatorColor="primary"
                                  textColor="primary"
                            >
                                <Tab label="List Options" />
                                <Tab label="Form Options" />
                            </Tabs>
                        </AppBar>
                        {tabContainer}
                    </Box>
                );
            } else {
                if (featureMediatorSettings && featureMediatorSettings.length > 0) {
                    let sortedFeatureMediators = featureMediatorSettings.sort((a: any,b: any) => {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;  // ascending
                        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;  // descending
                        return 0 //default return value (no sorting)
                    });
                    // console.log('%c sortedFeatureMediators: %O', 'color: red;', sortedFeatureMediators);

                    if (assignFeatureMediators) {
                        featureMediatorsIconElement = (
                            <IconButton color="secondary"
                                        onClick={(evt: any) => setAssignFeatureMediators(false)}
                                        aria-label={"Hide Mediator Assignment"}
                                        sx={{ float: 'right' }}
                            >
                                <Icon>minimize</Icon>
                            </IconButton>
                        );
                    } else {
                        featureMediatorsIconElement = (
                            <Typography component="span" variant="subtitle1" color='secondary' noWrap={true} sx={{ marginLeft: '4px' }}>
                                {sortedFeatureMediators.length}
                            </Typography>
                        );

                        addFeatureMediatorChip = (
                            <IconButton color="secondary"
                                        onClick={(evt: any) => setAssignFeatureMediators(true)}
                                        aria-label={"Add Mediator to Feature"}
                                        sx={{ float: 'right' }}
                            >
                                <Icon>checklist</Icon>
                            </IconButton>
                        );

                        assignedMediatorsElement = (
                            <div>
                                {sortedFeatureMediators.map((mediator: any) => {
                                    // if (!mediator.mediatorName) return true;

                                    selectedMediators.push(mediator.name);
                                    return (
                                        <Chip key={mediator.id}
                                                color="primary"
                                                onDelete={(evt: any) => removeFeatureMediator(mediator)}
                                                icon={(<Icon>settings</Icon>)}
                                                clickable
                                                onClick={(evt: any) => setChangeMediator(mediator)}
                                                variant="outlined"
                                                label={mediator.name}
                                                sx={{ margin: '4px' }}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }

                    featureMediatorsHeader = (
                        <Fragment>
                            <Typography component="span" variant="subtitle1" color='primary' noWrap={true}>
                                Mediators assigned to this feature:
                            </Typography>
                            {featureMediatorsIconElement}
                            {addFeatureMediatorChip}
                        </Fragment>
                    );
                } else {
                    let assignButton = null;
                    if (assignFeatureMediators) {
                        assignButton = (
                            <IconButton color="secondary"
                                        onClick={(evt: any) => setAssignFeatureMediators(false)}
                                        aria-label="Hide Mediator Assignment"
                                        >
                                <Icon>minimize</Icon>
                            </IconButton>
                        );
                    } else {
                        assignButton = (
                            <ButtonBase color='secondary' centerRipple onClick={(evt: any) => setAssignFeatureMediators(true)}>
                                <Typography variant="subtitle1" color='secondary' noWrap={true}>
                                    Click here to setup structure for this feature.
                                </Typography>
                            </ButtonBase>
                        );
                    }
                    featureMediatorsHeader = (
                        <Fragment>
                            <Typography component="span" variant="subtitle1" color='primary' noWrap={true}>
                                This feature has no structure.
                            </Typography>
                            {assignButton}
                        </Fragment>
                    );
                }
            }

            if (assignFeatureMediators) {
                if (allMediators) {
                    let sortedMediators = allMediators.sort((a: any,b: any) => {
                        if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;  // ascending
                        if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;  // descending
                        return 0 //default return value (no sorting)
                    });
                    // console.log('%c sortedMediators: %O', 'color: red;', sortedMediators);
    
                    let unassignedMediatorOptions: any[] = [];
                    unassignedMediatorOptions.push({ label: 'Select Mediator to Assign', id: 0 });
                    forEach(sortedMediators, (mediator: any, idx: number) => {
                        // console.log('%c mediator: %O', 'color: red;', mediator);

                        let assignedToFeature = false;
                        let assignedMediator = (socialSolutionMediatorSettings && socialSolutionMediatorSettings[mediator.title] ? socialSolutionMediatorSettings[mediator.title] : null);
                        // console.log('%c assignedMediator: %O', 'color: red;', assignedMediator);
                        if (assignedMediator) {
                            let medFeature = (assignedMediator && assignedMediator.feature ? assignedMediator.feature : null);
                            // console.log('%c medFeature: %O', 'color: red;', medFeature);
                            if (medFeature === featureId) assignedToFeature = true;

                            let medFeatures = (assignedMediator && assignedMediator.features ? assignedMediator.features : null);
                            // console.log('%c medFeatures: %O', 'color: red;', medFeatures);

                            if (medFeatures) {
                                let fndFtr = medFeatures.find(function(ftr: any) {
                                    return (ftr.id === featureId)
                                });
                                // console.log('%c fndFtr: %O', 'color: red;', fndFtr);
                                if (fndFtr) assignedToFeature = true;
                            }
                        }
                        // console.log('%c assignedToFeature: %O', 'color: red;', assignedToFeature);

                        if (!assignedToFeature) {
                            unassignedMediatorOptions.push({ label: mediator.title, id: mediator.recordId });
                        }
                    });

                    let selectMediatorLabel: any = (<Typography variant="overFieldLabel">Select Mediators</Typography>);
                    assignMediatorsElement = (
                        <Box sx={{ padding: '4px', paddingTop: '16px' }}>
                            <Autocomplete disablePortal fullWidth multiple
                                          options={unassignedMediatorOptions}
                                          renderInput={(params) => <TextField {...params} label={selectMediatorLabel} />}
                                          onChange={(evt: any, newValue: string | string[] | null) => onMediatorSelectionChanged(evt, newValue)}
                            />
                        </Box>
                    );
                }
            }
    
            let mediatorsElement = (
                <Box>
                    {featureMediatorsHeader}
                    {featureMediatorsToggleElement}
                    {assignMediatorsElement}
                    {assignedMediatorsElement}
                </Box>
            );

            featureContentElement = (
                <Paper elevation={3} sx={{ margin: '8px', padding: '8px' }}>
                    {mediatorsElement}
                </Paper>
            );
            break;
        case 'access':
            featureContentElement = (
                <Paper elevation={3} sx={{ padding: '8px' }}>
                    <Typography variant="caption">Feature Access Restrictions:</Typography>
                    <AssignRoleAccess roleOverrides={assignedRoles}
                                      assignableMediators={socialSolutionMediatorSettings}
                                      onApplyChanges={applyRoleChanges}
                                      onAddRole={addNewRole}
                                      onRemoveRole={removeRole}
                                      onAddOverride={addNewRoleOverride}
                                      onRemoveOverride={removeRoleOverride}
                    />
                </Paper>
            );
            break;
        case 'settings':
            featureContentElement = (
                <Paper elevation={3} sx={{ padding: '8px' }}>
                    <Typography variant="caption">Feature Settings:</Typography>
                </Paper>
            );
            break;
        default:
            featureContentElement = (
                <Paper elevation={3} sx={{ padding: '8px' }}>
                    <Typography variant="caption">Click on button above to update this feature.</Typography>
                </Paper>
            );
            break;
    }

    return (
        <Box>
            <ButtonGroup variant="text" color="primary">
                <Button color={(currentDisplay === 'details' ? "primary" : "info")} onClick={(evt: any) => setDisplay('details')}>Details</Button>
                <Button color={(currentDisplay === 'mediators' ? "primary" : "info")} onClick={(evt: any) => setDisplay('mediators')}>Mediators</Button>
                <Button color={(currentDisplay === 'access' ? "primary" : "info")} onClick={(evt: any) => setDisplay('access')}>Access Restrictions</Button>
                <Button color={(currentDisplay === 'settings' ? "primary" : "info")} onClick={(evt: any) => setDisplay('settings')}>Settings</Button>
            </ButtonGroup>
            {featureContentElement}
        </Box>
    )
}

export default UdicciFeatureAdmin;
