import { createContext, useContext, Dispatch, useReducer, useEffect, useState } from 'react'
import { forEach } from 'underscore';

import { ContextSubscriber } from 'src/classes/udicci-types';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

const defaultContextValue: any = {
    pane: null,
    contentDesign: null,
    paneIndex: -1, 
    designNodeId: ''
};

const PaneContext = createContext<{
    state: any;
    dispatch: Dispatch<any>;
}>({
    state: defaultContextValue,
    dispatch: () => null
});

var subscribers: ContextSubscriber[] = [];

const notifySubscribers = (state: any = null) => {
    // console.log('%c PaneContext notifySubscribers: %O', 'color: green;', state);
    subscribers.forEach((sub: ContextSubscriber) => {
        // console.log('%c sub: %O', classLogStyle, sub);
        var cb = sub.callback;
        if (cb) cb(state);
    });
}

export const subscribe = (subcriberId: string, cbFunc: Function) => {
    var foundSub = false;
    subscribers.forEach((sub: ContextSubscriber, idx: number) => {
        // console.log('%c sub: %O', 'color: blue;', sub);
        if (sub.subscriber === subcriberId) {
            foundSub = true;
            subscribers[idx].callback = cbFunc;
        }
    });

    if (!foundSub) {
        var newSub: ContextSubscriber = { subscriber: subcriberId, callback: cbFunc }
        subscribers.push(newSub)
    }
}

export const unsubscribe = (subcriberId: string) => {
    var newSubscriberList: ContextSubscriber[] = [];
    subscribers.forEach((sub: ContextSubscriber, idx: number) => {
        // console.log('%c sub: %O', 'color: blue;', sub);
        if (sub.subscriber !== subcriberId) {
            newSubscriberList.push(sub);
        }
    });
    subscribers = newSubscriberList;
}

const paneFactory = (state: any, action: any) => {
    // console.log('%c paneFactory state: %O', 'color: purple; font-weight: bold;', state);
    // console.log('%c paneFactory action: %O', 'color: purple; font-weight: bold;', action);

    var payload: any | null = (action && action.payload ? action.payload : null);

    var pPane: any = (payload && payload.pane ? payload.pane : null);
    if (!pPane) pPane = state.pane;

    var pContentDesign: any = (payload && payload.contentDesign ? payload.contentDesign : null);
    if (!pContentDesign) pContentDesign = state.contentDesign;

    switch (action.type.toString().toLowerCase()) {
        case 'refresh': {
            // console.log('%c refresh pPane: %O', 'color: blue;', pPane);
            state.pane = pPane;
            state.contentDesign = pContentDesign;
            // console.log('%c refresh state: %O', 'color: blue;', state);
            break;
        }
        default: {
            // console.log('%c paneFactory state: %O', 'color: purple; font-weight: bold;', state);
            // console.log('%c paneFactory action: %O', 'color: purple; font-weight: bold;', action);
        }
    }

    // console.log('%c PaneContext paneFactory state: %O', 'color: green;', state);
    return state;
}

function PaneContextProvider(props: any) {
    // console.log('%c PaneContextProvider props: %O', 'color: red;', props);

    const udicciHelpers = useUdicciHelpers();

    const [state, dispatch] = useReducer(paneFactory, defaultContextValue);
    // console.log('%c PaneContextProvider state: %O', 'color: red;', state);

    const [contextLevels, setContextLevels] = useState<any>(null);
    const [levelsSet, setLevels] = useState<boolean>(false);
    // console.log('%c PaneDesigner contextLevels: %O', 'color: maroon;', contextLevels);
    // console.log('%c PaneDesigner levelsSet: %O', 'color: maroon;', levelsSet);

    useEffect(() => {
        // console.log('%c PaneContextProvider useEffect props: %O', 'color: red;', props);

        var lpane: any = (props && props.pane !== undefined ? props.pane : defaultContextValue.pane);
        // console.log('%c PaneContextProvider lpane: %O', 'color: red;', lpane);

        var lcontentDesign: any = (props && props.contentDesign !== undefined ? props.contentDesign : defaultContextValue.contentDesign);
        // console.log('%c PaneContextProvider lcontentDesign: %O', 'color: red;', lcontentDesign);
    
        var lcontextValues: any = {
            pane: lpane,
            contentDesign: lcontentDesign
        };
        // console.log('%c PaneContextProvider useEffect contextValues: %O', 'color: purple;', contextValues);
            
        var dispatchParams = {
            type: 'Refresh',
            payload: lcontextValues
        };
        // console.log('%c PaneContextProvider updateUdicciContextHandler dispatchParams: %O', 'color: red;', dispatchParams);
        dispatch(dispatchParams);
    }, []);

    const getContextLevels = () => {
        let paneContentDesign: any = (state && state.contentDesign ? state.contentDesign : null) 
        // console.log('%c getContextLevels paneContentDesign: %O', 'color: maroon;', paneContentDesign);
        if (paneContentDesign && !levelsSet) {
            let rows: any = paneContentDesign.rows;
            // console.log('%c getContextLevels rows: %O', 'color: maroon;', rows);
            if (rows && rows.length > 0) {
                forEach(rows, (row: any) => {
                    let columns: any = row.cells;
                    // console.log('%c getContextLevels columns: %O', 'color: maroon;', columns);
                    if (columns && columns.length > 0) {
                        forEach(columns, (cell: any) => {
                            let plugin: any = (cell && cell.plugin && cell.plugin.id ? cell.plugin.id : '');
                            // console.log('%c getContextLevels plugin: %O', 'color: maroon;', plugin);
                            if (plugin === 'udicci.context.view') {
                                let dataI18n: any = cell.dataI18n;
                                // console.log('%c getContextLevels dataI18n: %O', 'color: maroon;', dataI18n);
                                let cv: any = (dataI18n && dataI18n.default && dataI18n.default.contextView ? dataI18n.default.contextView : null);
                                // console.log('%c getContextLevels cv: %O', 'color: maroon;', cv);
                                setLevels(true);
                                setContextLevels(cv);
                            }
                        });
                    }
                });
            }
        }
    }

    var stateUpdate: any = {
        pane: (state && state.pane ? state.pane : null),
        contentDesign: (state && state.contentDesign ? state.contentDesign : null),
        paneIndex: -1, 
        designNodeId: ''
    };
    // console.log('%c PaneContext.Provider stateUpdate: %O', 'color: red;', stateUpdate);

    const value = { state: stateUpdate, dispatch };
    // console.log('%c PaneContext.Provider value: %O', 'color: red;', value);
    return (
        <PaneContext.Provider value={value}>
            {props.children}
        </PaneContext.Provider>
    );
}

function usePaneContext() {
    const context = useContext(PaneContext)
    if (context === undefined) {
        throw new Error('usePaneContext must be used within a PaneContextProvider');
    }
    return context
}

export {PaneContextProvider, usePaneContext}
