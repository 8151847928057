import { useReducer } from 'react';

import { UdicciRecord } from 'src/classes/udicci-record';
import { Udicci } from 'src/classes/udicci-factory';
import { RecordFactory } from 'src/classes/record-factory';
import { ActionMap, UdicciListActions } from 'src/classes/udicci-types';

import { UdicciPorta } from 'src/classes/udicci-types'
import { UdicciContext, udicciFactory } from 'src/context/udicci-context'
// import { UdicciContext, UdicciContextDataType } from 'src/interfaces/udicci-context-interfaces';

export enum Types {
    Refresh = 'REFRESH',
    Create = 'CREATE',
    Delete = 'DELETE',
}

type Mediator = {
    mediator: string;
    records: UdicciRecord[];
    structure: any
}

export type PortaDataPayload = {
    porta?: UdicciPorta | null | undefined;
    showDesignerMenu?: boolean | null | undefined;
    showConsole?: boolean | null | undefined;
    showDemo?: boolean | null | undefined;
};

// type PortaPayload = {
//     data: PortaDataPayload;
// };

type UdicciActionPayload = {
    [Types.Refresh] : Mediator | PortaDataPayload | any;
    [Types.Create] : Mediator;
    [Types.Delete]: Mediator;
}

export type UdicciActions = ActionMap<UdicciActionPayload>[keyof ActionMap<UdicciActionPayload>];

export const udicciRecordsReducer = (state: any, action: UdicciActions, udicci: Udicci) => {
    // console.log('%c udicciRecordsReducer state: %O', 'color: red;', state);
    // console.log('%c udicciRecordsReducer action: %O', 'color: red;', action);
    // console.log('%c udicciRecordsReducer udicci: %O', 'color: red;', udicci);
    switch (action.type.toLowerCase()) {
        case Types.Refresh.toLowerCase():
            // state.data = [];
            return [...state, ]
        case Types.Create.toLowerCase():
            return [...state, ]
        case Types.Delete.toLowerCase():
            return [...state, ]
        // case 'ADD':
            // return [
            //     ...state,
            //     {
            //         id: action.payload.id,
            //         name: action.payload.name,
            //         price: action.payload.price,
            //     }
            // ]
        default:
            return state;
    }
}

export const udicciRecordReducer = (state: any, action: UdicciActions, factory: RecordFactory, engagementActions: any) => {
    // console.log('%c udicciRecordReducer state: %O', 'color: red;', state);
    // console.log('%c udicciRecordReducer action: %O', 'color: red;', action);
    // console.log('%c udicciRecordReducer factory: %O', 'color: red;', factory);
    // console.log('%c udicciRecordReducer engagementActions: %O', 'color: red;', engagementActions);
    switch (action.type) {
        case 'REFRESH':
            // state.data = [];
            if (factory && factory.record !== undefined) {
                return factory.record;
            } else if (state) {
                return state;
            } else {
                return null;
            }
        // case 'CREATE':
        //     return [...state, ]
        // case 'DELETE':
        //     return [...state, ]
        // case 'ADD':
            // return [
            //     ...state,
            //     {
            //         id: action.payload.id,
            //         name: action.payload.name,
            //         price: action.payload.price,
            //     }
            // ]
        // case 'DELETE':
        //     return [
        //         ...state.filter(product => product.id !== action.payload.id),
        //     ]
        default:
            return state;
    }
}

export const UdicciReducer = (state: any, action: UdicciActions) => {
    // console.log('%c UdicciReducer state: %O', 'color: purple;', state);
    // console.log('%c UdicciReducer action: %O', 'color: purple;', action);
    switch (action.type) {
        case Types.Refresh:
            return {
                ...state,
            }
        default:
            return state;
    }
}
