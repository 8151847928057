import { useTheme } from '@mui/material/styles';

import { Box, Typography, Icon, IconButton, InputAdornment, TextField } from '@mui/material';

import { useUdicciContext } from 'src/context/udicci-context';

import LoginForm from 'src/components/login';

export const LoginFromMenu: React.FC<any> = (props) => {
    let { onCancel, onLoginSuccess } = props;
    var loginDialogElement: any = (<LoginForm onClose={onCancel} onLoginSuccessful={onLoginSuccess} data={{ showInline: false }} />);
    return loginDialogElement;
};
