
import ReactGA from "react-ga4";
import UrlParser from 'url-parse';
import { formatRelative } from 'date-fns';
import browser from 'browser-detect';
// import { HandCashConnect } from '@handcash/handcash-connect';
import { format } from 'date-fns';

import {
    UdicciRequestBase, UrlRequest, SaveRecordRequest, GetPortaFocusBoardRequest,
    DeleteRecordRequest, MediatorStructureRequest, GetFilteredDataRequest, SendFormToEmail
} from 'src/interfaces/udicci-request-interfaces';

import { UdicciRecord, ProfileResponse } from './udicci-record';

import {
    ContextSubscriber, UdicciTheme, UdicciPorta, UdicciPortaMenu, 
    InstructionSet, Instruction, InstructionCondition, InstructionSetLog,
    UdicciUserDetails
} from './udicci-types'

import { forEach, find, findIndex, keys } from 'underscore';

export const UdicciFileStackApiKey: string = process.env.REACT_APP_UDICCI_FILESTACK_API_KEY;

export enum UdicciEnvironment {
    Production = 1,
    Development = 2,
    Local = 3,
}

const ACTIVE_REQUEST_LIST: any[] = [];

// udicci-factory class
export class Udicci {
    showDemo: boolean = false;
    showDesignerMenu: boolean = false;
    showNavigationBar: boolean = true;
    showConsole: boolean = true;
    ulyssesDConstantineProfileId: number = 2647;
    ulyssesDConstantineUserId: number = process.env.REACT_APP_ULYSSES_D_CONSTANTINE_USER_ID;
    defaultSocialIcon: string = 'https://cdn.filestackcontent.com/oQKubvMT3eAMz1MYbb5G';

    standardMenuDrawerWidth: number = 240;

    // 1 Byte = 1 Satoshi = 1 UDC.
    BSV_BASE_COST_PER_BYTE: number = 0.00000001;  // how many Satoshis per UDC
    BSV_PER_100_BYTES: number = 0.000001;  // how many UDCs to write 100 bytes of data

    socialSolutionUdicciIT: number = 163903; // Udicci.IT Social Solution Id
    socialSolutionDefaultMe: number = 163905; // .Me Social Solution Id

    API_URL: string = '';

    textAreaFields: string[] = ['Text','Html'];
    stringFields: string[] = ['String', 'Text', 'Html', 'Json'];
    numberFields: string[] = ['Integer','Decimal','Currency'];
    codeFields: string[] = ['Json','Html Code','Javascript Code','Css Class'];
    dateFields: string[] = ['DateTime'];
    booleanFields: string[] = ['Boolean'];

    isLoadingProfile: boolean = false;
    selectedProfile?: UdicciRecord | null;
    subscribers: ContextSubscriber[];

    profileTypes: UdicciRecord[] = [];

    themes: UdicciTheme[] = [];
    portas: UdicciPorta[] = [];
    portaMenus: UdicciPortaMenu[] = [];

    selectedPorta: UdicciPorta | null = null;

    selectedSocialSolution?: any | null;
    selectedFeature?: any | null;

    currentUser: UdicciUserDetails | null = null;
    currentUserRole: any | null = null;
    currentPublicRole: any | null = null;
    userHasTimedOut: boolean = false;

    instructionProcessingLogs: InstructionSetLog[] | null = null;

    focusBoard: any = null;
    platformIssueReport: any = null;
    nextProductionRelease: Date | null = null;

    signalRHub: any = null;
    messageHistory: any[] = [];

    udicciFileStackApiKey: string = UdicciFileStackApiKey;
    handCashAppIdProd: string = process.env.REACT_APP_UDICCI_HANDCASH_PROD_APP_ID;  // Udicci.IT
    handCashAppIdDev: string = process.env.REACT_APP_UDICCI_HANDCASH_DEV_APP_ID;  // dev.Udicci.IT
    initialUdicciTransactionIdOnMainNet: string = process.env.REACT_APP_UDICCI_INITIAL_TRANSACTION_ID_ON_MAIN_NET;
    selectedHandCashAppId: string = this.handCashAppIdProd;
    handCashConnect: any | null = null;
    defaultChainNetwork: string = 'main';
    ANALYTICS_MEASUREMENT_ID: string = '';

    engageablePortalUrls: string[] = [
        '/tim',
        '/chuck',
        // '/udicci',
        // '/udiccius'
    ];
    developerUsers: string[] = [
        'tim',
        'ulysses.d.constantine',
    ];
    specialUsers: string[] = [
        ...this.developerUsers,
        'chuck',
    ];
    udicciSpecialUsers: string[] = [
        ...this.specialUsers,
        // 'bryan',
    ];

    constructor() {
        this.subscribers = [];

        var userDetailsFromStore = sessionStorage.getItem('udicci.userDetails');

        var userDetails = (userDetailsFromStore ? JSON.parse(userDetailsFromStore) : null);
        // console.log('%c userDetails: %O', 'color: red;', userDetails);
        this.currentUser = userDetails;

        this.setAPIUrl();

        // this.handCashConnect = new HandCashConnect(this.selectedHandCashAppId);
        this.configureHandCashAuth();
    }

    private setAPIUrl(apiToSelect?: number | undefined) {
        // console.log('%c UdicciFactory setAPIUrl apiToSelect: %O', 'color: teal;', apiToSelect);
        var currentUrl = UrlParser(window.location.href, true);
        var { href } = currentUrl;
        // console.log('%c UdicciFactory setAPIUrl currentUrl: %O', 'color: teal;', currentUrl);
        // console.log('%c UdicciFactory setAPIUrl href: %O', 'color: teal;', href);

        // productionAPIUrl: string = process.env.REACT_APP_UDICCI_PROD_API_URL; // UdicciEnvironment.Production  (1)
        // developmentAPIUrl: string = process.env.REACT_APP_UDICCI_DEV_API_URL; // UdicciEnvironment.Development  (2)
        // localAPIUrl: string = process.env.REACT_APP_UDICCI_LOCAL_API_URL; // UdicciEnvironment.Local  (3)

        let overrideAPI: number = 0;

        switch (overrideAPI) {
            case 1: // Production
                this.API_URL = process.env.REACT_APP_UDICCI_PROD_API_URL;
                if (href.indexOf('://localhost') > 0) {
                    this.selectedHandCashAppId = this.handCashAppIdDev;
                } else {
                    this.selectedHandCashAppId = this.handCashAppIdProd;
                }
                break;
            case 2: // Development
                this.API_URL = process.env.REACT_APP_UDICCI_DEV_API_URL;
                // this.API_URL = this.productionAPIUrl;
                this.selectedHandCashAppId = this.handCashAppIdDev;
                break;
            case 3: // Local
                this.API_URL = process.env.REACT_APP_UDICCI_LOCAL_API_URL;
                this.selectedHandCashAppId = this.handCashAppIdDev;
                break;
            default: // use the default environment url
                this.API_URL = process.env.REACT_APP_UDICCI_DEFAULT_API_URL;
                this.selectedHandCashAppId = this.handCashAppIdDev;
                break;
        }
        this.API_URL += 'udicci/mediate';
    }

    isCurrentUserUlysses() {
        if (this.currentUser) {
            if (this.currentUser.UdicciUserId && this.currentUser.UdicciUserId === this.ulyssesDConstantineUserId) {
                return true;
            }
        }
        return false;
    }

    userIsLoggedIn() {
        if (this.currentUser) {
            if (this.currentUser.UdicciUserId && this.currentUser.UdicciUserId > 0) {
                return true;
            }
        }
        return false;
    }

    isMobileDevice() {
        var checkForMobileDevice = {
            Android: function() {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function() {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function() {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function() {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: function() {
                return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
            },
            any: function() {
                return (
                    checkForMobileDevice.Android() 
                    || checkForMobileDevice.BlackBerry() 
                    || checkForMobileDevice.iOS() 
                    || checkForMobileDevice.Opera() 
                    || checkForMobileDevice.Windows()
                );
            }
        };
        var mobDevice = checkForMobileDevice.any();
        return (mobDevice && mobDevice.length > 0 ? true : false);
    }

    formatBytes(bytes: number, decimals: number, binaryUnits: boolean = false) {
        if(bytes === 0) return '0 Bytes';

        var unitMultiple = (binaryUnits) ? 1024 : 1000; 
        var unitNames = (unitMultiple === 1024) ? // 1000 bytes in 1 Kilobyte (KB) or 1024 bytes for the binary version (KiB)
            ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']: 
            ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var unitChanges = Math.floor(Math.log(bytes) / Math.log(unitMultiple));
        return parseFloat((bytes / Math.pow(unitMultiple, unitChanges)).toFixed(decimals || 0)) + ' ' + unitNames[unitChanges];
    }

    openUrl(url: string, target: string = '_self') {
        // console.log('%c openUrl url: %O', 'color: hotpink;', url);
        var currentUrl = UrlParser(window.location.href, true);
        var { pathname } = currentUrl;
        if (url && url.toLowerCase() !== pathname.toLowerCase()) {
            window.open(url, target);
        }
    }

    generateUID() {
        // generate the UID from two parts to ensure the random number provides enough bits.
        var firstPart: number = (Math.random() * 46656) | 0;
        var secondPart: number = (Math.random() * 46656) | 0;
        var thirdPart: number = (Math.random() * 46656) | 0;
        var strFirstPart: string = ("000" + firstPart.toString(36)).slice(-3);
        var strSecondPart: string = ("000" + secondPart.toString(36)).slice(-3);
        var strThirdPart: string = ("000" + thirdPart.toString(36)).slice(-3);
        return strFirstPart + strSecondPart + strThirdPart;
    }

    notifySubscribers(arg1: any = null, arg2: any = null, arg3: any = null) {
        // console.log('%c UdicciFactory notifySubscribers (arg1: %O, arg2: %O, arg3: %O)', 'color: blue;', arg1, arg2, arg3);
        this.subscribers.forEach((sub: ContextSubscriber, idx: number) => {
            // console.log('%c UdicciFactory sub: %O', 'color: blue;', sub);
            var cb = sub.callback;
            if (cb) cb(arg1, arg2, arg3);
        });
    }

    subscribe(subcriberId: string, cbFunc: Function = this.cbUdicciFactory) {
        var foundSub = false;
        this.subscribers.forEach((sub: ContextSubscriber, idx: number) => {
            // console.log('%c sub: %O', 'color: blue;', sub);
            if (sub.subscriber === subcriberId) {
                foundSub = true;
                this.subscribers[idx].callback = cbFunc;
            }
        });

        if (!foundSub) {
            var newSub: ContextSubscriber = { subscriber: subcriberId, callback: cbFunc }
            this.subscribers.push(newSub)
        }
    }

    unsubscribe(subcriberId: string) {
        var newSubscriberList: ContextSubscriber[] = [];
        this.subscribers.forEach((sub: ContextSubscriber, idx: number) => {
            // console.log('%c sub: %O', 'color: blue;', sub);
            if (sub.subscriber !== subcriberId) {
                newSubscriberList.push(sub);
            }
        });
        this.subscribers = newSubscriberList;
    }

    cbUdicciFactory(request: any, response: any) {
        // console.log('%c Udicci cbUdicciFactory request: %O', 'color: maroon;', request);
        // console.log('%c Udicci cbUdicciFactory response: %O', 'color: maroon;', response);
    }

    setNavigationBarState(newState: boolean) {
        // console.log('%c setNavigationBarState newState: %O', 'color: red;', newState);
        this.showNavigationBar = newState;
        this.notifySubscribers();
    }

    setConsoleState(newState: boolean) {
        // console.log('%c setConsoleState newState: %O', 'color: red;', newState);
        this.showConsole = newState;
        this.notifySubscribers();
    }

    toggleDesignMode() {
        // console.log('%c toggleDesignMode newState: %O', 'color: red;', newState);
        var curDesignMode: string | null = sessionStorage.getItem('udicci.design.mode');
        var designMode: string = 'off';
        if (curDesignMode) designMode = curDesignMode.toString();
        designMode = ((designMode !== 'on') ? 'on' : 'off');
        // console.log('%c toggleDesignMode designMode: %O', 'color: red;', designMode);
        sessionStorage.setItem('udicci.design.mode', designMode);
        this.notifySubscribers();
    }

    getRecordInformation(record: UdicciRecord) {
        // console.log('%c getRecordInformation record: %O', 'color: blue;', record);
        // this.notifySubscribers(record);
    }

    setPortalHash(newHash: string) {
        // console.log('%c setPortalHash newHash: %O', 'color: red;', newHash);
        if (newHash) {
            let currentUrl = window.location.href;
            let url = UrlParser(currentUrl, true);

            let { hash } = url;
            // console.log('%c UdicciFactory setPortalHash hash: %O', 'color: red;', hash);
            if (hash !== newHash) window.location.hash = newHash;
        } else {
            window.location.hash = '';
        }
        this.notifySubscribers(this.selectedPorta);
        this.openUrl(window.location.href);
    }

    setPorta(porta: UdicciPorta) {
        // console.log('%c setPorta porta: %O', 'color: red;', porta);
        if (porta) {
            var portaName: string = (porta.Name ? porta.Name : '');
            var stngs = (porta.SettingsJson ? porta.SettingsJson : null);

            let currentUrl = window.location.href;
            let url = UrlParser(currentUrl, true);

            let { hash } = url;
            let portaHash: string = (stngs && stngs.portaUrl ? '#' + stngs.portaUrl : '');
            if (!portaHash && portaName) {
                portaHash = portaName.replace(/[^a-zA-Z0-9]/g,'').trim().toLowerCase();
            }
            // console.log('%c UdicciFactory setPorta hash: %O', 'color: red;', hash);
            // console.log('%c UdicciFactory setPorta portaHash: %O', 'color: red;', portaHash);
            if (hash !== portaHash) window.location.hash = portaHash;
        } else {
            window.location.hash = '';
        }
        this.focusBoard = null;
        this.selectedPorta = porta;
        // console.log('%c setPorta notifySubscribers porta: %O', 'color: red;', porta);
        this.notifySubscribers(porta);
        // console.log('%c setPorta notifySubscribers window.location.href: %O', 'color: red;', window.location.href);
        this.openUrl(window.location.href);
    }

    goToDefaultPorta() {
        // console.log('%c goToDefaultPorta this.portas: %O', 'color: red;', this.portas);
        var defaultPorta = find(this.portas, (p: UdicciPorta) => {
            var stngs = (p.SettingsJson ? p.SettingsJson : null);
            var isDefault: boolean = false;
            if (stngs && stngs.defaultPorta !== undefined && stngs.defaultPorta !== null) {
                isDefault = (stngs.defaultPorta === true ? true : false);
            }
            // console.log('%c goToDefaultPorta defaultPorta: %O', 'color: green;', defaultPorta);
            return isDefault
        });
        this.focusBoard = null;
        // console.log('%c goToDefaultPorta defaultPorta: %O', 'color: green;', defaultPorta);
        if (defaultPorta) this.setPorta(defaultPorta);
    }

    goToLandingPorta() {
        // console.log('%c goToLandingPorta porta: %O', 'color: red;', porta);
        var landingPorta = find(this.portas, (p: UdicciPorta) => {
            var stngs = (p.SettingsJson ? p.SettingsJson : null);
            var isLanding: boolean = false;
            if (stngs && stngs.landingPorta !== undefined && stngs.landingPorta !== null) {
                isLanding = (stngs.landingPorta === true ? true : false);
            }
            return isLanding
        });
        this.focusBoard = null;
        if (landingPorta) this.setPorta(landingPorta);
    }

    getPortaFocusBoard(settings: any) {
        // console.log('%c getPortaFocusBoard recordReference: %O', 'color: red;', recordReference);
        if (!this.selectedPorta) return false;

        this.focusBoard = null;

        var cu = (this.currentUser ? this.currentUser : { UdicciUserId: 0 });
        var currentUserId: number = cu.UdicciUserId;
        var sp = (this.selectedProfile ? this.selectedProfile : { recordId: 0 });
        var profileId: number = sp.recordId;

        let requestJson: GetPortaFocusBoardRequest = {
            UdicciCommand: 'Get Porta Focus Board',
            UdicciMediatorName: 'Portas',
            SelectedUdicciProfileId: profileId,
            UserId: currentUserId,
            SocialSolutionId: this.socialSolutionDefaultMe,
            RecordId: this.selectedPorta.UdicciRecordId
        };
        // console.log('%c getPortaFocusBoard requestJson: %O', 'color: blue;', requestJson);

        let requestSettings: any = {};
        if (settings && typeof(settings) === 'object') Object.assign(requestSettings, settings);

        this.sendPreparedRequest(requestJson, requestSettings);
    };

    setPortaFocusBoard(focusBoard: any) {
        // console.log('%c setPortaFocusBoard focusBoard: %O', 'color: darkcyan; font-weight: bold;', focusBoard);
        // console.log('%c setPortaFocusBoard selectedProfile: %O', 'color: red;', this.selectedProfile);
        if (focusBoard) {
            this.focusBoard = focusBoard;
        } else {
            this.focusBoard = null;
        }
    }

    reportANewIssue() {
        // console.log('%c reportANewIssue selectedProfile: %O', 'color: blue;', this.selectedProfile);
        // console.log('%c reportANewIssue selectedPorta: %O', 'color: blue;', this.selectedPorta);
        // console.log('%c reportANewIssue currentUser: %O', 'color: blue;', this.currentUser);

        let currentUrl = window.location.href;
        let url = UrlParser(currentUrl, true);

        let { href } = url;

        let browserInfo: any = browser();
        browserInfo.url = href;
        // console.log('%c reportANewIssue browserInfo: %O', 'color: green;', browserInfo);

        let profileDisplayName: string = (this.selectedProfile && this.selectedProfile.data ? this.selectedProfile.data.DisplayName : '');
        let profileUrl: string = (this.selectedProfile && this.selectedProfile.data ? this.selectedProfile.data.ProfileUrl : '');
        let profileId: number = (this.selectedProfile && this.selectedProfile.recordId ? this.selectedProfile.recordId : 0);

        let portaName: string = (this.selectedPorta && this.selectedPorta.Name ? this.selectedPorta.Name : '');
        let portaId: number = (this.selectedPorta && this.selectedPorta.UdicciRecordId ? this.selectedPorta.UdicciRecordId : 0);

        let socialSolutionName: string = (this.selectedSocialSolution && this.selectedSocialSolution.data ? this.selectedSocialSolution.data.Name : '');
        let socialSolutionId: number = (this.selectedSocialSolution && this.selectedSocialSolution.recordId ? this.selectedSocialSolution.recordId : 0);

        let username: string = (this.currentUser && this.currentUser.UserName ? this.currentUser.UserName : '');
        let myDisplayName: string = (this.currentUser && this.currentUser.myDisplayName ? this.currentUser.myDisplayName : '');
        let myProfileUrl: string = (this.currentUser && this.currentUser.myProfileUrl ? this.currentUser.myProfileUrl : '');
        let userId: number = (this.currentUser && this.currentUser.UdicciUserId ? this.currentUser.UdicciUserId : 0);
        let userProfileId: number = (this.currentUser && this.currentUser.MeUdicciProfileId ? this.currentUser.MeUdicciProfileId : 0);
        let tokenExpired: boolean = (this.currentUser && this.currentUser.TokenExpired ? true : false);
        let timedOut: boolean = (this.currentUser && this.currentUser.TimedOut ? true : false);

        let newIssue: any = {
            browser: browserInfo,
            profile: {
                displayName: profileDisplayName,
                url: profileUrl,
                id: profileId,
            },
            porta: {
                name: portaName,
                url: portaName,
                id: portaId,
            },
            pane: null,
            plugin: null,
            user: {
                username: username,
                displayName: myDisplayName,
                url: myProfileUrl,
                id: userId,
                userProfileId: userProfileId,
                tokenExpired: tokenExpired,
                timedOut: timedOut,
            },
            perspective: {
                descriptionOfExperience: '',
                descriptionOfExpectation: '',
                errorMessage: '',
            },
            additionalData: {
                apiUrl: this.API_URL,
                userHasTimedOut: this.userHasTimedOut,
                profileId: profileId,
                socialSolution: {
                    name: socialSolutionName,
                    id: socialSolutionId,
                },
            },
        };
        // console.log('%c reportANewIssue newIssue: %O', 'color: maroon;', newIssue);
        this.setPlatformIssueReport(newIssue);
    };

    clearPlatformIssueReport() {
        // console.log('%c setPlatformIssueReport platformIssueReport: %O', 'color: darkcyan; font-weight: bold;', this.platformIssueReport);
        this.platformIssueReport = null;
        this.notifySubscribers(null);
    }

    setPlatformIssueReport(issueReport: any) {
        // console.log('%c setPlatformIssueReport issueReport: %O', 'color: darkcyan; font-weight: bold;', issueReport);
        if (issueReport) {
            this.platformIssueReport = issueReport;
        } else {
            this.platformIssueReport = null;
        }
        this.notifySubscribers(this.platformIssueReport);
    }

    submitPlatformIssueReport() {
        // console.log('%c submitPlatformIssueReport this.platformIssueReport: %O', 'color: darkcyan; font-weight: bold;', this.platformIssueReport);
        if (!this.platformIssueReport) return false;

        var cu = (this.currentUser ? this.currentUser : { UdicciUserId: 0 });
        var currentUserId: number = cu.UdicciUserId;
        var sp = (this.selectedProfile ? this.selectedProfile : { recordId: 0 });
        var profileId: number = sp.recordId;

        let curDate: Date = new Date();

        let emailAuthId: string = process.env.REACT_APP_UDICCI_EMAIL_AUTH_ID;
        let emailAddress: string = 'ITSupport@udicci.com';
        let emailSubject: string = 'Platform Issue Report - Reported on Portal: ' + this.platformIssueReport.profile.displayName;
        let emailMessage: string = '';

        emailMessage += 'A new Platform Issue Report has been submitted on ' + format(curDate, 'MMMM dd, yyyy') + ' at ' + format(curDate, 'h:mi') + '. \r\n\r\n';
        emailMessage += 'The issue was reported on Portal: ' + this.platformIssueReport.profile.displayName + ' by user: ' + this.platformIssueReport.user.displayName;
        emailMessage += "  The details provided for this issue report are below:";
        emailMessage += "\r\n\r\n";
        emailMessage += "Experience:\r\n";
        emailMessage += this.platformIssueReport.perspective.descriptionOfExperience;

        emailMessage += "\r\n\r\n";
        emailMessage += "Expectation:\r\n";
        emailMessage += this.platformIssueReport.perspective.descriptionOfExpectation;

        emailMessage += "\r\n\r\n";
        emailMessage += "Error Message:\r\n";
        emailMessage += this.platformIssueReport.perspective.errorMessage;

        emailMessage += "\r\n\r\n";

        let htmlEmailMessage: string = '';
        let emailFormIncludeKeys: string = '';
        if (this.platformIssueReport) {
            let issueReportKeys: string[] = keys(this.platformIssueReport);
            emailFormIncludeKeys = issueReportKeys.join(',');
        }
        let emailFormValues: string = (this.platformIssueReport ? JSON.stringify(this.platformIssueReport) : '');

        let requestJson: SendFormToEmail = {
            UdicciCommand: 'Send Form To Email',
            UdicciMediatorName: 'Portas',
            SelectedUdicciProfileId: profileId,
            UserId: currentUserId,
            SocialSolutionId: this.socialSolutionDefaultMe,
            EmailAuthId: emailAuthId,
            EmailAddress: emailAddress,
            EmailSubject: emailSubject,
            EmailMessage: emailMessage,
            HtmlEmailMessage: htmlEmailMessage,
            EmailFormIncludeKeys: emailFormIncludeKeys,
            EmailFormValues: emailFormValues,
        };
        // console.log('%c submitPlatformIssueReport requestJson: %O', 'color: blue;', requestJson);
    
        this.sendPreparedRequest(requestJson, {
            onSuccess: (result: any) => this.platformIssueReportSuccess(result),
            onError: (result: any) => this.platformIssueReportFailed(result),
        });
    }

    platformIssueReportSuccess(result: any) {
        // console.log('%c platformIssueReportSuccess result: %O', 'color: red;', result);
        this.platformIssueReport = null;
        this.notifySubscribers(this.platformIssueReport);
    };

    platformIssueReportFailed(result: any) {
        // console.log('%c platformIssueReportFailed result: %O', 'color: red;', result);
        this.notifySubscribers(result);
    };

    portaFocusBoardRequestFailed(response: any) {
        // console.log('%c portaFocusBoardRequestFailed response: %O', 'color: red;', response);
        this.focusBoard = null;
    }

    userTimedOut(expectedTimeout: any) {
        // console.log('%c userTimedOut expectedTimeout: %O', 'color: maroon;', expectedTimeout);
        // console.log('%c userTimedOut expectedTimeout: %O', 'color: maroon;', this.currentUser);
        if (this.currentUser) this.currentUser.TimedOut = true;
        this.notifySubscribers(this.currentUser);
    }

    updatePorta(porta: UdicciPorta) {
        // console.log('%c updatePorta porta: %O', 'color: red;', porta);
        // console.log('%c updatePorta selectedProfile: %O', 'color: red;', this.selectedProfile);
        var prof: any = this.selectedProfile;
        // console.log('%c updatePorta prof: %O', 'color: red;', prof);
        if (prof && prof.data) {
            var portal: any = (prof.data.ProfilePorta ? prof.data.ProfilePorta : null);
            // console.log('%c updatePorta portal: %O', 'color: red;', portal);
            if (portal && portal.portas) {
                var portas: any[] = (portal.portas.length > 0 ? portal.portas : []);
                // console.log('%c updatePorta portas: %O', 'color: red;', portas);
                portas.forEach((prta: any, prtaIdx: number) => {
                    if (porta && porta.UdicciRecordId === prta.UdicciRecordId) {
                        Object.assign(prta, porta);

                        if (prta.SettingsJson) {
                            try {
                                prta.Settings = JSON.stringify(prta.SettingsJson);
                            } catch {
                                prta.Settings = "";
                            }
                        }
                        portas[prtaIdx] = prta;
                        // porta = portas[prtaIdx];
                    }
                });
                // console.log('%c updatePorta portas: %O', 'color: red;', portas);
                portal.portas = portas;
                prof.data.ProfilePorta = portal;
            }
        }
        // console.log('%c updatePorta porta: %O', 'color: red;', porta);

        this.focusBoard = null;
        this.selectedPorta = porta;
        this.selectedProfile = prof;

        // console.log('%c updatePorta selectedPorta: %O', 'color: red;', this.selectedPorta);
        this.notifySubscribers(porta);
    }

    goToPortaUsingUrl() {
        let currentUrl = window.location.href;
        // console.log('%c UdicciFactory goToPortaUsingUrl currentUrl: %O', 'color: green;', currentUrl);
        let url = UrlParser(currentUrl, true);

        let { hash } = url;
        let hashUrl: string = hash.replace('#', '').trim().toLowerCase();
        // console.log('%c UdicciFactory goToPortaUsingUrl hashUrl: %O', 'color: green;', hashUrl);

        let includedPortas: UdicciPorta[] = [];
        var foundPortasByHash = this.portas.filter(function(p: UdicciPorta) {
            var portaName = (p.Name ? p.Name : '');
            var stngs = (p.SettingsJson ? p.SettingsJson : null);
            var includePorta: boolean = false;
            if (hashUrl && stngs && stngs.portaUrl !== undefined && stngs.portaUrl !== null) {
                // console.log('%c stngs.portaUrl: %O', 'color: red;', stngs.portaUrl);
                includePorta = (stngs.portaUrl.toLowerCase().trim() === hashUrl ? true : false);
            }

            if (!includePorta && hashUrl && portaName) {
                // remove all special characters from the name
                let defaultPortaName: string = portaName.replace(/[^a-zA-Z0-9]/g,'').trim().toLowerCase();
                // console.log('%c defaultPortaName: %O', 'color: red;', defaultPortaName);
                includePorta = (defaultPortaName === hashUrl ? true : false);
            }
            // console.log('%c includePorta: %O', 'color: blue;', includePorta);
            return includePorta
        });
        // console.log('%c UdicciFactory profileReceived foundPortasByHash: %O', 'color: green;', foundPortasByHash);

        if (foundPortasByHash && foundPortasByHash.length > 0) {
            includedPortas = foundPortasByHash;
        }
        // console.log('%c UdicciFactory profileReceived includedPortas: %O', 'color: green;', includedPortas);

        var isNewPorta: boolean = false;
        if (includedPortas.length <= 0 && this.selectedPorta && this.selectedPorta.UdicciRecordId === 0) {
            isNewPorta = true;
            let cleanedPortaName: string = this.selectedPorta.Name.replace(/[^a-zA-Z0-9]/g,'').trim().toLowerCase();
            // console.log('%c cleanedPortaName: %O', 'color: red;', cleanedPortaName);
            if (cleanedPortaName === hashUrl) includedPortas.push(this.selectedPorta);
            // console.log('%c includedPortas: %O', 'color: red;', includedPortas);
        }

        // console.log('%c UdicciFactory goToPortaUsingUrl isNewPorta: %O', 'color: green;', isNewPorta);
        // console.log('%c UdicciFactory goToPortaUsingUrl includedPortas: %O', 'color: green;', includedPortas);
        var curDesignMode: string | null = sessionStorage.getItem('udicci.design.mode');
        if (includedPortas.length <= 0 && (!isNewPorta && curDesignMode && curDesignMode === 'on')) {
            // console.log('%c UdicciFactory goToPortaUsingUrl hashUrl: %O', 'color: green;', hashUrl);
            var landingPortaFound: boolean = false;
            var foundPortas: any = this.portas.filter(function(p: UdicciPorta) {
                var stngs = (p.SettingsJson ? p.SettingsJson : null);
                var landingPorta: boolean = false;
                if (hashUrl.length <= 0) {
                    if (stngs && stngs.landingPorta !== undefined && stngs.landingPorta !== null) {
                        landingPorta = (stngs.landingPorta === true ? true : false);
                        if (landingPorta) landingPortaFound = true;
                    }
                }
                return (landingPorta)
            });
            // console.log('%c foundPortas: %O', 'color: blue;', foundPortas);
            if (!landingPortaFound) {
                foundPortas = this.portas.filter(function(p: UdicciPorta) {
                    var stngs = (p.SettingsJson ? p.SettingsJson : null);
                    var defaultPorta: boolean = false;
                    if (stngs && stngs.defaultPorta !== undefined && stngs.defaultPorta !== null) {
                        defaultPorta = (stngs.defaultPorta === true ? true : false);
                    }
                    return (defaultPorta)
                });
            }
            if (foundPortas && foundPortas.length > 0) includedPortas = foundPortas;
        }

        // console.log('%c includedPortas: %O', 'color: blue;', includedPortas);
        if (includedPortas && includedPortas.length > 0) {
            let newSelectedPorta: UdicciPorta = includedPortas[0];
            // console.log('%c newSelectedPorta: %O', 'color: green;', newSelectedPorta);
            if (!this.selectedPorta && newSelectedPorta) {
                this.focusBoard = null;
                this.setPorta(newSelectedPorta);
            } else if (this.selectedPorta && newSelectedPorta) {
                if (this.selectedPorta.UdicciRecordId !== newSelectedPorta.UdicciRecordId) {
                    this.focusBoard = null;
                    this.setPorta(newSelectedPorta);
                }
            }
        }
    };

    getPortaMenus() {
        var rval: UdicciPorta[] = [];
        var prof: any = this.selectedProfile;
        // console.log('%c getPortaMenus prof: %O', 'color: red;', prof);
        if (prof && prof.data) {
            var portal: any = (prof.data.ProfilePorta ? prof.data.ProfilePorta : null);
            // console.log('%c getPortaMenus portal: %O', 'color: red;', portal);
            if (portal && portal.portaMenus) {
                var portaMenus: any[] = (portal.portaMenus.length > 0 ? portal.portaMenus : []);
                // console.log('%c getPortaMenus portaMenus: %O', 'color: red;', portaMenus);
                portaMenus.forEach((pmenu: any, pmIdx: number) => {
                    if (pmenu.Settings && !pmenu.jsonSettings) {
                        try {
                            pmenu.jsonSettings = JSON.parse(pmenu.Settings);
                        } catch {
                            pmenu.jsonSettings = null;
                        }
                    }
                    portaMenus[pmIdx] = pmenu;
                });
                // console.log('%c getPortaMenus portaMenus: %O', 'color: red;', portaMenus);
                rval = portaMenus;
            }
        }
        // console.log('%c getPortas rval: %O', 'color: red;', rval);
        return rval;
    }

    getPortas() {
        var rval: UdicciPorta[] = [];
        var prof: any = this.selectedProfile;
        // console.log('%c getPortas prof: %O', 'color: red;', prof);
        if (prof && prof.data) {
            var portal: any = (prof.data.ProfilePorta ? prof.data.ProfilePorta : null);
            // console.log('%c getPortas portal: %O', 'color: red;', portal);
            if (portal && portal.portas) {
                var portas: any = (portal.portas.length > 0 ? portal.portas : null);
                // console.log('%c getPortas portas: %O', 'color: red;', portas);
                portas.forEach((prta: UdicciPorta, prtIdx: number) => {
                    if (prta.Settings && !prta.SettingsJson) {
                        try {
                            prta.SettingsJson = JSON.parse(prta.Settings);
                        } catch {
                            prta.SettingsJson = null;
                        }
                    }
                    portas[prtIdx] = prta;
                });
                // console.log('%c getPortas portas: %O', 'color: red;', portas);
                rval = portas;
            }
        }
        // console.log('%c getPortas rval: %O', 'color: red;', rval);
        return rval;
    }

    getPortaThemes() {
        var rval: UdicciPorta[] = [];
        var prof: any = this.selectedProfile;
        // console.log('%c getPortaThemes prof: %O', 'color: red;', prof);
        if (prof && prof.data) {
            var portal: any = (prof.data.ProfilePorta ? prof.data.ProfilePorta : null);
            // console.log('%c getPortaThemes portal: %O', 'color: red;', portal);
            if (portal && portal.themes) {
                var themes: any[] = (portal.themes.length > 0 ? portal.themes : []);
                // console.log('%c getPortaThemes themes: %O', 'color: red;', themes);
                themes.forEach((ptheme: any, pmIdx: number) => {
                    if (ptheme.Settings && !ptheme.jsonSettings) {
                        try {
                            ptheme.jsonSettings = JSON.parse(ptheme.Settings);
                        } catch {
                            ptheme.jsonSettings = null;
                        }
                    }
                    themes[pmIdx] = ptheme;
                });
                // console.log('%c getPortaThemes themes: %O', 'color: red;', themes);
                rval = themes;
            }
        }
        // console.log('%c getPortas rval: %O', 'color: red;', rval);
        return rval;
    }

    setSocialSolution(socialSolution: UdicciRecord | null) {
        // console.log('%c setSocialSolution socialSolution: %O', 'color: red;', socialSolution);
        this.selectedSocialSolution = socialSolution;
        this.notifySubscribers(socialSolution);
    }

    setFeature(feature: UdicciRecord | null) {
        // console.log('%c setFeature feature: %O', 'color: red;', feature);
        this.selectedFeature = feature;
        this.notifySubscribers(feature);
    }

    setProfile(profile: UdicciRecord | null) {
        // console.log('%c setProfile profile: %O', 'color: red;', profile);
        this.selectedProfile = profile;
        this.notifySubscribers(profile);
    }

    onChangeProfile(fieldName: string, newValue: any = null) {
        // console.log('%c onChangeProfile selectedProfile: %O', 'color: red;', this.selectedProfile);
        // console.log('%c onChangeProfile fieldName: %O', 'color: red;', fieldName);
        if (this.selectedProfile && this.selectedProfile.data && fieldName) {
            if (fieldName.startsWith('contactCard.') === true) {
                var pdata = (this.selectedProfile.data ? this.selectedProfile.data : null);
                // console.log('%c handleChangeValue pdata: %O', 'color: maroon;', pdata);
                if (pdata) {
                    var pss = (pdata && pdata.jsonProfileSettingsJson ? pdata.jsonProfileSettingsJson : {});
                    var cc = (pss && pss.contactCard ? pss.contactCard : {});
                    var fn = fieldName.replace('contactCard.', '');
                    cc[fn] = newValue;
                    pss.contactCard = cc;
                    pdata.jsonProfileSettingsJson = pss;
                    this.selectedProfile.data = pdata;
                }
            } else {
                this.selectedProfile.data[fieldName] = newValue;
                if (fieldName === 'jsonProfileSettingsJson') {
                    try {
                        this.selectedProfile.data.ProfileSettingsJson = JSON.stringify(this.selectedProfile.data[fieldName]);
                    } catch {
                        this.selectedProfile.data.ProfileSettingsJson = '';
                    }
                }
            }
            // console.log('%c onChangeProfile this.selectedProfile: %O', 'color: maroon;', this.selectedProfile);

            this.selectedProfile.isDirty = true;
            // console.log('%c onChangeProfile this.selectedProfile: %O', 'color: maroon;', this.selectedProfile);
        }

        this.notifySubscribers(this.selectedProfile);
    }

    onSaveProfile(settings: any = null) {
        // console.log('%c onSaveProfile settings: %O', 'color: red;', settings);
        if (!this.currentUser || (this.currentUser && this.currentUser.UdicciUserId > 0)) {
            // console.log('%c onSaveProfile this.currentUser: %O', 'color: red;', this.currentUser);
            if ((this.currentUserRole && this.currentUserRole.IsAdministratorRole) || this.isCurrentUserUlysses()) {
                // console.log('%c onSaveProfile this.selectedProfile: %O', 'color: red;', this.selectedProfile);
                if (this.selectedProfile && this.selectedProfile.isDirty) {
                    this.selectedProfile.isSaving = true;
                    var pd = (this.selectedProfile && this.selectedProfile.data ? this.selectedProfile.data : {});
                    // console.log('%c saveProfile pd: %O', 'color: maroon;', pd);
                    var jsonProfileSettingsJson = (pd.jsonProfileSettingsJson ? pd.jsonProfileSettingsJson: null);
                    // console.log('%c saveProfile jsonProfileSettingsJson: %O', 'color: maroon;', jsonProfileSettingsJson);
        
                    var profileSettingsJson = '';
                    if (jsonProfileSettingsJson) {
                        try {
                            profileSettingsJson = JSON.stringify(jsonProfileSettingsJson);
                        } catch (ex: any) {
        
                        }
                    }
        
                    pd.ProfileSettingsJson = profileSettingsJson;
                    this.selectedProfile.data = pd;

                    if (settings && settings.profileId && settings.profileId !== this.ulyssesDConstantineProfileId) {
                        settings.profileId = this.ulyssesDConstantineProfileId;
                    } else if (!settings) {
                        settings = {
                            profileId: this.ulyssesDConstantineProfileId
                        };
                    }
                    // console.log('%c saveProfile selectedProfile: %O', 'color: maroon;', this.selectedProfile);
                    // console.log('%c saveProfile settings: %O', 'color: maroon;', settings);
                    this.saveRecord(this.selectedProfile, this.socialSolutionUdicciIT, settings);
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }

        this.notifySubscribers(this.selectedProfile);
    }

    getProfileSetting(mediatorName: string, socialSolutionId: number, settingName: string = 'all', useUserProfile: boolean = false) {
        // console.log('%c getProfileSetting mediatorName: %O', 'color: red;', mediatorName);
        // console.log('%c getProfileSetting socialSolutionId: %O', 'color: red;', socialSolutionId);
        // console.log('%c getProfileSetting settingName: %O', 'color: red;', settingName);
        // console.log('%c getProfileSetting settingName: %O', 'color: red;', settingName);

        if (!this.selectedProfile) return null;
        if (!this.selectedProfile.data) return null;

        var profile: any = this.selectedProfile.data;
        if (useUserProfile) {
            profile = this.selectedProfile.data;
        }
        // console.log('%c profile: %O', 'color: hotpink;', profile);
        if (!profile) return null;
    
        var profileSettings = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
        // console.log('%c profileSettings: %O', 'color: hotpink;', profileSettings);
        if (!profileSettings) return null;
    
        const stacks = (profileSettings.stacks ? profileSettings.stacks : null);
        // console.log('%c stacks: %O', 'color: hotpink;', stacks);
        const autoStackerRules = (profileSettings.autoStackerRules ? profileSettings.autoStackerRules : null);
        // console.log('%c autoStackerRules: %O', 'color: hotpink;', autoStackerRules);

        var profilePorta = (profile && profile.ProfilePorta ? profile.ProfilePorta : null);
        // console.log('%c profilePorta: %O', 'color: hotpink;', profilePorta);
        var profileThemes = (profilePorta && profilePorta.themes ? profilePorta.themes : null);
        // console.log('%c profileThemes: %O', 'color: hotpink;', profileThemes);
        var selectedTheme = profileThemes && profileThemes.find(function(theme: any) { return theme.IsDefault === true });
        // console.log('%c selectedTheme: %O', 'color: hotpink;', selectedTheme);
        if (selectedTheme) {
            if (selectedTheme && selectedTheme.Settings && !selectedTheme.jsonSettings) {
                try {
                    selectedTheme.jsonSettings = JSON.parse(selectedTheme.Settings);
                } catch (ex: any) {
                    selectedTheme.jsonSettings = null;
                }
            }
        }

        var psss = (profileSettings && profileSettings.socialSolutionSettings ? profileSettings.socialSolutionSettings : null);
        var pssSettings = (socialSolutionId && psss && psss[socialSolutionId] ? psss[socialSolutionId] : null);
        // console.log('%c pssSettings: %O', 'color: purple;', pssSettings);

        var pssMediators = (pssSettings && pssSettings.mediators ? pssSettings.mediators : null);
        // console.log('%c pssMediators: %O', 'color: purple;', pssMediators);
        if (pssMediators && Array.isArray(pssMediators)) pssMediators = null;
        var ftrMdtrSettings = (pssMediators && pssMediators[mediatorName] ? pssMediators[mediatorName] : null);
        // console.log('%c ftrMdtrSettings: %O', 'color: purple;', ftrMdtrSettings);

        // for listConfig ... we need to check social solution defaults here if the profile list settings are not set
        var listConfig = (ftrMdtrSettings && ftrMdtrSettings.list ? ftrMdtrSettings.list : null);
        // console.log('%c listConfig: %O', 'color: purple;', listConfig);
        var listFilterSettings = (listConfig && listConfig.defaultFilterSettings ? listConfig.defaultFilterSettings : null);
        // console.log('%c listFilterSettings: %O', 'color: purple;', listFilterSettings);
        var listSortSettings = (listConfig && listConfig.defaultSortSettings ? listConfig.defaultSortSettings : null);
        // console.log('%c listSortSettings: %O', 'color: purple;', listSortSettings);
        var listFields = (listConfig && listConfig.fields ? listConfig.fields : null);
        // console.log('%c listFields: %O', 'color: purple;', listFields);
        var listType = (listConfig && listConfig.type ? listConfig.type : 'List');
        // console.log('%c listType: %O', 'color: purple;', listType);

        // console.log('%c settingName.toLowerCase(): %O', 'color: purple;', settingName.toLowerCase());
        var topXRecords = (listConfig && listConfig.topXRecords ? listConfig.topXRecords : null);
        // console.log('%c topXRecords: %O', 'color: purple;', topXRecords);
        var pagingStyle = (listConfig && listConfig.pagingStyle ? listConfig.pagingStyle : null);
        // console.log('%c pagingStyle: %O', 'color: purple;', pagingStyle);
        var pageSize = (listConfig && listConfig.pageSize ? listConfig.pageSize : (pagingStyle !== 'Individual' ? 10 : 1));
        // console.log('%c pageSize: %O', 'color: purple;', pageSize);
        var nextPageLabel = (listConfig && listConfig.nextPageLabel ? listConfig.nextPageLabel : null);
        // console.log('%c nextPageLabel: %O', 'color: purple;', nextPageLabel);
        var prevPageLabel = (listConfig && listConfig.prevPageLabel ? listConfig.prevPageLabel : null);
        // console.log('%c prevPageLabel: %O', 'color: purple;', prevPageLabel);

        var formConfig = (ftrMdtrSettings && ftrMdtrSettings.form ? ftrMdtrSettings.form : null);
        // console.log('%c formConfig: %O', 'color: purple;', formConfig);

        // console.log('%c settingName.toLowerCase(): %O', 'color: purple;', settingName.toLowerCase());
        var rval: any = null;
        switch (settingName.toLowerCase()) {
            case 'all':
            case 'profile':
                rval = profileSettings;
                break;
            case 'porta':
            case 'profileporta':
                rval = profilePorta;
                break;
            case 'themes':
                rval = profileThemes;
                break;
            case 'selectedtheme':
                rval = selectedTheme;
                break;
            case 'solution':
            case 'socialsolution':
                rval = pssSettings;
                break;
            case 'formconfig':
                rval = formConfig;
                break;
            case 'listconfig':
                rval = listConfig;
                break;
            case 'listfiltersettings':
                rval = listFilterSettings;
                break;
            case 'listsortsettings':
                rval = listSortSettings;
                break;
            case 'listfields':
                rval = listFields;
                break;
            case 'listtype':
                rval = listType;
                break;
            case 'topxrecords':
                rval = topXRecords;
                break;
            case 'pagingstyle':
                rval = pagingStyle;
                break;
            case 'pagesize':
                rval = (pagingStyle === 'Individual' ? 1 : pageSize);
                break;
            case 'nextpagelabel':
                rval = nextPageLabel;
                break;
            case 'prevpagelabel':
                rval = prevPageLabel;
                break;
            case 'stacks':
                rval = stacks;
                break;
            case 'autostackerrules':
                rval = autoStackerRules;
                break;
            default:
                rval = profileSettings;
                break;
        }
        return rval;
    }

    sendRequest = async (options: any) => {
        // console.log('%c sendRequest options: %O', 'color: purple;', options);

        // console.log('%c sendRequest this.selectedProfile: %O', 'color: purple;', this.selectedProfile);
        if(!this.selectedProfile || (this.selectedProfile && (!this.selectedProfile.recordId))) return;

        if(!options) return;
        if(!options.mediator || !options.socialSolutionId) return;

        var selectedProfileId: number = 0;
        if (this.selectedProfile) {
            selectedProfileId = this.selectedProfile.recordId;
        } else {
            selectedProfileId = this.ulyssesDConstantineProfileId;
        }
        if (options.profileId) {
            selectedProfileId = options.profileId;
        }
        // console.log('%c sendRequest this.currentUser: %O', 'color: purple;', this.currentUser);
        var request: any = {
            UdicciMediatorName: options.mediator,
            UdicciCommand: options.udicciCommand,
            SelectedUdicciProfileId: selectedProfileId,
            SocialSolutionId: options.socialSolutionId,
            UserId: (this.currentUser !== null && this.currentUser.UdicciUserId ? this.currentUser.UdicciUserId : 0),
        }
        // if (options) {
        //     // console.log('%c options: %O', 'color: purple;', options);
        //     let wo: any = {};
        //     Object.assign(wo, options);
        //     // console.log('%c wo: %O', 'color: purple;', wo);
        //     mapObject(wo, (val: any, key: any) => {
        //         // console.log('%c val: %O', 'color: purple;', val);
        //         // console.log('%c key: %O', 'color: purple;', key);
        //         if (typeof(val) !== 'function') {
        //             if (request[key] === undefined) {
        //                 request[key] = val;
        //             }
        //         }
        //     });
        // }
        // console.log('%c sendRequest request: %O', 'color: purple;', request);
        this.callWebApi(request, options.onSuccess, options.onError);
    }

    sendPreparedRequest = async (request: UdicciRequestBase, options: any = {}) => {
        // console.log('%c sendPreparedRequest request: %O', 'color: purple;', request);
        // console.log('%c sendPreparedRequest options: %O', 'color: purple;', options);

        // console.log('%c sendPreparedRequest this.selectedProfile: %O', 'color: purple;', this.selectedProfile);
        if(!this.selectedProfile || (this.selectedProfile && (!this.selectedProfile.recordId))) return;

        if(!request) return;
        if(!request.UdicciCommand) return;
        if(!request.UdicciMediatorName) return;

        if (request.SelectedUdicciProfileId <= 0) {
            var selectedProfileId: number = 0;
            if (this.selectedProfile) {
                selectedProfileId = this.selectedProfile.recordId;
            } else {
                selectedProfileId = this.ulyssesDConstantineProfileId;
            }
            request.SelectedUdicciProfileId = selectedProfileId;
        }
        if(request.SelectedUdicciProfileId <= 0) return;

        if (request.UserId <= 0 && this.currentUser !== null && this.currentUser.UdicciUserId) {
            request.UserId = this.currentUser.UdicciUserId;
        }
        // console.log('%c sendPreparedRequest request: %O', 'color: purple;', request);
        var onSuccess = ((response: any) => this.sendPreparedRequestSuccess(request, options, response));
        var onFail = ((response: any) => this.sendPreparedRequestFail(request, options, response));
        this.callWebApi(request, onSuccess, onFail);
    }

    sendPreparedRequestSuccess = (request: any, settings: any, response: any) => {
        // console.log('%c sendPreparedRequestSuccess request: %O', 'color: green;', request);
        // console.log('%c sendPreparedRequestSuccess settings: %O', 'color: green;', settings);
        // console.log('%c sendPreparedRequestSuccess response: %O', 'color: green;', response);
        this.notifySubscribers(request, response, settings);
    }

    sendPreparedRequestFail = (request: any, settings: any, response: any) => {
        // console.log('%c sendPreparedRequestFail request: %O', 'color: green;', request);
        // console.log('%c sendPreparedRequestFail settings: %O', 'color: green;', settings);
        // console.log('%c sendPreparedRequestFail response: %O', 'color: green;', response);
        // this.notifySubscribers(request, response, settings);
    }

    getData = async (options: any) => {
        // console.log('%c getData options: %O', 'color: purple;', options);

        // console.log('%c getData this.selectedProfile: %O', 'color: purple;', this.selectedProfile);
        if(!this.selectedProfile || (this.selectedProfile && (!this.selectedProfile.recordId))) {
            if (!options || (options && (!options.profileId))) return;
        }

        if(!options) return;
        if(!options.mediator || !options.socialSolutionId) return;

        var selectedProfileId: number = 0;
        if (this.selectedProfile) {
            selectedProfileId = this.selectedProfile.recordId;
        } else {
            selectedProfileId = this.ulyssesDConstantineProfileId;
        }

        if (options.profileId) selectedProfileId = options.profileId;

        // console.log('%c getData this.currentUser: %O', 'color: purple;', this.currentUser);
        let includeFilters: boolean = false;
        let request: any = null;
        if (options.settings && options.settings.filterSettings && options.settings.filterSettings.length > 0 && options.mediator.toLowerCase() !== 'mediation agreements') {
            includeFilters = true;
            let filteredDataRequest: GetFilteredDataRequest = {
                UdicciMediatorName: options.mediator,
                UdicciCommand: "Fetch List",
                SelectedUdicciProfileId: selectedProfileId,
                SocialSolutionId: options.socialSolutionId,
                UserId: (this.currentUser !== null && this.currentUser.UdicciUserId ? this.currentUser.UdicciUserId : 0),
                Filters: options.settings.filterSettings
            };
            // console.log('%c getData filteredDataRequest: %O', 'color: purple;', filteredDataRequest);
            request = filteredDataRequest;
        } else {
            var baseRequest: UdicciRequestBase = {
                UdicciMediatorName: options.mediator,
                UdicciCommand: (options.mediator.toLowerCase() === 'mediation agreements' ? "Get Mediation Agreements" : "Fetch List"),
                SelectedUdicciProfileId: selectedProfileId,
                SocialSolutionId: options.socialSolutionId,
                UserId: (this.currentUser !== null && this.currentUser.UdicciUserId ? this.currentUser.UdicciUserId : 0),
            }
            request = baseRequest;
        }

        if (options.settings && options.settings.TopXRecords !== undefined) request.TopXRecords = options.settings.TopXRecords;
        if (options.settings && options.settings.PageSize !== undefined) request.PageSize = options.settings.PageSize;
        if (options.settings && options.settings.PageNumber !== undefined) request.PageNumber = options.settings.PageNumber;
        if (options.settings && options.settings.SortField !== undefined) request.SortField = options.settings.SortField;
        if (options.settings && options.settings.SortDirection !== undefined) request.SortDirection = options.settings.SortDirection;
        if (options.settings && options.settings.ParentUdicciRecordIds !== undefined) request.ParentUdicciRecordIds = options.settings.ParentUdicciRecordIds;
        if (options.settings && options.settings.FilterByUdicciRecordIds !== undefined) request.FilterByUdicciRecordIds = options.settings.FilterByUdicciRecordIds;

        // console.log('%c getData request: %O', 'color: purple;', request);
        if (request) this.callWebApi(request, this.getDataCompleted.bind(this, request, options));
    };

    getDataCompleted = (request: any, settings: any, response: any) => {
        // console.log('%c getDataCompleted request: %O', 'color: purple;', request);
        // console.log('%c getDataCompleted settings: %O', 'color: purple;', settings);
        // console.log('%c getDataCompleted response: %O', 'color: purple;', response);
        this.dataReceived(request, response, settings);
    };

    dataReceived = (request: any, response: any, settings: any = null) => {
        // console.log('%c dataReceived request: %O', 'color: green;', request);
        // console.log('%c dataReceived response: %O', 'color: green;', response);
        // console.log('%c dataReceived settings: %O', 'color: green;', settings);

        var structures = (response && response.structures ? response.structures : []);
        // console.log('%c dataReceived structures: %O', 'color: green;', structures);
        var udicciMediatorStructure = (response && response.UdicciMediatorStructure ? response.UdicciMediatorStructure : null);
        if (udicciMediatorStructure) {
            var mediatorStructure = JSON.parse(udicciMediatorStructure);
            // console.log('%c dataReceived mediatorStructure: %O', 'color: green;', mediatorStructure);
            if (mediatorStructure && mediatorStructure.Name) {
                if (!structures[mediatorStructure.Name]) {
                    structures[mediatorStructure.Name] = mediatorStructure;
                }
            }
        }
        // var udicciMediatorName = (response && response.UdicciMediatorName ? response.UdicciMediatorName : '');
        // var structure = (udicciMediatorName && structures && structures[udicciMediatorName] ? structures[udicciMediatorName] : null);
        // var urlProfile = (response && response.UrlProfile ? response.UrlProfile : null);
        // var profileRecord = new UdicciRecord(response.UdicciMediatorName, urlProfile, structure);

        var rvalData: any = {
            data: null,
            permissions: null,
            structures: null,
            linkedData: null,
            mediatedRecords: null,
            fullResponse: response,
            lastUpdate: new Date()
        };
        if (response.ResponseJson) {
            var rj: any = response.ResponseJson;
            if (typeof(rj) !== 'object') {
                try {
                    rj = JSON.parse(response.ResponseJson);
                } catch {
                    rj = response.ResponseJson;
                }
            }
            // console.log('%c dataReceived rj: %O', 'color: green;', rj);
            rvalData.data = rj;
        }
        if (response.MediatedRecords) {
            var mr: any = response.MediatedRecords;
             try {
                 mr = JSON.parse(mr);
             } catch {
                 // noop
             }
            // console.log('%c dataReceived mr: %O', 'color: green;', mr);
            rvalData.mediatedRecords = mr;
        }
        if (response.Permissions) rvalData.permissions = response.Permissions;
        if (response.LinkedData) rvalData.linkedData = response.LinkedData;
        if (structures) rvalData.structures = structures;
        // console.log('%c dataReceived rvalData: %O', 'color: green;', rvalData);
        if (!rvalData.data && !rvalData.permissions && !rvalData.linkedData && response) {
            // console.log('%c dataReceived response: %O', 'color: green;', response);
            rvalData = response;
        }

        // console.log('%c dataReceived request: %O', 'color: green;', request);
        // console.log('%c dataReceived rvalData: %O', 'color: green;', rvalData);
        this.notifySubscribers(request, rvalData, settings);
    }

    getMediationAgreements = async (options: any) => {
        // console.log('%c getMediationAgreements options: %O', 'color: purple;', options);

        // console.log('%c getMediationAgreements this.selectedProfile: %O', 'color: purple;', this.selectedProfile);
        if(!this.selectedProfile || (this.selectedProfile && (!this.selectedProfile.recordId))) return;

        // if(!options) return;
        // if(!options.mediator || !options.socialSolutionId) return;

        var selectedProfileId: number = 0;
        if (this.selectedProfile) {
            selectedProfileId = this.selectedProfile.recordId;
        } else {
            selectedProfileId = this.ulyssesDConstantineProfileId;
        }
        // console.log('%c getMediationAgreements this.currentUser: %O', 'color: purple;', this.currentUser);
        var request: UdicciRequestBase = {
            UdicciMediatorName: "Mediation Agreements",
            UdicciCommand: "Get Mediation Agreements",
            SelectedUdicciProfileId: selectedProfileId,
            SocialSolutionId: this.socialSolutionUdicciIT,
            UserId: (this.currentUser !== null && this.currentUser.UdicciUserId ? this.currentUser.UdicciUserId : 0),
        }
        // console.log('%c getMediationAgreements request: %O', 'color: purple;', request);
        this.callWebApi(request, this.dataReceived.bind(this, request));
    };

    getInstructionSet = async (options: any) => {
        // console.log('%c getInstructionSet options: %O', 'color: purple;', options);

        // console.log('%c getInstructionSet instructionProcessingLogs: %O', 'color: purple;', this.instructionProcessingLogs);
        var instructionLogs: InstructionSetLog[] = (this.instructionProcessingLogs && this.instructionProcessingLogs.length > 0 ? this.instructionProcessingLogs : []);
        // console.log('%c getInstructionSet instructionLogs: %O', 'color: purple;', instructionLogs);
        var firstInstruction: any = (instructionLogs.length > 0 ? instructionLogs[0] : null);
        // console.log('%c getInstructionSet firstInstruction: %O', 'color: purple;', firstInstruction);
        // var firstInstructionTitle: string = (firstInstruction && firstInstruction.title ? firstInstruction.title : '');
        // console.log('%c getInstructionSet firstInstructionTitle: %O', 'color: purple;', firstInstructionTitle);

        var onboardInstructionSet: InstructionSet | null = null;
        var firstLoginInstructionSet: InstructionSet | null = null;
        if (!firstInstruction) {
            // adding a test instruction for onboarding
            var onboardingConditions: InstructionCondition[] = [];
            var instCondProfileNowOwned: InstructionCondition = {
                name: 'Profile Not Owned',
                alwaysTrue: false,
                condition: 'equal',
                dataType: 'DateTime',
                jsonKey: 'DateOwnerAssumedControl',
                valueToCompare: null,
                roleLimitations: [],
                checkForCondition: (profile: UdicciRecord, condition: string, jsonKey: string, valueToCompare: string) => {
                    // console.log('%c checkForCondition profile: %O', 'color: bronze;', profile);
                    // console.log('%c checkForCondition condition: %O', 'color: bronze;', condition);
                    // console.log('%c checkForCondition jsonKey: %O', 'color: bronze;', jsonKey);
                    // console.log('%c checkForCondition valueToCompare: %O', 'color: bronze;', valueToCompare);
                    var rval: boolean = false;
                    if (profile && profile.data) {
                        var fieldValue = (profile.data[jsonKey] !== undefined ? profile.data[jsonKey] : '');
                        // console.log('%c checkForCondition fieldValue: %O', 'color: bronze;', fieldValue);
                        if (condition === 'equal' || condition === 'equals' || condition === 'eq') {
                            if (fieldValue === valueToCompare) rval = true;
                        }
                        if (condition === 'not.equal' || condition === 'not.equals' || condition === 'ne') {
                            if (fieldValue !== valueToCompare) rval = true;
                        }
                    }
                    return rval;
                },
                systemCheck: (urlHash: string) => {
                    // console.log('%c systemCheck urlHash: %O', 'color: red;', urlHash);
                    var urlHashCompare = urlHash.toLowerCase().replace('#', '');
                    // console.log('%c systemCheck urlHashCompare: %O', 'color: red;', urlHashCompare);
                    return urlHashCompare === 'onboard.me';
                }
            };
            onboardingConditions.push(instCondProfileNowOwned);

            var inst: Instruction = {
                uid: this.generateUID(),
                title: 'Onboarding',
                options: [],
                conditions: onboardingConditions
            };
            var instructions: Instruction[] = [];
            instructions.push(inst);

            var instSet: InstructionSet = {
                uid: this.generateUID(),
                instructions: instructions
            }
            onboardInstructionSet = instSet;

            var firstLoginConditions: InstructionCondition[] = [];
            var instCondFirstLogin: InstructionCondition = {
                name: 'Ready for First Login',
                alwaysTrue: false,
                condition: 'not.equal',
                dataType: 'DateTime',
                jsonKey: 'DateOwnerAssumedControl',
                valueToCompare: null,
                roleLimitations: [],
                checkForCondition: (profile: UdicciRecord, condition: string, jsonKey: string, valueToCompare: string) => {
                    // console.log('%c Ready for First Login checkForCondition profile: %O', 'color: bronze;', profile);
                    // console.log('%c Ready for First Login checkForCondition condition: %O', 'color: bronze;', condition);
                    // console.log('%c Ready for First Login checkForCondition jsonKey: %O', 'color: bronze;', jsonKey);
                    // console.log('%c Ready for First Login checkForCondition valueToCompare: %O', 'color: bronze;', valueToCompare);
                    var rval: boolean = false;
                    if (profile && profile.data) {
                        var fieldValue = (profile.data[jsonKey] !== undefined ? profile.data[jsonKey] : '');
                        // console.log('%c checkForCondition fieldValue: %O', 'color: bronze;', fieldValue);
                        if (condition === 'equal' || condition === 'equals' || condition === 'eq') {
                            if (fieldValue === valueToCompare) rval = true;
                        }
                        if (condition === 'not.equal' || condition === 'not.equals' || condition === 'ne') {
                            if (fieldValue !== valueToCompare) rval = true;
                        }
                    }
                    return rval;
                },
                systemCheck: (urlHash: string) => {
                    // console.log('%c systemCheck urlHash: %O', 'color: red;', urlHash);
                    var urlHashCompare = urlHash.toLowerCase().replace('#', '');
                    // console.log('%c systemCheck urlHashCompare: %O', 'color: red;', urlHashCompare);
                    return urlHashCompare === 'firstlogin';
                }
            };
            firstLoginConditions.push(instCondFirstLogin);

            var instFirstLogin: Instruction = {
                uid: this.generateUID(),
                title: 'First Login',
                options: [],
                conditions: firstLoginConditions
            };
            var instructionsFirstLogin: Instruction[] = [];
            instructionsFirstLogin.push(instFirstLogin);

            var instSetFirstLogin: InstructionSet = {
                uid: this.generateUID(),
                instructions: instructionsFirstLogin
            }
            firstLoginInstructionSet = instSetFirstLogin;
        }

        var checkForOnboardingInstSet = instructionLogs.find(function(log: any) {
            return (onboardInstructionSet && log.uid === onboardInstructionSet.uid)
        });
        // console.log('%c checkForOnboardingInstSet: %O', 'color: hotpink;', checkForOnboardingInstSet);
        // console.log('%c onboardInstructionSet: %O', 'color: hotpink;', onboardInstructionSet);
        if (!checkForOnboardingInstSet && onboardInstructionSet) {
            var onboardingInstSetLog: InstructionSetLog = {
                instructionSetId: onboardInstructionSet.uid,
                currentInstructionId: onboardInstructionSet.instructions[0].uid,
                instructionSet: onboardInstructionSet,
                taken: [],
                completed: false
            };
            instructionLogs.push(onboardingInstSetLog);
        }

        // var checkForFirstLoginInstSet = instructionLogs.find(function(log: any) {
        //     return (firstLoginInstructionSet && log.uid === firstLoginInstructionSet.uid)
        // });
        // console.log('%c checkForFirstLoginInstSet: %O', 'color: hotpink;', checkForFirstLoginInstSet);
        // console.log('%c firstLoginInstructionSet: %O', 'color: hotpink;', firstLoginInstructionSet);
        if (!checkForOnboardingInstSet && firstLoginInstructionSet) {
            var instSetLog: InstructionSetLog = {
                instructionSetId: firstLoginInstructionSet.uid,
                currentInstructionId: firstLoginInstructionSet.instructions[0].uid,
                instructionSet: firstLoginInstructionSet,
                taken: [],
                completed: false
            };
            instructionLogs.push(instSetLog);
        }

        this.instructionProcessingLogs = instructionLogs;
        // console.log('%c getInstructionSet instructionProcessingLogs: %O', 'color: blue;', this.instructionProcessingLogs);
        this.notifySubscribers("udicci.getInstructionSet", instructionLogs, options);
    };

    getRecord = (options: any) => {
        this.notifySubscribers("getRecord", options);
        // if (this.c-allback) {
        //     // console.log('%c getRecord this.c-allback: %O', 'color: green;', this.c-allback);
        //     this.c-allback("getRecord", options);
        // }
        // // console.log('%c getRecord this.contextC-allback: %O', 'color: green;', this.contextC-allback);
        // if (this.contextC-allback) {
        //     this.contextC-allback(options);
        // }
    }

    getProfileTypes = async () => {
        var request: UdicciRequestBase = {
            UdicciMediatorName: 'Udicci Profile Types',
            UdicciCommand: "Fetch List",
            SelectedUdicciProfileId: this.ulyssesDConstantineProfileId,
            SocialSolutionId: this.socialSolutionUdicciIT,
            UserId: (this.currentUser !== null && this.currentUser.UdicciUserId ? this.currentUser.UdicciUserId : 0),
        }
        // console.log('%c getProfileTypes request: %O', 'color: purple;', request);
        this.callWebApi(request, this.profileTypesReceived.bind(this, request));
    };

    profileTypesReceived = (request: any, response: any) => {
        // console.log('%c profileTypesReceived request: %O', 'color: green;', request);
        // console.log('%c profileTypesReceived response: %O', 'color: green;', response);

        this.notifySubscribers(request, response);
    }

    getMediatorStructures = async (udicciMediatorNames: any[]) => {
        var requestJson: MediatorStructureRequest = {
            UdicciCommand: 'Get Udicci Mediator Structures',
            UdicciMediatorName: 'Udicci Mediators',
            UdicciMediatorNames: udicciMediatorNames,
            // SelectedUdicciProfileId: this.ulyssesDConstantineProfileId,
            SelectedUdicciProfileId: this.ulyssesDConstantineProfileId,
            SocialSolutionId: this.socialSolutionUdicciIT,
            UserId: (this.currentUser !== null && this.currentUser.UdicciUserId ? this.currentUser.UdicciUserId : 0)
        };
        // console.log('%c getMediatorStructures requestJson: %O', 'color: purple;', requestJson);
        this.callWebApi(requestJson, this.mediatorStructuresReceived.bind(this, requestJson));
    };

    mediatorStructuresReceived = (request: any, response: any) => {
        // console.log('%c mediatorStructuresReceived request: %O', 'color: green;', request);
        // console.log('%c mediatorStructuresReceived response: %O', 'color: green;', response);

        this.notifySubscribers(request, response);
    }

    closeProfile = () => {
        this.currentPublicRole = null;
        this.currentUserRole = null;
        this.focusBoard = null;
        this.selectedProfile = null;
        this.selectedPorta = null;
        this.notifySubscribers('Closed Profile');
        // if (this.c-allback) this.c-allback('Closed Profile');
        // if (this.contextC-allback) this.contextC-allback('Closed Profile');
    };

    getProfileByUrl = async (url: string = '', preloadOnly: boolean = false) => {
        // console.log('%c getProfileByUrl url: %O', 'color: red;', url);
        // console.log('%c getProfileByUrl preloadOnly: %O', 'color: red;', preloadOnly);

        if (this.isLoadingProfile) return null;

        if (!url) {
            var profile: any = (this.selectedProfile && this.selectedProfile.data ? this.selectedProfile.data : null);
            // console.log('%c profile: %O', 'color: hotpink;', profile);
            if (!profile) return null;
            var profileUrl: any = (profile && profile.ProfileUrl ? profile.ProfileUrl : '');
            // console.log('%c Me profileUrl: %O', 'color: maroon;', profileUrl);
            if (profileUrl) url = profileUrl;
        }
        if (!url) url = "/ulyssesdconstantine";
        // console.log('%c getProfileByUrl this.currentUser: %O', 'color: red;', this.currentUser);

        // console.log('%c getProfileByUrl window.location: %O', 'color: #ea5508;', window.location);
        let windowUrl = UrlParser(window.location.href, true);
        // console.log('%c getProfileByUrl url: %O', 'color: #ea5508;', url);
        // console.log('%c getProfileByUrl windowUrl: %O', 'color: #ea5508;', windowUrl);

        let hostname = '';
        if (windowUrl && windowUrl.hostname) hostname = windowUrl.hostname;
        // hostname = 'udicci.tv';

        if (hostname && hostname.indexOf('.') > 0) {
            let hostnameParts = hostname.split('.');
            let nbrOfParts = hostnameParts.length;
            let newHostname = hostnameParts[nbrOfParts - 2] + '.' + hostnameParts[nbrOfParts - 1];
            hostname = newHostname;
            // console.log('%c hostname: %O', 'color: #teal; font-weight: bold;', hostname);
        }

        var request: UrlRequest = {
            UdicciMediatorName: "Udicci Profiles",
            UdicciCommand: "Get Profile By Url",
            SelectedUdicciProfileId: this.ulyssesDConstantineProfileId,
            SocialSolutionId: this.socialSolutionUdicciIT,
            UserId: (this.currentUser !== null && this.currentUser.UdicciUserId ? this.currentUser.UdicciUserId : 0),
            Url: url,
            PreLoadOnly: preloadOnly
        }
        if (hostname && hostname !== 'localhost') request.DomainAlias = hostname;
        // console.log('%c getProfileByUrl request: %O', 'color: purple;', request);
        this.isLoadingProfile = true;
        this.callWebApi(request, this.profileReceived.bind(this, request));
    };

    getProfileSplashByUrl = async (url: string = '', preloadOnly: boolean = false) => {
        // console.log('%c getProfileSplashByUrl url: %O', 'color: red;', url);
        // console.log('%c getProfileSplashByUrl preloadOnly: %O', 'color: red;', preloadOnly);

        if (this.isLoadingProfile) return null;

        if (!url) {
            var profile: any = (this.selectedProfile && this.selectedProfile.data ? this.selectedProfile.data : null);
            // console.log('%c profile: %O', 'color: hotpink;', profile);
            if (!profile) return null;
            var profileUrl: any = (profile && profile.ProfileUrl ? profile.ProfileUrl : '');
            // console.log('%c Me profileUrl: %O', 'color: maroon;', profileUrl);
            if (profileUrl) url = profileUrl;
        }
        if (!url) url = "/ulyssesdconstantine";
        // console.log('%c getProfileSplashByUrl this.currentUser: %O', 'color: red;', this.currentUser);

        let windowUrl = UrlParser(window.location.href, true);
        // console.log('%c getProfileSplashByUrl url: %O', 'color: #ea5508;', url);
        // console.log('%c getProfileSplashByUrl windowUrl: %O', 'color: #ea5508;', windowUrl);

        let hostname = '';
        if (windowUrl && windowUrl.hostname) hostname = windowUrl.hostname;
        // hostname = 'udicci.tv';

        if (hostname && hostname.indexOf('.') > 0) {
            let hostnameParts = hostname.split('.');
            let nbrOfParts = hostnameParts.length;
            let newHostname = hostnameParts[nbrOfParts - 2] + '.' + hostnameParts[nbrOfParts - 1];
            hostname = newHostname;
            // console.log('%c hostname: %O', 'color: #teal; font-weight: bold;', hostname);
        }

        var request: UrlRequest = {
            UdicciMediatorName: "Udicci Profiles",
            UdicciCommand: "Get Profile Splash By Url",
            SelectedUdicciProfileId: this.ulyssesDConstantineProfileId,
            SocialSolutionId: this.socialSolutionUdicciIT,
            UserId: (this.currentUser !== null && this.currentUser.UdicciUserId ? this.currentUser.UdicciUserId : 0),
            Url: url,
            PreLoadOnly: preloadOnly
        }
        if (hostname && hostname !== 'localhost') request.DomainAlias = hostname;
        // console.log('%c getProfileSplashByUrl request: %O', 'color: purple;', request);
        // console.log('%c getProfileSplashByUrl request: %O', 'color: purple;', request);
        this.isLoadingProfile = true;
        this.callWebApi(request, this.profileReceived.bind(this, request));
    };

    profileReceived = async (request: any, response: any) => {
        // console.log('%c profileReceived request: %O', 'color: green;', request);
        // console.log('%c profileReceived response: %O', 'color: green;', response);
        this.isLoadingProfile = false;

        let currentUrl = window.location.href;
        let url = UrlParser(currentUrl, true);
    
        let { hash } = url;
        // console.log('%c UdicciFactory profileReceived hash: %O', 'color: green;', hash);
        // console.log('%c UdicciFactory profileReceived this.portas: %O', 'color: green;', this.portas);
        let hashUrl: string = hash.replace('#', '').trim().toLowerCase();

        var structures = (response && response.structures ? response.structures : null);
        // console.log('%c profileReceived structures: %O', 'color: green;', structures);
        var udicciMediatorName = (response && response.UdicciMediatorName ? response.UdicciMediatorName : '');
        var structure = (udicciMediatorName && structures && structures[udicciMediatorName] ? structures[udicciMediatorName] : null);
        var urlProfile = (response && response.UrlProfile ? response.UrlProfile : null);
        // console.log('%c profileReceived urlProfile: %O', 'color: green;', urlProfile);
        if (!urlProfile) return false;

        var profileRecord = new UdicciRecord(response.UdicciMediatorName, urlProfile, structure);
        // console.log('%c profileReceived profileRecord: %O', 'color: green;', profileRecord);

        this.currentPublicRole = (profileRecord && profileRecord.data && profileRecord.data.PublicRole !== undefined ? profileRecord.data.PublicRole : this.currentPublicRole);
        // console.log('%c profileReceived this.currentPublicRole: %O', 'color: green;', this.currentPublicRole);
        this.currentUserRole = (profileRecord && profileRecord.data && profileRecord.data.UdicciUserRole !== undefined ? profileRecord.data.UdicciUserRole : this.currentUserRole);
        // console.log('%c profileReceived this.currentUserRole: %O', 'color: green;', this.currentUserRole);

        this.selectedProfile = profileRecord;

        var socialSolutions = (profileRecord && profileRecord.data.SocialSolutions ? profileRecord.data.SocialSolutions : null);
        var profilePorta = (profileRecord && profileRecord.data.ProfilePorta ? profileRecord.data.ProfilePorta : null);

        if (profilePorta) {
            // console.log('%c UdicciFactory profileReceived profilePorta: %O', 'color: red; font-weight: bold;', profilePorta);
            var portas = (profilePorta.portas ? profilePorta.portas : null);
            // console.log('%c UdicciFactory profileReceived portas: %O', 'color: red; font-weight: bold;', portas);
            if (portas && portas.length > 0) {
                portas.forEach((prta: UdicciPorta) => {
                    var foundPortas = this.portas.filter(function(p: UdicciPorta) { return p.UdicciRecordId === prta.UdicciRecordId });
                    // console.log('%c UdicciFactory profileReceived foundPortas: %O', 'color: green;', foundPortas);
                    if (foundPortas.length <= 0) {
                        var settingsJson = {};
                        if (prta.Settings && prta.Settings.length > 0) {
                            settingsJson = JSON.parse(prta.Settings);
                        }
                        var porta: UdicciPorta = {
                            Name: prta.Name,
                            Purpose: prta.Purpose,
                            Settings: prta.Settings,
                            UdicciRecordId: prta.UdicciRecordId,
                            SettingsJson: settingsJson,
                            IsDirty: false,
                            CreatedByUserId: prta.CreatedByUserId
                        }
                        this.portas.push(porta);
                    }
                });
            }
            // console.log('%c UdicciFactory profileReceived portas: %O', 'color: green;', portas);

            var portaMenus = (profilePorta.portaMenus ? profilePorta.portaMenus : null);
            if (portaMenus && portaMenus.length > 0) {
                // console.log('%c this.portaMenus: %O', 'color: blue;', this.portaMenus);
                portaMenus.forEach((prtaMenu: UdicciPortaMenu) => {
                    // console.log('%c prtaMenu: %O', 'color: blue;', prtaMenu);
                    var foundMenus = this.portaMenus.filter(function(pm: UdicciPortaMenu) { return pm.UdicciRecordId === prtaMenu.UdicciRecordId });
                    if (foundMenus.length <= 0) this.portaMenus.push(prtaMenu);
                });
                // console.log('%c this.portaMenus: %O', 'color: blue;', this.portaMenus);
            }

            var themes = (profilePorta.themes ? profilePorta.themes : null);
            // console.log('%c themes: %O', 'color: maroon;', themes);
            if (themes && themes.length > 0) {
                // console.log('%c this.themes: %O', 'color: blue;', this.themes);
                themes.forEach((thme: UdicciTheme) => {
                    // console.log('%c thme: %O', 'color: blue;', thme);
                    var foundThemes = this.themes.filter(function(th: UdicciTheme) { return th.UdicciRecordId === thme.UdicciRecordId });
                    if (foundThemes.length <= 0) this.themes.push(thme);
                });
                // console.log('%c this.themes: %O', 'color: blue;', this.themes);
            }

            if (this.portas && this.portas.length > 0) {
                // if hash not set, get the default porta
                // if hash is set, use hash value to get porta
                let includedPortas: UdicciPorta[] = [];
                var foundPortasByHash = this.portas.filter(function(p: UdicciPorta) {
                    var portaName = (p.Name ? p.Name : '');
                    var stngs = (p.SettingsJson ? p.SettingsJson : null);
                    var includePorta: boolean = false;
                    if (hashUrl && stngs && stngs.portaUrl !== undefined && stngs.portaUrl !== null) {
                        includePorta = (stngs.portaUrl.toLowerCase().trim() === hashUrl ? true : false);
                    }

                    if (!includePorta && hashUrl && portaName) {
                        // remove all special characters from the name
                        // let defaultPortaName: string = portaName.replace(/[^a-zA-Z0-9]/g,'').trim().toLowerCase();
                        let defaultPortaName: string = portaName.replace(/([^a-zA-Z0-9\w\.])/g,'').trim().toLowerCase();
                        // console.log('%c defaultPortaName: %O', 'color: red;', defaultPortaName);
                        includePorta = (defaultPortaName === hashUrl ? true : false);
                    }
        
                    // console.log('%c includePorta: %O', 'color: blue;', includePorta);
                    return includePorta
                });
                // console.log('%c UdicciFactory profileReceived foundPortasByHash: %O', 'color: green;', foundPortasByHash);

                if (foundPortasByHash && foundPortasByHash.length > 0) {
                    includedPortas = foundPortasByHash;
                }

                if (includedPortas.length <= 0) {
                    var landingPortaFound: boolean = false;
                    var foundPortas: any = null;
                    let userId: number = (this.currentUser && this.currentUser.UdicciUserId ? this.currentUser.UdicciUserId : 0);
                    let profileId: number = (this.selectedProfile && this.selectedProfile.recordId ? this.selectedProfile.recordId : 0);
                    let profileCreatedByUserId: number = (this.selectedProfile && this.selectedProfile.data.CreatedByUserId ? this.selectedProfile.data.CreatedByUserId : 0);
                    if (userId > 0 && profileId > 0 && profileCreatedByUserId === userId) {
                        foundPortas = this.portas.filter(function(p: UdicciPorta) {
                            var stngs = (p.SettingsJson ? p.SettingsJson : null);
                            var landingPorta: boolean = false;
                            if (hashUrl.length <= 0) {
                                if (stngs && stngs.landingPorta !== undefined && stngs.landingPorta !== null) {
                                    landingPorta = (stngs.landingPorta === true ? true : false);
                                    if (landingPorta) landingPortaFound = true;
                                }
                            }
                            return (landingPorta)
                        });
                    }

                    // console.log('%c foundPortas: %O', 'color: blue;', foundPortas);
                    if (!landingPortaFound) {
                        foundPortas = this.portas.filter(function(p: UdicciPorta) {
                            var stngs = (p.SettingsJson ? p.SettingsJson : null);
                            var defaultPorta: boolean = false;
                            if (stngs && stngs.defaultPorta !== undefined && stngs.defaultPorta !== null) {
                                defaultPorta = (stngs.defaultPorta === true ? true : false);
                            }
                            return (defaultPorta)
                        });
                    }
                    if (foundPortas && foundPortas.length > 0) includedPortas = foundPortas;
                }

                // console.log('%c includedPortas: %O', 'color: blue;', includedPortas);
                if (includedPortas && includedPortas.length > 0) {
                    this.focusBoard = null;
                    this.selectedPorta = includedPortas[0];
                }
            }
        }

        var rvalData: ProfileResponse = new ProfileResponse();
        if (socialSolutions && socialSolutions.length > 0) {
            var ssStructure = (structures && structures['Social Solutions'] ? structures['Social Solutions'] : null);
            // console.log('%c ssStructure: %O', 'color: green;', ssStructure);

            var socialSolutionRecords: UdicciRecord[] = [];
            socialSolutions.forEach((ss: object) => {
                // console.log('%c ss: %O', 'color: green;', ss);
                var ssRecord = new UdicciRecord('Social Solutions', ss, ssStructure);
                // console.log('%c ss: %O', 'color: green;', ss);
                socialSolutionRecords.push(ssRecord);
            });
            // console.log('%c socialSolutionRecords: %O', 'color: green;', socialSolutionRecords);
            rvalData.socialSolutions = socialSolutionRecords;
            profileRecord.data.SocialSolutions = socialSolutionRecords;
        }
        if (profilePorta) rvalData.profilePorta = profilePorta;
        if (structures) rvalData.structures = structures;
        rvalData.profile = profileRecord;

        this.notifySubscribers(request, rvalData);
        // this.c-allback!(request, rvalData);
        // this.contextC-allback!(rvalData);

        // console.log('%c profileReceived check request[%s]: %O', 'color: green;', request.UdicciCommand, request);
        if (request.UdicciCommand === 'Get Profile Splash By Url') {
            // console.log('%c profileReceived get full profile urlProfile: %O', 'color: green;', urlProfile);
            setTimeout(() => {
                this.getProfileByUrl(urlProfile.ProfileUrl, false);
            }, 3000)
        }

        if (this.currentUser !== null && this.currentUser.UdicciUserId > 0) this.getMediationAgreements(null);
    }

    userLogin = async (loginRequestSettings: any, settings: any) => {
        // console.log('%c userLogin loginRequestSettings: %O', 'color: purple;', loginRequestSettings);
        // console.log('%c userLogin settings: %O', 'color: purple;', settings);
        var url = this.API_URL; // loginRequestSettings.url;
        if (url.indexOf(process.env.REACT_APP_UDICCI_MEDIATION_ENDPOINT) >= 0) url = url.replace(process.env.REACT_APP_UDICCI_MEDIATION_ENDPOINT, '');
        var loginUrl = url + process.env.REACT_APP_UDICCI_USER_AUTHENTICATION_ENDPOINT;
        var headers = { "Content-type": "application/x-www-form-urlencoded", "Accept": "json" };

        var urlEncoded = 'grant_type=password&userName=' + settings.username + '&password=' + settings.password;
        var fetchSettings = { method: 'POST', headers: headers, body: urlEncoded };
        // console.log('User Login loginUrl: %O', loginUrl);

        var updateProfile = this.getProfileByUrl;
        var profile = this.selectedProfile;
        try {
            fetch(loginUrl, fetchSettings)
            .then(function (resp) {
                // console.log('User Login resp: %O', resp);
                if (!resp.ok) {
                    settings.onError(resp);
                    // console.log('User Login failed %O', resp);
                }
                return resp.json();
            })
            .then(function (data) {
                // console.log('User Login response data: %O', data);
                if (data.token) {
                    var usrDtls: any = {};
                    usrDtls.AccessToken = data.token;
                    usrDtls.RefreshToken = data.refreshToken;
                    usrDtls.TokenExpired = false;

                    var tokDet: any = null;
                    // var tokDet = _self.parseJwt(data.token);
                    if (data.token) {
                        var base64Url = data.token.split('.')[1];
                        var base64 = base64Url.replace('-', '+').replace('_', '/');
                        tokDet = JSON.parse(window.atob(base64));
                    }
                    // console.log('User Login tokDet: %O', tokDet);

                    if (tokDet) {
                        if (tokDet.udicciUserId) usrDtls.UdicciUserId = parseInt(tokDet.udicciUserId.toString(), 10);
                        if (tokDet.meUdicciProfileId) usrDtls.MeUdicciProfileId = parseInt(tokDet.meUdicciProfileId.toString(), 10);
                        if (tokDet.userName) usrDtls.UserName = tokDet.userName;
                        if (tokDet.myDisplayName) usrDtls.myDisplayName = tokDet.myDisplayName;
                        if (tokDet.mySocialIcon) usrDtls.mySocialIcon = tokDet.mySocialIcon;
                        if (tokDet.FirstName) usrDtls.FirstName = tokDet.FirstName;
                        if (tokDet.LastName) usrDtls.LastName = tokDet.LastName;
                        if (tokDet.myProfileUrl) usrDtls.myProfileUrl = tokDet.myProfileUrl;
                    }

                    var minutesToAdd: number = 49 * 60 * 1000;  // 49 minutes * 60 seconds per minute * 1000 milliseconds per second
                    var curDate = new Date();
                    usrDtls.ExpectedTimeout = new Date(curDate.getTime() + minutesToAdd);
                    usrDtls.TimedOut = false;
            
                    // console.log('User Login loginRequestSettings: %O', loginRequestSettings);
                    // console.log('User Login usrDtls: %O', usrDtls);
                    delete settings['username'];
                    delete settings['password'];
                    if (loginRequestSettings.onSuccess)
                        loginRequestSettings.onSuccess(settings, usrDtls);
                    else if (settings.onSuccess)
                        settings.onSuccess(usrDtls);
                    if (profile && profile.data) {
                        if (profile.data.ProfileUrl) {
                            updateProfile(profile.data.ProfileUrl);
                        }
                    }
                } else {
                    if (data.error) {
                        var error = JSON.parse(data.error);
                        if (settings.onError) {
                            settings.onError(error);
                        } else {
                            // console.log('User Login failed %O', error);
                        }
                    }
                }
            })
            .catch(function (error) {
                if (settings.onError) {
                    settings.onError(error);
                } else {
                    // console.log('User Login failed %O', error);
                }
            });
        } catch (ex) {
            // console.log('User Login failed %O', ex);
        }
    };

    makePortaDefault(newDefaultPorta: any) {
        // console.log('%c makePortaDefault newDefaultPorta: %O', 'color: red;', newDefaultPorta);
        if (!newDefaultPorta) return;
        if (!this.portas) return;

        let newDefaultPortaSettings: any = (newDefaultPorta.SettingsJson ? newDefaultPorta.SettingsJson : null);
        // console.log('%c makePortaDefault newDefaultPortaSettings: %O', 'color: maroon;', newDefaultPortaSettings);
        let defaultPorta: boolean = (newDefaultPortaSettings && newDefaultPortaSettings.defaultPorta ? true : false);
        // console.log('%c makePortaDefault defaultPorta: %O', 'color: maroon;', defaultPorta);
    
        if (!defaultPorta) {
            let requiresSave: boolean = false;
            let currentDefaultPortas: UdicciPorta[] = [];
            forEach(this.portas, (p: UdicciPorta, idx: number) => {
                // console.log('%c makePortaDefault porta: %O', 'color: red;', p);

                let pSettings: any = (p.SettingsJson ? p.SettingsJson : null);
                // console.log('%c makePortaDefault pSettings: %O', 'color: maroon;', pSettings);
                let pDefault: boolean = (pSettings && pSettings.defaultPorta ? true : false);
                // console.log('%c makePortaDefault pDefault: %O', 'color: maroon;', pDefault);
                if (pDefault && p.UdicciRecordId !== newDefaultPorta.UdicciRecordId) {
                    pSettings.defaultPorta = false;
                    p.SettingsJson = pSettings;
                    currentDefaultPortas.push(p);
                    requiresSave = true;
                }
            });

            newDefaultPorta.SettingsJson.defaultPorta = true;

            // console.log('%c makePortaDefault requiresSave: %O', 'color: red;', requiresSave);
            // console.log('%c makePortaDefault currentDefaultPortas: %O', 'color: red;', currentDefaultPortas);
            // console.log('%c makePortaDefault newDefaultPorta: %O', 'color: red;', newDefaultPorta);
            if (requiresSave) {
                if (currentDefaultPortas.length > 0) {
                    forEach(currentDefaultPortas, (dp: UdicciPorta) => this.saveSpecificPorta(dp));
                }
                this.saveSpecificPorta(newDefaultPorta);
            }
        }
    }

    makePortaLandingPorta(newLandingPorta: any, newLandingPageValue: boolean | undefined = true) {
        // console.log('%c makePortaDefault newLandingPorta: %O', 'color: red;', newLandingPorta);
        if (!newLandingPorta) return;
        if (!this.portas) return;

        let newLandingPortaSettings: any = (newLandingPorta.SettingsJson ? newLandingPorta.SettingsJson : null);
        // console.log('%c makePortaDefault newLandingPortaSettings: %O', 'color: maroon;', newLandingPortaSettings);
        let landingPorta: boolean = (newLandingPortaSettings && newLandingPortaSettings.landingPorta ? true : false);
        // console.log('%c makePortaDefault landingPorta: %O', 'color: maroon;', landingPorta);
    
        let requiresSave: boolean = false;
        let currentLandingPortas: UdicciPorta[] = [];
        if (newLandingPageValue === true) {
            forEach(this.portas, (p: UdicciPorta, idx: number) => {
                // console.log('%c makePortaDefault porta: %O', 'color: red;', p);

                if (p.UdicciRecordId !== newLandingPorta.UdicciRecordId) {
                    let pSettings: any = (p.SettingsJson ? p.SettingsJson : null);
                    // console.log('%c makePortaDefault pSettings: %O', 'color: maroon;', pSettings);
                    let pIsLanding: boolean = (pSettings && pSettings.landingPorta ? true : false);
                    // console.log('%c makePortaDefault pDefault: %O', 'color: maroon;', pDefault);
                    if (pIsLanding && p.UdicciRecordId !== newLandingPorta.UdicciRecordId) {
                        pSettings.landingPorta = false;
                        p.SettingsJson = pSettings;
                        currentLandingPortas.push(p);
                        requiresSave = true;
                    }
                }
            });
        }

        newLandingPorta.SettingsJson.landingPorta = newLandingPageValue;

        // console.log('%c makePortaDefault requiresSave: %O', 'color: red;', requiresSave);
        // console.log('%c makePortaDefault currentLandingPortas: %O', 'color: red;', currentLandingPortas);
        // console.log('%c makePortaDefault newLandingPorta: %O', 'color: red;', newLandingPorta);
        if (requiresSave && currentLandingPortas.length > 0) {
            forEach(currentLandingPortas, (lp: UdicciPorta) => this.saveSpecificPorta(lp));
        }
        this.saveSpecificPorta(newLandingPorta);
    }

    savePorta(settings: any | null = null) {
        // console.log('%c savePorta this.selectedPorta: %O', 'color: red;', this.selectedPorta);
        // console.log('%c savePorta settings: %O', 'color: red;', settings);
        if (!this.selectedPorta) return;
        this.saveSpecificPorta(this.selectedPorta, settings);
    }

    saveSpecificPorta(porta: UdicciPorta, saveSettings: any | null = null) {
        // console.log('%c saveSpecificPorta porta: %O', 'color: red;', porta);
        // console.log('%c saveSpecificPorta saveSettings: %O', 'color: red;', saveSettings);
        if (!porta) return;

        var recordId: number = (porta ? porta.UdicciRecordId : 0);
        var portaRecord: UdicciRecord = {
            recordId: recordId, 
            udicciMediator: 'Portas',
            data: porta,
            permissions: null,
            keys: { title: 'Name', description: 'Purpose' },
            title: porta.Name,
            description: porta.Purpose,
            isDirty: true,
            isSaving: true
        };
        // console.log('%c saveSpecificPorta portaRecord: %O', 'color: red;', portaRecord);

        let hasJsonError: boolean = false;
        if (portaRecord.data && portaRecord.data.SettingsJson !== undefined) {
            var portaSettingsJson: any = portaRecord.data.SettingsJson;
            // console.log('%c saveSpecificPorta portaSettingsJson: %O', 'color: red;', portaSettingsJson);
            if (portaSettingsJson) {
                try {
                    // console.log('%c saveSpecificPorta portaSettingsJson: %O', 'color: red;', portaSettingsJson);
                    if (portaSettingsJson.panes && portaSettingsJson.panes.length > 0) {
                        // we need to clean up some data that is carrying forward from the context values
                        // 1.  Udicci Context Views
                        // console.log('%c saveSpecificPorta portaSettingsJson: %O', 'color: green;', portaSettingsJson);
                        forEach(portaSettingsJson.panes, (p: any, pi: number) => {
                            // console.log('%c p: %O', 'color: green;', p);
                            if (p.content && p.content.length > 0) {
                                forEach(p.content, (c: any, ci: number) => {
                                    // console.log('%c c: %O', 'color: green;', c);
                                    if (c.data && c.data.default && c.data.default.contextView) {
                                        // console.log('%c saveSpecificPorta c.data.default.contextView: %O', 'color: red;', c.data.default.contextView);
                                        let rec: any = c.data.default.contextView.record;
                                        if (c.data.default.contextView.record && c.data.default.contextView.record["context"]) {
                                            delete c.data.default.contextView.record["context"];
                                        }
                                    }
                                    p.content[ci] = c;
                                });
                            }
                            portaSettingsJson.panes[pi] = p;
                        });
                        // console.log('%c saveSpecificPorta portaSettingsJson: %O', 'color: red;', portaSettingsJson);
                    }
                    portaRecord.data.Settings = JSON.stringify(portaSettingsJson);
                } catch (err: any) {
                    // console.log('%c saveSpecificPorta err: %O', 'color: red;', err);
                    // console.log('%c saveSpecificPorta settings: %O', 'color: red;', settings);
                    hasJsonError = true;
                }
            }
        }

        if (hasJsonError) return false;

        if (portaRecord.data && portaRecord.data.IsDirty !== undefined) delete portaRecord.data['IsDirty'];
        var sp = (this.selectedProfile ? this.selectedProfile : { recordId: 0 });
        var profileId: number = (sp && sp.recordId ? sp.recordId : this.ulyssesDConstantineProfileId);

        portaRecord.data.CreatedInUdicciProfileId = profileId;

        let saveRecordSettings: any = {};
        if (typeof(saveSettings) === 'object') Object.assign(saveRecordSettings, saveSettings);
        saveRecordSettings.profileId = profileId;
        // console.log('%c saveSpecificPorta saveRecordSettings: %O', 'color: red;', saveRecordSettings);
        if (recordId <= 0) {
            saveRecordSettings.RelationshipChanges = JSON.stringify([{
                "Add": "Parent",
                "RecordMediator": 'Portas',
                "RecordId": recordId,
                "RelatedMediator": "Udicci Profiles",
                "RelatedRecordId": profileId,
                "Priority": -1
            }]);
        }

        // console.log('%c saveSpecificPorta portaRecord: %O', 'color: red;', portaRecord);
        // console.log('%c saveSpecificPorta saveRecordSettings: %O', 'color: red;', saveRecordSettings);
        this.saveRecord(portaRecord, this.socialSolutionUdicciIT, saveRecordSettings);
    }

    saveRecord = async (record: UdicciRecord, socialSolutionId: number = 0, settings: any | null = null) => {
        // console.log('%c saveRecord record: %O', 'color: purple;', record);
        // console.log('%c saveRecord settings: %O', 'color: purple;', settings);
        if (!record.data) return;

        var { data } = record;
        // console.log('%c saveRecord data: %O', 'color: purple;', data);

        var cu = (this.currentUser ? this.currentUser : { UdicciUserId: 0 });
        var currentUserId: number = cu.UdicciUserId;
        var sp = (this.selectedProfile ? this.selectedProfile : { recordId: 0 });
        var profileId: number = sp.recordId;
        // console.log('%c saveRecord profileId: %O', 'color: purple;', profileId);

        var curSocialSolution: any = (this.selectedSocialSolution ? this.selectedSocialSolution : null);
        // console.log('%c saveRecord curSocialSolution: %O', 'color: purple;', curSocialSolution);
        var socialSolution: any = (curSocialSolution && curSocialSolution.solution ? curSocialSolution.solution : null);
        // console.log('%c saveRecord socialSolution: %O', 'color: purple;', socialSolution);
        var curFeature: any = (this.selectedFeature ? this.selectedFeature : null);
        // console.log('%c saveRecord curFeature: %O', 'color: purple;', curFeature);

        if (settings && settings.profileId) profileId = settings.profileId;

        if (settings && settings.socialSolutionId) socialSolutionId = settings.socialSolutionId;
        if (!socialSolutionId && socialSolution) socialSolutionId = socialSolution.recordId;
        if (!socialSolutionId && data && data.CreatedInSolutionId) socialSolutionId = data.CreatedInSolutionId;
        if (!socialSolutionId) socialSolutionId = this.socialSolutionDefaultMe;

        var featureId: number = 0;
        if (settings && settings.featureId) featureId = settings.featureId;
        if (!featureId && curFeature && curFeature.UdicciRecordId) featureId = curFeature.UdicciRecordId;

        var mediationSource = '';
        if (settings && settings.MediationSource) {
            mediationSource = JSON.stringify(settings.MediationSource);
        }
        var relationshipChanges: any = null;
        if (settings && settings.RelationshipChanges) {
            // relationshipChanges = JSON.stringify(settings.RelationshipChanges);
            relationshipChanges = settings.RelationshipChanges;
        }
        if (record.context && record.context.RelationshipChanges && record.context.RelationshipChanges.length > 0) {
            let otherChanges: any[] = record.context.RelationshipChanges;
            if (!relationshipChanges) relationshipChanges = [];
            if (relationshipChanges.length > 0) {
                // need to make sure we do not duplicate
                forEach(otherChanges, function(relChg: any) {
                    let relChangesCheck: any = find(relationshipChanges, (chck: any) => {
                        let mediatorsSame: boolean = (chck.RelatedMediator === relChg.RelatedMediator && chck.RecordMediator === relChg.RecordMediator ? true : false);
                        let idsSame: boolean = (chck.RelatedRecordId === relChg.RelatedRecordId && chck.RecordId === relChg.RecordId ? true : false);
                        return (mediatorsSame && idsSame ? true : false);
                    });
                    if (!relChangesCheck) relationshipChanges.push(relChg);
                });
            }
        }
        var request: SaveRecordRequest = {
            UdicciMediatorName: record.udicciMediator,
            UdicciCommand: "Save Record",
            SelectedUdicciProfileId: profileId,
            SocialSolutionId: socialSolutionId,
            FeatureId: featureId,
            UserId: currentUserId,
            UdicciRecordId: (record.recordId ? record.recordId : 0),
            SaveData: data,
            CreatedByUserId: (record.recordId <= 0 ? currentUserId : 0),
            CreatedInUdicciProfileId: (record.recordId <= 0 ? profileId : 0),
            MediationSource: (mediationSource !== '' ? mediationSource : null),
            RelationshipChanges: relationshipChanges
        }

        // console.log('%c saveRecord request: %O', 'color: purple;', request);
        this.callWebApi(request, this.saveCompleted.bind(this, request, settings));
    };

    saveCompleted = (request: any, settings: any, response: any) => {
        // console.log('%c saveCompleted request: %O', 'color: purple;', request);
        // console.log('%c saveCompleted settings: %O', 'color: purple;', settings);
        // console.log('%c saveCompleted response: %O', 'color: purple;', response);
        if (request && request.UdicciMediatorName === 'Engagement Requests') {
            var engagementTrackingFromStore = sessionStorage.getItem('udicci.engagementTracking');

            var engagementTracking = null;
            if (engagementTrackingFromStore) {
                engagementTracking = JSON.parse(engagementTrackingFromStore);
            }
            // console.log('%c addTrackingLog engagementTracking: %O', 'color: red;', engagementTracking);
            if (engagementTracking) {
                engagementTracking.engaged = true;

                engagementTrackingFromStore = JSON.stringify(engagementTracking);
                sessionStorage.setItem('udicci.engagementTracking', engagementTrackingFromStore);            
            }
        }
        if (request && request.UdicciMediatorName === 'Portas') {
            let rslt: any = null;
            if (response.ResponseJson) {
                var responseJson: any = response.ResponseJson;
                if (typeof(responseJson) !== 'object') {
                    try {
                        responseJson = JSON.parse(response.ResponseJson);
                    } catch {
                        responseJson = response.ResponseJson;
                    }
                }
                // console.log('%c saveCompleted responseJson: %O', 'color: green;', responseJson);
                rslt = responseJson;
            }
            if (settings.onSuccess) settings.onSuccess(rslt);
        }
        if (response && response.success === false) {
            let errorMessage: string = (response && response.Message ? "Save error: " + response.Message : 'Save could not be completed.  Please contact your Profile Guide.')
            alert(errorMessage);
        }
        this.dataReceived(request, response, settings);
    };

    deleteRecord = async (record: UdicciRecord, socialSolutionId: number = 0, settings: any | null = null) => {
        // console.log('%c deleteRecord record: %O', 'color: purple;', record);
        // console.log('%c deleteRecord settings: %O', 'color: purple;', settings);
        if (!record.data) return;
        if (record.recordId <= 0) return;

        var cu = (this.currentUser ? this.currentUser : { UdicciUserId: 0 });
        var currentUserId: number = cu.UdicciUserId;
        var sp = (this.selectedProfile ? this.selectedProfile : { recordId: 0 });
        var profileId: number = sp.recordId;

        if (settings && settings.socialSolutionId) socialSolutionId = settings.socialSolutionId;
        if (!socialSolutionId) socialSolutionId = this.socialSolutionDefaultMe;

        var request: DeleteRecordRequest = {
            UdicciMediatorName: record.udicciMediator,
            UdicciCommand: "Delete Record",
            SelectedUdicciProfileId: profileId,
            SocialSolutionId: socialSolutionId,
            UserId: currentUserId,
            UdicciRecordId: (record.recordId ? record.recordId : 0),
        }

        // console.log('%c deleteRecord request: %O', 'color: purple;', request);
        this.callWebApi(request, this.deleteCompleted.bind(this, request, settings));
    };

    deleteCompleted = (request: any, settings: any, response: any) => {
        // console.log('%c deleteCompleted request: %O', 'color: purple;', request);
        // console.log('%c deleteCompleted settings: %O', 'color: purple;', settings);
        // console.log('%c deleteCompleted response: %O', 'color: purple;', response);
        this.dataReceived(request, response, settings);
    };

    updateRecord = (record: UdicciRecord) => {
        // console.log('%c updateRecord record: %O', 'color: purple;', record);
        // var request = {
        //     UdicciMediatorName: record.udicciMediator,
        //     UdicciCommand: 'Local.Update Record'
        // }
        // console.log('%c updateRecord request: %O', 'color: purple;', request);
        this.notifySubscribers(record);
        // if (this.contextC-allback) this.contextC-allback(record);
    };

    setNextProductionRelease = (nextReleaseDate: Date | null) => {
        this.nextProductionRelease = nextReleaseDate;
    }

    productionReleaseScheduled = () => {
        const curDate = new Date();
        return (this.nextProductionRelease && curDate > this.nextProductionRelease ? true : false);
    }

    productionReleaseTimeframe = () => {
        let relativeDate: string = '';
        if (this.nextProductionRelease) {
            const curDate = new Date();
            if (this.nextProductionRelease.getUTCSeconds() < curDate.getUTCSeconds()) {
                // console.log('%c this.nextProductionRelease: %O', 'color: red;', this.nextProductionRelease);
                // console.log('%c curDate: %O', 'color: red;', curDate);
                relativeDate = formatRelative(this.nextProductionRelease, curDate);
            }
        }
        return relativeDate;
    }

    private callWebApi(data: any, onSuccess: any, onError: any = null): void {
        // console.log('%c callWebApi data: %O', 'color: red;', data);
        // console.log('%c callWebApi onSuccess: %O', 'color: red;', onSuccess);
        // console.log('%c callWebApi onError: %O', 'color: red;', onError);

        var apiUrl: string = this.API_URL;
        // console.log('%c callWebApi apiUrl: %O', 'color: red;', apiUrl);

        var _activeRequestId: string = '';
        // console.log('%c callWebApi ACTIVE_REQUEST_LIST: %O', 'color: red;', ACTIVE_REQUEST_LIST);
        let dataString: string = (data ? JSON.stringify(data) : '');
        let matchingRequestIndex: number = findIndex(ACTIVE_REQUEST_LIST, (ar: any, arIdx: number) => { return ar.dataString === dataString; });
        // console.log('%c callWebApi matchingRequestIndex: %O', 'color: red;', matchingRequestIndex);
        let postRequestIsOk: boolean = false;
        let curDate: Date = new Date();
        let curTimestamp: number = curDate.getTime();

        let matchingRequest: any = null;
        let matchIsRecent: boolean = true;
        if (matchingRequestIndex >= 0) {
            // found a matching request
            matchingRequest = ACTIVE_REQUEST_LIST[matchingRequestIndex];
            // console.log('%c callWebApi matchingRequest: %O', 'color: green;', matchingRequest);
            if (matchingRequest.timestamp) {
                // console.log('%c callWebApi curTimestamp: %O', 'color: green;', curTimestamp);
                let seconds: number = Math.abs(curTimestamp - matchingRequest.timestamp)/1000;
                // console.log('%c callWebApi seconds: %O', 'color: green;', seconds);
                if (seconds > 2) {
                    // console.log('%c callWebApi seconds > 2: %O', 'color: green;', seconds);
                    // if over 2 seconds, then allow it to process again assuming user is requesting something new
                    matchIsRecent = false;
                }
            }
        }

        if (matchingRequest) {
            // found a matching request
            // console.log('%c callWebApi matchingRequest: %O', 'color: green;', matchingRequest);
            matchingRequest.count++;
            ACTIVE_REQUEST_LIST[matchingRequestIndex] = matchingRequest;
            _activeRequestId = matchingRequest.rid;
            // console.log('%c callWebApi matchIsRecent: %O', 'color: green;', matchIsRecent);
            if (matchIsRecent) postRequestIsOk = false;
        } else {
            // not found, add a new request to the list
            let newRequest: any = {
                rid: this.generateUID(),
                dataString: dataString,
                UdicciCommand: data.UdicciCommand,
                timestamp: curTimestamp,
                response: null,
                count: 1,
            };
            // console.log('%c callWebApi newRequest: %O', 'color: green;', newRequest);
            ACTIVE_REQUEST_LIST.push(newRequest);
            _activeRequestId = newRequest.rid;
            postRequestIsOk = true;
        }

        if (postRequestIsOk) {
            var xhr = new XMLHttpRequest();

            // var _this = this;
            // var _callWebApi = this.callWebApi;
            var _curUser = this.currentUser;
            const _setNextProductionRelease = this.setNextProductionRelease;
            // var _refreshTokens = this.refreshTokens;
            xhr.onload = function () {
                // console.log('%c onload xhr: %O', 'color: red;', xhr);
                var retValue: string;
                retValue = xhr.responseText;

                var returnValue = null;
                if (retValue) {
                    returnValue = JSON.parse(retValue);
                }
                // console.log('%c onload returnValue: %O', 'color: red;', returnValue);

                var responseJson = null;
                if (returnValue && returnValue.result) {
                    responseJson = JSON.parse(returnValue.result);
                }
                // console.log('%c onload responseJson for Request Id %s: %O', 'color: red;', _activeRequestId, responseJson);

                if (responseJson.success === false && responseJson.InvalidAuthentication && responseJson.AllowAutoRefresh) {
                    /*
                    Error processing Udicci Data Request: There appears
                    to be a problem with your request.  Your request 
                    contained an invalid user which did not match your 
                    security tokens.  Please logout and log back in to 
                    the Udicci to correct this problem.
                    */
                    // console.log('%c onload _curUser: %O', 'color: red; font-weight: bold;', _curUser);
                    if (_curUser && _curUser.RefreshToken) {
                        // console.log('%c onload RefreshToken: %O', 'color: red;', _curUser.RefreshToken);
                        // _refreshTokens(apiUrl, data, onSuccess, _curUser);
                        _curUser = null;
                        // console.log('%c onload _curUser: %O', 'color: red;', _curUser);
                        sessionStorage.setItem('udicci.userDetails', '');
                        sessionStorage.setItem('udicci.user.handCash', '');

                        window.location.reload();
                        // if (onSuccess) onSuccess(_curUser);
                    }
                }

                if (responseJson && responseJson.UdicciSecurityStamp) {
                    let securityStamp: any = JSON.parse(responseJson.UdicciSecurityStamp);
                    // console.log('%c onload securityStamp for Request Id %s: %O', 'color: red;', _activeRequestId, securityStamp);
                    var npr: string = (securityStamp && securityStamp.NPR ? securityStamp.NPR : '');
                    // console.log('%c onload nextProductionRelease for Request Id %s: %O', 'color: red;', _activeRequestId, npr);
                    if (npr) {
                        var nprDate: Date = new Date(Date.parse(npr));
                        // console.log('%c nprDate: %O', 'color: red;', nprDate);
                        if (nprDate) _setNextProductionRelease(nprDate);
                    }
                    // var udicciState: string = (securityStamp && securityStamp.USS ? securityStamp.USS : '');
                    // console.log('%c onload udicciState for Request Id %s: %O', 'color: red;', _activeRequestId, udicciState);
                    responseJson.UdicciSecurityStamp = securityStamp;
                }

                let matchingRequestIndex: number = findIndex(ACTIVE_REQUEST_LIST, (ar: any, arIdx: number) => { return ar.rid === _activeRequestId; });
                // console.log('%c onload matchingRequestIndex: %O', 'color: red;', matchingRequestIndex);
                if (matchingRequestIndex >= 0) {
                    // found a matching request
                    let matchingRequest: any = ACTIVE_REQUEST_LIST[matchingRequestIndex];
                    // console.log('%c callWebApi matchingRequest: %O', 'color: green;', matchingRequest);
                    matchingRequest.response = responseJson;
                    ACTIVE_REQUEST_LIST[matchingRequestIndex] = matchingRequest;
                    _activeRequestId = matchingRequest.rid;
                }

                onSuccess(responseJson);
            }

            xhr.onerror = function () {
                // console.log('%c callWebApi onerror', 'color: red;');
                // console.log("Error while calling Web API");
            }

            xhr.open("POST", apiUrl);

            if (this.currentUser && this.currentUser.UdicciUserId) {
                xhr.setRequestHeader("Authorization", "Bearer " + this.currentUser.AccessToken);
            }
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader("Access-Control-Allow-Origin", "*");

            if (data == null) {
                xhr.send();
            }
            else {
                let requestData: string = '';
                // console.log('%c callWebApi data: %O', 'color: orange;', data);
                if (data) {
                    try {
                        if (data.UdicciMediatorName === 'Portas') {
                            var saveData: any = (data.SaveData ? data.SaveData : null);
                            // console.log('%c callWebApi saveData: %O', 'color: purple;', saveData);
                            if (saveData) {
                                var settingsJson: any = (saveData.SettingsJson ? saveData.SettingsJson : null);
                                // console.log('%c callWebApi settingsJson: %O', 'color: purple;', settingsJson);
                                var panes: any[] = (settingsJson.panes ? settingsJson.panes : []);
                                // console.log('%c callWebApi panes: %O', 'color: purple;', panes);

                                forEach(panes, (p: any, idx: number) => {
                                    // console.log('%c callWebApi p: %O', 'color: purple;', p);
                                    if (p.content !== undefined && p.contentDesign !== undefined) delete panes[idx]['contentDesign'];

                                    if (p.content) {
                                        let content: any[] = (p.content ? p.content : []);
                                        // console.log('%c callWebApi content: %O', 'color: purple;', content);
                                        forEach(content, (c: any, idxContent: number) => {
                                            let plugin: any = (c.plugin ? c.plugin : null);
                                            // console.log('%c callWebApi plugin: %O', 'color: purple;', plugin);
                                            let defaultData: any = (c.data && c.data.default ? c.data.default : null);
                                            // console.log('%c callWebApi defaultData: %O', 'color: purple;', defaultData);
                                            if (plugin && plugin.id === 'udicci.context.view' && defaultData) {
                                                let contextView: any = (defaultData.contextView ? defaultData.contextView : null);
                                                // console.log('%c callWebApi contextView: %O', 'color: purple;', contextView);
                                                let contextViewRecord: any = (contextView && contextView.record ? contextView.record : null);
                                                // console.log('%c callWebApi contextViewRecord: %O', 'color: purple;', contextViewRecord);
                                                if (contextViewRecord) {
                                                    if (contextViewRecord.context) delete contextViewRecord['context'];
                                                    contextView.record = contextViewRecord;
                                                    defaultData.contextView = contextView;
                                                }
                                            }
                                            c.data.default = defaultData;
                                            content[idxContent] = c;
                                        });
                                        p.content = content;
                                        panes[idx] = p;
                                    }
                                });
                                settingsJson.panes = panes;

                                saveData.SettingsJson = settingsJson;
                                data.SaveData = saveData;
                            }
                        }
                        // console.log('%c callWebApi data: %O', 'color: purple;', data);

                        requestData = JSON.stringify(data);
                    } catch (ex: any) {
                        // console.log('%c callWebApi data error parsing: %O', 'color: red;', ex);
                    }
                }
                // console.log('%c callWebApi requestData: %O', 'color: blue;', requestData);
                if (requestData) xhr.send(requestData);
            }
        } else if (onSuccess && matchingRequest && matchingRequest.response && !matchIsRecent) {
            onSuccess(matchingRequest.response);
        }
    }

    refreshTokens(apiUrl: string, recallData: UdicciRequestBase, recallFunc: any, currentUser: any) {
        // console.log('%c refreshTokens apiUrl: %O', 'color: red; font-weight: bold;', apiUrl);
        // console.log('%c refreshTokens recallData: %O', 'color: red; font-weight: bold;', recallData);
        // console.log('%c refreshTokens recallFunc: %O', 'color: red; font-weight: bold;', recallFunc);
        // console.log('%c refreshTokens currentUser: %O', 'color: red; font-weight: bold;', currentUser);

        var currentAccessToken = (currentUser && currentUser.AccessToken ? currentUser.AccessToken : '');
        var currentRefreshToken = (currentUser && currentUser.RefreshToken ? currentUser.RefreshToken : '');
        // console.log('%c refreshTokens currentAccessToken: %O', 'color: red; font-weight: bold;', currentAccessToken);
        // console.log('%c refreshTokens currentRefreshToken: %O', 'color: red; font-weight: bold;', currentRefreshToken);

        var url = apiUrl;
        if (url.indexOf(process.env.REACT_APP_UDICCI_MEDIATION_ENDPOINT) >= 0) url = url.replace(process.env.REACT_APP_UDICCI_MEDIATION_ENDPOINT, '');

        var refreshTokenUrl = url.replace('https:', 'http:') + process.env.REACT_APP_UDICCI_REFRESH_TOKEN_ENDPOINT;
        // console.log('%c refreshTokens refreshTokenUrl: %O', 'color: red; font-weight: bold;', refreshTokenUrl);

        try {
            // var headers = {
            //     "Content-Type": "application/x-www-form-urlencoded",
            //     // "content-type": "application/json",
            //     // "accept": "json",
            //     "Access-Control-Allow-Origin": "*",
            // };
            // console.log('%c refreshTokens headers: %O', 'color: red;', headers);

            var urlEncoded = 'token=' + encodeURIComponent(currentAccessToken) + '&refreshToken=' + encodeURIComponent(currentRefreshToken);
            // var refreshRequestSettings: any = {
            //     method: 'POST',
            //     mode: 'cors',
            //     cache: 'no-cache',
            //     credentials: 'include',
            //     headers: headers,
            //     // referrerPolicy: 'no-referrer',
            //     body: urlEncoded,
            // };
            // console.log('%c refreshTokens refreshRequestSettings: %O', 'color: red;', refreshRequestSettings);
            // fetch(refreshTokenUrl, refreshRequestSettings)
            // .then(function (resp) {
            //     // console.log('%c Udicci Request resp: %O', 'color: red; font-weight: bold;', resp);
            //     if (resp && resp.ok === true) {
            //         var rval = resp.json();
            //         return rval;
            //     } else {
            //         throw (resp);
            //     }
            // })
            // .then((response: any) => this.refreshTokensComplete(recallData, recallFunc, response))
            // .catch(e => {
            //     // console.log('%c refreshTokens catch e: %O', 'color: red; font-weight: bold;', e);
            //     // console.log('%c recallSettings: %O', 'color: red; font-weight: bold;', recallSettings);
            //     // console.error(e);

            //     // if (recallSettings && recallSettings.onError) {
            //     //     recallSettings.onError(e);
            //     // } else if (this.notifyMe) {
            //     //     // console.log('%c this.notifyMe: %O', 'color: red; font-weight: bold;', this.notifyMe);
            //     //     this.notifyMe({ message: 'Requires Login' });
            //     // }
            //     // window.location.reload();
            // });

            var headers = { "Content-type": "application/x-www-form-urlencoded", "Accept": "json" };

            var fetchSettings = { method: 'POST', headers: headers, body: urlEncoded, withCredentials: false };
            // console.log('Refresh Token fetchSettings: %O', fetchSettings);

            fetch(refreshTokenUrl, fetchSettings)
            .then(function (resp) {
                // console.log('Refresh Token resp: %O', resp);
                return resp.json();
            })
            .then((response: any) => this.refreshTokensComplete(recallData, recallFunc, response))
            .catch(function (error: any) {
                // console.log('Refresh Token failed %O', error);
            });
        } catch (ex: any) {
            // console.log('refreshTokens failed ex: %O', ex);
        }
    }

    refreshTokensComplete(recallData: UdicciRequestBase, recallFunc: any, response: any) {
        // console.log('%c refreshTokensComplete recallData: %O', 'color: red; font-weight: bold;', recallData);
        // console.log('%c refreshTokensComplete recallFunc: %O', 'color: red; font-weight: bold;', recallFunc);
        // console.log('%c refreshTokensComplete response: %O', 'color: red; font-weight: bold;', response);

        var userDetailsFromStore = sessionStorage.getItem('udicci.userDetails');

        var userDetails = null;
        if (userDetailsFromStore) {
            userDetails = JSON.parse(userDetailsFromStore);
        }
        // console.log('%c refreshTokensComplete userDetails: %O', 'color: red;', userDetails);

        userDetails.currentAccessToken = response.token;
        userDetails.currentRefreshToken = response.refreshToken;

        userDetailsFromStore = JSON.stringify(userDetails);
        sessionStorage.setItem('udicci.userDetails', userDetailsFromStore);

        this.currentUser = userDetails;
        // this.currentRefreshToken = response.refreshToken;

        // console.log('%c this.currentAccessToken: %O', 'color: red;', this.currentAccessToken);
        // console.log('%c this.currentRefreshToken: %O', 'color: red;', this.currentRefreshToken);

        if (recallData !== null || recallFunc) {
            // this.udicciRequest(recallSettings);
            this.callWebApi(recallData, recallFunc);
        }
    }

    refreshTokensOld(recallSettings: any) {
        // console.log('%c refreshTokensOld recallSettings: %O', 'color: red; font-weight: bold; font-size: 1.3em;', recallSettings);

        // console.log('%c refreshTokensOld this: %O', 'color: red; font-weight: bold; font-size: 1.3em;', this);
        var currentAccessToken = (this.currentUser && this.currentUser.AccessToken ? this.currentUser.AccessToken : '');
        var currentRefreshToken = (this.currentUser && this.currentUser.RefreshToken ? this.currentUser.RefreshToken : '');
        // console.log('%c refreshTokensOld currentAccessToken: %O', 'color: red; font-weight: bold;', currentAccessToken);
        // console.log('%c refreshTokensOld currentRefreshToken: %O', 'color: red; font-weight: bold;', currentRefreshToken);

        // var _this = this;
        var url = this.API_URL;
        if (url.indexOf(process.env.REACT_APP_UDICCI_MEDIATION_ENDPOINT) >= 0) url = url.replace(process.env.REACT_APP_UDICCI_MEDIATION_ENDPOINT, '');
        // var refreshTokenUrl = url + '/token/refresh';
        var refreshTokenUrl = url + process.env.REACT_APP_UDICCI_REFRESH_TOKEN_ENDPOINT;
        // console.log('%c refreshTokensOld refreshTokenUrl: %O', 'color: red; font-weight: bold;', refreshTokenUrl);

        // var userDetailsFromStore = sessionStorage.getItem('udicci.userDetails');

        // var userDetails = null;
        // if (userDetailsFromStore) {
        //     userDetails = JSON.parse(userDetailsFromStore);
        // }
        // console.log('%c refreshTokensOld userDetails: %O', 'color: red;', userDetails);

        try {
            var headers = { "Content-type": "application/x-www-form-urlencoded", "Accept": "json" };
            var urlEncoded = 'token=' + encodeURIComponent(currentAccessToken) + '&refreshToken=' + encodeURIComponent(currentRefreshToken);
            var refreshRequestSettings = { method: 'POST', headers: headers, body: urlEncoded };
            fetch(refreshTokenUrl, refreshRequestSettings)
            .then(function (resp) {
                // console.log('%c Udicci Request resp: %O', 'color: red; font-weight: bold;', resp);
                if (resp && resp.ok === true) {
                    var rval = resp.json();
                    return rval;
                } else {
                    throw (resp);
                }
            })
            .then((resp: any) => this.refreshTokensOldComplete(recallSettings, resp))
            .catch(e => {
                // console.log('%c catch e: %O', 'color: red; font-weight: bold;', e);
                // console.log('%c recallSettings: %O', 'color: red; font-weight: bold;', recallSettings);
                // console.error(e);
                // this.requiresLogin = true;

                if (recallSettings && recallSettings.onError) {
                    recallSettings.onError(e);
                // } else if (this.notifyMe) {
                //     // console.log('%c this.notifyMe: %O', 'color: red; font-weight: bold;', this.notifyMe);
                //     this.notifyMe({ message: 'Requires Login' });
                }
                // window.location.reload();
            });
        } catch (ex) {
            // console.log('refreshTokens failed ex: %O', ex);
            // this.requiresLogin = true;

            // console.log('refreshTokens recallSettings %O', recallSettings);
            if (recallSettings && recallSettings.onError) {
                recallSettings.onError(ex);
            }
        }
    }

    refreshTokensOldComplete(recallSettings: any, response: any) {
        // console.log('%c refreshTokensOldComplete recallSettings: %O', 'color: red; font-weight: bold;', recallSettings);
        // console.log('%c refreshTokensOldComplete response: %O', 'color: red; font-weight: bold;', response);

        var userDetailsFromStore = sessionStorage.getItem('udicci.userDetails');

        var userDetails = null;
        if (userDetailsFromStore) {
            userDetails = JSON.parse(userDetailsFromStore);
        }
        // console.log('%c refreshTokensOldComplete userDetails: %O', 'color: red;', userDetails);

        userDetails.currentAccessToken = response.token;
        userDetails.currentRefreshToken = response.refreshToken;

        userDetailsFromStore = JSON.stringify(userDetails);
        sessionStorage.setItem('udicci.userDetails', userDetailsFromStore);

        // if (classRef.currentUser) {
        //     classRef.currentUser.AccessToken = response.token;
        //     classRef.currentUser.RefreshToken = response.refreshToken;
        // }
        // console.log('%c classRef.currentUser: %O', 'color: red;', classRef.currentUser);

        // if (recallSettings) {
        //     this.udicciRequest(recallSettings);
        // }
    }

    getRedirectionLoginUrl() {
        if (!this.handCashConnect || (this.currentUser && this.currentUser.HandCashOptions)) return false;

        var currentUrl = UrlParser(window.location.href, true);
        // console.log('%c UdicciFactory getRedirectionLoginUrl currentUrl: %O', 'color: maroon;', currentUrl);
        var { pathname, hash, origin } = currentUrl;
        var extraParams: any = {
            redirectUrl: origin,
            udicciPortal: pathname,
            udicciInstruction: hash.replace('#', ''),
            referrer: 'tim.maslyn' // HandCash Handle
        };
        // console.log('%c UdicciFactory getRedirectionLoginUrl extraParams: %O', 'color: maroon;', extraParams);
        const redirectionLoginUrl =  this.handCashConnect.getRedirectionUrl(extraParams);
        // console.log('%c UdicciFactory getRedirectionLoginUrl redirectionLoginUrl: %O', 'color: maroon;', redirectionLoginUrl);
        return redirectionLoginUrl;
    };

    configureHandCashAuth() {
        if (!this.handCashConnect) return false;

        var userHandCashFromStore = sessionStorage.getItem('udicci.user.handCash');

        var userHandCash = null;
        if (userHandCashFromStore) {
            userHandCash = JSON.parse(userHandCashFromStore);
        }
        if (!userHandCash) userHandCash = { handCashOptions: null };
        // console.log('%c configureHandCashAuth userHandCash: %O', 'color: red;', userHandCash);

        var userDetailsFromStore = sessionStorage.getItem('udicci.userDetails');

        var userDetails = null;
        if (userDetailsFromStore) {
            userDetails = JSON.parse(userDetailsFromStore);
        }
        // console.log('%c configureHandCashAuth userDetails: %O', 'color: red;', userDetails);

        if (userDetails) {
            userDetails.HandCashOptions = userHandCash.handCashOptions;
            // console.log('%c configureHandCashAuth userDetails: %O', 'color: red;', userDetails);
        }

        userDetailsFromStore = JSON.stringify(userDetails);
        sessionStorage.setItem('udicci.userDetails', userDetailsFromStore);

        this.currentUser = userDetails;
    };

    getBlockChainTransaction = async (chainAddress: String, chainNetwork: String = this.defaultChainNetwork) => {
        // console.log('%c getBlockChainTransaction chainAddress: %O', 'color: darkorange;', chainAddress);
        // this.callBlockChainApi(chainNetwork, "info", "/tx/hash/" + chainAddress, this.getBlockChainRequestCompleted);
    };

    getBlockChainGetAddressInfo = async (chainAddress: String, chainNetwork: String = this.defaultChainNetwork) => {
        // console.log('%c getBlockChainGetAddressInfo chainAddress: %O', 'color: darkorange;', chainAddress);
        // this.callBlockChainApi(chainNetwork, "info", "/address/" + chainAddress + "/info", this.getBlockChainRequestCompleted);
    };

    getBlockChainGetAddressBalance = async (chainAddress: String, chainNetwork: String = this.defaultChainNetwork) => {
        // console.log('%c getBlockChainGetAddressBalance chainAddress: %O', 'color: darkorange;', chainAddress);
        // this.callBlockChainApi(chainNetwork, "balance", "/address/" + chainAddress + "/balance", this.getBlockChainRequestCompleted);
    };

    getBlockChainRequestCompleted = (requestDetails: String, response: any) => {
        // console.log('%c getBlockChainRequestCompleted requestDetails: %O', 'color: purple;', requestDetails);
        // console.log('%c getBlockChainRequestCompleted response: %O', 'color: purple;', response);
        // if (this.contextC-allback) this.contextC-allback(response);
    };

    getSignalRUrl = () => {
        var localUrl: string = "https://localhost:44349/mediationFeed";
        var devUrl: string = 'https://rt.udicci.com:44349/mediationFeed';
        // console.log('%c testSignalR localUrl: %O', 'color: purple;', localUrl);
        // console.log('%c testSignalR devUrl: %O', 'color: purple;', devUrl);
        return devUrl;
    };

    sendSocketMessage = async (request: UdicciRequestBase, options: any = {}) => {
        // console.log('%c sendSocketMessage request: %O', 'color: purple;', request);
        // console.log('%c sendSocketMessage options: %O', 'color: purple;', options);

        // console.log('%c sendSocketMessage this.selectedProfile: %O', 'color: purple;', this.selectedProfile);
        if(!this.selectedProfile || (this.selectedProfile && (!this.selectedProfile.recordId))) return;

        if(!request) return;
        if(!request.UdicciCommand) return;
        if(!request.UdicciMediatorName) return;

        if (request.SelectedUdicciProfileId <= 0) {
            var selectedProfileId: number = 0;
            if (this.selectedProfile) {
                selectedProfileId = this.selectedProfile.recordId;
            } else {
                selectedProfileId = this.ulyssesDConstantineProfileId;
            }
            request.SelectedUdicciProfileId = selectedProfileId;
        }
        if(request.SelectedUdicciProfileId <= 0) return;

        if (request.UserId <= 0 && this.currentUser !== null && this.currentUser.UdicciUserId) {
            request.UserId = this.currentUser.UdicciUserId;
        }
        // console.log('%c sendSocketMessage request: %O', 'color: purple;', request);
        var onSuccess = ((response: any) => this.sendSocketMessageSuccess(request, options, response));
        var onFail = ((response: any) => this.sendSocketMessageFail(request, options, response));
        // this.callWebApi(request, onSuccess, onFail);
    }

    sendSocketMessageSuccess = (request: any, settings: any, response: any) => {
        // console.log('%c sendSocketMessageSuccess request: %O', 'color: green;', request);
        // console.log('%c sendSocketMessageSuccess settings: %O', 'color: green;', settings);
        // console.log('%c sendSocketMessageSuccess response: %O', 'color: green;', response);
        // this.notifySubscribers(request, response, settings);
    }

    sendSocketMessageFail = (request: any, settings: any, response: any) => {
        // console.log('%c sendSocketMessageFail request: %O', 'color: green;', request);
        // console.log('%c sendSocketMessageFail settings: %O', 'color: green;', settings);
        // console.log('%c sendSocketMessageFail response: %O', 'color: green;', response);
        // this.notifySubscribers(request, response, settings);
    }

    initializeAnalyticsForPortal = async () => {
        var measurementId: string = "";
        let profile: any = (this.selectedProfile && this.selectedProfile.data ? this.selectedProfile.data : null);
        // console.log('%c initializeAnalyticsForPortal profile: %O', 'color: darkorange;', profile);
        let profileSettings: any = null;
        if (profile && profile.ProfileSettingsJson) {
            if (!profile.jsonProfileSettingsJson && profile.ProfileSettingsJson) {
                try {
                    profile.jsonProfileSettingsJson = JSON.parse(profile.ProfileSettingsJson);
                } catch (e: any) {

                }
            }
            if (profile.jsonProfileSettingsJson) profileSettings = profile.jsonProfileSettingsJson;
        }
        // this.developerLog('%c initializeAnalyticsForPortal profileSettings: %O', 'color: red;', profileSettings);

        var analytics: any = (profileSettings && profileSettings.analytics ? profileSettings.analytics : null);
        // console.log('%c initializeAnalyticsForPortal analytics: %O', 'color: maroon;', analytics);
        var analyticsDataStreams: any[] = (analytics && analytics.dataStreams ? analytics.dataStreams : []);
        // console.log('%c initializeAnalyticsForPortal analyticsDataStreams: %O', 'color: maroon;', analyticsDataStreams);

        let foundStream: boolean = false;
        if (analyticsDataStreams.length > 0) {
            let browserUrl: string = window.location.href;
            let currentUrl = UrlParser(browserUrl, true);
            // console.log('%c initializeAnalyticsForPortal currentUrl: %O', 'color: purple;', currentUrl);
    
            forEach(analyticsDataStreams, (ds: any, idx: number) => {
                // console.log('%c ds: %O', 'color: blue;', ds);
                // console.log('%c ds.url: %O', 'color: blue;', ds.url);
                // console.log('%c currentUrl: %O', 'color: blue;', currentUrl);
                let dsurl = UrlParser(ds.url, true);
                // console.log('%c dsurl: %O', 'color: blue;', dsurl);
                let isSameHost: boolean = (dsurl.host.toLowerCase() === currentUrl.host.toLowerCase() ? true : false);
                let isSamePath: boolean = (dsurl.pathname.toLowerCase() === currentUrl.pathname.toLowerCase() ? true : false);
                if (isSameHost || isSamePath) {
                    measurementId = ds.measurementId;
                    foundStream = true;
                }
            });
        }
        // console.log('%c initializeAnalyticsForPortal foundStream: %O', 'color: red;', foundStream);
        // console.log('%c initializeAnalyticsForPortal measurementId: %O', 'color: red;', measurementId);

        if (foundStream && measurementId) {
            // console.log('%c initializeAnalyticsForPortal measurementId: %O', 'color: blue;', measurementId);
            // Data Stream Udicci.com
            // Data Stream Udicci
            // measurementId = "G-HDCY0EBKQ0"; // Udicci Data Stream Measurement Id
            ReactGA.initialize(measurementId);
            this.ANALYTICS_MEASUREMENT_ID = measurementId;
        }
    };

    private callBlockChainApi(chainNetwork: String, requestDetails: String, requestUrl: String, onSuccess: any): void {
        // console.log('%c callBlockChainApi requestUrl: %O', 'color: red;', requestUrl);
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var returnValue = (xhr && xhr.responseText ? JSON.parse(xhr.responseText) : null);
            // console.log('%c onload returnValue: %O', 'color: red;', returnValue);
            onSuccess(requestDetails, returnValue);
        }
    
        xhr.onerror = function () {
            // console.log("Error while calling BlockChain API");
        }

        var chainUrl = 'https://api.whatsonchain.com';
        var chainVersion = '/v1';
        var chainName = '/bsv';
        var useChainNetwork = '/' + (chainNetwork ? chainNetwork : this.defaultChainNetwork);
        var chainRequestUrl = chainUrl + chainVersion + chainName + useChainNetwork;
        var chainAddressRequestUrl = chainRequestUrl + requestUrl;
        // console.log('%c chainAddressRequestUrl: %O', 'color: green; font-weight: bold;', chainAddressRequestUrl);
        xhr.open("GET", chainAddressRequestUrl);

        xhr.send();
        // if (data == null) {
        //     xhr.send();
        // }
        // else {
        //     xhr.send(JSON.stringify(data));
        // }
    }
}
