
import React, { useState, useEffect } from 'react';

// import Pluralize from 'pluralize';

import { Box, Paper, Typography, IconButton, Icon } from '@mui/material';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext, getMediatorStructures } from 'src/context/udicci-context'

import { forEach, keys, values } from 'underscore';

import MediatorStructureForm from 'src/admin/social-solution-management/mediator-structure-form';

const SocialSolutionStructureManagement: React.FC<any> = (props: any) => {
    // console.log('%c SocialSolutionStructureManagement props: %O', 'color: red;', props);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();

    let { udicci } = udicciContext.state;

    let { configuredMediators, onClose } = props;
    // console.log('%c SocialSolutionStructureManagement configuredMediators: %O', 'color: red;', configuredMediators);

    const [manageMediator, setManageMediator] = useState<any>(null);
    // console.log('%c SocialSolutionStructureManagement manageMediator: %O', 'color: red;', manageMediator);

    useEffect(() => {
        // console.log('%c CreateUI useEffect engagementData: %O', 'color: blue;', engagementData);
        let checkMediatorStructure: any = udicciHelpers.getMediatorStructure('Udicci Mediators');
        // console.log('%c checkMediatorStructure: %O', 'color: hotpink;', checkMediatorStructure);
        let checkFieldStructure: any = udicciHelpers.getMediatorStructure('Udicci Mediator Fields');
        // console.log('%c checkFieldStructure: %O', 'color: hotpink;', checkFieldStructure);
        let checkLumStructure: any = udicciHelpers.getMediatorStructure('Linked Udicci Mediators');
        // console.log('%c checkLumStructure: %O', 'color: hotpink;', checkLumStructure);

        if (!checkMediatorStructure || !checkFieldStructure || !checkLumStructure) {
            let udicciMediatorNames: string[] = [];
            udicciMediatorNames.push('Udicci Mediators');
            udicciMediatorNames.push('Udicci Mediator Fields');
            udicciMediatorNames.push('Linked Udicci Mediators');
            // console.log('%c udicciMediatorNames: %O', 'color: hotpink;', udicciMediatorNames);
            getMediatorStructures(udicciMediatorNames);
        }
    }, []);

    const onManageMediator = (med: any) => {
        // console.log('%c onManageMediator med: %O', 'color: blue;', med);
        setManageMediator((med && manageMediator && manageMediator.id === med.id ? null : med));
        if (med && med.name) {
            let structure: any = udicciHelpers.getMediatorStructure(med.name);
            // console.log('%c onClickMediator structure: %O', 'color: hotpink;', structure);
            if (!structure) {
                let requestMediators: string[] = [];
                requestMediators.push(med.name);
                // console.log('%c onClickMediator requestMediators: %O', 'color: hotpink;', requestMediators);
                udicci.getMediatorStructures(requestMediators);
            }
        }
    };

    let sortedMediators: any[] = [];
    if (configuredMediators) {
        if (keys(configuredMediators).length > 0) {
            sortedMediators = values(configuredMediators).sort((a: any, b: any) => {
                let sortA: string = (a.label ? a.label : a.name);
                let sortB: string = (b.label ? b.label : b.name);
                if (sortA.toLowerCase() < sortB.toLowerCase()) return -1;  // ascending
                if (sortA.toLowerCase() > sortB.toLowerCase()) return 1;  // descending
                return 0 //default return value (no sorting)
            });
        }
    }
    // console.log('%c SocialSolutionStructureManagement sortedMediators: %O', 'color: red;', sortedMediators);

    let assignedMediatorsElement: any = (
        <Box>
            {sortedMediators.map((mdtr: any) => {
                let mediatorText: string = (mdtr.label ? mdtr.label : mdtr.name);
                let ftre: string = (mdtr.feature ? mdtr.feature : '');
                // console.log('%c ftre: %O', 'color: red;', ftre);
                let ftreList: any[] = (mdtr.features ? mdtr.features : []);
                // console.log('%c ftreList: %O', 'color: red;', ftreList);

                let featureAssignmentsCount: number = 0;
                if (ftre && (ftreList && ftreList.length > 0 && !ftreList.find(f => f.name === ftre))) featureAssignmentsCount++;
                if (ftreList && ftreList.length > 0) featureAssignmentsCount++;
                // console.log('%c featureAssignmentsCount: %O', 'color: red;', featureAssignmentsCount);

                let manageMediatorButtonElement: any = null;
                let manageMediatorElement: any = null;
                if (manageMediator && manageMediator.id === mdtr.id) {
                    manageMediatorElement = (
                        <MediatorStructureForm mediator={manageMediator} onClose={(evt: any) => onManageMediator(null)} />
                    );
                    manageMediatorButtonElement = (
                        <IconButton size="small" onClick={(evt: any) => onManageMediator(null)}><Icon>close</Icon></IconButton>
                    );
                } else {
                    manageMediatorButtonElement = (
                        <IconButton size="small" onClick={(evt: any) => onManageMediator(mdtr)} color={(featureAssignmentsCount > 0 ? "success" : "info")}>
                            <Icon>settings</Icon>
                        </IconButton>
                    );
                }

                return (
                    <Paper key={'social.solution.structure.' + mdtr.id} elevation={3} sx={{ margin: '4px' }}>
                        {manageMediatorButtonElement}
                        <Typography variant={(featureAssignmentsCount > 0 ? "subtitle1" : "caption")} component="span">
                            {mediatorText}
                        </Typography>
                        {manageMediatorElement}
                    </Paper>
                );
            })}
        </Box>
    );
    return (
        <Box sx={{ padding: '16px' }}>
            <Box sx={{ borderBottom: '1px solid #ddd' }}>
                <Typography variant="subtitle2" component="div">Social Solution Structure Management</Typography>
                <Typography variant="caption" component="div">These are the mediators that are currently assigned to this Social Solution.</Typography>
                {assignedMediatorsElement}
            </Box>
        </Box>
    );
}

export default SocialSolutionStructureManagement;
