
import { MintMVnfTConfig } from './MintMVnfT/MintMVnfTConfig';
import { MintMVnfTUI } from './MintMVnfT/MintMVnfTUI';
import { MintMVnfTMenu } from './MintMVnfT/MintMVnfTMenuItem';
import { MintMVnfTHelp } from './MintMVnfT/MintMVnfTHelp';

const triggerAction = async (options: any) =>  {
    // console.log('%c MintMVnfT triggerAction options: %O', 'color: blue;', options);
    return false;
}

const submitAction = async (options: any) =>  {
    // console.log('%c MintMVnfT submitAction options: %O', 'color: blue;', options);
    return false;
}

const MintMVnfT: React.FC<any> = (props) => {
    // console.log('%c MintMVnfT props: %O', 'color: blue;', props);

    let configureAction = (props && props.configure === true ? true : false);

    let actionProps: any = {};
    Object.assign(actionProps, props);
    // console.log('%c actionProps: %O', 'color: red;', actionProps);

    let actionElement: any = null;
    if (configureAction) {
        actionElement = (<MintMVnfTConfig {...actionProps} />);
    } else {
        actionElement = (<MintMVnfTUI {...actionProps} />);
    }
    return actionElement;
}

export { MintMVnfT, MintMVnfTMenu, MintMVnfTHelp, triggerAction, submitAction }
