import { Fragment, useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import useUdicciHelpers, { UDICCI_NEW_LINE_CONSTANT } from 'src/hooks/useUdicciHelpers';
import { findIndex } from 'underscore';

import { useFocusedContext } from './focused-context';

export const InteractiveSentencesDisplay: React.FC<any> = (props) => {
    const focus = useFocusedContext();
    // console.log('%c InteractiveSentencesDisplay focus: %O', 'color: green;', focus);

    let { stringValue, displayProps, hoverProps, selectedProps } = props;

    var workingString: string = stringValue.toString();
    var focusSelectedSentences: any[] = (focus.aiPrompt && focus.aiPrompt.selectedSentences ? focus.aiPrompt.selectedSentences : []);
    let selectedSentenceCount: number = focusSelectedSentences.length;
    // console.log('%c InteractiveSentencesDisplay focusSelectedSentences: %O', 'color: green;', focusSelectedSentences);

    const udicciHelpers = useUdicciHelpers();

    const [highlightSentenceIndex, setHighlightSentence] = useState<number>(-1);
    const [selectedSentences, setSelectedSentences] = useState<any[]>(focusSelectedSentences);
    const [workingSentences, setWorkingSentences] = useState<any[]>([]);
    // console.log('%c InteractiveSentencesDisplay selectedSentences: %O', 'color: green;', selectedSentences);
    // console.log('%c InteractiveSentencesDisplay workingSentences: %O', 'color: green;', workingSentences);

    const onMouseEnter = (evt: any, sentenceIndex: number) => {
        // console.log('%c onMouseEnter sentenceIndex: %O', 'color: green;', sentenceIndex);
        if (sentenceIndex !== highlightSentenceIndex)
            setHighlightSentence(sentenceIndex);
    };
    const onMouseLeave = () => {
        if (highlightSentenceIndex >= 0)
            setHighlightSentence(-1);
    };

    const onSentenceClick = (evt: any, sentenceItem: any): any => {
        // console.log('%c onSentenceClick sentenceItem: %O', 'color: green;', sentenceItem);
        let aiPromptUpdated: any = {};
        if (focus.aiPrompt) Object.assign(aiPromptUpdated, focus.aiPrompt);

        let flexPrompt: string = (aiPromptUpdated && aiPromptUpdated.flexPrompt ? aiPromptUpdated.flexPrompt : '');
        // console.log('%c onSentenceClick flexPrompt: %O', 'color: green;', flexPrompt);

        let newSelectedSentences: any[] = (selectedSentences ? selectedSentences : []);
        const sentenceIndex: number = findIndex(newSelectedSentences, (x: any) => { return x.uid === sentenceItem.uid; });
        // console.log('%c onSentenceClick sentenceIndex: %O', 'color: green;', sentenceIndex);
        if (sentenceIndex >= 0) {
            let removeSentence: string = newSelectedSentences[sentenceIndex].sentence;
            // console.log('%c onSentenceClick flexPrompt: %O', 'color: green;', flexPrompt);
            if (flexPrompt.indexOf(removeSentence) >= 0) {
                flexPrompt = flexPrompt.replace(removeSentence, '');
            }
            newSelectedSentences.splice(sentenceIndex, 1);
        } else {
            newSelectedSentences.push(sentenceItem);
            flexPrompt += (flexPrompt.length > 0 ? ' ' : '') + sentenceItem.sentence;
        }
        // console.log('%c onSentenceClick newSelectedSentences: %O', 'color: green;', newSelectedSentences);

        if (!aiPromptUpdated.selectedSentences) aiPromptUpdated.selectedSentences = [];

        aiPromptUpdated.selectedSentences = newSelectedSentences;
        aiPromptUpdated.flexPrompt = flexPrompt.replace(/\s{3,}/g,'  ').trim();
        // console.log('%c onSentenceClick aiPromptUpdated: %O', 'color: green;', aiPromptUpdated);
        if (focus.updateAIPrompt) focus.updateAIPrompt(aiPromptUpdated);
        setSelectedSentences(newSelectedSentences);
    };

    useEffect(() => {
        // console.log('%c useEffect focus.aiPrompt.selectedSentences: %O', 'color: red;', focus.aiPrompt.selectedSentences);
        if (stringValue.length > 0 && workingSentences.length <= 0) {
            var sentences: any[] = udicciHelpers.getSentencesArrayFromString(workingString);
            setWorkingSentences(sentences);
        }
    }, []);

    useEffect(() => {
        // console.log('%c useEffect selectedSentences: %O', 'color: blue;', selectedSentences);
        // console.log('%c useEffect focus.aiPrompt.selectedSentences: %O', 'color: blue;', focus.aiPrompt.selectedSentences);
        if (focus.aiPrompt && selectedSentences.length !== focus.aiPrompt.selectedSentences.length) {
            setSelectedSentences(focus.aiPrompt.selectedSentences);
        }
    }, [selectedSentenceCount]);

    var interactiveSentences: any[] = [];

    workingSentences.forEach((sentenceItem: any, idxSentence: number) => {
        // console.log('%c sentenceItem: %O', 'color: green;', sentenceItem);
        // console.log('%c idx: %O', 'color: green;', idx);
        // let isPartOfFilter: boolean = true;
        // if (viewMediatorSentences && filteredSentences) {
        //     const filterIndex: number = findIndex(filteredSentences, (x: any) => { return x.sentenceIndex === idxSentence; });
        //     if (filterIndex < 0) isPartOfFilter = false;
        // }
        let sentence = sentenceItem.sentence;
        let sentenceUid = sentenceItem.uid;
        let sentenceDisplay: string = sentence;

        let sx: any = {};
        let sentenceProps: any = null;
        if (displayProps) {
            if (!sentenceProps)
                sentenceProps = {};
            Object.assign(sentenceProps, displayProps);
        }
        if (highlightSentenceIndex === idxSentence) {
            if (hoverProps) {
                if (!sentenceProps)
                    sentenceProps = {};
                Object.assign(sentenceProps, hoverProps);
            }
        }
        const selectedSentenceIndex: number = findIndex(selectedSentences, (x: any) => { return x.uid === sentenceUid; });
        if (selectedSentenceIndex >= 0) {
            if (selectedProps) {
                if (!sentenceProps)
                    sentenceProps = {};
                Object.assign(sentenceProps, selectedProps);
            }
        }
        if (!sentenceProps)
            sentenceProps = {};
        if (!sentenceProps.sx)
            sentenceProps.sx = {};
        if (!sentenceProps.sx.marginRight)
            sentenceProps.sx.marginRight = '4px';

        // let allAssignedSentenceIndexes: number[] = [];
        // if (showAssignedContent) {
        //     forEach(keys(lastMediation), (medName: string) => {
        //         if (lastMediation[medName].selectedSentences && lastMediation[medName].selectedSentences.length > 0) {
        //             forEach (lastMediation[medName].selectedSentences, (s: any, sIdx: number) => {
        //                 if (allAssignedSentenceIndexes.indexOf(s.sentenceIndex) < 0) allAssignedSentenceIndexes.push(s.sentenceIndex);
        //             });
        //         }
        //     });
        // }
        // console.log('%c allAssignedSentenceIndexes: %O', 'color: green;', allAssignedSentenceIndexes);
        // if ((viewMediatorSentences && isPartOfFilter) || (allAssignedSentenceIndexes.indexOf(idxSentence) >= 0) && showAssignedContent) {
        //     sentenceVariant = selectedVariant;
        //     sx.color = 'blue';
        //     sx.fontWeight = 'bold';
        // }
        var key = 'sentence.' + sentenceUid + '.' + idxSentence;
        let localProps: any = {
            key: key,
            sx: sx,
            component: "span",
            onMouseEnter: (evt: any) => onMouseEnter(evt, idxSentence),
            onMouseLeave: (evt: any) => onMouseLeave(),
            onClick: (evt: any) => onSentenceClick(evt, sentenceItem),
        };

        let sentenceDisplayElement: any = null;
        if (sentence.indexOf(UDICCI_NEW_LINE_CONSTANT) === 0) {
            sentenceDisplay = sentenceDisplay.replace(UDICCI_NEW_LINE_CONSTANT, '');
            let numberOfNewLines: number = parseInt(sentenceDisplay, 10);
            let brEls: any[] = [];
            if (numberOfNewLines === 1) {
                brEls.push(<br key={key + '.0'} />);
            } else {
                // we are removing one new line break when there are more than howManyBreaksToRemove,
                // so only loop number of new lines - howManyBreaksToRemove
                let howManyBreaksToRemove: number = 1;
                for (let i: number = 0; i < (numberOfNewLines - howManyBreaksToRemove); i++) {
                    brEls.push(<br key={key + '.' + i.toString()} />);
                }
            }
            sentenceDisplayElement = (<Fragment>{brEls}</Fragment>);
        } else {
            if (sentenceDisplay.indexOf('Udicci_IT') >= 0) {
                sentenceDisplay = sentenceDisplay.replace(/udicci_it/ig, 'Udicci.IT');
            }

            sentenceDisplayElement = (<Typography {...localProps} {...sentenceProps}>{sentenceDisplay}</Typography>);
        }
        if (sentenceDisplayElement)
            interactiveSentences.push(sentenceDisplayElement);
    });

    return (<Box>{interactiveSentences}</Box>);
};
