import { Fragment, useState, useEffect } from 'react';

import { useTheme } from '@mui/material/styles';

import UrlParser from 'url-parse';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import {
    Launch as LaunchIcon,
    SwitchAccount as SwitchAccountIcon,
    PersonSearch as FindPortalIcon
} from '@mui/icons-material';

import { forEach, values, sortBy, find } from 'underscore';

import DefaultSocialIcon from 'src/images/Ulysses Selfie Portrait.png'

import { useUdicciContext, sendPreparedRequest } from 'src/context/udicci-context';
import { AssignUserToRoleRequest, UpdateMediationAgreementRequest } from 'src/interfaces/udicci-request-interfaces';

import { udicciStyles } from 'src/theme/shared-styles';

export default function Relationships(props: any) {
    // console.log('%c Relationships props: %O', 'color: blue;', props);

    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const udicciContext = useUdicciContext();
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    let { udicci, data } = udicciContext.state;
    // console.log('%c Relationships data: %O', 'color: blue;', data);
    // console.log('%c Relationships udicci: %O', 'color: blue;', udicci);

    let { selectedProfile, currentUser } = udicci;
    // console.log('%c Relationships selectedProfile: %O', 'color: blue;', selectedProfile);
    // console.log('%c Relationships currentUser: %O', 'color: blue;', currentUser);

    const [selectedAgreement, setSelectedAgreement] = useState<any>(null);
    const [relationshipFilter, setRelationshipFilter] = useState<string>('all');
    const [assigningRole, setAssigningRole] = useState<boolean>(false);
    const [acceptingRole, setAcceptingRole] = useState<boolean>(false);
    const [agreementsState, setAgreementsState] = useState<boolean>(false);
    const [showFindPortal, setShowFindPortal] = useState<boolean>(false);
    const [profileData, setProfileData] = useState<any>(null);
    const [searchFor, setSearchFor] = useState<string>('');
    const [assignProfileRoleRequest, setAssignProfileRoleRequest] = useState<any>(null);
    const [profilesRequested, setProfilesRequested] = useState<boolean>(false);
    const [assignRole, setAssignRole] = useState<boolean>(false);
    const [forProfileId, setForProfileId] = useState<number>(-1);
    const [savingAgreement, setSavingAgreement] = useState<boolean>(false);
    const [forcedUpdateState, forceUpdate] = useState<boolean>(false);
    // console.log('%c Relationships agreementsState: %O', 'color: blue;', agreementsState);
    // console.log('%c Relationships profileData: %O', 'color: blue;', profileData);

    let selectedProfileId: number = (selectedProfile && selectedProfile.recordId ? selectedProfile.recordId : 0);
    // console.log('%c Relationships selectedProfileId: %O', 'color: blue;', selectedProfileId);
    let profileDisplayName: string = (selectedProfile && selectedProfile.title ? selectedProfile.title : '');
    // console.log('%c Relationships profileDisplayName: %O', 'color: blue;', profileDisplayName);

    let currentUserId: number = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
    let selectedProfileIsUserProfile: boolean = (currentUser && selectedProfileId === currentUser.MeUdicciProfileId ? true : false);
    // console.log('%c Relationships selectedProfileIsUserProfile: %O', 'color: blue;', selectedProfileIsUserProfile);
    if (currentUser && !selectedProfileIsUserProfile && agreementsState) {
        selectedProfileId = currentUser.MeUdicciProfileId;
        profileDisplayName = currentUser.myDisplayName;
        // console.log('%c Relationships selectedProfileId: %O', 'color: red;', selectedProfileId);
    }

    let defaultAgreements = (props && props.agreements ? props.agreements : []);
    // console.log('%c Relationships defaultAgreements: %O', 'color: blue;', defaultAgreements);

    let roleData: any = null;
    if (data) {
        let mediatorContext = data.find((x: any) => x.mediator === 'Roles' );
        // console.log('%c Relationships mediatorContext: %O', 'color: maroon;', mediatorContext);
        if (mediatorContext && mediatorContext.records) roleData = mediatorContext.records;
    }
    // console.log('%c Relationships roleData: %O', 'color: maroon;', roleData);

    const openUrl = (url: string, target: string = '_self') => {
        // console.log('%c openUrl url: %O', 'color: hotpink;', url);
        let currentUrl = UrlParser(window.location.href, true);
        let { pathname } = currentUrl;
        if (url && url.toLowerCase() !== pathname.toLowerCase()) {
            window.open(url, target);
        }
    }

    const reloadAgreements = () => {
        if (props && props.onRefreshMediationAgreements) {
            // console.log('%c Relationships agreementsState: %O', 'color: blue;', agreementsState);
            props.onRefreshMediationAgreements(!agreementsState);
        }

        // console.log('%c Relationships agreementsState: %O', 'color: blue;', agreementsState);
        setAgreementsState(!agreementsState);
    };

    const assignRoleFocus = (relationship: any, evt: any) => {
        // console.log('%c assignRoleFocus relationship: %O', 'color: red;', relationship);
        if (selectedAgreement && selectedAgreement.profileId === relationship.profileId) {
            setSelectedAgreement(null);
        } else {
            setSelectedAgreement(relationship);
        }
    };

    const closeAssignRole = (evt: any) => {
        setSelectedAgreement(null);
    };

    const toggleFindPortal = (evt: any) => {
        setShowFindPortal(!showFindPortal);
    };

    const getProfileListForSearch = () => {
        let optionsJson = {
            mediator: 'Udicci Profiles',
            profileId: udicci.ulyssesDConstantineProfileId,
            socialSolutionId: udicci.socialSolutionUdicciIT,
            onSuccess: profilesReceived,
            onError: profilesFailed
        };
        // console.log('%c optionsJson: %O', 'color: blue;', optionsJson);

        udicci.getData(optionsJson);    
        setProfilesRequested(true);
    }

    const profilesReceived = (data: any, additionalData: any) => {
        // console.log('%c profilesReceived data: %O', 'color: purple;', data);
        setProfilesRequested(false);
        setProfileData(data);
    }

    const profilesFailed = (response: any) => {
        // console.log('%c profilesFailed response: %O', 'color: purple;', response);
    }

    const onChangeRole = (evt: any) => {
        // console.log('%c onChangeRole selectedAgreement: %O', 'color: red;', selectedAgreement);
        // console.log('%c onChangeRole evt: %O', 'color: red;', evt);
        if (selectedAgreement) {
            let trgt = evt.target;
            let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
            // console.log('%c onChangeRole newValue: %O', 'color: maroon;', newValue);

            let providedAgreements = (selectedAgreement.providedAgreements ? selectedAgreement.providedAgreements : [] );
            // console.log('%c Relationships providedAgreements: %O', 'color: blue;', providedAgreements);
            let providedAgreement = (providedAgreements.length > 0 ? providedAgreements[0] : null );
            // console.log('%c Relationships providedAgreement: %O', 'color: blue;', providedAgreement);

            let paData = (providedAgreement && providedAgreement.data ? providedAgreement.data : null );
            // console.log('%c Relationships paData: %O', 'color: blue;', paData);

            let currentRoleId: any = (paData && paData.AcceptingUserRoleId ? paData.AcceptingUserRoleId : 0);
            if (selectedAgreement.newRoleId !== currentRoleId) currentRoleId = selectedAgreement.newRoleId;
            // console.log('%c currentRoleId: %O', 'color: maroon;', currentRoleId);

            if (currentRoleId !== newValue) {
                selectedAgreement.newRoleId = newValue;
                selectedAgreement.isDirty = true;
            }

            // console.log('%c onChangeRole selectedAgreement: %O', 'color: maroon;', selectedAgreement);
            setSelectedAgreement(selectedAgreement);
            forceUpdate(!forcedUpdateState);
        }
    }

    const submitRoleAssignment = (evt: any) => {
        // console.log('%c submitRoleAssignment selectedAgreement: %O', 'color: red;', selectedAgreement);
        let providedAgreements = (selectedAgreement.providedAgreements ? selectedAgreement.providedAgreements : [] );
        // console.log('%c submitRoleAssignment providedAgreements: %O', 'color: red;', providedAgreements);
        let acceptedAgreements = (selectedAgreement.acceptedAgreements ? selectedAgreement.acceptedAgreements : [] );
        // console.log('%c submitRoleAssignment acceptedAgreements: %O', 'color: red;', acceptedAgreements);
        let providedAgreement = (providedAgreements.length > 0 ? providedAgreements[0] : null );
        // console.log('%c submitRoleAssignment providedAgreement: %O', 'color: red;', providedAgreement);
        let acceptedAgreement = (acceptedAgreements.length > 0 ? acceptedAgreements[0] : null );
        // console.log('%c submitRoleAssignment acceptedAgreement: %O', 'color: red;', acceptedAgreement);
        if (!providedAgreement && !acceptedAgreement) return false;

        let padata = (providedAgreement && providedAgreement.data ? providedAgreement.data : null );
        let agreementRecordId: any = (providedAgreement && providedAgreement.recordId ? providedAgreement.recordId : 0);
        let assignUserId: any = (padata && padata.AcceptingProfileUserId ? padata.AcceptingProfileUserId : 0);
        let aadata = (acceptedAgreement && acceptedAgreement.data ? acceptedAgreement.data : null );
        let providerDisplayName: any = (aadata && aadata.ProviderDisplayName ? aadata.ProviderDisplayName : '');
        let acceptingDisplayName: any = (aadata && aadata.AcceptingDisplayName ? aadata.AcceptingDisplayName : '');

        if (!assignUserId) {
            // does not have a provider agreement, want to allow it but we still need the user id
            assignUserId = (aadata && aadata.ProviderProfileUserId ? aadata.ProviderProfileUserId : 0);
        }
        // can't assign a role if we don't know the user id
        if (!assignUserId) return false;

        let requestJson: AssignUserToRoleRequest = {
            UdicciCommand: 'Assign User To Profile Role',
            UdicciMediatorName: 'Mediation Agreements',
            SelectedUdicciProfileId: selectedProfileId,
            SocialSolutionId: udicci.socialSolutionUdicciIT,
            UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0),
            RelationshipId: agreementRecordId,
            AssignRoleId: selectedAgreement.newRoleId,
            AssignUserId: assignUserId,
            AgreementTitle: 'Agreement for ' + providerDisplayName + ' with ' + acceptingDisplayName
        };
        // console.log('%c submitRoleAssignment requestJson: %O', 'color: red;', requestJson);

        let okToContinue = true;
        if (okToContinue) {
            setAssigningRole(true);
            sendPreparedRequest(requestJson, {
                onSuccess: assignRoleRequestSuccess,
                onError: assignRoleRequestFailed
            });
        }
    };

    const assignRoleRequestSuccess = (result: any, request: any, response: any) => {
        // console.log('%c assignRoleRequestSuccess result: %O', 'color: blue;', result);
        // console.log('%c assignRoleRequestSuccess request: %O', 'color: blue;', request);
        // console.log('%c assignRoleRequestSuccess response: %O', 'color: blue;', response);
        setAssigningRole(false);
        setSelectedAgreement(null);
        if (props && props.onRefreshMediationAgreements) props.onRefreshMediationAgreements();
    };

    const assignRoleRequestFailed = (result: any) => {
        // console.log('%c assignRoleRequestFailed result: %O', 'color: red;', result);
        setAssigningRole(false);
    };

    const acceptAgreement = (agreement: any, evt: any) => {
        // console.log('%c acceptAgreement agreement: %O', 'color: red;', agreement);
        if (!agreement) return false;

        let recordId: any = (agreement.recordId ? agreement.recordId : 0);

        let requestJson: UpdateMediationAgreementRequest = {
            UdicciCommand: 'Update Mediation Agreement',
            UdicciMediatorName: 'Mediation Agreements',
            SelectedUdicciProfileId: selectedProfileId,
            SocialSolutionId: udicci.socialSolutionUdicciIT,
            UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0),
            MediationAgreementId: recordId,
            UpdateAction: 'Accept' // Agree === Accept
        };
        // console.log('%c acceptAgreement requestJson: %O', 'color: red;', requestJson);

        let okToContinue = true;
        if (okToContinue) {
            setAcceptingRole(true);
            sendPreparedRequest(requestJson, {
                onSuccess: agreementUpdateRequestSuccess,
                onError: agreementUpdateRequestFailed
            });
        }
    };

    // const endAgreement = (evt: any) => {
    //     // console.log('%c endAgreement selectedAgreement: %O', 'color: red;', selectedAgreement);
    //     let providedAgreements = (selectedAgreement.providedAgreements ? selectedAgreement.providedAgreements : [] );
    //     let providedAgreement = (providedAgreements.length > 0 ? providedAgreements[0] : null );
    //     let recordId: any = (providedAgreement && providedAgreement.recordId ? providedAgreement.recordId : 0);

    //     let requestJson: UpdateMediationAgreementRequest = {
    //         UdicciCommand: 'Update Mediation Agreement',
    //         UdicciMediatorName: 'Mediation Agreements',
    //         SelectedUdicciProfileId: selectedProfileId,
    //         SocialSolutionId: udicci.socialSolutionUdicciIT,
    //         UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0),
    //         MediationAgreementId: recordId,
    //         UpdateAction: 'End' // End === Remove
    //     };
    //     // console.log('%c endAgreement requestJson: %O', 'color: red;', requestJson);

    //     let okToContinue = true;
    //     if (okToContinue) {
    //         setAssigningRole(true);
    //         sendPreparedRequest(requestJson, {
    //             onSuccess: agreementUpdateRequestSuccess,
    //             onError: agreementUpdateRequestFailed
    //         });
    //     }
    // };

    const agreementUpdateRequestSuccess = (result: any, request: any, response: any) => {
        // console.log('%c agreementUpdateRequestSuccess result: %O', 'color: blue;', result);
        // console.log('%c agreementUpdateRequestSuccess request: %O', 'color: blue;', request);
        // console.log('%c agreementUpdateRequestSuccess response: %O', 'color: blue;', response);
        setAssigningRole(false);
        setAcceptingRole(false);
        setSelectedAgreement(null);
        if (props && props.onRefreshMediationAgreements) props.onRefreshMediationAgreements();
    };

    const agreementUpdateRequestFailed = (result: any) => {
        // console.log('%c agreementUpdateRequestFailed result: %O', 'color: red;', result);
        setAssigningRole(false);
        setAcceptingRole(false);
        setSelectedAgreement(null);
    };

    const onSearchForChanged = (evt: any) => {
        let trgt = evt.target;
        setSearchFor(trgt.value);
    }

    const assignProfileRole = () => {
        // console.log('%c assignProfileRoleRequest: %O', 'color: blue;', assignProfileRoleRequest);
        assignProfileRoleRequest.onSuccess = assignProfileRoleCompleted;
        assignProfileRoleRequest.onError = assignProfileRoleError;
        beginRelationship(assignProfileRoleRequest);
    }

    const assignProfileRoleCompleted = (result: any) => {
        // console.log('%c assignProfileRoleCompleted result: %O', 'color: blue;', result);
        // const { onAgreementCreated } = this.props;
        // if (onAgreementCreated) onAgreementCreated(result);
        setSearchFor('');
        setShowFindPortal(false);
        setAssignRole(false);
        setForProfileId(-1);
    }

    const assignProfileRoleError = (result: any) => {
        // console.log('%c assignProfileRoleError result: %O', 'color: blue;', result);
        // this.setState({ assignRole: false, forProfileId: -1, assignProfileRoleRequest: null });
        setAssignRole(false);
        setForProfileId(-1);
    }

    const getFindProfileForm = () => {
        let selectedProfileType = (selectedProfile && selectedProfile.data.ProfileType ? selectedProfile.data.ProfileType : null);
        // console.log('%c selectedProfileType: %O', 'color: blue;', selectedProfileType);
        let selectedProfileTypeName = (selectedProfileType.Name ? selectedProfileType.Name : '');
        // console.log('%c selectedProfileTypeName: %O', 'color: blue;', selectedProfileTypeName);

        // let portalContext = (this.context.portalContext ? this.context.portalContext : null);
        // let agreements = (portalContext && portalContext.agreements ? portalContext.agreements : null);
        // console.log('%c agreements: %O', 'color: blue;', agreements);

        let searchResultsElement = null;
        if (searchFor) {
            let agreements: any = null;
            if (profileData && profileData.length > 0) {
                // let xs = 12;
                // let sm = (profileData.length < 3 ? 12 : 4);
                // let md = (profileData.length < 4 ? 12 : 3);

                let searchWords: string[] = [];
                let searchForLower = searchFor.toLowerCase();
                if (searchForLower.indexOf(' ') > 0) {
                    searchWords = searchForLower.split(' ');
                } else {
                    searchWords.push(searchForLower);
                }
                // console.log('searchWords: %O', searchWords);

                let itemsPerPage = 7;
                let listCount = 0;
                let totalCount = 0;
                let matchingRecords = profileData.filter(
                    function(rec: any) {
                        let found = false;
                        // check title
                        if (searchWords.length > 0) {
                            if (searchWords.length > 1) {
                                Object.entries(searchWords).forEach(([idx, sw]) => {
                                    if (rec.title.toLowerCase().indexOf(sw) >= 0) found = true;
                                });
                            } else {
                                let sw = searchWords[0];
                                if (rec.title.toLowerCase().indexOf(sw) >= 0) found = true;
                            }
                        }
                        if (found) {
                            if (listCount <= itemsPerPage) listCount++;
                            totalCount++;
                        }
                        if (totalCount > itemsPerPage) found = false;
                        return found;
                    }
                );
                // console.log('matchingRecords: %O', matchingRecords);
                // console.log('listCount: %O', listCount);
                // console.log('totalCount: %O', totalCount);

                if (matchingRecords.length > 0) {
                    let agreementsWithMe = (agreements && agreements.withMe ? agreements.withMe : null);
                    // console.log('agreementsWithMe: %O', agreementsWithMe);
                    let agreementsWithOthers = (agreements && agreements.withOthers ? agreements.withOthers : null);
                    // console.log('agreementsWithOthers: %O', agreementsWithOthers);

                    let recordElements = matchingRecords.map((profile: any) => {
                        // console.log('%c profile: %O', 'color: purple;', profile);
                        let { recordId, title, data } = profile;
                        // if (!recordKeys) recordKeys = keys;
                        // console.log('recordId: %O', recordId);
                        // console.log('title: %O', title);
                        // console.log('data: %O', data);

                        let acceptingRole = '';
                        let providerDisplayName = '';
                        let hasAcceptingAgreement = false;
                        if (agreementsWithOthers && agreementsWithOthers.length > 0) {
                            let matchedAcceptingAgreements = agreementsWithOthers.filter(
                                function(rec: any) {
                                    let found = (rec.accepting_profile_id.toString() === recordId.toString());
                                    return found;
                                }
                            );                                
                            // console.log('matchedAcceptingAgreements: %O', matchedAcceptingAgreements);
                            if (matchedAcceptingAgreements.length > 0) {
                                hasAcceptingAgreement = true;
                                acceptingRole = matchedAcceptingAgreements[0].accepting_user_role_name;
                                providerDisplayName = matchedAcceptingAgreements[0].provider_display_name;
                            }
                        }
                        // console.log('hasAcceptingAgreement: %O', hasAcceptingAgreement);
                        // console.log('acceptingRole: %O', acceptingRole);

                        let acceptingDisplayName = '';
                        let providerAcceptingRole = '';
                        let hasProviderAgreement = false;
                        if (agreementsWithMe && agreementsWithMe.length > 0) {
                            let matchedProviderAgreements = agreementsWithMe.filter(
                                function(rec: any) {
                                    let chkId1 = recordId.toString();
                                    let chkId2 = rec.provider_profile_id.toString();
                                    let found = (chkId1 === chkId2 ? true : false);
                                    return found;
                                }
                            );                                
                            // console.log('matchedProviderAgreements: %O', matchedProviderAgreements);
                            hasProviderAgreement = (matchedProviderAgreements.length > 0 ? true : false);
                            if (matchedProviderAgreements.length > 0) {
                                hasProviderAgreement = true;
                                providerAcceptingRole = matchedProviderAgreements[0].accepting_user_role_name;
                                acceptingDisplayName = matchedProviderAgreements[0].accepting_display_name;
                            }
                        }
                        // console.log('hasProviderAgreement: %O', hasProviderAgreement);
                        // console.log('providerAcceptingRole: %O', providerAcceptingRole);

                        let assignedProfileRoleElement = null;
                        if (hasProviderAgreement && providerAcceptingRole) {
                            assignedProfileRoleElement = (
                                <Typography component="div" variant="body2">
                                    {acceptingDisplayName} Role is {providerAcceptingRole}
                                </Typography>
                            );
                        }

                        let assignedToProfileRoleElement = null;
                        if (hasAcceptingAgreement && acceptingRole) {
                            assignedToProfileRoleElement = (
                                <Typography component="div" variant="body2">
                                    {title} is assigned to the {acceptingRole} role on {providerDisplayName}.
                                </Typography>
                            );
                        }

                        let titleElement = null;
                        if (title) {
                            titleElement = (
                                <Typography component="span" variant="subtitle1" gutterBottom={true}>
                                    {title}
                                </Typography>
                            );
                        }

                        let headlineElement = null;
                        if (data && data.Headline) {
                            headlineElement = (
                                <Typography component="div" variant="caption" gutterBottom={true}>
                                    {data.Headline}
                                </Typography>
                            );
                        }

                        let profileSocialIconElement = null;
                        if (data && data.SocialIcon) {
                            profileSocialIconElement = (
                                <Avatar alt={title}
                                        src={data.SocialIcon}
                                        sx={udicciClasses.socialIcon}
                                        component='span'
                                />
                            );
                        } else {
                            profileSocialIconElement = (
                                <Avatar alt={title}
                                        src={DefaultSocialIcon}
                                        sx={udicciClasses.socialIcon}
                                        component='span'
                                />
                            );
                        }

                        let cardHeaderElement = (
                            <CardHeader title={titleElement} avatar={profileSocialIconElement} sx={udicciClasses.cardHeader} />
                        );
                        let cardContent = (
                            <CardContent sx={udicciClasses.cardContent}>
                                {assignedProfileRoleElement}
                                {assignedToProfileRoleElement}
                                {headlineElement}
                            </CardContent>
                        );

                        let assignRoleButton = null;
                        if (!hasAcceptingAgreement) {
                            assignRoleButton = (
                                <Button variant="text" color='primary' centerRipple onClick={(evt: any) => assignRoleForProfile(recordId)}>
                                    Assign Role on Profile
                                </Button>
                            );
                        }

                        let requestAgreementButton = null;
                        if (!hasProviderAgreement && selectedProfileTypeName === '.ME') {
                            // requestAgreementButton = (
                            //     <Button variant="text" color='primary' centerRipple onClick={this.requestAgreementWithProfile.bind(this, recordId)}>
                            //         Request Agreement with Profile
                            //     </Button>
                            // );
                        }

                        let cardActionsElement = (
                            <CardActions>
                                {assignRoleButton}
                                {requestAgreementButton}
                            </CardActions>
                        );

                        let profileCardProps = {
                            //sx: udicciClasses.relationshipCard,
                            raised: false,
                        };

                        let profileCard = ( <Card {...profileCardProps}>{cardHeaderElement} {cardContent} {cardActionsElement}</Card> );

                        return ( <Grid item xs={3} sm={3} md={12} key={recordId}>{profileCard}</Grid> );
                        // return ( <Grid item xs={xs} sm={sm} md={md} key={recordId}>{profileCard}</Grid> );
                    });
                    if (recordElements && recordElements.length > 0) {
                        searchResultsElement = (
                            <Box sx={udicciClasses.root}> {recordElements} </Box>
                        );
                    } else {
                        searchResultsElement = (
                            <Box sx={udicciClasses.root}>
                                <Typography variant="caption" component='div'>
                                    There were no matches found for your search.
                                </Typography>
                            </Box>
                        );
                    }
                } else {
                    searchResultsElement = (
                        <Box sx={udicciClasses.root}>
                            <Typography variant="caption" component='div'>
                                There were no matches found for your search.
                            </Typography>
                        </Box>
                    );
                }
            } else {
                searchResultsElement = (
                    <Box sx={udicciClasses.root}>
                        <Typography variant="caption" component='div'>
                            There are no profiles loaded to search ... please wait.
                        </Typography>
                    </Box>
                );
            }
        }

        let dialogTitle = (
            <Typography variant="h6" component="div">
                Search for other Portals
            </Typography>
        );

        let dialogContent = (
            <Box>
                <FormControl fullWidth>
                    <TextField value={searchFor}
                               onChange={onSearchForChanged}
                               margin="normal"
                               id='text-override'
                    />
                </FormControl>
                <Box sx={udicciClasses.generalContainer}> {searchResultsElement} </Box>
            </Box>
        );

        let dialogActions = (
            <Box>
                <Box sx={udicciClasses.grow} />
                <Button onClick={toggleFindPortal} color="primary">
                    Cancel
                </Button>
                <Button onClick={toggleFindPortal} color="primary" disabled={(searchFor === '' ? true : false)}>
                    Search
                </Button>
            </Box>
        );

        let assignRoleDialog = null;
        if (assignRole) assignRoleDialog = getAssignRoleDialog();

        return (
            <Box sx={udicciClasses.generalContainer}>
                {assignRoleDialog}
                {dialogTitle}
                {dialogContent}
                {dialogActions}
            </Box>
        );
    }

    const beginRelationship = (settings: any) => {
        // console.log('%c currentUser: %O', 'color: purple; font-weight: bold;', currentUser);
        // console.log('%c profile: %O', 'color: purple; font-weight: bold;', profile);
        // let userProfile = (udicciContext && udicciContext.user && udicciContext.user.profile ? udicciContext.user.profile : null);
        let assignRoleId = (settings && settings.assignRoleId ? settings.assignRoleId : 0);
        if (!assignRoleId) return false;
        if (savingAgreement) return false;
        // console.log('%c assignProfileRoleRequest: %O', 'color: blue;', assignProfileRoleRequest);

        let assignUserId = (settings && settings.assignUserId ? settings.assignUserId : 0);
        if (!assignUserId) return false;

        let agreementTitle = (settings && settings.agreementTitle ? settings.agreementTitle : '');

        let requestJson: any = {
            UdicciCommand: 'Assign User To Profile Role',
            UdicciMediatorName: 'Mediation Agreements',
            SelectedUdicciProfileId: selectedProfileId,
            UserId: currentUserId,
            AgreementTitle: agreementTitle,
            AssignRoleId: assignRoleId,
            AssignUserId: assignUserId,
            SocialSolutionId: udicci.socialSolutionUdicciIT,
        };
        // console.log('%c requestJson: %O', 'color: purple; font-weight: bold;', requestJson);

        let okToContinue = true;
        if (okToContinue) {
            sendPreparedRequest(requestJson, {
                onSuccess: beginRelationshipCompleted,
                onError: beginRelationshipError
            });
            setSavingAgreement(true);
        }
    };

    const beginRelationshipCompleted = (result: any) => {
        // console.log('%c beginRelationshipCompleted result: %O', 'color: blue;', result);
        setSavingAgreement(false);
        setAssignRole(false);
        setForProfileId(-1);
        setAssignProfileRoleRequest(null);
        reloadAgreements();
    }

    const beginRelationshipError = (result: any) => {
        // console.log('%c beginRelationshipError result: %O', 'color: blue;', result);
        setSavingAgreement(false);
    }

    const assignRoleForProfile = (udicciRecordId: number) => {
        // console.log('assignRoleForProfile udicciRecordId: %O', udicciRecordId);
        // this.setState({ assignRole: true, forProfileId: udicciRecordId });
        setForProfileId(udicciRecordId);
        setAssignRole(true);
    }

    const closeAssignRoleForProfile = () => {
        // console.log('closeAssignRoleForProfile');
        setAssignRole(false);
        setForProfileId(-1);
    }

    const onAgreementChanged = (fieldName: string, evt: any) => {
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c assignProfileRoleRequest: %O', 'color: blue;', assignProfileRoleRequest);
        // console.log('%c profileData: %O', 'color: blue;', profileData);
        // console.log('%c forProfileId: %O', 'color: blue;', forProfileId);

        // let displayName = (selectedProfile && selectedProfile.data.DisplayName ? selectedProfile.data.DisplayName : '');
        // console.log('%c displayName: %O', 'color: blue;', displayName);

        let assignRoleRequest: any = null;
        if (assignProfileRoleRequest) {
            assignRoleRequest = {};
            Object.assign(assignRoleRequest, assignProfileRoleRequest);
        }
        // console.log('%c assignRoleRequest: %O', 'color: blue;', assignRoleRequest);
        let matchedProfile = null;
        if (profileData && profileData.length > 0) {
            let matchedProfileList = profileData.filter(
                function(rec: any) {
                    let found = (rec.recordId.toString() === forProfileId.toString());
                    return found;
                }
            );                                
            // console.log('matchedProfileList: %O', matchedProfileList);
            if (matchedProfileList.length > 0) matchedProfile = matchedProfileList[0];
        }
        // console.log('matchedProfile: %O', matchedProfile);

        if (!assignRoleRequest) {
            // let profileId = (settings && settings.profileId ? settings.profileId : this.ulyssesDConstantineProfileId);
            // let assignRoleId = (settings && settings.assignRoleId ? settings.assignRoleId : 0);
            // let assignUserId = (settings && settings.assignUserId ? settings.assignUserId : 0);
            assignRoleRequest = {
                profileId: 0,
                assignRoleId: 0,
                assignUserId: 0,
                agreementTitle: ''
            };
        }

        if (!assignRoleRequest.profileId && matchedProfile) assignRoleRequest.profileId = matchedProfile.recordId;
        if (!assignRoleRequest.assignUserId && matchedProfile) assignRoleRequest.assignUserId = matchedProfile.data.CreatedByUserId;

        assignRoleRequest[fieldName] = newValue;

        // console.log('%c assignRoleRequest: %O', 'color: blue;', assignRoleRequest);
        setAssignProfileRoleRequest(assignRoleRequest);
    }

    const getAssignRoleDialog = () => {
        // console.log('%c profileData: %O', 'color: blue;', profileData);
        // console.log('%c forProfileId: %O', 'color: blue;', forProfileId);
        // console.log('%c assignProfileRoleRequest: %O', 'color: blue;', assignProfileRoleRequest);

        let selectedProfileRoles: any = roleData;
        // console.log('%c selectedProfileRoles: %O', 'color: blue;', selectedProfileRoles);
        let displayName = (selectedProfile && selectedProfile.data.DisplayName ? selectedProfile.data.DisplayName : '');
        // console.log('%c displayName: %O', 'color: blue;', displayName);

        let matchedProfile = null;
        if (profileData && profileData.length > 0) {
            let matchedProfileList = profileData.filter(
                function(rec: any) {
                    let profId: number = (forProfileId >= 0 ? forProfileId : 0);
                    let recId: number = (rec.recordId >= 0 ? rec.recordId : 0);
                    let found = (recId.toString() === profId.toString());
                    return found;
                }
            );                                
            // console.log('matchedProfileList: %O', matchedProfileList);
            if (matchedProfileList.length > 0) matchedProfile = matchedProfileList[0];
        }
        // console.log('matchedProfile: %O', matchedProfile);
        let matchedProfileDisplayName = (matchedProfile && matchedProfile.title ? matchedProfile.title : '<profile to assign role was not found>');
        // console.log('%c matchedProfileDisplayName: %O', 'color: blue;', matchedProfileDisplayName);

        let dialogTitle = (
            <Typography variant="h6" component="div">
                Assign Role for {matchedProfileDisplayName} on {displayName}
            </Typography>
        );

        let roleSelections = [];
        roleSelections.push(<MenuItem key={'role.not.set'} value={0}> select role </MenuItem>);
        if (selectedProfileRoles) {
            forEach(selectedProfileRoles, (role: any, idx: number) => {
                roleSelections.push(
                    <MenuItem key={role.recordId} value={role.recordId}> {role.title} </MenuItem>
                );
            });
        }

        let agreementRoleId = (assignProfileRoleRequest && assignProfileRoleRequest.assignRoleId ? assignProfileRoleRequest.assignRoleId : 0);
        let selectRoleElement = (
            <FormControl fullWidth>
                <Typography variant="caption" component="div">Choose Role</Typography>
                <Select value={agreementRoleId}
                        onChange={(evt: any) => onAgreementChanged('assignRoleId', evt)}
                        disabled={(savingAgreement ? true : false)}
                        inputProps={{ name: 'agreement-profile-role', id: 'agreement-profile-role', }}
                >
                    {roleSelections}
                </Select>
            </FormControl>
        );

        let agreementTitle = (assignProfileRoleRequest && assignProfileRoleRequest.agreementTitle ? assignProfileRoleRequest.agreementTitle : '');
        let dialogContent = (
            <Fragment>
                <FormControl fullWidth>
                    <Typography variant="caption" component="div">Title of Agreement</Typography>
                    <TextField value={agreementTitle}
                               disabled={(savingAgreement ? true : false)}
                               onChange={(evt: any) => onAgreementChanged('agreementTitle', evt)}
                               margin="normal"
                               id='text-override'
                    />
                </FormControl>
                {selectRoleElement}
            </Fragment>
        );

        let agreementProfileId = (assignProfileRoleRequest && assignProfileRoleRequest.profileId ? assignProfileRoleRequest.profileId : 0);
        let agreementAssignUserId = (assignProfileRoleRequest && assignProfileRoleRequest.assignUserId ? assignProfileRoleRequest.assignUserId : 0);

        let saveEnabled = false;
        if (agreementProfileId && agreementAssignUserId && agreementRoleId && agreementTitle.length > 3) saveEnabled = true;
        if (savingAgreement) saveEnabled = false;

        let dialogActions = (
            <Fragment>
                <Box sx={udicciClasses.grow} />
                <Button onClick={closeAssignRoleForProfile} color="primary">
                    Cancel
                </Button>
                <Button onClick={assignProfileRole} color="primary" disabled={!saveEnabled}>
                    Apply Changes
                </Button>
            </Fragment>
        );

        return (
            <Dialog open={true} fullWidth={true} maxWidth='md' onClose={closeAssignRoleForProfile} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent> {dialogContent} </DialogContent>
                <DialogActions> {dialogActions} </DialogActions>
            </Dialog>
        );
    }

    useEffect(() => {
        // console.log('%c Relationships profilesRequested: %O', 'color: blue;', profilesRequested);
        // console.log('%c Relationships profileData: %O', 'color: blue;', profileData);
        if (!profilesRequested && !profileData) getProfileListForSearch();
    }, []);
    // let userSocialIcon = (currentUser && currentUser.mySocialIcon ? currentUser.mySocialIcon : null);
    // console.log('%c Relationships userSocialIcon: %O', 'color: blue;', userSocialIcon);

    let relationshipsElement: any = null;
    if (profileData && profileData.length > 0) {
        let relationshipElements: any[] = [];
        if (!showFindPortal && defaultAgreements && defaultAgreements.length > 0) {
            let roleSelections: any[] = [];
            roleSelections.push(
                <MenuItem key={'role.not.selected'} value={0}>
                    Select Role
                </MenuItem>
            );
            if (roleData) {
                for (let [idx, role] of Object.entries<any>(roleData)) {
                    // console.log('%c role: %O', 'color: hotpink;', role);
                    if (!role.data.IsPublicRole) {
                        roleSelections.push(
                            <MenuItem key={'assign.role.' + role.recordId + '.' + idx.toString()} value={role.recordId}>
                                {role.data.RoleName}
                            </MenuItem>
                        );
                    }
                }
            }

            const AgreementType = {
                Self: 'SELF',
                Provided: 'PROVIDED',
                Accepted: 'ACCEPTED',
            }

            let allRelationships: any = {};
            forEach(defaultAgreements, function(agrmnt: any) {
                // console.log('%c agrmnt: %O', 'color: red;', agrmnt);
                let dad = (agrmnt.data ? agrmnt.data : {} );

                // let aid = (dad.UdicciRecordId ? dad.UdicciRecordId : 0 );
                let apid = (dad.AcceptingProfileId ? dad.AcceptingProfileId : 0 );
                let ppid = (dad.ProviderProfileId ? dad.ProviderProfileId : 0 );
                if (apid !== selectedProfileId && ppid !== selectedProfileId) {
                    // agreement is not related to the current portal, don't show it.
                    return true;
                }

                let agreementType: string = '';
                if (ppid === selectedProfileId) {
                    if (apid === selectedProfileId) {
                        agreementType = AgreementType.Self;
                    } else {
                        agreementType = AgreementType.Provided;
                    }
                } else {
                    agreementType = AgreementType.Accepted;
                }
                // console.log('%c agreementType: %O', 'color: blue;', agreementType);

                let keyProfileId: number = 0;
                let keyDisplayName: string = '';
                let keySocialIcon: string = '';
                let keyHeadline: string = '';
                let keyProfileUrl: string = '';
                let keyProfileType: string = '';

                let keyId: number = 0;
                if (agreementType === AgreementType.Provided) {
                    keyId = apid;
                    keyProfileId = (dad.AcceptingProfileId ? dad.AcceptingProfileId : 0 );
                    keyDisplayName = (dad.AcceptingDisplayName ? dad.AcceptingDisplayName : '' );
                    keySocialIcon = (dad.AcceptingSocialIcon ? dad.AcceptingSocialIcon : '' );
                    keyHeadline = (dad.AcceptingHeadline ? dad.AcceptingHeadline : '' );
                    keyProfileUrl = (dad.AcceptingProfileUrl ? dad.AcceptingProfileUrl : '' );
                    keyProfileType = (dad.AcceptingProfileType ? dad.AcceptingProfileType : '' );
                    if (!keyDisplayName) keyDisplayName = dad.Title;
                } else {
                    // agreementType === AgreementType.Self || agreementType === AgreementType.Accepted
                    keyId = ppid;
                    keyProfileId = (dad.ProviderProfileId ? dad.ProviderProfileId : 0 );
                    keyDisplayName = (dad.ProviderDisplayName ? dad.ProviderDisplayName : '' );
                    keySocialIcon = (dad.ProviderSocialIcon ? dad.ProviderSocialIcon : '' );
                    keyHeadline = (dad.ProviderHeadline ? dad.ProviderHeadline : '' );
                    keyProfileUrl = (dad.ProviderProfileUrl ? dad.ProviderProfileUrl : '' );
                    keyProfileType = (dad.ProviderProfileType ? dad.ProviderProfileType : '' );
                    if (!keyDisplayName) keyDisplayName = dad.Title;
                }
                // console.log('%c keyId: %O', 'color: blue;', keyId);

                if (keyId) {
                    let relationship: any = (allRelationships[keyId] ? allRelationships[keyId] : {});

                    relationship.agreementWithSelf = (agreementType === AgreementType.Self ? true : false);
                    // console.log('%c relationship: %O', 'color: blue;', relationship);

                    if (!relationship.profileId && keyProfileId) relationship.profileId = keyProfileId;
                    if (!relationship.displayName && keyDisplayName) relationship.displayName = keyDisplayName;
                    if (!relationship.socialIcon && keySocialIcon) relationship.socialIcon = keySocialIcon;
                    if (!relationship.headline && keyHeadline) relationship.headline = keyHeadline;
                    if (!relationship.url && keyProfileUrl) relationship.url = keyProfileUrl;
                    if (!relationship.profileType && keyProfileType) relationship.profileType = keyProfileType;
                    if (relationship.active === undefined) relationship.active = (dad.Active ? true : false);

                    // selected profile is the provider profile id
                    if (agreementType === AgreementType.Provided) {
                        if (!relationship.providedAgreements) relationship.providedAgreements = [];
                        relationship.providedAgreements.push(agrmnt);
                    }

                    // selected profile is the accepting profile id
                    if (agreementType === AgreementType.Accepted || agreementType === AgreementType.Self) {
                        if (!relationship.acceptedAgreements) relationship.acceptedAgreements = [];
                        relationship.acceptedAgreements.push(agrmnt);
                    }

                    // console.log('%c relationship: %O', 'color: green;', relationship);
                    allRelationships[keyId] = relationship;
                }
            });
            // agreements = sortBy(agreements, function(a) { return a.profileId; })
            // console.log('%c allRelationships: %O', 'color: blue;', allRelationships);

            let allSortedRelationships = sortBy(values(allRelationships), function(a) { return a.displayName; })
            // console.log('%c Relationships allSortedRelationships: %O', 'color: blue;', allSortedRelationships);

            let filterOptions: string[] = [];
            filterOptions.push('all');
            forEach(allSortedRelationships, function(relationship: any, i: number) {
                // console.log('%c Relationships relationship: %O', 'color: blue;', relationship);
                let profileId = (relationship.profileId ? relationship.profileId : 0 );
                let socialIcon = (relationship.socialIcon ? relationship.socialIcon : '' );
                let displayName = (relationship.displayName ? relationship.displayName : '' );
                let profileType = (relationship.profileType ? relationship.profileType : '' );

                if (profileType && filterOptions.indexOf(profileType) < 0) {
                    filterOptions.push(profileType);
                }
                if (relationshipFilter !== 'all' && profileType !== relationshipFilter) return true;

                let dateOwnerAssumedControl: any = null;
                if (profileData && profileData.length > 0) {
                    // console.log('%c relationship profileData: %O', 'color: green;', profileData);
                    // let profileDataList: any[] = profileData;
                    let relationshipProfile: any = find(profileData, function(p: any) {
                        return p.recordId === profileId;
                    });
                    // let relationshipProfile: any = find(profileDataList, (p: any) => { p.recordId === profileId });
                    // console.log('%c Relationships relationshipProfile: %O', 'color: blue;', relationshipProfile);
                    if (relationshipProfile && relationshipProfile.data && relationshipProfile.data.DateOwnerAssumedControl) {
                        dateOwnerAssumedControl = relationshipProfile.data.DateOwnerAssumedControl;
                    }
                }
                // console.log('%c Relationships dateOwnerAssumedControl: %O', 'color: blue;', dateOwnerAssumedControl);

                let url = (relationship.url ? relationship.url : '' );
                let active = (relationship.active ? true : false );
                // console.log('%c Relationships %s relationship: %O', 'color: blue;', displayName, relationship);

                let acceptedAgreements = (relationship.acceptedAgreements ? relationship.acceptedAgreements : [] );
                // console.log('%c Relationships acceptedAgreements: %O', 'color: blue;', acceptedAgreements);
                let providedAgreements = (relationship.providedAgreements ? relationship.providedAgreements : [] );
                // console.log('%c Relationships providedAgreements: %O', 'color: blue;', providedAgreements);

                let acceptedAgreement = (acceptedAgreements.length > 0 ? acceptedAgreements[0] : null );
                // console.log('%c Relationships acceptedAgreement: %O', 'color: red;', acceptedAgreement);

                let aaData = (acceptedAgreement && acceptedAgreement.data ? acceptedAgreement.data : null );
                // console.log('%c Relationships aaData: %O', 'color: blue;', aaData);

                let providedAgreement = (providedAgreements.length > 0 ? providedAgreements[0] : null );
                // console.log('%c Relationships providedAgreement: %O', 'color: red;', providedAgreement);

                let paData = (providedAgreement && providedAgreement.data ? providedAgreement.data : null );
                // console.log('%c Relationships paData: %O', 'color: blue;', paData);

                let roleName = (paData && paData.AcceptingUserRoleName ? paData.AcceptingUserRoleName : '' );
                let myAssignedRoleName = (aaData && aaData.AcceptingUserRoleName ? aaData.AcceptingUserRoleName : '' );

                let providedAgreementRequiresAccept: boolean = false;
                let acceptingAgreementRequiresAgree: boolean = false;

                if (paData && paData.dtDateAccepted) {
                    if (paData.dtDateApproved && paData.dtDateApproved > paData.dtDateAccepted) {
                        providedAgreementRequiresAccept = true;
                    }
                } else {
                    providedAgreementRequiresAccept = true;
                }
                // console.log('%c Relationships providedAgreementRequiresAccept: %O', 'color: blue;', providedAgreementRequiresAccept);

                if (aaData && aaData.dtDateAccepted) {
                    if (aaData.dtDateApproved && aaData.dtDateApproved > aaData.dtDateAccepted) {
                        acceptingAgreementRequiresAgree = true;
                    }
                } else {
                    acceptingAgreementRequiresAgree = true;
                }
                // console.log('%c Relationships acceptingAgreementRequiresAgree: %O', 'color: blue;', acceptingAgreementRequiresAgree);

                let userRoleId: any = (paData && paData.AcceptingUserRoleId ? paData.AcceptingUserRoleId : '0');
                if (relationship.newRoleId !== userRoleId) userRoleId = relationship.newRoleId;
                // console.log('%c userRoleId: %O', 'color: orange;', userRoleId);
                // let acceptingUserRoleId: any = (relationship.myAssignedRoleId ? relationship.myAssignedRoleId : 0);
                // console.log('%c acceptingUserRoleId: %O', 'color: orange;', acceptingUserRoleId);

                let socialIconAvatar: any = null;
                if (socialIcon) {
                    socialIconAvatar = (
                        <Avatar src={socialIcon}
                                component="span"
                                variant='rounded'
                                classes={{ img: 'profileSocialIcon' }}
                                sx={udicciClasses.acceptingSocialIcon}
                        />
                    );
                }

                let displayNameElement: any = null;
                if (displayName) {
                    displayNameElement = (
                        <Typography variant="subtitle1" component="span" align="left">
                            {displayName}
                        </Typography>
                    );
                }

                let profileTypeElement: any = null;
                if (profileType) {
                    let profileTypeText: string = profileType;
                    if (profileTypeText.startsWith('.') === false) profileTypeText = '.' + profileTypeText;
                    profileTypeElement = (
                        <Typography variant="caption" component="div">
                            {profileTypeText}
                        </Typography>
                    );
                }

                let headlineElement: any = null;
                if (relationship.headline && relationship.headline.trim().toLowerCase() !== relationship.displayName.trim().toLowerCase()) {
                    headlineElement = (
                        <Typography variant="caption" component="span">
                            {relationship.headline}
                        </Typography>
                    );
                }

                let myAssignedRoleNameElement: any = null;
                if (myAssignedRoleName) {
                    // console.log('%c Relationships acceptingAgreementRequiresAgree: %O', 'color: blue;', acceptingAgreementRequiresAgree);
                    let acceptAgreementElement: any = null;
                    if (acceptingAgreementRequiresAgree) {
                        let acceptingAgreementButtonElement: any = null;
                        let acceptingAgreementWaitElement: any = null;
                        if (acceptingRole) {
                            acceptingAgreementWaitElement = (<CircularProgress size={10} />);
                            acceptingAgreementButtonElement = (
                                <Typography variant="caption" component="span">
                                    accepting...
                                </Typography>
                            );
                        } else {
                            acceptingAgreementButtonElement = (
                                <Typography variant="caption" component="span" onClick={(evt: any) => acceptAgreement(acceptedAgreement, evt)}>
                                    accept
                                </Typography>
                            );
                        }
                        acceptAgreementElement = (
                            <Fragment>
                                &nbsp;
                                {acceptingAgreementButtonElement}
                                {acceptingAgreementWaitElement}
                            </Fragment>
                        );
                    }
                    myAssignedRoleNameElement = (
                        <Box>
                            <Typography variant="caption" component="span">
                                Your Role:
                            </Typography>
                            &nbsp;
                            <Typography variant="body2" component="span">
                                {myAssignedRoleName}
                            </Typography>
                            {acceptAgreementElement}
                        </Box>
                    );
                } else {
                    myAssignedRoleNameElement = (
                        <Box>
                            <Typography variant="caption" component="span">
                                Your role not assigned
                            </Typography>
                        </Box>
                    );
                }

                let assignRoleButtonElement: any = null;
                if (!selectedAgreement || (selectedAgreement && selectedAgreement.profileId !== relationship.profileId)) {
                    assignRoleButtonElement = (
                        <Fragment>
                            &nbsp;
                            <Typography variant="caption" component="span" onClick={(evt: any) => assignRoleFocus(relationship, evt)}>
                                {(roleName ? 'change' : 'assign')}
                            </Typography>
                        </Fragment>
                    );
                }

                let roleNameElement: any = null;
                if (roleName) {
                    let acceptAgreementElement: any = null;
                    if (providedAgreementRequiresAccept && paData.AcceptingProfileUserId === currentUser?.UdicciUserId) {
                        let acceptingAgreementButtonElement: any = null;
                        let acceptingAgreementWaitElement: any = null;
                        if (acceptingRole) {
                            acceptingAgreementWaitElement = (<CircularProgress size={10} />);
                            acceptingAgreementButtonElement = (
                                <Typography variant="caption" component="span">
                                    accepting...
                                </Typography>
                            );
                        } else {
                            acceptingAgreementButtonElement = (
                                <Typography variant="caption" component="span" onClick={(evt: any) => acceptAgreement(providedAgreement, evt)}>
                                    accept
                                </Typography>
                            );
                        }
                        acceptAgreementElement = (
                            <Fragment>
                                &nbsp;
                                {acceptingAgreementButtonElement}
                                {acceptingAgreementWaitElement}
                            </Fragment>
                        );
                    }

                    let roleNameProps: any = {
                        variant: 'body2',
                        component: 'span',
                        color: 'primary',
                    };
                    if (providedAgreementRequiresAccept) {
                        roleNameProps.color = 'textSecondary'   
                    }

                    roleNameElement = (
                        <Box>
                            <Typography variant="caption" component="span">
                                {((profileId === selectedProfileId) ? 'Your Role: ' : 'Their Role: ')}
                            </Typography>
                            &nbsp;
                            <Typography {...roleNameProps}>{roleName}</Typography>
                            {assignRoleButtonElement}
                            {acceptAgreementElement}
                        </Box>
                    );
                } else if (dateOwnerAssumedControl && profileType.toLowerCase() === '.me') {
                    let roleMessage: string = '';
                    if (providedAgreement) {
                        roleMessage = 'Their role not assigned';
                    } else {
                        roleMessage = 'Their role not assigned (no agreement)';
                    }
                    roleNameElement = (
                        <Box>
                            <Typography variant="caption" component="span">
                                {roleMessage}
                            </Typography>
                            {assignRoleButtonElement}
                        </Box>
                    );
                } else if (!dateOwnerAssumedControl) {
                    roleNameElement = (
                        <Box>
                            <Typography variant="caption" component="span">
                                This Portal is currently unable to engage with you.  (no assigned user)
                            </Typography>
                        </Box>
                    );
                }

                if (profileId === selectedProfileId) {
                    if (myAssignedRoleName) {
                        roleNameElement = null;
                    } else if (roleName) {
                        myAssignedRoleNameElement = null;
                    }
                }

                let relationshipGoToProfileElement: any = (
                    <IconButton size="small" aria-label="Go To Portal" onClick={(evt: any) => openUrl(url)} sx={udicciClasses.navigateButton}>
                        <LaunchIcon classes={{ root: 'navigateButtonIcon' }} />
                    </IconButton>
                );

                let assignRoleElement: any = null;
                if (selectedAgreement && selectedAgreement.profileId === relationship.profileId) {
                    // console.log('%c selectedAgreement: %O', 'color: orange;', selectedAgreement);
                    // console.log('%c userRoleId: %O', 'color: orange;', userRoleId);
                    if (selectedAgreement.newRoleId && selectedAgreement.newRoleId !== userRoleId) {
                        userRoleId = selectedAgreement.newRoleId;
                    }
                    let assignRoleCancelButton: any = (
                        <Button disabled={(assigningRole ? true : false)} size="small" variant="contained" color="info" onClick={closeAssignRole}>
                            Cancel
                        </Button>
                    );

                    let assignRoleButtonText: string = '';
                    if (providedAgreement) {
                        assignRoleButtonText = 'Assign Role';
                    } else {
                        assignRoleButtonText = 'Assign Role with new Agreement';
                    }
                    let assignRoleSubmitButton: any = (
                        <Button disabled={(assigningRole ? true : false)} size="small" variant="contained" color="primary" onClick={submitRoleAssignment}>
                            {assignRoleButtonText}
                        </Button>
                    );

                    let needsAcceptElement: any = null;
                    if (providedAgreementRequiresAccept) {
                        needsAcceptElement = (
                            <Typography variant="caption" component="div" style={{ fontSize: '0.8em' }}>
                                (agreement has not been accepted by {displayName})
                            </Typography>
                        );
                    }

                    assignRoleElement = (
                        <Box sx={udicciClasses.assignRoleContainer}>
                            <FormControl fullWidth disabled={(assigningRole ? true : false)}>
                                <Box sx={{ fontSize: '0.8em' }}>
                                    <Typography style={{ fontSize: '0.9em' }} variant="body1" component="span">
                                        Select {((profileId === selectedProfileId) ? 'Your Role: ' : 'Their Role: ')}
                                    </Typography>
                                    {needsAcceptElement}
                                </Box>
                                <Select value={userRoleId} onChange={(evt: any) => onChangeRole(evt)}>
                                    {roleSelections}
                                </Select>
                            </FormControl>
                            <Box sx={udicciClasses.assignRoleButtonContainer}>
                                {assignRoleCancelButton}
                                {assignRoleSubmitButton}
                            </Box>
                        </Box>
                    );
                }

                let reKey = 'relationship.' + relationship.profileId;
                relationshipElements.push(
                    <Paper key={reKey} elevation={1} sx={{backgroundColor: 'rgba(255, 255, 255, 0.1)', margin: '8px', padding: '8px'}}>
                        {relationshipGoToProfileElement}
                        {socialIconAvatar}
                        {displayNameElement}
                        {profileTypeElement}
                        <Box sx={udicciClasses.subContentArea}>
                            {headlineElement}
                            {myAssignedRoleNameElement}
                            {roleNameElement}
                            {assignRoleElement}
                        </Box>
                    </Paper>
                );
            });

            if (filterOptions.length > 1) {
                // filterOptions
                let filterButtons: any[] = [];

                let reKey = 'relationship.list.filters';
                filterOptions.forEach((filter: string) => {
                    if (!filter) return true;
                    let reButtonKey = reKey + '.button.' + filter.replace(' ', '');
                    let filterButton = (
                        <Button size="small"
                                onClick={(evt: any) => setRelationshipFilter(filter)}
                                variant="text"
                                key={reButtonKey}
                        >
                            <Typography variant="body2" component="span">
                                {filter}
                            </Typography>
                            <Typography variant="body2" component="span">
                                .
                            </Typography>
                        </Button>
                    );
                    filterButtons.push(filterButton);
                })
                let filterListElement: any = (
                    <Box key={reKey} sx={udicciClasses.subContentArea}>
                        {filterButtons}
                    </Box>
                );
                relationshipElements.unshift(filterListElement);
            }
        }

        let switchAccountsElement: any = null;
        if (!selectedProfileIsUserProfile) {
            switchAccountsElement = (
                <IconButton color="info" size="small" onClick={(evt: any) => reloadAgreements()} classes={{ root: 'switchAccountButton' }} sx={udicciClasses.formHeaderButtonRight}>
                    <SwitchAccountIcon classes={{ root: 'switchAccountButtonIcon' }} />
                </IconButton>
            );
        }

        let FindPortalElement: any = (
            <IconButton color="info" size="small" onClick={toggleFindPortal}>
                <FindPortalIcon />
            </IconButton>
        );
        let findProfileFormElement: any = null;
        if (showFindPortal) {
            findProfileFormElement = getFindProfileForm();
        }

        relationshipsElement = (
            <Paper elevation={0} sx={{ backgroundColor: 'unset' }}>
                <Typography component="span" variant="subtitle1" noWrap style={{ marginLeft: '4px' }} sx={(agreementsState ? udicciClasses.userRelationshipsName : udicciClasses.relationshipsName)}>
                    {profileDisplayName}
                </Typography>
                <Typography component="span" variant="subtitle2" noWrap style={{ marginLeft: '4px' }} sx={udicciClasses.relationshipsTitle}>
                    Relationship Agreements
                </Typography>
                {switchAccountsElement}
                {FindPortalElement}
                <Box>
                    {findProfileFormElement}
                    {relationshipElements}
                </Box>
            </Paper>
        );
    } else {
        relationshipsElement = (
            <Paper elevation={0} sx={{ backgroundColor: 'unset' }}>
                <Typography component="span" variant="subtitle1" noWrap style={{ marginLeft: '4px' }} sx={(agreementsState ? udicciClasses.userRelationshipsName : udicciClasses.relationshipsName)}>
                    {profileDisplayName}
                </Typography>
                <Typography component="span" variant="subtitle2" noWrap style={{ marginLeft: '4px' }} sx={udicciClasses.relationshipsTitle}>
                    Relationship Agreements
                </Typography>

                <Box>
                    <Typography component="div" variant="caption" noWrap style={{ marginLeft: '4px' }} sx={udicciClasses.relationshipsTitle}>
                        Waiting for Profiles to load ... please wait.
                    </Typography>
                </Box>
            </Paper>
        );
    }

    return relationshipsElement;
}
