
import React, { useState } from 'react';

import {
    Box, Typography, Button, Icon, Chip, Avatar, IconButton, Switch, TextField, Checkbox, Badge,
    Table, TableHead, TableBody, TableRow, TableCell, Tooltip, FormControlLabel, Autocomplete,
    // Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, FormControl, Select
} from '@mui/material';

import { keys, forEach, intersection } from 'underscore';

import useUdicciHelpers, { UdicciPermissionsInitialized } from 'src/hooks/useUdicciHelpers';
import { useUdicciContext } from 'src/context/udicci-context'
// import { UdicciPermissions } from 'src/classes/udicci-record';

const AssignRoleAccess: React.FC<any> = (props) => {
    // console.log('%c AssignRoleAccess props: %O', 'color: maroon;', props);

    const [assignRoles, setAssignRoles] = useState<boolean>(false);
    const [removeRoleId, setRemoveRoleId] = useState<number>(0);
    const [viewOverrides, setViewOverrideOverrides] = useState<number>(0);
    const [assignOverride, setAssignOverride] = useState<number>(0);
    const [assignOverrideMediator, setAssignOverrideMediator] = useState<string>('');
    const [assignFields, setAssignFields] = useState<string>('');
    const [restrictedFields, setRestrictedFields] = useState<any[] | null>(null);

    let { roleOverrides, assignableMediators, onApplyChanges, onAddRole, onRemoveRole, onAddOverride, onRemoveOverride } = props;
    // console.log('%c AssignRoleAccess roleOverrides: %O', 'color: maroon;', roleOverrides);
    // console.log('%c AssignRoleAccess assignableMediators: %O', 'color: maroon;', assignableMediators);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();

    let { udicci } = udicciContext.state;
    // console.log('%c AssignRoleAccess udicci: %O', 'color: maroon;', udicci);

    var profileRoles = udicciHelpers.preloadMediatorData('Roles', udicci.socialSolutionUdicciIT);
    // console.log('%c AssignRoleAccess profileRoles: %O', 'color: maroon;', profileRoles);

    const addRole = (roleToAdd: any) => {
        // console.log('%c addRole roleToAdd: %O', 'color: blue;', roleToAdd);
        let roleId: number = (roleToAdd && roleToAdd.roleId ? roleToAdd.roleId : 0);
        if (roleId && onAddRole) onAddRole(roleId);
    };

    const removeRole = () => {
        // console.log('%c removeRole removeRoleId: %O', 'color: red;', removeRoleId);
        if (!removeRoleId) return false;
        if (onRemoveRole) onRemoveRole(removeRoleId);
        setRemoveRoleId(0);
    };

    const updatePermissions = (permissionName: string, roleToUpdate: any, mediatorName: string, event: any) => {
        // console.log('%c updatePermissions permissionName: %O', 'color: purple;', permissionName);
        // console.log('%c updatePermissions roleToUpdate: %O', 'color: purple;', roleToUpdate);
        // console.log('%c updatePermissions mediatorName: %O', 'color: purple;', mediatorName);

        let newValue: boolean = (event.target.checked ? true : false);
        // console.log('%c updatePermissions newValue: %O', 'color: purple;', newValue);

        let roleId: number = (roleToUpdate && roleToUpdate.roleId ? roleToUpdate.roleId : 0);
        let roleOverride: any = (roleOverrides[roleId] ? roleOverrides[roleId] : null);
        if (!roleOverride) {
            roleOverride = {};
            Object.assign(roleOverride, UdicciPermissionsInitialized);
        }
        // console.log('roleOverride: %O', roleOverride);

        if (mediatorName) {
            if (!roleOverride.overrides) roleOverride.overrides = {};
            if (!roleOverride.overrides[mediatorName]) {
                roleOverride.overrides[mediatorName] = {};
                Object.assign(roleOverride.overrides[mediatorName], UdicciPermissionsInitialized);
            }
            roleOverride.overrides[mediatorName][permissionName] = newValue;
        } else {
            roleOverride[permissionName] = newValue;
        }

        // updateRoleOverrides[roleId] = roleOverride;
        // console.log('roleOverride: %O', roleOverride);
        if (onApplyChanges) onApplyChanges(roleId, roleOverride);
    }

    const removeOverride = (role: any, mediatorName: string) => {
        // console.log('%c role: %O', 'color: red;', role);
        // console.log('%c mediatorName: %O', 'color: red;', mediatorName);
        let roleId: number = (role && role.roleId ? role.roleId : 0);
        if (roleId && onRemoveOverride) onRemoveOverride(roleId, mediatorName);
    };

    const viewOverridesForRole = (roleId: number) => {
        // console.log('%c viewOverridesForRole roleId: %O', 'color: red;', roleId);
        setViewOverrideOverrides((viewOverrides === roleId ? 0 : roleId));
    };

    const assignRoleOverride = (roleId: number) => {
        // console.log('%c roleId: %O', 'color: red;', roleId);
        let newVal: number = (assignOverride === roleId ? 0 : roleId);
        setAssignOverride(newVal);
        if (newVal) setViewOverrideOverrides(newVal);
    };

    const addOverride = () => {
        // console.log('%c addOverride assignOverride: %O', 'color: red;', assignOverride);
        // console.log('%c addOverride assignOverrideMediator: %O', 'color: red;', assignOverrideMediator);
        if (!assignOverride) return false;
        if (!assignOverrideMediator) return false;

        if (onAddOverride) onAddOverride(assignOverride, assignOverrideMediator);
        setAssignOverrideMediator('');
    };

    const selectMediatorToOverride = (evt: any, newValue: any) => {
        // console.log('%c selectMediatorToOverride newValue: %O', 'color: maroon;', newValue);
        setAssignOverrideMediator((newValue && newValue.label ? newValue.label : ''));
    }

    const overrideFieldsForMediator = (mediatorName: string, viewFields: any[] | null) => {
        // console.log('%c overrideFieldsForMediator mediatorName: %O', 'color: red;', mediatorName);
        // console.log('%c overrideFieldsForMediator viewFields: %O', 'color: red;', viewFields);
        setAssignFields((assignFields === mediatorName ? '' : mediatorName));
        setRestrictedFields(viewFields);
        if (mediatorName) {
            let mediatorNames: string [] = [];
            mediatorNames.push(mediatorName);
            udicci.getMediatorStructures(mediatorNames);
        }
    };

    const onFieldSelectionChanged = (jsonFieldName: string, evt: any) => {
        // console.log('%c onFieldSelectionChanged jsonFieldName: %O', 'color: purple;', jsonFieldName);
        // console.log('%c onFieldSelectionChanged viewOverrides: %O', 'color: purple;', viewOverrides);
        // console.log('%c onFieldSelectionChanged assignFields: %O', 'color: purple;', assignFields);
        let trgt = evt.target;

        let newRestrictedFields: any[] = [];
        // console.log('%c onFieldSelectionChanged restrictedFields: %O', 'color: purple;', restrictedFields);
        if (restrictedFields && restrictedFields.length > 0) newRestrictedFields.push.apply(newRestrictedFields, restrictedFields);
        if (trgt.checked) {
            if (newRestrictedFields.indexOf(jsonFieldName) < 0) newRestrictedFields.push(jsonFieldName);
        } else {
            let newViewFields: any[] = [];
            Object.entries(newRestrictedFields).forEach(([idxField, field]) => {
                if (field !== jsonFieldName) newViewFields.push(field);
            });
            newRestrictedFields = newViewFields;
        }
        // console.log('%c newRestrictedFields: %O', 'color: purple;', newRestrictedFields);
        setRestrictedFields(newRestrictedFields);
    }

    const applyFieldOverrides = () => {
        // console.log('%c applyFieldOverrides viewOverrides: %O', 'color: purple;', viewOverrides);
        // console.log('%c applyFieldOverrides assignFields: %O', 'color: purple;', assignFields);

        let roleOverride: any = (roleOverrides[viewOverrides] ? roleOverrides[viewOverrides] : null);
        if (!roleOverride) {
            roleOverride = {};
            Object.assign(roleOverride, UdicciPermissionsInitialized);
        }
        // console.log('roleOverride: %O', roleOverride);

        if (assignFields) {
            if (!roleOverride.overrides) roleOverride.overrides = {};
            if (!roleOverride.overrides[assignFields]) {
                roleOverride.overrides[assignFields] = {};
                Object.assign(roleOverride.overrides[assignFields], UdicciPermissionsInitialized);
            }
        }

        roleOverride.overrides[assignFields].ViewFields = restrictedFields;
        // console.log('%c roleOverride: %O', 'color: purple;', roleOverride);
        if (onApplyChanges) onApplyChanges(viewOverrides, roleOverride);

        setAssignFields('');
        setRestrictedFields(null);
    }

    const getMediatorOptions = (overrides: any) => {
        let solutionMediators: any[] = [];
        if (assignableMediators) {
            forEach(assignableMediators, (medSettings: any, medName: string) => {
                if (!medName) return true;
                if (overrides && overrides[medName]) return true;
                solutionMediators.push({ id: medSettings.id, label: medName });
            });
        }
        return solutionMediators;
    }

    let roleOverridesList: any[] = [];
    let otherRolesList: any[] = [];
    if (profileRoles && profileRoles.length > 0) {
        forEach(profileRoles, (role: any, roleIndex: number) => {
            // console.log('%c role: %O', 'color: maroon;', role);

            let roleId: number = 0;
            let roleName: string = '';
            let permissions: any = null;
            let assignedRole: any = (roleOverrides && roleOverrides[role.recordId] ? roleOverrides[role.recordId] : null);
            // console.log('assignedRole: %O', assignedRole);

            roleId = role.recordId;
            roleName = role.data.RoleName;
            // console.log('%c roleId: %O', 'color: maroon;', roleId);
            // console.log('%c roleName: %O', 'color: maroon;', roleName);

            if (assignedRole) {
                permissions = assignedRole; // assignedRole value actually contains the permissions for the role along with overrides
                roleOverridesList.push({
                    roleId: roleId,
                    roleName: roleName,
                    permissions: permissions
                });
            } else {
                otherRolesList.push({
                    roleId: roleId,
                    roleName: roleName,
                    permissions: permissions
                });
            }
        });
    }

    let assignRolesElement: any = null;
    if (assignRoles) {
        if (otherRolesList.length > 0) {
            let sortedUnassignedRoles: any[] = otherRolesList.sort((a: any,b: any) => {
                if (a.roleName.toLowerCase() < b.roleName.toLowerCase()) return -1;  // ascending
                if (a.roleName.toLowerCase() > b.roleName.toLowerCase()) return 1;  // descending
                return 0 //default return value (no sorting)
            });
            // console.log('%c sortedUnassignedRoles: %O', 'color: maroon;', sortedUnassignedRoles);

            assignRolesElement = (
                <Box sx={{ margin: '16px' }}>
                    <IconButton size="small" onClick={(evt: any) => setAssignRoles(false)}><Icon>close</Icon></IconButton>
                    {sortedUnassignedRoles.map(role => {
                        return (
                            <Chip key={role.roleId}
                                color="secondary"
                                sx={{ margin: '4px' }}
                                onDelete={(evt: any) => addRole(role)}
                                avatar={<Avatar>{role.roleName.toString().substring(0, 1)}</Avatar>}
                                variant="outlined"
                                label={(<Typography variant="caption" sx={{ marginLeft: '8px', marginRight: '8px' }}>{role.roleName}</Typography>)}
                                deleteIcon={<Icon fontSize="small">add_circle</Icon>}
                            />
                        );
                    })}
                </Box>
            );
        } else {
            assignRolesElement = (
                <Box sx={{ margin: '16px' }}>
                    <IconButton size="small" onClick={(evt: any) => setAssignRoles(false)}><Icon>close</Icon></IconButton>
                    <Typography variant="caption">
                        There are no {(profileRoles && profileRoles.length > 0 ? 'more' : null)} roles to assign.
                        you can assign current or new roles to the solution from the Roles tab.
                    </Typography>
                </Box>
            );
        }
    } else {
        assignRolesElement = (
            <Box sx={{ margin: '16px' }}>
                <IconButton size="small" onClick={(evt: any) => setAssignRoles(true)}><Icon>add</Icon></IconButton>
            </Box>
        );
    }

    let permissionNames: string[] = [ 'View', 'Add', 'Edit', 'Edit If Owner', 'Delete', 'Delete If Owner' ];
    if (udicci.isCurrentUserUlysses()) permissionNames.push('Internal Reference Only');

    let currentlyAssignedRolesElement: any = null;
    if (roleOverridesList.length > 0) {
        let sortedRoleOverrides: any[] = roleOverridesList.sort((a: any,b: any) => {
            if (a.roleName.toLowerCase() < b.roleName.toLowerCase()) return -1;  // ascending
            if (a.roleName.toLowerCase() > b.roleName.toLowerCase()) return 1;  // descending
            return 0 //default return value (no sorting)
        });
        // console.log('%c sortedRoleOverrides: %O', 'color: maroon;', sortedRoleOverrides);

        let assignedRoleRowElements: any[] = [];
        forEach(sortedRoleOverrides, (role: any, idxRole: number) => {
            // console.log('%c idxRole: %O', 'color: purple;', idxRole);
            // console.log('%c role: %O', 'color: purple;', role);

            let removingARole: boolean = (removeRoleId ? true : false);
            let removingThisRole: boolean = (removingARole && removeRoleId === role.roleId ? true : false);

            let rowKey: string = 'role.row.' + role.roleId;
            let rolePermissions: any = (role.permissions ? role.permissions : null);
            // console.log('%c rolePermissions: %O', 'color: purple;', rolePermissions);
            let overrides: any = (rolePermissions && rolePermissions.overrides ? rolePermissions.overrides : null);
            // console.log('%c overrides: %O', 'color: purple;', overrides);

            let overrideRowElements: any[] = [];

            let removeRoleElement: any = null;
            let primaryProps: any = {
                size: 'small',
                sx: { marginLeft: '7px', marginRight: '0px', padding: '2px' }
            };
            // console.log('%c role: %O', 'color: purple;', role);
            if (removingThisRole) {
                let overrideRowKey: string = rowKey + '.override.assign';
                overrideRowElements.push(
                    <TableRow key={overrideRowKey}>
                        <TableCell colSpan={permissionNames.length + 2}>
                            <Box sx={{ display: 'flex' }}>
                                <Typography variant="errorMessage">
                                    You are requesting to remove {role.roleName} access for this social solution.  Are you sure?
                                </Typography>
                                <IconButton {...primaryProps} color="secondary" onClick={(evt: any) => setRemoveRoleId(0)}
                                            aria-label={'Cancel Remove Role'}
                                >
                                    <Icon fontSize="small">cancel</Icon>
                                </IconButton>
                                <IconButton {...primaryProps} color="success" onClick={(evt: any) => removeRole()}
                                            aria-label={'Confirm Remove Role'}
                                >
                                    <Icon fontSize="small">check</Icon>
                                </IconButton>
                            </Box>
                            </TableCell>
                    </TableRow>
                );
            } else {
                removeRoleElement = (
                    <IconButton {...primaryProps} disabled={removingARole} color="error" onClick={(evt: any) => setRemoveRoleId(role.roleId)}
                                aria-label={'Remove Role'}
                    >
                        <Icon fontSize="small">remove_circle_outline</Icon>
                    </IconButton>
                );
            }

            if (assignOverride === role.roleId) {
                let solutionMediators: any[] = getMediatorOptions(overrides);
                let mediatorSelector: any = (
                    <Box sx={{  display: 'flow-root', padding: '8px' }}>
                        <IconButton color="error" disabled={removingARole}
                                    size="small"
                                    onClick={(evt: any) => setAssignOverride(0)}
                                    aria-label={'Close Override'}
                                    sx={{ margin: '2px', float: 'left' }}
                        >
                            <Icon fontSize="small">search_off</Icon>
                        </IconButton>
                        <Autocomplete sx={{ width: '250px', margin: '4px', float: 'left' }} options={solutionMediators}
                                      onChange={selectMediatorToOverride}
                                      renderInput={(params) => <TextField {...params} label="Select Mediator to Override" />}
                        />
                        <IconButton color="success" disabled={removingARole}
                                    size="small"
                                    onClick={(evt: any) => addOverride()}
                                    aria-label={'Add Override'}
                                    sx={{ margin: '2px', float: 'left' }}
                        >
                            <Icon fontSize="small">enhanced_encryption</Icon>
                        </IconButton>
                    </Box>
                );

                let overrideRowKey: string = rowKey + '.override.assign';
                overrideRowElements.push(
                    <TableRow key={overrideRowKey}>
                        <TableCell colSpan={permissionNames.length + 2}>
                            {mediatorSelector}
                        </TableCell>
                    </TableRow>
                );
            }

            let isOverridingFields: boolean = false;
            if (overrides && (viewOverrides === role.roleId || assignOverride === role.roleId)) {
                forEach(overrides, (override: any, overrideMediatorName: string) => {
                    // console.log('%c overrideMediatorName: %O', 'color: purple;', overrideMediatorName);
                    // console.log('%c override: %O', 'color: purple;', override);

                    let medName: string = overrideMediatorName.replace(' ', '.').toLowerCase();
                    let viewFields: any = (override.ViewFields ? override.ViewFields : null);
                    // console.log('%c viewFields: %O', 'color: purple;', viewFields);

                    let assignFieldsForThisOverride: boolean = (assignFields && assignFields === overrideMediatorName ? true : false);
                    if (assignFieldsForThisOverride === true) isOverridingFields = true;

                    let fieldAssignmentsElement: any = null;
                    let selectFieldsElement: any = null;
                    if (viewOverrides === role.roleId) {
                        if (assignFieldsForThisOverride) {
                            fieldAssignmentsElement = (
                                <IconButton size="small" disabled={removingARole} 
                                            color={"info"}
                                            onClick={(evt: any) => overrideFieldsForMediator('', null)}
                                >
                                    <Icon fontSize="small">close</Icon>
                                </IconButton>
                            );

                            let selectedStructure: any = udicciHelpers.getMediatorStructure(overrideMediatorName);
                            // console.log('%c selectedStructure: %O', 'color: red;', selectedStructure);
                            // console.log('%c viewFields: %O', 'color: red;', viewFields);

                            let hasChanges: boolean = false;
                            let headerMessageElement: any = null;
                            let fields: any[] = (selectedStructure && selectedStructure.UdicciMediatorFields ? selectedStructure.UdicciMediatorFields : []);
                            // console.log('%c fields: %O', 'color: purple;', fields);
                            let includedFieldCount: number = 0;
                            let fieldSelections: any[] = [];
                            Object.entries(fields).forEach(([idxField, field]) => {
                                let fieldIncluded: boolean = (viewFields.indexOf(field.JsonFieldName) >= 0 ? true : false);
                                if (restrictedFields) {
                                    fieldIncluded = (restrictedFields.indexOf(field.JsonFieldName) >= 0 ? true : false);
                                    // console.log('%c viewFields: %O', 'color: purple;', viewFields);
                                    // console.log('%c restrictedFields: %O', 'color: purple;', restrictedFields);
                                    let dupFlds: any[] = intersection(viewFields, restrictedFields);
                                    // console.log('%c dupFlds: %O', 'color: purple;', dupFlds);
                                    if (dupFlds.length !== viewFields.length || dupFlds.length !== restrictedFields.length) hasChanges = true;
                                }
                                if (fieldIncluded) includedFieldCount++;
                                fieldSelections.push(
                                    <FormControlLabel
                                        key={field.UdicciMediatorFieldId}
                                        label={field.Name}
                                        control={
                                            <Checkbox checked={fieldIncluded}
                                                      onChange={(evt: any) => onFieldSelectionChanged(field.JsonFieldName, evt)}
                                                      color="primary"
                                            />
                                        }
                                    />
                                );
                            });
                    
                            if (fieldSelections.length <= 0) {
                                headerMessageElement = (
                                    <Typography component="div" variant="caption">
                                        Waiting for structure to load ...
                                    </Typography>
                                );
                            } else {
                                if (includedFieldCount > 0) {
                                    headerMessageElement = (
                                        <Typography component='div' variant='caption'>
                                            Only the selected fields below are accessible for {overrideMediatorName}.
                                        </Typography>
                                    );
                                } else {
                                    headerMessageElement = (
                                        <Typography component='div' variant='caption'>
                                            All fields are included by default.  Selecting at least one field on this list 
                                            will limit the fields that are accessible for {overrideMediatorName} to only those that 
                                            are selected.
                                        </Typography>
                                    );
                                }
                            }

                            selectFieldsElement = (
                                <Box sx={{ margin: '8px' }}>
                                    <Box>
                                        <Typography component='div' color='primary'>
                                            Select Field(s) to Include for {overrideMediatorName}
                                        </Typography>
                                        {headerMessageElement}
                                    </Box>
                                    <Box>
                                        {fieldSelections}
                                    </Box>
                                    <Box>
                                        <Button onClick={(evt: any) => overrideFieldsForMediator('', null)} color="info">
                                            Cancel
                                        </Button>
                                        <Button disabled={hasChanges ? false : true} onClick={(evt: any) => applyFieldOverrides()} color="success">
                                            Apply to Overrides
                                        </Button>
                                    </Box>
                                </Box>
                            );
                        } else {
                            fieldAssignmentsElement = (
                                <IconButton size="small" disabled={removingARole} 
                                            color={(viewFields && viewFields.length > 0 ? "editButton" : "info")}
                                            onClick={(evt: any) => overrideFieldsForMediator(overrideMediatorName, viewFields)}
                                >
                                    <Icon fontSize="small">list_alt</Icon>
                                </IconButton>
                            );
                        }
                    }

                    let overrideRowKey: string = rowKey + '.override.' + medName;
                    overrideRowElements.push(
                        <TableRow key={overrideRowKey}>
                            <TableCell>
                                <Typography variant="subtitle1" noWrap={true} component='span' sx={{ marginLeft: '8px', marginRight: '8px' }}>
                                    {overrideMediatorName}
                                </Typography>
                            </TableCell>
                            <TableCell align="right" sx={{ paddingRight: '8px' }}>
                                {(!assignFieldsForThisOverride ? fieldAssignmentsElement : null)}
                                <IconButton color="error" disabled={removingARole || assignFieldsForThisOverride}
                                            size="small"
                                            onClick={(evt: any) => removeOverride(role, overrideMediatorName)}
                                            aria-label={'Remove Override'}
                                            sx={{ margin: 0, padding: '2px' }}
                                >
                                    <Icon fontSize="small">remove</Icon>
                                </IconButton>                                
                            </TableCell>
                            {
                                permissionNames.map((permissionName: string) => {
                                    let permName: string = permissionName;
                                    let permLabel: string = permissionName;
                                    if (permName.indexOf(' ') > 0) permName = permName.replace(/ /g, '');
                                    if (permName !== 'InternalReferenceOnly') {
                                        permName = 'Can' + permName;
                                    } else {
                                        permLabel = 'Internal';
                                    }
                                    // console.log('%c permName: %O', 'color: purple;', permName);
        
                                    let permValue: boolean = false;
                                    if (override && override[permName] === true) {
                                        permValue = true;
                                    }
                                    // console.log('%c permValue: %O', 'color: purple;', permValue);
        
                                    return (
                                        <TableCell key={overrideRowKey + '.' + permName} align='center'>
                                            <Tooltip title={role.roleName + ' ' + permLabel + ' permission is ' + (permValue ? 'Currently Enabled' : 'not enabled')}>
                                                <Switch color="primary"
                                                        checked={permValue}
                                                        disabled={removingARole || isOverridingFields}
                                                        onChange={(evt: any) => updatePermissions(permName, role, overrideMediatorName, evt)}
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    );
                                })
                            }
                        </TableRow>
                    );

                    if (assignFieldsForThisOverride) {
                        overrideRowElements.push(
                            <TableRow key={overrideRowKey + '.field.assignments'}>
                                <TableCell colSpan={permissionNames.length + 2}>
                                    {fieldAssignmentsElement}
                                    {selectFieldsElement}
                                </TableCell>
                            </TableRow>
                        );
                    }
                });
            }

            // add the Role row
            assignedRoleRowElements.push(
                <TableRow key={rowKey}>
                    <TableCell>
                        <Typography variant="subtitle1" noWrap={true} component='span'>
                            {role.roleName}
                        </Typography>
                    </TableCell>
                    <TableCell align="right" sx={{ paddingRight: '8px' }}>
                        <Box sx={{ display: 'flex' }}>
                            <Tooltip title={'View Override Permissions for users assigned to the "' + role.roleName + '" Role.'}>
                                <Badge color="secondary" variant="dot" invisible={(keys(overrides).length > 0 ? false : true)}>
                                    <IconButton {...primaryProps} color={(viewOverrides === role.roleId ? "success" : "secondary")}
                                                onClick={(evt: any) => viewOverridesForRole(role.roleId)}
                                                aria-label={'View Role Overrides'}
                                    >
                                        <Icon fontSize="small">join_left</Icon>
                                    </IconButton>
                                </Badge>
                            </Tooltip>
                            <Tooltip title={'Add new Override Mediator Permissions for users assigned to the "' + role.roleName + '" Role.'}>
                                <IconButton {...primaryProps} color={(assignOverride === role.roleId ? "success" : "secondary")}
                                            onClick={(evt: any) => assignRoleOverride(role.roleId)}
                                            aria-label={'Assign Role Override'} disabled={removingARole || isOverridingFields}
                                >
                                    <Icon fontSize="small">playlist_add</Icon>
                                </IconButton>
                            </Tooltip>
                            {removeRoleElement}
                        </Box>
                    </TableCell>
                    {
                        permissionNames.map((permissionName: string) => {
                            let permName: string = permissionName;
                            let permLabel: string = permissionName;
                            if (permName.indexOf(' ') > 0) permName = permName.replace(/ /g, '');
                            if (permName !== 'InternalReferenceOnly') {
                                permName = 'Can' + permName;
                            } else {
                                permLabel = 'Internal';
                            }
                            // console.log('%c permName: %O', 'color: purple;', permName);

                            let permValue: boolean = false;
                            if (rolePermissions && rolePermissions[permName] === true) {
                                permValue = true;
                            }
                            // console.log('%c permValue: %O', 'color: purple;', permValue);

                            return (
                                <TableCell key={rowKey + '.' + permName} align='center'>
                                    <Tooltip title={role.roleName + ' ' + permLabel + ' permission is ' + (permValue ? 'Currently Enabled' : 'not enabled')}>
                                        <Switch color="primary"
                                                checked={permValue}
                                                disabled={removingARole || isOverridingFields}
                                                onChange={(evt: any) => updatePermissions(permName, role, '', evt)}
                                        />
                                    </Tooltip>
                                </TableCell>
                            );
                        })
                    }
                </TableRow>
            );

            if (overrideRowElements.length > 0) assignedRoleRowElements.push.apply(assignedRoleRowElements, overrideRowElements);
        });

        currentlyAssignedRolesElement = (
            <Table padding="none" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell><Typography variant="subtitle1" noWrap={true}>Name</Typography></TableCell>
                        <TableCell padding="checkbox">&nbsp;</TableCell>
                        {
                            permissionNames.map((permissionName: string) => {
                                let permName: string = permissionName;
                                let permLabel: string = permissionName;
                                if (permName.indexOf(' ') > 0) permName = permName.replace(/ /g, '');
                                if (permName !== 'InternalReferenceOnly') {
                                    permName = 'Can' + permName;
                                } else {
                                    permLabel = 'Internal';
                                }
                                // console.log('%c permName: %O', 'color: purple;', permName);

                                return (
                                    <TableCell key={'header.' + permName} align='center' sx={{ width: '5%' }}>
                                        <Typography component="span" variant="subtitle1" noWrap={true}>
                                            {permLabel}
                                        </Typography>
                                    </TableCell>
                                );
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {assignedRoleRowElements}
                </TableBody>
            </Table>
        );
    }

    let rolesElement: any = (
        <Box>
            {currentlyAssignedRolesElement}
            {assignRolesElement}
        </Box>
    );
    return rolesElement;
}

export default AssignRoleAccess;
