
import { DeleteConfig } from './Delete/DeleteConfig';
import { DeleteUI } from './Delete/DeleteUI';
import { DeleteMenu } from './Delete/DeleteMenuItem';
import { DeleteHelp } from './Delete/DeleteHelp';

const triggerAction = async (options: any) =>  {
    // console.log('%c Delete triggerAction options: %O', 'color: blue;', options);
    return false;
}

const submitAction = async (options: any) =>  {
    // console.log('%c Delete submitAction options: %O', 'color: blue;', options);
    return false;
}

const Delete: React.FC<any> = (props) => {
    // console.log('%c Delete props: %O', 'color: blue;', props);

    let configureAction = (props && props.configure === true ? true : false);

    let actionProps: any = {};
    Object.assign(actionProps, props);
    // console.log('%c actionProps: %O', 'color: red;', actionProps);

    let actionElement: any = null;
    if (configureAction) {
        actionElement = (<DeleteConfig {...actionProps} />);
    } else {
        actionElement = (<DeleteUI {...actionProps} />);
    }
    return actionElement;
}

export { Delete, DeleteMenu, DeleteHelp, triggerAction, submitAction }
