
import { BroadcastMVTConfig } from './BroadcastMVT/BroadcastMVTConfig';
import { BroadcastMVTUI } from './BroadcastMVT/BroadcastMVTUI';
import { BroadcastMVTMenu } from './BroadcastMVT/BroadcastMVTMenuItem';
import { BroadcastMVTHelp } from './BroadcastMVT/BroadcastMVTHelp';

const triggerAction = async (options: any) =>  {
    // console.log('%c BroadcastMVT triggerAction options: %O', 'color: blue;', options);
    return false;
}

const submitAction = async (options: any) =>  {
    // console.log('%c BroadcastMVT submitAction options: %O', 'color: blue;', options);
    return false;
}

const BroadcastMVT: React.FC<any> = (props) => {
    // console.log('%c BroadcastMVT props: %O', 'color: blue;', props);

    let configureAction = (props && props.configure === true ? true : false);

    let actionProps: any = {};
    Object.assign(actionProps, props);
    // console.log('%c actionProps: %O', 'color: red;', actionProps);

    let actionElement: any = null;
    if (configureAction) {
        actionElement = (<BroadcastMVTConfig {...actionProps} />);
    } else {
        actionElement = (<BroadcastMVTUI {...actionProps} />);
    }
    return actionElement;
}

export { BroadcastMVT, BroadcastMVTMenu, BroadcastMVTHelp, triggerAction, submitAction }
