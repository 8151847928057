import { createContext, useContext, useState, useCallback, Dispatch, useReducer } from 'react'

import { find, findIndex, forEach, last } from 'underscore';

import { UdicciRecord } from 'src/classes/udicci-record';

import { UdicciListActions, UdicciListContextStateType, UdicciBreadcrumb, AdvancedContextSubscriber } from 'src/classes/udicci-types';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

import {
    UdicciContext, 
    getUdicciData,
    getUdicciDataAsync,
    // getProfileSetting,
    subscribe as subscribeToUdicci
} from 'src/context/udicci-context';

const defaultContextValue: UdicciListContextStateType = {
    uid: '',
    data: null,
    socialSolution: null,
    feature: null,
    mediator: null,
    record: null,
    settings: null,
    breadcrumbs: null,
    onLoadBreadcrumb: null,
    onCloseBreadcrumb: null,
    onCloseAllBreadcrumbs: null,
    onRefresh: null,
    onAddRecord: null,
    onEditRecord: null,
    onEditContextRecord: null,
    onDeleteRecord: null,
    onEngageRecord: null,
    lastFetchResultDate: null
};

let UdicciListContext = createContext<{
    state: UdicciListContextStateType;
    dispatch: Dispatch<UdicciListActions>;
}>({
    state: defaultContextValue,
    dispatch: () => null
});

var subscribers: AdvancedContextSubscriber[] = [];

let notifySubscribers = (state: any = null, payload: any = null) => {
    // console.log('%c UdicciListContext notifySubscribers state: %O', 'color: teal; font-weight: bold;', state);
    // console.log('%c UdicciListContext notifySubscribers payload: %O', 'color: teal; font-weight: bold;', payload);
    // console.log('%c UdicciListContext notifySubscribers subscribers: %O', 'color: teal; font-weight: bold;', subscribers);
    subscribers.forEach((sub: AdvancedContextSubscriber) => {
        // console.log('%c sub: %O', 'color: red;', sub);
        let request: any = (payload && payload.request ? payload.request : null);
        // console.log('%c request: %O', 'color: teal; font-weight: bold;', request);
        let subNotes: any = (sub.notes ? sub.notes : null);
        // console.log('%c subNotes: %O', 'color: teal; font-weight: bold;', subNotes);
        if (subNotes && subNotes.mediator && subNotes.mediator.name && request && request.UdicciMediatorName) {
            // console.log('%c subNotes %s: %O', 'color: teal; font-weight: bold;', subNotes.mediator.name, subNotes);
            // console.log('%c request %s: %O', 'color: teal; font-weight: bold;', request.UdicciMediatorName, request);
            if (subNotes.mediator.name === request.UdicciMediatorName) {
                // console.log('%c payload: %O', 'color: teal; font-weight: bold;', payload);
                // console.log('%c state: %O', 'color: teal; font-weight: bold;', state);
                var cb = sub.callback;
                if (cb) cb(state, payload);
            }
        } else {
            // console.log('%c payload: %O', 'color: grey;', payload);
            // console.log('%c state: %O', 'color: grey;', state);
            var cb = sub.callback;
            if (cb) cb(state, payload);
        }
    });
}

export let subscribe = (subcriberId: string, cbFunc: Function, subscriberNotes: any = null) => {
    // console.log('%c UdicciListContext subscribe subcriberId: %O', 'color: blue;', subcriberId);
    // console.log('%c UdicciListContext subscribe subscriberNotes: %O', 'color: blue;', subscriberNotes);
    var foundSub = false;
    subscribers.forEach((sub: AdvancedContextSubscriber, idx: number) => {
        // console.log('%c sub: %O', 'color: blue;', sub);
        if (sub.subscriber === subcriberId) {
            foundSub = true;
            subscribers[idx].callback = cbFunc;
        }
    });

    if (!foundSub) {
        var newSub: AdvancedContextSubscriber = { subscriber: subcriberId, callback: cbFunc, notes: subscriberNotes }
        subscribers.push(newSub)
    }
}

export let unsubscribe = (subcriberId: string) => {
    var newSubscriberList: AdvancedContextSubscriber[] = [];
    subscribers.forEach((sub: AdvancedContextSubscriber, idx: number) => {
        // console.log('%c sub: %O', 'color: blue;', sub);
        if (sub.subscriber !== subcriberId) {
            newSubscriberList.push(sub);
        }
    });
    subscribers = newSubscriberList;
}

export let getListData = (subId: string, options: any, udicciHelpers: any) => {
    // console.log('%c getListData subId: %O', 'color: red;', subId);
    // console.log('%c getListData options: %O', 'color: red;', options);
    // console.log('%c getListData subscribers: %O', 'color: teal; font-weight: bold;', subscribers);
    if (!udicciHelpers) return [];

    let rval: any[] = [];
    if (subscribers) {
        let sub: any = subscribers.find((x: any) => x.subscriber === subId);
        // console.log('%c getListData sub: %O', 'color: red;', sub);
        if (sub) {
            let subNotes: any = (sub.notes ? sub.notes : null);
            // console.log('%c getListData subNotes: %O', 'color: teal; font-weight: bold;', subNotes);
            let subSocialSolution: any = (subNotes && subNotes.socialSolution ? subNotes.socialSolution : null);
            // console.log('%c getListData subSocialSolution: %O', 'color: teal; font-weight: bold;', subSocialSolution);
            let subSocialSolutionId: number = (subSocialSolution && subSocialSolution.id ? subSocialSolution.id : 0);
            // console.log('%c getListData subSocialSolutionId: %O', 'color: teal; font-weight: bold;', subSocialSolutionId);
            let subSocialSolutionName: string = (subSocialSolution && subSocialSolution.name ? subSocialSolution.name : '');
            // console.log('%c getListData subSocialSolutionName: %O', 'color: teal; font-weight: bold;', subSocialSolutionName);

            let subMediator: any = (subNotes && subNotes.mediator ? subNotes.mediator : null);
            // console.log('%c getListData subMediator: %O', 'color: teal; font-weight: bold;', subMediator);
            let subMediatorId: number = (subMediator && subMediator.id ? subMediator.id : 0);
            // console.log('%c getListData subMediatorId: %O', 'color: teal; font-weight: bold;', subMediatorId);
            let subMediatorName: string = (subMediator && subMediator.name ? subMediator.name : '');
            // console.log('%c getListData subMediatorName: %O', 'color: teal; font-weight: bold;', subMediatorName);
            let getDataSettings: any = { mediatorName: subMediatorName, socialSolutionId: subSocialSolutionId };
            // console.log('%c getListData getDataSettings: %O', 'color: teal; font-weight: bold;', getDataSettings);

            rval = udicciHelpers.preloadMediatorData(subMediatorName, subSocialSolutionId, false);
        }
    }
    // console.log('%c getListData rval: %O', 'color: teal; font-weight: bold;', rval);
    return rval;
}

let udicciListFactory = (state: any, action: any) => {
    // console.log('%c udicciListFactory state: %O', 'color: purple; font-weight: bold;', state);
    // console.log('%c udicciListFactory action: %O', 'color: purple; font-weight: bold;', action);

    var payload: any | null = (action && action.payload ? action.payload : null);

    var pData: UdicciRecord[] | null = (payload && payload.data ? payload.data : null);
    if (!pData) pData = state.data;

    var pLastFetchResultDate: any | null = (payload && payload.lastFetchResultDate ? payload.lastFetchResultDate : null);
    if (!pLastFetchResultDate) pLastFetchResultDate = state.lastFetchResultDate;
    // console.log('%c udicciListFactory pLastFetchResultDate: %O', 'color: red;', pLastFetchResultDate);

    var pSettingsDefined: boolean = (payload && payload.settings !== undefined ? true : false);
    var pSettings: any | null = (pSettingsDefined ? payload.settings : null);
    if (!pSettings && !pSettingsDefined) pSettings = state.settings;
    // console.log('%c udicciListFactory pSettings: %O', 'color: red;', pSettings);

    var pBreadcrumbsDefined: boolean = (payload && payload.breadcrumbs !== undefined ? true : false);
    var pBreadcrumbs: UdicciBreadcrumb[] | null = (pBreadcrumbsDefined ? payload.breadcrumbs : null);
    if (!pBreadcrumbs && !pBreadcrumbsDefined) pBreadcrumbs = state.breadcrumbs;

    var pMediatorDefined: boolean = (payload && payload.mediator !== undefined ? true : false);
    var pMediator: any | null = (pMediatorDefined ? payload.mediator : null);
    if (!pMediator && !pMediatorDefined) pMediator = state.mediator;

    var pSolutionDefined: boolean = (payload && payload.socialSolution !== undefined ? true : false);
    var pSolution: any | null = (pSolutionDefined ? payload.socialSolution : null);
    if (!pSolution && !pSolutionDefined) pSolution = state.socialSolution;
    // console.log('%c udicciListFactory pSolution: %O', 'color: red;', pSolution);

    var pListFieldsDefined: boolean = (payload && payload.listFields !== undefined ? true : false);
    var pListFields: any | null = (pListFieldsDefined ? payload.listFields : null);
    if (!pListFields && !pListFieldsDefined) pListFields = state.listFields;

    var pStructureDefined: boolean = (payload && payload.structure !== undefined ? true : false);
    var pStructure: any | null = (pStructureDefined ? payload.structure : null);
    if (!pStructure && pMediator && pMediator.structure && !pStructureDefined) pStructure = pMediator.structure;
    // console.log('%c udicciListFactory pStructure: %O', 'color: red;', pStructure);

    var pPermissionsDefined: boolean = (payload && payload.permissions !== undefined ? true : false);
    var pPermissions: any | null = (pPermissionsDefined ? payload.permissions : null);
    if (!pPermissions && pMediator && pMediator.permissions && !pPermissionsDefined) pPermissions = pMediator.permissions;
    // console.log('%c udicciListFactory pPermissions: %O', 'color: red;', pPermissions);

    var pRecordDefined: boolean = (payload && payload.record !== undefined ? true : false);
    var pRecord: UdicciRecord | any | null = (pRecordDefined ? payload.record : null);
    if (!pRecord && !pRecordDefined) pRecord = state.record;
    // console.log('%c udicciListFactory pRecord: %O', 'color: red;', pRecord);

    // var pRecordIndexDefined: boolean = (payload && payload.recordIndex !== undefined ? true : false);
    // var pRecordIndex: number = (pRecordIndexDefined ? payload.recordIndex : -1);
    // if (pRecordIndex < 0 && !pRecordIndexDefined) pRecordIndex = state.recordIndex;
    // console.log('%c udicciListFactory pRecordIndex: %O', 'color: red;', pRecordIndex);

    var pContextRecordDefined: boolean = (payload && payload.contextRecord !== undefined ? true : false);
    var pContextRecord: any = (pContextRecordDefined ? payload.contextRecord : null);
    if (!pContextRecord && !pContextRecordDefined) pContextRecord = state.contextRecord;

    var pEngagementActionDefined: boolean = (payload && payload.engagementAction !== undefined ? true : false);
    var pEngagementAction: any = (pEngagementActionDefined ? payload.engagementAction : null);
    if (!pEngagementAction && !pEngagementActionDefined) pEngagementAction = state.engagementAction;

    if (pData) state.data = pData;
    if (pLastFetchResultDate) state.lastFetchResultDate = pLastFetchResultDate;
    if (pSettings) state.settings = pSettings;
    if (pBreadcrumbs) state.breadcrumbs = pBreadcrumbs;
    if (pSolution) state.socialSolution = pSolution;
    // console.log('%c udicciListFactory pMediator: %O', 'color: purple; font-weight: bold;', pMediator);
    if (pStructure && pMediator) pMediator.structure = pStructure;
    if (pPermissions && pMediator) pMediator.permissions = pPermissions;
    if (pMediator) state.mediator = pMediator;
    if (pListFields) {
        if (!state.settings) state.settings = {};
        state.settings.listFields = pListFields;
    }

    var pParentRecordDefined: boolean = (payload && payload.parentRecord !== undefined ? true : false);
    var pParentRecord: UdicciRecord | null = (pParentRecordDefined ? payload.parentRecord : null);
    if (!pParentRecord && !pParentRecordDefined) pParentRecord = state.parentRecord;
    // console.log('%c udicciListFactory pParentRecord: %O', 'color: red;', pParentRecord);

    switch (action.type.toString().toLowerCase()) {
        case UdicciListActions.Refresh.toLowerCase(): {
            // console.log('%c udicciListFactory Refresh: %O', 'color: purple; font-weight: bold;', UdicciListActions.Refresh.toLowerCase());
            // console.log('%c udicciListFactory Refresh state: %O', 'color: purple; font-weight: bold;', state);
            notifySubscribers(state, payload);
            break;
        }
        case UdicciListActions.CloseAllBreadcrumbs.toLowerCase(): {
            // console.log('%c UdicciListActions.CloseAllBreadcrumbs pBreadcrumbs: %O', 'color: blue;', pBreadcrumbs);
            // console.log('%c UdicciListActions.CloseAllBreadcrumbs state.breadcrumbs: %O', 'color: blue;', state.breadcrumbs);
            let stateUpdate: any = {
                breadcrumbs: null,
                record: null,
                engagementAction: null
            };
            Object.assign(state, stateUpdate);
            // console.log('%c UdicciListActions.CloseAllBreadcrumbs state: %O', 'color: blue;', state);
            notifySubscribers(stateUpdate, payload);
            break;
        }
        case UdicciListActions.CloseBreadcrumb.toLowerCase(): {
            // console.log('%c UdicciListActions.CloseBreadcrumb pBreadcrumbs: %O', 'color: blue;', pBreadcrumbs);
            // console.log('%c UdicciListActions.CloseBreadcrumb state.breadcrumbs: %O', 'color: blue;', state.breadcrumbs);

            let newRecordValue: UdicciRecord | null = null;
            let newBreadcrumbs: any[] | null = null;
            let newEngagementAction: any | null = null;
    
            if (state.breadcrumbs && state.breadcrumbs.length > 1) {
                let numberOfBreadcrumbs: number = state.breadcrumbs.length;
                newBreadcrumbs = [];
                forEach(state.breadcrumbs, (bc: any, idx: number) => {
                    // console.log('%c bc: %O', 'color: blue;', bc);
                    if (idx < (numberOfBreadcrumbs - 1)) {
                        newBreadcrumbs?.push(bc);
                    }
                });
    
                if (newBreadcrumbs.length > 0) {
                    let lastIndex: number = newBreadcrumbs.length - 1;
                    if (newBreadcrumbs[lastIndex] && newBreadcrumbs[lastIndex].record) {
                        newRecordValue = newBreadcrumbs[lastIndex].record;
                    }
                    if (newBreadcrumbs[lastIndex] && newBreadcrumbs[lastIndex].engagementAction) {
                        newEngagementAction = newBreadcrumbs[lastIndex].engagementAction;
                    }
                }
            }
            // console.log('%c UdicciListActions.CloseBreadcrumb newBreadcrumbs: %O', 'color: maroon;', newBreadcrumbs);
            // console.log('%c UdicciListActions.CloseBreadcrumb newRecordValue: %O', 'color: maroon;', newRecordValue);
            // console.log('%c UdicciListActions.CloseBreadcrumb newEngagementAction: %O', 'color: maroon;', newEngagementAction);

            let stateUpdate: any = {
                breadcrumbs: newBreadcrumbs,
                record: newRecordValue,
                engagementAction: newEngagementAction
            };
            Object.assign(state, stateUpdate);
            // console.log('%c UdicciListActions.CloseBreadcrumb state: %O', 'color: blue;', state);
            notifySubscribers(stateUpdate, payload);
            break;
        }
        case UdicciListActions.LoadBreadcrumb.toLowerCase(): {
            // console.log('%c UdicciListActions.LoadBreadcrumb pBreadcrumbs: %O', 'color: blue;', pBreadcrumbs);
            // console.log('%c UdicciListActions.LoadBreadcrumb state.breadcrumbs: %O', 'color: blue;', state.breadcrumbs);

            if (pBreadcrumbs) {
                let stateUpdate: any = {
                    breadcrumbs: pBreadcrumbs,
                    record: pRecord,
                    engagementAction: pEngagementAction
                };
                Object.assign(state, stateUpdate);
                // console.log('%c UdicciListActions.LoadBreadcrumb state: %O', 'color: blue;', state);
                notifySubscribers(stateUpdate, payload);
            }
            break;
        }
        case UdicciListActions.Add.toLowerCase(): {
            let tmpBCs: any[] = (pBreadcrumbs ? pBreadcrumbs : []);
            let chk: UdicciBreadcrumb = find(tmpBCs, (bc: UdicciBreadcrumb) => {
                let bRecord: any = (bc.record ? bc.record : null);
                let isRecord: boolean = (bRecord && bRecord.udicciMediator === 'aRecord.udicciMediator' && bRecord.recordId === 0 ? true : false);
                return isRecord;
            });
            // console.log('%c UdicciListActions.Add chk: %O', 'color: blue;', chk);

            if (!chk) {
                var newRec: UdicciRecord | null = pRecord;
                if (pMediator) {
                    if (!newRec && (pBreadcrumbs === null || pBreadcrumbs.length <= 0)) {
                        newRec = new UdicciRecord(pMediator.name, null, pStructure, pPermissions);
                    }
                } else if (pStructure) {
                    if (!newRec && (pBreadcrumbs === null || pBreadcrumbs.length <= 0)) {
                        newRec = new UdicciRecord(pStructure.Name, null, pStructure, pPermissions);
                    }
                }
                // console.log('%c UdicciListActions.Add newRec: %O', 'color: blue;', newRec);
                // console.log('%c UdicciListActions.Add pBreadcrumbs: %O', 'color: blue;', pBreadcrumbs);
                if (newRec) {
                    // var socialSolution: any = (pSolution && pSolution.more && pSolution.more.solution ? pSolution.more.solution : null);
                    var bcAdd: UdicciBreadcrumb = {
                        record: newRec,
                        socialSolution: pSolution,
                        mediator: pMediator,
                        settings: {
                            showForm: true
                        },
                        engagementAction: null,
                        parents: []
                    };
                    if (pParentRecord && bcAdd.parents) bcAdd.parents.push(pParentRecord);
                    // console.log('%c UdicciListActions.Add newRec: %O', 'color: blue;', newRec);
                    if (!pBreadcrumbs) pBreadcrumbs = [];
                    pBreadcrumbs.push(bcAdd);
                }
            }
            // console.log('%c UdicciListActions.Add pBreadcrumbs: %O', 'color: blue;', pBreadcrumbs);
            state.breadcrumbs = pBreadcrumbs;
            state.mediator = pMediator;
            state.socialSolution = pSolution;
            // console.log('%c UdicciListActions.Add state: %O', 'color: blue;', state);
            notifySubscribers(state, payload);
            break;
        }
        case UdicciListActions.Edit.toLowerCase(): {
            // console.log('%c UdicciListActions.Edit pBreadcrumbs: %O', 'color: green;', pBreadcrumbs);
            if (pRecord || pContextRecord && pBreadcrumbs) {
                // var socialSolution: any = (pSolution && pSolution.more && pSolution.more.solution ? pSolution.more.solution : null);
                // console.log('%c UdicciListActions.Edit pBreadcrumbs: %O', 'color: blue;', pBreadcrumbs);
                let aRecord: any = (pRecord ? pRecord : null);
                // console.log('%c UdicciListActions.Edit aRecord: %O', 'color: blue;', aRecord);
                let tmpBCs: any[] = (pBreadcrumbs ? pBreadcrumbs : []);
                let chk: UdicciBreadcrumb = find(tmpBCs, (bc: UdicciBreadcrumb) => {
                    let bRecord: any = (bc.record ? bc.record : null);
                    let isRecord: boolean = (bRecord && bRecord.udicciMediator === aRecord.udicciMediator && bRecord.recordId === aRecord.recordId ? true : false);
                    return isRecord;
                });
                // console.log('%c UdicciListActions.Edit chk: %O', 'color: blue;', chk);
                if (!chk) {
                    let sameMediatorIndex: number = findIndex(tmpBCs, (bc: UdicciBreadcrumb) => {
                        let bMediator: any = (bc.mediator ? bc.mediator : null);
                        let bRecord: any = (bc.record ? bc.record : null);
                        let sameMediator: boolean = (bMediator && bMediator.name === aRecord.udicciMediator ? true : false);
                        let sameRecordMediator: boolean = (bRecord && bRecord.udicciMediator === aRecord.udicciMediator ? true : false);
                        return (sameMediator || sameRecordMediator);
                    });
                    // console.log('%c UdicciListActions.Edit sameMediatorIndex: %O', 'color: blue;', sameMediatorIndex);

                    if (sameMediatorIndex >= 0) {
                        tmpBCs[0].record = (pContextRecord && pContextRecord.record ? pContextRecord.record : pRecord);
                        tmpBCs[0].mediator = pMediator;
                        tmpBCs[0].settings = {
                            showForm: true
                        };
                        tmpBCs[0].engagementAction = null;
                        tmpBCs[0].parents = [];
                    } else {
                        var bcEdit: UdicciBreadcrumb = {
                            record: (pContextRecord && pContextRecord.record ? pContextRecord.record : pRecord),
                            socialSolution: pSolution,
                            mediator: pMediator,
                            settings: {
                                showForm: true
                            },
                            engagementAction: null,
                            parents: []
                        };
                        // console.log('%c UdicciListActions.Edit bcEdit: %O', 'color: blue;', bcEdit);
                        if (pParentRecord && bcEdit.parents) bcEdit.parents.push(pParentRecord);
                        if (!pBreadcrumbs) pBreadcrumbs = [];
                        pBreadcrumbs.push(bcEdit);
                    }
                }

                pBreadcrumbs = tmpBCs;
            }
            // console.log('%c UdicciListActions.Edit pBreadcrumbs: %O', 'color: green;', pBreadcrumbs);
            state.breadcrumbs = pBreadcrumbs;
            // console.log('%c UdicciListActions.Edit state: %O', 'color: blue;', state);
            notifySubscribers(state, payload);
            break;
        }
        case UdicciListActions.Delete.toLowerCase(): {
            // console.log('%c UdicciListActions.Delete pBreadcrumbs: %O', 'color: green;', pBreadcrumbs);
            // console.log('%c UdicciListActions.Delete pRecord: %O', 'color: green;', pRecord);
            if (pRecord) {
                // var socialSolution: any = (pSolution && pSolution.more && pSolution.more.solution ? pSolution.more.solution : null);
                var bcDelete: UdicciBreadcrumb = {
                    record: pRecord,
                    socialSolution: pSolution,
                    mediator: pMediator,
                    settings: {
                        showForm: true,
                        deleteRecord: true
                    },
                    engagementAction: null,
                    parents: []
                };
                // console.log('%c UdicciListActions.Add bcDelete: %O', 'color: blue;', bcDelete);
                if (!pBreadcrumbs) pBreadcrumbs = [];
                let bcCheck: any = find(pBreadcrumbs, (b: any) => {
                    return bcDelete.record && b.record && b.record.recordId === bcDelete.record.recordId
                });
                if (!bcCheck) pBreadcrumbs.push(bcDelete);
            }
            // console.log('%c UdicciListActions.Delete pBreadcrumbs: %O', 'color: green;', pBreadcrumbs);
            state.breadcrumbs = pBreadcrumbs;
            // console.log('%c UdicciListActions.Add state: %O', 'color: blue;', state);
            notifySubscribers(state, payload);
            break;
        }
        case UdicciListActions.Engage.toLowerCase(): {
            // console.log('%c UdicciListActions.Engage pBreadcrumbs: %O', 'color: green;', pBreadcrumbs);
            if (pRecord) {
                // var socialSolution: any = (pSolution && pSolution.more && pSolution.more.solution ? pSolution.more.solution : null);
                var delRec: boolean = (pEngagementAction && pEngagementAction.command === 'delete' ? true : false);
                var bcEngage: UdicciBreadcrumb = {
                    record: pRecord,
                    socialSolution: pSolution,
                    mediator: pMediator,
                    settings: {
                        showForm: true,
                        deleteRecord: delRec
                    },
                    engagementAction: pEngagementAction,
                    parents: []
                };
                if (pParentRecord && bcEngage.parents) bcEngage.parents.push(pParentRecord);
                // console.log('%c UdicciListActions.Engage bcEngage: %O', 'color: blue;', bcEngage);
                if (!pBreadcrumbs) pBreadcrumbs = [];
                pBreadcrumbs.push(bcEngage);
            }
            // console.log('%c UdicciListActions.Engage pBreadcrumbs: %O', 'color: blue;', pBreadcrumbs);
            state.breadcrumbs = pBreadcrumbs;
            // console.log('%c UdicciListActions.Engage state: %O', 'color: blue;', state);
            notifySubscribers(state, payload);
            break;
        }
        default: {
            throw new Error(`Unhandled udicci list action type: ${action.type}`)
        }
    }

    // console.log('%c UdicciListContext udicciListFactory state: %O', 'color: green;', state);
    return state;
}

function UdicciListContextProvider(props: any) {
    // console.log('%c UdicciListContextProvider props: %O', 'color: red;', props);

    let [dataRequested, setDataRequested] = useState<boolean>(false);
    // let [updateState, forceUpdate] = useState<boolean>(false);

    let udicciHelpers = useUdicciHelpers();
    let udicciContext = useContext(UdicciContext);
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    var { data, udicci } = udicciContext.state;
    // console.log('%c UdicciList data: %O', 'color: maroon;', data);
    // console.log('%c UdicciList udicci: %O', 'color: maroon;', udicci);

    var { selectedProfile } = udicci;

    let [state, dispatch] = useReducer(udicciListFactory, defaultContextValue);
    // console.log('%c UdicciListContextProvider state: %O', 'color: red;', state);

    var curMediatorName = (state && state.mediator && state.mediator.name ? state.mediator.name : '');
    // console.log('%c UdicciListContextProvider state.mediator: %O', 'color: red;', state.mediator);

    var propValues: any = (props && props.value ? props.value : null);
    // console.log('%c UdicciListContextProvider propValues: %O', 'color: red;', propValues);

    var propData: UdicciRecord[] | null = (propValues && propValues.data !== undefined ? propValues.data : defaultContextValue.data);
    // console.log('%c UdicciListContextProvider propData: %O', 'color: red;', propData);

    var socialSolution: any | null = (propValues && propValues.socialSolution !== undefined ? propValues.socialSolution : null);
    // console.log('%c UdicciListContextProvider socialSolution: %O', 'color: red;', socialSolution);

    var feature: any | null = (propValues && propValues.feature !== undefined ? propValues.feature : null);
    // console.log('%c UdicciListContextProvider feature: %O', 'color: red;', feature);

    var mediator: any | null = (propValues && propValues.mediator !== undefined ? propValues.mediator : null);
    // console.log('%c UdicciListContextProvider mediator: %O', 'color: red;', mediator);
    var mediatorName: string = (mediator && mediator.name ? mediator.name : '');
    // console.log('%c UdicciListContextProvider mediatorName: %O', 'color: red;', mediatorName);

    let updateUdicciContextHandler = (response: any, request: any, settings: any) => {
        // console.log('%c UdicciListContextProvider updateUdicciContextHandler response: %O', 'color: blue;', response);
        // console.log('%c UdicciListContextProvider updateUdicciContextHandler request: %O', 'color: blue;', request);
        // console.log('%c UdicciListContextProvider updateUdicciContextHandler settings: %O', 'color: blue;', settings);

        // if (request && request.UdicciCommand === 'Fetch List' && (request.UdicciMediatorName === curMediatorName || request.UdicciMediatorName === mediatorName)) {

        let okLetsProcessNow: boolean = true;

        // console.log('%c UdicciListContextProvider updateUdicciContextHandler dataRequested: %O', 'color: blue;', dataRequested);
        // console.log('%c UdicciListContextProvider updateUdicciContextHandler state.mediator: %O', 'color: blue;', state.mediator);
        // console.log('%c UdicciListContextProvider updateUdicciContextHandler curMediatorName: %O', 'color: blue;', curMediatorName);
        // console.log('%c UdicciListContextProvider updateUdicciContextHandler mediatorName: %O', 'color: blue;', mediatorName);
        if (okLetsProcessNow && request && request.UdicciCommand === 'Fetch List' && (request.UdicciMediatorName === curMediatorName || request.UdicciMediatorName === mediatorName)) {
            // console.log('%c UdicciListContextProvider updateUdicciContextHandler response: %O', 'color: red;', response);
            // console.log('%c UdicciListContextProvider updateUdicciContextHandler request: %O', 'color: red;', request);
            // console.log('%c UdicciListContextProvider updateUdicciContextHandler settings: %O', 'color: red;', settings);

            if (state.mediator) {
                subscribers.forEach((sub: AdvancedContextSubscriber) => {
                    // console.log('%c sub: %O', 'color: red;', sub);
                    let subNotes: any = (sub.notes ? sub.notes : null);
                    // console.log('%c subNotes: %O', 'color: teal; font-weight: bold;', subNotes);
                    if (subNotes && subNotes.mediator && subNotes.mediator.name && request && request.UdicciMediatorName) {
                        // console.log('%c subNotes %s: %O', 'color: teal; font-weight: bold;', subNotes.mediator.name, subNotes);
                        // console.log('%c request %s: %O', 'color: teal; font-weight: bold;', request.UdicciMediatorName, request);
                        if (subNotes.mediator.name === request.UdicciMediatorName) {
                            // console.log('%c response: %O', 'color: teal; font-weight: bold;', response);
                            // console.log('%c state: %O', 'color: teal; font-weight: bold;', state);
                            var cb = sub.callback;
                            if (cb) cb(state, response);
                        }
                    } else {
                        // console.log('%c response: %O', 'color: grey;', response);
                        // console.log('%c state: %O', 'color: grey;', state);
                        var cb = sub.callback;
                        if (cb) cb(state, response);
                    }
                });
            }
        
            // if (dataRequested === true) setDataRequested(false);

            // var payloadData: UdicciRecord[] | null = response;
            // var payloadStructure: any = null;
            // var payloadPermissions: any = null;

            // if (data) {
            //     var mediatorContext = data.find((x: any) => x.mediator === (curMediatorName ? curMediatorName : mediatorName) );
            //     if (mediatorContext && mediatorContext.records) payloadData = mediatorContext.records;
            //     if (mediatorContext && mediatorContext.structure) payloadStructure = mediatorContext.structure;
            //     if (mediatorContext && mediatorContext.permissions) payloadPermissions = mediatorContext.permissions;
            // }

            // if (payloadData && payloadData.length > 1) {
            //     // default sort uses the last modified date and then the date created if modified date not set
            //     // console.log('%c UdicciListContextProvider payloadData: %O', 'color: blue;', payloadData);
            //     payloadData.sort(function(a: any, b: any){
            //         var ad = (a.data ? a.data : null);
            //         var bd = (b.data ? b.data : null);
            //         var leftValue = (ad.dtModifiedDate ? ad.dtModifiedDate : ad.dtDateCreated);
            //         var rightValue = (bd.dtModifiedDate ? bd.dtModifiedDate : bd.dtDateCreated);
            //         if (leftValue < rightValue) return -1;
            //         if (leftValue > rightValue) return 1;
            //         return 0;
            //     });
            //     payloadData.reverse();
            //     // console.log('%c UdicciListContextProvider payloadData sorted: %O', 'color: blue;', payloadData);
            // }

            // var curDate = new Date();
            // var dispatchParams = {
            //     type: UdicciListActions.Refresh,
            //     payload: {
            //         data: payloadData,
            //         structure: payloadStructure,
            //         permissions: payloadPermissions,
            //         request: request,
            //         lastFetchResultDate: curDate
            //     }
            // };
            // // console.log('%c UdicciListContextProvider updateUdicciContextHandler dispatchParams: %O', 'color: blue;', dispatchParams);
            // // dispatch(dispatchParams);
            // setTimeout(() => { dispatch(dispatchParams); }, 250);
        }
    };

    var doNotPreloadData: any = (props && props.doNotPreloadData === true ? true : false);
    // console.log('%c UdicciListContextProvider doNotPreloadData: %O', 'color: red;', doNotPreloadData);

    // var propKey: any = (props && props.contextKey ? props.contextKey : '');
    // console.log('%c UdicciListContextProvider propKey: %O', 'color: red;', propKey);

    // TESTING A THEORY
    // subscribeToUdicci(propKey + '.provider.', updateUdicciContextHandler);

    var curMediator = (state && state.mediator ? state.mediator : null);
    // console.log('%c UdicciListContextProvider curMediator: %O', 'color: red;', curMediator);
    var curSocialSolution = (state && state.socialSolution ? state.socialSolution : null);
    // console.log('%c UdicciListContextProvider curSocialSolution: %O', 'color: red;', curSocialSolution);
    var curFeature = (state && state.feature ? state.feature : null);
    // console.log('%c UdicciListContextProvider curFeature: %O', 'color: red;', curFeature);
    var curStructure = (curMediator && curMediator.structure ? curMediator.structure : null);
    // console.log('%c UdicciListContextProvider curStructure: %O', 'color: red;', curStructure);
    var curPermissions = (curMediator && curMediator.permissions ? curMediator.permissions : null);
    // console.log('%contextLevelsc UdicciListContextProvider curPermissions: %O', 'color: red;', curPermissions);

    var curSettings = (state && state.settings ? state.settings : null);
    // console.log('%c UdicciListContextProvider curSettings: %O', 'color: red;', curSettings);
    var curBreadcrumbs = (state && state.breadcrumbs ? state.breadcrumbs : null);
    // console.log('%c UdicciListContextProvider curBreadcrumbs: %O', 'color: red;', curBreadcrumbs);
    var lastFetchResultDate = (state && state.lastFetchResultDate ? state.lastFetchResultDate : null);
    // console.log('%c UdicciListContextProvider lastFetchResultDate: %O', 'color: red;', lastFetchResultDate);

    var record: any | null = (propValues && propValues.record !== undefined ? propValues.record : null);
    // console.log('%c UdicciListContextProvider record: %O', 'color: red;', record);

    var structure: any | null = (mediator && mediator.structure !== undefined ? mediator.structure : null);
    // console.log('%c UdicciListContextProvider structure: %O', 'color: red;', structure);

    var permissions: any | null = (mediator && mediator.permissions !== undefined ? mediator.permissions : null);
    // console.log('%c UdicciListContextProvider permissions: %O', 'color: red;', permissions);

    var listFields: any | null = (propValues && propValues.listFields !== undefined ? propValues.listFields : null);
    // console.log('%c UdicciListContextProvider listFields: %O', 'color: red;', listFields);

    var settings: any | null = (propValues && propValues.settings !== undefined ? propValues.settings : null);
    // console.log('%c UdicciListContextProvider settings: %O', 'color: red;', settings);

    var breadcrumbs: UdicciBreadcrumb[] | null = (propValues && propValues.breadcrumbs !== undefined ? propValues.breadcrumbs : null);
    // console.log('%c UdicciListContextProvider breadcrumbs: %O', 'color: red;', breadcrumbs);

    var contextValues: UdicciListContextStateType = {
        uid: 'udicci.list.' + udicciHelpers.generateUID(),
        data: defaultContextValue.data,
        socialSolution: defaultContextValue.socialSolution,
        feature: defaultContextValue.feature,
        mediator: defaultContextValue.mediator,
        settings: (settings ? settings : defaultContextValue.settings),
        breadcrumbs: defaultContextValue.breadcrumbs,
        record: record,
        onLoadBreadcrumb: (breadcrumb: any) => loadBreadcrumb(breadcrumb),
        onCloseBreadcrumb: () => closeBreadcrumb(),
        onCloseAllBreadcrumbs: () => closeAllBreadcrumbs(),
        onRefresh: (forceRefresh: boolean) => refreshList(forceRefresh),
        onAddRecord: (structure: any, permissions: any, mediator: any) => addNewRecord(structure, permissions, mediator),
        onEditRecord: (record: UdicciRecord) => editRecord(record),
        onEditContextRecord: (contextRecord: any) => editContextRecord(contextRecord),
        onDeleteRecord: (record: UdicciRecord) => deleteRecord(record),
        onEngageRecord: (record: UdicciRecord, engagementAction: any) => engageRecord(record, engagementAction),
        lastFetchResultDate: null
    };
    // console.log('%c UdicciListContextProvider contextValues: %O', 'color: red;', contextValues);

    let refreshList = async (forceRefresh: boolean) => {
        // console.log('%c refreshList forceRefresh: %O', 'color: maroon;', forceRefresh);
        // console.log('%c refreshList dataRequested: %O', 'color: maroon;', dataRequested);
        // console.log('%c refreshList curMediatorName: %O', 'color: maroon;', curMediatorName);
        // console.log('%c refreshList mediatorName: %O', 'color: maroon;', mediatorName);
        // console.log('%c refreshList socialSolution: %O', 'color: maroon;', socialSolution);
        // console.log('%c refreshList curSocialSolution: %O', 'color: maroon;', curSocialSolution);
        // console.log('%c refreshList settings: %O', 'color: maroon;', settings);
        let socialSolutionId: number = 0;
        if (curSocialSolution && curSocialSolution.id) {
            socialSolutionId = curSocialSolution.id;
        }
        if (socialSolution && socialSolution.id) {
            socialSolutionId = socialSolution.id;
        }

        let requestSettings: any = null;
        if (settings) {
            requestSettings = {};
            // if (options.settings && options.settings.TopXRecords !== undefined) request.TopXRecords = options.settings.TopXRecords;
            // if (options.settings && options.settings.PageSize !== undefined) request.PageSize = options.settings.PageSize;
            // if (options.settings && options.settings.PageNumber !== undefined) request.PageNumber = options.settings.PageNumber;
            // if (options.settings && options.settings.SortField !== undefined) request.SortField = options.settings.SortField;
            // if (options.settings && options.settings.SortDirection !== undefined) request.SortDirection = options.settings.SortDirection;
            if (settings.paging) {
                if (settings.paging.topXRecords !== undefined && settings.paging.topXRecords > 0) requestSettings.TopXRecords = settings.paging.topXRecords;
                if (settings.paging.pageSize !== undefined) {
                    requestSettings.PageSize = settings.paging.pageSize;
                    requestSettings.PageNumber = 1;
                }
            }
            if (settings.sort) {
                if (settings.sort.field !== undefined) requestSettings.SortField = settings.sort.field;
                if (settings.sort.order !== undefined) requestSettings.SortDirection = settings.sort.order;
            }
        }
        // console.log('%c refreshList requestSettings: %O', 'color: maroon;', requestSettings);

        if ((!dataRequested || forceRefresh) && curMediatorName) {
            // setTimeout(() => { setDataRequested(true); }, 50);
            getUdicciDataAsync({ mediator: curMediatorName, socialSolutionId: socialSolutionId, settings: requestSettings });
            setDataRequested(true);
        } else if ((!dataRequested || forceRefresh) && mediatorName) {
            // setTimeout(() => { setDataRequested(true); }, 250);
            getUdicciDataAsync({ mediator: mediatorName, socialSolutionId: socialSolutionId, settings: requestSettings });
            setDataRequested(true);
            // console.log('%c refreshList dataRequested: %O', 'color: black;', dataRequested);
        }
        // var dispatchParams = {
        //     type: UdicciListActions.Refresh,
        //     payload: { record: null, engagementAction: null }
        // };
        // console.log('%c UdicciListContextProvider engageRecord dispatchParams: %O', 'color: blue;', dispatchParams);
        // dispatch(dispatchParams);
    }

    let addNewRecord = (structure: any, permissions: any, mediator: any) => {
        // console.log('%c UdicciListContextProvider addNewRecord structure: %O', 'color: red;', structure);
        // console.log('%c UdicciListContextProvider addNewRecord permissions: %O', 'color: red;', permissions);
        // console.log('%c UdicciListContextProvider addNewRecord mediator: %O', 'color: red;', mediator);
        // console.log('%c UdicciListContextProvider addNewRecord curMediator: %O', 'color: red;', curMediator);
        // console.log('%c UdicciListContextProvider addNewRecord permissions: %O', 'color: red;', permissions);
        // var newRec = new UdicciRecord(curMediatorName, null, curStructure, curPermissions);
        var dispatchParams = {
            type: UdicciListActions.Add,
            payload: {
                // record: newRec,
                structure: (structure ? structure : curStructure),
                permissions: permissions,
                mediator: mediator
            }
        };
        // console.log('%c UdicciListContextProvider addNewRecord dispatchParams: %O', 'color: red;', dispatchParams);
        dispatch(dispatchParams);
    }

    let editRecord = (record: UdicciRecord) => {
        // console.log('%c UdicciListContextProvider editRecord record: %O', 'color: blue;', record);
        // console.log('%c UdicciListContextProvider editRecord curStructure: %O', 'color: blue;', curStructure);
        // console.log('%c UdicciListContextProvider editRecord curPermissions: %O', 'color: blue;', curPermissions);
        // console.log('%c UdicciListContextProvider editRecord propData: %O', 'color: blue;', propData);
        // let recordIndex: number = -1;
        // if (propData && record) {
        //     recordIndex = findIndex(propData, (rec: UdicciRecord) => { return rec.recordId === record.recordId });
        // }
        // console.log('%c UdicciListContextProvider editRecord recordIndex: %O', 'color: blue;', recordIndex);
        var dispatchParams = {
            type: UdicciListActions.Edit,
            payload: {
                record: record,
                structure: curStructure,
                permissions: curPermissions
            }
        };
        // console.log('%c UdicciListContextProvider editRecord dispatchParams: %O', 'color: red;', dispatchParams);
        dispatch(dispatchParams);
    }

    let editContextRecord = (contextRecord: any) => {
        // console.log('%c UdicciListContextProvider editContextRecord contextRecord: %O', 'color: blue;', contextRecord);
        var dispatchParams = {
            type: UdicciListActions.Edit,
            payload: {
                record: contextRecord.record,
                contextRecord: contextRecord,
                structure: curStructure,
                permissions: curPermissions
            }
        };
        // console.log('%c UdicciListContextProvider editRecord dispatchParams: %O', 'color: red;', dispatchParams);
        dispatch(dispatchParams);
    }

    let deleteRecord = (record: UdicciRecord) => {
        // console.log('%c UdicciListContextProvider deleteRecord record: %O', 'color: blue;', record);
        var dispatchParams = {
            type: UdicciListActions.Delete,
            payload: {
                record: record,
                structure: curStructure,
                permissions: curPermissions
            }
        };
        // console.log('%c UdicciListContextProvider deleteRecord dispatchParams: %O', 'color: red;', dispatchParams);
        dispatch(dispatchParams);
    }

    let engageRecord = (record: UdicciRecord, engagementAction: any) => {
        // console.log('%c UdicciListContextProvider engageRecord record: %O', 'color: blue;', record);
        // console.log('%c UdicciListContextProvider engageRecord engagementAction: %O', 'color: blue;', engagementAction);
        var dispatchParams = {
            type: UdicciListActions.Engage,
            payload: {
                record: record,
                engagementAction: engagementAction,
                structure: curStructure,
                permissions: curPermissions
            }
        };
        // console.log('%c UdicciListContextProvider engageRecord dispatchParams: %O', 'color: red;', dispatchParams);
        dispatch(dispatchParams);
    }

    let closeBreadcrumb = () => {
        var dispatchParams = {
            type: UdicciListActions.CloseBreadcrumb,
            payload: {
                record: null,
                breadcrumbs: null
            }
        };
        // console.log('%c UdicciListContextProvider closeBreadcrumb dispatchParams: %O', 'color: red;', dispatchParams);
        dispatch(dispatchParams);
    }

    let closeAllBreadcrumbs = () => {
        var dispatchParams = {
            type: UdicciListActions.CloseAllBreadcrumbs,
            payload: {
                record: null,
                breadcrumbs: null
            }
        };
        // console.log('%c UdicciListContextProvider closeBreadcrumb dispatchParams: %O', 'color: red;', dispatchParams);
        dispatch(dispatchParams);
    }

    let loadBreadcrumb = (breadcrumb: any) => {
        // console.log('%c loadBreadcrumb curBreadcrumbs: %O', 'color: maroon;', curBreadcrumbs);
        // console.log('%c loadBreadcrumb breadcrumb: %O', 'color: maroon;', breadcrumb);

        let idx: number = findIndex(curBreadcrumbs, (bc: any) => {
            return bc.record.recordId === breadcrumb.record.recordId;
        });
        if (idx >= 0) {
            curBreadcrumbs.splice(idx + 1);
            // console.log('%c loadBreadcrumb curBreadcrumbs: %O', 'color: maroon;', curBreadcrumbs);

            let newLastBreadcrumb: any = last(curBreadcrumbs);
            // console.log('%c loadBreadcrumb newLastBreadcrumb: %O', 'color: maroon;', newLastBreadcrumb);

            var dispatchParams = {
                type: UdicciListActions.LoadBreadcrumb,
                payload: {
                    record: newLastBreadcrumb.record,
                    breadcrumbs: curBreadcrumbs
                }
            };
            // console.log('%c loadBreadcrumb dispatchParams: %O', 'color: red;', dispatchParams);
            dispatch(dispatchParams);
        } else {
            closeAllBreadcrumbs();
        }
    }

    if (!contextValues.data) contextValues.data = propData;
    if (!contextValues.socialSolution && socialSolution) contextValues.socialSolution = socialSolution;
    if (!contextValues.feature && feature) contextValues.feature = feature;
    if (!contextValues.mediator && mediator) contextValues.mediator = mediator;
    if (!contextValues.mediator.structure && structure) contextValues.mediator.structure = structure;
    if (!contextValues.mediator.permissions && permissions) contextValues.mediator.permissions = permissions;
    if ((!contextValues.settings || (contextValues.settings && !contextValues.settings.listFields)) && listFields) {
        if (!contextValues.settings) contextValues.settings = {};
        contextValues.settings.listFields = listFields;
    }
    // console.log('%c UdicciListContextProvider contextValues: %O', 'color: red;', contextValues);
    if ((!contextValues.settings || (contextValues.settings && !contextValues.settings.listFields)) && feature) {
        // get list fields from feature settings
        // console.log('%c UdicciListContextProvider feature: %O', 'color: red;', feature);
        if (feature.more) {
            let ftreMedListSettings: any = null;
            var ftreMediators: any | null = (feature.more.Mediators !== undefined ? feature.more.Mediators : null);
            // console.log('%c UdicciListContextProvider ftreMediators: %O', 'color: red;', ftreMediators);
            if (ftreMediators && ftreMediators.length > 0) {
                var curFtreMediator = ftreMediators.find((x: any) => x.name === mediatorName );
                // console.log('%c UdicciListContextProvider curFtreMediator: %O', 'color: red;', curFtreMediator);
                if (curFtreMediator && curFtreMediator.features && curFtreMediator.features.length > 0) {
                    var medFtreSetting = curFtreMediator.features.find((x: any) => x.id === feature.id );
                    // console.log('%c UdicciListContextProvider medFtreSetting: %O', 'color: red;', medFtreSetting);
                    if (medFtreSetting && medFtreSetting.list) ftreMedListSettings = medFtreSetting.list;
                }
            }
            // console.log('%c UdicciListContextProvider ftreMedListSettings: %O', 'color: red;', ftreMedListSettings);
            let ftreMedListFields: any = null;
            if (ftreMedListSettings && ftreMedListSettings.fields) {
                ftreMedListFields = ftreMedListSettings.fields;
            }
            // console.log('%c UdicciListContextProvider ftreMedListFields: %O', 'color: red;', ftreMedListFields);
            if (ftreMedListFields) {
                if (!contextValues.settings) contextValues.settings = {};
                contextValues.settings.listFields = ftreMedListFields;
            }
        }
    }
    if (!contextValues.breadcrumbs && breadcrumbs) contextValues.breadcrumbs = breadcrumbs;

    var listData: any = null;
    if (contextValues && contextValues.data) listData = contextValues.data;
    if (data && mediator) {
        var mediatorContext = data.find((x: any) => x.mediator === curMediatorName );
        if (mediatorContext && mediatorContext.records) listData = mediatorContext.records;
    }
    // console.log('%c listData: %O', 'color: maroon;', listData);

    // console.log('%c selectedProfile: %O', 'color: maroon;', selectedProfile);
    // console.log('%c mediator: %O', 'color: maroon;', mediator);
    // console.log('%c listData: %O', 'color: maroon;', listData);
    // console.log('%c lastFetchResultDate: %O', 'color: maroon;', lastFetchResultDate);
    // console.log('%c doNotPreloadData: %O', 'color: maroon;', doNotPreloadData);
    if (!dataRequested && selectedProfile && mediator && (((!listData || !lastFetchResultDate) && !doNotPreloadData))) {
        refreshList(false);
    }

    if (!curSettings && defaultContextValue.settings) curSettings = defaultContextValue.settings;
    if (!curSettings) curSettings = {};
    curSettings.dataRequested = dataRequested;
    curSettings.onAddRecord = (() => addNewRecord(curStructure, curPermissions, curMediator));
    curSettings.onLoadBreadcrumb = loadBreadcrumb;
    curSettings.onCloseBreadcrumb = closeBreadcrumb;
    curSettings.onCloseAllBreadcrumbs = closeAllBreadcrumbs;

    if (contextValues && curSettings && curSettings.settings) {
        if (!contextValues.settings) contextValues.settings = {};
        Object.assign(contextValues.settings, curSettings.settings);
    }
    // console.log('%c UdicciListContext.Provider contextValues: %O', 'color: red;', contextValues);

    let value = { state: contextValues, dispatch };
    // console.log('%c UdicciListContext.Provider value: %O', 'color: red;', value);
    return (
        <UdicciListContext.Provider value={value}>
            {props.children}
        </UdicciListContext.Provider>
    );
}

function useUdicciListContext() {
    let context = useContext(UdicciListContext)
    if (context === undefined) {
        throw new Error('useUdicciListContext must be used within a UdicciListContextProvider');
    }
    return context
}

export {UdicciListContextProvider, useUdicciListContext, UdicciListActions}
