import { useCallback, useEffect, useState } from 'react';

export default function useLongText(textValue: string, initialState: boolean = false, trimmedLength: number = 77, overflowAtOneLine: boolean | undefined = false) {
    const [value, setValue] = useState<string>((textValue ? textValue : ''));
    const [showTrimmed, setShowTrimmed] = useState<boolean>(initialState);
    const [showLength, setShowLength] = useState<number>(trimmedLength);

    useEffect(() => {
        setValue(textValue);
    }, [textValue]);

    useEffect(() => {
        setShowLength(trimmedLength);
    }, [trimmedLength]);

    useEffect(() => {
        setShowTrimmed(initialState);
    }, [initialState]);

    const more = useCallback(() => {
        // console.log('%c useLongText more value.length: %O', 'color: red;', value.length);
        // console.log('%c useLongText more showLength: %O', 'color: red;', showLength);
        // console.log('%c useLongText more showTrimmed: %O', 'color: red;', showTrimmed);
        setShowTrimmed(true);
    }, []);

    const less = useCallback(() => {
        setShowTrimmed(false);
    }, []);

    const getNumberOfLines = () => {
        let rval: number = 0;
        var lines: string[] = getLines();
        if (lines.length > 0) {
            lines.forEach((item: string) => { if (item.length > 0) rval++; });
        } else if (value.length > 0) {
            rval++;
        }
        return rval;
    };
    const getLines = () => {
        var lines: string[] = [];
        var workingString: string = value.toString();
        if (workingString && workingString.indexOf('\n') > 0) {
            lines = workingString.split('\n');
        } else {
            lines.push(workingString);
        }
        return lines;
    };
    const getLineOne = () => {
        var lines: string[] = getLines();
        if (lines.length > 0) {
            return lines[0];
        } else {
            return value;
        }
    };
    const canBeExpanded = () => {
        let rval: boolean = false;
        let numberOfLines: number = getNumberOfLines();
        if (value.length > showLength || numberOfLines > 1) {
            rval = true;
        }
        return rval;
    };
    const getTrimmedValue = () => {
        let rval: string = value;
        // console.log('%c getTrimmedValue rval: %O', 'color: blue;', rval);
        let numberOfLines: number = getNumberOfLines();
        // console.log('%c getTrimmedValue numberOfLines: %O', 'color: blue;', numberOfLines);
        if (rval.length > showLength || numberOfLines > 1) {
            if (numberOfLines > 1) {
                rval = getLineOne() + ' ...';
            } else if (rval.length > showLength) {
                rval = rval.substring(0, showLength - 1) + ' ...';
            }
        }
        // console.log('%c getTrimmedValue rval: %O', 'color: green;', rval);
        return rval;
    };
    const isExpanded = () => { return !showTrimmed };

    let displayValue: any = '';
    // console.log('%c useLongText value.length: %O', 'color: red;', value.length);
    // console.log('%c useLongText showLength: %O', 'color: red;', showLength);
    if (value && value.length > showLength) {
        // console.log('%c useLongText showTrimmed: %O', 'color: red;', showTrimmed);
        if (showTrimmed) {
            displayValue = getTrimmedValue();
        } else {
            displayValue = value;
        }
    } else {
        displayValue = value;
    }
    // console.log('%c useLongText displayValue.length: %O', 'color: red;', displayValue.length);
    // console.log('%c useLongText displayValue: %O', 'color: red;', displayValue);

    return {
        more, less, canBeExpanded, getNumberOfLines, getTrimmedValue, getLines, isExpanded, setShowTrimmed,
        displayValue: displayValue
    };
}

/**
 * 
 * Usage:
 * 
 * displayValue (trimmed/not-trimmed value)
 * more
 * less
 * canBeExpanded()
 * isExpanded()
 * 
 */
