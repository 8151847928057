import UrlParser from 'url-parse';

import UdicciSplashImage from 'src/images/Udicci 100.png';
import KDDisposalSplashImage from 'src/images/kddisposal.com.png';
// import CTNSSplashImage from 'src/images/ctnschool.org.png';
import OGRSplashImage from 'src/images/outlookglassrepair.com.png'
import UdicciTvSplashImage from 'src/images/udicci.tv.png'
import REBESplashImage from 'src/images/rebe.png'

import { Box } from '@mui/material';

export default function PortalSplash() {
    let url: any = UrlParser(window.location.href, true);
    let origin: string = (url && url.origin ? url.origin : '');
    // console.log('%c origin: %O', 'color: purple; font-weight: bold;', origin);
    let profileUrl: string = (url && url.pathname ? url.pathname : '');
    // console.log('%c profileUrl: %O', 'color: purple; font-weight: bold;', profileUrl);

    let containerSettings = { textAlign: 'center', background: '#fff', maxWidth: '-webkit-fill-available', height: '100%' };
    let imageSettings = { src: '', style: { maxWidth: '-webkit-fill-available', marginLeft: 'auto', marginRight: 'auto' } };

    // if (origin.indexOf('canaltownnurseryschool.org') > 0
    //          || origin.indexOf('canaltownnurseryschool.com') > 0 
    //          || origin.indexOf('canaltown.org') > 0 
    //          || origin.indexOf('ctnschool.org') > 0 
    //          || profileUrl === '/ctns'
    //    ) {
    //     imageSettings.width = '40%';
    //     imageSettings.src = CTNSSplashImage;
    //     containerSettings.background = "#ffff00";
    // }

    if (origin.indexOf('kddisposal.com') > 0 || profileUrl === '/kddisposal') {
        // imageSettings.width = '25%';
        imageSettings.src = KDDisposalSplashImage;
        containerSettings.background = "#263860";
    }

    if (origin.indexOf('outlookglassrepair.com') > 0 || origin.indexOf('outlookglassrepairs.com') > 0 || profileUrl === '/ogr') {
        imageSettings.src = OGRSplashImage;
        containerSettings.background = "#000";
    }

    if (origin.indexOf('udicci.tv') > 0 || profileUrl === '/udiccitv') {
        // imageSettings.width = '70%';
        imageSettings.src = UdicciTvSplashImage;
        containerSettings.background = "#171717";
    }

    if (origin.indexOf('timmaslyn.com') > 0 || profileUrl === '/tim') {
        // imageSettings.width = '25%';
        containerSettings.background = "#00557f";
    }

    // if (origin.indexOf('rebe.com') > 0 || profileUrl === '/rebe') {
    //     // imageSettings.width = '25%';
    //     imageSettings.src = REBESplashImage;
    //     containerSettings.background = "#fff";
    // }

    if (!imageSettings.src) {
        imageSettings.src = UdicciSplashImage;
        imageSettings.src = UdicciSplashImage;
    }

    // console.log('%c profileUrl: %O', 'color: purple; font-weight: bold;', profileUrl);
    return ( <Box sx={containerSettings}> <img {...imageSettings} /> </Box> );
}
