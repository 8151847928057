import { useState, useEffect } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { LocalizationProvider } from '@mui/x-date-pickers';

import { UdicciProvider } from 'src/context/udicci-context'

import UrlParser from 'url-parse';

import Portal from './portal';

declare module '@mui/material/styles' {
    // if (!portaThemePaletteSettings.text) portaThemePaletteSettings.text = { primary: buttonInfoColorValue, secondary: buttonSecondaryColorValue };
    // if (!portaThemePaletteSettings.cancelButton) portaThemePaletteSettings.cancelButton = { main: buttonInfoColorValue };
    // if (!portaThemePaletteSettings.loginButton) portaThemePaletteSettings.loginButton = { main: buttonSecondaryColorValue };
    // if (!portaThemePaletteSettings.saveButton) portaThemePaletteSettings.saveButton = { main: buttonSuccessColorValue};
    // if (!portaThemePaletteSettings.editButton) portaThemePaletteSettings.editButton = { main: buttonPrimaryColorValue};
    // if (!portaThemePaletteSettings.deleteButton) portaThemePaletteSettings.deleteButton = { main: textSecondaryColor};
    // if (!portaThemePaletteSettings.moveButton) portaThemePaletteSettings.moveButton = { main: buttonInfoColorValue};
    // if (!portaThemePaletteSettings.engagementButton1) portaThemePaletteSettings.engagementButton1 = { main: buttonInfoColorValue};
    // if (!portaThemePaletteSettings.engagementButton2) portaThemePaletteSettings.engagementButton2 = { main: buttonInfoColorValue};
    // if (!portaThemePaletteSettings.engagementButton3) portaThemePaletteSettings.engagementButton3 = { main: buttonInfoColorValue};
    // if (!portaThemePaletteSettings.portalMenuIcon) portaThemePaletteSettings.portalMenuIcon = { main: textSecondaryColor};
    // if (!portaThemePaletteSettings.platformMenuIcon) portaThemePaletteSettings.platformMenuIcon = { main: textSecondaryColor};
    interface TypographyVariants {
        leftFieldLabel: React.CSSProperties;
        overFieldLabel: React.CSSProperties;
        errorMessage: React.CSSProperties;
        clickable: React.CSSProperties;
        clickableSubTitle1: React.CSSProperties;
        clickableSubTitle2: React.CSSProperties;
        helpText: React.CSSProperties;
        portalName: React.CSSProperties;
        roleName: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        leftFieldLabel?: React.CSSProperties;
        overFieldLabel?: React.CSSProperties;
        errorMessage?: React.CSSProperties;
        clickable?: React.CSSProperties;
        clickableSubTitle1?: React.CSSProperties;
        clickableSubTitle2?: React.CSSProperties;
        helpText?: React.CSSProperties;
        portalName?: React.CSSProperties;
        roleName?: React.CSSProperties;
    }

    interface Palette {
        cancelButton: Palette['primary'];
        loginButton: Palette['primary'];
        portalMenuIcon: Palette['primary'];
        platformMenuIcon: Palette['primary'];
        saveButton?: Palette['primary'];
        editButton?: Palette['primary'];
        deleteButton?: Palette['primary'];
        moveButton?: Palette['primary'];
        engagementButton1: Palette['primary'];
        engagementButton2: Palette['primary'];
        engagementButton3: Palette['primary'];
    }
    interface PaletteOptions {
        cancelButton: PaletteOptions['primary'];
        loginButton: PaletteOptions['primary'];
        portalMenuIcon: PaletteOptions['primary'];
        platformMenuIcon: PaletteOptions['primary'];
        saveButton?: PaletteOptions['primary'];
        editButton?: PaletteOptions['primary'];
        deleteButton?: PaletteOptions['primary'];
        moveButton?: PaletteOptions['primary'];
        engagementButton1: PaletteOptions['primary'];
        engagementButton2: PaletteOptions['primary'];
        engagementButton3: PaletteOptions['primary'];
    }
}
  
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        leftFieldLabel: true;
        overFieldLabel: true;
        errorMessage: true;
        clickable: true;
        clickableSubTitle1: true;
        clickableSubTitle2: true;
        helpText: true;
        portalName: true;
        roleName: true;
        button: false;
    }
}
    
// Update the Typography's variant prop options
declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        loginButton: true;
        cancelButton: true;
        saveButton: true;
        editButton: true;
        deleteButton: true;
        moveButton: true;
        engagementButton1: true;
        engagementButton2: true;
        engagementButton3: true;
        portalMenuIcon: true;
        platformMenuIcon: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        loginButton: true;
        cancelButton: true;
        saveButton: true;
        editButton: true;
        deleteButton: true;
        moveButton: true;
        engagementButton1: true;
        engagementButton2: true;
        engagementButton3: true;
        portalMenuIcon: true;
        platformMenuIcon: true;
    }
}

export default function UdicciApp() {
    // data includes history, location, match and staticContext
    let currentUrl = window.location.href;
    let url = UrlParser(currentUrl, true);
    // console.log('%c UdicciApp url: %O', 'color: maroon;', url);

    let { query, href } = url;
    // console.log('%c UdicciApp pathname: %O', 'color: purple;', pathname);
    // console.log('%c UdicciApp hash: %O', 'color: purple;', hash);
    // console.log('%c UdicciApp query: %O', 'color: purple;', query);
    // console.log('%c UdicciApp href: %O', 'color: purple;', href);

    const [refreshState, forceUpdate] = useState<any>(null);

    useEffect(() => {
        // console.log('%c UdicciApp useEffect href: %O', 'color: blue;', href);
        return () => { forceUpdate(!refreshState); };
    }, [href]);

    let { appId, authToken, redirectUrl, udicciInstruction, udicciPortal } = query;
    // console.log('%c UdicciApp appId: %O', 'color: purple;', appId);
    // console.log('%c UdicciApp authToken: %O', 'color: purple;', authToken);
    // console.log('%c UdicciApp redirectUrl: %O', 'color: purple;', redirectUrl);
    // console.log('%c UdicciApp referrer: %O', 'color: purple;', referrer);
    // console.log('%c UdicciApp udicciInstruction: %O', 'color: purple;', udicciInstruction);
    // console.log('%c UdicciApp udicciPortal: %O', 'color: purple;', udicciPortal);

    if (redirectUrl && udicciPortal) {
        // console.log('%c UdicciApp redirectUrl: %O', 'color: purple;', redirectUrl);
        // console.log('%c UdicciApp udicciPortal: %O', 'color: purple;', udicciPortal);
        let compareUrl = redirectUrl + udicciPortal + (udicciInstruction ? '#' + udicciInstruction : '');
        if (href !== compareUrl) {
            // console.log('%c UdicciApp compareUrl: %O', 'color: blue;', compareUrl);
            // console.log('%c UdicciApp href: %O', 'color: blue;', href);

            // var handCashOptions: any = {
            //     appId: appId,
            //     authToken: authToken,
            //     udicciPortal: udicciPortal,
            //     redirectUrl: redirectUrl,
            //     udicciInstruction: udicciInstruction,
            //     referrer: 'tim.maslyn' // HandCash Handle
            // };
            // // console.log('%c UdicciApp handCashOptions: %O', 'color: maroon;', handCashOptions);

            // var userHandCashFromStore = sessionStorage.getItem('udicci.user.handCash');

            // var userHandCash = null;
            // if (userHandCashFromStore) {
            //     userHandCash = JSON.parse(userHandCashFromStore);
            // }
            // if (!userHandCash) userHandCash = {};
            // // console.log('%c UdicciApp userHandCash: %O', 'color: red;', userHandCash);

            // userHandCash.handCashOptions = handCashOptions;

            // userHandCashFromStore = JSON.stringify(userHandCash);
            // sessionStorage.setItem('udicci.user.handCash', userHandCashFromStore);

            window.open(compareUrl, '_self');
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <UdicciProvider>
                <Portal />
            </UdicciProvider>
        </LocalizationProvider>
    );
    // return ( <UdicciProvider> <Portal {...data} /> </UdicciProvider> );
}
