// import * as filestack from 'filestack-js';
// import { UdicciPlugin } from 'src/classes/udicci-types';
import { UdicciPlugin } from 'src/classes/udicci-types';

// The background plugin
// import background, { ModeEnum } from '@react-page/plugins-background';
// import css as well. currently, we caannot do this here in the demo project and have moved that to _app.tsx
// see https://github.com/vercel/next.js/issues/19717
// import '@react-page/plugins-background/lib/index.css';

// The divider plugin
// import divider from '@react-page/plugins-divider';

// The html5-video plugin
// import html5video from '@react-page/plugins-html5-video';
// import '@react-page/plugins-html5-video/lib/index.css';

// The image plugin
// import { imagePlugin, ImageUploadType } from '@react-page/plugins-image';
// import '@react-page/plugins-image/lib/index.css';

// The spacer plugin
// import spacer from '@react-page/plugins-spacer';
// import '@react-page/plugins-spacer/lib/index.css';

// The video plugin
// import video from '@react-page/plugins-video';
// import '@react-page/plugins-video/lib/index.css';

// import { defaultSlate, customizedSlate } from './slate';
// import '@react-page/plugins-slate/lib/index.css';

// The rich text area plugin
// import slate from '@react-page/plugins-slate';

// import customContentPlugin from './customContentPlugin';
// import customContentPluginWithListField from './customContentPluginWithListField';
// import customLayoutPlugin from './customLayoutPlugin';
// import customLayoutPluginWithInitialState from './customLayoutPluginWithInitialState';
// import customContentPluginTwitter from './customContentPluginTwitter';
// import codeSnippet from './code-snippet';
import loginPlugin from 'src/plugins/IT/login';
import changePasswordPlugin from 'src/plugins/IT/change-password';
import engageFormPlugin from 'src/plugins/IT/engage-form';
import onboardingPlugin from 'src/plugins/IT/onboarding';
import userTimeoutPlugin from 'src/plugins/IT/udicci-timer';
import udicciListPlugin from 'src/plugins/IT/udicci-list';
import relationshipsListPlugin from 'src/plugins/IT/relationships-list';
import socialSolutionMenuPlugin from 'src/plugins/IT/social-solutions-menu';
import manageProfileRolesPlugin from 'src/plugins/IT/manage-profile-roles';
import configureProfileSettingsPlugin from 'src/plugins/IT/configure-profile-settings';
import portalExternalProvidersPlugin from 'src/plugins/IT/portal-external-providers';
import configureContactCardSettingsPlugin from 'src/plugins/IT/configure-contact-card-settings';
import configureProfileSolutionsPlugin from 'src/plugins/IT/configure-portal-permissions';
import configureSettingsFormPlugin from 'src/plugins/IT/configure-settings-form';
import profileCardPlugin from 'src/plugins/IT/profile-card';
import contextViewPlugin from 'src/plugins/IT/udicci-context-view';
import recordDisplayPlugin from 'src/plugins/IT/udicci-single-record';
import portalConfigPlugin from 'src/plugins/IT/portal-config';
import ctnsPlugin from 'src/plugins/IT/ctns';
import classRegistrationsProcessorPlugin from 'src/plugins/IT/class-registrations-processor';
import udicciImagePlugin from 'src/plugins/IT/udicci-image';
import udicciDraftPlugin from 'src/plugins/IT/udicci-draft';
import chainStoryPlugin from 'src/plugins/IT/chain-story';
// import { UdicciFileStackApiKey } from 'src/classes/udicci-factory';
import udicciNavigationPlugin from './IT/udicci-navigation';
import udicciActivityFeedPlugin from './IT/udicci-activity-feed';
import mediaLibraryPlugin from './IT/media-library';
import udicciMessagingPlugin from './IT/udicci-messaging';
import udicciTvPlugin from './IT/udicci-tv';
import videoIndexingPlugin from './IT/video-indexing';
import paymentFormPlugin from './IT/payment-form';
import udicciDashboardPlugin from './IT/udicci-dashboard';
import gridContainerPlugin from './IT/grid-container';
import marqueeContainerPlugin from './IT/udicci-marquee';
import weekDisplayPlugin from './IT/udicci-week-display';
import paneSectionPlugin from './IT/udicci-pane-section';
import udicciOpenAISimpleStartPlugin from './IT/udicci-openai-simple-start';
import udicciHabitTrackerPlugin from './IT/udicci-habit-tracker';
import udicciDecisionMakerPlugin from './IT/udicci-decision-maker';

// const filestackClient = filestack.init(UdicciFileStackApiKey);

// const imageUpload: (url: string) => ImageUploadType = (
//   defaultUrl
// ) => (file: any, reportProgress: any) => {
//   return new Promise((resolve, reject) => {
//     // console.log('%c imageUpload file: %O', 'color: red;', file);
//     // console.log('%c imageUpload reportProgress: %O', 'color: red;', reportProgress);

//     filestackClient.upload(file)
//         .then(res => {
//             // console.log('%c imageUpload res: %O', 'color: red;', res);
//             let imageUrl = (res && res.url ? res.url : defaultUrl);
//             resolve({ url: imageUrl });
//         })
//         .catch(err => {
//             // console.log('%c imageUpload err: %O', 'color: red;', err);
//         });
//   });
// };

export const udicciPluginComponents: UdicciPlugin[] = [
    // slate(),
    // imagePlugin({ imageUpload: imageUpload('') }) as UdicciPlugin,
    udicciImagePlugin as UdicciPlugin,
    udicciDraftPlugin as UdicciPlugin,
    gridContainerPlugin as UdicciPlugin,
    marqueeContainerPlugin as UdicciPlugin,
    paneSectionPlugin as UdicciPlugin,
    weekDisplayPlugin as UdicciPlugin,
    socialSolutionMenuPlugin as UdicciPlugin,
    profileCardPlugin as UdicciPlugin,
    relationshipsListPlugin as UdicciPlugin,
    udicciListPlugin as UdicciPlugin,
    contextViewPlugin as UdicciPlugin,
    recordDisplayPlugin as UdicciPlugin,
    chainStoryPlugin as UdicciPlugin,
    videoIndexingPlugin as UdicciPlugin,
    udicciNavigationPlugin as UdicciPlugin,
    engageFormPlugin as UdicciPlugin,
    onboardingPlugin as UdicciPlugin,
    portalConfigPlugin as UdicciPlugin,
    loginPlugin as UdicciPlugin,
    manageProfileRolesPlugin as UdicciPlugin,
    configureProfileSolutionsPlugin as UdicciPlugin,
    configureSettingsFormPlugin as UdicciPlugin,
    configureProfileSettingsPlugin as UdicciPlugin,
    portalExternalProvidersPlugin as UdicciPlugin,
    configureContactCardSettingsPlugin as UdicciPlugin,
    udicciActivityFeedPlugin as UdicciPlugin,
    mediaLibraryPlugin as UdicciPlugin,
    changePasswordPlugin as UdicciPlugin,
    userTimeoutPlugin as UdicciPlugin,
    udicciMessagingPlugin as UdicciPlugin,
    udicciTvPlugin as UdicciPlugin,
    paymentFormPlugin as UdicciPlugin,
    udicciDashboardPlugin as UdicciPlugin,
    udicciOpenAISimpleStartPlugin as UdicciPlugin,
    udicciHabitTrackerPlugin as UdicciPlugin,
    udicciDecisionMakerPlugin as UdicciPlugin,
    ctnsPlugin as UdicciPlugin,
    classRegistrationsProcessorPlugin as UdicciPlugin,
    // divider as UdicciPlugin,
    // background({
    //     enabledModes: ModeEnum.COLOR_MODE_FLAG | ModeEnum.GRADIENT_MODE_FLAG
    // }) as UdicciPlugin,

    // spacer as UdicciPlugin,
    // video as UdicciPlugin,
    // html5video as UdicciPlugin,
    // codeSnippet as UdicciPlugin,
];
