
import UrlParser from 'url-parse';

import { find, values } from 'underscore';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { checkConditionAgainstRecord } from 'src/classes/udicci-record';

// import { udicciStyles } from 'src/theme/shared-styles';

export const GoToProfileMenu: React.FC<any> = ({ record, engagementAction, onClick }) => {
    // console.log('%c GoToProfileMenu record: %O', 'color: purple;', record);
    // console.log('%c GoToProfileMenu engagementAction: %O', 'color: purple;', engagementAction);
    // const theme = useTheme();
    // const udicciClasses = udicciStyles(theme);

    let udicciMediator: string = (record.udicciMediator ? record.udicciMediator : '');
    // console.log('%c GoToProfileMenu udicciMediator: %O', 'color: maroon;', udicciMediator);
    let permissions: any = (record.permissions ? record.permissions : null);
    // console.log('%c GoToProfileMenu permissions: %O', 'color: maroon;', permissions);

    let eaAccess = (engagementAction.access ? engagementAction.access : null);
    // console.log('%c eaAccess: %O', 'color: blue;', eaAccess);
    let eaMediators = (eaAccess && eaAccess.mediators ? eaAccess.mediators : []);
    // console.log('%c eaMediators: %O', 'color: blue;', eaMediators);

    const triggerEngagementAction = (ea: any) => {
        // console.log('%c ea: %O', 'color: blue;', ea);
        if (onClick) onClick(ea);
    };

    let mediatorActions = find(values(eaMediators), function(m: any) {
        return m.name === udicciMediator;
    });
    // console.log('%c mediatorActions: %O', 'color: blue;', mediatorActions);
    let requiredPermissions = (mediatorActions && mediatorActions.requiredPermissions ? mediatorActions.requiredPermissions : null);
    // console.log('%c requiredPermissions: %O', 'color: blue;', requiredPermissions);

    let allowed = (mediatorActions !== undefined ? true : false);
    if (requiredPermissions && requiredPermissions.length > 0) {
        allowed = false;
        let fp = requiredPermissions[0];
        // console.log('%c fp: %O', 'color: blue;', fp);
        let permValue = permissions[fp.permission];
        // console.log('%c permValue: %O', 'color: blue;', permValue);
        if (fp.required && permValue === true) allowed = true;
        if (!fp.required) allowed = true;
    }
    // console.log('%c allowed: %O', 'color: blue;', allowed);

    let rval: any = null;
    if (allowed) {
        // console.log('%c engagementAction: %O', 'color: blue;', engagementAction);
        let conditionalOverride = (engagementAction && engagementAction.conditionalOverride ? engagementAction.conditionalOverride : null);
        // console.log('%c conditionalOverride: %O', 'color: maroon;', conditionalOverride);

        let conditionalLabelText: string = '';
        if (conditionalOverride && conditionalOverride.action) {
            let conditionWhen = (conditionalOverride.when ? conditionalOverride.when : null);
            // console.log('%c conditionWhen: %O', 'color: maroon;', conditionWhen);
            let conditionLabel: any = (conditionalOverride.label ? conditionalOverride.label : null);
            // console.log('%c conditionLabel: %O', 'color: maroon;', conditionLabel);
            let conditionMet = checkConditionAgainstRecord(record, conditionWhen);
            // console.log('%c conditionMet: %O', 'color: maroon;', conditionMet);
            if (conditionMet) {
                conditionalLabelText = (conditionLabel && conditionLabel.text ? conditionLabel.text : '');
            }
        }
        // console.log('%c engagementAction: %O', 'color: purple;', engagementAction);
    
        let enagementMenuText = '';
        if (conditionalLabelText) {
            enagementMenuText = conditionalLabelText;
        } else if (engagementAction && engagementAction.label && engagementAction.label.text) {
            enagementMenuText = engagementAction.label.text;
        } else if (engagementAction && engagementAction.name) {
            enagementMenuText = engagementAction.name;
        } else {
            enagementMenuText = engagementAction.action;
        }

        let redirectUrl: string = '';
        if (record && record.udicciMediator) {
            if (record.udicciMediator === 'People') {
                let addtnlInfo: any = null;
                if (record.data && record.data.jsonAdditionalInformation) {
                    addtnlInfo = record.data.jsonAdditionalInformation;
                }
                // console.log('%c addtnlInfo: %O', 'color: purple;', addtnlInfo);
                let meProfile: any = (addtnlInfo && addtnlInfo.meProfile ? addtnlInfo.meProfile : null);
                // console.log('%c meProfile: %O', 'color: purple;', meProfile);
                if (meProfile && meProfile.profileUrl) {
                    let currentUrl = window.location.href;
                    let url = UrlParser(currentUrl, true);
                    // console.log('%c GoToProfileMenu url: %O', 'color: blue;', url);
                    let hostUrl: string = url.href;
                    if (url.href.indexOf('://localhost') < 0 && url.href.indexOf('://dev.udicci.com') < 0) {
                        hostUrl = 'https://udicci.me';
                    }

                    redirectUrl = hostUrl + meProfile.profileUrl;
                }
            }
        }
        // console.log('%c GoToProfileUI redirectUrl: %O', 'color: purple;', redirectUrl);

        let buttonDisabled: boolean = (redirectUrl ? false : true);
        // console.log('%c GoToProfileUI buttonDisabled: %O', 'color: red;', buttonDisabled);
        rval = (
            <Button size="small"
                    onClick={(evt: any) => triggerEngagementAction(engagementAction)}
                    variant="text"
                    disabled={buttonDisabled}
            >
                <Typography variant="body2" component="span">
                    {enagementMenuText}
                </Typography>
                <Typography variant="body2" component="span">
                    .
                </Typography>
            </Button>
        );
    }

    return rval;
};
