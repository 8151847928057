
import { find, values } from 'underscore';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// import { udicciStyles } from 'src/theme/shared-styles';

export const CreateProfileMenu: React.FC<any> = (props) => {
    // console.log('%c CreateProfileMenu props: %O', 'color: purple;', props);
    // const theme = useTheme();
    // const udicciClasses = udicciStyles(theme);

    let udicciMediator: string = (props && props.udicciMediator ? props.udicciMediator : '');
    // console.log('%c CreateProfileMenu udicciMediator: %O', 'color: maroon;', udicciMediator);
    let permissions: any = (props && props.permissions ? props.permissions : null);
    // console.log('%c CreateProfileMenu permissions: %O', 'color: maroon;', permissions);

    let engagedAction: any | null = (props && props.engagementAction ? props.engagementAction : null);
    // console.log('%c CreateProfileMenu engagedAction: %O', 'color: maroon;', engagedAction);
    let eaAccess = (engagedAction.access ? engagedAction.access : null);
    // console.log('%c eaAccess: %O', 'color: blue;', eaAccess);
    let eaMediators = (eaAccess && eaAccess.mediators ? eaAccess.mediators : []);
    // console.log('%c eaMediators: %O', 'color: blue;', eaMediators);

    const triggerEngagementAction = (engagedAction: any) => {
        let onClick: any = (props && props.onClick ? props.onClick : '');
        if (onClick) onClick(engagedAction);
    };

    let mediatorActions = find(values(eaMediators), function(m: any) {
        return m.name === udicciMediator;
    });
    // console.log('%c mediatorActions: %O', 'color: blue;', mediatorActions);
    let requiredPermissions = (mediatorActions && mediatorActions.requiredPermissions ? mediatorActions.requiredPermissions : null);
    // console.log('%c requiredPermissions: %O', 'color: blue;', requiredPermissions);

    let allowed = (mediatorActions !== undefined ? true : false);
    if (requiredPermissions && requiredPermissions.length > 0) {
        allowed = false;
        let fp = requiredPermissions[0];
        // console.log('%c fp: %O', 'color: blue;', fp);
        let permValue = permissions[fp.permission];
        // console.log('%c permValue: %O', 'color: blue;', permValue);
        if (fp.required && permValue === true) allowed = true;
        if (!fp.required) allowed = true;
    }
    // console.log('%c allowed: %O', 'color: blue;', allowed);

    let rval: any = null;
    if (allowed) {
        // console.log('%c engagedAction: %O', 'color: blue;', engagedAction);
        let enagementMenuText = '';
        if (engagedAction && engagedAction.label && engagedAction.label.text) {
            enagementMenuText = engagedAction.label.text;
        } else if (engagedAction && engagedAction.name) {
            enagementMenuText = engagedAction.name;
        } else {
            enagementMenuText = engagedAction.action;
        }
        rval = (
            <Button size="small"
                    onClick={(evt: any) => triggerEngagementAction(engagedAction)}
                    variant="text"
            >
                <Typography variant="body2" component="span">
                    {enagementMenuText}
                </Typography>
                <Typography variant="body2" component="span">
                    .
                </Typography>
            </Button>
        );
    }

    return rval;
};
