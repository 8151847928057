
import React from 'react';

import { Box, Typography } from '@mui/material';

import { getEngagementActionPackages } from 'src/components/engagement-actions/engagement-actions';

const ActionExchange: React.FC<any> = (props) => {
    // console.log('%c engagementActionPackages props: %O', 'color: maroon;', props);

    let engagementAction: any = (props && props.engagementAction ? props.engagementAction : null);
    // console.log('%c ActionExchange engagementAction: %O', 'color: maroon;', engagementAction);

    var engagementActionPackages: any = getEngagementActionPackages(props);
    // console.log('%c ActionExchange engagementActionPackages: %O', 'color: maroon;', engagementActionPackages);

    let command: any = null;
    if (engagementAction && engagementAction.command) {
        if (engagementActionPackages && engagementActionPackages[engagementAction.command]) {
            command = engagementActionPackages[engagementAction.command];
        }
    }
    // console.log('%c ActionExchange command: %O', 'color: purple;', command);

    let actionElement: any = (command && command.ui ? command.ui : null);

    return (
        <Box>
            <Typography variant="subtitle2" component="div">Action Exchange</Typography>
            {actionElement}
        </Box>
    );
}

export default ActionExchange;
