import { useState } from 'react';

import Typography from '@mui/material/Typography';
import { useUdicciContext, logoff, userTimedOut } from 'src/context/udicci-context'
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

const UserTimeoutDisplay: React.FC<any> = (props: any) => {
    // console.log('%c UserTimeoutDisplay props: %O', 'color: red;', props);
    const udicciContext = useUdicciContext();
    const udicciHelpers = useUdicciHelpers();

    let { udicci } = udicciContext.state;
    let { currentUser } = udicci;
    let curUserId: number = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);

    let { expectedTimeout, totalTimeLeft } = (currentUser ? udicciHelpers.getUserEstimatedTimeRemainingInSession(currentUser, userTimedOut) : { expectedTimeout: null, totalTimeLeft: ""});
    const [currentTimeLeft, setTotalTimeLeft] = useState<string>(totalTimeLeft);

    setTimeout((args: any) => {
        let minLeft: number = 0;
        let secLeft: number = 0;
        let dt = new Date();

        let updatedMessage: string = '';
        if (expectedTimeout && expectedTimeout.getTime() < dt.getTime()) {
            //logoff();
            updatedMessage = 'Time Up!';
            udicci.userTimedOut(expectedTimeout);
        } else if (expectedTimeout) {
            var dffrnc = Math.abs(expectedTimeout.getTime() - dt.getTime()) / 1000;
            minLeft = Math.floor(dffrnc / 60) % 60;
            secLeft = Math.floor(dffrnc % 60);
            updatedMessage = (minLeft ? minLeft.toString() + ':' : '') + (secLeft < 10 ? '0' + secLeft.toString() : secLeft.toString());
        }
        setTotalTimeLeft(updatedMessage);
    }, 1000);

    let rval: any = null;
    if (curUserId > 0) {
        if (totalTimeLeft) {
            rval = (
                <Typography component="span" variant="inherit">
                    {currentTimeLeft}
                </Typography>
            );
        }
    }

    return rval;
}

export default UserTimeoutDisplay;
