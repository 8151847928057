import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/HowToReg';

const UdicciImage = dynamic(() => import('src/components/udicci-image'));
const UdicciImageAdmin = dynamic(() => import('src/admin/udicci-image-admin'));

const udicciImagePlugin: UdicciPlugin = {
    Renderer: (data: any) => ( <UdicciImage {...data} /> ),
    id: 'udicciImage',
    title: 'Image',
    description: "Display an Image with some configurable options.  Images can be uploaded through our FileStack File Hosting Cloud solution's API implementation, or shared using a URL.",
    version: 1,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <UdicciImageAdmin {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c udicciImagePlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c udicciImagePlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
     },
    category: UdicciPluginCategory.Content
};
export default udicciImagePlugin;