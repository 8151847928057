import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/AddToQueue';

import { useUdicciContext } from 'src/context/udicci-context'

const ConfigureProfileSettings = dynamic(() => import('src/components/configure-profile-settings'));
const ConfigureContactCardSettings = dynamic(() => import('src/components/configure-contact-card-settings'));
const PortalConfigAdmin = dynamic(() => import('src/admin/portal-config-admin'));

const PluginId: string = 'portal.configuration';
const PluginTitle: string = 'Portal Configuration';
const PluginDescription: string = 'Display the Configuration Settings for a Portal.';
const PluginVersion: number = 1;

const PluginRenderer = (props: any) => {
    // console.log('%c PluginRenderer props: %O', 'color: red;', props);
    if (!props.data) props.data = {};
    props.data.showInline = true;

    const udicciContext = useUdicciContext();
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    let { udicci } = udicciContext.state;
    // console.log('%c Porta udicci: %O', 'color: maroon;', udicci);

    let { selectedProfile } = udicci;
    // console.log('%c Porta selectedProfile: %O', 'color: green;', selectedProfile);

    // console.log('%c PluginRenderer props.data: %O', 'color: red;', props.data);
    return (
        <div>
            <ConfigureProfileSettings {...props} configuration={selectedProfile?.data} />
            {/* <ConfigureContactCardSettings {...props} configuration={selectedProfile?.data} /> */}
        </div>
    );
};

const PluginAdminRenderer = (props: any) => {
    // console.log('%c PluginAdminRenderer props: %O', 'color: red;', props);
    if (!props.data) props.data = {};
    props.data.showInline = true;
    // console.log('%c PluginAdminRenderer props.data: %O', 'color: red;', props.data);
    return ( <PortalConfigAdmin {...props} /> );
};

const PluginComponent: UdicciPlugin = {
    Renderer: PluginRenderer,
    id: PluginId,
    title: PluginTitle,
    description: PluginDescription,
    version: PluginVersion,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: PluginAdminRenderer },
    hideInMenu: true,
    canPick: (props: any) => {
        // console.log('%c PortalConfiguration canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c PortalConfiguration canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
    },
    category: UdicciPluginCategory.Admin
};
export default PluginComponent;