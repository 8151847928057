import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/Timelapse';

const UserTimeout = dynamic(() => import('src/components/user-timeout-display'));
const UdicciTimerAdmin = dynamic(() => import('src/admin/udicci-timer-admin'));

const userTimeoutPlugin: UdicciPlugin = {
    Renderer: (data: any) => ( <UserTimeout {...data} /> ),
    id: 'udicci.user.timeout',
    title: 'User Timeout Countdown',
    description: 'Display the User Timeout Countdown component which is displayed when a user is logged in, and shows how long before the user session times out.',
    version: 1,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <UdicciTimerAdmin {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c UserTimeout canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c UserTimeout canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
     },
    category: UdicciPluginCategory.Special
};
export default userTimeoutPlugin;