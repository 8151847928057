import { useEffect, useState } from 'react';

export default function useUdicciConstants(defaultUrlIndex: number = 2) {
    const USER_MAX_TIMEOUT_IN_MINUTES: number = 49; // minutes

    const UlyssesProfileId: number = 2647;
    const UlyssesUserId: number = 49;
    const DefaultSocialIcon: string = 'https://cdn.filestackcontent.com/oQKubvMT3eAMz1MYbb5G';

    // 1 Byte = 1 Satoshi.
    const BSV_BASE_COST_PER_BYTE: number = 1000;  // how many Satoshis per UDC
    const BSV_PER_100_BYTES: number = 100000;  // how many UDCs to write 100 bytes of data

    const SOCIALSOLUTION_UDICCI_IT_ID: number = 163903; // Udicci.IT Social Solution Id
    const SOCIALSOLUTION_DEFAULT_ME_ID: number = 163905; // .Me Social Solution Id
    const DEFAULT_SOCIALSOLUTION_NAME: string = '.Me'; // Default Social Solution Name

    const StringFields: string[] = ['String'];
    const TextAreaFields: string[] = ['Text','Html'];
    const NumberFields: string[] = ['Integer','Decimal','Currency'];
    const CodeFields: string[] = ['Json','Html Code','Javascript Code','Css Class'];
    const DateFields: string[] = ['DateTime'];

    const localAPIUrl: string = process.env.REACT_APP_UDICCI_PROD_API_URL;
    const developmentAPIUrl: string = process.env.REACT_APP_UDICCI_DEV_API_URL;
    const productionAPIUrl: string = process.env.REACT_APP_UDICCI_PROD_API_URL;

    var API_URL: string = '';

    function changeAPI(urlIndex: number = 2) {
        switch (urlIndex) {
            case 1:
                API_URL = localAPIUrl;
                break;
            case 2:
                API_URL = developmentAPIUrl;
                break;
            case 3:
                API_URL = productionAPIUrl;
                break;
        }
        API_URL += 'udicci/mediate';
    }

    return {
        API_URL,
        USER_MAX_TIMEOUT_IN_MINUTES,
        changeAPI,
        UlyssesProfileId,
        UlyssesUserId,
        DefaultSocialIcon,
        BSV_BASE_COST_PER_BYTE,
        BSV_PER_100_BYTES,
        SOCIALSOLUTION_UDICCI_IT_ID,
        SOCIALSOLUTION_DEFAULT_ME_ID,
        DEFAULT_SOCIALSOLUTION_NAME,
        StringFields,
        TextAreaFields,
        NumberFields,
        CodeFields,
        DateFields,
    };
}

/**
 * 
 * Usage:
 * 
 */
