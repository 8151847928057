import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/Timelapse';

const UdicciHabitTracker = dynamic(() => import('src/components/udicci-habit-tracker'));
const UdicciHabitTrackerAdmin = dynamic(() => import('src/admin/udicci-habit-tracker-admin'));

const UdicciHabitTrackerPlugin: UdicciPlugin = {
    Renderer: (data: any) => ( <UdicciHabitTracker {...data} /> ),
    id: 'udicci.habit.tracker',
    title: 'Udicci Habit Tracker',
    description: 'Display the Udicci Habit Tracker component which allows for users to setup and track their habits.',
    version: 1,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <UdicciHabitTrackerAdmin {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c UdicciHabitTracker canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c UdicciHabitTracker canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
     },
    category: UdicciPluginCategory.Development
};
export default UdicciHabitTrackerPlugin;