
import React, { useState } from 'react';

import { Box, Paper, Divider, Typography, TextField, FormControlLabel, Checkbox, Button } from '@mui/material';

const MediatorForm: React.FC<any> = (props) => {
    // console.log('%c MediatorForm props: %O', 'color: red;', props);

    let { mediator, structure, onClose, onChange } = props;
    let mediatorId = (mediator && mediator.UdicciMediatorId ? mediator.UdicciMediatorId : 0);
    // console.log('%c mediatorId: %O', 'color: red;', mediatorId);

    const [showFullMediatorForm, setShowFullMediatorForm] = useState<boolean>(true);
    // const [editMediator, setEditMediator] = useState<any>(mediator);
    const [uiState, flashUI] = useState<boolean>(false);

    const onFieldValueChanged = (fieldName: string, evt: any) => {
        // console.log('%c onFieldValueChanged mediator: %O', 'color: maroon;', mediator);
        // console.log('%c onFieldValueChanged fieldName: %O', 'color: maroon;', fieldName);
        let trgt: any = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c onFieldValueChanged newValue: %O', 'color: maroon;', newValue);

        mediator[fieldName] = newValue;

        mediator.isDirty = true;
        if (onChange) onChange(mediator);
        // setEditMediator(mediator);
        flashUI(!uiState);
    };

    let nameElementDisabled: boolean = (!showFullMediatorForm || mediatorId > 0 ? true : false);
    let nameElement: any = (
        <Box>
            <Typography variant="overFieldLabel">Mediator Name</Typography>
            <TextField fullWidth
                       disabled={nameElementDisabled}
                       value={mediator.Name}
                       onChange={(evt: any) => onFieldValueChanged('Name', evt)}
                       margin="none"
                       variant='outlined'
            />
        </Box>
    );

    let overviewElement: any = null;
    if (showFullMediatorForm) {
        overviewElement = (
            <Box>
                <Typography variant="overFieldLabel">Mediator Overview</Typography>
                <TextField fullWidth multiline maxRows={7} minRows={3}
                            value={mediator.Overview}
                            onChange={(evt: any) => onFieldValueChanged('Overview', evt)}
                            margin="none"
                            variant='outlined'
                />
            </Box>
        );
    }

    let systemMediatorElement: any = null;
    if (showFullMediatorForm) {
        systemMediatorElement = (
            <FormControlLabel
                label={(<Typography variant="overFieldLabel">System Mediator</Typography>)}
                control={
                    <Checkbox checked={mediator.SystemMediator}
                              onChange={(evt: any) => onFieldValueChanged('SystemMediator', evt)}
                              color="primary"
                    />
                }
            />
        );
    }

    let generateDatabaseCodeElement: any = null;
    if (showFullMediatorForm) {
        generateDatabaseCodeElement = (
            <FormControlLabel
                label={(<Typography variant="overFieldLabel">Generate Database Code</Typography>)}
                control={
                    <Checkbox checked={mediator.GenerateDatabaseCode}
                              onChange={(evt: any) => onFieldValueChanged('GenerateDatabaseCode', evt)}
                              color="primary"
                    />
                }
            />
        );
    }

    let generateServerSideCodeElement: any = null;
    if (showFullMediatorForm) {
        generateServerSideCodeElement = (
            <FormControlLabel
                label={(<Typography variant="overFieldLabel">Generate Server Side Code</Typography>)}
                control={
                    <Checkbox checked={mediator.GenerateServerSideCode}
                            onChange={(evt: any) => onFieldValueChanged('GenerateServerSideCode', evt)}
                            color="primary"
                    />
                }
            />
        );
    }

    let generateClientSideCodeElement: any = null;
    if (showFullMediatorForm) {
        generateClientSideCodeElement = (
            <FormControlLabel
                label={(<Typography variant="overFieldLabel">Generate Client Side Code</Typography>)}
                control={
                    <Checkbox checked={mediator.GenerateClientSideCode}
                            onChange={(evt: any) => onFieldValueChanged('GenerateClientSideCode', evt)}
                            color="primary"
                    />
                }
            />
        );
    }

    let isDirty: boolean = (mediator && mediator.isDirty ? true : false);
    let formButtonsElement: any = (
        <Box sx={{ margin: '8px' }}>
            <Button onClick={(evt: any) => onClose()} variant="contained" color="info" sx={{ margin: '4px' }}>
                Cancel
            </Button>
            <Button onClick={(evt: any) => onClose()} variant="contained" color="success" disabled={!isDirty} sx={{ margin: '4px' }}>
                Save Changes
            </Button>
        </Box>
    );

    return (
        <Box>
            <Box>
                {nameElement}
                {overviewElement}
                {generateDatabaseCodeElement}
                {generateServerSideCodeElement}
                {generateClientSideCodeElement}
                {systemMediatorElement}
            </Box>
            {formButtonsElement}
            {/* <Divider />
            <Paper>
                {mediatorFieldsSection}
                {linkedMediatorsSection}
                {compareMediatorsSection}
            </Paper> */}
        </Box>
    );
}

export default MediatorForm;
