import { Fragment, useState } from 'react';

import { forEach } from 'underscore';

// import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';

import ViewOnIcon from '@mui/icons-material/Visibility';
// import ViewOffIcon from '@mui/icons-material/VisibilityOff';
import AddOnIcon from '@mui/icons-material/Add';
// import AddOffIcon from '@mui/icons-material/Add';
import EditOnIcon from '@mui/icons-material/Edit';
// import EditLimitedOnIcon from '@mui/icons-material/Edit';
// import EditOffIcon from '@mui/icons-material/Edit';
import DeleteOnIcon from '@mui/icons-material/Delete';
// import DeleteLimitedOnIcon from '@mui/icons-material/Delete';
// import DeleteOffIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RemovePermissionIcon from '@mui/icons-material/DeleteForever';

import { UdicciPermissionsInitialized } from 'src/hooks/useUdicciHelpers';
import useUdicciSocialSolution from 'src/hooks/useUdicciSocialSolution';

export default function UdicciPermissionsSetter(props: any) {
    // console.log('%c UdicciPermissionsSetter props: %O', 'color: red;', props);

    var { solution, title, permissions, onPermissionChanged } = props;
    // console.log('%c UdicciPermissionsSetter socialSolution %s: %O', 'color: red;', socialSolution.title, socialSolution);
    // console.log('%c UdicciPermissionsSetter title: %O', 'color: red;', title);
    // console.log('%c UdicciPermissionsSetter permissions: %O', 'color: red;', permissions);

    // const udicciHelpers = useUdicciHelpers();
    const udicciSocialSolution = useUdicciSocialSolution(solution);
    var { socialSolution, socialSolutionId, getSocialSolutionSetting } = udicciSocialSolution;
    // console.log('%c UdicciPermissionsSetter socialSolution: %O', 'color: red;', socialSolution);

    const [showAdvanced, toggleAdvanced] = useState<boolean>(false);

    const changePermission = (perm: string) => {
        // console.log('%c changePermission perm: %O', 'color: red;', perm);
        onPermissionChanged({
            socialSolutionId: socialSolutionId,
            permission: perm,
            remove: false,
        })
    }

    const removePermission = () => {
        onPermissionChanged({ socialSolutionId: socialSolutionId, remove: true })
    }

    const changeFeatureMediatorPermission = (feature: any, mediator: any, perm: string) => {
        // console.log('%c changeFeatureMediatorPermission feature: %O', 'color: red;', feature);
        // console.log('%c changeFeatureMediatorPermission mediator: %O', 'color: red;', mediator);
        // console.log('%c changeFeatureMediatorPermission perm: %O', 'color: red;', perm);
        onPermissionChanged({
            socialSolutionId: socialSolutionId,
            feature: feature,
            mediator: mediator,
            permission: perm,
            remove: false,
        })
    }

    // var socialSolutionFeatures: any = getSocialSolutionSetting({ setting: 'features' });
    // console.log('%c UdicciPermissionsSetter socialSolutionFeatures: %O', 'color: maroon;', socialSolutionFeatures);
    var socialSolutionMediators: any = getSocialSolutionSetting({ setting: 'mediators' });
    // console.log('%c UdicciPermissionsSetter socialSolutionMediators: %O', 'color: maroon;', socialSolutionMediators);

    var ssData: any = (socialSolution && socialSolution.data ? socialSolution.data : null);
    var solutionFeatures: any = (ssData && ssData.Features ? ssData.Features : null);
    // console.log('%c UdicciPermissionsSetter solutionFeatures: %O', 'color: red;', solutionFeatures);

    var permissionsSet: boolean = true;
    if (!permissions) {
        permissionsSet = false;
        permissions = {};
        Object.assign(permissions, UdicciPermissionsInitialized);
    }

    var { CanView, CanAdd, CanEdit, CanDelete, overrides } = permissions;

    var viewButtonElement = (
        <IconButton aria-label="View" size="small"
                    color={(!permissionsSet ? "default" : (CanView ? "success" : "warning"))}
                    onClick={(evt: any) => changePermission('view')}
        >
            <ViewOnIcon />
        </IconButton>
    );
    var addButtonElement = (
        <IconButton aria-label="Add" size="small"
                    color={(!permissionsSet ? "default" : (CanAdd ? "success" : "warning"))}
                    onClick={(evt: any) => changePermission('add')}
        >
            <AddOnIcon />
        </IconButton>
    );
    var editButtonElement = (
        <IconButton aria-label="Edit" size="small"
                    color={(!permissionsSet ? "default" : (CanEdit ? "success" : "warning"))}
                    onClick={(evt: any) => changePermission('edit')}
        >
            <EditOnIcon />
        </IconButton>
    );
    var deleteButtonElement = (
        <IconButton aria-label="Delete" size="small"
                    color={(!permissionsSet ? "default" : (CanDelete ? "success" : "warning"))}
                    onClick={(evt: any) => changePermission('delete')}
        >
            <DeleteOnIcon />
        </IconButton>
    );

    var showAdvancedIconElement: any = null;
    var showAdvancedElement: any = null;
    if (showAdvanced) {
        showAdvancedIconElement = (
            <IconButton aria-label="close advanced" size="small"
                        color={"default"}
                        onClick={(evt: any) => toggleAdvanced(false)}
            >
                <ExpandLessIcon />
            </IconButton>
        );

        var ssFeatureElements: any[] = [];
        // console.log('%c UdicciPermissionsSetter solutionFeatures: %O', 'color: red;', solutionFeatures);
        if (solutionFeatures && solutionFeatures.length > 0) {
            // console.log('%c UdicciPermissionsSetter solutionFeatures: %O', 'color: blue;', solutionFeatures);
            forEach(solutionFeatures, (ftre: any) => {
                // console.log('%c ftre: %O', 'color: red; font-weight: bold;', ftre);
                // console.log('%c recordId: %O', 'color: hotpink;', recordId);

                // var ssFtreSetting: any = null;
                // if (socialSolutionFeatures) {
                //     ssFtreSetting = find(socialSolutionFeatures, (ssf: any) => { return ssf.name === ftre.Name });
                // }
                // console.log('%c ssFtreSetting: %O', 'color: red;', ssFtreSetting);

                var ssFeatureMediatorElements: any[] = [];
                if (socialSolutionMediators) {
                    forEach(socialSolutionMediators, (med: any) => {
                        // console.log('%c med: %O', 'color: red; font-weight: bold;', med);
    
                        var okToContinue: boolean = false;
                        if (med.feature && med.feature === ftre.Name) okToContinue = true;
                        if (med.features && med.features.indexOf(ftre.Name) >= 0) okToContinue = true;

                        if (okToContinue) {
                            // var fmp: any = getMediatorPermissions({ feature: ftre, mediator: med.name })
                            var fmp: any = (overrides && overrides[med.name] ? overrides[med.name] : null);
                            // console.log('%c featureMediatorPermissions: %O', 'color: red; font-weight: bold;', fmp);

                            var mediatorPermissionsSet: boolean = true;
                            if (!fmp) {
                                mediatorPermissionsSet = false;
                                fmp = {};
                                Object.assign(fmp, UdicciPermissionsInitialized);
                            }
                            // var { CanView, CanAdd, CanEdit, CanDelete } = fmp;
                            var CanViewMediator: boolean = (fmp && fmp.CanView !== undefined ? fmp.CanView : false);
                            var CanAddMediator: boolean = (fmp && fmp.CanAdd !== undefined ? fmp.CanAdd : false);
                            var CanEditMediator: boolean = (fmp && fmp.CanEdit !== undefined ? fmp.CanEdit : false);
                            var CanDeleteMediator: boolean = (fmp && fmp.CanDelete !== undefined ? fmp.CanDelete : false);

                            var viewMediatorButtonElement = (
                                <IconButton aria-label="View" size="small"
                                            color={(!mediatorPermissionsSet ? "default" : (CanViewMediator ? "success" : "warning"))}
                                            onClick={(evt: any) => changeFeatureMediatorPermission(ftre, med, 'view')}
                                >
                                    <ViewOnIcon />
                                </IconButton>
                            );
                            var addMediatorButtonElement = (
                                <IconButton aria-label="Add" size="small"
                                            color={(!mediatorPermissionsSet ? "default" : (CanAddMediator ? "success" : "warning"))}
                                            onClick={(evt: any) => changeFeatureMediatorPermission(ftre, med, 'add')}
                                >
                                    <AddOnIcon />
                                </IconButton>
                            );
                            var editMediatorButtonElement = (
                                <IconButton aria-label="Edit" size="small"
                                            color={(!mediatorPermissionsSet ? "default" : (CanEditMediator ? "success" : "warning"))}
                                            onClick={(evt: any) => changeFeatureMediatorPermission(ftre, med, 'edit')}
                                >
                                    <EditOnIcon />
                                </IconButton>
                            );
                            var deleteMediatorButtonElement = (
                                <IconButton aria-label="Delete" size="small"
                                            color={(!mediatorPermissionsSet ? "default" : (CanDeleteMediator ? "success" : "warning"))}
                                            onClick={(evt: any) => changeFeatureMediatorPermission(ftre, med, 'delete')}
                                >
                                    <DeleteOnIcon />
                                </IconButton>
                            );

                            var featureMediatorKey = 'feature.mediator.' + med.id.toString();
                            ssFeatureMediatorElements.push(
                                <Box key={featureMediatorKey} sx={{ display: 'flex' }}>
                                    <Container component="span" sx={{ display: 'flex', pt: 0.5, pl: 0.5, pr: 0.5 }}>
                                        <Typography variant="caption" component="div">
                                            {med.name}
                                        </Typography>
                                    </Container>
                                    <Container component="span" sx={{ display: 'flex', pt: 0, pl: 0.5, pr: 0 }}>
                                        {viewMediatorButtonElement}
                                        {addMediatorButtonElement}
                                        {editMediatorButtonElement}
                                        {deleteMediatorButtonElement}
                                    </Container>
                                </Box>
                            );
                        }
                    });
                }
    
                if (ssFeatureMediatorElements.length > 0) {
                    // var fp: any = getFeaturePermissions({ feature: ftre })
                    // console.log('%c featurePermissions: %O', 'color: red; font-weight: bold;', fp);

                    var socialSolutionFeatureCardKey = 'feature.card.' + ftre.UdicciRecordId.toString();
                    ssFeatureElements.push(
                        <Box key={socialSolutionFeatureCardKey} sx={{ display: 'block', width: '100%', pl: 1 }}>
                            <Typography variant="caption" component="div">
                                {ftre.Name}
                            </Typography>
                            {ssFeatureMediatorElements}
                        </Box>
                    );
                }
            });
        }

        showAdvancedElement = (
            <Box sx={{ display: 'block' }}>
                {ssFeatureElements}
            </Box>
        );
    } else {
        showAdvancedIconElement = (
            <IconButton aria-label="show advanced" size="small"
                        color={"default"}
                        onClick={(evt: any) => toggleAdvanced(true)}
            >
                <ExpandMoreIcon />
            </IconButton>
        );
    }

    var removePermissionElement: any = null;
    if (permissionsSet) {
        removePermissionElement = (
            <RemovePermissionIcon color="error" onClick={(evt: any) => removePermission()} />
        );
    } else {
        removePermissionElement = (
            <RemovePermissionIcon color="disabled" />
        );
    }

    return (
        <Fragment>
            <Box sx={{ display: 'flex' }}>
                <Container component="span" sx={{ display: 'flex', pt: 0.5, pl: 0.5, pr: 0.5 }}>
                    {removePermissionElement}
                    <Typography variant="caption">
                        {title}
                    </Typography>
                </Container>
                <Container component="span" sx={{ display: 'flex', pt: 0.5, pl: 0.5, pr: 0 }}>
                    {viewButtonElement}
                    {addButtonElement}
                    {editButtonElement}
                    {deleteButtonElement}
                    {showAdvancedIconElement}
                </Container>
            </Box>
            {showAdvancedElement}
        </Fragment>
    );
};
