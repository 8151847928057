
import { find, values } from 'underscore';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// import { udicciStyles } from 'src/theme/shared-styles';

export const EditMenu: React.FC<any> = ({ record, engagementAction, onClick }) => {
    // console.log('%c EditMenu engagementAction: %O', 'color: blue;', engagementAction);
    // const theme = useTheme();
    // const udicciClasses = udicciStyles(theme);

    let udicciMediator: string = (record.udicciMediator ? record.udicciMediator : '');
    let permissions: any = (record.permissions ? record.permissions : null);

    let eaAccess = (engagementAction.access ? engagementAction.access : null);
    let eaMediators = (eaAccess && eaAccess.mediators ? eaAccess.mediators : []);
    let showForAllMediators = (eaAccess && eaAccess.showForAllMediators ? true : false);
    let accessRequiredPermissions = (eaAccess && eaAccess.requiredPermissions ? eaAccess.requiredPermissions : null);
    // console.log('%c accessRequiredPermissions: %O', 'color: blue;', accessRequiredPermissions);

    const triggerEngagementAction = (engagementAction: any) => {
        // console.log('%c EditMenu engagementAction: %O', 'color: maroon;', engagementAction);
        // console.log('%c EditMenu onClick: %O', 'color: maroon;', onClick);
        if (onClick) onClick(engagementAction);
    };

    let mediatorActions = find(values(eaMediators), function(m: any) { return m.name === udicciMediator; });
    let requiredPermissions = (mediatorActions && mediatorActions.requiredPermissions ? mediatorActions.requiredPermissions : accessRequiredPermissions);
    // console.log('%c requiredPermissions: %O', 'color: blue;', requiredPermissions);

    let allowed = (showForAllMediators || mediatorActions !== undefined ? true : false);
    if (requiredPermissions && requiredPermissions.length > 0) {
        allowed = false;
        let fp = requiredPermissions[0];
        let permValue = permissions[fp.permission];
        if (fp.required && permValue === true) allowed = true;
        if (!fp.required) allowed = true;
    }
    // console.log('%c allowed: %O', 'color: blue;', allowed);

    let rval: any = null;
    if (allowed) {
        // console.log('%c ea: %O', 'color: blue;', ea);
        let enagementMenuText = '';
        if (engagementAction && engagementAction.label && engagementAction.label.text) {
            enagementMenuText = engagementAction.label.text;
        } else if (engagementAction && engagementAction.name) {
            enagementMenuText = engagementAction.name;
        } else {
            enagementMenuText = engagementAction.action;
        }

        rval = (
            <Button size="small"
                    onClick={(evt: any) => triggerEngagementAction(engagementAction)}
                    variant="text"
            >
                <Typography variant="clickable" component="span">
                    {enagementMenuText}
                </Typography>
                <Typography variant="clickable" component="span">
                    .
                </Typography>
            </Button>
        );
    }

    return rval;
};
