
import { useState, useEffect, useCallback } from 'react';

import { styled } from '@mui/material/styles';

import { keys, forEach, find, isArray, has } from 'underscore';

import {
    Box, Paper, Typography, Icon, IconButton, Button, Grid, InputAdornment, TextField, 
    Select, MenuItem, Slider, Divider, Link, CircularProgress
} from '@mui/material';

import { useUdicciContext, subscribe as subscribeToUdicci } from 'src/context/udicci-context';
import { useFocusedContext } from './focused-context';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

export const ChatGPTSettings: React.FC<any> = (props) => {
    const focus = useFocusedContext();
    // console.log('%c ChatGPTSettings focus: %O', 'color: blue;', focus);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;
    let { selectedPorta } = udicci;
    // console.log('%c ChatGPTSettings selectedPorta: %O', 'color: blue;', selectedPorta);
    let portaSettings: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : null);
    // console.log('%c ChatGPTSettings portaSettings: %O', 'color: blue;', portaSettings);

    const [showAllModels, setShowAllModels] = useState<boolean>(false);
    const [processingRequest, setProcessingRequest] = useState<boolean>(false);
    const [changeField, setChangeField] = useState<string>('');
    const [changeFieldValue, setChangeFieldValue] = useState<any>(null);
    const [savingPorta, setSavingPorta] = useState<boolean>(false);
    const [changesToBeSaved, setChangesToBeSaved] = useState<any>(null);
    // console.log('%c ChatGPTSettings focusBoardModels: %O', 'color: blue;', focusBoardModels);
    // console.log('%c ChatGPTSettings savingPorta: %O', 'color: red;', savingPorta);
    // console.log('%c ChatGPTSettings changesToBeSaved: %O', 'color: red;', changesToBeSaved);
    // console.log('%c ChatGPTSettings changeField: %O', 'color: red;', changeField);
    // console.log('%c ChatGPTSettings changeFieldValue: %O', 'color: red;', changeFieldValue);

    const gpt3Models: any[] = [
        {
            model: 'text-davinci-003', 
            description: 'Most capable GPT-3 model. Can do any task the other models can do, often with higher quality, longer output and better instruction-following. Also supports inserting completions within text.',
            maxRequestTokens: 4000,
            trainingData: 'Up to Jun 2021',
            uid: udicciHelpers.generateUID()
        },
        {
            model: 'text-curie-001',
            description: 'Very capable, but faster and lower cost than Davinci.',
            maxRequestTokens: 2048,
            trainingData: 'Up to Oct 2019',
            uid: udicciHelpers.generateUID()
        },
        {
            model: 'text-babbage-001',
            description: 'Capable of straightforward tasks, very fast, and lower cost.',
            maxRequestTokens: 2048,
            trainingData: 'Up to Oct 2019',
            uid: udicciHelpers.generateUID()
        },
        {
            model: 'text-ada-001',
            description: 'Capable of very simple tasks, usually the fastest model in the GPT-3 series, and lowest cost.',
            maxRequestTokens: 2048,
            trainingData: 'Up to Oct 2019',
            uid: udicciHelpers.generateUID()
        },
    ];

    const onUicciUpdate = useCallback((response: any, request: any, settings: any) => {
        // console.log('%c ChatGPTSettings onUicciUpdate response: %O', 'color: red;', response);
        // console.log('%c ChatGPTSettings onUicciUpdate request: %O', 'color: red;', request);
        // console.log('%c ChatGPTSettings onUicciUpdate settings: %O', 'color: red;', settings);
        if (request && request.UdicciCommand === 'Save Record') {
            // console.log('%c response: %O', 'color: green;', response);
            // console.log('%c request: %O', 'color: green;', request);
            if (response && response.success !== undefined) {
                if (response && response.success === true) setSavingPorta(false);
            } else if (settings) {
                // check the settings object because something is messed up returning the values
                // current structure of settings returned from save is:
                /*
                 * data: array;
                 * fullResponse: any;
                 * lastUpdate: date;
                 * linkedData: any[];
                 * mediatedRecords: any[];
                 * structures: any[];
                 */
                let fullResponse: any = (settings.fullResponse ? settings.fullResponse : null);
                if (fullResponse && fullResponse.success === true) setSavingPorta(false);
            }
        }
    }, []);

    subscribeToUdicci('chat.gpt.settings', onUicciUpdate);

    const savePorta = () => {
        // console.log('%c ChatGPTSettings savePorta selectedPorta: %O', 'color: green;', selectedPorta);
        if (!savingPorta && ((changesToBeSaved && keys(changesToBeSaved).length > 0) || (selectedPorta && selectedPorta.IsDirty))) {
            // focus.processUpdates(changesToBeSaved);
            setSavingPorta(true);
            udicci.savePorta({ onSuccess: savePortaSuccess });
        }
    }

    const savePortaSuccess = (result: any) => {
        // console.log('%c ChatGPTSettings savePortaSuccess result: %O', 'color: green;', result);
        setChangesToBeSaved(null);
        setChangeField('');
        setChangeFieldValue(null);
    };

    const getOpenAIModels = async () => {
        if (processingRequest || !focus.getOpenAIModels) return false;

        if (focus.aiModels.length <= 0) {
            setProcessingRequest(true);
            focus.getOpenAIModels();
        } else {
            return focus.aiModels;
        }
    }

    const onChangeField = (fieldName: string) => {
        // console.log('%c onChangeField fieldName: %O', 'color: maroon;', fieldName);
        // console.log('%c onChangeField changeField: %O', 'color: maroon;', changeField);
        if (fieldName === changeField || !fieldName) {
            setChangeField('');
            setChangeFieldValue(null);
        } else {
            setChangeField(fieldName);
            if (changesToBeSaved && changesToBeSaved[fieldName] !== undefined) {
                setChangeFieldValue(changesToBeSaved[fieldName]);
            } else {
                // console.log('%c onChangeField focus: %O', 'color: maroon;', focus);
                let workingFocus: any = {};
                Object.assign(workingFocus, focus);
                let focusedValue: any = workingFocus[fieldName];
                // console.log('%c onChangeField focusedValue: %O', 'color: maroon;', focusedValue);
                setChangeFieldValue(focusedValue);
            }
        }
    }

    const handleChangeTemperature= (event: Event, newValue: number | number[]) => {
        if (!changeField) return false;
        let value: number = 0;
        if (isArray(newValue)) {
            value = newValue[0];
        } else {
            value = newValue;
        }
        setChangeFieldValue(value);
    };

    const handleChangeMaxCompletionsPerRequest = (event: Event, newValue: number | number[]) => {
        if (!changeField) return false;
        let value: number = 0;
        if (isArray(newValue)) {
            value = newValue[0];
        } else {
            value = newValue;
        }
        setChangeFieldValue(value);
    };

    const handleChangeMaxTokensPerRequest = (event: Event, newValue: number | number[]) => {
        if (!changeField) return false;
        let value: number = 0;
        if (isArray(newValue)) {
            value = newValue[0];
        } else {
            value = newValue;
        }
        setChangeFieldValue(value);
    };

    const onChangeFieldValue = (fieldName: string, evt: any) => {
        // console.log('%c onChangeField fieldName: %O', 'color: maroon;', fieldName);
        let trgt: any = evt.target;
        let newValue: any = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c onChangeField newValue: %O', 'color: maroon;', newValue);

        let changes: any = {};
        if (changesToBeSaved) Object.assign(changes, changesToBeSaved);

        if (fieldName === 'OPEN_AI_MODEL') {
            // if the model is changing
            let selectedGPT3Model: any = null;
            // console.log('%c gpt3Models: %O', 'color: maroon;', gpt3Models);
            // console.log('%c changes: %O', 'color: maroon;', changes);
            if (changeField === 'OPEN_AI_MODEL') {
                selectedGPT3Model = find(gpt3Models, (gm: any) => { return gm.model === newValue; });
            } else if (changes && changes.OPEN_AI_MODEL) {
                selectedGPT3Model = find(gpt3Models, (gm: any) => { return gm.model === changes.OPEN_AI_MODEL; });
            } else {
                selectedGPT3Model = find(gpt3Models, (gm: any) => { return gm.model === focus.OPEN_AI_MODEL; });
            }
            // console.log('%c selectedGPT3Model: %O', 'color: green;', selectedGPT3Model);
            let currentMaxTokens: number = focus.OPEN_AI_MAX_TOKENS;
            if (selectedGPT3Model) {
                // console.log('%c currentMaxTokens: %O', 'color: green;', currentMaxTokens);
                // console.log('%c changes: %O', 'color: green;', changes);
                if (changeField === 'OPEN_AI_MODEL') {
                    currentMaxTokens = newValue;
                } else if (changes.OPEN_AI_MAX_TOKENS !== undefined && changes.OPEN_AI_MAX_TOKENS !== currentMaxTokens) {
                    currentMaxTokens = changes.OPEN_AI_MAX_TOKENS;
                } else if (changes.OPEN_AI_MAX_TOKENS === undefined) {
                    currentMaxTokens = 0;
                }
                if (currentMaxTokens === undefined || currentMaxTokens !== selectedGPT3Model.maxRequestTokens) {
                    currentMaxTokens = selectedGPT3Model.maxRequestTokens;
                }
            }
            if (changes.OPEN_AI_MAX_TOKENS !== currentMaxTokens) {
                changes.OPEN_AI_MAX_TOKENS = currentMaxTokens;
                setChangesToBeSaved(changes);
            }
        }

        setChangeFieldValue(newValue);
    }

    const cancelChanges = () => {
        onChangeField('');
    };

    const commitChanges = () => {
        if (savingPorta) return false;

        let changes: any = {};
        if (changesToBeSaved) Object.assign(changes, changesToBeSaved);

        if (changeField && changes[changeField] !== changeFieldValue) {
            changes[changeField] = changeFieldValue;
            setChangesToBeSaved(changes);
            onChangeField('');

            if (changes && keys(changes).length > 0) {
                focus.processUpdates(changes);
            }
        }
    };

    const valuetext = (value: number) => {
        return `${value}`;
    }

    useEffect(() => {
        if (showAllModels && (focus.aiModels.length <= 0 && !processingRequest)) getOpenAIModels();
    }, []);

    let sectionHeaderSx: any = {};
    let sectionHeaderIconSx: any = {};
    if (focus.isMobile) {
        sectionHeaderSx.marginTop = '12px';
        sectionHeaderSx.padding = '2px';
        sectionHeaderIconSx.display = 'initial';
        sectionHeaderIconSx.marginRight = '8px';
    }

    let introGridProps: any = {
        container: true,
        spacing: 3,
        // direction: (focus.isMobile ? 'column' : 'row'),
        justifyContent: 'center',
        alignItems: 'stretch',
        // marginTop: 0,
    };

    let htTemperature: string = 'What sampling temperature to use. Higher values means the model will take more risks. Try 0.9 for more creative applications, and 0 (argmax sampling) for ones with a well-defined answer.  Possible Values include 0 through 1 with values of tenths (i.e. 0.1, 0.7, 0.9, etc.)';
    let htMaxTokens: string = 'The maximum number of tokens to generate in the completion.  The token count of your prompt plus max_tokens cannot exceed the model\'s context length.  Most models have a context length of 2048 tokens (except for the newest models, which support 4096).';
    let htCompletionsPerPrompt: string = 'How many completions to generate for each prompt.  Note: Because this parameter generates many completions, it can quickly consume your token quota. Use carefully and ensure that you have reasonable settings for max_tokens and stop.';
    let cgptOrgIDElement: any = null;
    let cgptAPIKeyElement: any = null;
    let cgptModelElement: any = null;
    let cgptTemperatureElement: any = null;
    let cgptMaxCompletionsElement: any = null;
    let cgptMaxTokensElement: any = null;

    let cancelIconButtonElement: any = (
        <IconButton color="info" size="small" onClick={() => cancelChanges()}>
            <Icon fontSize="small">cancel</Icon>
        </IconButton>
    );

    let commitIconButtonElement: any = (
        <IconButton color="success" size="small" onClick={() => commitChanges()}>
            <Icon fontSize="small">check</Icon>
        </IconButton>
    );

    let saveButtonDisabled: boolean = true;
    if (selectedPorta) {
        if (((selectedPorta.IsDirty) || (changesToBeSaved && keys(changesToBeSaved).length > 0)) && !savingPorta) saveButtonDisabled= false;
    }

    let savePortaButtonElement: any = null;
    if (savingPorta) {
        savePortaButtonElement = (
            <CircularProgress color="info" size={20} sx={{ float: 'right' }} />
        );
    } else {
        savePortaButtonElement = (
            <IconButton color="success" size="small" onClick={() => savePorta()} sx={{ float: 'right' }} disabled={saveButtonDisabled}>
                <Icon fontSize="small">save</Icon>
            </IconButton>
        );
    }

    let closeInputAdornmentElement: any = ( <InputAdornment position="end"> {cancelIconButtonElement}{commitIconButtonElement} </InputAdornment> );
    if (focus) {
        let lsOpenAIOrgID: string | null = localStorage.getItem('.it.udicci.openai.org.id');
        if (changesToBeSaved && changesToBeSaved.OPENAI_ORG_ID !== undefined) {
            lsOpenAIOrgID = changesToBeSaved.OPENAI_ORG_ID;
        }
        let changeOrgIdElement: any = null;
        if (changeField === 'OPENAI_ORG_ID') {
            // (lsOpenAIOrgID ? lsOpenAIOrgID : focus.OPENAI_ORG_ID)
            changeOrgIdElement = (
                <Box sx={{ marginBottom: '8px' }}>
                    <TextField fullWidth
                               type="text" autoFocus
                               value={changeFieldValue}
                               onChange={(evt: any) => onChangeFieldValue('OPENAI_ORG_ID', evt)}
                               InputProps={{ endAdornment: closeInputAdornmentElement, }}
                               margin="normal"
                               variant='filled'
                    />
                </Box>
            );
        } else {
            changeOrgIdElement = (
                <Typography variant="subtitle2" component="div" onClick={() => onChangeField('OPENAI_ORG_ID')}>
                    {(lsOpenAIOrgID ? lsOpenAIOrgID : focus.OPENAI_ORG_ID)}
                </Typography>
            );
        }
        cgptOrgIDElement = (
            <Box sx={{ margin: '8px' }}>
                <Typography variant="caption" component="div" sx={{ fontSize: '0.6em' }} onClick={() => onChangeField('OPENAI_ORG_ID')}> OpenAI Organization ID: </Typography>
                {changeOrgIdElement}
            </Box>
        );

        let lsOpenAIAPIKey: string | null = localStorage.getItem('.it.udicci.openai.api.key');
        // console.log('%c lsOpenAIAPIKey: %O', 'color: maroon;', lsOpenAIAPIKey);
        let apiKeyText: string = (changesToBeSaved && changesToBeSaved.OPENAI_API_KEY ? changesToBeSaved.OPENAI_API_KEY : focus.OPENAI_API_KEY);
        if (!apiKeyText && lsOpenAIAPIKey) apiKeyText = lsOpenAIAPIKey;

        // (lsOpenAIAPIKey ? lsOpenAIAPIKey : focus.OPENAI_API_KEY)
        cgptAPIKeyElement = (
            <Box sx={{ margin: '8px' }}>
                <Typography variant="caption" component="div" sx={{ fontSize: '0.6em' }} onClick={() => onChangeField('OPENAI_API_KEY')}> OpenAI API Key: </Typography>
                {( changeField === 'OPENAI_API_KEY' ?
                    (
                        <Box sx={{ marginBottom: '8px' }}>
                            <TextField fullWidth
                                       type="text" autoFocus
                                       value={changeFieldValue}
                                       onChange={(evt: any) => onChangeFieldValue('OPENAI_API_KEY', evt)}
                                       InputProps={{ endAdornment: closeInputAdornmentElement, }}
                                       margin="normal"
                                       variant='outlined'
                            />
                        </Box>
                    )
                     :
                    (
                        <Typography variant="subtitle2" component="div" onClick={() => onChangeField('OPENAI_API_KEY')}>
                            {(apiKeyText ? apiKeyText : 'not set - click here to set your OpenAI API Key')}
                        </Typography>
                    )
                )}
                <Box sx={{ margin: '8px' }}>
                    <Typography variant="caption" component="div">
                        You can get your own API Key from OpenAI.
                    </Typography>
                    <Link href="https://platform.openai.com/account/api-keys" target="_blank" rel="noopener">
                        Click here to get one now.
                    </Link>
                </Box>
            </Box>
        );

        let modelSelections: any[] = [];
        forEach(gpt3Models, (model: any, idx: number) => {
            // console.log('%c model: %O', 'color: green;', model);
            modelSelections.push( <MenuItem key={'model.' + model.uid} value={model.model}>{model.model}</MenuItem> );
        });
        let selectedGPT3Model: any = find(gpt3Models, (gm: any) => { return gm.model === focus.OPEN_AI_MODEL; });
        // console.log('%c selectedGPT3Model: %O', 'color: maroon;', selectedGPT3Model);

        let maxNumberTokens: number = 2048;
        let selectedModelOverviewElement: any = null;
        if (selectedGPT3Model) {
            selectedModelOverviewElement = (
                <Box sx={{ margin: '8px' }}>
                    <Typography variant="caption" component="div" sx={{ marginLeft: '8px' }}> {selectedGPT3Model.description} </Typography>
                    <Typography variant="caption" component="div" sx={{ marginLeft: '8px' }}> Max Tokens per Request:  {selectedGPT3Model.maxRequestTokens} </Typography>
                    <Typography variant="caption" component="div" sx={{ marginLeft: '8px' }}> Training Data: {selectedGPT3Model.trainingData} </Typography>
                </Box>
            );
            if (selectedGPT3Model.maxRequestTokens) maxNumberTokens = selectedGPT3Model.maxRequestTokens;
        }

        // focus.OPEN_AI_MODEL
        cgptModelElement = (
            <Box sx={{ margin: '8px' }}>
                <Typography variant="caption" component="div" sx={{ fontSize: '0.6em' }} onClick={() => onChangeField('OPEN_AI_MODEL')}> OpenAI Model: </Typography>
                {( changeField === 'OPEN_AI_MODEL' ?
                    (
                        <Box sx={{ marginBottom: '8px' }}>
                            <Select value={changeFieldValue} onChange={(evt: any) => onChangeFieldValue('OPEN_AI_MODEL', evt)}>
                                {modelSelections}
                            </Select>
                            {cancelIconButtonElement}
                            {commitIconButtonElement}
                        </Box>
                    )
                     :
                    (
                        <Typography variant="subtitle2" component="div" onClick={() => onChangeField('OPEN_AI_MODEL')}>
                            {(changesToBeSaved && changesToBeSaved.OPEN_AI_MODEL ? changesToBeSaved.OPEN_AI_MODEL : focus.OPEN_AI_MODEL)}
                        </Typography>
                    )
                )}
                {selectedModelOverviewElement}
            </Box>
        );
        // focus.OPEN_AI_TEMPERATURE
        cgptTemperatureElement = (
            <Box sx={{ margin: '8px' }}>
                <Typography variant="caption" component="span" sx={{ fontSize: '0.6em' }} onClick={() => onChangeField('OPEN_AI_TEMPERATURE')}> Temperature: </Typography>
                {( changeField === 'OPEN_AI_TEMPERATURE' ?
                    (
                        <Box sx={{ marginBottom: '8px' }}>
                            <Slider aria-label="Temperature" defaultValue={1} getAriaValueText={valuetext} step={0.1} min={0} max={1} marks
                                    valueLabelDisplay="auto" onChange={handleChangeTemperature} value={changeFieldValue}
                            />
                            <TextField fullWidth
                                       type="text" autoFocus
                                       value={changeFieldValue}
                                       onChange={(evt: any) => onChangeFieldValue('OPEN_AI_TEMPERATURE', evt)}
                                       InputProps={{ endAdornment: closeInputAdornmentElement, }}
                                       margin="normal"
                                       variant='outlined'
                                       helperText={htTemperature}
                            />
                        </Box>
                    )
                     :
                    (
                        <Typography variant="subtitle2" component="span" onClick={() => onChangeField('OPEN_AI_TEMPERATURE')}> {(changesToBeSaved && changesToBeSaved.OPEN_AI_TEMPERATURE ? changesToBeSaved.OPEN_AI_TEMPERATURE : focus.OPEN_AI_TEMPERATURE)} </Typography>
                    )
                )}
            </Box>
        );
        // focus.OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT
        cgptMaxCompletionsElement = (
            <Box sx={{ margin: '8px' }}>
                <Typography variant="caption" component="span" sx={{ fontSize: '0.6em' }} onClick={() => onChangeField('OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT')}> Max Completions per Prompt: </Typography>
                {( changeField === 'OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT' ?
                    (
                        <Box sx={{ marginBottom: '8px' }}>
                            <Slider aria-label="Max Completions Per Request" defaultValue={1} getAriaValueText={valuetext} step={1} min={1} max={5} marks
                                    valueLabelDisplay="auto" onChange={handleChangeMaxCompletionsPerRequest} value={changeFieldValue}
                            />
                            <TextField fullWidth
                                       type="text" autoFocus
                                       value={changeFieldValue}
                                       onChange={(evt: any) => onChangeFieldValue('OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT', evt)}
                                       InputProps={{ endAdornment: closeInputAdornmentElement, }}
                                       margin="normal"
                                       variant='outlined'
                                       helperText={htCompletionsPerPrompt}
                            />
                        </Box>
                    )
                     :
                    (
                        <Typography variant="subtitle2" component="span" onClick={() => onChangeField('OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT')}>
                            {(changesToBeSaved && changesToBeSaved.OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT ? changesToBeSaved.OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT : focus.OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT)}
                        </Typography>
                    )
                )}
            </Box>
        );
        // focus.OPEN_AI_MAX_TOKENS
        cgptMaxTokensElement = (
            <Box sx={{ margin: '8px' }}>
                <Typography variant="caption" component="span" sx={{ fontSize: '0.6em' }} onClick={() => onChangeField('OPEN_AI_MAX_TOKENS')}> Max Tokens per Prompt: </Typography>
                {( changeField === 'OPEN_AI_MAX_TOKENS' ?
                    (
                        <Box sx={{ marginBottom: '8px' }}>
                            <Slider aria-label="Max Tokensw Per Request" defaultValue={16} getAriaValueText={valuetext} step={1} min={1} max={maxNumberTokens}
                                    valueLabelDisplay="auto" onChange={handleChangeMaxTokensPerRequest} value={changeFieldValue}
                            />
                            <TextField fullWidth
                                       type="text" autoFocus
                                       value={changeFieldValue}
                                       onChange={(evt: any) => onChangeFieldValue('OPEN_AI_MAX_TOKENS', evt)}
                                       InputProps={{ endAdornment: closeInputAdornmentElement, }}
                                       margin="normal"
                                       variant='outlined'
                                       helperText={htMaxTokens}
                            />
                        </Box>
                    )
                     :
                    (
                        <Typography variant="subtitle2" component="span" onClick={() => onChangeField('OPEN_AI_MAX_TOKENS')}>
                            {(changesToBeSaved && changesToBeSaved.OPEN_AI_MAX_TOKENS ? changesToBeSaved.OPEN_AI_MAX_TOKENS : focus.OPEN_AI_MAX_TOKENS)}
                        </Typography>
                    )
                )}
            </Box>
        );
    }

    const PaperContainer = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        height: '100%',
    }));

    let chatGPTSettings: any = (
        <PaperContainer elevation={1}>
            <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ marginBottom: '16px' }}>
                    {savePortaButtonElement}
                    <Typography variant="caption">
                        These are your settings for using the OpenAI ChatGPT integrated capabilities with Udicci.
                        Click on any of the values below to change the value.
                    </Typography>
                    <Divider />
                </Box>
                {cgptOrgIDElement}
                <Divider />
                {cgptAPIKeyElement}
                <Divider />
                {cgptModelElement}
                <Divider />
                {cgptTemperatureElement}
                <Divider />
                {cgptMaxCompletionsElement}
                <Divider />
                {cgptMaxTokensElement}
            </Box>
        </PaperContainer>
    );

    let chatGPTSettingsGridElement: any = (
        <Grid {...introGridProps}>
            <Grid item={true} xs={12} md={12}> {chatGPTSettings} </Grid>
        </Grid>
    );

    return (
        <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '8px' }}> <Typography variant="subtitle1" component="span">ChatGPT Settings</Typography> </Box>
            {chatGPTSettingsGridElement}
        </Box>
    );
};
