
// import UrlParser from 'url-parse';

import { Helmet, HelmetProvider } from "react-helmet-async";
// import { format, formatDistance, formatRelative, subDays, addDays, isToday, isFuture } from 'date-fns';

import ReactGA from "react-ga4";
import UrlParser from 'url-parse';

import { UdicciPortalComponent, UdicciPortalProps, UdicciPortalState } from 'src/ui/udicci-ui-library';

import { forEach } from 'underscore';
import Porta from './porta';
import { addTrackingLog, useUdicciContext, subscribe as subscribeToUdicci } from 'src/context/udicci-context'

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

export default function Portal() {
    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    // console.log('%c Portal udicciContext: %O', 'color: maroon;', udicciContext);

    // let { udicci } = udicciContext.state;
    // console.log('%c Portal ludicci: %O', 'color: maroon;', udicci);

    // const nprDate: Date | null = udicci.nextProductionRelease;
    // if (nprDate) {
    //     // let nprIsToday: boolean = isToday(nprDate);
    //     // console.log('%c Portal nprIsToday: %O', 'color: maroon;', nprIsToday);
    //     // let nprInFuture: boolean = isFuture(nprDate);
    //     // console.log('%c Portal nprInFuture: %O', 'color: maroon;', nprInFuture);
    // }

    // let productionReleaseScheduled: boolean = udicci.productionReleaseScheduled();
    // console.log('%c Portal productionReleaseScheduled: %O', 'color: maroon;', productionReleaseScheduled);
    // let productionReleaseTimeframe: string = udicci.productionReleaseTimeframe();
    // console.log('%c Portal productionReleaseTimeframe: %O', 'color: maroon;', productionReleaseTimeframe);

    // const contextUpdated = (result: any, request: any, settings: any) => {
    //     // console.log('%c Portal contextUpdated result: %O', 'color: hotpink;', result);
    //     // console.log('%c Portal contextUpdated request: %O', 'color: hotpink;', request);
    //     // console.log('%c Portal contextUpdated settings: %O', 'color: hotpink;', settings);
    // };

    // subscribeToUdicci('portal', contextUpdated);

    return (<PortalComponent udicciContext={udicciContext} udicciHelpers={udicciHelpers} />);
}

// PortalComponent extends the UdicciComponent
class PortalComponent extends UdicciPortalComponent<UdicciPortalProps, UdicciPortalState> {
    componentDidUpdate(newProps: any, newState: any) {
        super.componentDidUpdate(newProps, newState);

        // this.developerLog('%c PortalComponent componentDidUpdate newProps: %O', 'color: red;', newProps);
        // this.developerLog('%c PortalComponent componentDidUpdate newState: %O', 'color: red;', newState);
        // this.developerLog('%c PortalComponent componentDidUpdate this.state: %O', 'color: red;', this.state);

        let analyticsInitalized: boolean = (this.state && this.state.AnalyticsInitalized ? true : false);
        // this.developerLog('%c PortalComponent componentDidUpdate analyticsInitalized: %O', 'color: red;', analyticsInitalized);
        let stateUrl: string = (this.state && this.state.currentUrl ? this.state.currentUrl : '');
        // this.developerLog('%c PortalComponent componentDidUpdate stateUrl: %O', 'color: red;', stateUrl);

        let browserUrl: string = window.location.href;
        let currentUrl = UrlParser(browserUrl, true);
        // console.log('%c PortalComponent currentUrl: %O', 'color: purple;', currentUrl);

        let udicci: any = super.getUdicciFactory();
        // console.log('%c PortalComponent udicci: %O', 'color: purple;', udicci);

        let selectedProfile: any = super.getUdicciProfile();
        // this.developerLog('%c PortalComponent componentDidUpdate selectedProfile: %O', 'color: red;', selectedProfile);

        if (selectedProfile && !analyticsInitalized) {
            udicci.initializeAnalyticsForPortal();
            this.sendAnalytics(browserUrl);
            this.setState({ AnalyticsInitalized: true, currentUrl: browserUrl });
        }

        if (analyticsInitalized && stateUrl !== browserUrl) {
            this.sendAnalytics(browserUrl);
            this.setState({ currentUrl: browserUrl });
        }
    }

    componentWillUnmount() {
        // this.developerLog('%c PortalComponent componentWillUnmount this.state: %O', 'color: black;', this.state);
    }

    componentDidMount() {
        // this.developerLog('%c PortalComponent componentDidMount this.state: %O', 'color: black;', this.state);
        super.componentDidMount();

        // if (this.state && this.state.isLoaded) {
        //     let currentUrl = window.location.href;
        //     this.developerLog('%c PortalComponent componentDidMount currentUrl: %O', 'color: black;', currentUrl);
        //     this.setState({ CanRead: true, currentUrl: currentUrl })
        // }
    }

    sendAnalytics = (pageViewed: string) => {
        this.developerLog('%c PortalComponent sendAnalytics pageViewed: %O', 'color: blue;', pageViewed);
        // console.log('%c PortalComponent sendAnalytics pageViewed: %O', 'color: blue;', pageViewed);
        ReactGA.send({
            hitType: "pageview",
            page: pageViewed,
        });
    }

    render() {
        // this.developerLog('%c PortalComponent render this.props: %O', 'color: black;', this.props);
        // this.developerLog('%c PortalComponent render this.state: %O', 'color: black;', this.state);

        // addTrackingLog({ location: location, url: url }, false);
    
        let udicciIcon: string = '/udicci.ico';
        let orangeDotIcon: string = '/orange-dot.ico';
    
        let selectedProfile: any = super.getUdicciProfile();
        // let selectedPorta: any = super.getUdicciPorta();
        // this.developerLog('%c PortalComponent selectedProfile: %O', 'color: purple; font-weight: bold;', selectedProfile);
        // this.developerLog('%c PortalComponent selectedPorta: %O', 'color: purple; font-weight: bold;', selectedPorta);

        let portalTabText: string = 'Udicci.';
        let portalTabIcon: any = null;
        if (selectedProfile && selectedProfile.title) {
            portalTabText = selectedProfile.title + '.';
            if (selectedProfile.title !== 'Udicci') portalTabIcon = (<link rel="icon" href={orangeDotIcon} />);
        }
    
        if (!portalTabIcon) portalTabIcon = (<link rel="icon" href={udicciIcon} />);
    
        return (
            <HelmetProvider>
                <Helmet>
                    {portalTabIcon}
                    <title>{portalTabText}</title>
                </Helmet>
                <Porta />
            </HelmetProvider>
        );
    }
}
