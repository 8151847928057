
import { useState } from 'react';

import DefaultSocialIcon from 'src/images/Ulysses Selfie Portrait.png'

import { Box, Paper, Typography, Icon, IconButton, Grid, Avatar, CircularProgress, Snackbar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { forEach, findIndex } from 'underscore';

// import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext, sendPreparedRequest } from 'src/context/udicci-context';
import { useFocusedContext } from './focused-context';
// import { UdicciRecord } from 'src/classes/udicci-record';

import { PerspectiveDisplay } from './perspective-display';
import { DisplayThePoint } from './display-the-point';

export const ORANGE_DOT_ICON: any = {
    backgroundColor: 'rgb(234 85 8) !important',
    float: 'left',
    maxHeight: '8px',
    minWidth: '8px',
    borderRadius: '8px',
    marginTop: '7px',
    marginRight: '8px',
    fontSize: 0,
};

const PaperContainer = styled(Paper, {
    shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>(({ theme, isMobile }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: (isMobile ? '100%' : '750px'),
    padding: '8px',
    paddingTop: '20px',
    paddingBottom: '20px',
}));

const PerspectivePaperContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    padding: '8px',
    // height: '100%',
}));

const SubTitleContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    // padding: '8px',
    position: 'sticky', 
    top: 0, 
    display: 'flow-root', 
    height: '50px', 
    marginLeft: '10px'
}));

export const FocusedContent: React.FC<any> = (props) => {
    const focus = useFocusedContext();
    // console.log('%c FocusedContent focus: %O', 'color: blue;', focus);

    // const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;
    let { selectedProfile, selectedPorta, currentUser } = udicci;
    // console.log('%c FocusedContent selectedProfile: %O', 'color: red;', selectedProfile);
    // console.log('%c FocusedContent selectedPorta: %O', 'color: red;', selectedPorta);

    const [showSnackbar, setShowSnackbar] = useState<boolean>(focus.hasChangesToSave());
    const [uiState, flashUI] = useState<boolean>(true);

    const saveTheFocusBoard = () => {
        // console.log('%c saveTheFocusBoard focus: %O', 'color: red;', focus);
        if (focus.savePortaFocusBoard) focus.savePortaFocusBoard();
        flashUI(!uiState);
    };

    let pointGridProps: any = {
        container: true,
        spacing: (focus.isMobile ? 1 : 3),
        justifyContent: 'center',
        alignItems: 'stretch',
        sx: {
            marginTop: 0,
            marginLeft: '-15px',
            paddingRight: '10px',
            paddingLeft: '10px',    
        }
    };

    if (focus.isMobile) {
        // pointGridProps.sx.marginTop = 50;
        pointGridProps.sx.paddingRight = 0;
    }

    let gridItemProps: any = {
        item: true,
        xs: 12,
        md: 12,
        sx: {
            paddingTop: (focus.isMobile ? '4px' : '4px') + ' !important',
            paddingLeft: '15px',
            paddingRight: '15px',
            marginBottom: '8px',
        }
    };
    if (focus.isMobile) {
        gridItemProps.sx.paddingLeft = 0;
        gridItemProps.sx.paddingRight = 0;
    }

    let promptSectionContentSx: any = { padding: '4px' };
    let displayName: string = (selectedProfile && selectedProfile.data.DisplayName ? selectedProfile.data.DisplayName : '');
    let profileSocialIconSource: any = null;
    if (selectedProfile && selectedProfile.data && selectedProfile.data.SocialIcon) {
        profileSocialIconSource = selectedProfile.data.SocialIcon;
    } else if (selectedProfile) {
        profileSocialIconSource = DefaultSocialIcon;
    }
    let profileSocialIconElement: any = (
        <Avatar alt={displayName} src={profileSocialIconSource} component='span' sx={{ float: 'left', margin: '4px' }} />
    );
    let perspectives: any[] = (focus.point && focus.point.perspectives ? focus.point.perspectives : []);
    // console.log('%c FocusedContent perspectives: %O', 'color: darkblue;', perspectives);

    let perspectiveGridElements: any[] = [];
    if (perspectives.length > 0) {
        forEach(perspectives, (psp: any, pspIdx: number) => {
            if (psp.data && psp.data.InTrashBin) return true;

            perspectiveGridElements.push(
                <Grid {...gridItemProps}>
                    <PerspectivePaperContainer elevation={5}>
                        <PerspectiveDisplay perspective={psp} />
                    </PerspectivePaperContainer>
                </Grid>
            );
        });
    }

    let focusedContentGridElement: any = (
        <Box>
            <Grid {...pointGridProps}>
                <Grid {...gridItemProps}>
                    <Box sx={promptSectionContentSx}>
                        <DisplayThePoint />
                    </Box>
                </Grid>
                {perspectiveGridElements}
            </Grid>
        </Box>
    );

    let closeFocusElement: any = (
        <IconButton color="info" aria-label="close point" edge="end" onClick={focus.closeFocus} sx={{ float: 'right', zIndex: 15 }}>
            <Icon>close</Icon>
        </IconButton>
    );

    let focusBoardHasChangesToSave: boolean = focus.hasChangesToSave();
    // console.log('%c FocusedContent focusBoardHasChangesToSave: %O', 'color: darkblue;', focusBoardHasChangesToSave);
    let focusBoardIsSaving: boolean = focus.isSavingFocusBoard();
    // console.log('%c FocusedContent focusBoardIsSaving: %O', 'color: darkblue;', focusBoardIsSaving);
    let saveFocusBoardDisabled: boolean = ((!focusBoardHasChangesToSave || focusBoardIsSaving) ? true : false); //  || focus.aiAssistantOn
    // console.log('%c FocusedContent saveFocusBoardDisabled: %O', 'color: darkblue;', saveFocusBoardDisabled);
    let saveFocusBoardIcon: any = null;
    if (focusBoardIsSaving) {
        saveFocusBoardIcon = ( <CircularProgress size={20} sx={{ float: 'right', mr: 1 }} /> );
    } else {
        saveFocusBoardIcon = (
            <IconButton sx={{ float: 'right', mr: 1 }} color="success" onClick={saveTheFocusBoard} disabled={saveFocusBoardDisabled}>
                <Icon>save</Icon>
            </IconButton>
        );
    }

    let subContainerSx: any = {
        paddingBottom: (focus.aiAssistantOn ? '500px' : '200px'),
        marginBottom: '0px',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    };
    if (!focus.isMobile) {
        subContainerSx.left = udicci.standardMenuDrawerWidth;
        subContainerSx.width = { md: `calc(100% - ${udicci.standardMenuDrawerWidth}px)` };
    } else {
        subContainerSx.top = 50;
    }

    let sxSubTitleGroupedContent: any = null;
    sxSubTitleGroupedContent = { marginTop: '2px' };
    let subTitle: any = (
        <SubTitleContainer elevation={0}>
            {closeFocusElement}
            {saveFocusBoardIcon}
            {profileSocialIconElement}
            <Typography variant="subtitle1" component="div" sx={sxSubTitleGroupedContent}>
                {selectedProfile?.data.DisplayName}
            </Typography>
        </SubTitleContainer>
    );

    let mainContainerSx: any = { padding: '8px', flexGrow: 1 };
    if (!focus.isMobile) {
        mainContainerSx.marginLeft = { md: `calc(100% - ${udicci.standardMenuDrawerWidth}px)` };
    // } else {
    //     mainContainerSx.marginTop = '50px';
    }

    let hasChangesToSaveElement: any = null;
    if (focus.hasChangesToSave()) {
        // console.log('%c FocusedContent focus.hasChangesToSave(): %O', 'color: darkblue;', focus.hasChangesToSave());
        hasChangesToSaveElement = (
            <Snackbar open={showSnackbar}
                      autoHideDuration={7777}
                      message={'Focus Board has changes to be saved.'}
                      onClose={(event: any, reason: any) => { setShowSnackbar(false); }}
            />
        );
    }

    return (
        <Box sx={mainContainerSx}>
            <Box {...subContainerSx} sx={{ overflowY: 'auto' }}>
                <PaperContainer isMobile={focus.isMobile}>
                    {subTitle}
                    {focusedContentGridElement}
                    {hasChangesToSaveElement}
                </PaperContainer>
            </Box>
        </Box>
    );
};
