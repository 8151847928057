import { Component } from "react";

import { useUdicciContext } from 'src/context/udicci-context';

// *********************************************************************************************************************
// *********************************************************************************************************************
// Props for the Base Component UdicciBaseComponent
export interface UdicciBaseComponentProps {
    udicciContext?: any,
    udicciHelpers?: any
}

// State for the Base Component UdicciBaseComponent
export interface UdicciBaseComponentState {
    isLoaded?: boolean,
    logConsoleMessages?: boolean,
}

// This Is the Main Base Component for all Udicci Components
export class UdicciBaseComponent<Props extends UdicciBaseComponentProps, State extends UdicciBaseComponentState, SS = any> extends Component<Props, State, SS> {

    logConsoleMessages: boolean = false;

    State: UdicciBaseComponentState = {
        isLoaded: false,
        logConsoleMessages: this.logConsoleMessages
    }

    developerLog(msg: string, color: string | null, ...args: any[]) {
        // ideas for the future include tracing, possibly grouping console logs, other console commands, etc.
        // trace/log other information - Udicci Context, etc.
        // make color easier --- they specify 'green' and we build the color: green;
        // add bold font and italicized font options true/false
        // add debug level option 1-5.  based on platform debug state, console messages display --- turn off in production
        if (!this.logConsoleMessages) return false;

        if (color) {
            console.log(msg, color, ...args);
        } else {
            console.log(msg, ...args);
        }
    }

    constructor(props: any) {
        super(props);
        // this.developerLog('%c UdicciBaseComponent constructor props: %O', 'color: black;', props);
        // this.developerLog('%c UdicciBaseComponent constructor this.State: %O', 'color: black;', this.State);
        // this.State.context = (props && props.context ? props.context : this.State.context);
    }

    componentDidMount() {
        this.setState({ isLoaded: true,  })
    }
    
    // componentDidUpdate(newProps: any, oldProps: any) {
    //     // this.developerLog('%c UdicciBaseComponent componentDidUpdate newProps: %O', 'color: black;', newProps);
    //     // this.developerLog('%c UdicciBaseComponent componentDidUpdate oldProps: %O', 'color: black;', oldProps);
    //     // this.developerLog('%c UdicciBaseComponent componentDidUpdate this.state: %O', 'color: black;', this.state);
    //     // let value = this.context;
    //     /* ... */
    // }

    getUdicciFactory() {
        let udicciContext: any = (this.props && this.props.udicciContext ? this.props.udicciContext : null);
        // this.developerLog('%c getUdicciFactory getUdicciProfile udicciContext: %O', 'color: black;', udicciContext);
        let { udicci } = udicciContext.state;
        // this.developerLog('%c getUdicciFactory getUdicciProfile udicci: %O', 'color: maroon;', udicci);
        return udicci;
    }

    getUdicciData() {
        let udicciContext: any = (this.props && this.props.udicciContext ? this.props.udicciContext : null);
        // this.developerLog('%c getUdicciData getUdicciProfile udicciContext: %O', 'color: black;', udicciContext);
        let { data } = udicciContext.state;
        // this.developerLog('%c getUdicciData getUdicciProfile udicci: %O', 'color: maroon;', udicci);
        return data;
    }

    getCurrentUser() {
        let udicci = this.getUdicciFactory();
        this.developerLog('%c UdicciBaseComponent getCurrentUser udicci: %O', 'color: maroon;', udicci);
        let { currentUser } = udicci;
        this.developerLog('%c UdicciBaseComponent getCurrentUser currentUser: %O', 'color: purple; font-weight: bold;', currentUser);
        return currentUser;
    }

    getPublicRole() {
        let profile: any = this.getUdicciProfile();
        this.developerLog('%c UdicciBaseComponent getPublicRole profile: %O', 'color: maroon;', profile);
        let { PublicRole } = profile;
        this.developerLog('%c UdicciBaseComponent getPublicRole PublicRole: %O', 'color: purple; font-weight: bold;', PublicRole);
        return PublicRole;
    }

    getCurrentUserRole() {
        let udicci = this.getUdicciFactory();
        this.developerLog('%c UdicciBaseComponent getCurrentUserRole udicci: %O', 'color: maroon;', udicci);
        let { currentUserRole } = udicci;
        this.developerLog('%c UdicciBaseComponent getCurrentUserRole currentUserRole: %O', 'color: purple; font-weight: bold;', currentUserRole);
        return currentUserRole;
    }

    getUdicciProfile() {
        let udicci = this.getUdicciFactory();
        let { selectedProfile } = udicci;
        // this.developerLog('%c UdicciBaseComponent getUdicciProfile selectedProfile: %O', 'color: purple; font-weight: bold;', selectedProfile);

        // let profile = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
        // this.developerLog('%c UdicciBaseComponent getUdicciProfile profile: %O', 'color: maroon;', profile);
        return selectedProfile;
    }

    getUdicciPorta() {
        let udicci = this.getUdicciFactory();
        let { selectedPorta } = udicci;
        // this.developerLog('%c UdicciBaseComponent getUdicciPorta selectedPorta: %O', 'color: purple; font-weight: bold;', selectedPorta);
        return selectedPorta;
    }
}

// *********************************************************************************************************************
// *********************************************************************************************************************
// Props for Portal component
export interface UdicciPortalProps extends UdicciBaseComponentProps {

}

// State for Portal component
export interface UdicciPortalState extends UdicciBaseComponentState {
    CanRead: boolean,
    AnalyticsInitalized: boolean,
    context?: any,
    currentUrl?: string,
}

export class UdicciPortalComponent<Props extends UdicciPortalProps, State extends UdicciPortalState, SS = any> extends UdicciBaseComponent<Props, State, SS> {

    State: UdicciPortalState = {
        isLoaded: false,
        CanRead: true,
        AnalyticsInitalized: false
    }

    constructor(props: any) {
        super(props);
        // this.developerLog('%c UdicciPortalComponent constructor props: %O', 'color: gold;', props);
        // this.developerLog('%c UdicciPortalComponent constructor this.State: %O', 'color: gold;', this.State);
    }

    componentDidMount() {
        let currentUrl = window.location.href;
        this.developerLog('%c UdicciPortalComponent componentDidMount currentUrl: %O', 'color: gold;', currentUrl);
        this.setState({ CanRead: true, AnalyticsInitalized: false, currentUrl: currentUrl })
    }
    
    componentDidUpdate(newProps: any, newState: any) {
        // this.developerLog('%c UdicciPortalComponent componentDidUpdate newProps: %O', 'color: gold;', newProps);
        // this.developerLog('%c UdicciPortalComponent componentDidUpdate newState: %O', 'color: gold;', newState);
        // this.developerLog('%c UdicciPortalComponent componentDidUpdate this.state: %O', 'color: gold;', this.state);
    }
}

// Props for Porta component
export interface UdicciPortaProps extends UdicciBaseComponentProps {

}

// State for Porta component
export interface UdicciPortaState extends UdicciBaseComponentState {
    CanRead: boolean,
}

export class UdicciPortaComponent<Props extends UdicciPortaProps, State extends UdicciPortaState, SS = any> extends UdicciBaseComponent<Props, State, SS> {

    State: UdicciPortaState = {
        isLoaded: false,
        CanRead: true,
    }

    constructor(props: any) {
        super(props);
        // this.developerLog('%c UdicciPortaComponent constructor props: %O', 'color: black;', props);
        // this.developerLog('%c UdicciPortaComponent constructor this.State: %O', 'color: black;', this.State);
    }

    componentDidMount() {
        this.setState({ CanRead: true,  })
    }
    
    componentDidUpdate(newProps: any, newState: any) {
        // this.developerLog('%c UdicciPortaComponent componentDidUpdate newProps: %O', 'color: gold;', newProps);
        // this.developerLog('%c UdicciPortaComponent componentDidUpdate newState: %O', 'color: gold;', newState);
        // this.developerLog('%c UdicciPortaComponent componentDidUpdate this.state: %O', 'color: gold;', this.state);
    }

    getSomething() {
        let udicciContext: any = (this.props && this.props.udicciContext ? this.props.udicciContext : null);
        this.developerLog('%c UdicciPortaComponent getSomething udicciContext: %O', 'color: black;', udicciContext);
    
        let { udicci } = udicciContext.state;
        this.developerLog('%c UdicciPortaComponent getSomething udicci: %O', 'color: maroon;', udicci);
    
        let { selectedProfile, currentUser, selectedPorta } = udicci;
        this.developerLog('%c UdicciPortaComponent getSomething selectedProfile: %O', 'color: purple; font-weight: bold;', selectedProfile);
        this.developerLog('%c UdicciPortaComponent getSomething selectedPorta: %O', 'color: purple; font-weight: bold;', selectedPorta);
        this.developerLog('%c UdicciPortaComponent getSomething currentUser: %O', 'color: purple; font-weight: bold;', currentUser);
        let profile = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
        this.developerLog('%c UdicciPortaComponent getSomething profile: %O', 'color: maroon;', profile);
        var profileSettings = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
        this.developerLog('%c UdicciPortaComponent getSomething profileSettings: %O', 'color: maroon;', profileSettings);
        var portalSetting: any = (profileSettings && profileSettings.portal ? profileSettings.portal : null);
        this.developerLog('%c UdicciPortaComponent getSomething portalSetting: %O', 'color: maroon;', portalSetting);
        var portalDesignSetting: any = (portalSetting && portalSetting.design ? portalSetting.design : null);
        this.developerLog('%c UdicciPortaComponent getSomething portalDesignSetting: %O', 'color: maroon;', portalDesignSetting);
    }
}

// Props for Pane component
export interface UdicciPaneProps extends UdicciBaseComponentProps {

}

// *********************************************************************************************************************
// *********************************************************************************************************************
// State for Pane component
export interface UdicciPaneState extends UdicciBaseComponentState {
    CanRead: boolean,
}

// Props for Portal component
export interface UdicciComponentProps extends UdicciBaseComponentProps {

}

// State for Portal component
export interface UdicciComponentState extends UdicciBaseComponentState {
    CanRead: boolean,
}

export class UdicciComponent<Props extends UdicciComponentProps, State extends UdicciComponentState, SS = any> extends UdicciBaseComponent<Props, State, SS> {

    State: UdicciComponentState = {
        isLoaded: false,
        CanRead: false,
    }

    constructor(props: any) {
        super(props);
        // this.developerLog('%c UdicciComponent constructor props: %O', 'color: black;', props);
        // this.developerLog('%c UdicciComponent constructor this.State: %O', 'color: black;', this.State);
    }

    componentDidMount() {
        this.setState({ isLoaded: true,  })
    }
    
    componentDidUpdate(newProps: any, newState: any) {
        this.developerLog('%c UdicciComponent componentDidUpdate newProps: %O', 'color: gold;', newProps);
        this.developerLog('%c UdicciComponent componentDidUpdate newState: %O', 'color: gold;', newState);
        // this.developerLog('%c UdicciComponent componentDidUpdate this.state: %O', 'color: gold;', this.state);
    }
}


// *********************************************************************************************************************
// *********************************************************************************************************************
// Props for Plugin components
export interface UdicciPluginProps extends UdicciBaseComponentProps {
    data: any,
    pane: any,
}

// State for Plugin component
export interface udicciPluginComponentstate extends UdicciBaseComponentState {
    uid: any,
    CanView: boolean,
}

export class UdicciPluginComponent<Props extends UdicciPluginProps, State extends udicciPluginComponentstate, SS = any> extends UdicciBaseComponent<Props, State, SS> {

    State: udicciPluginComponentstate = {
        isLoaded: false,
        uid: null,
        CanView: true,
    }

    constructor(props: any) {
        super(props);
        // this.developerLog('%c UdicciPluginComponent constructor props: %O', 'color: black;', props);
        // this.developerLog('%c UdicciPluginComponent constructor this.State: %O', 'color: black;', this.State);
    }

    componentDidMount() {
        this.setState({ CanView: true,  })
    }
    
    componentDidUpdate(prevProps: any, prevState: any) {
        this.developerLog('%c UdicciPluginComponent componentDidUpdate prevProps: %O', 'color: gold;', prevProps);
        // this.developerLog('%c UdicciPluginComponent componentDidUpdate prevState: %O', 'color: gold;', prevState);
        // this.developerLog('%c UdicciPluginComponent componentDidUpdate this.state: %O', 'color: gold;', this.state);
    }

    getSomething() {
        let udicciContext: any = (this.props && this.props.udicciContext ? this.props.udicciContext : null);
        this.developerLog('%c UdicciPluginComponent getSomething udicciContext: %O', 'color: black;', udicciContext);
    
        let { udicci } = udicciContext.state;
        this.developerLog('%c UdicciPluginComponent getSomething udicci: %O', 'color: maroon;', udicci);
    
        let { selectedProfile, currentUser, selectedPorta } = udicci;
        this.developerLog('%c UdicciPluginComponent getSomething selectedProfile: %O', 'color: purple; font-weight: bold;', selectedProfile);
        this.developerLog('%c UdicciPluginComponent getSomething selectedPorta: %O', 'color: purple; font-weight: bold;', selectedPorta);
        this.developerLog('%c UdicciPluginComponent getSomething currentUser: %O', 'color: purple; font-weight: bold;', currentUser);
        let profile = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
        this.developerLog('%c UdicciPluginComponent getSomething profile: %O', 'color: maroon;', profile);
        var profileSettings = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
        this.developerLog('%c UdicciPluginComponent getSomething profileSettings: %O', 'color: maroon;', profileSettings);
        var portalSetting: any = (profileSettings && profileSettings.portal ? profileSettings.portal : null);
        this.developerLog('%c UdicciPluginComponent getSomething portalSetting: %O', 'color: maroon;', portalSetting);
        var portalDesignSetting: any = (portalSetting && portalSetting.design ? portalSetting.design : null);
        this.developerLog('%c UdicciPluginComponent getSomething portalDesignSetting: %O', 'color: maroon;', portalDesignSetting);
    }
}
