
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { forEach } from 'underscore';

import {
    Box, Paper, Typography, Table, TableHead, TableFooter, TableBody, TableRow, TableCell, Tooltip, MenuItem, Divider,
    Icon, IconButton, Button, Select, 
} from '@mui/material';

import FieldFilter from 'src/admin/field-filter';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

const SelectUdicciSourceFilter: React.FC<any> = (props) => {
    // const udicciContext = useContext(UdicciContext);
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    const udicciHelpers = useUdicciHelpers();

    let { mediatorName, settings, onSelectionChanged } = props;
    // console.log('%c settings: %O', 'color: purple;', settings);

    const [filterField, setFilterField] = useState<any>(null);
    const [remove, setRemove] = useState<boolean>(false);
    const [reorderFilterFields, setReorderFilterFields] = useState<boolean>(false);
    const [reorderConditions, setReorderConditions] = useState<boolean>(false);
    const [uiState, flashUI] = useState<boolean>(false);

    let filterSettings = (settings && settings.filterSettings ? settings.filterSettings : null);
    // console.log('%c filterSettings: %O', 'color: purple;', filterSettings);

    let structure = udicciHelpers.getMediatorStructure(mediatorName);
    // console.log('%c structure: %O', 'color: red;', structure);
    let fields = (structure && structure.UdicciMediatorFields ? structure.UdicciMediatorFields : []);
    // console.log('%c fields: %O', 'color: red;', fields);
    let virtualFields = (structure && structure.VirtualFields ? structure.VirtualFields : []);
    // console.log('%c virtualFields: %O', 'color: red;', virtualFields);

    const addNewFilterField = (event: any) => {
        let newFilterField = {
            displayName: '',
            jsonKey: '',
            dataType: 'String',
            conditionToCheck: 'is.equal.to',
            valueToCompare: '',
            appliedForRoles: null
        };
        // console.log('%c addNewFilterField newFilterField: %O', 'color: purple;', newFilterField);
        setFilterField(newFilterField);
        setRemove(false);
    };

    const editFilterField = (field: any, event: any) => {
        // console.log('%c editFilterField field: %O', 'color: purple;', field);
        setFilterField(field);
        setRemove(false);
    };

    const removeFilterField = (field: any, event: any) => {
        // console.log('%c removeFilterField field: %O', 'color: purple;', field);
        setFilterField(field);
        setRemove(true);
    };

    const toggleGroupSelection = (filterSettingIndex: number, event: any) => {
        // console.log('%c toggleGroupSelection filterSettingIndex: %O', 'color: purple;', filterSettingIndex);

        if (!settings) settings = {};

        let previousFilterSetting: any = null;
        if (filterSettingIndex > 0) previousFilterSetting = filterSettings[filterSettingIndex - 1];
        // console.log('%c previousFilterSetting: %O', 'color: purple;', previousFilterSetting);

        let previousItemConditionGroup: any = (previousFilterSetting && previousFilterSetting.conditionGroup ? previousFilterSetting.conditionGroup : '');
        // console.log('%c previousItemConditionGroup: %O', 'color: purple;', previousItemConditionGroup);

        let activatedByFilterSetting: any = filterSettings[filterSettingIndex];
        // console.log('%c activatedByFilterSetting: %O', 'color: purple;', activatedByFilterSetting);

        let conditionGroup: any = (activatedByFilterSetting && activatedByFilterSetting.conditionGroup ? activatedByFilterSetting.conditionGroup : '');
        // console.log('%c conditionGroup: %O', 'color: purple;', conditionGroup);
        if (!conditionGroup) {
            if (!previousItemConditionGroup) previousItemConditionGroup = '1.a';
            conditionGroup = previousItemConditionGroup;
            // console.log('%c conditionGroup: %O', 'color: purple;', conditionGroup);

            activatedByFilterSetting.conditionGroup = conditionGroup;
            if (previousFilterSetting) previousFilterSetting.conditionGroup = previousItemConditionGroup;
        } else {
            // remove all conditions to get started
            Object.entries(filterSettings).forEach(([key, stng]) => {
                // console.log('%c %s stng: %O', 'color: blue;', key, stng);
                filterSettings[key].conditionGroup = null;
            });
        }
        // console.log('%c filterSettings: %O', 'color: blue;', filterSettings);

        if (filterSettingIndex > 0 && previousFilterSetting) {
            filterSettings[filterSettingIndex - 1] = previousFilterSetting;
        }
        filterSettings[filterSettingIndex] = activatedByFilterSetting;

        settings.filterSettings = filterSettings;

        // console.log('%c settings: %O', 'color: green;', settings);
        if (onSelectionChanged) onSelectionChanged(settings);
    };

    const toggleReorderConditions = (event: any) => {
        setReorderConditions(!reorderConditions);
    };

    const moveFilterField = (filterSettingIndex: number, direction: string, event: any) => {
        // console.log('%c moveFilterField filterSettingIndex: %O', 'color: purple;', filterSettingIndex);
        // console.log('%c moveFilterField direction: %O', 'color: purple;', direction);

        if (filterSettings) {
            let defaultSortSettingsIsArray = Array.isArray(filterSettings);
            // console.log('%c defaultSortSettingsIsArray: %O', 'color: blue;', defaultSortSettingsIsArray);
            if (!defaultSortSettingsIsArray) {
                let newDefaultSortSettings: any[] = [];
                forEach(filterSettings, (stng: any, idx: number) => {
                    if (stng) newDefaultSortSettings.push(stng);
                });
                // console.log('%c newDefaultSortSettings: %O', 'color: blue;', newDefaultSortSettings);
                filterSettings = newDefaultSortSettings;
                // console.log('%c filterSettings: %O', 'color: purple;', filterSettings);
            }
        }

        if (filterSettings && filterSettings.length > 0) {
            let checkForEmptyKeySetting = filterSettings.find((stng: any) => {
                return ((stng.jsonKey === '') ? true : false);
            });
            // console.log('%c checkForEmptyKeySetting: %O', 'color: red;', checkForEmptyKeySetting);
            if (checkForEmptyKeySetting) {
                let newDefaultSortSettings: any[] = [];
                forEach(filterSettings, (stng: any, idx: number) => {
                    if (stng.jsonKey) newDefaultSortSettings.push(stng);
                });
                // console.log('%c newDefaultSortSettings: %O', 'color: blue;', newDefaultSortSettings);
                filterSettings = newDefaultSortSettings;
                // console.log('%c filterSettings: %O', 'color: purple;', filterSettings);
            }
        }

        let moveToIndex = -1;
        if (filterSettingIndex < (filterSettings.length - 1) && direction.toLowerCase() === 'down') {
            moveToIndex = filterSettingIndex + 1;
        }
        if (filterSettingIndex > 0 && direction.toLowerCase() === 'up') {
            moveToIndex = filterSettingIndex - 1;
        }
        // console.log('%c moveToIndex: %O', 'color: blue;', moveToIndex);

        if (moveToIndex >= 0) {
            // console.log('%c filterSettingIndex: %O', 'color: blue;', filterSettingIndex);
            // console.log('%c moveToIndex: %O', 'color: blue;', moveToIndex);
            let moveFromSetting = filterSettings[filterSettingIndex];
            // console.log('%c moveFromSetting: %O', 'color: blue;', moveFromSetting);
            let moveToSetting = filterSettings[moveToIndex];
            // console.log('%c moveToSetting: %O', 'color: blue;', moveToSetting);
            moveFromSetting.conditionGroup = moveToSetting.conditionGroup;
            filterSettings[filterSettingIndex] = moveFromSetting;

            filterSettings = udicciHelpers.swapArrayElements(filterSettings, filterSettingIndex, moveToIndex);
            // console.log('%c filterSettings: %O', 'color: blue;', filterSettings);

            settings.filterSettings = filterSettings;
        }

        // console.log('%c settings: %O', 'color: green;', settings);
        if (onSelectionChanged) onSelectionChanged(settings);
        setFilterField(null);
        setRemove(false);
    };

    const confirmRemoveFilterField = (field: any, event: any) => {

        if (!settings) settings = {};

        if (filterSettings) {
            let defaultSortSettingsIsArray = Array.isArray(filterSettings);
            // console.log('%c defaultSortSettingsIsArray: %O', 'color: blue;', defaultSortSettingsIsArray);
            if (!defaultSortSettingsIsArray) {
                let newDefaultSortSettings: any[] = [];
                Object.entries(filterSettings).forEach(([key, stng]) => {
                    if (key) newDefaultSortSettings.push(stng);
                });
                // console.log('%c newDefaultSortSettings: %O', 'color: blue;', newDefaultSortSettings);
                filterSettings = newDefaultSortSettings;
                // console.log('%c filterSettings: %O', 'color: purple;', filterSettings);
            }
        }

        if (filterSettings && filterSettings.length > 0) {
            let checkForEmptyKeySetting = filterSettings.find((stng: any) => {
                return ((stng.jsonKey === '') ? true : false);
            });
            // console.log('%c checkForEmptyKeySetting: %O', 'color: red;', checkForEmptyKeySetting);
            if (checkForEmptyKeySetting) {
                let newDefaultSortSettings: any[] = [];
                forEach(filterSettings, (stng: any, idx: number) => {
                    if (stng.jsonKey) newDefaultSortSettings.push(stng);
                });
                // console.log('%c newDefaultSortSettings: %O', 'color: blue;', newDefaultSortSettings);
                filterSettings = newDefaultSortSettings;
                // console.log('%c filterSettings: %O', 'color: purple;', filterSettings);
            }

            let checkForRemoveKeySetting = filterSettings.find((stng: any) => {
                return ((stng.jsonKey && stng.jsonKey === filterField.jsonKey) ? true : false);
            });
            // console.log('%c checkForRemoveKeySetting: %O', 'color: red;', checkForRemoveKeySetting);
            if (checkForRemoveKeySetting) {
                let newDefaultSortSettings: any[] = [];
                forEach(filterSettings, (stng: any, idx: number) => {
                    if (stng.jsonKey && stng.jsonKey !== filterField.jsonKey) newDefaultSortSettings.push(stng);
                });
                // console.log('%c newDefaultSortSettings: %O', 'color: blue;', newDefaultSortSettings);
                filterSettings = newDefaultSortSettings;
                // console.log('%c filterSettings: %O', 'color: purple;', filterSettings);
            }
        }

        settings.filterSettings = filterSettings;

        // console.log('%c settings: %O', 'color: green;', settings);
        if (onSelectionChanged) onSelectionChanged(settings);

        setFilterField(null);
        setRemove(false);
        flashUI(!uiState);
    };

    const updateFilterField = (fieldName: string, event: any) => {
        // console.log('%c updateFilterField fieldName: %O', 'color: purple;', fieldName);
        // console.log('%c event: %O', 'color: purple;', event);

        let trgt = event.target;
        // console.log('%c trgt: %O', 'color: purple;', trgt);
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c newValue: %O', 'color: purple;', newValue);

        if (!settings) settings = {};

        // console.log('%c settings: %O', 'color: green;', settings);
        if (onSelectionChanged) onSelectionChanged(settings);

        filterField[fieldName] = newValue;

        if (fieldName === 'jsonKey') {
            // get the display name
            let currentField = fields.find((fld: any) => {
                return ((fld.JsonFieldName === newValue) ? true : false);
            });
            // console.log('%c currentField: %O', 'color: red;', currentField);
            if (!currentField && virtualFields && virtualFields.length > 0) {
                currentField = virtualFields.find((fld: any) => {
                    return ((fld.JsonFieldName === newValue) ? true : false);
                });
                // console.log('%c currentField: %O', 'color: red;', currentField);
            }

            let displayName = filterField.displayName;
            if (currentField && (currentField.DisplayName || currentField.Name)) {
                if (currentField.DisplayName) {
                    displayName = currentField.DisplayName;
                } else if (currentField.DisplayName) {
                    displayName = currentField.DisplayName;
                }
            }
            // console.log('%c displayName: %O', 'color: red;', displayName);
            filterField.displayName = displayName;

            let dataType = filterField.displayName;
            if (currentField && (currentField.DisplayName || currentField.Name)) {
                if (currentField.DataType) {
                    dataType = currentField.DataType;
                } else if (currentField.DisplayName) {
                    dataType = currentField.DataType;
                }
            }
            // console.log('%c dataType: %O', 'color: red;', dataType);
            filterField.dataType = dataType;
        }
        // console.log('%c filterField: %O', 'color: red;', filterField);

        setFilterField(filterField);
        flashUI(!uiState);
    };

    const updateFilterSettings = (filterSettings: any) => {
        // console.log('%c updateFilterSettings filterSettings: %O', 'color: purple;', filterSettings);
        setFilterField(filterSettings);
        flashUI(!uiState);
    };

    const applyFilterFieldChanges = (event: any) => {
        // console.log('%c event: %O', 'color: purple;', event);

        if (!settings) settings = {};

        if (filterSettings) {
            let defaultSortSettingsIsArray = Array.isArray(filterSettings);
            // console.log('%c defaultSortSettingsIsArray: %O', 'color: blue;', defaultSortSettingsIsArray);
            if (!defaultSortSettingsIsArray) {
                let newDefaultSortSettings: any[] = [];
                Object.entries(filterSettings).forEach(([key, stng]) => {
                    if (key) newDefaultSortSettings.push(stng);
                });
                // console.log('%c newDefaultSortSettings: %O', 'color: blue;', newDefaultSortSettings);
                filterSettings = newDefaultSortSettings;
                // console.log('%c filterSettings: %O', 'color: purple;', filterSettings);
            }
        }

        if (filterSettings && filterSettings.length > 0) {
            let foundSettingsItem = false;
            forEach(filterSettings, (stng: any, idx: number) => {
                if (stng.jsonKey && stng.jsonKey === filterField.jsonKey) {
                    filterSettings[idx] = filterField;
                    foundSettingsItem = true;
                }
            });
            // console.log('%c filterSettings: %O', 'color: purple;', filterSettings);
            // console.log('%c foundSettingsItem: %O', 'color: purple;', foundSettingsItem);
            if (!foundSettingsItem) filterSettings.push(filterField);
            // console.log('%c filterSettings: %O', 'color: purple;', filterSettings);

            let checkForEmptyKeySetting = filterSettings.find((stng: any) => {
                return ((stng.jsonKey === '') ? true : false);
            });
            // console.log('%c checkForEmptyKeySetting: %O', 'color: red;', checkForEmptyKeySetting);
            if (checkForEmptyKeySetting) {
                let newDefaultSortSettings: any[] = [];
                forEach(filterSettings, (stng: any, idx: number) => {
                    if (stng.jsonKey) newDefaultSortSettings.push(stng);
                });
                // console.log('%c newDefaultSortSettings: %O', 'color: blue;', newDefaultSortSettings);
                filterSettings = newDefaultSortSettings;
                // console.log('%c filterSettings: %O', 'color: purple;', filterSettings);
            }
        } else {
            if (!filterSettings) filterSettings = [];
            filterSettings.push(filterField);
            // console.log('%c filterSettings: %O', 'color: purple;', filterSettings);
        }

        settings.filterSettings = filterSettings;

        // console.log('%c settings: %O', 'color: green;', settings);
        if (onSelectionChanged) onSelectionChanged(settings);

        setFilterField(null);
        flashUI(!uiState);
    };

    let conditionsSettingsElement: any = null;
    if (filterField && filterField.jsonKey) {
        let editFieldJsonKey = filterField.jsonKey;
        let editField = fields.find(function(rec: any) {
            return (rec.JsonFieldName === editFieldJsonKey)
        });
        // console.log('%c editField: %O', 'color: purple;', editField);
        if (!editField) {
            // check virtual fields
            editField = virtualFields.find(function(rec: any) {
                return (rec.JsonFieldName === editFieldJsonKey)
            });
        }
        // console.log('%c editField: %O', 'color: purple;', editField);

        if (editField) {
            conditionsSettingsElement = (
                <FieldFilter field={editField}
                             filterSettings={filterField}
                             onUpdateFilterSettings={updateFilterSettings}
                />
            );
        }
    } else if (filterField) {
        conditionsSettingsElement = (
            <Fragment>
                {'<==='} select a field
            </Fragment>
        );
    }

    let filterSettingsCount: number = 0;
    let foundEditSetting: boolean = false;
    let filterSettingRows: any[] = [];
    let conditionBinderAdded: boolean = false;
    if (filterSettings) {
        filterSettingsCount = filterSettings.length;
        let currentConditionGroup: any = null;
        forEach(filterSettings, (fltr: any, idxFilterSetting: number) => {
            // console.log('%c idxFilterSetting: %O', 'color: purple;', idxFilterSetting);
            // console.log('%c filter setting: %O', 'color: purple;', fltr);
            if (!fields) return null;
            let jsonKey = fltr.jsonKey;
            let fld = fields.find(function(rec: any) {
                return (rec.JsonFieldName === jsonKey)
            });
            if (!fld && virtualFields) {
                fld = virtualFields.find(function(rec: any) {
                    return (rec.JsonFieldName === jsonKey)
                });
            }
            if (!fld) return null;
            // console.log('%c filter fld: %O', 'color: green;', fld);

            let filterSettingIndex = idxFilterSetting;

            let conditionGroup = (fltr.conditionGroup ? fltr.conditionGroup : null);
            // console.log('%c conditionGroup: %O', 'color: green;', conditionGroup);

            let isCurrentConditionGroup = false;
            // console.log('%c currentConditionGroup: %O', 'color: green;', currentConditionGroup);
            if (currentConditionGroup && conditionGroup === currentConditionGroup) {
                isCurrentConditionGroup = true;
            } else if (!currentConditionGroup && conditionGroup) {
                isCurrentConditionGroup = true;
            }
            // console.log('%c isCurrentConditionGroup: %O', 'color: green;', isCurrentConditionGroup);

            let editThisRow = false;
            if (filterField && filterField.jsonKey === fld.JsonFieldName) {
                editThisRow = true;
                foundEditSetting = true;
            }
            // console.log('%c editThisRow: %O', 'color: green;', editThisRow);

            let removeRequestRow = (remove && filterField && filterField.jsonKey === jsonKey ? true : false);
            // console.log('%c removeRequestRow: %O', 'color: purple;', removeRequestRow);

            let conditionBinder = (fltr.conditionBinder === 'Or' ? 'Or' : 'And');
            // console.log('%c conditionBinder: %O', 'color: purple;', conditionBinder);

            // determine if this is in a condition group
            let conditionGroupStartTableCellElement = null;
            let conditionGroupEndTableCellElement = null;
            if (conditionGroup) {
                // console.log('%c conditionGroup: %O', 'color: green;', conditionGroup);
                // need to figure out what gets displayed
                let conditionGroupElementOptions: any = {
                    component: 'span', variant: 'subtitle1', noWrap: true
                };
                // console.log('%c conditionGroupElementOptions: %O', 'color: green;', conditionGroupElementOptions);
                if (!currentConditionGroup) {
                    currentConditionGroup = conditionGroup;
                    // console.log('%c currentConditionGroup: %O', 'color: green;', currentConditionGroup);
                    isCurrentConditionGroup = true;
                    conditionGroupStartTableCellElement = (
                        <TableCell style={{ verticalAlign: 'top' }}>
                            <Typography {...conditionGroupElementOptions}>(</Typography>
                        </TableCell>
                    );
                    conditionGroupEndTableCellElement = ( <TableCell></TableCell> );
                } else {
                    // console.log('%c currentConditionGroup: %O', 'color: green;', currentConditionGroup);
                    if (conditionGroup === currentConditionGroup) {
                        // check the 'next setting'
                        let nextFilterSettingIndex = filterSettingIndex + 1;
                        // console.log('%c nextFilterSettingIndex: %O', 'color: green;', nextFilterSettingIndex);
                        if (filterSettings.length > nextFilterSettingIndex) {
                            let nextFilterSetting = filterSettings[nextFilterSettingIndex];
                            // console.log('%c nextFilterSetting: %O', 'color: green;', nextFilterSetting);
                            let nextConditionGroup = (nextFilterSetting.conditionGroup ? nextFilterSetting.conditionGroup : null);
                            // console.log('%c nextConditionGroup: %O', 'color: green;', nextConditionGroup);
                            if (!nextConditionGroup || (nextConditionGroup && nextConditionGroup !== conditionGroup)) {
                                conditionGroupStartTableCellElement = (
                                    <TableCell style={{ verticalAlign: 'top' }}> {conditionBinder} </TableCell>
                                );
                                conditionGroupEndTableCellElement = (
                                    <TableCell>
                                        <Typography {...conditionGroupElementOptions}>)</Typography>
                                    </TableCell>
                                );
                                conditionBinderAdded = true;
                            }
                        } else {
                            conditionGroupStartTableCellElement = (
                                <TableCell style={{ verticalAlign: 'top' }}> {conditionBinder} </TableCell>
                            );
                            conditionGroupEndTableCellElement = (
                                <TableCell>
                                    <Typography {...conditionGroupElementOptions}>)</Typography>
                                </TableCell>
                            );
                            conditionBinderAdded = true;
                        }
                    } else {
                        currentConditionGroup = conditionGroup;
                    }
                }
            } else if (filterSettingsCount > 1) {
                conditionGroupStartTableCellElement = ( <TableCell></TableCell> );
                conditionGroupEndTableCellElement = ( <TableCell></TableCell> );
            }

            let groupClauseBindIcon = null;
            if (filterSettingsCount > 1) {
                let gcIcon = null;
                let gcTitle = 'Condition Grouping';
                if (conditionGroup) {
                    gcIcon = (<Icon fontSize="small">surround_sound_outlined</Icon>);
                    gcTitle = 'Remove Condition Groupings';
                } else {
                    gcIcon = (<Icon fontSize="small">surround_sound</Icon>);
                    gcTitle = 'Group this condition with previous condition';
                }
                groupClauseBindIcon = (
                    <IconButton color="primary"
                                size="small"
                                onClick={(evt: any) => toggleGroupSelection(filterSettingIndex, evt)}
                                aria-label={gcTitle}
                                title={gcTitle}
                    >
                        {gcIcon}
                    </IconButton>
                );
            }

            let conditionBinderDisplayElement = null;
            let tableRow = null;
            if (removeRequestRow) {
                tableRow = (<TableRow key={'udicci.filter.settings.row.empty.' + idxFilterSetting}>
                        <TableCell align="center" colSpan={(filterSettingsCount > 1 ? 4 : 3)}>
                            <Typography variant="caption">
                                Are you sure you want to remove
                            </Typography>
                            &nbsp;
                            <Typography component="span" variant="subtitle1" color="secondary">
                                {filterField.displayName}
                            </Typography>
                            &nbsp;
                            <Typography variant="caption">
                                from the filter options?
                            </Typography>
                            <Button variant="contained" color="primary" onClick={(evt: any) => confirmRemoveFilterField(filterField, evt)}>
                                Delete
                                <Icon fontSize="small">delete</Icon>
                            </Button>
                            &nbsp;
                            <Button variant="contained" color="info" onClick={(evt: any) => removeFilterField(null, evt)}>
                                Cancel
                                <Icon fontSize="small">cancel</Icon>
                            </Button>
                        </TableCell>
                    </TableRow>);
            } else if (editThisRow) {
                if (filterSettingsCount > 1) {
                    // console.log('%c filterSettingIndex: %O', 'color: green;', filterSettingIndex);
                    // console.log('%c conditionBinderAdded: %O', 'color: green;', conditionBinderAdded);
                    // console.log('%c isCurrentConditionGroup: %O', 'color: green;', isCurrentConditionGroup);
                    if (filterSettingIndex <= 0 || (conditionBinderAdded && isCurrentConditionGroup)) {
                        conditionBinderDisplayElement = (
                            <TableCell></TableCell>
                        );
                    } else {
                        conditionBinderDisplayElement = (
                            <TableCell style={{ verticalAlign: 'top' }}>
                                <Select value={conditionBinder}
                                        id='select-condition-binder'
                                        onChange={(evt: any) => updateFilterField('conditionBinder', evt)}
                                >
                                    <MenuItem value="And"> And </MenuItem>
                                    <MenuItem value="Or"> Or </MenuItem>
                                </Select>
                            </TableCell>
                        );
                    }
                }
                tableRow = (<TableRow key={'udicci.filter.settings.row.' + idxFilterSetting}>
                        <TableCell size="small" style={{ verticalAlign: 'top' }}>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => applyFilterFieldChanges(evt)}
                                        aria-label={'Save Field Filter Settings'}
                                        title={'Save Field Filter Settings'}
                            >
                                <Icon fontSize="small">save</Icon>
                            </IconButton>
                            {groupClauseBindIcon}
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => editFilterField(null, evt)}
                                        aria-label={'Cancel edit Field Filter Settings'}
                                        title={'Cancel edit Field Filter Settings'}
                            >
                                <Icon fontSize="small">cancel</Icon>
                            </IconButton>
                        </TableCell>
                        {conditionBinderDisplayElement}
                        {conditionGroupStartTableCellElement}
                        <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                            <Select value={filterField.jsonKey}
                                    id='select-filter-field'
                                    onChange={(evt: any) => updateFilterField('jsonKey', evt)}
                            >
                                <MenuItem disabled value="">
                                    <em>Select Field</em>
                                </MenuItem>
                                {fields.map((fld: any) => {
                                        // console.log('%c fld: %O', 'color: red;', fld);
                                        return (
                                            <MenuItem key={'filter.field.' + fld.JsonFieldName}
                                                    value={fld.JsonFieldName}
                                            >
                                                {fld.DisplayName}
                                            </MenuItem>
                                        );
                                })}
                                {virtualFields.map((fld: any) => {
                                        // console.log('%c fld: %O', 'color: red;', fld);
                                        return (
                                            <MenuItem key={'filter.field.' + fld.JsonFieldName}
                                                    value={fld.JsonFieldName}
                                            >
                                                {fld.DisplayName}
                                            </MenuItem>
                                        );
                                })}
                            </Select>
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>
                            {conditionsSettingsElement}
                        </TableCell>
                        {conditionGroupEndTableCellElement}
                    </TableRow>);
            } else {
                let filterFieldsIcons = null;
                if (reorderConditions) {
                    filterFieldsIcons = (
                        <Fragment>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => moveFilterField(filterSettingIndex, 'DOWN', evt)}
                                        aria-label={'Move Field Down'}
                                        title={'Move Field Down'}
                                        disabled={(filterSettingIndex < filterSettings.length - 1 ? false : true)}
                            >
                                <Icon fontSize="small">arrow_drop_down</Icon>
                            </IconButton>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => moveFilterField(filterSettingIndex, 'UP', evt)}
                                        aria-label={'Move Field Up'}
                                        title={'Move Field Up'}
                                        disabled={(filterSettingIndex > 0 ? false : true)}
                            >
                                <Icon fontSize="small">arrow_drop_up</Icon>
                            </IconButton>
                        </Fragment>
                    );
                } else {
                    filterFieldsIcons = (
                        <Fragment>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => editFilterField(fltr, evt)}
                                        aria-label={'Edit Field Filter Settings'}
                                        title={'Edit Field Filter Settings'}
                            >
                                <Icon fontSize="small">edit</Icon>
                            </IconButton>
                            {groupClauseBindIcon}
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => removeFilterField(fltr, evt)}
                                        aria-label={'Remove Field from Filter'}
                                        title={'Remove Field from Filter'}
                            >
                                <Icon fontSize="small">remove_circle_outline</Icon>
                            </IconButton>
                        </Fragment>
                    );
                }

                let conditionDisplayElement: any = null;
                if (fltr) {
                    // console.log('%c filter fld: %O', 'color: purple;', fld);
                    let typoSettings: any = {
                        component: 'span',
                        noWrap: false
                    };

                    let conditionToCheck: string = fltr.conditionToCheck;
                    let conditionToCheckElement: any = null;
                    if (conditionToCheck) {
                        let ctcValue: string = '';
                        if (conditionToCheck.indexOf('.') >= 0) {
                            ctcValue = conditionToCheck.replace(/\./g, " ");
                        } else {
                            ctcValue = conditionToCheck;
                        }
                        conditionToCheckElement = (
                            <Typography variant="caption" {...typoSettings}>
                                {ctcValue}
                            </Typography>
                        );
                    }

                    let valueToCompareElement: any = null;
                    if (conditionToCheck !== 'between') {
                        let valueToCompare: any = fltr.valueToCompare;
                        if (valueToCompare) {
                            typoSettings.component = 'div';
                            valueToCompareElement = (
                                <Typography variant="body1" color='textPrimary' {...typoSettings}>
                                    {valueToCompare}
                                </Typography>
                            );
                        }
                    } else {
                        let styl: any = { paddingLeft: '8px' };
                        let lowValue: any = fltr.lowValue;
                        let lowValueElement: any = null;
                        if (lowValue) {
                            lowValueElement = (
                                <Typography variant="body1" color='textPrimary' {...typoSettings}>
                                    {lowValue}
                                </Typography>
                            );
                        }
                        let highValue: any = fltr.highValue;
                        let highValueElement: any = null;
                        if (highValue) {
                            highValueElement = (
                                <Typography variant="body1" color='textPrimary' {...typoSettings} sx={styl}>
                                    {highValue}
                                </Typography>
                            );
                        }
                        let valueDividerElement: any = null;
                        if (lowValueElement && highValueElement) {
                            valueDividerElement = (
                                <Typography variant="caption" {...typoSettings} sx={styl}>
                                    and
                                </Typography>
                            );
                        }

                        if (lowValueElement || highValueElement) {
                            valueToCompareElement = (
                                <div>
                                    {lowValueElement}
                                    {valueDividerElement}
                                    {highValueElement}
                                </div>
                            );
                        }
                    }

                    conditionDisplayElement = (
                        <Paper sx={{ padding: '8px' }}>
                            {conditionToCheckElement}
                            {valueToCompareElement}
                        </Paper>
                    );
                }

                // console.log('%c filterSettingsCount: %O', 'color: green;', filterSettingsCount);
                if (filterSettingsCount > 1) {
                    let previousFilterSetting: any = null;
                    if (filterSettingIndex > 0) previousFilterSetting = filterSettings[filterSettingIndex - 1];
                    // console.log('%c previousFilterSetting: %O', 'color: purple;', previousFilterSetting);
            
                    let previousItemConditionGroup: string = (previousFilterSetting && previousFilterSetting.conditionGroup ? previousFilterSetting.conditionGroup : '');
                    // console.log('%c previousItemConditionGroup: %O', 'color: purple;', previousItemConditionGroup);
            
                    if (filterSettingIndex <= 0 || (conditionBinderAdded && isCurrentConditionGroup)) {
                        conditionBinderDisplayElement = (
                            <TableCell></TableCell>
                        );
                    } else if (!conditionBinderAdded && isCurrentConditionGroup && previousItemConditionGroup) {
                        conditionBinderDisplayElement = (
                            <TableCell></TableCell>
                        );
                        conditionGroupStartTableCellElement = (
                            <TableCell style={{ verticalAlign: 'top' }}>
                                <Typography variant="subtitle2" color='primary' noWrap={true}>
                                    {conditionBinder}
                                </Typography>
                            </TableCell>
                        );
                    } else {
                        conditionBinderDisplayElement = (
                            <TableCell style={{ verticalAlign: 'top' }}>
                                <Typography variant="subtitle2" color='primary' noWrap={true}>
                                    {conditionBinder}
                                </Typography>
                            </TableCell>
                        );
                    }
                }

                tableRow = (<TableRow key={'udicci.filter.settings.row.' + idxFilterSetting}>
                        <TableCell size="small" style={{ verticalAlign: 'top' }}>
                            {filterFieldsIcons}
                        </TableCell>
                        {conditionBinderDisplayElement}
                        {conditionGroupStartTableCellElement}
                        <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                            <Typography component="span" variant="subtitle1" color='primary' noWrap={true}>
                                {fld.Name}
                            </Typography>
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>
                            {conditionDisplayElement}
                        </TableCell>
                        {conditionGroupEndTableCellElement}
                    </TableRow>);
            }
            if (tableRow) filterSettingRows.push(tableRow);
        });
    }

    if (filterField && !foundEditSetting) {
        let conditionBinderDisplayElement = null;
        if (filterSettingsCount > 1) {
            conditionBinderDisplayElement = (
                <TableCell></TableCell>
            );
        } else {
            let conditionBinder = (filterField.conditionBinder === 'Or' ? 'Or' : 'And');
            conditionBinderDisplayElement = (
                <TableCell style={{ verticalAlign: 'top' }}>
                    <Select value={conditionBinder}
                            id='select-condition-binder'
                            onChange={(evt: any) => updateFilterField('conditionBinder', evt)}
                    >
                        <MenuItem value="And"> And </MenuItem>
                        <MenuItem value="Or"> Or </MenuItem>
                    </Select>
                </TableCell>
            );
        }

        filterSettingRows.push(
            <TableRow key={'udicci.filter.settings.row.' + filterField.jsonKey}>
                <TableCell size="small" style={{ verticalAlign: 'top' }}>
                    <IconButton color="primary"
                                size="small"
                                onClick={(evt: any) => applyFilterFieldChanges(evt)}
                                aria-label={'Save Field Filter Settings'}
                                title={'Save Field Filter Settings'}
                    >
                        <Icon fontSize="small">save</Icon>
                    </IconButton>
                    <IconButton color="primary"
                                size="small"
                                onClick={(evt: any) => editFilterField(null, evt)}
                                aria-label={'Cancel edit Field Filter Settings'}
                                title={'Cancel edit Field Filter Settings'}
                    >
                        <Icon fontSize="small">cancel</Icon>
                    </IconButton>
                </TableCell>
                {conditionBinderDisplayElement}
                <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                    <Select value={filterField.jsonKey}
                            id='select-filter-field'
                            onChange={(evt: any) => updateFilterField('jsonKey', evt)}
                    >
                        <MenuItem disabled value="">
                            <em>Select Field</em>
                        </MenuItem>
                        {fields.map((fld: any) => {
                                // console.log('%c fld: %O', 'color: red;', fld);
                                return (
                                    <MenuItem key={'filter.field.' + fld.JsonFieldName}
                                            value={fld.JsonFieldName}
                                    >
                                        {fld.DisplayName}
                                    </MenuItem>
                                );
                        })}
                        {virtualFields.map((fld: any) => {
                                // console.log('%c fld: %O', 'color: red;', fld);
                                return (
                                    <MenuItem key={'filter.field.' + fld.JsonFieldName}
                                              value={fld.JsonFieldName}
                                    >
                                        {fld.DisplayName}
                                    </MenuItem>
                                );
                        })}
                    </Select>
                </TableCell>
                <TableCell style={{ verticalAlign: 'top' }}>
                    {conditionsSettingsElement}
                </TableCell>
            </TableRow>
        );
    } else if (filterSettingRows.length <= 0) {
        filterSettingRows.push(<TableRow key={'udicci.filter.settings.row.empty'}>
                <TableCell align="center" colSpan={3}>
                    <Typography variant="caption">
                        Click on the 
                    </Typography>
                    <IconButton color="primary"
                                size="small"
                                onClick={(evt: any) => addNewFilterField(evt)}
                                aria-label={'Add a Field to Filter'}
                                title={'Add a Field to Filter'}
                    >
                        <Icon fontSize="small">add</Icon>
                    </IconButton>
                    <Typography variant="caption">
                        to start building the default list filter.
                    </Typography>
                </TableCell>
            </TableRow>);
    }

    let rowAndOrHeaderContent: any = null;
    let rowGroupStartContent: any = null;
    let rowGroupEndContent: any = null;
    let enableReorderIconButton: any = null;
    if (filterSettingsCount > 1) {
        rowAndOrHeaderContent = ( <TableCell></TableCell> );
        rowGroupStartContent = ( <TableCell></TableCell> );
        rowGroupEndContent = ( <TableCell></TableCell> );

        let recorderIconElement: any = null;
        let recorderIconLabel = 'Reorder Conditions';
        if (reorderConditions) {
            recorderIconElement = ( <Icon fontSize="small">playlist_add_check</Icon> );
            recorderIconLabel = 'Manage Conditions';
        } else {
            recorderIconElement = ( <Icon fontSize="small">reorder</Icon> );
        }
        enableReorderIconButton = (
            <IconButton color="primary"
                        size="small"
                        onClick={(evt: any) => toggleReorderConditions(evt)}
                        aria-label={recorderIconLabel}
                        title={recorderIconLabel}
            >
                {recorderIconElement}
            </IconButton>
        );
    }

    let filterSettingsElement: any = (
        <Box>
            <Typography component="span" variant="subtitle1" noWrap={true}>
                Filter Settings
            </Typography>
            {enableReorderIconButton}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => addNewFilterField(evt)}
                                        aria-label={'Add a Field to Filter'}
                            >
                                <Icon fontSize="small">add</Icon>
                            </IconButton>
                        </TableCell>
                        {rowAndOrHeaderContent}
                        {rowGroupStartContent}
                        <TableCell>Name</TableCell>
                        <TableCell>Conditions</TableCell>
                        {rowGroupEndContent}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filterSettingRows}
                </TableBody>
            </Table>
        </Box>
    );

    return filterSettingsElement;
}

SelectUdicciSourceFilter.propTypes = { classes: PropTypes.object.isRequired };
SelectUdicciSourceFilter.contextTypes = { udicci: PropTypes.object, portalContext: PropTypes.object }

export default SelectUdicciSourceFilter;
