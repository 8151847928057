import React from 'react';
import { lightGreen, orange } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import { withUdicciTheme } from 'src/components/udicci-hoc';

const uitEngageButtonStyles = {
    color: orange[700],
    fontSize: '0.7em',
};
const uitEngageButtonHighlightStyles = {
    color: orange[700],
    textDecorationColor: lightGreen['A400'] + ' !important',
    textDecoration: 'underline',
    fontSize: '0.7em',
};

const uitEngageButton: React.FC<any> = (props: any) => {
    let { text, enabled, onClick } = props;

    let sxClass = (enabled ? uitEngageButtonHighlightStyles : uitEngageButtonStyles);
    let buttonStyle: any = { marginRight: '4px' };
    if (enabled) buttonStyle.cursor = 'pointer';
    let engageButton: any = (
        <span style={buttonStyle} onClick={(enabled ? onClick : null)}>
            <Typography variant="body2" component="span" sx={sxClass}>
                {text}
            </Typography>
            <Typography variant="body2" component="span" sx={sxClass}>
                .
            </Typography>
        </span>
    );

    return engageButton;
};

export default withUdicciTheme(uitEngageButton);
