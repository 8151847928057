
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { udicciStyles } from 'src/theme/shared-styles';

export const EngagementRequestUI: React.FC<any> = (props) => {
    // console.log('%c EngagementRequestUI props: %O', 'color: blue;', props);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const cancelAction = (evt: any) => {
        // console.log('%c cancelAction actionIsProcessing: %O', 'color: red;', actionIsProcessing);
        if (props && props.onClose) props.onClose();
    };

    return (
        <Box sx={udicciClasses.content}>
            EngagementRequest UI should be implemented here.
            <Box>
                <Button onClick={cancelAction}>Cancel</Button>
            </Box>
        </Box>
    );
};
