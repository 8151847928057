// import React, { Fragment, useState } from 'react';

import { styled } from '@mui/material/styles';

import { filter, find, values } from 'underscore';

import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { useUdicciContext, formatStringForDisplay } from 'src/context/udicci-context';
import useUdicciSocialSolution from "src/hooks/useUdicciSocialSolution";

// import { udicciStyles } from 'src/theme/shared-styles';

const SocialSolutionContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    // border: '0.7px dashed #132274',
    borderRadius: '8px',
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    // cursor: 'pointer'
}));

const FeatureButton = styled(Button)(({ theme }) => ({
    textTransform: 'none'
}));

export default function SocialSolutionCard(props: any) {
    // console.log('%c SocialSolutionCard props: %O', 'color: maroon;', props);

    let { solution, onShowSocialSolution, onShowSocialSolutionFeature } = props;

    // const theme = useTheme();
    // const udicciClasses = udicciStyles(theme);
    const udicciContext = useUdicciContext();
    const udicciSocialSolution = useUdicciSocialSolution(solution);

    if (!solution) return null;

    let { udicci } = udicciContext.state;
    // console.log('%c udicci: %O', 'color: red;', udicci);
    // console.log('%c data: %O', 'color: red;', data);

    let {
        socialSolution,
        // getSocialSolutionSetting,
        // getSocialSolutionEngagementActions,
        getFeaturePermissions,
    } = udicciSocialSolution;
    // console.log('%c SocialSolutionCard socialSolution: %O', 'color: red;', socialSolution);
    if (socialSolution.recordId === udicci.socialSolutionUdicciIT && !udicci.isCurrentUserUlysses()) return true;

    let solutionPermissions: any | null = (socialSolution.permissions ? socialSolution.permissions : null);
    // console.log('%c solutionPermissions: %O', 'color: hotpink;', solutionPermissions);
    let solutionPermissionOverrides: any | null = (solutionPermissions && solutionPermissions.overrides ? solutionPermissions.overrides : null);
    // console.log('%c solutionPermissionOverrides: %O', 'color: hotpink;', solutionPermissionOverrides);

    let ssKey = 'engage.in.social.solution.' + socialSolution.recordId.toString();

    // console.log('%c socialSolution: %O', 'color: hotpink;', socialSolution);
    let ssData = (socialSolution.data ? socialSolution.data : null);
    // console.log('%c ssData: %O', 'color: hotpink;', ssData);
    let ssSettings = (ssData.jsonSettingsJson ? ssData.jsonSettingsJson : null);
    // console.log('%c ssSettings: %O', 'color: hotpink;', ssSettings);

    let features = (ssData && ssData.Features ? ssData.Features : null);
    // console.log('%c features: %O', 'color: hotpink;', features);
    let mediators = (ssSettings && ssSettings.mediators ? ssSettings.mediators : null);
    // console.log('%c mediators: %O', 'color: hotpink;', mediators);

    let featureButtonElements: any[] = [];
    if (mediators !== null && features && features.length > 0) {
        features.forEach(function(ftre: any) {
            // console.log('%c ftre: %O', 'color: blue;', ftre);
            // console.log('%c idx: %O', 'color: blue;', idx);

            let featurePermissions: any = getFeaturePermissions({ feature: ftre });
            // console.log('%c featurePermissions: %O', 'color: blue;', featurePermissions);

            let featureMediators: any = null;
            if (featurePermissions.CanView) {
                featureMediators = filter(values(mediators), function(med: any) {
                    // console.log('%c med: %O', 'color: hotpink;', med);
    
                    let mediatorName = med.name;
                    // console.log('%c mediatorName: %O', 'color: hotpink;', mediatorName);

                    // let featureMediatorPermissions: any = getMediatorPermissions({ feature: ftre, mediator: mediatorName });
                    // console.log('%c featureMediatorPermissions: %O', 'color: orange;', featureMediatorPermissions);

                    let hasPermission: boolean = solutionPermissions.CanView;
                    if (mediatorName && solutionPermissionOverrides && solutionPermissionOverrides[mediatorName]) {
                        let mediatorPermissionOverrides: any = solutionPermissionOverrides[mediatorName];
                        // console.log('%c mediatorPermissionOverrides: %O', 'color: blue;', mediatorPermissionOverrides);
                        if (mediatorPermissionOverrides && mediatorPermissionOverrides.CanView !== undefined) {
                            hasPermission = mediatorPermissionOverrides.CanView;
                        }
                    }
                    // console.log('%c hasPermission: %O', 'color: blue;', hasPermission);
    
                    let isMediatorMainFeature: boolean = (med.feature === ftre.Name ? true : false)
                    let isMediatorFeature: boolean = false;
    
                    if (med.features && med.features.length > 0) {
                        let checkMedFeature = find(med.features, (f: any) => f.name === ftre.Name);
                        // console.log('%c checkMedFeature: %O', 'color: blue;', checkMedFeature);
                        if (checkMedFeature) {
                            isMediatorFeature = true;
                        }
                    }
                    return (hasPermission && (isMediatorMainFeature || isMediatorFeature));
                });
            }
            // console.log('%c featureMediators: %O', 'color: blue;', featureMediators);
            if (featureMediators && featureMediators.length > 0) {
                let featureButtonElement: any = (
                    <FeatureButton size="small" variant="text" key={ssKey + '.feature.' + ftre.UdicciRecordId.toString()}
                                   onClick={(evt: any) => onShowSocialSolutionFeature(socialSolution, ftre)}>
                        <Typography variant="clickableSubTitle2" component="span">{ftre.Name}</Typography>
                        <Typography variant="clickableSubTitle2" component="span">.</Typography>
                    </FeatureButton>
                );

                featureButtonElements.push(featureButtonElement);
            }
        });
    }

    let socialSolutionCardElement: any = null;
    if (featureButtonElements.length > 0) {
        let ssNameElement = (
            <Typography component="div" variant="clickableSubTitle1" onClick={(evt: any) => onShowSocialSolution(socialSolution)}>
                {formatStringForDisplay(socialSolution.title)}
            </Typography>
        );
        let ssDescriptionElement = (
            <Typography component="div" variant="caption">
                {formatStringForDisplay(socialSolution.description)}
            </Typography>
        );
        let gridItemProps = {
            // onClick: () => onShowSocialSolution(socialSolution),
            key: 'engage.in.social.solution.' + socialSolution.recordId
        };

        socialSolutionCardElement = (
            <Grid item xs={12} md={12} {...gridItemProps}>
                <Paper elevation={2} sx={{ borderRadius: '8px', background: 'unset' }}>
                    <SocialSolutionContainer>
                        {ssNameElement}
                        {ssDescriptionElement}
                        <Box>{featureButtonElements}</Box>
                    </SocialSolutionContainer>
                </Paper>
            </Grid>
        );
    }

    return socialSolutionCardElement;
}
