
import React, { useState } from 'react';

import { forEach } from 'underscore';

import {
    Box,Typography, TextField, Select, MenuItem, Button, Autocomplete
} from '@mui/material';

import { useUdicciContext } from 'src/context/udicci-context'
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

const LinkedMediatorForm: React.FC<any> = (props) => {
    // console.log('%c LinkedMediatorForm props: %O', 'color: red;', props);

    let { lum, structure, onClose, onChange } = props;

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();

    let { udicci } = udicciContext.state;

    let lumId: number = (lum.UdicciMediatorFieldId ? lum.UdicciMediatorFieldId : 0);
    // let lumIsLocked: boolean = (lumId > 0 ? true : false);

    const [uiState, flashUI] = useState<boolean>(false);

    let allMediators = udicciHelpers.preloadMediatorData('Udicci Mediators', udicci.socialSolutionUdicciIT, false, udicci.ulyssesDConstantineProfileId);
    // console.log('%c LinkedMediatorForm allMediators: %O', 'color: maroon;', allMediators);
    if (!allMediators || (allMediators && allMediators.length < 7)) {
        udicciHelpers.preloadMediatorData('Udicci Mediators', udicci.socialSolutionUdicciIT, true, udicci.ulyssesDConstantineProfileId);
    }

    const changeMediatorLumValue = (fieldName: string, evt: any) => {
        // console.log('%c changeMediatorLumValue lum: %O', 'color: maroon;', lum);
        // console.log('%c changeMediatorLumValue fieldName: %O', 'color: maroon;', fieldName);
        let trgt: any = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c changeMediatorLumValue newValue: %O', 'color: maroon;', newValue);

        if (fieldName === 'RelationshipType') {
            if (newValue === 'one.to.many') {
                lum.MaxAssociationsToLeft = 1;
                lum.MaxAssociationsToRight = -1;
            } else if (newValue === 'many.to.one') {
                lum.MaxAssociationsToLeft = -1;
                lum.MaxAssociationsToRight = 1;
            } else if (newValue === 'one.to.one') {
                lum.MaxAssociationsToLeft = 1;
                lum.MaxAssociationsToRight = 1;
            } else if (newValue === 'many.to.many') {
                lum.MaxAssociationsToLeft = -1;
                lum.MaxAssociationsToRight = -1;
            }
        } else {
            lum[fieldName] = newValue;
        }

        lum.isDirty = true;
        if (onChange) onChange(lum);
        flashUI(!uiState);
    };

    let nameElement: any = (
        <Box>
            <Typography variant="overFieldLabel" gutterBottom>Name</Typography>
            <TextField fullWidth
                       value={lum.Name}
                       onChange={(evt: any) => changeMediatorLumValue('Name', evt)}
                       margin="normal"
                       variant='outlined'
            />
        </Box>
    );

    let descriptionElement: any = (
        <Box>
            <Typography variant="overFieldLabel" gutterBottom>Description</Typography>
            <TextField fullWidth multiline maxRows={7} minRows={3}
                       value={lum.Description}
                       onChange={(evt: any) => changeMediatorLumValue('Description', evt)}
                       margin="normal"
                       variant='outlined'
            />
        </Box>
    );

    let sortedMediators: any[] = [];
    if (allMediators && allMediators.length > 0) {
        sortedMediators = allMediators.sort((a: any, b: any) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;  // ascending
            if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;  // descending
            return 0 //default return value (no sorting)
        });
    }
    // console.log('%c sortedMediators: %O', 'color: red;', sortedMediators);

    let mediatorOptions: any[] = [];
    forEach(sortedMediators, (mediator: any) => {
        mediatorOptions.push({ id: mediator.recordId, label: mediator.title });
    });

    const options: any[] = mediatorOptions.map((option: any) => {
        const firstLetter = option.label[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });
    
    let leftMediatorSelector: any = (
        <Box>
            <Typography variant="overFieldLabel" component="div">Left Mediator</Typography>
            <Autocomplete sx={{ width: '250px', margin: '4px' }} value={lum.LeftUdicciMediatorName}
                          options={options.sort((a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter))}
                          groupBy={(option: any) => option.firstLetter}
                          onChange={(evt: any) => changeMediatorLumValue('LeftUdicciMediatorId', evt)}
                          renderInput={(params) => <TextField {...params} />}
            />
        </Box>
    );

    let rightMediatorSelector: any = (
        <Box>
            <Typography variant="overFieldLabel" component="div">Right Mediator</Typography>
            <Autocomplete sx={{ width: '250px', margin: '4px' }} value={lum.RightUdicciMediatorName}
                          options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                          groupBy={(option) => option.firstLetter}
                          onChange={(evt: any) => changeMediatorLumValue('RightUdicciMediatorId', evt)}
                          renderInput={(params) => <TextField {...params} />}
            />
        </Box>
    );

    let maxAssociationsToLeft = (lum && lum.MaxAssociationsToLeft !== 0 ? lum.MaxAssociationsToLeft : 1);
    let maxAssociationsToRight = (lum && lum.MaxAssociationsToRight !== 0 ? lum.MaxAssociationsToRight : -1);
    let relationshipTypeValue = '';
    if (maxAssociationsToLeft === 1) {
        if (maxAssociationsToRight === 1) {
            relationshipTypeValue = 'one.to.one';
        } else {
            relationshipTypeValue = 'one.to.many';
        }
    } else {
        if (maxAssociationsToRight === 1) {
            relationshipTypeValue = 'many.to.one';
        } else {
            relationshipTypeValue = 'many.to.many';
        }
    }

    let relationshipTypeSelector: any = (
        <Box>
            <Typography variant="overFieldLabel" component="div">Relationship Type</Typography>
            <Select value={relationshipTypeValue} onChange={(evt: any) => changeMediatorLumValue('RelationshipType', evt)}>
                <MenuItem value={''}>Select Relationship Type</MenuItem>
                <MenuItem value={'one.to.many'}>One to Many</MenuItem>
                <MenuItem value={'many.to.one'}>Many to One</MenuItem>
                <MenuItem value={'one.to.one'}>One to One</MenuItem>
                <MenuItem value={'many.to.many'}>Many to Many</MenuItem>
            </Select>
        </Box>
    );

    let isDirty = (lum && lum.isDirty ? true : false);
    let formButtonsElement: any = (
        <Box sx={{ margin: '8px' }}>
            <Button onClick={(evt: any) => onClose()} variant="contained" color="info" sx={{ margin: '4px' }}>
                Cancel
            </Button>
            <Button onClick={(evt: any) => onClose()} variant="contained" color="success" disabled={!isDirty} sx={{ margin: '4px' }}>
                Save Changes
            </Button>
        </Box>
    );

    return (
        <Box>
            <Box>
                {nameElement}
                {descriptionElement}
                <Box sx={{ display: 'flex' }}>
                    {leftMediatorSelector}
                    {rightMediatorSelector}
                    {relationshipTypeSelector}
                </Box>
            </Box>
            {formButtonsElement}
        </Box>
    );
}

export default LinkedMediatorForm;
