
import { Fragment, useState, useContext } from 'react';

import { useTheme, styled } from '@mui/material/styles';

import { sortBy, forEach, find } from 'underscore';

import {
    Box, Paper, Typography, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider,
    Button, Icon,InputLabel, Input, IconButton, TextField, FormControl, Card, CardHeader,
    Dialog, DialogContent, DialogTitle, DialogActions, Drawer, CssBaseline, AppBar, Toolbar,
} from '@mui/material';
import { AppBarProps } from '@mui/material/AppBar';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import {
    UdicciContext,
    getUdicciData,
    subscribe as subscribeToUdicci
} from 'src/context/udicci-context';

import {
    RecordContextProvider,
    useUdicciRecordContext,
    saveRecord as saveUdicciRecord,
    subscribe as subscribeToRecord
} from 'src/context/new-record-context';

// import { createMarkup } from 'src/context/udicci-context';
import { UdicciRecord } from 'src/classes/udicci-record';
// import UdicciForm from './udicci-form';
import ChainStoryBit from './chain-story-bits';
// import Relationships from './relationships';

import { udicciStyles } from 'src/theme/shared-styles';

const drawerWidth: number = 240;

const MediaBitContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    display: 'flow-root',
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
        ...(open && {
            transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: `${drawerWidth}px`,
    }),
}));
  
interface MenuAppBarProps extends AppBarProps {
    open?: boolean;
}

const AppBarWrapper = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<MenuAppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
            // width: `calc(100% - ${drawerWidth}px)`,
            // marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
  
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    // alignItems: 'center',
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function ChainStories(this: any, props: any) {
    // console.log('%c ChainStories props: %O', 'color: maroon;', props);

    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const udicciContext = useContext(UdicciContext);
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    let { data, udicci } = udicciContext.state;
    // console.log('%c ChainStories data: %O', 'color: maroon;', data);
    // console.log('%c ChainStories udicci: %O', 'color: maroon;', udicci);

    const [open, setOpen] = useState<boolean>(false);
    const [storiesRequested, setStoriesRequested] = useState<boolean>(false);
    const [mediaBitsRequested, setMediaBitsRequested] = useState<boolean>(false);
    const [mediaBits, setMediaBits] = useState<(any)[]>([]);
    const [selectedStory, setSelectedStory] = useState<any>(null);
    const [editSelectedStory, setEditSelectedStory] = useState<boolean>(false);
    const [forcedUpdateState, forceUpdate] = useState<boolean>(false);
    // console.log('%c ChainStories selectedStory: %O', 'color: maroon;', selectedStory);
    // console.log('%c ChainStories editSelectedStory: %O', 'color: maroon;', editSelectedStory);

    let { selectedProfile, currentUser } = udicci;
    // console.log('%c ChainStories data: %O', 'color: maroon;', data);
    // console.log('%c ChainStories selectedProfile: %O', 'color: maroon;', selectedProfile);
    // console.log('%c ChainStories currentUser: %O', 'color: maroon;', currentUser);

    const recordContext = useUdicciRecordContext();
    // console.log('%c ChainStories recordContext: %O', 'color: red;', recordContext);

    let recordDispatch: any | null = (recordContext && recordContext.dispatch ? recordContext.dispatch : null);
    // console.log('%c Perspectives recordDispatch: %O', 'color: blue;', recordDispatch);

    let contextStory: any = (recordContext && recordContext.state && recordContext.state.record ? recordContext.state.record : null);
    // console.log('%c ChainStories contextStory: %O', 'color: maroon;', contextStory);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    
    const changeStoryRecordValue = (fieldName: any | null, evt: any) => {
        // console.log('%c changeStoryRecordValue fieldName: %O', 'color: red;', fieldName);
        // console.log('%c changeStoryRecordValue evt: %O', 'color: red;', evt);

        let structure: any = null;
        if (data && contextStory) {
            let mediatorContext = data.find((x: any) => x.mediator === contextStory.udicciMediator );
            // console.log('%c changeStoryRecordValue mediatorContext: %O', 'color: red;', mediatorContext);
            if (mediatorContext && mediatorContext.structure) structure = mediatorContext.structure;
        }
        // console.log('%c changeStoryRecordValue structure: %O', 'color: green;', structure);
    
        // convert fieldName to UdicciMediatorField object
        let fields: any = null;
        if (structure) {
            fields = (structure.UdicciMediatorFields ? structure.UdicciMediatorFields : null);
        }
        // console.log('%c changeStoryRecordValue fields: %O', 'color: maroon;', fields);
    
        let field: any = null;
        if (fields && fields.length > 0) {
            field = fields.find((x: any) => x.JsonFieldName === fieldName );
        }
        // console.log('%c changeStoryRecordValue field: %O', 'color: maroon;', field);
    
        if (field) {
            // console.log('%c changeStoryRecordValue evt: %O', 'color: red;', evt);
            // console.log('%c changeStoryRecordValue field: %O', 'color: red;', field);
            // console.log('%c changeStoryRecordValue contextStory: %O', 'color: red;', contextStory);

            let trgt = evt.target;
            let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
            // console.log('%c changeStoryRecordValue newValue: %O', 'color: red;', newValue);

            if (!contextStory) return false;

            let fieldJsonKey = (field && field.JsonFieldName ? field.JsonFieldName : field.Name)
            // console.log('%c changeStoryRecordValue fieldJsonKey: %O', 'color: red;', fieldJsonKey);

            if (contextStory) {
                let recordData = (contextStory.data ? contextStory.data : {});
                // console.log('%c changeStoryRecordValue recordData: %O', 'color: red;', recordData);
                let tf = (contextStory.keys && contextStory.keys.title ? contextStory.keys.title : '');
                let df = (contextStory.keys && contextStory.keys.description ? contextStory.keys.description : '');
                // console.log('%c changeStoryRecordValue recordData: %O', 'color: red;', recordData);
                if (recordData[fieldJsonKey] !== newValue) {
                    recordData[fieldJsonKey] = newValue;
                    // console.log('%c changeStoryRecordValue recordData: %O', 'color: darkorange;', recordData);
                    contextStory.data = recordData;
                    contextStory.isDirty = true;
                }
                if (fieldJsonKey === tf) contextStory.title = recordData[fieldJsonKey];
                if (fieldJsonKey === df) contextStory.description = recordData[fieldJsonKey];

                if (!contextStory.permissions && contextStory.recordId <= 0) {
                    let newPermissions: any = {
                        CanView: true, CanAdd: true, CanEdit: true, CanDelete: true, CanDuplicate: true, CanProvision: true
                    };
                    contextStory.permissions = newPermissions;
                }
                // console.log('%c changeStoryRecordValue contextStory: %O', 'color: darkorange;', contextStory);
                setSelectedStory(contextStory);
                forceUpdate(!forcedUpdateState);
            }
        }
    };

    const saveStoryRecord = (evt: any) => {
        // console.log('%c saveStoryRecord selectedStory: %O', 'color: maroon;', selectedStory);
        // console.log('%c saveStoryRecord contextStory: %O', 'color: maroon;', contextStory);
        if (!contextStory) return false;

        // console.log('%c saveStoryRecord selectedStory: %O', 'color: maroon;', selectedStory);
        // console.log('%c saveStoryRecord contextStory: %O', 'color: maroon;', contextStory);
        if (contextStory && contextStory.isDirty) {
            contextStory.isSaving = true;
            // console.log('%c saveStoryRecord udicciContext: %O', 'color: maroon;', udicciContext);
            // console.log('%c saveStoryRecord contextStory: %O', 'color: maroon;', contextStory);
            setSelectedStory(contextStory);
            saveUdicciRecord(recordDispatch, udicciContext, contextStory);
            forceUpdate(!forcedUpdateState);
        }
    };

    const getStoryForm = () => {
        // console.log('%c getStoryForm contextStory: %O', 'color: orange;', contextStory);
        if (!contextStory) return null;

        let fieldLabelTitle = 'Title';
        let controlPropsTitle: any = {};
        controlPropsTitle.fullWidth = true;
        // console.log('%c controlPropsTitle: %O', 'color: orange;', controlPropsTitle);

        let isDirty: boolean = (contextStory.isDirty ? true : false);
        let isSaving: boolean = (contextStory.isSaving ? true : false);

        let inputPropsTitle: any = {};
        inputPropsTitle.id = 'story.title.' + contextStory.recordId;
        inputPropsTitle.type = 'text';
        inputPropsTitle.autoFocus = true;
        inputPropsTitle.value = (contextStory.data ? contextStory.data.StoryTitle : '');
        inputPropsTitle.onChange = (evt: any) => { changeStoryRecordValue('StoryTitle', evt); };
        let titleFormElement = (
            <FormControl {...controlPropsTitle}>
                <InputLabel htmlFor={inputPropsTitle.id} shrink>{fieldLabelTitle}</InputLabel>
                <Input {...inputPropsTitle} />
            </FormControl>
        );


        let fieldLabelForeword = 'Foreword';
        let controlPropsForeword: any = {};
        controlPropsForeword.fullWidth = true;
        // console.log('%c controlPropsForeword: %O', 'color: orange;', controlPropsForeword);

        let inputPropsForeword: any = {};
        inputPropsForeword.id = 'media.bit.foreword.' + contextStory.recordId;;
        inputPropsForeword.type = 'text';
        inputPropsForeword.value = (contextStory.data ? contextStory.data.Foreword : '');
        inputPropsForeword.onChange = (evt: any) => { changeStoryRecordValue('Foreword', evt); };
        inputPropsForeword.multiline = true;
        inputPropsForeword.rows = 2;
        inputPropsForeword.rowsMax = 15;
        let forewordFormElement = (
            <FormControl {...controlPropsForeword}>
                <InputLabel htmlFor={inputPropsForeword.id} shrink>{fieldLabelForeword}</InputLabel>
                <Input {...inputPropsForeword} />
            </FormControl>
        );

        let buttonsElement: any = null;
        let saveDisabled = true;
        if (isDirty && !isSaving) saveDisabled = false;
        buttonsElement = (
            <Box sx={udicciClasses.buttonCardContent}>
                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" alignContent="center">
                    <Grid item xs={12} md={6}>
                        <Button fullWidth onClick={closeStoryFormClick} color="secondary" variant="contained">
                            Close Form
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button fullWidth
                                onClick={saveStoryRecord}
                                disabled={saveDisabled}
                                color="primary"
                                variant={(isSaving ? "outlined" : "contained")}>
                            {isSaving ? "Please wait, saving your record ..." : "Save"}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );

        let storyForm = (
            <Paper elevation={0}>
                <Box>
                    {titleFormElement}
                    {forewordFormElement}
                </Box>
                {buttonsElement}
            </Paper>
        );
        return storyForm;
    };

    const editStoryMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // console.log('%c ChainStories selectedStory: %O', 'color: maroon;', selectedStory);
        setEditSelectedStory(true);
    };

    const closeStoryFormClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // console.log('%c ChainStories selectedStory: %O', 'color: maroon;', selectedStory);
        setEditSelectedStory(false);
        if (selectedStory && selectedStory.recordId <= 0) setSelectedStory(null);
    };

    let storyData: any = null;
    let mediaBitsData: any = null;
    let foundMediaBitsInContext = false;
    let storiesStructure: any = null;
    let mediaBitsStructure: any = null;
    // let relationships: any = null;
    if (data) {
        let mediatorContext = data.find((x: any) => x.mediator === 'Stories' );
        if (mediatorContext && mediatorContext.records) storyData = mediatorContext.records;
        if (mediatorContext && mediatorContext.structure) storiesStructure = mediatorContext.structure;

        mediatorContext = data.find((x: any) => x.mediator === 'Media Bits' );
        if (mediatorContext && mediatorContext.records) mediaBitsData = mediatorContext.records;
        if (mediatorContext && mediatorContext.structure) mediaBitsStructure = mediatorContext.structure;

        // mediatorContext = data.find((x: any) => x.mediator === 'Mediation Agreements' );
        // if (mediatorContext && mediatorContext.records) relationships = mediatorContext.records;
    }
    // console.log('%c storyData: %O', 'color: green;', storyData);
    // console.log('%c storiesStructure: %O', 'color: green;', storiesStructure);
    // console.log('%c mediaBitsData: %O', 'color: green;', mediaBitsData);
    // console.log('%c mediaBitsStructure: %O', 'color: green;', mediaBitsStructure);

    // if (relationships && relationships.length > 0 && selectedProfile !== null) {
    //     relationships = filter(relationships, function(r) { return r.data.ProviderProfileId === selectedProfile?.recordId; } );
    //     // console.log('%c relationships: %O', 'color: orange;', relationships);
    // }

    if (!mediaBitsData) {
        mediaBitsData = [];
    } else {
        foundMediaBitsInContext = true;
    }
    if ((!mediaBits || (mediaBits && mediaBits.length <= 0)) && mediaBitsData && mediaBitsData.length > 0) {
        if (mediaBits && mediaBits.length > 0) setMediaBits(mediaBitsData);
        // console.log('%c mediaBitsData: %O', 'color: orange;', mediaBitsData);
    }
    // console.log('%c mediaBitsData: %O', 'color: orange;', mediaBitsData);
    // console.log('%c mediaBits: %O', 'color: orange;', mediaBits);

    const addNewStory = () => {
        // console.log('%c addNewStory storyData: %O', 'color: blue;', storyData);
        // console.log('%c addNewStory storiesStructure: %O', 'color: blue;', storiesStructure);
        let newStoryRecord = new UdicciRecord('Stories', null, storiesStructure);
        // console.log('%c addNewStory newStoryRecord: %O', 'color: green;', newStoryRecord);
        setSelectedStory(newStoryRecord);
        setEditSelectedStory(true);
    };

    const handleAddMediaBit = () => {
        // console.log('%c mediaBits: %O', 'color: blue;', mediaBits);
        // console.log('%c mediaBitsStructure: %O', 'color: blue;', mediaBitsStructure);
        let newMediaBitRecord = new UdicciRecord('Media Bits', null, mediaBitsStructure);
        // console.log('%c handleAddMediaBit newMediaBitRecord: %O', 'color: green;', newMediaBitRecord);
        mediaBits.unshift(newMediaBitRecord);
        // console.log('%c mediaBits: %O', 'color: blue;', mediaBits);
        setMediaBits(mediaBits);
        forceUpdate(!forcedUpdateState);
    };

    const startAddMediaBit = (evt: any) => {
        // console.log('%c startAddMediaBit mediaBitsData: %O', 'color: blue;', mediaBitsData);
        let newMediaBitRecord = new UdicciRecord('Media Bits', null, mediaBitsStructure);
        // console.log('%c startAddMediaBit newMediaBitRecord: %O', 'color: green;', newMediaBitRecord);

        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c startAddMediaBit newValue: %O', 'color: red;', newValue);
        newMediaBitRecord.data.Name = newValue;
        newMediaBitRecord.title = newValue;

        // console.log('%c startAddMediaBit newMediaBitRecord: %O', 'color: red;', newMediaBitRecord);

        mediaBits.unshift(newMediaBitRecord);
        // console.log('%c mediaBits: %O', 'color: blue;', mediaBits);
        setMediaBits(mediaBits);
        forceUpdate(!forcedUpdateState);
    };

    const setStory = (story: any) => {
        // console.log('%c setStory story: %O', 'color: blue;', story);
        setSelectedStory(story);
        forceUpdate(!forcedUpdateState);
    };

    const closeStory = () => {
        setSelectedStory(null);
    };

    const removeMediaBit = (mediaBitToRemove: any) => {
        // console.log('%c removeMediaBit mediaBitsData: %O', 'color: blue;', mediaBitsData);
        // console.log('%c removeMediaBit mediaBits: %O', 'color: blue;', mediaBits);
        // console.log('%c removeMediaBit mediaBitToRemove: %O', 'color: blue;', mediaBitToRemove);
        // console.log('%c mediaBits: %O', 'color: orange;', mediaBits);
        let newMediaBitsList: any[] = [];
        forEach(mediaBits, function(mb: UdicciRecord, i: number) {
            // console.log('%c mediaBit: %O', 'color: purple;', mb);
            // console.log('%c mediaBit Index: %O', 'color: purple;', i);
            if (mb.recordId !== mediaBitToRemove.recordId)
                newMediaBitsList.push(mb);
        });
        // console.log('%c newMediaBitsList: %O', 'color: purple;', newMediaBitsList);
        setMediaBits(newMediaBitsList);
    };

    const updateMediaBit = (mediaBitToUpdate: any) => {
        // console.log('%c updateMediaBit mediaBitsData: %O', 'color: blue;', mediaBitsData);
        // console.log('%c updateMediaBit mediaBits: %O', 'color: blue;', mediaBits);
        // console.log('%c updateMediaBit mediaBitToUpdate: %O', 'color: blue;', mediaBitToUpdate);

        let newMediaBitsList: any[] = [];
        forEach(mediaBits, function(mb: UdicciRecord, i: number) {
            // console.log('%c mediaBit: %O', 'color: purple;', mb);
            // console.log('%c mediaBit Index: %O', 'color: purple;', i);
            if (mb.recordId <= 0) {
                // new record
                let nextRecordId = mediaBits.length;
                if (mediaBitToUpdate.recordId <= 0) {
                    mediaBitToUpdate.recordId = nextRecordId;
                }
                if (mediaBitToUpdate.data.UdicciRecordId <= 0) {
                    mediaBitToUpdate.data.UdicciRecordId = nextRecordId;
                }
                newMediaBitsList.push(mediaBitToUpdate);
            } else if (mb.recordId !== mediaBitToUpdate.recordId) {
                newMediaBitsList.push(mb);
            } else if (mb.recordId === mediaBitToUpdate.recordId) {
                newMediaBitsList.push(mediaBitToUpdate);
            }
        });
        // console.log('%c newMediaBitsList: %O', 'color: purple;', newMediaBitsList);
        setMediaBits(newMediaBitsList);
    };

    if (selectedProfile){
        let socialSolutions = (selectedProfile.data && selectedProfile.data.SocialSolutions ? selectedProfile.data.SocialSolutions : []);
        // console.log('%c socialSolutions: %O', 'color: maroon;', socialSolutions);

        let defaultSS = find(socialSolutions, function(ss) {
            return ss.recordId === udicci.socialSolutionDefaultMe;
        });
        // console.log('%c defaultSS: %O', 'color: green;', defaultSS);

        if (defaultSS) {
            if (!storiesRequested && (!storyData || (storyData && storyData.length <= 0)) ) {
                setStoriesRequested(true);
                getUdicciData({ mediator: 'Stories', socialSolutionId: defaultSS.recordId });
            }
            if (!mediaBitsRequested && !foundMediaBitsInContext) {
                setMediaBitsRequested(true);
                getUdicciData({ mediator: 'Media Bits', socialSolutionId: defaultSS.recordId });
            }
        }
    }
    // console.log('%c ChainStories selectedMediaBit: %O', 'color: maroon;', selectedMediaBit);

    const mainContextUpdated = (rslt: any) => {
        // console.log('%c mainContextUpdated rslt: %O', 'color: orange;', rslt);
        // console.log('%c mainContextUpdated storyData: %O', 'color: green;', storyData);

        if (rslt && rslt.udicciMediator && rslt.udicciMediator === 'Stories') {
            // setSelectedStory(rslt);
            // setRecordFocus(rslt);
            // console.log('%c recordContextUpdated rslt: %O', 'color: orange;', rslt);
            // console.log('%c recordContextUpdated storyData: %O', 'color: green;', storyData);
            if (storyData && storyData.length > 0) {
                storyData.forEach((story: any, storyIndex: number) => {
                    // console.log('%c story: %O', classLogStyle, story);
                    if (rslt && rslt.length > 0) {
                        let updatedStory = rslt.find((stry: UdicciRecord) => {
                            // console.log('%c stry: %O', 'color: maroon;', stry);
                            return (stry.recordId === story.recordId);
                        })
                        // console.log('%c recordContextUpdated updatedStory: %O', 'color: green;', updatedStory);
    
                        if (updatedStory) {
                            setSelectedStory(updatedStory);
                        }
                    }
                });
            }
        }
    };

    subscribeToUdicci('chain.stories', mainContextUpdated);

    const recordContextUpdated = (rslt: any) => {
        // console.log('%c recordContextUpdated rslt: %O', 'color: orange;', rslt);
        // console.log('%c recordContextUpdated storyData: %O', 'color: green;', storyData);
        if (storyData && storyData.length > 0) {
            storyData.forEach((story: any, storyIndex: number) => {
                // console.log('%c story: %O', classLogStyle, story);
                if (rslt && rslt.length > 0) {
                    let updatedStory = rslt.find((stry: UdicciRecord) => {
                        // console.log('%c stry: %O', 'color: maroon;', stry);
                        return (stry.recordId === story.recordId);
                    })
                    // console.log('%c recordContextUpdated updatedStory: %O', 'color: green;', updatedStory);

                    if (updatedStory) {
                        setSelectedStory(updatedStory);
                    }
                }
            });
        }
    };

    subscribeToRecord('chain.stories', recordContextUpdated);

    let hasANewBitAlready = false;
    let mediaBitsDataElement: any = null;
    if (mediaBits && mediaBits.length > 0) {
        // console.log('%c mediaBits: %O', 'color: orange;', mediaBits);

        let sortedMediaBits = sortBy(mediaBits, function(a) { return a.recordId; })
        // console.log('%c sortedMediaBits: %O', 'color: orange;', sortedMediaBits);

        let mediaBitsDataElements: any[] = [];
        forEach(sortedMediaBits, function(mb: UdicciRecord, i: number) {
            // console.log('%c mediaBit: %O', 'color: purple;', mb);
            // console.log('%c mediaBit Index: %O', 'color: purple;', i);
            if (mb.recordId <= 0) hasANewBitAlready = true;
            let reKey = 'media.bit.' + mb.recordId.toString() + '.display';
            mediaBitsDataElements.push(
                <RecordContextProvider key={reKey}>
                    <ChainStoryBit mediaBit={mb}
                                   minimized={false}
                                   openInForm={(mb.recordId > 0 ? false : true)}
                                   requestRemove={removeMediaBit}
                                   requestUpdate={updateMediaBit}
                    />
                </RecordContextProvider>
            );
        });

        // let selectedBit = (mediaBits[selectedIndex] ? mediaBits[selectedIndex] : null);
        // console.log('%c selectedBit: %O', 'color: orange;', selectedBit);
        mediaBitsDataElement = (
            <Fragment>
                {mediaBitsDataElements}
            </Fragment>
        );
    } else {
        mediaBitsDataElement = (
            <Fragment>
                <Paper sx={udicciClasses.standardCardContent}>
                    <Typography variant="subtitle1" color="secondary" align="left">
                        Chain stories.
                    </Typography>
                    <Typography variant="caption" align="left">
                        Mini-collections of media bits 
                        stapled together to tell a story.
                    </Typography>
                </Paper>
                <Paper sx={udicciClasses.standardCardContent}>
                    <Typography variant="subtitle1" color="secondary" align="left">
                        Media Bits.
                    </Typography>
                    <Typography variant="caption" align="left">
                        Pretty much like any other social media post with some rich text 
                        functions like Evernote, a title and links to other media.
                    </Typography>
                </Paper>
                <Paper sx={udicciClasses.buttonCardContent}>
                    <Button fullWidth onClick={handleAddMediaBit} color="primary" variant="contained">
                        Start A Chain Story Now.
                    </Button>
                </Paper>
            </Fragment>
        );
    }
    // console.log('%c hasANewBitAlready: %O', 'color: purple;', hasANewBitAlready);

    let leftSideInterface: any = null;
    let rightSideInterface: any = null;

    let isMobile = false;
    if (!isMobile) {
        let storyButtonElements: any[] = [];
        if (storyData && storyData.length > 0) {
            let sortedStoryData = sortBy(storyData, function(a) { return a.recordId; })
            // console.log('%c sortedStoryData: %O', 'color: orange;', sortedStoryData);

            forEach(sortedStoryData, function(story: UdicciRecord, i: number) {
                // console.log('%c story: %O', 'color: purple;', story);
                // console.log('%c mediaBit Index: %O', 'color: purple;', i);
                let displayNameElement = (
                    <Typography variant="body2" color="primary">
                        {story.title}
                    </Typography>
                );

                // let storyMenuElement: any = null;
                // storyMenuElement = (
                //     <Menu
                //         id="story-menu"
                //         anchorEl={anchorBitEl}
                //         open={Boolean(anchorBitEl)}
                //         onClose={handleBitMenuClose}
                //     >
                //         <MenuItem onClick={(evt: any) => { editMediaBit(); } }>
                //             <EditIcon sx={udicciClasses.footerButtonIcon} />
                //             Edit Bit
                //         </MenuItem>
                //         <MenuItem onClick={secureRecord}>
                //             <Secured sx={udicciClasses.footerButtonIcon} />
                //             Secure Bit
                //         </MenuItem>
                //         <Divider />
                //         <MenuItem onClick={(evt: any) => removeMediaBitRecord()}>
                //             <RemoveIcon sx={udicciClasses.footerButtonIcon} />
                //             Remove Bit
                //         </MenuItem>
                //     </Menu>
                // );
        
                let editMediaBitIconElement: any = null;
                // if (!minimized) {
                //     editMediaBitIconElement = (
                //         <Fragment>
                //             <IconButton size="small"
                //                 color="primary"
                //                 edge="end"
                //                 aria-controls="media-bit-menu"
                //                 aria-haspopup="true"
                //                 onClick={handleBitMenuClick}
                //                 component="span"
                //                 sx={udicciClasses.headerButton}>
                //                 <MoreHorizIcon sx={udicciClasses.headerButtonIcon} />
                //             </IconButton>
                //             {storyMenuElement}
                //         </Fragment>
                //     );
                // }
        
                let cardContentElement: any = null;
                // cardContentElement = (
                //     <CardContent classes={{ root: cardContent.cardContent }}>
                //         <Typography variant="body2" component="div" color="textSecondary" sx={udicciClasses.narrative}>
                //             {story.description}
                //         </Typography>
                //     </CardContent>
                // );

                let reKey = 'story.' + story.recordId.toString() + '.button';
                let storyCard = (
                    <Card key={reKey}
                          raised={false}
                          classes={{ root: 'cardButton' }}
                          onClick={(evt: any) => { return setStory(story); }}
                    >
                        <CardHeader title={displayNameElement} 
                                    action={editMediaBitIconElement} 
                                    classes={{
                                        root: 'cardHeader',
                                        avatar: 'creatorSocialIconRoot'
                                    }}
                        />
                        {cardContentElement}
                    </Card>
                );
                storyButtonElements.push(storyCard);
            });
        }

        let addNewStoryButton: any = null;
        if (storiesStructure !== null) {
            addNewStoryButton = (
                <Button onClick={addNewStory}>
                    - add new story -
                </Button>
            );
        }

        // leftSideInterface = (
        //     <Grid item xs={2} md={3} zeroMinWidth>
        //         <Paper elevation={0} sx={udicciClasses.storiesContainer}>
        //             {storyButtonElements}
        //         </Paper>
        //         {addNewStoryButton}
        //     </Grid>
        // );

        leftSideInterface = (
            <Box>
                <Paper elevation={0} sx={udicciClasses.storiesContainer}>
                    {storyButtonElements}
                </Paper>
                {addNewStoryButton}
            </Box>
        );

        // rightSideInterface = (
        //     <Grid item xs={1} md={3}>
        //         <Relationships agreements={relationships} />
        //     </Grid>
        // );
    }

    let addNewMediaBit: any = null;
    if (currentUser && currentUser.UdicciUserId > 0 && mediaBits && mediaBits.length > 0 && !hasANewBitAlready) {
        let inputPropsTitle: any = {};
        inputPropsTitle.id = 'new.media.bit.title';
        inputPropsTitle.type = 'text';
        inputPropsTitle.fullWidth = true;
        inputPropsTitle.placeholder = 'Add to .Me & My Stories...';
        inputPropsTitle.variant = "filled";
        inputPropsTitle.size = "small";
        inputPropsTitle.InputProps = {
            classes: {
                input: udicciClasses.newMediaBit
            }
        };
        inputPropsTitle.classes = {
            root: udicciClasses.newMediaBitRoot
        };
        // inputPropsTitle.value = (selectedMediaBit.data ? selectedMediaBit.data.Title : '');
        inputPropsTitle.onChange = startAddMediaBit;
    
        addNewMediaBit = (
            <Paper elevation={2}>
                <TextField {...inputPropsTitle} />
            </Paper>
        );
    }

    // console.log('%c middleInterface open: %O', 'color: red;', open);
    let middleInterface: any = (
        <MediaBitContainer open={open}>
            {addNewMediaBit}
            {mediaBitsDataElement}
        </MediaBitContainer>
    );

    let menuInterface: any = null;
    if (leftSideInterface) {
        // sx={{ mr: 2, ...(open && { display: 'none' }) }}

        menuInterface = (
            <Box>
                <CssBaseline />
                <AppBarWrapper position="relative" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open chain stories menu"
                            onClick={(!open ? handleDrawerOpen : handleDrawerClose )}
                            edge="start"
                            sx={{ mr: 2 }}
                        >
                            {!open ? <Icon>menu</Icon> : <Icon>close</Icon>}
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            Chain Stories
                        </Typography>
                    </Toolbar>
                </AppBarWrapper>
                <Drawer
                    sx={{
                        position: 'fixed',
                        // float: 'left',
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            position: 'unset',
                            // maxHeight: '250px',
                            // overflowY: 'auto',
                            float: 'left',
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    open={open}
                >
                    {leftSideInterface}
                </Drawer>
            </Box>
        );
    }

    let storyDialogElement: any = null;
    if (selectedStory) {
        // console.log('%c selectedStory: %O', 'color: blue;', selectedStory);

        let dialogTitleEl: any = null;
        let dialogContentEl: any = null;
        let dialogActionsEl: any = null;
        if (editSelectedStory) {
            let storyEditIconElement = (
                <IconButton size="small"
                            color="primary"
                            edge="end"
                            onClick={closeStoryFormClick}
                            component="span"
                            sx={udicciClasses.headerButton}>
                    <CloseIcon sx={udicciClasses.footerButtonIcon} />
                </IconButton>
            );

            let formTitle = '';
            if (selectedStory.recordId > 0) {
                formTitle = 'Editing Story ';
            } else {
                formTitle = 'Adding Story ';
            }
            let storyTitleElement = (
                <Fragment>
                    <Typography variant="caption" component="span">{formTitle}</Typography>
                    <Typography variant="body2" color="secondary" component="span">
                        {selectedStory.title}
                    </Typography>
                </Fragment>
            );

            let storyForm = getStoryForm();

            dialogTitleEl = (
                <DialogTitle>
                    {storyEditIconElement}
                    {storyTitleElement}
                </DialogTitle>
            );

            dialogContentEl = (
                <DialogContent>
                    {storyForm}
                </DialogContent>
            );

            // dialogActionsEl = (
            //     <DialogActions>
            //         <div sx={udicciClasses.grow} />
            //         <Button size="small" color="secondary" onClick={closeStory}>
            //             Close
            //         </Button>
            //     </DialogActions>
            // );
        } else {
            let storyEditIconElement = (
                <IconButton size="small"
                            color="primary"
                            edge="end"
                            onClick={editStoryMenuClick}
                            component="span"
                            sx={udicciClasses.headerButton}>
                    <EditIcon sx={udicciClasses.footerButtonIcon} />
                </IconButton>
            );

            let storyTitleElement = (
                <Typography variant="body2" color="primary">
                    {selectedStory.title}
                </Typography>
            );

            let storyDescriptionElement = (
                <Typography variant="caption">
                    {selectedStory.description}
                </Typography>
            );

            dialogTitleEl = (
                <DialogTitle>
                    {storyEditIconElement}
                    {storyTitleElement}
                </DialogTitle>
            );

            dialogContentEl = (
                <DialogContent>
                    {storyDescriptionElement}
                </DialogContent>
            );

            dialogActionsEl = (
                <DialogActions>
                    <Box sx={udicciClasses.grow} />
                    <Button size="small" color="secondary" onClick={closeStory}>
                        Close
                    </Button>
                </DialogActions>
            );
        }
        storyDialogElement = (
            <RecordContextProvider key={'story.dialog.record.provider.' + selectedStory.recordId}>
                <Dialog open={true} onClose={closeStory} maxWidth={'md'} fullWidth>
                    {dialogTitleEl}
                    {dialogContentEl}
                    {dialogActionsEl}
                </Dialog>
            </RecordContextProvider>
        );
    }

    return (
        <Paper sx={udicciClasses.paper}>
            {storyDialogElement}
            <Box>
                {menuInterface}
                {middleInterface}
                {rightSideInterface}
            </Box>
        </Paper>
    );
}
