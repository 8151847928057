import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import { find } from 'underscore';

import DefaultSocialIcon from 'src/images/Ulysses Selfie Portrait.png';

import { Box, Paper, Typography, Icon, IconButton, Avatar, TextField, CircularProgress } from '@mui/material';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext, sendPreparedRequest } from 'src/context/udicci-context';

import { GetMyPointsRequest } from 'src/interfaces/udicci-request-interfaces';

import { InteractiveSentencesDisplay } from './interactive-sentences-display';

import { ORANGE_DOT_ICON } from './focused-content';

import { useFocusedContext } from './focused-context';

const PointTitleContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    // background: 'unset',
    padding: '0 8px 4px 16px',
    position: 'sticky', 
    top: 30, 
}));

export const DisplayThePoint: React.FC<any> = (props) => {
    const focus = useFocusedContext();
    // console.log('%c DisplayThePoint focus.point: %O', 'color: green;', focus.point);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;
    let { selectedProfile, currentUser } = udicci;

    const [loadPoints, setLoadPoints] = useState<boolean>(true);
    const [uiState, flashUI] = useState<boolean>(true);

    let userId: number = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
    // console.log('%c DisplayThePoint userId: %O', 'color: green;', userId);
    let focusPoint: any = (focus && focus.point ? focus.point : null);
    // console.log('%c DisplayThePoint focusPoint: %O', 'color: green;', focusPoint);
    let focusPointData: any = (focusPoint && focusPoint.data ? focusPoint.data : focusPoint);
    // console.log('%c DisplayThePoint focusPointData: %O', 'color: green;', focusPointData);
    let pointId: number = (focusPoint && focusPoint.recordId ? focusPoint.recordId : 0);
    // console.log('%c DisplayThePoint pointId: %O', 'color: green;', pointId);
    let createdByUserId: number = (focusPointData && focusPointData.CreatedByUserId ? focusPointData.CreatedByUserId : 0);
    // console.log('%c DisplayThePoint createdByUserId: %O', 'color: green;', createdByUserId);

    let readonly: boolean = ((pointId > 0 && (userId <= 0 || createdByUserId !== userId)) ? true : false);
    // console.log('%c DisplayThePoint readonly: %O', 'color: green;', readonly);

    const getMyPoints = () => {
        if (!selectedProfile) return false;

        let myPointsRequest: GetMyPointsRequest = {
            UdicciMediatorName: 'Points',
            UdicciCommand: 'Get My Points',
            SelectedUdicciProfileId: selectedProfile.recordId,
            SocialSolutionId: 0,
            UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0),
            IncludeEngagedPoints: true,
        }
        // console.log('%c getMyPoints myPointsRequest: %O', 'color: blue;', myPointsRequest);

        var okToContinue = true;
        if (okToContinue)  {
            sendPreparedRequest(myPointsRequest, { onSuccess: myPointsReturned, onError: failedToGetPoints });
            setLoadPoints(false);
        }
    }

    const myPointsReturned = (result: any) => {
        // console.log('%c myPointsReturned result: %O', 'color: red;', result);
        flashUI(!uiState);
    };

    const failedToGetPoints = (result: any) => {
        // console.log('%c failedToGetPoints result: %O', 'color: red;', result);
        setLoadPoints(true);
    };

    // const saveThePoint = () => {
    //     // console.log('%c saveThePoint focus.point: %O', 'color: red;', focus.point);
    //     if (focus.savePortaFocusBoard) focus.savePortaFocusBoard();
    // };

    useEffect(() => {
        // console.log('%c useEffect loadPoints: %O', 'color: brown;', loadPoints);
        if (loadPoints) {
            let allPoints: any = udicciHelpers.getMediatorData('Points');
            // console.log('%c useEffect allPoints: %O', 'color: brown;', allPoints);
            if (!allPoints || (allPoints && allPoints.length <= 0)) getMyPoints();
        }
    }, [loadPoints]);

    if (!focus.point || (focus.point && !focus.point.data)) {
        // this is not a UdicciRecord
        let allPoints: any = udicciHelpers.getMediatorData('Points');
        // console.log('%c DisplayThePoint allPoints: %O', 'color: darkgoldenrod;', allPoints);
        if (allPoints && allPoints.length > 0) {
            let checkPoint: any = find(allPoints, (p: any) => { return p.recordId === focus.point.recordId; });
            // console.log('%c DisplayThePoint checkPoint: %O', 'color: darkgoldenrod;', checkPoint);
            if (checkPoint) focus.setPointToFocus(checkPoint);
        }

        return (
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="caption">loading point, please stand by...</Typography>
                <CircularProgress size={20} />
            </Box>
        );
    }

    let pointIconProps: any = {
        sx: {
            ...ORANGE_DOT_ICON,
            // cursor: (focus.aiAssistantOn ? 'pointer' : 'default'),
        },
        // onClick: (focus.aiAssistantOn ? onPointClick : null),
    };
    let pointElement: any = (
        <PointTitleContainer>
            <IconButton {...pointIconProps}>&nbsp;</IconButton>
            <PointDisplay readonly={readonly} />
        </PointTitleContainer>
    );
    let reasonElement: any = (<ReasonDisplay readonly={readonly} />);

    let thePointDisplayElement: any = (
        <Box>
            {pointElement}
            {reasonElement}
        </Box>
    );
    return thePointDisplayElement;
};

export const PointDisplay: React.FC<any> = (props) => {
    const focus = useFocusedContext();

    const udicciHelpers = useUdicciHelpers();

    let isSaving: boolean = (focus.point && focus.point.isSaving ? true : false);
    let pointValue: any = (focus.point && focus.point.data.ThePoint ? focus.point.data.ThePoint : '');

    const [editThePoint, setEditThePoint] = useState<boolean>((!isSaving && pointValue.length <= 0 ? true : false));

    const toggleEdit = () => {
        setEditThePoint(!editThePoint);
    };

    const handleChange = (evt: any) => {
        // console.log('%c handleChange evt: %O', 'color: maroon;', evt);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c handleChange newValue: %O', 'color: maroon;', newValue);
        // console.log('%c handleChange point: %O', 'color: maroon;', point);
        let updatedPoint: any = {};
        Object.assign(updatedPoint, focus.point);

        updatedPoint.title = newValue;
        updatedPoint.data.ThePoint = newValue;
        updatedPoint.isDirty = true;

        setEditThePoint(true);
        if (focus.setPointToFocus)
            focus.setPointToFocus(updatedPoint);
    };

    let creatorDisplayName: string = (focus.point && focus.point.data.CreatorDisplayName ? focus.point.data.CreatorDisplayName : '');
    let creatorSocialIcon: string = (focus.point && focus.point.data.CreatorSocialIcon ? focus.point.data.CreatorSocialIcon : '');

    let pointDisplay: any[] = udicciHelpers.formatStringForDisplay(pointValue);

    let creatorNameElement: any = (
        <Typography variant="caption" sx={{ marginLeft: '4px', fontSize: '0.7em' }}> by {creatorDisplayName}</Typography>
    );
    let socialIconElement: any = null;
    if (creatorSocialIcon) {
        socialIconElement = (
            <Avatar alt={creatorDisplayName}
                src={creatorSocialIcon}
                sx={{ display: 'inline-block', maxHeight: 15, maxWidth: 15 }}
                component='span' />
        );
    } else {
        socialIconElement = (
            <Avatar alt={creatorDisplayName}
                src={DefaultSocialIcon}
                sx={{ display: 'inline-block', maxHeight: 15, maxWidth: 15 }}
                component='span' />
        );
    }

    let pointToggleEditClick: any = null;
    if (props && props.readonly === false) pointToggleEditClick = toggleEdit;

    let pointDisplayElement: any = null;
    if (focus.aiAssistantOn) {
        let pointInteractiveProps: any = {
            displayProps: { variant: 'clickableSubTitle2', sx: {} },
            hoverProps: { variant: 'clickableSubTitle2', sx: { textDecoration: 'underline', textDecorationThickness: '3px', textDecorationColor: 'lime' } },
            selectedProps: { variant: 'body1', sx: { textDecoration: 'underline', textDecorationThickness: '3px', textDecorationColor: 'green', cursor: 'pointer' } },
        };
        pointDisplayElement = (<Box><InteractiveSentencesDisplay {...pointInteractiveProps} stringValue={focus.point.title} /></Box>);
    } else if ((editThePoint || pointValue.length <= 0) && !isSaving) {
        pointDisplayElement = (
            <Box>
                <TextField fullWidth
                    type="text"
                    multiline={true}
                    minRows={1}
                    maxRows={2}
                    value={pointValue}
                    placeholder={"What's your Point?"}
                    margin="normal"
                    variant='filled'
                    sx={{ marginTop: '8px' }}
                    onChange={handleChange} />
                <IconButton color="info" aria-label="close point form" edge="end" onClick={pointToggleEditClick} sx={{ float: 'right' }}>
                    <Icon>cancel_presentation</Icon>
                </IconButton>
            </Box>
        );
    } else {
        pointDisplayElement = (
            <Box>
                <Typography variant="body2" onClick={pointToggleEditClick}>{pointDisplay}</Typography>
                {socialIconElement}
                {creatorNameElement}
            </Box>
        );
    }

    return pointDisplayElement;
};

export const ReasonDisplay: React.FC<any> = (props) => {
    const focus = useFocusedContext();

    const udicciHelpers = useUdicciHelpers();

    let isSaving: boolean = (focus.point && focus.point.isSaving ? true : false);
    let reasonValue: any = (focus.point && focus.point.data.TheReason ? focus.point.data.TheReason : '');
    let reasonDisplayFormatted: any[] = udicciHelpers.formatStringForDisplay(reasonValue);

    const [editTheReason, setEditTheReason] = useState<boolean>((!isSaving && reasonValue.length <= 0 ? true : false));

    const toggleEdit = () => {
        setEditTheReason(!editTheReason);
    };

    const handleChange = (evt: any) => {
        // console.log('%c handleChange evt: %O', 'color: maroon;', evt);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c handleChange newValue: %O', 'color: maroon;', newValue);
        // console.log('%c handleChange point: %O', 'color: maroon;', point);
        let updatedPoint: any = {};
        Object.assign(updatedPoint, focus.point);

        updatedPoint.description = newValue;
        updatedPoint.data.TheReason = newValue;
        updatedPoint.isDirty = true;

        if (focus.setPointToFocus)
            focus.setPointToFocus(updatedPoint);
    };

    let pointToggleEditClick: any = null;
    if (props && props.readonly === false) pointToggleEditClick = toggleEdit;

    let reasonDisplay: any = null;
    if (focus.aiAssistantOn) {
        let reasonInteractiveProps: any = {
            displayProps: { variant: 'caption', sx: { cursor: 'pointer' } },
            hoverProps: { variant: 'caption', sx: { cursor: 'pointer', textDecoration: 'underline', textDecorationThickness: '3px', textDecorationColor: 'lime' } },
            selectedProps: { variant: 'body1', sx: { textDecoration: 'underline', textDecorationThickness: '3px', textDecorationColor: 'green', cursor: 'pointer' } },
        };
        reasonDisplay = (<Box><InteractiveSentencesDisplay {...reasonInteractiveProps} stringValue={reasonValue} /></Box>);
    } else if ((editTheReason || reasonValue.length <= 0) && !isSaving) {
        reasonDisplay = (
            <Box>
                <TextField fullWidth
                           type="text"
                           multiline={true}
                           minRows={2}
                           maxRows={7}
                           value={reasonValue}
                           placeholder={'What is the reason?'}
                           margin="normal"
                           variant='filled'
                           sx={{ marginTop: '8px' }}
                           onChange={handleChange}
                />
                <IconButton color="info" aria-label="close point form" edge="end" onClick={pointToggleEditClick} sx={{ float: 'right' }}>
                    <Icon>cancel_presentation</Icon>
                </IconButton>
            </Box>
        );
    } else {
        reasonDisplay = (<Box> <Typography variant="caption" onClick={pointToggleEditClick}>{reasonDisplayFormatted}</Typography> </Box>);
    }

    return (<Box sx={{ paddingTop: '8px' }}> {reasonDisplay} </Box>);
};
