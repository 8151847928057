import React, { Fragment, useState } from 'react';

import { ThemeProvider, Theme } from '@mui/material/styles';

import { find, forEach } from 'underscore';

import {
    Box, Fab, Icon, Paper, SpeedDial, SpeedDialIcon, SpeedDialAction, Typography, Grid, MenuItem, IconButton,
    FormControl, InputLabel, Select, Backdrop, Tooltip
} from '@mui/material';

import { useUdicciContext, getDesignMode } from 'src/context/udicci-context';
import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import { udicciPluginComponents } from 'src/plugins/udicciPlugins';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

const UdicciPanePlugin: React.FC<any> = (props: any) => {
    // console.log('%c UdicciPanePlugin props: %O', 'color: red;', props);

    let { pane, configure, plugin, pluginIndex } = props;
    // console.log('%c UdicciPanePlugin pane: %O', 'color: red;', pane);
    // console.log('%c UdicciPanePlugin plugin: %O', 'color: red;', plugin);

    const udicciContext = useUdicciContext();
    const udicciHelpers = useUdicciHelpers();

    var { udicci } = udicciContext.state;
    var { currentUser, currentUserRole } = udicci;
    // console.log('%c UdicciPanePlugin selectedProfile: %O', 'color: red;', selectedProfile);
    // console.log('%c UdicciPanePlugin currentUser: %O', 'color: red;', currentUser);
    // console.log('%c UdicciPanePlugin currentUserRole: %O', 'color: red;', currentUserRole);

    const [selectPluginFilter, setSelectPluginFilter] = useState<string>('CONTENT');
    const [editPlugin, setEditPlugin] = useState<boolean>(false);
    // const [uiState, flashUI] = useState<boolean>(false);
    // console.log('%c UdicciPanePlugin editPlugin: %O', 'color: maroon;', editPlugin);
    const [pluginSort, setPluginSort] = useState<string>('');

    let dm: any = getDesignMode();
    // console.log('%c UdicciPanePlugin dm: %O', 'color: red;', dm);

    let readonly: boolean = true;
    if (dm && dm === 'on' && configure) readonly = false;

    let pluginDefaultData: any = (plugin && plugin.data && plugin.data.default ? plugin.data.default : null);
    // console.log('%c UdicciPanePlugin pluginDefaultData: %O', 'color: maroon;', pluginDefaultData);

    let pluginComponent: any = (plugin && plugin.plugin ? plugin.plugin : null);
    // console.log('%c UdicciPanePlugin pluginComponent: %O', 'color: maroon;', pluginComponent);
    let pluginComponentId: string = (pluginComponent && pluginComponent.id ? pluginComponent.id : '');
    // console.log('%c UdicciPanePlugin pluginComponentId: %O', 'color: maroon;', pluginComponentId);

    // temporary renames
    if (pluginComponentId === 'ory/editor/core/content/slate') pluginComponentId = 'udicciDraft';
    // if (pluginComponentId === 'ory/editor/core/content/slate') pluginComponentId = 'udicciDraft';
    if (pluginComponentId === 'configure-profile-solutions') pluginComponentId = 'configure-portal-permissions';

    // console.log('%c UdicciPanePlugin pluginComponentId: %O', 'color: maroon;', pluginComponentId);

    let selectedPaneIdx: number = (props.selectedPaneIdx !== undefined ? props.selectedPaneIdx : -1);
    // console.log('%c UdicciPanePlugin selectedPaneIdx: %O', 'color: maroon;', selectedPaneIdx);
    let paneIdx: number = (props.paneIdx !== undefined && props.paneIdx !== null ? props.paneIdx : -1);
    // console.log('%c UdicciPanePlugin paneIdx: %O', 'color: maroon;', paneIdx);
    let paneIsSelectedPane: boolean = (currentUser && currentUser.UdicciUserId > 0 && selectedPaneIdx >= 0 && selectedPaneIdx === paneIdx ? true : false);
    // console.log('%c UdicciPanePlugin paneIsSelectedPane: %O', 'color: maroon;', paneIsSelectedPane);

    // let contentDesign: any = (pane && pane.contentDesign ? pane.contentDesign : null);
    // console.log('%c UdicciPanePlugin contentDesign: %O', 'color: maroon;', contentDesign);

    let paneContentDesign: any = udicciHelpers.getPaneContentDesign(pane);
    // console.log('%c UdicciPanePlugin paneContentDesign: %O', 'color: red;', paneContentDesign);

    let content: any[] = (paneContentDesign && paneContentDesign.content && paneContentDesign.content.length > 0 ? paneContentDesign.content : []);
    // console.log('%c UdicciPanePlugin content: %O', 'color: red;', content);

    const editPanePlugin = () => {
        // console.log('%c PortaPane editPanePlugin pane: %O', 'color: maroon;', pane);
        // console.log('%c PortaPane editPanePlugin plugin: %O', 'color: maroon;', plugin);
        // console.log('%c PortaPane editPanePlugin editPlugin: %O', 'color: maroon;', editPlugin);
        let wasTrue: boolean = false;
        if (editPlugin) wasTrue = true;
        setEditPlugin(true);
        if (props && props.onClickPaneContentInDesignMode) props.onClickPaneContentInDesignMode();
    };

    const closePanePluginForm = () => {
        // console.log('%c PortaPane closePanePluginForm pane: %O', 'color: maroon;', pane);
        // console.log('%c PortaPane closePanePluginForm plugin: %O', 'color: maroon;', plugin);
        setEditPlugin(false);
    };

    const removePlugin = () => {
        // console.log('%c removePlugin pluginIndex: %O', 'color: maroon;', pluginIndex);

        let updatedContent: any[] = [];
        forEach(content, function(p: any, i: number) {
            // console.log('%c p: %O', 'color: purple;', p);
            if (pluginIndex !== i) updatedContent.push(p);
        });
        // console.log('%c removePlugin updatedContent: %O', 'color: maroon;', updatedContent);
        if (props.onChangeContent) props.onChangeContent(updatedContent);
    };

    const movePlugin = (direction: string = 'down') => {
        // console.log('%c movePlugin pluginIndex: %O', 'color: maroon;', pluginIndex);
        // console.log('%c movePlugin direction: %O', 'color: maroon;', direction);
        // console.log('%c movePlugin paneContentDesign: %O', 'color: maroon;', paneContentDesign);
        // console.log('%c movePlugin content: %O', 'color: maroon;', content);

        // console.log('%c movePlugin pluginIndex: %O', 'color: maroon;', pluginIndex);
        let targetPluginIndex = pluginIndex;
        if (direction.toString().toLowerCase() === 'up') targetPluginIndex--;
        if (direction.toString().toLowerCase() === 'down') targetPluginIndex++;
        // console.log('%c movePlugin targetPluginIndex: %O', 'color: maroon;', targetPluginIndex);

        content = udicciHelpers.swapArrayElements(content, pluginIndex, targetPluginIndex);
        // console.log('%c movePlugin content: %O', 'color: maroon;', content);

        if (props.onChangeContent) props.onChangeContent(content);
    };

    const onChangePlugin = (data: any) => {
        // console.log('%c onChangePlugin pluginIndex: %O', 'color: maroon;', pluginIndex);
        // console.log('%c onChangePlugin data: %O', 'color: maroon;', data);
        // console.log('%c onChangePlugin paneContentDesign: %O', 'color: maroon;', paneContentDesign);
        // console.log('%c onChangePlugin content: %O', 'color: maroon;', content);

        if (content && content.length > pluginIndex) {
            let plgn: any = content[pluginIndex];
            // console.log('%c onChangePlugin plgn: %O', 'color: maroon;', plgn);
            let plgnData: any = (plgn.data ? plgn.data : {});
            // console.log('%c onChangePlugin plgnData: %O', 'color: maroon;', plgnData);
            plgnData.default = data;
            plgn.data = plgnData;
            content[pluginIndex] = plgn;
        }

        if (props.onChangeContent) props.onChangeContent(content);
    };

    const choosePluginToAdd = (newPlugin: UdicciPlugin) => {
        // console.log('%c choosePluginToAdd currentSettings: %O', 'color: maroon;', currentSettings);
        // console.log('%c choosePluginToAdd newPlugin: %O', 'color: maroon;', newPlugin);
        if (!newPlugin) return null;

        if (props && props.onChangeContent) {
            let targetItem: any = (content && content.length > pluginIndex ? content[pluginIndex] : null);
            // console.log('%c choosePluginToAdd targetItem: %O', 'color: maroon;', targetItem);

            if (!targetItem) targetItem = {};
            if (!targetItem.plugin) targetItem.plugin = {};
            targetItem.plugin.id = newPlugin.id;
            targetItem.plugin.version = (newPlugin && newPlugin.version ? newPlugin.version : 1);

            content[pluginIndex] = targetItem;
            // console.log('%c choosePluginToAdd content: %O', 'color: maroon;', content);

            if (props.onChangeContent) props.onChangeContent(content);
        }
    };

    const handleChangeDesign = (settingName: string, subSettingName: string, event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log('%c handleChangeDesign currentSettings: %O', 'color: maroon;', currentSettings);
        // console.log('%c handleChangeDesign settingName: %O', 'color: maroon;', settingName);
        // console.log('%c handleChangeDesign subSettingName: %O', 'color: maroon;', subSettingName);
        // console.log('%c handleChangeDesign paneIsSelectedPane: %O', 'color: maroon;', paneIsSelectedPane);
        // console.log('%c handleChangeDesign pluginComponent: %O', 'color: maroon;', pluginComponent);
        // console.log('%c handleChangeDesign udicciPluginComponents: %O', 'color: maroon;', udicciPluginComponents);
        // console.log('%c handleChangeDesign content: %O', 'color: maroon;', content);
        // console.log('%c handleChangeDesign pluginIndex: %O', 'color: maroon;', pluginIndex);
        if (props && props.onChangeContent) {
            let trgt = event.target;
            let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
            // console.log('%c handleChangeDesign newValue: %O', 'color: maroon;', newValue);

            let udicciPlugin: UdicciPlugin | undefined = find(udicciPluginComponents, (up: UdicciPlugin) => { return up.id === newValue; });
            // console.log('%c handleChangeDesign udicciPlugin: %O', 'color: red;', udicciPlugin);

            let targetItem: any = (content && content.length > pluginIndex ? content[pluginIndex] : null);
            // console.log('%c handleChangeDesign targetItem: %O', 'color: maroon;', targetItem);

            if (!targetItem) targetItem = {};

            if (subSettingName) {
                if (targetItem[settingName] === undefined) targetItem[settingName] = {};
                targetItem[settingName][subSettingName] = newValue;
                if (settingName === 'plugin' && subSettingName === 'id') {
                    // set the plugin version
                    targetItem[settingName].version = (udicciPlugin && udicciPlugin.version ? udicciPlugin.version : 1);
                }
            } else {
                targetItem[settingName] = newValue;
            }

            content[pluginIndex] = targetItem;
            // console.log('%c handleChangeDesign content: %O', 'color: maroon;', content);

            if (props.onChangeContent) props.onChangeContent(content);
        }
    };

    const changeListFilter = (evt: any) => {
        // console.log('%c changeListFilter evt: %O', 'color: maroon;', evt);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c changeListFilter newValue: %O', 'color: maroon;', newValue);
        setSelectPluginFilter(newValue);
    };

    let editPluginComponentElement: any = null;
    let removePluginComponentElement: any = null;
    let moveUpPluginComponentElement: any = null;
    let moveDownPluginComponentElement: any = null;
    let pluginComponentElement: any = null;
    // console.log('%c UdicciPanePlugin pluginComponent: %O', 'color: red;', pluginComponent);
    // console.log('%c UdicciPanePlugin udicciPluginComponents: %O', 'color: red;', udicciPluginComponents);
    let missingPlugin: boolean = false;
    if (pluginComponent && udicciPluginComponents && udicciPluginComponents.length > 0) {
        let udicciPlugin: UdicciPlugin | undefined = find(udicciPluginComponents, (up: UdicciPlugin) => { return up.id === pluginComponentId; });
        // console.log('%c UdicciPanePlugin udicciPlugin: %O', 'color: red;', udicciPlugin);
        if (!udicciPlugin) missingPlugin= true;
        let pluginProps: any = {};
        Object.assign(pluginProps, props);
        pluginProps.plugin = plugin;
        pluginProps.data = pluginDefaultData;
        // pluginComponentElement = null;
        // console.log('%c UdicciPanePlugin pluginProps: %O', 'color: red;', pluginProps);

        // console.log('%c UdicciPanePlugin paneIsSelectedPane: %O', 'color: red;', paneIsSelectedPane);
        // console.log('%c UdicciPanePlugin configure: %O', 'color: red;', configure);
        // console.log('%c UdicciPanePlugin pluginSort: %O', 'color: red;', pluginSort);
        if (paneIsSelectedPane && configure && configure.name === pane.name) {
            let showAdditionalSettings: boolean = false;
            let udicciPluginComponentsSelectionElement: any = null;
            let udicciPluginComponentsSelectionItems: any[] = [];
            let listItemUniqueCategories: string[] = [];
            let listItemUniqueCategorySelectionItems: string[] = [];

            let showAllPluginsMenuItem: any = ( <MenuItem key={'show.all.plugins'} value={'all'}> Show All </MenuItem> );
            listItemUniqueCategorySelectionItems.push(showAllPluginsMenuItem);

            let sortedPlugins: UdicciPlugin[] = [];
            Object.assign(sortedPlugins, udicciPluginComponents);
            if (pluginSort) {
                sortedPlugins = sortedPlugins.sort((a: any,b: any) => {
                    if (a.title < b.title) return -1;  // ascending
                    if (a.title > b.title) return 1;  // descending
                    return 0 //default return value (no sorting)
                });
                if (pluginSort.toString() === 'DESC') sortedPlugins.reverse();
            }
            forEach(sortedPlugins, function(plugin: UdicciPlugin, idx: number) {
                // console.log('%c plugin: %O', 'color: purple;', plugin);
                // console.log('%c idx: %O', 'color: purple;', idx);
                if (dm && dm !== 'on') {
                    if (plugin && plugin.hideInMenu === true) return true;
                    if (plugin && plugin.category === UdicciPluginCategory.Development && currentUser && (currentUser.UserName !== 'tim' && currentUser.UserName !== 'ulyssesdconstantine')) return true;
                    if (plugin && plugin.category === UdicciPluginCategory.Custom && currentUser && (currentUser.UserName !== 'tim' && currentUser.UserName !== 'ulyssesdconstantine')) return true;
                    if (plugin && plugin.category === UdicciPluginCategory.Admin && currentUserRole && !currentUserRole.IsAdministratorRole) return true;
                    if (plugin && plugin.category === UdicciPluginCategory.Special && currentUserRole && !currentUserRole.IsAdministratorRole) return true;
                    // if (plugin && plugin.canPick(props) === true) return true;
                }

                // let canPick: any = plugin.canPick(props);
                // console.log('%c canPick: %O', 'color: purple;', canPick);
                // let canDisplay: any = plugin.canDisplay(props);
                // console.log('%c canDisplay: %O', 'color: purple;', canDisplay);

                if (listItemUniqueCategories.indexOf(plugin.category) < 0) {
                    listItemUniqueCategories.push(plugin.category);
                    let plgnMenuItem: any = (<MenuItem key={plugin.id + '.plugin.category.' + idx + '.' + plugin.category} value={plugin.category}> {plugin.category} </MenuItem>);
                    listItemUniqueCategorySelectionItems.push(plgnMenuItem);
                }

                if (selectPluginFilter && selectPluginFilter !== 'all' && plugin && plugin.category !== selectPluginFilter) return true;

                let hasRenderer: string = (plugin.Renderer ? 'Yes' : 'No');
                let hasIcon: string = (plugin.icon ? 'Yes' : 'No');
                let hasAdminElement: string = (plugin.controls && plugin.controls.Component ? 'Yes' : 'No');

                let additionalSettingsElement: any = null;
                if (showAdditionalSettings) {
                    additionalSettingsElement = (
                        <Fragment>
                            <Typography component="div" variant="caption" color="textPrimary">{plugin.id}</Typography>
                            <Typography component="div" variant="caption" color="textPrimary">Has Renderer: {hasRenderer}</Typography>
                            <Typography component="div" variant="caption" color="textPrimary">Has Icon: {hasIcon}</Typography>
                            <Typography component="div" variant="caption" color="textPrimary">Has Admin Element: {hasAdminElement}</Typography>
                        </Fragment>
                    );
                }

                let addPluginButtonProps: any = {
                    size: 'small',
                    color: 'primary',
                    sx: { float: 'right' },
                    onClick: (evt: any) => choosePluginToAdd(plugin),
                };
                let addPluginButton = (
                    <IconButton {...addPluginButtonProps} aria-label="Add Plugin to Pane">
                        <Icon>{'add'}</Icon>
                    </IconButton>
                );

                let plgnSelectionItemProps: any = {
                    key: plugin.id + '.plugin.selection.' + idx,
                    xs: 12,
                    md: 3,
                };
                let plgnSelectionItem: any = (
                    <Grid item {...plgnSelectionItemProps}>
                        <Paper sx={{ padding: '8px', height: '100%', background: 'rgba(255, 255, 255, 0.1)' }}>
                            {addPluginButton}
                            <Typography component="span" variant="subtitle1">{plugin.title}</Typography>
                            <Typography component="span" variant="caption"> version {plugin.version}</Typography>
                            <Box>
                                <Typography component="div" variant="body2">{plugin.description}</Typography>
                                <Typography component="div" variant="caption">{additionalSettingsElement}</Typography>
                            </Box>
                            <Box>
                                <Typography component="span" variant="caption">category: {plugin.category}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                );
                udicciPluginComponentsSelectionItems.push(plgnSelectionItem);
            });
            // console.log('%c listItemUniqueCategories: %O', 'color: purple;', listItemUniqueCategories);
            let sortButtonProps: any = {
                size: 'small',
                color: (pluginSort === 'ASC' ? 'primary' : (pluginSort === 'DESC' ? 'secondary' : 'default')),
                sx: { float: 'right' },
                onClick: (evt: any) => (pluginSort === 'ASC' ? setPluginSort('DESC') : (pluginSort === 'DESC' ? setPluginSort('') : setPluginSort('ASC'))),
            };
            udicciPluginComponentsSelectionElement = (
                <Grid container spacing={2} direction="row" justifyContent="space-evenly" alignItems="stretch">
                    <Box sx={{ background: 'rgba(255, 255, 255, 0.1)', margin: '4px', padding: '4px', width: '100%' }}>
                        <IconButton {...sortButtonProps} aria-label="Sort Plugin List">
                            <Icon>{'sort'}</Icon>
                        </IconButton>
                        <Select sx={{ width: 'calc(100% - 45px)' }} value={selectPluginFilter} placeholder="Select Category" color="primary" onChange={changeListFilter}>
                            {listItemUniqueCategorySelectionItems}
                        </Select>
                    </Box>
                    {udicciPluginComponentsSelectionItems}
                </Grid>
            );

            // console.log('%c UdicciPanePlugin editPlugin: %O', 'color: red;', editPlugin);
            if (editPlugin) {
                // const rightAlignedButtonTheme: Theme = udicciHelpers.getUdicciThemeTemplate('MuiFab', null);
                // console.log('%c rightAlignedButtonTheme: %O', 'color: blue; font-weight: bold;', rightAlignedButtonTheme);    
                pluginProps.onChangePlugin = onChangePlugin;

                editPluginComponentElement = (
                    <IconButton color="default" aria-label="Close Plugin Form" size="small" onClick={(evt: any) => closePanePluginForm()} sx={{ float: 'right' }}>
                        <Icon>close</Icon>
                    </IconButton>
                );
                removePluginComponentElement = (
                    <IconButton color="warning" aria-label="Remove Plugin" size="small" onClick={(evt: any) => removePlugin()} sx={{ float: 'right' }}>
                        <Icon>delete</Icon>
                    </IconButton>
                );
                moveUpPluginComponentElement = (
                    <IconButton color="default" aria-label="Move Plugin Up" size="small"
                                onClick={(evt: any) => movePlugin('up')}
                                sx={{ float: 'right' }}
                                disabled={(pluginIndex > 0 ? false : true)}
                    >
                        <Icon>keyboard_arrow_up</Icon>
                    </IconButton>
                );
                moveDownPluginComponentElement = (
                    <IconButton color="default" aria-label="Move Plugin Down" size="small"
                                onClick={(evt: any) => movePlugin('down')}
                                sx={{ float: 'right' }}
                                disabled={(pluginIndex < content.length - 1 ? false : true)}
                    >
                        <Icon>keyboard_arrow_down</Icon>
                    </IconButton>
                );
                //     // { icon: <Icon>content_copy</Icon>, name: 'Copy', onClick: null },

                let adminComponent: any = null;
                if (udicciPlugin) {
                    if (udicciPlugin?.controls.Component) {
                        adminComponent = (
                            <Box sx={{ display: 'flow-root', clear: 'both', margin: '8px' }}><Paper elevation={7}>{udicciPlugin?.controls.Component(pluginProps)}</Paper></Box>
                        );
                    }
                } else {
                    adminComponent = udicciPluginComponentsSelectionElement;
                }
                pluginComponentElement = adminComponent;
            } else {
                // console.log('%c sortedPlugins: %O', 'color: maroon;', sortedPlugins);
                // let selectedPluginTitle: string = pluginComponentId;
                // let selectedPluginDescription: string = '';
                // if (udicciPlugin && udicciPlugin.title) selectedPluginTitle = udicciPlugin.title;
                // if (udicciPlugin && udicciPlugin.description) selectedPluginDescription = udicciPlugin.description;

                let pluginComponentElementSx: any = { display: 'flow-root', clear: 'left' };
                // if (speedDialOpen) {
                //     pluginComponentElementSx.margin = '8px';
                //     pluginComponentElementSx.backgroundColor = '#ccc';
                // } else {
                //     pluginComponentElementSx.margin = '0px';
                //     pluginComponentElementSx.backgroundColor = 'unset';
                // }
                let pluginComponentElementSettings: any = {
                    sx: pluginComponentElementSx,
                    key: 'udicci.pane.plugin'
                };
                let pluginDisplayElement: any = null;
                if (udicciPlugin) {
                    pluginDisplayElement = udicciPlugin.Renderer(pluginProps);
                } else {
                    pluginDisplayElement = (
                        <Box sx={{ display: 'flow-root', margin: '8px' }}>
                            <Typography variant="errorMessage" component="div" sx={{ marginLeft: '8px' }}>
                                This Plugin has not been configured yet.  Click here to configure this Plugin.
                            </Typography>
                            <Typography variant="caption" component="div" sx={{ marginLeft: '8px' }}>
                                No content will show for this plugin in the pane until it has been configured.
                            </Typography>
                        </Box>
                    );
                }
                pluginComponentElement = (<Box {...pluginComponentElementSettings}>{pluginDisplayElement}</Box>);
            }
        } else {
            // console.log('%c UdicciPanePlugin udicciPlugin: %O', 'color: green;', udicciPlugin);
            if (udicciPlugin && udicciPlugin.Renderer) pluginComponentElement = udicciPlugin.Renderer(pluginProps);
        }
    }
    // console.log('%c UdicciPanePlugin pluginComponentElement: %O', 'color: red;', pluginComponentElement);
    // console.log('%c UdicciPanePlugin editPluginComponentElement: %O', 'color: red;', editPluginComponentElement);

    let resultElement: any = null;
    let hasActionElement: boolean = ((editPluginComponentElement || removePluginComponentElement || moveUpPluginComponentElement || moveDownPluginComponentElement) ? true : false);
    if (hasActionElement || pluginComponentElement) {
        // console.log('%c UdicciPanePlugin dm: %O', 'color: green;', dm);
        if (dm && dm === 'on') {
            let divTitle: string = "Click Left Mouse Button to Edit the plugin settings.";
            let divProps: any = {};
            let backdropProps: any = {
                open: true,
                onClick: (evt: any) => editPanePlugin(),
                sx: { position: 'relative'},
            };
            if (!editPluginComponentElement) {
                divProps.style = { pointerEvents: 'none' };
                backdropProps.sx.cursor = 'pointer';
                backdropProps.sx.display = 'flow-root';
                backdropProps.sx.maxHeight = '277px';
                backdropProps.sx.overflowY = 'auto';
                backdropProps.sx.background = 'rgba(255, 255, 255, 0.1)';

                resultElement = (
                    <Tooltip title={divTitle} placement="top">
                        <Backdrop {...backdropProps}>
                            <div {...divProps}>
                                {pluginComponentElement}
                            </div>
                        </Backdrop>
                    </Tooltip>
                );
            } else {
                let boxSx: any = {
                    display: 'flow-root',
                    float: 'right',
                    padding: '4px',
                    margin: '8px',
                    marginRight: '16px',
                    borderRadius: '16px',
                    border: '1px solid #555',
                    background: 'rgba(255, 255, 255, 0.3)',
                };
                resultElement = (
                    <Paper sx={{ margin: '8px', background: 'rgba(255, 255, 255, 0.1)' }}>
                        <Box sx={boxSx}>
                            {editPluginComponentElement}
                            {removePluginComponentElement}
                            {moveDownPluginComponentElement}
                            {moveUpPluginComponentElement}
                        </Box>
                        <Box sx={{ padding: '8px' }}>
                            {pluginComponentElement}
                        </Box>
                    </Paper>
                );
            }
        } else {
            resultElement = pluginComponentElement;
        }
    }
    // console.log('%c UdicciPanePlugin resultElement: %O', 'color: red;', resultElement);
    return resultElement;
};

export default UdicciPanePlugin;
