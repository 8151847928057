import { useState } from 'react';

import {
    Box, Typography, Icon, IconButton, InputAdornment, TextField, Paper, Button, Divider,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { styled } from '@mui/material/styles';

// import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext } from 'src/context/udicci-context';

const PaperContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
}));

export const ReportIssuePromptAreaContent: React.FC<any> = (props) => {
    // const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;
    let { currentUser, platformIssueReport } = udicci;
    // console.log('%c ReportIssuePromptAreaContent currentUser: %O', 'color: brown;', currentUser);
    // console.log('%c ReportIssuePromptAreaContent selectedProfile: %O', 'color: brown;', selectedProfile);
    // console.log('%c ReportIssuePromptAreaContent platformIssueReport: %O', 'color: brown;', platformIssueReport);

    let userId: number = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
    // console.log('%c DisplayThePoint userId: %O', 'color: green;', userId);

    const [tempPrompt, setTempPrompt] = useState<string>('');
    const [workingIssue, setWorkingIssue] = useState<any>(platformIssueReport);
    const [showInfoDIalog, setShowInfoDIalog] = useState<boolean>(false);
    const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    // console.log('%c ReportIssuePromptAreaContent tempPrompt: %O', 'color: brown;', tempPrompt);
    // console.log('%c ReportIssuePromptAreaContent workingIssue: %O', 'color: brown;', workingIssue);

    const handleChange = (evt: any) => {
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c handleChange newValue: %O', 'color: maroon;', newValue);
        setTempPrompt(newValue);
        setReadyToSubmit(false);
    };

    const handleChangeIssue = (evt: any, fieldName: string = '') => {
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c handleChangeIssue newValue: %O', 'color: maroon;', newValue);
        // console.log('%c handleChangeIssue fieldName: %O', 'color: maroon;', fieldName);

        let updatedIssue: any = {};
        Object.assign(updatedIssue, workingIssue);

        if (!updatedIssue.perspective) updatedIssue.perspective = {};
        updatedIssue.perspective[fieldName] = newValue;

        setWorkingIssue(updatedIssue);
        setReadyToSubmit(false);
    };

    const enterKeyCheck = (evt: any) => {
        // console.log('%c enterKeyCheck evt: %O', 'color: maroon;', evt);
        if (evt.key === "Enter") submitPrompt();
    };

    const submitPrompt = () => {
        // console.log('%c submitPrompt tempPrompt: %O', 'color: maroon;', tempPrompt);

        let updatedIssue: any = {};
        Object.assign(updatedIssue, workingIssue);

        if (!updatedIssue.perspective) updatedIssue.perspective = {};
        updatedIssue.perspective.descriptionOfExperience = tempPrompt;

        setWorkingIssue(updatedIssue);
        setTempPrompt('');
    };

    const save = (evt: any) => {
        // console.log('%c save workingIssue: %O', 'color: maroon;', workingIssue);
        // console.log('%c save readyToSubmit: %O', 'color: maroon;', readyToSubmit);
        if (readyToSubmit) {
            udicci.submitPlatformIssueReport();
            setSubmitted(true);
        } else {
            udicci.setPlatformIssueReport(workingIssue);
            setReadyToSubmit(true);
        }
    };

    const displayInfoDialog = () => {
        // console.log('%c displayInfoDialog platformIssueReport: %O', 'color: orange;', platformIssueReport);

        let rptProfile: any =(platformIssueReport && platformIssueReport.profile ? platformIssueReport.profile : null);
        let rptPorta: any =(platformIssueReport && platformIssueReport.porta ? platformIssueReport.porta : null);
        let rptPane: any =(platformIssueReport && platformIssueReport.pane ? platformIssueReport.pane : null);
        let rptUser: any =(platformIssueReport && platformIssueReport.user ? platformIssueReport.user : null);
        let rptBrowser: any =(platformIssueReport && platformIssueReport.browser ? platformIssueReport.browser : null);
        let rptAddntlData: any =(platformIssueReport && platformIssueReport.additionalData ? platformIssueReport.additionalData : null);

        let platformIssueReportElement: any = null;
        if (platformIssueReport) {
            let selectedPaneInfoElement: any = null;
            if (rptPane) {
                let panePluginElement: any = null;
                let panePluginDisplayComponentElement: any = null;
                let hasPlugins: boolean = false;
                if (rptPane.plugins) {
                    let pluginText: string = '';
                    if (typeof(rptPane.plugins) === 'string') {
                        pluginText = rptPane.plugins;
                    } else if (typeof(rptPane.plugins) === 'object' && Array.isArray(rptPane.plugins)) {
                        pluginText = rptPane.plugins.length.toString() + (rptPane.plugins.length > 1 ? ' plugins are ' : ' plugin is ') + 'currently installed on this pane.';
                        hasPlugins = true;
                    }
                    panePluginElement = (
                        <Box>
                            <Typography variant="caption" component="span" sx={{ marginRight: '4px' }}>Plugins: </Typography>
                            <Typography variant="body1" component="span">{pluginText}</Typography>
                        </Box>
                    );
                }
                // if (!hasPlugins && rptPane.displayComponent) {
                //     panePluginDisplayComponentElement = (
                //         <Box>
                //             <Typography variant="caption" component="span" sx={{ marginRight: '4px' }}>Display Component: </Typography>
                //             <Typography variant="body1" component="span">{rptPane.displayComponent}</Typography>
                //         </Box>
                //     );
                // }
                selectedPaneInfoElement = (
                    <Box>
                        <Divider />
                        <Box sx={{ margin: '8px' }}> {/*  Selected Pane Information  */}
                            <Typography variant="caption" component="div">Selected Pane Information</Typography>
                            <Box>
                                <Typography variant="overFieldLabel" component="span" sx={{ marginRight: '4px' }}>Name:</Typography>
                                <Typography variant="body1" component="span">{(rptPane && rptPane.name ? rptPane.name : '_______')}</Typography>
                            </Box>
                            {panePluginElement}
                            {panePluginDisplayComponentElement}
                        </Box>
                    </Box>
                );
            }
            platformIssueReportElement = (
                <Box>
                    <Typography variant="caption" component="div">The following information is sent to Udicci to help us solve problems and make your experience better.</Typography>
                    <Box sx={{ margin: '8px' }}> {/*  Selected Profile Information  */}
                        <Typography variant="caption" component="div">Selected Profile Information</Typography>
                        <Box>
                            <Typography variant="overFieldLabel" component="span" sx={{ marginRight: '4px' }}>Display Name:</Typography>
                            <Typography variant="body1" component="span">{(rptProfile && rptProfile.displayName ? rptProfile.displayName : '_______')}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="overFieldLabel" component="span" sx={{ marginRight: '4px' }}>Profile Url:</Typography>
                            <Typography variant="body1" component="span">{(rptProfile && rptProfile.url ? rptProfile.url : '_______')}</Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ margin: '8px' }}> {/*  Selected Porta Information  */}
                        <Typography variant="caption" component="div">Selected Porta Information</Typography>
                        <Box>
                            <Typography variant="overFieldLabel" component="span" sx={{ marginRight: '4px' }}>Name:</Typography>
                            <Typography variant="body1" component="span">{(rptPorta && rptPorta.name ? rptPorta.name : '_______')}</Typography>
                        </Box>
                    </Box>
                    {selectedPaneInfoElement}
                    <Divider />
                    <Box sx={{ margin: '8px' }}> {/*  Current User Information  */}
                        <Typography variant="caption" component="div">Current User Information</Typography>
                        <Box> {/*  Username  */}
                            <Typography variant="overFieldLabel" component="span" sx={{ marginRight: '4px' }}>Username:</Typography>
                            <Typography variant="body1" component="span">{(rptUser && rptUser.username ? rptUser.username : '_______')}</Typography>
                        </Box>
                        <Box> {/*  Url  */}
                            <Typography variant="overFieldLabel" component="span" sx={{ marginRight: '4px' }}>
                                User's .Me Profile Url. ({(rptUser && rptUser.url ? rptUser.url : '_______')})
                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ margin: '8px' }}> {/*  Environment Information  */}
                        <Typography variant="caption" component="div">Current Environment Information</Typography>
                        <Box> {/*  Browser  */}
                            <Typography variant="overFieldLabel" component="span" sx={{ marginRight: '4px' }}>Browser:</Typography>
                            <Typography variant="body1" component="span">{(rptBrowser && rptBrowser.name ? rptBrowser.name : '_______')}</Typography>
                        </Box>
                        <Box> {/*  Browser Version  */}
                            <Typography variant="overFieldLabel" component="span" sx={{ marginRight: '4px' }}>Browser Version:</Typography>
                            <Typography variant="body1" component="span">{(rptBrowser && rptBrowser.version ? rptBrowser.version : '_______')}</Typography>
                        </Box>
                        <Box> {/*  Operating System  */}
                            <Typography variant="overFieldLabel" component="span" sx={{ marginRight: '4px' }}>Operating System:</Typography>
                            <Typography variant="body1" component="span">{(rptBrowser && rptBrowser.os ? rptBrowser.os : '_______')}</Typography>
                        </Box>
                        <Box> {/*  Is Mobile  */}
                            <Typography variant="overFieldLabel" component="span" sx={{ marginRight: '4px' }}>Is Mobile:</Typography>
                            <Typography variant="body1" component="span">{(rptBrowser && rptBrowser.mobile ? 'Is Mobile' : 'Not Mobile')}</Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ margin: '8px' }}> {/*  Additional Information  */}
                        <Typography variant="caption" component="div">Additional Information</Typography>
                        <Box> {/*  Browser  */}
                            <Typography variant="overFieldLabel" component="span" sx={{ marginRight: '4px' }}>Udicci.IT API Url:</Typography>
                            <Typography variant="body1" component="span">{(rptAddntlData && rptAddntlData.apiUrl ? rptAddntlData.apiUrl : '_______')}</Typography>
                        </Box>
                        <Box> {/*  Is Mobile  */}
                            <Typography variant="overFieldLabel" component="span" sx={{ marginRight: '4px' }}>User Session Timed Out?</Typography>
                            <Typography variant="body1" component="span">{(rptAddntlData && rptAddntlData.userHasTimedOut ? 'Yes' : 'No')}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ textAlign: 'center', margin: '32px' }}>
                        <Typography variant="caption" component="div">
                            And some other information.  Too much and rather unnecessary to display here.
                        </Typography>
                        <Typography variant="caption" component="div">
                            Ask us if you want to see the full report.
                        </Typography>
                    </Box>
                </Box>
            );
        }
        // console.log('%c getGridItem gridItems: %O', 'color: orange;', gridItems);

        let sectionHeaderElement: any = null;
        // let sectionHeaderElement: any = (
        //     <Box sx={{ display: 'flex', float: 'right', textAlign: 'right' }}>
        //         <Typography variant="subtitle1"># Records: {itemRecordCount}</Typography>
        //         <IconButton size="small" onClick={(evt: any) => closeItem()}>
        //             <Icon>close</Icon>
        //         </IconButton>
        //     </Box>
        // );

        return (
            <Dialog open={true} onClose={() => { setShowInfoDIalog(false); }} fullWidth={true} maxWidth={'md'}>
                <DialogTitle classes={{ root: 'dialogTitleRoot' }}>
                    <Button onClick={() => setShowInfoDIalog(false)} sx={{ float: 'right' }}>Close</Button>
                    <Box sx={{ display: 'flow-root' }}>
                        <Typography variant="subtitle1">Platform Issue Report</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {platformIssueReportElement}
                </DialogContent>
            </Dialog>
        );
    };

    let containerProps: any = {
        padding: '8px',
        borderTop: 0,
    };
    // if (!udicci.isMobileDevice()) {
    //     containerProps.width = { sm: `calc(100% - ${udicci.standardMenuDrawerWidth}px)` };
    // } else {
    //     containerProps.left = 0;
    //     containerProps.borderTop = '1px solid #000;';
    // }

    let promptContainerSx: any = { maxWidth: '750px', marginLeft: 'auto', marginRight: 'auto' };

    let sendDisabled: boolean = (tempPrompt.length <= 2 ? true : false);
    let sendPromptIcon: any = (
        <InputAdornment position="end">
            <IconButton size="small" disabled={sendDisabled} onClick={submitPrompt}>
                <Icon fontSize="small">send</Icon>
            </IconButton>
        </InputAdornment>
    );

    let experiencedPlaceholder: string = 'What did you experience?';
    let expectedPlaceholder: string = 'What did you expect?';
    let promptElement: any = null;
    if (udicci.userIsLoggedIn() && !udicci.userHasTimedOut && userId && workingIssue) {
        let perspective: any = (workingIssue.perspective ? workingIssue.perspective : null);
        // console.log('%c perspective: %O', 'color: maroon;', perspective);
        if (perspective && perspective.descriptionOfExperience.length <= 0) {
            experiencedPlaceholder = 'Start by providing a description of what you experienced.';
            promptElement = (
                <Box>
                    <TextField fullWidth
                                type="text"
                                value={tempPrompt}
                                placeholder={experiencedPlaceholder}
                                margin="normal"
                                variant='filled'
                                InputProps={{ endAdornment: sendPromptIcon, }}
                                sx={{ marginTop: '8px' }}
                                onChange={handleChange}
                                onKeyDown={enterKeyCheck}
                    />
                </Box>
            );
        } else {
            experiencedPlaceholder = 'What did you Experience on the platform?';
            expectedPlaceholder = 'What did you Expect from the platform?';

            let descriptionOfExperience: string = (perspective && perspective.descriptionOfExperience ? perspective.descriptionOfExperience : '');
            // console.log('%c descriptionOfExperience: %O', 'color: maroon;', descriptionOfExperience);
            let descriptionOfExpectation: string = (perspective && perspective.descriptionOfExpectation ? perspective.descriptionOfExpectation : '');
            // console.log('%c descriptionOfExpectation: %O', 'color: maroon;', descriptionOfExpectation);
            let errorMessage: string = (perspective && perspective.errorMessage ? perspective.errorMessage : '');
            // console.log('%c errorMessage: %O', 'color: maroon;', errorMessage);

            let dialog: any = null;
            if (showInfoDIalog) dialog = displayInfoDialog();

            promptElement = (
                <Box sx={{ paddingTop: '4px' }}>
                    {dialog}
                    <Typography variant="caption" component="div" sx={{ marginBottom: '4px' }}>
                        This form provides a way for you to let us know that you are having
                        issues with your User Experience on the Udicci platform.
                    </Typography>
                    <Box sx={{ marginBottom: '4px' }}>
                        <Divider />
                    </Box>
                    <Box sx={{ marginBottom: '4px' }}>
                        <Typography variant="overFieldLabel">
                            What did you experience?
                        </Typography>
                        <TextField fullWidth
                                    type={'text'} multiline={true} minRows={1} maxRows={2}
                                    value={descriptionOfExperience}
                                    placeholder={experiencedPlaceholder}
                                    margin="normal" disabled={submitted}
                                    variant='filled'
                                    sx={{ marginTop: '8px' }}
                                    onChange={(evt: any) => { handleChangeIssue(evt, 'descriptionOfExperience'); }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '4px' }}>
                        <Typography variant="overFieldLabel">
                            What did you expect?
                        </Typography>
                        <TextField fullWidth
                                    type={'text'} multiline={true} minRows={1} maxRows={3}
                                    value={descriptionOfExpectation}
                                    placeholder={expectedPlaceholder}
                                    margin="normal" disabled={submitted}
                                    variant='filled'
                                    sx={{ marginTop: '8px' }}
                                    onChange={(evt: any) => { handleChangeIssue(evt, 'descriptionOfExpectation'); }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '4px' }}>
                        <Typography variant="overFieldLabel">
                            What error message was displayed?
                        </Typography>
                        <TextField fullWidth
                                    type={'text'}
                                    value={errorMessage}
                                    placeholder={'If an error message was displayed to you, please share it here.'}
                                    margin="normal" disabled={submitted}
                                    variant='filled'
                                    sx={{ marginTop: '8px' }}
                                    onChange={(evt: any) => { handleChangeIssue(evt, 'errorMessage'); }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '4px' }}>
                        <Divider />
                    </Box>
                    <Box sx={{ display: 'flow-root' }}>
                        <Box sx={{ display: 'flow-root', float: 'right', padding: '8px' }}>
                            <Button disabled={submitted} variant="contained" color="primary" onClick={save}>{(readyToSubmit ? 'Submit' : 'Ready')}</Button>
                        </Box>
                        <Box sx={{ display: 'flow-root', marginBottom: '4px' }}>
                            <Typography variant="body2" component="div">
                                When you submit this form, an email will be sent to Udicci.
                            </Typography>
                            <Box>
                                <Typography variant="body2" component="span">
                                    To view what information is being sent, 
                                </Typography>
                                <Typography variant="clickable" component="span" sx={{ marginLeft: '8px' }} onClick={() => setShowInfoDIalog(true)}>
                                    you can click here.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            );
        }
    }

    return (
        <Box {...containerProps}>
            <Box {...promptContainerSx}>
                <PaperContainer>
                    {promptElement}
                </PaperContainer>
            </Box>
        </Box>
    );
};
