
import React from 'react';

import { Box, Typography } from '@mui/material';

const DefaultLayoutSettings: React.FC<any> = (props) => {
    return (
        <Box>
            <Typography variant="subtitle2" component="div">Default Layout Settings</Typography>
            <Typography variant="caption" component="div">coming soon</Typography>
        </Box>
    );
}

export default DefaultLayoutSettings;
