
import { useEffect, useCallback, useRef } from "react"
import { useUdicciContext } from 'src/context/udicci-context';
import useUdicciProfile from 'src/hooks/useUdicciProfile';
import { UdicciRecord } from 'src/classes/udicci-record';
import { UdicciPermissions } from "src/classes/udicci-record";

export default function useUdicciSocialSolution( socialSolution: UdicciRecord ) {
    const refSocialSolutionRecord = useRef<UdicciRecord>(socialSolution);
    // console.log('%c useUdicciSocialSolution refSocialSolutionRecord: %O', 'color: red;', refSocialSolutionRecord);
    var socialSolutionId: number = 0;
    if (refSocialSolutionRecord && refSocialSolutionRecord.current && refSocialSolutionRecord.current.recordId) {
        socialSolutionId = refSocialSolutionRecord.current.recordId;
    }

    const udicciContext = useUdicciContext();

    var { udicci } = udicciContext.state;
    var { selectedProfile, currentUser } = udicci;
    // console.log('%c useUdicciSocialSolution selectedProfile: %O', 'color: darkgoldenrod;', selectedProfile);
    // console.log('%c useUdicciSocialSolution currentUser: %O', 'color: darkgoldenrod;', currentUser);

    const profile = useUdicciProfile(selectedProfile);
    // console.log('%c useUdicciSocialSolution profile: %O', 'color: darkgoldenrod;', profile);
    var { udicciProfile } = profile;
    // console.log('%c useUdicciSocialSolus
    var udicciUserRole = (udicciProfile && udicciProfile.data && udicciProfile.data.UdicciUserRole ? udicciProfile.data.UdicciUserRole : null);
    // console.log('%c useUdicciSocialSolution udicciUserRole: %O', 'color: darkgoldenrod;', udicciUserRole);
    var udicciUserRoleId = (udicciUserRole && udicciUserRole.UdicciRecordId ? udicciUserRole.UdicciRecordId : 0);
    // console.log('%c useUdicciSocialSolution udicciUserRoleId: %O', 'color: darkgoldenrod;', udicciUserRoleId);

    useEffect(() => {
        var cur: UdicciRecord | null = (refSocialSolutionRecord && refSocialSolutionRecord.current ? refSocialSolutionRecord.current : null);
        if (socialSolution && (!cur || (cur && cur.recordId === socialSolution.recordId))) {
            refSocialSolutionRecord.current = socialSolution;
        }
    }, [socialSolution]);

    const set = useCallback((socialSolution: UdicciRecord) => {
        var cur: UdicciRecord | null = (refSocialSolutionRecord && refSocialSolutionRecord.current ? refSocialSolutionRecord.current : null);
        if (socialSolution && (!cur || (cur && cur.recordId === socialSolution.recordId))) {
            refSocialSolutionRecord.current = socialSolution;
        }
    }, []);

    const getSocialSolutionSetting = (options: any = {}): any => {
        // console.log('%c getSocialSolutionSetting options: %O', 'color: purple;', options);
        // console.log('%c getSocialSolutionSetting refSocialSolutionRecord: %O', 'color: purple;', refSocialSolutionRecord);

        var mediatorName: any = (options && options.mediatorName ? options.mediatorName : '');
        // console.log('%c getSocialSolutionSetting mediatorName: %O', 'color: purple;', mediatorName);
        // if (!mediatorName) return null;
        var settingName: any = (options && options.setting ? options.setting : '');
        // console.log('%c getSocialSolutionSetting settingName: %O', 'color: purple;', settingName);
        if (!settingName) return null;

        var ss: UdicciRecord | null = (refSocialSolutionRecord && refSocialSolutionRecord.current ? refSocialSolutionRecord.current : null);
        if (!ss) return null;

        var ssData: any = (ss.data ? ss.data : null);
        var ssSettings: any = (ssData && ssData.jsonSettingsJson ? ssData.jsonSettingsJson : null);
        // console.log('%c getSocialSolutionSetting ssSettings: %O', 'color: purple;', ssSettings);
        var features: any = (ssSettings && ssSettings.features ? ssSettings.features : null);
        // console.log('%c getSocialSolutionSetting features: %O', 'color: purple;', features);
        var mediators: any = (ssSettings && ssSettings.mediators ? ssSettings.mediators : null);
        // console.log('%c getSocialSolutionSetting mediators: %O', 'color: purple;', mediators);

        var mediatorSettings: any = (mediatorName && mediators && mediators[mediatorName] ? mediators[mediatorName] : null);
        // console.log('%c getSocialSolutionSetting mediatorSettings: %O', 'color: purple;', mediatorSettings);
        var mediatorFeatures: any = (mediatorSettings && mediatorSettings.features ? mediatorSettings.features : null);
        // console.log('%c getSocialSolutionSetting mediatorFeatures: %O', 'color: purple;', mediatorFeatures);

        var featureId: any = (options && options.featureId ? options.featureId : 0);
        // console.log('%c getSocialSolutionSetting featureId: %O', 'color: purple;', featureId);
        var featureSettings = (mediatorFeatures && featureId ? mediatorFeatures.find((x: any) => x.id === featureId ) : null);
        // console.log('%c getSocialSolutionSetting featureSettings: %O', 'color: purple;', featureSettings);

        var listConfig: any = null;
        var formConfig: any = null;
        if (featureSettings) {
            listConfig = (featureSettings && featureSettings.list ? featureSettings.list : null);
            formConfig = (featureSettings && featureSettings.form ? featureSettings.form : null);
        }
        // console.log('%c getSocialSolutionSetting listConfig: %O', 'color: purple;', listConfig);
        // console.log('%c getSocialSolutionSetting formConfig: %O', 'color: purple;', formConfig);

        if (settingName === 'features') return features;
        if (settingName === 'mediators') return mediators;
        if (settingName === 'featureSettings') return featureSettings;
        if (settingName === 'listConfig') return listConfig;
        if (settingName === 'formConfig') return formConfig;

        return null;
    };

    const getSocialSolutionEngagementActions = (options: any = {}): any[] => {
        // console.log('%c useUdicciSocialSolution getSocialSolutionEngagementActions options: %O', 'color: purple;', options);
        // console.log('%c useUdicciSocialSolution getSocialSolutionEngagementActions refSocialSolutionRecord: %O', 'color: purple;', refSocialSolutionRecord);
        var ss: UdicciRecord | null = (refSocialSolutionRecord && refSocialSolutionRecord.current ? refSocialSolutionRecord.current : null);
        var ssData: any = (ss && ss.data ? ss.data : null);
        var ssSettings: any = (ssData && ssData.jsonSettingsJson ? ssData.jsonSettingsJson : null);
        var engagementActions: any = (ssSettings && ssSettings.engagementActions ? ssSettings.engagementActions : null);
        // console.log('%c useUdicciSocialSolution getSocialSolutionEngagementActions engagementActions: %O', 'color: purple;', engagementActions);
        return engagementActions;
    };

    const getFeaturePermissions = (options: any = {}): UdicciPermissions => {
        // console.log('%c useUdicciSocialSolution getFeaturePermissions options: %O', 'color: purple;', options);
        // console.log('%c useUdicciSocialSolution getFeaturePermissions refSocialSolutionRecord: %O', 'color: purple;', refSocialSolutionRecord);
        var permsResponse: UdicciPermissions = {
            CanView: false,
            CanAdd: false,
            CanEdit: false,
            CanDelete: false,
            CanDuplicate: false,
            CanProvision: false,
        }
        if (!refSocialSolutionRecord || (refSocialSolutionRecord && !refSocialSolutionRecord.current)) return permsResponse;

        var ss: UdicciRecord = refSocialSolutionRecord.current;
        // console.log('%c useUdicciSocialSolution getFeaturePermissions ss: %O', 'color: purple;', ss);
        var ssId: number = (ss.recordId ? ss.recordId : 0);
        if (ssId <= 0) return permsResponse;

        var feature: any = (options.feature ? options.feature : null);
        // console.log('%c useUdicciSocialSolution getFeaturePermissions feature: %O', 'color: purple;', feature);
        if (!feature) return permsResponse;

        // var featureId: any = (feature.UdicciRecordId ? feature.UdicciRecordId : 0);
        // console.log('%c useUdicciSocialSolution getFeaturePermissions featureId: %O', 'color: purple;', featureId);
        // var featureName: string = (feature.Name ? feature.Name : '');
        // console.log('%c useUdicciSocialSolution getFeaturePermissions featureName: %O', 'color: purple;', featureName);
        // console.log('%c useUdicciSocialSolution getFeaturePermissions ss (%s): %O', 'color: purple;', ssId, ss);

        var mediatorName: string = (options.mediator ? options.mediator : '');
        // console.log('%c useUdicciSocialSolution getFeaturePermissions mediatorName: %O', 'color: purple;', mediatorName);
        var profileSolutionSettings: any = profile.getSetting(mediatorName, ssId, 'solution')
        // console.log('%c useUdicciSocialSolution getFeaturePermissions profileSolutionSettings (%s): %O', 'color: purple;', ssId, profileSolutionSettings);

        var publicPermissions = (profileSolutionSettings && profileSolutionSettings.public ? profileSolutionSettings.public : null);
        // console.log('%c useUdicciSocialSolution getFeaturePermissions publicPermissions: %O', 'color: purple;', publicPermissions);
        var defaultPermissions = (profileSolutionSettings && profileSolutionSettings.default ? profileSolutionSettings.default : null);
        // console.log('%c useUdicciSocialSolution getFeaturePermissions defaultPermissions: %O', 'color: purple;', defaultPermissions);

        if (currentUser && currentUser.UdicciUserId > 0) {
            var roleOverrides = (profileSolutionSettings && profileSolutionSettings.RoleOverrides ? profileSolutionSettings && profileSolutionSettings.RoleOverrides : null);
            // console.log('%c useUdicciSocialSolution getFeaturePermissions roleOverrides: %O', 'color: purple;', roleOverrides);
            var uro = (udicciUserRoleId > 0 && roleOverrides && roleOverrides[udicciUserRoleId] ? roleOverrides[udicciUserRoleId] : null);
            // console.log('%c useUdicciSocialSolution getFeaturePermissions userRoleOverride: %O', 'color: purple;', uro);
            if (uro) {
                if (uro.CanView !== undefined && permsResponse.CanView !== uro.CanView) permsResponse.CanView = uro.CanView;
                if (uro.CanAdd !== undefined && permsResponse.CanAdd !== uro.CanAdd) permsResponse.CanAdd = uro.CanAdd;
                if (uro.CanEdit !== undefined && permsResponse.CanEdit !== uro.CanEdit) permsResponse.CanEdit = uro.CanEdit;
                if (uro.CanEditIfOwner !== undefined && permsResponse.CanEditIfOwner !== uro.CanEditIfOwner) permsResponse.CanEditIfOwner = uro.CanEditIfOwner;
                if (uro.CanDelete !== undefined && permsResponse.CanDelete !== uro.CanDelete) permsResponse.CanDelete = uro.CanDelete;
                if (uro.CanDeleteIfOwner !== undefined && permsResponse.CanDeleteIfOwner !== uro.CanDeleteIfOwner) permsResponse.CanDeleteIfOwner = uro.CanDeleteIfOwner;
                if (uro.InternalReferenceOnly !== undefined && permsResponse.InternalReferenceOnly !== uro.InternalReferenceOnly) permsResponse.InternalReferenceOnly = uro.InternalReferenceOnly;
                if (uro.LimitedView !== undefined && permsResponse.LimitedView !== uro.LimitedView) permsResponse.LimitedView = uro.LimitedView;
                if (uro.ViewFields !== undefined && permsResponse.ViewFields !== uro.ViewFields) permsResponse.ViewFields = uro.ViewFields;
                if (uro.overrides !== undefined && permsResponse.overrides !== uro.overrides) permsResponse.overrides = uro.overrides;
            } else {
                if (defaultPermissions) permsResponse = defaultPermissions;
            }
        } else {
            if (publicPermissions) permsResponse = publicPermissions;
        }
        // console.log('%c useUdicciSocialSolution getFeaturePermissions permsResponse: %O', 'color: purple;', permsResponse);

        return permsResponse;
    };

    const getMediatorPermissions = (options: any = {}): UdicciPermissions | null => {
        // console.log('%c useUdicciSocialSolution getMediatorPermissions options: %O', 'color: purple;', options);
        // console.log('%c useUdicciSocialSolution getMediatorPermissions refSocialSolutionRecord: %O', 'color: purple;', refSocialSolutionRecord);
        var permsResponse: UdicciPermissions | null = null;
        if (!refSocialSolutionRecord || (refSocialSolutionRecord && !refSocialSolutionRecord.current)) return permsResponse;

        var ss: UdicciRecord = refSocialSolutionRecord.current;
        // console.log('%c useUdicciSocialSolution getMediatorPermissions ss: %O', 'color: purple;', ss);
        var ssId: number = (ss.recordId ? ss.recordId : 0);
        if (ssId <= 0) return permsResponse;

        // var mediatorName: string = (options.mediator ? options.mediator : '');
        // console.log('%c useUdicciSocialSolution getMediatorPermissions mediatorName: %O', 'color: purple;', mediatorName);
        // var profileSolutionSettings: any = profile.getSetting(mediatorName, ssId, 'solution')
        // console.log('%c useUdicciSocialSolution getMediatorPermissions profileSolutionSettings: %O', 'color: purple;', profileSolutionSettings);

        permsResponse = getFeaturePermissions(options);
        // console.log('%c useUdicciSocialSolution getMediatorPermissions permsResponse from getFeaturePermissions: %O', 'color: purple;', permsResponse);

        return permsResponse;
    };

    return {
        set, getSocialSolutionSetting, getSocialSolutionEngagementActions,
        getFeaturePermissions, getMediatorPermissions,
        socialSolution: refSocialSolutionRecord.current, socialSolutionId
    };
}

/**
 * 
 * Usage:
 * 
 */
