
import { find, values } from 'underscore';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// import { udicciStyles } from 'src/theme/shared-styles';

export const MintMVnfTMenu: React.FC<any> = ({ record, engagementAction, onClick }) => {
    // console.log('%c MintMVnfTMenu engagementAction: %O', 'color: blue;', engagementAction);
    // console.log('%c MintMVnfTMenu onClick: %O', 'color: blue;', onClick);
    // const theme = useTheme();
    // const udicciClasses = udicciStyles(theme);

    let udicciMediator: string = (record.udicciMediator ? record.udicciMediator : '');
    // console.log('%c MintMVnfTMenu udicciMediator: %O', 'color: maroon;', udicciMediator);
    let permissions: any = (record.permissions ? record.permissions : null);
    // console.log('%c MintMVnfTMenu permissions: %O', 'color: maroon;', permissions);

    let eaAccess = (engagementAction.access ? engagementAction.access : null);
    // console.log('%c MintMVnfTMenu eaAccess: %O', 'color: blue;', eaAccess);
    let eaMediators = (eaAccess && eaAccess.mediators ? eaAccess.mediators : []);
    // console.log('%c MintMVnfTMenu eaMediators: %O', 'color: blue;', eaMediators);

    // let curUdicciListState = (udicciListContext && udicciListContext.state ? udicciListContext.state : null);
    // console.log('%c MintMVnfTMenu curUdicciListState: %O', 'color: maroon;', curUdicciListState);

    // let engageUdicciRecord: any = (curUdicciListState && curUdicciListState.onEngageRecord ? curUdicciListState.onEngageRecord : null);
    // console.log('%c MintMVnfTMenu engageUdicciRecord: %O', 'color: maroon;', engageUdicciRecord);

    const triggerEngagementAction = (ea: any) => {
        // console.log('%c MintMVnfTMenu triggerEngagementAction ea: %O', 'color: maroon;', ea);
        if (onClick) onClick(ea);
    };

    let mediatorActions = find(values(eaMediators), function(m: any) {
        return m.name === udicciMediator;
    });
    // console.log('%c mediatorActions: %O', 'color: blue;', mediatorActions);
    let requiredPermissions = (mediatorActions && mediatorActions.requiredPermissions ? mediatorActions.requiredPermissions : null);
    // console.log('%c requiredPermissions: %O', 'color: blue;', requiredPermissions);

    let allowed = (mediatorActions !== undefined ? true : false);
    if (requiredPermissions && requiredPermissions.length > 0) {
        allowed = false;
        let fp = requiredPermissions[0];
        // console.log('%c fp: %O', 'color: blue;', fp);
        let permValue = permissions[fp.permission];
        // console.log('%c permValue: %O', 'color: blue;', permValue);
        if (fp.required && permValue === true) allowed = true;
        if (!fp.required) allowed = true;
    }
    // console.log('%c allowed: %O', 'color: blue;', allowed);

    let rval: any = null;
    if (allowed) {
        // console.log('%c ea: %O', 'color: blue;', ea);
        let enagementMenuText = '';
        if (engagementAction && engagementAction.label && engagementAction.label.text) {
            enagementMenuText = engagementAction.label.text;
        } else if (engagementAction && engagementAction.name) {
            enagementMenuText = engagementAction.name;
        } else {
            enagementMenuText = engagementAction.action;
        }

        rval = (
            <Button size="small"
                    onClick={(evt: any) => triggerEngagementAction(engagementAction)}
                    variant="text"
            >
                <Typography variant="body2" component="span">
                    {enagementMenuText}
                </Typography>
                <Typography variant="body2" component="span">
                    .
                </Typography>
            </Button>
        );
    }

    return rval;
};
