import React from 'react';

import { useTheme } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

import { useUdicciContext, onChangeProfile } from 'src/context/udicci-context';

import { udicciStyles } from 'src/theme/shared-styles';

const ConfigureContactCardSettings: React.FC<any> = (props) => {
    // console.log('%c ConfigureContactCardSettings props: %O', 'color: maroon;', props);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const udicciContext = useUdicciContext();
    // console.log('%c ConfigureProfileSettings udicciContext: %O', 'color: red;', udicciContext);
    let { udicci } = udicciContext.state;
    let { selectedProfile } = udicci;
    // console.log('%c ConfigureProfileSettings selectedProfile: %O', 'color: maroon;', selectedProfile);

    let profile: any = null;
    if (selectedProfile && selectedProfile.data) {
        profile = selectedProfile.data;
    }

    const changeProfile = (fieldName: string, evt: any) => {
        // console.log('%c changeProfile fieldName: %O', 'color: red;', fieldName);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        onChangeProfile(fieldName, newValue);
    }

    let settings = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
    // console.log('%c ConfigureContactCardSettings settings: %O', 'color: maroon;', settings);
    let contactCard = (settings && settings.contactCard ? settings.contactCard : null);
    // console.log('%c ConfigureContactCardSettings contactCard: %O', 'color: maroon;', contactCard);
    let displayElement = (
        <div>
            <div style={{ padding: '8px' }}>
                <FormControl sx={udicciClasses.fieldArea} fullWidth>
                    <InputLabel htmlFor="contactCard.firstName">
                        <Typography variant="caption" color="primary">First Name</Typography>
                    </InputLabel>
                    <Input id="contactCard.firstName"
                        type={'text'}
                        value={(contactCard !== null && contactCard.firstName ? contactCard.firstName : '')}
                        onChange={(evt: any) => changeProfile('contactCard.firstName', evt)}
                    />
                </FormControl>
            </div>
            <div style={{ padding: '8px' }}>
                <FormControl sx={udicciClasses.fieldArea} fullWidth>
                    <InputLabel htmlFor="contactCard.lastName">
                        <Typography variant="caption" color="primary">Last Name</Typography>
                    </InputLabel>
                    <Input id="contactCard.lastName"
                        type={'text'}
                        value={(contactCard !== null && contactCard.lastName ? contactCard.lastName : '')}
                        onChange={(evt: any) => changeProfile('contactCard.lastName', evt)}
                    />
                </FormControl>
            </div>
            <div style={{ padding: '8px' }}>
                <FormControl sx={udicciClasses.fieldArea} fullWidth>
                    <InputLabel htmlFor="contactCard.email">
                        <Typography variant="caption" color="primary">Email</Typography>
                    </InputLabel>
                    <Input id="contactCard.email"
                        type={'text'}
                        value={(contactCard !== null && contactCard.email ? contactCard.email : '')}
                        onChange={(evt: any) => changeProfile('contactCard.email', evt)}
                    />
                </FormControl>
            </div>
            <div style={{ padding: '8px' }}>
                <FormControl sx={udicciClasses.fieldArea} fullWidth>
                    <InputLabel htmlFor="contactCard.phoneNumber">
                        <Typography variant="caption" color="primary">Phone Number</Typography>
                    </InputLabel>
                    <Input id="contactCard.phoneNumber"
                        type={'text'}
                        value={(contactCard !== null && contactCard.phoneNumber ? contactCard.phoneNumber : '')}
                        onChange={(evt: any) => changeProfile('contactCard.phoneNumber', evt)}
                    />
                </FormControl>
            </div>
            <div style={{ padding: '8px' }}>
                <FormControl sx={udicciClasses.fieldArea} fullWidth>
                    <InputLabel htmlFor="contactCard.youTubeUrl">
                        <Typography variant="caption" color="primary">YouTube Url</Typography>
                    </InputLabel>
                    <Input id="contactCard.youTubeUrl"
                        type={'text'}
                        value={(contactCard !== null && contactCard.youTubeUrl ? contactCard.youTubeUrl : '')}
                        onChange={(evt: any) => changeProfile('contactCard.youTubeUrl', evt)}
                    />
                </FormControl>
            </div>
            <div style={{ padding: '8px' }}>
                <FormControl sx={udicciClasses.fieldArea} fullWidth>
                    <InputLabel htmlFor="contactCard.linkedInUrl">
                        <Typography variant="caption" color="primary">LinkedIn Url</Typography>
                    </InputLabel>
                    <Input id="contactCard.linkedInUrl"
                        type={'text'}
                        value={(contactCard !== null && contactCard.linkedInUrl ? contactCard.linkedInUrl : '')}
                        onChange={(evt: any) => changeProfile('contactCard.linkedInUrl', evt)}
                    />
                </FormControl>
            </div>
            <div style={{ padding: '8px' }}>
                <FormControl sx={udicciClasses.fieldArea} fullWidth>
                    <InputLabel htmlFor="contactCard.facebookUrl">
                        <Typography variant="caption" color="primary">Facebook Url</Typography>
                    </InputLabel>
                    <Input id="contactCard.facebookUrl"
                        type={'text'}
                        value={(contactCard !== null && contactCard.facebookUrl ? contactCard.facebookUrl : '')}
                        onChange={(evt: any) => changeProfile('contactCard.facebookUrl', evt)}
                    />
                </FormControl>
            </div>
        </div>
    );
    return displayElement;
};

export default ConfigureContactCardSettings;