import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/HowToReg';

const DisplayElement = dynamic(() => import('src/components/udicci-draft'));
const AdminElement = dynamic(() => import('src/admin/udicci-draft-admin'));

const PluginId: string = 'udicciDraft';
const PluginTitle: string = 'Rich Text Editor';
const PluginDescription: string = 'Utilizing the Draft.js Rich Text Editor, Udicci provides a simple to use implementation of the RTE environment with some ties to the theming setup for a Portal.';
const PluginVersion: number = 1;

const udicciDraftPlugin: UdicciPlugin = {
    Renderer: (data: any) => ( <DisplayElement {...data} /> ),
    id: PluginId,
    title: PluginTitle,
    description: PluginDescription,
    version: PluginVersion,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <AdminElement {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c udicciDraftPlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c udicciDraftPlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
     },
    category: UdicciPluginCategory.Content
};
export default udicciDraftPlugin;