import React, { Fragment, useEffect, useState } from 'react';

import Pluralize from 'pluralize';

import { last, find, findIndex, forEach } from 'underscore';

import { ThemeProvider, Theme } from '@mui/material/styles';
// import { alpha  } from '@mui/material/styles';

import {
    Box, Button, IconButton, Icon, Breadcrumbs, Link, List, ListItem, Typography, 
    Table, TableHead, TableBody, TableRow, TableCell, Grid, LinearProgress, CircularProgress,
    Paper, InputBase, Divider
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

import { UdicciRecord, checkConditionAgainstRecord } from 'src/classes/udicci-record';

import { useUdicciContext, getProfileSetting } from 'src/context/udicci-context';
import { useUdicciListContext, subscribe as subscribeToListContext } from 'src/context/udicci-list-context';
import { RecordContextDisplay } from 'src/components/udicci-record-context-display';

import UdicciRecordDisplay from './udicci-record-display';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

const UdicciList: React.FC<any> = (props) => {
    // console.log('%c UdicciList props: %O', 'color: maroon;', props);

    let [refresh, setRefresh] = useState<boolean>(false);
    let [breadcrumbs, setBreadcrumbs] = useState<any>(null);
    let [searchString, setSearchString] = useState<string>('');
    // console.log('%c UdicciList breadcrumbs: %O', 'color: hotpink;', breadcrumbs);
    // console.log('%c UdicciList searchString: %O', 'color: hotpink;', searchString);

    let udicciListContext = useUdicciListContext();
    let curUdicciListState = (udicciListContext && udicciListContext.state ? udicciListContext.state : null);
    // console.log('%c UdicciListHeader curUdicciListState: %O', 'color: brown;', curUdicciListState);

    const contextUpdated = (state: any, payload: any) => {
        // console.log('%c UdicciList mediatorContext state: %O', 'color: hotpink;', state);
        // console.log('%c UdicciList contextUpdated payload: %O', 'color: hotpink;', payload);
        // let updateData: any = (state && state.data ? state.data : null);
        // console.log('%c UdicciList contextUpdated updateData: %O', 'color: hotpink;', updateData);
        setTimeout(() => { setRefresh(!refresh); }, 300);
    };

    const applySearchFilter = (filterValue: string) => {
        // console.log('%c UdicciList applySearchFilter filterValue: %O', 'color: hotpink;', filterValue);
        setSearchString(filterValue);
    };

    let propData: any = (props && props.data ? props.data : null);
    // console.log('%c UdicciListHeader propData: %O', 'color: maroon;', propData);
    let propListSettings: any = (propData && propData.listSettings ? propData.listSettings : null);
    if (!propListSettings && props && props.listSettings) {
        propListSettings = props && props.listSettings;
    }
    // console.log('%c UdicciList propListSettings: %O', 'color: maroon;', propListSettings);

    let componentUID: string = props.uid + '.wrap';
    let lsSolution: any = (curUdicciListState && curUdicciListState.socialSolution ? curUdicciListState.socialSolution : null);
    // console.log('%c UdicciList lsSolution: %O', 'color: maroon;', lsSolution);
    let lsMediator: any = (propListSettings && propListSettings.mediator ? propListSettings.mediator : null);
    // console.log('%c UdicciList lsMediator: %O', 'color: maroon;', lsMediator);

    let subscriptionNotes: any = {
        mediator: {
            id: (lsMediator && lsMediator.id ? lsMediator.id : 0),
            name: (lsMediator && lsMediator.name ? lsMediator.name : '')
        },
        socialSolution: {
            id: (lsSolution && lsSolution.id ? lsSolution.id : 0),
            name: (lsSolution && lsSolution.name ? lsSolution.name : '')
        }
    };
    subscribeToListContext(componentUID, contextUpdated, subscriptionNotes);

    let settings: any = {};
    Object.assign(settings, props);
    settings.source = "udicci-list";
    settings.breadcrumbs = breadcrumbs;
    settings.searchFilter = searchString;
    settings.onApplyFilter = applySearchFilter;
    return (
        <Fragment>
            <UdicciListHeader {...settings} />
            <UdicciListContents {...settings} />
        </Fragment>
    );
}

const UdicciListHeader: React.FC<any> = (props) => {
    // console.log('%c UdicciListHeader props: %O', 'color: maroon;', props);
    let udicciHelpers = useUdicciHelpers();

    let [searchValue, setSearchValue] = useState<string>((props && props.searchFilter ? props.searchFilter : ''));
    let [refreshingData, setRefreshingData] = useState<boolean>(false);
    // console.log('%c UdicciListHeader refreshingData: %O', 'color: maroon;', refreshingData);

    let onApplyFilter: any = (props && props.onApplyFilter ? props.onApplyFilter : null);
    const applySearchFilter = () => {
        // console.log('%c UdicciListHeader applySearchFilter searchValue: %O', 'color: hotpink;', searchValue);
        if (onApplyFilter) onApplyFilter(searchValue);
    };
    const clearSearchFilter = () => {
        // console.log('%c UdicciListHeader clearSearchFilter searchValue: %O', 'color: hotpink;', searchValue);
        setSearchValue('');
        if (onApplyFilter) onApplyFilter('');
    };

    let headerComponentUID: string = props.uid + '.header';

    let udicciListContext = useUdicciListContext();
    // console.log('%c UdicciListHeader udicciListContext: %O', 'color: maroon;', udicciListContext);

    // let propUid: any = (props && props.uid ? props.uid : 'udicci.list.header');
    // console.log('%c UdicciListHeader propUid: %O', 'color: maroon;', propUid);

    let propData: any = (props && props.data ? props.data : null);
    // console.log('%c UdicciListHeader propData: %O', 'color: maroon;', propData);
    let propListSettings: any = (propData && propData.listSettings ? propData.listSettings : null);
    if (!propListSettings && props && props.listSettings) {
        propListSettings = props && props.listSettings;
    }
    // console.log('%c UdicciListHeader propListSettings: %O', 'color: maroon;', propListSettings);

    let breadcrumbs: any = (props && props.breadcrumbs ? props.breadcrumbs : null);
    // console.log('%c UdicciListHeader breadcrumbs: %O', 'color: hotpink;', breadcrumbs);

    let curUdicciListState = (udicciListContext && udicciListContext.state ? udicciListContext.state : null);
    // console.log('%c UdicciListHeader curUdicciListState: %O', 'color: brown;', curUdicciListState);
    let listBreadcrumbs: any = (curUdicciListState && curUdicciListState.breadcrumbs ? curUdicciListState.breadcrumbs : null);
    // console.log('%c UdicciListHeader listBreadcrumbs: %O', 'color: maroon;', listBreadcrumbs);
    if (listBreadcrumbs && listBreadcrumbs.length > 0) {
        breadcrumbs = listBreadcrumbs;
        // console.log('%c UdicciListHeader breadcrumbs: %O', 'color: red;', breadcrumbs);
    }

    let pluginLayout: any = null;
    if (propListSettings && propListSettings.layout) {
        pluginLayout = propListSettings.layout;
    }
    // console.log('%c UdicciListContents pluginFilters: %O', 'color: purple;', pluginFilters);
    // console.log('%c UdicciListContents pluginSort: %O', 'color: purple;', pluginSort);
    // console.log('%c UdicciListContents pluginActions: %O', 'color: purple;', pluginActions);
    // console.log('%c UdicciListContents pluginLayout: %O', 'color: purple;', pluginLayout);

    let layoutHeader: any = (pluginLayout && pluginLayout.header ? pluginLayout.header : null);
    // console.log('%c layoutHeader: %O', 'color: blue;', layoutHeader);
    let showAddButton: boolean = (layoutHeader && layoutHeader.showAddButton !== undefined ? layoutHeader.showAddButton : true);
    let showRefreshButton: boolean = (layoutHeader && layoutHeader.showRefreshButton !== undefined ? layoutHeader.showRefreshButton : true);
    let showSearch: boolean = (layoutHeader && layoutHeader.showSearch !== undefined ? layoutHeader.showSearch : true);

    let refreshTheList: any = (curUdicciListState && curUdicciListState.onRefresh ? curUdicciListState.onRefresh : null);
    // console.log('%c UdicciListHeader refreshTheList: %O', 'color: maroon;', refreshTheList);

    let addNewUdicciRecord: any = (curUdicciListState && curUdicciListState.onAddRecord ? curUdicciListState.onAddRecord : null);
    let loadBreadcrumb: any = (curUdicciListState && curUdicciListState.onLoadBreadcrumb ? curUdicciListState.onLoadBreadcrumb : null);
    let closeAllBreadcrumbs: any = (curUdicciListState && curUdicciListState.onCloseAllBreadcrumbs ? curUdicciListState.onCloseAllBreadcrumbs : null);

    let lsData: any = (curUdicciListState && curUdicciListState.data ? curUdicciListState.data : null);
    // console.log('%c UdicciListHeader lsData: %O', 'color: maroon;', lsData);
    let lsMediator: any = (propListSettings && propListSettings.mediator ? propListSettings.mediator : null);
    // console.log('%c UdicciListHeader lsMediator: %O', 'color: maroon;', lsMediator);
    let mediatorName = (lsMediator && lsMediator.name ? lsMediator.name : '');
    // console.log('%c UdicciListHeader mediatorName: %O', 'color: hotpink;', mediatorName);
    let lastBreadcrumb: any = null;
    if (breadcrumbs && breadcrumbs.length > 0) {
        // console.log('%c UdicciListHeader mediatorName: %O', 'color: hotpink;', mediatorName);
        // let checkLast: any = last(breadcrumbs);
        // // console.log('%c checkLast: %O', 'color: maroon;', checkLast);
        // if (checkLast) {
        //     let compareMediatorName: string = '';
        //     if (checkLast.mediator && checkLast.mediator.name) compareMediatorName = checkLast.mediator.name;
        //     if (!compareMediatorName && checkLast.record && checkLast.record.udicciMediator) compareMediatorName = checkLast.record.udicciMediator;
        //     if (compareMediatorName === mediatorName) lastBreadcrumb = last(breadcrumbs);
        // }
        lastBreadcrumb = last(breadcrumbs);
    }
    // console.log('%c UdicciListHeader lastBreadcrumb: %O', 'color: hotpink;', lastBreadcrumb);

    let lsSolution: any = (curUdicciListState && curUdicciListState.socialSolution ? curUdicciListState.socialSolution : null);
    let bcEngagementAction: any = (lastBreadcrumb && lastBreadcrumb.engagementAction ? lastBreadcrumb.engagementAction : null);
    let bcEngagementActionName: any = (bcEngagementAction && bcEngagementAction.name ? bcEngagementAction.name : '');

    let lsPermissions: any = (lsMediator && lsMediator.permissions ? lsMediator.permissions : null);
    if (!lsPermissions && lsSolution && lsSolution.more && lsSolution.more.permissions) {
        lsPermissions = lsSolution.more.permissions;
        // console.log('%c UdicciListHeader lsPermissions: %O', 'color: blue;', lsPermissions);
    }

    let perms: any = null;
    if (lsPermissions && lsPermissions.overrides && lsPermissions.overrides[mediatorName]) {
        let medOvrdPerms: any = lsPermissions.overrides[mediatorName];
        // console.log('%c UdicciListHeader medOvrdPerms: %O', 'color: maroon;', medOvrdPerms);
        perms = medOvrdPerms;
    } else if (lsPermissions) {
        perms = lsPermissions;
    }
    // console.log('%c UdicciListHeader perms: %O', 'color: maroon;', perms);

    const rightAlignedButtonTheme: Theme = udicciHelpers.getUdicciThemeTemplate('MuiIconButton', null);
    // console.log('%c rightAlignedButtonTheme: %O', 'color: blue; font-weight: bold;', rightAlignedButtonTheme);

    const contextUpdated = (state: any, payload: any) => {
        // console.log('%c UdicciListHeader mediatorContext state: %O', 'color: hotpink;', state);
        // console.log('%c UdicciListHeader contextUpdated payload: %O', 'color: hotpink;', payload);
        // let updateData: any = (state && state.data ? state.data : null);
        // console.log('%c UdicciListHeader contextUpdated updateData: %O', 'color: hotpink;', updateData);
        setTimeout(() => { setRefreshingData(false); }, 250);
    };

    let subscriptionNotes: any = {
        mediator: {
            id: (lsMediator && lsMediator.id ? lsMediator.id : 0),
            name: (lsMediator && lsMediator.name ? lsMediator.name : '')
        },
        socialSolution: {
            id: (lsSolution && lsSolution.id ? lsSolution.id : 0),
            name: (lsSolution && lsSolution.name ? lsSolution.name : '')
        }
    };
    subscribeToListContext(headerComponentUID, contextUpdated, subscriptionNotes);

    const addNewRecord = (evt: any) => {
        // console.log('%c UdicciListHeader addNewRecord propListSettings: %O', 'color: hotpink;', propListSettings);
        // console.log('%c UdicciListHeader addNewRecord lsMediator: %O', 'color: hotpink;', lsMediator);
        let structure: any = udicciHelpers.getMediatorStructure(mediatorName);
        // console.log('%c UdicciListHeader addNewRecord structure: %O', 'color: hotpink;', structure);
        let permissions: any = null;
        // var featureMediatorPermissions: any = getMediatorPermissions({ feature: ftre, mediator: sm.name })
        // console.log('%c featureMediatorPermissions: %O', 'color: red; font-weight: bold;', featureMediatorPermissions);
        // let permissions: any = udicciHelpers.getMediatorStructure(mediatorName);
        // console.log('%c UdicciListHeader permissions: %O', 'color: hotpink;', permissions);
        if (addNewUdicciRecord) addNewUdicciRecord(structure, permissions, lsMediator);
    }

    const refreshList = (evt: any) => {
        // console.log('%c UdicciListHeader refreshList refreshingData: %O', 'color: maroon;', refreshingData);
        setRefreshingData(true);
        if (refreshTheList) refreshTheList(true);
    }

    const onClickBreadcrumbLink = (breadcrumb: any) => {
        // console.log('%c onClickBreadcrumbLink breadcrumb: %O', 'color: hotpink;', breadcrumb);
        if (loadBreadcrumb) loadBreadcrumb(breadcrumb);
    }

    const onClickCloseAllBreadcrumbs = () => {
        if (closeAllBreadcrumbs) closeAllBreadcrumbs();
    }

    const updateSearchValue = (evt: any) => {
        let trgt: any = evt.target;
        let newValue = (trgt && trgt.value ? trgt.value : '');
        // console.log('%c updateSearchValue newValue: %O', 'color: maroon;', newValue);
        setSearchValue(newValue);
    };

    let listHeaderElement = null;
    if (lastBreadcrumb) {
        // console.log('%c UdicciListHeader lastBreadcrumb: %O', 'color: maroon;', lastBreadcrumb);
        let bcMediator: any = (lastBreadcrumb.mediator ? lastBreadcrumb.mediator : null);
        // console.log('%c UdicciListHeader bcMediator: %O', 'color: maroon;', bcMediator);
        let bcMediatorName = (bcMediator && bcMediator.name ? bcMediator.name : '');
        // console.log('%c UdicciListHeader bcMediatorName: %O', 'color: maroon;', bcMediatorName);
        let bcRecord: any = (lastBreadcrumb.record ? lastBreadcrumb.record : null);
        // console.log('%c UdicciListHeader bcRecord: %O', 'color: maroon;', bcRecord);
        let isNewRecord: boolean = (bcRecord && bcRecord.recordId === 0 ? true : false);
        if (!bcMediatorName && bcRecord) bcMediatorName = bcRecord.udicciMediator;
        // let bcRecordTitle = (bcRecord && bcRecord.title ? bcRecord.title : (!isNewRecord ? '-' : 'New'));
        // let bcSocialSolution: any = (lastBreadcrumb.socialSolution ? lastBreadcrumb.socialSolution : null);
        // console.log('%c UdicciListHeader bcSocialSolution: %O', 'color: maroon;', bcSocialSolution);
        // let bcSettings: any = (lastBreadcrumb.settings ? lastBreadcrumb.settings : null);
        // console.log('%c UdicciListHeader bcSettings: %O', 'color: maroon;', bcSettings);

        let bcMediatorNameSingular = (bcMediatorName ? Pluralize.singular(bcMediatorName) : bcMediatorName);
        // console.log('bcMediatorNameSingular: %O', bcMediatorNameSingular);

        let bcMediatorNameElement: any = null;
        if (bcMediatorNameSingular) {
            let bcMediatorNameText = bcMediatorNameSingular;
            if (isNewRecord) {
                bcMediatorNameText = 'Add a New ' + bcMediatorNameText;
            }
            let bcMediatorEngagementElement: any = null;
            if (bcEngagementActionName) {
                bcMediatorEngagementElement = (
                    <Fragment>
                        <Typography variant="caption" component="span" noWrap>&nbsp;-</Typography>
                        <Typography variant="caption" component="span" noWrap>&nbsp;{bcEngagementActionName}</Typography>
                    </Fragment>
                );
            }
            bcMediatorNameElement = (
                <Fragment>
                    <Typography variant="subtitle2" component="span" noWrap>
                        {bcMediatorNameText}
                    </Typography>
                    {bcMediatorEngagementElement}
                    <Typography variant="body2" component="span">.</Typography>
                </Fragment>
            );
        }

        let breadcrumbLinks: any[] = [];

        let clearBreadcrumbsLinkProps: any = {
            key: 'clear.breadcrumbs',
            color: 'primary',
            sx: { fontSize: '0.8em' }
        };

        clearBreadcrumbsLinkProps.sx.cursor = 'pointer';
        clearBreadcrumbsLinkProps.underline = 'hover';
        clearBreadcrumbsLinkProps.onClick = (evt: any) => onClickCloseAllBreadcrumbs();

        breadcrumbLinks.push(
            <Link {...clearBreadcrumbsLinkProps}>
                <Icon fontSize="small" sx={{ padding: '4px', marginRight: '4px' }}>list</Icon>
                <Typography variant="clickableSubTitle1" component="span" noWrap>
                    {mediatorName}
                </Typography>
            </Link>
        );

        forEach(breadcrumbs, (bc: any, idxbc: number) => {
            let breadcrumbLinkProps: any = {
                key: 'breadcrumb.' + idxbc.toString(),
                color: 'secondary',
                sx: { fontSize: '0.8em' }
            };

            if (idxbc < (breadcrumbs.length - 1)) {
                breadcrumbLinkProps.sx.cursor = 'pointer';
                breadcrumbLinkProps.underline = 'hover';
                breadcrumbLinkProps.onClick = (evt: any) => onClickBreadcrumbLink(bc);
            } else {
                breadcrumbLinkProps.disabled = true;
                breadcrumbLinkProps.underline = 'none';
            }

            breadcrumbLinks.push( <Link {...breadcrumbLinkProps}> {bc.record.title} </Link> );
        });

        let bcs: any = ( <Breadcrumbs aria-label="breadcrumb"> {breadcrumbLinks} </Breadcrumbs> );

        listHeaderElement = (
            <Box sx={{ margin: '8px' }}>
                {bcs}
            </Box>
        );
    } else {
        let recordCount = 0;
        let totalRecordCount = 0;
        if (lsMediator && lsData !== null) {
            totalRecordCount = lsData.length;

            let refreshButtonElement: any = null;
            if (refreshTheList && showRefreshButton) {
                if (refreshingData) {
                    refreshButtonElement = (
                        <ThemeProvider theme={rightAlignedButtonTheme}>
                            <IconButton size="small" color="info" disabled={true} onClick={refreshList}>
                                <CircularProgress size={15} />
                            </IconButton>
                        </ThemeProvider>
                    );
                } else {
                    refreshButtonElement = (
                        <ThemeProvider theme={rightAlignedButtonTheme}>
                            <IconButton size="small" color="info" onClick={refreshList} disabled={refreshingData}>
                                <RefreshIcon />
                            </IconButton>
                        </ThemeProvider>
                    );
                }
            }

            let addButtonElement: any = null;
            if (perms && perms.CanAdd && showAddButton) {
                addButtonElement = (
                    <ThemeProvider theme={rightAlignedButtonTheme}>
                        <IconButton size="small" color="secondary" disabled={(refreshingData ? true : false)} onClick={addNewRecord}>
                            <AddIcon />
                        </IconButton>
                    </ThemeProvider>
                );
            }

            let searchElement: any = null;
            if (showSearch) {
                let clearSearchButton: any = null;
                if (searchValue.length > 0) {
                    clearSearchButton = (
                        <Fragment>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton color="primary" sx={{ p: '10px' }} aria-label="clear" onClick={(evt: any) => clearSearchFilter()}>
                                <Icon fontSize="small">clear</Icon>
                            </IconButton>
                        </Fragment>
                    );
                }
                searchElement = (
                    <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 270, float: 'right' }}>
                        <InputBase sx={{ ml: 1, flex: 1 }}
                                   placeholder={"Search " + mediatorName}
                                   inputProps={{ 'aria-label': "Search " + mediatorName }}
                                   value={searchValue}
                                   onChange={updateSearchValue}
                                   onKeyPress={(ev: any) => { if (ev.key === 'Enter') applySearchFilter(); }}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(evt: any) => applySearchFilter()}>
                            <Icon fontSize="small">search</Icon>
                        </IconButton>
                        {clearSearchButton}
                    </Paper>
                );
                // searchElement = (
                //     <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}>
                //         <IconButton sx={{ p: '10px' }} aria-label="menu">
                //             <Icon fontSize="small">menu</Icon>
                //         </IconButton>
                //         <InputBase sx={{ ml: 1, flex: 1 }}
                //                    placeholder={"Search " + mediatorName}
                //                    inputProps={{ 'aria-label': "Search " + mediatorName }}
                //         />
                //         <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                //             <Icon fontSize="small">search</Icon>
                //         </IconButton>
                        // <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        // <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                        //     <Icon fontSize="small">directions</Icon>
                        // </IconButton>
                //     </Paper>
                // );
            }

            let recordCountElement: any = null;
            if (searchValue.length <= 0) {
                recordCountElement = (
                    <Typography variant="body2" component="span" noWrap> ({totalRecordCount}) </Typography>
                );
            }

            listHeaderElement = (
                <Box>
                    {addButtonElement}
                    {refreshButtonElement}
                    <Box sx={{ padding: '2px' }}>
                        <Typography variant="h4" component="span" noWrap>
                            {mediatorName}
                        </Typography>
                        <Typography variant="body2" component="span">
                            .
                        </Typography>
                        {recordCountElement}
                        {searchElement}
                    </Box>
                </Box>
            );
        }
    }

    return listHeaderElement;
}

const UdicciListContents: React.FC<any> = (props) => {
    // console.log('%c UdicciListContents props: %O', 'color: maroon;', props);

    // let udicciHelpers = useUdicciHelpers();

    let udicciListContext = useUdicciListContext();
    // console.log('%c UdicciListContents udicciListContext: %O', 'color: maroon;', udicciListContext);
    let udicciContext = useUdicciContext();
    // console.log('%c UdicciListContents udicciContext: %O', 'color: red;', udicciContext);
    let { data } = udicciContext.state;

    let [recordCountToDisplay, setRecordCountToDisplay] = useState<number>(25);
    let [currentPage, setCurrentPage] = useState<number>(1);
    let [numberOfPages, setNumberOfPages] = useState<number>(1);
    // let [currentDataRecord, setCurrentDataRecord] = useState<number>(0);
    // let [forcedUpdateState, forceUpdate] = useState<boolean>(false);

    let maxRecordsToShowPerRequest: number = 25;
    // console.log('%c maxRecordsToShowPerRequest: %O', 'color: red;', maxRecordsToShowPerRequest);

    // console.log('%c maxRecordsToShowPerRequest: %O', 'color: maroon;', maxRecordsToShowPerRequest);
    // console.log('%c recordCountToDisplay: %O', 'color: maroon;', recordCountToDisplay);
    // console.log('%c currentPage: %O', 'color: maroon;', currentPage);
    // console.log('%c numberOfPages: %O', 'color: maroon;', numberOfPages);
    let totalNumberOfPages: number = Math.ceil(0 / maxRecordsToShowPerRequest);

    useEffect(() => {
        if (numberOfPages !== totalNumberOfPages) {
            setNumberOfPages(totalNumberOfPages);
            setRecordCountToDisplay(pageSize);
        }
    }, [numberOfPages, totalNumberOfPages]);

    let curUdicciListState = (udicciListContext && udicciListContext.state ? udicciListContext.state : null);
    // console.log('%c UdicciListContents curUdicciListState: %O', 'color: maroon;', curUdicciListState);

    // let propUid: any = (props && props.uid ? props.uid : 'udicci.list.contents');
    // console.log('%c UdicciListContents propUid: %O', 'color: maroon;', propUid);
    let propSource: any = (props && props.source ? props.source : 'udicci-list');
    // console.log('%c UdicciListContents propSource: %O', 'color: maroon;', propSource);
    let searchFilter: any = (props && props.searchFilter ? props.searchFilter : '');
    // console.log('%c UdicciListContents searchFilter: %O', 'color: maroon;', searchFilter);
    let propData: any = (props && props.data ? props.data : null);
    // console.log('%c UdicciListContents propData: %O', 'color: maroon;', propData);
    let propListSettings: any = (propData && propData.listSettings ? propData.listSettings : null);
    if (!propListSettings && props && props.listSettings) {
        propListSettings = props && props.listSettings;
    }
    // console.log('%c UdicciListContents propListSettings: %O', 'color: maroon;', propListSettings);

    let defaultTopXRecords: number = (props && props.topXRecords ? props.topXRecords : 0);
    let defaultPageSize: number = (props && props.pageSize ? props.pageSize : 10);
    let breadcrumbs: any = (props && props.breadcrumbs ? props.breadcrumbs : null);
    // console.log('%c UdicciListContents breadcrumbs: %O', 'color: maroon;', breadcrumbs);
    let listBreadcrumbs: any = (curUdicciListState && curUdicciListState.breadcrumbs ? curUdicciListState.breadcrumbs : null);
    // console.log('%c UdicciListHeader listBreadcrumbs: %O', 'color: maroon;', listBreadcrumbs);
    if (listBreadcrumbs && listBreadcrumbs.length > 0) {
        breadcrumbs = listBreadcrumbs;
        // console.log('%c UdicciListHeader breadcrumbs: %O', 'color: red;', breadcrumbs);
    }
    // console.log('%c UdicciListContents breadcrumbs: %O', 'color: maroon;', breadcrumbs);

    let lsLastFetchResultDate: any = (curUdicciListState && curUdicciListState.lastFetchResultDate ? curUdicciListState.lastFetchResultDate : null);
    // console.log('%c UdicciListContents lsLastFetchResultDate: %O', 'color: maroon;', lsLastFetchResultDate);
    let lsData: any = (curUdicciListState && curUdicciListState.data ? curUdicciListState.data : null);
    // console.log('%c UdicciListContents (%s) lsData: %O', 'color: maroon;', propUid, lsData);

    let lsSolution: any = (curUdicciListState && curUdicciListState.socialSolution ? curUdicciListState.socialSolution : null);
    // console.log('%c UdicciListContents lsSolution: %O', 'color: maroon;', lsSolution);
    let lsFeature: any = (curUdicciListState && curUdicciListState.feature ? curUdicciListState.feature : null);
    // console.log('%c UdicciListContents lsFeature: %O', 'color: maroon;', lsFeature);
    let lsMediator: any = (propListSettings && propListSettings.mediator ? propListSettings.mediator : null);
    // console.log('%c UdicciListContents lsMediator: %O', 'color: maroon;', lsMediator);
    // let lsSettings: any = (curUdicciListState && curUdicciListState.settings ? curUdicciListState.settings : null);
    // console.log('%c UdicciListContents (%s) lsSettings: %O', 'color: maroon;', propUid, lsSettings);
    // let lsStructure: any = (lsMediator && lsMediator.structure ? lsMediator.structure : null);
    // console.log('%c UdicciListContents lsStructure: %O', 'color: maroon;', lsStructure);
    let lspaging: any = (propListSettings && propListSettings.paging ? propListSettings.paging : null);
    // console.log('%c UdicciListContents lspaging: %O', 'color: maroon;', lspaging);

    let closeBreadcrumb: any = (curUdicciListState && curUdicciListState.onCloseBreadcrumb ? curUdicciListState.onCloseBreadcrumb : null);
    // console.log('%c UdicciListHeader closeBreadcrumb: %O', 'color: maroon;', closeBreadcrumb);

    let editUdicciRecord: any = (curUdicciListState && curUdicciListState.onEditRecord ? curUdicciListState.onEditRecord : null);
    // console.log('%c UdicciListHeader editUdicciRecord: %O', 'color: maroon;', editUdicciRecord);

    let lsSolutionId: number = (lsSolution && lsSolution.id ? lsSolution.id : 0);
    // console.log('%c UdicciListContents lsSolutionId: %O', 'color: maroon;', lsSolutionId);
    let mediatorName = (lsMediator && lsMediator.name ? lsMediator.name : '');
    // console.log('%c UdicciListContents (%s) mediatorName: %O', 'color: maroon;', propUid, mediatorName);

    let lastBreadcrumb: any = null;
    if (breadcrumbs && breadcrumbs.length > 0) {
        // console.log('%c UdicciListContents (%s) mediatorName: %O', 'color: green;', propUid, mediatorName);
        // let checkLast: any = last(breadcrumbs);
        // console.log('%c checkLast: %O', 'color: green;', checkLast);
        // if (checkLast && checkLast.mediator && checkLast.mediator.name === mediatorName) {
        //     lastBreadcrumb = last(breadcrumbs);
        // } else if (checkLast && checkLast.record && checkLast.record.udicciMediator === mediatorName) {
        //     lastBreadcrumb = last(breadcrumbs);
        // }
        lastBreadcrumb = last(breadcrumbs);
    }
    // console.log('%c UdicciListContents lastBreadcrumb: %O', 'color: green;', lastBreadcrumb);
    let lastBreadcrumbMediator: any = (lastBreadcrumb && lastBreadcrumb.mediator ? lastBreadcrumb.mediator : null);
    // console.log('%c UdicciListContents lastBreadcrumbMediator: %O', 'color: maroon;', lastBreadcrumbMediator);
    let lastBreadcrumbRecord: any = (lastBreadcrumb && lastBreadcrumb.record ? lastBreadcrumb.record : null);
    // console.log('%c UdicciListContents lastBreadcrumbRecord: %O', 'color: maroon;', lastBreadcrumbRecord);
    let lastBreadcrumbMediatorName: string = '';
    if (lastBreadcrumbMediator) {
        lastBreadcrumbMediatorName = lastBreadcrumbMediator.name;
    } else if (lastBreadcrumbRecord) {
        lastBreadcrumbMediatorName = lastBreadcrumbRecord.udicciMediator;
    }
    // console.log('%c UdicciListContents lastBreadcrumbMediatorName: %O', 'color: maroon;', lastBreadcrumbMediatorName);

    let mediatorContext: any = null;
    if (mediatorName) mediatorContext = data.find((x: any) => x.mediator === mediatorName );
    // console.log('%c UdicciListContents (%s) mediatorContext: %O', 'color: maroon;', propUid, mediatorContext);

    let listType: any = getProfileSetting(mediatorName, lsSolutionId, 'listType');
    // console.log('%c UdicciListContents listType: %O', 'color: red;', listType);

    // let listConfig: any = getProfileSetting(mediatorName, lsSolutionId, 'listConfig');
    // console.log('%c UdicciListContents listConfig: %O', 'color: purple;', listConfig);
    let pagingStyle: any = getProfileSetting(mediatorName, lsSolutionId, 'pagingStyle');
    // console.log('%c UdicciListContents pagingStyle: %O', 'color: red;', pagingStyle);
    let pageSize: any = getProfileSetting(mediatorName, lsSolutionId, 'pageSize');
    // console.log('%c UdicciListContents pageSize: %O', 'color: purple;', pageSize);
    if (lspaging && lspaging.pageSize && ((pageSize === undefined || pageSize === null) || (pageSize !== lspaging.pageSize))) pageSize = lspaging.pageSize;
    if (pageSize === undefined || pageSize === null) pageSize = defaultPageSize;
    // console.log('%c UdicciListContents pageSize: %O', 'color: purple;', pageSize);
    let topXRecords: any = getProfileSetting(mediatorName, lsSolutionId, 'topXRecords');
    // console.log('%c UdicciListContents topXRecords: %O', 'color: purple;', topXRecords);
    if ((topXRecords === undefined || topXRecords === null) && (lspaging && lspaging.topXRecords && topXRecords !== lspaging.topXRecords)) topXRecords = lspaging.topXRecords;
    if (topXRecords === undefined || topXRecords === null) topXRecords = defaultTopXRecords;
    // console.log('%c UdicciListContents topXRecords: %O', 'color: purple;', topXRecords);
    let nextPageLabel: any = getProfileSetting(mediatorName, lsSolutionId, 'nextPageLabel');
    // console.log('%c UdicciListContents nextPageLabel: %O', 'color: purple;', nextPageLabel);
    let prevPageLabel: any = getProfileSetting(mediatorName, lsSolutionId, 'prevPageLabel');
    // console.log('%c UdicciListContents prevPageLabel: %O', 'color: purple;', prevPageLabel);

    let ssMore = (lsSolution && lsSolution.more ? lsSolution.more : null);
    // console.log('%c UdicciListContents ssMore: %O', 'color: green;', ssMore);
    let mediators = (ssMore && ssMore.mediators ? ssMore.mediators : null);
    // console.log('%c UdicciListContents mediators: %O', 'color: green;', mediators);
    if (mediators && !pagingStyle) {
        let medObj: any = find(mediators, function(m) {
            return m.name === mediatorName;
        });
        // console.log('%c medObj: %O', 'color: blue;', medObj);
        let mediatorFeatures = (medObj && medObj.features ? medObj.features : null);
        // console.log('%c UdicciListContents mediatorFeatures: %O', 'color: green;', mediatorFeatures);
        if (mediatorFeatures) {
            let ftreObj: any = find(mediatorFeatures, function(f: any) {
                return f.id === lsFeature.id;
            });
            // console.log('%c ftreObj: %O', 'color: blue;', ftreObj);
            if (ftreObj && ftreObj.list) {
                if (ftreObj.list.topXRecords) topXRecords = ftreObj.list.topXRecords;
                if (ftreObj.list.pagingStyle) pagingStyle = ftreObj.list.pagingStyle;
                if (ftreObj.list.pageSize) pageSize = ftreObj.list.pageSize;
                if (ftreObj.list.nextPageLabel) nextPageLabel = ftreObj.list.nextPageLabel;
                if (ftreObj.list.prevPageLabel) prevPageLabel = ftreObj.list.prevPageLabel;
                // console.log('%c UdicciListContents topXRecords: %O', 'color: purple;', topXRecords);
                // console.log('%c UdicciListContents pagingStyle: %O', 'color: purple;', pagingStyle);
                // console.log('%c UdicciListContents pageSize: %O', 'color: purple;', pageSize);
                // console.log('%c UdicciListContents nextPageLabel: %O', 'color: purple;', nextPageLabel);
                // console.log('%c UdicciListContents prevPageLabel: %O', 'color: purple;', prevPageLabel);
            }
        }
    }

    const showMoreRecords = (evt: any) => {
        setRecordCountToDisplay(recordCountToDisplay + maxRecordsToShowPerRequest);
    };

    const scrollToTopOfContent = () => {
        var contentAreaElements: HTMLCollection = document.getElementsByClassName('portalLayout');
        // console.log('%c contentAreaElements: %O', 'color: red;', contentAreaElements);
        if (contentAreaElements.length > 0) {
            contentAreaElements[0].scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
              });
        } else {
            window.scrollTo(0, 0);
        }
    };

    const showNextRecords = (evt: any) => {
        if (currentPage < numberOfPages) setCurrentPage(currentPage + 1);
        scrollToTopOfContent();
    };

    const showPreviousRecords = (evt: any) => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
        scrollToTopOfContent();
    };

    let listFilterSettings: any = getProfileSetting(mediatorName, lsSolutionId, 'listFilterSettings');
    // console.log('%c UdicciListContents listFilterSettings: %O', 'color: purple;', listFilterSettings);

    let pluginFilters: any = null;
    let pluginSort: any = null;
    // let pluginActions: any = null;
    let pluginLayout: any = null;
    if (propListSettings) {
        if (propListSettings.filter && propListSettings.filter.length > 0) {
            pluginFilters = propListSettings.filter;
        }
        if (propListSettings.sort) {
            pluginSort = propListSettings.sort;
        }
        // if (propListSettings.actions) {
        //     pluginActions = propListSettings.actions;
        // }
        if (propListSettings.layout) {
            pluginLayout = propListSettings.layout;
        }
    }
    // console.log('%c UdicciListContents pluginFilters: %O', 'color: purple;', pluginFilters);
    // console.log('%c UdicciListContents pluginSort: %O', 'color: purple;', pluginSort);
    // console.log('%c UdicciListContents pluginActions: %O', 'color: purple;', pluginActions);
    // console.log('%c UdicciListContents pluginLayout: %O', 'color: purple;', pluginLayout);

    let layoutConfig: any = (pluginLayout && pluginLayout.config ? pluginLayout.config : null);
    // console.log('%c UdicciListContents layoutConfig: %O', 'color: purple;', layoutConfig);

    if (layoutConfig) {
        if (pluginLayout.type === 'grid') listType = 'Card Grid';
        if (pluginLayout.type === 'list') listType = 'List';
        if (pluginLayout.type === 'table') listType = 'Table';
        // console.log('%c UdicciListContents listType: %O', 'color: red;', listType);
    }

    let searchResultsCount: number = 0;
    let sortedData: UdicciRecord[] = [];
    if (lsData) {
        var listData: UdicciRecord[] = [];

        // filter the data
        if (pluginFilters && pluginFilters.length > 0) {
            // console.log('%c UdicciListContents pluginFilters: %O', 'color: purple;', pluginFilters);
            lsData.forEach((record: any) => {
                // console.log('%c record: %O', 'color: green;', record);
                let meetsConditions: boolean = checkConditionAgainstRecord(record, pluginFilters, mediatorContext);
                // console.log('%c meetsConditions: %O', 'color: green;', meetsConditions);

                if (meetsConditions && searchFilter.length > 0) {
                    let matchesSearchCriteria: boolean = false;
                    if (record.title.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) matchesSearchCriteria = true;
                    if (record.description.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) matchesSearchCriteria = true;
                    // let recD: any = record.data;
                    // if (recD.firstName.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) matchesSearchCriteria = true;
                    // if (recD.email.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) matchesSearchCriteria = true;
                    // if (recD.phone.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) matchesSearchCriteria = true;
                    // console.log('%c matchesSearchCriteria: %O', 'color: blue;', matchesSearchCriteria);

                    if (!matchesSearchCriteria) meetsConditions = false;
                }
    
                if (meetsConditions) listData.push(record);
            });
            if (searchFilter.length > 0) searchResultsCount = listData.length;
        } else if (searchFilter.length > 0) {
            lsData.forEach((record: any) => {
                // console.log('%c record: %O', 'color: green;', record);
                let matchesSearchCriteria: boolean = false;
                if (record.title.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) matchesSearchCriteria = true;
                if (record.description.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) matchesSearchCriteria = true;
                // let recD: any = record.data;
                // if (recD.firstName.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) matchesSearchCriteria = true;
                // if (recD.email.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) matchesSearchCriteria = true;
                // if (recD.phone.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) matchesSearchCriteria = true;
                // console.log('%c matchesSearchCriteria: %O', 'color: blue;', matchesSearchCriteria);

                if (matchesSearchCriteria) listData.push(record);
            });
            searchResultsCount = listData.length;
        } else {
            listData = lsData;
        }

        // sort the data
        if (pluginSort && pluginSort.field) {
            let sf: string = pluginSort.field;
            sortedData = listData.sort((a: any, b: any) => {
                if (a.data[sf] > b.data[sf]) { return 1; };
                if (a.data[sf] < b.data[sf]) { return -1; };

                // if you want to do multiple sort settings, add in second setting here
                // i.e.
                // if (a[sf2] > b[sf2]) { return 1; };
                // if (a[sf2] <>> b[sf2]) { return -1; };

                return 0;
            });
            if (pluginSort.order === 'desc') sortedData.reverse();
        } else {
            sortedData = listData;
        }
    }
    // console.log('%c UdicciListContents sortedData: %O', 'color: maroon;', sortedData);

    let totalRecordCount = (sortedData ? sortedData.length : 0);
    // console.log('%c totalRecordCount: %O', 'color: blue;', totalRecordCount);

    const changeSelectedRecord = (direction: string, specifiedIndex: number | undefined = -1) => {
        // console.log('%c changeSelectedRecord direction: %O', 'color: red;', direction);
        // console.log('%c changeSelectedRecord lastBreadcrumb: %O', 'color: red;', lastBreadcrumb);
        let bcRecord: any = (lastBreadcrumb && lastBreadcrumb.record ? lastBreadcrumb.record : null);
        // console.log('%c changeSelectedRecord bcRecord: %O', 'color: red;', bcRecord);

        if (bcRecord) {
            // console.log('%c changeSelectedRecord sortedData: %O', 'color: red;', sortedData);
            let bcRecordIndex: number = findIndex(sortedData, function(rec: UdicciRecord) {
                return rec.recordId === bcRecord.recordId;
            });
            // console.log('%c changeSelectedRecord bcRecordIndex: %O', 'color: red;', bcRecordIndex);

            let newSelectedRecord: any = null;
            let sortedDataCount: number = sortedData.length;
            if (specifiedIndex >= 0 && specifiedIndex < sortedDataCount) {
                newSelectedRecord = sortedData[specifiedIndex];
            } else {
                if (direction === 'next') {
                    if (bcRecordIndex < sortedDataCount) {
                        newSelectedRecord = sortedData[bcRecordIndex + 1];
                    }
                }
                if (direction === 'previous') {
                    if (bcRecordIndex > 0) {
                        newSelectedRecord = sortedData[bcRecordIndex - 1];
                    }
                }
            }
            // console.log('%c changeSelectedRecord newSelectedRecord: %O', 'color: blue;', newSelectedRecord);

            setTimeout(() => {
                editUdicciRecord(newSelectedRecord);
            }, 100);
            closeBreadcrumb();
        }
    };

    const getRecordPosition = () => {
        // console.log('%c getRecordPosition lastBreadcrumb: %O', 'color: red;', lastBreadcrumb);
        let recordPosition: string = '';
        let bcRecord: any = (lastBreadcrumb && lastBreadcrumb.record ? lastBreadcrumb.record : null);
        // console.log('%c getRecordPosition bcRecord: %O', 'color: red;', bcRecord);
        if (bcRecord) {
            // console.log('%c changeSelectedRecord sortedData: %O', 'color: red;', sortedData);
            let bcRecordIndex: number = findIndex(sortedData, function(rec: UdicciRecord) {
                return rec.recordId === bcRecord.recordId;
            });
            // console.log('%c getRecordPosition bcRecordIndex: %O', 'color: red;', bcRecordIndex);

            if (bcRecordIndex === 0) {
                recordPosition = 'first';
            } else if (bcRecordIndex > 0 && (bcRecordIndex === (sortedData.length - 1))) {
                recordPosition = 'last';
            } else {
                recordPosition = bcRecordIndex.toString();
            }
        }

        // console.log('%c getRecordPosition recordPosition: %O', 'color: blue;', recordPosition);
        return recordPosition;
    };

    let sortedDataCount: number = sortedData.length;
    let listContainerElement: any = null;
    let listFooterElement: any = null;
    // if (lastBreadcrumb && lastBreadcrumbMediatorName === mediatorName) {
    if (lastBreadcrumb) {
        // console.log('%c UdicciListContents lastBreadcrumb: %O', 'color: maroon;', lastBreadcrumb);
        let bcRecord: any = (lastBreadcrumb.record ? lastBreadcrumb.record : null);
        // console.log('%c UdicciListContents bcRecord: %O', 'color: maroon;', bcRecord);
        // console.log('%c UdicciListContents mediatorName: %O', 'color: maroon;', mediatorName);
        let bcSettings: any = (lastBreadcrumb.settings ? lastBreadcrumb.settings : null);
        // console.log('%c UdicciListContents bcSettings: %O', 'color: maroon;', bcSettings);
        let bcShowForm: boolean = (bcSettings && bcSettings.showForm ? true : false);
        // console.log('%c UdicciListContents bcShowForm: %O', 'color: maroon;', bcShowForm);
        let bcRecordMediator: string = (bcRecord && bcRecord.udicciMediator ? bcRecord.udicciMediator : '');
        // console.log('%c UdicciListContents bcRecordMediator: %O', 'color: maroon;', bcRecordMediator);
        let bcRecordId: number = (bcRecord && bcRecord.recordId ? bcRecord.recordId : 0);
        // console.log('%c UdicciListContents bcRecordId: %O', 'color: maroon;', bcRecordId);

        let recDisplayKey: string = 'udicci.record.display.' + bcRecordMediator + '.' + bcRecordId.toString();
        listContainerElement = (
            <UdicciRecordDisplay showForm={bcShowForm} showInline={true}
                                 record={bcRecord}
                                 key={recDisplayKey}
                                 totalRecordCount={sortedDataCount}
                                 source={propSource}
                                 onCloseForm={() => closeBreadcrumb(lastBreadcrumb)}
                                 onGetRecordPosition={getRecordPosition}
                                 onNextRecord={(specifiedIndex: number | undefined = -1) => changeSelectedRecord('next', specifiedIndex)}
                                 onPreviousRecord={(specifiedIndex: number | undefined = -1) => changeSelectedRecord('previous', specifiedIndex)}
                                 onEditRecord={editUdicciRecord}
                                 listSettings={propListSettings}
            />
        );
    } else if (!lastBreadcrumb) {
        let tableProps: any = { size: 'small' };
        let tableHeaderColumns: any[] = [];

        let dataIsLoading: boolean = false;
        let tableLoadingRow: any = null;
        let messageDisplayElement: any = null;
        let includedRecordCount = 0;
        let childrenDisplayElements: any[] = [];
        // console.log('%c UdicciListContents totalRecordCount: %O', 'color: blue;', totalRecordCount);
        // console.log('%c UdicciListContents pageSize: %O', 'color: blue;', pageSize);
        if (totalRecordCount > 0) {
            // console.log('%c UdicciListContents sortedData: %O', 'color: maroon;', sortedData);

            let recordsCheckedCount: number = 0;
            let startRecordCount: number = (pageSize * (currentPage - 1)) + 1;
            // console.log('%c UdicciListContents startRecordCount: %O', 'color: red;', startRecordCount);
            let endRecordCount: number = (pageSize * currentPage);
            // console.log('%c UdicciListContents endRecordCount: %O', 'color: red;', endRecordCount);
            sortedData.forEach(function(record: UdicciRecord, recIndex: number) {
                recordsCheckedCount++;
                // console.log('%c UdicciListContents recordsCheckedCount: %O', 'color: red;', recordsCheckedCount);

                if (pagingStyle === 'Continuous') {
                    // console.log('%c UdicciListContents recordsCheckedCount: %O', 'color: red;', recordsCheckedCount);
                    if (recordCountToDisplay <= includedRecordCount) {
                        return false;
                    }
                }

                // if (pagingStyle === 'Paged' && recordsCheckedCount < startRecordCount) {
                //     return false;
                // }
                // if (pagingStyle === 'Paged' && recordsCheckedCount > endRecordCount) {
                //     return false;
                // }
                if (recordsCheckedCount < startRecordCount) {
                    return false;
                }
                if (recordsCheckedCount > endRecordCount) {
                    return false;
                }

                if (pagingStyle === 'Individual' && includedRecordCount > 1) {
                    return false;
                }

                if (includedRecordCount >= pageSize) return false;

                // console.log('%c record: %O', 'color: hotpink;', record);
                let includeRecord = false;
                // console.log('%c listFilterSettings: %O', 'color: hotpink;', listFilterSettings);
                if (listFilterSettings && listFilterSettings.length > 0) {
                    let includedWithFilter = true;
                    let currentConditionGroup = '';
                    listFilterSettings.forEach(function(filterSetting: any) {
                        // console.log('%c filterSetting: %O', 'color: hotpink;', filterSetting);
                        let filterJsonKey = filterSetting.jsonKey;
                        // console.log('filterJsonKey: %O', filterJsonKey);

                        // let dataType = (filterSetting.dataType ? filterSetting.dataType : 'String');
                        let conditionToCheck = (filterSetting.conditionToCheck ? filterSetting.conditionToCheck : 'is.equal.to');
                        let conditionBinder = (filterSetting.conditionBinder ? filterSetting.conditionBinder : 'And');
                        let valueToCompare = filterSetting.valueToCompare;

                        let conditionGroup = (filterSetting.conditionGroup ? filterSetting.conditionGroup : '');
                        // console.log('conditionGroup: %O', conditionGroup);
                        // console.log('currentConditionGroup: %O', currentConditionGroup);
                        if (conditionGroup && conditionGroup !== currentConditionGroup) {
                            currentConditionGroup = conditionGroup;
                            includedWithFilter = true;
                        } else if (!conditionGroup) {
                            includedWithFilter = true;
                        }
                        // console.log('currentConditionGroup: %O', currentConditionGroup);

                        let recordValue = record.data[filterJsonKey];
                        // console.log('recordValue: %O', recordValue);

                        let conditionIsTrue = false;
                        switch (conditionToCheck) {
                            case 'is.equal.to':
                                if (recordValue === valueToCompare) conditionIsTrue = true;
                                break;
                            case 'not.equal.to':
                                if (recordValue !== valueToCompare) conditionIsTrue = true;
                                break;
                            case 'is.empty':
                                if (recordValue && recordValue.toString().length <= 0) conditionIsTrue = true;
                                break;
                            case 'not.empty':
                                if (recordValue && recordValue.toString().length > 0) conditionIsTrue = true;
                                break;
                            case 'is.null':
                                if (recordValue === null) conditionIsTrue = true;
                                break;
                            case 'not.null':
                                if (recordValue !== null) conditionIsTrue = true;
                                break;
                            case 'contains':
                                // console.log('recordValue: %O', recordValue);
                                // console.log('valueToCompare: %O', valueToCompare);
                                if (recordValue) {
                                    let rslt = recordValue.toString().indexOf(valueToCompare);
                                    // console.log('%c filterSettings: %O', 'color: red;', filterSettings);
                                    if (rslt >= 0) {
                                        // console.log('rslt: %O', rslt);
                                        conditionIsTrue = true;
                                        // console.log('conditionIsTrue: %O', conditionIsTrue);
                                    }
                                }
                                // console.log('conditionIsTrue: %O', conditionIsTrue);
                                break;
                            case 'does.not.contain':
                                if (recordValue && recordValue.toString().indexOf(valueToCompare) < 0) conditionIsTrue = true;
                                break;
                            case 'starts.with':
                                if (recordValue && recordValue.toString().indexOf(valueToCompare) === 0) conditionIsTrue = true;
                                break;
                            case 'does.not.start.with':
                                if (recordValue && recordValue.toString().indexOf(valueToCompare) !== 0) conditionIsTrue = true;
                                break;
                            case 'ends.with':
                                if (recordValue && recordValue.toString().endsWith(valueToCompare)) conditionIsTrue = true;
                                break;
                            case 'does.not.end.with':
                                if (recordValue && !recordValue.toString().endsWith(valueToCompare)) conditionIsTrue = true;
                                break;
                            case 'is.greater.than':
                                break;
                            case 'is.greater.than.or.equal.to':
                                break;
                            case 'is.less.than':
                                break;
                            case 'is.less.than.or.equal.to':
                                break;
                            case 'between':
                                break;
                        }

                        if (conditionBinder === 'Or') {
                            if (conditionIsTrue === true || includedWithFilter === true) {
                                includedWithFilter = true;
                            } else {
                                includedWithFilter = false;
                            }
                        } else { // And
                            if (conditionIsTrue !== true) {
                                includedWithFilter = false;
                            }
                        }
                    });
                    // console.log('includedWithFilter: %O', includedWithFilter);
                    includeRecord = includedWithFilter;
                } else {
                    includeRecord = true;
                }
                // console.log('includeRecord: %O', includeRecord);

                if (includeRecord) {
                    let recId = (record && record.recordId ? record.recordId : 0);
                    let recContextKey = 'udicci.record.context.' + recId.toString();
                    // let recKey = 'udicci.record.display.record.' + recId.toString();
                    childrenDisplayElements.push(
                        <UdicciRecordDisplay record={record} totalRecordCount={sortedDataCount} key={recContextKey} listSettings={propListSettings} />
                    );
                    includedRecordCount++;
                }
            });
        } else {
            // console.log('%c UdicciListContents lsLastFetchResultDate: %O', 'color: blue;', lsLastFetchResultDate);
            // console.log('%c UdicciListContents mediatorName: %O', 'color: blue;', mediatorName);
            // console.log('%c UdicciListContents lsData: %O', 'color: blue;', lsData);
            // console.log('%c UdicciListContents sortedData: %O', 'color: blue;', sortedData);
            let itemKey: string = '';
            if (lsLastFetchResultDate && mediatorName && lsData !== null) {
                itemKey = 'udicci.list.no.data';
                messageDisplayElement = (
                    <Box><Typography variant="caption" noWrap>No {mediatorName} to show.</Typography></Box>
                );
            } else {
                if (!lsData) {
                    itemKey = 'udicci.list.data.loading';
                    messageDisplayElement = (<Box><LinearProgress color="primary" /></Box>);
                    dataIsLoading = true;
                }
            }
            if (itemKey !== '') {
                // console.log('%c UdicciListContents listType: %O', 'color: blue;', listType);
                if (listType === 'Card Grid') {
                    childrenDisplayElements.push(<Grid item xs={12} md={9} key={itemKey}> {messageDisplayElement} </Grid>);
                    // console.log('%c UdicciListContents childrenDisplayElements.length: %O', 'color: blue;', childrenDisplayElements);
                } else if (listType === 'Table') {
                    // tableLoadingRow = (<TableRow key={itemKey}><TableCell colSpan={tableHeaderColumns.length}>{messageDisplayElement}</TableCell></TableRow>);
                } else {
                    childrenDisplayElements.push(<ListItem alignItems="flex-start" key={itemKey}>{messageDisplayElement}</ListItem>);
                    // console.log('%c UdicciListContents childrenDisplayElements.length: %O', 'color: blue;', childrenDisplayElements);
                }
            }
        }

        let noRecordsToShowElement: any = null;
        // console.log('%c UdicciListContents childrenDisplayElements.length: %O', 'color: blue;', childrenDisplayElements.length);
        if ((!childrenDisplayElements || (childrenDisplayElements && childrenDisplayElements.length <= 0)) && !dataIsLoading) {
            noRecordsToShowElement = (
                <Box>
                    <Typography variant="caption" noWrap>
                        No {mediatorName} to show.
                    </Typography>
                </Box>
            );
        }

        totalNumberOfPages = Math.ceil(totalRecordCount / pageSize);
        // console.log('%c UdicciListContents totalNumberOfPages: %O', 'color: blue;', totalNumberOfPages);

        let resultsCountElement: any = null;
        if (searchResultsCount > 0) {
            resultsCountElement = (
                <Fragment>
                    <Typography variant="caption" component="span" sx={{ marginLeft: (totalNumberOfPages > 1 ? '16px' : '4px') }}>Search includes </Typography>
                    <Typography variant="subtitle1" component="span"> {searchResultsCount} </Typography>
                    <Typography variant="caption" component="span" sx={{ marginLeft: '4px' }}>results.</Typography>
                </Fragment>
            );
        }

        if (totalNumberOfPages > 1) {
            // console.log('%c UdicciListContents totalRecordCount: %O', 'color: blue;', totalRecordCount);
            // console.log('%c UdicciListContents recordCountToDisplay: %O', 'color: blue;', recordCountToDisplay);
            // console.log('%c UdicciListContents pagingStyle: %O', 'color: blue;', pagingStyle);

            let fibProps: any = {
                sx: { margin: '4px' },
                size: 'small',
                color: 'info',
            };
            let listFooterIcons: any = (
                <Box sx={{ float: 'right', display: 'flex' }}>
                    <IconButton disabled={(currentPage > 1 ? false : true)} onClick={(evt: any) => setCurrentPage(1)} {...fibProps}>
                        <Icon>first_page</Icon>
                    </IconButton>
                    <IconButton disabled={(currentPage > 1 ? false : true)} onClick={(evt: any) => setCurrentPage(currentPage - 1)} {...fibProps}>
                        <Icon>chevron_left</Icon>
                    </IconButton>
                    <IconButton disabled={(currentPage >= totalNumberOfPages ? true : false)} onClick={(evt: any) => setCurrentPage(currentPage + 1)} {...fibProps}>
                        <Icon>chevron_right</Icon>
                    </IconButton>
                    <IconButton disabled={(currentPage >= totalNumberOfPages ? true : false)} onClick={(evt: any) => setCurrentPage(totalNumberOfPages)} {...fibProps}>
                        <Icon>last_page</Icon>
                    </IconButton>
                </Box>
            );

            listFooterElement = (
                <Box sx={{ padding: '4px' }}>
                    {listFooterIcons}
                    <Box sx={{ padding: '4px' }}>
                        <Typography variant="caption" component="span">Showing </Typography>
                        <Typography variant="subtitle1" component="span"> {currentPage} </Typography>
                        <Typography variant="caption" component="span"> of </Typography>
                        <Typography variant="subtitle1" component="span"> {totalNumberOfPages} </Typography>
                        <Typography variant="caption" component="span"> page{totalNumberOfPages ? 's' : ''}. </Typography>
                        {resultsCountElement}
                    </Box>
                </Box>
            );
        } else if (searchResultsCount > 0) {
            listFooterElement = (
                <Box sx={{ padding: '4px' }}>
                    <Box sx={{ padding: '4px' }}>
                        {resultsCountElement}
                    </Box>
                </Box>
            );
        }

        // console.log('%c UdicciListContents pagingStyle: %O', 'color: green; font-weight: bold;', pagingStyle);
        // console.log('%c UdicciListContents listType: %O', 'color: green; font-weight: bold;', listType);
        // console.log('%c UdicciListContents noRecordsToShowElement: %O', 'color: green; font-weight: bold;', noRecordsToShowElement);
        if (noRecordsToShowElement) {
            listContainerElement = (<Box>{noRecordsToShowElement}</Box>);
        } else if (pagingStyle === 'Individual') {
            // console.log('%c UdicciListContents lsData: %O', 'color: green; font-weight: bold;', lsData);
            let firstRec: any = (sortedData && sortedData.length > 0 ? sortedData[0] : null);
            // console.log('%c UdicciListContents firstRec: %O', 'color: green; font-weight: bold;', firstRec);
            let recordContextElement: any = null;
            if (firstRec) {
                let showAsContext: boolean = false;
                if (firstRec.recordId > 0) {
                    if (showAsContext) {
                        recordContextElement = (<RecordContextDisplay showForm={false} record={firstRec} recordSet={sortedData} />);
                    } else {
                        recordContextElement = (
                            <Box>
                                <UdicciRecordDisplay showForm={false} showInline={true}
                                                     record={firstRec}
                                                     totalRecordCount={sortedDataCount}
                                                     source={propSource}
                                                     listSettings={propListSettings}
                                />
                            </Box>
                        );                   
                    }
                }

                if (!recordContextElement) {
                    recordContextElement = (<Box><LinearProgress color="primary" /></Box>);
                }
    
                listContainerElement = ( <Box> {recordContextElement} </Box> );    
            } else if (lsData) {
                listContainerElement = (
                    <Box>
                        <Typography variant="caption" noWrap>
                            No {mediatorName} to show.
                        </Typography>
                    </Box>
                );
            } else {
                listContainerElement = (
                    <Box>
                        <LinearProgress color="primary" />
                    </Box>
                );
            }
        } else {
            let recordListFooterContent: any = null;
            if (totalRecordCount > 0 && recordCountToDisplay) {
                let showingMessageElement: any = null;
                let nextRecordButton: any = null;
                let previousRecordButton: any = null;
                let showMoreRecordsButton: any = null;

                if (pagingStyle === 'Continuous') {
                    if (recordCountToDisplay < totalRecordCount) {
                        showMoreRecordsButton = (
                            <Button onClick={showMoreRecords} color="secondary">
                                {(nextPageLabel ? nextPageLabel : 'Show More Records')}
                            </Button>
                        );
                    }

                    showingMessageElement = (
                        <Typography variant="caption">
                            showing {(recordCountToDisplay < totalRecordCount ? recordCountToDisplay : totalRecordCount)} of {totalRecordCount} records
                        </Typography>
                    );
                }

                if (pagingStyle === 'Paged') {
                    nextRecordButton = (
                        <Button onClick={showNextRecords} color="secondary" disabled={(currentPage >= numberOfPages ? true : false)}>
                            {(nextPageLabel ? nextPageLabel : 'Next')}
                        </Button>
                    );

                    previousRecordButton = (
                        <Button onClick={showPreviousRecords} color="secondary" disabled={(currentPage <= 1 ? true : false)}>
                            {(prevPageLabel ? prevPageLabel : 'Previous')}
                        </Button>
                    );

                    showingMessageElement = (
                        <Typography variant="caption">
                            showing {currentPage} of {numberOfPages} pages
                        </Typography>
                    );
                }

                recordListFooterContent = (
                    <Box>
                        {showMoreRecordsButton}
                        {previousRecordButton}
                        {nextRecordButton}
                        {showingMessageElement}
                    </Box>
                );
            }

            let listContentElement: any = null;
            if (listType === 'Card Grid') {
                // console.log('%c UdicciListContents layoutConfig: %O', 'color: red;', layoutConfig);
                let gridContainerProps: any = { spacing: 1 };
                if (layoutConfig) {
                    if (layoutConfig.grid && layoutConfig.grid.settings) {
                        if (layoutConfig.grid.settings.spacing !== undefined) gridContainerProps.spacing = layoutConfig.grid.settings.spacing;
                        if (layoutConfig.grid.settings.alignItems !== undefined) gridContainerProps.alignItems = layoutConfig.grid.settings.alignItems;
                        if (layoutConfig.grid.settings.direction !== undefined) gridContainerProps.direction = layoutConfig.grid.settings.direction;
                        if (layoutConfig.grid.settings.justifyContent !== undefined) gridContainerProps.justifyContent = layoutConfig.grid.settings.justifyContent;
                    }
                }
                listContentElement = (
                    <Grid container {...gridContainerProps}>{childrenDisplayElements}</Grid>
                );
            } else if (listType === 'Table') {
                // console.log('%c UdicciListContents layoutConfig: %O', 'color: red;', layoutConfig);
                if (layoutConfig) {
                    if (layoutConfig.table && layoutConfig.table.settings) {
                        if (layoutConfig.table.settings.size !== undefined) tableProps.size = layoutConfig.table.settings.size;
                        if (layoutConfig.table.settings.padding !== undefined) tableProps.padding = layoutConfig.table.settings.padding;
                    }
                    if (layoutConfig.table && layoutConfig.table.columns) {
                        forEach(layoutConfig.table.columns, (col: any, idx: number) => {
                            // console.log('%c col: %O', 'color: red;', col);
                            let header: any = (col.header ? col.header : null);
                            // console.log('%c header: %O', 'color: red;', header);

                            let headerVariant: string = (header && header.variant ? header.variant : 'caption');
                            // console.log('%c headerVariant: %O', 'color: red;', headerVariant);
                            let headerColor: string = (header && header.color ? header.color : 'primary');
                            // console.log('%c headerColor: %O', 'color: red;', headerColor);

                            let headerVariantProps: any = {
                                variant: headerVariant,
                                // color: headerColor
                            };
                            let cellProps: any = {
                                key: "col.header." + col.cell.field + "." + idx.toString(),
                                sx: { verticalAlign: 'top' }
                            };
                            tableHeaderColumns.push(
                                <TableCell {...cellProps}>
                                    <Typography {...headerVariantProps}>{(col.header.label ? col.header.label : col.cell.field)}</Typography>
                                </TableCell>
                            );
                        });
                    }
                }
                tableHeaderColumns.push(<TableCell key={"col.header.actions.1"}>&nbsp;</TableCell>);

                if (messageDisplayElement && dataIsLoading) {
                    tableLoadingRow = (<TableRow><TableCell colSpan={tableHeaderColumns.length}>{messageDisplayElement}</TableCell></TableRow>);
                }
                // console.log('%c UdicciListContents tableProps: %O', 'color: red;', tableProps);
                listContentElement = (
                    <Table {...tableProps}>
                        <TableHead><TableRow>{tableHeaderColumns}</TableRow></TableHead>
                        <TableBody>{tableLoadingRow}{childrenDisplayElements}</TableBody>
                    </Table>
                );
            } else {
                // console.log('%c UdicciListContents listType: %O', 'color: green; font-weight: bold;', listType);
                if (messageDisplayElement && dataIsLoading) {
                    listContentElement = (<Box sx={{ margin: '8px', padding: '8px' }}>{messageDisplayElement}</Box>);
                } else {
                    listContentElement = (<List>{childrenDisplayElements}</List>);
                }
            }

            listContainerElement = (
                <Box sx={{ display: 'flow-root', clear: 'both' }}>
                    {listContentElement}
                    {recordListFooterContent}
                </Box>
            );
        }
    }

    return (
        <Fragment>{listContainerElement}{listFooterElement}</Fragment>
    );
}

export default UdicciList;
