import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/AddToQueue';

const MediaLibrary = dynamic(() => import('src/components/media-library'));
const MediaLibraryAdmin = dynamic(() => import('src/admin/media-library-admin'));

const PluginId: string = 'media.library';
const PluginTitle: string = 'Media Library';
const PluginDescription: string = 'Display the Media Library for a Portal.';
const PluginVersion: number = 1;

const PluginRenderer = (props: any) => {
    // console.log('%c PluginRenderer props: %O', 'color: red;', props);
    if (!props.data) props.data = {};
    props.data.showInline = true;
    // console.log('%c PluginRenderer props.data: %O', 'color: red;', props.data);
    return ( <MediaLibrary {...props} fullWidth={true} /> );
};

const PluginAdminRenderer = (props: any) => {
    // console.log('%c PluginAdminRenderer props: %O', 'color: red;', props);
    if (!props.data) props.data = {};
    props.data.showInline = true;
    // console.log('%c PluginAdminRenderer props.data: %O', 'color: red;', props.data);
    return ( <MediaLibraryAdmin {...props} /> );
};

const PluginComponent: UdicciPlugin = {
    Renderer: PluginRenderer,
    id: PluginId,
    title: PluginTitle,
    description: PluginDescription,
    version: PluginVersion,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: PluginAdminRenderer },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c MediaLibrary canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c MediaLibrary canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
    },
    category: UdicciPluginCategory.Development
};
export default PluginComponent;