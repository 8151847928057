import { Fragment, useState, useContext, useEffect } from 'react';

import { forEach, find } from 'underscore';

import {
    Box, Paper, Typography, IconButton, FormControl, Input, InputLabel, Select, 
    MenuItem, Tabs, Tab, Button, FormControlLabel, Checkbox, Icon, Snackbar, Backdrop
} from '@mui/material';

import { UdicciContext, getDesignMode } from 'src/context/udicci-context';
import { udicciPluginComponents } from 'src/plugins/udicciPlugins';
import { UdicciPlugin } from 'src/classes/udicci-types';
import { PaneContextProvider } from 'src/context/pane-context';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

import UdicciPanePlugin from 'src/plugins/udicci-pane-plugin';

export default function PaneDesigner(props: any) {
    // console.log('%c PaneDesigner props: %O', 'color: green;', props);
    const udicciHelpers = useUdicciHelpers();
    // const udicciContext = useContext(UdicciContext);
    // console.log('%c PaneDesigner udicciContext: %O', 'color: red;', udicciContext);

    // let { udicci } = udicciContext.state;
    // console.log('%c PaneDesigner data: %O', 'color: maroon;', data);
    // console.log('%c PaneDesigner udicci: %O', 'color: maroon;', udicci);

    let { pane } = props;
    // console.log('%c PaneDesigner pane: %O', 'color: maroon;', pane);
    // console.log('%c PaneDesigner configure: %O', 'color: maroon;', configure);
    // let { selectedPorta } = udicci;
    // console.log('%c PaneDesigner selectedPorta: %O', 'color: maroon;', selectedPorta);
    // console.log('%c PaneDesigner showDesignerMenu: %O', 'color: maroon;', showDesignerMenu);
    // console.log('%c PaneDesigner showDemo: %O', 'color: maroon;', showDemo);
    // let selectedPortaId = (selectedPorta && selectedPorta.UdicciRecordId ? selectedPorta.UdicciRecordId : 0);
    let contentDesign: any = (pane && pane.contentDesign ? pane.contentDesign : null);
    // console.log('%c PaneDesigner contentDesign: %O', 'color: maroon;', contentDesign);
    // let content: any = (pane && pane.content ? pane.content : null);
    // console.log('%c PaneDesigner content: %O', 'color: maroon;', content);

    let dm: any = getDesignMode();
    // console.log('%c PaneDesigner dm: %O', 'color: maroon;', dm);

    let paneContentDesign: any = udicciHelpers.getPaneContentDesign(pane);
    // console.log('%c PaneDesigner paneContentDesign: %O', 'color: red;', paneContentDesign);

    let contentPlugins: any[] = (paneContentDesign && paneContentDesign.content && paneContentDesign.content.length > 0 ? paneContentDesign.content : []);
    // console.log('%c PaneDesigner contentPlugins: %O', 'color: red;', contentPlugins);

    // let readonly: boolean = true;
    // if (dm && dm === 'on' && configure) readonly = false;

    let pluginElements: any[] = [];
    if (contentPlugins.length > 0) {
        // console.log('%c PaneDesigner contentPlugins: %O', 'color: maroon;', contentPlugins);
        forEach(contentPlugins, function(plgn: any, idxPlugin: number) {
            // console.log('%c plgn: %O', 'color: purple;', plgn);
            let panePluginBackdropProps: any = {};
            Object.assign(panePluginBackdropProps, props);
            panePluginBackdropProps.key = `pane.${pane.displayComponent}.plugin.${plgn.id}.${idxPlugin}`;

            let panePluginProps: any = {};
            Object.assign(panePluginProps, props);
            panePluginProps.key = `pane.${pane.displayComponent}.plugin.${plgn.id}.${idxPlugin}`;
            panePluginProps.pane = pane;
            panePluginProps.plugin = plgn;
            panePluginProps.pluginIndex = idxPlugin;
            // console.log('%c panePluginProps: %O', 'color: purple;', panePluginProps);

            let udicciPlugin: UdicciPlugin | undefined = find(udicciPluginComponents, (up: UdicciPlugin) => { return up.id === plgn.plugin.id; });
            // console.log('%c udicciPlugin: %O', 'color: purple;', udicciPlugin);

            if (udicciPlugin || (dm && dm === 'on')) {
                // let canPick: any = udicciPlugin?.canPick(panePluginProps);
                // console.log('%c canPick: %O', 'color: purple;', canPick);
                let canDisplay: any = udicciPlugin?.canDisplay(panePluginProps);
                // console.log('%c canDisplay: %O', 'color: purple;', canDisplay);

                if (canDisplay || (dm && dm === 'on')) {
                    let panePluginElement: any = (<UdicciPanePlugin {...panePluginProps} />);
                    // console.log('%c panePluginElement: %O', 'color: blue;', panePluginElement);
                    pluginElements.push(panePluginElement);
                }
            // } else {
            //     let panePluginElement: any = (<UdicciPanePlugin {...panePluginProps} />);
            //     // console.log('%c panePluginElement: %O', 'color: purple;', panePluginElement);
            //     pluginElements.push(panePluginElement);
            }
        });
    }
    // console.log('%c pluginElements: %O', 'color: purple;', pluginElements);
    if (pluginElements.length <= 0) return null;
    // console.log('%c pluginElements: %O', 'color: green;', pluginElements);

    return ( <PaneContextProvider pane={pane} contentDesign={contentDesign}> {pluginElements} </PaneContextProvider> );
}
