import { useState, useEffect, useMemo, useCallback } from 'react';

import { Configuration, OpenAIApi } from "openai";

import { forEach, find, filter, keys, findIndex } from 'underscore';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext, subscribe as subscribeToUdicci, sendPreparedRequest } from 'src/context/udicci-context'
import { UdicciRecord } from 'src/classes/udicci-record';
import { SavePortaFocusBoardRequest } from 'src/interfaces/udicci-request-interfaces';
import {
    FocusedContext, OPENAI_API_KEY_UDICCI, DEFAULT_OPEN_AI_MODEL, DEFAULT_OPEN_AI_MAX_TOKENS, DEFAULT_OPEN_AI_TEMPERATURE, 
    DEFAULT_OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT, DEFAULT_OPEN_AI_PRESENCE_PENALTY, DEFAULT_OPEN_AI_FREQUENCY_PENALTY
} from './focused-context';

import { FocusBoardDisplay } from './focus-board-display';

export default function FocusBoardFullScreen(props: any) {
    const [selectedSettingMenu, setSelectedSettingMenu] = useState<string>('');
    const [point, setFocusedPoint] = useState<any>(null);
    const [configureSettings, setConfigureSettings] = useState<boolean>(false);
    const [aiAssistantOn, askForAssist] = useState<boolean>(false);
    const [aiPrompt, setAIPrompt] = useState<any>(null);
    const [aiError, setAIError] = useState<any>(null);
    const [focusBoardRequested, setFocusBoardRequested] = useState<boolean>(false);
    const [focusBoardSaving, setFocusBoardSaving] = useState<boolean>(false);
    const [uiState, flashUI] = useState<boolean>(false);
    // console.log('%c FocusBoardFullScreen point: %O', 'color: blue;', point);
    // console.log('%c FocusBoardFullScreen aiPrompt: %O', 'color: blue;', aiPrompt);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;
    let { currentUser, selectedPorta, selectedProfile } = udicci;
    // console.log('%c FocusBoardFullScreen selectedPorta: %O', 'color: blue;', selectedPorta);

    let pointStructure: any = udicciHelpers.getMediatorStructure('Points');
    // console.log('%c FocusBoardFullScreen pointStructure: %O', 'color: green;', pointStructure);

    let udicciFocusBoard: any = (udicci && udicci.focusBoard ? udicci.focusBoard : null);
    // const onUicciUpdate = useCallback((response: any, request: any, settings: any) => {
    //     // console.log('%c FocusBoardFullScreen onUicciUpdate response: %O', 'color: red;', response);
    //     // console.log('%c FocusBoardFullScreen onUicciUpdate request: %O', 'color: red;', request);
    //     // console.log('%c FocusBoardFullScreen onUicciUpdate settings: %O', 'color: red;', settings);
    //     if (request && request.UdicciCommand === 'Get Porta Focus Board') {
    //         // console.log('%c response: %O', 'color: green;', response);
    //         // console.log('%c request: %O', 'color: green;', request);
    //         processPortaFocusBoardResponse(response);
    //     }
    // }, []);

    // subscribeToUdicci('open.ai.full.screen', onUicciUpdate);

    let isMobile: boolean = udicci.isMobileDevice();

    let portaSettings: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : null);
    // console.log('%c ChatGPTSettings portaSettings: %O', 'color: blue;', portaSettings);
    let portaFocusBoard: any = (portaSettings && portaSettings.focusBoard ? portaSettings.focusBoard : null);
    // console.log('%c ChatGPTSettings portaFocusBoard: %O', 'color: blue;', portaFocusBoard);
    let focusBoardPoints: any[] = (portaFocusBoard && portaFocusBoard.points ? portaFocusBoard.points : []);
    // console.log('%c ChatGPTSettings focusBoardPoints: %O', 'color: blue;', focusBoardPoints);
    let focusBoardSettings: any = (portaFocusBoard && portaFocusBoard.settings ? portaFocusBoard.settings : null);
    // console.log('%c ChatGPTSettings focusBoardSettings: %O', 'color: blue;', focusBoardSettings);
    let openAiSettings: any = (focusBoardSettings && focusBoardSettings.openai ? focusBoardSettings.openai : null);
    // console.log('%c ChatGPTSettings openAiSettings: %O', 'color: blue;', openAiSettings);

    let openAIPortaOrganizationId: any = (openAiSettings && openAiSettings.organizationId ? openAiSettings.organizationId : '');
    // console.log('%c ChatGPTSettings openAIPortaOrganizationId: %O', 'color: blue;', openAIPortaOrganizationId);
    let openAIPortaModel: any = (openAiSettings && openAiSettings.model ? openAiSettings.model : DEFAULT_OPEN_AI_MODEL);
    // console.log('%c ChatGPTSettings openAIPortaModel: %O', 'color: blue;', openAIPortaModel);
    let openAIPortaMaxTokens: number = (openAiSettings && openAiSettings.maxTokens !== undefined ? openAiSettings.maxTokens : DEFAULT_OPEN_AI_MAX_TOKENS);
    // console.log('%c ChatGPTSettings openAIPortaMaxTokens: %O', 'color: blue;', openAIPortaMaxTokens);
    let openAIPortaTemperature: number = (openAiSettings && openAiSettings.temperature !== undefined ? openAiSettings.temperature : DEFAULT_OPEN_AI_TEMPERATURE);
    // console.log('%c ChatGPTSettings openAIPortaTemperature: %O', 'color: blue;', openAIPortaTemperature);
    let openAIPortaMaxCompletions: number = (openAiSettings && openAiSettings.maxCompletions !== undefined ? openAiSettings.maxCompletions : DEFAULT_OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT);
    // console.log('%c ChatGPTSettings openAIPortaMaxCompletions: %O', 'color: blue;', openAIPortaMaxCompletions);

    let apiKeyStorageName: string = '.it.udicci.' + 'OPENAI_API_KEY'.replace('_', '.').toLocaleLowerCase();
    let apiKeyStorage: string | null = localStorage.getItem(apiKeyStorageName);
    // console.log('%c apiKeyStorage: %O', 'color: blue;', apiKeyStorage);
    let orgIdStorageName: string = '.it.udicci.' + 'OPENAI_ORG_ID'.replace('_', '.').toLocaleLowerCase();
    let orgIdStorage: string | null = localStorage.getItem(orgIdStorageName);
    // console.log('%c orgIdStorage: %O', 'color: blue;', orgIdStorage);
    // console.log('%c OPENAI_API_KEY_UDICCI: %O', 'color: blue;', OPENAI_API_KEY_UDICCI);

    const [openAIAPIKey, setOpenAIAPIKey] = useState<string>((apiKeyStorage ? apiKeyStorage : OPENAI_API_KEY_UDICCI));
    const [openAIOrgId, setOpenAIOrgId] = useState<string>((orgIdStorage ? orgIdStorage : openAIPortaOrganizationId));
    const [openAIModel, setOpenAIModel] = useState<string>(openAIPortaModel);
    const [openAIMaxTokens, setOpenAIMaxTokens] = useState<number>(openAIPortaMaxTokens);
    const [openAITemperature, setOpenAITemperature] = useState<number>(openAIPortaTemperature);
    const [openAIMaxCompletions, setOpenAIMaxCompletions] = useState<number>(openAIPortaMaxCompletions);
    const [openAIPresencePenalty, setOpenAIPresencePenalty] = useState<number>((DEFAULT_OPEN_AI_PRESENCE_PENALTY ? DEFAULT_OPEN_AI_PRESENCE_PENALTY : 0));
    const [openAIFrequencyPenalty, setOpenAIFrequencyPenalty] = useState<number>((DEFAULT_OPEN_AI_FREQUENCY_PENALTY ? DEFAULT_OPEN_AI_FREQUENCY_PENALTY : 0));
    const [aiModels, setAIModels] = useState<any[]>([]);
    const [aiResponse, setAIResponse] = useState<any>(null);
    const [processingAIRequest, setProcessingAIRequest] = useState<boolean>(false);
    // console.log('%c FocusBoardFullScreen openAIAPIKey: %O', 'color: blue;', openAIAPIKey);
    // console.log('%c FocusBoardFullScreen openAIOrgId: %O', 'color: blue;', openAIOrgId);
    // console.log('%c FocusBoardFullScreen aiResponse: %O', 'color: blue;', aiResponse);

    const refreshFocusBoard = () => {
        // console.log('%c refreshFocusBoard udicciFocusBoard: %O', 'color: blue;', udicciFocusBoard);
        // console.log('%c refreshFocusBoard focusBoardRequested: %O', 'color: blue;', focusBoardRequested);
        udicci.getPortaFocusBoard({ onSuccess: focusBoardReceived });
        setFocusBoardRequested(true);
    }

    const hasChangesToSave = () => {
        // console.log('%c hasChangesToSave udicciFocusBoard: %O', 'color: blue;', udicciFocusBoard);
        // console.log('%c hasChangesToSave selectedPorta: %O', 'color: blue;', selectedPorta);
        // console.log('%c hasChangesToSave focusBoardRequested: %O', 'color: blue;', focusBoardRequested);
        let hasDirtyRecords: boolean = false;
        if (selectedPorta && selectedPorta.IsDirty) hasDirtyRecords = true;
        // console.log('%c hasChangesToSave hasDirtyRecords (1): %O', 'color: blue;', hasDirtyRecords);
        if (!hasDirtyRecords) {
            // check NOT necessary if the Porta is dirty, this means it will get saved anyway
            let points: UdicciRecord[] = (udicciFocusBoard && udicciFocusBoard.Points ? udicciFocusBoard.Points : []);
            // console.log('%c hasChangesToSave points: %O', 'color: blue;', points);
            forEach(points, (p: UdicciRecord, idx: number) => {
                if (hasDirtyRecords) return false;
                if (p.isDirty) hasDirtyRecords = true;
                // console.log('%c hasDirtyRecords (2): %O', 'color: green;', hasDirtyRecords);
            });
        }
        return hasDirtyRecords;
    }

    const isSavingFocusBoard = () => {
        // console.log('%c isSavingFocusBoard focusBoardSaving: %O', 'color: blue;', focusBoardSaving);
        return focusBoardSaving;
    }

    const focusBoardReceived = (response: any) => {
        // console.log('%c focusBoardReceived response: %O', 'color: blue;', response);
        processPortaFocusBoardResponse(response);
    }

    const processPortaFocusBoardResponse = (response: any) => {
        // console.log('%c processPortaFocusBoardResponse response: %O', 'color: blue;', response);
        let responseFocusBoard: any = (response && response.PortaFocusBoard ? response.PortaFocusBoard : null);
        // console.log('%c responseFocusBoard: %O', 'color: green;', responseFocusBoard);

        if (responseFocusBoard) {
            let resultPoints: any[] = (responseFocusBoard && responseFocusBoard.Points ? responseFocusBoard.Points : []);
            // console.log('%c resultPoints: %O', 'color: red;', resultPoints);
            let resultSettings: any = (responseFocusBoard && responseFocusBoard.Settings ? responseFocusBoard.Settings : '');
            // console.log('%c resultSettings: %O', 'color: red;', resultSettings);

            let pointStructure: any = udicciHelpers.getMediatorStructure('Points');
            // console.log('%c pointStructure: %O', 'color: red;', pointStructure);
            let perspectiveStructure: any = udicciHelpers.getMediatorStructure('Perspectives');
            // console.log('%c perspectiveStructure: %O', 'color: red;', perspectiveStructure);
            let pointRecords: UdicciRecord[] = [];
            forEach(resultPoints, (p: any, i: number) => {
                let pointRecord: UdicciRecord = new UdicciRecord('Points', p, pointStructure);
                // console.log('%c pointRecord: %O', 'color: red;', pointRecord);
                if (pointRecord && pointRecord.data && pointRecord.data.Perspectives) {
                    let perspectives: any = (pointRecord && pointRecord.data && pointRecord.data.Perspectives ? pointRecord.data.Perspectives : null);
                    // console.log('%c perspectives: %O', 'color: red;', perspectives);

                    let pointPerspectives: any[] = [];
                    if (perspectives && perspectives.length > 0) {
                        forEach(perspectives, (persp: any) => {
                            // console.log('%c persp: %O', 'color: red;', persp);
                            if (persp.recordId && persp.udicciMediator && persp.data) {
                                // console.log('%c persp: %O', 'color: red;', persp);
                                pointPerspectives.push(persp);
                            } else {
                                let perspectiveRecord: UdicciRecord = new UdicciRecord('Perspectives', persp, perspectiveStructure);
                                // console.log('%c perspectiveRecord: %O', 'color: red;', perspectiveRecord);
                                pointPerspectives.push(perspectiveRecord);
                            }
                        });
                    }
                    // console.log('%c pointPerspectives: %O', 'color: blue;', pointPerspectives);

                    pointRecord.perspectives = (pointPerspectives.length > 0 ? pointPerspectives : null);
                    // if (pointRecord.data.Perspectives !== undefined) delete pointRecord.data['Perspectives'];
                }

                let resultContext: string = (p.Context ? p.Context : '');
                // console.log('%c resultContext: %O', 'color: red;', resultContext);
                let pointContext: any = null;
                if (resultContext) {
                    try {
                        pointContext = JSON.parse(resultContext);
                    } catch {

                    }
                }
                // console.log('%c pointContext: %O', 'color: red;', pointContext);
                if (pointContext) pointRecord.context = pointContext;

                pointRecords.push(pointRecord);
            });
            // console.log('%c pointRecords: %O', 'color: red;', pointRecords);
            let settings: any = null;
            if (resultSettings) {
                if (typeof(resultSettings) === 'string') {
                    try {
                        settings = JSON.parse(resultSettings);
                    } catch {

                    }
                } else {
                    settings = resultSettings;
                }
            }

            if (!responseFocusBoard) responseFocusBoard = {};
            responseFocusBoard.Points = pointRecords;
            responseFocusBoard.Settings = settings;
        }
        // console.log('%c responseFocusBoard: %O', 'color: red;', responseFocusBoard);

        if (responseFocusBoard) {
            udicci.setPortaFocusBoard(responseFocusBoard);

            // console.log('%c point: %O', 'color: red;', point);
            if (point) {
                let updatedPoint: UdicciRecord = find(responseFocusBoard.Points, (p: UdicciRecord) => {
                    let isSameRecord: boolean = false;
                    let pcLocalId: string = (p.context && p.context.local_id ? p.context.local_id : '');
                    let focusPointLocalId: string = (point && point.context && point.context.local_id ? point.context.local_id : '');
                    if (point.recordId > 0 && p.recordId === point.recordId) {
                        isSameRecord = true;
                    } else if (focusPointLocalId && focusPointLocalId === pcLocalId) {
                        isSameRecord = true;
                    }
                    return isSameRecord;
                });
                // console.log('%c updatedPoint: %O', 'color: red;', updatedPoint);
                if (updatedPoint) setFocusedPoint(updatedPoint);
            }
        }
    }

    if (!udicciFocusBoard && !focusBoardRequested && pointStructure) {
        refreshFocusBoard();
    }

    const setPointToFocus = (point: any) => {
        // console.log('%c setPointToFocus point: %O', 'color: maroon;', point);
        let udicciFocusBoard: any = (udicci && udicci.focusBoard ? udicci.focusBoard : {});
        // console.log('%c setPointToFocus udicciFocusBoard: %O', 'color: red;', udicciFocusBoard);

        let focusBoardPoints: any[] = (udicciFocusBoard && udicciFocusBoard.Points ? udicciFocusBoard.Points : []);
        // console.log('%c setPointToFocus focusBoardPoints: %O', 'color: blue;', focusBoardPoints);
        if (point) {
            if (point.recordId) {
                let pointIndex: number = findIndex(focusBoardPoints, (fbp: any) => { return (fbp.recordId === point.recordId ? true : false) });
                if (pointIndex >= 0) focusBoardPoints[pointIndex] = point;
            } else {
                let updateLocalId: string = (point && point.context && point.context.local_id ? '' : '');
                let pointIndex: number = findIndex(focusBoardPoints, (fbp: any) => { return (fbp.context && fbp.context.local_id === updateLocalId ? true : false) });
                if (pointIndex >= 0) focusBoardPoints[pointIndex] = point;
            }
        }

        // console.log('%c setPointToFocus focusBoardPoints: %O', 'color: blue;', focusBoardPoints);
        udicciFocusBoard.Points = focusBoardPoints;

        setFocusedPoint(point);
        udicci.setPortaFocusBoard(udicciFocusBoard);

        // flashUI(!uiState);
    }

    const changeSettingMenu = (settingName: string) => {
        // console.log('%c changeSettingMenu settingName: %O', 'color: maroon;', settingName);
        setSelectedSettingMenu(settingName);
    }

    const closeFocus = () => {
        setAIPrompt(null);
        setAIError(null);
        askForAssist(false);
        setConfigureSettings(false);
        setSelectedSettingMenu('');
        setFocusedPoint(null);
    }

    const getAIRequestStandardSettings = () => {
        return {
            model: openAIModel,
            max_tokens: openAIMaxTokens,
            temperature: openAITemperature,
            n: openAIMaxCompletions,
        };
    }

    const requestAIAssistance = () => {
        // console.log('%c requestAIAssistance focusedPoint: %O', 'color: maroon;', focusedPoint);
        setAIPrompt(null);
        setAIError(null);
        askForAssist(!aiAssistantOn);
    }

    const toggleSettingsForm = () => {
        // console.log('%c toggleSettingssForm configureSettings: %O', 'color: maroon;', configureSettings);
        setConfigureSettings(!configureSettings);
    }

    const updateAIPrompt = (updatedPrompt: any) => {
        // console.log('%c updateAIPrompt updatedPrompt: %O', 'color: maroon;', updatedPrompt);
        setAIPrompt(updatedPrompt);
    }

    const clearAIError = () => {
        setAIError(null);
        flashUI(!uiState);
    }

    const getNewPointRecord = (): UdicciRecord => {
        let pointStructure: any = udicciHelpers.getMediatorStructure('Points');
        let newPoint: UdicciRecord = new UdicciRecord('Points', null, pointStructure)
        // console.log('%c getNewPointRecord newPoint %s: %O', 'color: blue;', newPoint.title, newPoint);

        newPoint.recordId = 0;
        newPoint.data.UdicciRecordId = 0;
        newPoint.title = '';
        newPoint.data.ThePoint = '';
        newPoint.description = '';
        newPoint.data.TheReason = '';

        newPoint.data.ShowPoint = false;
        newPoint.data.PointInTip = false;
        newPoint.data.ShowReason = false;
        newPoint.data.ReasonInTip = false;

        newPoint.data.CreatedByUserId = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
        newPoint.data.CreatorDisplayName = (currentUser && currentUser.myDisplayName ? currentUser.myDisplayName : '');
        newPoint.data.CreatorSocialIcon = (currentUser && currentUser.mySocialIcon ? currentUser.mySocialIcon : '');
        newPoint.data.ProfileUrl = (currentUser && currentUser.myProfileUrl ? currentUser.myProfileUrl : '');

        if (!newPoint.context) newPoint.context = {};
        // add a local id for associations
        newPoint.context.local_id = udicciHelpers.generateUID();

        return newPoint;
    };

    const getNewPerspectiveRecord = (): UdicciRecord => {
        let perspectiveStructure: any = udicciHelpers.getMediatorStructure('Perspectives');
        let newPerspective: UdicciRecord = new UdicciRecord('Perspectives', null, perspectiveStructure)
        // console.log('%c getNewPerspectiveRecord newPerspective %s: %O', 'color: blue;', newPerspective.title, newPerspective);

        newPerspective.recordId = 0;
        newPerspective.data.UdicciRecordId = 0;
        newPerspective.title = '';
        newPerspective.data.Title = '';
        newPerspective.description = '';
        newPerspective.data.TalkingPoints = '';
        newPerspective.data.EmbeddedMedia = '';
        newPerspective.data.SettingsJson = '';

        newPerspective.data.ExpectedDuration = 0;
        newPerspective.data.ActualDuration = 0;
        newPerspective.data.ShowReason = 0;

        newPerspective.data.CreatedByUserId = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
        newPerspective.data.CreatorDisplayName = (currentUser && currentUser.myDisplayName ? currentUser.myDisplayName : '');
        newPerspective.data.CreatorSocialIcon = (currentUser && currentUser.mySocialIcon ? currentUser.mySocialIcon : '');
        newPerspective.data.ProfileUrl = (currentUser && currentUser.myProfileUrl ? currentUser.myProfileUrl : '');

        if (!newPerspective.context) newPerspective.context = {};
        // add a local id for associations
        newPerspective.context.local_id = udicciHelpers.generateUID();

        return newPerspective;
    };

    const sendAIRequest = async (settings: any) => {
        // console.log('%c sendAIRequest settings: %O', 'color: maroon;', settings);
        // console.log('%c sendAIRequest aiPrompt: %O', 'color: maroon;', aiPrompt);

        setProcessingAIRequest(true);

        let configurationSettings: any = { organization: openAIOrgId, apiKey: openAIAPIKey };
        // console.log('%c sendAIRequest configurationSettings: %O', 'color: maroon;', configurationSettings);
        const configuration = new Configuration(configurationSettings);
        // console.log('%c sendAIRequest aiPrompt: %O', 'color: maroon;', aiPrompt);
        const openai = new OpenAIApi(configuration);

        let aiSettings: any = getAIRequestStandardSettings();
        aiSettings.prompt = aiPrompt.flexPrompt;
        // console.log("sendAIRequest aiSettings: %O", aiSettings);
        openai.createCompletion(aiSettings).then((aiResponse: any) => {
            // console.log("sendAIRequest createCompletion response aiResponse: %O", aiResponse);

            setProcessingAIRequest(false);
            setAIResponse(aiResponse);

            if (settings && settings.onAiResponseReceived) settings.onAiResponseReceived(aiResponse);
        }).catch((error: any) => {
            // console.log("sendAIRequest createCompletion error: %O", error);
            setProcessingAIRequest(false);
            let rsltErrResp: any = (error && error.response ? error.response : null);
            // console.log('%c sendAIRequest createCompletion errorResponse: %O', 'color: blue;', rsltErrResp);
            let rsltErrorData: any = (rsltErrResp && rsltErrResp.data ? rsltErrResp.data : null);
            // console.log('%c sendAIRequest createCompletion errorData: %O', 'color: blue;', errorData);
            let resultAIError: any = (rsltErrorData && rsltErrorData.error ? rsltErrorData.error : null);
            // console.log('%c sendAIRequest createCompletion resultAIError: %O', 'color: blue;', resultAIError);
            setAIError(resultAIError);
            if (settings && settings.onAiErrorReceived) settings.onAiErrorReceived(resultAIError);
            flashUI(!uiState);
        });
    }

    const getOpenAIModels = async () => {
        if (aiModels.length <= 0) {
            setProcessingAIRequest(true);

            let configuration = new Configuration({ organization: openAIOrgId, apiKey: openAIAPIKey, });
            let openai = new OpenAIApi(configuration);

            const openaiListModels: any = await openai.listModels();
            // console.log("getOpenAIModels openaiListModels: %O", openaiListModels);
            if (openaiListModels && openaiListModels.data && openaiListModels.data.data && openaiListModels.data.data.length > 0) {
                let aiModels: any[] = openaiListModels.data.data;
                // console.log("getOpenAIModels aiModels: %O", aiModels);
                let aiModelOwners: string[] = [];
                let aiModelsFiltered: any[] = filter(aiModels, (aim: any, idx: number) => {
                    if (aiModelOwners.indexOf(aim.owned_by) < 0) aiModelOwners.push(aim.owned_by);
                    return aim.owned_by === 'openai';
                });
                // console.log("getOpenAIModels aiModelsFiltered: %O", aiModelsFiltered);
                setAIModels(aiModelsFiltered);
                // console.log("getOpenAIModels aiModelOwners: %O", aiModelOwners);
            }
        } else {
            return aiModels;
        }
    }

    const processUpdates = (changes: any) => {
        // console.log('%c processUpdates changes: %O', 'color: maroon;', changes);
        if (!selectedPorta) return false;

        // console.log('%c processUpdates focus: %O', 'color: maroon;', focus);
        let ps: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : null);
        // console.log('%c ChatGPTSettings portaSettings: %O', 'color: red;', ps);
        let portaFocusBoard: any = (ps && ps.focusBoard ? ps.focusBoard : null);
        // console.log('%c processUpdates portaFocusBoard: %O', 'color: maroon;', portaFocusBoard);

        if (!portaFocusBoard) portaFocusBoard = {};
        if (!portaFocusBoard.settings) portaFocusBoard.settings = {};
        if (typeof(portaFocusBoard.settings) === 'string') {
            try {
                portaFocusBoard.settings = JSON.parse(portaFocusBoard.settings);
            } catch (ex: any) {

            }
        }
        if (!portaFocusBoard.settings.openai) portaFocusBoard.settings.openai = {};

        let changeKeys: string[] = keys(changes);
        // console.log('%c processUpdates changeKeys: %O', 'color: maroon;', changeKeys);
        forEach(changeKeys, (fieldName: string) => {
            let storageName: string = '.it.udicci.' + fieldName.replace('_', '.').toLocaleLowerCase();
            // console.log('%c onChangeField OPENAI_ORG_ID storageName: %O', 'color: maroon;', storageName);
            let newValue: any = changes[fieldName];
            // console.log('%c processUpdates newValue: %O', 'color: maroon;', newValue);
            switch (fieldName) {
                case 'OPENAI_ORG_ID':
                    if (newValue.length > 0) {
                        localStorage.setItem(storageName, newValue);
                    } else {
                        let checkStorage: string | null = localStorage.getItem(storageName);
                        // console.log('%c checkStorage: %O', 'color: maroon;', checkStorage);
                        if (checkStorage !== null) localStorage.removeItem(storageName);
                    }
                    setOpenAIOrgId(newValue);
                    portaFocusBoard.settings.openai.organizationId = newValue;
                    break;
                case 'OPENAI_API_KEY':
                    if (newValue.length > 0) {
                        localStorage.setItem(storageName, newValue);
                    } else {
                        let checkStorage: string | null = localStorage.getItem(storageName);
                        // console.log('%c checkStorage: %O', 'color: maroon;', checkStorage);
                        if (checkStorage !== null) localStorage.removeItem(storageName);
                    }
                    setOpenAIAPIKey(newValue);
                    break;
                case 'OPEN_AI_MODEL':
                    setOpenAIModel(newValue);
                    portaFocusBoard.settings.openai.model = newValue;
                    break;
                case 'OPEN_AI_TEMPERATURE':
                    setOpenAITemperature(newValue);
                    portaFocusBoard.settings.openai.temperature = newValue;
                    break;
                case 'OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT':
                    setOpenAIMaxCompletions(newValue);
                    portaFocusBoard.settings.openai.maxCompletions = newValue;
                    break;
                case 'OPEN_AI_MAX_TOKENS':
                    setOpenAIMaxTokens(newValue);
                    portaFocusBoard.settings.openai.maxTokens = newValue;
                    break;
            }
        });

        if (!ps) ps = {};
        ps.focusBoard = portaFocusBoard;
        selectedPorta.SettingsJson = ps;
        selectedPorta.IsDirty = true;
        // console.log('%c processUpdates selectedPorta: %O', 'color: maroon;', selectedPorta);
        udicci.setPorta(selectedPorta);
        if (openAIMaxTokens !== changes.OPEN_AI_MAX_TOKENS) setOpenAIMaxTokens(changes.OPEN_AI_MAX_TOKENS);
    }

    const savePortaFocusBoard = () => {
        if (!selectedProfile || !selectedPorta) return false;
        // console.log('%c savePortaFocusBoard focus: %O', 'color: red;', focus);
        if (!point) return false;
        let workingPoint: any = {};
        Object.assign(workingPoint, point);

        // console.log('%c savePortaFocusBoard workingPoint: %O', 'color: red;', workingPoint);
        // console.log('%c savePortaFocusBoard selectedPorta: %O', 'color: red;', selectedPorta);

        let udicciFocusBoard: any = (udicci && udicci.focusBoard ? udicci.focusBoard : null);
        // console.log('%c savePortaFocusBoard udicciFocusBoard: %O', 'color: red;', udicciFocusBoard);
        if (!udicciFocusBoard) return false;

        let focusBoardPoints: any[] = (udicciFocusBoard && udicciFocusBoard.Points ? udicciFocusBoard.Points : []);
        // console.log('%c savePortaFocusBoard focusBoardPoints: %O', 'color: blue;', focusBoardPoints);
        let focusBoardSettings: any = (udicciFocusBoard && udicciFocusBoard.Settings ? udicciFocusBoard.Settings : null);
        // console.log('%c savePortaFocusBoard focusBoardSettings: %O', 'color: blue;', focusBoardSettings);

        let portaSettings: any = (selectedPorta.SettingsJson ? selectedPorta.SettingsJson : null);
        // console.log('%c savePortaFocusBoard portaSettings: %O', 'color: red;', portaSettings);
        let portaFocusBoard: any = (portaSettings && portaSettings.focusBoard ? portaSettings.focusBoard : null);
        // console.log('%c savePortaFocusBoard portaFocusBoard: %O', 'color: red;', portaFocusBoard);

        if (!portaSettings) portaSettings = {};
        if (!portaFocusBoard) portaFocusBoard = {};

        let fpRecordId: number = (workingPoint && workingPoint.recordId ? workingPoint.recordId : 0);
        // console.log('%c savePortaFocusBoard fpRecordId: %O', 'color: red;', fpRecordId);

        portaFocusBoard.points = [];
        forEach(focusBoardPoints, (fbp: any, idx: number) => {
            let pfbp: any = {
                recordId: (fbp.recordId ? fbp.recordId : 0),
                title: (fbp.title ? fbp.title : ''),
                description: (fbp.description ? fbp.description : ''),
            };
            if (fpRecordId > 0 && fpRecordId === pfbp.recordId) {
                if (workingPoint.recordId) pfbp.recordId = workingPoint.recordId;
                if (workingPoint.title) pfbp.title = workingPoint.title;
                if (workingPoint.description) pfbp.description = workingPoint.description;
                if (workingPoint.perspectives) focusBoardPoints[idx].perspectives = workingPoint.perspectives;
            }
            portaFocusBoard.points.push(pfbp);
        });

        if (fpRecordId <= 0 && workingPoint) {
            let checkIdx: number = findIndex(focusBoardPoints, (x: any, idx: number) => { return x.recordId === workingPoint.recordId; });
            if (checkIdx < 0) focusBoardPoints.push(workingPoint);
        }
        // console.log('%c savePortaFocusBoard focusBoardPoints: %O', 'color: red;', focusBoardPoints);

        portaFocusBoard.settings = focusBoardSettings;
        selectedPorta.SettingsJson = portaSettings;
        selectedPorta.Settings = JSON.stringify(selectedPorta.SettingsJson);

        let porta: any = {};
        Object.assign(porta, selectedPorta);
        if (porta.SettingsJson !== undefined) delete porta['SettingsJson'];

        // let pointStructure: any = udicciHelpers.getMediatorStructure('Points');
        // console.log('%c savePortaFocusBoard pointStructure: %O', 'color: blue;', pointStructure);
        // let perspectiveStructure: any = udicciHelpers.getMediatorStructure('Perspectives');
        // console.log('%c savePortaFocusBoard perspectiveStructure: %O', 'color: blue;', perspectiveStructure);

        let savePortaFocusBoardRequest: SavePortaFocusBoardRequest = {
            UdicciMediatorName: 'Portas',
            UdicciCommand: 'Save Porta Focus Board',
            SelectedUdicciProfileId: selectedProfile.recordId,
            SocialSolutionId: 0,
            UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0),
            RecordId: porta.UdicciRecordId,
            Porta: porta,
            FocusBoardSettings: focusBoardSettings,
            FocusBoardPoints: focusBoardPoints,
        }
        // console.log('%c savePortaFocusBoard savePortaFocusBoardRequest: %O', 'color: blue;', savePortaFocusBoardRequest);

        var okToContinue = true;
        if (okToContinue)  {
            setFocusBoardSaving(true);
            sendPreparedRequest(savePortaFocusBoardRequest, { onSuccess: saveFocusBoardSuccess, onError: failedToSaveFocusBoard });
            flashUI(!uiState);
        }
    }

    const saveFocusBoardSuccess = (response: any) => {
        // console.log('%c saveFocusBoardSuccess response: %O', 'color: red;', response);
        setFocusBoardSaving(false);
        processPortaFocusBoardResponse(response);
        flashUI(!uiState);
    };

    const failedToSaveFocusBoard = (result: any) => {
        // console.log('%c failedToSaveFocusBoard result: %O', 'color: red;', result);
        setFocusBoardSaving(false);
        flashUI(!uiState);
    };

    useEffect(() => {
        let okToCheckStructures: boolean = true;
        if (currentUser && currentUser.UdicciUserId === process.env.REACT_APP_ULYSSES_D_CONSTANTINE_USER_ID) {
            if (openAIAPIKey.length <= 0 && openAIAPIKey !== process.env.REACT_APP_OPENAI_API_KEY) {
                // console.log('%c ULYSSES using openAIAPIKey: %O', 'color: green;', openAIAPIKey);
                // setOpenAIAPIKey(process.env.REACT_APP_OPENAI_API_KEY);
                // okToCheckStructures = false;
            }
        } else {
            // specialUsers - UC, TM, CL
            // udicciSpecialUsers - UC, TM, CL
            // developerUsers - UC, TM
            // if (currentUser && udicci.developerUsers.indexOf(currentUser.UserName.toLowerCase()) >= 0 && openAIAPIKey !== process.env.REACT_APP_OPENAI_API_KEY) {
            //     // console.log('%c developerUser using openAIAPIKey: %O', 'color: green;', openAIAPIKey);
            //     setOpenAIAPIKey(process.env.REACT_APP_OPENAI_API_KEY);
            //     // okToCheckStructures = false;
            // }
        }

        // console.log('%c FocusBoardFullScreen okToCheckStructures: %O', 'color: blue;', okToCheckStructures);
        if (okToCheckStructures) {
            let structurePoints: any = udicciHelpers.getMediatorStructure('Points');
            let structurePerspectives: any = udicciHelpers.getMediatorStructure('Perspectives');
            // console.log('%c useEffect structurePoints: %O', 'color: orange;', structurePoints);
            if (!structurePoints || !structurePerspectives) {
                udicci.getMediatorStructures(['Points', 'Perspectives', 'Attachments']);
            }
        } else {
            flashUI(!uiState);
        }
    }, []);

    const valueSettings: any = {
        isMobile,
        OPENAI_API_KEY: openAIAPIKey,
        OPENAI_ORG_ID: openAIOrgId,
        OPEN_AI_MODEL: openAIModel,
        OPEN_AI_MAX_TOKENS: openAIMaxTokens,
        OPEN_AI_TEMPERATURE: openAITemperature,
        OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT: openAIMaxCompletions,
        DEFAULT_OPEN_AI_PRESENCE_PENALTY: openAIPresencePenalty,
        DEFAULT_OPEN_AI_FREQUENCY_PENALTY: openAIFrequencyPenalty,
        selectedSettingMenu, setSelectedSettingMenu,
        point, setFocusedPoint,
        aiAssistantOn, askForAssist,
        aiModels, setAIModels,
        aiPrompt, setAIPrompt,
        aiError, setAIError, clearAIError,
        processingAIRequest: processingAIRequest,
        lastAIResponse: aiResponse,
        configureSettings, setConfigureSettings, refreshFocusBoard, hasChangesToSave, isSavingFocusBoard,
        setPointToFocus, changeSettingMenu, closeFocus, requestAIAssistance, toggleSettingsForm, updateAIPrompt, getNewPointRecord, 
        getNewPerspectiveRecord, setOpenAIAPIKey, setOpenAIOrgId, setOpenAIModel, setOpenAIMaxTokens, setOpenAITemperature, getOpenAIModels, 
        setOpenAIMaxCompletions, setOpenAIPresencePenalty, setOpenAIFrequencyPenalty, getAIRequestStandardSettings, sendAIRequest,
        processUpdates, savePortaFocusBoard
    };
    const valueWatchList: any[] = [
        selectedSettingMenu, point, aiAssistantOn, aiPrompt, configureSettings, openAIAPIKey, openAIOrgId, openAIModel, aiError,
        openAIMaxTokens, openAITemperature, openAIMaxCompletions, openAIPresencePenalty, openAIFrequencyPenalty, uiState,
    ];

    let displayElement: any = (
        <FocusedContext.Provider value={( useMemo( () => (valueSettings), valueWatchList ))}> { useMemo(() => ( <FocusBoardDisplay /> ), []) } </FocusedContext.Provider>
    );
    return displayElement;
}
