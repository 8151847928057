import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/Login';

const ChangePasswordForm = dynamic(() => import('src/components/change-password-form'));
const ChangePasswordAdmin = dynamic(() => import('src/admin/change-password-admin'));

const changePasswordPlugin: UdicciPlugin = {
    Renderer: (props: any) => { return ( <ChangePasswordForm {...props} /> ); },
    id: 'change.password',
    title: 'Change Udicci Password',
    description: 'Component to Change a Udicci User Password.',
    version: 1,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <ChangePasswordAdmin {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c changePasswordPlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c changePasswordPlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
    },
    category: UdicciPluginCategory.Admin
};
export default changePasswordPlugin;