import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import UdicciApp from "./components/udicci-app";

export default function UdicciIT() {
    return (
        <BrowserRouter basename="/">
            <Routes>
                <Route path="/" element={(<UdicciApp />)} />
                <Route path="/:portaId" element={(<UdicciApp />)} />
            </Routes>
        </BrowserRouter>
    );
}
