import { styled } from '@mui/material/styles';

import UdicciLogo from 'src/images/Udicci.png';

import { Box, Paper, Typography, Icon, IconButton, Divider, CircularProgress } from '@mui/material';

import { useUdicciContext, saveProfile as saveSelectedProfile } from 'src/context/udicci-context';
import { useFocusedContext } from './focused-context';

// going old school ... let's see if it will work at this level!
import ConfigureProfileSettings from 'src/components/configure-profile-settings';
import ChangePasswordForm from 'src/components/change-password-form';
import { FocusMenuSettings } from './focus-menu-settings';
import { ChatGPTSettings } from './chat-gpt-settings';

const PaperContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
}));

export const ConfigureSettings: React.FC<any> = (props) => {
    const focus = useFocusedContext();
    // console.log('%c ConfigureSettings focus: %O', 'color: red;', focus);

    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;
    let { selectedProfile } = udicci;
    let profile: any = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);

    const changeProfile = (fieldName: string, evt: any) => {
        // console.log('%c changeProfile fieldName: %O', 'color: red;', fieldName);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        udicci.onChangeProfile(fieldName, newValue);
    }

    const saveProfile = (evt: any) => {
        // console.log('%c saveProfile selectedProfile: %O', 'color: red;', selectedProfile);
        saveSelectedProfile();
    }

    let isDirty: boolean = (selectedProfile && selectedProfile.isDirty ? true : false);
    // console.log('%c ConfigureSettings isDirty: %O', 'color: maroon;', isDirty);
    let isSaving: boolean = (selectedProfile && selectedProfile.isSaving ? true : false);
    // console.log('%c ConfigureSettings isSaving: %O', 'color: maroon;', isSaving);

    var profileSocialIcon = (profile && profile.SocialIcon ? profile.SocialIcon : udicci.defaultSocialIcon);
    let logoDisplayElement: any = (<img src={(profileSocialIcon ? profileSocialIcon : UdicciLogo)} alt="Udicci" style={{ maxWidth: '177px' }} />);

    let introductionContainerSx: any = {
        padding: '8px',
        paddingTop: '20px',
        // paddingBottom: '20px',
        // textAlign: 'center',
        position: 'relative',
        marginTop: '40px',
        top: 5,
        bottom: (focus.selectedSettingMenu ? 0 : 120),
        left: 0,
        right: 0,
    };
    if (!focus.isMobile) {
        introductionContainerSx.maxWidth = '750px';
        introductionContainerSx.padding = '32px';
        introductionContainerSx.marginTop = '20px';
        introductionContainerSx.marginLeft = 'auto';
        introductionContainerSx.marginRight = 'auto';
        introductionContainerSx.left = udicci.standardMenuDrawerWidth;
        introductionContainerSx.width = { md: `calc(100% - ${udicci.standardMenuDrawerWidth}px)` };
    }

    let sxSubSectionContainer: any = null;
    let sxSubSectionLogoContainer: any = null;
    let sxSubTitleSection: any = null;
    let sxSubTitleGroupedContent: any = null;
    sxSubSectionContainer = { textAlign: 'center', marginBottom: '16px' };
    sxSubSectionLogoContainer = { marginLeft: 'auto', marginRight: 'auto', display: 'inline-flex', padding: '8px' };
    sxSubTitleSection = { marginTop: '8px', padding: '8px', marginBottom: '50px' };
    sxSubTitleGroupedContent = { marginTop: '2px' };

    let subTitleWelcome: string = 'Welcome to Udicci.IT.';
    let subTitleMessage: string = 'Configure your Settings.';

    let configureElement: any = null;
    switch (focus.selectedSettingMenu) {
        case 'FocusBoard':
            configureElement = (
                <Box sx={sxSubTitleSection}>
                    <FocusMenuSettings />
                </Box>
            );
            break;
        case 'Profile':
            let saveProfileIcon: any = null;
            if (isSaving) {
                saveProfileIcon = (<CircularProgress size={20} sx={{ float: 'right', marginLeft: '8px' }} />);
            } else {
                saveProfileIcon = (
                    <IconButton size='small' color="secondary" onClick={saveProfile} sx={{ float: 'right', marginLeft: '8px' }} disabled={!isDirty || isSaving}>
                        <Icon fontSize="small">save</Icon>
                    </IconButton>
                );
            }
            configureElement = (
                <Box sx={sxSubTitleSection}>
                    <Box sx={{ margin: '8px' }}>
                        {saveProfileIcon}
                        <Typography variant="subtitle2"> Profile Settings </Typography>
                    </Box>
                    <ConfigureProfileSettings configuration={profile} onChange={changeProfile} />
                </Box>
            );
            break;
        case 'ChatGPT':
            configureElement = (
                <Box sx={sxSubTitleSection}>
                    <ChatGPTSettings />
                </Box>
            );
            break;
        case 'ResetPassword':
            configureElement = (
                <Box sx={sxSubTitleSection}>
                    <ChangePasswordForm configuration={profile}
                                        onChange={changeProfile}
                                        onClose={null}
                    />
                </Box>
            );
            break;
        default:
            configureElement = (
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="caption" component="div" sx={sxSubTitleSection}> {subTitleWelcome} </Typography>
                    <Typography variant="subtitle1" component="div" sx={sxSubTitleGroupedContent}> {subTitleMessage} </Typography>
                    <Divider />
                    <Typography variant="caption" component="div" sx={{...sxSubTitleSection, marginTop: '32px'}}>
                        Choose an option from the menu on the left.
                    </Typography>
                </Box>
            );
            break;
    }

    let subTitle: any = (
        <Box sx={sxSubSectionContainer}>
            <PaperContainer sx={sxSubSectionLogoContainer}>
                {logoDisplayElement}
            </PaperContainer>
        </Box>
    );

    return (
        <Box sx={{ padding: '8px', flexGrow: 1, width: { md: `calc(100% - ${udicci.standardMenuDrawerWidth}px)` } }}>
            <Box sx={introductionContainerSx}>
                {subTitle}
                <PaperContainer>
                    {configureElement}
                </PaperContainer>
            </Box>
        </Box>
    );
};
