import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/AddToQueue';

import { UdicciPluginComponent, UdicciPluginProps, udicciPluginComponentstate } from 'src/ui/udicci-ui-library';
import { subscribe as subscribeToListContext, unsubscribe as cancelListContextSubscription } from 'src/context/udicci-list-context';
import { useUdicciContext } from 'src/context/udicci-context'
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

const PluginDisplayComponent = dynamic(() => import('src/components/configure-profile-settings'));
const PluginAdminComponent = dynamic(() => import('src/admin/configure-profile-settings-admin'));

const PluginId: string = 'configure.profile.settings';
const PluginTitle: string = 'Configure Profile Settings';
const PluginDescription: string = 'Configure Profile Settings for a Portal.';
const PluginVersion: number = 1;

const PluginRenderer = (props: any) => {
    // console.log('%c PluginRenderer props: %O', 'color: red;', props);
    if (!props.data) props.data = {};
    props.data.showInline = true;
    // console.log('%c PluginRenderer props.data: %O', 'color: red;', props.data);
    return ( <ConfigureProfileSettingsPluginComponent {...props} /> );
};

const PluginAdminRenderer = (props: any) => {
    // console.log('%c PluginAdminRenderer props: %O', 'color: red;', props);
    if (!props.data) props.data = {};
    props.data.showInline = true;
    // console.log('%c PluginAdminRenderer props.data: %O', 'color: red;', props.data);
    return ( <PluginAdminComponent {...props} /> );
};

const configureProfileSettingsPlugin: UdicciPlugin = {
    Renderer: PluginRenderer,
    id: PluginId,
    title: PluginTitle,
    description: PluginDescription,
    version: PluginVersion,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: PluginAdminRenderer },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c configureProfileSettingsPlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c configureProfileSettingsPlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
    },
    category: UdicciPluginCategory.Admin
};
export default configureProfileSettingsPlugin;

const ConfigureProfileSettingsPluginComponent = (props: UdicciPluginProps) => {
    const udicciContext = useUdicciContext();
    // console.log('%c ConfigureProfileSettingsPluginComponent udicciContext: %O', 'color: magenta;', udicciContext);
    const udicciHelpers = useUdicciHelpers();
    return (<ConfigureProfileSettingsComponent udicciContext={udicciContext} udicciHelpers={udicciHelpers} {...props} />);
}

// ProfileSettingsComponent extends the UdicciPluginComponent
class ConfigureProfileSettingsComponent extends UdicciPluginComponent<UdicciPluginProps, udicciPluginComponentstate> {
    constructor(props: any, state: any) {
        super(props);
        // this.developerLog('%c ConfigureProfileSettingsComponent constructor props: %O', 'color: red;', props);
        // this.developerLog('%c ConfigureProfileSettingsComponent constructor state: %O', 'color: red;', state);

        let componentUID: string = 'udicci.list.' + this.props.udicciHelpers.generateUID();
        this.state = {
            uid: componentUID,
            CanView: false,
        };
    }

    componentDidUpdate(newProps: any, newState: any) {
        super.componentDidUpdate(newProps, newState);

        // this.developerLog('%c ConfigureProfileSettingsComponent componentDidUpdate newProps: %O', 'color: black;', newProps);
        // this.developerLog('%c ConfigureProfileSettingsComponent componentDidUpdate newState: %O', 'color: black;', newState);
        // this.developerLog('%c ConfigureProfileSettingsComponent componentDidUpdate this.state: %O', 'color: black;', this.state);
    }

    componentWillUnmount() {
        // this.developerLog('%c ConfigureProfileSettingsComponent componentWillUnmount this.state: %O', 'color: black;', this.state);
        if (this.state && this.state.uid) cancelListContextSubscription(this.state.uid);
    }

    componentDidMount() {
        // this.developerLog('%c ConfigureProfileSettingsComponent componentDidMount this.props: %O', 'color: black;', this.props);
        // this.developerLog('%c ConfigureProfileSettingsComponent componentDidMount this.state: %O', 'color: black;', this.state);
        // this.developerLog('%c ConfigureProfileSettingsComponent componentDidMount this.context: %O', 'color: black;', this.context);
        super.componentDidMount();

        var propData: any = (this.props && this.props.data ? this.props.data : null);
        // console.log('%c ConfigureProfileSettingsComponent propData: %O', 'color: maroon;', propData);
        // var pane: any = (this.props && this.props.pane ? this.props.pane : null);
        // console.log('%c ConfigureProfileSettingsComponent pane: %O', 'color: maroon;', pane);
        var listSettings: any = (propData && propData.listSettings ? propData.listSettings : null);
        // console.log('%c ConfigureProfileSettingsComponent listSettings: %O', 'color: maroon;', listSettings);
        var socialSolution: any = (listSettings && listSettings.socialSolution ? listSettings.socialSolution : null);
        // console.log('%c ConfigureProfileSettingsComponent socialSolution: %O', 'color: maroon;', socialSolution);
        // var feature: any = (listSettings && listSettings.feature ? listSettings.feature : null);
        // console.log('%c ConfigureProfileSettingsComponent feature: %O', 'color: maroon;', feature);
        var mediator: any = (listSettings && listSettings.mediator ? listSettings.mediator : null);
        // console.log('%c ConfigureProfileSettingsComponent mediator: %O', 'color: maroon;', mediator);

        let subscriptionNotes: any = {
            mediator: {
                id: (mediator && mediator.id ? mediator.id : 0),
                name: (mediator && mediator.name ? mediator.name : '')
            },
            socialSolution: {
                id: (socialSolution && socialSolution.id ? socialSolution.id : 0),
                name: (socialSolution && socialSolution.name ? socialSolution.name : '')
            }
        };
        subscribeToListContext(this.state.uid, this.contextUpdated.bind(this), subscriptionNotes);

        if (this.state && this.state.isLoaded) {
            this.setState({ CanView: true });
        }
    }

    contextUpdated(state: any, payload: any) {
        // console.log('%c ConfigureProfileSettingsComponent contextUpdated state: %O', 'color: hotpink;', state);
        // console.log('%c ConfigureProfileSettingsComponent contextUpdated payload: %O', 'color: hotpink;', payload);
        // let updateData: any = (state && state.data ? state.data : null);
        // console.log('%c ConfigureProfileSettingsComponent contextUpdated updateData: %O', 'color: hotpink;', updateData);
        // setTimeout(() => { setRefreshingData(false); }, 1000);
        // this.setState({ CanView: true });
        this.forceUpdate();
    };

    render() {
        this.developerLog('%c ConfigureProfileSettingsComponent render this.props: %O', 'color: black;', this.props);
        this.developerLog('%c ConfigureProfileSettingsComponent render this.state: %O', 'color: black;', this.state);

        return (<PluginDisplayComponent {...this.props} uid={this.state.uid} />);
    }
}
