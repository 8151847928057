import React, { Fragment, createContext, useReducer, useCallback, useContext } from 'react';

import { udicciRecordsReducer, UdicciActions, UdicciReducer, Types } from './udicci-reducers';
import { ContextSubscriber, UdicciPorta, Mediator } from 'src/classes/udicci-types';

import { UdicciRecord, UdicciPermissions } from 'src/classes/udicci-record';
import { Udicci } from 'src/classes/udicci-factory';
import { UdicciRequestBase } from 'src/interfaces/udicci-request-interfaces';
import { UdicciContextDataType } from 'src/interfaces/udicci-context-interfaces';

import { filter, forEach, findIndex, find } from 'underscore';

const classLogStyle = 'color: #ef8c26; font-weight: bold;'

export const createMarkup = (markupContent: string) => {
    return {__html: markupContent};
}

export interface keyable {
    [key: string]: any  
}

const FactoryResponseHandler = (request: any, response: any, settings: any, state: any, dispatch: any) => {
    var umn: string = (request && request.UdicciMediatorName ? request.UdicciMediatorName : '-- unknown mediator --');
    var cmd: string = (request && request.UdicciCommand ? request.UdicciCommand : '-- unknown command --');
    // console.log('%c Udicci FactoryResponseHandler request (Mediator: %s - Command %s): %O', classLogStyle, umn, cmd, request);
    // console.log('%c Udicci FactoryResponseHandler settings: %O', classLogStyle, settings);
    // console.log('%c Udicci FactoryResponseHandler response: %O', classLogStyle, response);

    var { data, udicci } = state;
    // console.log('%c Udicci data: %O', classLogStyle, data);
    // console.log('%c Udicci udicci: %O', classLogStyle, udicci);

    var clientResultData: any = null;
    if (request && request.udicciMediator && request.recordId) {
        // this is a record being passed through as an update ... make sure it gets updated in context
        var md = data && data.find(function(med: Mediator) { return med.mediator === request.udicciMediator });
        // console.log('%c md: %O', classLogStyle, md);

        if (md && md.length > 0) {
            var recs = (md[0].records ? md[0].records : []);
            if (!recs) recs = [];
            var foundRecord: boolean = false;
            var record: UdicciRecord = request;
            var recordId: number = record.recordId;
            var udicciMediator: string = record.udicciMediator;
            recs.forEach((rec: any, recordIndex: number) => {
                // console.log('%c rec: %O', classLogStyle, rec);
                if (rec.udicciMediator === udicciMediator && rec.recordId === recordId) {
                    foundRecord = true;

                    // instead of just assigning the record, we will get a little more detailed in this part.
                    // found out I was overwriting record.data and sometimes there is important data on the record.data that is lost.
                    // so now, we are going to update everything except for .data, and we will use Object.assign to set .data with a little more grace.
                    // recs[recordIndex] = record;

                    recs[recordIndex].udicciMediator = record.udicciMediator;
                    recs[recordIndex].recordId = record.recordId;
                    recs[recordIndex].title = record.title;
                    recs[recordIndex].description = record.description;
                    recs[recordIndex].keys = record.keys;

                    recs[recordIndex].context = record.context;
                    recs[recordIndex].attachments = record.attachments;
                    recs[recordIndex].perspectives = record.perspectives;

                    var updatedData: any = {};
                    if (recs[recordIndex].data) Object.assign(updatedData, recs[recordIndex].data);
                    if (record.data) Object.assign(updatedData, record.data);
                    recs[recordIndex].data = updatedData;

                    recs[recordIndex].isDirty = false;
                    recs[recordIndex].isSaving = false;
                }
            });
            // console.log('%c foundRecord: %O', classLogStyle, foundRecord);
            if (!foundRecord) recs.push(record);
            md[0].records = recs;
            md[0].lastResult = new Date();
        }
    } else if (request && request === 'udicci.getInstructionSet') {
        // var instructionSetLogs: any = response;
        // console.log('%c Udicci FactoryResponseHandler udicci: %O', classLogStyle, udicci);
        // console.log('%c Udicci FactoryResponseHandler instructionSetLogs: %O', classLogStyle, instructionSetLogs);
    } else if (request && (request.UdicciCommand === 'Get Porta Focus Board' || request.UdicciCommand === 'Save Porta Focus Board')) {
        // var instructionSetLogs: any = response;
        // console.log('%c Udicci FactoryResponseHandler udicci: %O', classLogStyle, udicci);
        // console.log('%c Udicci FactoryResponseHandler instructionSetLogs: %O', classLogStyle, instructionSetLogs);
        // console.log('%c Udicci FactoryResponseHandler request (Mediator: %s - Command %s): %O', classLogStyle, umn, cmd, request);
        // console.log('%c Udicci FactoryResponseHandler settings: %O', classLogStyle, settings);
        // console.log('%c Udicci FactoryResponseHandler response: %O', classLogStyle, response);
        if (typeof(response.ResponseJson) === 'string') {
            try {
                response.ResponseJson = JSON.parse(response.ResponseJson);
            } catch (ex: any) {
                
            }
        }

        clientResultData = response.ResponseJson;
    } else if (request && request.UdicciCommand === 'Get Udicci Mediator Structures') {
        var udicciMediatorObjects = (response && response.UdicciMediatorObjects ? response.UdicciMediatorObjects : []);
        // console.log('%c Get Udicci Mediator Structures udicciMediatorObjects: %O', classLogStyle, udicciMediatorObjects);

        udicciMediatorObjects.forEach((structure: any) => {
            // console.log('%c structure: %O', classLogStyle, structure);
            // console.log('%c request: %O', classLogStyle, request);
            // console.log('%c data: %O', classLogStyle, data);

            var mediatorName: string = structure.Name;
            var mediatorContext = data.find((x: any) => x.mediator === mediatorName );
            // console.log('%c mediatorContext: %O', classLogStyle, mediatorContext);
            if (!mediatorContext) {
                var newMedRecs: Mediator = {
                    mediator: mediatorName,
                    records: [],
                    structure: structure,
                    permissions: null,
                    linkedData: null,
                    lastResult: null
                };

                // console.log('%c newMedRecs: %O', classLogStyle, newMedRecs);
                data.push(newMedRecs);
            }
        });
        // console.log('%c Udicci FactoryResponseHandler instructionSetLogs: %O', classLogStyle, instructionSetLogs);
    } else if (request) {
        var mediatorData = data && data.find(function(med: Mediator) { return med.mediator === request.UdicciMediatorName });
        // console.log('%c mediatorData: %O', classLogStyle, mediatorData);

        var structures = (response && response.structures ? response.structures : {});
        // console.log('%c structures: %O', classLogStyle, structures);

        var socialSolutions = (response && response.socialSolutions ? response.socialSolutions : null);
        // console.log('%c socialSolutions: %O', classLogStyle, socialSolutions);
        if (socialSolutions && socialSolutions.length > 0) {
            var socialSolutionsStructure: any = null;
            if (structures && structures['Social Solutions']) socialSolutionsStructure = structures['Social Solutions'];
            // console.log('%c socialSolutionsStructure: %O', classLogStyle, socialSolutionsStructure);

            socialSolutions.forEach((ss: any) => {
                // console.log('%c ss: %O', classLogStyle, ss);
                var ssRecord = new UdicciRecord('Social Solutions', ss, socialSolutionsStructure);
                // console.log('%c ssRecord: %O', classLogStyle, ssRecord);
                data = processRecord(ssRecord, data, socialSolutionsStructure, null, null);
            });
        }

        var udicciMediatorStructure = (response && response.UdicciMediatorStructure ? response.UdicciMediatorStructure : '');
        // console.log('%c udicciMediatorStructure: %O', classLogStyle, udicciMediatorStructure);

        var permissions = (response && response.permissions ? response.permissions : null);
        if (!permissions && response && response.Permissions) {
            permissions = response.Permissions;
        }
        // console.log('%c permissions: %O', classLogStyle, permissions);

        var linkedData: any = (response && response.linkedData ? response.linkedData : null);
        // console.log('%c linkedData: %O', classLogStyle, linkedData);
        if (response && response.LinkedData) {
            linkedData = response.LinkedData;
            // console.log('%c linkedData: %O', classLogStyle, linkedData);
        }

        let readyToGo: boolean = true;
        // first we need to update the linked data
        // console.log('%c linkedData: %O', classLogStyle, linkedData);
        if (readyToGo && linkedData && linkedData.length > 0) {
            forEach(linkedData, (lud: any, idxLud: number) => {
                // console.log('%c lud: %O', classLogStyle, lud);
                let leftMediator: string = (lud.LeftUdicciMediatorName ? lud.LeftUdicciMediatorName : '');
                // console.log('%c leftMediator: %O', classLogStyle, leftMediator);
                let rightMediator: string = (lud.RightUdicciMediatorName ? lud.RightUdicciMediatorName : '');
                // console.log('%c rightMediator: %O', classLogStyle, rightMediator);
                let ludData: any = (lud.Data ? lud.Data : []);
                // console.log('%c ludData: %O', classLogStyle, ludData);

                let leftIndex: number = findIndex(data, (med: Mediator) => { return med.mediator === leftMediator });
                // console.log('%c leftIndex: %O', classLogStyle, leftIndex);
                if (leftIndex >= 0) {
                    let leftMediatorData: any = (leftIndex >= 0 ? data[leftIndex] : null);
                    // console.log('%c leftMediatorData: %O', classLogStyle, leftMediatorData);
                    let leftLinkedData: any[] = (leftMediatorData && leftMediatorData.linkedData ? leftMediatorData.linkedData : []);
                    // console.log('%c leftLinkedData: %O', classLogStyle, leftLinkedData);

                    let leftLudIndex: number = findIndex(leftLinkedData, (lum: any) => {
                        return lum.LeftUdicciMediatorName === leftMediator && lum.RightUdicciMediatorName === rightMediator
                    });
                    // console.log('%c leftLudIndex: %O', classLogStyle, leftLudIndex);
                    let leftLud: any = (leftLudIndex >= 0 ? leftLinkedData[leftLudIndex] : null);
                    // console.log('%c leftLud: %O', classLogStyle, leftLud);

                    if (!leftLud) {
                        leftLud = lud;
                        leftLinkedData.push(leftLud);
                    } else {
                        let leftLudData: any = (leftLud && leftLud.Data ? leftLud.Data : null);
                        // console.log('%c leftLudData: %O', classLogStyle, leftLudData);

                        if (!leftLudData) {
                            leftLudData = ludData;
                        } else {
                            forEach(ludData, (ld: any, idx: number) => {
                                // console.log('%c ld: %O', classLogStyle, ld);
                                let leftId: string = (ld.LeftId ? ld.LeftId : 0);
                                // console.log('%c leftId: %O', classLogStyle, leftId);
                                let rightId: string = (ld.RightId ? ld.RightId : 0);
                                // console.log('%c rightId: %O', classLogStyle, rightId);
                                let ludData: any = (ld.Data ? ld.Data : []);
                                // console.log('%c ludData: %O', classLogStyle, ludData);
                                let chk: any = find(leftLudData, (rld: any) => {
                                    return rld.LeftId === leftId && rld.RightId === rightId
                                });
                                // console.log('%c chk: %O', classLogStyle, chk);
                                if (!chk) leftLudData.push(ld);
                            });
                        }

                        leftLud.Data = leftLudData;
                        leftLinkedData[leftLudIndex] = leftLud;
                    }

                    if (!leftMediatorData) {
                        leftMediatorData = {
                            lastResult: null,
                            linkedData: leftLinkedData,
                            mediator: leftMediator,
                            permissions: null,
                            records: null,
                            structure: null
                        };
                        data.push(leftMediatorData);
                    } else {
                        leftMediatorData.linkedData = leftLinkedData;
                        data[leftIndex] = leftMediatorData;
                    }
                }

                let rightIndex: number = findIndex(data, (med: Mediator) => { return med.mediator === rightMediator });
                // console.log('%c rightIndex: %O', classLogStyle, rightIndex);
                if (rightIndex >= 0) {
                    let rightMediatorData: Mediator | null = (rightIndex >= 0 ? data[rightIndex] : null);
                    // console.log('%c rightMediatorData: %O', classLogStyle, rightMediatorData);
                    let rightLinkedData: any[] = (rightMediatorData && rightMediatorData.linkedData ? rightMediatorData.linkedData : []);
                    // console.log('%c rightLinkedData: %O', classLogStyle, rightLinkedData);

                    let rightLudIndex: number = findIndex(rightLinkedData, (lum: any) => {
                        return lum.LeftUdicciMediatorName === leftMediator && lum.RightUdicciMediatorName === rightMediator
                    });
                    // console.log('%c rightLudIndex: %O', classLogStyle, rightLudIndex);
                    let rightLud: any = (rightLudIndex >= 0 ? rightLinkedData[rightLudIndex] : null);
                    // console.log('%c rightLud: %O', classLogStyle, rightLud);

                    if (!rightLud) {
                        rightLud = lud;
                        rightLinkedData.push(rightLud);
                    } else {
                        let rightLudData: any = (rightLud && rightLud.Data ? rightLud.Data : null);
                        // console.log('%c rightLudData: %O', classLogStyle, rightLudData);

                        if (!rightLudData) {
                            rightLudData = ludData;
                        } else {
                            forEach(ludData, (ld: any, idx: number) => {
                                // console.log('%c ld: %O', classLogStyle, ld);
                                let leftId: string = (ld.LeftId ? ld.LeftId : 0);
                                // console.log('%c leftId: %O', classLogStyle, leftId);
                                let rightId: string = (ld.RightId ? ld.RightId : 0);
                                // console.log('%c rightId: %O', classLogStyle, rightId);
                                let ludData: any = (ld.Data ? ld.Data : []);
                                // console.log('%c ludData: %O', classLogStyle, ludData);
                                let chk: any = find(rightLudData, (rld: any) => {
                                    return rld.LeftId === leftId && rld.RightId === rightId
                                });
                                // console.log('%c chk: %O', classLogStyle, chk);
                                if (!chk) rightLudData.push(ld);
                            });
                        }

                        rightLud.Data = rightLudData;
                        rightLinkedData[rightLudIndex] = rightLud;
                    }

                    if (!rightMediatorData) {
                        rightMediatorData = {
                            lastResult: null,
                            linkedData: rightLinkedData,
                            mediator: rightMediator,
                            permissions: null,
                            records: null,
                            structure: null
                        };
                        data.push(rightMediatorData);
                    } else {
                        rightMediatorData.linkedData = rightLinkedData;
                        data[rightIndex] = rightMediatorData;
                    }
                }
            });
        }

        if (structures !== null) {
            for (var [mediatorName, structure] of Object.entries<any>(structures)) {
                // console.log('%c mediatorName: %O', classLogStyle, mediatorName);
                // console.log('%c structure: %O', classLogStyle, structure);
                // console.log('%c request: %O', classLogStyle, request);
                // console.log('%c data: %O', classLogStyle, data);

                let structureName: string = (structure.Name ? structure.Name : '');
                var mediatorContext: Mediator | undefined = data.find((x: any) => x.mediator === structureName );
                // console.log('%c mediatorContext: %O', classLogStyle, mediatorContext);

                if (!mediatorContext && structure.Name !== request.UdicciMediatorName) {
                    var newMedRecs: Mediator = {
                        mediator: structure.Name,
                        records: [],
                        structure: structure,
                        permissions: permissions,
                        linkedData: linkedData,
                        lastResult: null
                    };

                    // console.log('%c newMedRecs: %O', classLogStyle, newMedRecs);
                    data.push(newMedRecs);
                }

                if (permissions && permissions[mediatorName]) {
                    // console.log('%c data (1): %O', classLogStyle, data);
                    for (var [idx, medContxt] of Object.entries<any>(data)) {
                        // console.log('%c medContxt: %O', 'color: hotpink;', medContxt);
                        if (medContxt.mediator === mediatorName) {
                            medContxt.permissions = permissions[mediatorName];
                            data[idx] = medContxt;
                        }
                    }
                    // console.log('%c data (2): %O', classLogStyle, data);
                }
            }
        }

        if (request.UdicciCommand === 'Get Profile By Url') {
            var profile = (response && response.profile ? response.profile : null);
            // console.log('%c profile: %O', 'color: red;', profile);

            var profileMediatorStructure: any = null;
            if (structures && structures[request.UdicciMediatorName]) profileMediatorStructure = structures[request.UdicciMediatorName];
            // console.log('%c profileMediatorStructure: %O', classLogStyle, profileMediatorStructure);

            var profileRecord = new UdicciRecord(request.UdicciMediatorName, profile, profileMediatorStructure);
            // console.log('%c profileRecord: %O', classLogStyle, profileRecord);
            data = processRecord(profileRecord, data, profileMediatorStructure, null, null);
            // console.log('%c Udicci data: %O', classLogStyle, data);
            clientResultData = data;
        } else if (request.UdicciCommand === 'Get Record Context') {
            if (typeof(response.ResponseJson) === 'string') {
                try {
                    response.ResponseJson = JSON.parse(response.ResponseJson);
                } catch (ex: any) {
                    
                }
            }

            var structuresToReturn: any[] = [];
            var recordsObject: any = {};
            if (response.ResponseJson) {
                for (let [recordId, record] of Object.entries<any>(response.ResponseJson)) {
                    // console.log('%c recordId: %O', classLogStyle, recordId);
                    // console.log('%c record: %O', classLogStyle, record);
                    if (typeof(record) === 'string') {
                        try {
                            record = JSON.parse(record);
                        } catch (ex: any) {
                            
                        }
                    }
                    var rec: any = (record.record ? record.record : null);
                    if (typeof(rec) === 'string') {
                        try {
                            rec = JSON.parse(rec);
                            record.record = rec;
                        } catch (ex: any) {
                            
                        }
                    }
                    // console.log('%c Udicci FactoryResponseHandler record: %O', classLogStyle, record);

                    let recMediator: any = (record.mediator ? record.mediator : '');
                    var medData = data && data.find(function(med: Mediator) {
                        return med.mediator === recMediator
                    });
                    // console.log('%c medData: %O', classLogStyle, medData);

                    var recStructure: any = (medData && medData.structure ? medData.structure : null);
                    // console.log('%c recStructure: %O', classLogStyle, recStructure);
                    if (!recStructure && structures && structures[recMediator]) {
                        recStructure = structures[recMediator];
                        // console.log('%c recStructure (try again): %O', classLogStyle, recStructure);
                    }
                    if (recStructure && structuresToReturn) {
                        var checkStructure3 = structuresToReturn.find(function(s: any) {
                            return s.Name === recMediator
                        });
                        if (!checkStructure3) structuresToReturn.push(recStructure);
                    }

                    var recPermissions: any = (medData && medData.permissions ? medData.permissions : null);
                    // console.log('%c recPermissions: %O', classLogStyle, recPermissions);

                    var updateRecord = new UdicciRecord(recMediator, record.record, recStructure, recPermissions);
                    // console.log('%c Udicci FactoryResponseHandler updateRecord: %O', classLogStyle, updateRecord);

                    record.record = updateRecord;
                    var updatedRecord: any = {};
                    Object.assign(updatedRecord, record);
                    // console.log('%c Udicci FactoryResponseHandler updateRecord: %O', classLogStyle, updateRecord);

                    data = processRecord(updateRecord, data, recStructure, recPermissions, null);
                    // if (!record) record = {};

                    recordsObject[recordId] = updatedRecord;
                }
            }

            var feature: any = udicci.selectedFeature;
            // console.log('%c Udicci feature: %O', classLogStyle, feature);
            var featureMediators: any = (feature && feature.Mediators ? feature.Mediators : []);
            // console.log('%c Udicci featureMediators: %O', classLogStyle, featureMediators);
            if (structures) {
                for (let [mednm, strctr] of Object.entries<any>(structures)) {
                    // console.log('%c mednm: %O', classLogStyle, mednm);
                    // console.log('%c structure: %O', classLogStyle, structure);
                    var checkFeatureMediator = featureMediators.find(function(m: any) {
                        return m.name === mednm
                    });
                    if (checkFeatureMediator) {
                        var checkStructure2 = structuresToReturn.find(function(s: any) {
                            return s.Name === mednm
                        });
                        if (!checkStructure2) structuresToReturn.push(strctr);
                    }
                }
            }

            clientResultData = {
                structures: structuresToReturn,
                records: recordsObject
            };
            // console.log('%c clientResultData: %O', 'color: red;', clientResultData);
        } else {
            var mediatorStructure: any = null;
            if (structures && structures[request.UdicciMediatorName]) mediatorStructure = structures[request.UdicciMediatorName];
            // console.log('%c mediatorStructure: %O', classLogStyle, mediatorStructure);
            if (!mediatorStructure && mediatorData && mediatorData.structure) {
                // check the current data for structure if it wasn't on the response
                mediatorStructure = mediatorData.structure;
                // console.log('%c mediatorStructure: %O', classLogStyle, mediatorStructure);
            } else if (!mediatorStructure && udicciMediatorStructure) {
                // console.log('%c udicciMediatorStructure: %O', classLogStyle, udicciMediatorStructure);
                if (typeof(udicciMediatorStructure) !== 'object') {
                    try {
                        udicciMediatorStructure = JSON.parse(udicciMediatorStructure);
                        // console.log('%c udicciMediatorStructure: %O', classLogStyle, udicciMediatorStructure);
                        if (udicciMediatorStructure) {
                            structures[request.UdicciMediatorName] = udicciMediatorStructure;
                            mediatorStructure = udicciMediatorStructure;
                        }
                    } catch (ex: any) {
                        mediatorStructure = null;
                    }
                }
            }
            // console.log('%c mediatorStructure: %O', classLogStyle, mediatorStructure);
            // console.log('%c structures: %O', classLogStyle, structures);

            var hasData = false;
            var recordsToReturn: UdicciRecord[] = [];
            if (response && response.data) {
                if (response.data.length > 0) {
                    response.data.forEach((rec: any) => {
                        // console.log('%c rec: %O', classLogStyle, rec);
                        var record = new UdicciRecord(request.UdicciMediatorName, rec, mediatorStructure, permissions);
                        // console.log('%c record: %O', classLogStyle, record);

                        if (request.UdicciCommand === 'Save Record') {
                            // console.log('%c request: %O', classLogStyle, request);
                            if (request.UdicciMediatorName === 'Udicci Profiles') {
                                // console.log('%c request: %O', classLogStyle, request);
                                if (udicci.selectedProfile && udicci.selectedProfile.recordId) {
                                    if (record.recordId === udicci.selectedProfile.recordId) {
                                        var profileRec: UdicciRecord = udicci.selectedProfile;
                                        // console.log('%c profileRec: %O', classLogStyle, profileRec);
                                        // console.log('%c record: %O', classLogStyle, record);

                                        record.data.CoreFieldNames = profileRec.data.CoreFieldNames;
                                        record.data.ProfilePorta = profileRec.data.ProfilePorta;
                                        record.data.ProfileType = profileRec.data.ProfileType;
                                        record.data.PublicRole = profileRec.data.PublicRole;
                                        record.data.SocialSolutions = profileRec.data.SocialSolutions;
                                        record.data.UdicciUserRole = profileRec.data.UdicciUserRole;

                                        // console.log('%c record: %O', classLogStyle, record);
                                        udicci.selectedProfile = record;
                                        // console.log('%c udicci.selectedProfile: %O', classLogStyle, udicci.selectedProfile);
                                    }
                                }
                            } else {
                                var recUpdateMediation: any = (response.mediatedRecords ? response.mediatedRecords : null);
                                // console.log('%c recUpdateMediation: %O', classLogStyle, recUpdateMediation);

                                var msRecId: number = (recUpdateMediation.recordId ? recUpdateMediation.recordId : 0);
                                // console.log('%c msRecId: %O', classLogStyle, msRecId);
                                var msMediator: string = (recUpdateMediation.udicciMediator ? recUpdateMediation.udicciMediator : '');
                                // console.log('%c msMediator: %O', classLogStyle, msMediator);
                                var mediatedRecords: any = (recUpdateMediation && recUpdateMediation.mediatedRecords ? recUpdateMediation.mediatedRecords : null);
                                // console.log('%c mediatedRecords: %O', classLogStyle, mediatedRecords);

                                if (msRecId && msMediator && mediatedRecords) {
                                    var mc = data.filter(function(x: any) { return x.mediator === msMediator });
                                    // console.log('%c mc: %O', classLogStyle, mc);
                                    var mcrecords: any = (mc && mc.length > 0 && mc[0].records && mc[0].records.length > 0 ? mc[0].records : []);
                                    // console.log('%c mcrecords: %O', classLogStyle, mcrecords);
                                    var mediatorData = mcrecords.filter(function(rec: UdicciRecord) { return rec.udicciMediator === msMediator && rec.recordId === msRecId });
                                    // console.log('%c mediatorData: %O', classLogStyle, mediatorData);
                                    if (mediatorData && mediatorData.length > 0) {
                                        mediatorData[0].data.MediatedRecords = mediatedRecords;
                                    }
                                    // console.log('%c mcrecords: %O', classLogStyle, mcrecords);
                                    // console.log('%c mc: %O', classLogStyle, mc);
                                }
                                // console.log('%c data: %O', classLogStyle, data);
                            }
                        }

                        data = processRecord(record, data, mediatorStructure, permissions, linkedData);
                        recordsToReturn.push(record);

                        hasData = true;
                    });
                }
                clientResultData = recordsToReturn;
            } else if (response && response.ResponseJson) {
                if (typeof(response.ResponseJson) === 'string') {
                    try {
                        response.ResponseJson = JSON.parse(response.ResponseJson);
                    } catch (ex: any) {
                        
                    }
                }

                if (request.UdicciCommand === 'Get Porta Focus Board') {            
                    clientResultData = response.ResponseJson;
                } else {
                    if (response.ResponseJson.length > 0) {
                        response.ResponseJson.forEach((rec: any) => {
                            // console.log('%c rec: %O', classLogStyle, rec);
                            var record = new UdicciRecord(request.UdicciMediatorName, rec, mediatorStructure, permissions);
                            // console.log('%c record: %O', classLogStyle, record);
                            data = processRecord(record, data, mediatorStructure, permissions, linkedData);
                            recordsToReturn.push(record);
                            hasData = true;
                        });
                    }

                    clientResultData = recordsToReturn;
                }

                // if (request.UdicciCommand === 'Get Record Perspectives') {            
                // }
            } else if (response && response.success === true && response.UdicciCommand === 'Delete Record') {
                data = removeRecord(response.UdicciMediatorName, response.UdicciRecordId, data);

                hasData = true;
            }
            // console.log('%c hasData: %O', classLogStyle, hasData);

            if (!hasData) {
                if (!mediatorData || mediatorData.length <= 0) {
                    var newMedRecs2: Mediator = {
                        mediator: request.UdicciMediatorName,
                        records: [],
                        structure: mediatorStructure,
                        permissions: permissions,
                        linkedData: linkedData,
                        lastResult: null
                    };

                    // console.log('%c newMedRecs2: %O', classLogStyle, newMedRecs2);
                    data.push(newMedRecs2);
                }
            } else if (request.UdicciCommand === 'Fetch List' && request.UdicciMediatorName === 'Udicci Profile Types') {
                // we put them directly on the factory as well.
                udicci.profileTypes = clientResultData;
            }
        }
        // console.log('%c data: %O', classLogStyle, data);
    }

    // console.log('%c FactoryResponseHandler data: %O', classLogStyle, data);
    // console.log('%c FactoryResponseHandler udicci: %O', classLogStyle, udicci);
    // console.log('%c FactoryResponseHandler clientResultData: %O', classLogStyle, clientResultData);
    if (!clientResultData) clientResultData = response;

    dispatch({ type: 'REFRESH', data: data, udicci: udicci, request: request, response: response });

    if (request && request.onSuccess) {
        request.onSuccess(clientResultData, request, settings);
    } else if (settings && settings.onSuccess) {
        settings.onSuccess(clientResultData, request, settings);
    } else {
        notifySubscribers(clientResultData, request, settings);
    }
}

export const udicciFactory = new Udicci();
const chainAddress1: String = 'mv8LP3F8cfWB2fC891xWTjY5J9eXw7Whug';
const chainAddress2: String = 'n3bFd9wgHKHoK3NVRYob4KaPCsEzJhhsDm';

udicciFactory.getBlockChainGetAddressInfo(chainAddress1, 'test');
udicciFactory.getBlockChainGetAddressBalance(chainAddress1, 'test');

udicciFactory.getBlockChainGetAddressInfo(chainAddress2, 'test');
udicciFactory.getBlockChainGetAddressBalance(chainAddress2, 'test');

const initialState = { data: [], udicci: udicciFactory }

export const mainUdicciReducer = ({ data, udicci }: UdicciContextDataType, action: any) => {
    // console.log('%c mainUdicciReducer data: %O', classLogStyle, data);
    // console.log('%c mainUdicciReducer udicci: %O', classLogStyle, udicci);
    // console.log('%c mainUdicciReducer action: %O', classLogStyle, action);

    var request: any = (action && action.request ? action.request : null);
    var response: any = (action && action.response ? action.response : null);

    var dataUpdate: any = udicciRecordsReducer(data, action, udicci);
    var rval: any = {
        data: dataUpdate,
        udicci: udicci
    };
    // console.log('%c mainUdicciReducer rval: %O', classLogStyle, rval);
    notifySubscribers(dataUpdate, request, response);
    return rval;
};

const UdicciContext = createContext<{
    state: UdicciContextDataType;
    dispatch: React.Dispatch<UdicciActions>;
}>({
    state: initialState,
    dispatch: () => null
});

var subscribers: ContextSubscriber[] = [];

const notifySubscribers = (arg1: any = null, arg2: any = null, arg3: any = null) => {
    // console.log('%c UdicciContext notifySubscribers (arg1: %O, arg2: %O, arg3: %O)', classLogStyle, arg1, arg2, arg3);
    subscribers.forEach((sub: ContextSubscriber, idx: number) => {
        // console.log('%c UdicciContext sub: %O', classLogStyle, sub);
        var instructions = sub.instructions;
        // console.log('%c UdicciContext notifySubscribers instructions: %O', 'color: red; font-weight: bold;', instructions);
        var notifySubscriber: boolean = true;
        if (instructions) {
            // console.log('%c UdicciContext notifySubscribers arg1: %O', 'color: red; font-weight: bold;', arg1);
            // console.log('%c UdicciContext notifySubscribers arg2: %O', 'color: red; font-weight: bold;', arg2);
            // console.log('%c UdicciContext notifySubscribers arg3: %O', 'color: red; font-weight: bold;', arg3);
            // console.log('%c UdicciContext notifySubscribers sub: %O', 'color: red; font-weight: bold;', sub);
            notifySubscriber = false;
            // arg1 === data
            // arg2 === request
            // arg3 === response (parsed response from server)
            if (arg2) {
                // check instructions now
                var udicciCommand: string = (arg2 && arg2.UdicciCommand ? arg2.UdicciCommand : '');
                var udicciMediatorName: string = (arg2 && arg2.UdicciMediatorName ? arg2.UdicciMediatorName : '');
                var recordId: string = (arg2 && arg2.UdicciRecordId ? arg2.UdicciRecordId : 0);
                var matchesInstruction: boolean = true;
                if (instructions.udicciCommand && instructions.udicciCommand !== udicciCommand) matchesInstruction = false;
                if (instructions.udicciMediator && instructions.udicciMediator !== udicciMediatorName) matchesInstruction = false;
                if (instructions.recordId && instructions.recordId !== recordId) matchesInstruction = false;
                if (matchesInstruction) notifySubscriber = true;
            }
        }
        // console.log('%c UdicciContext notifySubscribers notifySubscriber: %O', 'color: red; font-weight: bold;', notifySubscriber);

        if (notifySubscriber) {
            var cb = sub.callback;
            if (cb) cb(arg1, arg2, arg3);
        }
    });
}

export const subscribe = (subcriberId: string, cbFunc: Function, instructions: any | null = null) => {
    var foundSub = false;
    subscribers.forEach((sub: ContextSubscriber, idx: number) => {
        // console.log('%c sub: %O', 'color: blue;', sub);
        if (sub.subscriber === subcriberId) {
            foundSub = true;
            subscribers[idx].callback = cbFunc;
            if (instructions) subscribers[idx].instructions = instructions;
        }
    });

    if (!foundSub) {
        var newSub: ContextSubscriber = { subscriber: subcriberId, callback: cbFunc }
        subscribers.push(newSub)
    }
}

export const unsubscribe = (subcriberId: string) => {
    var newSubscriberList: ContextSubscriber[] = [];
    subscribers.forEach((sub: ContextSubscriber, idx: number) => {
        // console.log('%c sub: %O', 'color: blue;', sub);
        if (sub.subscriber !== subcriberId) {
            newSubscriberList.push(sub);
        }
    });
    subscribers = newSubscriberList;
}

const UdicciProvider: React.FC<any> = ({ children }) => {
    const [state, dispatch] = useReducer(mainUdicciReducer, initialState);
    // console.log('%c UdicciProvider state: %O', classLogStyle, state);
    // console.log('%c UdicciProvider dispatch: %O', classLogStyle, dispatch);

    const updateHandler = useCallback( (request: any, response: any, settings: any) => {
        // console.log('%c UdicciProvider updateHandler request: %O', classLogStyle, request);
        // console.log('%c UdicciProvider updateHandler settings: %O', classLogStyle, settings);
        // console.log('%c UdicciProvider updateHandler response: %O', classLogStyle, response);
        FactoryResponseHandler(request, response, settings, state, dispatch);
    }, [state]);

    udicciFactory.subscribe('udicci.context', updateHandler);

    return (
      <UdicciContext.Provider value={{state, dispatch}}>
        {children}
      </UdicciContext.Provider>
    )
}

export const processRecord = (record: UdicciRecord, data: Mediator[], structure: any, permissions: any | null, linkedData: any | null) => {
    // console.log('%c Process Record record: %O', classLogStyle, record);
    // console.log('%c Process Record data: %O', classLogStyle, data);
    // console.log('%c Process Record structure: %O', classLogStyle, structure);
    // console.log('%c Process Record permissions: %O', classLogStyle, permissions);
    // console.log('%c Process Record linkedData: %O', classLogStyle, linkedData);

    var { udicciMediator, recordId, isDirty, isSaving } = record;
    // console.log('%c Process Record recordId: %O', classLogStyle, recordId);
    // console.log('%c Process Record udicciMediator: %O', classLogStyle, udicciMediator);

    if (isDirty) record.isDirty = false;
    if (isSaving) record.isSaving = false;

    var recPermissions: UdicciPermissions | null = null;
    if (permissions && record) {
        recPermissions = {
            CanView: permissions.CanView,
            CanAdd: permissions.CanAdd,
            CanEdit: permissions.CanEdit,
            CanDelete: permissions.CanDelete,
            CanDuplicate: permissions.CanDuplicate,
            CanProvision: permissions.CanProvision
        };
        // if (permissions.CanEditIfOwner && !recPermissions.CanEdit) {
        //     if (record.data.CreatedByUserId === userId) recPermissions.CanEdit = true;
        // }
        // if (permissions.CanDeleteIfOwner && !recPermissions.CanDelete) {
        //     if (record.data.CreatedByUserId === userId) recPermissions.CanDelete = true;
        // }
        // console.log('%c Udicci Process Record recPermissions: %O', classLogStyle, recPermissions);
        record.permissions = recPermissions;
    }

    if (data && data.length > 0) {
        let mediatorDataIndex: number = findIndex(data, (med: Mediator) => { return med.mediator === udicciMediator });
        // console.log('%c mediatorDataIndex: %O', classLogStyle, mediatorDataIndex);
        var mediatorData: Mediator[] = data.filter(function(med: Mediator) { return med.mediator === udicciMediator });
        // console.log('%c mediatorData: %O', classLogStyle, mediatorData);
        if (mediatorData && mediatorData.length > 0) {
            // console.log('%c mediatorData: %O', classLogStyle, mediatorData);
            var recs = (mediatorData[0].records ? mediatorData[0].records : []);
            if (!recs) recs = [];
            var foundRecord: boolean = false;
            recs.forEach((rec: any, recordIndex: number) => {
                // console.log('%c rec: %O', classLogStyle, rec);
                if (rec.udicciMediator === udicciMediator && rec.recordId === recordId) {
                    foundRecord = true;

                    // instead of just assigning the record, we will get a little more detailed in this part.
                    // found out I was overwriting record.data and sometimes there is important data on the record.data that is lost.
                    // so now, we are going to update everything except for .data, and we will use Object.assign to set .data with a little more grace.
                    // recs[recordIndex] = record;

                    recs[recordIndex].udicciMediator = record.udicciMediator;
                    recs[recordIndex].recordId = record.recordId;
                    recs[recordIndex].title = record.title;
                    recs[recordIndex].description = record.description;
                    recs[recordIndex].keys = record.keys;

                    var updatedData: any = {};
                    if (recs[recordIndex].data) Object.assign(updatedData, recs[recordIndex].data);
                    if (record.data) Object.assign(updatedData, record.data);
                    recs[recordIndex].data = updatedData;

                    recs[recordIndex].isDirty = false;
                    recs[recordIndex].isSaving = false;
                }
            });
            // console.log('%c foundRecord: %O', classLogStyle, foundRecord);
            if (!foundRecord) recs.push(record);
            mediatorData[0].records = recs;

            mediatorData[0].lastResult = new Date();
            // console.log('%c mediatorData: %O', classLogStyle, mediatorData);
        } else {
            var newMedRecs: Mediator = {
                mediator: udicciMediator,
                records: [],
                structure: structure,
                permissions: permissions,
                linkedData: linkedData,
                lastResult: new Date()
            };
            if (!newMedRecs.records) newMedRecs.records = [];
            newMedRecs.records.push(record);
            // console.log('%c Udicci Process Record newMedRecs: %O', classLogStyle, newMedRecs);
            data.push(newMedRecs);
        }

        data[mediatorDataIndex] = mediatorData[0];
    } else {
        var newMedRecObj: Mediator = {
            mediator: udicciMediator,
            records: [],
            structure: structure,
            permissions: permissions,
            linkedData: linkedData,
            lastResult: new Date()
        };
        if (!newMedRecObj.records) newMedRecObj.records = [];
        newMedRecObj.records.push(record);
        // console.log('%c Udicci Process Record newMedRecObj: %O', classLogStyle, newMedRecObj);
        data.push(newMedRecObj);
    }

    // console.log('%c Udicci Process Record RETURN data: %O', classLogStyle, data);
    return data;
}

const removeRecord = (udicciMediator: string, recordId: number, data: Mediator[]) => {
    // console.log('%c removeRecord Record record: %O', classLogStyle, record);
    // console.log('%c removeRecord Record data: %O', classLogStyle, data);

    if (data && data.length > 0) {
        var mediatorData = data.filter(function(med: Mediator) { return med.mediator === udicciMediator });
        // console.log('%c mediatorData: %O', classLogStyle, mediatorData);
        if (mediatorData && mediatorData.length > 0) {
            // console.log('%c mediatorData: %O', classLogStyle, mediatorData);
            var recs = (mediatorData[0].records ? mediatorData[0].records : []);
            if (!recs) recs = [];
            recs.forEach((rec: any, recordIndex: number) => {
                // console.log('%c rec: %O', classLogStyle, rec);
                if (rec.udicciMediator === udicciMediator && rec.recordId === recordId) {
                    if (recs[recordIndex].isDirty) recs[recordIndex].isDirty = false;
                    if (recs[recordIndex].isSaving) recs[recordIndex].isSaving = false;

                    recs[recordIndex].data.InTrashBin = true;
                }
            });
            mediatorData[0].records = filter(recs, ((rec: any) => {
                return rec.data.InTrashBin === false;
            }));
            // console.log('%c mediatorData: %O', classLogStyle, mediatorData);
        }
    }

    // console.log('%c Udicci removeRecord Record RETURN data: %O', classLogStyle, data);
    return data;
}


export const saveRecord = (record: UdicciRecord, socialSolutionId: number = 0, settings: any | null = null) => {
    // console.log('%c saveRecord record: %O', classLogStyle, record);
    // console.log('%c saveRecord settings: %O', classLogStyle, settings);

    if (record) {
        record.isSaving = true;
        udicciFactory.saveRecord(record, socialSolutionId, settings);
    }
    notifySubscribers(record);
    // dispatch({ type: 'REFRESH', data: data });
}

export const updateRecord = (record: UdicciRecord) => {
    // console.log('%c updateRecord record: %O', classLogStyle, record);

    if (record) udicciFactory.updateRecord(record);
    notifySubscribers(record);
    // dispatch({ type: 'REFRESH', data: data });
}

export const deleteRecord = (record: UdicciRecord, settings: any | null = null) => {
    // console.log('%c deleteRecord record: %O', classLogStyle, record);
    // console.log('%c deleteRecord settings: %O', classLogStyle, settings);

    if (record) {
        record.isSaving = true;
        udicciFactory.deleteRecord(record, settings);
    }
    notifySubscribers(record);
}

export const userLogin = (loginRequest: any) => {
    // console.log('%c userLogin loginRequest: %O', classLogStyle, loginRequest);
    udicciFactory.userLogin({ onSuccess: userLoginSuccess.bind(this) }, loginRequest);
}

const userLoginSuccess = (settings: any, userDetails: any) => {
    // console.log('%c userLoginSuccess settings: %O', classLogStyle, settings);
    // console.log('%c userLoginSuccess userDetails: %O', classLogStyle, userDetails);

    udicciFactory.currentUser = userDetails;

    var username = (userDetails.user && userDetails.user.username ? userDetails.user.username : '');
    window.localStorage.setItem('udicci.last.login.username', username);

    sessionStorage.setItem('udicci.userDetails', JSON.stringify(userDetails));
    if (settings.onSuccess) settings.onSuccess(userDetails);
}

export const logoff = (loadDefaultProfile: boolean = true) => {
    // console.log('%c logoff loadDefaultProfile: %O', classLogStyle, loadDefaultProfile);
    udicciFactory.currentUser = null;
    // console.log('%c logoff udicciFactory: %O', classLogStyle, udicciFactory);
    sessionStorage.setItem('udicci.userDetails', '');
    sessionStorage.setItem('udicci.user.handCash', '');
    sessionStorage.setItem('udicci.design.mode', 'off');
    if (loadDefaultProfile) {
        udicciFactory.getProfileByUrl();
    } else {
        var profile = udicciFactory.selectedProfile;
        if (profile && profile.data) {
            if (profile.data.ProfileUrl) {
                udicciFactory.getProfileByUrl(profile.data.ProfileUrl);
            }
        }
    }
}

export const setPorta = (porta: UdicciPorta) => {
    // console.log('%c setPorta porta: %O', classLogStyle, porta);
    // console.log('%c setPorta udicciFactory: %O', classLogStyle, udicciFactory);
    udicciFactory.setPorta(porta);
}

export const goToDefaultPorta = () => {
    // console.log('%c goToDefaultPorta udicciFactory: %O', classLogStyle, udicciFactory);
    udicciFactory.goToDefaultPorta();
}

export const goToLandingPorta = () => {
    // console.log('%c goToLandingPorta udicciFactory: %O', classLogStyle, udicciFactory);
    udicciFactory.goToLandingPorta();
}

export const getPortaFocusBoard = (settings: any | null = null) => {
    // console.log('%c getPortaFocusBoard udicciFactory: %O', classLogStyle, udicciFactory);
    udicciFactory.getPortaFocusBoard(settings);
}

export const makePortaDefault = (newDefaultPorta: any) => {
    // console.log('%c makePortaDefault newDefaultPorta: %O', classLogStyle, newDefaultPorta);
    udicciFactory.makePortaDefault(newDefaultPorta);
}

export const makePortaLandingPorta = (newLandingPorta: any, newLandingPageValue: boolean | undefined = true) => {
    // console.log('%c makePortaLandingPorta newLandingPorta: %O', classLogStyle, newLandingPorta);
    // console.log('%c makePortaLandingPorta newLandingPageValue: %O', classLogStyle, newLandingPageValue);
    udicciFactory.makePortaLandingPorta(newLandingPorta, newLandingPageValue);
}

export const setPortalHash = (newHash: string) => {
    // console.log('%c setPortalHash newHash: %O', classLogStyle, newHash);
    // console.log('%c setPortalHash udicciFactory: %O', classLogStyle, udicciFactory);
    udicciFactory.setPortalHash(newHash);
}

export const getPortas = () => { return udicciFactory.getPortas(); }
export const getPortaMenus = () => { return udicciFactory.getPortaMenus(); }
export const getPortaThemes = () => { return udicciFactory.getPortaThemes(); }

export const savePorta = () => {
    // console.log('%c savePorta udicciFactory.savePorta: %O', classLogStyle, udicciFactory.savePorta);
    udicciFactory.savePorta();
}

export const setProfile = (profile: UdicciRecord | null) => {
    // console.log('%c setProfile profile: %O', classLogStyle, profile);
    udicciFactory.setProfile(profile);
}

export const setSocialSolution = (socialSolution: UdicciRecord | null) => {
    // console.log('%c setSocialSolution socialSolution: %O', classLogStyle, socialSolution);
    udicciFactory.setSocialSolution(socialSolution);
}

export const setFeature = (feature: UdicciRecord | null) => {
    // console.log('%c setFeature feature: %O', classLogStyle, feature);
    udicciFactory.setFeature(feature);
}

export const saveProfile = (settings: any = null) => {
    udicciFactory.onSaveProfile(settings);
}

export const onChangeProfile = (fieldName: string, newValue: any = null) => {
    // console.log('%c onChangeProfile fieldName: %O', classLogStyle, fieldName);
    // console.log('%c onChangeProfile newValue: %O', classLogStyle, newValue);
    udicciFactory.onChangeProfile(fieldName, newValue);
}

export const getProfileSetting = (mediatorName: string, socialSolutionId: number, settingName: string = 'all', useUserProfile: boolean = false) => {
    // console.log('%c getProfileSetting profile: %O', classLogStyle, profile);
    return udicciFactory.getProfileSetting(mediatorName, socialSolutionId, settingName, useUserProfile);
}

export const getUdicciData = (options: any) => {
    // console.log('%c getUdicciData options: %O', classLogStyle, options);
    udicciFactory.getData(options);
}

export const getUdicciDataAsync = async (options: any) => {
    // console.log('%c getUdicciDataAsync options: %O', classLogStyle, options);
    udicciFactory.getData(options);
}

export const getData = async (options: any) =>  {
    // console.log('%c getData options: %O', classLogStyle, options);
    const asyncTask = udicciFactory.getData;
    const result = await asyncTask(options);
    return result;
}

export const sendRequest = async (request: any) =>  {
    // console.log('%c sendRequest request: %O', classLogStyle, request);
    const asyncTask = udicciFactory.sendRequest;
    const result = await asyncTask(request);
    return result;
}

export const sendPreparedRequest = async (request: UdicciRequestBase, options: any = {}) =>  {
    // console.log('%c sendPreparedRequest request: %O', classLogStyle, request);
    const asyncTask = udicciFactory.sendPreparedRequest;
    const result = await asyncTask(request, options);
    return result;
}

export const userTimedOut = (expectedTimeout: any) => {
    // console.log('%c userTimedOut expectedTimeout: %O', 'color: maroon;', expectedTimeout);
    // this.currentUserTimedOut = true;
    // udicciFactory.userTimedOut(expectedTimeout);
}

export const loadProfileTypes = async () =>  {
    // console.log('%c loadProfileTypes', classLogStyle);
    const asyncTask = udicciFactory.getProfileTypes;
    const result = await asyncTask();
    return result;
}

export const getMediatorStructures = async (udicciMediatorNames: any[]) =>  {
    // console.log('%c getMediatorStructures', classLogStyle);
    const asyncTask = udicciFactory.getMediatorStructures;
    const result = await asyncTask(udicciMediatorNames);
    return result;
}

export const getMediationAgreements = async (options: any | null) =>  {
    // console.log('%c getMediationAgreements options: %O', classLogStyle, options);
    const asyncTask = udicciFactory.getMediationAgreements;
    const result = await asyncTask(options)
    return result
}

export const getInstructionSet = async (options: any | null) =>  {
    // console.log('%c getInstructionSet options: %O', classLogStyle, options);
    const asyncTask = udicciFactory.getInstructionSet;
    const result = await asyncTask(options)
    return result
}

export const getRecord = (options: any) => {
    // console.log('%c getRecord options: %O', classLogStyle, options);
    // console.log('%c getRecord mainReducer: %O', classLogStyle, mainReducer);
    //udicciFactory.getData(options);
}

export const formatStringForDisplay = (stringValue: string, overflowAtOneLine: boolean | undefined = false) => {
    // console.log('%c stringValue: %O', 'color: green;', stringValue);
    var rval: any[] = [];
    var workingString: string = stringValue.toString();
    if (workingString && workingString.indexOf('\n') > 0) {
        var lines: string[] = workingString.split('\n');
        if (overflowAtOneLine) {
            var key = 'sv.overflown';
            rval.push(
                <Fragment key={key}><span>{lines[0]} ...</span><br /></Fragment>
            );
        } else {
            lines.forEach((item: string, idx: number) => {
                // console.log('%c item: %O', 'color: green;', item);
                // console.log('%c idx: %O', 'color: green;', idx);
                var key = 'sv.' + idx;
                rval.push(
                    <Fragment key={key}><span>{item}</span><br /></Fragment>
                );
            });
        }
    } else {
        rval.push(workingString);
    }

    return rval;
}

export const toggleNavigationBar = (newValue: boolean) => {
    // console.log('%c newValue: %O', 'color: green;', newValue);
    udicciFactory.setNavigationBarState(newValue);
}

export const toggleConsole = (newValue: boolean) => {
    // console.log('%c newValue: %O', 'color: green;', newValue);
    udicciFactory.setConsoleState(newValue);
}

export const getRecordInfo = (record: UdicciRecord) => {
    // console.log('%c getRecordInfo record: %O', 'color: green;', record);
    if (!record || (record && !record.data)) return false;
    udicciFactory.getRecordInformation(record);
}

export const toggleDesignMode = () => {
    udicciFactory.toggleDesignMode();
}

export const getDesignMode = () => {
    var curDesignMode: string | null = sessionStorage.getItem('udicci.design.mode');
    var designMode: string = 'off';
    if (curDesignMode) designMode = curDesignMode.toString();

    let userId: number = (udicciFactory.currentUser ? udicciFactory.currentUser.UdicciUserId : 0);
    if (userId <= 0 && designMode === 'on') {
        designMode = 'off';
        sessionStorage.setItem('udicci.design.mode', 'off');
    }

    return designMode;
}

function useUdicciContext() {
    const context = useContext(UdicciContext)
    if (context === undefined) {
        throw new Error('useUdicciContext must be used within a UdicciContextProvider');
    }
    return context
}

export const addTrackingLog = (logStep: any, reset: boolean = false) =>  {
    // console.log('%c addTrackingLog logStep: %O', 'color: red; font-weight: bold;', logStep);
    let { url } = logStep;
    let { pathname, hash, href } = url;

    if (reset) sessionStorage.removeItem('udicci.engagementTracking');

    var engagementTrackingFromStore = sessionStorage.getItem('udicci.engagementTracking');

    var engagementTracking = null;
    if (engagementTrackingFromStore) {
        engagementTracking = JSON.parse(engagementTrackingFromStore);
    }
    // console.log('%c addTrackingLog engagementTracking: %O', 'color: red;', engagementTracking);

    // engagementTracking.currentAccessToken = response.token;
    // engagementTracking.currentRefreshToken = response.refreshToken;
    if (!engagementTracking) engagementTracking = {};
    if (!engagementTracking.url) engagementTracking.url = href;
    if (!engagementTracking.steps) engagementTracking.steps = [];
    if (!engagementTracking.engaged) engagementTracking.engaged = false;
    if (engagementTracking.engaged) return false;
 
    var curDate = new Date();
    let { steps } = engagementTracking;
    // console.log('%c addTrackingLog steps: %O', 'color: blue;', steps);
    let lastStep: any = (steps.length > 0 ? steps[steps.length - 1] : null);
    // console.log('%c addTrackingLog lastStep: %O', 'color: blue;', lastStep);
    if (!lastStep || (lastStep && (lastStep.pathname !== pathname || lastStep.hash !== hash))) {
        steps.push({
            hash: hash,
            pathname: pathname,
            timestamp: curDate.getTime()
        });
    }
    engagementTracking.steps = steps;
    // console.log('%c addTrackingLog engagementTracking: %O', 'color: blue;', engagementTracking);

    engagementTrackingFromStore = JSON.stringify(engagementTracking);
    sessionStorage.setItem('udicci.engagementTracking', engagementTrackingFromStore);
}

export const getRedirectionLoginUrl = () => {
    udicciFactory.getRedirectionLoginUrl();
}

export const configureHandCashAuth = () => {
    udicciFactory.configureHandCashAuth();
}

export const getBlockChainTransaction = async (chainAddress: String) => {
    udicciFactory.getBlockChainTransaction(chainAddress);
}

export const getBlockChainGetAddressInfo = async (chainAddress: String) => {
    udicciFactory.getBlockChainGetAddressInfo(chainAddress);
}

export const initializeAnalyticsForPortal = async () => {
    udicciFactory.initializeAnalyticsForPortal();
}

export { UdicciContext, UdicciProvider, useUdicciContext };
