
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

import { udicciStyles } from 'src/theme/shared-styles';

import useUdicciRecord from 'src/hooks/useUdicciRecord';

export const DeleteUI: React.FC<any> = (props) => {
    // console.log('%c DeleteUI props: %O', 'color: blue;', props);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    let { deleteRecord, udicciRecord } = useUdicciRecord(props.record);

    const cancelAction = (evt: any) => {
        // console.log('%c cancelAction actionIsProcessing: %O', 'color: red;', actionIsProcessing);
        if (props && props.onClose) props.onClose();
    };

    const confirmDelete = (evt: any) => {
        // console.log('%c confirmDelete evt: %O', 'color: maroon;', evt);
        deleteRecord();
    }

    return (
        <Box sx={udicciClasses.content}>
            <Box>
                <Typography variant="subtitle2" color="textPrimary" component="span">
                    Are you sure you want to delete
                </Typography>
                &nbsp;
                <Typography variant="subtitle2" color="secondary" component="span">
                    {(udicciRecord && udicciRecord.title ? udicciRecord.title : 'this record')}?
                </Typography>
            </Box>
            <Box sx={udicciClasses.root}>
                <Avatar variant="square" sx={(udicciRecord.isSaving ? udicciClasses.disabledSquare : udicciClasses.ysquare)} onClick={(evt: any) => confirmDelete(evt)}>
                    Yes
                </Avatar>                                            
                <Avatar variant="square" sx={(udicciRecord.isSaving ? udicciClasses.disabledSquare : udicciClasses.nsquare)} onClick={(evt: any) => cancelAction(evt)}>
                    No
                </Avatar>                                            
                <Button variant="contained" size="small" onClick={cancelAction} classes={{ root: 'buttonRoot' }}>
                    Cancel
                </Button>
            </Box>
        </Box>
    );
};
