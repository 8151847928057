
import React from 'react';
import { useTheme } from '@mui/material/styles';

import {
    Box, Typography, Table, TableHead, TableFooter, TableBody, TableRow, TableCell, Tooltip, MenuItem, Divider,
    Icon, IconButton, Checkbox, Select, 
} from '@mui/material';

import { forEach } from 'underscore';

import { udicciStyles } from 'src/theme/shared-styles';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext } from 'src/context/udicci-context'

const ConfigureFieldDisplay: React.FC<any> = (props) => {
    // const udicciContext = useContext(UdicciContext);
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    let { mediatorName, options, onChange } = props;
    // console.log('%c mediatorName: %O', 'color: maroon;', mediatorName);
    // console.log('%c options: %O', 'color: maroon;', options);

    const theme = useTheme();
    // console.log('%c theme: %O', 'color: purple;', theme);
    const udicciClasses = udicciStyles(theme);
    // console.log('%c udicciClasses: %O', 'color: purple;', udicciClasses);
    let colorPalette: any = null; // (udicciClasses && udicciClasses.colorPalette ? udicciClasses.colorPalette : {});
    // console.log('%c colorPalette: %O', 'color: maroon;', colorPalette);
    let contentPalette: any = null; // (udicciClasses && udicciClasses.contentPalette ? udicciClasses.contentPalette : {});
    // console.log('%c contentPalette: %O', 'color: maroon;', contentPalette);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();

    let { udicci } = udicciContext.state;
    // console.log('%c ConfigureFieldDisplay udicci: %O', 'color: purple;', udicci);

    const listFieldSelectionChanged = (field: any, event: any) => {
        // console.log('%c listFieldSelectionChanged field: %O', 'color: maroon;', field);
        // console.log('%c listFieldSelectionChanged event: %O', 'color: maroon;', event);

        if (!options) options = {};

        let fieldSettings = (options && options.fields ? options.fields : {});
        // console.log('%c fieldSettings: %O', 'color: maroon;', fieldSettings);

        let fieldKeys: any = Object.keys(fieldSettings);
        if (!fieldSettings[field.JsonFieldName]) {
            fieldSettings[field.JsonFieldName] = {
                key: field.JsonFieldName,
                name: field.Name,
                label: field.DisplayName,
                order: fieldKeys.length + 1
            };
        }

        let fieldsArray: any[] = [];
        if (fieldSettings) {
            Object.entries(fieldSettings).forEach(([key, fieldSettings]) => { fieldsArray.push(fieldSettings); });
        }

        let sortedFields = fieldsArray.sort((a,b) => {
            if (a.order < b.order) return -1;  // ascending
            if (a.order > b.order) return 1;  // descending
            return 0 //default return value (no sorting)
        });
        // console.log('%c sortedFields: %O', 'color: purple;', sortedFields);
        let currentOrder = 0;
        forEach(sortedFields, (field: any, idx: number) => {
            currentOrder++;
            sortedFields[idx].order = currentOrder;
            fieldSettings[field.key].order = currentOrder;
        });

        options.fields = fieldSettings;

        if (onChange) onChange(options);
    }

    const removeField = (field: any, event: any) => {
        // console.log('%c removeField field: %O', 'color: maroon;', field);
        // console.log('%c removeField event: %O', 'color: maroon;', event);

        let fieldSettings = (options && options.fields ? options.fields : {});
        // console.log('%c fieldSettings: %O', 'color: maroon;', fieldSettings);

        if (fieldSettings[field.JsonFieldName]) delete fieldSettings[field.JsonFieldName];

        let fieldsArray: any[] = [];
        if (fieldSettings) {
            Object.entries(fieldSettings).forEach(([key, fieldSettings]) => {  });
            forEach(fieldSettings, (fs: any, idx: number) => {
                fieldsArray.push(fs);
            });
        }

        let sortedFields = fieldsArray.sort((a,b) => {
            if (a.order < b.order) return -1;  // ascending
            if (a.order > b.order) return 1;  // descending
            return 0 //default return value (no sorting)
        });
        // console.log('%c sortedFields: %O', 'color: purple;', sortedFields);
        let currentOrder = 0;
        forEach(sortedFields, (field: any, idx: number) => {
            currentOrder++;
            sortedFields[idx].order = currentOrder;
            fieldSettings[field.key].order = currentOrder;
        });
        options.fields = fieldSettings;

        if (onChange) onChange(options);
    }

    const moveFieldInList = (field: any, direction: string, event: any) => {
        // console.log('%c moveFieldInList field: %O', 'color: blue;', field);
        // console.log('%c moveFieldInList direction: %O', 'color: blue;', direction);

        let fieldSettings = (options && options.fields ? options.fields : {});
        let fieldsArray: any[] = [];
        // console.log('%c fieldSettings: %O', 'color: purple;', fieldSettings);
        if (fieldSettings) {
            Object.entries(fieldSettings).forEach(([key, fieldSettings]) => {
                fieldsArray.push(fieldSettings);
            });
        }
        // console.log('%c fieldsArray: %O', 'color: purple;', fieldsArray);

        let sortedFields = fieldsArray.sort((a,b) => {
            if (a.order < b.order) return -1;  // ascending
            if (a.order > b.order) return 1;  // descending
            return 0 //default return value (no sorting)
        });
        // console.log('%c sortedFields: %O', 'color: purple;', sortedFields);
        let currentOrder = 0;
        Object.entries(sortedFields).forEach(([idx, sf]) => {
            // console.log('%c sf: %O', 'color: purple;', sf);
            currentOrder++;

            let sfIndex = parseInt(idx.toString(), 10);
            // console.log('%c sfIndex: %O', 'color: purple;', sfIndex);
            let sfPrevIndex = sfIndex - 1;
            // console.log('%c sfPrevIndex: %O', 'color: purple;', sfPrevIndex);
            let sfNextIndex = sfIndex + 1;
            // console.log('%c sfNextIndex: %O', 'color: purple;', sfNextIndex);

            if (sf.key === field.JsonFieldName) {
                if (direction === 'up') {
                    sortedFields[sfIndex].order = currentOrder - 1;
                    sortedFields[sfPrevIndex].order = currentOrder;

                    fieldSettings[sf.key].order = currentOrder - 1;
                    fieldSettings[sortedFields[sfPrevIndex].key].order = currentOrder;
                } else if (direction === 'down') {
                    sortedFields[sfNextIndex].order = currentOrder;
                    sortedFields[sfIndex].order = currentOrder + 1;

                    fieldSettings[sf.key].order = currentOrder + 1;
                    fieldSettings[sortedFields[sfNextIndex].key].order = currentOrder;
                }
            }
        });
        // console.log('%c fieldSettings: %O', 'color: purple;', fieldSettings);

        options.fields = fieldSettings;

        if (onChange) onChange(options);
    }

    const changeIncludedFieldSetting = (field: any, fieldName: string, event: any) => {
        // console.log('%c changeIncludedFieldSetting field: %O', 'color: blue;', field);
        // console.log('%c changeIncludedFieldSetting fieldName: %O', 'color: blue;', fieldName);

        let trgt = event.target;
        // console.log('%c trgt: %O', 'color: purple;', trgt);
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c newValue: %O', 'color: purple;', newValue);

        let fieldSettings = (options && options.fields ? options.fields : {});
        fieldSettings[field.JsonFieldName][fieldName] = newValue;
        // console.log('%c fieldSettings: %O', 'color: purple;', fieldSettings);
        options.fields = fieldSettings;

        if (onChange) onChange(options);
    }

    let mediatorStructure = udicciHelpers.getMediatorStructure(mediatorName);
    // console.log('%c mediatorStructure: %O', 'color: maroon;', mediatorStructure);
    if (!mediatorStructure) {
        let mns: string[] = [];
        mns.push(mediatorName);
        // console.log('%c mns: %O', 'color: maroon;', mns);
        udicci.getMediatorStructures(mns);
    }

    let mediatorFields = (mediatorStructure && mediatorStructure.UdicciMediatorFields !== undefined ? mediatorStructure.UdicciMediatorFields : null);
    // console.log('%c mediatorFields: %O', 'color: maroon;', mediatorFields);
    let virtualFields = (mediatorStructure && mediatorStructure.VirtualMediatorFields !== undefined ? mediatorStructure.VirtualMediatorFields : null);
    // console.log('%c virtualFields: %O', 'color: maroon;', virtualFields);

    let simpleDisplay = (options && options.simpleDisplay !== undefined ? options.simpleDisplay : true);
    let customDisplay = (options && options.customDisplay !== undefined ? options.customDisplay : false);
    if (customDisplay) simpleDisplay = false;

    let selectedFields = (options && options.fields ? options.fields : {});
    // console.log('%c selectedFields: %O', 'color: maroon;', selectedFields);

    let fieldsArray: any[] = [];
    if (selectedFields) {
        Object.entries(selectedFields).forEach(([key, selectedFields]) => { fieldsArray.push(selectedFields); });
    }
    // console.log('%c fieldsArray: %O', 'color: purple;', fieldsArray);

    let sortedFields = fieldsArray.sort((a,b) => {
        if (a.order < b.order) return -1;  // ascending
        if (a.order > b.order) return 1;  // descending
        return 0 //default return value (no sorting)
    });
    // console.log('%c sortedFields: %O', 'color: purple;', sortedFields);

    let includedFieldRows: any[] = [];
    if (sortedFields) {
        let displayVariantSelections: any[] = udicciHelpers.getCommonSelectionListItems('typography.variant', 'display.variant');
        let labelVariantSelections: any[] = udicciHelpers.getCommonSelectionListItems('typography.variant', 'label.variant');

        let componentSelections: any[] = [];
        componentSelections.push(<MenuItem key={'component.not.set'} value={''}>Not Set</MenuItem>);
        componentSelections.push(<MenuItem key={'component.div'} value={'div'}>div</MenuItem>);
        componentSelections.push(<MenuItem key={'component.span'} value={'span'}>span</MenuItem>);
        componentSelections.push(<MenuItem key={'component.p'} value={'p'}>p</MenuItem>);

        let contentPaletteSelections: any[] = [];
        contentPaletteSelections.push(<MenuItem key={'content.palette.not.selected'} value={'content.palette.not.selected'}> not selected </MenuItem>);

        // let contentPaletteArray: any[] = [];
        // if (contentPalette) {
        //     Object.entries(contentPalette).forEach(([colorId, color]) => { contentPaletteArray.push(color); });
        // }
        // // console.log('%c contentPaletteArray: %O', 'color: purple;', contentPaletteArray);
        // Object.entries(contentPaletteArray).forEach(([idxContent, ctnt]) => {
        //     // console.log('%c content: %O', 'color: purple;', ctnt);
        //     contentPaletteSelections.push(<MenuItem key={'content.palette.option.' + ctnt.uid} value={ctnt.uid}> {ctnt.name} </MenuItem>);
        // });

        Object.entries(sortedFields).forEach(([idxField, sf]) => {
            if (!mediatorFields) return null;
            let fieldIndex = parseInt(idxField.toString(), 10);
            let jsonKey = sf.key;
            let fld = mediatorFields.find(function(rec: any) {
                return (rec.JsonFieldName === jsonKey)
            });
            if (!fld) {
                fld = virtualFields.find(function(rec: any) {
                    return (rec.JsonFieldName === jsonKey)
                });
            }
            // console.log('%c fld: %O', 'color: purple;', fld);
            if (!fld) return null;

            let selectedHeader = (sf.header ? true : false);
            let selectedShowLabel = (sf.showLabel ? true : false);
            let selectedColor = (sf.color ? sf.color : 'color.not.selected');
            let selectedVariant = (sf.variant ? sf.variant : 'body2');
            let selectedComponent = (sf.component ? sf.component : null);
            let selectedContentPalette = (sf.contentPalette ? sf.contentPalette : 'content.palette.not.selected');
            let selectedLabelContentPalette = (sf.labelContentPalette ? sf.labelContentPalette : 'content.palette.not.selected');
            let selectedLabelVariant = (sf.labelVariant ? sf.labelVariant : '');
            includedFieldRows.push(
                <TableRow key={'included.field.row.' + jsonKey}>
                    <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                        <IconButton color="secondary"
                                    size="small"
                                    onClick={(evt: any) => removeField(fld, evt)}
                                    aria-label={'Remove Field'}
                                    sx={{ margin: 0, padding: '2px' }}
                        >
                            <Icon fontSize="small">remove_circle_outline</Icon>
                        </IconButton>
                        <IconButton color="default"
                                    size="small"
                                    onClick={(evt: any) => moveFieldInList(fld, 'up', evt)}
                                    aria-label={'Move Field Up'}
                                    disabled={(fieldIndex > 0 ? false : true)}
                                    sx={{ margin: 0, padding: '2px' }}
                        >
                            <Icon fontSize="small">keyboard_arrow_up</Icon>
                        </IconButton>
                        <IconButton color="default"
                                    size="small"
                                    onClick={(evt: any) => moveFieldInList(fld, 'down', evt)}
                                    aria-label={'Move Field Down'}
                                    disabled={((fieldIndex < (sortedFields.length - 1)) ? false : true)}
                                    sx={{ margin: 0, padding: '2px' }}
                        >
                            <Icon fontSize="small">keyboard_arrow_down</Icon>
                        </IconButton>
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'top' }}>
                        <Typography variant="caption" noWrap={true} component="span" sx={{ marginRight: '4px' }}>
                            {sf.order}
                        </Typography>
                        <Typography variant="caption" component="span" noWrap={false}>
                            {fld.DataType}
                        </Typography>
                        <Typography variant="subtitle1" noWrap={true} component='div'>
                            {fld.Name}
                        </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'top' }}>
                        <Checkbox checked={selectedHeader}
                                  onChange={(evt: any) => changeIncludedFieldSetting(fld, 'header', evt)}
                                  color="primary"
                        />
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'top' }}>
                        <Checkbox checked={selectedShowLabel}
                                  onChange={(evt: any) => changeIncludedFieldSetting(fld, 'showLabel', evt)}
                                  color="primary"
                        />
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'top' }}>
                        <Select value={selectedComponent}
                                inputProps={{ id: 'display-component-' + jsonKey, }}
                                onChange={(evt: any) => changeIncludedFieldSetting(fld, 'component', evt)}
                        >
                            {componentSelections}
                        </Select>
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'top' }}>
                        <Select value={selectedVariant}
                                inputProps={{ id: 'display-variant-' + jsonKey, }}
                                onChange={(evt: any) => changeIncludedFieldSetting(fld, 'variant', evt)}
                        >
                            {displayVariantSelections}
                        </Select>
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'top' }}>
                        <Select value={selectedLabelVariant}
                                inputProps={{ id: 'display-label-variant-' + jsonKey, }}
                                onChange={(evt: any) => changeIncludedFieldSetting(fld, 'labelVariant', evt)}
                        >
                            {labelVariantSelections}
                        </Select>
                    </TableCell>
                </TableRow>
            );
        });
    }
    let includedFieldsTableElement = (
        <Table padding="none" size="small">
            <TableHead>
                <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Header</TableCell>
                    <TableCell>Show Label</TableCell>
                    <TableCell>Component</TableCell>
                    <TableCell>Display Variant</TableCell>
                    <TableCell>Label Variant</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {includedFieldRows}
            </TableBody>
        </Table>
    );

    let notIncludedFieldRows: any[] = [];
    if (mediatorFields) {
        forEach(mediatorFields, (fld: any, idxField: number) => {
            // console.log('%c fld: %O', 'color: purple;', fld);

            // we want to exclude any 'virtual mediator fields' (id is 0)
            if (!fld.UdicciMediatorFieldId) return true;

            let fldSelected = (selectedFields && selectedFields[fld.JsonFieldName] ? true : false);
            // console.log('%c fldSelected: %O', 'color: purple;', fldSelected);

            if (!fldSelected) {
                notIncludedFieldRows.push(
                    <TableRow key={'not.included.field.row.' + fld.JsonFieldName}>
                        <TableCell style={{ verticalAlign: 'top' }}>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => listFieldSelectionChanged(fld, evt)}
                                        aria-label={'Add Field'}
                            >
                                <Icon fontSize="small">add</Icon>
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                            <Typography variant="subtitle1" noWrap={true}>
                                {fld.Name}
                            </Typography>
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>
                            <Typography variant="body1" noWrap={false}>
                                {fld.DataType}
                            </Typography>
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>
                            <Typography variant="body1" noWrap={false}>
                                {fld.Overview}
                            </Typography>
                        </TableCell>
                    </TableRow>
                );
            }
        });
    }
    if (virtualFields) {
        forEach(virtualFields, (fld: any, idxField: number) => {
            // console.log('%c fld: %O', 'color: purple;', fld);

            if (!fld.CanDisplay) return true;
            if (fld.JsonFieldName === 'RecordIdentifier') return true;

            let fldSelected = (selectedFields && selectedFields[fld.JsonFieldName] ? true : false);
            // console.log('%c fldSelected: %O', 'color: purple;', fldSelected);

            if (!fldSelected) {
                notIncludedFieldRows.push(
                    <TableRow key={'not.included.field.row.' + fld.JsonFieldName}>
                        <TableCell style={{ verticalAlign: 'top' }}>
                            <IconButton color="primary"
                                        size="small"
                                        onClick={(evt: any) => listFieldSelectionChanged(fld, evt)}
                                        aria-label={'Add Field'}
                            >
                                <Icon fontSize="small">add</Icon>
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                            <Typography variant="subtitle1" noWrap={true}>
                                {fld.Name}
                            </Typography>
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>
                            <Typography variant="body1" noWrap={false}>
                                {fld.DataType}
                            </Typography>
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>
                            <Typography variant="body1" noWrap={false}>
                                {fld.Overview}
                            </Typography>
                        </TableCell>
                    </TableRow>
                );
            }
        });
    }

    let notIncludedFieldsTableElement: any = (
        <Table padding="none" size="small" sx={{ marginTop: '16px' }}>
            <TableHead>
                <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Overview</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {notIncludedFieldRows}
            </TableBody>
        </Table>
    );

    let displaySelectionsElement = (
        <Box>
            {includedFieldsTableElement}
            {notIncludedFieldsTableElement}
        </Box>
    );

    return (
        <Box>
            <Typography variant="subtitle1">Field Display</Typography>
            {displaySelectionsElement}
        </Box>
    );
}

export default ConfigureFieldDisplay;
