import Box from '@mui/material/Box';

export const CreateProfileConfig: React.FC<any> = (props) => {
    // console.log('%c CreateProfileConfig props: %O', 'color: purple;', props);
    return (
        <Box>
            There are no configuration settings for the Create Profile Action.
        </Box>
    );
};
