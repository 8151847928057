import { Box } from '@mui/material';
import { useFocusedContext } from './focused-context';
import { MenuContent } from './menu-content';
import { IntroductionContent } from './introduction-content';
import { PromptAreaContent } from './prompt-area-content';
import { FocusedContent } from './focused-content';
import { ConfigureSettings } from './configure-settings';
import { AIPromptBuilder } from './ai-prompt-builder';
import { FloatingAIButton } from './floating-ai-button';

export function FocusBoardDisplay(props: any) {
    const focus = useFocusedContext();
    // console.log('%c FocusBoardDisplay focus: %O', 'color: green;', focus);
    let sxContainer: any = { height: '100%', display: 'flow-root' };

    let menuContentElement: any = (<MenuContent />);
    let mainAreaContentElement: any = null;
    let floatingButtonElement: any = null;
    let promptContentElement: any = null;

    if (focus.configureSettings) {
        mainAreaContentElement = (<ConfigureSettings />);
    } else if (!focus.point) {
        mainAreaContentElement = (<IntroductionContent />);
    } else {
        mainAreaContentElement = (<FocusedContent />);
        floatingButtonElement = (<FloatingAIButton />);
    }

    if (!focus.configureSettings)
        promptContentElement = (focus.aiAssistantOn ? (<AIPromptBuilder />) : (<PromptAreaContent />));
    
    let displayElement: any = (
        <Box sx={sxContainer}>
            {menuContentElement}
            {mainAreaContentElement}
            {promptContentElement}
            {floatingButtonElement}
        </Box>
    );
    return displayElement;
}
