
import { styled } from '@mui/material/styles';

import { useState, useEffect, useCallback } from 'react';

import { forEach, findIndex } from 'underscore';

import { Box, Paper, Typography, Icon, IconButton, Button, Grid, CircularProgress, Divider } from '@mui/material';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext, makePortaLandingPorta, sendPreparedRequest, subscribe as subscribeToUdicci } from 'src/context/udicci-context';
import { useFocusedContext } from './focused-context';

import { GetMyPointsRequest } from 'src/interfaces/udicci-request-interfaces';

import { ORANGE_DOT_ICON } from './focused-content';

export const FocusMenuSettings: React.FC<any> = (props) => {
    const focus = useFocusedContext();
    // console.log('%c FocusMenuSettings focus: %O', 'color: blue;', focus);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;
    let { selectedProfile, selectedPorta, currentUser } = udicci;
    // console.log('%c FocusMenuSettings selectedProfile: %O', 'color: blue;', selectedProfile);
    // console.log('%c FocusMenuSettings selectedPorta: %O', 'color: blue;', selectedPorta);
    // console.log('%c FocusMenuSettings selectedPorta: %O', 'color: blue;', selectedPorta);

    // let udicciFocusBoard: any = (udicci && udicci.focusBoard ? udicci.focusBoard : null);
    // console.log('%c FocusMenuSettings udicciFocusBoard: %O', 'color: blue;', udicciFocusBoard);

    let profileDisplayName: any = (selectedProfile && selectedProfile.data.DisplayName ? selectedProfile.data.DisplayName : '');
    // console.log('%c FocusMenuSettings profileDisplayName: %O', 'color: blue;', profileDisplayName);
    let portaSettings: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : null);
    // console.log('%c FocusMenuSettings portaSettings: %O', 'color: blue;', portaSettings);
    // let defaultPorta: boolean = (portaSettings && portaSettings.defaultPorta ? true : false);
    // console.log('%c FocusMenuSettings defaultPorta: %O', 'color: maroon;', defaultPorta);
    let landingPorta: boolean = (portaSettings && portaSettings.landingPorta ? true : false);
    // console.log('%c FocusMenuSettings landingPorta: %O', 'color: maroon;', landingPorta);
    let portaFocusBoard: any = (portaSettings && portaSettings.focusBoard ? portaSettings.focusBoard : null);
    // console.log('%c FocusMenuSettings portaFocusBoard: %O', 'color: blue;', portaFocusBoard);
    // let focusBoardSettings: any = (portaFocusBoard && portaFocusBoard.settings ? portaFocusBoard.settings : null);
    // console.log('%c FocusMenuSettings focusBoardSettings: %O', 'color: blue;', focusBoardSettings);
    let focusBoardPoints: any[] = (portaFocusBoard && portaFocusBoard.points ? portaFocusBoard.points : []);
    // console.log('%c FocusMenuSettings focusBoardPoints: %O', 'color: blue;', focusBoardPoints);

    const [loadPoints, setLoadPoints] = useState<boolean>(true);
    const [showAllPoints, setShowAllPoints] = useState<boolean>(false);
    const [processingRequest, setProcessingRequest] = useState<boolean>(false);
    const [landingPortaState, setLandingPortaState] = useState<boolean>(landingPorta);
    const [savingPorta, setSavingPorta] = useState<boolean>(false);
    const [uiState, flashUI] = useState<boolean>(true);
    // console.log('%c FocusMenuSettings loadPoints: %O', 'color: maroon;', loadPoints);

    const onUicciUpdate = useCallback((response: any, request: any, settings: any) => {
        // console.log('%c ChatGPTSettings onUicciUpdate response: %O', 'color: red;', response);
        // console.log('%c ChatGPTSettings onUicciUpdate request: %O', 'color: red;', request);
        // console.log('%c ChatGPTSettings onUicciUpdate settings: %O', 'color: red;', settings);
        if (request && request.UdicciCommand === 'Save Record') {
            // console.log('%c response: %O', 'color: green;', response);
            // console.log('%c request: %O', 'color: green;', request);
            if (response && response.success !== undefined) {
                if (response && response.success === true) setSavingPorta(false);
            } else if (settings) {
                // check the settings object because something is messed up returning the values
                // current structure of settings returned from save is:
                /*
                 * data: array;
                 * fullResponse: any;
                 * lastUpdate: date;
                 * linkedData: any[];
                 * mediatedRecords: any[];
                 * structures: any[];
                 */
                let fullResponse: any = (settings.fullResponse ? settings.fullResponse : null);
                if (fullResponse && fullResponse.success === true) setSavingPorta(false);
            }
        }
    }, []);

    subscribeToUdicci('focus.board.focus.menu.settings', onUicciUpdate);

    const savePorta = () => {
        if (!savingPorta && selectedPorta && selectedPorta.IsDirty) {
            setSavingPorta(true);
            udicci.savePorta();
        }
    }

    const processLandingPage = (newLandingPageValue: boolean) => {
        // console.log('%c processLandingPage newLandingPageValue: %O', 'color: maroon;', newLandingPageValue);
        setProcessingRequest(true);
        setLandingPortaState(newLandingPageValue);
        makePortaLandingPorta(selectedPorta, newLandingPageValue)
    }

    const toggleShowAllPoints = () => {
        setShowAllPoints(!showAllPoints);
    }

    const refreshPoints = () => {
        setLoadPoints(true);
    }

    const getMyPoints = () => {
        if (!selectedProfile) return false;

        let myPointsRequest: GetMyPointsRequest = {
            UdicciMediatorName: 'Points',
            UdicciCommand: 'Get My Points',
            SelectedUdicciProfileId: selectedProfile.recordId,
            SocialSolutionId: 0,
            UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0),
            IncludeEngagedPoints: true,
        }
        // console.log('%c getMyPoints myPointsRequest: %O', 'color: blue;', myPointsRequest);

        var okToContinue = true;
        if (okToContinue)  {
            sendPreparedRequest(myPointsRequest, { onSuccess: myPointsReturned, onError: failedToGetPoints });
            setLoadPoints(false);
        }
    }

    const myPointsReturned = (result: any) => {
        // console.log('%c myPointsReturned result: %O', 'color: red;', result);
        flashUI(!uiState);
    };

    const failedToGetPoints = (result: any) => {
        // console.log('%c failedToGetPoints result: %O', 'color: red;', result);
        setLoadPoints(true);
    };

    const onAddPointToBoard = (point: any) => {
        // console.log('%c onAddPointToBoard point: %O', 'color: red;', point);
        if (!selectedPorta) return false;

        if (point) {
            let focusBoardPoint: any = {
                recordId: point.recordId,
                title: point.title,
                description: point.description,
            }
            // console.log('%c onAddPointToBoard focusBoardPoint: %O', 'color: red;', focusBoardPoint);

            let ps: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : {});
            // console.log('%c onAddPointToBoard portaSettings: %O', 'color: red;', ps);
            let portaFocusBoard: any = (ps && ps.focusBoard ? ps.focusBoard : {});
            // console.log('%c processPortaUpdates portaFocusBoard: %O', 'color: maroon;', portaFocusBoard);
    
            if (!portaFocusBoard.points) portaFocusBoard.points = [];

            let fbpIndex: number = -1;
            if (portaFocusBoard.points.length > 0) {
                fbpIndex = findIndex(portaFocusBoard.points, (fbp: any, idx: number) => { return fbp.recordId === focusBoardPoint.recordId; });
            }
            // console.log('%c onAddPointToBoard fbpIndex: %O', 'color: red;', fbpIndex);
            if (fbpIndex >= 0) {
                portaFocusBoard.points[fbpIndex] = focusBoardPoint;
            } else {
                portaFocusBoard.points.push(focusBoardPoint);
            }

            ps.focusBoard = portaFocusBoard;
            selectedPorta.SettingsJson = ps;
            selectedPorta.IsDirty = true;
            // console.log('%c onAddPointToBoard selectedPorta: %O', 'color: maroon;', selectedPorta);
            udicci.setPorta(selectedPorta);
        }
    };

    const onRemovePointFromBoard = (removeFBP: any) => {
        // console.log('%c onRemovePointFromBoard removeFBP: %O', 'color: red;', removeFBP);
        if (!selectedPorta) return false;

        if (removeFBP) {
            let ps: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : {});
            // console.log('%c onRemovePointFromBoard portaSettings: %O', 'color: red;', ps);
            let portaFocusBoard: any = (ps && ps.focusBoard ? ps.focusBoard : {});
            // console.log('%c processPortaUpdates portaFocusBoard: %O', 'color: maroon;', portaFocusBoard);
    
            if (!portaFocusBoard.points) portaFocusBoard.points = [];

            let fbpIndex: number = -1;
            if (portaFocusBoard.points.length > 0) {
                fbpIndex = findIndex(portaFocusBoard.points, (fbp: any, idx: number) => { return fbp.recordId === removeFBP.recordId; });
            }
            // console.log('%c removeFBP fbpIndex: %O', 'color: red;', fbpIndex);
            if (fbpIndex >= 0) {
                let updatedPoints: any[] = [];
                forEach(portaFocusBoard.points, (p: any, idx: number) => {
                    if (idx !== fbpIndex) updatedPoints.push(p);
                });
                // console.log('%c removeFBP updatedPoints: %O', 'color: red;', updatedPoints);
                portaFocusBoard.points = updatedPoints;
            }

            ps.focusBoard = portaFocusBoard;
            selectedPorta.SettingsJson = ps;
            selectedPorta.IsDirty = true;
            // console.log('%c removeFBP selectedPorta: %O', 'color: red;', selectedPorta);
            udicci.setPorta(selectedPorta);
        }
    };

    const onMovePointOnBoard = (focusBoardPointIndex: number, direction: string) => {
        // console.log('%c onMovePointOnBoard focusBoardPointIndex: %O', 'color: red;', focusBoardPointIndex);
        // console.log('%c onMovePointOnBoard direction: %O', 'color: red;', direction);
        if (!selectedPorta) return false;

        if (focusBoardPointIndex >= 0) {
            let ps: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : {});
            // console.log('%c onMovePointOnBoard ps: %O', 'color: red;', ps);
            let pfb: any = (ps && ps.focusBoard ? ps.focusBoard : {});
            // console.log('%c onMovePointOnBoard pfb: %O', 'color: maroon;', pfb);
            let pfbPoints: any[] = (pfb && pfb.points ? pfb.points : []);
            // console.log('%c onMovePointOnBoard pfbPoints: %O', 'color: blue;', pfbPoints);
            
            if (!pfb.points) pfb.points = [];

            let moveToIndex = -1;
            if (focusBoardPointIndex < (pfbPoints.length - 1) && direction.toLowerCase() === 'down') {
                moveToIndex = focusBoardPointIndex + 1;
            }
            if (focusBoardPointIndex > 0 && direction.toLowerCase() === 'up') {
                moveToIndex = focusBoardPointIndex - 1;
            }
            // console.log('%c moveToIndex: %O', 'color: blue;', moveToIndex);
    
            if (moveToIndex >= 0) {
                // console.log('%c focusBoardPointIndex: %O', 'color: blue;', focusBoardPointIndex);
                // console.log('%c moveToIndex: %O', 'color: blue;', moveToIndex);
                pfbPoints = udicciHelpers.swapArrayElements(pfbPoints, focusBoardPointIndex, moveToIndex);
                // console.log('%c pfbPoints: %O', 'color: blue;', pfbPoints);
    
                pfb.points = pfbPoints;
            }

            ps.focusBoard = pfb;
            selectedPorta.SettingsJson = ps;
            selectedPorta.IsDirty = true;
            // console.log('%c onMovePointOnBoard selectedPorta: %O', 'color: red;', selectedPorta);
            udicci.setPorta(selectedPorta);
        }
    };

    useEffect(() => {
        // console.log('%c useEffect landingPortaState: %O', 'color: red;', landingPortaState);
        // console.log('%c useEffect landingPorta: %O', 'color: red;', landingPorta);
        if (landingPortaState === landingPorta) setProcessingRequest(false);
    }, [landingPorta,landingPortaState]);

    useEffect(() => {
        // console.log('%c useEffect loadPoints: %O', 'color: brown;', loadPoints);
        if (loadPoints) {
            let allPoints: any = udicciHelpers.getMediatorData('Points');
            // console.log('%c useEffect allPoints: %O', 'color: brown;', allPoints);
            if (allPoints.length <= 0) getMyPoints();
        }
    }, [loadPoints]);

    let sectionHeaderSx: any = {};
    let sectionHeaderIconSx: any = {};
    if (focus.isMobile) {
        sectionHeaderSx.marginTop = '12px';
        sectionHeaderSx.padding = '2px';
        sectionHeaderIconSx.display = 'initial';
        sectionHeaderIconSx.marginRight = '8px';
        sectionHeaderIconSx.float = 'left';
    } else {
        sectionHeaderSx.padding = '2px';
        sectionHeaderIconSx.display = 'flex';
        sectionHeaderIconSx.marginRight = '8px';
        sectionHeaderIconSx.float = 'left';
    }

    let introGridProps: any = {
        container: true,
        spacing: (focus.isMobile ? 1 : 3),
        // direction: (focus.isMobile ? 'column' : 'row'),
        justifyContent: 'center',
        alignItems: 'stretch',
    };

    let circularProgressElement: any = null;
    if (processingRequest) {
        circularProgressElement = (<CircularProgress size={20} />);
    }

    const PaperContainer = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        height: '100%',
        padding: '4px'
    }));

    let landingPage: any = null;
    if (landingPorta) {
        landingPage = (
            <PaperContainer elevation={3}>
                <Box sx={{ display: 'flow-root' }}>
                    <Box sx={sectionHeaderIconSx}><Icon fontSize="medium" color="success">flight_land</Icon></Box>
                    <Typography variant="subtitle1" component={focus.isMobile ? "span" : "div"}>Landing Page</Typography>
                </Box>
                <Box sx={{ textAlign: 'left' }}>
                    <Typography variant="subtitle1" component="div">
                        This Porta is currently the Portal Landing Page.
                    </Typography>
                    <Typography variant="body2" component="div">
                        Click the button below to make it so that this Porta is no longer the Landing Page for this Portal.
                    </Typography>
                    <Typography variant="caption" component="div" sx={{ fontSize: '0.7em', margin: '8px', marginLeft: '16px', marginRight: '16px' }}>
                        Disabling this Landing Page will cause the Portal to resort to loading the Default Porta.
                    </Typography>
                    <Box sx={{ marginTop: '16px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Button size="small" variant="text" color="warning" onClick={() => { processLandingPage(false) }} disabled={processingRequest}>
                            Make this Porta so it is Not the Landing Page
                            {circularProgressElement}
                        </Button>
                    </Box>
                </Box>
            </PaperContainer>
        );
    } else {
        landingPage = (
            <PaperContainer elevation={3}>
                <Box sx={{ display: 'flow-root' }}>
                    <Box sx={sectionHeaderIconSx}><Icon fontSize="medium" color="info">flight_land</Icon></Box>
                    <Typography variant="subtitle1" component={focus.isMobile ? "span" : "div"}>Landing Page</Typography>
                </Box>
                <Box sx={{ textAlign: 'left' }}>
                    <Typography variant="subtitle2" component="div">
                        This Porta is Not currently set as the Portal Landing Page for {profileDisplayName}.
                    </Typography>
                    <Typography variant="body2" component="div">
                        Click the button below to make it so that this Porta is the Landing Page for this Portal.
                    </Typography>
                    <Typography variant="caption" component="div" sx={{ fontSize: '0.7em' }}>
                        A landing page is the Porta that loads when a Portal is visited without pointing the URL 
                        to specific content using Hash instructions.
                    </Typography>
                    <Box sx={{ marginTop: '16px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Button size="small" variant="text" color="secondary" onClick={() => { processLandingPage(true) }} disabled={processingRequest}>
                            Make this the Landing Porta for {profileDisplayName}
                            {circularProgressElement}
                        </Button>
                    </Box>
                </Box>
            </PaperContainer>
        );
    }

    let allPoints: any = udicciHelpers.getMediatorData('Points');
    // console.log('%c FocusMenuSettings allPoints: %O', 'color: darkgoldenrod;', allPoints);
    let refreshPointsListElement: any = null;
    let toggleAllPointsElement: any = null;
    let pointsListElement: any = null;
    if (showAllPoints) {
        if (allPoints && allPoints.length > 0) {
            let allPointElements: any[] = [];
            let pointContainerSx: any = {
                margin: '4px',
                padding: '4px',
                border: '1px solid #eee',
            };
            let iconButtonProps: any = {
                size: "small",
                sx: {...ORANGE_DOT_ICON, ...{ cursor: 'default', margin: '8px', backgroundColor: '#659ac1' }},
            };
            forEach(allPoints, (p: any, i: number) => {
                let fbpIndex: number = -1;
                if (focusBoardPoints && focusBoardPoints.length > 0) {
                    fbpIndex = findIndex(focusBoardPoints, (fbp: any, idx: number) => { return fbp.recordId === p.recordId; });
                }
                // console.log('%c fbpIndex: %O', 'color: red;', fbpIndex);
                if (fbpIndex >= 0) return true;
    
                let addIconButtonProps: any = {
                    size: "small",
                    color: 'secondary',
                    sx: { float: 'right' },
                    onClick: (evt: any) => { onAddPointToBoard(p); }
                };
                allPointElements.push(
                    <Box sx={pointContainerSx} key={'point.' + p.recordId.toString() + '.' + i.toString()}>
                        <IconButton {...addIconButtonProps}><Icon>playlist_add_check</Icon></IconButton>
                        <IconButton {...iconButtonProps}>&nbsp;</IconButton>
                        <Typography variant="caption">{p.title}</Typography>
                    </Box>
                );
            });
            if (allPointElements.length > 0) {
                pointsListElement = (
                    <Box sx={{ display: 'flow-root' }}>
                        {allPointElements}
                    </Box>
                );
            } else {
                pointsListElement = (
                    <Box sx={{ display: 'flow-root' }}>
                        <Typography>There are no points that can be assigned for this Focus Board.</Typography>
                    </Box>
                );
            }
        }

        toggleAllPointsElement = (
            <IconButton color="info" size="small" onClick={(evt: any) => { toggleShowAllPoints(); }} sx={{ float: 'right' }}>
                <Icon fontSize="small">highlight_off</Icon>
            </IconButton>
        );
        refreshPointsListElement = (
            <IconButton color="secondary" size="small" onClick={(evt: any) => { refreshPoints(); }} sx={{ float: 'right' }}>
                <Icon fontSize="small">refresh</Icon>
            </IconButton>
        );
    } else {
        toggleAllPointsElement = (
            <IconButton color="info" size="small" onClick={(evt: any) => { toggleShowAllPoints(); }} sx={{ float: 'right' }}>
                <Icon fontSize="small">settings</Icon>
            </IconButton>
        );
    }

    let assignedPointsElement: any = null;
    if (focusBoardPoints && focusBoardPoints.length > 0) {
        let allPointElements: any[] = [];
        let pointContainerSx: any = {
            margin: '4px',
            padding: '4px',
            border: '1px solid #eee',
        };
        let fbpIconButtonProps: any = {
            size: "small",
            sx: {...ORANGE_DOT_ICON, ...{ cursor: 'default', margin: '5px' }},
        };
        forEach(focusBoardPoints, (fbp: any, idx: number) => {
            let fbpRemoveIcon: any = null;
            let fbpMoveUpIcon: any = null;
            let fbpMoveDownIcon: any = null;
            if (showAllPoints) {
                let fbpKey: string = 'focus.board.point.' + fbp.recordId.toString() + '.' + idx.toString();
                let fbpRemoveSettings: any = {
                    key: fbpKey,
                    size: 'small',
                    color: "warning",
                    sx: { float: 'right' },
                    onClick: (evt: any) => { onRemovePointFromBoard(fbp); },
                }
                fbpRemoveIcon = (
                    <IconButton {...fbpRemoveSettings}> <Icon fontSize="small">remove_circle_outline</Icon> </IconButton>
                );

                fbpMoveUpIcon = (
                    <IconButton color="primary"
                                size="small"
                                onClick={(evt: any) => onMovePointOnBoard(idx, 'UP')}
                                aria-label={'Move Point Up'}
                                title={'Move Point Up'}
                                disabled={(idx > 0 ? false : true)}
                                sx={{ float: 'right' }}
                    >
                        <Icon fontSize="small">arrow_drop_up</Icon>
                    </IconButton>
                );
                fbpMoveDownIcon = (
                    <IconButton color="primary"
                                size="small"
                                onClick={(evt: any) => onMovePointOnBoard(idx, 'DOWN')}
                                aria-label={'Move Point Down'}
                                title={'Move Point Down'}
                                disabled={(idx < focusBoardPoints.length - 1 ? false : true)}
                                sx={{ float: 'right' }}
                    >
                        <Icon fontSize="small">arrow_drop_down</Icon>
                    </IconButton>
                );
            }

            allPointElements.push(
                <Box sx={pointContainerSx} key={'point.' + fbp.recordId.toString() + '.' + idx.toString()}>
                    {fbpMoveDownIcon}
                    {fbpMoveUpIcon}
                    {fbpRemoveIcon}
                    <IconButton {...fbpIconButtonProps}>&nbsp;</IconButton>
                    <Typography variant="caption">{fbp.title}</Typography>
                </Box>
            );
        });
        if (allPointElements.length > 0) {
            assignedPointsElement = (
                <Box sx={{ padding: '4px' }}>
                    {allPointElements}
                </Box>
            );
        } else {
            assignedPointsElement = (
                <Box sx={{ margin: '4px', padding: '4px' }}>
                    <Typography>There are no points that can be assigned for this Focus Board.</Typography>
                </Box>
            );
        }
    } else {
        assignedPointsElement = (
            <Box sx={{ margin: '8px' }}>
                <Typography variant="caption" component="span">There are no Points assigned to this Focus Board.</Typography>
            </Box>
        );
    }

    let saveButtonDisabled: boolean = true;
    if (selectedPorta) {
        if (selectedPorta.IsDirty && !savingPorta) saveButtonDisabled= false;
    }

    let savePortaButtonElement: any = null;
    if (savingPorta) {
        savePortaButtonElement = (
            <CircularProgress size={20} sx={{ float: 'right' }} />
        );
    } else {
        savePortaButtonElement = (
            <IconButton color="success" size="small" onClick={() => savePorta()} sx={{ float: 'right' }} disabled={saveButtonDisabled}>
                <Icon fontSize="small">save</Icon>
            </IconButton>
        );
    }

    let assignedPoints: any = (
        <PaperContainer elevation={3}>
            <Box sx={{ display: 'flow-root' }}>
                {toggleAllPointsElement}
                {refreshPointsListElement}
                {savePortaButtonElement}
                <Box sx={sectionHeaderIconSx}><Icon fontSize="medium" color="info">list</Icon></Box>
                <Typography variant="subtitle1" component="div" sx={{ float: 'left' }}>Assigned Points</Typography>
            </Box>
            {assignedPointsElement}
            {pointsListElement}
        </PaperContainer>
    );

    let cgptOrgIDElement: any = null;
    let cgptModelElement: any = null;
    let cgptTemperatureElement: any = null;
    let cgptMaxCompletionsElement: any = null;
    let cgptMaxTokensElement: any = null;
    if (focus) {
        cgptOrgIDElement = (
            <Box>
                <Typography variant="caption" component="div" sx={{ fontSize: '0.6em' }}> OpenAI Organization ID: </Typography>
                <Typography variant="subtitle2" component="div"> {focus.OPENAI_ORG_ID} </Typography>
            </Box>
        );
        cgptModelElement = (
            <Box>
                <Typography variant="caption" component="div" sx={{ fontSize: '0.6em' }}> OpenAI Model: </Typography>
                <Typography variant="subtitle2" component="div"> {focus.OPEN_AI_MODEL} </Typography>
            </Box>
        );
        cgptTemperatureElement = (
            <Box>
                <Typography variant="caption" component="span" sx={{ fontSize: '0.6em' }}> Temperature: </Typography>
                <Typography variant="subtitle2" component="span"> {focus.OPEN_AI_TEMPERATURE} </Typography>
            </Box>
        );
        cgptMaxCompletionsElement = (
            <Box>
                <Typography variant="caption" component="span" sx={{ fontSize: '0.6em' }}> Max Completions per Prompt: </Typography>
                <Typography variant="subtitle2" component="span"> {focus.OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT} </Typography>
            </Box>
        );
        cgptMaxTokensElement = (
            <Box>
                <Typography variant="caption" component="span" sx={{ fontSize: '0.6em' }}> Max Tokens per Prompt: </Typography>
                <Typography variant="subtitle2" component="span"> {focus.OPEN_AI_MAX_TOKENS} </Typography>
            </Box>
        );
    }

    let chatGPTSettings: any = (
        <PaperContainer elevation={3}>
            <Box sx={{ display: 'flow-root' }}>
                <Box sx={sectionHeaderIconSx}><Icon fontSize="medium" color="info">settings</Icon></Box>
                <Typography variant="subtitle1" component={focus.isMobile ? "span" : "div"}>ChatGPT Settings</Typography>
            </Box>
            <Box sx={{ textAlign: 'left', padding: '8px' }}>
                {cgptOrgIDElement}
                {cgptModelElement}
                {cgptTemperatureElement}
                {cgptMaxCompletionsElement}
                {cgptMaxTokensElement}
            </Box>
        </PaperContainer>
    );

    let focusMenuSettingsGridElement: any = (
        <Grid {...introGridProps}>
            <Grid item={true} xs={12} md={12}> {assignedPoints} </Grid>
            <Grid item={true} xs={12} md={5}> {chatGPTSettings} </Grid>
            <Grid item={true} xs={12} md={7}> {landingPage} </Grid>
        </Grid>
    );

    let subtitleContainerProps: any = {
        marginBottom: '8px'
    };
    if (!focus.isMobile) subtitleContainerProps.textAlign = 'center';
    return (
        <Box sx={{ marginBottom: '77px' }}>
            <Box sx={subtitleContainerProps}> <Typography variant="subtitle1" component="span">Focus Board Configuration</Typography> </Box>
            {focusMenuSettingsGridElement}
        </Box>
    );
};
