import { Fragment, useState, createRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import UrlParser from 'url-parse';

import { forEach, filter, find } from 'underscore';

import ReactPlayer from 'react-player';

// import { create as ipfsHttpClient } from 'ipfs-http-client';

import * as filestack from 'filestack-js';
import { PickerOptions } from 'filestack-js';

import {
    Paper, Box, Avatar, TextField, Button, ClickAwayListener, MenuList, MenuItem, Grow, Popper, CircularProgress, Icon,
} from '@mui/material';
import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

// import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Upload';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import AttachmentMenuIcon from '@mui/icons-material/MoreHoriz';

import { AttachmentFile, AttachmentThumbnail } from 'src/classes/udicci-types';

import { FilePond, registerPlugin } from 'react-filepond';
import { FilePondInitialFile } from 'filepond';  //  , setOptions as SetFilePondOptions

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { udicciStyles } from 'src/theme/shared-styles';

import { useUdicciContext } from 'src/context/udicci-context';

import useUdicciRecord from 'src/hooks/useUdicciRecord';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

import { UdicciRecord } from 'src/classes/udicci-record';

// Register the plugins
registerPlugin(FilePondPluginFileValidateSize);
// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

const SocialIconContainer = styled('div')(({ theme }) => ({
    float: 'left',
}));

export default function Attachments(props: any) {
    // console.log('%c Attachments props: %O', 'color: maroon;', props);

    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);
    const udicciHelpers = useUdicciHelpers();

    let defaultAttachments = (props && props.attachments ? props.attachments : []);
    // console.log('%c Attachments defaultAttachments: %O', 'color: maroon;', defaultAttachments);
    // const [multiaddr, setmultiaddr] = useState<any>(createRef());
    const [addedFileHash, setAddedFileHash] = useState<string>('');
    const [ipfs, setIPFS] = useState<any>(null);
    const [keepFilename, setKeepFilename] = useState<boolean>(false);
    const [files, setFiles] = useState<(string | FilePondInitialFile | Blob)[]>([]);
    const [attachedCIDs, setAttachedCIDs] = useState<string[]>([]);
    const [selectedAttachedCID, setSelectedAttachedCID] = useState<string>('');
    const [onlineResources, setOnlineResources] = useState<string[]>([]);
    const [selectedOnlineResource, setSelectedOnlineResource] = useState<string>('');
    const [thumbnails, setThumbnails] = useState<AttachmentThumbnail[]>([]);
    const [attachmentTab, setAttachmentTab] = useState(2);
    const [forcedUpdateState, forceUpdate] = useState<boolean>(false);
    const [showAttachmentForm, setShowAttachmentForm] = useState<boolean>(false);
    const [formAttachment, setFormAttachment] = useState<any>(null);
    const [savingAttachment, setSavingAttachment] = useState<boolean>(false);
    const [formMessage, setFormMessage] = useState<string>('');
    const [checkAttachments, setCheckAttachments] = useState<boolean>(true);
    const [openAttachmentMenu, setOpenAttachmentMenu] = useState(false);
    const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);
    const [deleteAttachmentId, setDeleteAttachmentId] = useState<number>(-1);
    const [processingDelete, setProcessingDelete] = useState<boolean>(false);
    const [videoStateEnded, setVideoStateEnded] = useState<boolean>(false);
    const [videoProgress, setVideoProgress] = useState<any>(null);
    // console.log('%c videoStateEnded: %O', 'color: maroon;', videoStateEnded);
    // console.log('%c videoProgress: %O', 'color: maroon;', videoProgress);
    const multiaddr: any = createRef();
    // console.log('%c Attachments thumbnails: %O', 'color: maroon;', thumbnails);
    // console.log('%c Attachments formAttachment: %O', 'color: blue;', formAttachment);

    let currentUrl = window.location.href;
    let url = UrlParser(currentUrl, true);

    let { pathname, hash } = url;

    const udicciContext = useUdicciContext();
    let { udicci, data } = udicciContext.state;
    let { currentUser, selectedProfile, udicciFileStackApiKey } = udicci;

    let userSocialIcon = (currentUser && currentUser.mySocialIcon ? currentUser.mySocialIcon : null);
    // console.log('%c Attachments userSocialIcon: %O', 'color: blue;', userSocialIcon);

    let {
        udicciRecord, setRecord, saveRecord, checkForRecordAttachments, 
        saveAttachment, deleteAttachment
    } = useUdicciRecord(props.record);
    // console.log('%c Attachments udicciRecord: %O', 'color: blue;', udicciRecord);

    let recordMediator = (udicciRecord.udicciMediator ? udicciRecord.udicciMediator : '');
    // console.log('%c recordMediator: %O', 'color: purple;', recordMediator);
    let recordId = (udicciRecord.data ? udicciRecord.recordId : 0);
    // console.log('%c recordId: %O', 'color: purple;', recordId);
    let recordAttachments = (udicciRecord && udicciRecord.attachments ? udicciRecord.attachments : []);
    // console.log('%c recordAttachments: %O', 'color: purple;', recordAttachments);
    let recordHasAttachments = (recordAttachments.length > 0 ? true : false);
    // console.log('%c recordHasAttachments: %O', 'color: purple;', recordHasAttachments);

    let attachmentsContext = data.find((x: any) => x.mediator === 'Attachments' );
    // console.log('%c attachmentsContext: %O', 'color: maroon;', attachmentsContext);
    let attachmentsStructure = (attachmentsContext && attachmentsContext.structure ? attachmentsContext.structure : null);
    // console.log('%c attachmentsStructure: %O', 'color: maroon;', attachmentsStructure);

    let profile: any = null;
    let profileSettings: any = null;
    if (selectedProfile && selectedProfile.data) {
        profile = selectedProfile.data;
        if (!profile.jsonProfileSettingsJson && profile.ProfileSettingsJson) {
            try {
                selectedProfile.data.jsonProfileSettingsJson = JSON.parse(selectedProfile.data.ProfileSettingsJson);
            } catch (e: any) {

            }
        }
        if (profile.jsonProfileSettingsJson) profileSettings = profile.jsonProfileSettingsJson;
    }

    let useFileStackOption = 'useUdicciShared';
    if (profileSettings.useFileStackOption)
        useFileStackOption = profileSettings.useFileStackOption;
    // console.log('%c ConfigureProfileSettings useFileStackOption: %O', 'color: red;', useFileStackOption);
    let profileFileStackApiKey = '';
    if (profileSettings && profileSettings.fileStackApiKey) {
        useFileStackOption = 'useProfile';
        profileFileStackApiKey = profileSettings.fileStackApiKey;
    } else if (useFileStackOption === 'useUdicciShared') {
        profileFileStackApiKey = udicciFileStackApiKey;
    }
    // console.log('%c ConfigureProfileSettings profileFileStackApiKey: %O', 'color: red;', profileFileStackApiKey);
    // ********  FileStack Option
    // *************************************************************************************

    // let udicciDirectoryCID = 'QmaiEAFF7WbRN5vsscMDmYu99EM1S8i9LmEVb5VCyn8b8a';
    let rootIPFSUrl = 'http://127.0.0.1:5001/';
    // let rootIPFSUrl = 'https://ipfs.io/ipfs/';

    // Handles file upload event and updates state
    function updateFiles(fileItems: any) {
        // console.log('%c updateFiles fileItems: %O', 'color: maroon;', fileItems);
        // console.log('%c updateFiles thumbnails: %O', 'color: maroon;', thumbnails);
        setFiles(fileItems);
        setThumbnails(thumbnails);
    }
    // console.log('%c Attachments file: %O', 'color: maroon;', file);

    function checkForKeyFields(record: UdicciRecord) {
        let tf = (record.keys && record.keys.title ? record.keys.title : '');
        let df = (record.keys && record.keys.description ? record.keys.description : '');
        // console.log('%c checkForKeyFields record: %O', 'color: red;', record);
        if (record.title !== record.data[tf]) record.title = record.data[tf];
        if (record.description !== record.data[df]) record.description = record.data[df];
        return record;
    }

    async function removeFile(error: any, file: any) {
        // console.log('%c Attachments.removeFile file: %O', 'color: maroon;', file);
        // console.log('%c Attachments.removeFile files: %O', 'color: maroon;', files);
        // console.log('%c Attachments.removeFile thumbnails: %O', 'color: maroon;', thumbnails);
        let newFileList: any[] = [];
        let searchFileid: any = (file && file.id ? file.id : '0');
        let spliceIndexes: number[] = [];
        forEach(files, function(f: any, i: number) {
            if (f.id === searchFileid) {
                spliceIndexes.push(i);
            } else {
                newFileList.push(f);
            }
        });
        if (spliceIndexes.length > 0) {
            spliceIndexes.sort().reverse();
            forEach(spliceIndexes, function(idx: number, i: number) {
                newFileList = files.splice(idx, 1);
            });
        }

        spliceIndexes = [];
        forEach(thumbnails, function(t: AttachmentThumbnail, i: number) {
            if (t.id === searchFileid) {
                spliceIndexes.push(i);
            }
        });
        if (spliceIndexes.length > 0) {
            spliceIndexes.sort().reverse();
            forEach(spliceIndexes, function(idx: number, i: number) {
                thumbnails.splice(idx, 1);
            });
        }
        // console.log('%c Attachments.removeFile thumbnails: %O', 'color: maroon;', thumbnails);
        // console.log('%c Attachments.removeFile newFileList: %O', 'color: maroon;', newFileList);

        setThumbnails(thumbnails);
        setFiles(newFileList);
    }

    async function addFile(error: any, file: any) {
        let url = URL.createObjectURL(file.source);
        let thumbnailIndex = -1;
        if (thumbnails.length > 0) {
            forEach(thumbnails, function(t: AttachmentThumbnail, i: number) {
                if (t.id === file.id) {
                    thumbnailIndex = i;
                }
            });
            }
        // console.log('%c Attachments.addFile thumbnailIndex: %O', 'color: maroon;', thumbnailIndex);
        if (thumbnailIndex >= 0) {
            // console.log('%c Attachments.addFile thumbnailIndex: %O', 'color: red;', thumbnailIndex);
        } else {
            let thumb: AttachmentThumbnail = {
                id: file.id,
                filename: file.filename,
                url: url
            };
            // console.log('%c Attachments.addFile thumb: %O', 'color: red;', thumb);
            if (thumb && thumb.id && thumb.url) thumbnails.push(thumb);
            files.push(file);
        }
        // console.log('%c Attachments.addFile files: %O', 'color: red;', files);
        setFiles(files);
        setThumbnails(thumbnails);
    }

    function getAttachmentList() {
        let attachmentFiles: AttachmentFile[] = [];
        forEach(files, function(f: any, i: number) {
            let thumbnail: AttachmentThumbnail = { id: '', filename: '', url: '' };
            if (thumbnails && thumbnails.length > 0) {
                let chk= find(thumbnails, function(t) { return t.id === f.id });
                if (chk) thumbnail = chk;
            }
            let af: AttachmentFile = {
                id: f.id,
                filename: f.filename,
                fileExtension: f.fileExtension,
                fileType: f.fileType,
                fileThumbnail: thumbnail,
                filenameWithoutExtension: f.filenameWithoutExtension,
                fileSize: f.fileSize,
                file: f,
                Title: f.filenameWithoutExtension
            };
            attachmentFiles.push(af);
        });
        return attachmentFiles;
    }

    function attachAttachments() {
        // console.log('%c attachAttachments files: %O', 'color: maroon;', files);
        // console.log('%c attachAttachments thumbnails: %O', 'color: maroon;', thumbnails);
        // console.log('%c attachAttachments props: %O', 'color: maroon;', props);
        if (props && props.onAttach) {
            let attachmentFiles: AttachmentFile[] = getAttachmentList();
            props.onAttach(attachmentFiles);
        }
    }

    function cancelAttachments() {
        if (props && props.onCancel) props.onCancel();
    }

    async function connect () {
        try {
            // setIPFS(ipfsHttpClient(multiaddr.current.value));
            setAttachmentTab(0);
        } catch (ex: any) {
            alert('Error connecting to IPFS.  Message: ' + ex.message);
        }
    }

    async function saveToIpfs (files: FileList) {
        // console.log('%c saveToIpfs files: %O', 'color: red;', files);
        try {
            // forEach(files, function(file: any) {
            //     const added = await ipfs.add(
            //         file,
            //         { progress: (prog: any) => console.log(`received: ${prog}`) }
            //     )
            //     // console.log(added)
            // });
            let file = (files && files.length > 0 ? files[0] : null);
            if (file !== null) {
                const fileDetails = {
                    path: file.name,
                    content: file
                }
                const options = {
                    url: rootIPFSUrl,
                    progress: (prog: any) => console.log(`received: ${prog}`)
                }
                const added = await ipfs.add(fileDetails, options);
                // console.log('%c saveToIpfsWithFilename added: %O', 'color: red;', added);
                setAddedFileHash(added.cid.toString())
            }
        } catch (err) {
            // console.error(err)
        }
    }

    async function saveToIpfsWithFilename (files: FileList) {
        // console.log('%c saveToIpfsWithFilename files: %O', 'color: red;', files);
        try {
            // forEach(files, function(file: any) {
            //     const fileDetails = {
            //         path: file.name,
            //         content: file
            //     }
            //     const options = {
            //         wrapWithDirectory: true,
            //         progress: (prog: any) => console.log(`received: ${prog}`)
            //     }
            //     const added = await ipfs.add(fileDetails, options);
            //     // console.log(added)
            //     setAddedFileHash(added.cid.toString())
            // });
            let file = (files && files.length > 0 ? files[0] : null);
            // console.log('%c saveToIpfsWithFilename file: %O', 'color: red;', file);
            if (file !== null) {
                const fileDetails = {
                    path: file.name,
                    content: file
                };
                // console.log('%c saveToIpfsWithFilename fileDetails: %O', 'color: red;', fileDetails);
                const options = {
                    url: rootIPFSUrl,
                    wrapWithDirectory: true,
                    progress: (prog: any) => console.log(`received: ${prog}`)
                };
                // console.log('%c saveToIpfsWithFilename options: %O', 'color: red;', options);
                const added = await ipfs.add(fileDetails, options);
                // console.log('%c saveToIpfsWithFilename added: %O', 'color: red;', added);
                setAddedFileHash(added.cid.toString());
            }
        } catch (err) {
            // console.error(err)
        }
    }

    function captureFile (event: any) {
        event.stopPropagation()
        event.preventDefault()
        if (keepFilename) {
            saveToIpfsWithFilename(event.target.files)
        } else {
            saveToIpfs(event.target.files)
        }
    }

    function changeAttachmentTypeTab (event: React.ChangeEvent<{}>, newTab: number) {
        setAttachmentTab(newTab);
    }

    function handleSubmit (event: any) {
        event.preventDefault()
    }

    const addCIDAttachment = (evt: any) => {
        // console.log('%c addCIDAttachment selectedAttachedCID: %O', 'color: red;', selectedAttachedCID);
        if (selectedAttachedCID.length <= 0) return false;

        let newAttachedCIDs = attachedCIDs;
        if (newAttachedCIDs.indexOf(selectedAttachedCID) < 0) {
            newAttachedCIDs.push(selectedAttachedCID);
        }
        // console.log('%c addCIDAttachment newAttachedCIDs: %O', 'color: red;', newAttachedCIDs);
        setAttachedCIDs(newAttachedCIDs);
        setSelectedAttachedCID('');
    };

    const changeCIDValue = (evt: any) => {
        // console.log('%c changeCIDValue evt: %O', 'color: red;', evt);
        let trgt = evt.target;
        let cid = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c changeCIDValue cid: %O', 'color: red;', cid);
        setSelectedAttachedCID(cid);
    };

    function removeCID(idxOfCIDToRemove: number) {
        // console.log('%c removeCID idxOfCIDToRemove: %O', 'color: blue;', idxOfCIDToRemove);
        // console.log('%c removeCID attachedCIDs: %O', 'color: blue;', attachedCIDs);
        if (attachedCIDs.length <= 0) return false;

        let newAttachedCIDs = attachedCIDs;
        if (newAttachedCIDs.length > idxOfCIDToRemove) {
            newAttachedCIDs.splice(idxOfCIDToRemove, 1);
        }
        // console.log('%c removeCID newAttachedCIDs: %O', 'color: red;', newAttachedCIDs);
        setAttachedCIDs(newAttachedCIDs);
        forceUpdate(!forcedUpdateState);
    }

    const changeOnlineResourceValue = (evt: any) => {
        // console.log('%c changeOnlineResourceValue evt: %O', 'color: red;', evt);
        let trgt = evt.target;
        let url = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c changeOnlineResourceValue url: %O', 'color: red;', url);
        setSelectedOnlineResource(url);
    };

    function removeOnlineResource(idxOfOnlineResourceToRemove: number) {
        // console.log('%c removeOnlineResource idxOfOnlineResourceToRemove: %O', 'color: blue;', idxOfOnlineResourceToRemove);
        // console.log('%c removeOnlineResource attachedCIDs: %O', 'color: blue;', attachedCIDs);
        if (onlineResources.length <= 0) return false;

        let newOnlineResources = onlineResources;
        if (newOnlineResources.length > idxOfOnlineResourceToRemove) {
            newOnlineResources.splice(idxOfOnlineResourceToRemove, 1);
        }
        // console.log('%c removeOnlineResource newOnlineResources: %O', 'color: red;', newOnlineResources);
        setOnlineResources(newOnlineResources);
        forceUpdate(!forcedUpdateState);
    }

    const addOnlineResource = (evt: any) => {
        // console.log('%c addOnlineResource selectedOnlineResource: %O', 'color: red;', selectedOnlineResource);
        if (selectedOnlineResource.length <= 0) return false;

        let newOnlineResources = onlineResources;
        if (newOnlineResources.indexOf(selectedOnlineResource) < 0) {
            newOnlineResources.push(selectedOnlineResource);
        }
        // console.log('%c addOnlineResource newOnlineResources: %O', 'color: red;', newOnlineResources);
        setOnlineResources(newOnlineResources);
        setSelectedOnlineResource('');
    };

    const requestFailed = (details: any) => {
        // console.log('requestFailed details: %O', details);
    };

    const fileSuccess = (details: any) => {
        // console.log('fileSuccess details: %O', details);
        // let filesFailed: any[] = (details && details.filesFailed ? details.filesFailed : []);
        // console.log('fileSuccess filesFailed: %O', filesFailed);
        let filesUploaded: any[] = (details && details.filesUploaded ? details.filesUploaded : []);
        // console.log('fileSuccess filesUploaded: %O', filesUploaded);
        let uploadedFile: any = (filesUploaded.length > 0 ? filesUploaded[0] : null);
        // console.log('fileSuccess uploadedFile: %O', uploadedFile);
        let uploadedFileUrl: string = (uploadedFile && uploadedFile.url ? uploadedFile.url : '');
        // console.log('fileSuccess uploadedFileUrl: %O', uploadedFileUrl);
        // console.log('fileSuccess selectedProfile: %O', selectedProfile);

        let attachment: any = formAttachment;
        // console.log('%c changeAttachmentValue attachment: %O', 'color: maroon;', attachment);
        if (!attachment) {
            let structure: any = null;
            if (data) {
                let mediatorContext = data.find((x: any) => x.mediator === 'Attachments' );
                // console.log('%c changeAttachmentValue mediatorContext: %O', 'color: red;', mediatorContext);
                if (mediatorContext && mediatorContext.structure) structure = mediatorContext.structure;
            }
            // console.log('%c changeAttachmentValue structure: %O', 'color: green;', structure);
    
            let newAttachment: UdicciRecord = new UdicciRecord('Attachments', {}, structure);
            // console.log('%c changeAttachmentValue newAttachment: %O', 'color: maroon;', newAttachment);
            newAttachment.data.CreatedByUserId = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
            attachment = newAttachment;
        }

        let settings: any = {};
        if (attachment.data.jsonSettings) Object.assign(settings, attachment.data.jsonSettings);
        if (!settings) settings = {};
        settings.details = uploadedFile;
        // console.log('fileSuccess settings: %O', settings);
        attachment.data.jsonSettings = settings;
        attachment.data.SourceUrl = uploadedFileUrl;

        try {
            attachment.data.Settings = JSON.stringify(attachment.data.jsonSettings);
        } catch (e: any) {

        }

        attachment.isDirty = true;

        setFormAttachment(attachment);
        setShowAttachmentForm(true);
        forceUpdate(!forcedUpdateState);
    };

    let fileTags: any = { platform: 'Udicci.IT', filename: (file: any) => file.name };
    if (profile && profile.DisplayName) fileTags.profile = profile.DisplayName;
    if (profile && profile.ProfileUrl) fileTags.url = profile.ProfileUrl;
    if (pathname) fileTags.pathname = pathname;
    if (hash) fileTags.hash = hash;

    const client = filestack.init(profileFileStackApiKey);
    const options: PickerOptions = {
        accept: ['image/*', '.pdf'],
        fromSources: ["local_file_system","url","googlephotos","googledrive","instagram","facebook"],
        uploadConfig: {
            retry: 5,
            timeout: 60000,
            tags: fileTags
        },
        onFileUploadFailed: requestFailed,
        onUploadDone: (details: any) => fileSuccess(details),
    };

    const openFilePicker = (evt: any) => {
        // console.log('openFilePicker picker: %O', picker);
        // console.log('client.picker options: %O', options);
        client.picker(options).open();
    };

    const changeAttachmentValue = (fieldName: any | null, evt: any) => {
        // console.log('%c changeAttachmentValue fieldName: %O', 'color: red;', fieldName);
        // console.log('%c changeAttachmentValue evt: %O', 'color: red;', evt);
        // console.log('%c changeAttachmentValue recordAttachments: %O', 'color: red;', recordAttachments);
        // console.log('%c udicciContext: %O', 'color: red;', udicciContext);
        if (!udicciRecord) return null;

        // let { data } = udicciContext.state;
        // console.log('%c changeAttachmentValue data: %O', 'color: maroon;', data);
        // console.log('%c changeAttachmentValue udicci: %O', 'color: maroon;', udicci);

        let structure: any = null;
        if (data) {
            let mediatorContext = data.find((x: any) => x.mediator === 'Attachments' );
            // console.log('%c changeAttachmentValue mediatorContext: %O', 'color: red;', mediatorContext);
            if (mediatorContext && mediatorContext.structure) structure = mediatorContext.structure;
        }
        // console.log('%c changeAttachmentValue structure: %O', 'color: green;', structure);

        // // convert fieldName to UdicciMediatorField object
        let fields: any = null;
        if (structure) {
            fields = (structure.UdicciMediatorFields ? structure.UdicciMediatorFields : null);
        }
        // console.log('%c changeAttachmentValue fields: %O', 'color: maroon;', fields);

        let field: any = null;
        if (fields && fields.length > 0) {
            field = fields.find((x: any) => x.JsonFieldName === fieldName );
        }
        // console.log('%c changeAttachmentValue field: %O', 'color: maroon;', field);

        if (field) {
            // console.log('%c changeAttachmentValue evt: %O', 'color: red;', evt);
            // console.log('%c changeAttachmentValue field: %O', 'color: red;', field);
            // console.log('%c changeAttachmentValue mediaBit: %O', 'color: red;', mediaBit);

            let trgt = evt.target;
            let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
            // console.log('%c changeAttachmentValue newValue: %O', 'color: red;', newValue);

            let fieldJsonKey = (field && field.JsonFieldName ? field.JsonFieldName : field.Name)
            // console.log('%c changeAttachmentValue fieldJsonKey: %O', 'color: red;', fieldJsonKey);

            let attachment: any = formAttachment;
            // console.log('%c changeAttachmentValue attachment: %O', 'color: maroon;', attachment);

            let updatedAttachments = recordAttachments;
            // console.log('%c Attachments updatedAttachments: %O', 'color: gold;', updatedAttachments);

            let foundRecord: boolean = false;
            let foundRecordInAlternateList: boolean = false;
            let showAttachmentFormAsLastStep: boolean = false;
            if (!attachment) {
                let newData: any = {};
                newData[fieldJsonKey] = newValue;
                let newAttachment: UdicciRecord = new UdicciRecord('Attachments', newData, structure);
                // console.log('%c changeAttachmentValue newAttachment: %O', 'color: maroon;', newAttachment);
                newAttachment.data.CreatedByUserId = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
                attachment = newAttachment;
                // console.log('%c changeAttachmentValue attachment: %O', 'color: maroon;', attachment);
                attachment.isDirty = true;
                attachment = checkForKeyFields(attachment);
                updatedAttachments.push(attachment);
                showAttachmentFormAsLastStep = true;
            } else {
                forEach(updatedAttachments, function(p: UdicciRecord, i: number) {
                    // console.log('%c p: %O', 'color: purple;', p);
                    // console.log('%c mediaBit Index: %O', 'color: purple;', i);
                    // console.log('%c attachment: %O', 'color: purple;', attachment);
                    if (attachment !== null && p.recordId === attachment.recordId) {
                        updatedAttachments[i].data[fieldJsonKey] = newValue;
                        updatedAttachments[i] = checkForKeyFields(updatedAttachments[i]);
                        updatedAttachments[i].isDirty = true;
                        foundRecord = true;
                    }
                });
                attachment.data[fieldJsonKey] = newValue;

                // console.log('%c changeAttachmentValue foundRecord: %O', 'color: maroon;', foundRecord);
                // if (!foundRecord && recordAttachments && recordAttachments.length > 0) {
                //     forEach(recordAttachments, function(p: UdicciRecord, i: number) {
                //         // console.log('%c p: %O', 'color: purple;', p);
                //         // console.log('%c mediaBit Index: %O', 'color: purple;', i);
                //         // console.log('%c attachment: %O', 'color: purple;', attachment);
                //         if (attachment !== null && p.recordId === attachment.recordId) {
                //             recordAttachments[i].data[fieldJsonKey] = newValue;
                //             recordAttachments[i] = checkForKeyFields(recordAttachments[i]);
                //             recordAttachments[i].isDirty = true;
                //             foundRecord = true;
                //             foundRecordInAlternateList = true;
                //         }
                //     });
                // }
            }
            // console.log('%c changeAttachmentValue foundRecord: %O', 'color: maroon;', foundRecord);
            // console.log('%c changeAttachmentValue foundRecordInAlternateList: %O', 'color: maroon;', foundRecordInAlternateList);
            // console.log('%c attachment: %O', 'color: purple;', attachment);
            // console.log('%c updatedAttachments: %O', 'color: purple;', updatedAttachments);

            udicciRecord.attachments = updatedAttachments;
            // setRecord(udicciRecord);

            setFormAttachment(attachment);

            if (showAttachmentFormAsLastStep) setShowAttachmentForm(true);
            forceUpdate(!forcedUpdateState);
        }
    };

    function shareAttachment(overrideAttachment: UdicciRecord | null = null) {
        // console.log('%c shareAttachment props: %O', 'color: blue;', props);
        // console.log('%c shareAttachment formAttachment: %O', 'color: blue;', formAttachment);

        let attachmentToShare: UdicciRecord | null = (overrideAttachment ? overrideAttachment : formAttachment);
        // console.log('%c shareAttachment attachmentToShare: %O', 'color: blue;', attachmentToShare);

        let updatedAttachments = recordAttachments;
        // console.log('%c Attachments updatedAttachments: %O', 'color: gold;', updatedAttachments);

        let foundRecord: boolean = false;
        // let foundRecordInAlternateList: boolean = false;
        if (updatedAttachments && updatedAttachments.length > 0) {
            forEach(updatedAttachments, function(p: UdicciRecord, i: number) {
                // console.log('%c p: %O', 'color: purple;', p);
                if (formAttachment && p.recordId === formAttachment.recordId) {
                    attachmentToShare = p;
                    foundRecord = true;
                }
            });
            // console.log('%c shareAttachment foundRecord: %O', 'color: maroon;', foundRecord);
        }

        if (!attachmentToShare) return false;
        if (!recordMediator) return false;

        // console.log('%c udicciRecord: %O', 'color: purple;', udicciRecord);
        let recordData = (udicciRecord && udicciRecord.data ? udicciRecord.data : udicciRecord);
        // console.log('%c recordData: %O', 'color: purple;', recordData);

        let attachmentData = (attachmentToShare.data ? attachmentToShare.data : attachmentToShare);
        // console.log('%c attachmentData: %O', 'color: purple;', attachmentData);

        let additionalRequestSettings: any = {
            socialSolutionId: udicci.socialSolutionDefaultMe,
            onSuccess: shareAttachmentCompleted,
            onError: shareAttachmentFailed
        };
        if (udicciRecord && attachmentData.UdicciRecordId <= 0) {
            let relationshipChangesJson = JSON.stringify([{
                "Add": "Parent",
                "RecordMediator": 'Attachments',
                "RecordId": (attachmentData.UdicciRecordId ? attachmentData.UdicciRecordId : 0),
                "RelatedMediator": recordMediator,
                "RelatedRecordId": recordData.UdicciRecordId,
                "Priority": -1
            }]);
            additionalRequestSettings['RelationshipChanges'] = relationshipChangesJson;
        }
        // console.log('%c shareAttachment additionalRequestSettings: %O', 'color: red;', additionalRequestSettings);
        // console.log('%c shareAttachment attachmentToShare: %O', 'color: red;', attachmentToShare);

        let okToContinue = true;
        if (okToContinue) {
            setSavingAttachment(true);
            saveAttachment(attachmentToShare, additionalRequestSettings);
        } else {
            setSavingAttachment(false);
        }
    }

    const shareAttachmentCompleted = (result: any, request: any, settings: any) => {
        // console.log('%c shareAttachmentCompleted result: %O', 'color: red;', result);
        // console.log('%c shareAttachmentCompleted request: %O', 'color: red;', request);
        // console.log('%c shareAttachmentCompleted settings: %O', 'color: red;', settings);

        setSavingAttachment(false);

        let message: string = (result && result.Message ? result.Message : '');

        if (message) {
            setFormMessage(message);
        } else {
            setFormAttachment(null);
            setShowAttachmentForm(false);
            setCheckAttachments(true);
            refreshAttachments();
        }
    }

    const shareAttachmentFailed = (result: any) => {
        // console.log('%c shareAttachmentFailed result: %O', 'color: red;', result);
        setSavingAttachment(false);
    };

    function refreshAttachments() {
        // console.log('%c refreshAttachments checkAttachments: %O', 'color: red;', checkAttachments);
        if (checkAttachments) {
            // console.log('%c refreshAttachments checkAttachments: %O', 'color: red;', checkAttachments);
            setCheckAttachments(false);
            checkForRecordAttachments();
        }
    }

    const openAttachmentForm = (attachment: any, evt: any) => {
        // console.log('%c openAttachmentForm evt: %O', 'color: red;', evt);
        // console.log('%c openAttachmentForm attachmentId: %O', 'color: red;', attachmentId);
        setOpenAttachmentMenu(false);
        setAnchorMenuEl(null);
        setShowAttachmentForm(true);
        setFormAttachment(attachment);
    };

    const deleteAttachmentTrigger = (attachmentId: number, evt: any) => {
        // console.log('%c deleteAttachmentTrigger attachmentId: %O', 'color: red;', attachmentId);
        setOpenAttachmentMenu(false);
        setAnchorMenuEl(null);
        setDeleteAttachmentId(attachmentId);
    };

    const confirmDelete = (evt: any) => {
        // console.log('%c confirmDelete udicciRecord: %O', 'color: maroon;', udicciRecord);
        // console.log('%c confirmDelete deleteAttachmentId: %O', 'color: maroon;', deleteAttachmentId);

        // console.log('%c Attachments recordAttachments: %O', 'color: blue;', recordAttachments);
        let updatedAttachments = recordAttachments;
        // console.log('%c Attachments updatedAttachments: %O', 'color: blue;', updatedAttachments);

        let attachment: any = null;
        if (updatedAttachments && updatedAttachments.length > 0) {
            attachment = updatedAttachments.find((x: any) => x.recordId === deleteAttachmentId );
        }
        // console.log('%c confirmDelete attachment: %O', 'color: maroon;', attachment);

        if (attachment && attachment.isSaving === false) {
            if (attachment.recordId > 0) {
                setProcessingDelete(true);

                // console.log('%c confirmDelete attachment: %O', 'color: maroon;', attachment);
                deleteAttachment(attachment, { onSuccess: deleteAttachmentCompleted });
            } else {
                // remove it from the list of updatedAttachments
                // console.log('%c confirmDelete updatedAttachments: %O', 'color: maroon;', updatedAttachments);
                updatedAttachments = filter(updatedAttachments, (p: any) => {
                    return p.recordId.toString() !== deleteAttachmentId.toString();
                });
                // console.log('%c confirmDelete updatedAttachments: %O', 'color: maroon;', updatedAttachments);
                udicciRecord.attachments = updatedAttachments;
                setRecord(udicciRecord);
                setDeleteAttachmentId(-1);
            }
        }
    }

    const deleteAttachmentCompleted = (result: any, request: any, settings: any) => {
        // console.log('%c deleteAttachmentCompleted result: %O', 'color: maroon;', result);
        // console.log('%c deleteAttachmentCompleted request: %O', 'color: maroon;', request);
        // console.log('%c deleteAttachmentCompleted settings: %O', 'color: maroon;', settings);

        let updatedAttachments = recordAttachments;
        // console.log('%c Attachments updatedAttachments: %O', 'color: blue;', updatedAttachments);

        updatedAttachments = filter(updatedAttachments, (p: any) => {
            return p.recordId.toString() !== deleteAttachmentId.toString();
        });
        // console.log('%c deleteAttachmentCompleted updatedAttachments: %O', 'color: maroon;', updatedAttachments);
        udicciRecord.attachments = updatedAttachments;
        setRecord(udicciRecord);
        setDeleteAttachmentId(-1);

        setProcessingDelete(false);
        setCheckAttachments(true);
        refreshAttachments();
    }

    const showAttachmentMenu = (evt: React.MouseEvent<HTMLButtonElement>) => {
        setOpenAttachmentMenu(true);
        setAnchorMenuEl(evt.currentTarget);
    };

    const hideAttachmentMenu = (evt: any) => {
        setOpenAttachmentMenu(false);
        setAnchorMenuEl(null);
    };

    const onVideoStarted = () => {
        // console.log('%c onVideoStarted videoFile: %O', 'color: red;', videoFile);
        // console.log('%c onVideoStarted videoProgress: %O', 'color: red;', videoProgress);
        setVideoStateEnded(false);
        setVideoProgress(null);
    };

    const onVideoEnded = () => {
        // console.log('%c onVideoEnded videoFile: %O', 'color: red;', videoFile);
        // console.log('%c onVideoEnded videoProgress: %O', 'color: red;', videoProgress);
        setVideoStateEnded(true);
        setVideoProgress(null);
    };

    const onVideoError = (errorDetails: any) => {
        // console.log('%c videoError errorDetails: %O', 'color: red;', errorDetails);
    };

    const onVideoProgress = (progressStats: any) => {
        // console.log('%c onVideoProgress progressStats: %O', 'color: red;', progressStats);
        setVideoProgress({ stats: progressStats });
    };

    const onVideoDuration = (durationOfVideoInseconds: any) => {
        // console.log('%c onVideoDuration durationOfVideoInseconds: %O', 'color: red;', durationOfVideoInseconds);
    };

    // console.log('%c Attachments checkAttachments: %O', 'color: red;', checkAttachments);
    if (checkAttachments) refreshAttachments();

    // console.log('%c Attachments files: %O', 'color: blue;', files);
    let hasMinAttachmentReqs = (files && files.length > 0 ? true : false);
    if (attachedCIDs && attachedCIDs.length > 0) hasMinAttachmentReqs = true;

    let defaultAttachmentCount = (defaultAttachments && defaultAttachments.length > 0 ? defaultAttachments.length : 0);
    if (!hasMinAttachmentReqs && defaultAttachmentCount > 0) {
        forEach(defaultAttachments, function(af: any, i: number) {
            files.push(af.file);
        });
    }

    let hasDefaultFiles = (defaultAttachmentCount > 0 ? true : false);

    let aId = (formAttachment && formAttachment.recordId ? formAttachment.recordId : 0);
    let aTitle = (formAttachment && formAttachment.data && formAttachment.data.Title ? formAttachment.data.Title : '');
    let aDescription = (formAttachment && formAttachment.data && formAttachment.data.Description ? formAttachment.data.Description : '');
    let aSourceUrl = (formAttachment && formAttachment.data && formAttachment.data.SourceUrl ? formAttachment.data.SourceUrl : '');

    let tabContentElement: any = null;
    let useFilePond = false;

    let selectedTab = attachmentTab;
    // if ((selectedTab === 0 || selectedTab === 1) && !ipfs) selectedTab = 3;

    let sourcePreviewElement: any = null;
    if (aSourceUrl) {
        // let isCastr = (aSourceUrl.startsWith('https://player.castr') ? true : false);
        // let isVdo = (aSourceUrl.startsWith('https://vdo.ninja') ? true : false);
        let isYouTube = ((aSourceUrl.startsWith('https://youtube.com') 
                          || aSourceUrl.startsWith('https://www.youtube.com')
                          || aSourceUrl.startsWith('https://youtu.be')
                          || aSourceUrl.startsWith('https://www.youtu.be')) ? true : false);

        if (isYouTube) {
            let videoUrlElement: any = null;
            let videoLinkElement: any = null;
            let videoId = '';
            let showPlayer = false;
            videoUrlElement = (<Typography variant="subtitle2">{aSourceUrl}</Typography>);
            videoLinkElement = (
                <IconButton size="small" color="secondary" sx={{ marginLeft: '8px' }} onClick={(evt: any) => udicci.openUrl(aSourceUrl, 'blank')}>
                    <Icon>open_in_new</Icon>
                </IconButton>
            );

            showPlayer = ReactPlayer.canPlay(aSourceUrl);

            if (aSourceUrl.indexOf('www.youtube.com/embed') >= 0) {
                // //www.youtube.com/embed/rv_3WTDxMVE?rel=0
                let workingVideoUrl = aSourceUrl;
                // console.log('%c workingVideoUrl: %O', 'color: blue;', workingVideoUrl);
                videoId = workingVideoUrl.replace('//', '').split('/')[2];
                // console.log('%c videoId: %O', 'color: blue;', videoId);
                let questionMarkPosition = videoId.indexOf('?');
                if(questionMarkPosition != -1) {
                    videoId = videoId.substring(0, questionMarkPosition);
                }
            } else {
                // console.log('%c aSourceUrl: %O', 'color: maroon;', aSourceUrl);
                let vequalPosition = (aSourceUrl ? aSourceUrl.indexOf('v=') : -1) ;
                if (aSourceUrl && vequalPosition !== -1) {
                    videoId = aSourceUrl.split('v=')[1];
                }
                // console.log('%c videoId: %O', 'color: maroon;', videoId);
                let ampersandPosition = (videoId ? videoId.indexOf('&') : -1) ;
                // console.log('%c ampersandPosition: %O', 'color: maroon;', ampersandPosition);
                if(ampersandPosition !== -1) {
                    videoId = videoId.substring(0, ampersandPosition);
                }
                if (!videoId) {
                    let lastSlash = (aSourceUrl ? aSourceUrl.lastIndexOf('\/') : -1) ;
                    // console.log('%c lastSlash: %O', 'color: maroon;', lastSlash);
                    if (lastSlash !== -1) {
                        videoId = aSourceUrl.substring(lastSlash + 1);
                        // console.log('%c videoId: %O', 'color: maroon;', videoId);
                    }
                }
            }

            let youtubeConfigSettings = {
                youtube: {
                    playerVars: {
                        autoplay: false,
                        controls: true,
                        disableKeyboard: true,
                        allowFullscreen: false,
                        // muted: true,
                        modestBranding: false,
                        showRelatedVideos: false,
                        showInfo: false
                    }
                }
            };

            let videoPlayerElement: any = null;

            sourcePreviewElement = (
                <Box sx={{ display: 'flex', width: '350px' }}>
                    <ReactPlayer
                        url={aSourceUrl}
                        playing={false}
                        controls={false}
                        loop={false}
                        width={"100%"}
                        config={youtubeConfigSettings}
                        onStart={() => onVideoStarted()}
                        onEnded={() => onVideoEnded()}
                        onProgress={(progressStats: any) => onVideoProgress(progressStats)}
                        onDuration={(durationOfVideoInseconds: any) => onVideoDuration(durationOfVideoInseconds)}
                        onError={(errorDetails: any) => onVideoError(errorDetails)}
                    />
                </Box>
            );
        } else {
            let aSettings: any = (formAttachment && formAttachment.data && formAttachment.data.jsonSettings ? formAttachment.data.jsonSettings : null);
            if (aSettings && aSettings.details && aSettings.details.mimetype === 'application/pdf') {
                let filename: string = (aSettings.details ? aSettings.details.filename : '');
                let typoSettings: any = {
                    variant: 'clickableSubTitle2',
                    component: 'span',
                    sx: { paddingLeft: '8px' },
                    onClick: (evt: any) => udicciHelpers.openUrl(aSourceUrl, '_blank')
                };
                sourcePreviewElement = (
                    <Box sx={{ display: 'flex', width: '150px', margin: '8px' }}>
                        <Typography {...typoSettings}>PDF:</Typography>
                        <Typography {...typoSettings}>{filename}</Typography>
                    </Box>
                );
            } else {
                sourcePreviewElement = (
                    <Box sx={{ display: 'flex', width: '150px' }}>
                        <img src={aSourceUrl} style={{ width: '150px' }} />
                    </Box>
                );
            }
        }
    }

    switch (selectedTab) {
        case 0: // Upload to IPFS
            if (useFilePond) {
                tabContentElement = (
                    <FilePond files={files}
                            onupdatefiles={updateFiles}
                            onaddfile={addFile}
                            onremovefile={removeFile}
                            allowMultiple={true}
                            maxFiles={3}
                            dropOnPage
                            name="files"
                            dropValidation
                    />              
                );
            } else {
                tabContentElement = (
                    <Box sx={udicciClasses.attachmentFormContainer}>
                        <form id='capture-media' onSubmit={handleSubmit}>
                            <input type='file' name='input-file' id='input-file' onChange={captureFile} /><br/>
                            <label htmlFor='keep-filename'>
                                <input type='checkbox' id='keep-filename' name='keep-filename' checked={keepFilename} onChange={(evt: any) => setKeepFilename(evt.target.checked)} />
                                keep filename
                            </label>
                        </form>
                        <div>
                            <a id="gateway-link" target='_blank' rel="noreferrer" href={'https://ipfs.io/ipfs/' + addedFileHash}>
                                {addedFileHash}
                            </a>
                        </div>
                    </Box>
                );
            }
            break;
        case 1: // Use IPFS CID
            let ipfsCIDControlPropsTitle: any = {};
            ipfsCIDControlPropsTitle.fullWidth = true;
            // console.log('%c controlPropsTitle: %O', 'color: orange;', controlPropsTitle);

            let ipfsCIDInputPropsTitle: any = {};
            ipfsCIDInputPropsTitle.id = 'ipfs.cid.0';
            ipfsCIDInputPropsTitle.type = 'text';
            ipfsCIDInputPropsTitle.autoFocus = true;
            ipfsCIDInputPropsTitle.value = selectedAttachedCID;
            ipfsCIDInputPropsTitle.onChange = changeCIDValue;

            ipfsCIDInputPropsTitle.endAdornment = (
                <InputAdornment position="end">
                    <IconButton size="small"
                                aria-label="Link CID Attachment"
                                onClick={addCIDAttachment}
                    >
                        <AddIcon />
                    </IconButton>
                </InputAdornment>
            );

            let ipfsCIDFormElement: any = (
                <FormControl {...ipfsCIDControlPropsTitle}>
                    <InputLabel htmlFor={ipfsCIDInputPropsTitle.id} shrink>{'Enter Existing IPFS CID'}</InputLabel>
                    <Input {...ipfsCIDInputPropsTitle} />
                </FormControl>
            );

            let attachedCIDsListElements: any[] = [];
            if (attachedCIDs.length > 0) {
                forEach(attachedCIDs, function(cid: string, i: number) {
                    let boxProps = {
                        sx: udicciClasses.attachmentFormContentContainer,
                        key: 'attached.cid.' + i.toString()
                    };
                    attachedCIDsListElements.push(
                        <Box {...boxProps}>
                            <Typography component="span" variant="body2" color="secondary">
                                {cid}
                            </Typography>

                            <IconButton size="small"
                                        aria-label="Remove CID Attachment"
                                        onClick={(evt: any) => { removeCID(i); }}
                            >
                                <RemoveIcon />
                            </IconButton>
                        </Box>
                    )
                });
            }

            tabContentElement = (
                <Box sx={udicciClasses.attachmentFormContentContainer}>
                    {attachedCIDsListElements}
                    {ipfsCIDFormElement}
                </Box>
            );
            break;
        case 2: // Online Resource
            let controlPropsTitle: any = {};
            controlPropsTitle.fullWidth = true;
            // console.log('%c controlPropsTitle: %O', 'color: orange;', controlPropsTitle);

            let inputPropsSourceUrl: any = {};
            inputPropsSourceUrl.type = 'text';
            inputPropsSourceUrl.placeholder = 'Enter Existing Resource URL.';
            inputPropsSourceUrl.variant = "outlined";
            inputPropsSourceUrl.fullWidth = true;
            inputPropsSourceUrl.value = aSourceUrl;
            inputPropsSourceUrl.onChange = (evt: any) => {changeAttachmentValue('SourceUrl', evt)};
            // inputPropsSourceUrl.disabled = (savingAttachment ? true : false);
            // console.log('%c inputPropsSourceUrl: %O', 'color: orange;', inputPropsSourceUrl);

            let endAdornmentElement: any = (
                <InputAdornment position="end">
                    <IconButton size="small" aria-label="Link Online Resource Attachment" onClick={openFilePicker}>
                        <AddIcon />
                    </IconButton>
                </InputAdornment>
            );
            // inputPropsSourceUrl.endAdornment = endAdornmentElement;
            inputPropsSourceUrl.InputProps = { endAdornment: endAdornmentElement };

            // let onlineResourceFormElement: any = (
            //     <FormControl {...controlPropsTitle}>
            //         <Input {...inputPropsSourceUrl} />
            //     </FormControl>
            // );

            // let onlineResourcesListElements: any[] = [];
            // if (onlineResources.length > 0) {
            //     forEach(onlineResources, function(onlineResource: string, i: number) {
            //         let boxProps = {
            //             sx: udicciClasses.attachmentFormContentContainer,
            //             key: 'attached.online.resource.' + i.toString()
            //         };
            //         onlineResourcesListElements.push(
            //             <Box {...boxProps}>
            //                 <Typography component="span" variant="body2" color="secondary">
            //                     {onlineResource}
            //                 </Typography>

            //                 <IconButton size="small"
            //                             aria-label="Remove Online Resource"
            //                             onClick={(evt: any) => { removeOnlineResource(i); }}
            //                 >
            //                     <RemoveIcon />
            //                 </IconButton>
            //             </Box>
            //         )
            //     });
            // }

            // tabContentElement = ( <TextField {...inputPropsSourceUrl} /> );
            tabContentElement = (
                <Fragment>
                    {sourcePreviewElement}
                    <TextField {...inputPropsSourceUrl} /> 
                </Fragment>
            );

            // tabContentElement = (
            //     <Box sx={udicciClasses.attachmentFormContentContainer}>
            //         {onlineResourcesListElements}
            //         {onlineResourceFormElement}
            //     </Box>
            // );
            break;
        case 3: // IPFS Config
            tabContentElement = (
                <Box sx={udicciClasses.attachmentFormContentContainer}>
                    <h1>Enter the Address for an IPFS node</h1>
                    <form>
                        <input id="connect-input" type="text" defaultValue={'/ip4/127.0.0.1/tcp/5001'} ref={multiaddr} />
                        <input id="connect-submit" type="button" value="Connect" onClick={connect} />
                    </form>
                </Box>
            );
            break;
    }

    // let attachmentTabViewElement: any = (
    //     <Tabs
    //         value={selectedTab}
    //         indicatorColor="primary"
    //         textColor="primary"
    //         onChange={changeAttachmentTypeTab}
    //     >
    //         <Tab label="IPFS Upload" />
    //         <Tab label="IPFS CID" />
    //         <Tab label="Online Resource" />
    //         <Tab icon={(<SettingsIcon />)} classes={{ root: 'settingsIcon' }} />
    //     </Tabs>
    // );

    let inputPropsTitle: any = {};
    inputPropsTitle.type = 'text';
    inputPropsTitle.placeholder = 'Enter a title for the attachment.';
    inputPropsTitle.variant = "outlined";
    inputPropsTitle.fullWidth = true;
    inputPropsTitle.value = (showAttachmentForm && aId <= 0 ? aTitle : '');
    inputPropsTitle.onChange = (evt: any) => {changeAttachmentValue('Title', evt)};
    // inputPropsTitle.disabled = (savingAttachment ? true : false);
    // console.log('%c inputPropsTitle: %O', 'color: orange;', inputPropsTitle);

    let userSocialIconAvatar: any = null;
    if (userSocialIcon) {
        userSocialIconAvatar = (
            <SocialIconContainer>
                <Avatar src={userSocialIcon}
                        component="span"
                        variant="rounded"
                        classes={{ root: 'creatorSocialIconRoot', img: 'creatorSocialIcon' }}
                />
            </SocialIconContainer>
        );
    }

    let attachmentSaveFormButton: any = null;
    let attachmentCancelFormButton: any = null;
    let attachmentDescription: any = null;
    if (showAttachmentForm === true && aId <= 0) {
        // console.log('%c formAttachment: %O', 'color: maroon;', formAttachment);
        let inputPropsDescription: any = {};
        inputPropsDescription.type = 'text';
        inputPropsDescription.fullWidth = true;
        inputPropsDescription.multiline = true;
        inputPropsDescription.minRows = 2;
        inputPropsDescription.maxRows = 15;
        inputPropsDescription.placeholder = 'Write a description for the attachment.';
        inputPropsDescription.variant = "outlined";
        inputPropsDescription.value = aDescription;
        inputPropsDescription.onChange = (evt: any) => {changeAttachmentValue('Description', evt)};
        inputPropsDescription.disabled = (savingAttachment ? true : false);

        attachmentDescription = ( <TextField {...inputPropsDescription} /> );
        attachmentCancelFormButton = (
            <Button onClick={(evt: any) => cancelAttachments()} 
                    color="info" 
                    variant="outlined" 
                    disabled={(savingAttachment ? true : false)}
            >
                Cancel
            </Button>
        );
        attachmentSaveFormButton = (
            <Button onClick={(evt: any) => shareAttachment()} 
                    color="secondary" 
                    variant="outlined" 
                    disabled={(formAttachment && (savingAttachment || !formAttachment.isDirty) ? true : false)}
            >
                Save
            </Button>
        );
    }

    let formMessageElement: any = null;
    if (formMessage && showAttachmentForm === true) {
        formMessageElement = (
            <Typography variant="errorMessage" component="div">
                {formMessage}
            </Typography>
        );
    }

    let newAttachmentFormElevation: number = 0;
    let attachmentButtonsElement: any = null;
    if (showAttachmentForm && aId <= 0) {
        newAttachmentFormElevation = 5;
        attachmentButtonsElement = (
            <Box sx={udicciClasses.attachmentButtonsContainer}>
                {attachmentCancelFormButton}
                {attachmentSaveFormButton}
            </Box>
        );
    }

    let attachmentMessageElement: any = null;
    let recordAttachmentElements: any[] = [];
    if (recordHasAttachments && recordAttachments.length > 0) {
        // console.log('%c recordAttachments: %O', 'color: green;', recordAttachments);
        recordAttachments.forEach(function(attchmnt: UdicciRecord) {
            // console.log('%c attchmnt: %O', 'color: purple;', attchmnt);
            // console.log('%c mediaBit Index: %O', 'color: purple;', i);
            const attachmentId = (attchmnt.recordId ? attchmnt.recordId : 0);
            // console.log('%c attachmentId: %O', 'color: red;', attachmentId);
            if (!attachmentId && formAttachment && formAttachment.recordId === 0) return true;

            const attachmentTitle = (attchmnt.data && attchmnt.data.Title ? attchmnt.data.Title : '');
            const attachmentSourceUrl = (attchmnt.data && attchmnt.data.SourceUrl ? attchmnt.data.SourceUrl : '');
            // const attachmentDescription = (attchmnt.data && attchmnt.data.Description ? attchmnt.data.Description : '');
            const createdByUserId = (attchmnt.data && attchmnt.data.CreatedByUserId ? attchmnt.data.CreatedByUserId : 0);
            // const creatorDisplayName = (attchmnt.data && attchmnt.data.CreatorDisplayName ? attchmnt.data.CreatorDisplayName : '');
            const creatorSocialIcon = (attchmnt.data && attchmnt.data.CreatorSocialIcon ? attchmnt.data.CreatorSocialIcon : '');
            // const strDateCreated = (attchmnt.data && attchmnt.data.DateCreated ? attchmnt.data.DateCreated : '');
            // const dateCreated = new Date(strDateCreated);
            // console.log('%c dateCreated: %O', 'color: purple;', dateCreated);

            let attachmentPreviewElement: any = null;
            if (attachmentSourceUrl) {
                // let isCastr = (attachmentSourceUrl.startsWith('https://player.castr') ? true : false);
                // let isVdo = (attachmentSourceUrl.startsWith('https://vdo.ninja') ? true : false);
                let isYouTube = ((attachmentSourceUrl.startsWith('https://youtube.com') 
                                  || attachmentSourceUrl.startsWith('https://www.youtube.com')
                                  || attachmentSourceUrl.startsWith('https://youtu.be')
                                  || attachmentSourceUrl.startsWith('https://www.youtu.be')) ? true : false);
        
                if (isYouTube) {
                    let videoUrlElement: any = null;
                    let videoLinkElement: any = null;
                    let videoId = '';
                    let showPlayer = false;
                    videoUrlElement = (<Typography variant="subtitle2">{attachmentSourceUrl}</Typography>);
                    videoLinkElement = (
                        <IconButton size="small" color="secondary" sx={{ marginLeft: '8px' }} onClick={(evt: any) => udicci.openUrl(attachmentSourceUrl, 'blank')}>
                            <Icon>open_in_new</Icon>
                        </IconButton>
                    );
        
                    showPlayer = ReactPlayer.canPlay(attachmentSourceUrl);
        
                    if (attachmentSourceUrl.indexOf('www.youtube.com/embed') >= 0) {
                        // //www.youtube.com/embed/rv_3WTDxMVE?rel=0
                        let workingVideoUrl = attachmentSourceUrl;
                        // console.log('%c workingVideoUrl: %O', 'color: blue;', workingVideoUrl);
                        videoId = workingVideoUrl.replace('//', '').split('/')[2];
                        // console.log('%c videoId: %O', 'color: blue;', videoId);
                        let questionMarkPosition = videoId.indexOf('?');
                        if(questionMarkPosition != -1) {
                            videoId = videoId.substring(0, questionMarkPosition);
                        }
                    } else {
                        // console.log('%c attachmentSourceUrl: %O', 'color: maroon;', attachmentSourceUrl);
                        let vequalPosition = (attachmentSourceUrl ? attachmentSourceUrl.indexOf('v=') : -1) ;
                        if (attachmentSourceUrl && vequalPosition !== -1) {
                            videoId = attachmentSourceUrl.split('v=')[1];
                        }
                        // console.log('%c videoId: %O', 'color: maroon;', videoId);
                        let ampersandPosition = (videoId ? videoId.indexOf('&') : -1) ;
                        // console.log('%c ampersandPosition: %O', 'color: maroon;', ampersandPosition);
                        if(ampersandPosition !== -1) {
                            videoId = videoId.substring(0, ampersandPosition);
                        }
                        if (!videoId) {
                            let lastSlash = (attachmentSourceUrl ? attachmentSourceUrl.lastIndexOf('\/') : -1) ;
                            // console.log('%c lastSlash: %O', 'color: maroon;', lastSlash);
                            if (lastSlash !== -1) {
                                videoId = attachmentSourceUrl.substring(lastSlash + 1);
                                // console.log('%c videoId: %O', 'color: maroon;', videoId);
                            }
                        }
                    }

                    let youtubeConfigSettings = {
                        youtube: {
                            playerVars: {
                                autoplay: false,
                                controls: true,
                                disableKeyboard: true,
                                allowFullscreen: false,
                                // muted: true,
                                modestBranding: false,
                                showRelatedVideos: false,
                                showInfo: false
                            }
                        }
                    };
        
                    attachmentPreviewElement = (
                        <Box sx={{ display: 'flow-root', maxHeight: '150px', maxWidth: '150px' }}>
                            <ReactPlayer
                                url={attachmentSourceUrl}
                                playing={false}
                                controls={false}
                                loop={false}
                                width="100%"
                                height="80%"
                                config={youtubeConfigSettings}
                                onStart={() => onVideoStarted()}
                                onEnded={() => onVideoEnded()}
                                onProgress={(progressStats: any) => onVideoProgress(progressStats)}
                                onDuration={(durationOfVideoInseconds: any) => onVideoDuration(durationOfVideoInseconds)}
                                onError={(errorDetails: any) => onVideoError(errorDetails)}
                            />
                        </Box>
                    );
                } else {
                    // attachmentPreviewElement = (
                    //     <Box sx={{ display: 'flex', width: '150px' }}>
                    //         <Typography variant="subtitle1" component="div">
                    //             {attachmentSourceUrl}
                    //         </Typography>
                    //     </Box>
                    // );
                    let aSettings: any = (attchmnt.data && attchmnt.data.jsonSettings ? attchmnt.data.jsonSettings : null);
                    if (aSettings && aSettings.details && aSettings.details.mimetype === 'application/pdf') {
                        let filename: string = (aSettings.details ? aSettings.details.filename : '');
                        let typoSettings: any = {
                            variant: 'clickableSubTitle2',
                            component: 'span',
                            sx: { paddingLeft: '8px' },
                            onClick: (evt: any) => udicciHelpers.openUrl(attachmentSourceUrl, '_blank')
                        };
                        attachmentPreviewElement = (
                            <Box sx={{ display: 'flex', width: '150px', margin: '8px' }}>
                                <Typography {...typoSettings}>PDF:</Typography>
                                <Typography {...typoSettings}>{filename}</Typography>
                            </Box>
                        );
                    } else {
                        attachmentPreviewElement = (
                            <Box sx={{ display: 'flex', width: '150px' }}>
                                <img src={attachmentSourceUrl} style={{ width: '150px' }} />
                            </Box>
                        );
                    }
                }
            }
        
            let currentUserIsAttachmentOwner: boolean = false;
            if (currentUser && currentUser.UdicciUserId === createdByUserId) {
                currentUserIsAttachmentOwner = true;
            }
            let userAttachmentSocialIconAvatar: any = null;

            let isSelectedAttachment: boolean = (formAttachment && formAttachment.recordId > 0 && formAttachment.recordId === attachmentId ? true : false);
            let isDeleteAttachment: boolean = (deleteAttachmentId >= 0 && deleteAttachmentId === attachmentId ? true : false);
            let showAsDropDown: boolean = false;
            let saveAttachmentButton: any = null;
            let editAttachmentButton: any = null;
            let deleteAttachmentButton: any = null;
            if ((attachmentId && currentUserIsAttachmentOwner) || (attchmnt.permissions && attchmnt.permissions.CanEdit)) {
                let editButtonProps: any = {
                    key: 'edit.attachment.' + attachmentId.toString(),
                    onClick: (evt: any) => openAttachmentForm(attchmnt, evt)
                };
                if (showAsDropDown) {
                    editAttachmentButton = (
                        <MenuItem {...editButtonProps}>
                            <EditIcon aria-label="Edit Attachment" sx={udicciClasses.menuListIcon} />
                            Edit Attachment
                        </MenuItem>
                    );
                } else {
                    editButtonProps.size = "small";
                    editButtonProps.color = "primary";
                    editAttachmentButton = (
                        <IconButton {...editButtonProps}> <EditIcon aria-label="Edit Attachment" /> </IconButton>
                    );
                }
            }

            if ((attachmentId && currentUserIsAttachmentOwner) || (attchmnt.permissions && attchmnt.permissions.CanDelete)) {
                let deleteButtonProps: any = {
                    key: 'delete.attachment.' + attachmentId.toString(),
                    onClick: (evt: any) => deleteAttachmentTrigger(attachmentId, evt),
                    disabled: attchmnt.isSaving
                };
                if (showAsDropDown) {
                    deleteAttachmentButton = (
                        <MenuItem {...deleteButtonProps}>
                            <DeleteIcon aria-label="Delete Attachment" sx={udicciClasses.menuListIcon} />
                            Delete Attachment
                        </MenuItem>
                    );
                } else {
                    deleteButtonProps.size = "small";
                    deleteButtonProps.color = "secondary";
                    deleteAttachmentButton = (
                        <IconButton {...deleteButtonProps}> <DeleteIcon aria-label="Delete Attachment" /> </IconButton>
                    );
                }
            }

            if (currentUserIsAttachmentOwner && attchmnt.isDirty) {
                let saveButtonProps: any = {
                    key: 'save.attachment.' + attachmentId.toString(),
                    onClick: (evt: any) => shareAttachment(attchmnt),
                    disabled: attchmnt.isSaving
                };
                if (showAsDropDown) {
                    saveAttachmentButton = (
                        <MenuItem {...saveButtonProps}>
                            <EditIcon aria-label="Edit Attachment" sx={udicciClasses.menuListIcon} />
                            Save Attachment
                        </MenuItem>
                    );
                } else {
                    saveButtonProps.size = "small";
                    saveButtonProps.color = "primary";
                    saveAttachmentButton = (
                        <IconButton {...saveButtonProps}> <SaveIcon aria-label="Save Attachment" /> </IconButton>
                    );
                }
            }

            let listItemDropDownMenu: any = null;
            // console.log('%c isDeleteAttachment: %O', 'color: purple;', isDeleteAttachment);
            // console.log('%c isSelectedAttachment: %O', 'color: purple;', isSelectedAttachment);
            // console.log('%c currentUserIsAttachmentOwner: %O', 'color: purple;', currentUserIsAttachmentOwner);
            if (!isDeleteAttachment && !isSelectedAttachment && currentUserIsAttachmentOwner && (editAttachmentButton || deleteAttachmentButton)) {
                if (showAsDropDown) {
                    listItemDropDownMenu = (
                        <Box sx={udicciClasses.menuContainer}>
                            <IconButton size="small"
                                color="primary"
                                edge="end"
                                aria-controls={openAttachmentMenu ? 'split-button-menu' : undefined}
                                aria-expanded={openAttachmentMenu ? 'true' : undefined}
                                aria-haspopup="menu"
                                onClick={showAttachmentMenu}
                                component="span"
                            >
                                <AttachmentMenuIcon />
                            </IconButton>
                            <Popper open={openAttachmentMenu} anchorEl={anchorMenuEl} transition>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                        }}
                                        >
                                        <Paper>
                                            <ClickAwayListener onClickAway={hideAttachmentMenu}>
                                                <MenuList id="split-button-menu">
                                                    {saveAttachmentButton}
                                                    {editAttachmentButton}
                                                    {deleteAttachmentButton}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>        
                        </Box>
                    );
                } else {
                    listItemDropDownMenu = (
                        <Box sx={udicciClasses.menuContainer}>
                            {deleteAttachmentButton}
                            {saveAttachmentButton}
                            {editAttachmentButton}
                        </Box>
                    );
                }
            }

            let previewContentElement: any = null;
            let titleContentElement: any = null;
            let descriptionContentElement: any = null;
            let buttonsElement: any = null;

            if (isDeleteAttachment) {
                titleContentElement = (
                    <Typography variant="subtitle1" component="div">
                        {attachmentTitle}
                    </Typography>
                );

                descriptionContentElement = (
                    <Typography variant="body2" component="div">
                        Do you want to delete this attachment?
                    </Typography>
                );

                let cancelFormButton = (
                    <Button onClick={(evt: any) => cancelAttachments()} 
                            color="info" 
                            variant="outlined" 
                            disabled={(savingAttachment || processingDelete ? true : false)}
                    >
                        Cancel
                    </Button>
                );
                let saveFormButton: any = (
                    <Button onClick={confirmDelete} 
                            color="secondary" 
                            variant="outlined" 
                            disabled={(savingAttachment || processingDelete ? true : false)}
                    >
                        {(savingAttachment || processingDelete ? 'deleting ...' : 'Delete')}
                    </Button>
                );
                buttonsElement = (
                    <Box sx={udicciClasses.attachmentButtonsContainer}>
                        {cancelFormButton}
                        {saveFormButton}
                    </Box>
                );
            } else if (!isSelectedAttachment) {
                titleContentElement = (
                    <Typography variant="subtitle1" component="div">
                        {attachmentTitle}
                    </Typography>
                );

                previewContentElement = attachmentPreviewElement;
                // descriptionContentElement = (
                //     <Typography variant="caption" component="div">
                //         {attachmentDescription}
                //     </Typography>
                // );
            } else {
                // console.log('%c attchmnt: %O', 'color: maroon;', attchmnt);
                let pTitle = (attchmnt && attchmnt.data && attchmnt.data.Title ? attchmnt.data.Title : '');
                let pDescription = (attchmnt && attchmnt.data && attchmnt.data.Description ? attchmnt.data.Description : '');

                // if (creatorSocialIcon) {
                //     userAttachmentSocialIconAvatar = (
                //         <Avatar src={creatorSocialIcon}
                //                 variant="rounded"
                //                 component="span"
                //                 classes={{ root: 'creatorSocialIconRoot', img: 'creatorSocialIcon' }}
                //         />
                //     );
                // }

                let titleProps: any = {};
                titleProps.type = 'text';
                titleProps.fullWidth = true;
                titleProps.placeholder = 'Share a attachment...';
                titleProps.variant = "outlined";
                // titleProps.InputProps = {
                //     classes: {
                //         input: udicciClasses.attachmentComment
                //     }
                // };
                // titleProps.classes = {
                //     root: udicciClasses.attachmentCommentRootInline
                // };
                titleProps.value = pTitle;
                titleProps.onChange = (evt: any) => changeAttachmentValue('Title', evt);
                titleProps.disabled = (savingAttachment ? true : false);

                titleContentElement = (
                    <TextField {...titleProps} />
                );
            
                let descriptionProps: any = {};
                descriptionProps.type = 'text';
                descriptionProps.fullWidth = true;
                descriptionProps.multiline = true;
                descriptionProps.minRows = 2;
                descriptionProps.maxRows = 15;
                descriptionProps.placeholder = 'Write a description for your attachment.';
                descriptionProps.variant = "outlined";
                // descriptionProps.InputProps = {
                //     classes: {
                //         root: udicciClasses.attachmentDescription,
                //         input: udicciClasses.attachmentDescription,
                //     }
                // };
                // descriptionProps.classes = {
                //     root: udicciClasses.attachmentDescriptionRoot
                // };
                descriptionProps.value = pDescription;
                descriptionProps.onChange = (evt: any) => changeAttachmentValue('Description', evt);
                descriptionProps.disabled = (savingAttachment ? true : false);

                descriptionContentElement = (
                    <TextField {...descriptionProps} />
                );
                let cancelFormButton = (
                    <Button onClick={(evt: any) => cancelAttachments()} 
                            color="info" 
                            variant="outlined" 
                            disabled={(savingAttachment ? true : false)}
                    >
                        Cancel
                    </Button>
                );
                let saveFormButton: any = (
                    <Button onClick={(evt: any) => shareAttachment()} 
                            color="secondary" 
                            variant="outlined" 
                            disabled={(attchmnt && (savingAttachment || !attchmnt.isDirty) ? true : false)}
                    >
                        {(savingAttachment ? 'saving ...' : 'Save')}
                    </Button>
                );
                buttonsElement = (
                    <Box sx={udicciClasses.attachmentButtonsContainer}>
                        {cancelFormButton}
                        {saveFormButton}
                    </Box>
                );
            }

            let recordAttachmentProps: any = {
                key: 'record.' + recordId.toString() + '.attachment.' + attachmentId.toString(),
                sx: udicciClasses.attachmentItemContainer,
                elevation: 5
            };

            recordAttachmentElements.push(
                <Paper {...recordAttachmentProps}>
                    {listItemDropDownMenu}
                    {userAttachmentSocialIconAvatar}
                    <Box sx={{ display: 'flow-root' }}>
                        {previewContentElement}
                        {titleContentElement}
                        {descriptionContentElement}
                        {buttonsElement}
                    </Box>
                </Paper>
            );
        });
    } else if (checkAttachments) { // !recordHasAttachments && 
        attachmentMessageElement = (<CircularProgress size={20} />);
    } else if (recordHasAttachments && recordAttachments.length <= 0) {
        attachmentMessageElement = (
            <Typography variant="caption" component="div" style={{textAlign: 'center'}}>
                No Attachments shared yet
            </Typography>
        );
    }

    let attachmentFormElement = (
        <Paper elevation={newAttachmentFormElevation} sx={{ background: 'unset' }}>
            <Paper elevation={0} sx={{ background: 'unset', display: 'flow-root' }}>
                {formMessageElement}
                {userSocialIconAvatar}
                <Box sx={{ display: 'flow-root', padding: '8px', paddingTop: '0px' }}>
                    {tabContentElement}
                    {(attachmentDescription !== null ? (<TextField {...inputPropsTitle} />) : null)}
                    {attachmentDescription}
                    {attachmentButtonsElement}
                </Box>
            </Paper>
            <Box>
                {attachmentMessageElement}
                {recordAttachmentElements}
            </Box>
        </Paper>
    );

    return attachmentFormElement;

    // return (
    //     <Paper sx={udicciClasses.attachmentFormContainer}>
    //         <Box sx={udicciClasses.attachmentFormContentContainer}>
    //             {tabContentElement}
    //             <Box sx={udicciClasses.attachmentFormButtonContainer}>
    //                 <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" alignContent="center">
    //                     <Grid item xs={12} md={6}>
    //                         <Button fullWidth onClick={cancelAttachments} color="info" variant="contained">
    //                             Cancel
    //                         </Button>
    //                     </Grid>
    //                     <Grid item xs={12} md={6}>
    //                         <Button fullWidth
    //                                 color={(hasMinAttachmentReqs ? "primary" : "info")}
    //                                 variant="contained"
    //                                 onClick={attachAttachments} 
    //                                 disabled={(hasMinAttachmentReqs || (!hasMinAttachmentReqs && hasDefaultFiles) ? false : true)}>
    //                             Save
    //                         </Button>
    //                     </Grid>
    //                 </Grid>
    //             </Box>
    //         </Box>
    //     </Paper>
    // );
}
