
import { EngagementRequestConfig } from './EngagementRequest/EngagementRequestConfig';
import { EngagementRequestUI } from './EngagementRequest/EngagementRequestUI';
import { EngagementRequestMenu } from './EngagementRequest/EngagementRequestMenuItem';
import { EngagementRequestHelp } from './EngagementRequest/EngagementRequestHelp';

const triggerAction = async (options: any) =>  {
    // console.log('%c EngagementRequest triggerAction options: %O', 'color: blue;', options);
    return false;
}

const submitAction = async (options: any) =>  {
    // console.log('%c EngagementRequest submitAction options: %O', 'color: blue;', options);
    return false;
}

const EngagementRequest: React.FC<any> = (props) => {
    // console.log('%c EngagementRequest props: %O', 'color: blue;', props);

    let configureAction = (props && props.configure === true ? true : false);

    let actionProps: any = {};
    Object.assign(actionProps, props);
    // console.log('%c actionProps: %O', 'color: red;', actionProps);

    let actionElement: any = null;
    if (configureAction) {
        actionElement = (<EngagementRequestConfig {...actionProps} />);
    } else {
        actionElement = (<EngagementRequestUI {...actionProps} />);
    }
    return actionElement;
}

export { EngagementRequest, EngagementRequestMenu, EngagementRequestHelp, triggerAction, submitAction }
