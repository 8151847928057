import React, { Fragment, useState } from 'react';

import {
    Box, Paper, Typography, Tooltip, Button, Icon, IconButton, 
} from '@mui/material';

// import useUdicciRecord from 'src/hooks/useUdicciRecord';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext } from 'src/context/udicci-context'
// import { getUdicciData } from 'src/context/udicci-context';

import { find, forEach, values, sortBy, pluck } from 'underscore';

import { UdicciRecord } from 'src/classes/udicci-record';

import MediatorConfigurationForm from 'src/admin/social-solution-management/mediator-configuration-form';
import FeatureConfigurationForm from 'src/admin/social-solution-management/feature-configuration-form';
import SocialSolutionForm from 'src/admin/social-solution-management/social-solution-admin-form';

const SocialSolutionManagement: React.FC<any> = (props) => {
    // console.log('%c SocialSolutionManagement props: %O', 'color: purple;', props);

    const [uiState, flashUI] = useState<boolean>(false);
    const [selectedSolution, setSelectedSolution] = useState<any>(null);
    const [selectedFeature, setSelectedFeature] = useState<any>(null);
    const [selectedMediator, setSelectedMediator] = useState<any>(null);
    const [editSocialSolution, setEditSocialSolution] = useState<boolean>(false);
    const [preloaded, setPreloaded] = useState<boolean>(false);
    const [showAll, setShowAll] = useState<boolean>(false);
    const [removeSolution, setRemoveSolution] = useState<any>(null);
    // console.log('%c SocialSolutionManagement selectedSolution: %O', 'color: maroon;', selectedSolution);
    // console.log('%c SocialSolutionManagement selectedFeature: %O', 'color: maroon;', selectedFeature);
    // console.log('%c SocialSolutionManagement selectedMediator: %O', 'color: maroon;', selectedMediator);
    // console.log('%c SocialSolutionManagement removeSolution: %O', 'color: maroon;', removeSolution);
    // console.log('%c SocialSolutionManagement showAll: %O', 'color: maroon;', showAll);
    // console.log('%c SocialSolutionManagement editSocialSolution: %O', 'color: maroon;', editSocialSolution);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();

    let { udicci } = udicciContext.state;
    // console.log('%c SocialSolutionManagement udicci: %O', 'color: purple;', udicci);

    var allSocialSolutions: any = udicciHelpers.preloadMediatorData('Social Solutions', udicci.socialSolutionUdicciIT);
    // console.log('%c SocialSolutionManagement allSocialSolutions: %O', 'color: maroon;', allSocialSolutions);

    // we are just preloading the roles data, don't really need it until some of the child components
    udicciHelpers.preloadMediatorData('Roles', udicci.socialSolutionUdicciIT);
    // console.log('%c SocialSolutionManagement profileRoles: %O', 'color: maroon;', profileRoles);

    let { selectedProfile } = udicci;
    // console.log('%c SocialSolutionManagement selectedProfile: %O', 'color: purple; font-weight: bold;', selectedProfile);

    let profile: any = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    // console.log('%c SocialSolutionManagement profile: %O', 'color: purple;', profile);
    let profileDisplayName: string = (selectedProfile && selectedProfile.data.DisplayName ? selectedProfile.data.DisplayName : '');
    // console.log('%c SocialSolutionManagement profileDisplayName: %O', 'color: purple;', profileDisplayName);
    if (!profileDisplayName && selectedProfile && selectedProfile.title) {
        profileDisplayName = selectedProfile.title;
    }

    var profileSolutions: any = (profile && profile.SocialSolutions ? profile.SocialSolutions : null);
    // console.log('%c SocialSolutionManagement profileSolutions: %O', 'color: purple; font-weight: bold;', profileSolutions);

    if (profile && !profile.jsonProfileSettingsJson && profile.ProfileSettingsJson) {
        try {
            profile.jsonProfileSettingsJson = JSON.parse(profile.ProfileSettingsJson);
        } catch (e: any) {

        }
    }

    // var profileSettings = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
    // console.log('%c SocialSolutionManagement profileSettings: %O', 'color: purple;', profileSettings);
    const onClickSocialSolution = (ss: any) => {
        // console.log('%c onClickSocialSolution ss: %O', 'color: blue;', ss);
        setSelectedSolution((selectedSolution && selectedSolution.recordId === ss.recordId ? null : ss));
        setSelectedFeature(null);
        setSelectedMediator(null);
        flashUI(!uiState);
    };

    const onEditSocialSolution = (ss: any) => {
        // console.log('%c onEditSocialSolution ss: %O', 'color: blue;', ss);
        setEditSocialSolution(true);
    };

    const onCancelEditSocialSolution = () => {
        // console.log('%c onCancelEditSocialSolution ss: %O', 'color: blue;', ss);
        setEditSocialSolution(false);
    };

    const onChangeSocialSolution = (updatedSocialSolution: UdicciRecord) => {
        // console.log('%c onChangeSocialSolution updatedSocialSolution: %O', 'color: blue;', updatedSocialSolution);
        flashUI(!uiState);
    };

    const onClickFeature = (ss: any, ftre: any) => {
        // console.log('%c onClickFeature ss: %O', 'color: blue;', ss);
        // console.log('%c onClickFeature ftre: %O', 'color: blue;', ftre);
        setSelectedSolution(ss);
        setSelectedFeature((selectedFeature && selectedFeature.UdicciRecordId === ftre.UdicciRecordId ? null : ftre));
        setSelectedMediator(null);
        // flashUI(!uiState);
    };

    const onClickMediator = (ss: any, ftre: any, med: any) => {
        // console.log('%c onClickMediator ss: %O', 'color: blue;', ss);
        // console.log('%c onClickMediator ftre: %O', 'color: blue;', ftre);
        // console.log('%c onClickMediator med: %O', 'color: blue;', med);
        setSelectedSolution(ss);
        setSelectedFeature(ftre);
        setSelectedMediator((selectedMediator && selectedMediator.id === med.id ? null : med));

        // console.log('%c onClickMediator med.name: %O', 'color: hotpink;', med.name);
        if (med && med.name) {
            let structure: any = udicciHelpers.getMediatorStructure(med.name);
            // console.log('%c onClickMediator structure: %O', 'color: hotpink;', structure);
            if (!structure) {
                let requestMediators: string[] = [];
                requestMediators.push(med.name);
                // console.log('%c onClickMediator requestMediators: %O', 'color: hotpink;', requestMediators);
                udicci.getMediatorStructures(requestMediators);
            }
        }

        flashUI(!uiState);
    };

    const onCloseMediator = () => {
        setSelectedFeature(null);
        setSelectedMediator(null);
    };

    const onCloseFeature = () => {
        setSelectedFeature(null);
        setSelectedMediator(null);
    };

    const onCloseSocialSolution = () => {
        setSelectedSolution(null);
        setSelectedFeature(null);
        setSelectedMediator(null);
    };

    const toggleShowAll = () => {
        // console.log('%c SocialSolutionManagement toggleShowAll showAll: %O', 'color: maroon;', showAll);
        // console.log('%c SocialSolutionManagement toggleShowAll allSocialSolutions: %O', 'color: maroon;', allSocialSolutions);
        if (!showAll && !preloaded) {
            udicciHelpers.preloadMediatorData('Social Solutions', udicci.socialSolutionUdicciIT, true);
            setPreloaded(true);
            setShowAll(true);
        } else if (!showAll) {
            setShowAll(true);
        } else {
            setShowAll(false);
        }
    };

    const onRemoveSocialSolution = (ss: any) => {
        // console.log('%c onRemoveSocialSolution ss: %O', 'color: blue;', ss);
        setRemoveSolution(ss);
    };

    const onCancelRemoveSocialSolution = () => {
        // console.log('%c onCancelRemoveSocialSolution ss: %O', 'color: blue;', ss);
        setRemoveSolution(null);
    };

    const onConfirmRemoveSocialSolution = () => {
        // console.log('%c onConfirmRemoveSocialSolution removeSolution: %O', 'color: red;', removeSolution);
        if (!removeSolution || !selectedProfile) return false;

        var removeSolutionId: number = (removeSolution.recordId ? removeSolution.recordId : 0);
        // console.log('%c onConfirmRemoveSocialSolution removeSolutionId: %O', 'color: purple;', removeSolutionId);

        var profileSolutions: any[] = (profile && profile.SocialSolutions ? profile.SocialSolutions : []);
        // console.log('%c onConfirmRemoveSocialSolution profileSolutions: %O', 'color: red; font-weight: bold;', profileSolutions);

        if (profileSolutions) {
            let newProfileSolutions: any[] = [];
            forEach(profileSolutions, (ps: any) => {
                if (ps.recordId !== removeSolutionId) newProfileSolutions.push(ps);
            });
            // console.log('%c onConfirmRemoveSocialSolution newProfileSolutions: %O', 'color: red; font-weight: bold;', newProfileSolutions);
            profile.SocialSolutions = newProfileSolutions;
        }

        if (profile && !profile.jsonProfileSettingsJson && profile.ProfileSettingsJson) {
            try {
                profile.jsonProfileSettingsJson = JSON.parse(profile.ProfileSettingsJson);
            } catch (e: any) {
    
            }
        }
        // console.log('%c onConfirmRemoveSocialSolution profile.jsonProfileSettingsJson: %O', 'color: red;', profile.jsonProfileSettingsJson);

        var profileSettings: any = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : {});
        // console.log('%c onConfirmRemoveSocialSolution profileSettings: %O', 'color: purple;', profileSettings);
        var socialSolutionSettings: any = (profileSettings && profileSettings.socialSolutionSettings ? profileSettings.socialSolutionSettings : {});
        // console.log('%c onConfirmRemoveSocialSolution socialSolutionSettings: %O', 'color: purple;', socialSolutionSettings);
        var archives: any = (profileSettings && profileSettings.archives ? profileSettings.archives : {});
        // console.log('%c onConfirmRemoveSocialSolution archives: %O', 'color: purple;', archives);
        var profileSocialSolutionSettings: any = (socialSolutionSettings && socialSolutionSettings[removeSolutionId] ? socialSolutionSettings[removeSolutionId] : null);
        // console.log('%c onConfirmRemoveSocialSolution profileSocialSolutionSettings: %O', 'color: purple;', profileSocialSolutionSettings);

        if (profileSocialSolutionSettings) {
            var curDate = new Date();
            const options: any = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
            let currentDateForDisplay: string = curDate.toLocaleDateString('en-us', options);
            // console.log('%c onConfirmRemoveSocialSolution currentDateForDisplay: %O', 'color: red;', currentDateForDisplay);
        
            archives[removeSolutionId] = profileSocialSolutionSettings;
            archives[removeSolutionId].lastArchived = currentDateForDisplay;
            delete socialSolutionSettings[removeSolutionId];
        }
        // console.log('%c onConfirmRemoveSocialSolution socialSolutionSettings: %O', 'color: purple;', socialSolutionSettings);
        // console.log('%c onConfirmRemoveSocialSolution archives: %O', 'color: purple;', archives);

        profileSettings.socialSolutionSettings = socialSolutionSettings;
        profileSettings.archives = archives;

        profile.jsonProfileSettingsJson = profileSettings;
        profile.ProfileSettingsJson = JSON.stringify(profile.jsonProfileSettingsJson);

        selectedProfile.data = profile;
        selectedProfile.isDirty = true;

        udicci.setProfile(selectedProfile);

        setRemoveSolution(null);
        setSelectedSolution(null);
        setSelectedFeature(null);
        setSelectedMediator(null);
    };

    const onReinstallSocialSolution = (solutionArchives: any) => {
        // console.log('%c onReinstallSocialSolution solutionArchives: %O', 'color: red;', solutionArchives);
        // console.log('%c onReinstallSocialSolution editSocialSolution: %O', 'color: red;', editSocialSolution);
        if (!removeSolution || !selectedProfile) return false;

        var solutionId: number = (selectedSolution.recordId ? selectedSolution.recordId : 0);
        // console.log('%c onReinstallSocialSolution solutionId: %O', 'color: purple;', solutionId);

        var profileSolutions: any[] = (profile && profile.SocialSolutions ? profile.SocialSolutions : []);
        // console.log('%c onReinstallSocialSolution profileSolutions: %O', 'color: red; font-weight: bold;', profileSolutions);

        // if (profileSolutions) {
        //     let newProfileSolutions: any[] = [];
        //     forEach(profileSolutions, (ps: any) => {
        //         if (ps.recordId !== solutionId) newProfileSolutions.push(ps);
        //     });
        //     // console.log('%c onReinstallSocialSolution newProfileSolutions: %O', 'color: red; font-weight: bold;', newProfileSolutions);
        //     profile.SocialSolutions = newProfileSolutions;
        // }

        if (profile && !profile.jsonProfileSettingsJson && profile.ProfileSettingsJson) {
            try {
                profile.jsonProfileSettingsJson = JSON.parse(profile.ProfileSettingsJson);
            } catch (e: any) {
    
            }
        }
        // console.log('%c onReinstallSocialSolution profile.jsonProfileSettingsJson: %O', 'color: red;', profile.jsonProfileSettingsJson);

        var profileSettings: any = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : {});
        // console.log('%c onReinstallSocialSolution profileSettings: %O', 'color: purple;', profileSettings);
        var socialSolutionSettings: any = (profileSettings && profileSettings.socialSolutionSettings ? profileSettings.socialSolutionSettings : {});
        // console.log('%c onReinstallSocialSolution socialSolutionSettings: %O', 'color: purple;', socialSolutionSettings);
        var archives: any = (profileSettings && profileSettings.archives ? profileSettings.archives : {});
        // console.log('%c onReinstallSocialSolution archives: %O', 'color: purple;', archives);

        // var profileSocialSolutionSettings: any = (socialSolutionSettings && socialSolutionSettings[removeSolutionId] ? socialSolutionSettings[removeSolutionId] : null);
        // console.log('%c onConfirmRemoveSocialSolution profileSocialSolutionSettings: %O', 'color: purple;', profileSocialSolutionSettings);

        // if (profileSocialSolutionSettings) {
        //     archives[removeSolutionId] = profileSocialSolutionSettings;
        //     archives[removeSolutionId].lastArchived = profileSocialSolutionSettings;
        //     delete socialSolutionSettings[removeSolutionId];
        // }

        profileSettings.socialSolutionSettings = socialSolutionSettings;
        profileSettings.archives = archives;

        profile.jsonProfileSettingsJson = profileSettings;
        profile.ProfileSettingsJson = JSON.stringify(profile.jsonProfileSettingsJson);

        selectedProfile.data = profile;
        selectedProfile.isDirty = true;

        udicci.setProfile(selectedProfile);

        setRemoveSolution(null);
        setSelectedSolution(null);
        setSelectedFeature(null);
        setSelectedMediator(null);
    };

    let featuresStructure: any = udicciHelpers.getMediatorStructure('Udicci Features');
    // console.log('%c featuresStructure: %O', 'color: purple;', featuresStructure);

    var profileSettings: any = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
    // console.log('%c profileSettings: %O', 'color: purple;', profileSettings);
    var archives: any = (profileSettings && profileSettings.archives ? profileSettings.archives : null);
    // console.log('%c archives: %O', 'color: purple;', archives);

    let solutionElements: any[] = [];
    if (!editSocialSolution) {
        if (profileSolutions && !showAll) {
            let sortedSolutions: any[] = sortBy(profileSolutions, (ps: any) => { return ps.title });
            forEach(sortedSolutions, function(socialSolution: any, idxSocialSolution: number) {
                // console.log('%c socialSolution %s: %O', 'color: purple;', socialSolution.title, socialSolution);

                let ssData = (socialSolution && socialSolution.data ? socialSolution.data : null);
                // console.log('%c ssData: %O', 'color: purple;', ssData);
                if (ssData && !ssData.jsonSettingsJson && ssData.SettingsJson) {
                    try {
                        ssData.jsonSettingsJson = JSON.parse(ssData.SettingsJson);
                    } catch (e: any) {

                    }
                }

                let isSelectedSocialSolution: boolean = (selectedSolution && selectedSolution.recordId === socialSolution.recordId ? true : false);
                let isRemoveSocialSolution: boolean = (removeSolution && removeSolution.recordId === socialSolution.recordId ? true : false);
                var socialSolutionSettings = (ssData && ssData.jsonSettingsJson ? ssData.jsonSettingsJson : null);
                // console.log('%c socialSolutionSettings: %O', 'color: purple;', socialSolutionSettings);
                var ssMediatorSettings = (socialSolutionSettings && socialSolutionSettings.mediators ? socialSolutionSettings.mediators : null);
                // console.log('%c ssMediatorSettings: %O', 'color: purple;', ssMediatorSettings);
                var socialSolutionFeatures = (ssData && ssData.Features ? ssData.Features : null);
                // console.log('%c socialSolutionFeatures: %O', 'color: purple;', socialSolutionFeatures);

                let ssElementKey: string = 'ss.' + socialSolution.recordId.toString();

                let featureElements: any[] = [];
                if (!isRemoveSocialSolution && isSelectedSocialSolution && socialSolutionFeatures && socialSolutionFeatures.length > 0) {
                    forEach(socialSolutionFeatures, function(feature: any, idxFeature: number) {
                        // console.log('%c feature %s: %O', 'color: red;', feature.Name, feature);

                        let featureRecord: UdicciRecord = new UdicciRecord('Udicci Features', feature, featuresStructure)
                        // console.log('%c featureRecord %s: %O', 'color: red; font-weight: bold;', featureRecord.title, featureRecord);

                        let isSelectedFeature: boolean = (selectedFeature && selectedFeature.UdicciRecordId === feature.UdicciRecordId ? true : false);
                        // console.log('%c feature: %O', 'color: red;', isSelectedFeature);
                        if (!selectedFeature || (selectedFeature && isSelectedFeature)) {
                            let ftreElementKey: string = ssElementKey + '.feature.' + feature.UdicciRecordId.toString();

                            let featureData = (feature && feature.data ? feature.data : null);
                            // console.log('%c featureData: %O', 'color: purple;', featureData);
                            if (featureData && !featureData.jsonMediators && featureData.Mediators) {
                                try {
                                    featureData.jsonMediators = JSON.parse(featureData.Mediators);
                                } catch (e: any) {
                
                                }
                            }

                            let mediators: any[] = [];
                            if (ssMediatorSettings) {
                                forEach(values(ssMediatorSettings), function(med: any, idxMed: number) {
                                    // console.log('%c med %s: %O', 'color: purple;', med.name, med);
                                    let mediatorFeatures = (med && med.features ? med.features : null);
                                    // console.log('%c mediatorFeatures: %O', 'color: purple;', mediatorFeatures);
                                    if (mediatorFeatures) {
                                        let ftreMed: any = find(mediatorFeatures, (mf: any) => mf.id === feature.UdicciRecordId);
                                        // console.log('%c ftreMed: %O', 'color: purple;', ftreMed);
                                        if (ftreMed) mediators.push(med);
                                    }
                                });
                            }
                            // console.log('%c mediators: %O', 'color: red;', mediators);

                            let featureFormElement: any = null;
                            let mediatorElements: any[] = [];
                            if (selectedFeature && !selectedMediator) {
                                featureFormElement = (<FeatureConfigurationForm socialSolution={selectedSolution} feature={featureRecord} onClose={onCloseFeature} />);
                            } else {
                                if (mediators.length > 0) {
                                    let sortedMediators: any[] = sortBy(mediators, (med: any) => { return med.name });
                                    forEach(sortedMediators, function(med: any, idxMed: number) {
                                        // console.log('%c med %s: %O', 'color: purple;', med.name, med);

                                        let isSelectedMediator: boolean = (selectedMediator && selectedMediator.id === med.id ? true : false);
                                        // console.log('%c isSelectedMediator %s: %O', 'color: purple;', med.name, isSelectedMediator);
                                        if (isSelectedMediator) {
                                            let configFormKey: string = ftreElementKey + '.mediator.admin.' + med.id.toString();
                                            mediatorElements.push(
                                                <MediatorConfigurationForm key={configFormKey} mediator={med} onClose={onCloseMediator} />
                                            );
                                        } else if (!selectedMediator) {
                                            let ftreContainerSettings: any = {
                                                key: ftreElementKey + '.mediator.' + med.id.toString(),
                                                sx: { margin: '4px' }
                                            };
                                            let ftreMedElementSettings: any = {
                                                // key: ftreElementKey + '.mediator.' + med.id.toString(),
                                                variant: "caption",
                                                component: "span",
                                                onClick: null,
                                                onClose: null,
                                                sx: { cursor: 'default' }
                                            };
                                            if (idxMed < (sortedMediators.length)) {
                                                ftreContainerSettings.sx.borderBottom = '1px dashed #ccc';
                                            }
                                            if (!selectedMediator) {
                                                ftreMedElementSettings.onClick = (evt: any) => onClickMediator(socialSolution, feature, med);
                                                ftreMedElementSettings.sx.cursor = 'pointer';
                                            }

                                            let editMediatorIconElement: any = (
                                                <Tooltip title={'configure mediator ' + feature.Name}>
                                                    <IconButton size="small" onClick={(evt: any) => onClickMediator(socialSolution, feature, med)} sx={{ marginLeft: '4px', cursor: 'pointer' }}>                        
                                                        <Icon fontSize="small">edit_square</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            );

                                            mediatorElements.push(
                                                <Box {...ftreContainerSettings}>
                                                    {editMediatorIconElement}
                                                    <Typography {...ftreMedElementSettings}>{med.name}</Typography>
                                                </Box>
                                            );
                                        }
                                    });
                                }
                            }

                            let featureMediatorsDisplayElement: any = null;
                            let editFeatureIconElement: any = null;
                            if (featureFormElement) {
                                featureMediatorsDisplayElement = (
                                    <Box sx={{ padding: '8px' }}>
                                        {featureFormElement}
                                    </Box>
                                );
                            } else {
                                if (mediatorElements.length > 0) {
                                    featureMediatorsDisplayElement = (
                                        <Box sx={{ padding: '8px' }}>{mediatorElements}</Box>
                                    );
                                } else {
                                    featureMediatorsDisplayElement = (
                                        <Box sx={{ padding: '8px' }}>
                                            <Typography variant="errorMessage" component="div">no mediators assigned</Typography>
                                        </Box>
                                    );
                                }
                                editFeatureIconElement = (
                                    <Tooltip title={'configure feature ' + feature.Name}>
                                        <IconButton size="small" onClick={(evt: any) => onClickFeature(socialSolution, feature)} sx={{ marginLeft: '4px', cursor: 'pointer' }}>                        
                                            <Icon fontSize="small">edit</Icon>
                                        </IconButton>
                                    </Tooltip>
                                );
                            }

                            featureElements.push(
                                <Box key={ftreElementKey} sx={{ padding: '8px', borderBottom: '1px solid #ccc' }}>
                                    {editFeatureIconElement}
                                    <Typography variant="caption" component="span" key={ftreElementKey}>{feature.Name}</Typography>
                                    {featureMediatorsDisplayElement}
                                </Box>
                            );
                        }
                    });
                }

                let featureDisplayElement: any = null;
                if (!isRemoveSocialSolution && isSelectedSocialSolution) {
                    if (featureElements.length > 0) {
                        featureDisplayElement = (
                            <Box sx={{ padding: '8px', background: 'rgba(255, 255, 255, 0.1)' }}>{featureElements}</Box>
                        );
                    } else {
                        featureDisplayElement = (
                            <Box sx={{ padding: '8px', background: 'rgba(255, 255, 255, 0.1)' }}>
                                <Typography variant="errorMessage" component="div">no features assigned</Typography>
                            </Box>
                        );
                    }
                }

                let socialSolutionTitleProps: any = {
                    variant: 'caption',
                    component: 'div',
                };
                let solutionContainerSettings: any = {
                    key: ssElementKey,
                    sx: { margin: '8px', padding: '8px', background: 'rgba(255, 255, 255, 0.77)', display: 'flow-root' }
                };
                if (isRemoveSocialSolution) {
                    solutionContainerSettings.sx.border = "1px solid #a30000";
                } else if (isSelectedSocialSolution) {
                    solutionContainerSettings.sx.border = "1px solid #4C6FB1";
                } else {
                    socialSolutionTitleProps.onClick = (evt: any) => onClickSocialSolution(socialSolution);
                    socialSolutionTitleProps.sx = { cursor: 'pointer' };
                }
                if (!selectedSolution || (selectedSolution && (isSelectedSocialSolution || isRemoveSocialSolution))) {
                    let editIconElement: any = null;
                    let removeIconElement: any = null;
                    let closeIconElement: any = null;
                    let removeSocialSolutionElement: any = null;
                    if (removeSolution && isRemoveSocialSolution) {
                        removeIconElement = (
                            <Tooltip title={'cancel remove social solution from portal'}>
                                <IconButton size="small" onClick={(evt: any) => onCancelRemoveSocialSolution()} sx={{ float: 'right', marginLeft: '4px', cursor: 'pointer' }}>                        
                                    <Icon fontSize="small">cancel</Icon>
                                </IconButton>
                            </Tooltip>
                        );

                        removeSocialSolutionElement = (
                            <Paper elevation={3} sx={{ margin: '8px', padding: '8px' }}>
                                <Typography variant="errorMessage" component="div">Are you sure you want to remove this Social Solution from this Portal?</Typography>
                                <Typography variant="caption" component="div">Completing this action will result in the Social Solution being removed from your Portal.</Typography>
                                <Typography variant="caption" component="div">It will be archived in a special place if you ever decide to reinstall the Social Solution to your Portal.  All settings will be restored as they are when uninstalled.</Typography>

                                <Box sx={{ margin: '8px', padding: '8px' }}>
                                    <Typography variant="subtitle1" component="span">{removeSolution.title}</Typography>
                                    <Typography variant="caption" component="span"> will be removed from </Typography>
                                    <Typography variant="subtitle1" component="span">{profileDisplayName}.</Typography>
                                </Box>

                                <Box sx={{ margin: '8px', padding: '8px' }}>
                                    <Button variant="contained" color="error" onClick={(evt: any) => onConfirmRemoveSocialSolution()} sx={{ marginLeft: '16px', cursor: 'pointer' }}>Yep, This is Intentional. Go Ahead and Remove The Social Solution Now.</Button>
                                    <Button variant="contained" color="info" onClick={(evt: any) => onCancelRemoveSocialSolution()} sx={{ marginLeft: '16px', cursor: 'pointer' }}>No!  Do Not Remove This Social Solution!</Button>
                                </Box>
                            </Paper>
                        );
                    } else if (selectedSolution) {
                        if (editSocialSolution) {
                            editIconElement = (
                                <Tooltip title={'cancel edit ' + socialSolution.title}>
                                    <IconButton size="small" onClick={(evt: any) => onCancelEditSocialSolution()} sx={{ float: 'right', marginLeft: '4px', cursor: 'pointer' }}>                        
                                        <Icon fontSize="small">close</Icon>
                                    </IconButton>
                                </Tooltip>
                            );
                        } else {
                            editIconElement = (
                                <Tooltip title={'edit ' + socialSolution.title}>
                                    <IconButton size="small" onClick={(evt: any) => onEditSocialSolution(socialSolution)} sx={{ float: 'right', marginLeft: '4px', cursor: 'pointer' }}>                        
                                        <Icon fontSize="small">edit</Icon>
                                    </IconButton>
                                </Tooltip>
                            );
                            closeIconElement = (
                                <Tooltip title={'close solution'}>
                                    <IconButton size="small" onClick={(evt: any) => onCloseSocialSolution()} sx={{ float: 'right', marginLeft: '4px', cursor: 'pointer' }}>                        
                                        <Icon fontSize="small">close</Icon>
                                    </IconButton>
                                </Tooltip>
                            );
                            removeIconElement = (
                                <Tooltip title={'remove social solution from portal'}>
                                    <IconButton size="small" onClick={(evt: any) => onRemoveSocialSolution(socialSolution)} sx={{ float: 'right', marginLeft: '4px', cursor: 'pointer' }}>                        
                                        <Icon fontSize="small">delete_forever</Icon>
                                    </IconButton>
                                </Tooltip>
                            );
                        }
                    } else {
                        editIconElement = (
                            <Tooltip title={'configure ' + socialSolution.title}>
                                <IconButton size="small" onClick={(evt: any) => onClickSocialSolution(socialSolution)} sx={{ float: 'right', marginLeft: '4px', cursor: 'pointer' }}>                        
                                    <Icon fontSize="small">settings</Icon>
                                </IconButton>
                            </Tooltip>
                        );
                    }

                    solutionElements.push(
                        <Box {...solutionContainerSettings}>
                            {closeIconElement}
                            {editIconElement}
                            {removeIconElement}
                            <Typography {...socialSolutionTitleProps}>{socialSolution.title}</Typography>
                            {removeSocialSolutionElement}
                            {featureDisplayElement}
                        </Box>
                    );
                }
            });
        } else if (allSocialSolutions) {
            var profileSolutionRecordIds: number[] = (profileSolutions ? pluck(profileSolutions, 'recordId') : []);
            // console.log('%c profileSolutionRecordIds: %O', 'color: orange;', profileSolutionRecordIds);

            let sortedSolutions: any[] = sortBy(allSocialSolutions, (ps: any) => { return ps.title });
            forEach(sortedSolutions, function(socialSolution: any, idxSocialSolution: number) {
                // console.log('%c socialSolution %s: %O', 'color: purple;', socialSolution.title, socialSolution);

                if (profileSolutionRecordIds.indexOf(socialSolution.recordId) >= 0) return true;
                // console.log('%c socialSolution %s: %O', 'color: purple;', socialSolution.title, socialSolution);

                let ssData = (socialSolution && socialSolution.data ? socialSolution.data : null);
                // console.log('%c ssData: %O', 'color: purple;', ssData);
                if (ssData && !ssData.jsonSettingsJson && ssData.SettingsJson) {
                    try {
                        ssData.jsonSettingsJson = JSON.parse(ssData.SettingsJson);
                    } catch (e: any) {

                    }
                }

                let isSelectedSocialSolution: boolean = (selectedSolution && selectedSolution.recordId === socialSolution.recordId ? true : false);
                var socialSolutionSettings = (ssData && ssData.jsonSettingsJson ? ssData.jsonSettingsJson : null);
                // console.log('%c socialSolutionSettings: %O', 'color: purple;', socialSolutionSettings);
                var ssMediatorSettings = (socialSolutionSettings && socialSolutionSettings.mediators ? socialSolutionSettings.mediators : null);
                // console.log('%c ssMediatorSettings: %O', 'color: purple;', ssMediatorSettings);
                var socialSolutionFeatures = (ssData && ssData.Features ? ssData.Features : null);
                // console.log('%c socialSolutionFeatures: %O', 'color: purple;', socialSolutionFeatures);

                let ssElementKey: string = 'ss.' + socialSolution.recordId.toString();

                let featureElements: any[] = [];
                if (isSelectedSocialSolution && socialSolutionFeatures && socialSolutionFeatures.length > 0) {
                    forEach(socialSolutionFeatures, function(feature: any, idxFeature: number) {
                        // console.log('%c feature %s: %O', 'color: red;', feature.Name, feature);

                        let isSelectedFeature: boolean = (selectedFeature && selectedFeature.UdicciRecordId === feature.UdicciRecordId ? true : false);
                        // console.log('%c isSelectedFeature: %O', 'color: red;', isSelectedFeature);
                        if (!selectedFeature || (selectedFeature && isSelectedFeature)) {
                            let ftreElementKey: string = ssElementKey + '.feature.' + feature.UdicciRecordId.toString();

                            let featureData = (feature && feature.data ? feature.data : null);
                            // console.log('%c featureData: %O', 'color: purple;', featureData);
                            if (featureData && !featureData.jsonMediators && featureData.Mediators) {
                                try {
                                    featureData.jsonMediators = JSON.parse(featureData.Mediators);
                                } catch (e: any) {
                
                                }
                            }

                            let mediators: any[] = [];
                            if (ssMediatorSettings) {
                                forEach(values(ssMediatorSettings), function(med: any, idxMed: number) {
                                    // console.log('%c med %s: %O', 'color: purple;', med.name, med);
                                    let mediatorFeatures = (med && med.features ? med.features : null);
                                    // console.log('%c mediatorFeatures: %O', 'color: purple;', mediatorFeatures);
                                    if (mediatorFeatures) {
                                        let ftreMed: any = find(mediatorFeatures, (mf: any) => mf.id === feature.UdicciRecordId);
                                        // console.log('%c ftreMed: %O', 'color: purple;', ftreMed);
                                        if (ftreMed) mediators.push(med);
                                    }
                                });
                            }
                            // console.log('%c mediators: %O', 'color: red;', mediators);

                            let mediatorElements: any[] = [];
                            let featureFormElement: any = null;
                            if (selectedFeature && !selectedMediator) {
                                featureFormElement = (<FeatureConfigurationForm feature={feature} onClose={onCloseFeature} />);
                            } else {
                                if (mediators.length > 0) {
                                    let sortedMediators: any[] = sortBy(mediators, (med: any) => { return med.name });
                                    forEach(sortedMediators, function(med: any, idxMed: number) {
                                        // console.log('%c med %s: %O', 'color: purple;', med.name, med);

                                        let isSelectedMediator: boolean = (selectedMediator && selectedMediator.id === med.id ? true : false);
                                        // console.log('%c isSelectedMediator %s: %O', 'color: purple;', med.name, isSelectedMediator);
                                        if (isSelectedMediator) {
                                            let configFormKey: string = ftreElementKey + '.mediator.admin.' + med.id.toString();
                                            mediatorElements.push(
                                                <MediatorConfigurationForm key={configFormKey} mediator={med} onClose={onCloseMediator} />
                                            );
                                        } else if (!selectedMediator) {
                                            let ftreMedElementSettings: any = {
                                                key: ftreElementKey + '.mediator.' + med.id.toString(),
                                                variant: "caption",
                                                component: "div",
                                                onClick: null,
                                                onClose: null,
                                                sx: { cursor: 'default', margin: '4px' }
                                            };
                                            if (idxMed < (sortedMediators.length)) {
                                                ftreMedElementSettings.sx.borderBottom = '1px dashed #ccc';
                                            }
                                            if (!selectedMediator) {
                                                ftreMedElementSettings.onClick = (evt: any) => onClickMediator(socialSolution, feature, med);
                                                ftreMedElementSettings.sx.cursor = 'pointer';
                                            }
                                            mediatorElements.push(<Typography {...ftreMedElementSettings}>{med.name}</Typography>);
                                        }
                                    });
                                }
                            }

                            let featureMediatorsDisplayElement: any = null;
                            if (featureFormElement) {
                                featureMediatorsDisplayElement = (
                                    <Box sx={{ padding: '8px' }}>
                                        {featureFormElement}
                                    </Box>
                                );
                            } else if (mediatorElements.length > 0) {
                                featureMediatorsDisplayElement = (
                                    <Box sx={{ padding: '8px' }}>{mediatorElements}</Box>
                                );
                            } else {
                                featureMediatorsDisplayElement = (
                                    <Box sx={{ padding: '8px' }}>
                                        <Typography variant="errorMessage" component="div">no mediators assigned</Typography>
                                    </Box>
                                );
                            }

                            featureElements.push(
                                <Box key={ftreElementKey} sx={{ padding: '8px', borderBottom: '1px solid #ccc' }}>
                                    <Typography variant="caption" component="div" key={ftreElementKey}>{feature.Name}</Typography>
                                    {featureMediatorsDisplayElement}
                                </Box>
                            );
                        }
                    });
                }

                let featureDisplayElement: any = null;
                if (isSelectedSocialSolution) {
                    var solutionArchives: any = (archives && archives[socialSolution.recordId] ? archives[socialSolution.recordId] : null);
                    // console.log('%c solutionArchives: %O', 'color: purple;', solutionArchives);
                    if (featureElements.length > 0) {
                        let archivesElement: any = null;
                        if (archives) {
                            archivesElement = (
                                <Fragment>
                                    {/* <Typography variant="errorMessage" component="div">Arhives found</Typography> */}
                                    <Button variant="contained"
                                            color="secondary"
                                            size="small"
                                            sx={{ margin: '4px' }}
                                            onClick={(evt: any) => onReinstallSocialSolution(solutionArchives)}
                                    >
                                        Reinstall Social Solution using Prior Settings
                                    </Button>
                                </Fragment>
                            );
                        }
                        featureDisplayElement = (
                            <Box sx={{ padding: '8px', background: 'rgba(255, 255, 255, 0.1)' }}>
                                <Box sx={{ padding: '8px' }}>
                                    {archivesElement}
                                    <Button variant="contained" color="primary" size="small" sx={{ margin: '4px' }}>
                                        Install Social Solution
                                    </Button>
                                </Box>
                                {featureElements}
                            </Box>
                        );
                    } else if (showAll && archives) {
                        featureDisplayElement = (
                            <Box sx={{ padding: '8px', background: 'rgba(255, 255, 255, 0.1)' }}>
                                <Typography variant="errorMessage" component="div">Arhives found</Typography>
                            </Box>
                        );
                    } else {
                        featureDisplayElement = (
                            <Box sx={{ padding: '8px', background: 'rgba(255, 255, 255, 0.1)' }}>
                                <Typography variant="errorMessage" component="div">no features assigned</Typography>
                            </Box>
                        );
                    }
                }

                let socialSolutionTitleProps: any = {
                    variant: 'caption',
                    component: 'div',
                    onClick: (evt: any) => onClickSocialSolution(socialSolution),
                    sx: { cursor: 'pointer' }
                };
                let solutionContainerSettings: any = {
                    key: ssElementKey,
                    sx: { margin: '8px', padding: '8px', background: 'rgba(255, 255, 255, 0.77)' }
                };
                if (isSelectedSocialSolution) {
                    solutionContainerSettings.sx.border = "1px solid #4C6FB1";
                }
                if (!selectedSolution || (selectedSolution && isSelectedSocialSolution)) {
                    let editIconElement: any = null;
                    let removeIconElement: any = null;
                    let closeIconElement: any = null;
                    if (selectedSolution) {
                        closeIconElement = (
                            <Tooltip title={'close solution'}>
                                <IconButton size="small" onClick={(evt: any) => onCloseSocialSolution()} sx={{ float: 'right', marginLeft: '4px', cursor: 'pointer' }}>                        
                                    <Icon fontSize="small">close</Icon>
                                </IconButton>
                            </Tooltip>
                        );
                        removeIconElement = (
                            <Tooltip title={'add social solution to portal'}>
                                <IconButton size="small" onClick={(evt: any) => onCloseSocialSolution()} sx={{ float: 'right', marginLeft: '4px', cursor: 'pointer' }}>                        
                                    <Icon fontSize="small">assignment_add</Icon>
                                </IconButton>
                            </Tooltip>
                        );
                    } else {
                        editIconElement = (
                            <Tooltip title={'configure ' + socialSolution.title}>
                                <IconButton size="small" onClick={(evt: any) => onClickSocialSolution(socialSolution)} sx={{ float: 'right', marginLeft: '4px', cursor: 'pointer' }}>                        
                                    <Icon fontSize="small">settings</Icon>
                                </IconButton>
                            </Tooltip>
                        );
                    }
                    solutionElements.push(
                        <Box {...solutionContainerSettings}>
                            {closeIconElement}
                            {editIconElement}
                            {removeIconElement}
                            <Typography {...socialSolutionTitleProps}>{socialSolution.title}</Typography>
                            {featureDisplayElement}
                        </Box>
                    );
                }
            });
        }
    }

    let cancelEditIconElement: any = (
        <IconButton size="small" onClick={(evt: any) => onCancelEditSocialSolution()} sx={{ float: 'right', marginLeft: '4px', cursor: 'pointer' }}>                        
            <Icon fontSize="small">close</Icon>
        </IconButton>
    );

    let socialSolutionDisplayElement: any = null;
    if (editSocialSolution) {
        let solutionContainerSettings: any = {
            margin: '8px',
            padding: '8px',
            background: 'rgba(255, 255, 255, 0.77)',
            border: '1px solid #4C6FB1'
        };
        socialSolutionDisplayElement = (
            <Box sx={solutionContainerSettings}>
                {cancelEditIconElement}
                <Typography variant="subtitle1" component="span">Configure Social Solution: {selectedSolution.title} </Typography>
                <SocialSolutionForm socialSolution={selectedSolution}
                                    onChange={onChangeSocialSolution}
                                    onClose={onCancelEditSocialSolution}
                />
            </Box>
        );
    } else {
        socialSolutionDisplayElement = (
            <Box sx={{ margin: '8px' }}>
                {solutionElements}
            </Box>
        );
    }

    let changeSolutionsElement: any = null;
    let ssmHeaderElement: any = null;
    if (showAll) {
        changeSolutionsElement = (
            <Tooltip title={'close list'}>
                <IconButton size="small" onClick={(evt: any) => toggleShowAll()} sx={{ float: 'right', marginLeft: '4px', cursor: 'pointer' }}>                        
                    <Icon fontSize="small">close</Icon>
                </IconButton>
            </Tooltip>
        );
        if (selectedSolution) {
            ssmHeaderElement = (
                <Box>
                    <Typography variant="caption" component="span">Manage Social Solution </Typography>
                    <Typography variant="subtitle1" component="span"> {selectedSolution.title} </Typography>
                    <Typography variant="caption" component="span"> for </Typography>
                    <Typography variant="subtitle1" component="span"> {profile.DisplayName}</Typography>
                </Box>
            );
        } else {
            ssmHeaderElement = (
                <Box>
                    {changeSolutionsElement}
                    <Typography variant="subtitle1" component="span">Social Solutions </Typography>
                    <Typography variant="caption" component="span"> Not Enabled for </Typography>
                    <Typography variant="body2" component="span"> {profile.DisplayName}</Typography>
                </Box>
            );
        }
    } else {
        if (!editSocialSolution && !removeSolution) {
            if (selectedSolution) {
                ssmHeaderElement = (
                    <Box>
                        <Typography variant="caption" component="span">Manage Social Solution </Typography>
                        <Typography variant="subtitle1" component="span"> {selectedSolution.title} </Typography>
                        <Typography variant="caption" component="span"> for </Typography>
                        <Typography variant="subtitle1" component="span"> {profile.DisplayName}</Typography>
                    </Box>
                );
            } else {
                changeSolutionsElement = (
                    <Tooltip title={'show unassigned social solutions'}>
                        <IconButton size="small" onClick={(evt: any) => toggleShowAll()} sx={{ float: 'right', marginLeft: '4px', cursor: 'pointer' }}>                        
                            <Icon fontSize="small">post_add</Icon>
                        </IconButton>
                    </Tooltip>
                );
                ssmHeaderElement = (
                    <Box>
                        {changeSolutionsElement}
                        <Typography variant="subtitle1" component="span">Social Solutions </Typography>
                        <Typography variant="caption" component="span"> Enabled for </Typography>
                        <Typography variant="body2" component="span"> {profile.DisplayName}</Typography>
                    </Box>
                );
            }
            }
    }

    let socialSolutionManagementElement: any = (
        <Box sx={{ margin: '8px' }}>
            {ssmHeaderElement}
            {socialSolutionDisplayElement}
        </Box>
    );

    return socialSolutionManagementElement;
}

export { SocialSolutionManagement };
