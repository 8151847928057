import { UdicciRecord } from 'src/classes/udicci-record';

// #region Helpers (ActionMap)

export type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
            type: Key;
        }
        : {
            type: Key;
            payload: M[Key];
        }
};

// #endregion

// #region Platform (UdicciTheme, SocialSolution, Mediator, UdicciUserDetails,EngagementStructure)
 
export type UdicciTheme = {
    IsDefault: boolean;
    IsOwner: boolean;
    Name: string;
    Purpose: string;
    Settings: string;
    Status: string;
    UdicciRecordId: number;
    SettingsJson: any;
}

export type SocialSolution = {
    UdicciRecordId: number;
    Name: string;
    Purpose: string;
    SettingsJson: string;
    jsonSettingsJson: any;
}

export type Mediator = {
    mediator: string;
    records: UdicciRecord[] | null;
    structure: any;
    permissions: any;
    linkedData: any;
    lastResult: Date | null;
}

export type UdicciUserDetails = {
    AccessToken: string;
    MeUdicciProfileId: number,
    RefreshToken: string;
    UdicciUserId: number,
    UserName: string;
    myDisplayName: string;
    mySocialIcon: string;
    myProfileUrl: string;
    FirstName: string;
    LastName: string;
    TokenExpired: boolean;
    ExpectedTimeout: Date | null;
    HandCashOptions: any;
    TimedOut: boolean;
}

export type EngagementStructure = {
    UdicciRecordId: number;
    FirstName: string;
    LastName: string;
    EmailAddress: string;
    PhoneNumber: string;
    Organization: string;
    HowWeMet: string;
    Interest: string;
    AdditionalInfo: string;
    jsonAdditionalInfo: any;
    isDirty: boolean;
}

// #endregion

// #region Context (ContextSubscriber)

export type ContextSubscriber = {
    subscriber: string;
    callback: Function;
    instructions?: any | null;
}

export type AdvancedContextSubscriber = {
    subscriber: string;
    callback: Function;
    instructions?: any | null;
    notes?: any | null | undefined;
}

export type SubscriptionInstruction = {
    udicciCommand?: string;
    udicciMediator?: string;
    recordId?: number;
    socialSolutionId?: number;
    profileId?: number;
}

// #endregion

// #region Udicci List Context (UdicciListActions,UdicciListContextStateType,UdicciListContextActionPayload,UdicciListContextActionPayload,UdicciListContextActions)

export enum UdicciListActions {
    Refresh = 'REFRESH',
    Edit = 'EDIT',
    Add = 'ADD',
    LoadBreadcrumb = 'LOADBREADCRUMB',
    CloseBreadcrumb = 'CLOSEBREADCRUMB',
    CloseAllBreadcrumbs = 'CLOSEALLBREADCRUMBS',
    Engage = 'ENGAGE',
    Delete = 'DELETE',
}

export type UdicciBreadcrumb = {
    record: UdicciRecord | null;
    socialSolution: any | null;
    mediator: any | null;
    engagementAction: any | null;
    settings: any | null;
    parents: UdicciRecord[] | null;
}

export type UdicciListContextStateType = {
    uid: string;
    data: UdicciRecord[] | null;
    socialSolution: any | null;
    feature: any | null;
    mediator: any | null;
    record: any | null;
    settings: any | null;
    breadcrumbs: UdicciBreadcrumb[] | null;
    onLoadBreadcrumb: any | null;
    onCloseBreadcrumb: any | null;
    onCloseAllBreadcrumbs: any | null;
    onRefresh: any | null;
    onAddRecord: any | null;
    onEditRecord: any | null;
    onEditContextRecord: any | null;
    onDeleteRecord: any | null;
    onEngageRecord: any | null;
    lastFetchResultDate: Date | null;
}

export type UdicciListContextActionPayload = {
    [UdicciListActions.Refresh] : null | undefined;
    [UdicciListActions.Edit] : null | undefined;
    [UdicciListActions.Add] : null | undefined;
}

export type UdicciListContextActions = 
                ActionMap<UdicciListContextActionPayload>[keyof ActionMap<UdicciListContextActionPayload>];

// #endregion

// #region Record Context (ContextSubscriber)

export enum RecordContextActions {
    Refresh = 'REFRESH',
    Edit = 'EDIT',
    Add = 'ADD',
    Delete = 'DELETE',
    Save = 'SAVE',
    Validate = 'VALIDATE',
}

export type RecordContextStateType = {
    record: UdicciRecord | null;
    settings: any | null;
    engagementActions: any | null;
    recordContext: any | null;
    onFieldValueChanged: any | null;
    save: any | null;
    delete: any | null;
    getStructure: any | null;
    getField: any | null;
    setRecordContext: any | null;
    getPerspectives: any | null;
    deletePerspective: any | null;
}

export type RecordContextActionPayload = {
    [RecordContextActions.Refresh] : UdicciRecord | number;
    [RecordContextActions.Edit] : UdicciRecord | null;
    [RecordContextActions.Add] : UdicciRecord | null;
    [RecordContextActions.Delete]: UdicciRecord | number;
    [RecordContextActions.Save]: UdicciRecord;
}

export type UdicciRecordContextActions = 
                ActionMap<RecordContextActionPayload>[keyof ActionMap<RecordContextActionPayload>];

// #endregion

// #region Portas (UdicciPorta, UdicciPortaMenu)

export type UdicciPorta = {
    Name: string;
    Purpose: string;
    Settings: string;
    SettingsJson: any;
    UdicciRecordId: number;
    CreatedByUserId: number;
    IsDirty: boolean;
}

export type UdicciPortaMenu = {
    Name: string;
    Purpose: string;
    Settings: string;
    MenuDisplayOrder: number;
    TargetPortaId: number;
    UdicciRecordId: number;
    SettingsJson: any;
}

// #endregion

// #region Attachments (AttachmentFile, AttachmentThumbnail)

export type AttachmentFile = {
    id: string;
    filename: string;
    fileExtension: string;
    fileType: string;
    fileThumbnail: AttachmentThumbnail;
    filenameWithoutExtension: string;
    fileSize: number;
    file: any;
    Title: string;
}

export type AttachmentThumbnail = {
    id: string;
    filename: string;
    url: string;
}

// #endregion

// #region Instructions (InstructionCondition, InstructionOption, Instruction, InstructionSet, InstructionLogItem, InstructionSetLog)

export type InstructionCondition = {
    name: string;
    alwaysTrue?: boolean;
    group?: string;
    condition: string;
    dataType: string;
    jsonKey: string;
    valueToCompare: any;
    roleLimitations?: any[];
    checkForCondition?: any;
    systemCheck?: any;
}

export type InstructionOption = {
    name: string;
    dataType: string;
    defaultValue: any;
    conditions: InstructionCondition[];
}

/**
 * Instruction Conditions
 *      - when condition
 *          - Go To Next Instruction
 *          - Go To Previous Instruction
 *          - Go To Specific Instruction
 *          - Stop
 *          - Restart
 */
export type Instruction = {
    uid: string;
    title: string;
    options: InstructionOption[];
    conditions: InstructionCondition[];
}

export type InstructionSet = {
    uid: string;
    instructions: Instruction[];
}

export type InstructionLogItem = {
    instructionId: string;
    options: InstructionOption[];
    conditions: InstructionCondition[];
    conditionMet: boolean;
    readyForNextStep: boolean;
    internalMessage: string;
    timestamp: number;  // timestamp in milliseconds
}

export type InstructionSetLog = {
    instructionSetId: string;
    currentInstructionId: string;
    instructionSet: InstructionSet;
    completed: boolean;
    taken: InstructionLogItem[];
}

// #endregion


export enum UdicciPluginCategory {
    Content = 'CONTENT',
    Container = 'CONTAINER',
    Data = 'DATA',
    User = 'USER',
    Admin = 'ADMIN',
    Special = 'SPECIAL',
    Custom = 'CUSTOM',
    Other = 'OTHER',
    Development = 'DEVELOPMENT',
}

export type UdicciPlugin = {
    Renderer: any;
    id: string;
    title: string;
    description: string;
    version: number;
    icon: any;
    controls: any;
    hideInMenu: boolean;
    canPick: (props: any) => void;
    canDisplay: (props: any) => void;
    category: UdicciPluginCategory;
}
