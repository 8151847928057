import { useState, useEffect } from 'react';

import { styled, useTheme } from '@mui/material/styles';

import { find, filter, forEach, values, sortBy, keys, findIndex } from 'underscore';
import { deepOrange, green } from '@mui/material/colors';
import { Box, Paper, Icon, IconButton, Typography, TextField, Tooltip, Avatar, Button, ButtonGroup, CircularProgress } from '@mui/material';
import { useUdicciContext, getMediatorStructures } from 'src/context/udicci-context';
import { useFocusedContext } from './focused-context';

import { UdicciRecord } from 'src/classes/udicci-record';
import useUdicciHelpers, { UDICCI_NEW_LINE_CONSTANT } from 'src/hooks/useUdicciHelpers';

import UDAIC_SOCIAL_ICON from 'src/images/udaic-social-icon.jpeg';

const UDICCI_INTELLI_FACE_CONTAINER_ID: string = 'udicci.intelli.face.prompt.container';

const HeaderPaperContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
    margin: 0,
    padding: '8px',
    // height: '100%',
}));

const PaperContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    // margin: '8px',
    // height: '100%',
}));

const EngageContainer = styled(Box)(({ theme }) => ({
    borderTop: '1px solid ' + theme.palette.secondary.main,
    // margin: '8px',
    // height: '100%',
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
    borderTop: '3px solid ' + theme.palette.info.main,
}));

const StickyPaperContainer = styled(Paper)(({ theme }) => ({
    position: 'sticky',
    top: 35,
    color: theme.palette.text.secondary,
}));

export const shouldForwardProp = <CustomProps extends Record<string, unknown>>(
    props: Array<keyof CustomProps>,
    prop: PropertyKey,
): boolean => !props.includes(prop as string);

const IntelliFaceContainer = styled(Paper, {
    shouldForwardProp: (prop) => shouldForwardProp<{ isMobile?: boolean, standardMenuDrawerWidth?: number }>(['isMobile', 'standardMenuDrawerWidth'], prop),
})<{ isMobile?: boolean, standardMenuDrawerWidth?: number }>(({ theme, isMobile, standardMenuDrawerWidth }) => ({
    color: theme.palette.text.secondary,
    backgroundColor: 'unset',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    left: (isMobile === true ? 0 : standardMenuDrawerWidth),
    right: 0,
    minHeight: 110,
    // borderTop: 0,
}));

const IntelliFaceContentContainer = styled(Paper, {
    shouldForwardProp: (prop) => shouldForwardProp<{ isMobile?: boolean, standardMenuDrawerWidth?: number }>(['isMobile', 'standardMenuDrawerWidth'], prop),
})<{ isMobile?: boolean, standardMenuDrawerWidth?: number }>(({ theme, isMobile, standardMenuDrawerWidth }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    borderTop: '3px solid ' + theme.palette.info.main,
    overflowY: 'auto',
    maxHeight: '300px',
    minHeight: 110,
    maxWidth: '750px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'left',
    borderBottom: 0,
}));

export const AIPromptBuilder: React.FC<any> = (props) => {
    const focus = useFocusedContext();
    // console.log('%c AIPromptBuilder focus: %O', 'color: blue;', focus);
    // const theme = useTheme();
    // console.log('%c theme: %O', 'color: purple;', theme);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;

    let { selectedProfile, currentUser } = udicci;
    // console.log('%c AIPromptBuilder selectedProfile: %O', 'color: purple; font-weight: bold;', selectedProfile);

    let isMobile: any = (focus && focus.isMobile ? true : false);
    // console.log('%c AIPromptBuilder isMobile: %O', 'color: purple;', isMobile);

    let profile: any = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    // console.log('%c AIPromptBuilder profile: %O', 'color: purple;', profile);
    var profileSolutions: any = (profile && profile.SocialSolutions ? profile.SocialSolutions : null);
    // console.log('%c AIPromptBuilder profileSolutions: %O', 'color: purple; font-weight: bold;', profileSolutions);

    let aiPrompt: any = (focus && focus.aiPrompt ? focus.aiPrompt : null);
    // console.log('%c AIPromptBuilder aiPrompt: %O', 'color: blue;', aiPrompt);
    let lastAIResponse: any = (focus && focus.lastAIResponse ? focus.lastAIResponse : null);
    // console.log('%c AIPromptBuilder lastAIResponse: %O', 'color: blue;', lastAIResponse);
    let aiError: any = (focus && focus.aiError ? focus.aiError : null);
    // console.log('%c AIPromptBuilder aiError: %O', 'color: blue;', aiError);
    let flexPrompt: string = (aiPrompt && aiPrompt.flexPrompt ? aiPrompt.flexPrompt : '');
    // console.log('%c AIPromptBuilder flexPrompt: %O', 'color: blue;', flexPrompt);
    let selectedSentences: any[] = (aiPrompt && aiPrompt.selectedSentences && aiPrompt.selectedSentences.length > 0 ? aiPrompt.selectedSentences : []);

    let workingSubjectMatter: any = null;
    let wsm: string | null = sessionStorage.getItem('udicci.working.subject.matter');
    if (wsm) {
        try {
            workingSubjectMatter = JSON.parse(wsm);
        } catch (e: any) {
            // console.log('%c UdicciOpenAISimpleStart wsm parse error: %O', 'color: blue;', e);
        }
    }
    // console.log('%c AIPromptBuilder workingSubjectMatter: %O', 'color: blue;', workingSubjectMatter);

    const [processing, setProcessing] = useState<boolean>(false);
    const [engageToUdicci, setEngageToUdicci] = useState<boolean>(false);
    const [chooseSocialSolution, setChooseSocialSolution] = useState<boolean>(false);
    const [engagedSocialSolution, setEngagedSocialSolution] = useState<any>(null);
    const [chooseFeature, setChooseFeature] = useState<boolean>(false);
    const [engagedFeature, setEngagedFeature] = useState<any>(null);
    const [chooseMediator, setChooseMediator] = useState<boolean>(false);
    const [engagedMediator, setEngagedMediator] = useState<any>(null);
    const [chooseContext, setChooseContext] = useState<boolean>(false);
    const [editPrompt, setEditPrompt] = useState<boolean>((flexPrompt || selectedSentences.length > 0 ? true : false));
    const [subjectMatter, setSubjectMatter] = useState<any>(workingSubjectMatter);
    // console.log('%c AIPromptBuilder subjectMatter: %O', 'color: blue;', subjectMatter);

    const engageUdicci = () => {
        setEngageToUdicci(true);
    };

    const disengageUdicci = () => {
        setEngageToUdicci(false);
    };

    const toggleChooseSocialSolution = () => {
        setChooseMediator(false);
        setChooseFeature(false);
        setChooseSocialSolution(!chooseSocialSolution);
    };

    const scrollToTop = () => {
        let container: any = document.getElementById(UDICCI_INTELLI_FACE_CONTAINER_ID);
        if (container) container.scroll({ top: 0, behavior: 'smooth' });
    };

    const selectSocialSolution = (socialSolution: any) => {
        setEngagedSocialSolution(socialSolution);
        setEngagedFeature(null);
        setEngagedMediator(null);
        setChooseSocialSolution(false);
        setChooseFeature(true);
        scrollToTop();
    };

    const selectSocialSolutionFeature = (socialSolution: any, feature: any) => {
        setEngagedSocialSolution(socialSolution);
        setEngagedFeature(feature);
        setEngagedMediator(null);
        setChooseSocialSolution(false);
        setChooseFeature(false);
        setChooseMediator(true);
        scrollToTop();
    };

    const toggleChooseFeature = () => {
        setChooseMediator(false);
        setChooseSocialSolution(false);
        setChooseFeature(!chooseFeature);
    };

    const selectFeature = (feature: any) => {
        setEngagedFeature(feature);
        setEngagedMediator(null);
        setChooseFeature(false);
        setChooseMediator(true);
        scrollToTop();
    };

    const toggleChooseMediator = () => {
        setChooseSocialSolution(false);
        setChooseFeature(false);
        setChooseMediator(!chooseMediator);
    };

    const selectMediator = (mediator: any) => {
        setEngagedMediator(mediator);
        setChooseMediator(false);
        scrollToTop();

        if (mediator) {
            let newSubjectMatter: any = {
                socialSolution: (engagedSocialSolution ? engagedSocialSolution : null),
                feature: (engagedFeature ? engagedFeature : null),
                mediator: mediator,
                contextMediatorNames: null,
                includeMediatorNames: null,
                interactions: null,
            };
            if (subjectMatter) Object.assign(newSubjectMatter, subjectMatter);
            // console.log('%c selectMediator newSubjectMatter: %O', 'color: red;', newSubjectMatter);
    
            let smStructure: any = udicciHelpers.getMediatorStructure(mediator.name);
            let contextMediators: any = udicciHelpers.getContextMediatorsForStructure(smStructure);
            // console.log('%c selectMediator contextMediators: %O', 'color: blue;', contextMediators);
        }
    };

    const toggleChooseContext = () => {
        setChooseContext(!chooseContext);
    };

    const handleChange = (evt: any) => {
        // console.log('%c handleChange evt: %O', 'color: maroon;', evt);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // setTempPrompt(newValue);

        if (focus.updateAIPrompt) {
            let aiPromptUpdated: any = {};
            if (focus.aiPrompt) Object.assign(aiPromptUpdated, focus.aiPrompt);

            let flexPrompt: string = (aiPromptUpdated && aiPromptUpdated.flexPrompt ? aiPromptUpdated.flexPrompt : '');
            // console.log('%c handleChange flexPrompt: %O', 'color: green;', flexPrompt);
            if (flexPrompt !== newValue) {
                aiPromptUpdated.flexPrompt = newValue;

                if (aiPromptUpdated.selectedSentences && aiPromptUpdated.selectedSentences.length > 0) {
                    let removeIndexes: number[] = [];
                    forEach(aiPromptUpdated.selectedSentences, (ss: any, idx: number) => {
                        if (aiPromptUpdated.flexPrompt.indexOf(ss.sentence) < 0) {
                            // this sentence is no longer part of the prompt.
                            removeIndexes.push(idx);
                        }
                    });
                    forEach(removeIndexes, (rid: number) => {
                        aiPromptUpdated.selectedSentences.splice(rid);
                    });
                }
                // console.log('%c handleChange aiPromptUpdated: %O', 'color: green;', aiPromptUpdated);
                focus.updateAIPrompt(aiPromptUpdated);
            }
        }
    };

    const clearFocusBoardSelectedSentences = (evt: any) => {
        // console.log('%c clearFocusBoardSelectedSentences evt: %O', 'color: maroon;', evt);
        if (focus.updateAIPrompt) {
            let aiPromptUpdated: any = {};
            if (focus.aiPrompt) Object.assign(aiPromptUpdated, focus.aiPrompt);
            aiPromptUpdated.flexPrompt = '';
            aiPromptUpdated.selectedSentences = [];
            // console.log('%c aiPromptUpdated: %O', 'color: maroon;', aiPromptUpdated);
            focus.updateAIPrompt(aiPromptUpdated);
        }

        setChooseSocialSolution(false);
        setChooseFeature(false);
        setChooseMediator(false);
        setEngageToUdicci(false);
    };

    const editThePrompt = () => {
        setEditPrompt(true);
    };

    const clearAIError = () => {
        if (focus && focus.clearAIError) focus.clearAIError();
    };

    const closeEditPrompt = () => {
        setEditPrompt(false);
    };

    const submitPrompt = () => {
        // console.log("submitPrompt focus: %O", focus);
        if (!focus) return false;

        let aiPrompt: string = (focus.aiPrompt && focus.aiPrompt.flexPrompt ? focus.aiPrompt.flexPrompt : '');
        if (!aiPrompt) return false;
        // console.log("submitPrompt aiPrompt: %O", aiPrompt);

        setEditPrompt(false);
        setProcessing(true);
        focus.sendAIRequest({ onAiResponseReceived: onAiResponseReceived, onAiErrorReceived: onAiErrorResponseReceived  });
    };

    const onAiResponseReceived = (response: any) => {
        // console.log('%c onAiResponseReceived lastAIResponse: %O', 'color: blue;', lastAIResponse);
        // let response: any = (focus && focus.lastAIResponse ? focus.lastAIResponse : null);
        setProcessing(false);
        // console.log('%c onAiResponseReceived response: %O', 'color: blue;', response);
        if (response) {
            let data: any = (response.data ? response.data : null);
            // console.log('%c data: %O', 'color: blue;', data);
            let choices: any[] = (data.choices ? data.choices : []);
            // console.log('%c choices: %O', 'color: blue;', choices);
            // let usage: any = (data.usage ? data.usage : null);
            // console.log('%c usage: %O', 'color: blue;', usage);
            let created: any = (data.created ? data.created : '');
            // console.log('%c created: %O', 'color: blue;', created);

            let headers: any = (response.headers ? response.headers : null);
            // console.log('%c headers: %O', 'color: blue;', headers);
            let contentLength: any = (headers && headers['content-length'] ? headers['content-length'] : '0');
            // console.log('%c contentLength: %O', 'color: blue;', contentLength);

            let config: any = (response.config ? response.config : null);
            // console.log('%c config: %O', 'color: blue;', config);
            let request: any = (config && config.data ? config.data : '');
            // console.log('%c request: %O', 'color: blue;', request);
            let requestJson: any = null;
            if (request) {
                try {
                    requestJson = JSON.parse(request);
                } catch {

                }
            }
            // console.log('%c requestJson: %O', 'color: blue;', requestJson);
            let requestPrompt: string = (requestJson && requestJson.prompt ? requestJson.prompt : '');
            // console.log('%c requestPrompt: %O', 'color: blue;', requestPrompt);

            let createdDate: Date | null = null;
            if (created) createdDate = new Date(created);
            // console.log('%c request: %O', 'color: blue;', request);
            let title: string = 'AI Perspective generated ' + (createdDate ? createdDate.toString() : created.toString());
            let desc: string = (choices.length > 0 && choices[0].text ? choices[0].text.trim() : '');
            if (desc.startsWith('\n')) desc = desc.substring(1);
            if (desc.startsWith('\n')) desc = desc.substring(1);

            let newPerspective: UdicciRecord = focus.getNewPerspectiveRecord();
            newPerspective.title = title;
            newPerspective.data.Title = title;
            newPerspective.description = desc;
            newPerspective.data.TalkingPoints = desc;

            newPerspective.data.jsonSettingsJson = {
                aiPerspective: {
                    prompt: requestPrompt,
                    response: data,
                    contentLength: contentLength,
                    request: requestJson,
                }
            };
            newPerspective.data.SettingsJson = JSON.stringify(newPerspective.data.jsonSettingsJson);

            newPerspective.isDirty = true;
            // console.log('%c newPerspective: %O', 'color: blue;', newPerspective);

            let updatedPoint: any = {};
            Object.assign(updatedPoint, focus.point);

            let updatedPointPerspectives: any[] = (updatedPoint.perspectives ? updatedPoint.perspectives : []);
            updatedPointPerspectives.push(newPerspective);
            updatedPoint.perspectives = updatedPointPerspectives;

            updatedPoint.isDirty = true;

            // we are already passing the same method "onSetFocus" for onUpdate in other components,
            // but for this component we'll just use the "onSetFocus" method for updates instead of passing onUpdate
            if (focus.setPointToFocus) focus.setPointToFocus(updatedPoint);
            // if (focus.requestAIAssistance) focus.requestAIAssistance();
        }
    };

    const onAiErrorResponseReceived = (aiError: any) => {
        // console.log('%c onAiErrorResponseReceived aiError: %O', 'color: blue;', aiError);
        setProcessing(false);
    };

    useEffect(() => {
        // console.log('%c useEffect subjectMatter: %O', 'color: red;', subjectMatter);
        if (subjectMatter) {
            let med: any = (subjectMatter && subjectMatter.mediator ? subjectMatter.mediator : null);
            if (med) {
                let ss: any = (subjectMatter && subjectMatter.socialSolution ? subjectMatter.socialSolution : null);
                let ssd: any = (ss && ss.data ? ss.data : null);
                let sss: any = (ssd && ssd.jsonSettingsJson ? ssd.jsonSettingsJson : null);
                let ssm: any = (sss && sss.mediators ? sss.mediators : null);

                let solutionMediatorNames: string[] = keys(ssm);
                let missingMediatorStructures: string[] = [];
                forEach(solutionMediatorNames, (medName: string) => {
                    if (missingMediatorStructures.indexOf(medName) < 0) {
                        let chkStructure: any = udicciHelpers.getMediatorStructure(medName);
                        if (!chkStructure) missingMediatorStructures.push(medName);
                    }
                });
                // console.log('%c missingMediatorStructures: %O', 'color: hotpink;', missingMediatorStructures);
                if (missingMediatorStructures.length > 0) getMediatorStructures(missingMediatorStructures);
            }
        }
    }, [subjectMatter]);

    let aiPromptItemElements: any[] = [];
    if (aiPrompt && aiPrompt.selectedSentences && aiPrompt.selectedSentences.length > 0) {
        forEach(aiPrompt.selectedSentences, (sentenceItem: any, sentenceIndex: number) => {
            let sentence: string = sentenceItem.sentence;
            let sentenceUid: string = sentenceItem.uid;
            let sentenceKey: string = 'ai.prompt.sentence.' + sentenceUid + '.' + sentenceIndex.toString();

            let itemProps: any = {
                key: sentenceKey,
                variant: "caption",
                sx: { marginRight: '4px' },
            };
            aiPromptItemElements.push(<Typography {...itemProps}>{sentence}</Typography>);

            // flexPrompt += (flexPrompt.length > 0 ? '  ' : '') + sentence;
        });
    }

    let closeFocusElement: any = null;
    let restartFocusElement: any = null;
    let editAiPromptElement: any = null;
    let aiPromptDisplayElement: any = null;
    let sendPromptIcon: any = null;
    let engageUlyssesAIButton: any = null;
    let engageUlyssesITButton: any = null;
    let engageButtonGroup: any = null;
    let aiErrorDisplayElement: any = null;

    let sendDisabled: boolean = (flexPrompt.length <= 2 || processing || engageToUdicci ? true : false);
    let engageDisabled: boolean = (selectedSentences.length <= 0 || processing ? true : false);
    if (!sendDisabled) {
        sendPromptIcon = (
            <img src={UDAIC_SOCIAL_ICON} alt="Udicci" style={{ height: 'unset', width: '24px'  }} />
        );
    }

    let waitingForResponseIcon: any = null;
    if (processing) {
        waitingForResponseIcon = (<CircularProgress size={20} />);
    }
    engageUlyssesAIButton = (
        <Button color="secondary" size="small" disabled={sendDisabled} startIcon={sendPromptIcon} onClick={submitPrompt}>
            Get AI Perspective
            {waitingForResponseIcon}
        </Button>
    );

    let engageToUdicciElement: any = null;
    if (engageToUdicci) {
        engageUlyssesITButton = (
            <Button color="secondary" size="small" disabled={engageDisabled} onClick={disengageUdicci}>
                Hide Configuration
            </Button>
        );

        let sortedSolutions: any[] = sortBy(profileSolutions, (ps: any) => { return ps.title });
        // console.log('%c sortedSolutions: %O', 'color: purple; font-weight: bold;', sortedSolutions);

        let firstSolution: any = (sortedSolutions.length > 0 ? sortedSolutions[0] : null);
        // console.log('%c firstSolution: %O', 'color: purple;', firstSolution);
        let useSocialSolution: any = (engagedSocialSolution ? engagedSocialSolution : firstSolution);
        // console.log('%c useSocialSolution: %O', 'color: purple;', useSocialSolution);

        let ssData = (useSocialSolution && useSocialSolution.data ? useSocialSolution.data : null);
        // console.log('%c ssData: %O', 'color: purple;', ssData);
        if (ssData && !ssData.jsonSettingsJson && ssData.SettingsJson) {
            try {
                ssData.jsonSettingsJson = JSON.parse(ssData.SettingsJson);
            } catch (e: any) {

            }
        }

        var socialSolutionFeatures = (ssData && ssData.Features ? ssData.Features : null);
        // console.log('%c socialSolutionFeatures: %O', 'color: purple;', socialSolutionFeatures);
        let firstFeature: any = (socialSolutionFeatures && socialSolutionFeatures.length > 0 ? socialSolutionFeatures[0] : null);
        // console.log('%c firstFeature: %O', 'color: purple;', firstFeature);
        let useFeature: any = (engagedFeature ? engagedFeature : firstFeature);
        // console.log('%c useFeature: %O', 'color: purple;', useFeature);

        var socialSolutionSettings = (ssData && ssData.jsonSettingsJson ? ssData.jsonSettingsJson : null);
        // console.log('%c socialSolutionSettings: %O', 'color: purple;', socialSolutionSettings);
        var ssMediatorSettings = (socialSolutionSettings && socialSolutionSettings.mediators ? socialSolutionSettings.mediators : null);
        // console.log('%c ssMediatorSettings: %O', 'color: purple;', ssMediatorSettings);

        let mediators: any[] = [];
        if (ssMediatorSettings && useFeature) {
            forEach(values(ssMediatorSettings), function(med: any, idxMed: number) {
                // console.log('%c med %s: %O', 'color: purple;', med.name, med);
                let mediatorFeatures = (med && med.features ? med.features : null);
                // console.log('%c mediatorFeatures: %O', 'color: purple;', mediatorFeatures);
                if (mediatorFeatures) {
                    let ftreMed: any = find(mediatorFeatures, (mf: any) => mf.id === useFeature.UdicciRecordId);
                    // console.log('%c ftreMed: %O', 'color: purple;', ftreMed);
                    if (ftreMed) mediators.push(med);
                }
            });
        }
        // console.log('%c mediators: %O', 'color: red;', mediators);

        let firstMediator: any = (mediators && mediators.length > 0 ? mediators[0] : null);
        // console.log('%c firstMediator: %O', 'color: purple;', firstMediator);
        let useMediator: any = (engagedMediator ? engagedMediator : firstMediator);
        // console.log('%c useMediator: %O', 'color: purple;', useMediator);

        let socialSolutionName: string = (useSocialSolution && useSocialSolution.title ? useSocialSolution.title : 'Social Solution');
        let featureName: string = (useFeature && useFeature.Name ? useFeature.Name : 'Feature');
        let mediatorName: string = (useMediator && useMediator.name ? useMediator.name : 'Mediator');

        let socialSolutionContainerSx: any = {
            padding: '4px',
            marginBottom: '8px',
            background: 'rgba(255, 255, 255, 0.07)',
            borderBottom: '1px solid #ccc',
            display: 'flow-root',
        };
        let socialSolutionTitleSx: any = {
            cursor: 'pointer'
        };
        let featureTitleProps: any = {
            variant: 'clickableSubTitle2',
            component: 'div',
            sx: { marginRight: '4px' }
        };

        let solutionElements: any[] = [];
        if (sortedSolutions && chooseSocialSolution) {
            forEach(sortedSolutions, function(socialSolution: any, idxSocialSolution: number) {
                // console.log('%c socialSolution %s: %O', 'color: purple;', socialSolution.title, socialSolution);
    
                let isUdicciIT: boolean = (socialSolution.recordId === udicci.socialSolutionUdicciIT ? true : false);
                if (isUdicciIT) {
                    if (selectedProfile && selectedProfile.recordId === udicci.ulyssesDConstantineProfileId) {
                        if (currentUser && currentUser.UdicciUserId && udicci.specialUsers.indexOf(currentUser.UserName) < 0) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }

                let ssElementKey: string = 'ss.' + socialSolution.recordId.toString();
                let ssData = (socialSolution && socialSolution.data ? socialSolution.data : null);
                // console.log('%c ssData: %O', 'color: purple;', ssData);
                if (ssData && !ssData.jsonSettingsJson && ssData.SettingsJson) {
                    try {
                        ssData.jsonSettingsJson = JSON.parse(ssData.SettingsJson);
                    } catch (e: any) {
    
                    }
                }

                var socialSolutionSettings = (ssData && ssData.jsonSettingsJson ? ssData.jsonSettingsJson : null);
                // console.log('%c socialSolutionSettings: %O', 'color: purple;', socialSolutionSettings);
                var ssMediatorSettings = (socialSolutionSettings && socialSolutionSettings.mediators ? socialSolutionSettings.mediators : null);
                // console.log('%c ssMediatorSettings: %O', 'color: purple;', ssMediatorSettings);
                var socialSolutionFeatures = (ssData && ssData.Features ? ssData.Features : null);
                // console.log('%c socialSolutionFeatures: %O', 'color: purple;', socialSolutionFeatures);

                let ssFeaturesDisplay: any = null;
                if (socialSolutionFeatures && socialSolutionFeatures.length > 0) {
                    let featureElements: any[] = [];
                    forEach(socialSolutionFeatures, function(feature: any, idxFeature: number) {
                        // console.log('%c feature %s: %O', 'color: purple;', feature.Name, feature);

                        let mediatorsAssignedToFeature: any[] = [];
                        if (ssMediatorSettings) {
                            forEach(values(ssMediatorSettings), function(med: any, idxMed: number) {
                                // console.log('%c med %s: %O', 'color: purple;', med.name, med);
                                let mediatorFeatures = (med && med.features ? med.features : null);
                                // console.log('%c mediatorFeatures: %O', 'color: purple;', mediatorFeatures);
                                if (mediatorFeatures) {
                                    let ftreMed: any = find(mediatorFeatures, (mf: any) => mf.id === feature.UdicciRecordId);
                                    // console.log('%c ftreMed: %O', 'color: purple;', ftreMed);
                                    if (ftreMed) mediatorsAssignedToFeature.push(med);
                                }
                            });
                        }
                        // console.log('%c mediatorsAssignedToFeature: %O', 'color: red;', mediatorsAssignedToFeature);
                        if (mediatorsAssignedToFeature.length <= 0) return true;

                        let ssFtreElementKey: string = ssElementKey + '.ftre.' + feature.UdicciRecordId.toString();
                        let featureContainerSettings: any = {
                            key: ssFtreElementKey,
                            onClick: () => selectSocialSolutionFeature(socialSolution, feature),
                            sx: {
                                padding: '2px',
                                display: 'flex',
                                float: 'left',
                                // cursor: 'pointer'
                            }
                        };

                        featureElements.push(
                            <Box {...featureContainerSettings}>
                                <Typography {...featureTitleProps}>{feature.Name}.</Typography>
                            </Box>
                        );
                    });

                    if (featureElements.length > 0) ssFeaturesDisplay = ( <Box> {featureElements} </Box> );
                }

                if (ssFeaturesDisplay) {
                    let solutionContainerSettings: any = {
                        key: ssElementKey,
                        sx: socialSolutionContainerSx,
                    };
                    let socialSolutionTitleProps: any = {
                        variant: 'clickable',
                        component: 'div',
                        onClick: () => selectSocialSolution(socialSolution),
                        sx: socialSolutionTitleSx
                    };
                    solutionElements.push(
                        <Box {...solutionContainerSettings}>
                            <Typography {...socialSolutionTitleProps}>{socialSolution.title}</Typography>
                            {ssFeaturesDisplay}
                        </Box>
                    );
                }
            });
        }

        let chooseSocialSolutionElement: any = null;
        if (chooseSocialSolution) {
            let socialSolutionDisplayElement: any = null;
            if (solutionElements.length > 0) {
                socialSolutionDisplayElement = ( <Box sx={{ paddingBottom: '32px' }}> {solutionElements} </Box> );
            }
            chooseSocialSolutionElement = (
                <Box sx={{ margin: '8px' }}>
                    <Typography variant="caption">Choose Social Solution.</Typography>
                    {socialSolutionDisplayElement}
                </Box>
            );
        }

        let ssElementKey: string = 'ss.' + useSocialSolution.recordId.toString();
        let featureElements: any[] = [];
        // !isRemoveSocialSolution && isSelectedSocialSolution && 
        if (chooseFeature && socialSolutionFeatures && socialSolutionFeatures.length > 0) {
            forEach(socialSolutionFeatures, function(feature: any, idxFeature: number) {
                // console.log('%c feature %s: %O', 'color: red;', feature.Name, feature);

                let ftreElementKey: string = ssElementKey + '.feature.' + feature.UdicciRecordId.toString();

                let featureData = (feature && feature.data ? feature.data : null);
                // console.log('%c featureData: %O', 'color: purple;', featureData);
                if (featureData && !featureData.jsonMediators && featureData.Mediators) {
                    try {
                        featureData.jsonMediators = JSON.parse(featureData.Mediators);
                    } catch (e: any) {
    
                    }
                }

                // let mediators: any[] = [];
                // if (ssMediatorSettings) {
                //     forEach(values(ssMediatorSettings), function(med: any, idxMed: number) {
                //         // console.log('%c med %s: %O', 'color: purple;', med.name, med);
                //         let mediatorFeatures = (med && med.features ? med.features : null);
                //         // console.log('%c mediatorFeatures: %O', 'color: purple;', mediatorFeatures);
                //         if (mediatorFeatures) {
                //             let ftreMed: any = find(mediatorFeatures, (mf: any) => mf.id === feature.UdicciRecordId);
                //             // console.log('%c ftreMed: %O', 'color: purple;', ftreMed);
                //             if (ftreMed) mediators.push(med);
                //         }
                //     });
                // }
                // console.log('%c mediators: %O', 'color: red;', mediators);

                // let featureFormElement: any = null;
                // let mediatorElements: any[] = [];
                // if (mediators.length > 0) {
                //     let sortedMediators: any[] = sortBy(mediators, (med: any) => { return med.name });
                //     forEach(sortedMediators, function(med: any, idxMed: number) {
                //         // console.log('%c med %s: %O', 'color: purple;', med.name, med);

                //         if (true) { //isSelectedMediator) {
                //             let mediatorContainerSettings: any = {
                //                 key: ftreElementKey + '.mediator.' + med.id.toString(),
                //                 sx: { margin: '4px', display: 'flow-root' },
                //             };
                //             let ftreMedElementSettings: any = {
                //                 // key: ftreElementKey + '.mediator.' + med.id.toString(),
                //                 variant: "caption",
                //                 component: "span",
                //                 // onClick: () => onChooseSubjectMatter(socialSolution, feature, med),
                //                 onClose: null,
                //                 sx: { cursor: 'pointer', display: 'flex', borderBottom: '1px dashed #ccc' }
                //             };

                //             mediatorElements.push(
                //                 <Box {...mediatorContainerSettings}>
                //                     <Typography {...ftreMedElementSettings}>{med.name}</Typography>
                //                 </Box>
                //             );
                //         }
                //     });
                // }

                // let featureMediatorsDisplayElement: any = null;
                // let editFeatureIconElement: any = null;
                // if (featureFormElement) {
                //     featureMediatorsDisplayElement = (
                //         <Box sx={{ padding: '8px' }}>
                //             {featureFormElement}
                //         </Box>
                //     );
                // } else {
                //     if (mediatorElements.length > 0) {
                //         featureMediatorsDisplayElement = (
                //             <Box sx={{ padding: '8px', display: 'flex' }}>{mediatorElements}</Box>
                //         );
                //     }
                // }

                // if (mediatorElements.length > 0) {
                // }

                let featureContainerSettings: any = {
                    key: ftreElementKey,
                    onClick: () => selectFeature(feature),
                    sx: { padding: '2px', background: 'rgba(255, 255, 255, 0.07)', borderBottom: '1px solid #ccc', display: 'flow-root', cursor: 'pointer' }
                };
                featureElements.push(
                    <Box {...featureContainerSettings}>
                        <Typography variant="caption" component="span">{feature.Name}</Typography>
                    </Box>
                );
            });
        }

        let chooseFeatureDisplayElement: any = null;
        if (chooseFeature) { //!isRemoveSocialSolution && isSelectedSocialSolution) {
            let featureDisplayElement: any = null;
            if (featureElements.length > 0) {
                featureDisplayElement = (
                    <Box sx={{ paddingBottom: '32px' }}>
                        {featureElements}
                    </Box>
                );
            }
            chooseFeatureDisplayElement = (
                <Box sx={{ margin: '8px' }}>
                    <Typography variant="caption">Choose Feature.</Typography>
                    {featureDisplayElement}
                </Box>
            );
        }

        let mediatorElements: any[] = [];
        if (chooseMediator && mediators && mediators.length > 0) {
            forEach(mediators, function(med: any, idxMediator: number) {
                // console.log('%c med %s: %O', 'color: red;', med.name, med);

                let mdtrElementKey: string = ssElementKey + '.mediator.' + med.id.toString();

                let mediatorContainerSettings: any = {
                    key: mdtrElementKey,
                    onClick: () => selectMediator(med),
                    sx: { padding: '2px', background: 'rgba(255, 255, 255, 0.07)', borderBottom: '1px solid #ccc', display: 'flow-root', cursor: 'pointer' }
                };
                mediatorElements.push(
                    <Box {...mediatorContainerSettings}>
                        <Typography variant="caption" component="span">{med.name}</Typography>
                    </Box>
                );
            });
        }

        let chooseMediatorDisplayElement: any = null;
        if (chooseMediator) { //!isRemoveSocialSolution && isSelectedSocialSolution) {
            let mediatorDisplayElement: any = null;
            if (mediatorElements.length > 0) {
                mediatorDisplayElement = (
                    <Box sx={{ paddingBottom: '32px' }}>
                        {mediatorElements}
                    </Box>
                );
            }
            chooseMediatorDisplayElement = (
                <Box sx={{ margin: '8px' }}>
                    <StickyPaperContainer elevation={0}>
                        <Typography variant="caption" sx={{ position: 'sticky', top: 40 }}>Choose Mediator.</Typography>
                    </StickyPaperContainer>
                    {mediatorDisplayElement}
                </Box>
            );
        }

        let engageSummaryElement: any = null;
        if (!chooseSocialSolution && !chooseFeature && !chooseMediator) {
            engageSummaryElement = (
                <Box>
                    <Typography variant="caption">Select a Udicci Social Solution, a feature and then a mediator to begin.</Typography>
                </Box>
            );
        }

        engageToUdicciElement = (
            <EngageContainer>
                <Box sx={{ padding: '8px' }}>
                    <Typography variant="body2" sx={{ marginRight: '4px' }}>Configure Focus Board Subject Matter Associations.</Typography>
                    {engageSummaryElement}
                    {chooseSocialSolutionElement}
                    {chooseFeatureDisplayElement}
                    {chooseMediatorDisplayElement}
                </Box>
                <ButtonContainer sx={{ position: 'sticky', bottom: 0 }}>
                    <ButtonGroup fullWidth size="large" color="primary" variant="contained">
                        <Button disabled={chooseContext} size="small" variant="contained" color="secondary" onClick={toggleChooseSocialSolution}>
                            {socialSolutionName}
                        </Button>
                        <Button disabled={!useSocialSolution || chooseContext} size="small" variant="contained" color="secondary" onClick={toggleChooseFeature}>
                            {featureName}
                        </Button>
                        <Button disabled={!useFeature || chooseContext} size="small" variant="contained" color="secondary" onClick={toggleChooseMediator}>
                            {mediatorName}
                        </Button>
                        <Button disabled={!useMediator} size="small" variant="contained" color="secondary" onClick={toggleChooseContext} sx={{ width: '75px' }}>
                            <Icon>list</Icon>
                        </Button>
                    </ButtonGroup>
                </ButtonContainer>
            </EngageContainer>
        );
    } else {
        engageUlyssesITButton = (
            <Button color="secondary" size="small" disabled={engageDisabled} onClick={engageUdicci}>
                Configure Subject Matter
            </Button>
        );
    }

    if (editPrompt) {
        // console.log('%c AIPromptBuilder aiPrompt: %O', 'color: blue;', aiPrompt);
        let promptPlaceholder: string = 'Use this field to enter a prompt to send to ChatGPT AI.';

        // let sendPromptIcon: any = (
        //     <InputAdornment position="end" sx={{ position: 'absolute', bottom: 26, right: 16 }}>
        //         <IconButton size="small" disabled={sendDisabled} onClick={submitPrompt}>
        //             <Icon fontSize="small">send</Icon>
        //         </IconButton>
        //     </InputAdornment>
        // );
        // let sendPromptInputAdorment: any = (
        //     <InputAdornment position="end" sx={{ position: 'absolute', bottom: 26, right: 16 }}>
        //         {sendPromptIcon}
        //     </InputAdornment>
        // );

        // InputProps={{ endAdornment: sendPromptIcon, }}
        aiPromptDisplayElement = (
            <PaperContainer>
                <TextField fullWidth
                           type="text"
                           value={flexPrompt}
                           placeholder={promptPlaceholder}
                           margin="normal"
                           variant='filled'
                           multiline={true} minRows={3} maxRows={7}
                           sx={{ marginTop: '3px', marginBottom: '3px' }}
                           onChange={handleChange}
                />
            </PaperContainer>
        );

        editAiPromptElement = (
            <Tooltip title={"Close AI Prompt text input."}>
                <IconButton disabled={processing} color="cancelButton" aria-label="close AI Prompt text input" edge="end" sx={{ float: 'right' }} onClick={closeEditPrompt}>
                    <Icon>cancel_presentation</Icon>
                </IconButton>
            </Tooltip>
        );
    } else if (flexPrompt.length > 0) {
        closeFocusElement = (
            <Tooltip title={"Close Udicci.IT Intelli-Face."}>
                <IconButton disabled={processing} color="cancelButton" aria-label="close AI Assist" edge="end" sx={{ float: 'right' }} onClick={focus.requestAIAssistance}>
                    <Icon>do_not_disturb</Icon>
                </IconButton>
            </Tooltip>
        );
        aiPromptDisplayElement = (<Box sx={{ padding: '8px' }}> <Typography variant="body2">{flexPrompt}</Typography> </Box>);
    } else if (aiPromptItemElements.length > 0) {
        closeFocusElement = (
            <Tooltip title={"Close Udicci.IT Intelli-Face."}>
                <IconButton disabled={processing} color="cancelButton" aria-label="close AI Assist" edge="end" sx={{ float: 'right' }} onClick={focus.requestAIAssistance}>
                    <Icon>do_not_disturb</Icon>
                </IconButton>
            </Tooltip>
        );
        aiPromptDisplayElement = (<Box sx={{ padding: '8px' }}> {aiPromptItemElements} </Box>);
    } else {
        engageUlyssesAIButton = null;
        engageUlyssesITButton = null;
        closeFocusElement = (
            <Tooltip title={"Close Udicci.IT Intelli-Face."}>
                <IconButton disabled={processing} color="cancelButton" aria-label="close AI Assist" edge="end" sx={{ float: 'right' }} onClick={focus.requestAIAssistance}>
                    <Icon>do_not_disturb</Icon>
                </IconButton>
            </Tooltip>
        );
        aiPromptDisplayElement = (
            <Box sx={{ padding: '8px' }}>
                <Typography variant="caption" component="div" sx={{ margin: '8px' }}>
                    You can create a prompt to be sent to the ChatGPT AI processor from this screen.
                </Typography>
                <Typography variant="caption" component="div" sx={{ margin: '8px' }}>
                    The easiest way to get started is by clicking on sentences in your content above.  Each sentence 
                    is interactive and when clicked it is either included or excluded from your prompt. Your AI Prompt 
                    will be displayed where this message is currently displayed.
                </Typography>
                <Typography variant="caption" component="div" sx={{ margin: '8px' }}>
                    Sentences are added in the order which you click them.  You can remove sentences by clicking on the sentence again, 
                    which will remove it from your prompt.  You can also edit the prompt directly by clicking the pencil icon above.
                </Typography>
                <Box sx={{ margin: '8px', textAlign: 'center' }}>
                    <Typography variant="caption" component="span" sx={{ color: green[300], marginLeft: '4px' }}>
                        Try clicking on the title of your point above to give AI context for your request.
                    </Typography>
                </Box>
            </Box>
        );
    }

    if (!editAiPromptElement) {
        editAiPromptElement = (
            <Tooltip title={"Edit the prompt directly"}>
                <IconButton disabled={processing} color="primary" aria-label="change the prompt" edge="end" sx={{ float: 'right' }} onClick={editThePrompt}>
                    <Icon>edit</Icon>
                </IconButton>
            </Tooltip>
        );
    }

    if (engageUlyssesAIButton || engageUlyssesITButton) {
        engageButtonGroup = (
            <ButtonGroup fullWidth size="large" color="primary" variant="contained">
                {engageUlyssesAIButton}
                {engageUlyssesITButton}
            </ButtonGroup>
        );
    }

    if (aiError) {
        let aiErrorCode: string = (aiError.code ? aiError.code : '');
        let aiErrorMessage: string = (aiError.message ? aiError.message : '');
        let aiErrorType: string = (aiError.type ? aiError.type : '');
        aiErrorDisplayElement = (
            <Box sx={{ padding: '8px' }}>
                <IconButton color="secondary" aria-label="Clear AI Error" edge="end" sx={{ float: 'right' }} onClick={clearAIError}>
                    <Icon>highlight_off</Icon>
                </IconButton>
                <Typography variant="errorMessage" component="div" sx={{ margin: '8px' }}>
                    {aiErrorMessage}
                </Typography>
                <Box sx={{ margin: '8px', textAlign: 'center' }}>
                    <Typography variant="caption" component="span" sx={{ marginRight: '4px' }}>
                        {aiErrorType}
                    </Typography>
                    <Typography variant="caption" component="span"> / </Typography>
                    <Typography variant="caption" component="span" sx={{ marginLeft: '4px' }}>
                        {aiErrorCode}
                    </Typography>
                </Box>
            </Box>
        );
    }

    restartFocusElement = (
        <Tooltip title={"Clear all selected sentences."}>
            <IconButton disabled={processing || selectedSentences.length <= 0} color="cancelButton" aria-label="clear selected sentences" edge="end" sx={{ float: 'right' }} onClick={clearFocusBoardSelectedSentences}>
                <Icon>restart_alt</Icon>
            </IconButton>
        </Tooltip>
    );

    let buttonContainerElement: any = null;
    if (engageButtonGroup) {
        buttonContainerElement = (
            <ButtonContainer> {engageButtonGroup} </ButtonContainer>
        );
    }

    let promptHeader: string = (focus.point && focus.point.title ? 'Udicci.IT Intelli-Face' : '');
    let aiPromptBuilderDisplayElement: any = (
        <IntelliFaceContainer isMobile={isMobile} standardMenuDrawerWidth={udicci.standardMenuDrawerWidth} elevation={7}>
            <IntelliFaceContentContainer id={UDICCI_INTELLI_FACE_CONTAINER_ID} isMobile={isMobile} standardMenuDrawerWidth={udicci.standardMenuDrawerWidth}>
                <PaperContainer elevation={5}>
                    <Box sx={{ position: 'sticky', top: 0 }}>
                        <HeaderPaperContainer elevation={0}>
                            {closeFocusElement}
                            {editAiPromptElement}
                            {restartFocusElement}
                            <Typography variant="caption">{promptHeader}.</Typography>
                        </HeaderPaperContainer>
                    </Box>
                    {aiPromptDisplayElement}
                    {aiErrorDisplayElement}
                    {engageToUdicciElement}
                    {buttonContainerElement}
                </PaperContainer>
             </IntelliFaceContentContainer>
        </IntelliFaceContainer>
    );

    return aiPromptBuilderDisplayElement;
};
