import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/HowToReg';

const UdicciNavigation = dynamic(() => import('src/components/udicci-navigation'));
const UdicciNavigationAdmin = dynamic(() => import('src/admin/udicci-navigation-admin'));

const udicciNavigationPlugin: UdicciPlugin = {
    Renderer: (data: any) => ( <UdicciNavigation {...data} /> ),
    id: 'udicciNavigation',
    title: 'Udicci Navigation',
    description: 'To Set Up and Display Navigation.',
    version: 1,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <UdicciNavigationAdmin {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c udicciNavigationPlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c udicciNavigationPlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
     },
    category: UdicciPluginCategory.Special
};
export default udicciNavigationPlugin;