import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/HowToReg';

const ChainStory = dynamic(() => import('src/components/chain-stories'));
const ChainStoryAdmin = dynamic(() => import('src/admin/chain-story-admin'));

const chainStoryPlugin: UdicciPlugin = {
    Renderer: (data: any) => ( <ChainStory {...data} /> ),
    id: 'chainStory',
    title: 'Chain Story',
    description: 'Display a Chain Story.',
    version: 1,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <ChainStoryAdmin {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c chainStoryPlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c chainStoryPlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
    },
    category: UdicciPluginCategory.Data
};
export default chainStoryPlugin;