import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/Timelapse';

const UdicciDecisionMaker = dynamic(() => import('src/components/udicci-decision-maker'));
const UdicciDecisionMakerAdmin = dynamic(() => import('src/admin/udicci-decision-maker-admin'));

const UdicciDecisionMakerPlugin: UdicciPlugin = {
    Renderer: (data: any) => ( <UdicciDecisionMaker {...data} /> ),
    id: 'udicci.decision.maker',
    title: 'Udicci Decision Maker',
    description: 'Display the Decision Maker component which allows for users to setup and make decisions based on 2 lists, one for pros and one for cons.',
    version: 1,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <UdicciDecisionMakerAdmin {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c UdicciHabitTracker canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c UdicciHabitTracker canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
     },
    category: UdicciPluginCategory.Development
};
export default UdicciDecisionMakerPlugin;