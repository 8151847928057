import React from 'react';

import { useTheme } from '@mui/material/styles';

import UrlParser from 'url-parse';

import {
    Box, Paper, FormControl, Button, IconButton, Typography, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Grid, InputLabel, InputAdornment, TextField, CircularProgress
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useUdicciContext, userLogin } from 'src/context/udicci-context';

import { udicciStyles } from 'src/theme/shared-styles';

const Login: React.FC<any> = (props) => {
    // console.log('%c Login props: %O', 'color: maroon;', props);

    let { data, onClose, onLoginSuccessful } = props;
    // console.log('%c Login data: %O', 'color: maroon;', data);

    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;

    let savedUsername: string | null = window.localStorage.getItem('udicci.last.login.username');
    // console.log('%c Login savedUsername: %O', 'color: maroon;', savedUsername);

    const [username, setUsername] = React.useState((savedUsername ? savedUsername : ''));
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [processingLogin, setProcessingLogin] = React.useState(false);

    const [loginMessage, setLoginMessage] = React.useState('');

    const signIn = (evt: any) => {
        let loginRequest = {
            username: username,
            password: password,
            onSuccess: signInSuccessful.bind(this),
            onError: signInFailed.bind(this)
        };
        // console.log('%c signIn loginRequest: %O', 'color: maroon;', loginRequest);

        setProcessingLogin(true);
        userLogin(loginRequest);
    }

    const signInSuccessful = (userDetails: any) => {
        // console.log('%c signInSuccessful userDetails: %O', 'color: maroon;', userDetails);
        setProcessingLogin(false);

        let currentUrl = window.location.href;
        let url = UrlParser(currentUrl, true);
        // console.log('%c Me url: %O', 'color: blue;', url);

        if (username) window.localStorage.setItem('udicci.last.login.username', username);
        if (url.hash) window.location.hash = '';

        if (onLoginSuccessful) {
            onLoginSuccessful();
        } else {
            if (onClose) onClose();
        }
    }

    const signInFailed = (response: any) => {
        // console.log('%c signInFailed response: %O', 'color: maroon;', response);
        setProcessingLogin(false);
        let loginMessage = 'Invalid Login Credentials.  Please try again.';
        if (response && response.errorDescription) {
            loginMessage = response.errorDescription;
        }
        setLoginMessage(loginMessage);
    }

    const hideLogin = (evt: any) => {
        if (onClose) onClose();
    }

    const handleLoginFormChange = (fieldName: string, evt: any) => {
        // console.log('%c handleLoginFormChange fieldName: %O', 'color: maroon;', fieldName);
        // console.log('%c handleLoginFormChange evt: %O', 'color: maroon;', evt);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);

        if (fieldName === 'username') setUsername(newValue);
        if (fieldName === 'password') setPassword(newValue);
    };

    const handleFormKeyPress = (evt: any) => {
        if (evt.key === 'Enter') signIn(evt);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    let messageElement = null;
    if (loginMessage) {
        messageElement = (
            <DialogContentText id="alert-dialog-error">
                <Typography variant="errorMessage">
                    {loginMessage}
                </Typography>
            </DialogContentText>
        );
    }

    let autoFocusUsername = true;
    let autoFocusPassword = false;
    if (!username && savedUsername !== null && savedUsername !== '') {
        autoFocusUsername = false;
        autoFocusPassword = true;
    }

    let formTitleText: string = 'Enter Your Credentials to Login to Udicci.';
    if (props && props.title) formTitleText = props.title;
    if (data && data.title) formTitleText = data.title;
    let formTitleElement: any = (
        <Typography variant="body2"> {formTitleText} </Typography>
    );

    let formMessageElement: any = null;
    if (data && data.formMessage) {
        formMessageElement = (
            <Typography variant="errorMessage"> {data.formMessage} </Typography>
        );
    }

    let introGridProps: any = {
        container: true,
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'stretch',
        marginTop: 0,
    };
    let gridItemProps: any = { item: true, xs: 12, md: 6, sx: { paddingTop: '12px !important' } };

    let formControlProps: any = null;
    if (udicci.isMobileDevice()) {
        formControlProps = { fullWidth: true };
    }

    let loginFormGrid: any = (
        <Grid {...introGridProps}>
            <Grid {...gridItemProps}>
                <FormControl fullWidth>
                    <Typography variant="overFieldLabel"> Username </Typography>
                    <TextField id="udicci-username"
                                autoComplete={'username'}
                                variant={"filled"}
                                margin={'dense'}
                                value={username}
                                autoFocus={autoFocusUsername}
                                onKeyPress={handleFormKeyPress}
                                onChange={handleLoginFormChange.bind(this, 'username')}
                        />
                </FormControl>
            </Grid>
            <Grid {...gridItemProps}>
                <FormControl {...formControlProps}>
                    <Typography variant="overFieldLabel"> Password </Typography>
                    <TextField id="udicci-password"
                                autoComplete={'current-password'}
                                type={showPassword ? 'text' : 'password'}
                                variant={"filled"}
                                margin={'dense'}
                                value={password}
                                autoFocus={autoFocusPassword}
                                onKeyPress={handleFormKeyPress}
                                onChange={handleLoginFormChange.bind(this, 'password')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton size="small" color="info" aria-label="Toggle password visibility" onClick={handleClickShowPassword.bind(this)}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );

    let formContentElement: any = (
        <Box sx={{ padding: '8px' }}>
            {formMessageElement}
            {messageElement}
            {loginFormGrid}
        </Box>
    );
    let waitElement: any = null;
    if (processingLogin) {
        waitElement = (<CircularProgress size={20} />);
    }
    let formActionsElement: any = (
        <Box sx={{ display: 'flex' }}>
            <Box sx={udicciClasses.grow} />
            <Button onClick={hideLogin.bind(this)} color="info" variant="contained" sx={{ mr: 2 }}>
                Cancel
            </Button>
            <Button onClick={signIn.bind(this)} color="secondary" variant="contained" autoFocus disabled={processingLogin}>
                Login
                {waitElement}
            </Button>
        </Box>
    );

    let loginDisplayElement: any = null;
    if (data && data.showInline) {
        loginDisplayElement = (
            <Box sx={{ padding: '8px', maxWidth: '550px', marginRight: 'auto', marginLeft: 'auto' }}>
                <Box sx={{ padding: '8px' }}> {formTitleElement} </Box>
                <Box> {formContentElement} </Box>
                <Box sx={{ padding: '8px' }}> {formActionsElement} </Box>
            </Box>
        );
    } else {
        let open = true;
        loginDisplayElement = (
            <Dialog open={open} onClose={hideLogin}>
                <DialogTitle id="alert-dialog-title">{formTitleElement}</DialogTitle>
                <DialogContent> {formContentElement} </DialogContent>
                <DialogActions> {formActionsElement} </DialogActions>
            </Dialog>
        );
    }

    return loginDisplayElement;
};

export default Login;
