
import React, { Fragment, useState } from 'react';

import { styled, useTheme } from '@mui/material/styles';

import { find, values, last } from 'underscore';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Grid, { GridSize } from '@mui/material/Grid';

import AttachFile from '@mui/icons-material/AttachFile';
import PerspectivesIcon from '@mui/icons-material/Comment';

import useLongText from 'src/hooks/useLongText';

import {
    UnfoldMore as UnfoldMoreIcon,
    UnfoldLess as UnfoldLessIcon,
} from '@mui/icons-material';

import {
    useUdicciContext, getProfileSetting, createMarkup, keyable
} from 'src/context/udicci-context';

import { useUdicciListContext } from 'src/context/udicci-list-context';

import { checkConditionAgainstRecord } from 'src/classes/udicci-record';

import { AttachmentFile } from 'src/classes/udicci-types';

// import UdicciForm from './udicci-form';
import { RecordEngagement, EngagementMenuItem } from './record-engagement';
import { getEngagementActionPackages } from 'src/components/engagement-actions/engagement-actions';

import useUdicciRecord from 'src/hooks/useUdicciRecord';

import { RecordContextDisplay } from './udicci-record-context-display';

import Attachments from './attachments';
import Perspectives from './perspectives';

import { udicciStyles } from 'src/theme/shared-styles';

// const ListItemContainer = styled('div')(({ theme }) => ({
//     flexGrow: 1,
//     overflowY: 'auto',
//     overflowX: 'visible',
//     borderRadius: theme.spacing(1),
//     marginBottom: theme.spacing(0.5),
//     padding: theme.spacing(0.5),
// }));

const RecordListItem = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    border: '0.7px dashed #132274',
    borderRadius: theme.spacing(1),
}));

const PerspectivesContainer = styled('div')(({ theme }) => ({
    border: '0.7px dashed #132274',
    // borderRadius: theme.spacing(1),
    marginLeft: '4px',
    marginRight: '4px',
    padding: theme.spacing(1),
    backgroundColor: 'rgb(0 0 0 / 10%)',
}));

const AttachmentsContainer = styled('div')(({ theme }) => ({
    border: '0.7px dashed #132274',
    // borderRadius: theme.spacing(1),
    marginLeft: '4px',
    marginRight: '4px',
    padding: theme.spacing(1),
    backgroundColor: 'rgb(0 0 0 / 10%)',
}));

const UdicciRecordDisplay: React.FC<keyable> = (props) => {
    // console.log('%c UdicciRecordDisplay props: %O', 'color: maroon;', props);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const [showForm, setFormState] = React.useState((props && props.showForm ? true : false));
    const [showInline, setShowInline] = React.useState((props && props.showInline ? true : false));
    const [selectedEngagementAction, setEngagementAction] = React.useState<any | null>(null);
    const [showEngagementMenu, setShowEngagementMenu] = useState<boolean>(false);
    const [showAttachments, setShowAttachments] = useState<boolean>(false);
    const [showPerspectives, setShowPerspectives] = useState<boolean>(showEngagementMenu);
    // console.log('%c UdicciRecordDisplay showPerspectives: %O', 'color: red;', showPerspectives);
    // console.log('%c UdicciRecordDisplay showEngagementMenu: %O', 'color: red;', showEngagementMenu);
    // console.log('%c UdicciRecordDisplay showForm: %O', 'color: red;', showForm);
    // console.log('%c UdicciRecordDisplay showInline: %O', 'color: red;', showInline);
    // console.log('%c UdicciRecordDisplay selectedEngagementAction: %O', 'color: red;', selectedEngagementAction);
    // console.log('%c UdicciRecordDisplay contextRetrieved: %O', 'color: red;', contextRetrieved);

    // const udicciRecordContext = useUdicciRecordContext();
    const udicciContext = useUdicciContext();
    const udicciListContext = useUdicciListContext();

    let propSource: any = (props && props.source ? props.source : 'udicci-list');
    // console.log('%c UdicciRecordDisplay propSource: %O', 'color: maroon;', propSource);
    let propRecord: any = (props && props.record ? props.record : null);
    // console.log('%c UdicciRecordDisplay propRecord: %O', 'color: maroon;', propRecord);
    let propEngagedAction: any = (props && props.engagedAction ? props.engagedAction : null);
    // console.log('%c UdicciRecordDisplay propEngagedAction: %O', 'color: maroon;', propEngagedAction);
    let propNotification: any = (props && props.notification ? props.notification : null);
    // console.log('%c UdicciRecordDisplay propActivitySummary: %O', 'color: maroon;', propActivitySummary);
    let propActivitySummary: any = (props && props.activitySummary ? props.activitySummary : null);
    // console.log('%c UdicciRecordDisplay propActivitySummary: %O', 'color: maroon;', propActivitySummary);

    const onReloadActivity = () => {
        if (props && props.fnReloadActivity) {
            props.fnReloadActivity();
        }
    };

    const onUpdateNotification = (updatedNotification: any) => {
        if (props && props.fnUpdateNotification) {
            props.fnUpdateNotification(updatedNotification);
        }
    };

    let curUdicciListState = (udicciListContext && udicciListContext.state ? udicciListContext.state : null);
    // console.log('%c UdicciRecordDisplay curUdicciListState: %O', 'color: maroon;', curUdicciListState);

    let lsSettings: any = (curUdicciListState && curUdicciListState.settings ? curUdicciListState.settings : null);
    // console.log('%c UdicciRecordDisplay lsSettings: %O', 'color: maroon;', lsSettings);

    let breadcrumbs: any = (curUdicciListState && curUdicciListState.breadcrumbs ? curUdicciListState.breadcrumbs : null);
    let lsSolution: any = (curUdicciListState && curUdicciListState.socialSolution ? curUdicciListState.socialSolution : null);
    let socialSolutionId = (lsSolution && lsSolution.id ? lsSolution.id : 0);

    const getLastBreadcrumb = () => {
        let lbc: any = null;
        // console.log('%c UdicciRecordDisplay breadcrumbs: %O', 'color: blue;', breadcrumbs);
        if (breadcrumbs && breadcrumbs.length > 0) lbc = last(breadcrumbs);
        return lbc;
    };

    let lastBreadcrumb: any = getLastBreadcrumb();
    // console.log('%c UdicciRecordDisplay lastBreadcrumb: %O', 'color: blue;', lastBreadcrumb);
    let initRecord: any = (lastBreadcrumb && lastBreadcrumb.record ? lastBreadcrumb.record : propRecord);
    // console.log('%c UdicciRecordDisplay initRecord: %O', 'color: blue;', initRecord);
    let {
        engageAction, // setRecord, saveRecord, deleteRecord,
        getListElement, getFormElement, getEngagementActionElement,
        udicciRecord, engagementAction, socialSolution, structure
    } = useUdicciRecord(initRecord, propEngagedAction, propNotification, propActivitySummary, onReloadActivity, onUpdateNotification);
    // console.log('%c UdicciRecordDisplay engagementAction: %O', 'color: blue;', engagementAction);
    // console.log('%c UdicciRecordDisplay udicciRecord: %O', 'color: blue;', udicciRecord);
    // console.log('%c UdicciRecordDisplay socialSolution: %O', 'color: blue;', socialSolution);
    // console.log('%c UdicciRecordDisplay structure: %O', 'color: blue;', structure);

    const { more, less, canBeExpanded, isExpanded, displayValue } = useLongText((udicciRecord && udicciRecord.description ? udicciRecord.description.toString() : ''), true, 50);
    // console.log('%c UdicciRecordDisplay displayValue.length: %O', 'color: blue;', displayValue.length);

    let { data } = udicciContext.state;
    // let { selectedProfile, currentUser } = udicci;
    // console.log('%c SocialSolutions selectedProfile: %O', 'color: maroon;', selectedProfile);
    // console.log('%c SocialSolutions currentUser: %O', 'color: maroon;', currentUser);

    let editUdicciRecord: any = (curUdicciListState && curUdicciListState.onEditRecord ? curUdicciListState.onEditRecord : null);
    let deleteUdicciRecord: any = (curUdicciListState && curUdicciListState.onDeleteRecord ? curUdicciListState.onDeleteRecord : null);
    let lsListFields: any = (lsSettings && lsSettings.listFields ? lsSettings.listFields : null);
    // console.log('%c UdicciRecordDisplay lsListFields: %O', 'color: blue;', lsListFields);

    let udicciMediator = (udicciRecord && udicciRecord.udicciMediator ? udicciRecord.udicciMediator : '');
    // console.log('%c UdicciRecordDisplay udicciMediator: %O', 'color: maroon;', udicciMediator);

    // console.log('%c UdicciRecordDisplay lastBreadcrumb: %O', 'color: maroon;', lastBreadcrumb);
    let bcEngagementAction: any = (lastBreadcrumb && lastBreadcrumb.engagementAction ? lastBreadcrumb.engagementAction : null);
    // console.log('%c UdicciRecordDisplay bcEngagementAction: %O', 'color: hotpink;', bcEngagementAction);

    let listType: any = getProfileSetting(udicciRecord.udicciMediator, socialSolutionId, 'listType');
    // console.log('%c UdicciRecordDisplay listType: %O', 'color: purple;', listType);
    let pagingStyle: any = getProfileSetting(udicciRecord.udicciMediator, socialSolutionId, 'pagingStyle');
    // console.log('%c UdicciRecordDisplay pagingStyle: %O', 'color: purple;', pagingStyle);

    let recId = (udicciRecord && udicciRecord.recordId ? udicciRecord.recordId : 0);
    // let keys = (udicciRecord && udicciRecord.keys ? udicciRecord.keys : null);
    // console.log('%c UdicciRecordDisplay keys: %O', 'color: green;', keys);
    let permissions = (udicciRecord && udicciRecord.permissions ? udicciRecord.permissions : null);
    // console.log('%c UdicciRecordDisplay permissions: %O', 'color: green;', permissions);
    let ssMore = (lsSolution && lsSolution.more ? lsSolution.more : null);
    // console.log('%c UdicciRecordDisplay ssMore: %O', 'color: green;', ssMore);
    let engagementActions = (ssMore && ssMore.engagementActions ? ssMore.engagementActions : null);
    // console.log('%c UdicciRecordDisplay engagementActions: %O', 'color: green;', engagementActions);

    let mediators: any = null;
    if (socialSolution && socialSolution.socialSolution && socialSolution.socialSolution.data && socialSolution.socialSolution.data.jsonSettingsJson) {
        let ssSettings: any = socialSolution.socialSolution.data.jsonSettingsJson;
        // console.log('%c ssSettings: %O', 'color: maroon;', ssSettings);
        if (ssSettings.mediators) {
            mediators = ssSettings.mediators;
        }
        if (ssSettings.engagementActions) {
            engagementActions = ssSettings.engagementActions;
        }
    }
    // console.log('%c UdicciRecordDisplay mediators: %O', 'color: maroon;', mediators);        
    // console.log('%c UdicciRecordDisplay engagementActions: %O', 'color: maroon;', engagementActions);        

    let isNewRecord: boolean = (recId === 0 ? true : false);
    let recData = (udicciRecord && udicciRecord.data ? udicciRecord.data : null);

    if (!udicciRecord) return null;

    let propData: any = (props && props.data ? props.data : null);
    // console.log('%c UdicciRecordDisplay propData: %O', 'color: maroon;', propData);
    let propListSettings: any = (propData && propData.listSettings ? propData.listSettings : null);
    if (!propListSettings && props && props.listSettings) {
        propListSettings = props && props.listSettings;
    }
    // console.log('%c UdicciRecordDisplay propListSettings: %O', 'color: maroon;', propListSettings);
    let propSettings: any = (props && props.settings ? props.settings : null);
    // console.log('%c UdicciRecordDisplay propSettings: %O', 'color: maroon;', propSettings);
    let settingsLayout: any = (propSettings && propSettings.layout ? propSettings.layout : null);
    // console.log('%c UdicciRecordDisplay settingsLayout: %O', 'color: maroon;', settingsLayout);
    
    let layoutFooter: any = (settingsLayout && settingsLayout.footer ? settingsLayout.footer : null);
    // console.log('%c UdicciRecordDisplay layoutFooter: %O', 'color: maroon;', layoutFooter);
    let layoutPrimary: any = (settingsLayout && settingsLayout.primary ? settingsLayout.primary : null);
    // console.log('%c UdicciRecordDisplay layoutPrimary: %O', 'color: maroon;', layoutPrimary);
    let layoutSecondary: any = (settingsLayout && settingsLayout.secondary ? settingsLayout.secondary : null);
    // console.log('%c UdicciRecordDisplay layoutSecondary: %O', 'color: maroon;', layoutSecondary);
    let layoutTitle: any = (settingsLayout && settingsLayout.title ? settingsLayout.title : null);
    // console.log('%c UdicciRecordDisplay layoutTitle: %O', 'color: maroon;', layoutTitle);

    let footerLayoutFields: any = (layoutFooter && layoutFooter.layoutFields ? layoutFooter.layoutFields : null);
    // console.log('%c UdicciRecordDisplay footerLayoutFields: %O', 'color: maroon;', footerLayoutFields);
    let primaryLayoutFields: any = (layoutPrimary && layoutPrimary.layoutFields ? layoutPrimary.layoutFields : null);
    // console.log('%c UdicciRecordDisplay primaryLayoutFields: %O', 'color: maroon;', primaryLayoutFields);
    let secondaryLayoutFields: any = (layoutSecondary && layoutSecondary.layoutFields ? layoutSecondary.layoutFields : null);
    // console.log('%c UdicciRecordDisplay secondaryLayoutFields: %O', 'color: maroon;', secondaryLayoutFields);
    let titleLayoutFields: any = (layoutTitle && layoutTitle.layoutFields ? layoutTitle.layoutFields : null);
    // console.log('%c UdicciRecordDisplay titleLayoutFields: %O', 'color: maroon;', titleLayoutFields);

    let pluginActions: any = null;
    let pluginLayout: any = null;
    if (propListSettings) {
        if (propListSettings.actions) pluginActions = propListSettings.actions;
        if (propListSettings.layout) pluginLayout = propListSettings.layout;
    }
    // console.log('%c UdicciRecordDisplay pluginActions: %O', 'color: purple;', pluginActions);
    // console.log('%c UdicciRecordDisplay pluginLayout: %O', 'color: purple;', pluginLayout);

    let layoutEnabledPerspectives: boolean = (pluginLayout && pluginLayout.showPerspectives === true ? true : false);
    let layoutEnabledAttachments: boolean = (pluginLayout && pluginLayout.showAttachments === true ? true : false);
    let layoutEnabledActivity: boolean = (pluginLayout && pluginLayout.showActivity === true ? true : false);
    // console.log('%c UdicciRecordDisplay layoutEnabledPerspectives: %O', 'color: purple;', layoutEnabledPerspectives);
    // console.log('%c UdicciRecordDisplay layoutEnabledAttachments: %O', 'color: purple;', layoutEnabledAttachments);
    // console.log('%c UdicciRecordDisplay layoutEnabledActivity: %O', 'color: purple;', layoutEnabledActivity);

    let layoutConfig: any = (pluginLayout && pluginLayout.config ? pluginLayout.config : null);
    // console.log('%c UdicciRecordDisplay layoutConfig: %O', 'color: purple;', layoutConfig);
    let tableConfig: any = (layoutConfig && layoutConfig.table ? layoutConfig.table : null);
    // console.log('%c tableConfig: %O', 'color: blue;', tableConfig);
    // let gridConfig: any = (layoutConfig && layoutConfig.grid ? layoutConfig.grid : null);
    // console.log('%c gridConfig: %O', 'color: blue;', gridConfig);
    // let listConfig: any = (layoutConfig && layoutConfig.list ? layoutConfig.list : null);
    // console.log('%c listConfig: %O', 'color: blue;', listConfig);

    let layoutConfigSettings: any = null;
    if (layoutConfig && pluginLayout) {
        if (pluginLayout.type === 'grid') {
            listType = 'Card Grid';
            if (layoutConfig.grid) layoutConfigSettings = layoutConfig.grid;
        }
        if (pluginLayout.type === 'list') {
            listType = 'List';
            if (layoutConfig.list) layoutConfigSettings = layoutConfig.list;
        }
        if (pluginLayout.type === 'table') {
            listType = 'Table';
            if (layoutConfig.table) layoutConfigSettings = layoutConfig.table;
        }
        // console.log('%c UdicciRecordDisplay listType: %O', 'color: red;', listType);
        // console.log('%c UdicciRecordDisplay layoutConfigSettings: %O', 'color: red;', layoutConfigSettings);
    }
    // console.log('%c layoutConfigSettings: %O', 'color: blue;', layoutConfigSettings);

    const toggleAdditionalEngagements = (options: any) => {
        // console.log('%c toggleAdditionalEngagements options: %O', 'color: red;', options);
        // console.log('%c toggleAdditionalEngagements showPerspectives: %O', 'color: red;', showPerspectives);
        // console.log('%c toggleAdditionalEngagements showEngagementMenu: %O', 'color: red;', showEngagementMenu);
        // console.log('%c toggleAdditionalEngagements udicciRecord: %O', 'color: red;', udicciRecord);

        let newMenuState: boolean = (options && options.action === 'show engagement menu' ? true : false);
        // console.log('%c toggleAdditionalEngagements newMenuState: %O', 'color: red;', newMenuState);
        setShowEngagementMenu(newMenuState);
    };

    const showRecordForm = (evt: any) => {
        // console.log('%c showRecordForm evt: %O', 'color: red;', evt);
        // console.log('%c showRecordForm udicciRecord: %O', 'color: red;', udicciRecord);

        // console.log('%c showRecordForm editUdicciRecord: %O', 'color: red;', editUdicciRecord);
        if (editUdicciRecord) {
            editUdicciRecord(udicciRecord);
        } else {
            // setRecordFocus(udicciRecord);
            setFormState(true);
        }
    };

    const hideRecordForm = (evt: any) => {
        if (props && props.onCloseForm) {
            props.onCloseForm();
        } else {
            setFormState(false);
        }
    };

    const deleteRecordClick = (evt: any) => {
        // console.log('%c deleteRecordClick evt: %O', 'color: red;', evt);
        // console.log('%c deleteRecordClick udicciRecord: %O', 'color: red;', udicciRecord);
        // console.log('%c deleteRecordClick deleteUdicciRecord: %O', 'color: red;', deleteUdicciRecord);

        if (deleteUdicciRecord) {
            deleteUdicciRecord(udicciRecord);
        } else {
            setFormState(true);
        }
    };

    const triggerEngagementAction = (ea: any, evt: any) => {
        // console.log('%c triggerEngagementAction evt: %O', 'color: red;', evt);
        // console.log('%c triggerEngagementAction udicciRecord: %O', 'color: red;', udicciRecord);
        // console.log('%c triggerEngagementAction ea: %O', 'color: red;', ea);

        // console.log('%c triggerEngagementAction engageAction: %O', 'color: red;', engageAction);
        if (engageAction) {
            engageAction(ea);
        } else {
            setFormState(false);
            setEngagementAction(ea);
        }
    };

    const hideEngagementActionForm = (evt: any) => {
        if (engagementAction) {
            engageAction(null);
        } else if (props && props.onCloseForm) {
            props.onCloseForm();
        } else {
            setEngagementAction(null);
            setFormState(false);
        }
    };

    const toggleAttachments = () => {
        setShowPerspectives(false);
        setShowAttachments(!showAttachments);
    };

    const confirmAttachments = (attachmentFiles: AttachmentFile[]) => {
        // console.log('%c confirmAttachments attachmentFiles: %O', 'color: green;', attachmentFiles);
        // console.log('%c confirmAttachments mediaBit: %O', 'color: green;', mediaBit);
        // if (mediaBit) mediaBit.Attachments = attachmentFiles;
        // setMediaBit(mediaBit);
        setShowAttachments(!showAttachments);
    };

    const togglePerspectives = () => {
        // setShowAttachments(false);
        setShowPerspectives(!showPerspectives);
    };

    const sharePerspective = (perspective: any) => {
        // console.log('%c sharePerspective perspective: %O', 'color: maroon;', perspective);
        // setShowAttachments(false);
        // setShowPerspectives(false);
    };

    let titleElement: any = null;
    let descriptionElement = null;
    let mainDescriptionElement = null;

    let hasLayout: boolean = false;
    let fieldColumnElements: any[] = [];
    if (layoutConfigSettings) {
        // console.log('%c UdicciRecordDisplay layoutConfigSettings: %O', 'color: blue;', layoutConfigSettings);
        let settings: any = (layoutConfigSettings.settings ? layoutConfigSettings.settings : null);
        // console.log('%c UdicciRecordDisplay settings: %O', 'color: maroon;', settings);
        // let justifyContent: any = (settings && settings.justifyContent ? settings.justifyContent : null);
        // console.log('%c UdicciRecordDisplay justifyContent: %O', 'color: maroon;', justifyContent);
        // let spacing: any = (settings && settings.spacing ? settings.spacing : null);
        // console.log('%c UdicciRecordDisplay spacing: %O', 'color: maroon;', spacing);
        // let cardSettings: any = (settings && settings.card ? settings.card : null);
        // console.log('%c UdicciRecordDisplay cardSettings: %O', 'color: maroon;', cardSettings);
    } else if (titleLayoutFields || primaryLayoutFields || secondaryLayoutFields || footerLayoutFields) {
        hasLayout = true;
        let headerElements: any[] = [];
        let contentElements: any[] = [];
        let fieldCount: number = 0;
        for (let [idxFld, fld] of Object.entries<any>(titleLayoutFields)) {
            // console.log('%c idxFld: %O', 'color: green;', idxFld);
            // console.log('%c fld: %O', 'color: green;', fld);

            fieldCount++;
            let jsonKey: string = (fld.JsonFieldName ? fld.JsonFieldName : '');
            let displayElementProps: any = null;
            let fldDisplayElement: any = null;

            displayElementProps = {
                key: 'udicciRecord.display.header.element.' + jsonKey + '.' + fieldCount.toString(),
                component: (fld.component ? fld.component : 'div'),
                variant: (fld.variant ? fld.variant : 'subtitle2'),
                color: (fld.color ? fld.color : 'textPrimary'),
                gutterBottom: false,
                sx: udicciClasses.contentElement
            };
            // console.log('%c displayElementProps: %O', 'color: green;', displayElementProps);
            fldDisplayElement = (
                <Typography {...displayElementProps}>
                    {recData && recData[jsonKey]}
                </Typography>
            );

            if (listType === 'Table') {
                fieldColumnElements.push({
                    field: fld,
                    element: fldDisplayElement,
                    displayOrder: (fld.order ? fld.order : parseInt(idxFld, 10) + 1)
                });
            } else {
                headerElements.push({
                    field: fld,
                    element: fldDisplayElement,
                    displayOrder: (fld.order ? fld.order : parseInt(idxFld, 10) + 1)
                });
            }
        }

        let sortedHeaderElements = headerElements.sort((a: any,b: any) => {
            if (a.displayOrder < b.displayOrder) return -1;  // ascending
            if (a.displayOrder > b.displayOrder) return 1;  // descending
            return 0 //default return value (no sorting)
        });

        if (sortedHeaderElements.length > 0) {
            titleElement = (
                <Fragment>
                    {
                        sortedHeaderElements.map(function(hdrElement: any) {
                            // console.log('%c hdrElement: %O', 'color: hotpink;', hdrElement);
                            return hdrElement.element;
                        })
                    }
                </Fragment>
            );
        }

        let sortedContentElements = contentElements.sort((a: any,b: any) => {
            if (a.displayOrder < b.displayOrder) return -1;  // ascending
            if (a.displayOrder > b.displayOrder) return 1;  // descending
            return 0 //default return value (no sorting)
        });

        if (sortedContentElements.length > 0) {
            mainDescriptionElement = (
                <Fragment>
                    {
                        sortedContentElements.map(function(cntntElement: any) {
                            // console.log('%c cntntElement: %O', 'color: hotpink;', cntntElement);
                            return cntntElement.element;
                        })
                    }
                </Fragment>
            );
        }
    } else if (lsListFields) {
        let headerElements: any[] = [];
        let contentElements: any[] = [];
        let fieldCount: number = 0;
        for (let [jsonKey, fld] of Object.entries<any>(lsListFields)) {
            // console.log('%c jsonKey: %O', 'color: green;', jsonKey);
            // console.log('%c fld: %O', 'color: green;', fld);

            fieldCount++;
            let displayElementProps: any = null;
            let fldDisplayElement: any = null;
            if (fld.header === true) {
                displayElementProps = {
                    key: 'udicciRecord.display.header.element.' + jsonKey + '.' + fieldCount.toString(),
                    component: (fld.component ? fld.component : null),
                    variant: (fld.variant ? fld.variant : 'subtitle2'),
                    color: (fld.color ? fld.color : 'textPrimary'),
                    gutterBottom: false,
                    sx: udicciClasses.contentElement
                };
                // console.log('%c displayElementProps: %O', 'color: green;', displayElementProps);
                fldDisplayElement = (
                    <Typography {...displayElementProps}>
                        {recData && recData[jsonKey]}
                    </Typography>
                );

                if (listType === 'Table') {
                    fieldColumnElements.push({
                        field: fld,
                        element: fldDisplayElement,
                        displayOrder: fld.order
                    });
                } else {
                    headerElements.push({
                        field: fld,
                        element: fldDisplayElement,
                        displayOrder: fld.order
                    });
                }
            } else {
                displayElementProps = {
                    key: 'udicciRecord.display.content.element.' + jsonKey + '.' + fieldCount.toString(),
                    component: (fld.component ? fld.component : null),
                    variant: (fld.variant ? fld.variant : 'body2'),
                    color: (fld.color ? fld.color : 'textPrimary'),
                    gutterBottom: false
                };
                // console.log('%c displayElementProps: %O', 'color: green;', displayElementProps);
                fldDisplayElement = (
                    <Typography {...displayElementProps}>
                        {recData && recData[jsonKey]}
                    </Typography>
                );
                if (listType === 'Table') {
                    fieldColumnElements.push({
                        field: fld,
                        element: fldDisplayElement,
                        displayOrder: fld.order
                    });
                } else {
                    contentElements.push({
                        field: fld,
                        element: fldDisplayElement,
                        displayOrder: fld.order
                    });
                }
            }
        }

        let sortedHeaderElements = headerElements.sort((a: any,b: any) => {
            if (a.displayOrder < b.displayOrder) return -1;  // ascending
            if (a.displayOrder > b.displayOrder) return 1;  // descending
            return 0 //default return value (no sorting)
        });

        if (sortedHeaderElements.length > 0) {
            titleElement = (
                <Fragment>
                    {
                        sortedHeaderElements.map(function(hdrElement: any) {
                            // console.log('%c hdrElement: %O', 'color: hotpink;', hdrElement);
                            return hdrElement.element;
                        })
                    }
                </Fragment>
            );
        }

        let sortedContentElements = contentElements.sort((a: any,b: any) => {
            if (a.displayOrder < b.displayOrder) return -1;  // ascending
            if (a.displayOrder > b.displayOrder) return 1;  // descending
            return 0 //default return value (no sorting)
        });

        if (sortedContentElements.length > 0) {
            mainDescriptionElement = (
                <Fragment>
                    {
                        sortedContentElements.map(function(cntntElement: any) {
                            // console.log('%c cntntElement: %O', 'color: hotpink;', cntntElement);
                            return cntntElement.element;
                        })
                    }
                </Fragment>
            );
        }
    }
    // console.log('%c UdicciRecordDisplay hasLayout: %O', 'color: purple;', hasLayout);

    if (!layoutConfigSettings) {
        if (titleElement === null) {
            titleElement = (
                <Typography variant="caption" gutterBottom={false} classes={{ root: 'itemTitleContainer' }}>
                    {udicciRecord && udicciRecord.title}
                </Typography>
            );
            if (listType === 'Table') {
                let titleField: any = {};
                fieldColumnElements.push({
                    field: titleField,
                    element: titleElement,
                    displayOrder: 1
                });
            }
            // console.log('%c UdicciRecordDisplay fieldColumnElements: %O', 'color: purple;', fieldColumnElements);
        }

        if (mainDescriptionElement === null) {
            let toggleLongTextViewElement: any = null;
            if (canBeExpanded()) {
                if (isExpanded()) {
                    toggleLongTextViewElement = (
                        <IconButton size="small" aria-label="Show Less" onClick={(evt: any) => more()} classes={{ root: 'longTextToggleIconButton' }}>
                            <UnfoldLessIcon />
                        </IconButton>
                    );
                } else {
                    toggleLongTextViewElement = (
                        <IconButton size="small" aria-label="Show More" onClick={(evt: any) => less()} classes={{ root: 'longTextToggleIconButton' }}>
                            <UnfoldMoreIcon />
                        </IconButton>
                    );
                }
            }
        
            if (displayValue.toString().indexOf('<') >= 0 && displayValue.toString().indexOf('</') > 0) {
                mainDescriptionElement = (
                    <Fragment>
                        {toggleLongTextViewElement}
                        <Typography variant="body2" component="div" gutterBottom={false}>
                            <div dangerouslySetInnerHTML={createMarkup(displayValue.toString())}></div>
                        </Typography>
                    </Fragment>
                );
            } else {
                mainDescriptionElement = (
                    <Fragment>
                        {toggleLongTextViewElement}
                        <Typography variant="body2" component="div" gutterBottom={false}>
                            {displayValue.toString()}
                        </Typography>
                    </Fragment>
                );
            }
            if (listType === 'Table') {
                let descriptionField: any = {};
                fieldColumnElements.push({
                    field: descriptionField,
                    element: mainDescriptionElement,
                    displayOrder: 2
                });
            }
            // console.log('%c UdicciRecordDisplay fieldColumnElements: %O', 'color: purple;', fieldColumnElements);
        }
    }

    let recordFormElement = null;
    let engagementActionElement = null;
    let listItemActions: any = null;
    let recordContextDisplayElement: any = null;
    // console.log('%c UdicciRecordDisplay showForm: %O', 'color: purple;', showForm);
    // console.log('%c UdicciRecordDisplay udicciRecord: %O', 'color: purple;', udicciRecord);
    // console.log('%c UdicciRecordDisplay engagementAction: %O', 'color: purple;', engagementAction);

    let attachmentsElement = null;
    let perspectivesElement = null;
    let childrenElement = null;

    let attachmentsIconButton: any = null;
    let perspectivesIconButton: any = null;

    if (!isNewRecord) {
        if (showAttachments) {
            attachmentsIconButton = (
                <IconButton size="small" color="primary" onClick={toggleAttachments}>
                    <AttachFile classes={{ root: 'recordActionIconButton' }} />
                </IconButton>
            );
        } else {
            attachmentsIconButton = (
                <IconButton size="small" color="info" onClick={toggleAttachments}>
                    <AttachFile classes={{ root: 'recordActionIconButton' }} />
                </IconButton>
            );
        }

        if (showPerspectives) {
            perspectivesIconButton = (
                <IconButton size="small" color="secondary" onClick={togglePerspectives}>
                    <PerspectivesIcon classes={{ root: 'recordActionIconButton' }} />
                </IconButton>
            );
        } else {
            perspectivesIconButton = (
                <IconButton size="small" color="info" onClick={togglePerspectives}>
                    <PerspectivesIcon classes={{ root: 'recordActionIconButton' }} />
                </IconButton>
            );
        }
    }

    let showingAttachments: boolean = false;
    let attachments: any[] = (udicciRecord && udicciRecord.attachments ? udicciRecord.attachments : []);
    // console.log('%c UdicciRecordDisplay attachments: %O', 'color: blue;', attachments);

    let showingPerspectives: boolean = false;
    // console.log('%c UdicciRecordDisplay udicciRecord: %O', 'color: blue;', udicciRecord);
    let perspectives: any[] = (udicciRecord && udicciRecord.perspectives ? udicciRecord.perspectives : []);
    // console.log('%c UdicciRecordDisplay perspectives: %O', 'color: blue;', perspectives);

    if (!isNewRecord) {
        if (showEngagementMenu || engagementAction) {
            if (layoutEnabledAttachments) {
                if (showAttachments) {
                    attachmentsElement = (
                        <AttachmentsContainer>
                            {attachmentsIconButton}
                            <Typography component="span" variant="clickableSubTitle2" onClick={toggleAttachments}>Attachments</Typography>
                            <Attachments onAttach={confirmAttachments} onCancel={toggleAttachments} attachments={attachments} record={udicciRecord} />
                        </AttachmentsContainer>
                    );
                    showingAttachments = true;
                } else {
                    attachmentsElement = (
                        <AttachmentsContainer>
                            {attachmentsIconButton}
                            <Typography component="span" variant="clickableSubTitle2" onClick={toggleAttachments}>Attachments</Typography>
                        </AttachmentsContainer>
                    );
                }
            }
            if (layoutEnabledPerspectives) {
                if (showPerspectives) {
                    perspectivesElement = (
                        <PerspectivesContainer>
                            {perspectivesIconButton}
                            <Typography component="span" variant="clickableSubTitle2" onClick={togglePerspectives}>Perspectives</Typography>
                            <Perspectives onShare={sharePerspective}
                                        onCloseAll={togglePerspectives}
                                        perspectives={perspectives}
                                        record={udicciRecord}
                            />
                        </PerspectivesContainer>
                    );
                    showingPerspectives = true;
                } else {
                    perspectivesElement = (
                        <PerspectivesContainer>
                            {perspectivesIconButton}
                            <Typography component="span" variant="clickableSubTitle2" onClick={togglePerspectives}>Perspectives</Typography>
                        </PerspectivesContainer>
                    );
                }
            }
        }

        childrenElement = (
            <Box>This will be the children options section of the form.</Box>
        )
    }

    let engagementActionElementProps: any = {
        onClose: hideEngagementActionForm
    };
    if (!showForm && engagementAction) {
        // console.log('%c UdicciRecordDisplay engagementAction: %O', 'color: purple;', engagementAction);

        // console.log('%c listType: %O', 'color: purple;', listType);
        // if (listType.toLowerCase() === 'table') 
        engagementActionElementProps.listSettings = propListSettings;
        engagementActionElementProps.inline = false;
        engagementActionElement = getEngagementActionElement(engagementActionElementProps);

        if (listType.toLowerCase() === 'card grid') {
            listItemActions = (
                <Box>
                    {engagementActionElement}
                </Box>
            );
        } else if (listType.toLowerCase() === 'table') {
            listItemActions = (
                <TableCell align="right">
                    {engagementActionElement}
                </TableCell>
            );
        } else {
            //  sx={udicciClasses.menuContainer}
            listItemActions = (
                <Box sx={udicciClasses.itemButtonsContainer}>
                    {engagementActionElement}
                </Box>
            );
        }
    } else if (showForm) {
        // console.log('%c UdicciRecordDisplay engagementAction: %O', 'color: purple;', engagementAction);
        if (engagementAction) {
            engagementActionElement = getEngagementActionElement(engagementActionElementProps);
        } else if (bcEngagementAction) {
            let engagementProps: keyable = {};
            Object.assign(engagementProps, props);
            engagementProps.engagementAction = bcEngagementAction;
            engagementProps.inline = false;
            engagementProps.record = udicciRecord;
            engagementProps.socialSolution = socialSolution;
            // engagementProps.onChange = changeEngagementRecordValue;
            engagementProps.onClose = hideEngagementActionForm;
            // console.log('%c UdicciRecordDisplay engagementProps: %O', 'color: maroon;', engagementProps);
            engagementActionElement = ( <RecordEngagement {...engagementProps} /> );
        } else {
            // console.log('%c UdicciRecordDisplay propSource: %O', 'color: blue;', propSource);
            if (propSource !== 'udicci-list') {
                let recordContextElement: any = null;
                if (recId > 0) recordContextElement = (<RecordContextDisplay showForm={showForm} />);

                let formProps: keyable = {};
                Object.assign(formProps, props);
                formProps.record = udicciRecord;
                formProps.inline = false;
                formProps.onClose = hideRecordForm;
                // console.log('%c UdicciRecordDisplay formProps: %O', 'color: maroon;', formProps);
                recordFormElement = (
                    <Paper>
                        {recordContextElement}
                        {/* <UdicciForm {...formProps} /> */}
                    </Paper>
                );
            }
        }
    } else if (pagingStyle === 'Individual') {
        recordContextDisplayElement = (<RecordContextDisplay showForm={showForm} />);
    } else {
        // console.log('%c udicciRecord: %O', 'color: purple;', udicciRecord);
        if (selectedEngagementAction !== null) {
            let engagementProps: keyable = {};
            Object.assign(engagementProps, props);
            engagementProps.engagementAction = selectedEngagementAction;
            engagementProps.inline = false;
            engagementProps.record = udicciRecord;
            // engagementProps.onChange = changeEngagementRecordValue;
            engagementProps.onClose = hideEngagementActionForm;
            // console.log('%c UdicciRecordDisplay engagementProps: %O', 'color: maroon;', engagementProps);
            engagementActionElement = ( <RecordEngagement {...engagementProps} /> );
        }

        if (!hasLayout && udicciRecord && udicciRecord.permissions) {
            let viewRecordButton: any = null;
            let editRecordButton: any = null;
            let deleteRecordButton: any = null;
            // console.log('%c engagementActions: %O', 'color: purple;', engagementActions);

            let engagementActionListItems: any[] = [];
            if (engagementActions && engagementActions.length > 0) {
                // console.log('%c udicciRecord: %O', 'color: red;', udicciRecord);
                for (let [idx, ea] of Object.entries<any>(engagementActions)) {
                    // console.log('%c ea: %O', 'color: blue;', ea);
                    // let eaname: string = (ea.action ? ea.action : ea.name);
                    // console.log('%c ea %s: %O', 'color: orange; font-weight: bold;', eaname, ea);

                    let eaAccess = (ea.access ? ea.access : null);
                    // console.log('%c eaAccess: %O', 'color: blue;', eaAccess);
                    if (pluginActions && pluginActions.length > 0) {
                        let checkAction = find(pluginActions, function(a) {
                            return a.action === ea.action;
                        });
                        // console.log('%c checkAction: %O', 'color: orange; font-weight: bold;', checkAction);
                        if (!checkAction) continue;
                    }
                    let eaMediators = (eaAccess && eaAccess.mediators ? eaAccess.mediators : []);
                    // console.log('%c eaMediators: %O', 'color: blue;', eaMediators);
                    let enableWhen = (ea && ea.enableWhen ? ea.enableWhen : null);
                    // console.log('%c enableWhen: %O', 'color: blue;', enableWhen);
                    let showWhen = (ea && ea.showWhen ? ea.showWhen : null);
                    // console.log('%c showWhen: %O', 'color: blue;', showWhen);
                    let showForAllMediators = (eaAccess && eaAccess.showForAllMediators ? true : false);
                    // console.log('%c showForAllMediators: %O', 'color: blue;', showForAllMediators);
                    let accessRequiredPermissions = (eaAccess && eaAccess.requiredPermissions ? eaAccess.requiredPermissions : null);
                    // console.log('%c accessRequiredPermissions: %O', 'color: blue;', accessRequiredPermissions);

                    let mediatorActions = find(values(eaMediators), function(m) {
                        return m.name === udicciMediator;
                    });
                    // console.log('%c mediatorActions: %O', 'color: blue;', mediatorActions);
                    let requiredPermissions = (mediatorActions && mediatorActions.requiredPermissions ? mediatorActions.requiredPermissions : accessRequiredPermissions);
                    // console.log('%c requiredPermissions: %O', 'color: blue;', requiredPermissions);

                    let allowed = (showForAllMediators || mediatorActions !== undefined ? true : false);
                    if (permissions && requiredPermissions && requiredPermissions.length > 0) {
                        allowed = false;
                        let fp = requiredPermissions[0];
                        // console.log('%c fp: %O', 'color: blue;', fp);
                        let comparePerms: any = permissions
                        let permValue = comparePerms[fp.permission];
                        // console.log('%c permValue: %O', 'color: blue;', permValue);
                        if (fp.required && permValue === true) allowed = true;
                        if (!fp.required) allowed = true;
                    }

                    let showAction = false;
                    let enableAction = false;
                    // let mediatorData: any = null;
                    if (allowed && udicciRecord) {
                        let mediatorContext = data.find((x: any) => x.mediator === udicciRecord.udicciMediator );
                        // console.log('%c SocialSolutions mediatorContext: %O', 'color: maroon;', mediatorContext);
                        showAction = checkConditionAgainstRecord(udicciRecord, showWhen, mediatorContext);
                        // console.log('%c showAction: %O', 'color: red;', showAction);
                        enableAction = checkConditionAgainstRecord(udicciRecord, enableWhen, mediatorContext);
                        // console.log('%c enableAction: %O', 'color: red;', enableAction);
                        // mediatorData = udicciHelpers.preloadMediatorData(udicciRecord.udicciMediator, socialSolutionId);
                    }
                    // console.log('%c mediatorData: %O', 'color: red;', mediatorData);

                    // console.log('%c allowed: %O', 'color: red;', allowed);
                    // console.log('%c showAction: %O', 'color: red;', showAction);
                    if (allowed && showAction) {
                        // console.log('%c enableAction: %O', 'color: red;', enableAction);
                        // console.log('%c ea: %O', 'color: blue;', ea);

                        let engagementActionPackages: any = getEngagementActionPackages(props);
                        // console.log('%c engagementActionPackages: %O', 'color: maroon;', engagementActionPackages);

                        let action: any = null;
                        if (ea && ea.action) {
                            if (engagementActionPackages && engagementActionPackages[ea.action]) {
                                action = engagementActionPackages[ea.action];
                            }
                        }
                        // console.log('%c action: %O', 'color: purple;', action);

                        if (action && action.menu) {
                            let eaListItemKey = 'udicciRecord.' + recId.toString() + '.engagement.action.' + ea.uid + '.' + idx.toString();
                            // console.log('%c eaListItemKey: %O', 'color: purple;', eaListItemKey);
                            let engagementMenuItemElement: any = (
                                <EngagementMenuItem key={eaListItemKey}
                                                    record={udicciRecord} 
                                                    engagementAction={ea} 
                                                    udicciMediator={udicciMediator} 
                                                    permissions={permissions}
                                                    enableAction={enableAction}
                                                    onClick={triggerEngagementAction}
                                />
                            );
                            // console.log('%c engagementMenuItemElement: %O', 'color: blue;', engagementMenuItemElement);
                            if (engagementMenuItemElement) engagementActionListItems.push(engagementMenuItemElement);
                        }
                    }
                }
            }

            if (engagementActionListItems.length <= 0) {
                // console.log('%c udicciRecord.permissions: %O', 'color: maroon;', udicciRecord.permissions);
                // console.log('%c udicciRecord.permissions.CanView: %O', 'color: maroon;', udicciRecord.permissions.CanView);
                // console.log('%c udicciRecord.permissions.CanEdit: %O', 'color: maroon;', udicciRecord.permissions.CanEdit);
                if (udicciRecord.permissions.CanView && !udicciRecord.permissions.CanEdit) {
                    // sx={udicciClasses.recordActionButton}
                    viewRecordButton = (
                        <Button size="small"
                                onClick={showRecordForm}
                                variant="text"
                        >
                            <Typography variant="body2" component="span">
                                view
                            </Typography>
                            <Typography variant="body2" component="span">
                                .
                            </Typography>
                        </Button>
                    );
                } else if (udicciRecord.permissions.CanEdit) {
                    // console.log('%c udicciRecord.permissions.CanEdit: %O', 'color: green;', udicciRecord.permissions.CanEdit);
                    editRecordButton = (
                        <Button size="small"
                                onClick={showRecordForm}
                                variant="text"
                        >
                            <Typography variant="body2" component="span">
                                edit
                            </Typography>
                            <Typography variant="body2" component="span">
                                .
                            </Typography>
                        </Button>
                    );
                }

                if (udicciRecord.permissions.CanDelete) {
                    // console.log('%c udicciRecord.permissions.CanDelete: %O', 'color: green;', udicciRecord.permissions.CanDelete);
                    deleteRecordButton = (
                        <Button size="small"
                                onClick={deleteRecordClick}
                                variant="text"
                        >
                            <Typography variant="body2" component="span">
                                delete
                            </Typography>
                            <Typography variant="body2" component="span">
                                .
                            </Typography>
                        </Button>
                    );
                }
            }

            // console.log('%c openRecordMenu: %O', 'color: purple;', openRecordMenu);
            // console.log('%c engagementActionListItems: %O', 'color: purple;', engagementActionListItems);

            let listItemDropDownMenu = (
                <Fragment>
                    {engagementActionListItems}
                    {viewRecordButton}
                    {editRecordButton}
                    {deleteRecordButton}
                </Fragment>
            );

            // console.log('%c listType: %O', 'color: purple;', listType);
            if (listType === 'Card Grid' && !showInline) {
                listItemActions = (
                    <Box sx={udicciClasses.itemButtonsContainer}>
                        {listItemDropDownMenu}
                    </Box>
                );
            } else if (listType === 'Table' && !showInline) {
                listItemActions = (
                    <TableCell align="right">
                        {listItemDropDownMenu}
                    </TableCell>
                );
            } else {
                //  sx={udicciClasses.menuContainer}
                listItemActions = (
                    <Box sx={udicciClasses.itemButtonsContainer}>
                        <span>
                            {listItemDropDownMenu}
                        </span>
                    </Box>
                );
            }
        }
    }

    if (!showForm) {
        descriptionElement = (
            <Box>
                {mainDescriptionElement}
                {engagementActionElement}
            </Box>
        );
    }

    let recordAvatar: any = null;
    if (udicciRecord && udicciRecord.data.CreatorSocialIcon) {
        // let dateCreated: Date = new Date(udicciRecord.data.DateCreated);
        let dateCreated: Date = udicciRecord.data.dtDateCreated;
        let dateModified: Date = udicciRecord.data.dtModifiedDate;
        let modifiedByDisplayName: string = udicciRecord.data.ModifiedByDisplayName;

        let matchHours = (dateCreated.getHours() === dateModified.getHours() ? true : false);
        let matchMinutes = (dateCreated.getMinutes() === dateModified.getMinutes() ? true : false);
        let modifiedDifferentElement: any = null;
        if (!matchHours || !matchMinutes) {
            let modifiedByElement: any = null;
            if (modifiedByDisplayName) {
                modifiedByElement = ( <Fragment>&nbsp;by {modifiedByDisplayName}</Fragment> );
            }

            modifiedDifferentElement = (
                <Typography variant="caption" component="div" noWrap sx={udicciClasses.createdDate}>
                    (last edit {dateModified.toLocaleString()}{modifiedByElement})
                </Typography>
            );
        }

        recordAvatar = (
            <Box>
                <Avatar src={udicciRecord.data.CreatorSocialIcon}
                        variant="rounded"
                />
                <Typography variant="caption" component="div" noWrap sx={udicciClasses.createdByTitle}>
                    {udicciRecord.data.CreatorDisplayName}
                </Typography>
                <Typography variant="caption" component="div" noWrap sx={udicciClasses.createdDate}>
                    created {dateCreated.toLocaleString()}
                </Typography>
                {modifiedDifferentElement}
            </Box>
        );
    } else if (udicciRecord && udicciRecord.data.CreatedInSocialIcon) {
        recordAvatar = (
            <Box>
                <Avatar src={udicciRecord.data.CreatedInSocialIcon} variant="rounded" classes={{ img: 'engagementAvatar' }} />
                <Typography variant="caption" component="div" noWrap>
                    {udicciRecord.data.CreatorDisplayName}
                </Typography>
                <Typography variant="caption" component="div" noWrap>
                    {udicciRecord.data.DateCreated}
                </Typography>
            </Box>
        );
    }

    let recordItemElement: any = null;
    if (!showForm) {
        // console.log('%c listType: %O', 'color: purple;', listType);
        if (listType === 'Card Grid' && !showInline) {
            let mdval: GridSize = 6;
            // let gridPaperStyle: any = null;
            // let gridClasses: any = null;
            if (recordFormElement || engagementActionElement || showingAttachments || showingPerspectives) {
                mdval = 12;
            }
            let recordDisplayElement: any = null;
            if (pagingStyle === 'Individual') {
                recordDisplayElement = recordContextDisplayElement;
            } else {
                let listElementProps: any = {
                    display: true,
                    showEngagementMenu: showEngagementMenu,
                    onShowForm: showRecordForm,
                    onDeleteRecord: deleteRecordClick,
                    onToggleAdditionalEngagements: toggleAdditionalEngagements,
                    listFields: lsListFields,
                    listType: listType,
                    listSettings: propListSettings,
                    layout: settingsLayout
                };
                // console.log('%c listElementProps: %O', 'color: purple;', listElementProps);
                recordDisplayElement = getListElement(listElementProps);
            }
            recordItemElement = (
                <Grid item xs={12} md={mdval}>
                    {recordDisplayElement}
                    {attachmentsElement}
                    {perspectivesElement}
                </Grid>
            );
        } else if (listType === 'Table' && !showInline) {
            // console.log('%c listType: %O', 'color: purple;', listType);
            // console.log('%c showInline: %O', 'color: purple;', showInline);
            let recordDisplayElement: any = null;
            if (pagingStyle === 'Individual') {
                recordDisplayElement = recordContextDisplayElement;
            } else {
                let listElementProps: any = {
                    display: true,
                    showEngagementMenu: showEngagementMenu,
                    onShowForm: showRecordForm,
                    onDeleteRecord: deleteRecordClick,
                    onToggleAdditionalEngagements: toggleAdditionalEngagements,
                    listFields: lsListFields,
                    listType: listType,
                    listSettings: propListSettings,
                    layout: settingsLayout
                };
                // console.log('%c listElementProps: %O', 'color: purple;', listElementProps);
                recordDisplayElement = getListElement(listElementProps);
            }
            recordItemElement = (
                <TableRow>
                    {recordDisplayElement}
                    {listItemActions}
                </TableRow>
            );
        } else {
            let recordDisplayElement: any = null;
            if (pagingStyle === 'Individual') {
                recordDisplayElement = recordContextDisplayElement;
            } else {
                let listElementProps: any = {
                    display: true,
                    showEngagementMenu: showEngagementMenu,
                    onToggleAdditionalEngagements: toggleAdditionalEngagements,
                    onShowForm: showRecordForm,
                    onDeleteRecord: deleteRecordClick,
                    listFields: lsListFields,
                    listType: listType,
                    listSettings: propListSettings,
                    layout: settingsLayout
                };
                recordDisplayElement = (
                    <Box>
                        {getListElement(listElementProps)}
                        {attachmentsElement}
                        {perspectivesElement}
                    </Box>
                );
            }
            recordItemElement = (
                <Box sx={udicciClasses.recordListItemContainer}> {recordDisplayElement} </Box>
            );
        }
    } else {
        let engagementActionContentElement: any = null;
        let additionalContentElement: any = null;
        let formProps: any = {
            display: true,
            breadcrumb: lastBreadcrumb,
            showForm: true,
            onClose: hideRecordForm,
            totalRecordCount: (props.totalRecordCount !== undefined && props.totalRecordCount !== null ? props.totalRecordCount : -1),
            onGetRecordPosition: (props.onGetRecordPosition ? props.onGetRecordPosition : null),
            onNextRecord: (props.onNextRecord ? props.onNextRecord : null),
            onPreviousRecord: (props.onPreviousRecord ? props.onPreviousRecord : null),
            listSettings: propListSettings,
            layout: settingsLayout
        };
        // console.log('%c UdicciRecordDisplay formProps(2): %O', 'color: blue;', formProps);
        recordFormElement = null;
        engagementActionContentElement = getFormElement(formProps);

        if (listType === 'Card Grid' && !showInline) {
            recordItemElement = (
                <Grid item xs={12} md={12}>
                    {recordFormElement}
                    {additionalContentElement}
                    {engagementActionContentElement}
                </Grid>
            );
        } else if (listType === 'Table' && !showInline) {
            // console.log('%c tableConfig: %O', 'color: blue;', tableConfig);
            let colSpan: number = (tableConfig && tableConfig.columns && tableConfig.columns.length > 0 ? (tableConfig.columns.length + 1) : 2);
            recordItemElement = (
                <TableRow>
                    <TableCell colSpan={colSpan}>
                        {recordFormElement}
                        {additionalContentElement}
                        {engagementActionContentElement}
                    </TableCell>
                </TableRow>
            );
        } else {
            recordItemElement = (
                <Box>
                    {recordFormElement}
                    {additionalContentElement}
                    {engagementActionContentElement}
                </Box>
            );
        }
    }

    return recordItemElement;
}

export default UdicciRecordDisplay;
