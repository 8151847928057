import { useState } from 'react';

import { useTheme } from '@mui/material/styles';

import DefaultSocialIcon from 'src/images/Ulysses Selfie Portrait.png';
import UDAIC_SOCIAL_ICON from 'src/images/udaic-social-icon.jpeg';

import { Box, Typography, Icon, IconButton, Avatar, TextField, MobileStepper, Button, Tooltip  } from '@mui/material';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { findIndex, forEach } from 'underscore';
import { InteractiveSentencesDisplay } from './interactive-sentences-display';

import { UdicciRecord } from 'src/classes/udicci-record';
import { useUdicciContext } from 'src/context/udicci-context';

import { useFocusedContext } from './focused-context';

export const PerspectiveDisplay: React.FC<any> = (props) => {
    const focus = useFocusedContext();
    let { perspective } = props;
    // console.log('%c PerspectiveDisplay perspective: %O', 'color: red;', perspective);

    const theme = useTheme();
    // console.log('%c theme: %O', 'color: purple;', theme);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;
    let { currentUser } = udicci;

    const [showForm, setShowForm] = useState<boolean>((perspective && perspective.recordId === 0 ? true : false));
    const [hovered, setHovered] = useState<boolean>(false);
    const [alwaysOn, setAlwaysOn] = useState<boolean>(false);
    const [editTheTitle, setEditTheTitle] = useState<boolean>(false);
    const [showUsageDisplay, setShowUsageDisplay] = useState<boolean>(false);
    const [showPromptDisplay, setShowPromptDisplay] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
    const [wp, setWorkingPerspective] = useState<any>(perspective);
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // const handleStepChange = (step: number) => {
    //     setActiveStep(step);
    // };

    const toggleEditTitle = () => {
        if (showForm) {
            setEditTheTitle(!editTheTitle);
        } else {
            setEditTheTitle(false);
        }
    };

    const toggleUsageDisplay = () => {
        setShowUsageDisplay(!showUsageDisplay);
    };

    const togglePromptDisplay = () => {
        setShowPromptDisplay(!showPromptDisplay);
    };

    let creatorDisplayName: string = (wp && wp.data.CreatorDisplayName ? wp.data.CreatorDisplayName : '');
    let creatorSocialIcon: any = (wp && wp.data.CreatorSocialIcon ? wp.data.CreatorSocialIcon : '');

    let choices: any = null;
    let usage: any = null;
    let prompt: string = '';
    if (perspective.data.jsonSettingsJson && perspective.data.jsonSettingsJson.aiPerspective) {
        let aiPerspective: any = perspective.data.jsonSettingsJson.aiPerspective;
        // console.log('%c aiPerspective: %O', 'color: blue;', aiPerspective);
        if (aiPerspective && aiPerspective.response) {
            creatorSocialIcon = UDAIC_SOCIAL_ICON;
            creatorDisplayName = 'Ulysses, with an AI Assist';
            choices = (aiPerspective.response.choices ? aiPerspective.response.choices : null);
            usage = (aiPerspective.response.usage ? aiPerspective.response.usage : null);
            prompt = (aiPerspective.request.prompt ? aiPerspective.request.prompt : null);
            // console.log('%c creatorSocialIcon: %O', 'color: blue;', creatorSocialIcon);
        }
    }
    // console.log('%c prompt: %O', 'color: blue;', prompt);
    // console.log('%c usage: %O', 'color: blue;', usage);
    // console.log('%c choices: %O', 'color: blue;', choices);

    let userId: number = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
    // console.log('%c PerspectiveDisplay userId: %O', 'color: green;', userId);
    let perspectiveId: number = (perspective && perspective.recordId ? perspective.recordId : 0);
    // console.log('%c PerspectiveDisplay perspectiveId: %O', 'color: green;', perspectiveId);
    let createdByUserId: number = (perspective && perspective.data && perspective.data.CreatedByUserId ? perspective.data.CreatedByUserId : 0);
    // console.log('%c PerspectiveDisplay createdByUserId: %O', 'color: green;', createdByUserId);

    let readonly: boolean = ((perspectiveId > 0 && (userId <= 0 || createdByUserId !== userId)) ? true : false);
    // console.log('%c PerspectiveDisplay readonly: %O', 'color: green;', readonly);

    let title: string = (wp && wp.data.Title ? wp.data.Title : '');
    let talkingPoints: string = (wp && wp.data.TalkingPoints ? wp.data.TalkingPoints : '');

    let matchedChoiceIndex: number =-1;
    let matchedChoice: any = null;
    // console.log('%c talkingPoints: %O', 'color: blue;', talkingPoints);

    let talkingPointsCompare: string = talkingPoints;
    if (talkingPointsCompare.startsWith('\r\n') === true) talkingPointsCompare = talkingPointsCompare.substring(2);
    talkingPointsCompare = talkingPointsCompare.replace(new RegExp('\r', 'g'), ' ');
    talkingPointsCompare = talkingPointsCompare.replace(new RegExp('\n', 'g'), ' ');
    // console.log('%c talkingPointsCompare: %O', 'color: blue;', talkingPointsCompare);

    // console.log('%c wp: %O', 'color: blue;', wp);
    if (choices && choices.length > 0) {
        forEach(choices, (choice: any, idx: number) => {
            // console.log('%c choice: %O', 'color: blue;', choice);
            let choiceTextCompare: string = choice.text;
            if (choiceTextCompare.startsWith('\r\n') === true) choiceTextCompare = choiceTextCompare.substring(2);
            choiceTextCompare = choiceTextCompare.replace(new RegExp('\r', 'g'), ' ');
            choiceTextCompare = choiceTextCompare.replace(new RegExp('\n', 'g'), ' ');
            // console.log('%c choiceTextCompare: %O', 'color: blue;', choiceTextCompare);
            // console.log('%c talkingPoints: %O', 'color: blue;', talkingPoints);
            let areEqual: boolean = (choiceTextCompare.trim().toUpperCase() === talkingPointsCompare.trim().toUpperCase() ? true : false);
            // console.log('%c areEqual: %O', 'color: red;', areEqual);
            if (areEqual) {
                matchedChoiceIndex = idx;
                matchedChoice = choice;
            }
        });
    }
    // console.log('%c matchedChoiceIndex: %O', 'color: blue;', matchedChoiceIndex);
    // console.log('%c matchedChoice: %O', 'color: blue;', matchedChoice);

    const makeActiveChoicePerspectiveContent = () => {
        // console.log('%c makeActiveChoicePerspectiveContent matchedChoiceIndex: %O', 'color: blue;', matchedChoiceIndex);
        // console.log('%c makeActiveChoicePerspectiveContent activeStep: %O', 'color: blue;', activeStep);
        if (activeStep >= 0 && activeStep !== matchedChoiceIndex) {
            if (choices && choices.length > 0 && choices.length > activeStep) {
                let newActiveChoice: any = choices[activeStep];
                // console.log('%c newActiveChoice: %O', 'color: blue;', newActiveChoice);
                let newChoiceText: string = newActiveChoice.text;
                let updatedPerspective: any = {};
                Object.assign(updatedPerspective, wp);
        
                updatedPerspective.data.TalkingPoints = newChoiceText.trim();
                updatedPerspective.isDirty = true;
        
                if (updatedPerspective.setKeyFieldValues)
                    updatedPerspective.setKeyFieldValues();
                // console.log('%c handleChange updatedPerspective: %O', 'color: maroon;', updatedPerspective);
        
                setWorkingPerspective(updatedPerspective);
                setTimeout(() => { applyChangesToPerspective(); }, 100);
            }
        }
    };

    let unselectedProps: any = {};
    let unselectedChoiceIcon: any = null;
    let titleDisplay: any[] = udicciHelpers.formatStringForDisplay(title);
    let talkingPointsDisplayFormatted: any[] = [];
    // console.log('%c activeStep: %O', 'color: blue;', activeStep);
    if (activeStep >= 0 && activeStep < choices.length && matchedChoiceIndex !== activeStep) {
        talkingPointsDisplayFormatted = udicciHelpers.formatStringForDisplay(choices[activeStep].text, false, false);
        unselectedChoiceIcon = (
            <Tooltip title="Replace the Perspective Content with the choice in focus.">
                <IconButton size="small" color="secondary" aria-label="Assign Choice to Perspective" edge="start" onClick={makeActiveChoicePerspectiveContent} sx={{ zIndex: 15, marginRight: '4px' }}>
                    <Icon fontSize="small">smart_screen</Icon>
                </IconButton>
            </Tooltip>
        );
        unselectedProps = {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            border: '1px solid ' + theme.palette.primary.main,
            padding: '8px'
        };
    } else if (activeStep >= 0 && activeStep < choices.length && matchedChoice && matchedChoiceIndex >= 0 && matchedChoiceIndex !== activeStep) {
        talkingPointsDisplayFormatted = udicciHelpers.formatStringForDisplay(choices[activeStep].text, false, false);
        unselectedChoiceIcon = (
            <Tooltip title="Replace the Perspective Content with the choice in focus.">
                <IconButton size="small" color="secondary" aria-label="Assign Choice to Perspective" edge="start" onClick={makeActiveChoicePerspectiveContent} sx={{ zIndex: 15, marginRight: '4px' }}>
                    <Icon fontSize="small">smart_screen</Icon>
                </IconButton>
            </Tooltip>
        );    
        unselectedProps = {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            border: '1px solid ' + theme.palette.primary.main,
            padding: '8px'
        };
    } else {
        talkingPointsDisplayFormatted = udicciHelpers.formatStringForDisplay(talkingPoints, false, false);
    }

    let titleInteractiveProps: any = {
        displayProps: { variant: 'clickableSubTitle2', sx: {} },
        hoverProps: { variant: 'clickableSubTitle2', sx: { textDecoration: 'underline', textDecorationThickness: '3px', textDecorationColor: 'lime' } },
        selectedProps: { variant: 'body1', sx: { textDecoration: 'underline', textDecorationThickness: '3px', textDecorationColor: 'green', cursor: 'pointer' } },
    };
    let talkingPointsInteractiveProps: any = {
        displayProps: { variant: 'caption', sx: { cursor: 'pointer' } },
        hoverProps: { variant: 'caption', sx: { cursor: 'pointer', textDecoration: 'underline', textDecorationThickness: '3px', textDecorationColor: 'lime' } },
        selectedProps: { variant: 'body1', sx: { textDecoration: 'underline', textDecorationThickness: '3px', textDecorationColor: 'green', cursor: 'pointer' } },
    };

    // let titleSentences: any[] = udicciHelpers.getInteractiveSentences(title, 'clickableSubTitle2', 'caption', 'clickableSubTitle1');
    // console.log('%c PerspectiveDisplay titleSentences: %O', 'color: blue;', titleSentences);
    // console.log('%c PerspectiveDisplay titleInteractiveProps: %O', 'color: blue;', titleInteractiveProps);
    // console.log('%c PerspectiveDisplay talkingPointsInteractiveProps: %O', 'color: blue;', talkingPointsInteractiveProps);
    let titleInteractiveDisplayElement: any = (<InteractiveSentencesDisplay {...titleInteractiveProps} stringValue={title} />);
    let talkingPointsInteractiveDisplayElement: any = (<InteractiveSentencesDisplay {...talkingPointsInteractiveProps} stringValue={talkingPoints} />);

    const closePerspective = () => {
        // console.log('%c closePerspective wp: %O', 'color: red;', wp);
        setEditTheTitle(false);
        setShowForm(false);
    };

    const editPerspective = () => {
        // console.log('%c editPerspective wp: %O', 'color: red;', wp);
        if (readonly) return false;
        setShowForm(true);
    };

    const applyChangesToPerspective = () => {
        // console.log('%c applyChangesToPerspective wp: %O', 'color: red;', wp);

        setEditTheTitle(false);
        setShowForm(false);

        let updatedPoint: any = {};
        Object.assign(updatedPoint, focus.point);

        let pointPerspectives: any[] = (updatedPoint && updatedPoint.perspectives ? updatedPoint.perspectives : []);
        let updatePerspectiveIndex: number = findIndex(pointPerspectives, (prsp: any, idx: number) => {
            let isLocalId: boolean = (prsp && prsp.context && wp && wp.context && prsp.context.local_id === wp.context.local_id ? true : false);
            let isId: boolean = (prsp && wp && prsp.recordId === wp.recordId ? true : false);
            return (isLocalId || isId);
        });
        if (updatePerspectiveIndex >= 0) {
            pointPerspectives[updatePerspectiveIndex] = wp;
        } else {
            pointPerspectives.push(wp);
        }
        updatedPoint.perspectives = pointPerspectives;
        updatedPoint.isDirty = true;

        // console.log('%c handleChange updatedPoint: %O', 'color: maroon;', updatedPoint);
        if (focus.setPointToFocus)
            focus.setPointToFocus(updatedPoint);
    };

    const handleChange = (fieldName: string, evt: any) => {
        // console.log('%c handleChange fieldName: %O', 'color: maroon;', fieldName);
        // console.log('%c handleChange evt: %O', 'color: maroon;', evt);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c handleChange newValue: %O', 'color: maroon;', newValue);
        // console.log('%c handleChange wp: %O', 'color: maroon;', wp);
        let updatedPerspective: any = {};
        Object.assign(updatedPerspective, wp);

        updatedPerspective.data[fieldName] = newValue;
        updatedPerspective.isDirty = true;

        if (updatedPerspective.setKeyFieldValues)
            updatedPerspective.setKeyFieldValues();
        // console.log('%c handleChange updatedPerspective: %O', 'color: maroon;', updatedPerspective);

        setWorkingPerspective(updatedPerspective);
    };

    const toggleAlwaysOn = () => {
        setAlwaysOn(!alwaysOn);
    };
    const onMouseEnter = (evt: any) => {
        setHovered(true);
    };
    const onMouseLeave = () => {
        setHovered(false);
    };

    const removePerspective = () => {
        // console.log('%c removePerspective perspective: %O', 'color: maroon;', perspective);

        if (focus.point) {
            // if there is a point in focus, then we are removing a perspective from the point in focus.
            let updatedPoint: any = {};
            Object.assign(updatedPoint, focus.point);

            let updatedPointPerspectives: any[] = (updatedPoint.perspectives ? updatedPoint.perspectives : []);
            // console.log('%c removePerspective updatedPointPerspectives: %O', 'color: maroon;', updatedPointPerspectives);

            let removePerspectiveIndex: number = findIndex(updatedPointPerspectives, (prsp: any, idx: number) => {
                let isLocalId: boolean = (prsp && prsp.context && wp && wp.context && prsp.context.local_id === wp.context.local_id ? true : false);
                let isId: boolean = (prsp && wp && prsp.recordId === wp.recordId ? true : false);
                return (isLocalId || isId);
            });
            // console.log('%c removePerspective removePerspectiveIndex: %O', 'color: maroon;', removePerspectiveIndex);

            if (removePerspectiveIndex >= 0) {
                let workingPerspective: UdicciRecord = updatedPointPerspectives[removePerspectiveIndex];
                workingPerspective.data.InTrashBin = true;
                if (!workingPerspective.context) workingPerspective.context = {};
                workingPerspective.context.delete = true;
                // console.log('%c removePerspective workingPerspective: %O', 'color: maroon;', workingPerspective);
                updatedPointPerspectives[removePerspectiveIndex] = workingPerspective;
                // console.log('%c removePerspective updatedPointPerspectives: %O', 'color: maroon;', updatedPointPerspectives);
            }

            updatedPoint.perspectives = updatedPointPerspectives;

            updatedPoint.isDirty = true;

            if (focus.setPointToFocus) focus.setPointToFocus(updatedPoint);
            setShowConfirmDelete(false);
        }
    };

    const cancelDeleteThePerspective = () => {
        setShowConfirmDelete(false);
    };

    const confirmDeleteThePerspective = () => {
        removePerspective();
    };

    let localProps: any = {};

    let aiUsageElement: any = null;
    let aiUsageDisplayElement: any = null;
    let aiPromptElement: any = null;
    let aiPromptDisplayElement: any = null;
    let removePerspectiveElement: any = null;
    let removePerspectiveConfirmElement: any = null;
    let togglePerspectiveFormElement: any = null;
    let applyPerspectiveChangesElement: any = null;
    if (showForm) {
        togglePerspectiveFormElement = (
            <IconButton size="small" color="info" aria-label="close" edge="start" onClick={closePerspective} sx={{ float: 'right', zIndex: 15 }}>
                <Icon fontSize="small">close</Icon>
            </IconButton>
        );
        applyPerspectiveChangesElement = (
            <IconButton size="small" color="success" aria-label="done" edge="start" onClick={applyChangesToPerspective} sx={{ float: 'right', zIndex: 15 }}>
                <Icon fontSize="small">done</Icon>
            </IconButton>
        );
    } else if (!focus.aiAssistantOn) {
        if (hovered || alwaysOn || showConfirmDelete) {
            togglePerspectiveFormElement = (
                <IconButton disabled={readonly} size="small" color="secondary" aria-label="edit" edge="start" onClick={editPerspective} sx={{ float: 'right', zIndex: 15, marginRight: '7px' }}>
                    <Icon fontSize="small">edit</Icon>
                </IconButton>
            );
            let removePerspectiveIcon: any = null;
            if (perspective.recordId <= 0) {
                removePerspectiveIcon = ( <Icon fontSize="small">delete</Icon> );
            } else {
                removePerspectiveIcon = ( <Icon fontSize="small">delete_forever</Icon> );
            }
            let removePerspectiveProps: any = {
                disabled: (readonly || showConfirmDelete ? true : false),
                size: 'small',
                color: 'warning',
                edge: 'start',
                sx: { float: 'right', zIndex: 15, marginRight: '7px' },
            };
            if (!showConfirmDelete) {
                removePerspectiveProps.onClick = (evt: any) => { setShowConfirmDelete(true); };
            } else {
                removePerspectiveProps.onClick = (evt: any) => { removePerspective(); };
            }
            // setShowConfirmDelete(true)
            removePerspectiveElement = (
                <IconButton {...removePerspectiveProps} aria-label="remove perspective">
                    {removePerspectiveIcon}
                </IconButton>
            );
            if (showConfirmDelete) {
                removePerspectiveConfirmElement = (
                    <Box sx={{ display: 'inline-block' }}>
                        <Typography variant="errorMessage">Are you sure you want to delete this perspective?</Typography>
                        <IconButton size="small" color="info" aria-label="cancel" edge="end" onClick={cancelDeleteThePerspective} sx={{ marginLeft: '7px' }}>
                            <Icon fontSize="small">cancel</Icon>
                        </IconButton>
                        <IconButton size="small" color="error" aria-label="delete" edge="end" onClick={confirmDeleteThePerspective} sx={{ marginLeft: '7px' }}>
                            <Icon fontSize="small">check</Icon>
                        </IconButton>
                    </Box>
                );
            }

            if (showUsageDisplay) {
                // console.log('%c usage: %O', 'color: blue;', usage);
                aiUsageDisplayElement = (
                    <Box sx={{ display: 'flow-root', padding: '8px' }}>
                        <IconButton disabled={readonly} size="small" color="secondary" aria-label="close" edge="start" onClick={toggleUsageDisplay} sx={{ float: 'right', zIndex: 15, marginRight: '7px' }}>
                            <Icon fontSize="small">close</Icon>
                        </IconButton>
                        <Box sx={{ display: 'flex' }}>
                            <IconButton disabled={readonly} size="small" color="secondary" aria-label="show AI transaction summary" edge="start" onClick={toggleUsageDisplay} sx={{ float: 'right', zIndex: 15, marginRight: '4px' }}>
                                <Icon fontSize="small">paid</Icon>
                            </IconButton>
                            <Typography variant="body2" component="div">AI Perspective Interaction Usage Tokens Summary</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant="caption" sx={{ paddingRight: '4px', width: '150px' }}>Prompt Tokens:</Typography>
                            <Typography variant="caption" sx={{ textAlign: 'right', width: '50px' }}>{usage && usage.prompt_tokens ? usage.prompt_tokens : 0}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant="caption" sx={{ paddingRight: '4px', width: '150px', borderBottom: '1px solid #000' }}>Completion Tokens:</Typography>
                            <Typography variant="caption" sx={{ textAlign: 'right', width: '50px', borderBottom: '1px solid #000' }}>{usage && usage.completion_tokens ? usage.completion_tokens : 0}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant="caption" sx={{ paddingRight: '4px', width: '150px' }}>Total Tokens:</Typography>
                            <Typography variant="caption" sx={{ textAlign: 'right', width: '50px' }}>{usage && usage.total_tokens ? usage.total_tokens : 0}</Typography>
                        </Box>
                    </Box>
                );
            } else {
                aiUsageElement = (
                    <IconButton disabled={readonly} size="small" color="secondary" aria-label="show AI transaction summary" edge="start" onClick={toggleUsageDisplay} sx={{ float: 'right', zIndex: 15, marginRight: '4px' }}>
                        <Icon fontSize="small">paid</Icon>
                    </IconButton>
                );    
            }

            if (showPromptDisplay) {
                // console.log('%c prompt: %O', 'color: blue;', prompt);
                aiPromptDisplayElement = (
                    <Box sx={{ display: 'flow-root', padding: '8px' }}>
                        <IconButton disabled={readonly} size="small" color="secondary" aria-label="edit" edge="start" onClick={togglePromptDisplay} sx={{ float: 'right', zIndex: 15, marginRight: '7px' }}>
                            <Icon fontSize="small">close</Icon>
                        </IconButton>
                        <Box sx={{ display: 'flex' }}>
                            <IconButton disabled={readonly} size="small" color="secondary" aria-label="edit" edge="start" onClick={togglePromptDisplay} sx={{ float: 'right', zIndex: 15, marginRight: '4px' }}>
                                <Icon fontSize="small">sticky_note_2</Icon>
                            </IconButton>
                            <Typography variant="caption" component="div">AI Perspective Prompt</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', padding: '8px' }}>
                            <Typography variant="caption">{prompt}</Typography>
                        </Box>
                    </Box>
                );
            } else {
                aiPromptElement = (
                    <IconButton disabled={readonly} size="small" color="secondary" aria-label="edit" edge="start" onClick={togglePromptDisplay} sx={{ float: 'right', zIndex: 15, marginRight: '4px' }}>
                        <Icon fontSize="small">sticky_note_2</Icon>
                    </IconButton>
                );    
            }
        }
        localProps = {
            onMouseEnter: (evt: any) => onMouseEnter(evt),
            onMouseLeave: (evt: any) => onMouseLeave(),
            onClick: (evt: any) => toggleAlwaysOn(),
        };
    }

    if (showUsageDisplay) {
        // console.log('%c usage: %O', 'color: blue;', usage);
        aiUsageDisplayElement = (
            <Box sx={{ display: 'flow-root', padding: '8px' }}>
                <IconButton disabled={readonly} size="small" color="secondary" aria-label="edit" edge="start" onClick={toggleUsageDisplay} sx={{ float: 'right', zIndex: 15, marginRight: '7px' }}>
                    <Icon fontSize="small">close</Icon>
                </IconButton>
                <Box sx={{ display: 'flex' }}>
                    <IconButton disabled={readonly} size="small" color="secondary" aria-label="show AI transaction summary" edge="start" onClick={toggleUsageDisplay} sx={{ float: 'left', zIndex: 15, marginRight: '4px' }}>
                        <Icon fontSize="small">paid</Icon>
                    </IconButton>
                    <Typography variant="caption" component="div">AI Perspective Usage Tokens Summary</Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography variant="caption" sx={{ paddingRight: '4px', width: '150px' }}>Prompt Tokens:</Typography>
                    <Typography variant="body2" sx={{ textAlign: 'right', width: '50px' }}>{usage && usage.prompt_tokens ? usage.prompt_tokens : 0}</Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography variant="caption" sx={{ paddingRight: '4px', width: '150px', borderBottom: '1px solid #000' }}>Completion Tokens:</Typography>
                    <Typography variant="body2" sx={{ textAlign: 'right', width: '50px', borderBottom: '1px solid #000' }}>{usage && usage.completion_tokens ? usage.completion_tokens : 0}</Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography variant="caption" sx={{ paddingRight: '4px', width: '150px' }}>Total Tokens:</Typography>
                    <Typography variant="body2" sx={{ textAlign: 'right', width: '50px' }}>{usage && usage.total_tokens ? usage.total_tokens : 0}</Typography>
                </Box>
            </Box>
        );
    }

    let socialIconElement: any = null;
    if (creatorSocialIcon) {
        socialIconElement = (
            <Avatar alt={creatorDisplayName}
                src={creatorSocialIcon}
                sx={{ display: 'inline-block', maxHeight: 20, maxWidth: 20, margin: '4px', marginRight: '8px', float: 'left' }}
                component='span' />
        );
    } else {
        socialIconElement = (
            <Avatar alt={creatorDisplayName}
                src={DefaultSocialIcon}
                sx={{ display: 'inline-block', maxHeight: 20, maxWidth: 20, margin: '4px', marginRight: '8px', float: 'left' }}
                component='span' />
        );
    }

    let titleDisplayElement: any = null;
    if (focus.aiAssistantOn) {
        titleDisplayElement = (<Box>{titleInteractiveDisplayElement}</Box>);
    } else if (showForm && editTheTitle) {
        titleDisplayElement = (
            <Box sx={{ margin: '4px' }}>
                <TextField fullWidth
                           type="text"
                           multiline={true}
                           minRows={1}
                           maxRows={2}
                           value={title}
                           placeholder={"Enter a title for your perspective"}
                           margin="normal"
                           variant='outlined'
                           sx={{ marginTop: '8px' }}
                           onChange={(evt: any) => handleChange('Title', evt)}
                />
                <IconButton color="info" aria-label="close title form" edge="end" onClick={toggleEditTitle} sx={{ float: 'right' }}>
                    <Icon>cancel_presentation</Icon>
                </IconButton>
            </Box>
        );
    } else {
        titleDisplayElement = (<Box sx={{ margin: '8px' }}> <Typography variant="body2" onClick={toggleEditTitle}>{socialIconElement}{titleDisplay}</Typography> </Box>);
    }

    let pointTitle: string = focus.point.title;
    let perspectiveTitle: string = wp.title;
    let placeholder: string = 'Add other additional information that is important for the "' + perspectiveTitle + '" perspective of the point "' + pointTitle + '".';
    let talkingPointsDisplay: any = null;
    if (showForm && !focus.aiAssistantOn) {
        talkingPointsDisplay = (
            <Box sx={{ margin: '4px' }}>
                <TextField fullWidth
                    type="text"
                    multiline={true}
                    minRows={2}
                    maxRows={7}
                    value={talkingPoints}
                    placeholder={placeholder}
                    margin="normal"
                    variant='outlined'
                    sx={{ marginTop: '8px' }}
                    onChange={(evt: any) => handleChange('TalkingPoints', evt)} />
            </Box>
        );
    } else if (focus.aiAssistantOn) {
        talkingPointsDisplay = (<Box> {talkingPointsInteractiveDisplayElement} </Box>);
    } else {
        let talkingPointContainerSettings: any = {
            onClick: () => { setExpanded(!expanded); },
            sx: { margin: '8px', cursor: 'pointer' },
        };
        talkingPointsDisplay = (
            <Box {...talkingPointContainerSettings} {...unselectedProps}>
                {unselectedChoiceIcon}
                <Typography variant="caption">{talkingPointsDisplayFormatted}</Typography>
            </Box>
        );
    }

    let choicesDisplayElement: any = null;
    if (choices.length > 1 && !showForm) {
        let leftArrowIcon: any = (<Icon>keyboard_arrow_left</Icon>);
        let rightArrowIcon: any = (<Icon>keyboard_arrow_right</Icon>);
        let nextButton: any = (
            <Button color="secondary" size="small" onClick={handleNext} disabled={activeStep >= choices.length - 1}>
                Next {theme.direction === 'rtl' ? (leftArrowIcon) : (rightArrowIcon)}
            </Button>
        );
        let backButton: any = (
            <Button color="secondary" size="small" onClick={handleBack} disabled={choices.length > 0 && activeStep <= 0}>
                {theme.direction === 'rtl' ? (rightArrowIcon) : (leftArrowIcon)} Back
            </Button>
        );
        let stepperProps: any = {
            steps: choices.length,
            position: "static",
            activeStep: activeStep,
            nextButton: nextButton,
            backButton: backButton,
            sx: { paddingBottom: 0 }
        }
        choicesDisplayElement = (
            <Box>
                <MobileStepper {...stepperProps} />
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="caption" component="span">AI Response Choices: </Typography>
                    <Typography variant="body2" component="span">{(activeStep + 1).toString()}</Typography>
                    <Typography variant="body2" component="span"> of </Typography>
                    <Typography variant="body2" component="span">{choices.length}</Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Box {...localProps}>
            {applyPerspectiveChangesElement}
            {togglePerspectiveFormElement}
            {removePerspectiveElement}
            {aiPromptElement}
            {removePerspectiveConfirmElement}
            {aiUsageElement}
            {titleDisplayElement}
            {talkingPointsDisplay}
            {aiPromptDisplayElement}
            {aiUsageDisplayElement}
            {choicesDisplayElement}
        </Box>
    );
};
