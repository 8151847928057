import { createContext, useContext } from 'react';
import { UdicciRecord } from 'src/classes/udicci-record';

import { OpenAIApi } from "openai";

export const OPENAI_API_KEY_UDICCI: string = '';
export const OPENAI_ORG_ID_UDICCI: string = process.env.REACT_APP_OPENAI_ORG_ID;

export const DEFAULT_OPEN_AI_MODEL: string = "text-davinci-003";
export const DEFAULT_OPEN_AI_MAX_TOKENS: number = 2048;
export const DEFAULT_OPEN_AI_TEMPERATURE: number = 0;
export const DEFAULT_OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT: number = 2;
export const DEFAULT_OPEN_AI_PRESENCE_PENALTY: number = 0;
export const DEFAULT_OPEN_AI_FREQUENCY_PENALTY: number = 0;

interface FocusedContextType {
    isMobile: boolean;

    OPENAI_API_KEY: string;
    OPENAI_ORG_ID: string;

    OPEN_AI_MODEL: string;
    OPEN_AI_MAX_TOKENS: number;
    OPEN_AI_TEMPERATURE: number;
    OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT: number;
    OPEN_AI_PRESENCE_PENALTY: number;
    OPEN_AI_FREQUENCY_PENALTY: number;

    selectedSettingMenu: string;
    setSelectedSettingMenu: (value: string) => void;

    point: any;
    setFocusedPoint: (value: any) => void;

    aiAssistantOn: boolean;
    askForAssist: (value: boolean) => void;

    aiModels: any[];
    setAIModels: (value: any[]) => void;

    aiPrompt: any;
    setAIPrompt: (value: any) => void;

    aiError: any;
    setAIError: (value: any) => void;
    clearAIError: () => void;

    configureSettings: boolean;
    setConfigureSettings: (value: boolean) => void;

    refreshFocusBoard: () => void;
    hasChangesToSave: any;
    isSavingFocusBoard: any;
    savePortaFocusBoard: () => void;
    setPointToFocus: (point: any) => void;
    changeSettingMenu: (settingName: string) => void;
    closeFocus: () => void;
    processingAIRequest: boolean;
    lastAIResponse: any;
    sendAIRequest: (settings: any) => void;
    getAIRequestStandardSettings: () => void;
    requestAIAssistance: () => void;
    toggleSettingsForm: () => void;
    updateAIPrompt: (updatedPrompt: any) => void;
    getNewPointRecord: any;
    getNewPerspectiveRecord: any;

    getOpenAIModels: () => void;
    setOpenAIAPIKey: (apiKey: string) => void;
    setOpenAIOrgId: (orgId: string) => void;
    setOpenAIModel: (modelName: string) => void;

    setOpenAIMaxTokens: (maxTokens: number) => void;
    setOpenAITemperature: (temperature: number) => void;
    setOpenAIMaxCompletions: (maxCompletions: number) => void;
    setOpenAIPresencePenalty: (presencePenalty: number) => void;
    setOpenAIFrequencyPenalty: (frequencyPenalty: number) => void;
    processUpdates: (changes: any) => void;
};

export const FocusedContext = createContext<FocusedContextType>({
    isMobile: false,

    OPENAI_API_KEY: '', // OPENAI_API_KEY_UDICCI,
    OPENAI_ORG_ID: '', // OPENAI_ORG_ID_UDICCI,

    OPEN_AI_MODEL: DEFAULT_OPEN_AI_MODEL,
    OPEN_AI_MAX_TOKENS: DEFAULT_OPEN_AI_MAX_TOKENS,
    OPEN_AI_TEMPERATURE: DEFAULT_OPEN_AI_TEMPERATURE,
    OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT: DEFAULT_OPEN_AI_MAX_COMPLETIONS_FOR_EACH_PROMPT,
    OPEN_AI_PRESENCE_PENALTY: DEFAULT_OPEN_AI_PRESENCE_PENALTY,
    OPEN_AI_FREQUENCY_PENALTY: DEFAULT_OPEN_AI_FREQUENCY_PENALTY,

    selectedSettingMenu: '',
    setSelectedSettingMenu: (value: string) => { },
    point: null,
    setFocusedPoint: (value: any) => { },

    aiAssistantOn: false,
    askForAssist: (value: boolean) => { },
    aiModels: [],
    setAIModels: (value: any[]) => { },
    aiPrompt: null,
    setAIPrompt: (value: any) => { },
    aiError: null,
    setAIError: (value: any) => { },
    clearAIError: () => { },

    configureSettings: false,
    setConfigureSettings: (value: boolean) => { },
    refreshFocusBoard: () => { },
    hasChangesToSave: (): boolean => { return false; },
    isSavingFocusBoard: (): boolean => { return false; },
    savePortaFocusBoard: () => { },
    setPointToFocus: (point: any) => { },
    changeSettingMenu: (settingName: string) => { },
    closeFocus: () => { },
    processingAIRequest: false,
    lastAIResponse: null,
    sendAIRequest: (settings: any) => { },
    getAIRequestStandardSettings: () => { },
    requestAIAssistance: () => { },
    toggleSettingsForm: () => { },
    updateAIPrompt: (updatedPrompt: any) => { },

    getNewPointRecord: (): UdicciRecord | null => { return null; },
    getNewPerspectiveRecord: (): UdicciRecord | null => { return null; },

    getOpenAIModels: () => { },
    setOpenAIAPIKey: (apiKey: string) => { },
    setOpenAIOrgId: (orgId: string) => { },
    setOpenAIModel: (modelName: string) => { },

    setOpenAIMaxTokens: (maxTokens: number) => { },
    setOpenAITemperature: (temperature: number) => { },
    setOpenAIMaxCompletions: (maxCompletions: number) => { },
    setOpenAIPresencePenalty: (presencePenalty: number) => { },
    setOpenAIFrequencyPenalty: (frequencyPenalty: number) => { },
    processUpdates: (changes: any) => { },
});

export function useFocusedContext() {
    const context = useContext(FocusedContext)
    if (context === undefined) {
        throw new Error('useFocusedContext must be used within a FocusedContextProvider');
    }
    return context
}
