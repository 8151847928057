import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';

import { Box, Grid, Typography } from '@mui/material';

import { useUdicciContext } from 'src/context/udicci-context';

import SocialSolutionCard from './social-solution-card';
import SocialSolutions from './social-solutions';

import { forEach } from 'underscore';

const SocialSolutionsMenu: React.FC<any> = (props) => {
    // console.log('%c SocialSolutionsMenu props: %O', 'color: maroon;', props);

    // const [showSocialSolutions, toggleShowSocialSolutions] = useState<boolean>(false);
    const [selectedSocialSolution, setSelectedSocialSolution] = useState<any>(null);
    const [selectedFeature, setSelectedFeature] = useState<any>(null);

    const theme = useTheme();

    const udicciContext = useUdicciContext();
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    let { udicci } = udicciContext.state;
    let { selectedProfile, currentUser } = udicci;
    // console.log('%c SocialSolutions selectedProfile: %O', 'color: maroon;', selectedProfile);
    // console.log('%c SocialSolutions selectedFeature: %O', 'color: maroon;', selectedFeature);
    // console.log('%c SocialSolutions currentUser: %O', 'color: maroon;', currentUser);

    let profile: any = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    // console.log('%c profile: %O', 'color: hotpink;', profile);
    let profileSolutions = (profile && profile.SocialSolutions ? profile.SocialSolutions : null);
    // console.log('%c profileSolutions: %O', 'color: purple; font-weight: bold;', profileSolutions);

    let publicRole = (profile && profile.PublicRole ? profile.PublicRole : null);
    // console.log('%c publicRole: %O', 'color: hotpink;', publicRole);
    let userRoleOnProfile = (profile && profile.UdicciUserRole ? profile.UdicciUserRole : null);
    if (!userRoleOnProfile) userRoleOnProfile = publicRole;
    // console.log('%c userRoleOnProfile: %O', 'color: hotpink;', userRoleOnProfile);
    let profileSettings = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
    // console.log('%c profileSettings: %O', 'color: hotpink;', profileSettings);
    var udicciUserRole = (profile && profile.UdicciUserRole ? profile.UdicciUserRole : null);
    // console.log('%c udicciUserRole: %O', 'color: purple; font-weight: bold;', udicciUserRole);
    var udicciUserRoleId = (udicciUserRole && udicciUserRole.UdicciRecordId ? udicciUserRole.UdicciRecordId : 0);
    // console.log('%c Me udicciUserRoleId: %O', 'color: purple; font-weight: bold;', udicciUserRoleId);

    var includedSocialSolutions: any = {};
    // var socialSolutionsMenuElement: any = null;
    if (profileSettings && profileSettings.socialSolutionSettings) {
        if (currentUser && currentUser.UdicciUserId > 0) {
            var sss: any = profileSettings.socialSolutionSettings;
            // console.log('%c Me socialSolutionSettings: %O', 'color: maroon;', sss);
            if (sss) {
                for (let [id, setting] of Object.entries<any>(sss)) {
                    // console.log('%c Me id: %O', 'color: green;', id);
                    // console.log('%c Me setting: %O', 'color: green;', setting);

                    var publicPermissions = (setting.public ? setting.public : null);
                    // console.log('%c Me publicPermissions: %O', 'color: maroon;', publicPermissions);
                    var defaultPermissions = (setting.default ? setting.default : null);
                    // console.log('%c Me defaultPermissions: %O', 'color: maroon;', defaultPermissions);

                    var permissions: any | null = null;
                    if (currentUser && currentUser.UdicciUserId > 0) {
                        permissions = defaultPermissions;
                        // check for current user role overrides
                    } else {
                        permissions = publicPermissions;
                    }
                    // console.log('%c Me permissions: %O', 'color: blue;', permissions);

                    var roleOverrides = (setting.RoleOverrides ? setting.RoleOverrides : null);
                    // console.log('%c Me roleOverrides: %O', 'color: maroon;', roleOverrides);
                    var userRoleOverride = (udicciUserRoleId > 0 && roleOverrides && roleOverrides[udicciUserRoleId] ? roleOverrides[udicciUserRoleId] : null);
                    // console.log('%c Me userRoleOverride: %O', 'color: maroon;', userRoleOverride);
                    if (userRoleOverride) {
                        // console.log('%c Me userRoleOverride: %O', 'color: green;', userRoleOverride);
                        permissions = userRoleOverride;

                        if (userRoleOverride.overrides) {
                            for (var [medName, medPerms] of Object.entries<any>(userRoleOverride.overrides)) {
                                // console.log('%c Me medName: %O', 'color: green;', medName);
                                // console.log('%c Me medPerms: %O', 'color: green;', medPerms);
                                if (!medName) continue;

                                if (medPerms && medPerms.CanView !== undefined) permissions.CanView = medPerms.CanView;
                                if (medPerms && medPerms.CanAdd !== undefined) permissions.CanAdd = medPerms.CanAdd;
                            }
                        }
                    }

                    // console.log('%c Me permissions: %O', 'color: blue;', permissions);
                    if (permissions && permissions.CanView) {
                        // console.log('%c Me permissions: %O', 'color: green; font-weight: bold;', permissions);
                        if (!includedSocialSolutions[id]) includedSocialSolutions[id] = {};

                        var ss: any | null = null;
                        if (profileSolutions) {
                            ss = profileSolutions.find((x: any) => x.recordId.toString() === id.toString());
                        }
                        // console.log('%c ss: %O', 'color: green; font-weight: bold;', ss);

                        if (ss) {
                            if (!ss.permissions) ss.permissions = permissions;
                            includedSocialSolutions[id].permissions = permissions;
                            includedSocialSolutions[id].setting = setting;
                            includedSocialSolutions[id].solution = ss;
                            // ssCount++;
                        }
                    }
                }
            }
            // console.log('%c Me ssCount: %O', 'color: blue;', ssCount);
        }
    }
    // console.log('%c Me includedSocialSolutions: %O', 'color: blue;', includedSocialSolutions);

    const onShowSocialSolution = (ss: any) => {
        // console.log('%c Me onShowSocialSolution ss: %O', 'color: red;', ss);
        setSelectedSocialSolution(ss);
    };

    const closeSocialSolutions = (evt: any) => {
        // console.log('%c closeSocialSolutions evt: %O', 'color: maroon;', evt);
        setSelectedSocialSolution(null);
        setSelectedFeature(null);
    };

    const onShowSocialSolutionFeature = (ss: any, feature: any) => {
        // console.log('%c Me onShowSocialSolutionFeature ss: %O', 'color: red;', ss);
        // console.log('%c Me onShowSocialSolutionFeature sfeatures: %O', 'color: red;', feature);
        setSelectedSocialSolution(ss);
        setSelectedFeature(feature);
    };

    let socialSolutionsMenuElement: any = null;
    if (selectedSocialSolution) {
        var socialSolutionElementProps = {
            solutions: includedSocialSolutions,
            socialSolution: selectedSocialSolution,
            feature: selectedFeature,
            onClose: closeSocialSolutions
        };
        socialSolutionsMenuElement = (
            <Box>
                <SocialSolutions {...socialSolutionElementProps} />
            </Box>
        );
    } else if (includedSocialSolutions) {
        // console.log('%c includedSocialSolutions: %O', 'color: red; font-weight: bold;', includedSocialSolutions);
        var ssListElements: any[] = [];
        forEach(includedSocialSolutions, (ss: any) => {
            // console.log('%c ss: %O', 'color: red; font-weight: bold;', ss);
            // console.log('%c recordId: %O', 'color: hotpink;', recordId);
            var ssSolution: any = ss.solution;
            if (!ssSolution) return true;
            if (ssSolution.recordId === udicci.socialSolutionUdicciIT && !udicci.isCurrentUserUlysses()) return true;

            var socialSolutionCardKey = 'social.solution.card.' + ssSolution.recordId.toString();
            ssListElements.push(
                <SocialSolutionCard key={socialSolutionCardKey}
                                    solution={ssSolution}
                                    onShowSocialSolution={onShowSocialSolution}
                                    onShowSocialSolutionFeature={onShowSocialSolutionFeature}
                />
            );
        });
        // console.log('%c ssListElements.length: %O', 'color: maroon;', ssListElements.length);
        if (ssListElements.length > 0) {
            socialSolutionsMenuElement = (
                <Box>
                    <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="stretch">
                        {ssListElements}
                    </Grid>
                </Box>
            );
        } else {
            socialSolutionsMenuElement = (
                <Box>
                    <Typography variant="errorMessage" component="div">
                        There are no Social Solutions for you to see at this time.
                    </Typography>
                </Box>
            );
        }
    }

    return (<Box> {socialSolutionsMenuElement} </Box>);
};

export default SocialSolutionsMenu;
