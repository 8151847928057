
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';

import { udicciStyles } from 'src/theme/shared-styles';

export const MintMVnfTHelp: React.FC<any> = (props) => {
    // console.log('%c MintMVnfTHelp props: %O', 'color: blue;', props);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);
    return (
        <Box sx={udicciClasses.content}>
            MintMVnfT Help should be implemented here.
        </Box>
    );
};
