
import React, { Fragment, useState } from 'react';

import Pluralize from 'pluralize';

import { forEach } from 'underscore';

import { Box, Typography, Icon, IconButton, Button, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext } from 'src/context/udicci-context'

import MediatorForm from './mediator-form';
import LinkedMediatorForm from './linked-mediator-form';
import MediatorFieldForm from './mediator-field-form';
import { UdicciRecord } from 'src/classes/udicci-record';

const MediatorStructureForm: React.FC<any> = (props: any) => {
    // console.log('%c MediatorStructureForm props: %O', 'color: red;', props);

    const [editMediator, setEditMediator] = useState<any>(null);
    const [editField, setEditField] = useState<any>(null);
    const [editLum, setEditLum] = useState<any>(null);
    // const [showFields, setShowFields] = useState<boolean>(false);
    // const [showLums, setShowLums] = useState<boolean>(false);
    // console.log('%c MediatorStructureForm profileSolutionSettings: %O', 'color: red;', profileSolutionSettings);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();

    let { udicci } = udicciContext.state;

    let { mediator, onClose } = props;
    // console.log('%c MediatorStructureForm mediator: %O', 'color: hotpink;', mediator);

    let structure: any = udicciHelpers.getMediatorStructure(mediator.name);
    // console.log('%c MediatorStructureForm structure: %O', 'color: hotpink;', structure);

    const onChangeMediator = (updatedMediator: any) => {
        // console.log('%c onChangeMediator updatedMediator: %O', 'color: blue;', updatedMediator);
        setEditMediator(updatedMediator);
    };

    const onChangeField = (updatedField: any) => {
        // console.log('%c onChangeField updatedField: %O', 'color: blue;', updatedField);
    };

    const onChangeLum = (updatedLum: any) => {
        // console.log('%c onChangeLum updatedLum: %O', 'color: blue;', updatedLum);
    };

    const onCloseForm = () => {
        setEditLum(null);
        setEditField(null);
        setEditMediator(null);
    };

    const onAddNewField = () => {
        let fieldMediatorStructure: any = udicciHelpers.getMediatorStructure('Udicci Mediator Fields');
        // console.log('%c onAddNewField fieldMediatorStructure: %O', 'color: hotpink;', fieldMediatorStructure);
        let newField: UdicciRecord = new UdicciRecord('Udicci Mediator Fields', {}, fieldMediatorStructure);
        // console.log('%c onAddNewField newField: %O', 'color: maroon;', newField);
        setEditField(newField);
        setEditLum(null);
        setEditMediator(null);
    };

    const onAddNewLum = () => {
        let lumMediatorStructure: any = udicciHelpers.getMediatorStructure('Linked Udicci Mediators');
        // console.log('%c onAddNewLum lumMediatorStructure: %O', 'color: hotpink;', lumMediatorStructure);
        let newLum: UdicciRecord = new UdicciRecord('Linked Udicci Mediators', {}, lumMediatorStructure);
        // console.log('%c onAddNewLum newLum: %O', 'color: maroon;', newLum);
        setEditLum(newLum);
        setEditField(null);
        setEditMediator(null);
    };

    let fieldsCount: number = 0;
    let editFieldFound: boolean = false;
    let fieldElements: any[] = [];
    if (!editMediator && !editLum && structure && structure.UdicciMediatorFields) {
        forEach(structure.UdicciMediatorFields, (field: any, idxField: number) => {
            // console.log('%c field: %O', 'color: maroon;', field);
            if (!field.UdicciMediatorFieldId) return true;
            let isMultilineField: boolean = ((udicci.textAreaFields.indexOf(field.DataType) >= 0) ? true : false);

            let messageElement: any = null;
            if (isMultilineField && !editField) {
                messageElement = (
                    <Typography variant="errorMessage" component="div">
                        Displayed as a MultiLine Field.
                    </Typography>
                );
            }
            if (field.DataType === 'Json' && !editField) {
                messageElement = (
                    <Typography variant="errorMessage" component="div">
                        This field will not be displayed on the form by default.
                    </Typography>
                );
            }

            let title: string = (field.DisplayName ? field.DisplayName : field.Name);
            let descriptionElement: any = null;
            if (field.Overview && field.Overview !== title && !editField) {
                descriptionElement = (
                    <Typography variant="caption" component="div">{field.Overview}</Typography>
                );
            }

            let fieldFormElement: any = null;
            let fieldHeaderElement: any = null;
            if (editField) {
                if (editField.UdicciMediatorFieldId === field.UdicciMediatorFieldId) {
                    editFieldFound = true;
                    fieldHeaderElement = (
                        <Fragment>
                            <IconButton size="small" onClick={(evt: any) => { setEditField(null); }}>
                                <Icon fontSize="small">close</Icon>
                            </IconButton>
                            <Typography variant="subtitle2" component="span">
                                {title}
                            </Typography>
                        </Fragment>
                    );
                    fieldFormElement = (
                        <MediatorFieldForm onClose={() => { onCloseForm() }} onChange={onChangeField} field={editField} structure={structure} />
                    );
                }
            } else {
                fieldHeaderElement = (
                    <Fragment>
                        <IconButton size="small" onClick={(evt: any) => { setEditField(field); }}>
                            <Icon fontSize="small">settings</Icon>
                        </IconButton>
                        <Typography variant="subtitle2" component="span">
                            {title}
                        </Typography>
                        <Typography variant="caption" component="span" sx={{ marginLeft: '4px' }}>
                            ({field.DataType})
                        </Typography>
                    </Fragment>
                );
            }

            if (fieldHeaderElement || messageElement || descriptionElement) {
                let fldKey: string = 'mediator.field.' + field.UdicciMediatorFieldId.toString() + '.' + idxField.toString();
                fieldElements.push(
                    <Box key={fldKey} sx={{ borderBottom: '1px solid #ddd', margin: '8px' }}>
                        {fieldHeaderElement}
                        {fieldFormElement}
                        {messageElement}
                        {descriptionElement}
                    </Box>
                );
            }
            fieldsCount++;
        });
    }

    let fieldListElement: any = null;
    if (!editMediator && !editLum) {
        if (fieldsCount > 0) {
            let addNewFieldForm: any = null;
            let addNewFieldButton: any = null;
            if (editField && !editFieldFound) {
                addNewFieldForm = (
                    <MediatorFieldForm onClose={() => { onCloseForm() }} onChange={onChangeField} field={editField} structure={structure} />
                );
            } else if (!editField) {
                addNewFieldButton = (
                    <Box sx={{ padding: '8px' }}>
                        <Button size="small" variant="contained" color="secondary" onClick={(evt: any) => { onAddNewField(); }}>
                            <Icon fontSize="small" sx={{ marginRight: '4px' }}>add</Icon>
                            Add a new Field to {structure.Name}
                        </Button>
                    </Box>
                );
            }
            fieldListElement = (
                <Box>
                    <Accordion sx={{ background: 'unset' }}>
                        <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                            <Typography variant="caption" component="span" sx={{ marginLeft: '4px' }}>
                                {(editField ? "field settings:" : "fields:")}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {fieldElements}
                            {addNewFieldForm}
                            {addNewFieldButton}
                        </AccordionDetails>
                    </Accordion>
                </Box>
            );
        } else if (structure) {
            fieldListElement = (
                <Box>
                    <Typography variant="errorMessage" component="span" sx={{ marginLeft: '4px' }}>
                        no fields to show for this structure at this time.
                    </Typography>
                </Box>
            );
        } else {
            fieldListElement = (
                <Box>
                    <Typography variant="errorMessage" component="span" sx={{ marginLeft: '4px' }}>
                        structure is being loaded.  please wait a moment.
                    </Typography>
                </Box>
            );
        }
    }

    let relatedMediatorsCount: number = 0;
    let editLumFound: boolean = false;
    let relatedMediatorElements: any[] = [];
    if (!editMediator && structure && structure.LinkedUdicciMediators) {
        forEach(structure.LinkedUdicciMediators, (lum: any, idxLum: number) => {
            // console.log('%c lum: %O', 'color: maroon;', lum);
            if (!lum.LinkedUdicciMediatorId) return true;

            let leftMediator: string = (lum.LeftUdicciMediatorName ? lum.LeftUdicciMediatorName : '');
            let rightMediator: string = (lum.RightUdicciMediatorName ? lum.RightUdicciMediatorName : '');

            let leftMediatorSingle: string = Pluralize.singular(leftMediator);
            let rightMediatorSingle: string = Pluralize.singular(rightMediator);

            let structureIsParent: boolean = (lum.LeftUdicciMediatorName === structure.Name ? true : false);
            let structureIsChild: boolean = (lum.RightUdicciMediatorName === structure.Name ? true : false);
            let leftMax: number = (lum.MaxAssociationsToLeft !== undefined ? lum.MaxAssociationsToLeft : 1);
            let rightMax: number = (lum.MaxAssociationsToRight !== undefined ? lum.MaxAssociationsToRight : 1);

            let leftHighlightStyle: any = { textDecoration: 'underline', marginRight: '2px' };
            let rightHighlightStyle: any = { textDecoration: 'underline', marginRight: '2px' };
            if (structureIsParent) {
                leftHighlightStyle.fontWeight = 'bold';
                rightHighlightStyle.fontStyle = 'italic';
            } else if (structureIsChild) {
                leftHighlightStyle.fontStyle = 'italic';
                rightHighlightStyle.fontWeight = 'bold';
            }
            
            let relationshipTypeElement: any = null;
            if (leftMax === 1 && rightMax === 1) {
                relationshipTypeElement = (
                    <Typography variant="caption" component="div">
                        For this relationship, Each <span style={leftHighlightStyle}>{leftMediatorSingle}</span> 
                        can Only be related to 1 <span style={rightHighlightStyle}>{rightMediatorSingle},</span> 
                        and Each <span style={rightHighlightStyle}>{rightMediatorSingle}</span> 
                        can Only be related to 1 <span style={leftHighlightStyle}>{leftMediatorSingle}</span>
                        ... aka 1 to 1
                    </Typography>
                );
            } else if (leftMax === -1 && rightMax === -1) {
                relationshipTypeElement = (
                    <Typography variant="caption" component="div">
                        For this relationship, Each <span style={leftHighlightStyle}>{leftMediatorSingle}</span>
                        can be related to None, One or Many <span style={rightHighlightStyle}>{rightMediator},</span>
                        and Each <span style={rightHighlightStyle}>{rightMediatorSingle}</span>
                        can be related to any number of <span style={leftHighlightStyle}>{leftMediator}</span>
                        ... aka Many to Many
                    </Typography>
                );
            } else {
                relationshipTypeElement = (
                    <Typography variant="caption" component="div">
                        For this relationship, Each <span style={leftHighlightStyle}>{leftMediatorSingle}</span>
                        can be related to None, One or Many <span style={rightHighlightStyle}>{rightMediator},</span>
                        and Each <span style={rightHighlightStyle}>{rightMediatorSingle}</span>
                        can Only be related to 1 <span style={leftHighlightStyle}>{leftMediatorSingle}</span>
                        ... aka 1 to Many
                    </Typography>
                );
            }

            let relationshipElement: any = null;
            if (!editLum) {
                if (structureIsParent) {
                    relationshipElement = (
                        <Fragment>
                            <Typography variant="overFieldLabel" component="span" sx={{ marginLeft: '4px' }}>is a Child Relationship for</Typography>
                            <Typography variant="overFieldLabel" component="span" sx={{ marginLeft: '4px', fontStyle: 'italic', textDecoration: 'underline' }}>{lum.RightUdicciMediatorName}</Typography>
                            {relationshipTypeElement}
                        </Fragment>
                    );
                } else if (structureIsChild) {
                    relationshipElement = (
                        <Fragment>
                            <Typography variant="overFieldLabel" component="span" sx={{ marginLeft: '4px' }}>is a Parent Relationship for</Typography>
                            <Typography variant="overFieldLabel" component="span" sx={{ marginLeft: '4px', fontStyle: 'italic', textDecoration: 'underline' }}>
                                {lum.LeftUdicciMediatorName}
                            </Typography>
                            {relationshipTypeElement}
                        </Fragment>
                    );
                }
            }

            let lumFormElement: any = null;
            let lumHeaderElement: any = null;
            if (editLum) {
                if (editLum.LinkedUdicciMediatorId === lum.LinkedUdicciMediatorId) {
                    editLumFound = true;
                    lumHeaderElement = (
                        <Fragment>
                            <IconButton size="small" onClick={(evt: any) => { setEditLum(null); }}>
                                <Icon fontSize="small">close</Icon>
                            </IconButton>
                            <Typography variant="subtitle2" component="span">
                                {lum.Name}
                            </Typography>
                        </Fragment>
                    );
                    lumFormElement = (
                        <LinkedMediatorForm onClose={() => { onCloseForm() }} onChange={onChangeLum} lum={lum} structure={structure} />
                    );
                }
            } else {
                lumHeaderElement = (
                    <Fragment>
                        <IconButton size="small" onClick={(evt: any) => { setEditLum(lum); }}>
                            <Icon fontSize="small">settings</Icon>
                        </IconButton>
                        <Typography variant="subtitle2" component="span">
                            {lum.Name}
                        </Typography>
                    </Fragment>
                );
            }

            if (lumHeaderElement || relationshipElement || lumFormElement) {
                let lumKey: string = 'linked.udicci.mediator.' + lum.LinkedUdicciMediatorId.toString() + '.' + idxLum.toString();
                relatedMediatorElements.push(
                    <Box key={lumKey} sx={{ borderBottom: '1px solid #ddd', padding: '4px' }}>
                        {lumHeaderElement}
                        {relationshipElement}
                        {lumFormElement}
                    </Box>
                );
            }
            relatedMediatorsCount++;
        });
    }

    let lumListElement: any = null;
    if (!editMediator && !editField) {
        if (relatedMediatorsCount > 0) {
            let addNewLumForm: any = null;
            let addNewLumButton: any = null;
            if (editLum && !editLumFound) {
                addNewLumForm = (
                    <LinkedMediatorForm onClose={() => { onCloseForm() }} onChange={onChangeLum} lum={editLum} structure={structure} />
                );
            } else if (!editLum) {
                addNewLumButton = (
                    <Box sx={{ padding: '8px' }}>
                        <Button size="small" variant="contained" color="secondary" onClick={(evt: any) => { onAddNewLum(); }}>
                            <Icon fontSize="small" sx={{ marginRight: '4px' }}>add</Icon>
                            Add a new Linked Udicci Mediator for {structure.Name}
                        </Button>
                    </Box>
                );
            }
            lumListElement = (
                <Box sx={{ marginTop: '8px' }}>
                    <Accordion sx={{ background: 'unset' }}>
                        <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                            <Typography variant="caption" component="span" sx={{ marginLeft: '4px' }}>
                                {(editLum ? "relationship settings:" : "mediators related to " + structure.Name + ":")}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {relatedMediatorElements}
                            {addNewLumForm}
                            {addNewLumButton}
                        </AccordionDetails>
                    </Accordion>
                </Box>
            );
        } else if (structure) {
            lumListElement = (
                <Box sx={{ marginTop: '8px' }}>
                    <Typography variant="errorMessage" component="span" sx={{ marginLeft: '4px' }}>
                        no related mediators to show for {structure.Name} at this time.
                    </Typography>
                </Box>
            );
        }
    }

    let mediatorOverViewElement: any = null;
    if (!editMediator && structure && structure.Overview && structure.Overview !== structure.Name) {
        mediatorOverViewElement = (<Typography variant="caption" component="div">{structure.Overview}</Typography>);
    }

    let mediatorFormElement: any = null;
    let mediatorIcon: any = null;
    if (editMediator) {
        mediatorIcon = (
            <IconButton size="small" onClick={(evt: any) => { setEditMediator(null) }}>
                <Icon fontSize="small">close</Icon>
            </IconButton>
        );
        mediatorFormElement = (
            <MediatorForm onClose={() => { onCloseForm() }} onChange={onChangeMediator} mediator={editMediator} structure={structure} />
        );
    } else {
        mediatorIcon = (
            <IconButton size="small" onClick={(evt: any) => { setEditMediator(structure) }}>
                <Icon fontSize="small">settings</Icon>
            </IconButton>
        );
    }

    return (
        <Box sx={{ padding: '16px', background: 'rgba(255, 255, 255, 0.1)', border: '1px dashed #4C6FB1' }}>
            <Box sx={{ borderBottom: '1px solid #ddd' }}>
                <Box>
                    <Typography variant="subtitle2" component="span">{mediator.name}</Typography>
                    {mediatorIcon}
                </Box>
                {mediatorOverViewElement}
            </Box>
            {mediatorFormElement}
            {fieldListElement}
            {lumListElement}
        </Box>
    );
}

export default MediatorStructureForm;
