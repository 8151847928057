
import { useEffect, useCallback, useRef } from "react"

import { useUdicciContext } from 'src/context/udicci-context';

export default function useUdicciMediator( mediatorName: string ) {
    // console.log('%c useUdicciMediator mediatorName: %O', 'color: red;', mediatorName);

    const udicciContext = useUdicciContext();
    var { data } = udicciContext.state;

    var mediatorContext = data.find((x: any) => x.mediator === mediatorName );
    var mediatorStructure = (mediatorContext && mediatorContext.structure ? mediatorContext.structure : null);
    // console.log('%c useUdicciMediator mediatorStructure: %O', 'color: maroon;', mediatorStructure);

    const refUdicciMediator = useRef<any>(mediatorStructure);
    // console.log('%c useUdicciMediator refUdicciMediator: %O', 'color: red;', refUdicciMediator);

    useEffect(() => {
        var mediatorContext = data.find((x: any) => x.mediator === mediatorName );
        // console.log('%c useUdicciMediator mediatorContext: %O', 'color: maroon;', mediatorContext);
        var mediatorStructure = (mediatorContext && mediatorContext.structure ? mediatorContext.structure : null);
        // console.log('%c useUdicciMediator mediatorStructure: %O', 'color: maroon;', mediatorStructure);
        refUdicciMediator.current = mediatorStructure;
    }, [ mediatorName, data ]);

    const setMediator = useCallback((structure: any) => {
        refUdicciMediator.current = structure;
    }, []);

    const getField = (fieldJsonKey: string) => {
        // console.log('%c useUdicciMediator getField fieldJsonKey: %O', 'color: blue;', fieldJsonKey);
        var m: any = refUdicciMediator.current;
        // console.log('%c useUdicciMediator getField m: %O', 'color: blue;', m);
        var mf: any = (m && m.UdicciMediatorFields ? m.UdicciMediatorFields : null);
        // console.log('%c useUdicciMediator getField mediatorFields: %O', 'color: blue;', mf);
        if (!mf) return null;

        var field = mf.find((f: any) => f.JsonFieldName === fieldJsonKey );
        // console.log('%c useUdicciMediator getField field: %O', 'color: maroon;', field);
        return field;
    };

    return {
        setMediator, getField,
        udicciMediator: refUdicciMediator.current
    };
}

/**
 * 
 * Usage:
 * 
 */
