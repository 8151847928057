
import { useTheme } from '@mui/material/styles';

import { find, values } from 'underscore';

import Box from '@mui/material/Box';
import Portal from '@mui/material/Portal';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { udicciStyles } from 'src/theme/shared-styles';

import { useUdicciContext } from 'src/context/udicci-context';
import { checkConditionAgainstRecord } from 'src/classes/udicci-record';
import { getEngagementActionPackages } from 'src/components/engagement-actions/engagement-actions';

const RecordEngagement: React.FC<any> = (props) => {
    // console.log('%c RecordEngagement props: %O', 'color: purple;', props);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const udicciContext = useUdicciContext();
    // console.log('%c RecordEngagement udicciContext: %O', 'color: red;', udicciContext);

    let { data } = udicciContext.state;
    // console.log('%c RecordEngagement data: %O', 'color: maroon;', data);

    let { record, engagementAction } = props;
    // console.log('%c RecordEngagement record: %O', 'color: purple;', record);
    // console.log('%c RecordEngagement engagementAction: %O', 'color: purple;', engagementAction);

    let actionProps: any = {};
    Object.assign(actionProps, props);
    // console.log('%c actionProps: %O', 'color: red;', actionProps);

    let engagementActionPackages: any = getEngagementActionPackages(actionProps);
    // console.log('%c engagementActionPackages: %O', 'color: maroon;', engagementActionPackages);

    let udicciMediator: string = (record.udicciMediator ? record.udicciMediator : '');
    // console.log('%c RecordEngagement udicciMediator: %O', 'color: maroon;', udicciMediator);
    // let permissions: any = (record.permissions ? record.permissions : null);
    // console.log('%c RecordEngagement permissions: %O', 'color: maroon;', permissions);

    let mediatorContext = data.find((x: any) => x.mediator === udicciMediator );
    // console.log('%c RecordEngagement mediatorContext: %O', 'color: maroon;', mediatorContext);

    let action: any = null;
    if (engagementAction && engagementAction.action) {
        if (engagementActionPackages && engagementActionPackages[engagementAction.action]) {
            action = engagementActionPackages[engagementAction.action];
        }
    }
    // console.log('%c RecordEngagement action: %O', 'color: purple;', action);

    let conditionalOverride = (engagementAction && engagementAction.conditionalOverride ? engagementAction.conditionalOverride : null);
    // console.log('%c conditionalOverride: %O', 'color: maroon;', conditionalOverride);

    if (conditionalOverride && conditionalOverride.action) {
        let conditionWhen = (conditionalOverride.when ? conditionalOverride.when : null);
        // console.log('%c conditionWhen: %O', 'color: maroon;', conditionWhen);
        let conditionMet = checkConditionAgainstRecord(record, conditionWhen, mediatorContext);
        // console.log('%c conditionMet: %O', 'color: maroon;', conditionMet);
        if (conditionMet) {
            if (engagementActionPackages && engagementActionPackages[conditionalOverride.action]) {
                action = engagementActionPackages[conditionalOverride.action];
            }
        }
    }
    // console.log('%c RecordEngagement action: %O', 'color: purple;', action);

    let actionElement: any = (action && action.ui ? action.ui : null);
    let uiTitle: any = (action && action.uiTitle ? action.uiTitle : null);
    let actionButtons: any = (action && action.actionButtons ? action.actionButtons : null);

    let dialogTitleElement: any = null;
    if (uiTitle) dialogTitleElement = ( <DialogTitle> {uiTitle} </DialogTitle> );

    let dialogActionsElement: any = null;
    if (actionButtons) dialogActionsElement = ( <DialogActions> {actionButtons} </DialogActions> );

    let recordEngagementElement: any = null;
    if (props && props.inline) {
        recordEngagementElement = (
            <Box>
                {dialogTitleElement}
                <Box>
                    {actionElement}
                </Box>
                {dialogActionsElement}
            </Box>
        );
    } else {
        recordEngagementElement = (
            <Portal>
                <Dialog open={true} fullWidth={true} maxWidth={'md'}>
                    {dialogTitleElement}
                    <DialogContent classes={{ root: 'contentArea' }}>
                        {actionElement}
                    </DialogContent>
                    {dialogActionsElement}
                </Dialog>
            </Portal>
        );
    }

    return recordEngagementElement;
}

const EngagementMenuItem: React.FC<any> = (props) => {
    // console.log('%c EngagementMenuItem props: %O', 'color: purple;', props);

    const udicciContext = useUdicciContext();
    // console.log('%c EngagementMenuItem udicciContext: %O', 'color: red;', udicciContext);

    let { data } = udicciContext.state;
    // console.log('%c EngagementMenuItem data: %O', 'color: maroon;', data);

    let actionProps: any = {};
    Object.assign(actionProps, props);
    // console.log('%c actionProps: %O', 'color: red;', actionProps);
    let engagementActionPackages: any = getEngagementActionPackages(actionProps);
    // console.log('%c EngagementMenuItem engagementActionPackages: %O', 'color: maroon;', engagementActionPackages);

    // let { selectedPorta, showDesignerMenu } = udicci;
    let { record, engagementAction } = props;
    // console.log('%c EngagementMenuItem engagementAction: %O', 'color: maroon;', engagementAction);
    // console.log('%c EngagementMenuItem record: %O', 'color: maroon;', record);

    let eaAccess = (engagementAction.access ? engagementAction.access : null);
    let eaMediators = (eaAccess && eaAccess.mediators ? eaAccess.mediators : []);
    let showWhen = (engagementAction && engagementAction.showWhen ? engagementAction.showWhen : null);
    let showForAllMediators = (eaAccess && eaAccess.showForAllMediators ? true : false);
    let accessRequiredPermissions = (eaAccess && eaAccess.requiredPermissions ? eaAccess.requiredPermissions : null);

    let eaAction = (engagementAction && engagementAction.action ? engagementAction.action : null);
    let conditionalOverride = (engagementAction && engagementAction.conditionalOverride ? engagementAction.conditionalOverride : null);

    let udicciMediator: string = (record.udicciMediator ? record.udicciMediator : '');
    let permissions: any = (record.permissions ? record.permissions : null);

    let mediatorContext = data.find((x: any) => x.mediator === udicciMediator );
    let mediatorActions = find(values(eaMediators), function(m) {
        return m.name === udicciMediator;
    });
    // console.log('%c mediatorActions: %O', 'color: blue;', mediatorActions);
    let requiredPermissions = (mediatorActions && mediatorActions.requiredPermissions ? mediatorActions.requiredPermissions : accessRequiredPermissions);
    // console.log('%c requiredPermissions: %O', 'color: blue;', requiredPermissions);

    let allowed = (showForAllMediators || mediatorActions !== undefined ? true : false);
    if (permissions && requiredPermissions && requiredPermissions.length > 0) {
        allowed = false;
        let fp = requiredPermissions[0];
        // console.log('%c fp: %O', 'color: blue;', fp);
        let comparePerms: any = permissions
        let permValue = comparePerms[fp.permission];
        // console.log('%c permValue: %O', 'color: blue;', permValue);
        if (fp.required && permValue === true) allowed = true;
        if (!fp.required) allowed = true;
    }

    let showAction = false;
    if (allowed && record) {
        showAction = checkConditionAgainstRecord(record, showWhen, mediatorContext);
    }

    if (allowed && showAction) {
        let action: any = null;
        if (eaAction) {
            if (engagementActionPackages && engagementActionPackages[eaAction]) {
                action = engagementActionPackages[eaAction];
            }
        }
        // console.log('%c EngagementMenuItem action: %O', 'color: red;', action);

        if (conditionalOverride && conditionalOverride.action) {
            let conditionWhen = (conditionalOverride.when ? conditionalOverride.when : null);
            // console.log('%c EngagementMenuItem conditionWhen: %O', 'color: maroon;', conditionWhen);
            let conditionMet = checkConditionAgainstRecord(record, conditionWhen, mediatorContext);
            // console.log('%c EngagementMenuItem conditionMet: %O', 'color: maroon;', conditionMet);
            if (conditionMet) {
                if (engagementActionPackages && engagementActionPackages[conditionalOverride.action]) {
                    action = engagementActionPackages[conditionalOverride.action];
                }
            }
        }
        // console.log('%c EngagementMenuItem action: %O', 'color: purple;', action);

        let menu: any = (action && action.menu ? action.menu : null);
        // console.log('%c EngagementMenuItem menu: %O', 'color: purple;', menu);
        return menu;
    } else {
        return null;
    }
}

export { RecordEngagement, EngagementMenuItem };
