
import React, { useState, Fragment } from 'react';

import { forEach } from 'underscore';

import {
    Box, Paper, Typography, MenuItem, TextField, Select, FormControl, InputLabel, IconButton, Icon,
    Checkbox, FormControlLabel, Table, TableHead, TableBody, TableRow, TableCell,
    AppBar, Tabs, Tab, Button
} from '@mui/material';

import useUdicciHelpers, { TabPanel, VerticalTabPanel } from 'src/hooks/useUdicciHelpers';

import FieldFilter from './field-filter';
import ActionExchange from './action-exchange';

const EngagementActionForm: React.FC<any> = (props) => {
    // console.log('%c EngagementActionForm props: %O', 'color: maroon;', props);

    let { engagementAction, onChange, onApply, onClose } = props;
    // console.log('%c EngagementActionForm engagementAction: %O', 'color: maroon;', engagementAction);

    const [tabIndex, setTabIndex] = useState<number>(0);
    const [mediatorIndex, setMediatorIndex] = useState<number>(0);
    const [editAction, setEditAction] = useState<any>(engagementAction);
    // console.log('%c EngagementActionsConfiguration editAction: %O', 'color: red;', editAction);
    const [editConditionIndex, setEditConditionIndex] = useState<number>(-1);
    const [editCondition, setEditCondition] = useState<any>(null);
    const [uiState, flashUI] = useState<boolean>(false);

    const udicciHelpers = useUdicciHelpers();

    const handleTabChange = (event: any, value: number) => {
        setTabIndex(value);
    };

    const changeValue = (fieldName: string, path: string, evt: any) => {
        // console.log('%c changeValue fieldName: %O', 'color: maroon;', fieldName);
        // console.log('%c changeValue path: %O', 'color: maroon;', path);
        let trgt: any = evt.target;
        let newValue: any = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c newValue: %O', 'color: maroon;', newValue);

        if (fieldName === 'showWhen') {
            let newShowWhen: any = { field: '', comparison: '', value: null, alwaysShown: false };
            switch (newValue) {
                case '':
                    newShowWhen.alwaysShown = true;
                    break;
                case 'not.udicciRecordId':
                    newShowWhen.field = 'udicciRecordId';
                    newShowWhen.comparison = 'Equals';
                    newShowWhen.value = 0;
                    break;
                case 'udicciRecordId':
                    newShowWhen.field = 'udicciRecordId';
                    newShowWhen.comparison = 'Greater Than';
                    newShowWhen.value = 0;
                    break;
                case 'not.isDirty':
                    newShowWhen.field = 'isDirty';
                    newShowWhen.comparison = 'Equals';
                    newShowWhen.value = false;
                    break;
                case 'isDirty':
                    newShowWhen.field = 'isDirty';
                    newShowWhen.comparison = 'Equals';
                    newShowWhen.value = true;
                    break;
                default:
                    break;
            }
            // console.log('%c newShowWhen: %O', 'color: blue;', newShowWhen);
            editAction.showWhen = newShowWhen;
        } else if (fieldName === 'enableWhen') {
            let newEnableWhen: any = { field: '', comparison: '', value: null, alwaysEnabled: false };
            switch (newValue) {
                case '':
                    newEnableWhen.alwaysEnabled = true;
                    break;
                case 'not.udicciRecordId':
                    newEnableWhen.field = 'udicciRecordId';
                    newEnableWhen.comparison = 'Equals';
                    newEnableWhen.value = 0;
                    break;
                case 'udicciRecordId':
                    newEnableWhen.field = 'udicciRecordId';
                    newEnableWhen.comparison = 'Greater Than';
                    newEnableWhen.value = 0;
                    break;
                case 'not.isDirty':
                    newEnableWhen.field = 'isDirty';
                    newEnableWhen.comparison = 'Equals';
                    newEnableWhen.value = false;
                    break;
                case 'isDirty':
                    newEnableWhen.field = 'isDirty';
                    newEnableWhen.comparison = 'Equals';
                    newEnableWhen.value = true;
                    break;
                default:
                    break;
            }
            // console.log('%c newEnableWhen: %O', 'color: blue;', newEnableWhen);
            editAction.enableWhen = newEnableWhen;
        } else if (!path) {
            editAction[fieldName] = newValue;
        } else {
            let useConditional: boolean = (path.indexOf('conditionalOverride.') === 0 ? true : false);
            if (useConditional) {
                let condOverParts: string[] = path.split('.');
                let pathName: string = (condOverParts.length > 1 ? condOverParts[1] : '');
                // console.log('%c pathName: %O', 'color: blue;', pathName);
                let settingName: string = (condOverParts.length > 2 ? condOverParts[2] : pathName);
                // console.log('%c settingName: %O', 'color: blue;', settingName);
                let conditionalOverride: any = (editAction.conditionalOverride ? editAction.conditionalOverride : {});
                let conditionSetting: any = {};
                // let conditionSetting = (conditionalOverride && conditionalOverride[settingName] ? conditionalOverride[settingName] : {});
                if (pathName !== settingName) {
                    if (!conditionalOverride[pathName]) conditionalOverride[pathName] = {};
                    // conditionalOverride[pathName][settingName] = conditionSetting;
                    conditionSetting = (conditionalOverride && conditionalOverride[pathName] && conditionalOverride[settingName] ? conditionalOverride[pathName][settingName] : {});
                } else {
                    conditionSetting = (conditionalOverride && conditionalOverride[settingName] ? conditionalOverride[settingName] : {});
                }
                if (settingName === 'when') conditionSetting = convertOverrideWhenObjectToArray(conditionSetting);

                // console.log('%c conditionSetting: %O', 'color: blue;', conditionSetting);
                let conditionToEdit: any = conditionSetting[editConditionIndex];
                // console.log('%c conditionToEdit: %O', 'color: blue;', conditionToEdit);

                if (!conditionToEdit) conditionToEdit = {};

                conditionToEdit[fieldName] = newValue;
                // console.log('%c conditionToEdit: %O', 'color: blue;', conditionToEdit);

                if (pathName !== settingName) {
                    conditionSetting = conditionToEdit;
                    // console.log('%c conditionSetting: %O', 'color: purple;', conditionSetting);

                    if (!conditionalOverride[pathName]) conditionalOverride[pathName] = {};
                    conditionalOverride[pathName][settingName] = conditionSetting;
                } else {
                    if (settingName === 'when') 
                        conditionSetting[editConditionIndex] = conditionToEdit;
                    else
                        conditionSetting = conditionToEdit;

                    // console.log('%c conditionSetting: %O', 'color: purple;', conditionSetting);
                    conditionalOverride[settingName] = conditionSetting;
                }
                // console.log('%c conditionalOverride: %O', 'color: green;', conditionalOverride);
                editAction.conditionalOverride = conditionalOverride;
            } else {
                if (!editAction[path]) editAction[path] = {};
                editAction[path][fieldName] = newValue;
            }
        }
        // console.log('%c editAction: %O', 'color: blue;', editAction);

        editAction.isDirty = true;
        if (onChange) onChange(editAction);

        setEditAction(editAction);
        flashUI(!uiState);
    };

    const convertOverrideWhenObjectToArray = (overrideWhen: any) => {
        if (overrideWhen) {
            // make sure it is an array... if not, make it so
            if (overrideWhen instanceof Array) {
                // we are ok, don't need to change anything
            } else {
                let overrideWhenHold: any = {};
                Object.assign(overrideWhenHold, overrideWhen);
                overrideWhen = [];
                overrideWhenHold.conditionGroup = null;
                overrideWhenHold.order = 1;
                if (!overrideWhenHold.dataType) overrideWhenHold.dataType = '';
                overrideWhen.push(overrideWhenHold);
            }
        } else {
            overrideWhen = [];
        }
        // console.log('%c overrideWhen: %O', 'color: purple;', overrideWhen);
        return overrideWhen;
    }

    const closeEngagementAction = () => {
        if (onClose) onClose();
    }

    const updateFilterSettings = (conditionIndex: number, condition: any, filterSettings: any) => {
        // console.log('%c updateFilterSettings filterSettings: %O', 'color: purple;', filterSettings);
        // console.log('%c updateFilterSettings conditionIndex: %O', 'color: purple;', conditionIndex);
        // console.log('%c updateFilterSettings condition: %O', 'color: purple;', condition);

        let conditionalOverride: any = (editAction.conditionalOverride ? editAction.conditionalOverride : {});
        let conditionalOverrideWhen: any = (conditionalOverride.when ? conditionalOverride.when : {});

        conditionalOverrideWhen = convertOverrideWhenObjectToArray(conditionalOverrideWhen);
        // console.log('%c conditionalOverrideWhen: %O', 'color: purple;', conditionalOverrideWhen);

        let whenCondition: any = (conditionalOverrideWhen.length > editConditionIndex ? conditionalOverrideWhen[editConditionIndex] : null);
        // console.log('%c whenCondition: %O', 'color: purple;', whenCondition);
        if (whenCondition && filterSettings) {
            Object.entries(filterSettings).forEach(([key, value]) => {
                whenCondition[key] = value;
            });

            conditionalOverrideWhen[editConditionIndex] = whenCondition;
        }

        // console.log('%c conditionalOverrideWhen: %O', 'color: blue;', conditionalOverrideWhen);
        conditionalOverride.when = conditionalOverrideWhen;
        editAction.conditionalOverride = conditionalOverride;

        // console.log('%c editAction: %O', 'color: blue;', editAction);
        setEditAction(editAction);
        flashUI(!uiState);
    };

    const addMediatorAccess = (evt: any) => {
        let access: any = (editAction.access ? editAction.access : {});
        let accessMediators: any[] = (access && access.mediators ? access.mediators : []);

        accessMediators.push({
            enableTrigger: true,
            name: '',
            requiredPermissions: []
        });

        access.mediators = accessMediators;
        editAction.access = access;

        setEditAction(editAction);
        flashUI(!uiState);
    }

    const changeMediatorWithAccess = (mediatorIndex: number, evt: any) => {
        let trgt: any = evt.target;
        let newValue: any = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);

        let access: any = (editAction.access ? editAction.access : {});
        let accessMediators: any[] = (access && access.mediators ? access.mediators : []);

        if (!accessMediators[mediatorIndex]) {
            accessMediators[mediatorIndex] = {
                enableTrigger: true,
                name: '',
                requiredPermissions: []
            };
        }
        accessMediators[mediatorIndex].name = newValue;

        access.mediators = accessMediators;
        editAction.access = access;

        setEditAction(editAction);
        flashUI(!uiState);
    }

    const removeMediatorAccess = (mediatorIndex: number, evt: any) => {
        // console.log('%c removeMediatorAccess mediatorIndex: %O', 'color: purple;', mediatorIndex);
        let access: any = (editAction.access ? editAction.access : {});
        let accessMediators: any[] = (access && access.mediators ? access.mediators : []);
        // console.log('%c accessMediators: %O', 'color: purple;', accessMediators);

        let newAccessMediators: any[] = [];
        forEach(accessMediators, (med: any, idx: number) => {
            if (mediatorIndex !== idx) newAccessMediators.push(med);
        });
        // console.log('%c newAccessMediators: %O', 'color: purple;', newAccessMediators);

        access.mediators = newAccessMediators;
        editAction.access = access;

        setEditAction(editAction);
        flashUI(!uiState);
    }

    const onRequiredPermissionChanged = (mediatorIndex: number, requiredPermissionIndex: number, fieldName: string, evt: any) => {
        let trgt: any = evt.target;
        let newValue: any = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);

        let access: any = (editAction.access ? editAction.access : {});
        let accessMediators: any[] = (access && access.mediators ? access.mediators : []);
        let am: any = accessMediators[mediatorIndex];
        let requiredPermissions: any[] = (am && am.requiredPermissions ? am.requiredPermissions : []);
        if (requiredPermissions[requiredPermissionIndex].when !== undefined) {
            delete requiredPermissions[requiredPermissionIndex]['when'];
        }
        requiredPermissions[requiredPermissionIndex][fieldName] = newValue;
        am.requiredPermissions = requiredPermissions;
        accessMediators[mediatorIndex] = am;
        access.mediators = accessMediators;
        editAction.access = access;

        setEditAction(editAction);
        flashUI(!uiState);
    }

    const removeRequiredPermission = (mediatorIndex: number, permissionIndex: number, evt: any) => {
        // console.log('%c mediatorIndex: %O', 'color: purple;', mediatorIndex);
        // console.log('%c permissionIndex: %O', 'color: purple;', permissionIndex);

        let access = (editAction.access ? editAction.access : {});
        let accessMediators = (access && access.mediators ? access.mediators : []);

        let am = accessMediators[mediatorIndex];
        let requiredPermissions = (am && am.requiredPermissions ? am.requiredPermissions : []);

        let newRequiredPermissions: any[] = [];
        forEach(requiredPermissions, (rp: any, idx: number) => {
            if (permissionIndex !== idx) newRequiredPermissions.push(rp);
        });

        am.requiredPermissions = newRequiredPermissions;
        accessMediators[mediatorIndex] = am;
        access.mediators = accessMediators;
        editAction.access = access;

        setEditAction(editAction);
        flashUI(!uiState);
    }

    const addRequiredPermissionWhen = (mediatorIndex: number, evt: any) => {
        // console.log('%c addRequiredPermissionWhen mediatorIndex: %O', 'color: purple;', mediatorIndex);

        let access: any = (editAction.access ? editAction.access : {});
        let accessMediators: any[] = (access && access.mediators ? access.mediators : []);
        // console.log('%c addRequiredPermissionWhen accessMediators: %O', 'color: purple;', accessMediators);
        let am: any = (accessMediators[mediatorIndex] ? accessMediators[mediatorIndex] : {});
        // console.log('%c addRequiredPermissionWhen am: %O', 'color: purple;', am);
        let requiredPermissions: any[] = (am && am.requiredPermissions ? am.requiredPermissions : []);
        requiredPermissions.push({ permission: '', required: true, when: null });
        am.requiredPermissions = requiredPermissions;
        accessMediators[mediatorIndex] = am;
        access.mediators = accessMediators;
        editAction.access = access;
        // console.log('%c addRequiredPermissionWhen editAction: %O', 'color: purple;', editAction);

        setEditAction(editAction);
        flashUI(!uiState);
    }

    const editConditionalOverrideCondition = (condition: any, conditionIndex: number) => {
        // console.log('%c editConditionalOverrideCondition condition: %O', 'color: blue;', condition);
        // console.log('%c editConditionalOverrideCondition conditionIndex: %O', 'color: blue;', conditionIndex);
        if (conditionIndex === undefined || conditionIndex < 0) conditionIndex = 0;
        setEditConditionIndex(conditionIndex);
        setEditCondition(condition);
    }

    const closeConditionalOverrideCondition = () => {
        setEditConditionIndex(-1);
        setEditCondition(null);
    }

    const addConditionalOverrideCondition = (conditionIndex: number | undefined = undefined) => {
        let conditionalOverride: any = (editAction && editAction.conditionalOverride ? editAction.conditionalOverride : {});
        // console.log('%c conditionalOverride: %O', 'color: purple;', conditionalOverride);

        let overrideWhen: any = (conditionalOverride && conditionalOverride.when ? conditionalOverride.when : []);
        // console.log('%c overrideWhen: %O', 'color: purple;', overrideWhen);
        overrideWhen = convertOverrideWhenObjectToArray(overrideWhen);

        let newConditionIndex: number = (conditionIndex !== undefined && conditionIndex >= 0 ? conditionIndex : (overrideWhen.length - 1));
        newConditionIndex++;
        let newCondition: any = {
            conditionGroup: null,
            conditionToCheck: '',
            dataType: '',
            jsonKey: '',
            jsonPath: '',
            valueToCompare: '',
            order: newConditionIndex
        };
        overrideWhen.splice(newConditionIndex, 0, newCondition);
        // console.log('%c newCondition: %O', 'color: blue;', newCondition);
        // console.log('%c overrideWhen: %O', 'color: blue;', overrideWhen);
        conditionalOverride.when = overrideWhen;
        // console.log('%c conditionalOverride: %O', 'color: blue;', conditionalOverride);
        editAction.conditionalOverride = conditionalOverride;
        // console.log('%c newConditionIndex: %O', 'color: purple;', newConditionIndex);

        setEditConditionIndex(newConditionIndex);
        setEditCondition(newCondition);
        setEditAction(editAction);
        flashUI(!uiState);
    }

    const removeConditionalOverrideCondition = (conditionIndex: number) => {
        // console.log('%c editAction: %O', 'color: purple;', editAction);
        let conditionalOverride = (editAction && editAction.conditionalOverride ? editAction.conditionalOverride : null);
        // console.log('%c conditionalOverride: %O', 'color: purple;', conditionalOverride);

        let overrideWhen = (conditionalOverride && conditionalOverride.when ? conditionalOverride.when : null);
        // console.log('%c overrideWhen: %O', 'color: purple;', overrideWhen);
        overrideWhen = convertOverrideWhenObjectToArray(overrideWhen);
        overrideWhen.splice(conditionIndex, 1);
        // console.log('%c overrideWhen: %O', 'color: blue;', overrideWhen);
        conditionalOverride.when = overrideWhen;
        editAction.conditionalOverride = conditionalOverride;

        setEditConditionIndex(-1);
        setEditCondition(null);
        setEditAction(editAction);
        flashUI(!uiState);
    }

    const onMediatorAccessEnableTriggerChanged = (mediatorIndex: number, evt: any) => {
        let trgt: any = evt.target;
        let newValue: any = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);

        let access: any = (editAction.access ? editAction.access : {});
        let accessMediators: any[] = (access && access.mediators ? access.mediators : []);

        if (!accessMediators[mediatorIndex]) {
            accessMediators[mediatorIndex] = {
                enableTrigger: true,
                name: '',
                requiredPermissions: []
            };
        }
        accessMediators[mediatorIndex].enableTrigger = newValue;

        access.mediators = accessMediators;
        editAction.access = access;

        setEditAction(editAction);
        flashUI(!uiState);
    }

    const addRestrictedToRole = (evt: any) => {
        // let access = (editAction.access ? editAction.access : null);
        // let accessMediators = (access && access.mediators ? access.mediators : []);

        // accessMediators.push({
        //     enableTrigger: true,
        //     name: '',
        //     requiredPermissions: []
        // });

        // access.mediators = accessMediators;
        // editAction.access = access;

        setEditAction(editAction);
        flashUI(!uiState);
    }

    const changeRestrictedRole = (roleIndex: number, evt: any) => {
        let trgt: any = evt.target;
        let newValue: any = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);

        // let access = (editAction.access ? editAction.access : {});
        // let accessMediators = (access && access.mediators ? access.mediators : []);

        // if (!accessMediators[roleIndex]) {
        //     accessMediators[roleIndex] = {
        //         enableTrigger: true,
        //         name: '',
        //         requiredPermissions: []
        //     };
        // }
        // accessMediators[roleIndex].roleName = newValue;

        // access.mediators = accessMediators;
        // editAction.access = access;

        setEditAction(editAction);
        flashUI(!uiState);
    }

    const removeRestrictedRole = (roleIndex: number, evt: any) => {
        // let access = (editAction.access ? editAction.access : {});
        // let accessMediators = (access && access.mediators ? access.mediators : []);

        // let newAccessMediators = [];
        // Object.entries(accessMediators).forEach(([idx, med]) => {
        //     if (roleIndex !== idx) newAccessMediators.push(med);
        // });

        // access.mediators = newAccessMediators;
        // editAction.access = access;

        setEditAction(editAction);
        flashUI(!uiState);
    }

    const actionUpdated = (action: any) => {
        // console.log('%c actionUpdated action: %O', 'color: blue;', action);
        setEditAction(action);
        flashUI(!uiState);
    }

    const applyChangesToEngagementAction = () => {
        // console.log('%c applyChangesToEngagementAction editAction: %O', 'color: blue;', editAction);
        if (!editAction) return false;

        if (onApply) onApply(editAction);

        // let portalContext = (this.context && this.context.portalContext ? this.context.portalContext : null);
        // let ui = (portalContext && portalContext.ui ? portalContext.ui : null);
        // let design = (ui && ui.design ? ui.design : null);
        // // console.log('%c design: %O', 'color: purple;', design);

        // let editSocialSolution = (design && design.editSocialSolution ? design.editSocialSolution : null);
        // let settings = (editSocialSolution && editSocialSolution.data.jsonSettingsJson ? editSocialSolution.data.jsonSettingsJson : {});
        // let engagementActions = (settings && settings.engagementActions ? settings.engagementActions : []);
        // let foundAction = false;
        // Object.entries(engagementActions).forEach(([idx, ea]) => {
        //     // console.log('%c ea: %O', 'color: purple;', ea);
        //     if (editAction.uid === ea.uid) {
        //         engagementActions[idx] = editAction;
        //         foundAction = true;
        //     }
        // });
        // if (!foundAction) engagementActions.push(editAction);

        // settings.engagementActions = engagementActions;
        // editSocialSolution.data.jsonSettingsJson = settings;
        // editSocialSolution.isDirty = true;

        // design.editSocialSolution = editSocialSolution;
        // ui.design = design;
        // this.setState({ editAction: null, removeAction: null });
        // portalContext.setUserInterface(ui);
    }

    const getActionForm = () => {
        let uid: string = (editAction.uid ? editAction.uid : '');
        let action: string = (editAction.action ? editAction.action : '');
        // console.log('%c action: %O', 'color: purple;', action);
        let command: string = (editAction.command ? editAction.command : '');
        // console.log('%c command: %O', 'color: purple;', command);
        let actionName: string = (editAction.name ? editAction.name : '');
        // console.log('%c actionName: %O', 'color: purple;', actionName);
        let label: any = (editAction.label ? editAction.label : null);
        let labelText: string = (label && label.text ? label.text : actionName);
        let componentType: string = (editAction.componentType ? editAction.componentType : 'span');

        let showWhen: any = (editAction.showWhen ? editAction.showWhen : null);
        // console.log('%c showWhen: %O', 'color: purple;', showWhen);
        let enableWhen: any = (editAction.enableWhen ? editAction.enableWhen : null);
        // console.log('%c enableWhen: %O', 'color: purple;', enableWhen);

        let actionTitle: string = '';
        if (labelText) {
            actionTitle = labelText;
        } else if (action) {
            actionTitle = action;
        } else if (command) {
            actionTitle = command;
        }

        let access: any = (editAction.access ? editAction.access : null);
        // console.log('%c access: %O', 'color: purple;', access);
        let accessMediators: any[] = (access && access.mediators ? access.mediators : []);
        let accessRequiredPermissions: any = (access && access.requiredPermissions ? access.requiredPermissions : null);
        // console.log('%c accessRequiredPermissions: %O', 'color: purple;', accessRequiredPermissions);
        let accessShowForAllMediators: any = (access && access.showForAllMediators ? true : false);

        let tabContentElement: any = null;
        if (tabIndex === 0) {
            let availableActions: any[] = udicciHelpers.getAvailableActions();
            // console.log('%c availableActions: %O', 'color: blue;', availableActions);

            let availableActionSelections: any[] = [];
            Object.entries(availableActions).forEach(([idxAction, action]) => {
                // console.log('%c action: %O', 'color: green;', action);
                availableActionSelections.push(
                    <MenuItem key={action.command} value={action.command}>{action.command}</MenuItem>
                );
            });

            let nameElement: any = (
                <Box>
                    <Typography variant="overFieldLabel">Action Name</Typography>
                    <TextField fullWidth
                               value={actionName}
                               onChange={(evt: any) => changeValue('name', '', evt)}
                               margin="none"
                               variant='outlined'
                    />
                </Box>
            );
            let labelTextElement: any = (
                <Box>
                    <Typography variant="overFieldLabel">Label Text</Typography>
                    <TextField fullWidth
                               value={labelText}
                               onChange={(evt: any) => changeValue('text', 'label', evt)}
                               margin="none"
                               variant='outlined'
                    />
                </Box>
            );
            let actionElement: any = (
                <Box>
                    <Typography variant="overFieldLabel">Action Command</Typography>
                    <Select value={command} fullWidth
                            onChange={(evt: any) => changeValue('command', '', evt)}
                            inputProps={{ name: 'command', id: 'command', }}>
                        {availableActionSelections}
                    </Select>
                </Box>
            );

            tabContentElement = (
                <Box>
                    {nameElement}
                    {labelTextElement}
                    {actionElement}
                </Box>
            );
        }

        if (tabIndex === 1) {
            let mediatorName: string = '';
            let structure: any = null;
            let fields: any[] = [];
            let virtualFields: any[] = [];
            if (accessMediators && accessMediators.length > 0) {
                let firstMediator = accessMediators[0];
                mediatorName = firstMediator.name;
                structure = udicciHelpers.getMediatorStructure(mediatorName);
                fields = (structure && structure.UdicciMediatorFields ? structure.UdicciMediatorFields : []);
                virtualFields = (structure && structure.VirtualFields ? structure.VirtualFields : []);
            }
            // console.log('%c structure: %O', 'color: red;', structure);
            // console.log('%c fields: %O', 'color: red;', fields);
            // console.log('%c virtualFields: %O', 'color: red;', virtualFields);
    
            let availableActions: any[] = udicciHelpers.getAvailableActions();
            // console.log('%c availableActions: %O', 'color: blue;', availableActions);

            let availableActionSelections: any[] = [];
            forEach(availableActions, (action: any, idxAction: number) => {
                // console.log('%c action: %O', 'color: green;', action);
                availableActionSelections.push(
                    <MenuItem key={action.command} value={action.command}>{action.command}</MenuItem>
                );
            });

            let conditionalOverride: any = (editAction && editAction.conditionalOverride ? editAction.conditionalOverride : null);
            // console.log('%c conditionalOverride: %O', 'color: blue;', conditionalOverride);
            let overrideLabel: any = (conditionalOverride && conditionalOverride.label ? conditionalOverride.label : null);
            let overrideLabelText: string = (overrideLabel && overrideLabel.text ? overrideLabel.text : '');
            let overrideAction: any = (conditionalOverride && conditionalOverride.action ? conditionalOverride.action : action);

            let overrideWhen: any[] | null = (conditionalOverride && conditionalOverride.when ? conditionalOverride.when : null);
            overrideWhen = convertOverrideWhenObjectToArray(overrideWhen);
            // console.log('%c overrideWhen: %O', 'color: blue;', overrideWhen);

            let conditionsSettingsElement: any = null;
            if (overrideWhen && overrideWhen.length > 0) {
                let conditionsSettingsElements: any[] = [];
                forEach(overrideWhen, (condition: any, conditionIndex: number) => {
                    // console.log('condition: %O', condition);
                    let conditionJsonKey: string = (condition.jsonKey ? condition.jsonKey : '');
                    let conditionJsonPath: string = (condition.jsonPath ? condition.jsonPath : '');
                    let conditionBinder: string = (condition.conditionBinder ? condition.conditionBinder : 'And');
        
                    let conditionField: any = {};
                    if (conditionJsonKey && (fields.length > 0 || virtualFields.length > 0)) {
                        if (fields.length > 0) {
                            conditionField = fields.find(f =>
                                (f.JsonFieldName === conditionJsonKey ? true : false)
                            );
                        }
                        if (!conditionField && virtualFields.length > 0) {
                            conditionField = virtualFields.find(v =>
                                (v.JsonFieldName === conditionJsonKey ? true : false)
                            );
                        }
                    }
                    // console.log('conditionField: %O', conditionField);

                    let editThisCondition: boolean = false;
                    let whenConditionDisplayElement: any = null;
                    // console.log('editConditionIndex: %O', editConditionIndex);
                    // console.log('editCondition: %O', editCondition);
                    if (conditionIndex.toString() === editConditionIndex.toString() && editCondition) {
                        let selectJsonPathElement: any = null;
                        if (conditionField && conditionField.DataType === 'Json') {
                            selectJsonPathElement = (
                                <TextField fullWidth
                                           label={'Enter a Path using Dot Notation'}
                                           value={conditionJsonPath}
                                           onChange={(evt: any) => changeValue('jsonPath', 'conditionalOverride.when', evt)}
                                           margin="normal"
                                           variant='filled'
                                />
                            );
                        }

                        let conditionBinderDisplayElement: any = null;
                        if (conditionBinder && conditionIndex > 0) {
                            conditionBinderDisplayElement = (
                                <Select value={conditionBinder}
                                        id='select-condition-binder'
                                        onChange={(evt: any) => changeValue('conditionBinder', 'conditionalOverride.when', evt)}
                                >
                                    <MenuItem value="And"> And </MenuItem>
                                    <MenuItem value="Or"> Or </MenuItem>
                                </Select>
                            );
                        }

                        let selectFieldElement = (
                            <Select value={conditionJsonKey} onChange={(evt: any) => changeValue('jsonKey', 'conditionalOverride.when', evt)}>
                                <MenuItem disabled value="">
                                    <em>Select Field</em>
                                </MenuItem>
                                {fields.map(fld => {
                                    // console.log('%c fld: %O', 'color: red;', fld);
                                    return (
                                        <MenuItem key={'filter.field.' + fld.JsonFieldName}
                                                value={fld.JsonFieldName}
                                        >
                                            {fld.DisplayName}
                                        </MenuItem>
                                    );
                                })}
                                {virtualFields.map(fld => {
                                    // console.log('%c fld: %O', 'color: red;', fld);
                                    return (
                                        <MenuItem key={'filter.field.' + fld.JsonFieldName}
                                                value={fld.JsonFieldName}
                                        >
                                            {fld.DisplayName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        );

                        let whenTrueElement = (
                            <div>
                                {conditionBinderDisplayElement}
                                {selectFieldElement}
                                {selectJsonPathElement}
                                {conditionsSettingsElement}
                            </div>
                        );

                        whenConditionDisplayElement = (
                            <div>
                                {whenTrueElement}

                                <FieldFilter field={conditionField}
                                             filterSettings={condition}
                                             readonly={false}
                                             onUpdateFilterSettings={(filterSettings: any) => updateFilterSettings(conditionIndex, condition, filterSettings)}
                                />

                                <div>
                                    <IconButton color="secondary"
                                                size="small"
                                                onClick={(evt: any) => closeConditionalOverrideCondition()}
                                                aria-label="Cancel Edit"
                                                title="Cancel Edit"
                                    >
                                        <Icon>cancel</Icon>
                                    </IconButton>
                                    <IconButton color="primary"
                                                size="small"
                                                onClick={(evt: any) => closeConditionalOverrideCondition()}
                                                aria-label="Apply Changes"
                                                title="Apply Changes"
                                    >
                                        <Icon>check</Icon>
                                    </IconButton>
                                </div>
                            </div>
                        );
                    } else {
                        let selectedField = fields.find(x => x.JsonFieldName === conditionJsonKey);
                        if (!selectedField) {
                            selectedField = virtualFields.find(x => x.JsonFieldName === conditionJsonKey);
                        }

                        let conditionBinderElement = null;
                        if (conditionBinder && conditionIndex > 0) {
                            conditionBinderElement = (
                                <Typography component="span" variant="caption" color="secondary">
                                    {conditionBinder}
                                </Typography>
                            );
                        }

                        let displayNameElement = null;
                        if (selectedField && selectedField.DisplayName) {
                            displayNameElement = (
                                <Typography component="span" variant="subtitle1" color="primary">
                                    {selectedField.DisplayName}
                                </Typography>
                            );
                        }

                        let jsonPathElement = null;
                        if (conditionJsonPath) {
                            jsonPathElement = (
                                <React.Fragment>
                                    <Typography component="span" variant="caption">.</Typography>
                                    <Typography component="span" variant="body2" color="textPrimary">
                                        {conditionJsonPath}
                                    </Typography>
                                </React.Fragment>
                            );
                        }

                        whenConditionDisplayElement = (
                            <div>
                                <IconButton color="primary"
                                            size="small"
                                            onClick={(evt: any) => addConditionalOverrideCondition(conditionIndex)}
                                            aria-label="Add a New Condition"
                                            title="Add a New Condition"
                                >
                                    <Icon>add</Icon>
                                </IconButton>
                                <IconButton color="primary"
                                            size="small"
                                            onClick={(evt: any) => editConditionalOverrideCondition(overrideWhen, conditionIndex)}
                                            aria-label="Edit this Condition"
                                            title="Edit this Condition"
                                >
                                    <Icon>edit</Icon>
                                </IconButton>
                                <IconButton color="secondary"
                                            size="small"
                                            style={{ paddingRight: '8px' }}
                                            onClick={(evt: any) => removeConditionalOverrideCondition(conditionIndex)}
                                            aria-label="Remove this Condition"
                                            title="Remove this Condition"
                                >
                                    <Icon>remove</Icon>
                                </IconButton>
    
                                {conditionBinderElement}
                                {displayNameElement}
                                {jsonPathElement}

                                <FieldFilter field={conditionField}
                                             filterSettings={condition}
                                             readonly={true}
                                             onUpdateFilterSettings={(filterSettings: any) => updateFilterSettings(conditionIndex, condition, filterSettings)}
                                />
                            </div>
                        );
                    }

                    conditionsSettingsElements.push(
                        <React.Fragment key={'conditional.override.' + conditionIndex}>
                            {whenConditionDisplayElement}
                        </React.Fragment>
                    );
                });
                conditionsSettingsElement = (
                    <div>
                        {conditionsSettingsElements}
                    </div>
                );
            } else {
                conditionsSettingsElement = (
                    <div>
                        <Typography component="div" variant="caption" gutterBottom>
                            Conditional overrides not setup
                        </Typography>
                        <IconButton color="primary"
                                    size="small"
                                    onClick={(evt: any) => addConditionalOverrideCondition()}
                                    aria-label="Add a New Condition"
                                    title="Add a New Condition"
                        >
                            <Icon>add</Icon>
                        </IconButton>
                    </div>
                );
            }

            let whenFilterElement = (
                <Box>
                    <Typography component="div" variant="body2" color='textSecondary' gutterBottom>
                        When the following is true
                    </Typography>
                    {conditionsSettingsElement}
                </Box>
            );

            let overrideLabelTextElement = (
                <Box>
                    <Typography variant="overFieldLabel">Set the Label Text to</Typography>
                    <TextField fullWidth
                               value={overrideLabelText}
                               placeholder={labelText}
                               onChange={(evt: any) => changeValue('text', 'conditionalOverride.label', evt)}
                               margin="none"
                               variant='outlined'
                    />
                </Box>
            );
            let alternateActionElement = (
                <Box>
                    <Typography variant="overFieldLabel">Engage this command instead</Typography>
                    <Select value={overrideAction} fullWidth
                            onChange={(evt: any) => changeValue('action', 'conditionalOverride', evt)}
                            inputProps={{ name: 'alternate-action', id: 'alternate-action', }}>
                        {availableActionSelections}
                    </Select>
                </Box>
            );

            tabContentElement = (
                <Box>
                    {whenFilterElement}
                    {overrideLabelTextElement}
                    {alternateActionElement}
                </Box>
            );
        }

        if (tabIndex === 2) {
            let mediators: any[] = []; 
            let roles: any[] = []; // (portalContext.selectedProfileRoles ? portalContext.selectedProfileRoles : null);
            // console.log('%c roles: %O', 'color: purple;', roles);

            let restrictions: any = (editAction.restrictions ? editAction.restrictions : null);
            let restrictRoles: any[] = (restrictions && restrictions.roles ? restrictions.roles : []);
            let restrictUsers: any = (restrictions && restrictions.users ? restrictions.users : null);

            let accessRequiredPermissionsElement: any = null;
            let mediatorOptions: any[] = [];
            // console.log('%c mediators: %O', 'color: purple;', mediators);
            if (mediators && mediators.length > 0) {
                mediators = mediators.sort((a,b) => (
                    a.data.Name.toLowerCase() > b.data.Name.toLowerCase()) ? 1 : ((b.data.Name.toLowerCase() > a.data.Name.toLowerCase()) ? -1 : 0)
                );
                forEach(mediators, (med: any, idx: number) => {
                    // console.log('med: %O', med);
                    mediatorOptions.push(
                        <MenuItem key={'select.mediator.' + med.udicciRecordId} value={med.data.Name}>
                            {med.data.Name}
                        </MenuItem>
                    );
                });
            } else {
                let requiredPermissions: any = null;
                let accessRequiredPermissionsElements: any[] = [];
                if (accessRequiredPermissions && accessRequiredPermissions.length > 0) {
                    forEach(accessRequiredPermissions, (reqPerm: any, requiredPermissionIndex: number) => {
                        // console.log('reqPerm: %O', reqPerm);
                        let isRequired = (reqPerm && reqPerm.required ? true : false);
                        let permission = (reqPerm && reqPerm.permission ? reqPerm.permission : '');
                        let permissionName = permission.replace('Can', 'Can ');

                        let isRequiredElement = (
                            <Checkbox checked={(isRequired ? true : false)}
                                        onChange={(evt: any) => onRequiredPermissionChanged(mediatorIndex, requiredPermissionIndex, 'required', evt)}
                                        color="primary"
                            />
                        );

                        let permissionNameElement: any = null;
                        if (permission) {
                            permissionNameElement = (
                                <Typography component="span" color="secondary"> {permissionName} </Typography>
                            );
                        } else {
                            let permissionSelections: any[] = [];
                            forEach(permissionOptions, (option: any, idx: number) => {
                                // console.log('%c option: %O', 'color: green;', option);
                                let checkPerm: any = null;
                                if (requiredPermissions) {
                                    checkPerm = requiredPermissions.find((p: any) =>
                                        (p.permission === option.value ? true : false)
                                    );
                                }
                                // console.log('checkPerm: %O', checkPerm);

                                if (!checkPerm) {
                                    permissionSelections.push(
                                        <MenuItem key={'permission.option.' + option.value} value={option.value}>{option.text}</MenuItem>
                                    );
                                }
                            });

                            permissionNameElement = (
                                <Select value={permission} onChange={(evt: any) => onRequiredPermissionChanged(mediatorIndex, requiredPermissionIndex, 'permission', evt)}>
                                    {permissionSelections}
                                </Select>
                            );
                        }

                        let removeRequiredPermissionElement = (
                            <IconButton onClick={(evt: any) => removeRequiredPermission(mediatorIndex, requiredPermissionIndex, evt)} size='small'>
                                <Icon fontSize='small'>remove_circle_outline</Icon>
                            </IconButton>
                        );

                        accessRequiredPermissionsElements.push(
                            <div key={'required.permission.' + mediatorIndex + '.' + requiredPermissionIndex}>
                                {isRequiredElement}
                                {permissionNameElement}
                                {removeRequiredPermissionElement}
                            </div>
                        );
                    });
                }

                let accessPaperStyle: any = { textAlign: 'center' };
                if (accessRequiredPermissionsElements.length > 0) {
                    accessPaperStyle.marginTop = '8px';
                    accessRequiredPermissionsElement = (
                        <Fragment>
                            <Box>
                                {accessRequiredPermissionsElements}
                            </Box>
                            <Box>
                                <IconButton onClick={(evt: any) => addRequiredPermissionWhen(mediatorIndex, evt)} size='small'>
                                    <Icon fontSize='small'>add_circle</Icon>
                                </IconButton>
                            </Box>
                        </Fragment>
                    );
                } else {
                    accessRequiredPermissionsElement = (
                        <Box>
                            <IconButton onClick={(evt: any) => addRequiredPermissionWhen(mediatorIndex, evt)} size='small'>
                            <Icon fontSize='small'>add_circle</Icon>
                            </IconButton>
                        </Box>
                    );
                }

            }

            let showForAllMediatorsElement: any = (
                <FormControlLabel
                    label="Show For All Mediators"
                    control={
                        <Checkbox checked={accessShowForAllMediators}
                                  onChange={(evt: any) => changeValue('showForAllMediators', 'access', evt)}
                                  color="primary"
                        />
                    }
                />
            );

            let permissionOptions: any[] = [
                { value: 'CanView', text: 'Can View' },
                { value: 'CanAdd', text: 'Can Add' },
                { value: 'CanEdit', text: 'Can Edit' },
                { value: 'CanDelete', text: 'Can Delete' }
            ];

            let mediatorList: any[] = [];
            if (accessShowForAllMediators) {
                mediatorList = [{
                    name: 'All',
                    enableTrigger: true,
                    requiredPermissions: accessRequiredPermissions
                }];
            } else {
                mediatorList = accessMediators;
            }


            let tableRows: any[] = [];
            // if (accessRequiredPermissionsElement) {
            //     tableRows.push(
            //         <TableRow key={'access.row'}>
            //             <TableCell align="center" padding="checkbox">
            //                 <IconButton onClick={(evt: any) => removeMediatorAccess(mediatorIndex, evt)} size='small'>
            //                     <Icon fontSize='small'>remove_circle_outline</Icon>
            //                 </IconButton>
            //             </TableCell>
            //             <TableCell align="center">{enableTriggerForMediator}</TableCell>
            //             <TableCell>{mediatorNameElement}</TableCell>
            //             <TableCell>{requiredPermissionsElement}</TableCell>
            //         </TableRow>
            //     );
            // }

            let selectedMediatorNames: string[] = [];
            forEach(mediatorList, (med: any, mediatorIndex: number) => {
                // console.log('%c med: %O', 'color: green;', med);
                let enableTriggerForMediator: any = (
                    <Checkbox checked={(med.enableTrigger ? true : false)}
                                onChange={(evt: any) => onMediatorAccessEnableTriggerChanged(mediatorIndex, evt)}
                                color="primary"
                    />
                );

                let mediatorSelectionElement: any = (
                    <Select value=''
                            onChange={(evt: any) => changeMediatorWithAccess(mediatorIndex, evt)}
                            placeholder="Select Mediator to assign Access"
                    >
                        {mediatorOptions}
                    </Select>
                );

                let mediatorNameElement: any = null;
                if (med.name) {
                    selectedMediatorNames.push(med.name);
                    mediatorNameElement = (
                        <Typography variant='body1' color='secondary'> {med.name} </Typography>
                    );
                } else {
                    mediatorNameElement = mediatorSelectionElement;
                }

                let requiredPermissionsElements: any[] = [];
                let requiredPermissions: any[] | null = (med && med.requiredPermissions ? med.requiredPermissions : null);
                if (requiredPermissions && requiredPermissions.length > 0) {
                    forEach(requiredPermissions, (reqPerm: any, requiredPermissionIndex: number) => {
                        // console.log('reqPerm: %O', reqPerm);
                        let isRequired: boolean = (reqPerm && reqPerm.required ? true : false);
                        let permission: string = (reqPerm && reqPerm.permission ? reqPerm.permission : '');
                        let permissionName: string = permission.replace('Can', 'Can ');

                        let isRequiredElement: any = (
                            <Checkbox checked={(isRequired ? true : false)}
                                        onChange={(evt: any) => onRequiredPermissionChanged(mediatorIndex, requiredPermissionIndex, 'required', evt)}
                                        color="primary"
                            />
                        );

                        let permissionNameElement: any = null;
                        if (permission) {
                            permissionNameElement = (
                                <Typography component="span" color="secondary"> {permissionName} </Typography>
                            );
                        } else {
                            let permissionSelections: any[] = [];
                            forEach(permissionOptions, (option: any, idx: number) => {
                                // console.log('%c option: %O', 'color: green;', option);
                                let checkPerm: any = null;
                                if (requiredPermissions) {
                                    checkPerm = requiredPermissions.find((p: any) =>
                                        (p.permission === option.value ? true : false)
                                    );
                                }
                                // console.log('checkPerm: %O', checkPerm);

                                if (!checkPerm) {
                                    permissionSelections.push(
                                        <MenuItem key={'permission.option.' + option.value} value={option.value}>{option.text}</MenuItem>
                                    );
                                }
                            });

                            permissionNameElement = (
                                <Select value={permission} onChange={(evt: any) => onRequiredPermissionChanged(mediatorIndex, requiredPermissionIndex, 'permission', evt)}>
                                    {permissionSelections}
                                </Select>
                            );
                        }

                        let removeRequiredPermissionElement: any = (
                            <IconButton onClick={(evt: any) => removeRequiredPermission(mediatorIndex, requiredPermissionIndex, evt)} size='small'>
                                <Icon fontSize='small'>remove_circle_outline</Icon>
                            </IconButton>
                        );

                        requiredPermissionsElements.push(
                            <div key={'required.permission.' + mediatorIndex + '.' + requiredPermissionIndex}>
                                {isRequiredElement}
                                {permissionNameElement}
                                {removeRequiredPermissionElement}
                            </div>
                        );
                    });
                }

                let requiredPermissionsElement: any = null;
                let paperStyle: any = { textAlign: 'center' };
                if (requiredPermissionsElements.length > 0) {
                    paperStyle.marginTop = '8px';
                    requiredPermissionsElement = (
                        <Fragment>
                            <Box>
                                {requiredPermissionsElements}
                            </Box>
                            <Box>
                                <IconButton onClick={(evt: any) => addRequiredPermissionWhen(mediatorIndex, evt)} size='small'>
                                    <Icon fontSize='small'>add_circle</Icon>
                                </IconButton>
                            </Box>
                        </Fragment>
                    );
                } else {
                    requiredPermissionsElement = (
                        <Box>
                            <IconButton onClick={(evt: any) => addRequiredPermissionWhen(mediatorIndex, evt)} size='small'>
                                <Icon fontSize='small'>add_circle</Icon>
                            </IconButton>
                        </Box>
                    );
                }

                tableRows.push(
                    <TableRow key={'access.mediator.row.' + mediatorIndex}>
                        <TableCell align="center" padding="checkbox">
                            <IconButton onClick={(evt: any) => removeMediatorAccess(mediatorIndex, evt)} size='small'>
                                <Icon fontSize='small'>remove_circle_outline</Icon>
                            </IconButton>
                        </TableCell>
                        <TableCell align="center">{enableTriggerForMediator}</TableCell>
                        <TableCell>{mediatorNameElement}</TableCell>
                        <TableCell>{requiredPermissionsElement}</TableCell>
                    </TableRow>
                );
            });

            let accessMediatorsTableElement: any = (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" padding="checkbox">
                                <IconButton onClick={(evt: any) => addMediatorAccess(evt)} size='small'>
                                    <Icon fontSize='small'>add_circle</Icon>
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant='subtitle1' color='primary'> Enable </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='subtitle1' color='primary'>
                                    Show for Mediator
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='subtitle1' color='primary'>
                                    Required Permissions for Mediator
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{tableRows}</TableBody>
                </Table>
            );

            let roleTableRows: any[] = [];
            forEach(restrictRoles, (role: any, idx: number) => {
                // console.log('%c role: %O', 'color: green;', role);
                let roleSelectionElement: any = (
                    <Select onChange={(evt: any) => changeMediatorWithAccess(idx, evt)}
                            placeholder="Select Role to assign Access"
                    >
                    </Select>
                );

                let roleNameElement: any = null;
                if (role.roleName) {
                    roleNameElement = (
                        <Typography variant='body1' color='secondary'> {role.roleName} </Typography>
                    );
                } else {
                    roleNameElement = roleSelectionElement;
                }

                roleTableRows.push(
                    <TableRow key={'restricted.to.role.row.' + idx}>
                        <TableCell align="center" padding="checkbox">
                            <IconButton onClick={(evt: any) => removeRestrictedRole(idx, evt)} size='small'>
                                <Icon fontSize='small'>remove_circle_outline</Icon>
                            </IconButton>
                        </TableCell>
                        <TableCell>{roleNameElement}</TableCell>
                    </TableRow>
                );
            });

            let restrictedToRolesTableElement: any = (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" padding="checkbox">
                                <IconButton onClick={(evt: any) => addRestrictedToRole(evt)} size='small'>
                                    <Icon fontSize='small'>add_circle</Icon>
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <Typography variant='subtitle1' color='primary'>
                                    Role Name
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{roleTableRows}</TableBody>
                </Table>
            );

            let restrictionsElement: any = (
                <Box>
                    <Typography variant='subtitle1' color='primary'> Restrictions (Platform) </Typography>
                    {restrictedToRolesTableElement}
                </Box>
            );

            tabContentElement = (
                <Box>
                    <Box>
                        <Typography variant='subtitle1' color='primary'> Access (Profile) </Typography>
                        {showForAllMediatorsElement}
                        {accessMediatorsTableElement}
                    </Box>
                </Box>
            );
        }

        if (tabIndex === 3) {
            let showComponentWhenSelections: any[] = [
                { value: 'always', text: 'Always Shown' },
                { value: 'not.udicciRecordId', text: 'Record Is New' },
                { value: 'udicciRecordId', text: 'Record Is Not New' },
                { value: 'not.isDirty', text: 'Record Has Not Been Updated' },
                { value: 'isDirty', text: 'Record Has Changes To Be Saved' }
            ];

            let showWhenSelections: any[] = [];
            forEach(showComponentWhenSelections, (option: any, idx: number) => {
                // console.log('%c option: %O', 'color: green;', option);
                showWhenSelections.push(
                    <MenuItem key={'show.when.' + option.value} value={option.value}>{option.text}</MenuItem>
                );
            });

            let showWhenField: string = (showWhen && showWhen.field ? showWhen.field : '');
            let showWhenComparison: any = (showWhen && showWhen.comparison ? showWhen.comparison : null);

            let showComponentWhenSelectionValue: string = 'always';
            if (showWhenField === 'udicciRecordId') {
                if (showWhenComparison === 'Greater Than') {
                    showComponentWhenSelectionValue = 'udicciRecordId';
                } else if (showWhenComparison === 'Equals') {
                    showComponentWhenSelectionValue = 'not.udicciRecordId';
                }
            }
            if (showWhenField === 'isDirty') {
                if (showWhenComparison === 'Equals') {
                    showComponentWhenSelectionValue = 'isDirty';
                } else {
                    showComponentWhenSelectionValue = 'not.isDirty';
                }
            }

            let showWhenElement: any = (
                <Box>
                    <Typography variant="overFieldLabel">Show When</Typography>
                    <Select value={showComponentWhenSelectionValue} fullWidth
                            onChange={(evt: any) => changeValue('showWhen', '', evt)}
                            inputProps={{ name: 'showWhen', id: 'showWhen', }}>
                        {showWhenSelections}
                    </Select>
                </Box>
            );

            let enableComponentWhenSelections: any[] = [
                { value: 'always', text: 'Always Enabled' },
                { value: 'not.udicciRecordId', text: 'Record Is New' },
                { value: 'udicciRecordId', text: 'Record Is Not New' },
                { value: 'not.isDirty', text: 'Record Has Not Been Updated' },
                { value: 'isDirty', text: 'Record Has Changes To Be Saved' }
            ];

            let enableWhenSelections: any[] = [];
            forEach(enableComponentWhenSelections, (option: any, idx: number) => {
                // console.log('%c option: %O', 'color: green;', option);
                enableWhenSelections.push(
                    <MenuItem key={'enable.when.' + option.value} value={option.value}>{option.text}</MenuItem>
                );
            });

            let enableWhenField: string = (enableWhen && enableWhen.field ? enableWhen.field : '');
            let enableWhenComparison: string = (enableWhen && enableWhen.comparison ? enableWhen.comparison : '');

            let enableComponentWhenSelectionValue: string = 'always';
            if (enableWhenField === 'udicciRecordId') {
                if (enableWhenComparison === 'Greater Than') {
                    enableComponentWhenSelectionValue = 'udicciRecordId';
                } else if (enableWhenComparison === 'Equals') {
                    enableComponentWhenSelectionValue = 'not.udicciRecordId';
                }
            }
            if (enableWhenField === 'isDirty') {
                if (enableWhenComparison === 'Equals') {
                    enableComponentWhenSelectionValue = 'isDirty';
                } else {
                    enableComponentWhenSelectionValue = 'not.isDirty';
                }
            }
            // console.log('%c enableComponentWhenSelectionValue: %O', 'color: red;', enableComponentWhenSelectionValue);

            let enableWhenElement: any = (
                <Box>
                    <Typography variant="overFieldLabel">Enable When</Typography>
                    <Select value={enableComponentWhenSelectionValue} fullWidth
                            onChange={(evt: any) => changeValue('enableWhen', '', evt)}
                            inputProps={{ name: 'enableWhen', id: 'enableWhen', }}>
                        {enableWhenSelections}
                    </Select>
                </Box>
            );

            tabContentElement = (
                <Box>
                    {showWhenElement}
                    {enableWhenElement}
                </Box>
            );
        }

        if (tabIndex === 4) {
            let customConfigElement: any = (
                <ActionExchange engagementAction={editAction} configure={true} onUpdateAction={actionUpdated} onClose={closeEngagementAction} />
            );

            tabContentElement = (
                <Box>
                    {customConfigElement}
                </Box>
            );
        }

        return (<Box sx={{ padding: '8px' }}>{tabContentElement}</Box>);
    }

    return (
        <Box sx={{ background: 'rgba(255, 255, 255, 0.3)', padding: '8px' }}>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
                <Box sx={{ display: 'flow-root' }}>
                    <Tabs value={tabIndex}
                          onChange={(event: any, value: number) => { handleTabChange(event, value); }}
                          indicatorColor="secondary"
                          textColor="primary"
                          variant="standard"
                          orientation="vertical"
                    >
                        <Tab value={0} label="Action Settings" />
                        <Tab value={2} label="Access" />
                        <Tab value={1} label="Conditional Overrides" />
                        <Tab value={3} label="Display Settings" />
                        <Tab value={4} label="Configuration" />
                    </Tabs>
                </Box>
                <VerticalTabPanel>{getActionForm()}</VerticalTabPanel>
            </Box>
            <Box sx={{ paddingRight: '16px', paddingTop: '8px', textAlign: 'right' }}>
                <Button size="small" variant="contained" onClick={(evt: any) => closeEngagementAction()} color="info" sx={{ margin: '4px' }}>
                    Close
                </Button>
                <Button size="small" variant="contained" onClick={(evt: any) => applyChangesToEngagementAction()} color="success" sx={{ margin: '4px' }}>
                    Apply Changes
                </Button>
            </Box>
        </Box>
    );
}

export default EngagementActionForm;
