import React, { useState } from 'react';
import { Box, Paper } from '@mui/material';
import { useUdicciContext, getUdicciData } from 'src/context/udicci-context';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import Relationships from './relationships';

const RelationshipsList: React.FC<any> = (props) => {
    // console.log('%c RelationshipsList props: %O', 'color: maroon;', props);
    const udicciContext = useUdicciContext();
    const udicciHelpers = useUdicciHelpers();
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);
    let { udicci } = udicciContext.state;

    const [dataRequested, setDataRequested] = useState<boolean>(false);

    let { currentUser } = udicci;
    // console.log('%c Me selectedProfile: %O', 'color: maroon;', selectedProfile);

    const refreshMediationAgreements = (useUserProfile: boolean = false) => {
        // console.log('%c refreshMediationAgreements selectedProfile: %O', 'color: red;', selectedProfile);
        // console.log('%c refreshMediationAgreements useUserProfile: %O', 'color: red;', useUserProfile);
        if (dataRequested) return false;

        setDataRequested(true);

        let requestOptions: any = {
            mediator: 'Mediation Agreements',
            socialSolutionId: udicci.socialSolutionUdicciIT,
            onSuccess: refreshMediationAgreementsSuccess,
            onError: refreshMediationAgreementsFailed,
        };

        if (currentUser && currentUser.MeUdicciProfileId && useUserProfile) {
            requestOptions.profileId = currentUser.MeUdicciProfileId;
        }
        // console.log('%c refreshMediationAgreements requestOptions: %O', 'color: red;', requestOptions);
        getUdicciData(requestOptions);
    };

    const refreshMediationAgreementsSuccess = (result: any) => {
        // console.log('%c refreshMediationAgreementsSuccess result: %O', 'color: red;', result);
        setDataRequested(false);
    };

    const refreshMediationAgreementsFailed = (result: any) => {
        // console.log('%c refreshMediationAgreementsFailed result: %O', 'color: red;', result);
        setDataRequested(false);
    };

    let roles = udicciHelpers.preloadMediatorData('Roles', udicci.socialSolutionUdicciIT);
    // console.log('%c RelationshipsList roles: %O', 'color: maroon;', roles);
    let mediationAgreements = udicciHelpers.preloadMediatorData('Mediation Agreements', udicci.socialSolutionUdicciIT);
    // console.log('%c RelationshipsList mediationAgreements: %O', 'color: red;', mediationAgreements);
    let relationshipsElement: any = null;
    if (mediationAgreements && mediationAgreements.length > 0) {
        relationshipsElement = (
            <Paper style={{ padding: '4px' }}>
                <Relationships agreements={mediationAgreements} roles={roles} onRefreshMediationAgreements={refreshMediationAgreements} />
            </Paper>
        );
    }

    return relationshipsElement;
};

export default RelationshipsList;
