import { deepOrange, green } from '@mui/material/colors';
import { Zoom, Fab, Avatar, useTheme, Icon, Badge } from '@mui/material';
import UDAIC_SOCIAL_ICON from 'src/images/udaic-social-icon.jpeg';
import { useUdicciContext } from 'src/context/udicci-context';

export const FloatingHelperButton: React.FC<any> = (props) => {
    const theme = useTheme();

    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;
    let { currentUser, selectedProfile, platformIssueReport } = udicci;
    // console.log('%c FloatingHelperButton currentUser: %O', 'color: blue;', currentUser);
    // console.log('%c FloatingHelperButton selectedProfile: %O', 'color: blue;', selectedProfile);
    // console.log('%c FloatingHelperButton platformIssueReport: %O', 'color: blue;', platformIssueReport);

    let udaicSocialIconElement: any = null;
    // let bgColor: string = '';
    let normBorderColor: string = '';
    let hovrBorderColor: string = '';
    if (!platformIssueReport) {
        // normBorderColor = green[700];
        // hovrBorderColor = deepOrange[700];
        // udaicSocialIconElement = (<Icon>center_focus_strong</Icon>);
        return null;
    } else {
        normBorderColor = deepOrange[700];
        hovrBorderColor = green[700];
        udaicSocialIconElement = (<Avatar alt={(props && props.title ? props.title : "Report an Issue.")} src={UDAIC_SOCIAL_ICON} />);
    }
    const fabRequestAssistStyle: any = {
        // color: 'common.white',
        bgcolor: 'common.white',
        border: '1px solid ' + normBorderColor,
        '&:hover': {
            bgcolor: 'common.white',
            border: '1px solid ' + hovrBorderColor,
        },
        position: 'sticky',
        bottom: 16,
        right: 16,
        float: 'right',
    };
    // console.log('%c FloatingHelperButton fabRequestAssistStyle: %O', 'color: blue;', fabRequestAssistStyle);

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    let fabElement: any = (
        <Zoom in={true} timeout={transitionDuration} unmountOnExit>
            <Fab sx={fabRequestAssistStyle} aria-label={(props && props.title ? props.title : "Report an Issue.")} color={'inherit'} onClick={props && props.onHelperClicked}>
                <Badge color="info" badgeContent={(<Icon color="info" fontSize="small">close</Icon>)}>
                    {udaicSocialIconElement}
                </Badge>
            </Fab>
        </Zoom>
    );

    return fabElement;
};
