
import React from 'react';

import { Box, Typography, Icon } from '@mui/material';

import UdicciFeatureAdmin from 'src/admin/social-solution-management/udicci-feature-admin'

const FeatureConfigurationForm: React.FC<any> = (props: any) => {
    // console.log('%c FeatureConfigForm props: %O', 'color: red;', props);
    let { feature, onClose } = props;
    return (
        <Box sx={{ margin: '8px', padding: '16px', background: 'rgba(255, 255, 255, 0.1)', border: '1px dashed #4C6FB1' }}>
            <Icon fontSize="small" sx={{ float: 'right', cursor: 'pointer' }} onClick={onClose}>close</Icon>
            <Typography variant="subtitle1" component="div">{feature.name}</Typography>
            <UdicciFeatureAdmin {...props} />
        </Box>
    );
}

export default FeatureConfigurationForm;
