import { useState, useEffect, useRef } from 'react';

import { useTheme } from '@mui/material/styles';

import UrlParser from 'url-parse';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import { udicciStyles } from 'src/theme/shared-styles';

export const GoToProfileUI: React.FC<any> = (props) => {
    // console.log('%c GoToProfileUI props: %O', 'color: blue;', props);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const [progress, setProgress] = useState<number>(0);
    const [redirected, redirect] = useState<boolean>(false);
    const progressRef = useRef(() => {});

    const maxSteps = 20;

    let timer: any = null;
    const timerRef = useRef(timer);

    let currentUrl = window.location.href;
    let url = UrlParser(currentUrl, true);
    // console.log('%c Me url: %O', 'color: blue;', url);
    let isOnLocalHost: boolean = false;
    if (url.href.indexOf('://localhost') > 0) {
        isOnLocalHost = true;
    }

    let redirectUrl: string = '';
    if (props.record && props.record.udicciMediator) {
        if (props.record.udicciMediator === 'People') {
            let addtnlInfo: any = null;
            if (props.record.data && props.record.data.jsonAdditionalInformation) {
                addtnlInfo = props.record.data.jsonAdditionalInformation;
            }
            // console.log('%c addtnlInfo: %O', 'color: purple;', addtnlInfo);
            let meProfile: any = (addtnlInfo && addtnlInfo.meProfile ? addtnlInfo.meProfile : null);
            // console.log('%c meProfile: %O', 'color: purple;', meProfile);
            if (meProfile && meProfile.profileUrl) {
                redirectUrl = (isOnLocalHost ? 'http://localhost:3000' : 'https://udicci.me') + meProfile.profileUrl;
            }
        }
    }
    // console.log('%c GoToProfileUI redirectUrl: %O', 'color: purple;', redirectUrl);

    useEffect(() => {
        progressRef.current = () => {
            // console.log('%c progress: %O', 'color: red;', progress);
            // console.log('%c maxSteps: %O', 'color: purple;', maxSteps);
            if (progress >= maxSteps) {
                // console.log('%c redirectUrl: %O', 'color: purple;', redirectUrl);
                clearTheTimer();
                if (!redirected && redirectUrl) {
                    redirect(true);
                    window.open(redirectUrl, '_self')?.focus();
                }
            } else {
                setProgress(progress + 1);
            }
        };
    });

    useEffect(() => {
        timerRef.current = setInterval(() => {
            progressRef.current();
        }, 250);

        return () => {
            clearTheTimer();
        };
    }, []);

    const clearTheTimer = () => {
        if (timerRef) {
            clearInterval(timerRef.current);
            setProgress(0);
        }
    }

    const cancelAction = (evt: any) => {
        // console.log('%c cancelAction actionIsProcessing: %O', 'color: red;', actionIsProcessing);
        clearTheTimer();
        if (props && props.onClose) props.onClose();
    };

    const goNowAction = (evt: any) => {
        clearTheTimer();
        if (redirectUrl) {
            redirect(true);
            window.open(redirectUrl, '_self')?.focus();
        }
    };

    let cancelActionButton: any = (
        <Button size="small" variant="contained" onClick={cancelAction} color="info" sx={udicciClasses.recordActionButton}>
            Cancel
        </Button>
    );

    let goNowActionButton: any = (
        <Button size="small" variant="contained" onClick={goNowAction} color="primary" sx={udicciClasses.recordActionButton}>
            Go Now
        </Button>
    );

    let linearProgressElement: any = (
        <LinearProgress variant="determinate" value={progress * (100/maxSteps)}  />
    );

    return (
        <Box sx={udicciClasses.content}>
            Redirecting to {redirectUrl} ...
            {linearProgressElement}
            <Box sx={udicciClasses.buttonContainer}>
                {cancelActionButton}
                {goNowActionButton}
            </Box>
        </Box>
    );
};
