
import React, { Fragment, useState, CSSProperties } from 'react';

import Pluralize from 'pluralize';

import {
    Box, Typography, Table, TableHead, TableFooter, TableBody, TableRow, TableCell,
    Button, ButtonGroup, Icon, MenuItem, Select, InputLabel, Input, IconButton
} from '@mui/material';

// import useUdicciRecord from 'src/hooks/useUdicciRecord';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext } from 'src/context/udicci-context'
// import { getUdicciData } from 'src/context/udicci-context';

import { find, forEach, values, keys } from 'underscore';

import { useCSVReader } from 'react-papaparse';
import { UdicciRecord } from 'src/classes/udicci-record';

const styles = {
    csvReader: {
        display: 'block',
        marginBottom: 10,
    } as CSSProperties,
    browseFile: {
        width: '100%',
    } as CSSProperties,
    acceptedFile: {
        border: '1px solid #ccc',
        height: 45,
        lineHeight: 2.5,
        paddingLeft: 10,
        width: '80%',
    } as CSSProperties,
    remove: {
        borderRadius: 0,
        padding: '0 20px',
    } as CSSProperties,
    progressBarBackgroundColor: {
        backgroundColor: 'red',
    } as CSSProperties,
};

const MediatorDataImportForm: React.FC<any> = (props: any) => {
    // console.log('%c MediatorDataImportForm props: %O', 'color: red;', props);

    let sampleSize: number = 5;

    const [uiState, flashUI] = useState<boolean>(false);
    const [showData, setShowData] = useState<boolean>(false);
    const [currentDisplay, setDisplay] = useState<string>('prepare');
    const [importedData, setImportedData] = useState<any>(null);
    const [importedDataHeader, setImportedDataHeader] = useState<any>(null);
    const [mappings, setMappings] = useState<any>(null);
    const [configMap, setConfigMap] = useState<number>(-1);
    const [importField, setImportField] = useState<number>(-1);
    const [currentFieldDisplay, setFieldDisplay] = useState<string>('choose');
    const [maxRowsToDisplay, setMaxRowsToDisplay] = useState<number>(sampleSize);
    // console.log('%c MediatorDataImportForm currentDisplay: %O', 'color: purple; font-weight: bold;', currentDisplay);
    // console.log('%c MediatorDataImportForm importedData: %O', 'color: purple; font-weight: bold;', importedData);
    // console.log('%c MediatorDataImportForm importedDataHeader: %O', 'color: purple; font-weight: bold;', importedDataHeader);
    // console.log('%c MediatorDataImportForm mappings: %O', 'color: purple; font-weight: bold;', mappings);
    // console.log('%c MediatorDataImportForm configMap: %O', 'color: purple; font-weight: bold;', configMap);
    // console.log('%c MediatorDataImportForm importField: %O', 'color: purple; font-weight: bold;', importField);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    const { CSVReader } = useCSVReader();

    let { udicci } = udicciContext.state;

    let { mediator, onClose } = props;
    // console.log('%c MediatorDataImportForm mediator: %O', 'color: purple; font-weight: bold;', mediator);

    let mediatorStructure: any = udicciHelpers.getMediatorStructure(mediator.name);
    // console.log('%c mediatorStructure: %O', 'color: hotpink;', mediatorStructure);
    // if (!mediatorStructure) {
    //     let requestMediators: string[] = [];
    //     requestMediators.push(mediator.name);
    //     udicci.getMediatorStructures(requestMediators);
    // }

    let selectedMediatorStructure: any = null;
    if (configMap >= 0 && mappings && mappings.map) {
        let configureMap: any = mappings.map[configMap];
        // console.log('%c configureMap: %O', 'color: maroon;', configureMap);
        if (configureMap && configureMap.mediator) {
            selectedMediatorStructure = udicciHelpers.getMediatorStructure(configureMap.mediator);
        }
    }

    if (!selectedMediatorStructure && mediatorStructure) {
        selectedMediatorStructure = {};
        Object.assign(selectedMediatorStructure, mediatorStructure);
    }
    // console.log('%c selectedMediatorStructure: %O', 'color: hotpink;', selectedMediatorStructure);

    const loadMediatorStructure = () => {
        // console.log('%c loadMediatorStructure mappings: %O', 'color: blue;', mappings);
        // console.log('%c loadMediatorStructure configMap: %O', 'color: blue;', configMap);
        if (mappings && mappings.map && configMap >= 0) {
            let configureMap: any = mappings.map[configMap];
            // console.log('%c loadMediatorStructure configureMap: %O', 'color: maroon;', configureMap);

            if (configureMap.mediator) {
                let structure: any = udicciHelpers.getMediatorStructure(configureMap.mediator);
                // console.log('%c loadMediatorStructure structure: %O', 'color: hotpink;', structure);
                if (!structure) {
                    let requestMediators: string[] = [];
                    requestMediators.push(configureMap.mediator);
                    udicci.getMediatorStructures(requestMediators);
                }
            }
        }

        flashUI(!uiState);
    };

    const onUploadCsvFile = (results: any) => {
        // console.log('%c onUploadCsvFile results: %O', 'color: blue;', results);

        let resultData: any = (results && results.data ? results.data : null);
        // console.log('%c onUploadCsvFile resultData: %O', 'color: blue;', resultData);
        let headerRow: any[] = resultData[0];
        if (resultData && resultData.length > 0) {
            // console.log('%c onUploadCsvFile headerRow: %O', 'color: blue;', headerRow);
            let rowStructure: any = {};
            forEach(headerRow, (headerColumn: string) => {
                // console.log('%c onUploadCsvFile headerColumn: %O', 'color: blue;', headerColumn);
                rowStructure[headerColumn] = '';
            });
            // console.log('%c onUploadCsvFile rowStructure: %O', 'color: blue;', rowStructure);

            let records: any[] = [];
            forEach(resultData, (row: string[], rowIndex: number) => {
                // skip the header row
                if (rowIndex > 0) {
                    // console.log('%c row: %O', 'color: blue;', row);
                    // console.log('%c rowIndex: %O', 'color: blue;', rowIndex);

                    let record: any = {};
                    Object.assign(record, rowStructure);
                    forEach(row, (column: string, colIndex: number) => {
                        // console.log('%c column: %O', 'color: blue;', column);
                        // console.log('%c colIndex: %O', 'color: blue;', colIndex);
                        let headerCol: string = (headerRow && headerRow.length > colIndex ? headerRow[colIndex] : '');
                        // console.log('%c headerCol: %O', 'color: blue;', headerCol);
                        record[headerCol] = column;
                    });

                    records.push(record);
                }
            });
            // console.log('%c onUploadCsvFile records: %O', 'color: blue;', records);
            setImportedData(records);
            setImportedDataHeader(headerRow);
        } else {
            setImportedData(null);
            setImportedDataHeader(null);
        }
        flashUI(!uiState);
    };

    const addMapping = (afterIndex?: number | undefined) => {
        let updatedMappings: any = {};
        if (mappings) Object.assign(updatedMappings, mappings);
        // console.log('%c addMapping updatedMappings: %O', 'color: purple;', updatedMappings);
        if (!updatedMappings.map) updatedMappings.map = [];

        let setMapIndex: number = 0;
        let newMapping: any = {
            mediator: '',
            field: '',
            config: []
        };
        if (afterIndex !== undefined && afterIndex >= 0 && afterIndex < updatedMappings.map.length) {
            updatedMappings.map.splice((afterIndex !== undefined ? afterIndex : 0), 0, newMapping);
            setMapIndex = (afterIndex !== undefined ? (updatedMappings.map.length - 1) : 0);
        } else {
            updatedMappings.map.push(newMapping);
            setMapIndex = updatedMappings.map.length - 1;
        }

        setMappings(updatedMappings);
        setConfigMap(setMapIndex);
    };

    const removeMapping = (mapIndex: number) => {
        let updatedMappings: any = {};
        if (mappings) Object.assign(updatedMappings, mappings);
        // console.log('%c removeMapping updatedMappings: %O', 'color: purple;', updatedMappings);
        if (!updatedMappings.map) updatedMappings.map = [];

        if (mapIndex >= 0 && mapIndex < updatedMappings.map.length) {
            updatedMappings.map.splice(mapIndex, 1);
        }

        setMappings(updatedMappings);
        setConfigMap(-1)
    };

    const handleChangeValue = (evt: any) => {
        let trgt = evt.target;
        let targetName = (trgt ? trgt.name : '');
        // console.log('%c handleChangeValue targetName: %O', 'color: maroon;', targetName);
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c handleChangeValue newValue: %O', 'color: maroon;', newValue);
        if (mappings && mappings.map && configMap >= 0) {
            let updatedMappings: any = {};
            if (mappings) Object.assign(updatedMappings, mappings);
            // console.log('%c handleChangeValue updatedMappings: %O', 'color: purple;', updatedMappings);

            let configureMap: any = updatedMappings.map[configMap];
            // console.log('%c handleChangeValue configureMap: %O', 'color: maroon;', configureMap);
            // console.log('%c handleChangeValue importField: %O', 'color: maroon;', importField);

            if (targetName === 'mediator') {
                configureMap.mediator = newValue;
                configureMap.field = '';
                if (!configureMap.config) configureMap.config = [];
            }

            if (targetName === 'field') {
                if (!configureMap.mediator && configMap > 0) {
                    let previousMap: any = mappings.map[configMap - 1];
                    // console.log('%c previousMap: %O', 'color: hotpink;', previousMap);
                    if (previousMap && previousMap.mediator) configureMap.mediator = previousMap.mediator;
                }
                configureMap.field = newValue;
                if (!configureMap.config) configureMap.config = [];
            }

            if (targetName === 'import.field' || targetName === 'import.custom') {
                if (importField >= 0 && configureMap.config.length > importField) {
                    if (targetName === 'import.custom') {
                        configureMap.config[importField].field = '';
                        configureMap.config[importField].customValue = newValue;
                    } else {
                        configureMap.config[importField].field = newValue;
                        configureMap.config[importField].customValue = '';
                    }
                } else {
                    let newVal: any = {
                        field: '',
                        customValue: '',
                    };
                    if (targetName === 'import.custom') {
                        newVal.customValue = newValue;
                    } else {
                        newVal.field = newValue;
                    }
                    configureMap.config.push(newVal);
                    setImportField(configureMap.config.length - 1);
                }
            }

            // if lum - set lum
            // console.log('%c handleChangeValue configureMap: %O', 'color: purple;', configureMap);
            if (mediatorStructure && configureMap && configureMap.mediator && mediatorStructure.Name !== configureMap.mediator) {
                let linkedMediators: any = (mediatorStructure.LinkedUdicciMediators ? mediatorStructure.LinkedUdicciMediators : null);
                // console.log('%c handleChangeValue linkedMediators: %O', 'color: purple;', linkedMediators);
                let lumChild: any = find(linkedMediators, (linkedMediator: any) => {
                    let parentMediator: string = linkedMediator.LeftUdicciMediatorName;
                    let childMediator: string = linkedMediator.RightUdicciMediatorName;
                    let mainIsParent: boolean = (parentMediator === mediatorStructure.Name ? true : false);
                    if (mainIsParent) {
                        let childIsSelectedMediator: boolean = (childMediator === configureMap.mediator ? true : false);
                        return childIsSelectedMediator;
                    }
                });
                // console.log('%c handleChangeValue lumChild: %O', 'color: purple;', lumChild);
                if (lumChild) {
                    configureMap.lum = lumChild;
                    configureMap.relationship = 'child';
                } else {
                    let lumParent: any = find(linkedMediators, (linkedMediator: any) => {
                        let parentMediator: string = linkedMediator.LeftUdicciMediatorName;
                        let childMediator: string = linkedMediator.RightUdicciMediatorName;
                        let mainIsChild: boolean = (childMediator === mediatorStructure.Name ? true : false);
                        if (mainIsChild) {
                            let parentIsSelectedMediator: boolean = (parentMediator === configureMap.mediator ? true : false);
                            return parentIsSelectedMediator;
                        }
                    });
                    // console.log('%c handleChangeValue lumParent: %O', 'color: purple;', lumParent);
                    if (lumParent) {
                        configureMap.lum = lumParent;
                        configureMap.relationship = 'parent';
                    }
                }
            }
            
            updatedMappings.map[configMap] = configureMap;

            setMappings(updatedMappings);
            loadMediatorStructure();
            flashUI(!uiState);
        }
    };

    const addMapOption = (afterIndex?: number | undefined) => {
        if (mappings && mappings.map && configMap >= 0) {
            let updatedMappings: any = {};
            if (mappings) Object.assign(updatedMappings, mappings);
            // console.log('%c addMapOption updatedMappings: %O', 'color: purple;', updatedMappings);

            let selectImportFieldIndex: number = -1;
            let configureMap: any = updatedMappings.map[configMap];
            // console.log('%c addMapOption configureMap: %O', 'color: maroon;', configureMap);
            let newConfigOption: any = {
                field: '',
                customValue: '',
            };
            if (afterIndex === undefined || (afterIndex !== undefined && afterIndex >= 0 && afterIndex < configureMap.config.length)) {
                configureMap.config.splice(afterIndex, 0, newConfigOption);
                selectImportFieldIndex = (afterIndex !== undefined ? afterIndex : 0);
            } else {
                configureMap.config.push(newConfigOption);
                selectImportFieldIndex = configureMap.config.length - 1
            }

            updatedMappings.map[configMap] = configureMap;
            setMappings(updatedMappings);
            setImportField(selectImportFieldIndex);
            loadMediatorStructure();
            flashUI(!uiState);
        }
    };

    const removeMapOption = (optionIndex: number) => {
        let updatedMappings: any = {};
        if (mappings) Object.assign(updatedMappings, mappings);
        // console.log('%c removeMapping updatedMappings: %O', 'color: purple;', updatedMappings);
        if (!updatedMappings.map) updatedMappings.map = [];

        let configureMap: any = updatedMappings.map[configMap];
        // console.log('%c removeMapOption configureMap: %O', 'color: maroon;', configureMap);
        if (!configureMap.config) configureMap.config = [];

        if (optionIndex >= 0 && optionIndex < configureMap.config.length) {
            configureMap.config.splice(optionIndex, 1);
        }

        updatedMappings.map[configMap] = configureMap;

        setMappings(updatedMappings);
        setImportField(-1)
    };

    const moveMapOption = (moveIndex: number, direction: string = 'down') => {
        let updatedMappings: any = {};
        if (mappings) Object.assign(updatedMappings, mappings);
        // console.log('%c removeMapping updatedMappings: %O', 'color: purple;', updatedMappings);
        if (!updatedMappings.map) updatedMappings.map = [];

        let configureMap: any = updatedMappings.map[configMap];
        // console.log('%c removeMapOption configureMap: %O', 'color: maroon;', configureMap);
        if (!configureMap.config) configureMap.config = [];

        if (moveIndex >= 0 && moveIndex < configureMap.config.length) {
            let targetIndex = moveIndex;
            if (direction.toString().toLowerCase() === 'up') targetIndex--;
            if (direction.toString().toLowerCase() === 'down') targetIndex++;
            // console.log('%c targetIndex: %O', 'color: blue;', targetIndex);
    
            configureMap.config = udicciHelpers.swapArrayElements(configureMap.config, moveIndex, targetIndex);
            // console.log('%c moveFieldItem formFields: %O', 'color: maroon;', formFields);

            updatedMappings.map[configMap] = configureMap;

            setMappings(updatedMappings);
            setImportField(targetIndex)
        }
    };

    const getBulkImportRecords = () => {
        // console.log('%c getBulkImportRecords importedData: %O', 'color: red; font-weight: bold;', importedData);
        // console.log('%c getBulkImportRecords importedDataHeader: %O', 'color: red; font-weight: bold;', importedDataHeader);
        // console.log('%c getBulkImportRecords mappings: %O', 'color: red; font-weight: bold;', mappings);

        let bulkRecords: any[] = [];
        if (mappings && mappings.map && mappings.map.length > 0) {
            forEach(importedData, function(importRecord: any, idxImportRecord: number) {
                // console.log('%c importRecord: %O', 'color: hotpink;', importRecord);
                // console.log('%c idxMap: %O', 'color: hotpink;', idxMap);
                let record: UdicciRecord = getBulkImportRecord(importRecord);
                // console.log('%c record: %O', 'color: hotpink;', record);
                // only add the record if it is marked as dirty, otherwise the record is likely a dud ... aka empty, no fields have data, etc.
                if (record.isDirty) bulkRecords.push(record);
            });
        }
        // console.log('%c review bulkRecords: %O', 'color: red; font-weight: bold;', bulkRecords);
        return bulkRecords;
    };

    const getBulkImportRecord = (importRecord: any) => {
        // console.log('%c getBulkImportRecord importRecord: %O', 'color: red; font-weight: bold;', importRecord);
        // console.log('%c getBulkImportRecord mappings: %O', 'color: red; font-weight: bold;', mappings);

        let newRecord: UdicciRecord = new UdicciRecord(mediatorStructure.Name, {}, mediatorStructure);
        // console.log('%c getBulkImportRecord newRecord: %O', 'color: red; font-weight: bold;', newRecord);

        if (mappings && mappings.map && mappings.map.length > 0) {
            forEach(values(mappings.map), function(mapConfig: any, idxMap: number) {
                // console.log('%c mapConfig: %O', 'color: hotpink;', mapConfig);

                let newValue: string = '';
                if (mapConfig.config.length > 0) {
                    forEach(mapConfig.config, function(option: any, idxOption: number) {
                        // console.log('%c option: %O', 'color: hotpink;', option);
                        if (option.field) {
                            newValue += importRecord[option.field].toString();
                        } else if (option.customValue !== '') {
                            newValue += option.customValue;
                        }
                    });
                }
                // console.log('%c newValue: %O', 'color: blue;', newValue);

                if (mapConfig.mediator === newRecord.udicciMediator) {
                    newRecord.data[mapConfig.field] = newValue.trim();
                    if (newValue) newRecord.isDirty = true;
                } else {
                    // console.log('%c mapConfig.mediator: %O', 'color: red;', mapConfig.mediator);
                    // console.log('%c newValue: %O', 'color: red;', newValue);

                    let recContext: any = newRecord.context;
                    // console.log('%c recContext: %O', 'color: red;', recContext);
                    if (!recContext) recContext = {};

                    if (mapConfig.relationship && mapConfig.relationship === 'child') {
                        let children: any = recContext.children;
                        // console.log('%c children: %O', 'color: red;', children);
                        if (!children) children = {};

                        let childMediatorStructure: any = udicciHelpers.getMediatorStructure(mapConfig.mediator);
                        let childRecord: UdicciRecord | null = null;
                        if (children[mapConfig.mediator]) {
                            childRecord = children[mapConfig.mediator];
                        } else {
                            childRecord = new UdicciRecord(mapConfig.mediator, {}, childMediatorStructure);
                        }
                        // console.log('%c getBulkImportRecord childRecord: %O', 'color: red; font-weight: bold;', childRecord);

                        if (childRecord) {
                            childRecord.data[mapConfig.field] = newValue.trim();
                            childRecord.isDirty = (childRecord.data[mapConfig.field] ? true : false);
                            if (childRecord.setKeyFieldValues) childRecord.setKeyFieldValues();
                            children[mapConfig.mediator] = childRecord;
                            recContext.children = children;
                        }
                    }

                    if (mapConfig.relationship && mapConfig.relationship === 'parent') {
                        let parents: any = recContext.parents;
                        // console.log('%c parents: %O', 'color: red;', parents);
                        if (!parents) parents = {};

                        let parentRecord: UdicciRecord | null = null;
                        if (parents[mapConfig.mediator]) {
                            parentRecord = parents[mapConfig.mediator];
                        } else {
                            let parentMediatorStructure: any = udicciHelpers.getMediatorStructure(mapConfig.mediator);
                            parentRecord = new UdicciRecord(mapConfig.mediator, {}, parentMediatorStructure);
                        }
                        // console.log('%c getBulkImportRecord parentRecord: %O', 'color: red; font-weight: bold;', parentRecord);

                        if (parentRecord) {
                            parentRecord.data[mapConfig.field] = newValue.trim();
                            parentRecord.isDirty = (parentRecord.data[mapConfig.field] ? true : false);
                            if (parentRecord.setKeyFieldValues) parentRecord.setKeyFieldValues();
                            parents[mapConfig.mediator] = parentRecord;
                            recContext.parents = parents;
                        }
                    }

                    if (recContext && recContext.children) {
                        // need to cleanup up children with no values
                        let removeChildKeys: string[] = [];
                        forEach(keys(recContext.children), function(childMediatorName: string) {
                            let validateChildRecord: UdicciRecord = recContext.children[childMediatorName];
                            if (!validateChildRecord.isDirty) {
                                // if it wasn't changed then we can simply remove it.
                                if (removeChildKeys.indexOf(childMediatorName) < 0) removeChildKeys.push(childMediatorName);
                            }
                        });
                        if (removeChildKeys.length > 0) {
                            forEach(removeChildKeys, function(childMediatorName: string) {
                                delete recContext.children[childMediatorName];
                            });    
                        }
                        if (keys(recContext.children).length <= 0) delete recContext["children"];
                    }
                    if (recContext && recContext.parents) {
                        // need to cleanup up parents with no values
                        let removeParentKeys: string[] = [];
                        forEach(keys(recContext.parents), function(parentMediatorName: string) {
                            let validateParentRecord: UdicciRecord = recContext.children[parentMediatorName];
                            if (!validateParentRecord.isDirty) {
                                // if it wasn't changed then we can simply remove it.
                                if (removeParentKeys.indexOf(parentMediatorName) < 0) removeParentKeys.push(parentMediatorName);
                            }
                        });
                        if (removeParentKeys.length > 0) {
                            forEach(removeParentKeys, function(parentMediatorName: string) {
                                delete recContext.parents[parentMediatorName];
                            });    
                        }
                        if (keys(recContext.parents).length <= 0) delete recContext["parents"];
                    }

                    // console.log('%c recContext: %O', 'color: red;', recContext);
                    newRecord.context = recContext;
                }

                newRecord.title = newRecord.data[newRecord.keys.title];
                newRecord.description = newRecord.data[newRecord.keys.description];
            });
        }
        // console.log('%c getBulkImportRecord newRecord: %O', 'color: red; font-weight: bold;', newRecord);

        return newRecord;
    };

    let numberRecordsDisplayed: number = 0;
    let headerRows: any[] = [];
    if (importedDataHeader) {
        let cols: any[] = [];
        forEach(values(importedDataHeader), function(hdrTitle: any, idxHdr: number) {
            // console.log('%c hdrTitle: %O', 'color: purple;', hdrTitle);
            cols.push(
                <TableCell key={'imported.data.header.row.column.' + idxHdr.toString()}>
                    {hdrTitle}
                </TableCell>
            );
        });
        if (cols.length > 0) {
            headerRows.push(<TableRow key={'imported.data.header.row'}>{cols}</TableRow>);
        }
    }

    let mediatorManagementElement: any = null;
    if (currentDisplay === 'prepare') {
        let csvReaderElement: any = (
            <Box sx={{ margin: '8px', display: 'flow-root' }}>
                <CSVReader header={true} onUploadAccepted={onUploadCsvFile}>
                    {({
                        getRootProps,
                        acceptedFile,
                        ProgressBar,
                        getRemoveFileProps,
                    }: any) => {
                        return (
                            <>
                                <div style={styles.csvReader}>
                                    <button type='button' {...getRootProps()} style={styles.browseFile}>
                                        Browse file
                                    </button>
                                    <div style={styles.acceptedFile}>
                                        {acceptedFile && acceptedFile.name}
                                    </div>
                                    <button {...getRemoveFileProps()} style={styles.remove}>
                                        Remove
                                    </button>
                                </div>
                                <ProgressBar style={styles.progressBarBackgroundColor} />
                            </>
                        )
                    }}
                </CSVReader>
            </Box>
        );
        if (importedData && importedData.length > 0) {
            // console.log('%c importedDataHeader: %O', 'color: blue;', importedDataHeader);

            let importedDataCount: number = importedData.length;
            let importedDataColumnCount: number = 0;

            let dataRows: any[] = [];
            forEach(values(importedData), function(rec: any, idxRec: number) {
                // console.log('%c rec: %O', 'color: purple;', rec);
                if ((maxRowsToDisplay > 0) && ((idxRec + 1) > maxRowsToDisplay)) return false;

                let cols: any[] = [];
                forEach(values(importedDataHeader), function(hdrTitle: any, idxHdr: number) {
                    // console.log('%c hdrTitle: %O', 'color: purple;', hdrTitle);
                    if (idxRec === 0) importedDataColumnCount++;

                    cols.push(
                        <TableCell key={'imported.data.record.row.' + idxRec.toString() + '.column.' + idxHdr.toString()}>
                            {(rec[hdrTitle] ? rec[hdrTitle] : '')}
                        </TableCell>
                    );
                });
                if (cols.length > 0) {
                    dataRows.push(<TableRow key={'imported.data.record.row.' + idxRec.toString()}>{cols}</TableRow>);
                    numberRecordsDisplayed++;
                }
            });

            if (dataRows.length > 0) {
                mediatorManagementElement = (
                    <Box sx={{ margin: '8px', display: 'block' }}>
                        <Box sx={{ margin: '8px' }}>{csvReaderElement}</Box>
                        <Box sx={{ display: 'inline' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    {headerRows}
                                </TableHead>
                                <TableBody>
                                    {dataRows}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={importedDataColumnCount} align={'right'}>
                                            <Typography variant="caption" component="span">
                                                Showing
                                            </Typography>
                                            <Typography variant="subtitle1" component="span" sx={{ marginLeft: '4px' }}>
                                                {numberRecordsDisplayed}
                                            </Typography>
                                            <Typography variant="caption" component="span" sx={{ marginLeft: '4px' }}>
                                                of
                                            </Typography>
                                            <Typography variant="subtitle1" component="span" sx={{ marginLeft: '4px' }}>
                                                {importedDataCount}
                                            </Typography>
                                            <Typography variant="caption" component="span" sx={{ marginLeft: '4px' }}>
                                                records from the data import file.
                                            </Typography>
                                            <Typography variant="body2"
                                                        component="span"
                                                        sx={{ cursor: 'pointer', float: 'left' }}
                                                        onClick={(et: any) => setMaxRowsToDisplay((maxRowsToDisplay > 0 ? 0 : sampleSize))}
                                            >
                                                {(maxRowsToDisplay > 0 ? "show all" : "show sample")}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Box>
                    </Box>
                );
            } else {
                mediatorManagementElement = (
                    <Box sx={{ margin: '8px', display: 'block' }}>
                        <Box sx={{ margin: '8px', display: 'flow-root' }}>{csvReaderElement}</Box>
                        <Box sx={{ display: 'inline' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    {headerRows}
                                </TableHead>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={importedDataColumnCount} align={'right'}>
                                            <Typography variant="caption" component="span">
                                                Total records to import:
                                            </Typography>
                                            <Typography variant="subtitle1" component="span" sx={{ marginLeft: '8px' }}>
                                                {importedDataCount}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Box>
                    </Box>
                );
            }
        } else {
            mediatorManagementElement = (
                <Box sx={{ margin: '8px', display: 'flow-root' }}>{csvReaderElement}</Box>
            );
        }
    }

    if (currentDisplay === 'configure') {
        let structureLoaded: boolean = false;
        let mediatorFields: any = null;
        let linkedMediators: any = null;
        if (configMap >= 0) {
            if (selectedMediatorStructure && selectedMediatorStructure.UdicciMediatorFields) {
                mediatorFields = selectedMediatorStructure.UdicciMediatorFields;
                linkedMediators = selectedMediatorStructure.LinkedUdicciMediators;
                structureLoaded = true;
            }
        } else if (mediatorStructure) {
            mediatorFields = mediatorStructure.UdicciMediatorFields;
            linkedMediators = mediatorStructure.LinkedUdicciMediators;
            structureLoaded = true;
        }

        if ((!structureLoaded && mappings && mappings.map && mappings.map.length > 0) || (mediatorFields && mediatorFields.length > 0)) {
            let buttonIconSettings: any = {
                sx: { marginRight: '8px', cursor: 'pointer', float: 'right' }
            };
            let subButtonIconSettings: any = {
                sx: { marginRight: '8px', cursor: 'pointer', float: 'left' }
            };
            let fieldRows: any[] = [];
            if (mappings && mappings.map && mappings.map.length > 0) {
                forEach(values(mappings.map), function(mapConfig: any, idxMap: number) {
                    // console.log('%c mapConfig: %O', 'color: hotpink;', mapConfig);
                    // console.log('%c idxMap: %O', 'color: hotpink;', idxMap);

                    let elMediator: any = null;
                    let elField: any = null;
                    let elImport: any = null;
                    let commandsElement: any = null;
                    if (configMap === idxMap) {
                        // console.log('%c mediatorStructure: %O', 'color: hotpink;', mediatorStructure);
                        // console.log('%c mediatorFields: %O', 'color: hotpink;', mediatorFields);
                        // console.log('%c linkedMediators: %O', 'color: hotpink;', linkedMediators);
                        // console.log('%c mediator: %O', 'color: hotpink;', mediator);

                        let mediatorSelections = [];
                        mediatorSelections.push(<MenuItem key={'mediator.not.selected'} value={''}> Select Mediator </MenuItem>);
                        mediatorSelections.push(<MenuItem key={'mediator.' + mediator.id} value={mediator.name}> {Pluralize.singular(mediator.name)} </MenuItem>);
                        if (linkedMediators) {
                            forEach(linkedMediators, function(lum: any, idxLum: number) {
                                // console.log('%c lum: %O', 'color: hotpink;', lum);
                                let medId = (lum.LinkedUdicciMediatorId ? lum.LinkedUdicciMediatorId : 0);
                                let medName = (lum.LeftUdicciMediatorName === mediator.name ? lum.RightUdicciMediatorName : lum.LeftUdicciMediatorName);
                                // console.log('%c medName: %O', 'color: hotpink;', medName);
                                mediatorSelections.push(<MenuItem key={'mediator.' + medId} value={medName}> {Pluralize.singular(medName)} </MenuItem>);
                            });
                        }

                        let selectedMediatorName: string = mapConfig.mediator;
                        if (!selectedMediatorName && idxMap > 0) {
                            let previousMap: any = mappings.map[idxMap - 1];
                            // console.log('%c previousMap: %O', 'color: hotpink;', previousMap);
                            if (previousMap && previousMap.mediator) selectedMediatorName = previousMap.mediator;
                        }

                        elMediator = (
                            <Fragment>
                                <Select value={selectedMediatorName} onChange={handleChangeValue}
                                        fullWidth={true} variant="standard"
                                        inputProps={{ name: 'mediator', id: 'select-mediator', }}
                                >
                                    {mediatorSelections}
                                </Select>
                            </Fragment>
                        );

                        if (selectedMediatorName) {
                            let _mediatorFields: any[] | null = null;
                            let configStructure: any = udicciHelpers.getMediatorStructure(selectedMediatorName);
                            // console.log('%c configStructure: %O', 'color: blue;', configStructure);
                            if (configStructure) {
                                _mediatorFields = configStructure.UdicciMediatorFields;
                            } else {
                                _mediatorFields = [];
                            }

                            if (!_mediatorFields) {
                                if (mediatorStructure) {
                                    _mediatorFields = mediatorStructure.UdicciMediatorFields;
                                } else {
                                    _mediatorFields = [];
                                }
                            }
                            // console.log('%c _mediatorFields: %O', 'color: blue;', _mediatorFields);

                            let fieldSelections = [];
                            fieldSelections.push(<MenuItem key={'mediator.field.not.selected'} value={''}> Select Field </MenuItem>);
                            if (_mediatorFields) {
                                forEach(_mediatorFields, function(fld: any, idxFld: number) {
                                    // console.log('%c fld: %O', 'color: hotpink;', fld);
                                    let fldId = (fld.UdicciMediatorFieldId ? fld.UdicciMediatorFieldId : 0);
                                    let fldJsonKey = fld.JsonFieldName;
                                    let fldName = (fld.DisplayName ? fld.DisplayName : fld.Name);
                                    // console.log('%c medName: %O', 'color: hotpink;', medName);
                                    fieldSelections.push(<MenuItem key={'mediator.field.' + fldId} value={fldJsonKey}> {fldName} </MenuItem>);
                                });
                            }

                            elField = (
                                <Fragment>
                                    <Select value={mapConfig.field} onChange={handleChangeValue}
                                            fullWidth={true} variant="standard"
                                            inputProps={{ name: 'field', id: 'select-mediator-field', }}
                                    >
                                        {fieldSelections}
                                    </Select>
                                </Fragment>
                            );
                        } else {
                            elField = (<Fragment>&nbsp;</Fragment>);
                        }

                        if (importedDataHeader) {
                            // console.log('%c mapConfig.config: %O', 'color: hotpink;', mapConfig.config);
                            // console.log('%c importField: %O', 'color: hotpink;', importField);

                            let selImpField: any = null;
                            if (importField >= 0) {
                                selImpField = mapConfig.config[importField];
                            }
                            // console.log('%c selImpField: %O', 'color: hotpink;', selImpField);
                            let selectedFieldName: string = (selImpField && selImpField.field ? selImpField.field : '');
                            let customValue: string = (selImpField && selImpField.customValue ? selImpField.customValue : '');

                            let importFieldSelections: any[] = [];
                            importFieldSelections.push(<MenuItem key={'import.field.not.selected'} value={''}> Select Field from Import </MenuItem>);
                            forEach(importedDataHeader, function(importFieldName: any, idxFld: number) {
                                // console.log('%c importFieldName: %O', 'color: hotpink;', importFieldName);
                                importFieldSelections.push(<MenuItem key={'import.field.' + idxFld} value={importFieldName}> {importFieldName} </MenuItem>);
                            });

                            let configDisplayElements: any[] = [];
                            if (mapConfig.config.length > 0) {
                                forEach(mapConfig.config, function(option: any, idxOption: number) {
                                    // console.log('%c option: %O', 'color: hotpink;', option);
                                    let configOptionElement: any = null;
                                    let optionCommandsElement: any = null;
                                    if (importField >= 0 && importField === idxOption) {
                                        optionCommandsElement = (
                                            <Fragment>
                                                <Icon onClick={(evt: any) => setFieldDisplay('choose')} {...buttonIconSettings}>data_array</Icon>
                                                <Icon onClick={(evt: any) => setFieldDisplay('custom')} {...buttonIconSettings}>keyboard</Icon>

                                                <Icon onClick={(evt: any) => setImportField(-1)} {...subButtonIconSettings}>close</Icon>
                                                <Icon onClick={(evt: any) => moveMapOption(idxOption, 'up')} {...subButtonIconSettings}>arrow_drop_up</Icon>
                                                <Icon onClick={(evt: any) => moveMapOption(idxOption, 'down')} {...subButtonIconSettings}>arrow_drop_down</Icon>
                                            </Fragment>
                                        );

                                        let configFormElement: any = null;
                                        if (currentFieldDisplay === 'custom') {
                                            configFormElement = (
                                                <Box>
                                                    <InputLabel htmlFor="import.custom">
                                                        Content Margin
                                                    </InputLabel>
                                                    <Input id="import.custom"
                                                            name='import.custom'
                                                            type={'text'}
                                                            fullWidth={true}
                                                            value={customValue}
                                                            onChange={(evt: any) => handleChangeValue(evt)} />
                                                </Box>
                                            );
                                        } else if (currentFieldDisplay === 'choose') {
                                            configFormElement = (
                                                <Box>
                                                    <Select value={selectedFieldName} onChange={handleChangeValue}
                                                            variant="standard" fullWidth={true}
                                                            inputProps={{ name: 'import.field', id: 'select-import-field' }}
                                                    >
                                                        {importFieldSelections}
                                                    </Select>
                                                </Box>
                                            );
                                        }

                                        configOptionElement = (
                                            <Box sx={{ margin: '8px', padding: '8px', background: 'rgba(255, 255, 255, 0.1)' }}>
                                                {optionCommandsElement}
                                                {configFormElement}
                                            </Box>
                                        );
                                    } else {
                                        optionCommandsElement = (
                                            <Fragment>
                                                <Icon onClick={(evt: any) => addMapOption(idxOption + 1)} {...subButtonIconSettings}>add</Icon>
                                                <Icon onClick={(evt: any) => removeMapOption(idxOption)} {...subButtonIconSettings}>remove</Icon>
                                                <Icon onClick={(evt: any) => setImportField(idxOption)} {...subButtonIconSettings}>edit</Icon>
                                            </Fragment>                                        
                                        );

                                        let displayValue: string = (option.customValue !== '' ? option.customValue : option.field);
                                        configOptionElement = (
                                            <Box>
                                                {optionCommandsElement}
                                                <Typography variant="body2"> {displayValue.replace(/ /g, "_")} </Typography>
                                            </Box>
                                        );
                                    }
                                    configDisplayElements.push(
                                        <Box key={'import.field.' + idxOption} sx={{ display: 'flow-root', margin: '8px' }}>
                                            {configOptionElement}
                                        </Box>
                                    );
                                });
                            } else {
                                let configOptionElement: any = (
                                    <Box>
                                        <Select value={selectedFieldName} onChange={handleChangeValue}
                                                variant="standard" fullWidth={true}
                                                inputProps={{ name: 'import.field', id: 'select-import-field', }}
                                        >
                                            {importFieldSelections}
                                        </Select>
                                    </Box>
                                );
                                configDisplayElements.push( <Box key={'import.field.new'}> {configOptionElement} </Box> );
                            }

                            elImport = ( <Box> {configDisplayElements} </Box> );
                        } else {
                            elImport = (<Fragment>&nbsp;</Fragment>);
                        }

                        commandsElement = (
                            <Box>
                                {/* <Icon {...buttonIconSettings}>save</Icon> */}
                                {/* <Icon onClick={(evt: any) => addMapOption(idxMap + 1)} {...buttonIconSettings}>add</Icon> */}
                                <Icon onClick={(evt: any) => { setConfigMap(-1); }} {...buttonIconSettings}>close</Icon>
                            </Box>
                        );
                    } else {
                        let configDisplayElements: any[] = [];
                        forEach(mapConfig.config, function(option: any, idxOption: number) {
                            // console.log('%c option: %O', 'color: hotpink;', option);
                            configDisplayElements.push(
                                <Box key={'import.field.' + idxOption}>
                                    <Typography variant="subtitle1" component="div">{option.field}</Typography>
                                </Box>
                            );
                        });

                        let configMediator: string = (mapConfig.mediator ? mapConfig.mediator : '');
                        let fieldDisplay: string = (mapConfig.field ? mapConfig.field : '');
                        if (fieldDisplay) {
                            // console.log('%c configMediator: %O', 'color: blue;', configMediator);
                            // console.log('%c fieldDisplay: %O', 'color: blue;', fieldDisplay);
                            let _mediatorFields: any[] | null = null;
                            if (configMediator) {
                                let configStructure: any = udicciHelpers.getMediatorStructure(configMediator);
                                // console.log('%c configStructure: %O', 'color: blue;', configStructure);
                                if (configStructure) {
                                    _mediatorFields = configStructure.UdicciMediatorFields;
                                } else {
                                    _mediatorFields = [];
                                }
                            }

                            if (!_mediatorFields) {
                                if (mediatorStructure) {
                                    _mediatorFields = mediatorStructure.UdicciMediatorFields;
                                } else {
                                    _mediatorFields = [];
                                }
                            }
                            // console.log('%c _mediatorFields: %O', 'color: blue;', _mediatorFields);

                            let fld: any = null;
                            if (_mediatorFields) fld = find(_mediatorFields, (fld: any) => fld.JsonFieldName === fieldDisplay);
                            // console.log('%c fld: %O', 'color: blue;', fld);
                            if (fld) fieldDisplay = fld.DisplayName;
                        }

                        elMediator = (<Fragment>{Pluralize.singular(mapConfig.mediator)}</Fragment>);
                        elField = (<Fragment>{fieldDisplay}</Fragment>);
                        elImport = (<Fragment>{configDisplayElements}</Fragment>);

                        commandsElement = (
                            <Box>
                                <Icon onClick={(evt: any) => { setConfigMap(idxMap); setImportField(-1); }} {...buttonIconSettings}>edit</Icon>
                                <Icon onClick={(evt: any) => { removeMapping(idxMap); }} {...buttonIconSettings}>remove</Icon>
                                <Icon onClick={(evt: any) => addMapping(idxMap + 1)} {...buttonIconSettings}>add</Icon>
                            </Box>
                        );
                    }

                    fieldRows.push(
                        <TableRow key={'map.field.row.' + idxMap.toString()}>
                            <TableCell>{elMediator}</TableCell>
                            <TableCell>{elField}</TableCell>
                            <TableCell>{elImport}</TableCell>
                            <TableCell align='right'>{commandsElement}</TableCell>
                        </TableRow>
                    );
                });
            } else {
                fieldRows.push(
                    <TableRow key={'no.map.field.row.0'}>
                        <TableCell colSpan={4}>No mapping configuration has been setup yet.</TableCell>
                    </TableRow>
                );
            }

            mediatorManagementElement = (
                <Box sx={{ margin: '8px' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Target Mediator</TableCell>
                                <TableCell>Target Field</TableCell>
                                <TableCell>Import Config</TableCell>
                                <TableCell align='right'>
                                    <Icon sx={{ cursor: 'pointer', margin: '4px' }} onClick={(evt: any) => addMapping()}>add</Icon>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fieldRows}
                        </TableBody>
                    </Table>
                </Box>
            );
        } else if (!structureLoaded) {
            mediatorManagementElement = (
                <Box sx={{ margin: '8px' }}>
                    <Typography variant="caption">
                        Waiting for Structure to load.
                    </Typography>
                </Box>
            );
        } else {
            mediatorManagementElement = (
                <Box sx={{ margin: '8px' }}>
                    <Typography variant="errorMessage">
                        Structure does not have usable fields defined.  Contact Platform Administrator.
                    </Typography>
                </Box>
            );
        }
    }

    if (currentDisplay === 'review') {
        let importRecords: any[] = getBulkImportRecords();
        // console.log('%c review importRecords: %O', 'color: red; font-weight: bold;', importRecords);

        let importDataReviewElement: any = null;
        if (showData) {
            let dataItems: any[] = [];
            let recStatElements: any[] = [];
            if (importRecords) {
                let recStats: any = {};
                forEach(importRecords, function(importRecord: any, idxImportRecord: number) {
                    // console.log('%c importRecord %s %s: %O', 'color: purple;', importRecord.udicciMediator, importRecord.title, importRecord);

                    let keyBase: string = 'import.record.' + (idxImportRecord + 1).toString();
                    // let commaElement: any = null;
                    // if (idxImportRecord > 0) {
                    //     let commaSettings: any = {
                    //         key: keyBase + '.comma',
                    //         variant: "caption",
                    //         component: "span",
                    //         sx: { marginRight: '4px' }
                    //     };
                    //     commaElement = (<Typography {...commaSettings}>,</Typography>);
                    // }

                    let mediatorName: string = importRecord.udicciMediator;
                    let mediatorNameSingular: string = Pluralize.singular(mediatorName);

                    if (!recStats[mediatorName]) {
                        recStats[mediatorName] = { count: 1 };
                    } else {
                        recStats[mediatorName].count++;
                    }

                    // let importRecordData: any = importRecord.data;
                    // console.log('%c importRecordData: %O', 'color: blue;', importRecordData);
                    let importRecordContext: any = (importRecord && importRecord.context ? importRecord.context : null);
                    // console.log('%c importRecordContext: %O', 'color: blue;', importRecordContext);
                    let children: any = (importRecordContext && importRecordContext.children ? importRecordContext.children : null);
                    // console.log('%c children: %O', 'color: blue;', children);

                    let childRecSummary: any[] = [];
                    if (children) {
                        forEach(keys(children),(childMediatorName: string) => {
                            // console.log('%c childMediatorName: %O', 'color: red;', childMediatorName);
                            let childObject: any = children[childMediatorName];
                            // console.log('%c childObject: %O', 'color: red;', childObject);
                            let childObjectType: string = typeof(childObject);
                            // console.log('%c childObjectType: %O', 'color: red;', childObjectType);
                            if (childObjectType === 'object') {
                                if (childObject.udicciMediator) {
                                    let childSettings: any = {
                                        key: keyBase + '.record.child.' + childObject.udicciMediator.toLowerCase(),
                                        variant: "caption",
                                        component: "div",
                                        sx: { marginLeft: '16px' }
                                    };
                                    childRecSummary.push(<Typography {...childSettings}>{childObject.udicciMediator} to be imported and associated: 1</Typography>);
                                    if (!recStats[childObject.udicciMediator]) {
                                        recStats[childObject.udicciMediator] = { count: 1 };
                                    } else {
                                        recStats[childObject.udicciMediator].count++;
                                    }
                                }
                            }
                        });
                    }

                    let settings: any = {
                        key: keyBase + '.record.title',
                        variant: "body2",
                        component: "div",
                    };
                    let containerSettings: any = { key: keyBase + '.record.title.container'};
                    dataItems.push(
                        <Box {...containerSettings}>
                            <Typography {...settings}>{mediatorNameSingular} to be imported: {importRecord.title}</Typography>
                            {childRecSummary}
                        </Box>
                    );
                });
                // console.log('%c recStats: %O', 'color: blue;', recStats);

                forEach(keys(recStats),(mediatorName: string, idxStat: number) => {
                    // console.log('%c mediatorName: %O', 'color: red;', mediatorName);
                    let mediatorStats: any = recStats[mediatorName];
                    // console.log('%c mediatorStats: %O', 'color: red;', mediatorStats);
                    if (mediatorStats && mediatorStats.count) {
                        let statSettings: any = {
                            key: 'mediator.stat.' + mediatorName.toLowerCase(),
                            variant: "errorMessage",
                            component: "div",
                            sx: { marginTop: '16px' }
                        };
                        if (idxStat > 0) statSettings.sx.marginTop = '2px';
                        recStatElements.push(<Typography {...statSettings}>Total {mediatorName} to be imported: {mediatorStats.count}</Typography>);
                    }
                });
            }
            importDataReviewElement = ( <Box sx={{ margin: '8px', clear: 'both' }}> {dataItems}{recStatElements} </Box> );
        }

        mediatorManagementElement = (
            <Box sx={{ margin: '8px', display: 'flex' }}>
                <Typography variant="caption" component="div">
                    1.  Built the main mediator records for import. &#10003;
                </Typography>
                <Typography variant="caption" component="div">
                    2.  Built and associated the children mediator records for import. &#10003;
                </Typography>
                <Typography variant="caption" component="div">
                    3.  Build and associated the parent mediator records for import. &#10003;
                </Typography>
                <Box>
                    <Typography variant="caption" component="span">
                        4.  Present the Data = Import Record Count: {(importRecords && importRecords.length ? importRecords.length : 0)} &#10003;
                    </Typography>
                    <IconButton size="small" onClick={(evt: any) => { setShowData(!showData); }} sx={{ marginLeft: '4px', cursor: 'pointer' }}>                        
                        {(showData ? (<Icon>expand_less</Icon>) : (<Icon>expand_more</Icon>))}
                    </IconButton>
                    {importDataReviewElement}
                </Box>
                <Typography variant="caption" component="div">
                    5.  Submit button is currently hidden
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', background: 'rgba(255, 255, 255, 0.1)', border: '1px dashed #4C6FB1' }}>
            <Box sx={{ display: 'flex', borderRight: '1px dashed #4C6FB1' }}>
                <ButtonGroup orientation="vertical" variant="text" color="info">
                    <Button color={(currentDisplay === 'prepare' ? "primary" : "info")} onClick={(evt: any) => setDisplay('prepare')}>Prepare</Button>
                    <Button color={(currentDisplay === 'configure' ? "primary" : "info")} onClick={(evt: any) => setDisplay('configure')}>Configure</Button>
                    <Button color={(currentDisplay === 'review' ? "primary" : "info")} onClick={(evt: any) => setDisplay('review')}>Review &amp; Import</Button>
                </ButtonGroup>
            </Box>
            <Box sx={{ display: 'flex' }}>
                {mediatorManagementElement}
            </Box>
        </Box>
    );
}

export default MediatorDataImportForm;
