import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/AddToQueue';

import { useUdicciContext } from 'src/context/udicci-context'
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

import { UdicciPluginComponent, UdicciPluginProps, udicciPluginComponentstate } from 'src/ui/udicci-ui-library';

const PluginDisplayComponent = dynamic(() => import('src/components/udicci-pane-section'));
const PluginAdminComponent = dynamic(() => import('src/admin/udicci-pane-section-admin'));

const PluginId: string = 'udicci.pane.section';
const PluginTitle: string = 'Pane Section';
const PluginDescription: string = 'This plugin can be used to build a collapsible section of plugins within a Pane.';
const PluginVersion: number = 1;

const PluginRenderer = (props: any) => {
    // console.log('%c %s PluginRenderer props: %O', 'color: red;', PluginTitle, props);
    if (!props.data) props.data = {};
    props.data.showInline = true;
    // console.log('%c %s PluginRenderer props.data: %O', 'color: red;', PluginTitle, props.data);
    return ( <PluginComponentWrapper {...props} /> );
};

const PluginAdminRenderer = (props: any) => {
    // console.log('%c %s PluginAdminRenderer props: %O', 'color: red;', PluginTitle, props);
    if (!props.data) props.data = {};
    props.data.showInline = true;
    // console.log('%c %s PluginAdminRenderer props.data: %O', 'color: red;', PluginTitle, props.data);
    return ( <PluginAdminComponent {...props} /> );
};

const PluginComponent: UdicciPlugin = {
    Renderer: PluginRenderer,
    id: PluginId,
    title: PluginTitle,
    description: PluginDescription,
    version: PluginVersion,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: PluginAdminRenderer },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c %s PluginComponent canPick props: %O', 'color: red;', PluginTitle, props);
        let canPickResult: boolean = true;
        // console.log('%c %s PluginComponent canPickResult: %O', 'color: red;', PluginTitle, canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c %s PluginComponent canDisplay props: %O', 'color: red;', PluginTitle, props);
        let canDisplayResult: boolean = true;
        // console.log('%c %s PluginComponent canDisplayResult: %O', 'color: red;', PluginTitle, canDisplayResult);
        return canDisplayResult;
     },
    category: UdicciPluginCategory.Container
};

const PluginComponentWrapper = (props: any) => {
    const udicciContext = useUdicciContext();
    // console.log('%c %s PluginComponentWrapper udicciContext: %O', 'color: magenta;', PluginTitle, udicciContext);
    const udicciHelpers = useUdicciHelpers();
    return (<PluginDisplayComponent udicciContext={udicciContext} udicciHelpers={udicciHelpers} {...props} />);
}

export default PluginComponent;
