
import { EditConfig } from './Edit/EditConfig';
import { EditUI } from './Edit/EditUI';
import { EditMenu } from './Edit/EditMenuItem';
import { EditHelp } from './Edit/EditHelp';
import { EditUITitle } from './Edit/EditUITitle';
import { EditActionButtons } from './Edit/EditActionButtons';

const triggerAction = async (options: any) =>  {
    // console.log('%c Edit triggerAction options: %O', 'color: blue;', options);
    return false;
}

const submitAction = async (options: any) =>  {
    // console.log('%c Edit submitAction options: %O', 'color: blue;', options);
    return false;
}

const Edit: React.FC<any> = (props) => {
    // console.log('%c Edit props: %O', 'color: blue;', props);

    let configureAction = (props && props.configure === true ? true : false);

    let actionProps: any = {};
    Object.assign(actionProps, props);
    // console.log('%c actionProps: %O', 'color: red;', actionProps);

    let actionElement: any = null;
    if (configureAction) {
        actionElement = (<EditConfig {...actionProps} />);
    } else {
        actionElement = (<EditUI {...actionProps} />);
    }
    return actionElement;
}

export {
    Edit, EditMenu, EditUITitle, EditActionButtons, EditHelp,
    triggerAction, submitAction
}
