
import React, { useState } from 'react';

import {
    Box, 
    Typography, 
    Button, 
    Switch, 
    FormControl,
    FormGroup, 
    FormLabel, 
    FormControlLabel, 
    FormHelperText,
    InputLabel,
    Input,
    Slider
} from '@mui/material';

import { UdicciRecord } from 'src/classes/udicci-record';
import { useUdicciContext } from 'src/context/udicci-context';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

const AccessSettingsAdmin: React.FC<any> = (props) => {
    // console.log('%c AccessSettingsAdmin props: %O', 'color: maroon;', props);

    const [editRoleLabelOverride, setEditRoleLabelOverride] = useState<number>(0);
    // console.log('%c AccessSettingsAdmin editRoleLabelOverride: %O', 'color: maroon;', editRoleLabelOverride);

    const udicciHelpers = useUdicciHelpers();

    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;
    let { selectedPorta } = udicci;
    // console.log('%c AccessSettingsAdmin selectedPorta: %O', 'color: maroon;', selectedPorta);

    let settings: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : null);
    // console.log('%c AccessSettingsAdmin settings: %O', 'color: maroon;', settings);
    let menuSettings: any = (settings && settings.menu ? settings.menu : null);
    // console.log('%c AccessSettingsAdmin menuSettings: %O', 'color: maroon;', menuSettings);
    let roleMenuSettings: any = (menuSettings && menuSettings.roles ? menuSettings.roles : null);
    // console.log('%c AccessSettingsAdmin roleMenuSettings: %O', 'color: maroon;', roleMenuSettings);

    let allowRoleMenuOverride: any = (props && props.allowRoleMenuOverride ? true : false);
    // console.log('%c AccessSettingsAdmin allowRoleMenuOverride: %O', 'color: maroon;', allowRoleMenuOverride);
    let currentSettings: any = (props && props.currentSettings ? props.currentSettings : null);
    // console.log('%c AccessSettingsAdmin currentSettings: %O', 'color: maroon;', currentSettings);
    let roleSettings: any = (currentSettings && currentSettings.roles ? currentSettings.roles : null);
    // console.log('%c AccessSettingsAdmin roleSettings: %O', 'color: maroon;', roleSettings);

    var profileRoles = udicciHelpers.preloadMediatorData('Roles', udicci.socialSolutionUdicciIT);
    // console.log('%c AccessSettingsAdmin profileRoles: %O', 'color: maroon;', profileRoles);

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log('%c AccessSettingsAdmin handleRoleChange profileRoles: %O', 'color: maroon;', profileRoles);
        // console.log('%c AccessSettingsAdmin handleRoleChange event.target.name: %O', 'color: maroon;', event.target.name);
        if (props && props.onChangeAccessSetting) {
            let update: any = { [event.target.name]: event.target.checked };
            props.onChangeAccessSetting(update, 'roles');
        }
    };

    const addRoleLabelOverride = (roleId: number, event: any) => {
        // console.log('%c addRoleLabelOverride roleId: %O', 'color: maroon;', roleId);
        // console.log('%c addRoleLabelOverride selectedPorta: %O', 'color: maroon;', selectedPorta);
        // console.log('%c addRoleLabelOverride roleSettings: %O', 'color: maroon;', roleSettings);
        // console.log('%c addRoleLabelOverride profileRoles: %O', 'color: maroon;', profileRoles);
        // console.log('%c addRoleLabelOverride menuSettings: %O', 'color: maroon;', menuSettings);
        // console.log('%c addRoleLabelOverride roleMenuSettings: %O', 'color: maroon;', roleMenuSettings);

        if (!menuSettings) menuSettings = {};
        if (!roleMenuSettings) roleMenuSettings = {};
        if (roleId && !roleMenuSettings[roleId]) {
            roleMenuSettings[roleId] = {
                label: (menuSettings && menuSettings.label ? menuSettings.label : ''),
                priority: (menuSettings && menuSettings.priority ? menuSettings.priority : 1)
            };
            // console.log('%c addRoleLabelOverride roleMenuSettings: %O', 'color: blue;', roleMenuSettings);
        }

        if (props && props.onChangeMenuSetting) {
            // console.log('%c addRoleLabelOverride roleMenuSettings: %O', 'color: maroon;', roleMenuSettings);
            props.onChangeMenuSetting(roleMenuSettings, 'menu', 'roles');
        }
    };

    const removeRoleLabelOverride = (roleId: number, event: any) => {
        // console.log('%c removeRoleLabelOverride roleId: %O', 'color: maroon;', roleId);
        // console.log('%c removeRoleLabelOverride selectedPorta: %O', 'color: maroon;', selectedPorta);
        // console.log('%c removeRoleLabelOverride roleSettings: %O', 'color: maroon;', roleSettings);
        // console.log('%c removeRoleLabelOverride profileRoles: %O', 'color: maroon;', profileRoles);
        // console.log('%c removeRoleLabelOverride menuSettings: %O', 'color: maroon;', menuSettings);
        // console.log('%c removeRoleLabelOverride roleMenuSettings: %O', 'color: maroon;', roleMenuSettings);

        if (!menuSettings) menuSettings = {};
        if (!roleMenuSettings) roleMenuSettings = {};
        if (roleId && roleMenuSettings[roleId]) {
            delete roleMenuSettings[roleId];
            menuSettings.roles = roleMenuSettings;
        }
        // console.log('%c removeRoleLabelOverride menuSettings: %O', 'color: maroon;', menuSettings);

        if (props && props.onChangeMenuSetting) {
            props.onChangeMenuSetting(menuSettings, 'menu', '');
        }
    };

    const handleRoleLabelOverrideChange = (event: any, roleId: number, newNumberValue: number | number[] | undefined) => {
        // console.log('%c handleRoleLabelOverrideChange roleId: %O', 'color: maroon;', roleId);
        // console.log('%c handleRoleLabelOverrideChange newNumberValue: %O', 'color: maroon;', newNumberValue);
        // console.log('%c handleRoleLabelOverrideChange roleSettings: %O', 'color: maroon;', roleSettings);
        // console.log('%c handleRoleLabelOverrideChange profileRoles: %O', 'color: maroon;', profileRoles);
        // console.log('%c handleRoleLabelOverrideChange menuSettings: %O', 'color: maroon;', menuSettings);
        // console.log('%c handleRoleLabelOverrideChange roleMenuSettings: %O', 'color: maroon;', roleMenuSettings);
        // console.log('%c handleRoleLabelOverrideChange event: %O', 'color: maroon;', event);

        if (!menuSettings) menuSettings = {};
        if (!roleMenuSettings) roleMenuSettings = {};
        if (roleId && !roleMenuSettings[roleId]) {
            roleMenuSettings[roleId] = {
                label: (menuSettings && menuSettings.label ? menuSettings.label : ''),
                priority: (menuSettings && menuSettings.priority ? menuSettings.priority : 1)
            };
        }

        if (newNumberValue !== undefined) {
            roleMenuSettings[roleId].priority = newNumberValue;
        } else {
            let trgt = event.target;
            let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
            // console.log('%c handleRoleLabelOverrideChange newValue: %O', 'color: maroon;', newValue);

            roleMenuSettings[roleId].label = newValue;
        }
        // console.log('%c handleRoleLabelOverrideChange roleMenuSettings: %O', 'color: maroon;', roleMenuSettings);

        if (props && props.onChangeMenuSetting) {
            props.onChangeMenuSetting(roleMenuSettings, 'menu', 'roles');
        }
    };

    let accessRoleConfig: any = (
        <FormControl component="fieldset" variant="standard">
            <FormLabel component="div">
                <Typography variant="caption" component="div">Access Settings Admin.</Typography>
            </FormLabel>
            <FormGroup>
                {profileRoles && profileRoles.map((role: UdicciRecord) => {
                    // console.log('%c role: %O', 'color: red;', role);
                    let roleHasAccess: boolean = false;
                    if (roleSettings && roleSettings[role.recordId]) roleHasAccess = true;

                    let rowElement: any = (
                        <FormControlLabel
                            control={ <Switch color="secondary" checked={roleHasAccess} onChange={handleRoleChange} name={role.recordId.toString()} /> }
                            label={(<Typography variant="leftFieldLabel">{role.title}</Typography>)}
                        />
                    );

                    let roleLabelOverrideElement: any = null;
                    let roleMenuOverrideElement: any = null;
                    if (allowRoleMenuOverride && roleHasAccess) {
                        let roleMenuLabel: string = '';
                        let roleMenuPriority: number = 1;
                        if (roleMenuSettings && roleMenuSettings[role.recordId]) {
                            if (roleMenuSettings[role.recordId].label) roleMenuLabel = roleMenuSettings[role.recordId].label;
                            if (roleMenuSettings[role.recordId].priority) roleMenuPriority = roleMenuSettings[role.recordId].priority;

                            if (editRoleLabelOverride && editRoleLabelOverride === role.recordId) {
                                roleMenuOverrideElement = (
                                    <div style={{ marginLeft: '40px' }}>
                                        <div style={{ padding: '8px' }}>
                                            <FormControl>
                                                <InputLabel htmlFor="role.menu.label">
                                                    <Typography variant="caption">Menu Label</Typography>
                                                </InputLabel>
                                                <Input id="role.menu.label"
                                                        type={'text'}
                                                        name='role.menu.label'
                                                        value={(roleMenuLabel ? roleMenuLabel : '')}
                                                        onChange={(evt: any) => handleRoleLabelOverrideChange(evt, role.recordId, undefined)} />
                                            </FormControl>
                                        </div>
                                        <div style={{ padding: '8px' }}>
                                            <Box sx={{ width: 300 }}>
                                                <Typography variant="caption">Menu Priority</Typography>
                                                <Slider aria-label="Menu Priority" color="secondary"
                                                        defaultValue={1}
                                                        value={roleMenuPriority}
                                                        onChange={(evt: Event, newValue: number | number[]) => handleRoleLabelOverrideChange(evt, role.recordId, newValue)}
                                                        step={1}
                                                        marks
                                                        min={1}
                                                        max={7}
                                                        valueLabelDisplay="auto"
                                                />
                                            </Box>
                                        </div>
                                        <div style={{ padding: '8px' }}>
                                            <Button size="small" variant="deleteButton"
                                                    onClick={(evt: any) => removeRoleLabelOverride(role.recordId, evt)}>
                                                <Typography variant="caption" component="span">remove override</Typography>
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button size="small" variant="cancelButton"
                                                    onClick={(evt: any) => setEditRoleLabelOverride(0)}>
                                                <Typography variant="caption" component="span">close</Typography>
                                            </Button>
                                        </div>
                                    </div>
                                );

                                // roleLabelOverrideElement = (
                                //     <Button size="small" variant="text" 
                                //             onClick={(evt: any) => removeRoleLabelOverride(role.recordId, evt)}>
                                //         <Typography variant="caption" component="span">remove override</Typography>
                                //     </Button>
                                // );
                            } else {
                                roleMenuOverrideElement = (
                                    <div style={{ marginLeft: '48px' }}>
                                        <div style={{ float: 'left' }}>
                                            <Typography variant="caption" component="span">Menu Label Override: </Typography>
                                            <Typography variant="body2" component="span">{roleMenuLabel}</Typography>
                                            <Typography variant="caption" component="span"> (priority {roleMenuPriority})</Typography>
                                        </div>
                                        <div style={{ float: 'left', padding: '8px' }}>
                                            <Button size="small" variant="contained" autoCapitalize={'false'} color="secondary"
                                                    onClick={(evt: any) => setEditRoleLabelOverride(role.recordId)}>
                                                <Typography variant="caption" component="span">change</Typography>
                                            </Button>
                                        </div>
                                    </div>
                                );
                            }
                        } else {
                            roleLabelOverrideElement = (
                                <Button size="small" variant="text" color="secondary"
                                        onClick={(evt: any) => addRoleLabelOverride(role.recordId, evt)}>
                                    override label
                                </Button>
                            );
                        }
                    }

                    return (
                        <div key={'select.role.' + role.recordId.toString()}>
                            {rowElement}
                            {roleLabelOverrideElement}
                            {roleMenuOverrideElement}
                        </div>
                    );
                })}
            </FormGroup>
            <FormHelperText>Toggle the switch to the "On" position to restrict access to only the selected roles.</FormHelperText>
        </FormControl>
    );
    return ( <Box> {accessRoleConfig} </Box> );
}

export default AccessSettingsAdmin;
