import React, { useState, cloneElement, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isToday, isFuture, isThisWeek } from 'date-fns';

import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
// import { orange } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { UrlRequest } from 'src/interfaces/udicci-request-interfaces';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Slide, { SlideProps } from '@mui/material/Slide';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import {
    Box, Paper, Typography, Avatar, IconButton, Divider, Popper, Grow, ClickAwayListener, MenuList, Button, Tooltip, 
    MenuItem, Toolbar, CssBaseline, useScrollTrigger, Drawer, List, ListItem, ListItemText, Icon, Snackbar, CircularProgress 
} from '@mui/material';

import {
    Menu as OpenPortalMenuIcon,
    Logout as LogoutIcon,
    Contacts as ContactsIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext, logoff as logoffUdicci, getDesignMode, toggleDesignMode, saveProfile } from 'src/context/udicci-context';
import { UdicciPorta } from 'src/classes/udicci-types';
import { udicciStyles } from 'src/theme/shared-styles';

import UserTimeoutDisplay from './user-timeout-display';

type TransitionProps = Omit<SlideProps, 'direction'>;

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
  
export const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
}),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    // justifyContent: 'flex-end',
    minHeight: '35px !important',
}));

export const DrawerContent = styled('div')(({ theme }) => ({
    display: 'Flow-root',
    height: '100%',
    overflowY: 'auto',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export const PortalMenu = styled('span')(({ theme }) => ({
    margin: '0 !important',
    // marginTop: '4px !important',
    float: 'right',
    maxHeight: '20px',
    // maxWidth: '20px',
}))

export const PortalHeader: React.FC<any> = (props) => {
    // console.log('%c PortalHeader props: %O', 'color: maroon;', props);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    let paletteSettings: any = (theme && theme.palette ? theme.palette : null);
    // console.log('%c PortalHeader paletteSettings: %O', 'color: maroon;', paletteSettings);
    let typographySettings: any = (theme && theme.typography ? theme.typography : null);
    // console.log('%c PortalHeader typographySettings: %O', 'color: maroon;', typographySettings);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();

    var { udicci } = udicciContext.state;
    // console.log('%c PortalHeader udicci: %O', 'color: maroon;', udicci);
    var { selectedProfile, selectedPorta, currentUser } = udicci;
    // console.log('%c PortalHeader selectedProfile: %O', 'color: blue;', selectedProfile);
    // console.log('%c PortalHeader selectedPorta: %O', 'color: blue;', selectedPorta);
    // console.log('%c PortalHeader currentUser: %O', 'color: blue;', currentUser);

    // let nprIsToday: boolean = false;
    let nprIsThisWeek: boolean = false;
    let nprInFuture: boolean = false;
    let productionReleaseTimeframe: string = '';
    const nprDate: Date | null = udicci.nextProductionRelease;
    // console.log('%c PortalHeader nprDate: %O', 'color: blue;', nprDate);
    if (nprDate) {
        // nprIsToday = isToday(nprDate);
        nprIsThisWeek = isThisWeek(nprDate);
        nprInFuture = isFuture(nprDate);
        if (nprIsThisWeek && nprInFuture) productionReleaseTimeframe = udicci.productionReleaseTimeframe();
    }
    // console.log('%c PortalHeader nprIsToday: %O', 'color: maroon;', nprIsToday);
    // console.log('%c PortalHeader nprInFuture: %O', 'color: maroon;', nprInFuture);
    // console.log('%c PortalHeader productionReleaseTimeframe: %O', 'color: maroon;', productionReleaseTimeframe);

    let profileIsDirty: boolean = (selectedProfile && selectedProfile.isDirty ? true : false);
    let profileIsSaving: boolean = (selectedProfile && selectedProfile.isSaving ? true : false);

    let anchorMenuElRef = React.useRef<HTMLDivElement>(null);
    const [snackbarOpen, setSnackbarOpen] = useState((profileIsDirty || profileIsSaving ? true : false));
    const [openMainMenu, setOpenMainMenu] = useState(false);
    const [openPortalMenu, setOpenPortalMenu] = useState(false);
    const [showPlatformNPR, setShowPlatformNPR] = useState(false);
    const isPrinting = useMediaQuery("print");
    // console.log('%c isPrinting: %O', 'color: maroon;', isPrinting);

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 1));
        }, 70);

        return () => { clearInterval(timer); };
    }, []);
  
    useEffect(() => {
        setSnackbarOpen(((profileIsDirty || profileIsSaving) ? true : false));
    }, [profileIsDirty, profileIsSaving]);
    if (isPrinting) return null;

    var userLoggedIn = (currentUser && currentUser.UdicciUserId > 0 ? true : false);
    var userDisplayName = (currentUser && currentUser.myDisplayName ? currentUser.myDisplayName : '');
    // console.log('%c PortalHeader userDisplayName: %O', 'color: maroon;', userDisplayName);
    let dm: any = getDesignMode();
    // console.log('%c PortalHeader dm: %O', 'color: maroon;', dm);
    let isMobile = udicci.isMobileDevice();
    // console.log('%c PortalHeader isMobile: %O', 'color: maroon;', isMobile);

    let isDeveloper: boolean = false;
    if (currentUser && udicci.developerUsers.indexOf(currentUser.UserName.toLowerCase()) >= 0) isDeveloper = true;
    let isSpecialUser: boolean = false;
    if (currentUser && udicci.specialUsers.indexOf(currentUser.UserName.toLowerCase()) >= 0) {
        isSpecialUser = true;
    }

    var profile = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    // console.log('%c PortalHeader profile: %O', 'color: maroon;', profile);
    var profileName: any = (profile && profile.DisplayName ? profile.DisplayName : '');
    var profileType: any = (profile && profile.ProfileType ? profile.ProfileType : null);
    var publicRole = (profile && profile.PublicRole ? profile.PublicRole : null);
    // console.log('%c PortalHeader publicRole: %O', 'color: maroon;', publicRole);
    var udicciUserRole = (profile && profile.UdicciUserRole ? profile.UdicciUserRole : null);
    // console.log('%c PortalHeader udicciUserRole: %O', 'color: maroon;', udicciUserRole);
    var publicRoleId = (publicRole && publicRole.UdicciRecordId ? publicRole.UdicciRecordId : 0);
    var udicciUserRoleId = (udicciUserRole && udicciUserRole.UdicciRecordId ? udicciUserRole.UdicciRecordId : 0);
    var userIsAdministratorRole = (udicciUserRole && udicciUserRole.IsAdministratorRole ? true : false);
    // console.log('%c PortalHeader userIsAdministratorRole: %O', 'color: maroon;', userIsAdministratorRole);
    var selectedProfileIsUser = false;
    if (userLoggedIn && selectedProfile) {
        selectedProfileIsUser = (selectedProfile.recordId === currentUser?.MeUdicciProfileId ? true : false);
    }

    // var userIsPublicRole = false;
    // if (!userLoggedIn && selectedProfile) {
    //     userIsPublicRole = (udicciUserRole && udicciUserRole.IsPublicRole ? true : false);
    // }

    var profileSettings = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
    // console.log('%c PortalHeader profileSettings: %O', 'color: maroon;', profileSettings);
    var portalSetting: any = (profileSettings && profileSettings.portal ? profileSettings.portal : null);
    // console.log('%c PortalHeader portalSetting: %O', 'color: maroon;', portalSetting);
    var portalDesignSetting: any = (portalSetting && portalSetting.design ? portalSetting.design : null);
    // console.log('%c PortalHeader portalDesignSetting: %O', 'color: maroon;', portalDesignSetting);

    let settingsJson: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : null);
    // console.log('%c PortalHeader settingsJson: %O', 'color: maroon;', settingsJson);
    let portaDesign: any = (settingsJson && settingsJson.design ? settingsJson.design : null);
    // console.log('%c PortalHeader portaDesign: %O', 'color: green;', portaDesign);
    let portaDisplay: any = (settingsJson && settingsJson.display ? settingsJson.display : 'plugins');
    // console.log('%c Porta portaDisplay: %O', 'color: maroon;', portaDisplay);
    let portaDesignSettings: any = (portaDesign ? portaDesign : portalDesignSetting);
    // console.log('%c PortalHeader portaDesignSettings: %O', 'color: green;', portaDesignSettings);

    let headerSettings: any = (portaDesignSettings && portaDesignSettings.header ? portaDesignSettings.header : null);
    // console.log('%c PortalHeader headerSettings: %O', 'color: green;', headerSettings);
    let headerBackgroundColor: any = (headerSettings && headerSettings.backgroundColor ? headerSettings.backgroundColor : null);
    let headerBorderBottomColor: any = (headerSettings && headerSettings.borderBottomColor ? headerSettings.borderBottomColor : '');

    let headerPrimaryColor: any = (headerSettings && headerSettings.primaryColor ? headerSettings.primaryColor : null);
    let headerSecondaryColor: any = (headerSettings && headerSettings.secondaryColor ? headerSettings.secondaryColor : null);

    let menuSettings: any = (portaDesignSettings && portaDesignSettings.menu ? portaDesignSettings.menu : null);
    // console.log('%c PortalHeader menuSettings: %O', 'color: green;', menuSettings);
    let menuColor: any = (menuSettings && menuSettings.iconColor ? menuSettings.iconColor : '');
    let menuBackgroundColor: any = (menuSettings && menuSettings.backgroundColor ? menuSettings.backgroundColor : '');
    let menuBorderRightColor: any = (menuSettings && menuSettings.borderRightColor ? menuSettings.borderRightColor : '');
    let activeMenuTextColor: any = (menuSettings && menuSettings.activeMenuTextColor ? menuSettings.activeMenuTextColor : '');
    let inactiveMenuTextColor: any = (menuSettings && menuSettings.inactiveMenuTextColor ? menuSettings.inactiveMenuTextColor : '');
    let selectedMenuIndicatorColor: any = (menuSettings && menuSettings.selectedMenuIndicatorColor ? menuSettings.selectedMenuIndicatorColor : '');

    let portas: UdicciPorta[] = udicci.getPortas();
    // console.log('%c PortalHeader portas: %O', 'color: green;', portas);
    let sortedPortas: UdicciPorta[] = [];
    if (portas) {
        sortedPortas = portas.sort((a: any,b: any) => {
            let aj: any = (a.SettingsJson ? a.SettingsJson : {});
            let bj: any = (b.SettingsJson ? b.SettingsJson : {});
            let am: any = (aj.menu ? aj.menu : {});
            let bm: any = (bj.menu ? bj.menu : {});
            let ap: number = (am.priority ? am.priority : 9);
            let bp: number = (bm.priority ? bm.priority : 9);
            let amr: any = (am.roles ? am.roles : null);
            let bmr: any = (bm.roles ? bm.roles : null);
            let amrs: any = (amr && amr[udicciUserRoleId] ? amr[udicciUserRoleId] : null);
            let bmrs: any = (bmr && bmr[udicciUserRoleId] ? bmr[udicciUserRoleId] : null);
            // console.log('%c amrs: %O', 'color: blue;', amrs);
            // console.log('%c bmrs: %O', 'color: blue;', bmrs);
            ap = (amrs && amrs.priority ? amrs.priority : ap);
            bp = (bmrs && bmrs.priority ? bmrs.priority : bp);

            if (ap < bp) return -1;  // ascending
            if (ap > bp) return 1;  // descending
            return 0 //default return value (no sorting)
        });
    }
    // console.log('%c sortedPortas: %O', 'color: maroon;', sortedPortas);

    const handlePortalMenuOpen = () => {
        if (openPortalMenu) {
            setOpenPortalMenu(false);
        } else {
            setOpenPortalMenu(true);
        }
    };

    const handlePortalMenuClose = () => {
        setOpenPortalMenu(false);
    };

    const showMyMenu = (evt: React.MouseEvent<HTMLButtonElement>) => {
        // setAnchorMenuEl(evt.currentTarget);
        setOpenMainMenu(true);
    };

    const handleMenuClose = (evt: any) => {
        // setAnchorMenuEl(null);
        setOpenMainMenu(false);
    };

    // const special = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setOpenMainMenu(false);
    //     // console.log('%c PortalHeader special portas: %O', 'color: maroon;', portas);
    //     if (props.onChange) {
    //         props.onChange({
    //             showChangePassword: false,
    //             showPortalAgreements: false,
    //             showPortalConfig: false,
    //         });
    //     }

    //     var newPorta: any | null = null;
    //     if (portas) {
    //         newPorta = portas.find((x: any) => x.name.toLowerCase() === 'configure platform porta');
    //     }
    //     // console.log('%c newPorta: %O', 'color: green; font-weight: bold;', newPorta);
    //     if (newPorta)
    //         udicci.setPorta(newPorta);
    // };

    const getColorValue = (ctc: any) => {
        if (typeof(ctc) === 'object') {
            return `rgba(${ ctc.r }, ${ ctc.g }, ${ ctc.b }, ${ ctc.a })`;
        } else {
            return ctc;
        }
    }

    const setPorta = (porta: any) => {
        setOpenMainMenu(false);
        setOpenPortalMenu(false);
        // console.log('%c PortalHeader setPorta porta: %O', 'color: maroon;', porta);
        if (props.onChange) {
            props.onChange({
                showChangePassword: false,
                showPortalAgreements: false,
                showPortalConfig: false,
            });
        }
        udicci.setPorta(porta);
    };

    const addPorta = () => {
        setOpenMainMenu(false);
        setOpenPortalMenu(false);

        let newPorta: UdicciPorta = {
            UdicciRecordId: 0,
            Name: 'New Porta',
            Purpose: '',
            Settings: '{ portaUrl: "NewPorta" }',
            SettingsJson: {
                portaUrl: "NewPorta"
            },
            IsDirty: true,
            CreatedByUserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0),
        };
        // console.log('%c PortalHeader addPorta newPorta: %O', 'color: maroon;', newPorta);
        udicci.setPorta(newPorta);
    };

    const logoff = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenMainMenu(false);
        logoffUdicci();
    };

    // const openConfigurationDialog = (evt: any) => {
    //     // console.log('%c openConfigurationDialog evt: %O', 'color: maroon;', evt);
    //     setOpenMainMenu(false);
    //     if (props.onChange) {
    //         props.onChange({
    //             showChangePassword: false,
    //             showPortalAgreements: false,
    //             showPortalConfig: true,
    //         });
    //     }
    // };

    // const openChangePassword = (evt: any) => {
    //     // console.log('%c openChangePassword evt: %O', 'color: maroon;', evt);
    //     setOpenMainMenu(false);
    //     if (props.onChange) {
    //         props.onChange({
    //             showChangePassword: true,
    //             showPortalAgreements: false,
    //             showPortalConfig: false,
    //         });
    //     }
    // };

    const openRelationships = (evt: any) => {
        // console.log('%c openRelationships evt: %O', 'color: maroon;', evt);
        setOpenMainMenu(false);
        if (props.onChange) {
            props.onChange({
                showChangePassword: false,
                showPortalAgreements: true,
                showPortalConfig: false,
            });
        }
    };

    const reportAnIssue = (evt: any) => {
        setOpenMainMenu(false);
        udicci.reportANewIssue();
    };

    const clearIssueReport = (evt: any) => {
        setOpenMainMenu(false);
        udicci.clearPlatformIssueReport();
    };

    const loadHomeScreen = (evt: any) => {
        // console.log('%c loadHomeScreen evt: %O', 'color: maroon;', evt);
        setOpenMainMenu(false);
        if (props.onChange) {
            props.onChange({
                showChangePassword: false,
                showPortalAgreements: false,
                showPortalConfig: false,
            });
        }
        if (!selectedPorta || (selectedPorta && selectedPorta.SettingsJson && selectedPorta.SettingsJson.defaultPorta !== true)) {
            var defaultPorta: any | null = null;
            if (portas) {
                defaultPorta = portas.find((x: any) => { return (x.SettingsJson && x.SettingsJson.defaultPorta === true); });
            }
            // console.log('%c defaultPorta: %O', 'color: green; font-weight: bold;', defaultPorta);
            if (defaultPorta)
                udicci.setPorta(defaultPorta);
        }
    };

    const onToggleDesignMode = (event: any) => {
        setOpenMainMenu(false);
        setOpenPortalMenu(false);
        // console.log('%c PortalHeader onToggleDesignMode portas: %O', 'color: maroon;', portas);
        toggleDesignMode();
    };

    const onTestRefreshToken = (event: any) => {
        setOpenMainMenu(false);
        // console.log('%c PortalHeader onTestRefreshToken portas: %O', 'color: maroon;', portas);

        let currentAccessToken = (currentUser && currentUser.AccessToken ? currentUser.AccessToken : '');
        let currentRefreshToken = (currentUser && currentUser.RefreshToken ? currentUser.RefreshToken : '');
        // console.log('%c PortalHeader onTestRefreshToken currentAccessToken: %O', 'color: red; font-weight: bold;', currentAccessToken);
        // console.log('%c PortalHeader onTestRefreshToken currentRefreshToken: %O', 'color: red; font-weight: bold;', currentRefreshToken);
        let sampleRequest: UrlRequest = {
            UdicciCommand: 'Get Profile By Url',
            UdicciMediatorName: 'Udicci Profiles',
            SelectedUdicciProfileId: udicci.ulyssesDConstantineProfileId,
            SocialSolutionId: udicci.socialSolutionUdicciIT,
            PreLoadOnly: false,
            UserId: (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0),
            Url: '/ulyssesdconstantine'
        };
        // console.log('%c sampleRequest: %O', 'color: maroon;', sampleRequest);
        udicci.refreshTokens(udicci.API_URL, sampleRequest, sampleRequestResponse, currentUser);
    };

    const sampleRequestResponse = (response: any) => {
        // console.log('%c sampleRequestResponse response: %O', 'color: blue;', response);
    };

    const handleClick = () => {
        setSnackbarOpen(true);
    };
    
    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')  return;
        setSnackbarOpen(false);
    };

    let appBarRootStyle: any = udicciClasses.portalHeader;
    let platformMenuContainerStyle: any = {};
    let portalMenuContainerStyle: any = {};
    var headerContentElement: any = null;
    let menuDrawerElement: any = null;
    let menuDrawerHeader: any = null;
    let menuDrawerContent: any = null;
    let menuAppBarElement: any = null;
    let portalMenuPalette: any = null;
    if (paletteSettings) {
        portalMenuPalette = {};
        Object.assign(portalMenuPalette, paletteSettings);
    }
    // console.log('%c PortalHeader portalMenuPalette: %O', 'color: maroon;', portalMenuPalette);

    let sxPortalHeader: any = {};
    portalMenuContainerStyle.backgroundColor = 'rgb(215 215 215 / 90%)';
    platformMenuContainerStyle.backgroundColor = 'rgb(215 215 215 / 90%)';
    sxPortalHeader.backgroundColor = 'rgb(215 215 215 / 90%)';
    // sxPortalHeader.color = 'unset';

    let action: any = null;
    action = (<CircularProgress variant="determinate" value={progress} />);
    // action = (
    //     <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
    //         <Icon fontSize="small">close</Icon>
    //     </IconButton>
    // );

    let saveProfileSnackbarMessage: any = null;
    if (selectedProfile && selectedProfile.isSaving === true) {
        saveProfileSnackbarMessage = (
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={7000}
                onClose={handleSnackbarClose}
                message="Profile is being Saved ...  Please stand by."
                action={action}
            />
        );
    } else if (selectedProfile && selectedProfile.isDirty === true) {
        saveProfileSnackbarMessage = (
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={7000}
                onClose={handleSnackbarClose}
                message="Profile Requires Save.  Please click the Save icon at the top left of the Portal next to the menu icon."
                action={action}
            />
        );
    }

    if (!headerContentElement) {  // userLoggedIn
        var userProfileUrl = (currentUser && currentUser.myProfileUrl ? currentUser.myProfileUrl : '');

        var userProfileCardClickEvent: any = loadHomeScreen;
        // if (isDeveloper)
        //     userProfileCardClickEvent = handlePortalMenuOpen;

        var userRoleElement: any = null;
        if (udicciUserRoleId && (selectedProfile && selectedProfile.isSaving !== true)) {
            userRoleElement = (
                <Fragment>
                    <Typography variant="subtitle2" component="span" style={{ paddingLeft: '4px' }}>
                        {udicciUserRole.RoleName}
                    </Typography>
                    <Typography variant="subtitle2" component="span">.</Typography>
                </Fragment>
            );
        // } else if (openPortalMenu && publicRoleId && (selectedProfile && selectedProfile.isSaving !== true)) {
        //     userRoleElement = (
        //         <Fragment>
        //             <Typography variant="caption" component="span" style={{ paddingLeft: '4px' }}>
        //                 {publicRole.RoleName}
        //             </Typography>
        //             <Typography variant="caption" component="span">.</Typography>
        //         </Fragment>
        //     );
        }
        
        let saveProfileButton: any = null;
        if ((udicci.isCurrentUserUlysses() || userIsAdministratorRole) && selectedProfile && selectedProfile.isDirty) {
            if (selectedProfile.isSaving === true) {
                saveProfileButton = (
                    <Fragment>
                        <Typography variant="caption" component="span">
                            saving
                        </Typography>
                        &nbsp;
                        <Typography variant="caption" component="span">
                            {profileName}
                        </Typography>
                        &nbsp;
                        <Typography variant="caption" component="span">
                            ...  please wait.
                        </Typography>
                    </Fragment>
                );
                saveProfileSnackbarMessage = (
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={7000}
                        onClose={handleSnackbarClose}
                        message="Profile is being Saved ...  Please stand by."
                        action={action}
                    />
                );
            } else {
                saveProfileButton = (
                    <IconButton aria-label="Save Profile Changes"
                                size="small"
                                color="secondary"
                                onClick={(evt: any) => saveProfile()}
                                sx={{ paddingTop: 0, marginRight: '8px' }}
                    >
                        <SaveIcon fontSize="small" />
                    </IconButton>
                );
                saveProfileSnackbarMessage = (
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={7000}
                        onClose={handleSnackbarClose}
                        message="Profile Requires Save.  Please click the Save icon at the top left of the Portal next to the menu icon."
                        action={action}
                    />
                );
            }
        }

        let platformMenuIconProps: any = {
            // paddingTop: '8px',
            marginRight: '8px'
        };
        var userProfileCardAvatar: any = null;
        if (!selectedProfileIsUser) {
            // var userProfileElement: any = null;
            var profileNameElement: any = null;
            if (selectedProfile && selectedProfile.isSaving !== true) {
                let profileNameElementSettings: any = {
                    variant: 'subtitle1',
                    component: 'span',
                    onClick: loadHomeScreen,
                    sx: { cursor: 'pointer' }
                };
                if (!openPortalMenu && currentUser && !isMobile) {
                    // userProfileElement = (
                    //     <Fragment>
                    //         <Typography component="span" variant="clickableSubTitle2" onClick={(evt: any) => udicciHelpers.openUrl(userProfileUrl)}>
                    //             {userDisplayName}
                    //         </Typography>
                    //         <Typography variant="clickableSubTitle2" component="span">
                    //             .
                    //         </Typography>
                    //     </Fragment>
                    // );
                    profileNameElement = (
                        <Fragment>
                            <Typography {...profileNameElementSettings}>{profileName}</Typography>
                            <Typography {...profileNameElementSettings}>.</Typography>
                        </Fragment>
                    );
                } else {
                    profileNameElement = (
                        <Fragment>
                            <Typography {...profileNameElementSettings}>{profileName}</Typography>
                            <Typography {...profileNameElementSettings}>.</Typography>
                        </Fragment>
                    );
                }
            }

            if (headerBackgroundColor) {
                if (headerBackgroundColor.r !== undefined) {
                    let bgColor: string = getColorValue(headerBackgroundColor);
                    sxPortalHeader.backgroundColor = bgColor;
                } else {
                    sxPortalHeader.backgroundColor = headerBackgroundColor;
                    platformMenuContainerStyle.backgroundColor = headerBackgroundColor;
                }
            }

            let upcaStyle: any = { float: 'left', marginBottom: '4px' };
            if (headerBackgroundColor) {
                if (headerBackgroundColor.r !== undefined) {
                    let bgColor: string = getColorValue(headerBackgroundColor);
                    upcaStyle.backgroundColor = bgColor;
                } else {
                    upcaStyle.backgroundColor = headerBackgroundColor;
                }
            }
            userProfileCardAvatar = (
                <Box sx={upcaStyle}>
                    {saveProfileButton}
                    {profileNameElement}
                    {(!isMobile && !openPortalMenu && userRoleElement)}
                    {(openPortalMenu && userRoleElement && (<div style={{ display: 'flow-root' }}><Typography variant="subtitle2" component="span">role: </Typography>{userRoleElement}</div>))}
                </Box>
            );
        } else {
            userProfileCardAvatar = (
                <Box sx={{ float: 'left' }}>
                    {saveProfileButton}
                    <Typography onClick={userProfileCardClickEvent} variant="subtitle1" component="span">
                        {(profileType && profileType.Name ? profileType.Name : 'Udicci Portal')}
                    </Typography>
                    <Typography variant="subtitle1" component="span">.</Typography>
                    {(!isMobile && !openPortalMenu && userRoleElement)}
                </Box>
            );
        }

        let standardDividerElement: any = (<Divider sx={{ marginTop: '2px', marginBottom: '2px' }} />);
        if (profile) {  // && userLoggedIn
            let includeMenu: boolean = (currentUser && currentUser.UdicciUserId > 0) || (userIsAdministratorRole || udicci.isCurrentUserUlysses());
            var logoffMenuDividerElement: any = null;
            var logoffMenuElement: any = null;

            var designMenuDividerElement: any = null;
            var designMenuElement: any = null;
            // if (isDeveloper || isSpecialUser) {
            //     if ((includeMenu && udicciUserRoleId > 0) && !designMenuDividerElement) designMenuDividerElement = standardDividerElement;
            //     let icon: any = (<Typography variant="clickableSubTitle1" sx={platformMenuIconProps}>{(dm && dm === 'on' ? (<ToggleOnIcon fontSize='small' />) : (<ToggleOffIcon fontSize='small' />))}</Typography>);
            //     designMenuElement = (
            //         <MenuItem dense onClick={(evt: any) => { onToggleDesignMode(evt); }}>
            //             <Typography variant="clickableSubTitle1">
            //                 {icon} {(dm && dm === 'on' ? 'Disable' : 'Enable')} Design Mode
            //             </Typography>
            //         </MenuItem>
            //     );
            // }

            if (currentUser && currentUser.UdicciUserId > 0) {
                logoffMenuDividerElement = standardDividerElement;
                logoffMenuElement = (
                    <MenuItem dense onClick={(evt: any) => { logoff(evt); }}>
                        <Typography variant="clickableSubTitle1" component="span" sx={platformMenuIconProps}>
                            <LogoutIcon fontSize='small' />
                        </Typography>
                        <Typography variant="clickableSubTitle1" component="span">
                            Logoff
                        </Typography>
                        <Typography variant="body2" sx={{ marginLeft: '8px' }}>
                            <UserTimeoutDisplay />
                        </Typography>
                    </MenuItem>
                );
            }

            let sxPortalHeaderToolbar: any = {
                display: 'flow-root !important',
                minHeight: '35px !important',
                paddingTop: '4px',
                paddingBottom: '4px',
                // color: 'unset',
            };
            let sxOpenPortalMenuButton: any = {
                float: 'left',
                p: 0,
                mr: 2,
                ...(openPortalMenu && { display: 'none' })
            };
            let sxClosePortalMenuButton: any = {
                float: 'left',
                p: 0,
                ml: 0.5,
                mr: 2,
            };

            // console.log('%c PortalHeader headerBackgroundColor: %O', 'color: maroon;', headerBackgroundColor);
            if (headerBackgroundColor) {
                if (headerBackgroundColor.r !== undefined) {
                    let bgColor: string = getColorValue(headerBackgroundColor);
                    sxPortalHeader.backgroundColor = bgColor;
                    platformMenuContainerStyle.backgroundColor = headerBackgroundColor;
                } else {
                    sxPortalHeader.backgroundColor = headerBackgroundColor;
                    platformMenuContainerStyle.backgroundColor = headerBackgroundColor;
                }
            }
            if (headerBorderBottomColor) {
                let borderBottomColor: string = getColorValue(headerBorderBottomColor);
                sxPortalHeader.borderBottom = '1px solid ' + borderBottomColor;
                platformMenuContainerStyle.border = '1px solid ' + borderBottomColor;
            }

            if (menuColor) {
                sxOpenPortalMenuButton.color = getColorValue(menuColor);
                sxClosePortalMenuButton.color = getColorValue(menuColor);
            }

            // console.log('%c PortalHeader sxPortalHeader: %O', 'color: maroon;', sxPortalHeader);
            appBarRootStyle = sxPortalHeader;
            Object.assign(portalMenuContainerStyle, sxPortalHeader);
            // console.log('%c PortalHeader portalMenuContainerStyle: %O', 'color: maroon;', portalMenuContainerStyle);

            portalMenuContainerStyle.height = '100%';
            portalMenuContainerStyle.overflowY = 'auto';

            if (menuBackgroundColor) {
                let bgColor: string = getColorValue(menuBackgroundColor);
                portalMenuContainerStyle.backgroundColor = bgColor;
                platformMenuContainerStyle.backgroundColor = bgColor;
            }
            if (menuBorderRightColor) {
                let borderRightColor: string = getColorValue(menuBorderRightColor);
                portalMenuContainerStyle.borderRight = '1px solid ' + borderRightColor;
            }
            // console.log('%c PortalHeader portalMenuContainerStyle: %O', 'color: maroon;', portalMenuContainerStyle);

            if (activeMenuTextColor) {
                let activeMenuTextColorConverted: string = getColorValue(activeMenuTextColor);
                if (!portalMenuPalette) portalMenuPalette = {};
                portalMenuPalette.primary = { main: activeMenuTextColorConverted };
            }
            if (inactiveMenuTextColor) {
                let inactiveMenuTextColorConverted: string = getColorValue(inactiveMenuTextColor);
                if (!portalMenuPalette) portalMenuPalette = {};
                portalMenuPalette.secondary = { main: inactiveMenuTextColorConverted };
            }
            // if (selectedMenuIndicatorColor) {
            //     let selectedMenuIndicatorColorConverted: string = getColorValue(selectedMenuIndicatorColor);
            //     if (!portalMenuPalette) portalMenuPalette = {};
            //     if (!portalMenuPalette.text) portalMenuPalette.text = {};
            //     portalMenuPalette.text.primary = selectedMenuIndicatorColorConverted;
            // }

            function TransitionDown(props: TransitionProps) {
                return <Slide {...props} direction="down" />;
            }
              
            var nextProductionReleaseInfoElement: any = null;
            if (udicci.userIsLoggedIn() && productionReleaseTimeframe) {
                if (showPlatformNPR) {
                    let snackbarSx: any = {
                        border: '1px solid rgb(234 85 8) !important',
                        width: '100%',
                        background: 'white',
                        padding: '8px',
                        color: 'black',
                        borderRadius: '8px',
                    };
                    const PaperContainer = styled(Paper)(({ theme }) => ({
                        ...theme.typography.body2,
                        color: theme.palette.text.secondary,
                        ...snackbarSx
                    }));

                    nextProductionReleaseInfoElement = (
                        <Snackbar open={true} onClose={() => { setShowPlatformNPR(false); }} TransitionComponent={TransitionDown}>
                            <PaperContainer elevation={3}>
                                <Typography>
                                    {'The Next Udicci.IT Production Release is scheduled for ' + productionReleaseTimeframe}
                                </Typography>
                            </PaperContainer>
                        </Snackbar>
                    );
                } else {
                    nextProductionReleaseInfoElement = (
                        <IconButton color="primary" size="small" onClick={() => { setShowPlatformNPR(true); }}>
                            <Icon fontSize="small">event</Icon>
                        </IconButton>
                    );
                }
            }

            var testRefresthTokenMenuElement: any = null;
            if (currentUser && currentUser.UdicciUserId) {
                testRefresthTokenMenuElement = (
                    <MenuItem dense onClick={onTestRefreshToken}>
                        <Typography variant="clickableSubTitle1" sx={platformMenuIconProps}>
                            <Icon>bug_report</Icon>
                        </Typography>
                        <Typography variant="clickableSubTitle1">
                            Test Refresh Token
                        </Typography>
                    </MenuItem>
                );
            }

            var goToMyPortalMenuElement: any = null;
            if (currentUser && currentUser.UdicciUserId) {
                goToMyPortalMenuElement = (
                    <MenuItem dense onClick={(evt: any) => udicciHelpers.openUrl(userProfileUrl)}>
                        <Typography variant="clickableSubTitle1" component="span" sx={platformMenuIconProps}>
                            <Icon fontSize='small'>home</Icon>
                        </Typography>
                        <Typography variant="clickableSubTitle1" component="span">
                            Go To My Portal
                        </Typography>
                    </MenuItem>
                );
            }
            var reportPlatformIssueElement: any = null;
            var agreementsToggleElement: any = null;
            var portalMenuElement: any = null;
            // var agreementsListDividerElement: any = null;
            if (includeMenu) {
                if (udicciUserRoleId > 0) {
                    agreementsToggleElement = (
                        <MenuItem dense onClick={openRelationships}>
                            <Typography variant="clickableSubTitle1" component="span" sx={platformMenuIconProps}>
                                <ContactsIcon fontSize='small' />
                            </Typography>
                            <Typography variant="clickableSubTitle1" component="span">
                                Relationships
                            </Typography>
                        </MenuItem>
                    );
                    if (udicci.platformIssueReport) {
                        reportPlatformIssueElement = (
                            <MenuItem dense onClick={clearIssueReport}>
                                <Typography variant="clickableSubTitle1" component="span" sx={platformMenuIconProps}>
                                    <Icon fontSize='small'>do_not_disturb</Icon>
                                </Typography>
                                <Typography variant="clickableSubTitle1" component="span">
                                    Clear Issue Report
                                </Typography>
                            </MenuItem>
                        );
                    } else {
                        reportPlatformIssueElement = (
                            <MenuItem dense onClick={reportAnIssue}>
                                <Typography variant="clickableSubTitle1" component="span" sx={platformMenuIconProps}>
                                    <Icon fontSize='small'>bug_report</Icon>
                                </Typography>
                                <Typography variant="clickableSubTitle1" component="span">
                                    Report an Issue
                                </Typography>
                            </MenuItem>
                        );
                    }
                }

                let muiIconButtonSettings: any = {
                    styleOverrides: {
                        root: udicciClasses.profileMenuButton,
                    },
                };
                let muiIconSettings: any = {
                    styleOverrides: {
                        root: udicciClasses.profileMenuButtonIcon,
                    },
                };

                const portalMenuButtonTheme = createTheme({
                    components: {
                        MuiIconButton: muiIconButtonSettings,
                        MuiIcon: muiIconSettings,
                    },
                });

                let portalMenuIconElement: any = null;
                if (currentUser && currentUser.mySocialIcon) {
                    portalMenuIconElement = (<Avatar src={currentUser.mySocialIcon} sx={{ width: '25px', height: '25px' }} />);
                } else {
                    portalMenuIconElement = (<Icon>arrow_drop_down</Icon>);
                }

                portalMenuElement = (
                    <Fragment>
                        <PortalMenu>
                            <ThemeProvider theme={portalMenuButtonTheme}>
                                <IconButton size="small"
                                            color="secondary"
                                            aria-controls="my-menu"
                                            aria-haspopup="true"
                                            onClick={showMyMenu}
                                            component="span"
                                            ref={anchorMenuElRef}
                                >
                                    {portalMenuIconElement}
                                </IconButton>
                            </ThemeProvider>
                        </PortalMenu>
                        <Popper sx={udicciClasses.menuPopperRoot} placement="bottom-end" open={openMainMenu} anchorEl={anchorMenuElRef.current} transition>
                            {({ TransitionProps, placement }) => {
                                let growStyle = { transformOrigin: (placement === 'bottom' ? 'center top' : 'center bottom'), };
                                return (
                                    <Grow {...TransitionProps} style={growStyle}>
                                        <Paper sx={platformMenuContainerStyle}>
                                            <ClickAwayListener onClickAway={handleMenuClose}>
                                                <MenuList sx={{ root: udicciClasses.menuRoot }}>
                                                    {goToMyPortalMenuElement}
                                                    {logoffMenuDividerElement}
                                                    {agreementsToggleElement}
                                                    {standardDividerElement}
                                                    {reportPlatformIssueElement}
                                                    {designMenuDividerElement}
                                                    {designMenuElement}
                                                    {logoffMenuDividerElement}
                                                    {logoffMenuElement}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            }
                        </Popper>
                    </Fragment>
                );
            }
            if(openPortalMenu) sxPortalHeader.borderRight = portalMenuContainerStyle.borderRight;

            let adminMenuItem: any = null;
            if (!headerContentElement) { // if (isDeveloper || isSpecialUser) {
                var addPortaElement: any = null;
                if (dm === 'on') {
                    var listItemTextElement: any = (
                        <Typography variant="clickableSubTitle1">
                            add a new porta
                        </Typography>
                    );
                    addPortaElement = (
                        <Fragment>
                            <Divider light={true} />
                            <ListItem button dense onClick={(evt: any) => addPorta()}>
                                <ListItemText disableTypography={true} primary={listItemTextElement} />
                            </ListItem>
                        </Fragment>
                    );

                    adminMenuItem = (
                        <Tooltip title="Turn off Design Mode and View Portal">
                            <IconButton size="small" color="secondary" sx={{ marginLeft: '8px', padding: 0 }} onClick={onToggleDesignMode}>
                                <Icon fontSize="small">view_in_ar</Icon>
                            </IconButton>
                        </Tooltip>
                    );
                }

                let includedPortas: any[] = [];
                sortedPortas.map((porta: any, index: number) => {
                    // console.log('%c PortalHeader porta: %O', 'color: maroon;', porta);
                    let settingsJson: any = (porta.SettingsJson ? porta.SettingsJson : null);
                    // console.log('%c PortalHeader settingsJson: %O', 'color: maroon;', settingsJson);
                    let ms: any = (settingsJson && settingsJson.menu ? settingsJson.menu : null);
                    // console.log('%c PortalHeader ms: %O', 'color: maroon;', ms);
                    let menuLabel: string = (ms && ms.label ? ms.label : porta.Name);
                    // console.log('%c PortalHeader menuLabel: %O', 'color: maroon;', menuLabel);
                    let access: any = (settingsJson && settingsJson.access ? settingsJson.access : null);
                    // console.log('%c PortalHeader access: %O', 'color: maroon;', access);
                    let accessRoles: any = (access && access.roles ? access.roles : null);
                    // console.log('%c PortalHeader accessRoles: %O', 'color: maroon;', accessRoles);
                    let userHasAccess: boolean = false;
                    if (accessRoles) {
                        if (udicciUserRoleId && accessRoles[udicciUserRoleId] === true) userHasAccess = true;
                        if (!udicciUserRoleId && publicRoleId && accessRoles[publicRoleId] === true) userHasAccess = true;
                    }
                    // console.log('%c PortalHeader userHasAccess: %O', 'color: maroon;', userHasAccess);

                    if (userHasAccess || dm === 'on') {
                        let menuRoles: any = (ms && ms.roles ? ms.roles : null);
                        // console.log('%c PortalHeader menuRoles: %O', 'color: blue;', menuRoles);
                        if (menuRoles && menuRoles[udicciUserRoleId]) {
                            let roleMenuSettings: any = menuRoles[udicciUserRoleId];
                            // console.log('%c PortalHeader roleMenuSettings: %O', 'color: blue;', roleMenuSettings);
                            // console.log('%c PortalHeader roleMenuSettings.label: %O', 'color: blue;', roleMenuSettings.label);
                            if (roleMenuSettings && roleMenuSettings.label) menuLabel = roleMenuSettings.label;
                        }

                        porta.userHasAccess = userHasAccess;
                        porta.menuLabel = menuLabel;
                        includedPortas.push(porta);
                    }
                })
                // console.log('%c PortalHeader includedPortas: %O', 'color: maroon;', includedPortas);

                let dividerAdded: boolean = false;
                let portalMenuIconButton: any = null;
                if (includedPortas && includedPortas.length > 0) {
                    portalMenuIconButton = (
                        <IconButton color="secondary" aria-label="open menu"
                                    onClick={handlePortalMenuOpen}
                                    edge="start" sx={sxOpenPortalMenuButton}
                        >
                            <OpenPortalMenuIcon />
                        </IconButton>
                    );
                }

                menuAppBarElement = (
                    <AppBar open={openPortalMenu}>
                        <Toolbar sx={sxPortalHeaderToolbar}>
                            {portalMenuElement}
                            {adminMenuItem}
                            {portalMenuIconButton}
                            {(!openPortalMenu ? userProfileCardAvatar : null)}
                        </Toolbar>
                    </AppBar>
                );

                menuDrawerHeader = (
                    <Fragment>
                        <IconButton color="secondary" aria-label="close menu"
                                    onClick={handlePortalMenuClose}
                                    edge="start" sx={sxClosePortalMenuButton}
                        >
                            <Icon>close</Icon>
                        </IconButton>
                        {(openPortalMenu ? userProfileCardAvatar : null)}
                        <Box sx={{ flexGrow: 1 }} />
                        {nextProductionReleaseInfoElement}
                    </Fragment>
                );
                if (isDeveloper || isSpecialUser) {
                    let adminMenuSettings: any = {
                        position: 'absolute',
                        bottom: '1px',
                        left: '1px',
                        right: '1px',
                        padding: '8px',
                        cursor: 'pointer',
                        background: portalMenuContainerStyle.backgroundColor,
                        borderTop: '1px solid #ddd',
                    };
                    if (portalMenuContainerStyle && portalMenuContainerStyle.borderRight) {
                        adminMenuSettings.borderTop = portalMenuContainerStyle.borderRight;
                    }
                    adminMenuItem = (
                        <Box sx={adminMenuSettings} onClick={onToggleDesignMode}>
                            <IconButton size="small" color="secondary" sx={{ marginRight: '8px', padding: 0 }}>
                                <Icon fontSize="small">{(dm === 'on' ? 'view_in_ar' : 'settings')}</Icon>
                            </IconButton>
                            <Typography variant="clickableSubTitle1">
                                {(dm === 'on' ? "View Portal" : "Configure Portal")}
                            </Typography>
                        </Box>
                    );
                }

                menuDrawerContent = (
                    <Box sx={portalMenuContainerStyle}>
                        <Box sx={{ paddingBottom: '40px' }}>
                            <List sx={{ color: 'unset' }}>
                                {
                                    includedPortas.map((porta: any, index: number) => {
                                        // console.log('%c PortalHeader porta: %O', 'color: maroon;', porta);
                                        let settingsJson: any = (porta.SettingsJson ? porta.SettingsJson : null);
                                        // console.log('%c PortalHeader settingsJson: %O', 'color: maroon;', settingsJson);
                                        let ms: any = (settingsJson && settingsJson.menu ? settingsJson.menu : null);
                                        // console.log('%c PortalHeader ms: %O', 'color: maroon;', ms);
                                        let menuLabel: string = (ms && ms.label ? ms.label : porta.Name);
                                        // console.log('%c PortalHeader menuLabel: %O', 'color: maroon;', menuLabel);
                                        let access: any = (settingsJson && settingsJson.access ? settingsJson.access : null);
                                        // console.log('%c PortalHeader access: %O', 'color: maroon;', access);
                                        let accessRoles: any = (access && access.roles ? access.roles : null);
                                        // console.log('%c PortalHeader accessRoles: %O', 'color: maroon;', accessRoles);
                                        let userHasAccess: boolean = false;
                                        // if (dm === 'on') {
                                        //     userHasAccess = true;
                                        // } else {
                                        // }
                                        if (accessRoles) {
                                            if (udicciUserRoleId && accessRoles[udicciUserRoleId] === true) userHasAccess = true;
                                            if (!udicciUserRoleId && publicRoleId && accessRoles[publicRoleId] === true) userHasAccess = true;
                                        }
                                        // console.log('%c PortalHeader userHasAccess: %O', 'color: maroon;', userHasAccess);

                                        let menuRoles: any = (ms && ms.roles ? ms.roles : null);
                                        // console.log('%c PortalHeader menuRoles: %O', 'color: blue;', menuRoles);
                                        if (menuRoles && menuRoles[udicciUserRoleId]) {
                                            let roleMenuSettings: any = menuRoles[udicciUserRoleId];
                                            // console.log('%c PortalHeader roleMenuSettings: %O', 'color: blue;', roleMenuSettings);
                                            // console.log('%c PortalHeader roleMenuSettings.label: %O', 'color: blue;', roleMenuSettings.label);
                                            if (roleMenuSettings && roleMenuSettings.label) menuLabel = roleMenuSettings.label;
                                        }
                
                                        if (userHasAccess || dm === 'on') {
                                            var listItemTextElement: any = null;
                                            if (userHasAccess) {
                                                if (selectedPorta && selectedPorta.UdicciRecordId === porta.UdicciRecordId) {
                                                    listItemTextElement = (
                                                        <Fragment>
                                                            <Typography variant="body2" component="span">
                                                                &gt;&gt;&gt;&nbsp;
                                                            </Typography>
                                                            <Typography variant="body2" component="span">
                                                                {menuLabel}
                                                            </Typography>
                                                        </Fragment>
                                                    );
                                                } else {
                                                    listItemTextElement = (
                                                        <Typography variant="clickableSubTitle1">
                                                            {menuLabel}
                                                        </Typography>
                                                    );
                                                }
                                            } else if (dm === 'on') {
                                                if (selectedPorta && selectedPorta.UdicciRecordId === porta.UdicciRecordId) {
                                                    listItemTextElement = (
                                                        <Fragment>
                                                            <Typography variant="body2" component="span">
                                                                &gt;&gt;&gt;&nbsp;
                                                            </Typography>
                                                            <Typography variant="body2" component="span">
                                                                {menuLabel}
                                                            </Typography>
                                                        </Fragment>
                                                    );
                                                } else {
                                                    listItemTextElement = (
                                                        <Typography variant="clickableSubTitle2">
                                                            {menuLabel}
                                                        </Typography>
                                                    );
                                                }
                                            }

                                            let dividerListItem: any = null;
                                            if (!dividerAdded && !accessRoles) {
                                                dividerListItem = standardDividerElement;
                                                dividerAdded = true;
                                            }

                                            return (
                                                <Fragment key={porta.UdicciRecordId}>
                                                    {dividerListItem}
                                                    <ListItem button dense onClick={(evt: any) => setPorta(porta)}>
                                                        <ListItemText disableTypography={true} primary={listItemTextElement} />
                                                    </ListItem>
                                                </Fragment>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                                {addPortaElement}
                            </List>
                        </Box>
                        {adminMenuItem}
                    </Box>
                );

                // classes={{ root: udicciClasses.portalHeader }} 
            } else {
                if (dm === 'on') {
                    adminMenuItem = (
                        <IconButton size="small" color="secondary" sx={{ marginRight: '8px', padding: 0 }} onClick={onToggleDesignMode}>
                            <Icon fontSize="small">view_in_ar</Icon>
                        </IconButton>
                    );
                }
                menuAppBarElement = 
                    (<ElevationScroll {...props}>
                        <AppBar>
                            <Toolbar sx={{ root: udicciClasses.portalToolbarRoot }}>
                                {portalMenuElement}
                                {adminMenuItem}
                                <Box sx={udicciClasses.portalToolbarContent}>
                                    {userProfileCardAvatar}
                                </Box>
                            </Toolbar>
                        </AppBar>
                    </ElevationScroll>);
                menuDrawerElement = (<Toolbar sx={{ root: udicciClasses.headerSpacerToolbar }} />);
            }
        }
    }

    let newPalette: any = null;
    // console.log('%c PortalHeader portalMenuPalette: %O', 'color: maroon;', portalMenuPalette);
    if (portalMenuPalette) {
        if (!newPalette) newPalette = {};
        Object.assign(newPalette, portalMenuPalette);
    }
    // console.log('%c PortalHeader newPalette: %O', 'color: maroon;', newPalette);

    let newTypography: any = null;
    if (headerPrimaryColor) {
        let primaryColor: string = getColorValue(headerPrimaryColor);
        if (!newTypography) newTypography = {};
        newTypography.subtitle1 = { main: primaryColor };
    }
    if (headerSecondaryColor) {
        let secondaryColor: string = getColorValue(headerSecondaryColor);
        if (!newTypography) newTypography = {};
        newTypography.caption = { main: secondaryColor };
    }
    if (portalMenuPalette && portalMenuPalette.primary) {
        if (!newTypography) newTypography = {};
        newTypography.clickableSubTitle2 = portalMenuPalette.primary;
    }
    if (portalMenuPalette && portalMenuPalette.secondary) {
        if (!newTypography) newTypography = {};
        newTypography.helpText = portalMenuPalette.secondary;
    }
    // console.log('%c PortalHeader newTypography: %O', 'color: maroon;', newTypography);

    // let headerThemeSettings: any = {};
    // Object.assign(headerThemeSettings, theme);
    // // if (newPalette) headerThemeSettings.palette = newPalette;
    // // if (headerComponentSettings) headerThemeSettings.components = headerComponentSettings;
    // let headerThemeTypographySettings: any = (headerThemeSettings.typography && headerThemeSettings.typography ? headerThemeSettings.typography : {});
    // if (newTypography) Object.assign(headerThemeTypographySettings, newTypography);
    // headerThemeSettings.typography = headerThemeTypographySettings;
    // const headerTheme = createTheme(headerThemeSettings);
    // console.log('%c PortalHeader headerTheme: %O', 'color: maroon;', headerTheme);

    if (menuDrawerHeader || menuDrawerContent) {
        // let portalMenuTheme: Theme = headerTheme;
        // if (portalMenuPalette) portalMenuTheme = createTheme({ palette: portalMenuPalette });

        let drawerSx: any = {
            width: drawerWidth,
            flexShrink: 0,
            backgroundColor: 'unset !important',
            '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: 'unset !important',
            },
        };
        menuDrawerElement = (
            <Drawer sx={drawerSx} variant="persistent" anchor="left" open={openPortalMenu}>
                <DrawerHeader style={sxPortalHeader}>{menuDrawerHeader}</DrawerHeader>
                <DrawerContent>{menuDrawerContent}</DrawerContent>
            </Drawer>
        );
        headerContentElement = (<Fragment><CssBaseline />{menuAppBarElement}{menuDrawerElement}{saveProfileSnackbarMessage}</Fragment>);
    }
    return headerContentElement;
    // return (<ThemeProvider theme={headerTheme}>{headerContentElement}</ThemeProvider>);
};

function ElevationScroll(props: any) {
    const { children } = props;
    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0, target: window, });
    return cloneElement(children, { elevation: trigger ? 4 : 0 });
}

ElevationScroll.propTypes = { children: PropTypes.element.isRequired };
