
import React, { useState } from 'react';

import { Box, Typography, Button, ButtonGroup, IconButton, Icon, Chip, Avatar } from '@mui/material';

import { forEach } from 'underscore';

// import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

import EngagementActionForm from './engagement-action-form';

const EngagementActionsConfiguration: React.FC<any> = (props) => {
    let { engagementActions, onApplyChanges } = props;
    // console.log('%c engagementActions: %O', 'color: maroon;', engagementActions);

    const [editEngagementAction, setEditEngagementAction] = useState<any>(null);
    // console.log('%c EngagementActionsConfiguration editEngagementAction: %O', 'color: red;', editEngagementAction);

    const applyChanges = (updatedEngagementAction: any) => {
        // console.log('%c applyChanges updatedEngagementAction: %O', 'color: blue;', updatedEngagementAction);
        setEditEngagementAction(updatedEngagementAction);
        if (onApplyChanges) onApplyChanges();
    };

    const onEditEngagementAction = (engagementActionToEdit: any) => {
        // console.log('%c onEditEngagementAction engagementActionToEdit: %O', 'color: blue;', engagementActionToEdit);
        setEditEngagementAction(engagementActionToEdit);
    };

    const removeEngagementAction = (engagementActionToRemove: any) => {
        // console.log('%c removeEngagementAction engagementActionToRemove: %O', 'color: red;', engagementActionToRemove);
    };

    const onCloseEngagementAction = () => {
        setEditEngagementAction(null);
    };

    let assignEngagementActionsElement: any = null;
    let eas: any[] = [];
    if (engagementActions && engagementActions.length > 0) {
        forEach(engagementActions, (engagementAction: any, engagementActionsIndex: number) => {
            // console.log('%c engagementAction: %O', 'color: maroon;', engagementAction);
            // console.log('%c engagementActionsIndex: %O', 'color: maroon;', engagementActionsIndex);
            eas.push({
                uid: engagementAction.uid,
                name: engagementAction.name,
                settings: engagementAction,
                edit: (editEngagementAction && editEngagementAction.uid === engagementAction.uid ? true : false)
            });
        });
    }

    if (eas.length > 0) {
        let sortedEngagementActions: any[] = eas.sort((a: any,b: any) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;  // ascending
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;  // descending
            return 0 //default return value (no sorting)
        });
        // console.log('%c sortedEngagementActions: %O', 'color: maroon;', sortedEngagementActions);

        assignEngagementActionsElement = (
            <Box>
                {sortedEngagementActions.map((ea: any) => {
                    let labelText: string = (ea.settings && ea.settings.label && ea.settings.label.text ? ea.settings.label.text : ea.name).trim();
                    if (ea.edit) {
                        return (
                            <Box key={ea.uid}>
                                <Typography variant="subtitle1" component="span">{labelText}</Typography>
                                <IconButton size="small" onClick={(evt: any) => onEditEngagementAction(null)}>
                                    <Icon fontSize="small">close</Icon>
                                </IconButton>
                                <Box>
                                    <EngagementActionForm engagementAction={editEngagementAction}
                                                          onApply={applyChanges}
                                                          onClose={onCloseEngagementAction}
                                    />
                                </Box>
                            </Box>
                        );
                    } else if (!editEngagementAction) {
                        let avatarText: string = '';
                        if (labelText) {
                            if (labelText.indexOf(' ') > 0) {
                                let arr: string[] = labelText.split(' ');
                                forEach(arr, (s: string) => { avatarText += s.substring(0, 1); });
                            } else {
                                avatarText = labelText.substring(0, 1);
                            }
                        } else {
                            avatarText = 'EA';
                        }
                        return (
                            <Chip key={ea.uid}
                                  color="secondary"
                                  sx={{ margin: '4px' }}
                                  onDelete={(evt: any) => onEditEngagementAction(ea.settings)}
                                  avatar={<Avatar>{avatarText}</Avatar>}
                                  variant="outlined"
                                  label={labelText}
                                  deleteIcon={<Icon>settings_applications</Icon>}
                            />
                        );
                    }
                })}
            </Box>
        );
    } else {
        assignEngagementActionsElement = (
            <Box>
                <Typography color="secondary">
                    no {(engagementActions && engagementActions.length > 0 ? 'more' : null)} engagement actions to assign.
                    you can assign current or new engagement actions to the solution from the Engagement Actions tab.
                </Typography>
            </Box>
        );
    }

    let engagementActionsElement: any = ( <Box> {assignEngagementActionsElement} </Box> );
    return engagementActionsElement;
}

export default EngagementActionsConfiguration;
