import { useState } from 'react';

import { FilePond, registerPlugin } from 'react-filepond';
import { FilePondInitialFile } from 'filepond';

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginFileValidateSize );

export default function BitcoinSvFileUpload(data: any) {
    // console.log('%c BitcoinSvFileUpload data: %O', 'color: maroon;', data);

    const [files, setFiles] = useState<(string | FilePondInitialFile | Blob)[]>([]);

    // Handles file upload event and updates state
    function updateFiles(fileItems: any) {
        // console.log('%c updateFiles fileItems: %O', 'color: maroon;', fileItems);
        setFiles(fileItems);
        // setFile(event.target.files[0]);
        // console.log('%c BitcoinSvFileUpload file: %O', 'color: maroon;', file);

        // Add code here to upload file to server
        // ...
    }
    // console.log('%c BitcoinSvFileUpload file: %O', 'color: maroon;', file);
  
    return (
        <div id="upload-box">
            <FilePond files={files}
                      onupdatefiles={updateFiles}
                      allowMultiple={false}
                      dropOnPage
                      name="files"
                      dropValidation
            />
        </div>
    );
}

// const ImageThumb = (image: any) => {
//     return <img src={URL.createObjectURL(image)} alt={image.name} />;
// };
