import React, { useContext, useState } from 'react';

import { forEach, find } from 'underscore';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import InfoIcon from '@mui/icons-material/Info';

import { UdicciContext, formatStringForDisplay } from 'src/context/udicci-context';

import useUdicciProfile from 'src/hooks/useUdicciProfile';
import useUdicciSocialSolution from 'src/hooks/useUdicciSocialSolution';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import UdicciPermissionsSetter from './udicci/udicci-permissions-setter';
import { UdicciPermissions } from 'src/classes/udicci-record';
import EngageButton from 'src/components/UIT/engage-button';

const ConfigurePortalPermissions: React.FC<any> = (props) => {
    // console.log('%c ConfigurePortalPermissions props: %O', 'color: maroon;', props);

    const udicciContext = useContext(UdicciContext);
    var { udicci } = udicciContext.state;
    var { selectedProfile } = udicci;
    // console.log('%c ConfigurePortalPermissions selectedProfile: %O', 'color: maroon;', selectedProfile);

    const profile = useUdicciProfile(selectedProfile);
    var { udicciProfile } = profile;
    // console.log('%c useUdicciSocialSolution udicciProfile: %O', 'color: darkgoldenrod;', udicciProfile);

    var profileData = (udicciProfile && udicciProfile.data ? udicciProfile.data : null);
    var profileSocialSolutions: any = (profileData && profileData.SocialSolutions ? profileData.SocialSolutions : null);
    // console.log('%c ConfigurePortalPermissions profileSocialSolutions: %O', 'color: maroon;', profileSocialSolutions);

    var ssElements: any[] = [];
    if (profileSocialSolutions && profileSocialSolutions.length > 0) {
        forEach(profileSocialSolutions, (ss: any) => {
            // console.log('%c ss: %O', 'color: red; font-weight: bold;', ss);
            var socialSolutionCardKey = 'social.solution.card.' + ss.recordId.toString();
            ssElements.push(
                <Box key={socialSolutionCardKey} style={{ width: '100%' }}>
                    <Box sx={{ width: '100%', padding: '8px' }}>
                        <ConfigSolution solution={ss} {...props} />
                    </Box>
                </Box>
            );
        });
    }

    return (
        <Box>
            <Typography variant="caption" component="div">
                Set permissions for your social solutions below:
            </Typography>
            <Grid container spacing={1} direction="row" alignItems="stretch"> {ssElements} </Grid>
        </Box>
    );
};

const ConfigSolution: React.FC<any> = (props) => {
    // console.log('%c ConfigSolution props: %O', 'color: maroon;', props);
    var { solution } = props;
    // console.log('%c ConfigSolution solution %s: %O', 'color: blue;', solution.title, solution);
    // console.log('%c ConfigSolution roles: %O', 'color: maroon;', roles);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useContext(UdicciContext);

    var { udicci } = udicciContext.state;
    var { selectedProfile } = udicci;
    // console.log('%c ConfigSolution selectedProfile: %O', 'color: maroon;', selectedProfile);

    var roles = udicciHelpers.preloadMediatorData('Roles', udicci.socialSolutionUdicciIT);
    // console.log('%c roles: %O', 'color: maroon;', roles);

    const [showSolutionDesc, toggleSolutionDesc] = useState<boolean>(false);
    const [showUnassignedRoles, toggleUnassignedRoles] = useState<boolean>(false);

    const profile = useUdicciProfile(selectedProfile);
    var { udicciProfile, changePermission } = profile;
    // console.log('%c useUdicciSocialSolution udicciProfile: %O', 'color: darkgoldenrod;', udicciProfile);

    const udicciSocialSolution = useUdicciSocialSolution(solution);
    var { socialSolution, getMediatorPermissions } = udicciSocialSolution;
    // var socialSolutionName: string = (socialSolution && socialSolution.title ? socialSolution.title : '');
    var socialSolutionId: number = (socialSolution && socialSolution.recordId ? socialSolution.recordId : 0);
    // console.log('%c useUdicciRecord socialSolution %s (%s): %O', 'color: red;', socialSolutionName, socialSolutionId, socialSolution);
    var profileData = (udicciProfile && udicciProfile.data ? udicciProfile.data : null);
    // console.log('%c ConfigSolution profileData: %O', 'color: maroon;', profileData);
    var profileSettings: any = (profileData && profileData.jsonProfileSettingsJson ? profileData.jsonProfileSettingsJson : null);
    // console.log('%c ConfigSolution profileSettings: %O', 'color: maroon;', profileSettings);
    var sss: any = (profileSettings && profileSettings.socialSolutionSettings ? profileSettings.socialSolutionSettings : null);
    // console.log('%c ConfigSolution socialSolutionSettings: %O', 'color: maroon;', sss);
    var pssSettings: any = (sss && sss[socialSolutionId] ? sss[socialSolutionId] : null);
    // console.log('%c ConfigSolution pssSettings for %s (%s): %O', 'color: maroon;', socialSolutionName, socialSolutionId, pssSettings);

    var publicPermissions: UdicciPermissions = (pssSettings && pssSettings.public ? pssSettings.public : null);
    // console.log('%c ConfigSolution publicPermissions: %O', 'color: maroon;', publicPermissions);
    var defaultPermissions: UdicciPermissions = (pssSettings && pssSettings.default ? pssSettings.default : null);
    // console.log('%c ConfigSolution defaultPermissions: %O', 'color: maroon;', defaultPermissions);
    var roleOverrides = (pssSettings && pssSettings.RoleOverrides ? pssSettings.RoleOverrides : null);
    // console.log('%c ConfigSolution roleOverrides: %O', 'color: maroon;', roleOverrides);

    var ssData: any = (socialSolution && socialSolution.data ? socialSolution.data : null);
    var ssName: any = (ssData && ssData.Name ? ssData.Name : '');
    var ssPurpose: any = (ssData && ssData.Purpose ? ssData.Purpose : '');
    var solutionFeatures: any = (ssData && ssData.Features ? ssData.Features : null);
    var jsonSettingsJson: any = (ssData && ssData.jsonSettingsJson ? ssData.jsonSettingsJson : null);
    var ssMediators: any = (jsonSettingsJson && jsonSettingsJson.mediators ? jsonSettingsJson.mediators : null);
    // console.log('%c ConfigSolution ssMediators: %O', 'color: maroon;', ssMediators);

    const permissionChange = (settings: any, permissionType: string, role: any = null) => {
        // console.log('%c permissionChange settings: %O', 'color: red;', settings);
        // console.log('%c permissionChange permissionType: %O', 'color: red;', permissionType);
        // console.log('%c permissionChange role: %O', 'color: red;', role);
        if (!settings) settings = {};
        settings.permissionType = permissionType;
        settings.role = role;
        changePermission(settings);
    }

    var showFeatures: boolean = false;

    var ssFeatureElements: any[] = [];
    if (showFeatures && solutionFeatures && solutionFeatures.length > 0) {
        forEach(solutionFeatures, (ftre: any) => {
            // console.log('%c ftre: %O', 'color: red; font-weight: bold;', ftre);
            // console.log('%c recordId: %O', 'color: hotpink;', recordId);
            // var featurePermissions: any = getFeaturePermissions({ feature: ftre })
            // console.log('%c featurePermissions: %O', 'color: red; font-weight: bold;', featurePermissions);
            var featureMediators = (ftre.Mediators ? ftre.Mediators : null);
            if (featureMediators && typeof(featureMediators) === 'string') {
                try {
                    featureMediators = JSON.parse(featureMediators);
                } catch (e: any) {

                }
            }
            // console.log('%c featureMediators: %O', 'color: maroon;', featureMediators);

            var ssFeatureMediatorElements: any[] = [];
            if (ssMediators) {
                // console.log('%c ssMediators: %O', 'color: red;', ssMediators);
                forEach(ssMediators, (sm: any) => {
                    // console.log('%c sm: %O', 'color: red; font-weight: bold;', sm);

                    var isFeatureMediator: boolean = false;
                    if (ftre.Name === sm.feature) {
                        isFeatureMediator = true;
                    } else if (sm.features && sm.features.length > 0) {
                        var ftreCheck = find(sm.features, function(f: any) {
                            return f.name === ftre.Name;
                        });
                        if (ftreCheck) isFeatureMediator = true;
                    }

                    if (isFeatureMediator) {
                        var featureMediatorPermissions: any = getMediatorPermissions({ feature: ftre, mediator: sm.name })
                        // console.log('%c featureMediatorPermissions: %O', 'color: red; font-weight: bold;', featureMediatorPermissions);

                        if (featureMediatorPermissions && featureMediatorPermissions.CanView) {
                            var featureMediatorKey = 'feature.mediator.' + sm.id.toString();
                            ssFeatureMediatorElements.push(
                                <Typography variant="caption" component="div" key={featureMediatorKey}>
                                    {sm.name}
                                </Typography>
                            );
                        }
                    }
                });
            } else if (featureMediators && featureMediators.length > 0) {
                forEach(featureMediators, (fm: any) => {
                    // console.log('%c fm: %O', 'color: red; font-weight: bold;', fm);

                    // var featureMediatorPermissions: any = getMediatorPermissions({ feature: ftre, mediator: fm.name })
                    // console.log('%c featureMediatorPermissions: %O', 'color: red; font-weight: bold;', featureMediatorPermissions);

                    var featureMediatorKey = 'feature.mediator.' + fm.id.toString();
                    ssFeatureMediatorElements.push(
                        <Typography variant="caption" component="div" key={featureMediatorKey}>
                            {fm.name}
                        </Typography>
                    );
                });
            }

            if (ssFeatureMediatorElements.length > 0) {
                var socialSolutionFeatureCardKey = 'feature.card.' + ftre.UdicciRecordId.toString();
                ssFeatureElements.push(
                    <Box key={socialSolutionFeatureCardKey}>
                        <Typography variant="caption" component="div">
                            {ftre.Name}
                        </Typography>
                        {ssFeatureMediatorElements}
                    </Box>
                );
            }
        });
    }

    var publicPermsSetterKey = 'social.solution.public.permission.' + socialSolution.recordId.toString();
    var publicPermsSetter: any = (
        <UdicciPermissionsSetter key={publicPermsSetterKey}
                                 permissions={publicPermissions}
                                 solution={socialSolution}
                                 title={'Public'}
                                 onPermissionChanged={(settings: any) => permissionChange(settings, 'public')}
        />
    );
    var publicPermsRow: any = (
        <TableRow>
            <TableCell>
                {publicPermsSetter}
            </TableCell>
        </TableRow>
    );

    var defaultPermsSetterKey = 'social.solution.default.permission.' + socialSolution.recordId.toString();
    var defaultPermsSetter: any = (
        <UdicciPermissionsSetter key={defaultPermsSetterKey}
                                 permissions={defaultPermissions}
                                 solution={socialSolution}
                                 title={'Default'}
                                 onPermissionChanged={(settings: any) => permissionChange(settings, 'default')}
        />
    );
    var defaultPermsRow: any = (
        <TableRow>
            <TableCell>
                {defaultPermsSetter}
            </TableCell>
        </TableRow>
    );

    var rolesDisplayedCount: number = 0;
    var rolePermissionElements: any[] = [];
    var unassignedRolePermissionElements: any[] = [];
    var footerRowElement: any = null;
    if (roles && roles.length > 0) {
        forEach(roles, (role: any) => {
            // console.log('%c role %s: %O', 'color: red; font-weight: bold;', role.title, role);
            // console.log('%c recordId: %O', 'color: hotpink;', recordId);

            // console.log('%c roleOverrides: %O', 'color: red; font-weight: bold;', roleOverrides);
            var roleOverride: any = (roleOverrides && roleOverrides[role.recordId] ? roleOverrides[role.recordId] : null);
            // console.log('%c roleOverride: %O', 'color: red; font-weight: bold;', roleOverride);

            var rolePermsSetterKey = 'social.solution.role.permissions.' + socialSolution.recordId.toString() + '.' + role.recordId.toString();
            var rolePermsSetter: any = (
                <UdicciPermissionsSetter key={rolePermsSetterKey}
                                         permissions={roleOverride}
                                         solution={socialSolution}
                                         title={role.title}
                                         onPermissionChanged={(settings: any) => permissionChange(settings, 'role', role)}
                />
            );

            var rolePermElementKey = 'role.permission.card.' + role.recordId.toString();

            var rolePermsRow: any = null;
            if (roleOverride || showUnassignedRoles) {
                rolePermsRow = (
                    <TableRow key={rolePermElementKey}>
                        <TableCell>
                            {rolePermsSetter}
                        </TableCell>
                    </TableRow>
                );
            }

            if (rolePermsRow) {
                if (roleOverride) {
                    rolePermissionElements.push(rolePermsRow);
                    rolesDisplayedCount++;
                } else if (showUnassignedRoles) {
                    unassignedRolePermissionElements.push(rolePermsRow);
                }
            }
        });

        if (rolesDisplayedCount < roles.length) {
            var engageButtonText: string = (showUnassignedRoles ? 'close' : 'add') + ' role access';
            // footerRowElement = (
            //     <TableRow>
            //         <TableCell align="right">
            //             <EngageButton text={engageButtonText} enabled={true} onClick={(evt: any) => toggleUnassignedRoles(!showUnassignedRoles)} />
            //         </TableCell>
            //     </TableRow>
            // );
            footerRowElement = (
                <Box sx={{ marginLeft: '8px', display: 'block' }}>
                    <EngageButton text={engageButtonText} enabled={true} onClick={(evt: any) => toggleUnassignedRoles(!showUnassignedRoles)} />
                </Box>
            );
        }
    }

    var toggleDescriptionElement: any = (
        <IconButton aria-label="Social Solution Description" size="small"
                    color="primary"
                    onClick={(evt: any) => toggleSolutionDesc(!showSolutionDesc)}
        >
            <InfoIcon />
        </IconButton>
    );

    var solutionDescriptionElement: any = null;
    if (showSolutionDesc) {
        solutionDescriptionElement = (
            <Typography variant="caption" component="div">
                {formatStringForDisplay(ssPurpose)}
            </Typography>
        );
    }

    let tableFooter: any = (
        <TableFooter>
            {footerRowElement}
        </TableFooter>
    );
    return (
        <Box sx={{ margin: '8px', padding: '4px', background: 'rgba(255, 255, 255, 0.1)' }}>
            <Box>
                <Typography variant="subtitle2" component="span">
                    {ssName}
                </Typography>
                {toggleDescriptionElement}
            </Box>
            {solutionDescriptionElement}
            <TableContainer>
                <Table padding="none" size="small">
                    <TableBody>
                        {publicPermsRow}
                        {defaultPermsRow}
                        {rolePermissionElements}
                        {unassignedRolePermissionElements}
                        {ssFeatureElements}
                    </TableBody>
                </Table>
            </TableContainer>
            {footerRowElement}
        </Box>
    );
};

export default ConfigurePortalPermissions;
