
import React, { useState } from 'react';

import { forEach, values, mapObject } from 'underscore';

import { useTheme, ThemeProvider, Theme, styled } from '@mui/material/styles';

import { SketchPicker } from 'react-color';

import {
    Box, Paper, Typography, Menu, MenuItem, Select, InputLabel, Slider, Grid, Tabs, Tab,
    Accordion, AccordionDetails, AccordionSummary, Button, IconButton, Icon, Tooltip, Avatar
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useUdicciHelpers, { TabPanel } from 'src/hooks/useUdicciHelpers';

const ConfigureContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
    padding: '8px !important',
    margin: '4px !important',
}));

const UdicciSelectComponent = styled(Select)(({ theme }) => ({
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary,
}));

const DesignSettingsAdmin: React.FC<any> = (props) => {
    const [uiState, setUiState] = useState<boolean>(false);
    const [selectedTextCategory, setSelectedTextCategory] = useState<string>('');
    const [selectedPaletteCategory, setSelectedPaletteCategory] = useState<string>('');
    const [expanded, setExpanded] = useState<string | false>(false);
    const [containerHelpShown, setContainerHelpShown] = useState<boolean>(true);
    const [containerTab, setContainerTab] = useState<number | false>(false);
    const [selectedSetting, setSelectedSetting] = useState<any>(null);
    const [selectedColorSetting, setSelectedColorSetting] = useState<any>(null);
    const [colorMenuAnchorEl, setColorMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    // console.log('%c DesignSettingsAdmin selectedSetting: %O', 'color: maroon;', selectedSetting);
    // console.log('%c DesignSettingsAdmin selectedColorSetting: %O', 'color: maroon;', selectedColorSetting);

    const menuIsOpen = Boolean(colorMenuAnchorEl);
    const handleClickColor = (event: React.MouseEvent<HTMLElement>, colorIdentifier: any) => {
        // console.log('%c handleClickColor colorIdentifier: %O', 'color: maroon;', colorIdentifier);
        setSelectedColorSetting(colorIdentifier);
        setColorMenuAnchorEl(event.currentTarget);
    };
    const handleCloseColorMenu = () => {
        setColorMenuAnchorEl(null);
        setSelectedColorSetting(null);
    };
    const toggleContainerHelp = (evt: any) => {
        evt.stopPropagation();
        setContainerHelpShown(!containerHelpShown);
    };

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleContainerTabClicked = (containerType: string, index: number) => {
        // console.log('%c handleContainerTabClicked containerTab: %O', 'color: blue;', containerTab);
        // console.log('%c handleContainerTabClicked containerType: %O', 'color: blue;', containerType);
        // console.log('%c handleContainerTabClicked index: %O', 'color: blue;', index);
        setContainerTab(containerTab !== index ? index : false);
    };

    const textSettingsIconClicked = (settings: any) => {
        if (selectedSetting && settings && settings.key === selectedSetting.key) {
            setSelectedSetting(null);
        } else {
            setSelectedSetting(settings);
        }
    };

    const changeTextSettingsCategory = (evt: any) => {
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        setSelectedTextCategory(newValue);
    };

    const changePaletteSettingsCategory = (evt: any) => {
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        setSelectedPaletteCategory(newValue);
    };

    const udicciHelpers = useUdicciHelpers();
    const theme = useTheme();
    // console.log('%c DesignSettingsAdmin theme: %O', 'color: maroon;', theme);
    let paletteSettings: any = (theme && theme.palette ? theme.palette : null);
    // console.log('%c DesignSettingsAdmin paletteSettings: %O', 'color: maroon;', paletteSettings);
    let typographySettings: any = (theme && theme.typography ? theme.typography : null);
    // console.log('%c DesignSettingsAdmin typographySettings: %O', 'color: maroon;', typographySettings);

    let designTarget: any = (props && props.target ? props.target : 'pane');
    // console.log('%c DesignSettingsAdmin designTarget: %O', 'color: maroon;', designTarget);
    let currentSettings: any = (props && props.currentSettings ? props.currentSettings : null);
    // console.log('%c DesignSettingsAdmin currentSettings: %O', 'color: maroon;', currentSettings);

    let outerPane: any = (currentSettings && currentSettings.outer ? currentSettings.outer : null);
    // console.log('%c DesignSettingsAdmin outerPane: %O', 'color: maroon;', outerPane);
    let outerPaneBackground: any = (outerPane && outerPane.backgroundColor ? outerPane.backgroundColor : '');
    let outerPaneBorderColor: any = (outerPane && outerPane.borderColor ? outerPane.borderColor : '');
    let outerPaneBorderStyle: any = (outerPane && outerPane.borderStyle ? outerPane.borderStyle : '');
    let outerPaneBorderRadius: any = (outerPane && outerPane.borderRadius ? outerPane.borderRadius : '');
    let outerPaneBorderWidth: any = (outerPane && outerPane.borderWidth !== undefined ? outerPane.borderWidth : 1);
    let outerPaneMargin: any = (outerPane && outerPane.margin !== undefined ? outerPane.margin : 1);
    let outerPanePadding: any = (outerPane && outerPane.padding !== undefined ? outerPane.padding : 1);

    let innerPane: any = (currentSettings && currentSettings.inner ? currentSettings.inner : null);
    // console.log('%c DesignSettingsAdmin innerPane: %O', 'color: maroon;', innerPane);
    let innerPaneBackground: any = (innerPane && innerPane.backgroundColor ? innerPane.backgroundColor : '');
    let innerPaneBorderColor: any = (innerPane && innerPane.borderColor ? innerPane.borderColor : '');
    let innerPaneBorderStyle: any = (innerPane && innerPane.borderStyle ? innerPane.borderStyle : '');
    let innerPaneBorderRadius: any = (innerPane && innerPane.borderRadius ? innerPane.borderRadius : '');
    let innerPaneBorderWidth: any = (innerPane && innerPane.borderWidth !== undefined ? innerPane.borderWidth : 1);
    let innerPaneMargin: any = (innerPane && innerPane.margin !== undefined ? innerPane.margin : 1);
    let innerPanePadding: any = (innerPane && innerPane.padding !== undefined ? innerPane.padding : 1);

    let paperPane: any = (currentSettings && currentSettings.paper ? currentSettings.paper : null);
    // console.log('%c DesignSettingsAdmin paperPane: %O', 'color: maroon;', paperPane);
    let paperPaneBackground: any = (paperPane && paperPane.backgroundColor ? paperPane.backgroundColor : '');
    let paperPaneBorderColor: any = (paperPane && paperPane.borderColor ? paperPane.borderColor : '');
    let paperPaneBorderStyle: any = (paperPane && paperPane.borderStyle ? paperPane.borderStyle : '');
    let paperPaneBorderRadius: any = (paperPane && paperPane.borderRadius ? paperPane.borderRadius : '');
    let paperPaneBorderWidth: any = (paperPane && paperPane.borderWidth !== undefined ? paperPane.borderWidth : 1);
    let paperPaneMargin: any = (paperPane && paperPane.margin !== undefined ? paperPane.margin : 1);
    let paperPanePadding: any = (paperPane && paperPane.padding !== undefined ? paperPane.padding : 1);

    let headerSettings: any = (currentSettings && currentSettings.header ? currentSettings.header : null);
    // console.log('%c DesignSettingsAdmin headerSettings: %O', 'color: maroon;', headerSettings);
    // let platformMenuColor: any = (headerSettings && headerSettings.platformMenuColor ? headerSettings.platformMenuColor : '');
    let headerBackgroundColor: any = (headerSettings && headerSettings.backgroundColor ? headerSettings.backgroundColor : '');
    let headerBorderBottomColor: any = (headerSettings && headerSettings.borderBottomColor ? headerSettings.borderBottomColor : '');
    let headerPrimaryColor: any = (headerSettings && headerSettings.primaryColor ? headerSettings.primaryColor : '');
    let headerSecondaryColor: any = (headerSettings && headerSettings.secondaryColor ? headerSettings.secondaryColor : '');

    let menuSettings: any = (currentSettings && currentSettings.menu ? currentSettings.menu : null);
    // console.log('%c DesignSettingsAdmin menuSettings: %O', 'color: maroon;', menuSettings);
    let menuColor: any = (menuSettings && menuSettings.iconColor ? menuSettings.iconColor : '');
    let menuBackgroundColor: any = (menuSettings && menuSettings.backgroundColor ? menuSettings.backgroundColor : '');
    let menuBorderRightColor: any = (menuSettings && menuSettings.borderRightColor ? menuSettings.borderRightColor : '');
    let activeMenuTextColor: any = (menuSettings && menuSettings.activeMenuTextColor ? menuSettings.activeMenuTextColor : '');
    let inactiveMenuTextColor: any = (menuSettings && menuSettings.inactiveMenuTextColor ? menuSettings.inactiveMenuTextColor : '');
    let selectedMenuIndicatorColor: any = (menuSettings && menuSettings.selectedMenuIndicatorColor ? menuSettings.selectedMenuIndicatorColor : '');

    // let textSettings: any = (currentSettings && currentSettings.text ? currentSettings.text : null);
    // console.log('%c DesignSettingsAdmin textSettings: %O', 'color: maroon;', textSettings);
    // let buttonSettings: any = (currentSettings && currentSettings.button ? currentSettings.button : null);
    // console.log('%c DesignSettingsAdmin buttonSettings: %O', 'color: maroon;', buttonSettings);

    const handleChangeDesign = (settingName: string, subSettingName: string, event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log('%c DesignSettingsAdmin handleChangeDesign currentSettings: %O', 'color: maroon;', currentSettings);
        // console.log('%c DesignSettingsAdmin handleChangeDesign settingName: %O', 'color: maroon;', settingName);
        // console.log('%c DesignSettingsAdmin handleChangeDesign subSettingName: %O', 'color: maroon;', subSettingName);
        if (props && props.onChange) {
            let trgt = event.target;
            // let fieldName = (trgt ? trgt.name : '');
            // console.log('%c DesignSettingsAdmin updatePortaDetails fieldName: %O', 'color: maroon;', fieldName);
            let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
            // console.log('%c DesignSettingsAdmin updatePortaDetails newValue: %O', 'color: maroon;', newValue);

            let update: any = (currentSettings ? currentSettings : {});
            if (subSettingName) {
                if (!update[settingName]) update[settingName] = {};
                update[settingName][subSettingName] = newValue;
            } else {
                update[settingName] = newValue;
            }
            props.onChange(update, '');
        }
    };

    const handleChangeRootSetting = (settingName: string, subSettingName: string, event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log('%c DesignSettingsAdmin handleChangeRootSetting currentSettings: %O', 'color: maroon;', currentSettings);
        // console.log('%c DesignSettingsAdmin handleChangeRootSetting settingName: %O', 'color: maroon;', settingName);
        // console.log('%c DesignSettingsAdmin handleChangeRootSetting subSettingName: %O', 'color: maroon;', subSettingName);
        // console.log('%c DesignSettingsAdmin handleChangeRootSetting selectedSetting: %O', 'color: maroon;', selectedSetting);
        if (props && props.onChange) {
            let trgt = event.target;
            let fieldName = (trgt ? trgt.name : '');
            // console.log('%c DesignSettingsAdmin handleChangeRootSetting fieldName: %O', 'color: maroon;', fieldName);
            let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
            // console.log('%c DesignSettingsAdmin handleChangeRootSetting newValue: %O', 'color: maroon;', newValue);
    
            let update: any = {};
            if (currentSettings) Object.assign(update, currentSettings);
            let updatedSetting: any = {};
            if (selectedSetting) Object.assign(updatedSetting, selectedSetting);
            if (fieldName) {
                if (!update[settingName]) update[settingName] = {};
                if (subSettingName && !update[settingName][subSettingName]) update[settingName][subSettingName] = {};
                update[settingName][subSettingName][fieldName] = newValue;

                if (!updatedSetting.settings) updatedSetting.settings = {};
                updatedSetting.settings[fieldName] = newValue;

                // cleanup
                if (update[settingName][subSettingName].letterSpacing !== undefined) delete update[settingName][subSettingName]["letterSpacing"];
                if (update[settingName][subSettingName].lineHeight !== undefined) delete update[settingName][subSettingName]["lineHeight"];
                if (updatedSetting.settings.letterSpacing !== undefined) delete updatedSetting.settings["letterSpacing"];
                if (updatedSetting.settings.lineHeight !== undefined) delete updatedSetting.settings["lineHeight"];
            } else if (subSettingName) {
                if (!update[settingName]) update[settingName] = {};
                update[settingName][subSettingName] = newValue;
            } else {
                update[settingName] = newValue;
            }
            // console.log('%c DesignSettingsAdmin handleChangeRootSetting update: %O', 'color: blue;', update);
            // console.log('%c DesignSettingsAdmin handleChangeRootSetting updatedSetting: %O', 'color: blue;', updatedSetting);
            // console.log('%c DesignSettingsAdmin handleChangeRootSetting props.onChange: %O', 'color: blue;', props.onChange);
            props.onChange(update, '');
            setSelectedSetting(updatedSetting);
            setUiState(!uiState);
        }
    };

    const handleChangeColor = (settingName: string, subSettingName: string, color: any) => {
        // console.log('%c DesignSettingsAdmin handleChangeColor settingName: %O', 'color: maroon;', settingName);
        // console.log('%c DesignSettingsAdmin handleChangeColor subSettingName: %O', 'color: maroon;', subSettingName);
        // console.log('%c DesignSettingsAdmin handleChangeColor color: %O', 'color: maroon;', color);
        // console.log('%c DesignSettingsAdmin handleChangeColor currentSettings: %O', 'color: maroon;', currentSettings);
        // console.log('%c DesignSettingsAdmin handleChangeColor selectedSetting: %O', 'color: maroon;', selectedSetting);
        if (props && props.onChange) {
            let correctedSettingName: string = settingName[0].toLowerCase() + settingName.substring(1);
            if (selectedSetting) {
                if (!selectedSetting.settings) selectedSetting.settings = {};
                if (selectedSetting.type === 'button') {
                    selectedSetting.settings.main = color.hex;
                } else {
                    selectedSetting.settings.color = color.hex;
                }
            }

            let update: any = (currentSettings ? currentSettings : {});
            if (subSettingName) {
                if (!update[correctedSettingName]) update[correctedSettingName] = {};
                if (!update[correctedSettingName][subSettingName]) update[correctedSettingName][subSettingName] = {};
                if (selectedSetting) {
                    update[correctedSettingName][subSettingName] = selectedSetting.settings;
                } else {
                    update[correctedSettingName][subSettingName] = color.rgb;
                }
            } else {
                if (!update[correctedSettingName]) update[correctedSettingName] = {};
                if (selectedSetting) {
                    update[correctedSettingName] = selectedSetting.settings;
                } else {
                    update[correctedSettingName] = color.rgb;
                }
            }

            // do some cleanup
            if (correctedSettingName !== subSettingName && update[correctedSettingName] && update[correctedSettingName][correctedSettingName]) {
                delete update[correctedSettingName][correctedSettingName];
            }

            // console.log('%c DesignSettingsAdmin handleChangeColor update: %O', 'color: maroon;', update);
            // console.log('%c DesignSettingsAdmin handleChangeColor selectedSetting: %O', 'color: maroon;', selectedSetting);
            // console.log('%c DesignSettingsAdmin handleChangeColor props.onChange: %O', 'color: maroon;', props.onChange);
            props.onChange(update, '');
            setSelectedSetting(selectedSetting);
            setUiState(!uiState);
        }
    };

    const handleChangeSize = (settingName: string, subSettingName: string, event: Event, newValue: number | number[]) => {
        // console.log('%c DesignSettingsAdmin handleChangeSize settingName: %O', 'color: maroon;', settingName);
        // console.log('%c DesignSettingsAdmin handleChangeSize subSettingName: %O', 'color: maroon;', subSettingName);
        // console.log('%c DesignSettingsAdmin handleChangeSize color: %O', 'color: maroon;', color);
        // console.log('%c DesignSettingsAdmin handleChangeSize currentSettings: %O', 'color: maroon;', currentSettings);
        if (props && props.onChange) {
            let update: any = (currentSettings ? currentSettings : {});
            if (subSettingName) {
                if (!update[settingName]) update[settingName] = {};
                update[settingName][subSettingName] = newValue;
            } else {
                update[settingName] = newValue;
            }
            // console.log('%c DesignSettingsAdmin handleChangeSize update: %O', 'color: maroon;', update);
            props.onChange(update, '');
        }
    };

    const removeContainerSettings = (settingName: string) => {
        // console.log('%c removeContainerSettings settingName: %O', 'color: maroon;', settingName);
        // console.log('%c removeContainerSettings currentSettings: %O', 'color: maroon;', currentSettings);
        if (props && props.onChange) {
            let update: any = (currentSettings ? currentSettings : {});
            update[settingName] = null;
            // console.log('%c removeContainerSettings update: %O', 'color: maroon;', update);
            props.onChange(update, '');
        }
    };

    const rightAlignedButtonTheme: Theme = udicciHelpers.getUdicciThemeTemplate('MuiIconButton', null);
    // console.log('%c rightAlignedButtonTheme: %O', 'color: blue; font-weight: bold;', rightAlignedButtonTheme);

    let borderRadiusSelections: any[] = udicciHelpers.getCommonSelectionListItems('border.radius', 'design.settings.');
    let borderStyleSelections: any[] = udicciHelpers.getCommonSelectionListItems('border.style', 'design.settings.');
    let fontFamilySelections: any[] = udicciHelpers.getCommonSelectionListItems('font.family', 'design.settings.');
    let fontWeightSelections: any[] = udicciHelpers.getCommonSelectionListItems('font.weight', 'design.settings.');
    let fontSizeSelections: any[] = udicciHelpers.getCommonSelectionListItems('font.size', 'design.settings.');

    const getColorElement = (containerType: string, colorSettingName: string, settingDisplayName: string, currentColor: any, defaultColor: string = '#fff') => {
        // console.log('%c DesignSettingsAdmin getColorElement containerType: %O', 'color: blue;', containerType);
        // console.log('%c DesignSettingsAdmin getColorElement colorSettingName: %O', 'color: blue;', colorSettingName);
        // console.log('%c DesignSettingsAdmin getColorElement settingDisplayName: %O', 'color: blue;', settingDisplayName);
        // console.log('%c DesignSettingsAdmin getColorElement currentColor: %O', 'color: blue;', currentColor);
        // console.log('%c DesignSettingsAdmin getColorElement selectedColorSetting: %O', 'color: blue;', selectedColorSetting);

        let colorValue: string = udicciHelpers.convertColorToString(currentColor, defaultColor);
        // console.log('%c DesignSettingsAdmin getColorElement colorValue: %O', 'color: blue;', colorValue);

        let chosenColorElementSettings: any = {
            borderRadius: '8px',
            border: '1px solid black',
            width: '17px',
            height: '17px',
        }

        let chooseColorIconButtonSettings: any = {
            margin: '3px',
            marginTop: '0px',
            padding: '0px',
            float: 'left',
            minWidth: '15px',
            minHeight: '15px',
            // background: 'unset',
        }

        let isSelectedColor: any = (selectedColorSetting && selectedColorSetting.containerType === containerType && selectedColorSetting.colorSettingName === colorSettingName ? true : false);
        let colorChoiceElement: any = null;
        if (menuIsOpen && isSelectedColor) {    
            colorChoiceElement = (
                <Menu anchorEl={colorMenuAnchorEl}
                      id="color-choice-menu"
                      open={menuIsOpen}
                      onClose={handleCloseColorMenu}
                      sx={{ padding: '0px', paddingTop: '0px', paddingBottom: '0px' }}
                      MenuListProps={{ sx: { padding: '0px', paddingTop: '0px', paddingBottom: '0px' } }}
                >
                    <MenuItem sx={{ padding: '0px' }}>
                        <SketchPicker color={currentColor} onChangeComplete={((color: any) => handleChangeColor(containerType, colorSettingName, color))} />
                    </MenuItem>
                </Menu>
            );
        }

        let colorIdentifier: any = { containerType, colorSettingName };
        // let elementKey: string = "color.choice.menu." + containerType + "." + colorValue.replace(/#/g, '.').replace(/\(/g, '.').replace(/\)/g, '.').replace(/,/g, '.').replace(/\s/g, '');
        // console.log('%c DesignSettingsAdmin getColorElement elementKey: %O', 'color: blue;', elementKey);
        // console.log('%c DesignSettingsAdmin getColorElement chosenColorElementSettings: %O', 'color: blue;', chosenColorElementSettings);
        // console.log('%c DesignSettingsAdmin getColorElement colorValue: %O', 'color: blue;', colorValue);
        return (
            <div style={{ padding: '8px', float: 'left' }}>
                <Tooltip title={"Click to Change " + containerType + ' ' + settingDisplayName}>
                    <IconButton onClick={(evt: any) => handleClickColor(evt, colorIdentifier)}
                                size="small"
                                color="secondary"
                                sx={chooseColorIconButtonSettings}
                                aria-controls={menuIsOpen && isSelectedColor ? 'color-choice-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={menuIsOpen && isSelectedColor ? 'true' : undefined}
                    >
                        <Avatar sx={{ background: colorValue, ...chosenColorElementSettings }}>&nbsp;</Avatar>
                    </IconButton>
                </Tooltip>
                <div style={{float: 'left', display: 'flow-root', cursor: 'pointer',  marginLeft: '4px'}} onClick={(evt: any) => handleClickColor(evt, colorIdentifier)}>
                    <Typography variant="overFieldLabel" component="div">
                        {settingDisplayName}
                    </Typography>
                    <Typography variant="caption" component="div">click to change color</Typography>
                </div>
                {colorChoiceElement}
            </div>
        );
    };

    const getColorPreviewElement = (containerType: string, colorSettingName: string, currentColor: any, defaultColor: string = '#fff', onClickItem: any | null = null) => {
        // console.log('%c DesignSettingsAdmin getColorPreviewElement containerType: %O', 'color: blue;', containerType);
        // console.log('%c DesignSettingsAdmin getColorPreviewElement colorSettingName: %O', 'color: blue;', colorSettingName);
        // console.log('%c DesignSettingsAdmin getColorPreviewElement settingDisplayName: %O', 'color: blue;', settingDisplayName);
        // console.log('%c DesignSettingsAdmin getColorPreviewElement currentColor: %O', 'color: blue;', currentColor);
        // console.log('%c DesignSettingsAdmin getColorPreviewElement selectedColorSetting: %O', 'color: blue;', selectedColorSetting);

        let isSelectedColor: any = (selectedColorSetting && selectedColorSetting.containerType === containerType && selectedColorSetting.colorSettingName === colorSettingName ? true : false);
        if (isSelectedColor) return false;

        let colorValue: string = udicciHelpers.convertColorToString(currentColor, defaultColor);
        // console.log('%c DesignSettingsAdmin getColorPreviewElement colorValue: %O', 'color: blue;', colorValue);

        let chosenColorElementSettings: any = {
            borderRadius: '8px',
            border: '1px solid black',
            width: '17px',
            height: '17px',
        }

        if (onClickItem) {
            chosenColorElementSettings.cursor = 'pointer';
        }

        return (
            <div style={{ padding: '8px', float: 'left' }}>
                <Avatar sx={{ background: colorValue, ...chosenColorElementSettings }} onClick={onClickItem}>&nbsp;</Avatar>
            </div>
        );
    };

    let sectionSxSettings: any = {
        margin: '8px',
        padding: '16px',
        display: 'flow-root',
        width: 'fit-content',
        // background: 'rgba(0, 0, 0, 0.1)',
        border: '1px solid #333',
    };
    let removeOuterSettingsButton: any = null;
    if (outerPane) {
        removeOuterSettingsButton = (
            <Button size="small" color="info" variant="contained" onClick={(evt: any) => removeContainerSettings('outer')} sx={{ marginLeft: '8px' }}>
                Remove Outer Pane Settings
            </Button>
        );
    }

    let standardSelectProps: any = {
        variant: "filled",
    };

    let outerContainerSettings: any = (
        <Box sx={{ display: 'flow-root' }}>
            <Box sx={{ display: 'flex' }}>
                <Typography variant="caption" component="div">
                    The Outer container settings are used to configure the Outer container of each pane on a Porta.
                </Typography>
            </Box>

            <Box sx={{ display: 'flex' }}>
                <Box sx={sectionSxSettings}>
                    <Typography variant="subtitle1">Outer Container Appearance Settings</Typography>

                    <div style={{ padding: '8px', display: 'flow-root' }}>
                        {getColorElement('Outer', 'backgroundColor', 'Background Color', outerPaneBackground, '#fff')}
                    </div>

                    <div style={{ padding: '8px', paddingLeft: '16px', display: 'flow-root' }}>
                        <InputLabel>
                            <Typography variant="overFieldLabel">Margin ({outerPaneMargin}px)</Typography>
                        </InputLabel>
                        <Slider defaultValue={1}
                                min={0}
                                max={21}
                                value={outerPaneMargin}
                                aria-label="Margin"
                                valueLabelDisplay="auto"
                                onChange={(event: Event, newValue: number | number[]) => handleChangeSize('outer', 'margin', event, newValue)} />
                    </div>

                    <div style={{ padding: '8px', paddingLeft: '16px', display: 'flow-root' }}>
                        <InputLabel>
                            <Typography variant="overFieldLabel">Padding ({outerPanePadding}px)</Typography>
                        </InputLabel>
                        <Slider defaultValue={1}
                                min={0}
                                max={21}
                                value={outerPanePadding}
                                aria-label="Padding"
                                valueLabelDisplay="auto"
                                onChange={(event: Event, newValue: number | number[]) => handleChangeSize('outer', 'padding', event, newValue)} />
                    </div>
                </Box>

                <Box sx={sectionSxSettings}>
                    <Typography variant="subtitle1">Outer Container Border Settings</Typography>

                    <div style={{ padding: '8px', display: 'flow-root' }}>
                        <InputLabel>
                            <Typography variant="overFieldLabel">Border Style</Typography>
                        </InputLabel>
                        <Select variant="filled" fullWidth value={outerPaneBorderStyle} onChange={(evt: any) => handleChangeDesign('outer', 'borderStyle', evt)}>
                            {borderStyleSelections}
                        </Select>
                    </div>

                    {outerPaneBorderStyle && outerPaneBorderStyle !== '' && outerPaneBorderStyle !== 'hidden' && outerPaneBorderStyle !== 'unset' && (
                        <Box>
                            <div style={{ padding: '8px', display: 'flow-root' }}>
                                {getColorElement('Outer', 'borderColor', 'Border Color', outerPaneBorderColor, '#fff')}
                            </div>

                            <div style={{ padding: '8px', paddingLeft: '16px', display: 'flow-root' }}>
                                <InputLabel>
                                    <Typography variant="overFieldLabel">Border Size ({outerPaneBorderWidth}px)</Typography>
                                </InputLabel>
                                <Slider defaultValue={1}
                                        min={0}
                                        max={7}
                                        value={outerPaneBorderWidth}
                                        aria-label="Border Size"
                                        valueLabelDisplay="auto"
                                        onChange={(event: Event, newValue: number | number[]) => handleChangeSize('outer', 'borderWidth', event, newValue)} />
                            </div>

                            <div style={{ padding: '8px', display: 'flow-root' }}>
                                <InputLabel>
                                    <Typography variant="overFieldLabel">Border Radius</Typography>
                                </InputLabel>
                                <Select variant="filled" fullWidth value={outerPaneBorderRadius} onChange={(evt: any) => handleChangeDesign('outer', 'borderRadius', evt)}>
                                    {borderRadiusSelections}
                                </Select>
                            </div>
                        </Box>
                    )}
                </Box>
            </Box>

            {removeOuterSettingsButton}
        </Box>
    );

    let removeInnerSettingsButton: any = null;
    if (innerPane) {
        removeInnerSettingsButton = (
            <Button size="small" color="info" variant="contained" onClick={(evt: any) => removeContainerSettings('inner')} sx={{ marginLeft: '8px' }}>
                Remove Inner Pane Settings
            </Button>
        );
    }
    let innerContainerSettings: any = (
        <Box sx={{ display: 'flow-root' }}>
            <Typography variant="caption" component="div">
                The Inner container settings are used to configure the Inner container of each pane on a Porta.
            </Typography>

            <Box sx={{ display: 'flex' }}>
                <Box sx={sectionSxSettings}>
                    <Typography variant="subtitle1">Inner Container Appearance Settings</Typography>

                    <div style={{ padding: '8px', display: 'flow-root' }}>
                        {getColorElement('Inner', 'backgroundColor', 'Background Color', innerPaneBackground, '#fff')}
                    </div>

                    <div style={{ padding: '8px', paddingLeft: '16px', display: 'flow-root' }}>
                        <InputLabel>
                            <Typography variant="overFieldLabel">Margin ({innerPaneMargin}px)</Typography>
                        </InputLabel>
                        <Slider defaultValue={1}
                                min={0}
                                max={21}
                                value={innerPaneMargin}
                                aria-label="Margin"
                                valueLabelDisplay="auto"
                                onChange={(event: Event, newValue: number | number[]) => handleChangeSize('inner', 'margin', event, newValue)} />
                    </div>

                    <div style={{ padding: '8px', paddingLeft: '16px', display: 'flow-root' }}>
                        <InputLabel>
                            <Typography variant="overFieldLabel">Padding ({innerPanePadding}px)</Typography>
                        </InputLabel>
                        <Slider defaultValue={1}
                                min={0}
                                max={21}
                                value={innerPanePadding}
                                aria-label="Padding"
                                valueLabelDisplay="auto"
                                onChange={(event: Event, newValue: number | number[]) => handleChangeSize('inner', 'padding', event, newValue)} />
                    </div>
                </Box>

                <Box sx={sectionSxSettings}>
                    <Typography variant="subtitle1">Inner Container Border Settings</Typography>

                    <div style={{ padding: '8px', display: 'flow-root' }}>
                        <InputLabel>
                            <Typography variant="overFieldLabel">Border Style</Typography>
                        </InputLabel>
                        <Select variant="filled" fullWidth value={innerPaneBorderStyle} onChange={(evt: any) => handleChangeDesign('inner', 'borderStyle', evt)}>
                            {borderStyleSelections}
                        </Select>
                    </div>

                    {innerPaneBorderStyle && innerPaneBorderStyle !== '' && innerPaneBorderStyle !== 'hidden' && innerPaneBorderStyle !== 'unset' && (
                        <Box>
                            <div style={{ padding: '8px', display: 'flow-root' }}>
                                {getColorElement('Inner', 'borderColor', 'Border Color', innerPaneBorderColor, '#fff')}
                            </div>

                            <div style={{ padding: '8px', paddingLeft: '16px', display: 'flow-root' }}>
                                <InputLabel>
                                    <Typography variant="overFieldLabel">Border Size ({innerPaneBorderWidth}px)</Typography>
                                </InputLabel>
                                <Slider defaultValue={1}
                                        min={0}
                                        max={7}
                                        value={innerPaneBorderWidth}
                                        aria-label="Border Size"
                                        valueLabelDisplay="auto"
                                        onChange={(event: Event, newValue: number | number[]) => handleChangeSize('inner', 'borderWidth', event, newValue)} />
                            </div>

                            <div style={{ padding: '8px', display: 'flow-root' }}>
                                <InputLabel>
                                    <Typography variant="overFieldLabel">Border Radius</Typography>
                                </InputLabel>
                                <Select variant="filled" fullWidth value={innerPaneBorderRadius} onChange={(evt: any) => handleChangeDesign('inner', 'borderRadius', evt)}>
                                    {borderRadiusSelections}
                                </Select>
                            </div>
                        </Box>
                    )}
                </Box>
            </Box>
            {removeInnerSettingsButton}
        </Box>
    );

    let removePaperSettingsButton: any = null;
    if (paperPane) {
        removePaperSettingsButton = (
            <Button size="small" color="info" variant="contained" onClick={(evt: any) => removeContainerSettings('paper')} sx={{ marginLeft: '8px' }}>
                Remove Paper Pane Settings
            </Button>
        );
    }
    let paperContainerSettings: any = (
        <Box sx={{ display: 'flow-root' }}>
            <Typography variant="caption" component="div">
                The Paper/Dialog containers are primarily used to display information in forms and dialog windows.  There are many Plugins that
                also utilize the Paper/Dialog settings throughout the platform, as you will quickly discover.
            </Typography>

            <Box sx={{ display: 'flex' }}>
                <Box sx={sectionSxSettings}>
                    <Typography variant="subtitle1">Paper Container Appearance Settings</Typography>

                    <div style={{ padding: '8px', display: 'flow-root' }}>
                        {getColorElement('Paper', 'backgroundColor', 'Background Color', paperPaneBackground, '#fff')}
                    </div>

                    <div style={{ padding: '8px', paddingLeft: '16px', display: 'flow-root' }}>
                        <InputLabel>
                            <Typography variant="overFieldLabel">Margin ({paperPaneMargin}px)</Typography>
                        </InputLabel>
                        <Slider defaultValue={1}
                                min={0}
                                max={21}
                                value={paperPaneMargin}
                                aria-label="Margin"
                                valueLabelDisplay="auto"
                                onChange={(event: Event, newValue: number | number[]) => handleChangeSize('paper', 'margin', event, newValue)} />
                    </div>

                    <div style={{ padding: '8px', paddingLeft: '16px', display: 'flow-root' }}>
                        <InputLabel>
                            <Typography variant="overFieldLabel">Padding ({paperPanePadding}px)</Typography>
                        </InputLabel>
                        <Slider defaultValue={1}
                                min={0}
                                max={21}
                                value={paperPanePadding}
                                aria-label="Padding"
                                valueLabelDisplay="auto"
                                onChange={(event: Event, newValue: number | number[]) => handleChangeSize('paper', 'padding', event, newValue)} />
                    </div>
                </Box>

                <Box sx={sectionSxSettings}>
                    <Typography variant="subtitle1">Paper Container Border Settings</Typography>

                    <div style={{ padding: '8px', display: 'flow-root' }}>
                        <InputLabel>
                            <Typography variant="overFieldLabel">Border Style</Typography>
                        </InputLabel>
                        <Select variant="filled" fullWidth value={paperPaneBorderStyle} onChange={(evt: any) => handleChangeDesign('paper', 'borderStyle', evt)}>
                            {borderStyleSelections}
                        </Select>
                    </div>

                    {paperPaneBorderStyle && paperPaneBorderStyle !== '' && paperPaneBorderStyle !== 'hidden' && paperPaneBorderStyle !== 'unset' && (
                        <Box>
                            <div style={{ padding: '8px', display: 'flow-root' }}>
                                {getColorElement('Paper', 'borderColor', 'Border Color', paperPaneBorderColor, '#fff')}
                            </div>

                            <div style={{ padding: '8px', paddingLeft: '16px', display: 'flow-root' }}>
                                <InputLabel>
                                    <Typography variant="overFieldLabel">Border Size ({paperPaneBorderWidth}px)</Typography>
                                </InputLabel>
                                <Slider defaultValue={1}
                                        min={0}
                                        max={7}
                                        value={paperPaneBorderWidth}
                                        aria-label="Border Size"
                                        valueLabelDisplay="auto"
                                        onChange={(event: Event, newValue: number | number[]) => handleChangeSize('paper', 'borderWidth', event, newValue)} />
                            </div>

                            <div style={{ padding: '8px', display: 'flow-root' }}>
                                <InputLabel>
                                    <Typography variant="overFieldLabel">Border Radius</Typography>
                                </InputLabel>
                                <Select variant="filled" fullWidth value={paperPaneBorderRadius} onChange={(evt: any) => handleChangeDesign('paper', 'borderRadius', evt)}>
                                    {borderRadiusSelections}
                                </Select>
                            </div>
                        </Box>
                    )}
                </Box>
            </Box>
            {removePaperSettingsButton}
        </Box>
    );

    let showHeaderSettings: boolean = true;
    let headerSettingsElement: any = null;
    let menuSettingsElement: any = null;
    if ((designTarget === 'portal' || designTarget === 'porta') && showHeaderSettings) {
        headerSettingsElement = (
            <Accordion expanded={expanded === 'header'} onChange={handleChange('header')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="info" />} id="header-header">
                    <Typography variant="clickableSubTitle2" component="div">Portal Header</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flow-root', marginBottom: '8px' }}>
                        <div style={{ display: 'flex' }}>
                            {getColorElement('Header', 'backgroundColor', 'Header Background', headerBackgroundColor, '#fff')}
                            {getColorElement('Header', 'borderBottomColor', 'Header Bottom Border Color', headerBorderBottomColor, '#000')}
                            {getColorElement('Header', 'primaryColor', 'Header Primary Text Color', headerPrimaryColor, '#000')}
                            {getColorElement('Header', 'secondaryColor', 'Header Secondary Text Color', headerSecondaryColor, '#333')}
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        );

        menuSettingsElement = (
            <Accordion expanded={expanded === 'menu'} onChange={handleChange('menu')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="info" />} id="header-menu">
                    <Typography variant="clickableSubTitle2" component="div">Portal Menu</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flow-root', marginBottom: '8px' }}>
                        <div style={{ display: 'flex' }}>
                            {getColorElement('Menu', 'iconColor', 'Portal Menu Icon Color', menuColor, '#000')}
                            {getColorElement('Menu', 'backgroundColor', 'Menu Background', menuBackgroundColor, '#fff')}
                            {getColorElement('Menu', 'borderRightColor', 'Menu Right Border Color', menuBorderRightColor, '#000')}
                            {getColorElement('Menu', 'activeMenuTextColor', 'Active Menu Text Color', activeMenuTextColor, '#333')}
                            {getColorElement('Menu', 'inactiveMenuTextColor', 'Inactive Menu Text Color', inactiveMenuTextColor, '#aaa')}
                            {getColorElement('Menu', 'selectedMenuIndicatorColor', 'Selected Menu Indicator Color', selectedMenuIndicatorColor, 'blue')}
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }

    let settingsFormElement: any = null;
    if (selectedSetting && selectedSetting.type === 'button') {
        // console.log('%c DesignSettingsAdmin selectedSetting: %O', 'color: green;', selectedSetting);

        // console.log('%c DesignSettingsAdmin currentSettings: %O', 'color: maroon;', currentSettings);
        let selectedSettingTypeSettings: any = (currentSettings && currentSettings[selectedSetting.type] ? currentSettings[selectedSetting.type] : null);
        // console.log('%c DesignSettingsAdmin selectedSettingTypeSettings: %O', 'color: maroon;', selectedSettingTypeSettings);
        let settingColor: any = (selectedSettingTypeSettings && selectedSettingTypeSettings[selectedSetting.key] ? selectedSettingTypeSettings[selectedSetting.key] : '');
        // console.log('%c DesignSettingsAdmin settingColor: %O', 'color: maroon;', settingColor);
        let settingColorValue: any = '';
        if (selectedSetting.type === 'button') {
            settingColorValue = (settingColor && settingColor.main ? settingColor.main : '');
        } else {
            settingColorValue = (settingColor && settingColor.color ? settingColor.color : '');
        }
        // console.log('%c DesignSettingsAdmin settingColorValue: %O', 'color: maroon;', settingColorValue);
        let standardDetails: any = udicciHelpers.lookupStandardInformation(selectedSetting.standardId, selectedSetting.key)
        // console.log('%c DesignSettingsAdmin standardDetails: %O', 'color: maroon;', standardDetails);

        let labelText: string = (standardDetails && standardDetails.title ? standardDetails.title : selectedSetting.key);

        settingsFormElement = (
            <div style={{ display: 'flex', padding: '8px' }}>
                {getColorElement(selectedSetting.type, selectedSetting.key, labelText, settingColorValue, '#fff')}
            </div>
        );
    }

    let variantTypeElementsDisplay: any = null;
    let variantTypeCategories: string[] = [];
    let variantTypeElements: any[] = [];
    if (typographySettings) {
        mapObject(typographySettings, function(ts: any, key: any) {
            // console.log('%c typographySetting: %O', 'color: purple;', ts);
            // console.log('%c key: %O', 'color: purple;', key);
            if (typeof(ts) === 'object' && key !== 'button') {
                // console.log('%c object %s ts: %O', 'color: green;', key, ts);

                let settingIsSelected: boolean = (selectedSetting && selectedSetting.type === 'text' && selectedSetting.key === key ? true : false);
                // if (selectedSetting && !settingIsSelected) return true;

                let standardDetails: any = udicciHelpers.lookupStandardInformation('typography', key)
                // console.log('%c standardDetails: %O', 'color: blue;', standardDetails);

                let category: string = '';
                if (standardDetails && standardDetails.category) {
                    category = standardDetails.category;
                }
                if (category && variantTypeCategories.indexOf(category) < 0) variantTypeCategories.push(category);

                if ((selectedTextCategory && category !== selectedTextCategory) || (!selectedTextCategory && variantTypeCategories.length > 0 && category !== variantTypeCategories[0])) return true;

                let labelText: string = key;
                if (standardDetails && standardDetails.title) {
                    labelText = standardDetails.title;
                }
                let descriptionElement: any = null;
                if (standardDetails && standardDetails.description) {
                    descriptionElement = (
                        <Typography variant="caption" component="div" sx={{ clear: 'both', margin: '8px' }}>{standardDetails.description}</Typography>
                    );
                }

                let onClickItem: any = (evt: any) => textSettingsIconClicked({ type: 'text', standardId: 'typography', key: key, settings: ts });
                let colorPreviewElement: any = null;
                let textSettingsFormElement: any = null;
                if (selectedSetting && settingIsSelected) {
                    // console.log('%c DesignSettingsAdmin selectedSetting: %O', 'color: green;', selectedSetting);

                    // console.log('%c DesignSettingsAdmin currentSettings: %O', 'color: maroon;', currentSettings);
                    let selectedSettingTypeSettings: any = (currentSettings && currentSettings[selectedSetting.type] ? currentSettings[selectedSetting.type] : null);
                    // console.log('%c DesignSettingsAdmin selectedSettingTypeSettings: %O', 'color: maroon;', selectedSettingTypeSettings);
                    let settingColor: any = (selectedSettingTypeSettings && selectedSettingTypeSettings[selectedSetting.key] ? selectedSettingTypeSettings[selectedSetting.key] : '');
                    // console.log('%c DesignSettingsAdmin settingColor: %O', 'color: maroon;', settingColor);
                    let settingColorValue: any = '';
                    if (selectedSetting.type === 'button') {
                        settingColorValue = (settingColor && settingColor.main ? settingColor.main : '');
                    } else {
                        settingColorValue = (settingColor && settingColor.color ? settingColor.color : '');
                        // console.log('%c DesignSettingsAdmin settingColorValue: %O', 'color: red;', settingColorValue);
                        // console.log('%c DesignSettingsAdmin settingColor: %O', 'color: red;', settingColor);
                        if (!settingColorValue && settingColor && settingColor.r !== undefined) {
                            // console.log('%c DesignSettingsAdmin settingColor.settings: %O', 'color: orange;', settingColor.settings);
                            settingColorValue = settingColor;
                            // console.log('%c DesignSettingsAdmin settingColorValue: %O', 'color: green;', settingColorValue);
                        }
                    }
                    // console.log('%c DesignSettingsAdmin settingColorValue: %O', 'color: blue;', settingColorValue);

                    let labelText: string = (standardDetails && standardDetails.title ? standardDetails.title : selectedSetting.key);
                    let settings: any = (selectedSetting && selectedSetting.settings ? selectedSetting.settings : null);
                    // console.log('%c DesignSettingsAdmin settings: %O', 'color: black;', settings);
                    let fontFamily: string = (settings && settings.fontFamily ? settings.fontFamily : '');
                    // console.log('%c DesignSettingsAdmin fontFamily: %O', 'color: black;', fontFamily);
                    if (fontFamily && fontFamily.indexOf('"') >= 0) {
                        fontFamily = fontFamily.replace(/\"/g, "'");
                        // console.log('%c DesignSettingsAdmin fontFamily: %O', 'color: black;', fontFamily);
                    }
                    let fontWeight: string = (settings && settings.fontWeight ? settings.fontWeight : '');
                    // console.log('%c DesignSettingsAdmin fontWeight: %O', 'color: black;', fontWeight);
                    let fontSize: string = (settings && settings.fontSize ? settings.fontSize : '');
                    // console.log('%c DesignSettingsAdmin fontSize: %O', 'color: black;', fontSize);

                    let textSettingPreviewElement: any = null;
                    // console.log('%c DesignSettingsAdmin selectedSetting: %O', 'color: black;', selectedSetting);
                    if (selectedSetting) {
                        let previewSettingsProps: any = {};
                        if (selectedSetting.fontFamily) previewSettingsProps.fontFamily = selectedSetting.fontFamily;
                        if (selectedSetting.fontSize) previewSettingsProps.fontSize = selectedSetting.fontSize;
                        if (selectedSetting.fontWeight) previewSettingsProps.fontWeight = selectedSetting.fontWeight;
                        if (selectedSetting.color) previewSettingsProps.color = selectedSetting.color;
                        textSettingPreviewElement = (
                            <Paper>
                                <Box sx={{ margin: '8px', padding: '16px', textAlign: 'center' }}>
                                    <Typography variant={selectedSetting.key} sx={previewSettingsProps}>Sample Content 1 2 3 $ & ! </Typography>
                                </Box>
                            </Paper>
                        );
                    }

                    textSettingsFormElement = (
                        <div style={{ display: 'flow-root' }}>
                            <div style={{ display: 'flex', clear: 'both' }}>
                                {getColorElement(selectedSetting.type, selectedSetting.key, labelText, settingColorValue, '#fff')}
                            </div>

                            <div>
                                <InputLabel>
                                    <Typography variant="overFieldLabel">Font Family</Typography>
                                </InputLabel>
                                <Select variant="filled" fullWidth name="fontFamily" value={fontFamily} onChange={(evt: any) => handleChangeRootSetting(selectedSetting.type, selectedSetting.key, evt)}>
                                    {fontFamilySelections}
                                </Select>
                            </div>

                            <div>
                                <InputLabel>
                                    <Typography variant="overFieldLabel">Font Weight</Typography>
                                </InputLabel>
                                <Select variant="filled" fullWidth name="fontWeight" value={fontWeight} onChange={(evt: any) => handleChangeRootSetting(selectedSetting.type, selectedSetting.key, evt)}>
                                    {fontWeightSelections}
                                </Select>
                            </div>

                            <div>
                                <InputLabel>
                                    <Typography variant="overFieldLabel">Font Size</Typography>
                                </InputLabel>
                                <Select variant="filled" fullWidth name="fontSize" value={fontSize} onChange={(evt: any) => handleChangeRootSetting(selectedSetting.type, selectedSetting.key, evt)}>
                                    {fontSizeSelections}
                                </Select>
                            </div>

                            {textSettingPreviewElement}
                        </div>
                    );
                } else {
                    colorPreviewElement = getColorPreviewElement('typography', key, (ts && ts.color ? ts.color : ts), '#fff', onClickItem)
                }

                variantTypeElements.push(
                    <Grid item xs={4} sm={4} md={3} lg={3} key={'typography.setting.' + key} sx={{ backgroundColor: 'unset' }}>
                        <ConfigureContainer elevation={7} sx={{ ...paperPane }}>
                            <IconButton color="info" edge="end" aria-label="Help" size="small" 
                                        onClick={onClickItem}
                                        sx={{ float: 'right', margin: '2px', cursor: 'pointer' }}
                            >
                                <Icon>{(settingIsSelected ? 'visibility_off' : 'visibility')}</Icon>
                            </IconButton>
                            <Box sx={{ padding: '4px' }}>
                                <Box>
                                    {colorPreviewElement}
                                    <Typography variant={'subtitle1'} sx={{ paddingTop: '4px', cursor: 'pointer' }} onClick={onClickItem}>
                                        {labelText}
                                    </Typography>
                                </Box>
                                {descriptionElement}
                                {textSettingsFormElement}
                            </Box>
                        </ConfigureContainer>
                    </Grid>
                );
            }
        });
    }
    // console.log('%c variantTypeCategories: %O', 'color: blue; font-weight: bold;', variantTypeCategories);

    let variantTypeCategoryMenuItems: any[] = [];
    forEach(variantTypeCategories, function(categoryName: string, idx: number) {
        // console.log('%c plugin: %O', 'color: purple;', plugin);
        // console.log('%c idx: %O', 'color: purple;', idx);
        variantTypeCategoryMenuItems.push(<MenuItem key={'text.setting.category.' + categoryName + '.' + idx} value={categoryName}> {categoryName} </MenuItem>);
    });

    let buttonTypeElements: any[] = [];
    let paletteTypeCategories: string[] = [];
    if (paletteSettings) {
        mapObject(paletteSettings, function(ps: any, key: any) {
            // console.log('%c ps: %O', 'color: purple;', ps);
            // console.log('%c key: %O', 'color: purple;', key);
            // ignore some others that are objects and don't belong here yet
            if (['common','grey','text','background','action'].indexOf(key) >= 0) return true;

            if (typeof(ps) === 'object') {
                let settingIsSelected: boolean = (selectedSetting && selectedSetting.type === 'button' && selectedSetting.key === key ? true : false);
                if (selectedSetting && !settingIsSelected) return true;
                // console.log('%c object %s bs: %O', 'color: green;', key, bs);

                let standardDetails: any = udicciHelpers.lookupStandardInformation('palette', key)
                // console.log('%c standardDetails: %O', 'color: blue;', standardDetails);

                let category: string = '';
                if (standardDetails && standardDetails.category) {
                    category = standardDetails.category;
                }
                if (category && paletteTypeCategories.indexOf(category) < 0) paletteTypeCategories.push(category);

                if ((selectedPaletteCategory && category !== selectedPaletteCategory) || (!selectedPaletteCategory && paletteTypeCategories.length > 0 && category !== paletteTypeCategories[0])) return true;

                let labelText: string = (standardDetails && standardDetails.title ? standardDetails.title : (ps.id ? ps.id : key));
                buttonTypeElements.push(
                    <Button key={'button.setting.' + key} color={key} variant="contained" onClick={(evt: any) => setSelectedSetting({ type: 'button', standardId: 'palette', key: key, settings: ps })}>
                        {labelText}
                    </Button>
                );
            }
        });
    }

    let paletteTypeCategoryMenuItems: any[] = [];
    forEach(paletteTypeCategories, function(categoryName: string, idx: number) {
        // console.log('%c plugin: %O', 'color: purple;', plugin);
        // console.log('%c idx: %O', 'color: purple;', idx);
        paletteTypeCategoryMenuItems.push(<MenuItem key={'palette.setting.category.' + categoryName + '.' + idx} value={categoryName}> {categoryName} </MenuItem>);
    });

    if (settingsFormElement) {
        variantTypeElementsDisplay = (
            <Box>
                <ThemeProvider theme={rightAlignedButtonTheme}>
                    <IconButton color="info" edge="end" aria-label="Close Pane Configuration" size="small" onClick={(evt: any) => setSelectedSetting(null)}>
                        <Icon>close</Icon>
                    </IconButton>
                </ThemeProvider>
                <Grid container spacing={1} justifyContent="flex-start" alignItems="stretch" sx={{ backgroundColor: 'unset' }}>
                    {variantTypeElements}
                </Grid>
            </Box>
        );
    } else {
        variantTypeElementsDisplay = (
            <Grid container spacing={1} justifyContent="flex-start" alignItems="stretch">
                {variantTypeElements}
            </Grid>
        );
    }

    let containerTabsElement: any = (
        <Box>
            {containerHelpShown && (
                <Box>
                    <IconButton color="info" edge="end" aria-label="Help" size="small" onClick={toggleContainerHelp} sx={{ marginLeft: '8px', cursor: 'pointer', float: 'right' }}>
                        <Icon>close</Icon>
                    </IconButton>
                    <Typography variant="caption" component="div">
                        Plugin Content that is installed on a Pane is displayed within 2 containers on the end user display.
                    </Typography>
                    <Typography variant="caption" component="div">
                        The Outer Container mainly serves as a Design feature for administrators to get creative in their Portal designs.
                    </Typography>
                    <Typography variant="caption" component="div">
                        The Inner Container serves to provide the primary area where the Plugin Content is shown within the User Experience.
                    </Typography>
                    <Typography variant="caption" component="div">
                        The Paper/Dialog containers are primarily used to display information in forms and dialog windows.  There are many Plugins that
                        also utilize the Paper/Dialog settings throughout the platform, as you will quickly discover.
                    </Typography>
                </Box>
            )}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={(containerTab >= 0 ? containerTab : false)} aria-label="Container Selection">
                    <Tab value={0} label={(<Typography variant="caption">Outer</Typography>)} id="container-outer" onClick={(event: any) => handleContainerTabClicked('outer', 0)} />
                    <Tab value={1} label={(<Typography variant="caption">Inner</Typography>)} id="container-inner" onClick={(event: any) => handleContainerTabClicked('inner', 1)} />
                    <Tab value={2} label={(<Typography variant="caption">Paper/Dialog</Typography>)} id="container-paper" onClick={(event: any) => handleContainerTabClicked('paper', 2)} />
                </Tabs>
            </Box>
            <TabPanel value={containerTab} index={0}>
                {outerContainerSettings}
            </TabPanel>
            <TabPanel value={containerTab} index={1}>
                {innerContainerSettings}
            </TabPanel>
            <TabPanel value={containerTab} index={2}>
                {paperContainerSettings}
            </TabPanel>
        </Box>
    );

    let containerSettingsElement: any = (
        <Accordion expanded={expanded === 'containers'} onChange={handleChange('containers')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon color="info" />} id="containers-header">
                <Typography variant="clickableSubTitle2" component="div">Containers</Typography>
                {!containerHelpShown && (
                    <IconButton color="info" edge="end" aria-label="Help" size="small" onClick={toggleContainerHelp} sx={{ padding: '0px', margin: '0px', marginLeft: '8px', cursor: 'pointer' }}>
                        <Icon>help_outline</Icon>
                    </IconButton>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ display: 'flow-root', marginBottom: '8px' }}>
                    {containerTabsElement}
                </div>
            </AccordionDetails>
        </Accordion>
    );

    let textSettingsElement: any = (
        <Accordion expanded={expanded === 'text'} onChange={handleChange('text')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon color="info" />} id="text-settings">
                <Typography variant="clickableSubTitle2" component="div">Text Settings</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ display: 'flow-root', marginBottom: '8px' }}>
                    <Typography variant="caption" component="div">
                        Text Settings provide the ability to setup the styles for the text that is displayed on your Portal.  You can switch to different categories
                        by using the drop down menu below, and the styles associated with the categories will display below.
                    </Typography>
                    <div>
                        <Select variant="filled" fullWidth value={(selectedTextCategory ? selectedTextCategory : (variantTypeCategories && variantTypeCategories.length > 0 ? variantTypeCategories[0] : ''))} onChange={changeTextSettingsCategory}>
                            {variantTypeCategoryMenuItems}
                        </Select>
                    </div>
                    <br />
                    {variantTypeElementsDisplay}
                </div>
            </AccordionDetails>
        </Accordion>
    );

    let chooseButtonElement: any = null;
    if (!settingsFormElement) {
        chooseButtonElement = (
            <Box sx={{ display: 'flow-root', padding: '8px' }}>
                <Typography variant="caption" component="div">Choose category to show available buttons to configure.</Typography>
                <UdicciSelectComponent variant="filled" fullWidth value={(selectedPaletteCategory ? selectedPaletteCategory : (paletteTypeCategories && paletteTypeCategories.length > 0 ? paletteTypeCategories[0] : ''))} onChange={changePaletteSettingsCategory}>
                    {paletteTypeCategoryMenuItems}
                </UdicciSelectComponent>
            </Box>
        );
    }

    let buttonSettingsElement: any = (
        <Accordion expanded={expanded === 'button'} onChange={handleChange('button')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon color="info" />} id="button-settings">
                <Typography variant="clickableSubTitle2" component="div">Button Settings</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ display: 'flow-root', padding: '8px', background: 'rgba(255, 255, 255, 0.1)' }}>
                    {(settingsFormElement && (<ThemeProvider theme={rightAlignedButtonTheme}>
                        <IconButton color="info" edge="end" aria-label="Close Pane Configuration" size="small" onClick={(evt: any) => setSelectedSetting(null)}>
                            <Icon>close</Icon>
                        </IconButton>
                    </ThemeProvider>))}
                    {chooseButtonElement}
                    <div style={{ display: 'flow-root' }}>
                        {buttonTypeElements}
                        {settingsFormElement}
                    </div>
                </Box>
            </AccordionDetails>
        </Accordion>
    );

    let designSettingsElement: any = (
        <div style={{ display: 'flow-root' }}>
            <div style={{ display: 'flow-root', margin: '4px' }}>
                <Typography variant={(designTarget === 'portal' ? 'overFieldLabel' : 'subtitle1')} sx={{ marginRight: '4px' }} component="span">{(designTarget === 'portal' ? 'Design' : 'Override')} Settings for</Typography>
                <Typography variant={(designTarget === 'portal' ? 'subtitle1' : 'body1')} component="span">{(designTarget === 'portal' ? 'Portal' : 'Porta')}</Typography>
            </div>
            {headerSettingsElement}
            {menuSettingsElement}
            {containerSettingsElement}
            {textSettingsElement}
            {buttonSettingsElement}
        </div>
    );

    return ( <Box> {designSettingsElement} </Box> );
}

export default DesignSettingsAdmin;
