
import { FC, createContext, useContext, useState, useEffect, useCallback } from 'react';

import { values, filter, forEach } from 'underscore';

import { useUdicciContext } from 'src/context/udicci-context';
import {  UdicciRecord } from 'src/classes/udicci-record';
// import useUdicciSocialSolution from "src/hooks/useUdicciSocialSolution";
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

interface IRecordContext {
    rootRecord?: UdicciRecord;
    recordContext?: any;
    // currentContextView?: any;
    rootRecords?: any;
    setRootRecords?: any;
    setData?: any;
    getData?: any;
    displayState?: any;
    datasets?: any;
    contentDesign?: any;
    RecordContextStateUpdate?: boolean;
    setRootRecord?: (updatedRootRecord: UdicciRecord) => void;
    setRecordContext?: (updatedRecordContext: any) => void;
    addRecord?: (level: number) => void;
    setRecord?: (updatedRecord: UdicciRecord) => void;
    removeRecord?: (removeRecord: UdicciRecord) => void;
    removeDisplayRecord?: (removeRecord: UdicciRecord) => void;
    loadRecordContext?: (record: UdicciRecord, socialSolution: UdicciRecord | null) => void;
    loadRecordContextByView?: (contextView: any, force?: boolean | undefined) => void;
    updateDisplayState?: (updatedDisplayState: any) => void;
    navigate?: (udicciMediatorName: string, direction: string) => void;
    jump?: (jumpToRecord: any) => void;
    getCurrentDisplayContext?: () => void;
    getContextLevels?: (contentDesign: any) => void;
    updateContextLevels?: (updatedContextLevels: any) => void;
    retrieveContextLevels?: () => void;
}

const RecordContextFactory = createContext<Partial<IRecordContext>>({});

const RecordContextProvider: FC = (props: any) => {
    // console.log('%c RecordContextProvider props: %O', 'color: darkgoldenrod;', props);

    const udicciContext = useUdicciContext();
    const udicciHelpers = useUdicciHelpers();

    var { udicci } = udicciContext.state;
    var { selectedPorta } = udicci;
    // console.log('%c RecordContextProvider selectedPorta: %O', 'color: green;', selectedPorta);
    // console.log('%c RecordContextProvider selectedSocialSolution: %O', 'color: darkgoldenrod;', selectedSocialSolution);
    // console.log('%c RecordContextProvider selectedFeature: %O', 'color: darkgoldenrod;', selectedFeature);

    // const socialSolutionContext = useUdicciSocialSolution(selectedSocialSolution);
    // var { socialSolution } = socialSolutionContext;
    // console.log('%c RecordContextProvider socialSolution: %O', 'color: red;', socialSolution);

    var nodeId: string = (props && props.nodeId ? props.nodeId : '');
    // console.log('%c RecordContextProvider nodeId: %O', 'color: darkgoldenrod;', nodeId);
    var pRecord: UdicciRecord | null = (props && props.record ? props.record : null);
    // console.log('%c RecordContextProvider pRecord: %O', 'color: darkgoldenrod;', pRecord);
    var pRecordContext: any = (pRecord && pRecord.context ? pRecord.context : null);
    // console.log('%c RecordContextProvider pRecordContext: %O', 'color: darkgoldenrod;', pRecordContext);
    var pRecordSet: any = (props && props.recordSet ? props.recordSet : null);
    // console.log('%c RecordContextProvider pRecordSet: %O', 'color: darkgoldenrod;', pRecordSet);
    var pRecords: UdicciRecord[] = (props && props.records ? props.records : null);
    // console.log('%c RecordContextProvider pRecords: %O', 'color: darkgoldenrod;', pRecords);
    var pContextView: any = (props && props.contextView ? props.contextView : null);
    // console.log('%c RecordContextProvider pContextView: %O', 'color: darkgoldenrod;', pContextView);
    var ctxData: any = (pContextView && pContextView.data ? pContextView.data : null);
    // console.log('%c RecordContextProvider ctxData: %O', 'color: darkgoldenrod;', ctxData);
    var ctxRecord: any = (pContextView && pContextView.record ? pContextView.record : null);
    // console.log('%c RecordContextProvider ctxRecord: %O', 'color: darkgoldenrod;', ctxRecord);
    if (!pRecordSet && ctxData) pRecordSet = ctxData;
    // console.log('%c RecordContextProvider pRecordSet: %O', 'color: blue;', pRecordSet);
    if (!pRecordSet && pRecords) pRecordSet = pRecords;
    // console.log('%c RecordContextProvider pRecordSet: %O', 'color: green;', pRecordSet);
    if (!pRecord && pRecordSet && pRecordSet.length > 0) pRecord = pRecordSet[0];
    if (!pRecord && pRecords && pRecords.length > 0) pRecord = pRecords[0];
    // console.log('%c RecordContextProvider pRecord: %O', 'color: blue;', pRecord);
    var recContext: any = (ctxRecord && ctxRecord.context ? ctxRecord.context : null);
    // console.log('%c RecordContextProvider recContext: %O', 'color: darkgoldenrod;', recContext);

    if (pRecord && !pRecord.context) {
        pRecord.context = recContext;
        // console.log('%c RecordContextProvider pRecord: %O', 'color: darkgoldenrod;', pRecord);
    }

    const [rootRecords, setRootRecords] = useState<any>(pRecordSet);
    const [rootRecord, setRootRecord] = useState<any>(pRecord);
    const [recordContext, setRecordContext] = useState<any>(pRecordContext);
    const [contextDatasets, setContextDatasets] = useState<any>(null);
    const [contextRetrieved, setContextRetrieved] = useState(false);
    const [displayState, setDisplayState] = useState<any>(null);
    const [state, setState] = useState<boolean>(false);
    // console.log('%c RecordContextProvider rootRecords: %O', 'color: blue;', rootRecords);
    // console.log('%c RecordContextProvider rootRecord: %O', 'color: blue;', rootRecord);
    // console.log('%c RecordContextProvider recordContext: %O', 'color: blue;', recordContext);
    // console.log('%c RecordContextProvider displayState: %O', 'color: blue;', displayState);
    // console.log('%c RecordContextProvider contextDatasets: %O', 'color: blue;', contextDatasets);

    const [contextLevels, setContextLevels] = useState<any>(null);
    const [levelsSet, setLevels] = useState<boolean>(false);
    // console.log('%c RecordContextProvider contextLevels: %O', 'color: maroon;', contextLevels);
    // console.log('%c RecordContextProvider levelsSet: %O', 'color: maroon;', levelsSet);

    useEffect(() => {
        // console.log('%c RecordContextProvider rootRecord: %O', 'color: gold;', rootRecord);
        // console.log('%c RecordContextProvider pRecord: %O', 'color: gold;', pRecord);

        if (contextRetrieved && (!rootRecord && pRecord)) {
            setRootRecord(pRecord);
        }
    }, [ contextRetrieved, pRecord, rootRecord ]);

    useEffect(() => {
        // console.log('%c RecordContextProvider recordContext: %O', 'color: silver;', recordContext);
        // console.log('%c RecordContextProvider rootRecord: %O', 'color: silver;', rootRecord);
        // console.log('%c RecordContextProvider pRecordContext: %O', 'color: silver;', pRecordContext);

        if (contextRetrieved && (!recordContext && pRecordContext)) {
            setRecordContext(pRecordContext);
        }
        if (contextRetrieved && (!recordContext && rootRecord && rootRecord.context)) {
            setRecordContext(rootRecord.context);
        }
    }, [ contextRetrieved, pRecordContext, recordContext, rootRecord  ]);

    const updateContextLevels = (updatedContextLevels: any) => {
        // console.log('%c updateRootRecord updatedContextLevels: %O', 'color: red;', updatedContextLevels);
        setContextLevels(updatedContextLevels);
    };

    const retrieveContextLevels = () => {
        // console.log('%c retrieveContextLevels contextLevels: %O', 'color: red;', contextLevels);
        return contextLevels;
    };

    const getContextLevels = (contentDesign: any = null) => {
        let rval: any = null;
        let paneContentDesign: any = contentDesign; 
        // console.log('%c getContextLevels paneContentDesign: %O', 'color: maroon;', paneContentDesign);
        if (paneContentDesign) {
            let rows: any = paneContentDesign.rows;
            // console.log('%c getContextLevels rows: %O', 'color: maroon;', rows);
            if (rows && rows.length > 0) {
                forEach(rows, (row: any) => {
                    let columns: any = row.cells;
                    // console.log('%c getContextLevels columns: %O', 'color: maroon;', columns);
                    if (columns && columns.length > 0) {
                        forEach(columns, (cell: any) => {
                            let plugin: any = (cell && cell.plugin && cell.plugin.id ? cell.plugin.id : '');
                            // console.log('%c getContextLevels plugin: %O', 'color: maroon;', plugin);
                            if (plugin === 'udicci.context.view') {
                                let dataI18n: any = cell.dataI18n;
                                // console.log('%c getContextLevels dataI18n: %O', 'color: maroon;', dataI18n);
                                let cv: any = (dataI18n && dataI18n.default && dataI18n.default.contextView ? dataI18n.default.contextView : null);
                                // console.log('%c getContextLevels cv: %O', 'color: maroon;', cv);
                                rval = (cv.levels ? cv.levels : rval);
                                // setLevels(true);
                                setContextLevels(rval);
                            }
                        });
                    }
                });
            }
        }
        return rval;
    }

    const updateDisplayState = (updatedDisplayState: any) => {
        // console.log('%c updateDisplayState updatedDisplayState: %O', 'color: red;', updatedDisplayState);
        setDisplayState(updatedDisplayState);
        setState(!state);
    };

    const updateRootRecord = (updatedRootRecord: any) => {
        // console.log('%c updateRootRecord updatedRootRecord: %O', 'color: red;', updatedRootRecord);
        if (!updatedRootRecord) return false;
        setRootRecord(updatedRootRecord);
        if (updatedRootRecord && updatedRootRecord.context) {
            setRecordContext(updatedRootRecord.context);
            var datasets: any = getContextDatasets(updatedRootRecord.context);
            // console.log('%c updateRootRecord datasets: %O', 'color: red;', datasets);
            setContextDatasets(datasets);
            setState(!state);
        }
    };

    const updateContextRecord = (updatedRecord: UdicciRecord) => {
        // console.log('%c updateContextRecord updatedRecord: %O', 'color: red;', updatedRecord);
        if (!updatedRecord) return false;

        var updatedDatasets: any = {};
        Object.assign(updatedDatasets, contextDatasets);
        // console.log('%c updateContextRecord updatedDatasets: %O', 'color: red;', updatedDatasets);
        if (updatedDatasets && updatedRecord && updatedRecord.udicciMediator) {
            if (updatedDatasets[updatedRecord.udicciMediator]) {
                var ctxDS = updatedDatasets[updatedRecord.udicciMediator];
                // console.log('%c updateContextRecord ctxDS: %O', 'color: red;', ctxDS);
                var ctxDSidx = (ctxDS.idx ? ctxDS.idx : 0);
                // console.log('%c updateContextRecord ctxDSidx: %O', 'color: red;', ctxDSidx);
                var data: any[] = (ctxDS.data ? ctxDS.data : []);
                // console.log('%c updateContextRecord data: %O', 'color: red;', data);
                var found: boolean = false;
                var newRecordIndex: number = -1;
                data.forEach((rec: any, recIndex: number) => {
                    // console.log('%c updateContextRecord rec: %O', 'color: red;', rec);
                    // console.log('%c updateContextRecord recIndex: %O', 'color: red;', recIndex);
                    if (updatedRecord.recordId === rec.recordId) {
                        var currentDataRec: any = data[recIndex];
                        var curRec: any = {};
                        Object.assign(curRec, currentDataRec.record);

                        curRec.recordId = updatedRecord.recordId;
                        curRec.mediator = updatedRecord.udicciMediator;
                        curRec.record = updatedRecord;

                        currentDataRec.record = curRec;
                        data[recIndex] = currentDataRec;

                        found = true;
                    } else if (rec.recordId === 0) {
                        newRecordIndex = recIndex;
                    }
                });

                if (!found) {
                    var curRec: any = {};
                    if (newRecordIndex >= 0) {
                        curRec = data[newRecordIndex];
                    }
                    curRec.recordId = updatedRecord.recordId;
                    curRec.mediator = updatedRecord.udicciMediator;
                    curRec.record = updatedRecord;

                    curRec.level = (curRec.parentId ? curRec.parentId : ctxDS.level);
                    curRec.parentId = (curRec.parentId ? curRec.parentId : 0);
                    curRec.parentMediator = (curRec.parentMediator ? curRec.parentMediator : '');

                    if (newRecordIndex >= 0) {
                        data[newRecordIndex] = curRec;
                        ctxDSidx = newRecordIndex;
                    } else {
                        data.push(curRec);
                        ctxDSidx = data.length - 1;
                    }
                    // console.log('%c updateContextRecord data.length: %O', 'color: red;', data.length);
                }
                // console.log('%c updateContextRecord data: %O', 'color: red;', data);
                // console.log('%c updateContextRecord ctxDSidx: %O', 'color: red;', ctxDSidx);
                ctxDS.data = data;
                if (ctxDS.idx !== ctxDSidx) ctxDS.idx = ctxDSidx;
                updatedDatasets[updatedRecord.udicciMediator] = ctxDS;
            }

            if (updatedRecord.context && updatedRecord.context.RelationshipChanges) {
                var relChanges: any [] = updatedRecord.context.RelationshipChanges;
                // console.log('%c updateContextRecord relChanges: %O', 'color: red;', relChanges);
                relChanges.forEach((relChange: any, relChangeIndex: number) => {
                    // console.log('%c updateContextRecord relChange: %O', 'color: red;', relChange);
                    // console.log('%c updateContextRecord relChangeIndex: %O', 'color: red;', relChangeIndex);
                    if (relChange.RecordMediator === updatedRecord.udicciMediator) {
                        if (relChange.RelatedMediator && relChange.RelatedRecordId) {
                            var relCtxDS = updatedDatasets[relChange.RelatedMediator];
                            // console.log('%c updateContextRecord relCtxDS: %O', 'color: blue;', relCtxDS);
                            if (relCtxDS) {
                                var relCurIdx: number = relCtxDS.idx;
                                // console.log('%c updateContextRecord relCurIdx: %O', 'color: blue;', relCurIdx);
                                var relData: UdicciRecord[] = (relCtxDS.data ? relCtxDS.data : []);
                                // console.log('%c updateContextRecord relData: %O', 'color: red;', relData);
                                relData.forEach((rec: UdicciRecord, recIndex: number) => {
                                    // console.log('%c updateContextRecord rec: %O', 'color: red;', rec);
                                    // console.log('%c updateContextRecord recIndex: %O', 'color: red;', recIndex);
                                    if (relChange.RelatedRecordId === rec.recordId) relCurIdx = recIndex;
                                });
                                // console.log('%c updateContextRecord relCurIdx: %O', 'color: blue;', relCurIdx);
                                relCtxDS.idx = relCurIdx;
                            }
                            updatedDatasets[relChange.RelatedMediator] = relCtxDS;
                        }
                    }
                });
            }

            // console.log('%c updateContextRecord updatedDatasets: %O', 'color: red;', updatedDatasets);
            setContextDatasets(updatedDatasets);
            setState(!state);
        }
    };

    const removeContextRecord = (removeRecord: UdicciRecord) => {
        // console.log('%c removeContextRecord removeRecord: %O', 'color: red;', removeRecord);
        if (!removeRecord) return false;

        var updatedDatasets: any = {};
        Object.assign(updatedDatasets, contextDatasets);
        // console.log('%c removeContextRecord updatedDatasets: %O', 'color: red;', updatedDatasets);
        if (updatedDatasets && removeRecord && removeRecord.udicciMediator) {
            if (updatedDatasets[removeRecord.udicciMediator]) {
                var ctxDS = updatedDatasets[removeRecord.udicciMediator];
                // console.log('%c removeContextRecord ctxDS: %O', 'color: red;', ctxDS);
                var data: any[] = (ctxDS.data ? ctxDS.data : []);
                // console.log('%c removeContextRecord data: %O', 'color: red;', data);
                var updatedData: any[] = [];
                data.forEach((rec: any) => {
                    // console.log('%c removeContextRecord rec: %O', 'color: red;', rec);
                    if (removeRecord.recordId !== rec.recordId) updatedData.push(rec);
                });

                // console.log('%c removeContextRecord updatedData: %O', 'color: red;', updatedData);
                ctxDS.data = updatedData;
                ctxDS.idx = 0;
                updatedDatasets[removeRecord.udicciMediator] = ctxDS;
            }

            // console.log('%c removeContextRecord updatedDatasets: %O', 'color: red;', updatedDatasets);
            setContextDatasets(updatedDatasets);
            setState(!state);
        }
    };

    const updateRecordContext = (updatedRecordContext: any) => {
        // console.log('%c updateRecordContext updatedRecordContext: %O', 'color: red;', updatedRecordContext);
        if (!updatedRecordContext) return false;
        setRecordContext(updatedRecordContext);
        var datasets: any = getContextDatasets(updatedRecordContext);
        // console.log('%c updateRecordContext datasets: %O', 'color: red;', datasets);
        setContextDatasets(datasets);
        setState(!state);
    };

    const loadRecordContext = useCallback((record: UdicciRecord, socialSolution: UdicciRecord | null = null) => {
        // console.log('%c loadRecordContext record: %O', 'color: darkgoldenrod;', record);
        // console.log('%c loadRecordContext socialSolution: %O', 'color: darkgoldenrod;', socialSolution);
        if (!record) return false;

        if (!recordContext && !contextRetrieved) udicciHelpers.getRecordContext(record, socialSolution);
        setContextRetrieved(true);
    }, [ contextRetrieved, recordContext, udicciHelpers ]);

    const loadRecordContextByView = useCallback((contextView: any, force?: boolean | undefined) => {
        // console.log('%c loadRecordContextByView contextView: %O', 'color: darkgoldenrod;', contextView);
        // console.log('%c loadRecordContextByView contextLevels: %O', 'color: darkgoldenrod;', contextLevels);
        if (!contextView) return false;

        // console.log('%c loadRecordContextByView recordContext: %O', 'color: darkgoldenrod;', recordContext);
        // console.log('%c loadRecordContextByView contextRetrieved: %O', 'color: darkgoldenrod;', contextRetrieved);
        if ((!recordContext && !contextRetrieved) || force) udicciHelpers.getRecordContextByView(contextView, contextLevels);
        setContextRetrieved(true);
    }, [ contextRetrieved, recordContext, udicciHelpers, contextLevels ]);

    const getContextDatasets = (recContext: any) => {
        // console.log('%c getContextDatasets recContext: %O', 'color: purple;', recContext);
        var datasets: any = null;
        if (recContext && recContext.records) {
            var records: any[] = recContext.records;
            if (records && records.length > 0) {
                // console.log('%c RecordContextDisplay useEffect records: %O', 'color: red;', records);
                if (!datasets) datasets = {};
                records.forEach((rec: any) => {
                    // console.log('%c rec: %O', 'color: blue;', rec);
                    var recMediatorName = rec.record.udicciMediator;
                    var recParentMediatorName = rec.parentMediator;
                    var recChildMediatorName = rec.childMediator;

                    if (!datasets[recMediatorName]) {
                        datasets[recMediatorName] = { idx: -1, data: [], level: 0 };
                    }

                    var _level: number = 2;
                    if (recParentMediatorName) {
                        if (!datasets[recMediatorName].parentMediators) datasets[recMediatorName].parentMediators = [];
                        if (datasets[recMediatorName].parentMediators.indexOf(recParentMediatorName) < 0) {
                            datasets[recMediatorName].parentMediators.push(recParentMediatorName);
                        }

                        var dsParent: any = datasets[recParentMediatorName];
                        // console.log('%c dsParent: %O', 'color: blue;', dsParent);
                        if (dsParent.level > 1) {
                            _level = dsParent.level + 1;
                        }
                    } else {
                        _level = 1;
                    }

                    datasets[recMediatorName].level = _level;
                    rec.level = _level;
                    // console.log('%c datasets: %O', 'color: blue;', datasets);

                    datasets[recMediatorName].data.push(rec);

                    if (datasets[recMediatorName].idx < 0) datasets[recMediatorName].idx = 0;
                    if (!datasets[recMediatorName].mediator) datasets[recMediatorName].mediator = recMediatorName;

                    if (recChildMediatorName) {
                        if (!datasets[recMediatorName].childMediators) datasets[recMediatorName].childMediators = [];
                        if (datasets[recMediatorName].childMediators.indexOf(recChildMediatorName) < 0) {
                            datasets[recMediatorName].childMediators.push(recChildMediatorName);
                        }
                    }
                });
            }
        }
        if (recContext && recContext.structures) {
            var structs: any[] = recContext.structures;
            if (structs && structs.length > 0) {
                // console.log('%c RecordContextDisplay useEffect records: %O', 'color: red;', records);
                if (!datasets) datasets = {};
                structs.forEach((s: any) => {
                    // console.log('%c rec: %O', 'color: blue;', rec);
                    if (!datasets[s.Name]) {
                        datasets[s.Name] = { idx: -1, data: [], level: 2, mediator: s.Name };
                    }
                });
            }
        }
        return datasets;
    };

    const setData = (udicciMediatorName: string, data: any[]) => {
        // console.log('%c setData udicciMediatorName: %O', 'color: blue;', udicciMediatorName);
        // console.log('%c setData contextDatasets: %O', 'color: blue;', contextDatasets);
        // console.log('%c setData data: %O', 'color: blue;', data);

        if (!contextDatasets) return;
        if (!contextDatasets[udicciMediatorName]) return;

        var _datasets: any = {};
        if (contextDatasets) Object.assign(_datasets, contextDatasets);
        // console.log('%c setData _datasets: %O', 'color: red;', _datasets);
        var curDs = (_datasets[udicciMediatorName] ? _datasets[udicciMediatorName] : {});
        // console.log('%c setData curDs: %O', 'color: red;', curDs);
        var curLevel = (curDs && curDs.level ? curDs.level : 1);
        // console.log('%c setData curLevel: %O', 'color: red;', curLevel);

        var updatedData: any[] = [];
        // console.log('%c updateContextRecord data: %O', 'color: red;', data);
        data.forEach((rec: any) => {
            // console.log('%c updateContextRecord rec: %O', 'color: red;', rec);
            // console.log('%c updateContextRecord recIndex: %O', 'color: red;', recIndex);
            var listRec: any = {};

            listRec.level = curLevel;
            listRec.recordId = rec.recordId;
            listRec.mediator = rec.udicciMediator;
            listRec.record = rec;

            if (listRec.level === 1) {
                listRec.parentId = 0;
                listRec.parentMediator = '';
            }

            updatedData.push(listRec);
        });
        // console.log('%c setData updatedData: %O', 'color: red;', updatedData);

        curDs.data = updatedData;
        _datasets[udicciMediatorName] = curDs;
        // console.log('%c setData _datasets: %O', 'color: red;', _datasets);
        setContextDatasets(_datasets);
    };

    const getData = (udicciMediatorName: string) => {
        // console.log('%c getData udicciMediatorName: %O', 'color: blue;', udicciMediatorName);
        // console.log('%c getData contextDatasets: %O', 'color: blue;', contextDatasets);
        var curDs = (contextDatasets && contextDatasets[udicciMediatorName] ? contextDatasets[udicciMediatorName] : null);
        // console.log('%c getData curDs: %O', 'color: red;', curDs);
        return (curDs && curDs.data ? curDs.data : []);
    };

    const getCurrentDisplayContext = useCallback(() => {
        // console.log('%c getCurrentDisplayContext contextDatasets: %O', 'color: blue;', contextDatasets);
        // console.log('%c getCurrentDisplayContext displayState: %O', 'color: blue;', displayState);

        var rval: any = {};
        if (displayState) Object.assign(rval, displayState);
        // var newRecords: any[] = [];
        if (contextDatasets) {
            // need to sort datasets, then get data from each dataset in order at this point.
            values(contextDatasets).forEach((ds: any) => {
                // console.log('%c getCurrentDisplayContext ds: %O', 'color: red;', ds);

                var level: number = (ds.level ? ds.level : 1);
                // console.log('%c level: %O', 'color: red;', level);
                var selectedIndex: number = (ds.idx ? ds.idx : 0);
                // console.log('%c selectedIndex: %O', 'color: red;', selectedIndex);
                var mediator: string = (ds.mediator ? ds.mediator : '');
                // console.log('%c mediator: %O', 'color: red;', mediator);
                var data: any[] = (ds.data ? ds.data : []);
                // console.log('%c data: %O', 'color: red;', data);

                var parentLevel: number = (level > 1 ? level - 1 : 0);
                // console.log('%c level: %O', 'color: red;', level);
                var parentRecord: any | null = null;
                if (parentLevel > 0 && rval && rval[parentLevel] && rval[parentLevel].selectedRecord && rval[parentLevel].selectedRecord.record) {
                    parentRecord = rval[parentLevel].selectedRecord.record;
                }

                var totalRecordsInGroup: number = 1; // at least this one exists
                var groupRecords: any[] = getLevelGroupRecords(data, level, parentRecord);
                totalRecordsInGroup = groupRecords.length;
                // console.log('%c groupRecords: %O', 'color: red;', groupRecords);
                // console.log('%c totalRecordsInGroup: %O', 'color: red;', totalRecordsInGroup);

                var selectedRecord: any | null = null;
                if (totalRecordsInGroup > 0 && selectedIndex >= 0 && selectedIndex < totalRecordsInGroup) {
                    selectedRecord = groupRecords[selectedIndex];
                }

                var levelStructure: any = udicciHelpers.getMediatorStructure(mediator);
                // console.log('%c levelStructure: %O', 'color: red;', levelStructure);

                rval[level] = {
                    level: level,
                    mediator: mediator,
                    structure: levelStructure,
                    groupRecords: groupRecords,
                    recordCount: totalRecordsInGroup,
                    selectedIndex: selectedIndex,
                    selectedRecord: selectedRecord,
                };
            });
            // console.log('%c RecordContextDisplayContents newRecords: %O', 'color: red;', newRecords);
        }

        // console.log('%c rval: %O', 'color: red;', rval);
        setDisplayState(rval);
        // console.log('%c getCurrentDisplayContext displayState: %O', 'color: blue;', displayState);
        return rval;
    }, [contextDatasets, displayState, udicciHelpers]);

    function getLevelGroupRecords(data: any[], level: number = 1, parentRecord: UdicciRecord | null) {
        // console.log('%c getLevelGroupRecords data: %O', 'color: blue;', data);
        // console.log('%c getLevelGroupRecords level: %O', 'color: blue;', level);
        // console.log('%c getLevelGroupRecords parentRecord: %O', 'color: blue;', parentRecord);
        var groupRecords: any[] = [];
        // console.log('%c getLevelGroupRecords displayState: %O', 'color: blue;', displayState);
        if (data && data.length > 0) {
            groupRecords = filter(data, function (rec: any) {
                // console.log('%c rec: %O', 'color: green;', rec);
                var isLevelOneRec: boolean = false;
                var parentIsSelected: boolean = false;
                var parentIsIncludedInUpdates: boolean = false;
                if (level === 1) {
                    isLevelOneRec = true;
                } else {
                    var recCntxt = (parentRecord && parentRecord.context ? parentRecord.context : null);
                    // console.log('%c recCntxt: %O', 'color: red;', recCntxt);
                    var recRelChanges: any[] | null = (recCntxt && recCntxt.RelationshipChanges ? recCntxt.RelationshipChanges : null);
                    // console.log('%c recRelChanges: %O', 'color: red;', recRelChanges);
                    var parentMediator: string = (parentRecord && parentRecord.udicciMediator ? parentRecord.udicciMediator : '');
                    var parentRecordId: number = (parentRecord && parentRecord.recordId ? parentRecord.recordId : 0);
                    if (recRelChanges && recRelChanges.length > 0 && parentRecordId > 0) {
                        var chk: any = recRelChanges.find(x => (x.RelatedRecordId === parentRecord?.recordId && x.RelatedMediator === parentMediator)
                        );
                        // console.log('%c chk: %O', 'color: red;', chk);
                        if (chk)
                            parentIsIncludedInUpdates = true;
                    }

                    if (!parentIsIncludedInUpdates && parentRecord && parentRecordId > 0) {
                        parentIsSelected = (rec.parentMediator === parentMediator && rec.parentId === parentRecordId);
                    }
                }

                // console.log('%c parentIsSelected %s: %O', 'color: red;', rec.record.title, parentIsSelected);
                // console.log('%c parentIsIncludedInUpdates %s: %O', 'color: red;', rec.record.title, parentIsIncludedInUpdates);
                // console.log('%c isLevelOneRec %s: %O', 'color: red;', rec.record.title, isLevelOneRec);
                return isLevelOneRec || parentIsSelected || parentIsIncludedInUpdates;
            });
        }
        return groupRecords;
    }

    const navigate = (udicciMediatorName: string, direction: string) => {
        var curDs = (contextDatasets[udicciMediatorName] ? contextDatasets[udicciMediatorName] : {});
        var curLevel = (curDs && curDs.level ? curDs.level : 1);
        var _displayState: any = {};
        if (displayState) Object.assign(_displayState, displayState);
        var curDisp = (_displayState[curLevel] ? _displayState[curLevel] : {});

        if (direction === 'next' && (curDisp.selectedIndex < (curDisp.groupRecords.length - 1))) curDisp.selectedIndex++;
        if (direction === 'previous' && curDisp.selectedIndex > 0) curDisp.selectedIndex--;

        var newRecordFocus: any = (curDisp.selectedIndex >= 0 ? curDisp.groupRecords[curDisp.selectedIndex] : null);
        curDisp.selectedRecord = newRecordFocus;

        _displayState[curLevel] = curDisp;
        _displayState = resetSubsets(_displayState, newRecordFocus);

        setDisplayState(_displayState);

        if (newRecordFocus && newRecordFocus.level === 1) setRootRecord(newRecordFocus);

        return newRecordFocus;
    };

    const jump = (jumpToRecord: any) => {
        // console.log('%c jump jumpToRecord: %O', 'color: red;', jumpToRecord);
        if (!jumpToRecord) return false;

        var curDs = (contextDatasets[jumpToRecord.mediator] ? contextDatasets[jumpToRecord.mediator] : {});
        var curLevel = (curDs && curDs.level ? curDs.level : 1);
        var curData = (curDs && curDs.data ? curDs.data : []);
        var _displayState: any = {};
        if (displayState) Object.assign(_displayState, displayState);
        var curDisp = (_displayState[curLevel] ? _displayState[curLevel] : {});
        var curIdx = (curDisp && curDisp.selectedIndex ? curDisp.selectedIndex : 0);

        curData.forEach((dr: any, idx: number) => {
            if (dr.recordId === jumpToRecord.recordId) curIdx = idx;
        });

        curDisp.selectedIndex = curIdx;
        var newRecordFocus: any = (curIdx >= 0 ? curDisp.groupRecords[curIdx] : null);
        curDisp.selectedRecord = newRecordFocus;

        _displayState[curLevel] = curDisp;
        _displayState = resetSubsets(_displayState, newRecordFocus);

        setDisplayState(_displayState);

        if (newRecordFocus && newRecordFocus.level === 1) setRootRecord(newRecordFocus);

        return newRecordFocus;
    };

    const resetSubsets = (updatedDisplayState: any, keyRecord: any) => {
        // console.log('%c resetSubsets updatedDisplayState: %O', 'color: blue;', updatedDisplayState);
        if (keyRecord) {
            for (var [level, ds] of Object.entries<any>(updatedDisplayState)) {
                if (ds.level > keyRecord.level) {
                    var levelDs = (contextDatasets[ds.mediator] ? contextDatasets[ds.mediator] : {});
                    // console.log('%c resetSubsets levelDs: %O', 'color: red;', levelDs);
                    var levelData: any[] = (levelDs.data ? levelDs.data : []);
                    // console.log('%c levelData: %O', 'color: red;', levelData);
    
                    var levelParentRec: any = null;
                    if (ds.level > 1) {
                        var levelParentDs: any = updatedDisplayState[(ds.level - 1)];
                        var lvlParRec: any = (levelParentDs && levelParentDs.selectedRecord ? levelParentDs.selectedRecord : null);
                        levelParentRec = (lvlParRec && lvlParRec.record ? lvlParRec.record : null);
                    }
                    // console.log('%c levelParentRec: %O', 'color: red;', levelParentRec);
                    var groupRecords: any[] = getLevelGroupRecords(levelData, levelDs.level, levelParentRec);
                    var totalRecordsInGroup: number = groupRecords.length;
                    // console.log('%c groupRecords: %O', 'color: red;', groupRecords);

                    updatedDisplayState[level].groupRecords = groupRecords;
                    updatedDisplayState[level].selectedIndex = 0;
                    updatedDisplayState[level].selectedRecord = (totalRecordsInGroup > 0 ? groupRecords[0] : null);
                    updatedDisplayState[level].recordCount = totalRecordsInGroup;
                }
            }
        }

        return updatedDisplayState;
    };

    const addRecord = (level: number) => {
        // console.log('%c addRecord level: %O', 'color: red;', level);
        if (!displayState || level <= 0) return null;
        // console.log('%c addRecord displayState: %O', 'color: red;', displayState);

        var _displayState: any = {};
        if (displayState) Object.assign(_displayState, displayState);
        var levelDisplay = (_displayState[level] ? _displayState[level] : {});
        // console.log('%c addRecord levelDisplay: %O', 'color: red;', levelDisplay);

        var mediator: string = (levelDisplay.mediator ? levelDisplay.mediator : '');
        // console.log('%c addRecord mediator: %O', 'color: maroon;', mediator);
        if (!mediator) return null;
        var structure: any = (levelDisplay.structure ? levelDisplay.structure : null);
        // console.log('%c addRecord structure: %O', 'color: maroon;', structure);
        if (!structure) return null;

        var groupRecords: any[] = (levelDisplay.groupRecords ? levelDisplay.groupRecords : []);
        // console.log('%c addRecord groupRecords: %O', 'color: maroon;', groupRecords);

        var chkForNewRec: any = groupRecords.find(x => (x.recordId === 0 && x.RelatedMediator === mediator)
        );
        // console.log('%c chkForNewRec: %O', 'color: red;', chkForNewRec);

        var newRecord: any = null;
        if (!chkForNewRec) {
            var parentId: number = 0;
            var parentMediator: string = '';
            if (level > 1) {
                // var parentLevel: number = level - 1;
                // console.log('%c parentLevel: %O', 'color: red;', parentLevel);
                var parentLevelDisplay = (_displayState[level] ? _displayState[level] : {});
                // console.log('%c addRecord parentLevelDisplay: %O', 'color: red;', parentLevelDisplay);

                // var parentGroupRecords: any[] = (parentLevelDisplay.groupRecords ? parentLevelDisplay.groupRecords : []);
                // console.log('%c addRecord parentGroupRecords: %O', 'color: maroon;', parentGroupRecords);
                var selectedParentRec: any = (parentLevelDisplay.selectedRecord ? parentLevelDisplay.selectedRecord : null);
                // console.log('%c addRecord selectedParentRec: %O', 'color: maroon;', selectedParentRec);
                if (selectedParentRec) {
                    parentId = selectedParentRec.recordId;
                    parentMediator = selectedParentRec.mediator;
                }
            }

            var newUdicciRecord: UdicciRecord = new UdicciRecord(mediator, null, structure);
            // console.log('%c addRecord newUdicciRecord: %O', 'color: red;', newUdicciRecord);
            newRecord = {
                level: level,
                mediator: newUdicciRecord.udicciMediator,
                parentId: parentId,
                parentMediator: parentMediator,
                record: newUdicciRecord,
                recordId: newUdicciRecord.recordId
            };
            // console.log('%c addRecord newRecord: %O', 'color: red;', newRecord);
            groupRecords.push(newRecord);

            levelDisplay.groupRecords = groupRecords;
            levelDisplay.selectedIndex = groupRecords.length - 1;
            levelDisplay.selectedRecord = newRecord;

            _displayState[level] = levelDisplay;
            _displayState = resetSubsets(_displayState, newRecord);
    
            setDisplayState(_displayState);
        }

        if (newRecord && newRecord.level === 1) setRootRecord(newRecord);

        return newRecord;
    };

    const removeDisplayRecord = (removeRecord: any) => {
        // console.log('%c removeDisplayRecord removeRecord: %O', 'color: red;', removeRecord);
        if (!removeRecord) return false;

        var updatedDisplayState: any = {};
        Object.assign(updatedDisplayState, displayState);
        // console.log('%c removeDisplayRecord updatedDisplayState: %O', 'color: red;', updatedDisplayState);
        if (updatedDisplayState && removeRecord && removeRecord.level) {
            if (updatedDisplayState[removeRecord.level]) {
                var ctxDS = updatedDisplayState[removeRecord.level];
                // console.log('%c removeDisplayRecord ctxDS: %O', 'color: red;', ctxDS);
                var groupRecords: any[] = (ctxDS.groupRecords ? ctxDS.groupRecords : []);
                // console.log('%c removeDisplayRecord groupRecords: %O', 'color: red;', groupRecords);
                var updatedGroupRecords: any[] = [];
                groupRecords.forEach((rec: any) => {
                    // console.log('%c removeDisplayRecord rec: %O', 'color: red;', rec);
                    if (removeRecord.recordId !== rec.recordId) updatedGroupRecords.push(rec);
                });

                // console.log('%c removeDisplayRecord updatedGroupRecords: %O', 'color: red;', updatedGroupRecords);
                ctxDS.groupRecords = updatedGroupRecords;
                ctxDS.selectedIndex = 0;
                ctxDS.selectedRecord = (updatedGroupRecords.length > 0 ? updatedGroupRecords[0] : null);
                // console.log('%c removeDisplayRecord updatedGroupRecords: %O', 'color: red;', updatedGroupRecords);
                updatedDisplayState[removeRecord.level] = ctxDS;
                // console.log('%c removeDisplayRecord updatedDisplayState: %O', 'color: red;', updatedDisplayState);
            }

            // console.log('%c removeDisplayRecord updatedDisplayState: %O', 'color: red;', updatedDisplayState);
            setDisplayState(updatedDisplayState);
            setState(!state);
        }
    };

    useEffect(() => {
        // console.log('%c RecordContextProvider rootRecord: %O', 'color: darkgoldenrod;', rootRecord);
        // console.log('%c RecordContextProvider recordContext: %O', 'color: darkgoldenrod;', recordContext);
        // console.log('%c RecordContextProvider contextRetrieved: %O', 'color: darkgoldenrod;', contextRetrieved);
        // console.log('%c RecordContextProvider contextDatasets: %O', 'color: darkgoldenrod;', contextDatasets);
        // console.log('%c RecordContextProvider displayState: %O', 'color: darkgoldenrod;', displayState);
        // console.log('%c RecordContextProvider pContextView: %O', 'color: darkgoldenrod;', pContextView);

        if ((!contextRetrieved) && pContextView) {
            loadRecordContextByView(pContextView);
        } else if (!recordContext || !contextRetrieved) {
            loadRecordContext(rootRecord);
        } else if (!recordContext && contextRetrieved) {
            if (pContextView && pContextView.record) {
                updateRootRecord(pContextView.record);
            }
        } else if (recordContext && contextRetrieved && !contextDatasets) {
            var datasets: any = getContextDatasets(recordContext);
            // console.log('%c updateRecordContext datasets: %O', 'color: red;', datasets);
            setContextDatasets(datasets);
        } else if (recordContext && contextRetrieved && contextDatasets && !displayState) {
            getCurrentDisplayContext();
        }
    }, [
        contextRetrieved,
        contextDatasets,
        rootRecord,
        recordContext,
        displayState,
        loadRecordContext,
        getCurrentDisplayContext,
        loadRecordContextByView
    ]);

    return (
        <RecordContextFactory.Provider value={{
            setRootRecord: updateRootRecord,
            setRecordContext: updateRecordContext, loadRecordContext, loadRecordContextByView, 
            setRecord: updateContextRecord,
            addRecord, getContextLevels, updateContextLevels, retrieveContextLevels, 
            removeRecord: removeContextRecord, removeDisplayRecord,
            navigate, jump,
            displayState, updateDisplayState,
            datasets: contextDatasets,
            rootRecord, recordContext, rootRecords, setRootRecords,
            RecordContextStateUpdate: state,
            setData, getData, getCurrentDisplayContext
        }}>
            {props.children}
        </RecordContextFactory.Provider>
    )
}

function useRecordContextFactory() {
    const context = useContext(RecordContextFactory)
    if (context === undefined) {
        throw new Error('useUdicciRecordContext must be used within a RecordContextProvider');
    }
    return context
}

export {
    useRecordContextFactory,
    RecordContextFactory,
    RecordContextProvider
};
