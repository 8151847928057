import React from 'react';

// import { useTheme } from '@mui/material/styles';

// import { udicciStyles } from 'src/theme/shared-styles';
import useUdicciRecordField from 'src/hooks/useUdicciRecordField';

const FieldDisplay: React.FC<any> = (props) => {
    // console.log('%c FieldDisplay props: %O', 'color: blue;', props);

    // const theme = useTheme();
    // const udicciClasses = udicciStyles(theme);

    let defaultShowTrimmed: boolean = true;
    if (props && props.showTrimmed !== undefined && props.showTrimmed !== null) {
        defaultShowTrimmed = Boolean(props.showTrimmed);
    }
    var overflowAtOneLine = (props && props.overflowAtOneLine ? true : false);
    // console.log('%c overflowAtOneLine: %O', 'color: maroon;', overflowAtOneLine);

    const { getListElement, getFieldFormElement } = useUdicciRecordField(props.record, props.field, props.onChange, { showTrimmed: defaultShowTrimmed, overflowAtOneLine: overflowAtOneLine });

    let fieldProps: any = {};
    Object.assign(fieldProps, props);

    let displaySettings: any = fieldProps.displaySettings;
    if (!displaySettings) displaySettings = {};
    if (fieldProps.variant === undefined) fieldProps.variant = (displaySettings && displaySettings.variant ? displaySettings.variant : 'body1');
    // if (fieldProps.color === undefined) fieldProps.color = (displaySettings && displaySettings.color ? displaySettings.color : 'textPrimary');
    if (fieldProps.gutterBottom === undefined) fieldProps.gutterBottom = (displaySettings && displaySettings.gutterBottom ? true : false);
    // if (fieldProps.classes === undefined) fieldProps.classes = (displaySettings && displaySettings.classes ? displaySettings.classes : { root: udicciClasses.itemTitleContainer });
    // console.log('%c FieldDisplay fieldProps: %O', 'color: blue;', fieldProps);
    return (props.showForm ? getFieldFormElement(fieldProps) : getListElement(fieldProps));
}

export default FieldDisplay;
