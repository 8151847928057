import { useTheme } from '@mui/material/styles';

interface WithThemeProps {
    primaryColor: string;
}

export interface UdicciDefaultProps {
    data?: any | null | undefined;
    onClose?: any | null | undefined;
}

export interface UdicciProps extends WithThemeProps {
    children: React.ReactNode;
}
 
export function withUdicciTheme<T extends WithThemeProps = WithThemeProps>(
    UdicciComponent: React.ComponentType<T>
) {
    // Try to create a nice displayName for React Dev Tools.
    const displayName = UdicciComponent.displayName || UdicciComponent.name || "Component";
  
    // Creating the inner component. The calculated UdicciProps type here is where the magic happens.
    const ComponentWithTheme = (props: Omit<T, keyof WithThemeProps>) => {
        // Fetch the props you want to inject. This could be done with context instead.
        const themeProps = useTheme();

        // props comes afterwards so the can override the default ones.
        return (
            <UdicciComponent {...themeProps} {...(props as T)} />
        );
    };
  
    ComponentWithTheme.displayName = `withTheme(${displayName})`;
  
    return ComponentWithTheme;
}