
import { Fragment, useEffect, useCallback, useState } from "react"
import Pluralize from 'pluralize';

import { styled, useTheme } from '@mui/material/styles';

import { find, values, forEach } from 'underscore';

import {
    Box, Typography, Divider, Avatar, Button, CircularProgress, IconButton, Icon,
    Card, CardHeader, CardContent, CardActions, TableCell,
    Dialog, DialogTitle, DialogContent, DialogActions, FormControl, FormControlLabel,
    Checkbox, MenuItem, TextField, Select, Badge
} from '@mui/material';

import {
    MenuOpen as MenuOpenIcon,
    LastPage as LastPageIcon,
    Explore as AcknowledgeIcon,
    ExploreOff as AcknowledgeAndHideIcon,
} from '@mui/icons-material';

import { useUdicciContext, sendPreparedRequest } from 'src/context/udicci-context';
import { UdicciRecord, UdicciPermissions, checkConditionAgainstRecord } from 'src/classes/udicci-record';
import {
    SaveRecordRequest,
    DeleteRecordRequest,
    GetRecordPerspectivesRequest,
    GetRecordAttachmentsRequest,
    AcknowledgeNotificationRequest
} from 'src/interfaces/udicci-request-interfaces';

import useUdicciSocialSolution from "src/hooks/useUdicciSocialSolution";
import useUdicciMediator from "src/hooks/useUdicciMediator";

import { useRecordContextFactory } from 'src/context/record-context-factory';
// import { useUdicciListContext } from 'src/context/udicci-list-context';

import { EngagementMenuItem } from 'src/components/record-engagement';
import { getEngagementActionPackages } from 'src/components/engagement-actions/engagement-actions';

import { udicciStyles } from 'src/theme/shared-styles';

import FieldDisplay from "src/components/field-display";
import { RecordEngagement } from "src/components/record-engagement";
import { RecordParentSelection } from 'src/components/record-parent-selector';
import { RecordChildrenDisplay } from 'src/components/record-children-display';
// import { usePaneContext } from 'src/context/pane-context';

const RecordListItem = styled('div')(({ theme }) => ({
    display: 'flow-root',
    padding: theme.spacing(1),
    border: '0.7px dashed #132274',
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: theme.spacing(1),
}));

const EngagementMenuContainer = styled('div')(({ theme }) => ({
    float: 'right',
    // display: 'flow-root'
}));

export default function useUdicciRecord( record: UdicciRecord, engagedAction: any = null, forNotification: any = null, activitySummary: any = null, onReloadActivity: any = null, onUpdateNotification: any = null ) {
    // console.log('%c useUdicciRecord record: %O', 'color: red;', record);
    // console.log('%c useUdicciRecord engagedAction: %O', 'color: red;', engagedAction);
    // console.log('%c useUdicciRecord forNotification: %O', 'color: red;', forNotification);

    const [ recordReference, setRecordReference ] = useState<UdicciRecord>(record);
    // console.log('%c useUdicciRecord recordReference: %O', 'color: red;', recordReference);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showHelp, setShowHelp] = useState<boolean>(false);
    const [processingRequest, setProcessingRequest] = useState<boolean>(false);
    const [recordContext, setRecordContext] = useState<any>(null);
    const [engagementAction, setEngagementAction] = useState<any>(engagedAction);
    const [contextRetrieved, setContextRetrieved] = useState(false);
    const [datasets, setDatasets] = useState<any>(null);
    const [showStackForm, setShowStackForm] = useState<boolean>(false);
    const [showNewStackForm, setShowNewStackForm] = useState<boolean>(false);
    const [newStackName, setNewStackName] = useState<string>('');
    const [newAssignedStack, setNewAssignedStack] = useState<any>(null);
    const [autoAcknowledgeWhenStack, setAutoAcknowledgeWhenStack] = useState<boolean>(false);
    const [showFormAdditionalDetails, setShowFormAdditionalDetails] = useState<boolean>(false);
     // console.log('%c useUdicciRecord recordContext: %O', 'color: red;', recordContext);
    // console.log('%c useUdicciRecord engagementAction: %O', 'color: red;', engagementAction);
    // console.log('%c useUdicciRecord datasets: %O', 'color: red;', datasets);

    const theme = useTheme();

    let recordMediator: string = (record && record.udicciMediator ? record.udicciMediator : '');

    // const paneContext = usePaneContext();
    // console.log('%c useUdicciRecord paneContext: %O', 'color: red;', paneContext);

    // var udicciHelpers = useUdicciHelpers();
    const um = useUdicciMediator(recordMediator);
    var { udicciMediator, getField } = um;
    // console.log('%c useUdicciRecord udicciMediator: %O', 'color: red;', udicciMediator);

    const udicciContext = useUdicciContext();
    // const udicciListContext = useUdicciListContext();

    const recordContextFactory = useRecordContextFactory();
    // console.log('%c useUdicciRecord recordContextFactory: %O', 'color: red;', recordContextFactory);
    var { loadRecordContext } = recordContextFactory;

    var { udicci, data } = udicciContext.state;
    // console.log('%c udicci: %O', 'color: red;', udicci);
    // console.log('%c data: %O', 'color: red;', data);
    var { currentUser, selectedProfile, selectedSocialSolution, selectedFeature } = udicci;
    // console.log('%c selectedProfile: %O', 'color: red;', selectedProfile);
    // console.log('%c selectedSocialSolution: %O', 'color: red;', selectedSocialSolution);
    // console.log('%c selectedFeature: %O', 'color: red;', selectedFeature);

    var ssRec: any = null;
    if (data) {
        var recData: any = (record && record.data ? record.data : null);
        var ssId: number = (recData && recData.CreatedInSolutionId ? recData.CreatedInSolutionId : 0);
        // console.log('%c ssId: %O', 'color: red;', ssId);
        // console.log('%c selectedSocialSolution: %O', 'color: red;', selectedSocialSolution);
        if (!ssId && selectedSocialSolution) {
            var ss: any = (selectedSocialSolution.solution ? selectedSocialSolution.solution : null);
            // console.log('%c ss: %O', 'color: red;', ss);
            if (ss) ssId = ss.recordId;
        }
        var mediatorContext = data.find((x: any) => x.mediator === 'Social Solutions' );
        if (mediatorContext && mediatorContext.records) {
            ssRec = mediatorContext.records.find((x: any) => x.recordId === ssId );
        }
    }
    // console.log('%c ssRec: %O', 'color: red;', ssRec);

    const udicciSocialSolution = useUdicciSocialSolution(ssRec);
    var { socialSolution, getSocialSolutionSetting, getSocialSolutionEngagementActions } = udicciSocialSolution;
    // console.log('%c useUdicciRecord socialSolution: %O', 'color: red;', socialSolution);
    var socialSolutionId: number = (socialSolution && socialSolution.recordId ? socialSolution.recordId : 0);
    // console.log('%c socialSolutionId: %O', 'color: red;', socialSolutionId);

    var featureId: number = (selectedFeature && selectedFeature.UdicciRecordId ? selectedFeature.UdicciRecordId : 0);
    // console.log('%c featureId: %O', 'color: red;', featureId);

    const udicciClasses = udicciStyles(theme);
    // console.log('%c udicciClasses: %O', 'color: red;', udicciClasses);

    // console.log('%c recordReference: %O', 'color: red;', recordReference);
    // var recordId = (recordReference && recordReference.recordId ? recordReference.recordId : 0);
    var recordPermissions = (recordReference && recordReference.permissions ? recordReference.permissions : null);
    // console.log('%c recordPermissions: %O', 'color: red;', recordPermissions);
    var isDirty = (recordReference && recordReference.isDirty ? true : false);
    var isSaving = ((recordReference && recordReference.isSaving) || processingRequest ? true : false);

    const changeAutoAcknowledgeValue = (evt: any) => {
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        setAutoAcknowledgeWhenStack(newValue)
    };

    const changeNewStackName = (evt: any) => {
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        setNewStackName(newValue)
    };

    const changeSelectedStack = (evt: any) => {
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        if (newAssignedStack !== newValue) setNewAssignedStack(newValue)
    };

    const applyStackSelection = () => {
        // console.log('%c applyStackSelection forNotification: %O', 'color: red;', forNotification);
        // console.log('%c applyStackSelection newStackName: %O', 'color: red;', newStackName);
        // console.log('%c applyStackSelection newAssignedStack: %O', 'color: red;', newAssignedStack);
        // console.log('%c applyStackSelection autoAcknowledgeWhenStack: %O', 'color: red;', autoAcknowledgeWhenStack);
    };

    const deleteRecord = async (settings: any | null = null) => {
        // console.log('%c useUdicciRecord deleteRecord recordReference: %O', 'color: blue;', recordReference);
        // console.log('%c useUdicciRecord deleteRecord settings: %O', 'color: blue;', settings);
        if (processingRequest) return;
        if (!recordReference) return;
        if (!recordReference.recordId) return;
        if (recordReference.isSaving) return;

        var cu = (currentUser ? currentUser : { UdicciUserId: 0 });
        var currentUserId: number = cu.UdicciUserId;
        var sp = (selectedProfile ? selectedProfile : { recordId: 0 });
        var profileId: number = sp.recordId;

        var request: DeleteRecordRequest = {
            UdicciMediatorName: record.udicciMediator,
            UdicciCommand: "Delete Record",
            SelectedUdicciProfileId: profileId,
            SocialSolutionId: socialSolutionId,
            UserId: currentUserId,
            UdicciRecordId: (recordReference.recordId ? recordReference.recordId : 0),
        }
        // console.log('%c deleteRecord request: %O', 'color: purple;', request);

        var okToContinue = true;
        if (okToContinue) {
            sendPreparedRequest(request, {
                onSuccess: deleteCompleted,
                onError: requestFailed
            });
            setProcessingRequest(true);
            recordReference.isSaving = true;
            setRecordReference(recordReference);
        } else {
            setProcessingRequest(false);
            recordReference.isSaving = false;
            setRecordReference(recordReference);
        }
    };

    const deleteCompleted = (response: any, request: any, settings: any) => {
        // console.log('%c deleteCompleted request: %O', 'color: purple;', request);
        // console.log('%c deleteCompleted settings: %O', 'color: purple;', settings);
        // console.log('%c deleteCompleted response: %O', 'color: purple;', response);

        setProcessingRequest(false);

        var savedRecord: UdicciRecord | null = null;
        if (response && response.length > 0) savedRecord = response[0];
        // console.log('%c deleteCompleted savedRecord: %O', 'color: purple;', savedRecord);
        // console.log('%c deleteCompleted recordReference: %O', 'color: purple;', recordReference);
        if (savedRecord) {
            savedRecord.attachments = recordReference.attachments;
            savedRecord.context = recordReference.context;
            savedRecord.permissions = recordReference.permissions;
            savedRecord.perspectives = recordReference.perspectives;

            setRecord(savedRecord);
        }
    };

    const onRecordContextChange = (newContext: any) => {
        // console.log('%c setRecordContext newContext: %O', 'color: red;', newContext);
        setRecordContext(newContext);
    };

    const getRecordContext = () => {
        // console.log('%c getRecordContext recordContext: %O', 'color: red;', recordContext);
        if (loadRecordContext) loadRecordContext(record, socialSolution);
    };

    const requestFailed = (response: any) => {
        // console.log('requestFailed response: %O', response);
        setProcessingRequest(false);
        setContextRetrieved(false);

        recordReference.isSaving = false;
        // console.log('%c saveCompleted savedRecord: %O', 'color: purple;', savedRecord);

        setRecord(recordReference);
        setRecordReference(recordReference);

        if (response && response.Message) setErrorMessage(response.Message);
    }

    useEffect(() => {
        var cur: UdicciRecord | null = (recordReference ? recordReference : null);
        // console.log('%c useUdicciRecord useEffect cur: %O', 'color: red; font-weight: bold;', cur);
        // console.log('%c useUdicciRecord useEffect record: %O', 'color: red; font-weight: bold;', record);
        if (record && (!cur || (cur && cur.recordId === record.recordId))) {
            setRecordReference(record);
        }
    }, [ record, recordReference ]);

    useEffect(() => {
        if (!datasets && recordContext && recordContext.records) {
            var records: any[] = recordContext.records;
            if (records && records.length > 0 && !datasets) {
                // console.log('%c useUdicciRecord useEffect records: %O', 'color: red;', records);
                var _datasets: any = {};
                if (datasets) Object.assign(_datasets, datasets);
                // console.log('%c useUdicciRecord useEffect _datasets: %O', 'color: red;', _datasets);
                records.forEach((rec: any) => {
                    // console.log('%c rec: %O', 'color: blue;', rec);
                    var recMediatorName = rec.record.udicciMediator;
                    var recParentMediatorName = rec.parentMediator;
                    var recChildMediatorName = rec.childMediator;

                    if (!_datasets[recMediatorName]) {
                        _datasets[recMediatorName] = { idx: -1, data: [], level: 0 };
                    }

                    var _level: number = 2;
                    if (recParentMediatorName) {
                        if (!_datasets[recMediatorName].parentMediators) _datasets[recMediatorName].parentMediators = [];
                        if (_datasets[recMediatorName].parentMediators.indexOf(recParentMediatorName) < 0) {
                            _datasets[recMediatorName].parentMediators.push(recParentMediatorName);
                        }

                        var dsParent: any = _datasets[recParentMediatorName];
                        // console.log('%c dsParent: %O', 'color: blue;', dsParent);
                        if (dsParent.level > 1) {
                            _level = dsParent.level + 1;
                        }
                    } else {
                        _level = 1;
                    }

                    _datasets[recMediatorName].level = _level;
                    rec.level = _level;
                    // console.log('%c _datasets: %O', 'color: blue;', _datasets);

                    _datasets[recMediatorName].data.push(rec);

                    if (_datasets[recMediatorName].idx < 0) _datasets[recMediatorName].idx = 0;
                    if (!_datasets[recMediatorName].mediator) _datasets[recMediatorName].mediator = recMediatorName;

                    if (recChildMediatorName) {
                        if (!_datasets[recMediatorName].childMediators) _datasets[recMediatorName].childMediators = [];
                        if (_datasets[recMediatorName].childMediators.indexOf(recChildMediatorName) < 0) {
                            _datasets[recMediatorName].childMediators.push(recChildMediatorName);
                        }
                    }
                });
                setDatasets(_datasets);
            }
        }
    }, [ recordContext, datasets ]);

    const setRecord = useCallback((updatedRecord: UdicciRecord, loadContext: boolean = false) => {
        // console.log('%c setRecord updatedRecord: %O', 'color: orange;', updatedRecord);

        // console.log('%c setRecord recordReference: %O', 'color: red;', recordReference);
        setRecordReference(updatedRecord);
        // console.log('%c setRecord recordReference: %O', 'color: red;', recordReference);
        udicci.updateRecord(updatedRecord);
        if (loadContext && !recordContext) getRecordContext();
        // console.log('%c setRecord recordContextFactory: %O', 'color: red;', recordContextFactory);
        if (recordContextFactory && recordContextFactory.setRecord) {
            // console.log('%c recordContextFactory.setRecord updatedRecord: %O', 'color: darkorange;', updatedRecord);
            recordContextFactory.setRecord(updatedRecord);
        }
    }, [ recordContext, recordContextFactory, udicci, getRecordContext ]);

    const changeRecordValue = (field: any | null, evt: any, secondaryValue: any = null) => {
        // console.log('%c changeRecordValue field: %O', 'color: red;', field);
        // console.log('%c changeRecordValue evt: %O', 'color: red;', evt);
        // console.log('%c changeRecordValue secondaryValue: %O', 'color: red;', secondaryValue);
        // console.log('%c changeRecordValue recordReference: %O', 'color: red;', recordReference);

        if (!field) return false;

        let fieldObject: any = getField(field);
        // console.log('%c changeRecordValue fieldObject: %O', 'color: red;', fieldObject);

        var newValue: any = null;
        if (fieldObject) {
            if (fieldObject.DataType === 'DateTime') {
                newValue = evt;
            } else if (evt && evt.target) {
                newValue = (evt.target && evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value);
            }
        } else if (evt && evt.target) {
            newValue = (evt.target && evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value);
        }
        // console.log('%c changeRecordValue newValue: %O', 'color: red;', newValue);
        if (secondaryValue && (secondaryValue instanceof Date)) newValue = secondaryValue;
        // console.log('%c changeRecordValue newValue: %O', 'color: red;', newValue);

        var adtnl: any = null;
        if (typeof field === 'object' && !Array.isArray(field) && field !== null)
            adtnl = field;
        // console.log('%c changeRecordValue adtnl: %O', 'color: red;', adtnl);

        var changeRecord: UdicciRecord | null = (recordReference ? recordReference : null);
        if (!changeRecord) return false;
        // console.log('%c changeRecordValue changeRecord: %O', 'color: red;', changeRecord);

        var isDirty: boolean = (changeRecord.isDirty ? true : false);

        var relationshipChangesJson: any[] = [];
        if (changeRecord) {
            if (adtnl && adtnl.parent) {
                var parnt: any = adtnl.parent;
                // console.log('%c changeRecordValue parnt: %O', 'color: red;', parnt);
                var parentMediator: string = (parnt.mediator ? parnt.mediator : '');
                // console.log('%c changeRecordValue parentMediator: %O', 'color: red;', parentMediator);
                var currentParentValue: number = (parnt.currentValue ? parnt.currentValue : 0);
                var newParentValue: number = (parnt.value ? parnt.value : 0);
                // console.log('%c changeRecordValue newParentValue: %O', 'color: red;', newParentValue);
                // console.log('%c changeRecordValue currentParentValue: %O', 'color: red;', currentParentValue);
                if (parentMediator && currentParentValue !== newParentValue) {
                    var relChangeJson: any = {
                        RecordMediator: changeRecord.udicciMediator,
                        RecordId: (changeRecord.recordId ? changeRecord.recordId : 0),
                        RelatedMediator: parentMediator,
                        RelatedRecordId: newParentValue,
                        Priority: -1
                    };
                    // this will need to be updated to handle many-to-many updates/adds differently
                    if (newParentValue <= 0) {  // handle Remove
                        relChangeJson.Remove = "Parent";
                        relChangeJson.RelatedRecordId = currentParentValue;
                    } else {
                        relChangeJson.Add = "Parent";
                    }
                    relationshipChangesJson.push(relChangeJson);
                }
            } else {
                var recordData = (changeRecord.data ? changeRecord.data : {});
                // console.log('%c changeRecordValue recordData: %O', 'color: red;', recordData);
                var tf = (changeRecord.keys && changeRecord.keys.title ? changeRecord.keys.title : '');
                var df = (changeRecord.keys && changeRecord.keys.description ? changeRecord.keys.description : '');
                // console.log('%c changeRecordValue recordData: %O', 'color: red;', recordData);
                if (recordData[field] !== newValue) {
                    recordData[field] = newValue;
                    // console.log('%c changeRecordValue recordData: %O', 'color: darkorange; font-weight: bold;', recordData);
                    // console.log('%c changeRecordValue recordData[%s]: %O', 'color: darkorange; font-weight: bold;', field, recordData[field]);
                    changeRecord.data = recordData;
                    isDirty = true;
                }
                if (field === tf) changeRecord.title = recordData[field];
                if (field === df) changeRecord.description = recordData[field];
                // console.log('%c changeRecordValue recordData: %O', 'color: red;', recordData);
            }
            // console.log('%c changeRecordValue changeRecord: %O', 'color: green;', changeRecord);

            if (!changeRecord.permissions && changeRecord.recordId <= 0) {
                var newPermissions: UdicciPermissions = {
                    CanView: true, CanAdd: true, CanEdit: true, CanDelete: true, CanDuplicate: true, CanProvision: true
                };
                changeRecord.permissions = newPermissions;
            }

            var recContext: any = (changeRecord.context ? changeRecord.context : null);

            // console.log('%c changeRecordValue recContext: %O', 'color: red;', recContext);
            // console.log('%c changeRecordValue relationshipChangesJson: %O', 'color: red;', relationshipChangesJson);
            if (relationshipChangesJson.length > 0) {
                // console.log('%c changeRecordValue recContext: %O', 'color: red;', recContext);
                if (!recContext) recContext = {};
                // console.log('%c changeRecordValue recContext: %O', 'color: red;', recContext);
                recContext.RelationshipChanges = relationshipChangesJson;
                // console.log('%c changeRecordValue recContext: %O', 'color: red;', recContext);
                isDirty = true;
            }
            // console.log('%c changeRecordValue recContext: %O', 'color: maroon;', recContext);
            changeRecord.context = recContext;
            // console.log('%c changeRecordValue changeRecord: %O', 'color: maroon;', changeRecord);

            // console.log('%c changeRecordValue isDirty: %O', 'color: darkorange;', isDirty);
            changeRecord.isDirty = isDirty;
            // console.log('%c changeRecordValue changeRecord.isDirty: %O', 'color: red;', changeRecord.isDirty);
            // console.log('%c changeRecordValue changeRecord: %O', 'color: orange;', changeRecord);
            setRecord(changeRecord);
            // setRecordReference(changeRecord);
        }
    };

    const engageAction = useCallback((ea: any) => {
        // console.log('%c useUdicciRecord engageAction recordReference: %O', 'color: red;', recordReference);
        // console.log('%c useUdicciRecord engageAction ea: %O', 'color: red;', ea);
        setEngagementAction(ea);
    }, []);

    const updatePerspective = useCallback((perspective: UdicciRecord) => {
        // console.log('%c useUdicciRecord updatePerspective recordReference: %O', 'color: red;', recordReference);
        // console.log('%c useUdicciRecord updatePerspective perspective: %O', 'color: red;', perspective);

        if (recordReference && perspective) {
            let perspectives: UdicciRecord[] = (recordReference.perspectives ? recordReference.perspectives : []);
            let found: boolean = false;
            if (perspectives.length > 0) {
                forEach(perspectives, function(p: UdicciRecord, i: number) {
                    // console.log('%c p: %O', 'color: purple;', p);
                    if (!found && p.recordId === 0 && p.title === perspective.title) {
                        perspectives[i] = perspective;
                        found = true;
                    }
                    if (!found && p.recordId === perspective.recordId) {
                        perspectives[i] = perspective;
                        found = true;
                    }
                });
            } 
            if (!found) {
                perspectives.push(perspective);
            }
            recordReference.perspectives = perspectives;
        }
        setRecord(recordReference);
        setRecordReference(recordReference);
    }, []);

    const updateAttachment = useCallback((attachment: UdicciRecord) => {
        // console.log('%c useUdicciRecord updateAttachment recordReference: %O', 'color: red;', recordReference);
        // console.log('%c useUdicciRecord updateAttachment attachment: %O', 'color: red;', attachment);

        if (recordReference && attachment) {
            let attachments: UdicciRecord[] = (recordReference.attachments ? recordReference.attachments : []);
            let found: boolean = false;
            if (attachments.length > 0) {
                forEach(attachments, function(p: UdicciRecord, i: number) {
                    // console.log('%c p: %O', 'color: purple;', p);
                    if (!found && p.recordId === 0 && p.title === attachment.title) {
                        attachments[i] = attachment;
                        found = true;
                    }
                    if (!found && p.recordId === attachment.recordId) {
                        attachments[i] = attachment;
                        found = true;
                    }
                });
            } 
            if (!found) {
                attachments.push(attachment);
            }
            recordReference.attachments = attachments;
        }
        setRecord(recordReference);
        setRecordReference(recordReference);
    }, []);

    // const sampleSaveRecordSettings: any = {
    //     socialSolutionId: 0,
    //     MediationSource: null, // object - sample to be shown later
    //     RelationshipChanges: null, // array of objects - sample to be shown later
    // };

    const saveRecord = async (settings: any | null = null) => {
        // console.log('%c useUdicciRecord saveRecord recordReference: %O', 'color: blue;', recordReference);
        // console.log('%c useUdicciRecord saveRecord settings: %O', 'color: blue;', settings);
        // console.log('%c useUdicciRecord saveRecord processingRequest: %O', 'color: blue;', processingRequest);
        if (processingRequest) return;
        if (!recordReference) return;
        if (recordReference.isSaving) return;
        if (!recordReference.isDirty) return;
        if (!recordReference.data) return;

        var recData = recordReference.data;
        // console.log('%c saveRecord recData: %O', 'color: purple;', recData);

        var cu = (currentUser ? currentUser : { UdicciUserId: 0 });
        var currentUserId: number = cu.UdicciUserId;
        var sp = (selectedProfile ? selectedProfile : { recordId: 0 });
        var profileId: number = sp.recordId;

        var ssId: number = socialSolutionId;
        if (settings && settings.socialSolutionId) ssId = settings.socialSolutionId;
        if (!ssId && socialSolutionId) ssId = socialSolutionId;
        if (!ssId && recData && recData.CreatedInSolutionId) ssId = recData.CreatedInSolutionId;
        // console.log('%c useUdicciRecord saveRecord ssId: %O', 'color: blue;', ssId);

        var mediationSource = '';
        if (settings && settings.MediationSource) {
            mediationSource = JSON.stringify(settings.MediationSource);
        }

        var relationshipChanges: any = null;
        if (settings && settings.RelationshipChanges) {
            // relationshipChanges = JSON.stringify(settings.RelationshipChanges);
            relationshipChanges = settings.RelationshipChanges;
        }
        if (record.context && record.context.RelationshipChanges && record.context.RelationshipChanges.length > 0) {
            let otherChanges: any[] = record.context.RelationshipChanges;
            // console.log('%c useUdicciRecord saveRecord otherChanges: %O', 'color: blue;', otherChanges);
            if (!relationshipChanges) relationshipChanges = [];
            if (otherChanges.length > 0) {
                // need to make sure we do not duplicate
                forEach(otherChanges, function(relChg: any) {
                    let relChangesCheck: any = find(relationshipChanges, (chck: any) => {
                        let mediatorsSame: boolean = (chck.RelatedMediator === relChg.RelatedMediator && chck.RecordMediator === relChg.RecordMediator ? true : false);
                        let idsSame: boolean = (chck.RelatedRecordId === relChg.RelatedRecordId && chck.RecordId === relChg.RecordId ? true : false);
                        return (mediatorsSame && idsSame ? true : false);
                    });
                    if (!relChangesCheck) relationshipChanges.push(relChg);
                });
            }
        }

        var request: SaveRecordRequest = {
            UdicciMediatorName: recordReference.udicciMediator,
            UdicciCommand: "Save Record",
            SelectedUdicciProfileId: profileId,
            SocialSolutionId: ssId,
            FeatureId: featureId,
            UserId: currentUserId,
            UdicciRecordId: (recordReference.recordId ? recordReference.recordId : 0),
            SaveData: recData,
            CreatedByUserId: (recordReference.recordId <= 0 ? currentUserId : null),
            CreatedInUdicciProfileId: (recordReference.recordId <= 0 ? profileId : null),
            MediationSource: (mediationSource !== '' ? mediationSource : null),
            RelationshipChanges: relationshipChanges
        }
        // console.log('%c saveRecord request: %O', 'color: purple;', request);

        var okToContinue = true;
        if (okToContinue) {
            sendPreparedRequest(request, {
                onSuccess: (response: any, request: any) => saveCompleted(response, request, settings),
                onError: requestFailed
            });
            setProcessingRequest(true);
            recordReference.isSaving = true;
            setRecordReference(recordReference);
            setRecord(recordReference);
        } else {
            setProcessingRequest(false);
            recordReference.isSaving = false;
            setRecordReference(recordReference);
            setRecord(recordReference);
            // console.log('%c saveRecord DISABLED request: %O', 'color: purple;', request);
        }
    };

    const saveCompleted = (response: any, request: any, settings: any) => {
        // console.log('%c saveCompleted request: %O', 'color: purple;', request);
        // console.log('%c saveCompleted settings: %O', 'color: purple;', settings);
        // console.log('%c saveCompleted response: %O', 'color: purple;', response);

        setProcessingRequest(false);

        if (!response || (response && response.success === false)) {
            recordReference.isSaving = false;
            // console.log('%c saveCompleted recordReference: %O', 'color: purple;', recordReference);

            setRecord(recordReference);
            setRecordReference(recordReference);
        } else {
            var savedRecord: UdicciRecord | null = null;
            if (response && response.length > 0) savedRecord = response[0];
            // console.log('%c saveCompleted savedRecord: %O', 'color: purple;', savedRecord);
            // console.log('%c saveCompleted recordReference: %O', 'color: purple;', recordReference);
            if (savedRecord) {
                savedRecord.attachments = recordReference.attachments;
                savedRecord.context = recordReference.context;
                savedRecord.permissions = recordReference.permissions;
                savedRecord.perspectives = recordReference.perspectives;

                if (savedRecord && savedRecord.context) {
                    if (savedRecord.context.RelationshipChanges) {
                        delete savedRecord.context["RelationshipChanges"];
                    }
                }

                savedRecord.isDirty = false;
                savedRecord.isSaving = false;
                // console.log('%c saveCompleted savedRecord: %O', 'color: purple;', savedRecord);

                setRecord(savedRecord);
                setRecordReference(savedRecord);

                // let recordIds: number[] = [];
                // recordIds.push(savedRecord.recordId);
                // let getDataRequestJson: any = {
                //     mediator: savedRecord.udicciMediator,
                //     socialSolutionId: savedRecord.data.CreatedInSolutionId,
                //     FilterByUdicciRecordIds: recordIds
                // };
                // console.log('%c saveCompleted getDataRequestJson: %O', 'color: purple;', getDataRequestJson);
                // udicci.getData(getDataRequestJson);
            }
        }

        // console.log('%c saveCompleted settings: %O', 'color: red;', settings);
        if (settings && settings.onSaveCompleted) {
            // console.log('%c saveCompleted settings: %O', 'color: green;', settings);
            settings.onSaveCompleted(response);
        }
    };


    const savePerspective = async (perspective: UdicciRecord, settings: any | null = null) => {
        // console.log('%c useUdicciRecord savePerspective recordReference: %O', 'color: blue;', recordReference);
        // console.log('%c useUdicciRecord savePerspective perspective: %O', 'color: blue;', perspective);
        // console.log('%c useUdicciRecord savePerspective settings: %O', 'color: blue;', settings);
        // console.log('%c useUdicciRecord savePerspective processingRequest: %O', 'color: blue;', processingRequest);
        if (processingRequest) return;
        if (!perspective) return;
        if (perspective.isSaving) return;
        if (!perspective.isDirty) return;
        if (!perspective.data) return;

        var recData = perspective.data;
        // console.log('%c savePerspective recData: %O', 'color: purple;', recData);

        var cu = (currentUser ? currentUser : { UdicciUserId: 0 });
        var currentUserId: number = cu.UdicciUserId;
        var sp = (selectedProfile ? selectedProfile : { recordId: 0 });
        var profileId: number = sp.recordId;

        var ssId: number = socialSolutionId;
        if (settings && settings.socialSolutionId) ssId = settings.socialSolutionId;

        var relationshipChanges = null;
        if (settings && settings.RelationshipChanges) {
            relationshipChanges = JSON.stringify(settings.RelationshipChanges);
            relationshipChanges = settings.RelationshipChanges;
        } else if (perspective.recordId <= 0) {
            relationshipChanges = JSON.stringify([{
                "Add": "Parent",
                "RecordMediator": 'Perspectives',
                "RecordId": (perspective.recordId ? perspective.recordId : 0),
                "RelatedMediator": recordReference.udicciMediator,
                "RelatedRecordId": recordReference.recordId,
                "Priority": -1
            }]);
        }

        var request: SaveRecordRequest = {
            UdicciMediatorName: perspective.udicciMediator,
            UdicciCommand: "Save Record",
            SelectedUdicciProfileId: profileId,
            SocialSolutionId: ssId,
            FeatureId: featureId,
            UserId: currentUserId,
            UdicciRecordId: (perspective.recordId ? perspective.recordId : 0),
            SaveData: recData,
            CreatedByUserId: (perspective.recordId <= 0 ? currentUserId : null),
            CreatedInUdicciProfileId: (perspective.recordId <= 0 ? profileId : null),
            MediationSource: null,
            RelationshipChanges: relationshipChanges
        }
        // console.log('%c savePerspective request: %O', 'color: purple;', request);

        var okToContinue = true;
        if (okToContinue) {
            sendPreparedRequest(request, {
                onSuccess: (response: any, request: any, addtnlData: any) => savePerspectiveCompleted(perspective, response, request, addtnlData, settings),
                onError: requestFailed
            });
            setProcessingRequest(true);
            perspective.isSaving = true;
        } else {
            setProcessingRequest(false);
            perspective.isSaving = false;
        }
        updatePerspective(perspective);
    };

    const savePerspectiveCompleted = (perspective: UdicciRecord, response: any, request: any, addtnlData: any, settings: any) => {
        // console.log('%c savePerspectiveCompleted perspective: %O', 'color: purple;', perspective);
        // console.log('%c savePerspectiveCompleted request: %O', 'color: purple;', request);
        // console.log('%c savePerspectiveCompleted response: %O', 'color: purple;', response);
        // console.log('%c savePerspectiveCompleted settings: %O', 'color: purple;', settings);
        // console.log('%c savePerspectiveCompleted addtnlData: %O', 'color: purple;', addtnlData);

        setProcessingRequest(false);

        let requiresUpdate = true;

        if (response && response.length > 0) {
            var savedPerspective: UdicciRecord | null = response[0];
            // console.log('%c savePerspectiveCompleted savedPerspective: %O', 'color: purple;', savedPerspective);
            // console.log('%c savePerspectiveCompleted recordReference: %O', 'color: purple;', recordReference);
            if (savedPerspective) {
                savedPerspective.isDirty = false;
                savedPerspective.isSaving = false;
                // console.log('%c savePerspectiveCompleted savedPerspective: %O', 'color: purple;', savedPerspective);
                updatePerspective(savedPerspective);
                requiresUpdate = false;
            }
        } else {
            // console.log('%c savePerspectiveCompleted recordReference: %O', 'color: purple;', recordReference);
        }
        // console.log('%c savePerspectiveCompleted requiresUpdate: %O', 'color: purple;', requiresUpdate);
        if (requiresUpdate) {
            perspective.isSaving = false;
            updatePerspective(perspective);
        }
        if (settings && settings.onSuccess) settings.onSuccess(response, request, addtnlData);
    };

    const saveAttachment = async (attachment: UdicciRecord, settings: any | null = null) => {
        // console.log('%c useUdicciRecord saveAttachment recordReference: %O', 'color: blue;', recordReference);
        // console.log('%c useUdicciRecord saveAttachment attachment: %O', 'color: blue;', attachment);
        // console.log('%c useUdicciRecord saveAttachment settings: %O', 'color: blue;', settings);
        // console.log('%c useUdicciRecord saveAttachment processingRequest: %O', 'color: blue;', processingRequest);
        if (processingRequest) return;
        if (!attachment) return;
        if (attachment.isSaving) return;
        if (!attachment.isDirty) return;
        if (!attachment.data) return;

        var recData = attachment.data;
        // console.log('%c saveAttachment recData: %O', 'color: purple;', recData);

        var cu = (currentUser ? currentUser : { UdicciUserId: 0 });
        var currentUserId: number = cu.UdicciUserId;
        var sp = (selectedProfile ? selectedProfile : { recordId: 0 });
        var profileId: number = sp.recordId;

        var ssId: number = socialSolutionId;
        if (settings && settings.socialSolutionId) ssId = settings.socialSolutionId;

        var relationshipChanges = null;
        if (settings && settings.RelationshipChanges) {
            relationshipChanges = JSON.stringify(settings.RelationshipChanges);
            relationshipChanges = settings.RelationshipChanges;
        } else if (attachment.recordId <= 0) {
            relationshipChanges = JSON.stringify([{
                "Add": "Parent",
                "RecordMediator": 'Attachments',
                "RecordId": (attachment.recordId ? attachment.recordId : 0),
                "RelatedMediator": recordReference.udicciMediator,
                "RelatedRecordId": recordReference.recordId,
                "Priority": -1
            }]);
        }

        var request: SaveRecordRequest = {
            UdicciMediatorName: attachment.udicciMediator,
            UdicciCommand: "Save Record",
            SelectedUdicciProfileId: profileId,
            SocialSolutionId: ssId,
            FeatureId: featureId,
            UserId: currentUserId,
            UdicciRecordId: (attachment.recordId ? attachment.recordId : 0),
            SaveData: recData,
            CreatedByUserId: (attachment.recordId <= 0 ? currentUserId : null),
            CreatedInUdicciProfileId: (attachment.recordId <= 0 ? profileId : null),
            MediationSource: null,
            RelationshipChanges: relationshipChanges
        }
        // console.log('%c saveAttachment request: %O', 'color: purple;', request);

        var okToContinue = true;
        if (okToContinue) {
            sendPreparedRequest(request, {
                onSuccess: (response: any, request: any, addtnlData: any) => saveAttachmentCompleted(attachment, response, request, addtnlData, settings),
                onError: requestFailed
            });
            setProcessingRequest(true);
            attachment.isSaving = true;
        } else {
            setProcessingRequest(false);
            attachment.isSaving = false;
        }
        updateAttachment(attachment);
    };

    const saveAttachmentCompleted = (attachment: UdicciRecord, response: any, request: any, addtnlData: any, settings: any) => {
        // console.log('%c saveAttachmentCompleted attachment: %O', 'color: purple;', attachment);
        // console.log('%c saveAttachmentCompleted request: %O', 'color: purple;', request);
        // console.log('%c saveAttachmentCompleted response: %O', 'color: purple;', response);
        // console.log('%c saveAttachmentCompleted settings: %O', 'color: purple;', settings);
        // console.log('%c saveAttachmentCompleted addtnlData: %O', 'color: purple;', addtnlData);

        setProcessingRequest(false);

        let requiresUpdate = true;

        if (response && response.length > 0) {
            var savedAttachment: UdicciRecord | null = response[0];
            // console.log('%c saveAttachmentCompleted savedAttachment: %O', 'color: purple;', savedAttachment);
            // console.log('%c saveAttachmentCompleted recordReference: %O', 'color: purple;', recordReference);
            if (savedAttachment) {
                savedAttachment.isDirty = false;
                savedAttachment.isSaving = false;
                // console.log('%c saveAttachmentCompleted savedAttachment: %O', 'color: purple;', savedAttachment);
                updateAttachment(savedAttachment);
                requiresUpdate = false;
            }
        } else {
            // console.log('%c saveAttachmentCompleted recordReference: %O', 'color: purple;', recordReference);
        }
        // console.log('%c saveAttachmentCompleted requiresUpdate: %O', 'color: purple;', requiresUpdate);
        if (requiresUpdate) {
            attachment.isSaving = false;
            updateAttachment(attachment);
        }
        if (settings && settings.onSuccess) settings.onSuccess(response, request, addtnlData);
    };

    const handleRecordAction = (evt: any, options: any) => {
        // console.log('%c handleRecordAction evt: %O', 'color: red;', evt);
        // console.log('%c handleRecordAction options: %O', 'color: red;', options);
        // console.log('%c handleRecordAction recordReference: %O', 'color: red;', recordReference);

        if (options && options.listProps && (options.action === 'show engagement menu' || options.action === 'hide engagement menu')) {
            let onToggleAdditionalEngagements: any = (options.listProps.onToggleAdditionalEngagements ? options.listProps.onToggleAdditionalEngagements : null);
            // console.log('%c useUdicciRecord onToggleAdditionalEngagements: %O', 'color: blue;', onToggleAdditionalEngagements);
            if (onToggleAdditionalEngagements) onToggleAdditionalEngagements({ action: options.action });
        } else {
            var engagementActions: any[] = getSocialSolutionEngagementActions({});
            // console.log('%c handleRecordAction engagementActions: %O', 'color: purple;', engagementActions);

            var ea: any = engagementActions.find((x: any) => x.action === options.action );
            // console.log('%c handleRecordAction ea: %O', 'color: purple;', ea);
            engageAction((ea ? ea : null));
        }
    };

    const getListElement = (listProps: any = {}) => {
        // console.log('%c useUdicciRecord getListElement recordReference: %O', 'color: blue;', recordReference);
        // console.log('%c useUdicciRecord getListElement listProps: %O', 'color: blue;', listProps);

        var record: any = recordReference;
        var keys: any = record.keys;
        // console.log('%c useUdicciRecord getListElement record: %O', 'color: blue;', record);
        // console.log('%c useUdicciRecord getListElement keys: %O', 'color: blue;', keys);

        var fields: any = null;
        // var virtualFields: any = null;
        // console.log('udicciMediator: %O', udicciMediator);
        if (udicciMediator) {
            fields = (udicciMediator.UdicciMediatorFields ? udicciMediator.UdicciMediatorFields : null);
            // virtualFields = (udicciMediator.VirtualMediatorFields ? udicciMediator.VirtualMediatorFields : null);
        }
        // console.log('%c getListElement fields: %O', 'color: green;', fields);
        // console.log('%c getListElement virtualFields: %O', 'color: green;', virtualFields);

        // let onShowRecordForm: any = (listProps && listProps.onShowForm ? listProps.onShowForm : null);
        // let onDeleteRecord: any = (listProps && listProps.onDeleteRecord ? listProps.onDeleteRecord : null);
        let onRecordAction: any = (listProps && listProps.onRecordAction ? listProps.onRecordAction : handleRecordAction);
        // console.log('%c useUdicciRecord onRecordAction: %O', 'color: blue;', onRecordAction);

        var titleElement: any = null;
        var descriptionElement = null;
        var mainDescriptionElement = null;
        var subheaderElement = null;
        var cardContentElement = null;
        var cardActionsElement = null;
        var listType: string = (listProps && listProps.listType ? listProps.listType : 'Card Grid');
        let layout: any = (listProps && listProps.layout ? listProps.layout : null);
        // console.log('%c getListElement layout: %O', 'color: maroon;', layout);
        let showEngagementMenu: boolean = (listProps && listProps.showEngagementMenu ? true : false);
        // console.log('%c getListElement showEngagementMenu: %O', 'color: maroon;', showEngagementMenu);

        let listSettings: any = (listProps && listProps.listSettings ? listProps.listSettings : null);
        // console.log('%c getListElement listSettings: %O', 'color: maroon;', listSettings);
        if (!layout && listSettings) {
            layout = (listSettings && listSettings.layout ? listSettings.layout : layout);
        }

        let showPerspectives: boolean = (layout && layout.showPerspectives ? true : false);
        // console.log('%c getListElement showPerspectives: %O', 'color: maroon;', showPerspectives);
        let showAttachments: boolean = (layout && layout.showAttachments ? true : false);
        // console.log('%c getListElement showAttachments: %O', 'color: maroon;', showAttachments);
        let showActivity: boolean = (layout && layout.showActivity ? true : false);
        // console.log('%c getListElement showActivity: %O', 'color: maroon;', showActivity);
        // if (showPerspectives || showAttachments || showActivity) showEngagementMenu = true;
        // console.log('%c getListElement showEngagementMenu: %O', 'color: maroon;', showEngagementMenu);

        let layoutFooter: any = (layout && layout.footer ? layout.footer : null);
        // console.log('%c getListElement layoutFooter: %O', 'color: maroon;', layoutFooter);
        let layoutPrimary: any = (layout && layout.primary ? layout.primary : null);
        // console.log('%c getListElement layoutPrimary: %O', 'color: maroon;', layoutPrimary);
        let layoutSecondary: any = (layout && layout.secondary ? layout.secondary : null);
        // console.log('%c getListElement layoutSecondary: %O', 'color: maroon;', layoutSecondary);
        let layoutTitle: any = (layout && layout.title ? layout.title : null);
        // console.log('%c getListElement layoutTitle: %O', 'color: maroon;', layoutTitle);
        
        let footerLayoutFields: any = (layoutFooter && layoutFooter.layoutFields ? layoutFooter.layoutFields : null);
        // console.log('%c getListElement footerLayoutFields: %O', 'color: maroon;', footerLayoutFields);
        let primaryLayoutFields: any = (layoutPrimary && layoutPrimary.layoutFields ? layoutPrimary.layoutFields : null);
        // console.log('%c getListElement primaryLayoutFields: %O', 'color: maroon;', primaryLayoutFields);
        let secondaryLayoutFields: any = (layoutSecondary && layoutSecondary.layoutFields ? layoutSecondary.layoutFields : null);
        // console.log('%c getListElement secondaryLayoutFields: %O', 'color: maroon;', secondaryLayoutFields);
        let titleLayoutFields: any = (layoutTitle && layoutTitle.layoutFields ? layoutTitle.layoutFields : null);
        // console.log('%c getListElement titleLayoutFields: %O', 'color: maroon;', titleLayoutFields);

        let layoutConfig: any = (layout && layout.config ? layout.config : null);
        // console.log('%c getListElement layoutConfig: %O', 'color: maroon;', layoutConfig);

        // let tableConfig: any = (layoutConfig && layoutConfig.table ? layoutConfig.table : null);
        // // console.log('%c tableConfig: %O', 'color: blue;', tableConfig);
        // let gridConfig: any = (layoutConfig && layoutConfig.grid ? layoutConfig.grid : null);
        // // console.log('%c gridConfig: %O', 'color: blue;', gridConfig);
        // let listConfig: any = (layoutConfig && layoutConfig.list ? layoutConfig.list : null);
        // // console.log('%c listConfig: %O', 'color: blue;', listConfig);
    
        listType = (layout && layout.type ? layout.type : listType);
        // console.log('%c getListElement listType: %O', 'color: maroon;', listType);

        let layoutConfigSettings: any = null;
        if (layoutConfig) {
            if (layout.type === 'grid') {
                listType = 'Card Grid';
                if (layoutConfig.grid) layoutConfigSettings = layoutConfig.grid;
            }
            if (layout.type === 'list') {
                listType = 'List';
                if (layoutConfig.list) layoutConfigSettings = layoutConfig.list;
            }
            if (layout.type === 'table') {
                listType = 'Table';
                if (layoutConfig.table) layoutConfigSettings = layoutConfig.table;
            }
            // console.log('%c getListElement listType: %O', 'color: red;', listType);
            // console.log('%c getListElement layoutConfigSettings: %O', 'color: red;', layoutConfigSettings);
        }
        // console.log('%c getListElement layoutConfigSettings: %O', 'color: blue;', layoutConfigSettings);
    
        const getSectionContentItemDisplay = (sectionContentName: string = '', contentItem: any, contentItemIndex: number) => {
            // console.log('%c getSectionContentItemDisplay sectionContentName: %O', 'color: maroon;', sectionContentName);
            // console.log('%c getSectionContentItemDisplay contentItem: %O', 'color: maroon;', contentItem);
            // console.log('%c getSectionContentItemDisplay contentItemIndex: %O', 'color: maroon;', contentItemIndex);

            if (!sectionContentName || !contentItem) return null;
    
            let sectionContentElement: any = null;
    
            if (contentItem) {
                // console.log('%c getSectionContentItemDisplay contentItem: %O', 'color: orange;', contentItem);
                let contentField: any = (contentItem.field ? contentItem.field : '');
                let contentSize: any = (contentItem.size ? contentItem.size : 'small');
                let contentVariant: any = (contentItem.variant ? contentItem.variant : 'contained');
                let contentColor: any = (contentItem.color ? contentItem.color : '');
                let contentComponent: any = (contentItem.component ? contentItem.component : 'div');
                let customValue: any = (contentItem.customValue ? contentItem.customValue : '');
                let contentLabel: any = (contentItem.label ? contentItem.label : '');
                let contentAction: any = (contentItem.action ? contentItem.action : '');

                if (sectionContentName === 'card.actions') {
                    let buttonSettings: any = {
                        size: contentSize,
                        variant: contentVariant,
                        color: (contentColor ? contentColor : 'engagementButton1')
                    };
                    // console.log('%c getSectionContentItemDisplay buttonSettings: %O', 'color: orange;', buttonSettings);
                    // console.log('%c getSectionContentItemDisplay contentAction: %O', 'color: orange;', contentAction);
                    // console.log('%c getSectionContentItemDisplay onRecordAction: %O', 'color: orange;', onRecordAction);
                    if (onRecordAction) buttonSettings.onClick = (evt: any) => onRecordAction(evt, { action: contentAction });
                    // console.log('%c getSectionContentItemDisplay buttonSettings: %O', 'color: maroon;', buttonSettings);
                    sectionContentElement = (<Button {...buttonSettings}>{contentLabel}</Button>);
                } else {
                    let fieldValue: any = '';
                    if (contentField.indexOf('.it') >= 0) {
                        switch (contentField) {
                            case '.it.space.single':
                                fieldValue = (<Fragment>&nbsp;</Fragment>);
                                break;
                            case '.it.space.triple':
                                fieldValue = (<Fragment>&nbsp;&nbsp;&nbsp;</Fragment>);
                                break;
                            case '.it.custom.value':
                                fieldValue = (<Fragment>{customValue}</Fragment>);
                                break;
                            default:
                                fieldValue = '';
                                break;
                        }
                    } else if (record && record.data && record.data[contentField] !== undefined) {
                        // console.log('%c getSectionContentItemDisplay record: %O', 'color: black;', record);
                        fieldValue = record.data[contentField];
                    }
                    // console.log('%c getSectionContentItemDisplay fieldValue: %O', 'color: black;', fieldValue);

                    if (fieldValue) {
                        if (contentLabel) {
                            sectionContentElement = (
                                <Fragment>
                                    <Typography variant={contentVariant} color={contentColor} component={'span'}>{contentLabel}</Typography>
                                    <Typography variant={contentVariant} color={contentColor} component={'span'}>{fieldValue}</Typography>
                                </Fragment>
                            );
                        } else {
                            sectionContentElement = ( <Typography variant={contentVariant} color={contentColor} component={contentComponent}>{fieldValue}</Typography> );
                        }
                    }
                }
            }
            return sectionContentElement;
        };

        const getSectionContentItemsDisplay = (sectionContentName: string = '', contentItems: any[], showForm: boolean = false) => {
            // console.log('%c getSectionContentItemsDisplay sectionContentName: %O', 'color: teal;', sectionContentName);
            // console.log('%c getSectionContentItemsDisplay contentItems: %O', 'color: teal;', contentItems);
            // console.log('%c getSectionContentItemsDisplay showForm: %O', 'color: teal;', showForm);

            // console.log('%c getSectionContentItemsDisplay selectedContentSection: %O', 'color: teal;', selectedContentSection);
            // console.log('%c getSectionContentItemsDisplay select', 'color: teal;', selectedContentItem);
            if ((!sectionContentName) || !contentItems) return null;

            let displayItems: any[] = [];
            forEach(contentItems, (contentItem: any, idx: number) => {
                // console.log('%c contentItem: %O', 'color: teal;', contentItem);

                let itemIsVisible: boolean = true;
                if (contentItem.visible !== undefined) itemIsVisible = contentItem.visible;
                // console.log('%c itemIsVisible: %O', 'color: teal;', itemIsVisible);
                if (!itemIsVisible) return true;

                let ciKey: string = sectionContentName + '.content.item.' + idx.toString();
                // let displayText: string = '';
                // if (contentItem && contentItem.label) displayText = contentItem.label;
                // if (!displayText) displayText = 'Content Item ' + (idx + 1).toString() + ' for ' + sectionContentName;
                let contentItemContent: any = getSectionContentItemDisplay(sectionContentName, contentItem, idx);
    
                // let itemContent: any = null;
                // console.log('%c showForm: %O', 'color: teal;', showForm);
                // console.log('%c sectionContentName: %O', 'color: teal;', sectionContentName);
                // console.log('%c selectedContentSection: %O', 'color: teal;', selectedContentSection);
                // if (showForm === true || sectionContentName === selectedContentSection) {
                //     if (selectedContentItem === idx) {
                //         // itemContent = getSectionContentItemsForm(sectionContentName, contentItem, idx);
                //     } else {
                //         itemContent = contentItemContent;
                //     }
                // } else {
                //     itemContent = contentItemContent;
                // }
                displayItems.push(<Fragment key={ciKey}>{contentItemContent}</Fragment>);
            });
            // console.log('%c displayItems: %O', 'color: maroon;', displayItems);
            if (displayItems.length > 0) {
                return (
                    <Box sx={{ width: '100%' }}> {displayItems} </Box>
                );
            } else {
                return null;
            }
        };

        let hasLayout: boolean = false;
        var fieldColumnElements: any[] = [];
        var headerElements: any[] = [];
        var contentElements: any[] = [];
        var subContentElements: any[] = [];
        var footerElements: any[] = [];
        var fieldCount: number = 0;
        let sortedHeaderElements: any = null;
        let sortedContentElements: any = null;

        if (layoutConfigSettings) {
            // console.log('%c getListElement layoutConfigSettings: %O', 'color: blue;', layoutConfigSettings);
            let settings: any = (layoutConfigSettings.settings ? layoutConfigSettings.settings : null);
            // console.log('%c getListElement settings: %O', 'color: maroon;', settings);
            // console.log('%c getListElement listType: %O', 'color: maroon;', listType);
            if (listType.toLowerCase() === 'card grid') {
                // let justifyContent: any = (settings && settings.justifyContent ? settings.justifyContent : null);
                // console.log('%c getListElement justifyContent: %O', 'color: maroon;', justifyContent);
                // let spacing: any = (settings && settings.spacing ? settings.spacing : null);
                // console.log('%c getListElement spacing: %O', 'color: maroon;', spacing);
                let cardSettings: any = (settings && settings.card ? settings.card : null);
                // console.log('%c getListElement cardSettings: %O', 'color: maroon;', cardSettings);

                let titleSettings: any = (cardSettings && cardSettings.title ? cardSettings.title : null);
                // console.log('%c getListElement titleSettings: %O', 'color: maroon;', titleSettings);
                let titleContent: any = (titleSettings && titleSettings.content ? titleSettings.content : null);
                // console.log('%c getListElement titleContent: %O', 'color: maroon;', titleContent);
                let titleContentItems: any = (titleContent && titleContent.items ? titleContent.items : null);
                // console.log('%c getListElement titleContentItems: %O', 'color: maroon;', titleContentItems);

                let titleContentItemElements: any = getSectionContentItemsDisplay('card.title', titleContentItems, false);
                if (titleContentItemElements) titleElement = titleContentItemElements;

                let subheaderSettings: any = (cardSettings && cardSettings.subheader ? cardSettings.subheader : null);
                // console.log('%c getListElement subheaderSettings: %O', 'color: maroon;', subheaderSettings);
                let subheaderContent: any = (subheaderSettings && subheaderSettings.content ? subheaderSettings.content : null);
                // console.log('%c getListElement subheaderContent: %O', 'color: maroon;', subheaderContent);
                let subheaderContentItems: any = (subheaderContent && subheaderContent.items ? subheaderContent.items : null);
                // console.log('%c getListElement subheaderContentItems: %O', 'color: maroon;', subheaderContentItems);

                let subheaderContentItemElements: any = getSectionContentItemsDisplay('card.subheader', subheaderContentItems, false);
                if (subheaderContentItemElements) subheaderElement = subheaderContentItemElements;

                let cardContentSettings: any = (cardSettings && cardSettings.content ? cardSettings.content : null);
                // console.log('%c getListElement cardContentSettings: %O', 'color: maroon;', cardContentSettings);
                let cardContent: any = (cardContentSettings && cardContentSettings.content ? cardContentSettings.content : null);
                // console.log('%c getListElement cardContent: %O', 'color: maroon;', cardContent);
                let cardContentItems: any = (cardContent && cardContent.items ? cardContent.items : null);
                // console.log('%c getListElement cardContentItems: %O', 'color: maroon;', cardContentItems);

                let cardContentItemElements: any = getSectionContentItemsDisplay('card.content', cardContentItems, false);
                // console.log('%c getListElement cardContentItemElements: %O', 'color: maroon;', cardContentItemElements);
                if (cardContentItemElements) cardContentElement = cardContentItemElements;
                // console.log('%c getListElement cardContentElement: %O', 'color: maroon;', cardContentElement);

                let cardActionsSettings: any = (cardSettings && cardSettings.actions ? cardSettings.actions : null);
                // console.log('%c getListElement cardActionsSettings: %O', 'color: maroon;', cardActionsSettings);
                let cardActions: any = (cardActionsSettings && cardActionsSettings.content ? cardActionsSettings.content : null);
                // console.log('%c getListElement cardActions: %O', 'color: maroon;', cardActions);
                let cardActionsItems: any = (cardActions && cardActions.items ? cardActions.items : null);
                // console.log('%c getListElement cardActionsItems: %O', 'color: maroon;', cardActionsItems);

                let cardActionsItemElements: any = getSectionContentItemsDisplay('card.actions', cardActionsItems, false);
                if (cardActionsItemElements) cardActionsElement = cardActionsItemElements;

                hasLayout = true;
            } else if (listType.toLowerCase() === 'table') {
                // console.log('%c getListElement layoutConfigSettings: %O', 'color: red;', layoutConfigSettings);
                let tableColumns: any = (layoutConfigSettings && layoutConfigSettings.columns ? layoutConfigSettings.columns : []);
                // console.log('%c getListElement tableColumns: %O', 'color: red;', tableColumns);
                // console.log('%c getListElement recData: %O', 'color: red;', recData);

                forEach(tableColumns, (tblColumn: any) => {
                    // console.log('%c tblColumn: %O', 'color: hotpink;', tblColumn);
                    let cell: any = (tblColumn.cell ? tblColumn.cell : null);
                    // console.log('%c cell: %O', 'color: hotpink;', cell);
                    let cellVariant: string = (cell && cell.variant ? cell.variant : 'caption');
                    // console.log('%c cellVariant: %O', 'color: blue;', cellVariant);

                    fieldCount++;
                    let jsonKey: string = cell.field;
                    let displayElementProps: any = null;
                    let fldDisplayElement: any = null;

                    let field: any = null;
                    if (fields) {
                        var fieldCheck = fields.find((x: any) => x.JsonFieldName === jsonKey );
                        if (fieldCheck) field = fieldCheck;
                    }
                    // console.log('%c field: %O', 'color: green;', field);

                    displayElementProps = {
                        key: 'udicciRecord.display.header.element.' + jsonKey + '.' + fieldCount.toString(),
                        component: (cell.component ? cell.component : null),
                        variant: cellVariant,
                        // color: cellColor,
                        gutterBottom: false,
                        // sx: udicciClasses.contentElement
                    };
                    if (field && field.JsonFieldName) {
                        let fieldDisplayProps: any = {
                            showHelp: showHelp,
                            overflowAtOneLine: true,
                            readonly: true,
                            hideEmptyFields: true,
                            toggleFields: true,
                            fieldToggled: false,
                            onToggleField: null, // (evt: any) => fieldToggled(field),
                            record: record,
                            key: displayElementProps.key,
                            displaySettings: pfld,
                            udicciMediator: udicciMediator,
                            field: (field ? field : pfld)
                        };
                        // console.log('%c fieldDisplayProps: %O', 'color: green;', fieldDisplayProps);
                        fldDisplayElement = ( <FieldDisplay {...fieldDisplayProps} /> );
                    } else {
                        fldDisplayElement = (
                            <Typography {...displayElementProps}>
                                {recData && recData[jsonKey]}
                            </Typography>
                        );
                    }

                    if (listType.toLowerCase() === 'table') {
                        fieldColumnElements.push({
                            field: cell,
                            element: fldDisplayElement,
                            displayOrder: (cell.order ? cell.order : (parseInt(tidxFld, 10) + 1))
                        });
                    } else {
                        headerElements.push({
                            field: cell,
                            element: fldDisplayElement,
                            displayOrder: (cell.order ? cell.order : (parseInt(tidxFld, 10) + 1))
                        });
                    }
                })

                hasLayout = true;
            }
        } else if (titleLayoutFields || primaryLayoutFields || secondaryLayoutFields) {
            // console.log('%c getListElement titleLayoutFields: %O', 'color: blue;', titleLayoutFields);
            hasLayout = true;

            if (titleLayoutFields) {
                for (var [tidxFld, tfld] of Object.entries<any>(titleLayoutFields)) {
                    // console.log('%c tidxFld: %O', 'color: green;', tidxFld);
                    // console.log('%c tfld: %O', 'color: green;', tfld);
        
                    fieldCount++;
                    let jsonKey: string = tfld.JsonFieldName;
                    let displayElementProps: any = null;
                    let fldDisplayElement: any = null;
                    displayElementProps = {
                        key: 'udicciRecord.display.header.element.' + jsonKey + '.' + fieldCount.toString(),
                        component: (tfld.component ? tfld.component : null),
                        variant: (tfld.variant ? tfld.variant : 'subtitle2'),
                        // color: (tfld.color ? tfld.color : 'textPrimary'),
                        gutterBottom: false,
                        sx: udicciClasses.contentElement
                    };
                    fldDisplayElement = (
                        <Typography {...displayElementProps}>
                            {recData && recData[jsonKey]}
                        </Typography>
                    );

                    if (listType.toLowerCase() === 'table') {
                        fieldColumnElements.push({
                            field: tfld,
                            element: fldDisplayElement,
                            displayOrder: (tfld.order ? tfld.order : (parseInt(tidxFld, 10) + 1))
                        });
                    } else {
                        headerElements.push({
                            field: tfld,
                            element: fldDisplayElement,
                            displayOrder: (tfld.order ? tfld.order : (parseInt(tidxFld, 10) + 1))
                        });
                    }
                }

            }

            if (primaryLayoutFields) {
                for (var [pidxFld, pfld] of Object.entries<any>(primaryLayoutFields)) {
                    // console.log('%c pidxFld: %O', 'color: green;', pidxFld);
                    // console.log('%c pfld: %O', 'color: green;', pfld);
        
                    fieldCount++;
                    let jsonKey: string = pfld.JsonFieldName;
                    let displayElementProps: any = null;
                    let fldDisplayElement: any = null;

                    let field: any = null;
                    if (fields) {
                        var fieldCheck = fields.find((x: any) => x.JsonFieldName === jsonKey );
                        if (fieldCheck) field = fieldCheck;
                    }
                    // console.log('%c field: %O', 'color: green;', field);

                    displayElementProps = {
                        key: 'udicciRecord.display.content.element.' + jsonKey + '.' + fieldCount.toString(),
                        component: (pfld.component ? pfld.component : null),
                        variant: (pfld.variant ? pfld.variant : 'caption'),
                        // color: (pfld.color ? pfld.color : 'textPrimary'),
                        gutterBottom: false
                    };

                    let fieldDisplayProps: any = {
                        showHelp: showHelp,
                        readonly: true,
                        hideEmptyFields: true,
                        toggleFields: true,
                        fieldToggled: false,
                        onToggleField: null, // (evt: any) => fieldToggled(field),
                        record: record,
                        key: displayElementProps.key,
                        displaySettings: pfld,
                        udicciMediator: udicciMediator,
                        field: (field ? field : pfld)
                    };
                    // console.log('%c fieldDisplayProps: %O', 'color: green;', fieldDisplayProps);
                    fldDisplayElement = ( <FieldDisplay {...fieldDisplayProps} /> );
        
                    if (listType.toLowerCase() === 'Table') {
                        fieldColumnElements.push({
                            field: pfld,
                            element: fldDisplayElement,
                            displayOrder: (pfld.order ? pfld.order : (parseInt(pidxFld, 10) + 1))
                        });
                    } else {
                        contentElements.push({
                            field: pfld,
                            element: fldDisplayElement,
                            displayOrder: (pfld.order ? pfld.order : (parseInt(pidxFld, 10) + 1))
                        });
                    }
                }
            }

            if (secondaryLayoutFields) {
                for (var [sidxFld, sfld] of Object.entries<any>(secondaryLayoutFields)) {
                    // console.log('%c sidxFld: %O', 'color: green;', sidxFld);
                    // console.log('%c sfld: %O', 'color: green;', sfld);
        
                    fieldCount++;
                    let jsonKey: string = sfld.JsonFieldName;
                    let displayElementProps: any = null;
                    let fldDisplayElement: any = null;
                    displayElementProps = {
                        key: 'udicciRecord.display.sub.content.element.' + jsonKey + '.' + fieldCount.toString(),
                        component: (sfld.component ? sfld.component : null),
                        variant: (sfld.variant ? sfld.variant : 'caption'),
                        // color: (sfld.color ? sfld.color : 'textPrimary'),
                        gutterBottom: false
                    };
                    fldDisplayElement = (
                        <Typography {...displayElementProps}>
                            {recData && recData[jsonKey]}
                        </Typography>
                    );
                    if (listType.toLowerCase() === 'table') {
                        fieldColumnElements.push({
                            field: sfld,
                            element: fldDisplayElement,
                            displayOrder: (sfld.order ? sfld.order : (parseInt(sidxFld, 10) + 1))
                        });
                    } else {
                        subContentElements.push({
                            field: sfld,
                            element: fldDisplayElement,
                            displayOrder: (sfld.order ? sfld.order : (parseInt(sidxFld, 10) + 1))
                        });
                    }
                }
            }

            if (footerLayoutFields) {
                for (var [fidxFld, ffld] of Object.entries<any>(footerLayoutFields)) {
                    // console.log('%c fidxFld: %O', 'color: green;', fidxFld);
                    // console.log('%c ffld: %O', 'color: green;', ffld);
        
                    fieldCount++;
                    let jsonKey: string = ffld.JsonFieldName;
                    let displayElementProps: any = null;
                    let fldDisplayElement: any = null;
                    displayElementProps = {
                        key: 'udicciRecord.display.footer.element.' + jsonKey + '.' + fieldCount.toString(),
                        component: (ffld.component ? ffld.component : null),
                        variant: (ffld.variant ? ffld.variant : 'caption'),
                        color: (ffld.color ? ffld.color : 'textPrimary'),
                        gutterBottom: false
                    };
                    fldDisplayElement = (
                        <Typography {...displayElementProps}>
                            {recData && recData[jsonKey]}
                        </Typography>
                    );
                    if (listType.toLowerCase() === 'table') {
                        fieldColumnElements.push({
                            field: ffld,
                            element: fldDisplayElement,
                            displayOrder: (ffld.order ? ffld.order : (parseInt(fidxFld, 10) + 1))
                        });
                    } else {
                        footerElements.push({
                            field: ffld,
                            element: fldDisplayElement,
                            displayOrder: (ffld.order ? ffld.order : (parseInt(fidxFld, 10) + 1))
                        });
                    }
                }
            }

            sortedHeaderElements = headerElements.sort((a: any,b: any) => {
                if (a.displayOrder < b.displayOrder) return -1;  // ascending
                if (a.displayOrder > b.displayOrder) return 1;  // descending
                return 0 //default return value (no sorting)
            });
    
            if (sortedHeaderElements.length > 0) {
                titleElement = (
                    <Fragment>
                        {
                            sortedHeaderElements.map(function(hdrElement: any) {
                                // console.log('%c hdrElement: %O', 'color: hotpink;', hdrElement);
                                return hdrElement.element;
                            })
                        }
                    </Fragment>
                );
            }
    
            sortedContentElements = contentElements.sort((a: any,b: any) => {
                if (a.displayOrder < b.displayOrder) return -1;  // ascending
                if (a.displayOrder > b.displayOrder) return 1;  // descending
                return 0 //default return value (no sorting)
            });
    
            var sortedSubContentElements = subContentElements.sort((a: any,b: any) => {
                if (a.displayOrder < b.displayOrder) return -1;  // ascending
                if (a.displayOrder > b.displayOrder) return 1;  // descending
                return 0 //default return value (no sorting)
            });
    
            var sortedFooterElements = footerElements.sort((a: any,b: any) => {
                if (a.displayOrder < b.displayOrder) return -1;  // ascending
                if (a.displayOrder > b.displayOrder) return 1;  // descending
                return 0 //default return value (no sorting)
            });
    
            if (sortedContentElements.length > 0) {
                mainDescriptionElement = (
                    <Fragment>
                        {
                            sortedContentElements.map(function(cntntElement: any) {
                                // console.log('%c cntntElement: %O', 'color: hotpink;', cntntElement);
                                if (cntntElement.field && cntntElement.field.JsonFieldName.startsWith('CreatedBy')) {
                                    return getRecordAvatarElement({ display: 'creator' });
                                } else if (cntntElement.field && cntntElement.field.JsonFieldName.startsWith('ModifiedBy')) {
                                    return getRecordAvatarElement({ display: 'modifier' });
                                } else {
                                    return cntntElement.element;
                                }
                            })
                        }
                        {
                            sortedSubContentElements.map(function(subcntntElement: any) {
                                // console.log('%c subcntntElement: %O', 'color: hotpink;', subcntntElement);
                                if (subcntntElement.field && subcntntElement.field.JsonFieldName.startsWith('CreatedBy')) {
                                    return getRecordAvatarElement({ display: 'creator' });
                                } else if (subcntntElement.field && subcntntElement.field.JsonFieldName.startsWith('ModifiedBy')) {
                                    return getRecordAvatarElement({ display: 'modifier' });
                                } else {
                                    return subcntntElement.element;
                                }
                            })
                        }
                        {
                            sortedFooterElements.map(function(footerElement: any) {
                                // console.log('%c footerElement: %O', 'color: hotpink;', footerElement);
                                if (footerElement.field && footerElement.field.JsonFieldName.startsWith('CreatedBy')) {
                                    return getRecordAvatarElement({ display: 'creator' });
                                } else if (footerElement.field && footerElement.field.JsonFieldName.startsWith('ModifiedBy')) {
                                    return getRecordAvatarElement({ display: 'modifier' });
                                } else {
                                    return footerElement.element;
                                }
                            })
                        }
                    </Fragment>
                );
            }
        } else if (listProps && listProps.listFields) {
            // console.log('%c getListElement listProps: %O', 'color: blue;', listProps);
            for (var [jsonKey, fld] of Object.entries<any>(listProps.listFields)) {
                // console.log('%c jsonKey: %O', 'color: green;', jsonKey);
                // console.log('%c fld: %O', 'color: green;', fld);
    
                fieldCount++;
                let displayElementProps: any = null;
                let fldDisplayElement: any = null;
                if (fld.header === true) {
                    displayElementProps = {
                        key: 'udicciRecord.display.header.element.' + jsonKey + '.' + fieldCount.toString(),
                        component: (fld.component ? fld.component : null),
                        variant: (fld.variant ? fld.variant : 'subtitle2'),
                        color: (fld.color ? fld.color : 'textPrimary'),
                        gutterBottom: false,
                        sx: udicciClasses.contentElement
                    };
                    fldDisplayElement = (
                        <Typography {...displayElementProps}>
                            {recData && recData[jsonKey]}
                        </Typography>
                    );
    
                    if (listType.toLowerCase() === 'table') {
                        fieldColumnElements.push({
                            field: fld,
                            element: fldDisplayElement,
                            displayOrder: fld.order
                        });
                    } else {
                        headerElements.push({
                            field: fld,
                            element: fldDisplayElement,
                            displayOrder: fld.order
                        });
                    }
                } else {
                    displayElementProps = {
                        key: 'udicciRecord.display.content.element.' + jsonKey + '.' + fieldCount.toString(),
                        component: (fld.component ? fld.component : null),
                        variant: (fld.variant ? fld.variant : 'caption'),
                        // color: (fld.color ? fld.color : 'textPrimary'),
                        gutterBottom: false
                    };
                    fldDisplayElement = (
                        <Typography {...displayElementProps}>
                            {recData && recData[jsonKey]}
                        </Typography>
                    );
                    if (listType.toLowerCase() === 'table') {
                        fieldColumnElements.push({
                            field: fld,
                            element: fldDisplayElement,
                            displayOrder: fld.order
                        });
                    } else {
                        contentElements.push({
                            field: fld,
                            element: fldDisplayElement,
                            displayOrder: fld.order
                        });
                    }
                }
            }
    
            sortedHeaderElements = headerElements.sort((a: any,b: any) => {
                if (a.displayOrder < b.displayOrder) return -1;  // ascending
                if (a.displayOrder > b.displayOrder) return 1;  // descending
                return 0 //default return value (no sorting)
            });
            // console.log('%c sortedHeaderElements: %O', 'color: green;', sortedHeaderElements);
    
            if (sortedHeaderElements.length > 0) {
                hasLayout = true;
                titleElement = (
                    <Fragment>
                        {
                            sortedHeaderElements.map(function(hdrElement: any) {
                                // console.log('%c hdrElement: %O', 'color: hotpink;', hdrElement);
                                return hdrElement.element;
                            })
                        }
                    </Fragment>
                );
            }
    
            sortedContentElements = contentElements.sort((a: any,b: any) => {
                if (a.displayOrder < b.displayOrder) return -1;  // ascending
                if (a.displayOrder > b.displayOrder) return 1;  // descending
                return 0 //default return value (no sorting)
            });
            // console.log('%c sortedContentElements: %O', 'color: green;', sortedContentElements);
    
            if (sortedContentElements.length > 0) {
                hasLayout = true;
                mainDescriptionElement = (
                    <Fragment>
                        {
                            sortedContentElements.map(function(cntntElement: any) {
                                // console.log('%c cntntElement: %O', 'color: hotpink;', cntntElement);
                                return cntntElement.element;
                            })
                        }
                    </Fragment>
                );
            }
        }
        // console.log('%c useUdicciRecord getListElement hasLayout: %O', 'color: blue;', hasLayout);

        if (titleElement === null && !hasLayout) {
            // console.log('%c getListElement getFieldElement (title) for %O', 'color: red;', keys.title);
            titleElement = getFieldElement({ fieldJsonKey: keys.title, variant: 'caption' });
        }

        if (mainDescriptionElement === null && !engagementAction && !hasLayout) {
            // console.log('%c getListElement getFieldElement (description) for %O', 'color: red;', keys.title);
            mainDescriptionElement = getFieldElement({ fieldJsonKey: keys.description, variant: 'caption' });
        }

        let recordAvatar: any = null;

        let toggleEngagementMenuElement: any = null;
        let iconButtonSettings: any = {
            float: 'right',
            size: 'small',
            onClick: null
        }
        // console.log('%c useUdicciRecord getListElement onRecordAction: %O', 'color: blue;', onRecordAction);
        if (onRecordAction !== null) {
            if (showEngagementMenu) {
                if (onRecordAction) iconButtonSettings.onClick = (evt: any) => onRecordAction(evt, { action: 'hide engagement menu', listProps });
                toggleEngagementMenuElement = (
                    <EngagementMenuContainer>
                        <IconButton aria-label="Hide Engagement Menu" {...iconButtonSettings}>
                            <LastPageIcon />
                        </IconButton>
                    </EngagementMenuContainer>
                );
            } else {
                if (onRecordAction) iconButtonSettings.onClick = (evt: any) => onRecordAction(evt, { action: 'show engagement menu', listProps });
                toggleEngagementMenuElement = (
                    <EngagementMenuContainer>
                        <IconButton aria-label="Show Engagement Menu" {...iconButtonSettings}>
                            <MenuOpenIcon />
                        </IconButton>
                    </EngagementMenuContainer>
                );
            }
        }

        let notificationDetailsElement: any = null;
        if (forNotification) {
            // console.log('%c useUdicciRecord getListElement forNotification: %O', 'color: blue;', forNotification);
            let stackFormElement: any = null;
            if (showStackForm) {
                const currentAssignedStack = (forNotification.AssignedStack ? forNotification.AssignedStack : '');
                // console.log('%c currentAssignedStack: %O', 'color: purple;', currentAssignedStack);
                const stackRecord = (forNotification.record ? forNotification.record : null);
                // console.log('%c stackRecord: %O', 'color: purple;', stackRecord);

                let assignedStack = '';
                if (newAssignedStack !== null) {
                    assignedStack = newAssignedStack;
                } else if (currentAssignedStack) {
                    assignedStack = currentAssignedStack;
                }
        
                let dialogTitleDetails = null;
                if (stackRecord && stackRecord.title) {
                    dialogTitleDetails = (
                        <Fragment>
                            <Typography variant="caption" component='span' sx={{ marginLeft: '4px' }}>for</Typography>
                            <Typography variant="body1" component='span' sx={{ marginLeft: '4px' }}>{stackRecord.title}</Typography>
                        </Fragment>
                    );
                }
                let dialogTitle = (
                    <DialogTitle id="assign-stack-title">
                        <Typography variant="body1" component='span'>Stack Activity</Typography>
                        {dialogTitleDetails}
                    </DialogTitle>
                );

                let currentlyAssignedStack = (
                    <div>
                        <Typography variant="caption" component='span'>Current Stack:</Typography>
                        <Typography variant="body1" component='span' sx={{ marginLeft: '4px' }}>
                            {(currentAssignedStack ? currentAssignedStack : 'not stacked')}
                        </Typography>
                    </div>
                );

                let stacks: any = udicci.getProfileSetting('Udicci Notification Logs', udicci.socialSolutionUdicciIT, 'stacks');
                let unacknowledged = (activitySummary && activitySummary.unacknowledged ? activitySummary.unacknowledged : null);
                // console.log('%c unacknowledged: %O', 'color: blue;', unacknowledged);
                let allStacks = (activitySummary && activitySummary.all ? activitySummary.all : null);
                // console.log('%c allStacks: %O', 'color: blue;', allStacks);

                let stackNames: string[] = [];
                if (stacks) {
                    forEach(stacks, (stack: any, idxStack: number) => {
                        if (typeof(stack) === 'string') {
                            if (stackNames.indexOf(stack) < 0) stackNames.push(stack);
                        } else if (stack.name) {
                            if (stackNames.indexOf(stack.name) < 0) stackNames.push(stack.name);
                        }
                    });
                }
                if (unacknowledged) {
                    forEach(unacknowledged, (stack: any, idxStack: number) => {
                        let notificationStack = stack.NotificationStack;
                        if (notificationStack && stackNames.indexOf(notificationStack) < 0) {
                            stackNames.push(notificationStack);
                        }
                    });
                }
            
                if (allStacks) {
                    forEach(allStacks, (stack: any, idxStack: number) => {
                        let notificationStack = stack.NotificationStack;
                        if (notificationStack && stackNames.indexOf(notificationStack) < 0) {
                            stackNames.push(notificationStack);
                        }
                    });
                }
                let sortedStackNames = stackNames.sort((a,b) => {
                    if (a < b) return -1;  // ascending
                    if (a > b) return 1;  // descending
                    return 0 //default return value (no sorting)
                });
                // console.log('%c sortedStackNames: %O', 'color: purple;', sortedStackNames);
                if (sortedStackNames.indexOf('not stacked') < 0) sortedStackNames.unshift('not stacked')
            
                let recKey: string = 'record.' + record.recordId + '.';
                let stackSelectionItems: any[] = [];
                Object.entries(sortedStackNames).forEach(([idxStack, stackName]) => {
                    // console.log('%c stackName: %O', 'color: purple;', stackName);
                    stackSelectionItems.push( <MenuItem key={recKey + stackName.replace(' ', '.')} value={stackName}>{stackName}</MenuItem> );
                });
        
                let addNewStackButton = null;
                if (showNewStackForm) {
                    addNewStackButton = (
                        <Button aria-label="Close New Stack"
                                title="Close New Stack"
                                color='info'
                                onClick={(evt: any) => setShowNewStackForm(false)}
                        >
                            <Typography variant="body2">Close new stack</Typography>
                        </Button>
                    );
                } else {
                    addNewStackButton = (
                        <Button aria-label="Add a New Stack"
                                title="Add a New Stack"
                                color='primary'
                                onClick={(evt: any) => setShowNewStackForm(true)}
                        >
                            <Icon>add</Icon>
                            <Typography variant="body2">Add new stack</Typography>
                        </Button>
                    );
                }
        
                let autoAcknowledgeCheckboxElement = (
                    <Checkbox checked={autoAcknowledgeWhenStack} onChange={changeAutoAcknowledgeValue} />
                );
                let autoAcknowledgeElement = (
                    <FormControlLabel control={autoAcknowledgeCheckboxElement}
                                      labelPlacement="end"
                                      label="Acknowledge activity when stacked."
                    />
                )
        
                let assignNewStackSelection = null;
                let newStackFormElement = null;
                if (showNewStackForm) {
                    newStackFormElement = (
                        <Box sx={{ margin: '4px' }}>
                            <FormControl fullWidth>
                                <Typography variant="caption" component='span'>New Stack Name:</Typography>
                                <TextField placeholder='Enter name of new stack'
                                           value={newStackName}
                                           InputLabelProps={{ shrink: true }}
                                           onChange={changeNewStackName}
                                />
                            </FormControl>
                            {addNewStackButton}
                        </Box>
                    );
                } else {
                    assignNewStackSelection = (
                        <Box sx={{ margin: '4px' }}>
                            <FormControl fullWidth>
                                <Typography variant="caption" component='span'>Move To Stack:</Typography>
                                <Select value={(assignedStack ? assignedStack : 'not stacked')}
                                        onChange={changeSelectedStack}
                                        inputProps={{
                                            name: 'assignedStack',
                                            id: 'assign-new-stack',
                                        }}
                                >
                                    {stackSelectionItems}
                                </Select>
                            </FormControl>
                            {addNewStackButton}
                        </Box>
                    );
                }
        
                stackFormElement = (
                    <div>
                        <Dialog maxWidth="sm"
                                fullWidth={true}
                                aria-labelledby="assign-stack-title"
                                open={showStackForm}
                        >
                            {dialogTitle}
                            <DialogContent>
                               {currentlyAssignedStack}
                               {assignNewStackSelection}
                               {newStackFormElement}
                            </DialogContent>
                            <DialogActions>
                                {autoAcknowledgeElement}
                                <Button onClick={(evt: any) => setShowStackForm(false)} color="info">
                                    Cancel
                                </Button>
                                <Button onClick={(evt: any) => applyStackSelection()} color="primary">
                                    Continue
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                );
            }
        
            notificationDetailsElement = (
                <Box>
                    <Typography variant="caption">{forNotification.CreatedInDisplayName + '. '}</Typography>
                    <Typography variant="caption">{forNotification.SocialSolutionName + '. '}</Typography>
                    <Typography variant="caption">{forNotification.UdicciMediatorName + '. '}</Typography>
                    <Typography variant="caption">{forNotification.NotificationCount + ' actions'}</Typography>
                    {stackFormElement}
                </Box>
            );
        }

        var listItemActions: any = null;
        var engagementActionsElement: any = null;
        if (engagementAction) { //  && !hasLayout
            let actionProps: any = {};
            if (listProps) Object.assign(actionProps, listProps);
            actionProps.inline = false;
            engagementActionsElement = getEngagementActionElement(actionProps);
        } else {
            descriptionElement = mainDescriptionElement;
            if (!hasLayout) {
                recordAvatar = getRecordAvatarElement({});
                listItemActions = getEngagementActionsElement(listProps);    
            } else {
                listItemActions = getEngagementActionsElement(listProps);    
            }
        }
        // console.log('%c useUdicciRecord getListElement engagementActionsElement: %O', 'color: blue;', engagementActionsElement);

        if (listType.toLowerCase() === 'card grid') {
            // console.log('%c getListElement layoutConfigSettings: %O', 'color: blue;', layoutConfigSettings);
            let headerAvatar: any = null;
            let headerActionMenu: any = null;

            let cardHeader: any = null;
            let cardContent: any = null;
            let cardActions: any = null;

            // headerAvatar = (<Avatar aria-label="avatar"> A </Avatar>);

            if (headerAvatar || headerActionMenu || titleElement || subheaderElement) {
                cardHeader = (
                    <CardHeader
                        avatar={headerAvatar}
                        action={headerActionMenu}
                        title={titleElement}
                        subheader={subheaderElement}
                        sx={{ padding: '8px' }}
                    />
                );
            }

            if (cardContentElement) cardContent = ( <CardContent sx={{ padding: '8px', paddingTop: '0' }}> {cardContentElement}{notificationDetailsElement} </CardContent> );

            if (cardActionsElement) {
                cardActions = (
                    <CardActions sx={{ display: 'flow-root' }}>
                        {toggleEngagementMenuElement}
                        {cardActionsElement}
                    </CardActions>
                );
            }

            return (
                <Card>
                    {cardHeader}
                    {cardContent}
                    {cardActions}
                </Card>
            );
        } else if (listType.toLowerCase() === 'table') {
            // console.log('%c getListElement fieldColumnElements: %O', 'color: blue;', fieldColumnElements);
            return (
                <Fragment>
                    {
                        fieldColumnElements.map((fldCol: any, idx: number) => {
                            // console.log('%c getListElement fldCol: %O', 'color: blue;', fldCol);
                            let fldJsonName: string = (fldCol.field && fldCol.field.field ? fldCol.field.field : 'fld');
                            var fldColKey = 'record.' + record.recordId.toString() + '.field.column.' + fldJsonName.toString() + '.' + idx.toString();
                            // console.log('%c getListElement fldColKey: %O', 'color: blue;', fldColKey);
                            let cellProps: any = {
                                key: fldColKey,
                                sx: { verticalAlign: 'top' }
                            };
                            return (<TableCell {...cellProps}>{fldCol.element}</TableCell>)
                        })
                    }
                </Fragment>
            );
        } else {
            return (
                <RecordListItem>
                    {titleElement}
                    {descriptionElement}
                    {recordAvatar}
                    {notificationDetailsElement}
                    {engagementActionsElement}
                    {(listItemActions || toggleEngagementMenuElement ? (<Divider />) : null)}
                    {toggleEngagementMenuElement}
                    {listItemActions}
                </RecordListItem>
            );
        }
    };

    const getFormElement = (formProps: any = {}) => {
        // console.log('%c getFormElement formProps: %O', 'color: green;', formProps);
        // console.log('%c getFormElement socialSolutionId: %O', 'color: green;', socialSolutionId);
        // console.log('%c getFormElement recordReference: %O', 'color: red;', recordReference);
        // console.log('%c getFormElement recordPermissions: %O', 'color: red;', recordPermissions);

        const hideForm = (evt: any) => {
            // console.log('%c hideForm props: %O', 'color: red;', props);
            if (formProps.onClose) formProps.onClose();
        }

        let breadcrumb: any = (formProps && formProps.breadcrumb ? formProps.breadcrumb : null);
        // console.log('%c getFormElement breadcrumb: %O', 'color: green;', breadcrumb);

        let formRecord: UdicciRecord = recordReference;
        // if (breadcrumb && breadcrumb.record) formRecord = breadcrumb.record;
        // console.log('%c getFormElement formRecord: %O', 'color: green;', formRecord);

        var recordId: number = (formRecord && formRecord.recordId ? formRecord.recordId : 0);
        var totalRecordCount: number = (formProps && formProps.totalRecordCount ? formProps.totalRecordCount : 0);
        let listSettings: any = (formProps && formProps.listSettings ? formProps.listSettings : null);
        // console.log('%c getFormElement listSettings: %O', 'color: green;', listSettings);
        let listSettingsMediator: any = (listSettings && listSettings.mediator ? listSettings.mediator : null);

        var listSettingsForm: any = null;
        var formLayout: any = null;
        var formFields: any = null;
        if (listSettingsMediator && listSettingsMediator.name === formRecord.udicciMediator) {
            // console.log('%c getFormElement listSettingsMediator: %O', 'color: green;', listSettingsMediator);
            listSettingsForm = (listSettings && listSettings.form ? listSettings.form : null);
            formLayout = (listSettingsForm && listSettingsForm.layout ? listSettingsForm.layout : null);
            formFields = (formLayout && formLayout.formFields ? formLayout.formFields : null);
        }
        // console.log('%c getFormElement listSettingsForm: %O', 'color: green;', listSettingsForm);
        // console.log('%c getFormElement formLayout: %O', 'color: green;', formLayout);
        // console.log('%c getFormElement formFields: %O', 'color: green;', formFields);

        var titleElement: any = null;
        var saveButtonElement: any = null;
        if (recordPermissions) {
            if ((recordId > 0 && recordPermissions.CanEdit) || (recordId <= 0 && recordPermissions.CanAdd)) {
                let saveProps: any = null;
                if (listSettings && listSettings.socialSolution) {
                    saveProps = {
                        socialSolutionId: (listSettings.socialSolution.id ? listSettings.socialSolution.id : 0)
                    };
                }
                var saveButtonElementProps = {
                    disabled: (isDirty && !isSaving ? false : true),
                    onClick: (evt: any) => saveRecord(saveProps),
                    // classes: { root: udicciClasses.button },
                    sx: { marginLeft: '32px', marginRight: '16px' }
                }

                var savingProgress: any = null;
                if (isSaving) {
                    savingProgress = (<CircularProgress size={10} />);
                }
                saveButtonElement = (
                    <Button color="primary" size="small" variant="contained" {...saveButtonElementProps}>
                        {(isSaving ? 'saving ... ' : 'Save')}
                        {savingProgress}
                    </Button>
                );
            }
        }

        var recMediatorNameSingular = (formRecord.udicciMediator ? Pluralize.singular(formRecord.udicciMediator) : formRecord.udicciMediator);
        // console.log('recMediatorNameSingular: %O', recMediatorNameSingular);

        // console.log('engagementAction: %O', engagementAction);
        var eaAction: string = (engagementAction && engagementAction.action ? engagementAction.action : '');
        // console.log('eaAction: %O', eaAction);
        var eaName: string = (engagementAction && engagementAction.name ? engagementAction.name : '');
        // console.log('eaName: %O', eaName);
        var eaLabel: any = (engagementAction && engagementAction.label ? engagementAction.label : null);
        // console.log('eaLabel: %O', eaLabel);
        var eaLabelText: any = (eaLabel && eaLabel.text ? eaLabel.text : '');
        // console.log('eaLabelText: %O', eaLabelText);

        var titleText: string = '';
        if (eaLabelText) {
            titleText = eaLabelText;
            titleText += '  (' + (eaName ? eaName : (eaAction ? eaAction + ' ' + recMediatorNameSingular : '')) + ')';
        }
        // console.log('titleText: %O', titleText);
        if (titleText) {
            titleElement = (
                <Fragment>
                    <Typography variant="caption" color="primary" component="span" noWrap>
                        {titleText}
                    </Typography>
                    <Typography variant="caption" component="span" color="error">
                        .
                    </Typography>
                </Fragment>
            );
        }

        var fields: any = null;
        // var virtualFields: any = null;
        var linkedUdicciMediators: any = null;
        // console.log('udicciMediator: %O', udicciMediator);
        if (udicciMediator) {
            fields = (udicciMediator.UdicciMediatorFields ? udicciMediator.UdicciMediatorFields : null);
            // virtualFields = (udicciMediator.VirtualMediatorFields ? udicciMediator.VirtualMediatorFields : null);
            linkedUdicciMediators = (udicciMediator.LinkedUdicciMediators ? udicciMediator.LinkedUdicciMediators : null);
        }
        // console.log('%c getFormElement fields: %O', 'color: green;', fields);
        // console.log('%c getFormElement virtualFields: %O', 'color: green;', virtualFields);
        // console.log('%c getFormElement linkedUdicciMediators: %O', 'color: green;', linkedUdicciMediators);
        // console.log('%c getFormElement formRecord: %O', 'color: green;', formRecord);

        var fieldDisplayElements: any[] = [];
        if (fields && fields.length > 0) {
            fields.forEach(function(field: any) {
                // console.log('%c field: %O', 'color: blue;', field);
                let fieldLayoutSettings: any = null;
                if (formFields && formFields.length > 0) {
                    // console.log('%c formFields: %O', 'color: blue;', formFields);
                    fieldLayoutSettings = find(formFields, (ff: any) => {
                        return ff.id === field.UdicciMediatorFieldId;
                    });
                    // console.log('%c fieldLayoutSettings: %O', 'color: orange;', fieldLayoutSettings);
                    if (!fieldLayoutSettings) return true;
                } else {
                    if (field.UdicciMediatorId <= 0) return true;
                    if (field.DataType === 'Json' /*&& !udicci.isCurrentUserUlysses()*/) return true;
                }
                // console.log('%c fieldLayoutSettings: %O', 'color: blue;', fieldLayoutSettings);

                var fieldKey = 'record.' + recordId + '.field.' + field.UdicciMediatorFieldId;
                var fieldElementProps: any = {};
                Object.assign(fieldElementProps, formProps);
                fieldElementProps.key = fieldKey;
                fieldElementProps.showForm = true;
                fieldElementProps.readonly = false;
                fieldElementProps.field = field;
                fieldElementProps.record = formRecord;
                fieldElementProps.layout = fieldLayoutSettings;
                // console.log('%c fieldElementProps: %O', 'color: blue;', fieldElementProps);
                fieldDisplayElements.push(getFieldElement(fieldElementProps));
            });
        }

        var parentDisplayElement: any = getParentElements(formProps);
        var childrenDisplayElement: any = getChildElements(formProps);
        let closeButtonIsDisabled: boolean = false;
        // if (record.isSaving) closeButtonIsDisabled = true;

        let helpIconSettings: any = {
            sx: {
                fontSize: 20,
                cursor: 'pointer',
                float: 'right',
                marginRight: '8px',
            },
            onClick: (evt: any) => setShowHelp(!showHelp)
        };
        let helpIconElement: any = null;
        if (showHelp) {
            helpIconElement = (<Icon {...helpIconSettings}>help</Icon>);
        } else {
            helpIconElement = (<Icon {...helpIconSettings}>help_outline</Icon>);
        }

        let confirmDeleteRecordElement: any = null;
        if (breadcrumb && breadcrumb.settings && breadcrumb.settings.deleteRecord) {
            confirmDeleteRecordElement = (
                <Box>
                    <Typography component="span" variant="errorMessage" sx={{ marginLeft: '16px', marginRight: '8px' }}>Confirm Delete?</Typography>
                    <Typography component="span" variant="clickableSubTitle1" sx={{ marginRight: '8px' }}>Yes</Typography>
                    <Typography component="span" variant="clickableSubTitle2" sx={{ marginRight: '8px' }}>No</Typography>
                </Box>
            );
        }

        let firstRecordIconElement: any = null;
        let lastRecordIconElement: any = null;
        let nextRecordIconElement: any = null;
        let previousRecordIconElement: any = null;
        let recordPositionElement: any = null;
        if (formProps.onGetRecordPosition) {
            let recPos: string = formProps.onGetRecordPosition();
            // console.log('%c getFormElement recPos: %O', 'color: green;', recPos);
            let nextRecordDisabled: boolean = false;
            let previousRecordDisabled: boolean = false;
            let recIndex: number = 1;
            if (recPos === 'first') {
                recIndex = 1;
                previousRecordDisabled = true;
            } else if (recPos === 'last') {
                recIndex = (totalRecordCount - 1);
                nextRecordDisabled = true;
            } else if (recPos !== '-1') {
                recIndex = parseInt(recPos, 10);
                recordPositionElement = (
                    <Badge badgeContent={(recIndex + 1)} color="success" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        &nbsp;
                    </Badge>
                );
            }
            // console.log('%c getFormElement nextRecordDisabled: %O', 'color: green;', nextRecordDisabled);
            // console.log('%c getFormElement previousRecordDisabled: %O', 'color: green;', previousRecordDisabled);
            // console.log('%c getFormElement recIndex: %O', 'color: green;', recIndex);

            if (recPos !== '-1') {
                let nextRecordIconSettings: any = {
                    size: 'small',
                    onClick: formProps.onNextRecord,
                    sx: { marginLeft: '14px', marginRight: '8px', cursor: (nextRecordDisabled ? 'default' : 'pointer') },
                    disabled: nextRecordDisabled,
                };
                nextRecordIconElement = (
                    <IconButton {...nextRecordIconSettings}><Icon fontSize="small">navigate_next</Icon></IconButton>
                );

                let previousRecordIconSettings: any = {
                    size: 'small',
                    onClick: formProps.onPreviousRecord,
                    sx: { marginLeft: '8px', marginRight: '8px', cursor: (previousRecordDisabled ? 'default' : 'pointer') },
                    disabled: previousRecordDisabled,
                };
                previousRecordIconElement = (
                    <IconButton {...previousRecordIconSettings}><Icon fontSize="small">navigate_before</Icon></IconButton>
                );

                let firstRecordIconSettings: any = {
                    size: 'small',
                    onClick: () => formProps.onNextRecord(0),
                    sx: { marginLeft: '8px', cursor: (previousRecordDisabled ? 'default' : 'pointer') },
                    disabled: previousRecordDisabled,
                };
                firstRecordIconElement = (
                    <Badge showZero badgeContent={1} color={(previousRecordDisabled ? "success" : "primary")} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                        <IconButton {...firstRecordIconSettings}><Icon fontSize="small">first_page</Icon></IconButton>
                    </Badge>
                );

                let lastRecordIconSettings: any = {
                    size: 'small',
                    onClick: () => formProps.onNextRecord(totalRecordCount - 1),
                    sx: { marginRight: '8px', cursor: (nextRecordDisabled ? 'default' : 'pointer') },
                    disabled: nextRecordDisabled,
                };
                lastRecordIconElement = (
                    <Badge showZero badgeContent={totalRecordCount} color={(nextRecordDisabled ? "success" : "primary")} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <IconButton {...lastRecordIconSettings}><Icon fontSize="small">last_page</Icon></IconButton>
                    </Badge>
                );
            }
        }

        let showMoreDetailsIconElement: any = null;
        let moreDetailsElement: any = null;
        let showMoreDetailsIconSettings: any = {
            size: 'small',
            onClick: () => setShowFormAdditionalDetails(!showFormAdditionalDetails),
            sx: { marginRight: '8px', cursor: 'pointer' },
        };
        if (showFormAdditionalDetails) {
            showMoreDetailsIconElement = (
                <IconButton {...showMoreDetailsIconSettings}><Icon fontSize="small">unfold_less</Icon></IconButton>
            );

            // console.log('%c formRecord: %O', 'color: blue;', formRecord);
            let recordId: number = (formRecord.recordId ? formRecord.recordId : 0);
            // console.log('%c recordId: %O', 'color: blue;', recordId);
            let recData: any = (formRecord.data ? formRecord.data : null);
            // console.log('%c recData: %O', 'color: blue;', recData);

            // let dtDateCreated: any = (recData && recData.dtDateCreated ? recData.dtDateCreated : null);
            // console.log('%c dtDateCreated: %O', 'color: blue;', dtDateCreated);
            // let creatorDisplayName: string = (recData && recData.CreatorDisplayName ? recData.CreatorDisplayName : '');
            // console.log('%c creatorDisplayName: %O', 'color: blue;', creatorDisplayName);
            // let creatorProfileUrl: string = (recData && recData.CreatorProfileUrl ? recData.CreatorProfileUrl : '');
            // console.log('%c creatorProfileUrl: %O', 'color: blue;', creatorProfileUrl);
            // let creatorSocialIcon: string = (recData && recData.CreatorSocialIcon ? recData.CreatorSocialIcon : '');
            // console.log('%c creatorSocialIcon: %O', 'color: blue;', creatorSocialIcon);

            // let dtModifiedDate: any = (recData && recData.dtModifiedDate ? recData.dtModifiedDate : null);
            // console.log('%c dtModifiedDate: %O', 'color: blue;', dtModifiedDate);
            // let modifiedByDisplayName: string = (recData && recData.ModifiedByDisplayName ? recData.ModifiedByDisplayName : '');
            // console.log('%c modifiedByDisplayName: %O', 'color: blue;', modifiedByDisplayName);
            // let modifiedByProfileUrl: string = (recData && recData.ModifiedByProfileUrl ? recData.ModifiedByProfileUrl : '');
            // console.log('%c modifiedByProfileUrl: %O', 'color: blue;', modifiedByProfileUrl);
            // let modifiedBySocialIcon: string = (recData && recData.ModifiedBySocialIcon ? recData.ModifiedBySocialIcon : '');
            // console.log('%c modifiedBySocialIcon: %O', 'color: blue;', modifiedBySocialIcon);

            let createdByElement: any = getRecordAvatarElement({ display: 'creator' });

            moreDetailsElement = (
                <Box sx={{ display: 'flow-root', margin: '16px', marginTop: 0 }}>
                    {createdByElement}
                </Box>
            );
        } else {
            showMoreDetailsIconElement = (
                <IconButton {...showMoreDetailsIconSettings}><Icon fontSize="small">unfold_more</Icon></IconButton>
            );
        }

        var formDisplayElement: any = (
            <Box sx={udicciClasses.inlineContentArea}>
                {helpIconElement}
                {titleElement}
                {fieldDisplayElements}
                {confirmDeleteRecordElement}
                <Box sx={udicciClasses.buttonContent}>
                    <Box sx={{ display: 'flex' }}>
                        {firstRecordIconElement}
                        {previousRecordIconElement}
                        {recordPositionElement}
                        {nextRecordIconElement}
                        {lastRecordIconElement}
                    </Box>
                    <Box sx={udicciClasses.grow} />
                    <Box sx={{ display: 'flex', marginLeft: '8px' }}>
                        {showMoreDetailsIconElement}
                    </Box>
                    <Box sx={{ display: 'flex', marginLeft: '8px' }}>
                        {saveButtonElement}
                        <Button disabled={closeButtonIsDisabled} variant="contained" size="small" color="info" onClick={hideForm}>
                            Close
                        </Button>
                    </Box>
                </Box>
                {moreDetailsElement}
                {parentDisplayElement}
                {childrenDisplayElement}
            </Box>
        );
    
        // var recordContextElement: any = null;

        return (
            <Box>
                {formDisplayElement}
            </Box>
        );
    
    };

    const getFieldElement = (fieldProps: any = {}) => {
        // console.log('%c useUdicciRecord getFieldElement recordReference: %O', 'color: blue;', recordReference);
        // console.log('%c useUdicciRecord getFieldElement fieldProps: %O', 'color: blue;', fieldProps);
        var field: any = getField(fieldProps.fieldJsonKey);
        // console.log('%c useUdicciRecord getFieldElement field: %O', 'color: blue;', field);
        var displayProps: any = {
            variant: (fieldProps && fieldProps.variant ? fieldProps.variant : 'body1'),
            // color: (fieldProps && fieldProps.color ? fieldProps.color : 'textPrimary'),
            gutterBottom: (fieldProps && fieldProps.gutterBottom ? true : false),
            // classes: { root: udicciClasses.itemTitleContainer }
        };
        if (fieldProps.useHook === undefined) fieldProps.useHook = true;
        if (fieldProps.showForm === undefined) fieldProps.showForm = false;
        if (fieldProps.showHelp === undefined) fieldProps.showHelp = showHelp;
        if (fieldProps.readonly === undefined) fieldProps.readonly = true;
        if (fieldProps.udicciMediator === undefined) fieldProps.udicciMediator = udicciMediator;
        if (fieldProps.field === undefined) fieldProps.field = field;
        if (fieldProps.record === undefined) fieldProps.record = record;
        if (fieldProps.displayProps === undefined) fieldProps.displayProps = displayProps;
        if (fieldProps.onChange === undefined) fieldProps.onChange = changeRecordValue;
        // console.log('%c useUdicciRecord getFieldElement fieldProps: %O', 'color: orange;', fieldProps);
        return (<FieldDisplay {...fieldProps} />);
    };

    const getParentElements = (parentProps: any = {}) => {
        // console.log('%c useUdicciRecord getParentElements parentProps: %O', 'color: blue;', parentProps);
        // console.log('%c useUdicciRecord getParentElements recordReference: %O', 'color: blue;', recordReference);
        // console.log('%c useUdicciRecord getParentElements selectedSocialSolution: %O', 'color: blue;', selectedSocialSolution);
        // console.log('%c useUdicciRecord getParentElements selectedFeature: %O', 'color: blue;', selectedFeature);
        // console.log('%c useUdicciRecord getParentElements udicciMediator: %O', 'color: blue;', udicciMediator);

        var linkedUdicciMediators: any = null;
        // console.log('udicciMediator: %O', udicciMediator);
        if (udicciMediator) {
            linkedUdicciMediators = (udicciMediator.LinkedUdicciMediators ? udicciMediator.LinkedUdicciMediators : null);
        }
        // console.log('%c useUdicciRecord getParentElements linkedUdicciMediators: %O', 'color: green;', linkedUdicciMediators);

        if (!selectedFeature && socialSolution && socialSolution.data && socialSolution.data.Features) {
            if (parentProps && parentProps.listSettings && parentProps.listSettings.feature && parentProps.listSettings.feature.id) {
                let compareFtre: any = parentProps.listSettings.feature;
                // console.log('%c useUdicciRecord getParentElements compareFtre: %O', 'color: red;', compareFtre);
                selectedFeature = find(socialSolution.data.Features, (ftre: any) => { return ftre.UdicciRecordId === compareFtre.id });
                // console.log('%c useUdicciRecord getParentElements selectedFeature: %O', 'color: red;', selectedFeature);
            }
        }

        var featureMediators: any[] | null = null;
        // console.log('selectedFeature: %O', selectedFeature);
        if (selectedFeature) {
            featureMediators = (selectedFeature.Mediators ? selectedFeature.Mediators : null);
        }
        // console.log('%c useUdicciRecord getParentElements featureMediators: %O', 'color: green;', featureMediators);

        var includedFeatureMediators: any[] | null = null;
        if (featureMediators) {
            forEach(featureMediators, function(fm: any) {
                var lumCheck: any = null;
                if (linkedUdicciMediators) {
                    lumCheck = find(linkedUdicciMediators, function(lum: any) {
                        var fmIsParent: boolean = (lum.LeftUdicciMediatorName === fm.name ? true : false);
                        var recIsChild: boolean = (lum.RightUdicciMediatorName === udicciMediator.Name ? true : false);
                        return fmIsParent && recIsChild;
                    });
                }
                // console.log('%c lumCheck: %O', 'color: blue;', lumCheck);

                if (lumCheck) {
                    if (!includedFeatureMediators) includedFeatureMediators = [];
                    includedFeatureMediators.push({
                        name: fm.name,
                        lum: lumCheck,
                    });
                }
            });
        }
        // console.log('%c useUdicciRecord getParentElements includedFeatureMediators: %O', 'color: green;', includedFeatureMediators);

        var featureMediatorElements: any[] = [];
        if (includedFeatureMediators) {
            forEach(includedFeatureMediators, function(featureMediator: any) {
                // console.log('%c useUdicciRecord getParentElements featureMediator: %O', 'color: blue;', featureMediator);

                // var parentContext: any = udicciHelpers.getMediatorContext(featureMediator.name);
                // console.log('%c useUdicciRecord getParentElements parentContext: %O', 'color: purple;', parentContext);
                // var parLinkedData = (parentContext && parentContext.linkedData ? parentContext.linkedData : null);
                // console.log('%c useUdicciRecord getParentElements parLinkedData: %O', 'color: purple;', parLinkedData);
                // var parCtxData = (parentContext && parentContext.records ? parentContext.records : null);
                // console.log('%c useUdicciRecord getParentElements parCtxData: %O', 'color: purple;', parCtxData);

                // if (parLinkedData) {
                //     var lum: any = find(parLinkedData, function(pld: any) {
                //         return (featureMediator.lum && pld.Name === featureMediator.lum.Name);
                //     });
                //     // console.log('%c useUdicciRecord getParentElements lum: %O', 'color: purple;', lum);
                // }

                var parentFormProps: any = {};
                Object.assign(parentFormProps, parentProps);
                parentFormProps.key = 'record.' + recordReference.recordId + '.parent.' + featureMediator.name.toLowerCase() + '.selection';
                parentFormProps.record = recordReference;
                parentFormProps.parentData = [];
                parentFormProps.selectedParentId = 0;
                parentFormProps.selectedParentMediator = featureMediator.name;
                parentFormProps.lum = featureMediator.lum;
                parentFormProps.updateFormRecord = (updatedRecord: UdicciRecord) => { setRecord(updatedRecord, false) };
                // console.log('%c useUdicciRecord getParentElements parentFormProps: %O', 'color: purple;', parentFormProps);

                featureMediatorElements.push( <RecordParentSelection {...parentFormProps} /> );
            });
        }

        var rvalParentElement: any = null;
        if (featureMediatorElements.length > 0) {
            rvalParentElement = ( <Box> {featureMediatorElements} </Box> );
        }

        return rvalParentElement;
    };

    const getChildElements = (childProps: any = {}) => {
        // console.log('%c useUdicciRecord getChildElements childProps: %O', 'color: blue;', childProps);
        // console.log('%c useUdicciRecord getChildElements recordReference: %O', 'color: blue;', recordReference);
        // console.log('%c useUdicciRecord getChildElements selectedSocialSolution: %O', 'color: blue;', selectedSocialSolution);
        // console.log('%c useUdicciRecord getChildElements selectedFeature: %O', 'color: blue;', selectedFeature);
        // console.log('%c useUdicciRecord getChildElements udicciMediator: %O', 'color: blue;', udicciMediator);

        var linkedUdicciMediators: any = null;
        // console.log('udicciMediator: %O', udicciMediator);
        if (udicciMediator) {
            linkedUdicciMediators = (udicciMediator.LinkedUdicciMediators ? udicciMediator.LinkedUdicciMediators : null);
        }
        // console.log('%c useUdicciRecord getChildElements linkedUdicciMediators: %O', 'color: green;', linkedUdicciMediators);

        if (!selectedFeature && socialSolution && socialSolution.data && socialSolution.data.Features) {
            if (childProps && childProps.listSettings && childProps.listSettings.feature && childProps.listSettings.feature.id) {
                let compareFtre: any = childProps.listSettings.feature;
                // console.log('%c useUdicciRecord getChildElements compareFtre: %O', 'color: red;', compareFtre);
                selectedFeature = find(socialSolution.data.Features, (ftre: any) => { return ftre.UdicciRecordId === compareFtre.id });
                // console.log('%c useUdicciRecord getChildElements selectedFeature: %O', 'color: red;', selectedFeature);
            }
        }

        var featureMediators: any[] | null = null;
        // console.log('selectedFeature: %O', selectedFeature);
        if (selectedFeature) {
            featureMediators = (selectedFeature.Mediators ? selectedFeature.Mediators : null);
        }
        // console.log('%c useUdicciRecord getChildElements featureMediators: %O', 'color: green;', featureMediators);

        let listSettings: any = (childProps && childProps.listSettings ? childProps.listSettings : null);
        // console.log('%c getFormElement listSettings: %O', 'color: green;', listSettings);
        let listSettingsForm: any = (listSettings && listSettings.form ? listSettings.form : null);
        // console.log('%c getFormElement listSettingsForm: %O', 'color: green;', listSettingsForm);
        let formLayout: any = (listSettingsForm && listSettingsForm.layout ? listSettingsForm.layout : null);
        // console.log('%c getFormElement formLayout: %O', 'color: green;', formLayout);
        let relatedDataSections: any = (formLayout && formLayout.relatedDataSections ? formLayout.relatedDataSections : null);
        // console.log('%c getFormElement relatedDataSections: %O', 'color: green;', relatedDataSections);

        var includedFeatureMediators: any[] | null = null;
        if (featureMediators) {
            forEach(featureMediators, function(fm: any) {
                var lumCheck: any = null;
                if (linkedUdicciMediators) {
                    lumCheck = find(linkedUdicciMediators, function(lum: any) {
                        var fmIsChild: boolean = (lum.RightUdicciMediatorName === fm.name ? true : false);
                        var recIsParent: boolean = (lum.LeftUdicciMediatorName === udicciMediator.Name ? true : false);
                        return fmIsChild && recIsParent;
                    });
                }
                // console.log('%c lumCheck: %O', 'color: blue;', lumCheck);

                if (lumCheck) {
                    if (!includedFeatureMediators) includedFeatureMediators = [];

                    let settings: any = null;
                    if (relatedDataSections && relatedDataSections.length > 0) {
                        // console.log('%c relatedDataSections: %O', 'color: blue;', relatedDataSections);
                        let relatedDataSection: any = find(relatedDataSections, (rds: any) => {
                            return rds.id === lumCheck.LinkedUdicciMediatorId;
                        });
                        // console.log('%c relatedDataSection: %O', 'color: orange;', relatedDataSection);
                        if (relatedDataSection && relatedDataSection.settings) {
                            settings = relatedDataSection.settings;
                        }
                    }

                    includedFeatureMediators.push({
                        name: fm.name,
                        lum: lumCheck,
                        settings: settings,
                    });
                }
            });
        }
        // console.log('%c useUdicciRecord getChildElements includedFeatureMediators: %O', 'color: green;', includedFeatureMediators);

        var featureMediatorElements: any[] = [];
        if (includedFeatureMediators) {
            forEach(includedFeatureMediators, function(featureMediator: any) {
                // console.log('%c useUdicciRecord getChildElements featureMediator: %O', 'color: blue;', featureMediator);

                var childFormProps: any = {};
                Object.assign(childFormProps, childProps);
                childFormProps.key = 'record.' + recordReference.recordId + '.child.' + featureMediator.name.toLowerCase() + '.selection';
                childFormProps.parentRecord = recordReference;
                childFormProps.lum = featureMediator.lum;
                childFormProps.childData = [];
                childFormProps.selectedChildId = 0;
                childFormProps.selectedChildMediator = featureMediator.name;
                childFormProps.parentHelpShown = showHelp;
                childFormProps.settings = (featureMediator.settings ? featureMediator.settings : null);
                // childFormProps.onChange = changeRecordValue;
                // console.log('%c useUdicciRecord getChildElements childFormProps: %O', 'color: purple;', childFormProps);

                featureMediatorElements.push( <RecordChildrenDisplay {...childFormProps} /> );
            });
        }

        var rvalChildElement: any = null;
        if (featureMediatorElements.length > 0) {
            rvalChildElement = ( <Box> {featureMediatorElements} </Box> );
        }

        return rvalChildElement;
    };

    const getRecordAvatarElement = (avatarProps: any = {}) => {
        // console.log('%c useUdicciRecord getRecordAvatarElement recordReference: %O', 'color: blue;', recordReference);
        // console.log('%c useUdicciRecord getRecordAvatarElement avatarProps: %O', 'color: blue;', avatarProps);

        var record: any = recordReference;
        // console.log('%c useUdicciRecord getRecordAvatarElement record: %O', 'color: blue;', record);
        var displayOption: any = (avatarProps.display ? avatarProps.display : 'creator');
        // console.log('%c useUdicciRecord getRecordAvatarElement displayOption: %O', 'color: blue;', displayOption);

        var recordAvatarElement: any = null;

        var dateCreated: Date = record.data.dtDateCreated;
        var dateModified: Date = record.data.dtModifiedDate;
        var modifiedByDisplayName: string = record.data.ModifiedByDisplayName;
        var modifiedDifferentElement: any = null;
        var modifiedByElement: any = null;

        if (displayOption === 'creator') {
            if (record && record.data.CreatorSocialIcon) {
                // var dateCreated: Date = new Date(record.data.DateCreated);
                var matchHours = (dateCreated.getHours() === dateModified.getHours() ? true : false);
                var matchMinutes = (dateCreated.getMinutes() === dateModified.getMinutes() ? true : false);
                if (!matchHours || !matchMinutes) {
                    if (modifiedByDisplayName) {
                        modifiedByElement = ( <Fragment>&nbsp;by {modifiedByDisplayName}</Fragment> );
                    }
        
                    modifiedDifferentElement = (
                        <Typography variant="caption" component="span" noWrap sx={{ margin: '8px' }}>
                            (last edit {dateModified.toLocaleString()}{modifiedByElement})
                        </Typography>
                    );
                }

                recordAvatarElement = (
                    <Box sx={{ margin: '8px' }}>
                        <Box sx={{ padding: '8px', float: 'left' }}>
                            <Avatar src={record.data.CreatorSocialIcon} variant="rounded" />
                        </Box>
                        <Box sx={{ padding: '8px' }}>
                            <Typography variant="caption" component="div" noWrap>
                                {record.data.CreatorDisplayName}
                            </Typography>
                            <Typography variant="caption" component="span" noWrap>
                                created {dateCreated.toLocaleString()}
                            </Typography>
                            {modifiedDifferentElement}
                        </Box>
                    </Box>
                );
            } else if (record && record.data.CreatedInSocialIcon) {
                // classes={{ img: udicciClasses.engagementAvatar }}
                recordAvatarElement = (
                    <Box>
                        <Avatar src={record.data.CreatedInSocialIcon} variant="rounded"  />
                        <Typography variant="caption" color="textPrimary" component="div" noWrap>
                            {record.data.CreatorDisplayName}
                        </Typography>
                        <Typography variant="caption" component="div" noWrap>
                            {record.data.DateCreated}
                        </Typography>
                    </Box>
                );
            }
        }
        if (displayOption === 'modifier') {
            if (record && record.data.ModifiedBySocialIcon) {
                if (dateModified) {
                    if (modifiedByDisplayName) {
                        modifiedByElement = ( <Fragment>&nbsp;by {modifiedByDisplayName}</Fragment> );
                    }
        
                    modifiedDifferentElement = (
                        <Typography variant="caption" component="div" noWrap>
                            (last edit {dateModified.toLocaleString()}{modifiedByElement})
                        </Typography>
                    );
                }

                recordAvatarElement = (
                    <Box>
                        <Avatar src={record.data.ModifiedBySocialIcon}
                                variant="rounded"
                        />
                        <Typography variant="caption" color="textPrimary" component="div" noWrap>
                            {record.data.ModifiedByDisplayName}
                        </Typography>
                        {modifiedDifferentElement}
                    </Box>
                );
            } else if (record && record.data.CreatedInSocialIcon) {
                //  classes={{ img: udicciClasses.engagementAvatar }}
                recordAvatarElement = (
                    <Box>
                        <Avatar src={record.data.CreatedInSocialIcon} variant="rounded" />
                        <Typography variant="caption" color="textPrimary" component="div" noWrap>
                            {record.data.ModifiedByDisplayName}
                        </Typography>
                        <Typography variant="caption" component="div" noWrap>
                            {record.data.ModifiedDate}
                        </Typography>
                    </Box>
                );
            }
            
        }

        return recordAvatarElement;
    };

    const acknowledgeActivity = (hideActivity: boolean) => {
        // console.log('%c acknowledgeActivity hideActivity: %O', 'color: red;', hideActivity);

        if (processingRequest) return;
        if (!recordReference) return;
        if (!recordReference.recordId) return;
        if (recordReference.isSaving) return;

        if (!forNotification) return false;
        // console.log('%c acknowledgeActivity forNotification: %O', 'color: red;', forNotification);

        let hideNotification = false;
        if (hideActivity) hideNotification = true;

        var cu = (currentUser ? currentUser : { UdicciUserId: 0 });
        var currentUserId: number = cu.UdicciUserId;
        var sp = (selectedProfile ? selectedProfile : { recordId: 0 });
        var profileId: number = sp.recordId;

        var request: AcknowledgeNotificationRequest = {
            UdicciMediatorName: forNotification.UdicciMediatorName,
            UdicciCommand: "Acknowledge Notification",
            SelectedUdicciProfileId: profileId,
            SocialSolutionId: socialSolutionId,
            UserId: currentUserId,
            NotificationId: (forNotification.NotificationId ? forNotification.NotificationId : 0),
            Hidden: hideNotification,
            NotifyUserId: currentUserId,
        }
        // console.log('%c acknowledgeActivity request: %O', 'color: purple;', request);

        var okToContinue = true;
        if (okToContinue) {
            sendPreparedRequest(request, {
                onSuccess: acknowledgeActivityCompleted,
                onError: requestFailed
            });
            setProcessingRequest(true);
        } else {
            setProcessingRequest(false);
        }
    };

    const acknowledgeActivityCompleted = (response: any, request: any, settings: any, addtnlDetails: any) => {
        // console.log('%c acknowledgeActivityCompleted response: %O', 'color: purple;', response);
        // console.log('%c acknowledgeActivityCompleted request: %O', 'color: purple;', request);
        // console.log('%c acknowledgeActivityCompleted settings: %O', 'color: purple;', settings);
        // console.log('%c acknowledgeActivityCompleted addtnlDetails: %O', 'color: purple;', addtnlDetails);
        setProcessingRequest(false);
        if (onReloadActivity) onReloadActivity();
    };

    const getEngagementActionsElement = (engagementActionsProps: any) => {
        // console.log('%c useUdicciRecord getEngagementActionsElement recordReference: %O', 'color: blue;', recordReference);
        // console.log('%c useUdicciRecord getEngagementActionsElement engagementActionsProps: %O', 'color: blue;', engagementActionsProps);
        // console.log('%c useUdicciRecord getEngagementActionsElement forNotification: %O', 'color: blue;', forNotification);

        var engagementActionsElement: any = null;
        let onShowRecordForm: any = (engagementActionsProps && engagementActionsProps.onShowForm ? engagementActionsProps.onShowForm : null);
        let onDeleteRecord: any = (engagementActionsProps && engagementActionsProps.onDeleteRecord ? engagementActionsProps.onDeleteRecord : null);
        let listSettings: any = (engagementActionsProps && engagementActionsProps.listSettings ? engagementActionsProps.listSettings : null);

        let pluginActions: any = null;
        if (listSettings) {
            if (listSettings.actions) pluginActions = listSettings.actions;
        }
        // console.log('%c getEngagementActionsElement pluginActions: %O', 'color: purple;', pluginActions);
    
        var viewRecordButton: any = null;
        var editRecordButton: any = null;
        var deleteRecordButton: any = null;
        var acknowledgeButton: any = null;
        var acknowledgeAndHideButton: any = null;
        var stackButtonElement: any = null;
        var engagementActionListItems: any[] = [];

        if (record && record.permissions) {
            var permissions: any = record.permissions;
            // console.log('%c useUdicciRecord getEngagementActionsElement permissions: %O', 'color: blue;', permissions);
            var engagementActions: any[] = getSocialSolutionEngagementActions({});
            // console.log('%c useUdicciRecord getEngagementActionsElement engagementActions: %O', 'color: purple;', engagementActions);

            if (engagementActions && engagementActions.length > 0) {
                // console.log('%c useUdicciRecord getEngagementActionsElement record: %O', 'color: red;', record);
                for (let [idx, ea] of Object.entries<any>(engagementActions)) {
                    // console.log('%c useUdicciRecord getEngagementActionsElement ea: %O', 'color: red;', ea);
                    // var eaname: string = (ea.action ? ea.action : ea.name);
                    // console.log('%c ea %s: %O', 'color: orange; font-weight: bold;', eaname, ea);
                    // var eaUid = (ea.uid ? ea.uid : '');
                    // console.log('%c eaUid: %O', 'color: blue;', eaUid);
                    var eaAccess = (ea.access ? ea.access : null);
                    // console.log('%c eaAccess: %O', 'color: blue;', eaAccess);
                    if (pluginActions && pluginActions.length > 0) {
                        let checkAction = find(pluginActions, function(a) { return a.uid === ea.uid; });
                        // console.log('%c checkAction: %O', 'color: orange; font-weight: bold;', checkAction);
                        if (!checkAction) continue;
                    }

                    var eaMediators = (eaAccess && eaAccess.mediators ? eaAccess.mediators : []);
                    var enableWhen = (ea && ea.enableWhen ? ea.enableWhen : null);
                    var showWhen = (ea && ea.showWhen ? ea.showWhen : null);
                    var showForAllMediators = (eaAccess && eaAccess.showForAllMediators ? true : false);
                    var accessRequiredPermissions = (eaAccess && eaAccess.requiredPermissions ? eaAccess.requiredPermissions : null);

                    var mediatorActions = find(values(eaMediators), function(m) {
                        return m.name === record.udicciMediator;
                    });
                    // console.log('%c mediatorActions: %O', 'color: blue;', mediatorActions);
                    var requiredPermissions = (mediatorActions && mediatorActions.requiredPermissions ? mediatorActions.requiredPermissions : accessRequiredPermissions);
                    // console.log('%c requiredPermissions: %O', 'color: blue;', requiredPermissions);

                    var allowed = (showForAllMediators || mediatorActions !== undefined ? true : false);
                    if (permissions && requiredPermissions && requiredPermissions.length > 0) {
                        allowed = false;
                        var fp = requiredPermissions[0];
                        var comparePerms: any = permissions
                        var permValue = comparePerms[fp.permission];
                        if (fp.required && permValue === true) allowed = true;
                        if (!fp.required) allowed = true;
                    }

                    var showAction = false;
                    var enableAction = false;
                    if (allowed && record) {
                        var mediatorContext = data.find((x: any) => x.mediator === record.udicciMediator );
                        showAction = checkConditionAgainstRecord(record, showWhen, mediatorContext);
                        enableAction = checkConditionAgainstRecord(record, enableWhen, mediatorContext);
                    }

                    // console.log('%c useUdicciRecord getEngagementActionsElement allowed: %O', 'color: red;', allowed);
                    // console.log('%c useUdicciRecord getEngagementActionsElement showAction: %O', 'color: red;', showAction);
                    if (allowed && showAction) {
                        // console.log('%c enableAction: %O', 'color: red;', enableAction);
                        // console.log('%c ea: %O', 'color: blue;', ea);

                        var engagementActionPackages: any = getEngagementActionPackages(engagementActionsProps);
                        // console.log('%c engagementActionPackages: %O', 'color: maroon;', engagementActionPackages);

                        var action: any = null;
                        if (ea && ea.action) {
                            if (engagementActionPackages && engagementActionPackages[ea.action]) {
                                action = engagementActionPackages[ea.action];
                            }
                        }
                        // console.log('%c action: %O', 'color: purple;', action);

                        if (action && action.menu) {
                            var eaListItemKey = 'record.' + record.recordId.toString() + '.engagement.action.' + idx.toString();
                            var engagementMenuItemElement: any = (
                                <EngagementMenuItem key={eaListItemKey}
                                                    record={record} 
                                                    engagementAction={ea} 
                                                    socialSolution={socialSolution} 
                                                    udicciMediator={record.udicciMediator} 
                                                    permissions={permissions}
                                                    enableAction={enableAction}
                                                    onClick={(evt: any) => engageAction(ea)}
                                />
                            );
                            // console.log('%c engagementMenuItemElement: %O', 'color: blue;', engagementMenuItemElement);
                            if (engagementMenuItemElement) engagementActionListItems.push(engagementMenuItemElement);
                        }
                    }
                }
            }
            // console.log('%c useUdicciRecord getEngagementActionsElement engagementActionListItems: %O', 'color: red;', engagementActionListItems);

            if (engagementActionListItems.length <= 0) {
                if (record.permissions.CanView && !record.permissions.CanEdit) {
                    viewRecordButton = (
                        <Button size="small" onClick={onShowRecordForm} variant="text">
                            <Typography variant="body2" component="span">
                                view
                            </Typography>
                            <Typography variant="body2" component="span">
                                .
                            </Typography>
                        </Button>
                    );
                } else if (record.permissions.CanEdit) {
                    // console.log('%c useUdicciRecord getEngagementActionsElement record.permissions.CanEdit: %O', 'color: red;', record.permissions.CanEdit);
                    editRecordButton = (
                        <Button size="small" onClick={onShowRecordForm} variant="text">
                            <Typography variant="body2" component="span">
                                edit
                            </Typography>
                            <Typography variant="body2" component="span">
                                .
                            </Typography>
                        </Button>
                    );
                }

                if (record.permissions.CanDelete) {
                    deleteRecordButton = (
                        <Button size="small" onClick={onDeleteRecord} variant="text">
                            <Typography variant="body2" component="span">
                                delete
                            </Typography>
                            <Typography variant="body2" component="span">
                                .
                            </Typography>
                        </Button>
                    );
                }
            }

            // console.log('%c openRecordMenu: %O', 'color: purple;', openRecordMenu);
            // console.log('%c useUdicciRecord engagementActionListItems: %O', 'color: purple;', engagementActionListItems);
            // console.log('%c useUdicciRecord viewRecordButton: %O', 'color: purple;', viewRecordButton);
            // console.log('%c useUdicciRecord getEngagementActionsElement editRecordButton: %O', 'color: purple;', editRecordButton);
            // console.log('%c useUdicciRecord getEngagementActionsElement deleteRecordButton: %O', 'color: purple;', deleteRecordButton);
        }

        if (forNotification) {
            // console.log('%c useUdicciRecord getEngagementActionsElement record: %O', 'color: blue;', record);
            // console.log('%c useUdicciRecord getEngagementActionsElement forNotification: %O', 'color: blue;', forNotification);

            acknowledgeButton = (
                <Button size="small" disabled={((forNotification.DateAcknowledged !== null) || processingRequest ? true : false)} onClick={(evt:any) => { acknowledgeActivity(false) }} variant="engagementButton1">
                    <AcknowledgeIcon sx={{ marginRight: '4px' }} />
                    <Typography variant="body2" component="span">
                        acknowledge
                    </Typography>
                    <Typography variant="body2" component="span">
                        .
                    </Typography>
                    {processingRequest && (<div>wait</div>)}
                </Button>
            );

            acknowledgeAndHideButton = (
                <Button size="small" disabled={(processingRequest ? true : false)} onClick={(evt:any) => { acknowledgeActivity(true) }} variant="engagementButton2">
                    <AcknowledgeAndHideIcon sx={{ marginRight: '4px' }} />
                    <Typography variant="body2" component="span">
                        hide
                    </Typography>
                    <Typography variant="body2" component="span">
                        .
                    </Typography>
                    {processingRequest && (<div>wait</div>)}
                </Button>
            );

            stackButtonElement = (
                <Button aria-label="Stack this Activity"
                        title="Stack this Activity"
                        color="primary"
                        size="small"
                        onClick={(evt: any) => setShowStackForm(true)}
                >
                    <Icon>dynamic_feed</Icon>
                    <Typography component='span' sx={{ marginLeft: '4px' }}>
                        Stack
                    </Typography>
                </Button>
            );
        }

        engagementActionsElement = (
            <Fragment>
                {engagementActionListItems}
                {viewRecordButton}
                {editRecordButton}
                {deleteRecordButton}
                {acknowledgeButton}
                {acknowledgeAndHideButton}
                {stackButtonElement}
            </Fragment>
        );

        return (
            <Box sx={udicciClasses.itemButtonsContainer}>
                {engagementActionsElement}
            </Box>
        );
    };

    const getEngagementActionElement = (engagementActionProps: any = {}) => {
        // console.log('%c getEngagementActionElement engagementActionProps: %O', 'color: green;', engagementActionProps);

        // console.log('%c getEngagementActionElement engagementAction: %O', 'color: green;', engagementAction);
        if (!engagementAction) return null;

        const hideEngagementActionForm = (evt: any) => {
            // console.log('%c hideEngagementActionForm props: %O', 'color: red;', props);
            engageAction(null);
        }

        var engagementProps: any = {};
        Object.assign(engagementProps, engagementActionProps);
        engagementProps.engagementAction = engagementAction;
        engagementProps.inline = (engagementActionProps.inline !== undefined ? engagementActionProps.inline : false);
        engagementProps.showForm = true;
        engagementProps.record = recordReference;
        engagementProps.socialSolution = socialSolution;
        // engagementProps.onChange = changeEngagementRecordValue;
        engagementProps.onClose = hideEngagementActionForm;
        // console.log('%c getEngagementActionElement engagementProps: %O', 'color: maroon;', engagementProps);
        return ( <RecordEngagement {...engagementProps} /> );    
    };

    const getFootprintElement = (footprintProps: any) => {
        // console.log('%c useUdicciRecord getFootprintElement recordReference: %O', 'color: blue;', recordReference);
        // console.log('%c useUdicciRecord getFootprintElement footprintProps: %O', 'color: blue;', footprintProps);

        var footprintElement: any = null;

        return footprintElement;
    };

    const checkForRecordPerspectives = () => {
        // console.log('%c checkForRecordPerspectives recordReference: %O', 'color: red;', recordReference);
        if (!recordReference) return false;

        var cu = (currentUser ? currentUser : { UdicciUserId: 0 });
        var currentUserId: number = cu.UdicciUserId;
        var sp = (selectedProfile ? selectedProfile : { recordId: 0 });
        var profileId: number = sp.recordId;
    
        let requestJson: GetRecordPerspectivesRequest = {
            UdicciCommand: 'Get Record Perspectives',
            UdicciMediatorName: 'Perspectives',
            SelectedUdicciProfileId: profileId,
            UserId: currentUserId,
            SocialSolutionId: udicci.socialSolutionDefaultMe,
            RecordId: recordReference.recordId
        };
        // console.log('%c checkForRecordPerspectives requestJson: %O', 'color: blue;', requestJson);
    
        sendPreparedRequest(requestJson, {
            onSuccess: checkForRecordPerspectivesCompleted,
            onError: requestFailed,
        });
    };
    
    const checkForRecordPerspectivesCompleted = (result: any, request: any, settings: any) => {
        // console.log('%c checkForRecordPerspectivesCompleted result: %O', 'color: cyan;', result);
        // console.log('%c checkForRecordPerspectivesCompleted request: %O', 'color: cyan;', request);
        // console.log('%c checkForRecordPerspectivesCompleted settings: %O', 'color: cyan;', settings);
    
        let rec: UdicciRecord = recordReference;
        rec.perspectives = result;
        setRecordReference(recordReference);
        setRecord(recordReference);
    }

    const deletePerspective = async (perspective: UdicciRecord, settings: any | null = null) => {
        // console.log('%c useUdicciRecord deletePerspective perspective: %O', 'color: blue;', perspective);
        // console.log('%c useUdicciRecord deletePerspective recordReference: %O', 'color: blue;', recordReference);
        // console.log('%c useUdicciRecord deletePerspective settings: %O', 'color: blue;', settings);
        if (processingRequest) return;
        if (!perspective) return;
        if (!perspective.recordId) return;
        if (perspective.isSaving) return;

        var cu = (currentUser ? currentUser : { UdicciUserId: 0 });
        var currentUserId: number = cu.UdicciUserId;
        var sp = (selectedProfile ? selectedProfile : { recordId: 0 });
        var profileId: number = sp.recordId;

        var request: DeleteRecordRequest = {
            UdicciMediatorName: perspective.udicciMediator,
            UdicciCommand: "Delete Record",
            SelectedUdicciProfileId: profileId,
            SocialSolutionId: socialSolutionId,
            UserId: currentUserId,
            UdicciRecordId: (perspective.recordId ? perspective.recordId : 0),
        }
        // console.log('%c deletePerspective request: %O', 'color: purple;', request);

        var okToContinue = true;
        if (okToContinue) {
            sendPreparedRequest(request, {
                onSuccess: (response: any, request: any, addtnlDetails: any) => deletePerspectiveCompleted(perspective, response, request, settings, addtnlDetails),
                onError: requestFailed
            });
            setProcessingRequest(true);
            perspective.isSaving = true;
            updatePerspective(perspective);
        } else {
            setProcessingRequest(false);
            perspective.isSaving = false;
            updatePerspective(perspective);
        }
    };

    const deletePerspectiveCompleted = (perspective: UdicciRecord, response: any, request: any, settings: any, addtnlDetails: any) => {
        // console.log('%c deletePerspectiveCompleted perspective: %O', 'color: purple;', perspective);
        // console.log('%c deletePerspectiveCompleted request: %O', 'color: purple;', request);
        // console.log('%c deletePerspectiveCompleted settings: %O', 'color: purple;', settings);
        // console.log('%c deletePerspectiveCompleted response: %O', 'color: purple;', response);

        setProcessingRequest(false);

        var savedPerspective: UdicciRecord | null = null;
        if (response && response.length > 0) savedPerspective = response[0];
        // console.log('%c deletePerspectiveCompleted savedPerspective: %O', 'color: purple;', savedPerspective);
        // console.log('%c deletePerspectiveCompleted recordReference: %O', 'color: purple;', recordReference);
        if (savedPerspective) {
            updatePerspective(savedPerspective);
        }
        if (settings && settings.onSuccess) settings.onSuccess(response, request, settings);
    };

    const checkForRecordAttachments = () => {
        // console.log('%c checkForRecordAttachments recordReference: %O', 'color: red;', recordReference);
        if (!recordReference) return false;

        var cu = (currentUser ? currentUser : { UdicciUserId: 0 });
        var currentUserId: number = cu.UdicciUserId;
        var sp = (selectedProfile ? selectedProfile : { recordId: 0 });
        var profileId: number = sp.recordId;

        let requestJson: GetRecordAttachmentsRequest = {
            UdicciCommand: 'Get Record Attachments',
            UdicciMediatorName: 'Attachments',
            SelectedUdicciProfileId: profileId,
            UserId: currentUserId,
            SocialSolutionId: udicci.socialSolutionDefaultMe,
            RecordId: recordReference.recordId
        };
        // console.log('%c checkForRecordAttachments requestJson: %O', 'color: blue;', requestJson);

        sendPreparedRequest(requestJson, {
            onSuccess: checkForRecordAttachmentsCompleted,
            onError: requestFailed,
        });
    };
    
    const checkForRecordAttachmentsCompleted = (result: any, request: any, settings: any) => {
        // console.log('%c checkForRecordAttachmentsCompleted result: %O', 'color: cyan;', result);
        // console.log('%c checkForRecordAttachmentsCompleted request: %O', 'color: cyan;', request);
        // console.log('%c checkForRecordAttachmentsCompleted settings: %O', 'color: cyan;', settings);
    
        let rec: UdicciRecord = recordReference;
        rec.attachments = result;
        setRecordReference(recordReference);
        setRecord(recordReference);
    }

    const deleteAttachment = async (attachment: UdicciRecord, settings: any | null = null) => {
        // console.log('%c useUdicciRecord deleteAttachment attachment: %O', 'color: blue;', attachment);
        // console.log('%c useUdicciRecord deleteAttachment recordReference: %O', 'color: blue;', recordReference);
        // console.log('%c useUdicciRecord deleteAttachment settings: %O', 'color: blue;', settings);
        if (processingRequest) return;
        if (!attachment) return;
        if (!attachment.recordId) return;
        if (attachment.isSaving) return;

        var cu = (currentUser ? currentUser : { UdicciUserId: 0 });
        var currentUserId: number = cu.UdicciUserId;
        var sp = (selectedProfile ? selectedProfile : { recordId: 0 });
        var profileId: number = sp.recordId;

        var request: DeleteRecordRequest = {
            UdicciMediatorName: attachment.udicciMediator,
            UdicciCommand: "Delete Record",
            SelectedUdicciProfileId: profileId,
            SocialSolutionId: socialSolutionId,
            UserId: currentUserId,
            UdicciRecordId: (attachment.recordId ? attachment.recordId : 0),
        }
        // console.log('%c deleteAttachment request: %O', 'color: purple;', request);

        var okToContinue = true;
        if (okToContinue) {
            sendPreparedRequest(request, {
                onSuccess: (response: any, request: any, addtnlDetails: any) => deleteAttachmentCompleted(attachment, response, request, settings, addtnlDetails),
                onError: requestFailed
            });
            setProcessingRequest(true);
            attachment.isSaving = true;
            updateAttachment(attachment);
        } else {
            setProcessingRequest(false);
            attachment.isSaving = false;
            updateAttachment(attachment);
        }
    };

    const deleteAttachmentCompleted = (attachment: UdicciRecord, response: any, request: any, settings: any, addtnlDetails: any) => {
        // console.log('%c deleteAttachmentCompleted attachment: %O', 'color: purple;', attachment);
        // console.log('%c deleteAttachmentCompleted request: %O', 'color: purple;', request);
        // console.log('%c deleteAttachmentCompleted settings: %O', 'color: purple;', settings);
        // console.log('%c deleteAttachmentCompleted response: %O', 'color: purple;', response);

        setProcessingRequest(false);

        var savedAttachment: UdicciRecord | null = null;
        if (response && response.length > 0) savedAttachment = response[0];
        // console.log('%c deleteAttachmentCompleted savedAttachment: %O', 'color: purple;', savedAttachment);
        // console.log('%c deleteAttachmentCompleted recordReference: %O', 'color: purple;', recordReference);
        if (savedAttachment) {
            updateAttachment(savedAttachment);
        }
        if (settings && settings.onSuccess) settings.onSuccess(response, request, settings);
    };

    return {
        setRecord, engageAction, saveRecord, deleteRecord, savePerspective, updatePerspective, deletePerspective,
        getListElement, getFootprintElement, getRecordAvatarElement, checkForRecordPerspectives, checkForRecordAttachments,
        getFormElement, getFieldElement, getRecordContext, saveAttachment, updateAttachment, deleteAttachment,
        getEngagementActionsElement, getEngagementActionElement,
        udicciRecord: recordReference, engagementAction, processingRequest, errorMessage,
        socialSolution: udicciSocialSolution, getSocialSolutionSetting, changeRecordValue,
        structure: udicciMediator, recordContext, contextRetrieved, datasets, onRecordContextChange
    };
}

/**
 * 
 * Usage:
 * 
 */
