import React, { Fragment, useEffect } from 'react';

import { styled, useTheme } from '@mui/material/styles';

import {
    Box, Paper, Typography, Button, IconButton, ButtonGroup, Popper, Grow, ClickAwayListener, MenuList,
    MenuItem, Card, CardContent, CardHeader
} from '@mui/material';

import {
    ArrowDropDown as ArrowDropDownIcon,
    FirstPage as FirstPageIcon,
} from '@mui/icons-material';

import { useUdicciContext, getProfileSetting, setSocialSolution, setFeature } from 'src/context/udicci-context';
import { UdicciListContextProvider } from 'src/context/udicci-list-context';

import UdicciList from './udicci-list';
import { UdicciListContextStateType } from 'src/classes/udicci-types';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

import { find, values, filter } from 'underscore';

const SocialSolutionContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    // background: 'rgb(255 255 255 / 90%)',
    borderRadius: theme.spacing(1),
}));

const SocialSolutions: React.FC<any> = ({ solutions, socialSolution, feature, onloadInstructions, onClose }) => {
    // console.log('%c SocialSolutions solutions: %O', 'color: maroon;', solutions);
    // console.log('%c SocialSolutions socialSolution: %O', 'color: maroon;', socialSolution);
    // console.log('%c SocialSolutions feature: %O', 'color: maroon;', feature);
    // console.log('%c SocialSolutions onloadInstructions: %O', 'color: maroon;', onloadInstructions);
    // console.log('%c SocialSolutions onClose: %O', 'color: maroon;', onClose);
    // const theme = useTheme();
    const udicciHelpers = useUdicciHelpers();

    const [openSolutionMenu, setOpenSolutionMenu] = React.useState(false);
    const [openFeatureMenu, setOpenFeatureMenu] = React.useState(false);
    const [openMediatorMenu, setOpenMediatorMenu] = React.useState(false);
    const [mediatorChanged, setMediatorChanged] = React.useState(false);
    const solutionMenuAnchorRef = React.useRef<HTMLDivElement>(null);
    const featureAnchorRef = React.useRef<HTMLDivElement>(null);
    const mediatorAnchorRef = React.useRef<HTMLDivElement>(null);
    // const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [selectedSocialSolutionId, setSelectedSocialSolutionId] = React.useState<number>((socialSolution && socialSolution.recordId ? socialSolution.recordId : 0));
    const [selectedFeatureId, setFeatureId] = React.useState<number>((feature && feature.UdicciRecordId ? feature.UdicciRecordId : 0));
    const [selectedMediatorId, setMediatorId] = React.useState<number>(0);

    const udicciContext = useUdicciContext();
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    let { udicci, data } = udicciContext.state;
    // console.log('%c SocialSolutions data: %O', 'color: maroon;', data);
    // console.log('%c SocialSolutions udicci: %O', 'color: maroon;', udicci);

    let { selectedProfile, selectedSocialSolution, selectedFeature } = udicci;
    // console.log('%c SocialSolutions selectedProfile: %O', 'color: maroon;', selectedProfile);
    // console.log('%c SocialSolutions selectedSocialSolution: %O', 'color: maroon;', selectedSocialSolution);
    // console.log('%c SocialSolutions selectedFeature: %O', 'color: maroon;', selectedFeature);
    // console.log('%c SocialSolutions currentUser: %O', 'color: maroon;', currentUser);

    let onloadSocialSolution = (onloadInstructions && onloadInstructions.socialSolution ? onloadInstructions.socialSolution : null);
    // console.log('%c onloadSocialSolution: %O', 'color: hotpink;', onloadSocialSolution);
    let onloadFeature = (onloadInstructions && onloadInstructions.feature ? onloadInstructions.feature : null);
    // console.log('%c onloadFeature: %O', 'color: hotpink;', onloadFeature);
    let onloadMediator = (onloadInstructions && onloadInstructions.udicciMediator ? onloadInstructions.udicciMediator : null);
    // console.log('%c onloadMediator: %O', 'color: hotpink;', onloadMediator);

    let profile: any = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    // console.log('%c profile: %O', 'color: hotpink;', profile);
    // let profileSolutions = (profile && profile.SocialSolutions ? profile.SocialSolutions : null);
    // console.log('%c profileSolutions: %O', 'color: purple; font-weight: bold;', profileSolutions);

    let profileType = (profile && profile.ProfileType ? profile.ProfileType : null);
    // console.log('%c profileType: %O', 'color: hotpink;', profileType);
    let publicRole = (profile && profile.PublicRole ? profile.PublicRole : null);
    // console.log('%c publicRole: %O', 'color: hotpink;', publicRole);
    let userRoleOnProfile = (profile && profile.UdicciUserRole ? profile.UdicciUserRole : null);
    if (!userRoleOnProfile) userRoleOnProfile = publicRole;
    // console.log('%c userRoleOnProfile: %O', 'color: hotpink;', userRoleOnProfile);
    let profileSettings = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
    // console.log('%c profileSettings: %O', 'color: hotpink;', profileSettings);
    let socialSolutionSettings = (profileSettings && profileSettings.socialSolutionSettings ? profileSettings.socialSolutionSettings : null);
    // console.log('%c socialSolutionSettings: %O', 'color: hotpink;', socialSolutionSettings);

    let socialSolutionMenuItems: any[] = [];
    let firstSocialSolution: any = null;
    let selSS: any = null;
    if (solutions) {
        values(solutions).forEach(function(ss: any) {
            // console.log('%c ss: %O', 'color: blue;', ss);
            // console.log('%c id: %O', 'color: blue;', id);

            let solution: any = (ss.solution ? ss.solution : null);
            // console.log('%c solution: %O', 'color: hotpink;', solution);
            if (!solution) return true;

            let solutionId = (solution.recordId ? solution.recordId : 0);
            // console.log('%c solutionId: %O', 'color: hotpink;', solutionId);

            if (solutionId === udicci.socialSolutionUdicciIT) {
                if (!udicci.isCurrentUserUlysses()) return true;
            }

            let socialSolutionMediators: any[] = [];
            let features = (ss.features ? ss.features : null);
            // console.log('%c features: %O', 'color: hotpink;', features);
            let permissions = (ss.permissions ? ss.permissions : null);
            if (permissions && permissions.CanView) {
                // console.log('%c permissions: %O', 'color: hotpink;', permissions);
                let ssData = (solution.data ? solution.data : null);
                // console.log('%c ssData: %O', 'color: hotpink;', ssData);

                let ssSettings = (ssData.jsonSettingsJson ? ssData.jsonSettingsJson : null);
                // console.log('%c ssSettings: %O', 'color: hotpink;', ssSettings);

                let ssFeatures = (ssData && ssData.Features ? ssData.Features : null);
                if (features) ssFeatures = features;
                // console.log('%c ssFeatures: %O', 'color: red;', ssFeatures);
                let mediators = (ssSettings && ssSettings.mediators ? ssSettings.mediators : null);
                // console.log('%c mediators: %O', 'color: hotpink;', mediators);
                let engagementActions = (ssSettings && ssSettings.engagementActions ? ssSettings.engagementActions : null);
                // console.log('%c engagementActions: %O', 'color: hotpink;', engagementActions);

                if (mediators !== null && ssFeatures && ssFeatures.length > 0) {
                    for (let [idx, ftre] of Object.entries<any>(ssFeatures)) {
                        // console.log('%c ftre %s: %O', 'color: blue;', ftre.Name, ftre);
                        // console.log('%c idx: %O', 'color: blue;', idx);
                        let featureMediators = filter(values(mediators), function(med: any) {
                            // console.log('%c med %s: %O', 'color: blue;', med.name, med);
                            let isDefaultFeature: boolean = (med.feature === ftre.Name ? true : false);
                            let otherFeatureMediator: any = null;
                            if (med.features && med.features.length > 0) {
                                otherFeatureMediator = find(med.features, function(ftrMed: any) {
                                    return ftrMed.name === ftre.Name;
                                });
                            }
                            // console.log('%c isDefaultFeature: %O', 'color: red;', isDefaultFeature);
                            // console.log('%c otherFeatureMediator: %O', 'color: red;', otherFeatureMediator);
                            return isDefaultFeature || otherFeatureMediator;
                        });
                        // console.log('%c featureMediators: %O', 'color: blue;', featureMediators);
                        if (featureMediators) {
                            // console.log('%c featureMediators: %O', 'color: hotpink;', featureMediators);
                            ssFeatures[idx].Mediators = featureMediators;
                            if (featureMediators.length > 0) {
                                socialSolutionMediators = socialSolutionMediators.concat(featureMediators);
                            }
                        }
                    }
                }

                ss.features = ssFeatures;
                ss.mediators = socialSolutionMediators;
                ss.engagementActions = engagementActions;

                if (ssFeatures && ssFeatures.length > 0) {
                    if (socialSolutionMediators && socialSolutionMediators.length > 0) {
                        if (firstSocialSolution === null) firstSocialSolution = ss;
                        socialSolutionMenuItems.push({
                            id: solution.recordId,
                            value: solution.title,
                            solution: ss
                        });
                        if (!selSS && !selectedSocialSolutionId && onloadSocialSolution && onloadSocialSolution.id) {
                            if (onloadSocialSolution.id === solution.recordId) {
                                selSS = ss;
                            }
                        }
                        if (!selSS && selectedSocialSolutionId === solution.recordId) {
                            selSS = ss;
                        }
                    }
                }
            }
        });
    }
    // console.log('%c socialSolutionMenuItems: %O', 'color: hotpink;', socialSolutionMenuItems);
    // console.log('%c firstSocialSolution: %O', 'color: hotpink;', firstSocialSolution);
    // console.log('%c selectedSocialSolution: %O', 'color: hotpink;', selSS);
    if (selSS === null) selSS = firstSocialSolution;
    // console.log('%c selectedSocialSolution: %O', 'color: hotpink;', selSS);
    // console.log('%c solutions: %O', 'color: hotpink;', solutions);

    useEffect(() => {
        // console.log('%c SocialSolutions selSS: %O', 'color: red;', selSS);
        // console.log('%c SocialSolutions selectedSocialSolution: %O', 'color: red;', selectedSocialSolution);

        let requiresUpdate: boolean = false;
        if (!selectedSocialSolution) {
            requiresUpdate = true;
        } else if (selSS && selectedSocialSolution) {
            let ssid1: number = (selSS.solution && selSS.solution.recordId ? selSS.solution.recordId : 0);
            let ssid2: number = (selectedSocialSolution.solution && selectedSocialSolution.solution.recordId ? selectedSocialSolution.solution.recordId : 0);
            if (ssid1 !== ssid2 && ssid2 > 0) {
                requiresUpdate = true;
            }
        }

        if (requiresUpdate) setSocialSolution(selSS);
    }, [selSS, selectedSocialSolution]);

    let featureMenuItems: any[] = [];
    let firstFeature: any = null;
    let selFtre: any = null;

    let mediatorMenuItems: any[] = [];
    let firstMediator: any = null;
    let selectedMediator: any = null;

    if (selSS && selSS.solution) {
        let sss = (socialSolutionSettings && socialSolutionSettings[selectedSocialSolutionId] ? socialSolutionSettings[selectedSocialSolutionId] : null);
        // console.log('%c sss: %O', 'color: hotpink;', sss);

        let defaultFeature = (sss && sss.defaultFeature ? sss.defaultFeature : null);
        // console.log('%c defaultFeature: %O', 'color: hotpink;', defaultFeature);
        let defaultMediator = (sss && sss.defaultMediator ? sss.defaultMediator : null);
        // console.log('%c defaultMediator: %O', 'color: hotpink;', defaultMediator);

        let ssData = (selSS.solution.data ? selSS.solution.data : null);
        // console.log('%c ssData: %O', 'color: hotpink;', ssData);
        let ssSettings = (ssData.jsonSettingsJson ? ssData.jsonSettingsJson : null);
        // console.log('%c ssSettings: %O', 'color: hotpink;', ssSettings);

        let features = (ssData && ssData.Features ? ssData.Features : null);
        // console.log('%c features: %O', 'color: hotpink;', features);
        let mediators = (ssSettings && ssSettings.mediators ? ssSettings.mediators : null);
        // console.log('%c mediators: %O', 'color: hotpink;', mediators);

        if (mediators !== null && features && features.length > 0) {
            features.forEach(function(ftre: any) {
                // console.log('%c ftre %s: %O', 'color: blue;', ftre.Name, ftre);
                // console.log('%c idx: %O', 'color: blue;', idx);
                let featureMediators = filter(values(mediators), function(med: any) {
                    // console.log('%c med %s: %O', 'color: blue;', med.name, med);
                    let isDefaultFeature: boolean = (med.feature === ftre.Name ? true : false);
                    let otherFeatureMediator: any = null;
                    if (med.features && med.features.length > 0) {
                        otherFeatureMediator = find(med.features, function(ftrMed: any) {
                            return ftrMed.name === ftre.Name;
                        });
                    }
                    // console.log('%c isDefaultFeature: %O', 'color: red;', isDefaultFeature);
                    // console.log('%c otherFeatureMediator: %O', 'color: red;', otherFeatureMediator);
                    return isDefaultFeature || otherFeatureMediator;
                });
                // console.log('%c featureMediators: %O', 'color: blue;', featureMediators);
                if (featureMediators && featureMediators.length > 0) {
                    if (firstFeature === null) firstFeature = ftre;
                    featureMenuItems.push({ value: ftre.Name, id: ftre.UdicciRecordId });
                    if (!selFtre && !selectedFeatureId && onloadFeature && onloadFeature.id) {
                        if (onloadFeature.id === ftre.UdicciRecordId) {
                            selFtre = ftre;
                        }
                    }

                    if (!selFtre && selectedFeatureId === ftre.UdicciRecordId) {
                        selFtre = ftre;
                    } else if (!selFtre && selectedFeatureId <= 0 && (defaultFeature && defaultFeature.id === ftre.UdicciRecordId)) {
                        selFtre = ftre;
                    }
                }
            });
        }
        if (selFtre === null) selFtre = firstFeature;

        if (mediators !== null) {
            values(mediators).forEach(function(mdtr: any) {
                // console.log('%c mdtr: %O', 'color: blue;', mdtr);
                let isDefaultFeature: boolean = (mdtr.feature === selFtre.Name ? true : false);
                let otherFeatureMediator: any = null;
                if (mdtr.features && mdtr.features.length > 0) {
                    otherFeatureMediator = find(mdtr.features, function(ftrMed: any) {
                        return ftrMed.name === selFtre.Name;
                    });
                }
                if (selFtre !== null && (isDefaultFeature || otherFeatureMediator)) {
                    let medTitleText = (mdtr.label ? mdtr.label : mdtr.name);
                    if (!medTitleText) return true;

                    if (firstMediator === null && mdtr.id) firstMediator = mdtr;
                    mediatorMenuItems.push({ value: medTitleText, id: mdtr.id });

                    if (!selectedMediator && !selectedMediatorId && onloadMediator && onloadMediator.id) {
                        if (onloadMediator.id === mdtr.id) {
                            selectedMediator = mdtr;
                        }
                    }

                    if (selectedMediatorId && !selectedMediator && selectedMediatorId === mdtr.id) {
                        selectedMediator = mdtr;
                    } else if (!selectedMediator && selectedMediatorId <= 0 && (defaultMediator && defaultMediator.id === mdtr.id)) {
                        selectedMediator = mdtr;
                    }
                }
            });
        }
        if (selectedMediator === null) selectedMediator = firstMediator;
    }
    // console.log('%c featureMenuItems: %O', 'color: hotpink;', featureMenuItems);
    // console.log('%c selectedFeature: %O', 'color: hotpink;', selFtre);
    // console.log('%c selectedMediator: %O', 'color: hotpink;', selectedMediator);
    // console.log('%c firstFeature: %O', 'color: hotpink;', firstFeature);

    useEffect(() => {
        // console.log('%c SocialSolutions selFtre: %O', 'color: red;', selFtre);
        // console.log('%c SocialSolutions selectedFeature: %O', 'color: red;', selectedFeature);

        let requiresUpdate: boolean = false;
        if (!selectedFeature) {
            requiresUpdate = true;
        } else if (selFtre && selectedFeature) {
            let ftreid1: number = (selFtre.UdicciRecordId ? selFtre.UdicciRecordId : 0);
            let ftreid2: number = (selectedFeature.UdicciRecordId ? selectedFeature.UdicciRecordId : 0);
            if (ftreid1 !== ftreid2 && ftreid2 > 0) {
                requiresUpdate = true;
            }
        }

        if (requiresUpdate) setFeature(selFtre);
    }, [selFtre, selectedFeature]);

    const handleSolutionMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        option: any,
    ) => {
        // console.log('%c handleSolutionMenuItemClick option: %O', 'color: hotpink;', option);
        setSelectedSocialSolutionId(option.id);
        if (mediatorChanged) setMediatorChanged(false);
        setOpenSolutionMenu(false);
        // setSocialSolutionFocus(option.solution);
    };

    const handleToggleSolutionMenu = () => {
        if (mediatorChanged) setMediatorChanged(false);
        setOpenSolutionMenu((prevOpen) => !prevOpen);
    };

    const handleCloseSolutionMenu = (event: any) => {
        if (solutionMenuAnchorRef.current && solutionMenuAnchorRef.current.contains(event.target as HTMLElement)) {
          return;
        }

        if (mediatorChanged) setMediatorChanged(false);
        setOpenSolutionMenu(false);
    };

    const handleFeatureMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        recordId: number,
    ) => {
        setFeatureId(recordId);
        setMediatorId(0);
        if (mediatorChanged) setMediatorChanged(false);
        setOpenFeatureMenu(false);
    };

    const handleMediatorMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        recordId: number,
    ) => {
        if (recordId !== selectedMediatorId) {
            setMediatorChanged(true);
            setMediatorId(recordId);
        } else {
            setMediatorChanged(false);
        }
        setOpenMediatorMenu(false);
    };

    const handleToggleFeatureMenu = () => {
        if (mediatorChanged) setMediatorChanged(false);
        setOpenFeatureMenu((prevOpen) => !prevOpen);
    };

    const handleToggleMediatorMenu = () => {
        if (mediatorChanged) setMediatorChanged(false);
        setOpenMediatorMenu((prevOpen) => !prevOpen);
    };

    const handleCloseFeatureMenu = (event: any) => {
        if (featureAnchorRef.current && featureAnchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
    
        if (mediatorChanged) setMediatorChanged(false);
        setOpenFeatureMenu(false);
    };

    const handleCloseMediatorMenu = (event: any) => {
        if (mediatorAnchorRef.current && mediatorAnchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
    
        if (mediatorChanged) setMediatorChanged(false);
        setOpenMediatorMenu(false);
    };
    
    let socialSolutionMenuElement: any = null;
    let featureMenuElement: any = null;
    let mediatorMenuElement: any = null;

    let hasSocialSolutionMenuItems = (socialSolutionMenuItems && socialSolutionMenuItems.length > 0 ? true : false);
    if (hasSocialSolutionMenuItems) {
        let dropDownArrowButton: any = null;
        //if (!udicci.isMobileDevice()) {
            dropDownArrowButton = (
                <Button size="small"
                    aria-controls={openSolutionMenu ? 'split-button-menu' : undefined}
                    aria-expanded={openSolutionMenu ? 'true' : undefined}
                    aria-label="Select Social Solution"
                    aria-haspopup="menu"
                    onClick={handleToggleSolutionMenu}
                >
                    <ArrowDropDownIcon />
                </Button>
            );
        //}

        socialSolutionMenuElement = (
            <Fragment>
                <ButtonGroup size="small" variant="outlined" color="info" ref={solutionMenuAnchorRef} aria-label="Social Solution Menu">
                    <Button size="small"
                        aria-controls={openSolutionMenu ? 'split-button-menu' : undefined}
                        aria-expanded={openSolutionMenu ? 'true' : undefined}
                        aria-label="Select Social Solution"
                        aria-haspopup="menu"
                        onClick={handleToggleSolutionMenu}
                    >
                        {(selSS.solution ? selSS.solution.title : firstSocialSolution!.solution!.title)}
                        <Typography variant="body2" component="span">
                            .
                        </Typography>
                    </Button>
                    {dropDownArrowButton}
                </ButtonGroup>
                <Popper open={openSolutionMenu} anchorEl={solutionMenuAnchorRef.current} transition>
                    {({ TransitionProps, placement }) => (
                        <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                        >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseSolutionMenu}>
                                <MenuList id="split-button-menu">
                                    {socialSolutionMenuItems.map((option, index) => (
                                        <MenuItem
                                            key={option.id}
                                            selected={option.id === selSS.solution.recordId}
                                            onClick={(event) => handleSolutionMenuItemClick(event, option)}
                                        >
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                        </Grow>
                    )}
                </Popper>        
            </Fragment>
        );
    } else {
        socialSolutionMenuElement = (
            <Typography variant="errorMessage" component="div">
                No Social Solutions Found.
            </Typography>
        );
    }

    let selectedFeatureName = '';
    if (selFtre !== null) selectedFeatureName = selFtre.Name;
    if (selectedFeatureName === '' && firstFeature !== null) selectedFeatureName = firstFeature.Name;

    if (hasSocialSolutionMenuItems) {
        if (featureMenuItems.length > 0) {
            featureMenuElement = (
                <Fragment>
                    <ButtonGroup size="small" variant="outlined" color="info" ref={featureAnchorRef} aria-label="Feature Menu">
                        <Button size="small"
                            aria-controls={openFeatureMenu ? 'split-button-menu' : undefined}
                            aria-expanded={openFeatureMenu ? 'true' : undefined}
                            aria-label="Select Feature"
                            aria-haspopup="menu"
                            onClick={handleToggleFeatureMenu}
                        >
                            {selectedFeatureName}
                            <Typography variant="body2" component="span">
                                .
                            </Typography>
                        </Button>
                        <Button size="small"
                            aria-controls={openFeatureMenu ? 'split-button-menu' : undefined}
                            aria-expanded={openFeatureMenu ? 'true' : undefined}
                            aria-label="Select Feature"
                            aria-haspopup="menu"
                            onClick={handleToggleFeatureMenu}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper open={openFeatureMenu} anchorEl={featureAnchorRef.current} transition>
                        {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                            >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseFeatureMenu}>
                                    <MenuList id="split-button-menu">
                                        {featureMenuItems.map((option, index) => (
                                            <MenuItem
                                                key={option.id}
                                                selected={option.id === selFtre.UdicciRecordId}
                                                onClick={(event) => handleFeatureMenuItemClick(event, option.id)}
                                            >
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                            </Grow>
                        )}
                    </Popper>        
                </Fragment>
            );
        }
    }

    let selectedMediatorLabel = '';
    if (selectedMediator && selectedMediator.label) {
        selectedMediatorLabel = selectedMediator.label;
    } else if (selectedMediator && selectedMediator.name) {
        selectedMediatorLabel = selectedMediator.name;
    } else if (firstMediator && firstMediator.label) {
        selectedMediatorLabel = firstMediator.label;
    } else if (firstMediator && firstMediator.name) {
        selectedMediatorLabel = firstMediator.name;
    }

    if (hasSocialSolutionMenuItems) {
        if (mediatorMenuItems && mediatorMenuItems.length > 0) {
            mediatorMenuElement = (
                <Fragment>
                    <ButtonGroup size="small" variant="outlined" color="primary" ref={mediatorAnchorRef} aria-label="Mediator Menu">
                        <Button size="small"
                            aria-controls={openMediatorMenu ? 'split-button-menu' : undefined}
                            aria-expanded={openMediatorMenu ? 'true' : undefined}
                            aria-label="Select Mediator"
                            aria-haspopup="menu"
                            onClick={handleToggleMediatorMenu}
                        >
                            {selectedMediatorLabel}
                            <Typography variant="body2" component="span">
                                .
                            </Typography>
                        </Button>
                        <Button size="small"
                            aria-controls={openMediatorMenu ? 'split-button-menu' : undefined}
                            aria-expanded={openMediatorMenu ? 'true' : undefined}
                            aria-label="Select Mediator"
                            aria-haspopup="menu"
                            onClick={handleToggleMediatorMenu}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper open={openMediatorMenu} anchorEl={mediatorAnchorRef.current} transition>
                        {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                            >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseMediatorMenu}>
                                    <MenuList id="split-button-menu">
                                        {mediatorMenuItems.map((option, index) => (
                                            <MenuItem
                                                key={option.id}
                                                selected={option.id === selectedMediator.id}
                                                onClick={(event) => handleMediatorMenuItemClick(event, option.id)}
                                            >
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                            </Grow>
                        )}
                    </Popper>        
                </Fragment>
            );
        }        
    }

    let cardHeaderElement: any = null;
    if (profile && profile.DisplayName) {
        cardHeaderElement = (
            <SocialSolutionContainer>
                <div style={{ float: 'left', margin: '-8px', marginRight: '4px' }}>
                    <IconButton onClick={onClose} color="primary">
                        <FirstPageIcon />
                    </IconButton>
                </div>
                {socialSolutionMenuElement}
                {featureMenuElement}
                {mediatorMenuElement}
            </SocialSolutionContainer>
        );
    } else {
        let engageCardHeader = (
            <Typography variant="subtitle1" component="div">
                Engage {(profileType ? 'with ' + profileType.Name : '')}
            </Typography>
        );
        cardHeaderElement = (
            <CardHeader title={engageCardHeader} />
        );
    }

    let displayElement: any = null;

    // console.log('%c selectedSocialSolution: %O', 'color: purple;', selectedSocialSolution);
    // console.log('%c selSS: %O', 'color: purple;', selSS);
    // console.log('%c selectedMediator: %O', 'color: purple;', selectedMediator);
    if (selSS && selSS.solution && selectedMediator) {
        let listFields: any = getProfileSetting(selectedMediator.name, selSS.solution.recordId, 'listFields');
        // console.log('%c listFields: %O', 'color: purple;', listFields);

        let listData: any = null;
        if ((data && selectedMediator)) {
            let mediatorContext = data.find((x: any) => x.mediator === selectedMediator.name );
            // console.log('%c SocialSolutions mediatorContext: %O', 'color: maroon;', mediatorContext);
            if (mediatorContext && mediatorContext.records) listData = mediatorContext.records;
        }
        // console.log('%c SocialSolutions listData: %O', 'color: maroon;', listData);

        let socialSolutionId: number = (selSS.solution.recordId ? selSS.solution.recordId : 0);
        let mediatorId: number = (selectedMediator.id ? selectedMediator.id : 0);
        let contextValue: UdicciListContextStateType = {
            uid: 'social.solutions.' + udicciHelpers.generateUID(),
            data: listData,
            socialSolution: {
                id: selSS.solution.recordId,
                name: selSS.solution.title,
                more: selSS
            },
            feature: {
                id: selFtre.UdicciRecordId,
                name: selFtre.Name,
                more: selFtre
            },
            mediator: {
                id: selectedMediator.id,
                name: selectedMediator.name,
                label: (selectedMediator && selectedMediator.label ? selectedMediator.label : selectedMediator.name)
            },
            settings: {
                listFields: listFields
            },
            record: null,
            breadcrumbs: null,
            onLoadBreadcrumb: null,
            onCloseBreadcrumb: null,
            onCloseAllBreadcrumbs: null,
            onRefresh: null,
            onAddRecord: null,
            onEditRecord: null,
            onEditContextRecord: null,
            onDeleteRecord: null,
            onEngageRecord: null,
            lastFetchResultDate: null
        };

        let contextProviderProps: any = {
            value: contextValue,
            key: 'udicci.list.' + socialSolutionId.toString() + '.' + mediatorId.toString(),
            socialSolution: selSS,
            feature: selFtre,
        };
        displayElement = (
            <UdicciListContextProvider {...contextProviderProps}>
                <UdicciList />
            </UdicciListContextProvider>
        );
    } else {
        displayElement = (
            <Typography variant="errorMessage" component="div">
                Your current role does not give you access to any information at this time.
            </Typography>
        );
    }

    let cardContentElement = (
        <CardContent sx={{ padding: '0 !important' }}>
            <SocialSolutionContainer>
                {displayElement}
            </SocialSolutionContainer>
        </CardContent>
    );

    let contentCards = [];
    contentCards.push(
        <Card key={'content.card'} sx={{ backgroundColor: 'unset' }}>
            {cardHeaderElement}
            {cardContentElement}
        </Card>
    );

    return (<Box> {contentCards} </Box>);
};

export default SocialSolutions;
