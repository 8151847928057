import { Fragment, useState, useContext } from 'react';

import { useTheme } from '@mui/material/styles';

import { countBy, filter, sortBy, forEach, find } from 'underscore';

import useMediaQuery from '@mui/material/useMediaQuery';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Icon from '@mui/material/Icon';

import useUdicciProfile from 'src/hooks/useUdicciProfile';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';

import {
    UdicciContext,
    getUdicciData,
    // subscribe as subscribeToUdicci
} from 'src/context/udicci-context';

import { udicciStyles } from 'src/theme/shared-styles';

export default function ClassRegistrationsUtil(propData: any) {
    // console.log('%c ClassRegistrationsUtil propData: %O', 'color: maroon;', propData);

    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);
    const isPrinting = useMediaQuery("print");
    // console.log('%c isPrinting: %O', 'color: maroon;', isPrinting);

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useContext(UdicciContext);
    let { udicci } = udicciContext.state;

    let { selectedProfile } = udicci;
    // console.log('%c ClassRegistrationsUtil selectedProfile: %O', 'color: maroon;', selectedProfile);
    // console.log('%c ClassRegistrationsUtil currentUser: %O', 'color: maroon;', currentUser);

    const profile = useUdicciProfile(selectedProfile);
    // console.log('%c ClassRegistrationsUtil profile: %O', 'color: darkgoldenrod;', profile);
    var { udicciProfile } = profile;
    // console.log('%c ClassRegistrationsUtil udicciProfile: %O', 'color: darkgoldenrod;', udicciProfile);

    var profileData = (udicciProfile && udicciProfile.data ? udicciProfile.data : null);
    var profileSocialSolutions: any = (profileData && profileData.SocialSolutions ? profileData.SocialSolutions : null);
    // console.log('%c ClassRegistrationsUtil profileSocialSolutions: %O', 'color: maroon;', profileSocialSolutions);

    let youngMindsSocialSolution = find(profileSocialSolutions, function(ymss: any){ return ymss.title === 'Young Minds'; });
    // console.log('%c ClassRegistrationsUtil youngMindsSocialSolution: %O', 'color: maroon;', youngMindsSocialSolution);
    let youngMindsSSId = (youngMindsSocialSolution && youngMindsSocialSolution.recordId ? youngMindsSocialSolution.recordId : 0);
    // console.log('%c ClassRegistrationsUtil youngMindsSSId: %O', 'color: maroon;', youngMindsSSId);

    const [registrationJson, setRegistrationJson] = useState<(string)>("");
    const [registration, setRegistration] = useState<(any)>(null);
    const [registrations, setRegistrations] = useState<(any)[]>([]);
    const [showRegistration, setShowRegistration] = useState<(any)>(null);

    const [selectedTab, setSelectedTab] = useState('All');
    const useSummaryCard = false;

    const handleChangeTabIndex = (evt: any, index: any) => {
        // console.log('%c handleChangeTabIndex index: %O', 'color: maroon;', index);
        setSelectedTab(index);
    };

    function copyStringToClipboard (str: string) {
        // Create new element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        // el.sx = udicciClasses.hiddenTextarea;
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
    }

    async function copyRegistrationsFromClipboard () {
        const newRegistrations = await navigator.clipboard.readText();
        // console.log('%c newRegistrations: %O', 'color: maroon;', newRegistrations);
        let jsonRegistrations: any = null;
        if (newRegistrations !== '') {
            if (newRegistrations !== '') {
                try {
                    jsonRegistrations = JSON.parse(newRegistrations);
                } catch (e: any) {
                    // console.log('%c could not parse registration - %O', 'color: maroon;', e);
        
                    // try wrapping it with brackets to see if that helps
                    try {
                        let retryJson = '{ ' + newRegistrations + ' }';
                        // console.log('%c retryJson: %O', 'color: maroon;', retryJson);
                        jsonRegistrations = JSON.parse(retryJson);
                    } catch (e: any) {
                        // console.log('%c could not parse registrations - %O', 'color: maroon;', e);
                    }
                }
            }
            // console.log('%c jsonRegistrations: %O', 'color: maroon;', jsonRegistrations);
        }

        if (jsonRegistrations !== null) {
            let regs = (jsonRegistrations.registrations ? jsonRegistrations.registrations : []);
            // console.log('%c regs: %O', 'color: orange;', regs);
            setRegistrations(regs);
        }
    }

    function changeRegistrationJson(evt: any) {
        // console.log('%c changeRegistrationJson evt: %O', 'color: red;', evt);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        
        let fixedValue: string = newValue.toString().trim();

        if (fixedValue.length > 0) {
            // we have 2 keys to fix
            fixedValue = fixKeyValuePair(fixedValue, 'Full Name Of Child:', 'Uid:');
            fixedValue = fixKeyValuePair(fixedValue, 'Uid:', 'Child Pickups:');
            fixedValue = fixKeyValuePair(fixedValue, 'Register Child For Class:', 'Permission To Participate In Walking:', 'Date Submitted:');
            fixedValue = fixKeyValuePair(fixedValue, 'Permission To Participate In Walking:', 'Permission To Use Personal Info Outside Ctns:');
            fixedValue = fixKeyValuePair(fixedValue, 'Permission To Use Personal Info Outside Ctns:', 'Parent Signature:');
            fixedValue = fixKeyValuePair(fixedValue, 'Parent Signature:','Parent Typed Signature:');
            fixedValue = fixKeyValuePair(fixedValue, 'Parent Typed Signature:', 'Date Of Signature:');
            fixedValue = fixKeyValuePair(fixedValue, 'Date Of Signature:', 'Register Child For Class:', 'Date Submitted:');
            fixedValue = fixKeyValuePair(fixedValue, 'Date Submitted:', '');

            // now fix the keys
            fixedValue = fixedValue.replace('Children:', '"Children":');
            fixedValue = fixedValue.replace('Parent Guardians:', ',"ParentGuardians":');
            fixedValue = fixedValue.replace('Household Members:', ',"HouseholdMembers":');
            fixedValue = fixedValue.replace('Siblings:', ',"Siblings":');
            fixedValue = fixedValue.replace('Full Name Of Child:', ',"FullNameOfChild":');
            fixedValue = fixedValue.replace('Uid:', ',"uid":');
            fixedValue = fixedValue.replace('Child Pickups:', ',"ChildPickups":');
            fixedValue = fixedValue.replace('Emergency Contacts:', ',"EmergencyContacts":');
            fixedValue = fixedValue.replace('Allergies:', ',"Allergies":');
            fixedValue = fixedValue.replace('Register Child For Class:', ',"RegisterChildForClass":');
            fixedValue = fixedValue.replace('Parent Signature:', ',"ParentSignature":');
            fixedValue = fixedValue.replace('Permission To Participate In Walking:', ',"PermissionToParticipateInWalking":');
            fixedValue = fixedValue.replace('Permission To Use Personal Info Outside Ctns:', ',"PermissionToUsePersonalInfoOutsideCtns":');
            fixedValue = fixedValue.replace('Parent Typed Signature:', ',"ParentTypedSignature":');
            fixedValue = fixedValue.replace('Date Of Signature:', ',"DateOfSignature":');
            fixedValue = fixedValue.replace('Date Submitted:', ',"DateSubmitted":');
        }

        setRegistrationJson(fixedValue);

        let jsonRegistration: any = null;
        if (fixedValue !== '') {
            try {
                jsonRegistration = JSON.parse(fixedValue);
            } catch (e: any) {
                // console.log('%c could not parse registration - %O', 'color: maroon;', e);
    
                // try wrapping it with brackets to see if that helps
                try {
                    let retryJson = '{ ' + fixedValue + ' }';
                    // console.log('%c retryJson: %O', 'color: maroon;', retryJson);
                    jsonRegistration = JSON.parse(retryJson);
                } catch (e: any) {
                    // console.log('%c could not parse registration - %O', 'color: maroon;', e);
                }
            }
        }
        // console.log('%c jsonRegistration: %O', 'color: maroon;', jsonRegistration);
        setRegistration(jsonRegistration);
    }
    // console.log('%c registrationJson: %O', 'color: maroon;', registrationJson);

    function fixKeyValuePair(stringVal: string, keyToFind: string, nextKeyToFind: string, altNextKeyToFind: string = '') {
        let startPosOfKey = 0;
        let endPosOfKey = 0;
        let startPosOfNextKey = 0;

        startPosOfKey = stringVal.indexOf(keyToFind);
        endPosOfKey = startPosOfKey + keyToFind.length - 1;

        if (nextKeyToFind.length > 0) {
            startPosOfNextKey = stringVal.indexOf(nextKeyToFind);
        } else {
            startPosOfNextKey = stringVal.length + 1;
        }

        // console.log('%c keyToFind: %O', 'color: red;', keyToFind);
        // console.log('%c startPosOfKey: %O', 'color: red;', startPosOfKey);
        // console.log('%c endPosOfKey: %O', 'color: red;', endPosOfKey);

        // console.log('%c nextKeyToFind: %O', 'color: red;', nextKeyToFind);
        // console.log('%c startPosOfNextKey: %O', 'color: red;', startPosOfNextKey);

        // console.log('%c start character: %O', 'color: red;', stringVal.substr(startPosOfKey, 1));
        // console.log('%c end character: %O', 'color: red;', stringVal.substr(endPosOfKey, 1));
        // console.log('%c next key start character: %O', 'color: red;', stringVal.substr(startPosOfNextKey, 1));

        let val = '';
        if (startPosOfKey >= 0 && endPosOfKey > startPosOfKey && startPosOfNextKey > endPosOfKey) {
            for (let x = endPosOfKey + 1; x < startPosOfNextKey - 1; x++) {
                val += stringVal.substr(x, 1);
            }
        }
        // val = val.trim();
        // console.log('%c val: %O', 'color: red;', val);

        let corrected = false;
        if (val.length > 0) {
            stringVal = stringVal.replace(val, ' "' + val.trim() + '"');
            corrected = true;
        }
        // console.log('%c corrected: %O', 'color: red;', corrected);

        if (!corrected && altNextKeyToFind !== '') {
            startPosOfNextKey = stringVal.indexOf(altNextKeyToFind);
            val = '';
            if (startPosOfKey >= 0 && endPosOfKey > startPosOfKey && startPosOfNextKey > endPosOfKey) {
                for (let x = endPosOfKey + 1; x < startPosOfNextKey - 1; x++) {
                    val += stringVal.substr(x, 1);
                }
            }
            // val = val.trim();
            // console.log('%c val: %O', 'color: red;', val);

            if (val.length > 0) {
                stringVal = stringVal.replace(val, ' "' + val.trim() + '"');
                corrected = true;
            }
            // console.log('%c corrected: %O', 'color: red;', corrected);
        }

        return stringVal;
    }

    const addRegistration = (evt: any) => {
        // console.log('%c addRegistration registration: %O', 'color: red;', registration);
        if (registration !== null) {
            // console.log('%c addRegistration registrations: %O', 'color: red;', registrations);

            let chkReg = registrations.find((x: any) => {
                return x.uid === registration.uid 
                        && x.FullNameOfChild === registration.FullNameOfChild
                        && x.ParentTypedSignature === registration.ParentTypedSignature
                        && x.RegisterChildForClass === registration.RegisterChildForClass
            });
            // console.log('%c addRegistration chkReg: %O', 'color: red;', chkReg);
            if (chkReg === undefined || chkReg == null) {
                registrations.push(registration);
            }
        }

        setRegistrations(registrations);
        setRegistration(null);
        setRegistrationJson('');
    }

    const exportRegistrations = (format: string = 'json', evt: any) => {
        // console.log('%c exportRegistrations format: %O', 'color: red;', format);
        // console.log('%c exportRegistrations registrations: %O', 'color: red;', registrations);

        let rval: string = '';
        if (format === 'json') {
            rval = JSON.stringify({
                registrations: registrations
            });
        } else {
            let filteredRegistrations = registrations;
            if (selectedTab === 'All') {
                filteredRegistrations = registrations;
            } else {
                filteredRegistrations = filter(filteredRegistrations, function(reg){ return reg.RegisterChildForClass === selectedTab; });
            }
            // console.log('%c filteredRegistrations: %O', 'color: maroon;', filteredRegistrations);

            let recordCount: number = 0;
            let csvLines: string[] = [];
            filteredRegistrations.map((reg: any) => {
                recordCount++;

                let csvLineValues: string[] = [];

                // let dateOfSignature = (reg['DateOfSignature'] ? reg['DateOfSignature'] : '');
                // let registerChildForClass = (reg['RegisterChildForClass'] ? reg['RegisterChildForClass'] : '');
                let children = (reg['Children'] ? reg['Children'] : []);
                // let siblings = (reg['Siblings'] ? reg['Siblings'] : []);
                let parents = (reg['ParentGuardians'] ? reg['ParentGuardians'] : []);
                // let programSessions = (reg['ProgramSessions'] ? reg['ProgramSessions'] : []);
                // let pickups = (reg['ChildPickups'] ? reg['ChildPickups'] : []);
                // let household = (reg['HouseholdMembers'] ? reg['HouseholdMembers'] : []);
                // let emergencyContacts = (reg['EmergencyContacts'] ? reg['EmergencyContacts'] : []);
                // console.log('%c children: %O', 'color: blue;', children);
                // console.log('%c siblings: %O', 'color: blue;', siblings);
                // console.log('%c parents: %O', 'color: blue;', parents);
                // console.log('%c pickups: %O', 'color: blue;', pickups);
                // console.log('%c household: %O', 'color: blue;', household);
                // console.log('%c emergencyContacts: %O', 'color: blue;', emergencyContacts);

                let child = (children.length > 0 ? children[0] : null);
                // console.log('%c child: %O', 'color: blue;', child);
                let firstParent = (parents.length > 0 ? parents[0] : null);
                // console.log('%c firstParent: %O', 'color: blue;', firstParent);

                let parentName = (firstParent && firstParent.FirstName);
                if (parentName.length > 0 && firstParent && firstParent.LastName) parentName += ' ';
                parentName += (firstParent && firstParent.LastName);

                csvLineValues.push(recordCount.toString());
                csvLineValues.push(reg.FullNameOfChild);
                csvLineValues.push(parentName);
                csvLineValues.push(firstParent && firstParent.EmailAddress);
                csvLineValues.push(firstParent && (firstParent.CellPhone || firstParent.HomePhone));
                csvLineValues.push(child && child.DateOfBirth);
                csvLineValues.push(child && child.Gender);
                csvLineValues.push(reg && reg.RegisterChildForClass);

                csvLines.push(csvLineValues.join(","));
                return true;
            });
            rval = csvLines.join("\r\n");
        }
        // console.log('%c exportRegistrations rval: %s', 'color: red;', rval);
        copyStringToClipboard(rval);
        alert('Registrations have been copied to your clipboard.');
    }

    // console.log('%c registrations: %O', 'color: maroon;', registrations);
    // console.log('%c showRegistration: %O', 'color: maroon;', showRegistration);

    let schoolPrograms: any = (youngMindsSSId > 0 ? udicciHelpers.preloadMediatorData('Programs', youngMindsSSId) : null);
    // console.log('%c schoolPrograms: %O', 'color: maroon;', schoolPrograms);

    let registrationsForList = registrations;
    if (selectedTab === 'Summary' || selectedTab === 'All') {
        registrationsForList = registrations;
        registrationsForList = sortBy(registrationsForList, 'FullNameOfChild');
        registrationsForList = sortBy(registrationsForList, 'RegisterChildForClass');
    } else {
        registrationsForList = filter(registrationsForList, function(reg){ return reg.RegisterChildForClass === selectedTab; });
        registrationsForList = sortBy(registrationsForList, 'FullNameOfChild');
    }
    // console.log('%c registrationsForList: %O', 'color: maroon;', registrationsForList);

    let txtFldLabel = '';
    if (registrationJson !== '') {
        txtFldLabel = 'Registration Json';
    } else {
        txtFldLabel = 'Paste Registration Json here';
    }

    let showListInTable = (selectedTab === 'Summary' ? true : false);
    let registrationsListElement: any = null;
    if (showListInTable) {
        let registrationTableHeaderRow = (<TableRow>
                <TableCell>
                    <Typography variant="subtitle2" color="textPrimary" component="span">
                        Class
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" color="textPrimary" component="span">
                        Child Name
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" color="textPrimary" component="span">
                        Child's Date of Birth
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" color="textPrimary" component="span">
                        Parent Name
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" color="textPrimary" component="span">
                        Email
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" color="textPrimary" component="span">
                        Home Phone
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" color="textPrimary" component="span">
                        Cell Phone
                    </Typography>
                </TableCell>
            </TableRow>);

        registrationsListElement = (
            <TableContainer>
                <Table size={'small'}>
                    <TableHead> {registrationTableHeaderRow} </TableHead>
                    <TableBody>
                        {registrationsForList.map((reg: any) => {
                            // console.log('%c reg: %O', 'color: blue;', reg);
                            // let dateOfSignature = (reg['DateOfSignature'] ? reg['DateOfSignature'] : '');
                            let children = (reg['Children'] ? reg['Children'] : []);
                            // let siblings = (reg['Siblings'] ? reg['Siblings'] : []);
                            let parents = (reg['ParentGuardians'] ? reg['ParentGuardians'] : []);
                            // let pickups = (reg['ChildPickups'] ? reg['ChildPickups'] : []);
                            // let household = (reg['HouseholdMembers'] ? reg['HouseholdMembers'] : []);
                            // let emergencyContacts = (reg['EmergencyContacts'] ? reg['EmergencyContacts'] : []);
                            // console.log('%c children: %O', 'color: blue;', children);
                            // console.log('%c siblings: %O', 'color: blue;', siblings);
                            // console.log('%c parents: %O', 'color: blue;', parents);
                            // console.log('%c pickups: %O', 'color: blue;', pickups);
                            // console.log('%c household: %O', 'color: blue;', household);
                            // console.log('%c emergencyContacts: %O', 'color: blue;', emergencyContacts);
                            let selectedProgramSessions = (reg['ProgramSessions'] ? reg['ProgramSessions'] : []);

                            let registerChildForClass = (reg.RegisterChildForClass ? reg.RegisterChildForClass : '');
                            // console.log('%c registerChildForClass: %O', 'color: blue;', registerChildForClass);

                            let child = (children.length > 0 ? children[0] : null);
                            // console.log('%c child: %O', 'color: blue;', child);
                            let parent = (parents && parents.length > 0 ? parents[0] : null);
                            // console.log('%c parent: %O', 'color: blue;', parent);

                            let childName = (reg.FullNameOfChild ? reg.FullNameOfChild : '');
                            let childDateOfBirth = (child && child.DateOfBirth ? child.DateOfBirth : '');

                            let parentName = (reg.ParentTypedSignature ? reg.ParentTypedSignature : '');
                            let parentEmail = (parent && parent.EmailAddress ? parent.EmailAddress : '');
                            let parentCellNumber = (parent && parent.CellPhone ? parent.CellPhone : '');
                            let parentHomeNumber = (parent && parent.HomePhone ? parent.HomePhone : '');

                            let selectedProgram = find(schoolPrograms, function(schoolProgram: any){ return schoolProgram.title === registerChildForClass; });
                            // console.log('%c selectedProgram: %O', 'color: maroon;', selectedProgram);
                            let selectedProgramData = (selectedProgram && selectedProgram.data ? selectedProgram.data : null);
                            // console.log('%c selectedProgramData: %O', 'color: maroon;', selectedProgramData);
                            let programSessions = (selectedProgramData && selectedProgramData.jsonSessions ? selectedProgramData.jsonSessions : []);
                            // console.log('%c programSessions: %O', 'color: maroon;', programSessions);
                            let programSessionsData = (programSessions && programSessions.data ? programSessions.data : []);
                            // console.log('%c programSessionsData: %O', 'color: maroon;', programSessionsData);

                            // console.log('%c selectedProgramSessions: %O', 'color: maroon;', selectedProgramSessions);
                            let programsSelectedElement = (
                                <Fragment>
                                    {programSessionsData.map((programSession: string) => {
                                        let programSessionIcon: any = null;
                                        if (selectedProgramSessions.length > 0) {
                                            if (selectedProgramSessions.indexOf(programSession) >= 0) {
                                                programSessionIcon = (<Icon color="primary">check_circle</Icon>);
                                            } else {
                                                programSessionIcon = (<Icon color="secondary">block</Icon>);
                                            }
                                        }else {
                                            programSessionIcon = (<Icon color="primary">check_circle</Icon>);
                                        }

                                        return (
                                            <div key={'registration.' + reg.uid + '.' + programSession.replace(' ', '.').toLowerCase()}>
                                                <Typography component="span" variant="caption" color="secondary">
                                                    {programSession}
                                                </Typography>
                                                {programSessionIcon}
                                            </div>
                                        );
                                    })}
                                </Fragment>
                            );
                                            
                            let registrationTableRow = (
                                <TableRow key={'registration.' + reg.uid}>
                                    <TableCell>
                                        <Typography variant="body2" color="secondary" component="span">
                                            {registerChildForClass}
                                        </Typography>
                                        {programsSelectedElement}
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" color="secondary" component="span">
                                            {childName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" color="textPrimary" component="span">
                                            {childDateOfBirth}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" color="textPrimary" component="span">
                                            {parentName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" color="textPrimary" component="span">
                                            {parentEmail}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" color="textPrimary" component="span">
                                            {parentHomeNumber}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" color="textPrimary" component="span">
                                            {parentCellNumber}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
    
                            return registrationTableRow;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else {
        let registrationCards: any[] = [];
        forEach(registrationsForList, function(reg: any) {
            let regContent = getRegistrationDisplay(reg, schoolPrograms);
            // console.log('%c regContent: %O', 'color: pink;', regContent);
            registrationCards.push(
                <Box sx={udicciClasses.pageBreakContainer}>
                    {regContent}
                </Box>
            );
        });
        // console.log('%c registrationCards: %O', 'color: pink;', registrationCards);

        // console.log('%c isPrinting: %O', 'color: pink;', isPrinting);
        if (!isPrinting && useSummaryCard) {
            registrationsListElement = (
                <Box sx={udicciClasses.mainArea}>
                    {registrationCards}
                </Box>
            );
        } else {
            registrationsListElement = (
                <Box sx={udicciClasses.pageBreakParentContainer}>
                    {registrationCards.map((regCard: any) => {
                        // console.log('%c regCard: %O', 'color: orange;', regCard);
                        return (<Fragment>{regCard}</Fragment>);
                    })}
                </Box>
            );
        }
    }

    let tabs = [];

    tabs.push(
        <Tab label={(
                <Fragment>
                    <Typography component="span">Full Summary</Typography>
                    <Typography variant="caption" component="span">{registrations.length}</Typography>
                </Fragment>
            )}
            value={'Summary'}
            id={"registration-tab-summary"}
            aria-controls={"registration-summary"}
            key="registrations.list.summary"
        />
    );

    tabs.push(
        <Tab label={(
                <Fragment>
                    <Typography component="span">All</Typography>
                    <Typography variant="caption" component="span">{registrations.length}</Typography>
                </Fragment>
            )}
            value={'All'}
            id={"registration-tab-all"}
            aria-controls={"registration-all"}
            key="registrations.list.all"
        />
    );

    if (registrations.length > 0) {
        let uniqueClassRegistrations = countBy(registrations, "RegisterChildForClass");
        // console.log('%c uniqueClassRegistrations: %O', 'color: maroon;', uniqueClassRegistrations);

        for (let [registerForClass, classRegistrationCount] of Object.entries<any>(uniqueClassRegistrations)) {
            // console.log('%c registerForClass: %O', 'color: blue;', registerForClass);
            // console.log('%c classRegistrationCount: %O', 'color: blue;', classRegistrationCount);
            tabs.push(
                <Tab label={(
                        <Fragment>
                            <Typography component="span" variant="body2">{registerForClass}</Typography>
                            <Typography variant="caption" component="span">{classRegistrationCount}</Typography>
                        </Fragment>
                    )}
                    value={registerForClass}
                    id={"registration-tab-" + registerForClass.replace(' ', '-').toLowerCase()}
                    aria-controls={"registration-" + registerForClass.replace(' ', '-').toLowerCase()}
                    key={"registrations.for.class" + registerForClass.replace(' ', '.').toLowerCase()}
                />
            );
        }
    }

    let registrationFullForm: any = null;
    if (showRegistration !== null) {
        let dialogRegistrationContent = getRegistrationDisplay(showRegistration, schoolPrograms);
        registrationFullForm = (
            <Dialog open={true} onClose={(() => setShowRegistration(null))} fullWidth={true} maxWidth={'md'}>
                <DialogTitle>Registration</DialogTitle>
                <DialogContent>
                    {dialogRegistrationContent}
                </DialogContent>
                <DialogActions>
                    <Box sx={udicciClasses.grow} />
                    <Button size="small" color="secondary" onClick={(() => setShowRegistration(null))}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    let buttonDisabled = (registration === null ? true : false);
    let exportButtonDisabled = (registrationsForList.length <= 0 ? true : false);

    let registrationsHeader: any = null;
    if (!isPrinting) {
        registrationsHeader = (
            <Fragment>
                <Box sx={udicciClasses.mainArea}>
                    <Button variant="contained" disabled={buttonDisabled} onClick={addRegistration} color="primary" size="small" sx={udicciClasses.headerButton}>
                        Add Registration to List
                    </Button>
                    <Typography variant="subtitle1" color="primary" component="span">
                        Registrations Utility
                    </Typography>
                </Box>
                <Box sx={udicciClasses.mainArea}>
                    <TextField
                        label={txtFldLabel}
                        variant="outlined"
                        multiline fullWidth autoFocus
                        minRows={3}
                        maxRows={15}
                        value={registrationJson}
                        onChange={changeRegistrationJson}
                    />
                </Box>
            </Fragment>
        );
    }

    let registrationsListHeader: any = null;
    if (!isPrinting) {
        registrationsListHeader = (
            <Box sx={udicciClasses.mainArea}>
                <Button variant="contained" disabled={exportButtonDisabled} onClick={(evt) => exportRegistrations('csv', evt)} color="primary" size="small" sx={udicciClasses.headerButton}>
                    Export CSV
                </Button>
                <Button variant="contained" disabled={exportButtonDisabled} onClick={(evt) => exportRegistrations('json', evt)} color="primary" size="small" sx={udicciClasses.headerButton}>
                    Export Json
                </Button>
                <Button variant="contained" onClick={copyRegistrationsFromClipboard} color="info" size="small" sx={udicciClasses.headerButton}>
                    Import Registrations
                </Button>
                <Typography variant="subtitle1" color="primary" component="span">
                    Current Registrations
                </Typography>
            </Box>
        );
    }

    let tabFiltersElement: any = null;
    if (!isPrinting) {
        tabFiltersElement = (
            <Box sx={udicciClasses.mainArea}>
                <AppBar position="static" color="default">
                    <Tabs value={selectedTab} onChange={handleChangeTabIndex} indicatorColor="primary" textColor="primary" variant="fullWidth">
                        {tabs}
                    </Tabs>
                </AppBar>
                {registrationsListElement}
            </Box>
        );
    } else {
        tabFiltersElement = (
            <Box sx={udicciClasses.mainArea}>
                {registrationsListElement}
            </Box>
        );
    }

    return (
        <div id="registration-form-parser">
            {registrationsHeader}
            {registrationsListHeader}
            {tabFiltersElement}
            {registrationFullForm}
        </div>
    );

    function getRegistrationDisplay(reg: any, schoolPrograms: any) {
        // console.log('%c getRegistrationDisplay registration: %O', 'color: purple;', reg);
        // console.log('%c getRegistrationDisplay schoolPrograms: %O', 'color: purple;', schoolPrograms);
        let registerChildForClass = (reg['RegisterChildForClass'] ? reg['RegisterChildForClass'] : '');
        let children = (reg['Children'] ? reg['Children'] : []);
        let siblings = (reg['Siblings'] ? reg['Siblings'] : []);
        let parents = (reg['ParentGuardians'] ? reg['ParentGuardians'] : []);
        let selectedProgramSessions = (reg['ProgramSessions'] ? reg['ProgramSessions'] : []);
        let pickups = (reg['ChildPickups'] ? reg['ChildPickups'] : []);
        let household = (reg['HouseholdMembers'] ? reg['HouseholdMembers'] : []);
        let emergencyContacts = (reg['EmergencyContacts'] ? reg['EmergencyContacts'] : []);
        let allergies = (reg['Allergies'] ? reg['Allergies'] : null);
        let permissionToParticipateInWalking = (reg['PermissionToParticipateInWalking'] ? reg['PermissionToParticipateInWalking'] : '');
        let permissionToUsePersonalInfoOutsideCtns = (reg['PermissionToUsePersonalInfoOutsideCtns'] ? reg['PermissionToUsePersonalInfoOutsideCtns'] : '');
        // console.log('%c selectedProgramSessions: %O', 'color: blue;', selectedProgramSessions);
        // console.log('%c children: %O', 'color: blue;', children);
        // console.log('%c siblings: %O', 'color: blue;', siblings);
        // console.log('%c parents: %O', 'color: blue;', parents);
        // console.log('%c pickups: %O', 'color: blue;', pickups);
        // console.log('%c household: %O', 'color: blue;', household);
        // console.log('%c emergencyContacts: %O', 'color: blue;', emergencyContacts);
        // console.log('%c allergies: %O', 'color: blue;', allergies);

        // let descriptionOfAllergy = (allergies && allergies['DescriptionOfAllergy'] ? allergies['DescriptionOfAllergy'] : []);
        // let allergyActionPlan = (allergies && allergies['AllergyActionPlan'] ? allergies['AllergyActionPlan'] : []);

        let child = (children.length > 0 ? children[0] : null);
        // console.log('%c child: %O', 'color: blue;', child);
        let firstParent = (parents.length > 0 ? parents[0] : null);
        // console.log('%c firstParent: %O', 'color: blue;', firstParent);

        let cardKey = 'registration.' + reg.uid;
        if (isPrinting) cardKey += '.print'
        cardKey += '.' + reg.FullNameOfChild.trim().toLowerCase().replace(new RegExp(' ', 'g'), '.');
        cardKey += '.' + reg.RegisterChildForClass.trim().toLowerCase().replace(new RegExp(' ', 'g'), '.');
        // console.log('%c cardKey: %O', 'color: blue;', cardKey);

        let selectedProgram = find(schoolPrograms, function(schoolProgram: any){ return schoolProgram.title === registerChildForClass; });
        // console.log('%c selectedProgram: %O', 'color: maroon;', selectedProgram);
        let selectedProgramData = (selectedProgram && selectedProgram.data ? selectedProgram.data : null);
        // console.log('%c selectedProgramData: %O', 'color: maroon;', selectedProgramData);
        let programSessions = (selectedProgramData && selectedProgramData.jsonSessions ? selectedProgramData.jsonSessions : null);
        // console.log('%c programSessions: %O', 'color: maroon;', programSessions);
        let programSessionsData = (programSessions && programSessions.data ? programSessions.data : []);
        // console.log('%c programSessionsData: %O', 'color: maroon;', programSessionsData);

        // console.log('%c selectedProgramSessions: %O', 'color: maroon;', selectedProgramSessions);
        let programsSelectedElement = (
            <Fragment>
                {programSessionsData.map((programSession: string) => {
                    // console.log('%c programSession: %O', 'color: maroon;', programSession);
                    let programSessionIcon: any = null;
                    if (selectedProgramSessions.length > 0) {
                        if (selectedProgramSessions.indexOf(programSession) >= 0) {
                            programSessionIcon = (<Icon color="primary">check_circle</Icon>);
                        } else {
                            programSessionIcon = (<Icon color="secondary">block</Icon>);
                        }
                    }else {
                        programSessionIcon = (<Icon color="primary">check_circle</Icon>);
                    }

                    return (
                        <div key={cardKey + '.' + programSession.replace(' ', '.').toLowerCase()}>
                            <Typography component="span" variant="caption" color={("secondary")}>
                                {programSession}
                            </Typography>
                            {programSessionIcon}
                        </div>
                    );
                })}
            </Fragment>
        );

        let allergiesElement: any = null;
        if (allergies && !allergies.NoKnownAllergies && allergies.DescriptionOfAllergy) {
            allergiesElement = (
                <Box sx={udicciClasses.sectionContent}>
                    <Box sx={udicciClasses.blockContentAllergies}>
                        <Typography variant="body2" color="textPrimary">
                            Description Of Allergy: {allergies.DescriptionOfAllergy}
                        </Typography>
                        <Typography variant="body2" color="textPrimary">
                            Action Plan: {allergies.AllergyActionPlan}
                        </Typography>
                    </Box>
                </Box>
            );
        }
        let householdMembersElement: any = null;
        if (household.length > 0) {
            householdMembersElement = (
                <Box sx={udicciClasses.sectionContent}>
                    <Typography variant="caption" color="primary">
                        Other Household Members
                    </Typography>
                    <Box sx={udicciClasses.blockContent}>
                        {household.map((hh: any) => {
                            return (
                                <Box>
                                    <Typography variant="caption" color="textPrimary" component="span">
                                        {(hh.Name)}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            );
        }

        let parentsElement: any = (
            <Box sx={udicciClasses.sectionContent}>
                <Typography variant="caption" color="primary">
                    Parents
                </Typography>
                {parents.map((parent: any) => {
                    let homeAddress: any = (parent.HomeAddress ? parent.HomeAddress : null);
                    let homeAddressElement: any = null;
                    if (homeAddress) {
                        homeAddressElement = (
                            <Box>
                                <Typography variant="caption" component="span">
                                    Home Address: 
                                </Typography>
                                <Typography variant="body2" color="secondary" component="div">
                                    {homeAddress.StreetOne}
                                </Typography>
                                <Typography variant="body2" color="secondary" component="span">
                                    {(homeAddress.StreetTwo ? homeAddress.StreetTwo : '')}
                                </Typography>
                                <Typography variant="body2" color="secondary" component="span">
                                    {(homeAddress.City ? homeAddress.City : '')}{(homeAddress.State ? ' ' + homeAddress.State : '')}
                                    {(homeAddress.ZipCode ? homeAddress.ZipCode : '')}
                                </Typography>
                            </Box>
                        );
                    }
                    return (
                        <Box sx={udicciClasses.blockContent}>
                            <Typography variant="body2" color="primary">
                                {(parent.FirstName)} {(parent.LastName)}
                            </Typography>
                            <Box>
                                <Typography variant="caption" color="textPrimary" component="span">
                                    Cell Phone: 
                                </Typography>
                                &nbsp;&nbsp;
                                <Typography variant="body2" color="secondary" component="span">
                                    {(parent.CellPhone)}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color="textPrimary" variant="caption" component="span">
                                    Home Phone: 
                                </Typography>
                                &nbsp;&nbsp;
                                <Typography variant="body2" color="secondary" component="span">
                                    {(parent.HomePhone)}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color="textPrimary" variant="caption" component="span">
                                    Work Phone: 
                                </Typography>
                                &nbsp;&nbsp;
                                <Typography variant="body2" color="secondary" component="span">
                                    {(parent.WorkPhone)}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color="textPrimary" variant="caption" component="span">
                                    Email: 
                                </Typography>
                                &nbsp;&nbsp;
                                <Typography variant="body2" color="secondary" component="span">
                                    {(parent.EmailAddress)}
                                </Typography>
                            </Box>
                            {homeAddressElement}
                            <Box>
                                <Typography color="textPrimary" variant="caption" component="span">
                                    Occupation: 
                                </Typography>
                                &nbsp;&nbsp;
                                <Typography variant="body2" color="secondary" component="span">
                                    {(parent.Occupation)}
                                </Typography>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        );

        let emergencyContactsElement: any = (
            <Box sx={udicciClasses.sectionContent}>
                <Typography variant="caption" color="primary">
                    Emergency Contacts
                </Typography>
                {emergencyContacts.map((contact: any) => {
                    let phone1: any = null;
                    let phone2: any = null;
                    let phone3: any = null;

                    if (contact.FirstPhoneNumber !== '') {
                        phone1 = (
                            <Box>
                                <Typography variant="caption" component="span">
                                    First Contact Phone: 
                                </Typography>
                                <Typography variant="body2" color="secondary" component="span">
                                    {(contact.FirstPhoneNumber)}
                                </Typography>
                            </Box>
                        );
                    }

                    if (contact.SecondPhoneNumber !== '') {
                        phone2 = (
                            <Box>
                                <Typography variant="caption" component="span">
                                    Second Contact Phone: 
                                </Typography>
                                <Typography variant="body2" color="secondary" component="span">
                                    {(contact.SecondPhoneNumber)}
                                </Typography>
                            </Box>
                        );
                    }

                    if (contact.ThirdPhoneNumber !== '') {
                        phone3 = (
                            <Box>
                                <Typography variant="caption" component="span">
                                    Third Contact Phone: 
                                </Typography>
                                <Typography variant="body2" color="secondary" component="span">
                                    {(contact.ThirdPhoneNumber)}
                                </Typography>
                            </Box>
                        );
                    }

                    return (
                        <Box sx={udicciClasses.blockContent}>
                            <Typography variant="body2" color="textPrimary">
                                {(contact.Name)} ({(contact.RelationshipToChild)})
                            </Typography>
                            {phone1}
                            {phone2}
                            {phone3}
                        </Box>
                    );
                })}
            </Box>
        );

        let pickupsElement: any = (
            <Box sx={udicciClasses.sectionContent}>
                <Typography variant="caption" color="primary">
                    Pickups
                </Typography>
                <Box sx={udicciClasses.blockContent}>
                    {pickups.map((person: any) => {
                        return (
                            <Box>
                                <Typography variant="body2" color="secondary" component="span">
                                    {(person.Name)}
                                </Typography>
                                &nbsp;&nbsp;
                                <Typography variant="caption" color="primary" component="span">
                                    {(person.RelationshipToChild)}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        );

        let siblingsElement: any = (
            <Box sx={udicciClasses.sectionContent}>
                <Typography variant="caption" color="primary">
                    Siblings
                </Typography>
                <Box sx={udicciClasses.blockContent}>
                    {(siblings.length > 0 ? null : (
                        <Box>
                            <Typography variant="caption">
                                no siblings
                            </Typography>
                        </Box>
                    ))}
                    {siblings.map((sibling: any) => {
                        return (
                            <Box>
                                <Typography variant="body2" color="textPrimary" component="span">
                                    {(sibling.Name)}
                                </Typography>
                                &nbsp;&nbsp;
                                <Typography variant="caption" component="span">
                                    {(sibling.Age)}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        );

        let permissionsElement: any = (
            <Box sx={udicciClasses.sectionContent}>
                <Typography variant="caption" color="primary" component="div">
                    Permissions:
                </Typography>
                <Box sx={udicciClasses.blockContent}>
                    <Typography variant="caption" color="initial" component="div">
                        Participate in Walking: {permissionToParticipateInWalking}
                    </Typography>
                    <Typography variant="caption" color="initial" component="div">
                        Use Personal Info Outside CTNS: {permissionToUsePersonalInfoOutsideCtns}
                    </Typography>
                </Box>
            </Box>
        );

        let studentDetailsElement: any = (
            <Box sx={udicciClasses.sectionContent}>
                <Typography variant="caption" color="primary">
                    About {(child && child.FirstName)}:
                </Typography>
                <Box sx={udicciClasses.blockContent}>
                    <Box>
                        <Typography variant="caption" component="span">
                            Nickname:
                        </Typography>
                        &nbsp;&nbsp;
                        <Typography variant="body2" color="secondary" component="span">
                            {(child && child.Nickname)}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="caption" component="span">
                            Date of Birth: 
                        </Typography>
                        &nbsp;&nbsp;
                        <Typography variant="body2" color="secondary" component="span">
                            {(child && child.DateOfBirth)}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="caption" component="span">
                            Gender:
                        </Typography>
                        &nbsp;&nbsp;
                        <Typography variant="body2" color="secondary" component="span">
                            {(child && child.Gender)}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="caption" component="span">
                            Special Needs:
                        </Typography>
                        &nbsp;&nbsp;
                        <Typography variant="body2" color="secondary" component="span">
                            {(child && child.SpecialNeeds)}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="caption" component="span">
                            Allergies:
                        </Typography>
                        &nbsp;&nbsp;
                        <Typography variant="body2" color="secondary" component="span">
                            {(allergies && allergies.NoKnownAllergies ? 'No Known Allergies' : 'Has Allergies')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );

        let studentHeaderElement: any = (
            <Box sx={udicciClasses.headerContent}>
                <Typography variant="subtitle1" color="secondary">
                    {(child && child.FirstName)} {(child && child.LastName)}
                </Typography>
            </Box>
        );

        // let selectedProgramsElementSx: any = {
        //     marginBottom: (isPrinting ? '48px' : '16px'),
        //     pageBreakAfter: 'always',
        // };
        //  sx={selectedProgramsElementSx}
        let selectedProgramsElement: any = (
            <Box sx={udicciClasses.sectionContent}>
                <Typography variant="body1" color="primary">
                    {(child && child.ProgramRequested)}
                </Typography>
                {programsSelectedElement}
            </Box>
        );

        let cardContent: any = null;
        if (!isPrinting && useSummaryCard) {
            cardContent = (
                <Grid item xs={12} md={4} key={cardKey} onClick={(() => setShowRegistration(reg))}>
                    <Paper sx={udicciClasses.paper}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" color="primary">
                                    {(child && child.FirstName)} {(child && child.LastName)}
                                </Typography>
                                <Typography variant="body2" color="textPrimary">
                                    Date of Birth: {(child && child.DateOfBirth)}
                                </Typography>
                                <Typography variant="caption" component="div">
                                    Special Needs: {(child && child.SpecialNeeds)}
                                </Typography>
                                <Typography variant={(allergies && allergies.NoKnownAllergies ? "caption" : "body1")} component="div" color={(allergies && allergies.NoKnownAllergies ? "initial" : "error")}>
                                    Allergies: {(allergies && allergies.NoKnownAllergies ? 'No Known Allergies' : 'Has Allergies')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle2" color="primary">
                                    Parent
                                </Typography>
                                <Typography variant="body2" color="textPrimary">
                                    {(firstParent && firstParent.FirstName)} {(firstParent && firstParent.LastName)}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Phone: {((firstParent && firstParent.CellPhone) || (firstParent && firstParent.HomePhone))}
                                </Typography>
                                <Typography variant="caption">
                                    Email: {(firstParent && firstParent.EmailAddress)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography variant="body2" color="primary">
                                    {(child && child.ProgramRequested)}
                                </Typography>
                                {programsSelectedElement}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );
        } else {
            cardContent = (
                <Grid container spacing={1} key={cardKey}>
                    <Grid item sm={12} md={12}> {studentHeaderElement} </Grid>
                    <Grid container sm={12} md={12}>
                        <Grid item xs={4} md={4}>
                            {parentsElement}
                        </Grid>
                        <Grid item xs={4} md={4}>
                            {studentDetailsElement}
                            {allergiesElement}
                            {siblingsElement}
                            {householdMembersElement}
                            {permissionsElement}
                        </Grid>
                        <Grid item xs={4} md={4}>
                            {pickupsElement}
                            {emergencyContactsElement}
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={12}> {selectedProgramsElement} </Grid>
                </Grid>
            );
        }

        // console.log('%c cardContent: %O', 'color: red;', cardContent);
        return cardContent;
    }
}
