
import { SaveConfig } from './Save/SaveConfig';
import { SaveUI } from './Save/SaveUI';
import { SaveMenu } from './Save/SaveMenuItem';
import { SaveHelp } from './Save/SaveHelp';

const triggerAction = async (options: any) =>  {
    // console.log('%c Save triggerAction options: %O', 'color: blue;', options);
    return false;
}

const submitAction = async (options: any) =>  {
    // console.log('%c Save submitAction options: %O', 'color: blue;', options);
    return false;
}

const Save: React.FC<any> = (props) => {
    // console.log('%c Save props: %O', 'color: blue;', props);

    let configureAction = (props && props.configure === true ? true : false);

    let actionProps: any = {};
    Object.assign(actionProps, props);
    // console.log('%c actionProps: %O', 'color: red;', actionProps);

    let actionElement: any = null;
    if (configureAction) {
        actionElement = (<SaveConfig {...actionProps} />);
    } else {
        actionElement = (<SaveUI {...actionProps} />);
    }
    return actionElement;
}

export { Save, SaveMenu, SaveHelp, triggerAction, submitAction }
