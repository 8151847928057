import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/AddToQueue';

const PluginDisplayComponent = dynamic(() => import('src/components/relationships-list'));
const PluginAdminComponent = dynamic(() => import('src/admin/relationships-list-admin'));

const PluginId: string = 'relationships.list';
const PluginTitle: string = 'Engaged Relationships';
const PluginDescription: string = 'Engaged Relationships use the Mediation Agreements to show relationships associated with a Portal or associated with the logged in user.  This is the Plugin that is loaded from "Relationships" option found in the Platform menu at the upper right of the page.';
const PluginVersion: number = 1;

const PluginRenderer = (props: any) => {
    // console.log('%c PluginRenderer props: %O', 'color: red;', props);
    if (!props.data) props.data = {};
    props.data.showInline = true;
    // console.log('%c PluginRenderer props.data: %O', 'color: red;', props.data);
    return ( <PluginDisplayComponent {...props} /> );
};

const PluginAdminRenderer = (props: any) => {
    // console.log('%c PluginAdminRenderer props: %O', 'color: red;', props);
    if (!props.data) props.data = {};
    props.data.showInline = true;
    // console.log('%c PluginAdminRenderer props.data: %O', 'color: red;', props.data);
    return ( <PluginAdminComponent {...props} /> );
};

const RelationshipsList: UdicciPlugin = {
    Renderer: PluginRenderer,
    id: PluginId,
    title: PluginTitle,
    description: PluginDescription,
    version: PluginVersion,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: PluginAdminRenderer },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c RelationshipsList canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c RelationshipsList canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
    },
    category: UdicciPluginCategory.User
};
export default RelationshipsList;