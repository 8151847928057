import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/AddToQueue';

const Me = dynamic(() => import('src/components/me'));
const ProfileCardAdmin = dynamic(() => import('src/admin/profile-card-admin'));

const PluginId: string = 'profile.card';
const PluginTitle: string = 'Profile Card';
const PluginDescription: string = 'Display the Default Profile Card for a Portal.';
const PluginVersion: number = 1;

const PluginRenderer = (props: any) => {
    // console.log('%c PluginRenderer props: %O', 'color: red;', props);
    if (!props.data) props.data = {};
    props.data.showInline = true;
    // console.log('%c PluginRenderer props.data: %O', 'color: red;', props.data);
    return ( <Me {...props} fullWidth={true} /> );
};

const PluginAdminRenderer = (props: any) => {
    // console.log('%c PluginAdminRenderer props: %O', 'color: red;', props);
    if (!props.data) props.data = {};
    props.data.showInline = true;
    // console.log('%c PluginAdminRenderer props.data: %O', 'color: red;', props.data);
    return ( <ProfileCardAdmin {...props} /> );
};

const PluginComponent: UdicciPlugin = {
    Renderer: PluginRenderer,
    id: PluginId,
    title: PluginTitle,
    description: PluginDescription,
    version: PluginVersion,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: PluginAdminRenderer },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c ProfileCard(Me) canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c ProfileCard(Me) canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
    },
    category: UdicciPluginCategory.Special
};
export default PluginComponent;