
import { Fragment, useState } from 'react';

import { useTheme } from '@mui/material/styles';

import Pluralize from 'pluralize';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import CircularProgress from '@mui/material/CircularProgress';

import ShowMatchesIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import HideMatchesIcon from '@mui/icons-material/SupervisedUserCircleSharp';
import useUdicciRecord from 'src/hooks/useUdicciRecord';

import { udicciStyles } from 'src/theme/shared-styles';

export const CreateProfileFormHeader: React.FC<any> = ({
    record, onClose, matchingProfiles, engagementData, showPotentialMatches, onTogglePotentialMatches, onSetEngagementData
}) => {
    // console.log('%c CreateProfileFormHeader record: %O', 'color: purple;', record);
    // console.log('%c CreateProfileFormHeader engagementData: %O', 'color: purple;', engagementData);
    // console.log('%c matchingProfiles: %O', 'color: blue;', matchingProfiles);

    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const [matchedProfile, setMatchedProfile] = useState<any | null>(null);
    const [matchError, setMatchError] = useState<string>('');

    let { saveRecord } = useUdicciRecord(record);
    // console.log('%c SaveMenu udicciRecord: %O', 'color: blue;', udicciRecord);

    let createProfile: any = {};
    if (engagementData && engagementData.createProfile) {
        createProfile = engagementData.createProfile;
    }
    // console.log('%c CreateProfileFormHeader createProfile: %O', 'color: blue;', createProfile);

    const togglePossibleMatches = (evt: any) => {
        let newValueShowPotentialMatches = !showPotentialMatches;
        // console.log('%c togglePossibleMatches newValueShowPotentialMatches: %O', 'color: maroon;', newValueShowPotentialMatches);

        setMatchedProfile(null);
        if (onTogglePotentialMatches)
            onTogglePotentialMatches(newValueShowPotentialMatches);
        if (onSetEngagementData)
            onSetEngagementData(engagementData);
    };

    const toggleMatchSelection = (possibleMatchingProfile: any) => {
        // console.log('%c togglematchSelection possibleMatchingProfile: %O', 'color: red;', possibleMatchingProfile);
        let newSelectedMatch: any = matchedProfile;
        if (!matchedProfile || (matchedProfile && matchedProfile.UdicciRecordId !== possibleMatchingProfile.UdicciRecordId)) {
            newSelectedMatch = possibleMatchingProfile;
        } else {
            newSelectedMatch = null;
        }
        // console.log('%c newSelectedMatch: %O', 'color: red;', newSelectedMatch);
        setMatchedProfile(newSelectedMatch);
    };

    const cancelAction = (evt: any) => {
        if (onClose) onClose();
    };

    const associateMatchSelectionSuccess = (result: any = null, request: any = null) => {
        // console.log('%c associateMatchSelectionSuccess (result: %O, request: %O)', 'color: red;', result, request);
        if (Array.isArray(result) && result.length > 0) {
            // just close the action window, it worked ok.
            if (onClose) onClose();
        } else if (result && result.success === false) {
            // uh oh, spaghettios ... it didn't work
            setMatchError(result.Message);
        } else {
            // well, something unexpected ... let's show it
            // console.log('%c associateMatchSelectionSuccess result: %O', 'color: red;', result);
            // console.log('%c associateMatchSelectionSuccess request: %O', 'color: red;', request);
        }
    }
    
    const associateMatchSelection = () => {
        // console.log('%c matchedProfile: %O', 'color: blue;', matchedProfile);
        if (!matchedProfile) return null;

        // console.log('%c record: %O', 'color: blue;', record);
        if (record && (record.udicciMediator === 'People' || record.udicciMediator === 'Organizations')) {
            let recordSettings: any = null;
            if (record.data && record.data.jsonAdditionalInformation) {
                recordSettings = record.data.jsonAdditionalInformation;
            }
            // console.log('%c recordSettings: %O', 'color: red; font-weight: bold;', recordSettings);
            if (!recordSettings) recordSettings = {};

            if (record.udicciMediator === 'People') {
                if (!recordSettings.meProfile) recordSettings.meProfile = {};

                recordSettings.meProfile.displayName = matchedProfile.DisplayName;
                recordSettings.meProfile.headline = matchedProfile.Headline;
                recordSettings.meProfile.profileUrl = matchedProfile.ProfileUrl;
                recordSettings.meProfile.socialIcon = matchedProfile.SocialIcon;
                recordSettings.meProfile.udicciRecordId = matchedProfile.UdicciRecordId;
                // console.log('%c recordSettings: %O', 'color: red; font-weight: bold;', recordSettings);
            }

            if (record.udicciMediator === 'Organizations') {
                if (!recordSettings.profile) recordSettings.profile = {};

                recordSettings.profile.displayName = matchedProfile.DisplayName;
                recordSettings.profile.headline = matchedProfile.Headline;
                recordSettings.profile.profileUrl = matchedProfile.ProfileUrl;
                recordSettings.profile.socialIcon = matchedProfile.SocialIcon;
                recordSettings.profile.udicciRecordId = matchedProfile.UdicciRecordId;
                // console.log('%c recordSettings: %O', 'color: red; font-weight: bold;', recordSettings);
            }

            // console.log('%c recordSettings: %O', 'color: red; font-weight: bold;', recordSettings);
            record.data.jsonAdditionalInformation = recordSettings;

            if (recordSettings) {
                record.data.AdditionalInformation = JSON.stringify(recordSettings);
            } else {
                record.data.AdditionalInformation = "";
            }

            record.isDirty = true;

            let saveRecordSettings: any = { onSuccess: associateMatchSelectionSuccess };
            // console.log('%c saveRecordSettings: %O', 'color: blue;', saveRecordSettings);
            saveRecord(saveRecordSettings);
        }
    }

    let profileRecord = (createProfile && createProfile.profileRecord ? createProfile.profileRecord : null);
    // console.log('%c profileRecord: %O', 'color: blue;', profileRecord);
    let udicciMediator = (profileRecord && profileRecord.udicciMediator ? profileRecord.udicciMediator : '');

    // let createProfileAction = null;
    // if (engagementAction && engagementAction.createProfile) {
    //     createProfileAction = engagementAction.createProfile;
    // }
    // console.log('%c createProfileAction: %O', 'color: blue;', createProfileAction);

    let profileDisplayName: string = (profileRecord && profileRecord.data.DisplayName ? profileRecord.data.DisplayName : null);
    // console.log('%c profileDisplayName: %O', 'color: blue;', profileDisplayName);
    let toggleMatchesIconButton = null;
    if (showPotentialMatches) {
        toggleMatchesIconButton = (<HideMatchesIcon />);
    } else {
        toggleMatchesIconButton = (<ShowMatchesIcon />);
    }
    // let associateMatchEventHandler = this.associateMatchSelection.bind(this, selectedMatch);
    let iconTitle = 'Checking for matches...';
    let toggleDisabled = false;
    let noMatchesMessageElement = null;
    let badgeContent = '';
    // let toggleColor = 'default';
    let potentialMatchesElement = null;
    if (matchingProfiles && matchingProfiles.length > 0) {
        // console.log('%c matchingProfiles: %O', 'color: blue;', matchingProfiles);
        let matchCount = matchingProfiles.length;
        // console.log('%c matchCount: %O', 'color: blue;', matchCount);
        if (showPotentialMatches) {
            let mediatorNameSingular = (udicciMediator ? Pluralize.singular(udicciMediator) : udicciMediator);
            // console.log('mediatorNameSingular: %O', mediatorNameSingular);
            potentialMatchesElement = (
                <div>
                    <Typography component="div" variant="caption">
                        Potential matches found.  Click on a profile to highlight it as the profile that
                        matches the {mediatorNameSingular}, then click on the "Associate" button below.
                    </Typography>
                    <Grid container direction="row" spacing={1} justifyContent="flex-start" alignItems="stretch" alignContent="stretch">
                        {matchingProfiles.map((possibleMatchingProfile: any) => {
                            // console.log('%c possibleMatchingProfile: %O', 'color: red;', possibleMatchingProfile);
                            let profileDisplayName = (possibleMatchingProfile.DisplayName ? possibleMatchingProfile.DisplayName : 'Udicci Profile');
                            let profileHeadline = (possibleMatchingProfile.Headline ? possibleMatchingProfile.Headline : '');
                            let profileSocialIcon = null;
                            if (possibleMatchingProfile.SocialIcon) {
                                profileSocialIcon = (
                                    <Avatar alt={profileDisplayName}
                                            src={possibleMatchingProfile.SocialIcon}
                                            component='span'
                                    />
                                );
                            }

                            let itemStyle: any = { cursor: 'pointer' };
                            if (matchedProfile && matchedProfile.UdicciRecordId === possibleMatchingProfile.UdicciRecordId) {
                                itemStyle.border = '1px solid lime';
                                itemStyle.backgroundColor = '#f8f8f8';
                            }
                            let itemKey = 'possible.matching.profile.' + possibleMatchingProfile.UdicciRecordId;
                            return (
                                <Grid item xs={12} sm={6} md={3} key={itemKey}
                                    onClick={(evt: any) => toggleMatchSelection(possibleMatchingProfile)}
                                >
                                    <Paper style={itemStyle} sx={udicciClasses.contentArea}>
                                        <div>
                                            <Typography component="div" variant="subtitle2" color='secondary'>
                                                {profileSocialIcon}
                                                {profileDisplayName}
                                            </Typography>
                                            <Typography component="div" variant="body2" color='textSecondary'>
                                                {profileHeadline}
                                            </Typography>
                                            <Typography component="div" variant="caption">
                                                {possibleMatchingProfile.ProfileUrl}
                                            </Typography>
                                        </div>
                                    </Paper>
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            );
        }
        iconTitle = 'Found ' + matchCount + ' possible match' + (matchCount > 1 ? 'es' : '') + '.';
        badgeContent = matchCount.toString();
        // toggleColor = 'primary';
    } else if (matchingProfiles && matchingProfiles.length <= 0) {
        iconTitle = ' no matches found.';
        toggleDisabled = true;
        noMatchesMessageElement = (
            <Typography component="span" variant="caption" color="textSecondary">
                {iconTitle}
            </Typography>
        );
    } else {
        iconTitle = ' checking for matches...';
        toggleDisabled = true;
        noMatchesMessageElement = (
            <Typography component="span" variant="caption" color="textSecondary">
                <CircularProgress size={7} />
                {iconTitle}
            </Typography>
        );
    }

    let togglePotentialMatchesElement = (
        <Fragment>
            <Badge badgeContent={badgeContent} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <IconButton aria-label={iconTitle}
                    title={iconTitle}
                    sx={udicciClasses.cardMenuIcon}
                    disabled={toggleDisabled}
                    onClick={togglePossibleMatches}
                >
                    {toggleMatchesIconButton}
                </IconButton>
            </Badge>
            {noMatchesMessageElement}
        </Fragment>
    );

    let mediatorName = (record && record.udicciMediator ? record.udicciMediator : '');
    // console.log('mediatorName: %O', mediatorName);

    let mediatorNameSingular = (mediatorName ? Pluralize.singular(mediatorName) : mediatorName);
    // console.log('mediatorNameSingular: %O', mediatorNameSingular);

    let submitActionButton: any = null;
    let cancelActionButton: any = null;
    if (potentialMatchesElement) {
        submitActionButton = (
            <Button onClick={associateMatchSelection} disabled={(matchedProfile && !record.isSaving ? false : true)} color="primary" autoFocus>
                Associate {mediatorNameSingular} with Selected Profile
            </Button>
        );

        cancelActionButton = (
            <Button onClick={cancelAction} color="info" disabled={(!record.isSaving ? false : true)}>
                Cancel
            </Button>
        );
    }

    // console.log('%c matchError: %O', 'color: red;', matchError);
    let matchErrorElement: any = null;
    if (matchError !== '') {
        matchErrorElement = (
            <Box sx={udicciClasses.errorContentArea}>
                <Typography variant="body2" color="error">
                    A problem was encountered trying to associate the selected profile.
                </Typography>
                <Typography variant="caption" color="primary">
                    {matchError}
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Box>
                <Typography component="span" variant="subtitle2" color='secondary'>
                    {profileDisplayName}
                </Typography>
                {togglePotentialMatchesElement}
            </Box>
            {matchErrorElement}
            {potentialMatchesElement}
            {submitActionButton}
            {cancelActionButton}
        </Box>
    );
};
