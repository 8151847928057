import dynamic from 'next/dynamic';

import { UdicciPlugin, UdicciPluginCategory } from 'src/classes/udicci-types';
import PluginIcon from '@mui/icons-material/Login';

const Login = dynamic(() => import('src/components/login'));
const LoginAdmin = dynamic(() => import('src/admin/login-admin'));

const loginPlugin: UdicciPlugin = {
    Renderer: (props: any) => {
        // console.log('%c loginPlugin Renderer props: %O', 'color: red;', props);
        if (!props.data) props.data = {};
        props.data.showInline = true;
        // console.log('%c loginPlugin Renderer props.data: %O', 'color: red;', props.data);
        return ( <Login {...props} /> );
    },
    id: 'login',
    title: 'Login',
    description: 'Login form for users to login to the Udicci Platform and interact and engage with Udicci Portals.',
    version: 1,
    icon: (<PluginIcon />),
    controls: { type: 'admin', Component: (data: any) => ( <LoginAdmin {...data} /> ) },
    hideInMenu: false,
    canPick: (props: any) => {
        // console.log('%c loginPlugin canPick props: %O', 'color: red;', props);
        let canPickResult: boolean = true;
        // console.log('%c canPickResult: %O', 'color: red;', canPickResult);
        return canPickResult;
    },
    canDisplay: (props: any) => {
        // console.log('%c loginPlugin canDisplay props: %O', 'color: red;', props);
        let canDisplayResult: boolean = true;
        // console.log('%c canDisplayResult: %O', 'color: red;', canDisplayResult);
        return canDisplayResult;
     },
    category: UdicciPluginCategory.User
};
export default loginPlugin;