
import React, { useState } from 'react';
import UrlParser from 'url-parse';

import { styled, useTheme } from '@mui/material/styles';

import UdicciLogo from 'src/images/Udicci 100.png';
import { withUdicciTheme } from './udicci-hoc';

import { forEach } from 'underscore';

import {
    Box, Paper, Typography, Button, IconButton, Avatar, Grid, Snackbar, CircularProgress, LinearProgress
} from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import {
    Login as LoginIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

import { InstructionSetLog, InstructionSet, Instruction } from 'src/classes/udicci-types';

import {
    useUdicciContext,
    sendPreparedRequest,
    saveProfile as saveSelectedProfile,
    formatStringForDisplay,
    onChangeProfile,
    subscribe as subscribeToUdicci
} from 'src/context/udicci-context'

import LoginForm from './login';
import EngagementForm from './engagement-form';
import SocialSolutionsMenu from './social-solutions-menu';
import ConfigureProfileSettings from "./configure-profile-settings";
import ConfigureContactCardSettings from "./configure-contact-card-settings";
import ManageProfileRoles from "./manage-profile-roles";
import ChangePasswordForm from "./change-password-form";
import ConfigProfileSolutions from './configure-portal-permissions';
// import Onboarding from './onboarding';

import { SendInvitationRequest } from 'src/interfaces/udicci-request-interfaces';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { udicciStyles } from 'src/theme/shared-styles';

const PortaPaneContainer = styled('div')(({ theme }) => ({
    padding: '1px',
    // background: 'rgb(255 255 255 / 90%)',
    borderRadius: theme.spacing(1),
}));

const PaneContainer = styled('div')(({ theme }) => ({
    // border: '0.7px dashed #132274',
    borderRadius: theme.spacing(1),
    margin: theme.spacing(1),
    background: 'rgba(255, 255, 255, 0.1)',
    // display: 'flex',
}));

const PaneSubContent = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
    textAlign: 'center',
    background: 'rgba(255, 255, 255, 0.1)',
    // display: 'flex',
    borderRadius: theme.spacing(1),
}));

const ShowMoreButton = styled(Button)(({ theme }) => ({
    marginLeft: 'auto',
    maxHeight: '21px',
    borderRadius: theme.spacing(2),
    textTransform: 'none'
}))

const EngageButton = styled(Button)(({ theme }) => ({
    maxHeight: '21px',
    borderRadius: theme.spacing(2),
    background: '#132274'
}))

const UserEngageButton = styled(Button)(({ theme }) => ({
    maxHeight: '21px',
    borderRadius: theme.spacing(2),
    background: 'rgb(222 113 5 / 77%) !important',
    color: 'rgb(118 255 3 / 90%)',
}))

const PortaContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    // margin: '8px',
    // height: '100%',
}));

// const styledButton = styled(Button)`
//     background-color: ${(props: StyledButtonProps) => props.primary ? "palevioletred" : "white"};
//     box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
//     padding: 7px 14px;

//     &:hover {
//         background-color: aqua;
//     }

//     & .MuiButton-label {
//         color: #070303;
//     }
// ` as React.ComponentType<StyledButtonProps>

export function Me(props: any) {
    // console.log('%c Me props: %O', 'color: maroon;', props);
    const theme = useTheme();
    // console.log('%c Me theme: %O', 'color: maroon;', theme);
    const udicciClasses = udicciStyles(theme);
    const udicciHelpers = useUdicciHelpers();

    const udicciContext = useUdicciContext();
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    // const theme = useTheme();

    const [showEngageForm, toggleShowEngageForm] = useState<boolean>(false);
    const [showSocialSolutions, toggleShowSocialSolutions] = useState<boolean>(false);
    const [sendingInvitation, setSendingInvitation] = useState<boolean>(false);
    const [sentInvitation, setSentInvitation] = useState<boolean>(false);
    const [invitationMessage, setInvitationMessage] = useState<string>('');
    const [savingProfile, setSavingProfile] = useState<boolean>(false);

    const [showLogin, setShowLogin] = React.useState(false);
    const [showPortalContactConfig, setShowPortalContactConfiguration] = React.useState(false);
    const [showPortalRoles, setShowPortalRoles] = React.useState(false);
    const [showPortalSolutions, setShowPortalSolutions] = React.useState(false);
    const [showContactInfo, toggleContactInfo] = useState<any>(null);
    const [showAdditionalDetails, toggleAdditionalDetails] = useState<any>(null);

    const showChangePassword = (props && props.showChangePassword !== undefined ? props.showChangePassword : false);
    const showPortalConfig = (props && props.showPortalConfig !== undefined ? props.showPortalConfig : false);

    // const [chatUsername, setChatUsername] = useState<string>('tim');
    // const [messageToSend, setMessageToSend] = useState<string>('');
    // const [messages, setMessages] = useState<any[]>([]);
    // const [lastMessage, setLastMessage] = useState<any>(null);
    // console.log('%c Me lastMessage: %O', 'color: maroon;', lastMessage);
    // console.log('%c Me messages: %O', 'color: maroon;', messages);

    // var { allowHeader, allowConsole } = props;
    // console.log('%c Me allowHeader: %O', 'color: maroon;', allowHeader);
    // console.log('%c Me allowConsole: %O', 'color: maroon;', allowConsole);
    // console.log('%c Me showHeader: %O', 'color: maroon;', showHeader);

    var { udicci } = udicciContext.state;
    // console.log('%c Me data: %O', 'color: maroon;', data);
    // console.log('%c Me udicci: %O', 'color: maroon;', udicci);

    var {
        selectedProfile, currentUser, instructionProcessingLogs
    } = udicci;
    // console.log('%c Me selectedProfile: %O', 'color: maroon;', selectedProfile);
    // console.log('%c Me currentUser: %O', 'color: maroon;', currentUser);
    // console.log('%c Me instructionProcessingLogs: %O', 'color: maroon;', instructionProcessingLogs);

    var curUserId: number = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
    // console.log('%c Me curUserId: %O', 'color: maroon;', curUserId);

    const [showProfile, toggleShowProfile] = useState<boolean>((curUserId <= 0 ? true : false));

    var profileIsDirty = (selectedProfile && selectedProfile.isDirty ? true : false);
    // console.log('%c Me profileIsDirty: %O', 'color: maroon;', profileIsDirty);
    // console.log('%c Me savingProfile: %O', 'color: maroon;', savingProfile);

    if (!profileIsDirty && savingProfile) setSavingProfile(false);

    var userFirstName = (currentUser && currentUser.FirstName ? currentUser.FirstName : '');
    // console.log('%c Me userFirstName: %O', 'color: maroon;', userFirstName);

    var profile = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    // console.log('%c Me profile: %O', 'color: maroon;', profile);
    var profileId: number = (profile && profile.UdicciRecordId ? profile.UdicciRecordId : 0);
    // console.log('%c Me profileId: %O', 'color: maroon;', profileId);
    var dateOwnerAssumedControl: any = (profile && profile.DateOwnerAssumedControl ? profile.DateOwnerAssumedControl : null);
    // console.log('%c Me dateOwnerAssumedControl: %O', 'color: maroon;', dateOwnerAssumedControl);
    var profileType: any = (profile && profile.ProfileType ? profile.ProfileType : null);
    // console.log('%c Me profileType: %O', 'color: maroon;', profileType);
    var profileSettings: any = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
    // console.log('%c Me profileSettings: %O', 'color: maroon;', profileSettings);
    // var profileOnLoad: any = (profileSettings && profileSettings.onload ? profileSettings.onload : null);
    // console.log('%c Me profileOnLoad: %O', 'color: maroon;', profileOnLoad);
    var takeControlLog: any = (profileSettings && profileSettings.takeControlLog ? profileSettings.takeControlLog : null);
    // console.log('%c Me takeControlLog: %O', 'color: maroon;', takeControlLog);

    // var localUrl: string = "https://localhost:44349/testHub";
    // var devUrl: string = 'https://rt.udicci.com:44349/mediationFeed';
    // var hubUdicciR: any = null;
    // if (!hubUdicciR) {
    //     try {
    //         hubUdicciR = new signalR.HubConnectionBuilder().withUrl(devUrl).build();
    //         // console.log('%c hubUdicciR: %O', 'color: blue; font-weight: bold;', hubUdicciR);
    //     } catch (exception: any) {
    //         // console.log('%c hubUdicciR error - exception: %O', 'color: red; text-decoration: italic;', exception);
    //     }
    // }

    // if (hubUdicciR) {
    //     // hubUdicciR.start({withCredentials: false}).then(() => hubUdicciR.invoke("SendMessage", "Hello"));
    //     hubUdicciR.start({withCredentials: false});

    //     hubUdicciR.on("ChatUpdate", (chatUpdate: any) => {
    //         // console.log('%c Me hubUdicciR on ChatUpdate - chatUpdate: %O', 'color: blue;', chatUpdate);
    //         var jsonChatUpdate: any = null;
    //         if (chatUpdate) {
    //             try {
    //                 jsonChatUpdate = JSON.parse(chatUpdate);
    //             } catch (e: any) {

    //             }
    //             // console.log('%c Me hubUdicciR on ChatUpdate - jsonChatUpdate: %O', 'color: blue;', jsonChatUpdate);

    //             if (jsonChatUpdate) {
    //                 if (jsonChatUpdate.NewMessage) setLastMessage(jsonChatUpdate.NewMessage);
    //                 // if (jsonChatUpdate.ChatContent) setMessages(jsonChatUpdate.ChatContent);
    //             }
    //         }
    //         setMessageToSend('');
    //     });

    //     // hubUdicciR.on("messageReceived", (username: string, message: string) => {
    //     //     // console.log('%c Me hubUdicciR on messageReceived - username: %O', 'color: blue;', username);
    //     //     // console.log('%c Me hubUdicciR on messageReceived - message: %O', 'color: blue;', message);
    //     //     var curMessages: any[] = messages;
    //     //     curMessages.unshift({ username: username, message: message });
    //     //     setMessages(curMessages);
    //     // });
    // }

    // function sendMessageToHub(evt: any) {
    //     // console.log('%c sendMessageToHub hubUdicciR: %O', 'color: green;', hubUdicciR);

    //     if (hubUdicciR) {
    //         try {
    //             // console.log('%c sendMessageToHub messageToSend: %O', 'color: green;', messageToSend);
    //             hubUdicciR.send("SendMessage", chatUsername, messageToSend);
    //                 // .then(() => tbMessage.value = "");
    //         } catch (exception: any) {
    //             // console.log('%c sendMessageToHub exception: %O', 'color: error;', exception);
    //         }
    //     }
    // }

    const contextUpdated = (result: any, request: any, settings: any) => {
        // console.log('%c Me contextUpdated result: %O', 'color: hotpink;', result);
        // console.log('%c Me contextUpdated request: %O', 'color: hotpink;', request);
        // console.log('%c Me contextUpdated settings: %O', 'color: hotpink;', settings);
        if (request && request.UdicciCommand === 'Send Invitation To Take Control') {
            if (result.success) {
                sendInvitationSuccess(result);
            } else {
                sendInvitationFailed(result);
            }
        }
    };

    subscribeToUdicci('me', contextUpdated);

    var currentUrl = window.location.href;
    var url = UrlParser(currentUrl, true);
    // console.log('%c Me url: %O', 'color: blue;', url);

    var engagementGuide: any = (profileSettings && profileSettings.engagementGuide ? profileSettings.engagementGuide : null);
    // console.log('%c Me engagementGuide: %O', 'color: maroon;', engagementGuide);

    // var isGuide = (udicci.isCurrentUserUlysses() || (curUserId && engagementGuide && curUserId === engagementGuide) ? true : false);
    var isGuide = ((curUserId && engagementGuide && curUserId === engagementGuide) ? true : false);
    // console.log('%c Me isGuide: %O', 'color: maroon;', isGuide);

    var activeInstructionLogs: InstructionSetLog[] = [];
    var instructionLog: InstructionSetLog | null = (instructionProcessingLogs && instructionProcessingLogs.length > 0 ? instructionProcessingLogs[0] : null);
    // console.log('%c Me instructionLog: %O', 'color: maroon;', instructionLog);
    if (instructionProcessingLogs && instructionProcessingLogs.length > 1) {
        forEach(instructionProcessingLogs, function(isl: InstructionSetLog, i: number) {
            // console.log('%c InstructionSetLog: %O', 'color: purple;', isl);
            // var islCompleted: boolean = (isl.completed ? true : false);
            // console.log('%c Me islCompleted: %O', 'color: maroon;', islCompleted);
            var islCurrentInstructionId: string = (isl.currentInstructionId ? isl.currentInstructionId : '');
            // console.log('%c Me islCurrentInstructionId: %O', 'color: maroon;', islCurrentInstructionId);
            var islInstructionSet: InstructionSet | null = (isl.instructionSet ? isl.instructionSet : null);
            // console.log('%c Me islInstructionSet: %O', 'color: maroon;', islInstructionSet);
            var islInstructions: Instruction[] = (islInstructionSet && islInstructionSet.instructions ? islInstructionSet.instructions : []);
            // console.log('%c Me islInstructions: %O', 'color: maroon;', islInstructions);

            var islSelectedInstruction: any | null = null;
            if (islInstructions && islCurrentInstructionId) {
                islSelectedInstruction = islInstructions.find((x: any) => x.uid === islCurrentInstructionId);
            } else if (islInstructions && islInstructions.length > 0) {
                islSelectedInstruction = islInstructions[0];
            }
            // console.log('%c Me islSelectedInstruction: %O', 'color: maroon;', islSelectedInstruction);

            var islInstructionConditionsMet: boolean = false;
            if (islSelectedInstruction) {
                // check for the conditions first
                if (islSelectedInstruction.conditions && islSelectedInstruction.conditions.length > 0) {
                    var currentConditionState: boolean = false;
                    islSelectedInstruction.conditions.forEach(function(condition: any) {
                        // console.log('%c condition: %O', 'color: purple;', condition);
                        if (condition.checkForCondition) {
                            var alwaysTrue: boolean = (condition.alwaysTrue ? true : false);
                            if (alwaysTrue) {
                                // console.log('%c alwaysTrue: %O', 'color: purple;', alwaysTrue);
                                currentConditionState = true;
                            } else {
                                var valueToCompare: string = (condition.valueToCompare !== undefined ? condition.valueToCompare : null);
                                var jsonKey: string = (condition.jsonKey !== undefined ? condition.jsonKey : '');
                                // var dataType: string = (condition.dataType !== undefined ? condition.dataType : '');
                                var checkCondition: string = (condition.condition !== undefined ? condition.condition : 'equals');
        
                                var checkResult = condition.checkForCondition(selectedProfile, checkCondition, jsonKey, valueToCompare);
                                // console.log('%c checkResult: %O', 'color: purple;', checkResult);
                                if (checkResult) currentConditionState = true;
        
                                if (condition.systemCheck) {
                                    var systemCheckResult = condition.systemCheck(url.hash);
                                    // console.log('%c Me systemCheckResult: %O', 'color: blue;', systemCheckResult);
                                    if (!systemCheckResult) currentConditionState = false;
                                }
                            }
                        }
                    });
                    // console.log('%c currentConditionState: %O', 'color: purple;', currentConditionState);
        
                    if (currentConditionState) {
                        islInstructionConditionsMet = true;
                    }
                }
            }

            if (islInstructionConditionsMet) {
                activeInstructionLogs.push(isl);
            }
        });
    }
    // console.log('%c Me activeInstructionLogs: %O', 'color: maroon;', activeInstructionLogs);

    if (activeInstructionLogs && activeInstructionLogs.length > 0) {
        instructionLog = activeInstructionLogs[0];
        // console.log('%c Me instructionLog: %O', 'color: darkred;', instructionLog);
    }

    var currentInstructionId: any = (instructionLog && instructionLog.currentInstructionId ? instructionLog.currentInstructionId : '');
    // console.log('%c Me currentInstructionId: %O', 'color: maroon;', currentInstructionId);
    var instructionSet: InstructionSet | null = (instructionLog && instructionLog.instructionSet ? instructionLog.instructionSet : null);
    // console.log('%c Me instructionSet: %O', 'color: maroon;', instructionSet);
    var instructions: Instruction[] | null = (instructionSet && instructionSet.instructions ? instructionSet.instructions : null);
    // console.log('%c Me instructions: %O', 'color: maroon;', instructions);

    var selectedInstruction: any | null = null;
    if (instructions && currentInstructionId) {
        selectedInstruction = instructions.find((x: any) => x.uid === currentInstructionId);
    } else if (instructions && instructions.length > 0) {
        selectedInstruction = instructions[0];
    }
    // console.log('%c Me selectedInstruction: %O', 'color: maroon;', selectedInstruction);

    var instructionConditionsMet: boolean = false;
    if (selectedInstruction) {
        // check for the conditions first
        if (selectedInstruction.conditions && selectedInstruction.conditions.length > 0) {
            var currentConditionState: boolean = false;
            selectedInstruction.conditions.forEach(function(condition: any) {
                // console.log('%c condition: %O', 'color: purple;', condition);
                if (condition.checkForCondition) {
                    var alwaysTrue: boolean = (condition.alwaysTrue ? true : false);
                    if (alwaysTrue) {
                        // console.log('%c alwaysTrue: %O', 'color: purple;', alwaysTrue);
                        currentConditionState = true;
                    } else {
                        var valueToCompare: string = (condition.valueToCompare !== undefined ? condition.valueToCompare : null);
                        var jsonKey: string = (condition.jsonKey !== undefined ? condition.jsonKey : '');
                        // var dataType: string = (condition.dataType !== undefined ? condition.dataType : '');
                        var checkCondition: string = (condition.condition !== undefined ? condition.condition : 'equals');

                        var checkResult = condition.checkForCondition(selectedProfile, checkCondition, jsonKey, valueToCompare);
                        // console.log('%c checkResult: %O', 'color: purple;', checkResult);
                        if (checkResult) currentConditionState = true;

                        if (condition.systemCheck) {
                            var systemCheckResult = condition.systemCheck(url.hash);
                            // console.log('%c Me systemCheckResult: %O', 'color: blue;', systemCheckResult);
                            if (!systemCheckResult) currentConditionState = false;
                        }
                    }
                }
            });
            // console.log('%c currentConditionState: %O', 'color: purple;', currentConditionState);

            if (currentConditionState) {
                instructionConditionsMet = true;
            }
        }
    }

    var profileLoaded = (selectedProfile && selectedProfile.recordId > 0 ? true : false);

    var waitMessage = 'Loading Udicci Portal ... please wait.';
    var profileName = (profile && profile.Name ? profile.Name : '');
    var profileTitle = (profile && profile.DisplayName ? profile.DisplayName : profileName);
    var profileSocialIcon = (profile && profile.SocialIcon ? profile.SocialIcon : udicci.defaultSocialIcon);
    var profileHeadline = (profile && profile.Headline ? profile.Headline : '');
    var profileMissionStatement = (profile && profile.MissionStatement ? profile.MissionStatement : '');
    var profileBiography = (profile && profile.Biography ? profile.Biography : '');
    var profileUrl = (profile && profile.ProfileUrl ? profile.ProfileUrl : '');
    // console.log('%c Me profileUrl: %O', 'color: maroon;', profileUrl);
    var profileDateOwnerAssumedControl = (profile && profile.DateOwnerAssumedControl ? profile.DateOwnerAssumedControl : null);
    // var profilePorta = (profile && profile.ProfilePorta ? profile.ProfilePorta : null);
    // console.log('%c Me profilePorta: %O', 'color: maroon;', profilePorta);
    var profileSolutions = (profile && profile.SocialSolutions ? profile.SocialSolutions : null);
    // console.log('%c profileSolutions: %O', 'color: purple; font-weight: bold;', profileSolutions);
    var udicciUserRole = (profile && profile.UdicciUserRole ? profile.UdicciUserRole : null);
    // console.log('%c udicciUserRole: %O', 'color: purple; font-weight: bold;', udicciUserRole);
    var udicciUserRoleId = (udicciUserRole && udicciUserRole.UdicciRecordId ? udicciUserRole.UdicciRecordId : 0);
    // console.log('%c Me udicciUserRoleId: %O', 'color: purple; font-weight: bold;', udicciUserRoleId);

    const login = (evt: any) => {
        // console.log('%c login evt: %O', 'color: maroon;', evt);
        setShowLogin(true);
    };

    const closeLoginDialog = (evt: any) => {
        // console.log('%c closeLoginDialog evt: %O', 'color: maroon;', evt);
        setShowLogin(false);
        if (props && props.onNavigation) props.onNavigation({ showPortalConfig: false });
    };

    const openUrl = (url: string, target: string = '_self') => {
        // console.log('%c openUrl url: %O', 'color: hotpink;', url);
        var currentUrl = UrlParser(window.location.href, true);
        var { pathname } = currentUrl;
        if (url && url.toLowerCase() !== pathname.toLowerCase()) {
            window.open(url, target);
        } else {
            closeAll();
        }
    }

    const sendEmail = (contactCard: any) => {
        // console.log('%c sendEmail contactCard: %O', 'color: hotpink;', contactCard);
        var email = (contactCard && contactCard.email ? contactCard.email : '');
        // console.log('%c sendEmail email: %O', 'color: hotpink;', email);
        toggleContactInfo({ show: 'email', details: email });
    }

    const showPhoneNumber = (contactCard: any) => {
        // console.log('%c showPhoneNumber contactCard: %O', 'color: hotpink;', contactCard);
        var phoneNumber = (contactCard && contactCard.phoneNumber ? contactCard.phoneNumber : '');
        // console.log('%c showPhoneNumber phoneNumber: %O', 'color: hotpink;', phoneNumber);
        toggleContactInfo({ show: 'phoneNumber', details: phoneNumber });
    }

    const hideContactInfo = () => {
        toggleContactInfo(null);
    }

    const openConfigurationDialog = (evt: any) => {
        // console.log('%c openConfigurationDialog evt: %O', 'color: maroon;', evt);
        setShowPortalContactConfiguration(false);
        setShowPortalRoles(false);
        toggleShowEngageForm(false);
        toggleShowSocialSolutions(false);
        toggleShowProfile(false);

        if (props && props.onNavigation) props.onNavigation({ showChangePassword: false, showPortalAgreements: false });
    };

    const closeConfigurationDialog = (evt: any) => {
        // console.log('%c closeConfigurationDialog evt: %O', 'color: maroon;', evt);
        closeAll();
        // if (props && props.onNavigation) props.onNavigation({ showPortalConfig: false });
    };

    const closeChangePassword = (evt: any) => {
        // console.log('%c closeChangePassword evt: %O', 'color: maroon;', evt);
        if (props && props.onNavigation) props.onNavigation({ showChangePassword: false });
    };

    const showContactCardForm = (evt: any) => {
        // console.log('%c showContactCardForm evt: %O', 'color: maroon;', evt);
        // closeAll();
        setShowPortalRoles(false);
        setShowPortalSolutions(false);
        setShowPortalContactConfiguration(true);
    };

    const showRoles = (evt: any) => {
        // console.log('%c showRoles evt: %O', 'color: maroon;', evt);
        // closeAll();
        setShowPortalContactConfiguration(false);
        setShowPortalSolutions(false);
        setShowPortalRoles(true);
    };

    const showSolutions = (evt: any) => {
        // console.log('%c showSolutions evt: %O', 'color: maroon;', evt);
        // closeAll();
        setShowPortalRoles(false);
        setShowPortalContactConfiguration(false);
        setShowPortalSolutions(true);
    };

    const engage = (event: React.MouseEvent<HTMLButtonElement>) => {
        toggleShowProfile(false);
        if (curUserId <= 0) {
            toggleShowEngageForm(true);
        }
    };

    const closeAll = () => {
        setShowPortalContactConfiguration(false);
        setShowPortalRoles(false);
        toggleShowEngageForm(false);
        toggleShowSocialSolutions(false);
        toggleShowProfile(true);

        if (props && props.onNavigation) {
            props.onNavigation({
                showChangePassword: false,
                showPortalAgreements: false,
                showPortalConfig: false,
            });
        }
    };

    const saveProfile = (evt: any) => {
        // console.log('%c saveProfile selectedProfile: %O', 'color: maroon;', selectedProfile);
        setSavingProfile(true);
        saveSelectedProfile();
    }

    const closeEngagementForm = (evt: any) => {
        // console.log('%c closeEngagementForm evt: %O', 'color: maroon;', evt);
        toggleShowEngageForm(false);
        // setRegistration(null);
    };

    const copyStringToClipboard = (str: string) => {
        // Create new element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        // el.sx = udicciClasses.hiddenTextarea;
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);

        hideContactInfo();
        alert('copied to clipboard.');
    }

    const sendInvitation = (evt: any) => {
        // console.log('%c sendInvitation evt: %O', 'color: blue;', evt);

        // console.log('%c sendInvitation curUserId: %O', 'color: blue;', curUserId);
        // console.log('%c sendInvitation udicci: %O', 'color: blue;', udicci);
        // console.log('%c sendInvitation profileId: %O', 'color: blue;', profileId);
        var requestJson: SendInvitationRequest = {
            UdicciCommand: 'Send Invitation To Take Control',
            UdicciMediatorName: 'Udicci Profiles',
            SelectedUdicciProfileId: udicci.ulyssesDConstantineProfileId,
            UserId: curUserId,
            SocialSolutionId: udicci.socialSolutionUdicciIT,
            UdicciProfileId: profileId
        };
        // console.log('%c sendInvitation requestJson: %O', 'color: blue;', requestJson);

        var okToContinue = true;
        if (okToContinue) {
            sendPreparedRequest(requestJson, {
                onSuccess: sendInvitationSuccess,
                onError: sendInvitationFailed
            });
            setSendingInvitation(true);
        }
    };

    const sendInvitationSuccess = (result: any) => {
        // console.log('%c sendInvitationSuccess result: %O', 'color: red;', result);
        var success = (result && result.success ? true : false);
        // console.log('%c sendInvitationSuccess success: %O', 'color: red;', success);
        var confirmationMessage: string = (result && result.ConfirmationMessage ? result.ConfirmationMessage : '');
        // console.log('%c sendInvitationSuccess confirmationMessage: %O', 'color: red;', confirmationMessage);
        setSendingInvitation(false);
        setSentInvitation(success);
        setInvitationMessage(confirmationMessage);
    };

    const sendInvitationFailed = (result: any) => {
        // console.log('%c sendInvitationFailed result: %O', 'color: red;', result);
        setSendingInvitation(false);
    };

    const changeProfile = (fieldName: string, evt: any) => {
        // console.log('%c changeProfile fieldName: %O', 'color: red;', fieldName);
        if (savingProfile) return false;
        var trgt = evt.target;
        var newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        onChangeProfile(fieldName, newValue);
    }

    const cycleMeDisplay = () => {
        // console.log('%c showPortalContactConfig: %O', 'color: red;', showPortalContactConfig);
        // console.log('%c showPortalRoles: %O', 'color: red;', showPortalRoles);
        // console.log('%c showEngageForm: %O', 'color: red;', showEngageForm);
        // console.log('%c showSocialSolutions: %O', 'color: red;', showSocialSolutions);
        // console.log('%c showProfile: %O', 'color: red;', showProfile);

        if (showProfile) {
            toggleShowProfile(false);
        } else {
            toggleShowProfile(true);
        }
        // setShowPortalContactConfiguration(false);
        // setShowPortalRoles(false);
        // toggleShowEngageForm(false);
        // toggleShowSocialSolutions(false);
        // toggleShowProfile(true);

        // if (props && props.onNavigation) {
        //     props.onNavigation({
        //         showChangePassword: false,
        //         showPortalAgreements: false,
        //         showPortalConfig: false,
        //     });
        // }
    };

    var stopHere: boolean = false;
    var udicciLogoElement: any = null;
    var waitingElement: any = null;
    if (!profileLoaded || stopHere) {
        udicciLogoElement = (
            <img src={UdicciLogo} alt="Udicci" style={{ maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto' }} />
        );
        waitingElement = (
            <Box sx={{ margin: '8px' }}>
                <LinearProgress color="inherit" variant="query" />
                <Typography variant="caption" component="div">
                    {waitMessage}
                </Typography>
            </Box>
        );
    }

    var titleElement: any = null;
    var headlineElement: any = null;

    var titleElementProps: any = {
        component: 'span',
        variant: 'caption',
    };
    if (profile) {
        titleElementProps.variant = 'subtitle1';
        titleElementProps.sx = (curUserId > 0 ? udicciClasses.displayNameOnline : udicciClasses.displayName);
        titleElementProps.onClick = closeAll;
        titleElement = (
            <Typography {...titleElementProps}>{(profileLoaded ? profileTitle : waitMessage)}</Typography>
        );
        if (!showLogin && !showEngageForm && showProfile && !showPortalConfig && !showChangePassword) {
            headlineElement = (
                <Typography component="div" variant="body2">
                    {formatStringForDisplay(profileHeadline)}
                </Typography>
            );
        }
    } else {
        titleElement = (
            <Typography {...titleElementProps}> {(profileLoaded ? profileTitle : waitMessage)} </Typography>
        );
    }

    var missionStatementElement: any = null;
    var biographyElement: any = null;
    var guideElement: any = null;
    if (!showLogin && isGuide && !dateOwnerAssumedControl && (profileType && profileType.Name.toLowerCase() === '.me')) {
        var sendInvitationButton: any = null;
        if (sentInvitation && invitationMessage) {
            sendInvitationButton = (
                <Typography variant="body2">
                    {invitationMessage}
                </Typography>
            );
        } else if (!dateOwnerAssumedControl) {
            var buttonText: string = 'Send Invitation';
            if (takeControlLog) buttonText = 'Re-Send Invitation';
            sendInvitationButton = (
                <Button sx={udicciClasses.menuLink} onClick={sendInvitation} disabled={sendingInvitation}>
                    {sendingInvitation && (<CircularProgress size={20} />)}
                    <Typography variant="subtitle1"> {buttonText} </Typography>
                </Button>
            );
        }

        var invitationSentElement: any = null;
        if (takeControlLog) {
            // var initialEmail: string = (takeControlLog.initialEmail ? takeControlLog.initialEmail : '');
            // console.log('%c sendInvitationSuccess initialEmail: %O', 'color: red;', initialEmail);
            // var initialEmailSent: string = (takeControlLog.initialEmailSent ? takeControlLog.initialEmailSent : '');
            // console.log('%c sendInvitationSuccess initialEmailSent: %O', 'color: red;', initialEmailSent);

            // var dtInitialEmailSent: Date | null = null;
            // if (initialEmailSent) {
            //     try {
            //         dtInitialEmailSent = new Date(initialEmailSent);
            //     } catch (ex: any) {
            //         // console.log('%c sendInvitationSuccess could not parse initialEmailSent: %O', 'color: red;', ex);
            //     }
            // }
            // console.log('%c sendInvitationSuccess dtInitialEmailSent: %O', 'color: red;', dtInitialEmailSent);

            invitationSentElement = (
                <Box>
                    <Typography component="div" variant="body2" sx={udicciClasses.cardContentRoot}>
                        As a Profile Guide, your Role does come with some responsibilities.
                        Simply stated, you are engaging a new profile with Udicci and we do request
                        that you help ensure a smooth transition into using the Udicci platform.
                    </Typography>
                    <Typography component="div" variant="body2" sx={udicciClasses.cardContentRoot}>
                        Your Role as Profile Guide also gives you special access to the data in 
                        the new Portal.  We respectfully ask that you help as needed, then turn over 
                        when it makes sense.
                    </Typography>
                    <Typography component="div" variant="body2" sx={udicciClasses.cardContentRoot}>
                        The next step is to invite the person to "Take Control" of Their Udicci Profile.
                    </Typography>
                </Box>
            );
        }

        guideElement = (
            <Box sx={udicciClasses.cardContentRoot}>
                <Typography component="div" variant="body2">
                    Hi {userFirstName}, you are the Profile Guide for {profileTitle}.
                </Typography>

                {invitationSentElement}
                <Box sx={udicciClasses.cardContentRoot}>
                    {sendInvitationButton}
                </Box>
            </Box>
        );
    } else if (showProfile && !showLogin && !showPortalConfig && !showChangePassword) {
        if (profileMissionStatement) {
            missionStatementElement = (
                <Typography variant="body2">{formatStringForDisplay(profileMissionStatement)}</Typography>
            );
        }
        if (profileBiography) {
            biographyElement = (
                <Typography variant="caption">{formatStringForDisplay(profileBiography)}</Typography>
            );
        }
    }

    var nameElement: any = null;
    var emailElement: any = null;
    var phoneElement: any = null;
    var onFacebookElement: any = null;
    var onYouTubeElement: any = null;
    var onLinkedInElement: any = null;
    if (!showLogin && profileSettings && profileSettings.contactCard) {
        var cc: any = profileSettings.contactCard;
        // console.log('%c Me contactCard: %O', 'color: maroon;', cc);

        var fullName = '';
        if (cc.firstName || cc.lastName) {
            if (cc.firstName) fullName += cc.firstName;
            if (cc.lastName) {
                if (fullName) fullName += ' ';
                fullName += cc.lastName;
            }
        }

        if (cc.email) {
            emailElement = (
                <IconButton size="small" aria-label={'Email ' + fullName} onClick={(evt: any) => {sendEmail(cc)}}>
                    <EmailIcon />
                </IconButton>
            );
        }
        if (cc.phoneNumber) {
            phoneElement = (
                <IconButton size="small" aria-label={'Call ' + fullName} onClick={(evt: any) => {showPhoneNumber(cc)}}>
                    <PhoneIcon />
                </IconButton>
            );
        }
        if (cc.facebookUrl) {
            onFacebookElement = (
                <IconButton size="small" aria-label="On Facebook" onClick={(evt: any) => {openUrl(cc.facebookUrl, '_blank')}}>
                    <FacebookIcon />
                </IconButton>
            );
        }
        if (cc.youTubeUrl) {
            onYouTubeElement = (
                <IconButton size="small" aria-label="On YouTube" onClick={(evt: any) => {openUrl(cc.youTubeUrl, '_blank')}}>
                    <YouTubeIcon />
                </IconButton>
            );
        }
        if (cc.linkedInUrl) {
            onLinkedInElement = (
                <IconButton size="small" aria-label="On LinkedIn" onClick={(evt: any) => {openUrl(cc.linkedInUrl, '_blank')}}>
                    <LinkedInIcon />
                </IconButton>
            );
        }

        // if (emailElement || phoneElement || onFacebookElement || onYouTubeElement || onLinkedInElement) {
        //     if (fullName) {
        //         nameElement = (
        //             <Typography component="span">
        //                 {fullName}
        //             </Typography>
        //         );
        //     }
        // }
    }

    // var chatElement: any = null;
    // var messageInputElement = (
    //     <TextField
    //         placeholder={'message to send'}
    //         sx={udicciClasses.textField}
    //         value={messageToSend}
    //         onChange={(evt: any) => setMessageToSend(evt.target.value)}
    //         margin="normal"
    //         variant='filled' />
    // );
    // chatElement = (
    //     <Box>
    //         {messageInputElement}
    //         <Button size="small" onClick={sendMessageToHub} color="info" variant="contained">
    //             Send Message
    //         </Button>
    //     </Box>
    // );

    // console.log('%c Me udicci.engageablePortalUrls: %O', 'color: maroon;', udicci.engageablePortalUrls);
    // console.log('%c Me profileUrl: %O', 'color: maroon;', profileUrl);
    var engagePortalIndex: number = (udicci.engageablePortalUrls.indexOf(profileUrl) >= 0 ? udicci.engageablePortalUrls.indexOf(profileUrl) : -1);
    // console.log('%c Me engagePortalIndex: %O', 'color: maroon;', engagePortalIndex);

    var moreElement: any = null;
    var engageButtonElement: any = null;
    var loginButtonElement: any = null;
    if (!showLogin && (profileDateOwnerAssumedControl || (profileType && profileType.Name.toLowerCase() !== '.me')) && !showEngageForm && showProfile && !showPortalConfig && !showChangePassword) {
        if (profileBiography) {
            moreElement = (
                <PaneSubContent>
                    <ShowMoreButton size="small" onClick={(evt: any) => toggleAdditionalDetails(!showAdditionalDetails)} color="secondary" variant="text">
                        See {(showAdditionalDetails ? 'less' : 'more')} ...
                    </ShowMoreButton>
                </PaneSubContent>
            );
        }

        // if (!curUserId) {
        //     loginButtonElement = (
        //         <Box sx={udicciClasses.loginButtonContainer}>
        //             <IconButton color="secondary" size="small" onClick={login} sx={udicciClasses.loginButton}>
        //                 <LoginIcon />
        //             </IconButton>
        //         </Box>
        //     );
        // }
    }

    if (!showLogin && !showEngageForm && showProfile && !showPortalConfig && !showChangePassword) {
        if ((engagePortalIndex >= 0) || (currentUser && currentUser.UdicciUserId)) {
            if (currentUser && currentUser.UdicciUserId) {
                engageButtonElement = (
                    <UserEngageButton size="small" onClick={engage} color="primary" variant="contained">
                        Engage
                    </UserEngageButton>
                );
            } else {
                engageButtonElement = (
                    <EngageButton size="small" onClick={engage} color="primary" variant="contained">
                        Engage
                    </EngageButton>
                );
            }
        } else {
            engageButtonElement = (
                <div>&nbsp;</div>
            );
        }
    }

    var userLoggedIn = (currentUser && currentUser.UdicciUserId > 0 ? true : false);

    var includedSocialSolutions: any = {};
    // var socialSolutionsMenuElement: any = null;
    if (profileSettings && profileSettings.socialSolutionSettings) {
        if (currentUser && currentUser.UdicciUserId > 0) {
            var sss: any = profileSettings.socialSolutionSettings;
            // console.log('%c Me socialSolutionSettings: %O', 'color: maroon;', sss);
            if (sss) {
                for (let [id, setting] of Object.entries<any>(sss)) {
                    // console.log('%c Me id: %O', 'color: green;', id);
                    // console.log('%c Me setting: %O', 'color: green;', setting);

                    var publicPermissions = (setting.public ? setting.public : null);
                    // console.log('%c Me publicPermissions: %O', 'color: maroon;', publicPermissions);
                    var defaultPermissions = (setting.default ? setting.default : null);
                    // console.log('%c Me defaultPermissions: %O', 'color: maroon;', defaultPermissions);

                    var permissions: any | null = null;
                    if (currentUser && currentUser.UdicciUserId > 0) {
                        permissions = defaultPermissions;
                        // check for current user role overrides
                    } else {
                        permissions = publicPermissions;
                    }
                    // console.log('%c Me permissions: %O', 'color: blue;', permissions);

                    var roleOverrides = (setting.RoleOverrides ? setting.RoleOverrides : null);
                    // console.log('%c Me roleOverrides: %O', 'color: maroon;', roleOverrides);
                    var userRoleOverride = (udicciUserRoleId > 0 && roleOverrides && roleOverrides[udicciUserRoleId] ? roleOverrides[udicciUserRoleId] : null);
                    // console.log('%c Me userRoleOverride: %O', 'color: maroon;', userRoleOverride);
                    if (userRoleOverride) {
                        // console.log('%c Me userRoleOverride: %O', 'color: green;', userRoleOverride);
                        permissions = userRoleOverride;

                        if (userRoleOverride.overrides) {
                            for (var [medName, medPerms] of Object.entries<any>(userRoleOverride.overrides)) {
                                // console.log('%c Me medName: %O', 'color: green;', medName);
                                // console.log('%c Me medPerms: %O', 'color: green;', medPerms);
                                if (!medName) continue;

                                if (medPerms && medPerms.CanView !== undefined) permissions.CanView = medPerms.CanView;
                                if (medPerms && medPerms.CanAdd !== undefined) permissions.CanAdd = medPerms.CanAdd;
                            }
                        }
                    }

                    // console.log('%c Me permissions: %O', 'color: blue;', permissions);
                    if (permissions && permissions.CanView) {
                        // console.log('%c Me permissions: %O', 'color: green; font-weight: bold;', permissions);
                        if (!includedSocialSolutions[id]) includedSocialSolutions[id] = {};

                        var ss: any | null = null;
                        if (profileSolutions) {
                            ss = profileSolutions.find((x: any) => x.recordId.toString() === id.toString());
                        }
                        // console.log('%c ss: %O', 'color: green; font-weight: bold;', ss);

                        if (ss) {
                            if (!ss.permissions) ss.permissions = permissions;
                            includedSocialSolutions[id].permissions = permissions;
                            includedSocialSolutions[id].setting = setting;
                            includedSocialSolutions[id].solution = ss;
                            // ssCount++;
                        }
                    }
                }
            }
            // console.log('%c Me ssCount: %O', 'color: blue;', ssCount);

            // if (ssCount > 0) {
            //     socialSolutionsMenuElement = (
            //         <MenuItem onClick={onShowSocialSolution}> Social Solutions </MenuItem>
            //     );
            // }
        }
    }
    // console.log('%c Me includedSocialSolutions: %O', 'color: blue;', includedSocialSolutions);

    var settingsDialogElement = null;
    // console.log('%c showPortalConfig: %O', 'color: maroon;', showPortalConfig);
    if (showPortalConfig) {
        var roles = udicciHelpers.preloadMediatorData('Roles', udicci.socialSolutionUdicciIT);
        // console.log('%c roles: %O', 'color: maroon;', roles);
    
        var configFormElement: any = null;
        if (showPortalContactConfig) {
            configFormElement = (
                <ConfigureContactCardSettings configuration={profile} onChange={changeProfile} onClose={closeConfigurationDialog} />
            );
        } else if (showPortalRoles) {
            configFormElement = (
                <ManageProfileRoles roles={roles} configuration={profile} onChange={changeProfile} onClose={closeConfigurationDialog} />
            );
        } else if (showPortalSolutions && currentUser && udicci.specialUsers.indexOf(currentUser.UserName.toLowerCase()) >= 0) {
            configFormElement = (
                <ConfigProfileSolutions roles={roles} configuration={profile} onChange={changeProfile} onClose={closeConfigurationDialog} />
            );
        } else {
            configFormElement = (
                <ConfigureProfileSettings configuration={profile} onChange={changeProfile} onClose={closeConfigurationDialog} />
            );
        }

        var detailsFormButtonClass = (showPortalConfig && !showPortalContactConfig && !showPortalRoles && !showPortalSolutions ? udicciClasses.highlightTextOrangeLimeUnderline : udicciClasses.highlightTextOrange);
        var detailsFormButton: any = null;
        if (!savingProfile) {
            detailsFormButton = (
                <span>
                    <Typography variant="body2" component="span" onClick={openConfigurationDialog} sx={detailsFormButtonClass}>
                        Profile
                    </Typography>
                    <Typography variant="body2" component="span" sx={detailsFormButtonClass}>
                        .
                    </Typography>
                </span>
            );
        } else {
            detailsFormButton = (
                <span>
                    <Typography variant="body2" component="span" sx={detailsFormButtonClass}>
                        Profile
                    </Typography>
                    <Typography variant="body2" component="span" sx={detailsFormButtonClass}>
                        .
                    </Typography>
                </span>
            );
        }

        var contactFormButtonClass = (showPortalContactConfig ? udicciClasses.highlightTextOrangeLimeUnderline : udicciClasses.highlightTextOrange);
        var contactFormButton: any = null;
        if (!savingProfile) {
            contactFormButton = (
                <span>
                    <Typography variant="body2" component="span" onClick={showContactCardForm} sx={contactFormButtonClass}>
                        Contact
                    </Typography>
                    <Typography variant="body2" component="span" sx={contactFormButtonClass}>
                        .
                    </Typography>
                </span>
            );
        } else {
            contactFormButton = (
                <span>
                    <Typography variant="body2" component="span" sx={contactFormButtonClass}>
                        Contact
                    </Typography>
                    <Typography variant="body2" component="span" sx={contactFormButtonClass}>
                        .
                    </Typography>
                </span>
            );
        }

        var roleFormButtonClass = (showPortalRoles ? udicciClasses.highlightTextOrangeLimeUnderline : udicciClasses.highlightTextOrange);
        var roleFormButton: any = null;
        if (!savingProfile) {
            roleFormButton = (
                <span>
                    <Typography variant="body2" component="span" onClick={showRoles} sx={roleFormButtonClass}>
                        Roles
                    </Typography>
                    <Typography variant="body2" component="span" sx={roleFormButtonClass}>
                        .
                    </Typography>
                </span>
            );
        } else {
            roleFormButton = (
                <span>
                    <Typography variant="body2" component="span" sx={roleFormButtonClass}>
                        Roles
                    </Typography>
                    <Typography variant="body2" component="span" sx={roleFormButtonClass}>
                        .
                    </Typography>
                </span>
            );
        }

        var permissionsFormButtonClass = (showPortalSolutions ? udicciClasses.highlightTextOrangeLimeUnderline : udicciClasses.highlightTextOrange);
        var permissionsFormButton: any = null;
        if (currentUser && udicci.specialUsers.indexOf(currentUser.UserName.toLowerCase()) >= 0) {
            if (!savingProfile) {
                permissionsFormButton = (
                    <span>
                        <Typography variant="body2" component="span" onClick={showSolutions} sx={permissionsFormButtonClass}>
                            Permissions
                        </Typography>
                        <Typography variant="body2" component="span" sx={permissionsFormButtonClass}>
                            .
                        </Typography>
                    </span>
                );
            } else {
                permissionsFormButton = (
                    <span>
                        <Typography variant="body2" component="span" sx={permissionsFormButtonClass}>
                            Permissions
                        </Typography>
                        <Typography variant="body2" component="span" sx={permissionsFormButtonClass}>
                            .
                        </Typography>
                    </span>
                );
            }
        }

        var saveProfileButtonElement: any = null;
        if (!showPortalRoles) {
            if (savingProfile) {
                saveProfileButtonElement = (
                    <Box sx={udicciClasses.formHeaderButtonRight}>
                        <CircularProgress size={20} />
                    </Box>
                );
            } else {
                saveProfileButtonElement = (
                    <IconButton color="secondary" disabled={((profileIsDirty && !savingProfile) ? false : true)} size="small" onClick={saveProfile} sx={udicciClasses.formHeaderButtonRight}>
                        <SaveIcon />
                    </IconButton>
                );
            }
        }

        settingsDialogElement = (
            <Box sx={udicciClasses.formCardContainer}>
                <Box sx={udicciClasses.profileSubContentContainer}>
                    <Box sx={udicciClasses.formHeaderButtonArea}>
                        {saveProfileButtonElement}
                        {detailsFormButton}
                        {contactFormButton}
                        {roleFormButton}
                        {permissionsFormButton}
                    </Box>
                    {configFormElement}
                </Box>
            </Box>
        );
    }

    if (showChangePassword) {
        settingsDialogElement = (
            <Box sx={udicciClasses.formCardContainer}>
                <Box sx={udicciClasses.profileSubContentContainer}>
                    {/* <Box sx={udicciClasses.formHeaderButtonArea}>
                        {saveProfileButtonElement}
                        {detailsFormButton}
                        {contactFormButton}
                    </Box> */}
                    <ChangePasswordForm configuration={profile}
                                        onChange={changeProfile}
                                        onClose={closeChangePassword}
                    />
                </Box>
            </Box>
        );
    }

    var loginDialogElement: any = null;
    if (showLogin) {
        loginDialogElement = (
            <PortaPaneContainer style={{ marginTop: '8px' }}>
                <Box sx={udicciClasses.profileSubContentContainer}>
                    <LoginForm onClose={closeLoginDialog} data={{ showInline: true }} />
                </Box>
            </PortaPaneContainer>
        );
    }

    var onboardingElement: any = null;
    // console.log('%c selectedInstruction: %O', 'color: green;', selectedInstruction);
    // console.log('%c instructionConditionsMet: %O', 'color: green;', instructionConditionsMet);
    // if (selectedInstruction && selectedInstruction.title.toString().toLowerCase() === 'onboarding' && instructionConditionsMet) {
    //     onboardingElement = ( <Paper> <Onboarding /> </Paper> );
    // }
    // if (selectedInstruction && selectedInstruction.title.toString().toLowerCase() === 'first login' && instructionConditionsMet) {
    //     onboardingElement = (
    //         <Paper>
    //             <Box>
    //                 <LoginForm onClose={closeLoginDialog} data={{ showInline: true }} />
    //             </Box>
    //         </Paper>
    //     );
    // }

    var selectedProfileIsUser = false;
    if (userLoggedIn && selectedProfile) {
        selectedProfileIsUser = (selectedProfile.recordId === currentUser?.MeUdicciProfileId ? true : false);
    }
    var profileCardAvatar: any = null;

    var isX: boolean = false;
    if (!showProfile && !showPortalConfig && !showChangePassword && udicciUserRoleId) {
        isX = true;
    }

    var showMinimizedHeader: boolean = ((isX || showLogin || showEngageForm || showPortalConfig || showSocialSolutions || showChangePassword) ? true : false);
    var profileAvatarStyle = udicciClasses.profileAvatar;
    if (userLoggedIn && selectedProfileIsUser) profileAvatarStyle = udicciClasses.profileAvatar;
    var profileCardAvatarIconButton: any = null;
    if (profile) {
        var avatarBoxStyle = (showMinimizedHeader ? udicciClasses.avatarButtonContainerMin : udicciClasses.avatarButtonContainer);
        // var avatarButtonStyle = (showMinimizedHeader ? udicciClasses.avatarButtonMin : udicciClasses.avatarButton);
        profileAvatarStyle = (showMinimizedHeader ? udicciClasses.profileAvatarMin : profileAvatarStyle);
        profileCardAvatarIconButton = (
            <Box sx={avatarBoxStyle}>
                <img src={profileSocialIcon} alt={profileTitle} style={profileAvatarStyle} onClick={(evt: any) => cycleMeDisplay()} />
                {/* <Avatar alt={profileTitle}
                        aria-label={profileTitle}
                        src={profileSocialIcon}
                        sx={profileAvatarStyle}
                        variant="rounded"
                        onClick={(evt: any) => cycleMeDisplay()}
                /> */}
            </Box>
        );
    } else {
        profileCardAvatarIconButton = (
            <IconButton disabled={true} disableFocusRipple={true}>
                <img src={profileSocialIcon} alt={profileTitle} style={profileAvatarStyle} />
                {/* <Avatar alt={profileTitle}
                        aria-label={profileTitle}
                        src={profileSocialIcon}
                        variant="rounded"
                /> */}
            </IconButton>
        );
    }
    profileCardAvatar = profileCardAvatarIconButton;

    var profileCardTitle: any = titleElement;
    var profileCardSubheader: any = headlineElement;

    var profileContactContent: any = null;
    var profileCardContent1: any = null;
    var profileCardContent2: any = null;
    var profileCardLinks: any = null;
    var profileCardActions: any = null;

    if (!showEngageForm) {
        // console.log('%c showContactInfo: %O', 'color: hotpink;', showContactInfo);
        var contactInfoContent: any = null;
        if (showContactInfo && !onboardingElement) {
            var actionButton: any = (
                <Button color="secondary" onClick={(evt: any) => copyStringToClipboard(showContactInfo.details)}>
                    Copy
                </Button>
            );
            var snackMessageElement: any = (
                <Box textAlign="center" onClick={(evt: any) => copyStringToClipboard(showContactInfo.details)}>
                    {showContactInfo.details}
                </Box>
            );
            contactInfoContent = (
                <Snackbar
                    message={snackMessageElement}
                    action={actionButton}
                    autoHideDuration={15000}
                    open={true}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={(evt: any) => toggleContactInfo(null)}
                />
            );
        }

        if ((contactInfoContent || nameElement) && !onboardingElement) {
            profileContactContent = (
                <Box>
                    {contactInfoContent}
                    {nameElement}
                </Box>
            );
        }

        if (missionStatementElement && !onboardingElement) {
            profileCardContent1 = (
                <PaneSubContent>
                    {missionStatementElement}
                </PaneSubContent>
            );
        }

        if (showAdditionalDetails && biographyElement && !onboardingElement) {
            profileCardContent2 = (
                <PaneSubContent>
                    {biographyElement}
                </PaneSubContent>
            );
        }

        if ((onFacebookElement || onYouTubeElement || onLinkedInElement) && !onboardingElement && showProfile && !showPortalConfig && !showChangePassword) {
            profileCardLinks = (
                <PaneSubContent>
                    {onFacebookElement}
                    {onYouTubeElement}
                    {onLinkedInElement}
                    {emailElement}
                    {phoneElement}
                </PaneSubContent>
            );
        }
    }

    if ((engageButtonElement || loginButtonElement) && !onboardingElement) {
        profileCardActions = (
            <Box sx={udicciClasses.profileSubSecondaryContentContainer}>
                {loginButtonElement}
                {engageButtonElement}
            </Box>
        );
    }

    var profileCard: any = null;
    var profileCardHeader: any = null;
    if (profileLoaded) {
        var headerSubContainerStyle = (showMinimizedHeader ? udicciClasses.profileHeaderSubContainerMin : udicciClasses.profileHeaderSubContainer);
        var profileHeaderContainerStyle = (showMinimizedHeader ? udicciClasses.profileHeaderContainerMin : udicciClasses.profileHeaderContainer);
        // console.log('%c Me profileHeaderContainerStyle: %O', 'color: red;', profileHeaderContainerStyle);
        // console.log('%c Me headerSubContainerStyle: %O', 'color: red;', headerSubContainerStyle);
        profileCardHeader = (
            <Box sx={profileHeaderContainerStyle}>
                <Box sx={headerSubContainerStyle}>
                    {(udicciLogoElement ? udicciLogoElement : profileCardAvatar)}
                    {profileCardTitle}
                    {profileCardSubheader}
                </Box>
                {profileContactContent}
            </Box>
        );
        if (props && props.fullWidth) {
            profileCard = (
                <Box>
                    {profileCardHeader}
                    {guideElement}
                    {profileCardContent1}
                    {profileCardLinks}
                    {profileCardContent2}
                    {moreElement}
                </Box>
            );
        } else {
            profileCard = (
                <PortaPaneContainer>
                    <Paper>
                        {profileCardHeader}
                        {guideElement}
                        {profileCardContent1}
                        {profileCardLinks}
                        {profileCardContent2}
                        {moreElement}
                    </Paper>
                    {profileCardActions}
                </PortaPaneContainer>
            );
        }
    } else {
        profileCard = (
            <Paper elevation={7} sx={{ display: 'flow-root', padding: '8px', margin: '32px' }}>
                {udicciLogoElement}
                {waitingElement}
            </Paper>
        );
    }

    var registrationFormDisplay: any = null;
    var contentFormElement: any = null;
    var secondaryContentFormElement: any = null;
    if (!showProfile && !showPortalConfig && !showChangePassword && udicciUserRoleId) {
        secondaryContentFormElement = (
            <Box sx={udicciClasses.socialSolutionsMenuContainer}>
                <SocialSolutionsMenu />
            </Box>
        );
    } else {
        if (showEngageForm) {
            secondaryContentFormElement = (
                <PortaPaneContainer style={{ marginTop: '8px' }}>
                    <Paper>
                        <EngagementForm onClose={closeEngagementForm} />
                    </Paper>
                </PortaPaneContainer>
            );
        }
    }
    // console.log('%c Me showEngageForm: %O', 'color: red;', showEngageForm);
    // console.log('%c Me udicciUserRoleId: %O', 'color: red;', udicciUserRoleId);
    // console.log('%c Me props.fullWidth: %O', 'color: red;', props.fullWidth);
    if (props && props.fullWidth) {
        contentFormElement = (
            <Box>
                <PortaContainer>
                    {profileCard}
                    {onboardingElement}
                    {secondaryContentFormElement}
                </PortaContainer>
                {settingsDialogElement}
                {loginDialogElement}
            </Box>
        );
    } else {
        contentFormElement = (
            <Box>
                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" alignContent="center">
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <PortaContainer>
                            {profileCard}
                            {onboardingElement}
                            {secondaryContentFormElement}
                        </PortaContainer>
                        {settingsDialogElement}
                        {loginDialogElement}
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <div>
            {contentFormElement}
            {registrationFormDisplay}
        </div>
    );
}

export default withUdicciTheme(Me);
