import Button from '@mui/material/Button';

export const ExecuteActionButton: React.FC<any> = (props) => {
    // console.log('%c ExecuteActionButton props: %O', 'color: purple;', props);
    let actionIsProcessing: any = (props.actionIsProcessing ? true : false);
    // console.log('%c ExecuteActionButton actionIsProcessing: %O', 'color: red;', actionIsProcessing);
    let overrideProfileRecord: any = (props.overrideProfileRecord ? props.overrideProfileRecord : null);
    // console.log('%c ExecuteActionButton overrideProfileRecord: %O', 'color: red;', overrideProfileRecord);
    let engagementData: any = (props.engagementData ? props.engagementData : null);
    // console.log('%c ExecuteActionButton engagementData: %O', 'color: red;', engagementData);
    let engagedAction: any = (props.engagedAction ? props.engagedAction : null);
    // console.log('%c ExecuteActionButton engagedAction: %O', 'color: red;', engagedAction);
    let createProfile: any = {};
    if (engagementData && engagementData.createProfile) {
        createProfile = engagementData.createProfile;
    }
    // console.log('%c createProfile: %O', 'color: blue;', createProfile);
    let profileRecord = (createProfile.profileRecord ? createProfile.profileRecord : null);
    // console.log('%c profileRecord: %O', 'color: blue;', profileRecord);
    if (overrideProfileRecord)
        profileRecord = overrideProfileRecord;

    let submitActionButtonText = 'Create Profile';
    if (engagedAction.label) {
        let actionLabel = (engagedAction.label ? engagedAction.label : null);
        // console.log('%c actionLabel: %O', 'color: hotpink;', actionLabel);
        if (actionLabel && actionLabel.text) {
            submitActionButtonText = actionLabel.text;
        }
    } else if (engagedAction.name) {
        submitActionButtonText = engagedAction.name;
    }

    const submitAction = (evt: any) => {
        // console.log('%c submitAction actionIsProcessing: %O', 'color: red;', actionIsProcessing);
        if (actionIsProcessing === true)
            return false;
        // console.log('%c submitAction actionIsProcessing: %O', 'color: red;', actionIsProcessing);
        // this.setState({ actionIsProcessing: true }, this.submitActionProcessStep1.bind(this));
    };

    let buttonDisabled = true;
    if (profileRecord.isDirty && !actionIsProcessing) {
        buttonDisabled = false;
    }
    // console.log('%c buttonDisabled: %O', 'color: blue;', buttonDisabled);
    return (
        <Button onClick={submitAction}
            disabled={buttonDisabled}
            color="primary"
            autoFocus
        >
            {submitActionButtonText}
        </Button>
    );
};
