import React, { Fragment, useState, useEffect } from 'react';
import UrlParser from 'url-parse';

import { createTheme, ThemeProvider, Theme, styled } from '@mui/material/styles';
// import { alpha  } from '@mui/material/styles';

import type {} from '@mui/x-date-pickers/themeAugmentation';

import { forEach, keys, filter } from 'underscore';

import LinearProgress from '@mui/material/LinearProgress';

import {
    Box, Slider, Tabs, Tab, Button, TextField, FormHelperText , InputAdornment,
    Paper, Grid, Typography, IconButton, FormControl, Input, InputLabel,
    Select, MenuItem, Icon, ButtonGroup, Tooltip
} from '@mui/material';

import {
    Settings as SettingsIcon,
    Close as CloseSettingsIcon,
    Save as SaveIcon,
    Add as AddPaneIcon,
} from '@mui/icons-material';

import PageLayout from 'src/components/PageLayout';
import UdicciConsole from 'src/components/udicci-console';
import { Me } from 'src/components/me';
import RelationshipsList from 'src/components/relationships-list';

import { useUdicciContext, getDesignMode, logoff } from 'src/context/udicci-context'
// import { udicciStyles } from 'src/theme/shared-styles';
import { PortalHeader } from 'src/components/portal-header';
import PortaPane from 'src/components/porta-pane';
import { UdicciPorta } from 'src/classes/udicci-types';
import LoginForm from 'src/components/login';

import AccessSettingsAdmin from 'src/admin/access-settings';
import DesignSettingsAdmin from 'src/admin/design-settings';

import { UdicciPortaComponent, UdicciPortaProps, UdicciPortaState } from 'src/ui/udicci-ui-library';

import useUdicciHelpers, { TabPanel } from 'src/hooks/useUdicciHelpers';
import PortalSplash from 'src/components/portal-splash';
import { SocialSolutionManagement } from 'src/admin/social-solution-management';
import FocusBoardFullScreen from 'src/components/udicci-it/full-screen';

import { FloatingHelperButton } from 'src/components/udicci-it/floating-helper-button';
import { ReportIssuePromptAreaContent } from 'src/components/udicci-it/report-issue-prompt-area-content';

export const drawerWidth = 240;

const PortaContainer: any = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<{ open?: boolean; }>(({ theme, open }) => ({
    flexGrow: 1,
    pageBreakInside: 'auto',
    marginTop: theme.spacing(5),
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const PortaAIContainer: any = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    // marginTop: theme.spacing(5),
    // padding: theme.spacing(1),
    // height: '96vh',
}));

const PortaAIAdminContainer: any = styled(Paper)(({ theme }) => ({
    position: 'initial',
    // top: 0,
    // right: 0,
    // bottom: 0,
    // left: 0,
    marginTop: theme.spacing(5),
    // padding: theme.spacing(1),
    // height: '96vh',
}));

const ConfigurePortaHeaderContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
}));

const PortaPaneContainer = styled('div')(({ theme }) => ({
    height: '100%',
    padding: '1px',
    borderRadius: theme.spacing(1),
    pageBreakInside: 'auto',
}));

const PaneContainer = styled('div')(({ theme }) => ({
    borderRadius: theme.spacing(1),
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    pageBreakInside: 'auto',
}));

const ReportIssueOverlayContainer = styled(Paper)(({ theme }) => ({
    display: 'flow-root',
    borderTop: '1px solid orange',
    // background: 'rgba(0, 0, 0, 0.87)',
    padding: theme.spacing(1),
    overflowY: 'auto',
    position: 'sticky',
    bottom: 0,
    right: 0,
    left: 0,
    minHeight: '110px',
    maxHeight: '300px'
}));

const ReportIssueInnerContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(1),
}));

export default function Porta() {
    const udicciContext = useUdicciContext();
    // console.log('%c Porta udicciContext: %O', 'color: magenta;', udicciContext);

    return (<PortaComponent udicciContext={udicciContext} />);
}

// PortaComponent extends the UdicciComponent
class PortaComponent extends UdicciPortaComponent<UdicciPortaProps, UdicciPortaState> {
    componentDidUpdate(newProps: any, newState: any) {
        super.componentDidUpdate(newProps, newState);

        // this.developerLog('%c PortaComponent componentDidUpdate newProps: %O', 'color: black;', newProps);
        // this.developerLog('%c PortaComponent componentDidUpdate newState: %O', 'color: black;', newState);
        // this.developerLog('%c PortaComponent componentDidUpdate this.state: %O', 'color: black;', this.state);
    }

    componentWillUnmount() {
        // this.developerLog('%c PortaComponent componentWillUnmount this.state: %O', 'color: black;', this.state);
    }

    componentDidMount() {
        // this.developerLog('%c PortaComponent componentDidMount this.state: %O', 'color: black;', this.state);
        super.componentDidMount();

        if (this.state && this.state.isLoaded) {
            this.setState({ CanRead: true })
        }
    }

    render() {
        this.developerLog('%c PortaComponent render this.props: %O', 'color: black;', this.props);
        this.developerLog('%c PortaComponent render this.state: %O', 'color: black;', this.state);
    
        // let currentUrl = window.location.href;
        // let url = UrlParser(currentUrl, true);
        // console.log('%c PortaComponent url: %O', 'color: maroon;', url);
    
        // addTrackingLog({ location: location, url: url }, false);

        let udicciIcon: string = '/udicci.ico';
        let orangeDotIcon: string = '/orange-dot.ico';
    
        let selectedProfile: any = super.getUdicciProfile();
        // console.log('%c PortaComponent selectedProfile: %O', 'color: purple; font-weight: bold;', selectedProfile);
    
        // super.getSomething();

        // let portalTabText: string = 'Udicci.';
        let portalTabIcon: any = null;
        if (selectedProfile && selectedProfile.title) {
            // portalTabText = selectedProfile.title + '.';
            if (selectedProfile.title !== 'Udicci') portalTabIcon = (<link rel="icon" href={orangeDotIcon} />);
        }
    
        if (!portalTabIcon) portalTabIcon = (<link rel="icon" href={udicciIcon} />);
    
        return (<PortaDisplay />);
    }
}

const PaperContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    display: 'flow-root',
    padding: '0',
    margin: '32px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    // maxWidth: '-webkit-fill-available', 
    minHeight: '200px',
    maxWidth: '300px',
    background: 'rgba(199, 199, 199, 0.8)',
}));

export function PortaDisplay() {
    const udicciContext = useUdicciContext();
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    let currentUrl = window.location.href;
    let url = UrlParser(currentUrl, true);

    // const theme = useTheme();
    // console.log('%c theme: %O', 'color: red;', theme);
    // const udicciClasses = udicciStyles(theme);
    // console.log('%c udicciClasses: %O', 'color: red;', udicciClasses);
    const udicciHelpers = useUdicciHelpers();

    let { udicci } = udicciContext.state;
    // console.log('%c Porta udicci: %O', 'color: maroon;', udicci);

    let { selectedProfile, currentUser, selectedPorta, instructionProcessingLogs, platformIssueReport } = udicci;
    // console.log('%c Porta selectedProfile: %O', 'color: purple; font-weight: bold;', selectedProfile);
    // console.log('%c Porta selectedPorta %s: %O', 'color: purple; font-weight: bold;', (selectedPorta && selectedPorta.Name ? selectedPorta.Name : 'unknonwn'), selectedPorta);
    // console.log('%c Porta currentUser: %O', 'color: purple; font-weight: bold;', currentUser);
    // console.log('%c Porta currentUserRole: %O', 'color: purple; font-weight: bold;', udicci.currentUserRole);
    // console.log('%c Porta platformIssueReport: %O', 'color: purple; font-weight: bold;', platformIssueReport);

    let dm: any = getDesignMode();
    // console.log('%c Porta dm: %O', 'color: maroon;', dm);

    const [value, setValue] = React.useState(0);
    const [currentHashUrl, setCurrentHashUrl] = useState<string>('');
    const [showLogin, setShowLogin] = React.useState((currentUser && currentUser.TimedOut ? true : false));
    // const [forcedUpdate, setForcedUpdate] = useState<boolean>(false);
    // console.log('%c Porta showLogin: %O', 'color: purple; font-weight: bold;', showLogin);
    // console.log('%c Porta currentHashUrl: %O', 'color: purple; font-weight: bold;', currentHashUrl);

    let { pathname, hash } = url;
    // console.log('%c Porta pathname: %O', 'color: purple;', pathname);
    // console.log('%c Porta hash: %O', 'color: purple;', hash);
    // console.log('%c Porta query: %O', 'color: purple;', query);

    let hashUrl: string = hash.replace('#', '').trim().toLowerCase();
    // console.log('%c Porta hashUrl: %O', 'color: purple;', hashUrl);
    // console.log('%c Porta href: %O', 'color: green;', href);
    // console.log('%c Porta host: %O', 'color: green;', host);
    // console.log('%c Porta hostname: %O', 'color: green;', hostname);
    // console.log('%c Porta origin: %O', 'color: green;', origin);
    // console.log('%c Porta port: %O', 'color: green;', port);
    // console.log('%c Porta protocol: %O', 'color: green;', protocol);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    window.addEventListener('hashchange', function() {
        // console.log('The hash has changed!')
        // setForcedUpdate(!forcedUpdate);
        if (hashUrl !== currentHashUrl) setCurrentHashUrl(hashUrl);
    }, false);

    // let portas: UdicciPorta[] = udicci.getPortas();
    // console.log('%c Porta portas: %O', 'color: green;', portas);

    // const udicciPortal = useUdicciProfile();
    // console.log('%c Porta udicciPortal: %O', 'color: maroon;', udicciPortal);
    // sayHi();

    let isNewPorta: boolean = (selectedPorta && selectedPorta.UdicciRecordId === 0 && selectedPorta.Name === 'new' ? true : false);
    // console.log('%c Porta isNewPorta: %O', 'color: purple; font-weight: bold;', isNewPorta);

    const [instructionProcessed, setInstructionProcessed] = useState<boolean>(false);
    // const [loadingInstructions, setLoadingInstructions] = useState<boolean>(false);
    const [loadingProfile, setLoadingProfile] = useState<boolean>(false);
    const [makingDefaultPorta, setMakingDefaultPorta] = useState<boolean>(false);
    const [makingLandingPorta, setMakingLandingPorta] = useState<boolean>(false);
    const [configurePorta, toggleConfigurePorta] = useState<boolean>(isNewPorta);
    const [configureSolutions, toggleConfigureSolutions] = useState<boolean>(false);
    // console.log('%c Porta configurePorta: %O', 'color: maroon;', configurePorta);
    // const [checkingLoadingInstructions, setCheckingLoadingInstructions] = useState<boolean>(false);
    // const [loadingInstructions, setLoadingInstructions] = useState<any>(null);
    // console.log('%c Porta loadingProfile: %O', 'color: green;', loadingProfile);
    // console.log('%c Porta loadingInstructions: %O', 'color: green;', loadingInstructions);

    // useEffect(() => {
    //     // console.log('%c Porta showLogin: %O', 'color: blue;', showLogin);
    //     // console.log('%c Porta currentUser: %O', 'color: blue;', currentUser);
    //     if (!showLogin && currentUser && currentUser.TimedOut) login();
    // }, [showLogin, currentUser]);

    const login = () => {
        // console.log('%c login evt: %O', 'color: maroon;', evt);
        setShowLogin(true);
    };

    const loginSuccessful = (evt: any) => {
        // console.log('%c loginSuccessful evt: %O', 'color: maroon;', evt);
        if (selectedPorta) udicci.setPorta(selectedPorta);
    };

    const closeLoginDialog = (evt: any) => {
        // console.log('%c closeLoginDialog currentUser: %O', 'color: maroon;', currentUser);
        if (currentUser && currentUser.TimedOut) logoff(false);
        setShowLogin(false);
    };

    const [showPortalConfig, setShowPortalConfiguration] = useState<boolean>(false);
    const [showPortalAgreements, setShowPortalAgreements] = useState<boolean>(false);
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);

    const [workingPortaUrl, setWorkingPortaUrl] = useState<string>('');
    const [configurePaneIndex, setConfigurePane] = useState<number>(-1);
    // console.log('%c Porta configurePaneIndex: %O', 'color: purple; font-weight: bold;', configurePaneIndex);
    const [uiFlashState, flashUI] = useState<boolean>(false);
    const [hasPaneToPaste, setHasPaneToPaste] = useState<boolean>(false);

    let paneToPaste: any = udicciHelpers.getPastedPane();
    // console.log('%c paneToPaste: %O', 'color: blue; font-weight: bold;', paneToPaste);

    useEffect(() => {
        if (paneToPaste) {
            setHasPaneToPaste(true);
            // flashUI(!uiFlashState);
        } else {
            setHasPaneToPaste(false);
        }
    }, [paneToPaste]);

    useEffect(() => {
        if (dm !== 'on' && configureSolutions === true) toggleConfigureSolutions(false);
    }, [dm]);

    if (!pathname || pathname === '/') pathname = '/udicci';
    // console.log('%c Porta pathname: %O', 'color: green;', pathname);

    let profile = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    // console.log('%c Porta profile: %O', 'color: maroon;', profile);

    if (profile && !profile.jsonProfileSettingsJson && profile.ProfileSettingsJson) {
        try {
            profile.jsonProfileSettingsJson = JSON.parse(profile.ProfileSettingsJson);
        } catch (e: any) {

        }
    }

    let profileUrl: any = (profile && profile.ProfileUrl ? profile.ProfileUrl : '');
    // console.log('%c Porta profileUrl: %O', 'color: maroon;', profileUrl);
    var publicRole = (profile && profile.PublicRole ? profile.PublicRole : null);
    // console.log('%c Porta publicRole: %O', 'color: maroon;', publicRole);
    var udicciUserRole = (profile && profile.UdicciUserRole ? profile.UdicciUserRole : null);
    // console.log('%c Porta udicciUserRole: %O', 'color: maroon;', udicciUserRole);
    var publicRoleId = (publicRole && publicRole.UdicciRecordId ? publicRole.UdicciRecordId : 0);
    var udicciUserRoleId = (udicciUserRole && udicciUserRole.UdicciRecordId ? udicciUserRole.UdicciRecordId : 0);
    var profileSettings = (profile && profile.jsonProfileSettingsJson ? profile.jsonProfileSettingsJson : null);
    // console.log('%c Porta profileSettings: %O', 'color: maroon;', profileSettings);
    var portalSetting: any = (profileSettings && profileSettings.portal ? profileSettings.portal : null);
    // console.log('%c Porta portalSetting: %O', 'color: maroon;', portalSetting);
    var portalDesignSetting: any = (portalSetting && portalSetting.design ? portalSetting.design : null);
    // console.log('%c Porta portalDesignSetting: %O', 'color: maroon;', portalDesignSetting);

    let portaName: string = (selectedPorta && selectedPorta.Name ? selectedPorta.Name : 'New Porta');
    // console.log('%c Porta portaName: %O', 'color: maroon;', portaName);
    let settingsJson: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : null);
    // console.log('%c Porta settingsJson: %O', 'color: maroon;', settingsJson);
    let portaUrl: string = (settingsJson && settingsJson.portaUrl ? settingsJson.portaUrl : '');
    // console.log('%c Porta portaUrl: %O', 'color: maroon;', portaUrl);

    if (!workingPortaUrl) {
        if (portaUrl) {
            setWorkingPortaUrl(portaUrl);
        } else {
            // console.log('%c portaName: %O', 'color: red;', portaName);
            let defaultPortaName: string = portaName.replace(/([^a-zA-Z0-9\w\.])/g,'').trim().toLowerCase();
            // console.log('%c defaultPortaName: %O', 'color: red;', defaultPortaName);
            setWorkingPortaUrl(defaultPortaName);
        }
    }

    // var userLoggedIn = (currentUser && currentUser.UdicciUserId > 0 ? true : false);
    var profileLoaded = (selectedProfile && selectedProfile.recordId > 0 ? true : false);

    const goToPortaUsingUrl = () => {
        // console.log('%c Porta goToPortaUsingUrl workingPortaUrl: %O', 'color: green;', workingPortaUrl);
        udicci.goToPortaUsingUrl();
    };

    if (portaUrl || hashUrl) {
        // console.log('%c Porta portaUrl: %O', 'color: orange;', portaUrl);
        // console.log('%c Porta hashUrl: %O', 'color: orange;', hashUrl);
        if (portaUrl !== hashUrl) {
            if (hashUrl === 'portal.social.icon') {
                if (!instructionProcessed && selectedProfile && selectedProfile.data.SocialIcon) {
                    setInstructionProcessed(true);
                    window.open(selectedProfile.data.SocialIcon, '_self');
                }
            } else {
                goToPortaUsingUrl();
            }
        }
    }

    var loginDialogElement: any = null;
    // console.log('%c Porta showLogin: %O', 'color: red;', showLogin);
    // console.log('%c Porta currentUser: %O', 'color: red;', currentUser);
    if (currentUser && currentUser.TimedOut) {
        loginDialogElement = (<LoginForm onClose={closeLoginDialog} onLoginSuccessful={loginSuccessful} data={{ showInline: false }} />);
    }

    let defaultPorta: boolean = (settingsJson && settingsJson.defaultPorta ? true : false);
    // console.log('%c Porta defaultPorta: %O', 'color: maroon;', defaultPorta);
    let landingPorta: boolean = (settingsJson && settingsJson.landingPorta ? true : false);
    // console.log('%c Porta landingPorta: %O', 'color: maroon;', landingPorta);
    let portaPanes: any[] = (settingsJson && settingsJson.panes ? settingsJson.panes : []);
    // console.log('%c Porta portaPanes: %O', 'color: maroon;', portaPanes);
    let gridSettings: any = (settingsJson && settingsJson.grid ? settingsJson.grid : null);
    // console.log('%c Porta gridSettings: %O', 'color: maroon;', gridSettings);
    // let gridDirection: string = (gridSettings && gridSettings.direction ? gridSettings.direction : 'row');
    // console.log('%c Porta gridDirection: %O', 'color: maroon;', gridDirection);
    let gridJustifyContent: string = (gridSettings && gridSettings.justifyContent ? gridSettings.justifyContent : 'flex-start');
    // console.log('%c Porta gridJustifyContent: %O', 'color: maroon;', gridJustifyContent);
    let gridAlignItems: string = (gridSettings && gridSettings.alignItems ? gridSettings.alignItems : 'flex-start');
    // console.log('%c Porta gridAlignItems: %O', 'color: maroon;', gridAlignItems);
    let gridSpacing: string = (gridSettings && gridSettings.spacing ? gridSettings.spacing : '1');
    // console.log('%c Porta gridSpacing: %O', 'color: maroon;', gridSpacing);

    let menuSettings: any = (settingsJson && settingsJson.menu ? settingsJson.menu : null);
    // console.log('%c Porta menuSettings: %O', 'color: maroon;', menuSettings);
    let portaMenuLabel: string = (menuSettings && menuSettings.label ? menuSettings.label : portaName);
    // console.log('%c Porta portaMenuLabel: %O', 'color: maroon;', portaMenuLabel);
    let portaMenuPriority: number = (menuSettings && menuSettings.priority ? menuSettings.priority : 1);
    // console.log('%c Porta portaMenuPriority: %O', 'color: maroon;', portaMenuPriority);
    let portaStatus: any = (settingsJson && settingsJson.status ? settingsJson.status : 'Design');
    // console.log('%c Porta portaStatus: %O', 'color: maroon;', portaStatus);
    let portaDisplay: any = (settingsJson && settingsJson.display ? settingsJson.display : 'plugins');
    // console.log('%c Porta portaDisplay: %O', 'color: maroon;', portaDisplay);

    let accessSettings: any = (settingsJson && settingsJson.access ? settingsJson.access : null);
    // console.log('%c Porta accessSettings: %O', 'color: maroon;', accessSettings);
    let accessRoles: any = (accessSettings && accessSettings.roles ? accessSettings.roles : null);
    // console.log('%c Porta accessRoles: %O', 'color: maroon;', accessRoles);
    let portaOverridesDesign: boolean = (settingsJson && settingsJson.design ? true : false);
    // console.log('%c Porta portaOverridesDesign: %O', 'color: green;', portaOverridesDesign);
    let portaDesignSettings: any = (settingsJson && settingsJson.design ? settingsJson.design : portalDesignSetting);
    // console.log('%c Porta portaDesignSettings: %O', 'color: green;', portaDesignSettings);
    let portaPaneOuter: any = (portaDesignSettings && portaDesignSettings.outer ? portaDesignSettings.outer : null);
    // console.log('%c Porta portaPaneOuter: %O', 'color: green;', portaPaneOuter);
    let headerSettings: any = (portaDesignSettings && portaDesignSettings.header ? portaDesignSettings.header : null);
    // console.log('%c Porta headerSettings: %O', 'color: maroon;', headerSettings);
    let portaPaper: any = (portaDesignSettings && portaDesignSettings.paper ? portaDesignSettings.paper : null);
    // console.log('%c Porta portaPaper: %O', 'color: green;', portaPaper);
    let portaPaneText: any = (portaDesignSettings && portaDesignSettings.text ? portaDesignSettings.text : null);
    // console.log('%c Porta portaPaneText: %O', 'color: green;', portaPaneText);
    let portaPaneButtons: any = (portaDesignSettings && portaDesignSettings.button ? portaDesignSettings.button : null);
    // console.log('%c Porta portaPaneButtons: %O', 'color: green;', portaPaneButtons);
    let portaPaneMenu: any = (portaDesignSettings && portaDesignSettings.menu ? portaDesignSettings.menu : null);
    // console.log('%c Porta portaPaneMenu: %O', 'color: green;', portaPaneMenu);

    let hasAccess: boolean = true;
    if (accessRoles) {
        if (udicciUserRoleId > 0 && accessRoles[udicciUserRoleId] !== true) hasAccess = false;
        if (udicciUserRoleId <= 0 && accessRoles[publicRoleId] !== true) hasAccess = false;
    }
    // console.log('%c Porta hasAccess: %O', 'color: maroon;', hasAccess);

    let loadProfileUrl: string = '';
    if (!loadingProfile && !selectedProfile) {
        if (profileUrl && profileUrl !== pathname) {
            loadProfileUrl = pathname;
        } else {
            // console.log('%c Porta pathname: %O', 'color: red;', pathname);
            loadProfileUrl = '/udicci';
        }
    } else if (!loadingProfile && selectedProfile) {
        if (profileUrl && profileUrl !== pathname) {
            loadProfileUrl = pathname;
        }
    }

    if (!loadingProfile && loadProfileUrl) {
        // udicci.getProfileByUrl(pathname, true);
        udicci.getProfileSplashByUrl(pathname, true);
        setLoadingProfile(true);
    }

    const mainMenuClicked = (settings: any) => {
        // console.log('%c mainMenuClicked settings: %O', 'color: maroon;', settings);
        if (settings && settings.showChangePassword !== undefined) setShowChangePassword(settings.showChangePassword);
        if (settings && settings.showPortalAgreements !== undefined) setShowPortalAgreements(settings.showPortalAgreements);
        if (settings && settings.showPortalConfig !== undefined) setShowPortalConfiguration(settings.showPortalConfig);
    }

    const addPane = (paneToAdd: any = null) => {
        let settings: any = null;
        if (selectedPorta && selectedPorta.SettingsJson) {
            settings = selectedPorta.SettingsJson;
        } else {
            settings = {};
        }
        // console.log('%c Porta addPane settings: %O', 'color: maroon;', settings);

        let portaPanes: any[] = (settings.panes ? settings.panes : []);
        // console.log('%c Porta addPane portaPanes: %O', 'color: maroon;', portaPanes);

        if (paneToAdd) {
            let chkDupNames: any = filter(portaPanes, (p: any) => p.name === paneToAdd.name);
            // console.log('%c Porta addPane chkDupNames: %O', 'color: maroon;', chkDupNames);
            if (chkDupNames.length > 0) paneToAdd.name += ' ' + chkDupNames.length.toString();
            portaPanes.push(paneToAdd);
        } else {
            portaPanes.push({
                contentDesign: null,
                previewContentDesign: null,
                displayComponent: '',
                lookAndFeel: null,
                name: ''
            });
        }

        settings.panes = portaPanes;
        // console.log('%c Porta addPane settings: %O', 'color: blue;', settings);
        if (selectedPorta) {
            selectedPorta.SettingsJson = settings;
            selectedPorta.IsDirty = true;
            udicci.updatePorta(selectedPorta);
            setConfigurePane(portaPanes.length - 1);
        }
    };

    const pastePane = () => {
        let pastedPane: any = udicciHelpers.getPastedPane();
        // console.log('%c Porta pastePane pastedPane: %O', 'color: green;', pastedPane);
        addPane(pastedPane);
        sessionStorage.removeItem('udicci.copied.pane');
        setHasPaneToPaste(false);
    }

    const updatePortaDetails = (evt: any) => {
        // console.log('%c Porta updatePortaDetails evt: %O', 'color: maroon;', evt);
        // console.log('%c Porta updatePortaDetails selectedPorta: %O', 'color: maroon;', selectedPorta);

        let porta: any = selectedPorta;

        let trgt = evt.target;
        let fieldName = (trgt ? trgt.name : '');
        // console.log('%c Porta updatePortaDetails fieldName: %O', 'color: maroon;', fieldName);
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c Porta updatePortaDetails newValue: %O', 'color: maroon;', newValue);

        if (porta && fieldName) {
            porta[fieldName] = newValue;
            porta.IsDirty = true;
            // console.log('%c Porta updatePortaDetails porta: %O', 'color: maroon;', porta);
            udicci.updatePorta(porta);
        }
    };

    const updatePortaSetting = (evt: any, settingName: string = '', subSettingName: string = '', numberValue: number | number[] | undefined = undefined) => {
        // console.log('%c Porta updatePortaSetting selectedPorta: %O', 'color: maroon;', selectedPorta);
        // console.log('%c Porta updatePortaSetting settingName: %O', 'color: maroon;', settingName);
        // console.log('%c Porta updatePortaSetting subSettingName: %O', 'color: maroon;', subSettingName);
        // console.log('%c Porta updatePortaSetting numberValue: %O', 'color: maroon;', numberValue);
        // console.log('%c Porta updatePortaSetting evt: %O', 'color: maroon;', evt);

        let porta: any = selectedPorta;
        if (porta && settingName) {
            let settings: any = null;
            if (porta.SettingsJson) {
                settings = porta.SettingsJson;
            } else {
                settings = {};
            }
            // console.log('%c Porta updatePortaSetting settings: %O', 'color: maroon;', settings);

            let newPortaUrl: string = workingPortaUrl;
            if (settingName === 'access' || (settingName === 'menu' && (subSettingName !== 'roles' && subSettingName !== 'priority' && subSettingName !== 'label'))) {
                // evt is not a standard event, but an onChange response from the AccessSettingsAdmin component
                // if (props && props.onChange) props.onChange({ [event.target.name]: event.target.checked });
                // event.target.name === Role Id
                // event.target.checked === Updated Toggle State (indicating porta is only viewable by those that are toggled into the on position)
                // console.log('%c Porta updatePortaSetting access or menu (not roles, priority or label) evt: %O', 'color: maroon;', evt);
                if (evt) {
                    if (subSettingName) {
                        if (!settings[settingName]) settings[settingName]= {};
                        let curSettings: any = (settings[settingName][subSettingName] ? settings[settingName][subSettingName] : {});
                        Object.assign(curSettings, evt);
                        settings[settingName][subSettingName] = curSettings;
                    } else {
                        let curSettings: any = (settings[settingName] ? settings[settingName] : {});
                        Object.assign(curSettings, evt);
                        settings[settingName] = curSettings;
                    }
                }
            } else if (settingName === 'menu' && subSettingName === 'roles') {
                // console.log('%c Porta updatePortaSetting menu.roles settingName: %O', 'color: maroon;', settingName);
                // console.log('%c Porta updatePortaSetting menu.roles subSettingName: %O', 'color: maroon;', subSettingName);
                // console.log('%c Porta updatePortaSetting menu.roles evt: %O', 'color: maroon;', evt);
                if (evt) {
                    // evt is object { 123456: { label: "Label 1", priority: 1 } }
                    // where 123456 is the Role Id of the menu that is being overridden

                    if (!settings[settingName]) settings[settingName] = {};
                    let curSettings: any = (settings[settingName][subSettingName] ? settings[settingName][subSettingName] : {});
                    forEach(keys(evt), (key: string) => { curSettings[key] = evt[key]; });
                    // console.log('%c Porta updatePortaSetting menu.roles curSettings: %O', 'color: maroon;', curSettings);
                    settings[settingName][subSettingName] = curSettings;
                }
            } else if (settingName === 'design') {
                // evt is not a standard event, but an onChange response from the DesignSettingsAdmin component
                // if (props && props.onChange) props.onChange({ [event.target.name]: event.target.checked });
                // event.target.name === Role Id
                // event.target.checked === Updated Toggle State (indicating porta is only viewable by those that are toggled into the on position)
                // console.log('%c Porta updatePortaSetting design settingName: %O', 'color: maroon;', settingName);
                // console.log('%c Porta updatePortaSetting design subSettingName: %O', 'color: maroon;', subSettingName);
                // console.log('%c Porta updatePortaSetting design evt: %O', 'color: maroon;', evt);
                if (evt) {
                    if (subSettingName) {
                        if (!settings[settingName]) settings[settingName]= {};
                        let curSettings: any = (settings[settingName][subSettingName] ? settings[settingName][subSettingName] : {});
                        Object.assign(curSettings, evt);
                        settings[settingName][subSettingName] = curSettings;
                    } else {
                        let curSettings: any = (settings[settingName] ? settings[settingName] : {});
                        Object.assign(curSettings, evt);
                        settings[settingName] = curSettings;
                    }
                }
            } else {
                let trgt = evt.target;
                let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
                // console.log('%c Porta updatePortaSetting newValue: %O', 'color: maroon;', newValue);
                // console.log('%c Porta updatePortaSetting settingName: %O', 'color: maroon;', settingName);
                // console.log('%c Porta updatePortaSetting subSettingName: %O', 'color: maroon;', subSettingName);
                // console.log('%c Porta updatePortaSetting evt: %O', 'color: maroon;', evt);

                if (subSettingName) {
                    if (!settings[settingName]) settings[settingName]= {};
                    settings[settingName][subSettingName] = (numberValue !== undefined ? numberValue : newValue);
                } else {
                    settings[settingName] = (numberValue !== undefined ? numberValue : newValue);
                }

                if (settingName === 'portaUrl') newPortaUrl = newValue;
            }

            // console.log('%c Porta updatePortaSetting settings: %O', 'color: maroon;', settings);
            if (settingName === 'portaUrl') {
                setWorkingPortaUrl(newPortaUrl)
            } else {
                porta.SettingsJson = settings;
                porta.IsDirty = true;
                // console.log('%c Porta updatePortaSetting porta: %O', 'color: maroon;', porta);
                udicci.updatePorta(porta);
            }
        }
    };

    const applyPortaUrl = () => {
        // console.log('%c Porta applyPortaUrl selectedPorta: %O', 'color: maroon;', selectedPorta);
        // console.log('%c Porta applyPortaUrl workingPortaUrl: %O', 'color: maroon;', workingPortaUrl);
        // console.log('%c Porta applyPortaUrl portalUrl: %O', 'color: maroon;', portalUrl);
        // console.log('%c Porta applyPortaUrl numberValue: %O', 'color: maroon;', numberValue);
        // console.log('%c Porta applyPortaUrl evt: %O', 'color: maroon;', evt);

        let porta: any = selectedPorta;
        if (porta && workingPortaUrl) {
            let settings: any = null;
            if (porta.SettingsJson) {
                settings = porta.SettingsJson;
            } else {
                settings = {};
            }
            // console.log('%c Porta applyPortaUrl settings: %O', 'color: maroon;', settings);

            settings.portaUrl = workingPortaUrl;
            // window.location.hash = '#' + workingPortaUrl;

            porta.SettingsJson = settings;
            porta.IsDirty = true;
            // console.log('%c Porta applyPortaUrl porta: %O', 'color: maroon;', porta);
            udicci.updatePorta(porta);
        }
    };

    const duplicatePorta = () => {
        // console.log('%c duplicatePorta selectedPorta: %O', 'color: maroon;', selectedPorta);
        if (!selectedPorta) return false;
        let porta: any = {};
        Object.assign(porta, selectedPorta);
        // console.log('%c duplicatePorta porta: %O', 'color: maroon;', porta);
        let newPorta: UdicciPorta = {
            UdicciRecordId: 0,
            Name: '',
            Purpose: '',
            Settings: '',
            SettingsJson: null,
            IsDirty: false,
            CreatedByUserId: 0,
        };
        // console.log('%c duplicatePorta newPorta: %O', 'color: maroon;', newPorta);
        Object.assign(newPorta, porta);
        // console.log('%c duplicatePorta newPorta: %O', 'color: maroon;', newPorta);
        newPorta.UdicciRecordId = 0;
        newPorta.CreatedByUserId = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
        newPorta.IsDirty = true;
        newPorta.Name = newPorta.Name + ' *DUPLICATED*';
        udicci.setPorta(newPorta);
    };

    const overridePortaPaneDesignFromPortal = (evt: any) => {
        var porta: any = selectedPorta;
        if (porta) {
            let settings: any = null;
            if (porta.SettingsJson) {
                settings = porta.SettingsJson;
            } else {
                settings = {};
            }
            // console.log('%c Porta overridePortaPaneDesign settings: %O', 'color: maroon;', settings);

            settings.design = portalDesignSetting;

            porta.SettingsJson = settings;
            porta.IsDirty = true;
            // console.log('%c Porta overridePortaPaneDesign porta: %O', 'color: maroon;', porta);
            udicci.updatePorta(porta);
        }
    };

    const overridePortaPaneDesign = (evt: any) => {
        var porta: any = selectedPorta;
        if (porta) {
            let settings: any = null;
            if (porta.SettingsJson) {
                settings = porta.SettingsJson;
            } else {
                settings = {};
            }
            // console.log('%c Porta overridePortaPaneDesign settings: %O', 'color: maroon;', settings);

            settings.design = { inner: {}, outer: {} };

            porta.SettingsJson = settings;
            porta.IsDirty = true;
            // console.log('%c Porta overridePortaPaneDesign porta: %O', 'color: maroon;', porta);
            udicci.updatePorta(porta);
        }
    };

    const removePortaPaneDesignOverride = (evt: any) => {
        var porta: any = selectedPorta;
        if (porta) {
            let settings: any = null;
            if (porta.SettingsJson) {
                settings = porta.SettingsJson;
            } else {
                settings = {};
            }
            // console.log('%c Porta removePortaPaneDesignOverride settings: %O', 'color: maroon;', settings);

            settings.design = null;

            porta.SettingsJson = settings;
            try {
                porta.Settings = JSON.stringify(porta.SettingsJson);
            } catch (err: any) {
                // console.log('%c err: %O', 'color: red;', err);
            }
            porta.IsDirty = true;
            // console.log('%c Porta removePortaPaneDesignOverride porta: %O', 'color: maroon;', porta);
            udicci.updatePorta(porta);
            flashUI(!uiFlashState);
        }
    };

    const makePortaDesignPortalDesign = (evt: any) => {
        var porta: any = selectedPorta;
        if (selectedProfile && porta) {
            let settings: any = null;
            if (porta.SettingsJson) {
                settings = porta.SettingsJson;
            } else {
                settings = {};
            }
            // console.log('%c Porta overridePortaPaneDesign settings: %O', 'color: maroon;', settings);

            if (selectedProfile.data) {
                if (!selectedProfile.data.jsonProfileSettingsJson) {
                    selectedProfile.data.jsonProfileSettingsJson = {};
                }
                if (!selectedProfile.data.jsonProfileSettingsJson.portal) {
                    selectedProfile.data.jsonProfileSettingsJson.portal = {};
                }
                selectedProfile.data.jsonProfileSettingsJson.portal.design = settings.design;
                selectedProfile.isDirty = true;
                udicci.setProfile(selectedProfile);
            }
        }
    };

    const updatePortalSetting = (evt: any, settingName: string = '', subSettingName: string = '', numberValue: number | number[] | undefined = undefined) => {
        // console.log('%c Porta updatePortalSetting selectedPorta: %O', 'color: maroon;', selectedPorta);
        // console.log('%c Porta updatePortalSetting settingName: %O', 'color: maroon;', settingName);
        // console.log('%c Porta updatePortalSetting subSettingName: %O', 'color: maroon;', subSettingName);
        // console.log('%c Porta updatePortalSetting numberValue: %O', 'color: maroon;', numberValue);
        // console.log('%c Porta updatePortalSetting evt: %O', 'color: maroon;', evt);

        if (!selectedProfile || (selectedProfile && !selectedProfile.data)) return false;

        let profile: any = selectedProfile.data;
        if (profile && settingName) {
            let settings: any = null;
            if (profile.jsonProfileSettingsJson && selectedProfile.data.jsonProfileSettingsJson.portal) {
                settings = selectedProfile.data.jsonProfileSettingsJson.portal;
            } else {
                settings = {};
            }
            // console.log('%c Porta updatePortalSetting settings: %O', 'color: maroon;', settings);

            if (settingName === 'design') {
                if (evt) {
                    if (subSettingName) {
                        if (!settings[settingName]) settings[settingName]= {};
                        let curSettings: any = (settings[settingName][subSettingName] ? settings[settingName][subSettingName] : {});
                        Object.assign(curSettings, evt);
                        settings[settingName][subSettingName] = curSettings;
                    } else {
                        let curSettings: any = (settings[settingName] ? settings[settingName] : {});
                        Object.assign(curSettings, evt);
                        settings[settingName] = curSettings;
                    }
                }
            }

            // console.log('%c Porta updatePortalSetting settings: %O', 'color: maroon;', settings);
            if (!selectedProfile.data.jsonProfileSettingsJson) {
                selectedProfile.data.jsonProfileSettingsJson = {};
            }
            if (!selectedProfile.data.jsonProfileSettingsJson.portal) {
                selectedProfile.data.jsonProfileSettingsJson.portal = {};
            }
            selectedProfile.data.jsonProfileSettingsJson.portal.design = settings.design;
            selectedProfile.isDirty = true;
            // console.log('%c Porta updatePortalSetting selectedProfile: %O', 'color: blue;', selectedProfile);
            udicci.setProfile(selectedProfile);
        }
    };

    const savePorta = () => {
        // console.log('%c Porta savePorta selectedPorta: %O', 'color: maroon;', selectedPorta);

        if (selectedPorta !== null && selectedPorta.IsDirty) {
            // console.log('%c Porta savePorta selectedPorta: %O', 'color: green;', selectedPorta);
            udicci.savePorta();
        }
    };

    const makePortaDefaultForPortal = (evt: any) => {
        // console.log('%c Porta makePortaDefaultForPortal selectedPorta: %O', 'color: maroon;', selectedPorta);
        if (selectedPorta !== null) {
            setMakingDefaultPorta(true);
            udicci.makePortaDefault(selectedPorta);
        }
    };

    const makePortaLandingForPortal = (evt: any) => {
        // console.log('%c Porta makePortaLandingForPortal selectedPorta: %O', 'color: maroon;', selectedPorta);
        if (selectedPorta !== null) {
            setMakingLandingPorta(true);
            udicci.makePortaLandingPorta(selectedPorta);
        }
    };

    const getColorValue = (ctc: any) => {
        if (typeof(ctc) === 'object') {
            return `rgba(${ ctc.r }, ${ ctc.g }, ${ ctc.b }, ${ ctc.a })`;
        } else {
            return ctc;
        }
    }

    const rightAlignedButtonTheme: Theme = udicciHelpers.getUdicciThemeTemplate('MuiIconButton', null);
    // console.log('%c rightAlignedButtonTheme: %O', 'color: blue; font-weight: bold;', rightAlignedButtonTheme);

    let displayTerminal: boolean = false;
    let displayingSplash: boolean = false;

    // let isDeveloper: boolean = false;
    // if (currentUser && udicci.developerUsers.indexOf(currentUser.UserName.toLowerCase()) >= 0) isDeveloper = true;
    let isSpecialUser: boolean = false;
    if (currentUser && udicci.specialUsers.indexOf(currentUser.UserName.toLowerCase()) >= 0) isSpecialUser = true;

    let stopAtLoading: boolean = false;
    let stopAtLoadingProfile: boolean = false;
    // console.log('%c Porta selectedProfile: %O', 'color: red; font-weight: bold;', selectedProfile);
    // console.log('%c Porta selectedPorta: %O', 'color: red; font-weight: bold;', selectedPorta);
    // console.log('%c Porta portaPanes: %O', 'color: red; font-weight: bold;', portaPanes);
    let displayMe: boolean = (selectedProfile && !selectedPorta && (!portaPanes || (portaPanes && portaPanes.length <= 0)) ? true : false);
    // console.log('%c Porta displayMe: %O', 'color: red; font-weight: bold;', displayMe);
    // let displayMe: boolean = (!isSpecialUser ? true : false);
    // if (defaultPorta && dm !== 'on') displayMe = true;
    // console.log('%c Porta displayMe: %O', 'color: red; font-weight: bold;', displayMe);
    // displayMe = true;
    if (portaDisplay === 'focusBoard' && dm !== 'on') displayMe = false;
    // console.log('%c Porta displayMe: %O', 'color: red; font-weight: bold;', displayMe);

    let displaySettings: any = {
        allowHeader: false,
        allowConsole: false,
        showHeader: false,
        showChangePassword: showChangePassword,
        showPortalConfig: showPortalConfig,
        configurePorta: configurePorta,
    };
    // console.log('%c Porta displaySettings: %O', 'color: blue; font-weight: bold;', displaySettings);

    let paperSx: any = {
    };

    var splashElement: any = null;
    var udicciLogoElement: any = null;
    var waitingElement: any = null;
    if (!profileLoaded || stopAtLoading || stopAtLoadingProfile) {
        var waitMessage = 'Loading Udicci Portal ... please wait.';
        // udicciLogoElement = (
        //     <img src={UdicciLogo} alt="Udicci" style={{ maxWidth: '90%', maxHeight: '100px', width: 'auto', height: 'auto', marginLeft: 'auto', marginRight: 'auto' }} />
        // );
        udicciLogoElement = (<PortalSplash />);
        displayingSplash = true;
        waitingElement = (
            <Box sx={{ margin: '8px', marginTop: '32px' }}>
                <LinearProgress color="inherit" variant="query" />
                <Typography variant="body2" component="div" sx={{ marginTop: '16px' }}>
                    {waitMessage}
                </Typography>
            </Box>
        );
        splashElement = (
            <PaperContainer elevation={7}>
                {udicciLogoElement}
                {waitingElement}
            </PaperContainer>
        );
    }
    if (stopAtLoading) return splashElement;

    let gs: number = 1;
    if (gridSpacing) gs = parseFloat(gridSpacing);

    if (stopAtLoadingProfile) {
        return (
            <Grid container spacing={1} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={12} md={9} lg={6}>
                    <PaperContainer elevation={7}>
                        {udicciLogoElement}
                        {waitingElement}
                    </PaperContainer>
                </Grid>
            </Grid>
        );
    }

    let headerPrimaryColor: any = (headerSettings && headerSettings.primaryColor ? headerSettings.primaryColor : '');
    let headerSecondaryColor: any = (headerSettings && headerSettings.secondaryColor ? headerSettings.secondaryColor : '');

    let headerPrimaryColorValue: string = udicciHelpers.convertColorToString(headerPrimaryColor, '#000');
    let headerSecondaryColorValue: string = udicciHelpers.convertColorToString(headerSecondaryColor, '#131C5F');

    // console.log('%c Porta menuSettings: %O', 'color: red; font-weight: bold;', menuSettings);
    // console.log('%c Porta portaPaneMenu: %O', 'color: red; font-weight: bold;', portaPaneMenu);
    let activeMenuTextColor: any = (portaPaneMenu && portaPaneMenu.activeMenuTextColor ? portaPaneMenu.activeMenuTextColor : '');
    // console.log('%c Porta activeMenuTextColor: %O', 'color: red; font-weight: bold;', activeMenuTextColor);
    let inactiveMenuTextColor: any = (portaPaneMenu && portaPaneMenu.inactiveMenuTextColor ? portaPaneMenu.inactiveMenuTextColor : '');
    let selectedMenuIndicatorColor: any = (portaPaneMenu && portaPaneMenu.selectedMenuIndicatorColor ? portaPaneMenu.selectedMenuIndicatorColor : '');

    let activeMenuTextColorValue: string = udicciHelpers.convertColorToString(activeMenuTextColor, '#000');
    // console.log('%c Porta activeMenuTextColorValue: %O', 'color: red; font-weight: bold;', activeMenuTextColorValue);
    let inactiveMenuTextColorValue: string = udicciHelpers.convertColorToString(inactiveMenuTextColor, '#777');
    let selectedMenuIndicatorColorValue: string = udicciHelpers.convertColorToString(selectedMenuIndicatorColor, '#015A14');

    let textPrimaryColor: any = udicciHelpers.getValueFromSettings(portaPaneText, 'primaryColor', '#000');
    let textSecondaryColor: any = udicciHelpers.getValueFromSettings(portaPaneText, 'secondaryColor', '#000');
    let textInfoColor: any = udicciHelpers.getValueFromSettings(portaPaneText, 'infoColor', '#000');
    let textSuccessColor: any = udicciHelpers.getValueFromSettings(portaPaneText, 'successColor', '#000');
    let textWarningColor: any = udicciHelpers.getValueFromSettings(portaPaneText, 'warningColor', '#000');
    let textErrorColor: any = udicciHelpers.getValueFromSettings(portaPaneText, 'errorColor', '#BD2703');

    let buttonPrimaryColor: any = (portaPaneButtons && portaPaneButtons.primary ? portaPaneButtons.primary : null);
    if (buttonPrimaryColor && !buttonPrimaryColor.main) delete portaPaneButtons["primary"];
    let buttonSecondaryColor: any = (portaPaneButtons && portaPaneButtons.secondary ? portaPaneButtons.secondary : null);
    if (buttonSecondaryColor && !buttonSecondaryColor.main) delete portaPaneButtons["secondary"];
    let buttonInfoColor: any = (portaPaneButtons && portaPaneButtons.info ? portaPaneButtons.info : null);
    if (buttonInfoColor && !buttonInfoColor.main) delete portaPaneButtons["info"];
    let buttonSuccessColor: any = (portaPaneButtons && portaPaneButtons.success ? portaPaneButtons.success : null);
    if (buttonSuccessColor && !buttonSuccessColor.main) delete portaPaneButtons["success"];
    // let buttonWarningColor: any = (portaPaneButtons && portaPaneButtons.warning ? portaPaneButtons.warning : null);
    // if (buttonWarningColor && !buttonWarningColor.main) delete portaPaneButtons["warning"];

    // let buttonPrimaryColor: any = udicciHelpers.getValueFromSettings(portaPaneText, 'primaryColor', 'color');
    // let buttonSecondaryColor: any = udicciHelpers.getValueFromSettings(portaPaneText, 'secondaryColor', 'color');
    // let buttonInfoColor: any = udicciHelpers.getValueFromSettings(portaPaneText, 'infoColor', 'color');
    // let buttonSuccessColor: any = udicciHelpers.getValueFromSettings(portaPaneText, 'successColor', 'color');
    // let buttonWarningColor: any = udicciHelpers.getValueFromSettings(portaPaneText, 'warningColor', 'color');

    let buttonPrimaryColorValue: string = udicciHelpers.convertColorToString(buttonPrimaryColor, '#000');  // Black
    let buttonSecondaryColorValue: string = udicciHelpers.convertColorToString(buttonSecondaryColor, '#4C6FB1');  // SlateBlue
    let buttonInfoColorValue: string = udicciHelpers.convertColorToString(buttonInfoColor, '#9898A1');  // Grey
    let buttonSuccessColorValue: string = udicciHelpers.convertColorToString(buttonSuccessColor, '#228B22');  // ForestGreen
    // let buttonWarningColorValue: string = udicciHelpers.convertColorToString(buttonWarningColor, '#D2691E');  // Chocolate - Deep Orange

    let typographySettings: any = null;
    // #region Typography Settings Setup

    if (portaPaneText) {
        typographySettings = {};
        Object.assign(typographySettings, portaPaneText);
    } else {
        typographySettings = {};
    }
    if (!typographySettings.leftFieldLabel) typographySettings.leftFieldLabel = {
        color: textInfoColor,
        marginleft: '4px',
        marginRight: '4px',
        fontSize: '0.75rem'
    };
    if (!typographySettings.overFieldLabel) typographySettings.overFieldLabel = {
        color: textSecondaryColor,
        marginBottom: '4px',
        fontSize: '0.75rem'
    };
    if (!typographySettings.errorMessage) typographySettings.errorMessage = {
        color: textErrorColor,
        marginBottom: '4px',
        fontSize: '0.75rem'
    };
    if (!typographySettings.clickable) typographySettings.clickable = {
        color: textSecondaryColor,
        marginBottom: '4px',
        fontSize: '0.75rem',
        cursor: 'pointer'
    };
    if (!typographySettings.helpText) typographySettings.helpText = {
        color: buttonInfoColorValue,
        margin: '4px',
        padding: '4px',
        fontSize: '0.75rem',
        border: '1px solid ' + buttonInfoColorValue
    };
    if (!typographySettings.subtitle1) typographySettings.subtitle1 = {
        color: textPrimaryColor
    };
    if (!typographySettings.clickableSubTitle1) typographySettings.clickableSubTitle1 = {
        color: textPrimaryColor,
        cursor: 'pointer'
    };
    if (!typographySettings.subtitle2) typographySettings.subtitle2 = {
        color: textSecondaryColor
    };
    if (!typographySettings.clickableSubTitle2) typographySettings.clickableSubTitle2 = {
        color: textSecondaryColor,
        cursor: 'pointer'
    };
    if (!typographySettings.body1) typographySettings.body1 = {
        color: textPrimaryColor
    };
    if (!typographySettings.body2) typographySettings.body2 = {
        color: textPrimaryColor
    };
    if (!typographySettings.caption) typographySettings.caption = {
        color: textInfoColor
    };
    if (!typographySettings.h1) typographySettings.h1 = {
        color: textPrimaryColor
    };
    if (!typographySettings.h2) typographySettings.h2 = {
        color: textSecondaryColor
    };
    if (!typographySettings.h3) typographySettings.h3 = {
        color: textSuccessColor
    };
    if (!typographySettings.h4) typographySettings.h4 = {
        color: textInfoColor
    };
    if (!typographySettings.h5) typographySettings.h5 = {
        color: textErrorColor
    };
    if (!typographySettings.h6) typographySettings.h6 = {
        color: textWarningColor,
    };
    if (!typographySettings.text) typographySettings.text = {
        primary: textPrimaryColor,
        secondary: textSecondaryColor,
        disabled: textInfoColor,
    };

    // #endregion
    // console.log('%c Porta typographySettings: %O', 'color: maroon;', typographySettings);

    let portaThemePaletteSettings: any = null;
    // #region Palette Settings Setup

    if (portaPaneButtons) {
        portaThemePaletteSettings = portaPaneButtons;
    } else {
        portaThemePaletteSettings = {};
    }
    if (portaThemePaletteSettings.contrastThreshhold !== 3) portaThemePaletteSettings.contrastThreshhold = 3;
    if (portaThemePaletteSettings.contrastThreshold !== 3) portaThemePaletteSettings.contrastThreshold = 3;
    if (portaThemePaletteSettings.tonalOffset !== 0.2) portaThemePaletteSettings.tonalOffset = 0.2;
    if (!portaThemePaletteSettings.primary) portaThemePaletteSettings.primary = {
        // light: will be calculated from palette.primary.main,
        main: buttonPrimaryColorValue,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
    };
    if (!portaThemePaletteSettings.secondary) portaThemePaletteSettings.secondary = { main: buttonSecondaryColorValue };
    if (!portaThemePaletteSettings.text) portaThemePaletteSettings.text = { primary: buttonInfoColorValue, secondary: buttonSecondaryColorValue };
    if (!portaThemePaletteSettings.info) portaThemePaletteSettings.info = { main: buttonInfoColorValue };
    if (!portaThemePaletteSettings.default) portaThemePaletteSettings.default = { main: buttonInfoColorValue };
    if (!portaThemePaletteSettings.success) portaThemePaletteSettings.success = { main: buttonSuccessColorValue };
    // if (!portaThemePaletteSettings.warning) portaThemePaletteSettings.warning = { main: buttonWarningColorValue };
    if (!portaThemePaletteSettings.cancelButton) portaThemePaletteSettings.cancelButton = { main: buttonInfoColorValue };
    if (!portaThemePaletteSettings.loginButton) portaThemePaletteSettings.loginButton = { main: buttonSecondaryColorValue };
    if (!portaThemePaletteSettings.saveButton) portaThemePaletteSettings.saveButton = { main: buttonSuccessColorValue};
    if (!portaThemePaletteSettings.editButton) portaThemePaletteSettings.editButton = { main: buttonPrimaryColorValue};
    if (!portaThemePaletteSettings.deleteButton) portaThemePaletteSettings.deleteButton = { main: textSecondaryColor};
    if (!portaThemePaletteSettings.moveButton) portaThemePaletteSettings.moveButton = { main: buttonInfoColorValue};
    if (!portaThemePaletteSettings.engagementButton1) portaThemePaletteSettings.engagementButton1 = { main: buttonInfoColorValue};
    if (!portaThemePaletteSettings.engagementButton2) portaThemePaletteSettings.engagementButton2 = { main: buttonInfoColorValue};
    if (!portaThemePaletteSettings.engagementButton3) portaThemePaletteSettings.engagementButton3 = { main: buttonInfoColorValue};
    if (!portaThemePaletteSettings.portalMenuIcon) portaThemePaletteSettings.portalMenuIcon = { main: textSecondaryColor};
    if (!portaThemePaletteSettings.platformMenuIcon) portaThemePaletteSettings.platformMenuIcon = { main: textSecondaryColor};

    // #endregion
    // console.log('%c Porta portaThemePaletteSettings: %O', 'color: maroon;', portaThemePaletteSettings);

    let linkSettings: any = {
        // active: '#fff',
        // activeOpacity: 1,
        // hover: '#fff',
        // hoverOpacity: 0.7,
        // focus: '#fff',
        // focusOpacity: 1,
        // selected: '#fff',
        // selectedOpacity: 1
    };
    // console.log('%c Porta linkSettings: %O', 'color: maroon;', linkSettings);

    let headerPaletteSettings: any = {};
    Object.assign(headerPaletteSettings, portaThemePaletteSettings);
    if (!headerPaletteSettings.primary) headerPaletteSettings.primary = { main: headerPrimaryColorValue };
    if (!headerPaletteSettings.secondary) headerPaletteSettings.secondary = { main: headerSecondaryColorValue };
    // console.log('%c Porta headerPaletteSettings: %O', 'color: maroon;', headerPaletteSettings);

    let headerTypographySettings: any = {};
    Object.assign(headerTypographySettings, portaThemePaletteSettings);
    if (!headerTypographySettings.subtitle1) headerTypographySettings.subtitle1 = {
        color: headerPrimaryColorValue,
        main: headerPrimaryColorValue,
    };
    if (!headerTypographySettings.subtitle2) headerTypographySettings.subtitle2 = {
        color: headerSecondaryColorValue,
        main: headerSecondaryColorValue,
    };
    if (!headerTypographySettings.caption) headerTypographySettings.caption = {
        color: headerSecondaryColorValue,
        main: headerSecondaryColorValue,
    };
    if (!headerTypographySettings.clickableSubTitle1) headerTypographySettings.clickableSubTitle1 = {
        color: activeMenuTextColorValue,
        main: activeMenuTextColorValue,
    };
    if (!headerTypographySettings.clickableSubTitle2) headerTypographySettings.clickableSubTitle2 = {
        color: inactiveMenuTextColorValue,
        main: inactiveMenuTextColorValue,
    };
    if (!headerTypographySettings.body2) headerTypographySettings.body2 = {
        color: selectedMenuIndicatorColorValue,
        main: selectedMenuIndicatorColorValue,
    };
    // console.log('%c Porta headerTypographySettings: %O', 'color: maroon;', headerTypographySettings);

    let sxPortalHeader: any = {};
    // sxPortalHeader.backgroundColor = 'rgb(215 215 215 / 90%)';
    // sxPortalHeader.color = 'unset';

    let headerBackgroundColor: any = (headerSettings && headerSettings.backgroundColor ? headerSettings.backgroundColor : '');
    let headerBorderBottomColor: any = (headerSettings && headerSettings.borderBottomColor ? headerSettings.borderBottomColor : '');
    // console.log('%c Porta headerBackgroundColor: %O', 'color: maroon;', headerBackgroundColor);
    // console.log('%c Porta headerBorderBottomColor: %O', 'color: maroon;', headerBorderBottomColor);
    if (headerBackgroundColor) {
        let bgColor: string = getColorValue(headerBackgroundColor);
        sxPortalHeader.backgroundColor = bgColor;
    }
    if (headerBorderBottomColor) {
        let borderBottomColor: string = getColorValue(headerBorderBottomColor);
        sxPortalHeader.borderBottom = '1px solid ' + borderBottomColor;
    }

    let paperDefaultProps: any = {
        sx: {
            color: 'unset',
            backgroundColor: sxPortalHeader.backgroundColor
        }
    };
    let dialogDefaultProps: any = {
        PaperProps: { sx: {backgroundColor: sxPortalHeader.backgroundColor} },
    };
    let drawerDefaultProps: any = {
        PaperProps: { sx: {backgroundColor: sxPortalHeader.backgroundColor} },
    };
    let accordionDefaultProps: any = {
        sx: { backgroundColor: sxPortalHeader.backgroundColor },
    };
    let tabDefaultProps: any = {
        sx: { backgroundColor: sxPortalHeader.backgroundColor },
    };
    let checkboxDefaultProps: any = {
        inputProps: {
            style: { color: buttonSecondaryColorValue },
        },
        color: 'secondary'
    };
    let inputBaseDefaultProps: any = {
        inputProps: {
            sx: { color: buttonSecondaryColorValue },
        },
    };
    let selectDefaultProps: any = {
        inputProps: {
            sx: { color: buttonSecondaryColorValue },
        },
        // color: 'secondary'
    };
    let selectStyleOverrides: any = {
        select: { color: (buttonSecondaryColor && buttonSecondaryColor.main ? buttonSecondaryColor.main : buttonSecondaryColor) }
    };
    let iconDefaultProps: any = {
        // color: 'secondary',
        fontSize: 'small'
    };
    // let iconStyleProps: any = {
    //     root: { color: 'red' },
    //     colorPrimary: 'red',
    //     colorSecondary: 'yellow',
    // };
    let iconButtonDefaultProps: any = {
        // color: 'secondary',
        size: 'small',
        // sx: { padding: '0px' },
    };
    let iconButtonStyleProps: any = {
        root: { sx: { padding: '0px' } },
    };
    let paletteBackgroundDefault: string = '#fff';
    let paletteBackgroundPaper: string = '#fff';
    if (portaPaper) {
        if (portaPaper.backgroundColor) {
            // console.log('%c Porta portaPaper: %O', 'color: maroon;', portaPaper);
            let bgColor: string = udicciHelpers.convertColorToString(portaPaper.backgroundColor, '#fff');
            // console.log('%c Porta bgColor: %O', 'color: maroon;', bgColor);
            paperDefaultProps.sx.backgroundColor = bgColor;
            dialogDefaultProps.PaperProps.sx.backgroundColor = bgColor;
            drawerDefaultProps.PaperProps.sx.backgroundColor = bgColor;
            accordionDefaultProps.sx.backgroundColor = bgColor;
            tabDefaultProps.sx.backgroundColor = bgColor;
            paletteBackgroundDefault = bgColor;
            paletteBackgroundPaper = bgColor;
        }
        if (portaPaper.borderColor) {
            let borderColor: string = udicciHelpers.convertColorToString(portaPaper.borderColor, '#fff');
            // console.log('%c Porta borderColor: %O', 'color: maroon;', borderColor);
            paperDefaultProps.sx.borderColor = borderColor;
            dialogDefaultProps.PaperProps.sx.borderColor = borderColor;
            drawerDefaultProps.PaperProps.sx.borderColor = borderColor;
            accordionDefaultProps.sx.borderColor = borderColor;
            tabDefaultProps.sx.borderColor = borderColor;
        }
        if (portaPaper.borderStyle) {
            paperDefaultProps.sx.borderStyle = portaPaper.borderStyle;
            dialogDefaultProps.PaperProps.sx.borderStyle = portaPaper.borderStyle;
            drawerDefaultProps.PaperProps.sx.borderStyle = portaPaper.borderStyle;
            accordionDefaultProps.sx.borderStyle = portaPaper.borderStyle;
            tabDefaultProps.sx.borderStyle = portaPaper.borderStyle;
        }
        if (portaPaper.borderRadius) {
            paperDefaultProps.sx.borderRadius = portaPaper.borderRadius;
            dialogDefaultProps.PaperProps.sx.borderRadius = portaPaper.borderRadius;
            drawerDefaultProps.PaperProps.sx.borderRadius = portaPaper.borderRadius;
            accordionDefaultProps.sx.borderRadius = portaPaper.borderRadius;
            tabDefaultProps.sx.borderRadius = portaPaper.borderRadius;
        }
        if (portaPaper.borderWidth) {
            paperDefaultProps.sx.borderWidth = portaPaper.borderWidth;
            dialogDefaultProps.PaperProps.sx.borderWidth = portaPaper.borderWidth;
            drawerDefaultProps.PaperProps.sx.borderWidth = portaPaper.borderWidth;
            accordionDefaultProps.sx.borderWidth = portaPaper.borderWidth;
            tabDefaultProps.sx.borderWidth = portaPaper.borderWidth;
        }
        if (portaPaper.margin) {
            paperDefaultProps.sx.margin = portaPaper.margin;
            dialogDefaultProps.PaperProps.sx.margin = portaPaper.margin;
            drawerDefaultProps.PaperProps.sx.margin = portaPaper.margin;
            accordionDefaultProps.sx.margin = portaPaper.margin;
            tabDefaultProps.sx.margin = portaPaper.margin;
        }
        if (portaPaper.padding) {
            paperDefaultProps.sx.padding = portaPaper.padding;
            dialogDefaultProps.PaperProps.sx.padding = portaPaper.padding;
            drawerDefaultProps.PaperProps.sx.padding = portaPaper.padding;
            accordionDefaultProps.sx.padding = portaPaper.padding;
            tabDefaultProps.sx.padding = portaPaper.padding;
        }
    }

    if (paletteBackgroundDefault) {
        if (!portaThemePaletteSettings.background) portaThemePaletteSettings.background = {};
        portaThemePaletteSettings.background.default = paletteBackgroundDefault;
        portaThemePaletteSettings.background.paper = paletteBackgroundPaper;
    }

    if (!paperDefaultProps) paperDefaultProps = {};
    if (!paperDefaultProps.sx) paperDefaultProps.sx = {};
    Object.assign(paperDefaultProps.sx, linkSettings);
    // console.log('%c Porta paperDefaultProps: %O', 'color: maroon;', paperDefaultProps);
    // console.log('%c Porta dialogDefaultProps: %O', 'color: maroon;', dialogDefaultProps);
    // console.log('%c Porta drawerDefaultProps: %O', 'color: maroon;', drawerDefaultProps);
    // console.log('%c Porta accordionDefaultProps: %O', 'color: maroon;', accordionDefaultProps);
    // console.log('%c Porta tabDefaultProps: %O', 'color: maroon;', tabDefaultProps);
    // console.log('%c Porta selectStyleOverrides: %O', 'color: maroon;', selectStyleOverrides);
    let themeComponents: any = {
        MuiPaper: {
            defaultProps: paperDefaultProps,
        },
        MuiDialog: {
            defaultProps: dialogDefaultProps,
        },
        MuiDrawer: {
            defaultProps: drawerDefaultProps,
        },
        MuiInputBase: {
            defaultProps: inputBaseDefaultProps
        },
        MuiCheckbox: {
            defaultProps: checkboxDefaultProps,
        },
        MuiAccordion: {
            defaultProps: accordionDefaultProps,
        },
        MuiTab: {
            defaultProps: tabDefaultProps,
        },
        MuiGrid: {
            defaultProps: { sx: { backgroundColor: 'unset' } },
        },
        MuiDatePicker: {
            styleOverrides: {
                root: {
                    backgroundColor: accordionDefaultProps.sx.backgroundColor,
                },
            },
        },
        MuiSelect: {
            styleOverrides: selectStyleOverrides,
            defaultProps: selectDefaultProps,
        },
        MuiMenu: {
            styleOverrides: selectStyleOverrides,
            defaultProps: selectDefaultProps,
        },
        MuiMenuItem: {
            styleOverrides: selectStyleOverrides,
            defaultProps: selectDefaultProps,
        },
        MuiIconButton: {
            styleOverrides: iconButtonStyleProps,
            defaultProps: iconButtonDefaultProps,
        },
        MuiIcon: {
            // styleOverrides: iconStyleProps,
            defaultProps: iconDefaultProps,
        },
    };

    let headerThemeComponents: any = {
        MuiAppBar: {
            styleOverrides: {
                root: sxPortalHeader
            }
        },
    };
    // Object.assign(headerThemeComponents, themeComponents);

    const headerTheme = createTheme({
        palette: headerPaletteSettings,
        typography: headerTypographySettings,
        components: headerThemeComponents
    });
    // console.log('%c headerTheme: %O', 'color: blue; font-weight: bold;', headerTheme);

    const portaTheme = createTheme({
        palette: portaThemePaletteSettings,
        typography: typographySettings,
        components: themeComponents
    });
    // console.log('%c portaTheme: %O', 'color: blue; font-weight: bold;', portaTheme);

    let portalDesignHeaderElement: any = null;
    let portaConfigHeaderElement: any = null;
    let sxPortaContainer: any = null;
    let portaDisplayElement: any = null;
    let portaAIElement: any = null;
    if (displayTerminal) {
        portaDisplayElement = ( <UdicciConsole /> );
    } else if (showPortalAgreements) {
        portaDisplayElement = (
            <Box>
                <RelationshipsList />
            </Box>
        );
    } else if (displayMe) {
        if (profileSettings && profileSettings.SplashImage) {
            displayingSplash = true;
            let splashContainerProps: any = {
                display: 'flow-root',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '-35px', // adjust up for missing header
                // maxWidth: '-webkit-fill-available'
            };
            portaDisplayElement = (
                <Box sx={splashContainerProps}>
                    <img src={profileSettings.SplashImage}
                            alt={(profile && profile.DisplayName ? profile.DisplayName : "Udicci")}
                            style={{ maxWidth: '-webkit-fill-available' }}
                    />
                    <Box sx={{ display: 'flow-root', width: '100%', marginLeft: 'auto', marginRight: 'auto', marginTop: '8px' }}>
                        <LinearProgress color="primary" variant="query" />
                    </Box>
                </Box>
            );
        } else {
            portaDisplayElement = ( <Me {...displaySettings} onNavigation={mainMenuClicked} /> );
        }
    } else if (portaDisplay === 'focusBoard' && dm !== 'on') {
        if (dm === 'on') {
            portaAIElement = (
                <PortaAIAdminContainer>
                    <Box sx={{ margin: '8px', padding: '8px', textAlign: 'center' }}>
                        <Typography variant="caption">
                            No admin capabilities for the Focus Board inteface at this time.  It is all configured within the component itself.
                        </Typography>
                    </Box>
                </PortaAIAdminContainer>
            );
        } else {
            portaAIElement = (
                <PortaAIContainer> <FocusBoardFullScreen {...displaySettings} onNavigation={mainMenuClicked} /> </PortaAIContainer>
            );
        }
    } else if (selectedPorta && portaPanes) {
        if (!hasAccess && dm !== 'on') {
            let paneOuterContainerStyleOverride: any = {};
            paneOuterContainerStyleOverride.margin = '8px';
            paneOuterContainerStyleOverride.padding = '8px';

            let additionalDetails: any = null;
            if (udicci.currentUserRole) {
                additionalDetails = (
                    <Box>
                        <Typography variant="caption" component="span">
                            Your Assigned Role ({udicci.currentUserRole.RoleName}) has not been given Access Rights to this Porta.
                        </Typography>
                    </Box>
                );
            } else {
                // additionalDetails = ();
            }
            portaDisplayElement = (
                <Grid container spacing={gs} justifyContent={gridJustifyContent} alignItems={gridAlignItems}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Paper elevation={7} sx={{ backgroundColor: 'unset' }}>
                            <Box>
                                <PortaPaneContainer style={paneOuterContainerStyleOverride}>
                                    <Box>
                                        <Typography variant="errorMessage" component="span">
                                            You do not have access to view the content on this Porta.
                                        </Typography>
                                        &nbsp;&nbsp;
                                        <Typography variant="body2" component="span">
                                            {(selectedPorta && selectedPorta.Name ? '(' + selectedPorta.Name + ')' : '')}
                                        </Typography>
                                    </Box>
                                    {additionalDetails}
                                </PortaPaneContainer>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            );
        } else {
            let paneElements: any[] = [];
            let paneGridElements: any[] = [];
            // console.log('%c portaPanes: %O', 'color: red; font-weight: bold;', portaPanes);
            let disableDisplayPanes: boolean = (false && dm !== 'on');
            // console.log('%c disableDisplayPanes: %O', 'color: red; font-weight: bold;', disableDisplayPanes);
            if (!disableDisplayPanes) {
                forEach(portaPanes, (pane: any, idxPane: number) => {
                    // console.log('%c pane: %O', 'color: red; font-weight: bold;', pane);
                    // console.log('%c idxPane: %O', 'color: red; font-weight: bold;', idxPane);

                    let contentDesign: any = (pane && pane.contentDesign ? pane.contentDesign : null);
                    // console.log('%c Porta contentDesign: %O', 'color: maroon;', contentDesign);
                    let content: any = (pane && pane.content ? pane.content : null);
                    // console.log('%c Porta content: %O', 'color: maroon;', content);
                    if (dm !== 'on' && (!contentDesign && !content)) return true;

                    let paneAccess: any = (pane && pane.access ? pane.access : null);
                    // console.log('%c Porta paneAccess: %O', 'color: maroon;', paneAccess);
                    let paneAccessRoles: any = (paneAccess && paneAccess.roles ? paneAccess.roles : null);
                    // console.log('%c Porta paneAccessRoles: %O', 'color: maroon;', paneAccessRoles);
                    if (dm !== 'on' && paneAccessRoles) {
                        if (udicciUserRoleId > 0 && paneAccessRoles[udicciUserRoleId] !== true) return;
                        if (udicciUserRoleId <= 0 && paneAccessRoles[publicRoleId] !== true) return;
                    }
                    // console.log('%c Porta paneAccessRoles[%s]: %O', 'color: maroon;', udicciUserRoleId, (paneAccessRoles && paneAccessRoles[udicciUserRoleId] ? paneAccessRoles[udicciUserRoleId] : 'not found'));

                    let paneName = (pane.name ? pane.name : pane.displayComponent);

                    let pgSettings: any = (pane && pane.grid ? pane.grid : null);
                    let pgxs: number = (pgSettings && pgSettings.xs !== undefined && pgSettings.xs !== null ? parseInt(pgSettings.xs, 10) : 12);
                    let pgsm: number = (pgSettings && pgSettings.sm !== undefined && pgSettings.sm !== null ? parseInt(pgSettings.sm, 10) : 12);
                    let pgmd: number = (pgSettings && pgSettings.md !== undefined && pgSettings.md !== null ? parseInt(pgSettings.md, 10) : 12);
                    let pglg: number = (pgSettings && pgSettings.lg !== undefined && pgSettings.lg !== null ? parseInt(pgSettings.lg, 10) : 12);
                    let pgxl: number = (pgSettings && pgSettings.xl !== undefined && pgSettings.xl !== null ? parseInt(pgSettings.xl, 10) : 12);

                    let paneDesignSettings: any = (pane && pane.design ? pane.design : null);
                    // console.log('%c Porta paneDesignSettings: %O', 'color: maroon;', paneDesignSettings);
                    let paneOuter: any = (paneDesignSettings && paneDesignSettings.outer ? paneDesignSettings.outer : portaPaneOuter);
                    // console.log('%c Porta paneOuter: %O', 'color: maroon;', paneOuter);

                    let paneOuterBackground: any = (paneOuter && paneOuter.backgroundColor ? paneOuter.backgroundColor : null);
                    let paneOuterBorderColor: any = (paneOuter && paneOuter.borderColor ? paneOuter.borderColor : null);
                    let paneOuterBorderStyle: any = (paneOuter && paneOuter.borderStyle ? paneOuter.borderStyle : null);
                    let paneOuterBorderRadius: any = (paneOuter && paneOuter.borderRadius ? paneOuter.borderRadius : null);
                    let paneOuterBorderWidth: any = (paneOuter && paneOuter.borderWidth !== undefined ? paneOuter.borderWidth : 1);
                    let paneOuterMargin: any = (paneOuter && paneOuter.margin !== undefined ? paneOuter.margin : 1);
                    let paneOuterPadding: any = (paneOuter && paneOuter.padding !== undefined ? paneOuter.padding : 1);

                    // let panePaper: any = (paneDesignSettings && paneDesignSettings.paper ? paneDesignSettings.paper : portaPaper);
                    // // console.log('%c Porta panePaper: %O', 'color: maroon;', panePaper);

                    // let panePaperBackground: any = (panePaper && panePaper.backgroundColor ? panePaper.backgroundColor : null);
                    // let panePaperBorderColor: any = (panePaper && panePaper.borderColor ? panePaper.borderColor : null);
                    // let panePaperBorderStyle: any = (panePaper && panePaper.borderStyle ? panePaper.borderStyle : null);
                    // let panePaperBorderRadius: any = (panePaper && panePaper.borderRadius ? panePaper.borderRadius : null);
                    // let panePaperBorderWidth: any = (panePaper && panePaper.borderWidth !== undefined ? panePaper.borderWidth : 1);
                    // let panePaperMargin: any = (panePaper && panePaper.margin !== undefined ? panePaper.margin : 1);
                    // let panePaperPadding: any = (panePaper && panePaper.padding !== undefined ? panePaper.padding : 1);

                    let paneOuterContainerStyleOverride: any = {};
                    if (paneOuterBackground || paneOuterBorderColor || paneOuterBorderStyle || paneOuterBorderRadius) {
                        if (paneOuterBackground) {
                            let bgColor: string = udicciHelpers.convertColorToString(paneOuterBackground, '#fff');
                            paneOuterContainerStyleOverride.backgroundColor = bgColor;
                        }
                        if (paneOuterBorderColor) {
                            let borderColor: string = udicciHelpers.convertColorToString(paneOuterBorderColor, '#fff');
                            paneOuterContainerStyleOverride.borderColor = borderColor;
                        }
                        if (paneOuterBorderStyle) {
                            paneOuterContainerStyleOverride.borderStyle = paneOuterBorderStyle;
                        }
                        if (paneOuterBorderRadius) {
                            paneOuterContainerStyleOverride.borderRadius = paneOuterBorderRadius;
                        }
                    }
                    paneOuterContainerStyleOverride.borderWidth = paneOuterBorderWidth + 'px';
                    paneOuterContainerStyleOverride.margin = paneOuterMargin + 'px';
                    paneOuterContainerStyleOverride.padding = paneOuterPadding + 'px';
                    // console.log('%c Porta paneOuterContainerStyleOverride: %O', 'color: maroon;', paneOuterContainerStyleOverride);

                    let paneKey: string = 'porta.pane.' + idxPane + '.' + paneName.replace(' ', '.');
                    let paneConfigSettings: any = {
                        pane: pane,
                        paneIdx: idxPane,
                        selectedPaneIdx: configurePaneIndex,
                        key: paneKey,
                        configurePane: (selectPaneIndex: number) => { setConfigurePane(selectPaneIndex); if(selectPaneIndex >= 0) toggleConfigurePorta(false);  },
                        showPortalConfig: showPortalConfig,
                        configurePorta: configurePorta,
                        configureSolutions: configureSolutions,
                        onCopyPane: () => setHasPaneToPaste(true),
                    };
                    let portaPaneElement: any = ( <PortaPane {...paneConfigSettings} /> );
                    // console.log('%c Porta portaPaneElement: %O', 'color: maroon;', portaPaneElement);
                    let paneContentElement: any = null;
                    if (pane.removeContainers) {
                        paneContentElement = portaPaneElement;
                        paneElements.push(paneContentElement);
                    } else {
                        if (configurePaneIndex >= 0 || configurePorta || configureSolutions) {
                            pgxs = 12;
                            pgsm = 12;
                            pgmd = 12;
                            pglg = 12;
                            pgxl = 12;
                        }
                        paneContentElement = (
                            <Grid item xs={pgxs} sm={pgsm} md={pgmd} lg={pglg} xl={pgxl} key={paneKey}>
                                <PortaPaneContainer style={paneOuterContainerStyleOverride}>
                                    {portaPaneElement}
                                </PortaPaneContainer>
                            </Grid>
                        );
                        paneGridElements.push(paneContentElement);
                    }
                });
            }

            if (dm === 'on') {
                let configurePortaIconButton: any = (
                    <ThemeProvider theme={rightAlignedButtonTheme}>
                        <IconButton color="info" aria-label="Configure Porta" size="small" onClick={(evt: any) => { toggleConfigurePorta(!configurePorta); setConfigurePane(-1); }}>
                            {(configurePorta ? (<Icon>close</Icon>) : (<Icon>settings</Icon>))}
                        </IconButton>
                    </ThemeProvider>
                );

                let savePortaIconButton: any = (
                    <ThemeProvider theme={rightAlignedButtonTheme}>
                        <IconButton aria-label="Save Porta"
                                    size="small"
                                    color={(selectedPorta && selectedPorta.IsDirty === true ? 'primary' : 'info')}
                                    disabled={(selectedPorta && selectedPorta.IsDirty === true ? false : true)}
                                    onClick={(evt: any) => savePorta()}
                        >
                            <SaveIcon />
                        </IconButton>
                    </ThemeProvider>
                );

                let addPaneIconButton: any = (
                    <ThemeProvider theme={rightAlignedButtonTheme}>
                        <IconButton color="primary" aria-label="Add Pane" size="small" onClick={(evt: any) => addPane()}>
                            <AddPaneIcon />
                        </IconButton>
                    </ThemeProvider>
                );

                let pastePaneIconButton: any = null;
                if (hasPaneToPaste && paneToPaste) {
                    pastePaneIconButton = (
                        <ThemeProvider theme={rightAlignedButtonTheme}>
                            <IconButton sx={{ color: 'green' }} edge="end" aria-label="Paste Pane" size="small" onClick={(evt: any) => pastePane()} title={'Paste Pane: ' + paneToPaste.name}>
                                <Icon>content_paste_go</Icon>
                            </IconButton>
                        </ThemeProvider>
                    );
                }

                if (configureSolutions && udicci.isCurrentUserUlysses()) {
                    portaConfigHeaderElement = (
                        <Box sx={{ paddingTop: '5px', display: 'flow-root' }}>
                            <Typography variant="caption" component="span" sx={{ marginLeft: '4px' }}>
                                Configure Social Solutions
                            </Typography>
                        </Box>
                    );
                } else {
                    let aiDisplayMessage: any = null;
                    if (portaDisplay === 'focusBoard') {
                        aiDisplayMessage = (
                            <Box sx={{ margin: '0px', padding: '8px', textAlign: 'center' }}>
                                <Typography variant="subtitle2">
                                    This Porta is setup to display a Focus Board with Artificial Intelligence.
                                </Typography>
                            </Box>
                        );
                    }
                    portaConfigHeaderElement = (
                        <Box sx={{ paddingTop: '5px', display: 'flow-root' }}>
                            <Typography variant="caption" component="span" sx={{ marginLeft: '4px' }}>
                                Configure Porta
                            </Typography>
                            &nbsp;
                            <Typography variant="subtitle1" component="span" sx={{ marginLeft: '4px' }}>
                                {portaName}
                            </Typography>
                            {addPaneIconButton}
                            {configurePortaIconButton}
                            {savePortaIconButton}
                            {pastePaneIconButton}
                            {aiDisplayMessage}
                        </Box>
                    );
                }

                let portaNameElement: any = null;
                if (configurePorta) {
                    let directionSelections = [];
                    directionSelections.push(<MenuItem key={'select.direction'} value={''}> Select Direction </MenuItem>);
                    directionSelections.push(<MenuItem key={'direction.row'} value={'row'}> row </MenuItem>);
                    directionSelections.push(<MenuItem key={'direction.row.reverse'} value={'row-reverse'}> row-reverse </MenuItem>);
                    directionSelections.push(<MenuItem key={'direction.column'} value={'column'}> column </MenuItem>);
                    directionSelections.push(<MenuItem key={'direction.column.reverse'} value={'column-reverse'}> column-reverse </MenuItem>);

                    let justifyContentSelections = [];
                    justifyContentSelections.push(<MenuItem key={'select.justify.content'} value={''}> Select Justify Content </MenuItem>);
                    justifyContentSelections.push(<MenuItem key={'justify.content.flex.start'} value={'flex-start'}> flex-start </MenuItem>);
                    justifyContentSelections.push(<MenuItem key={'justify.content.center'} value={'center'}> center </MenuItem>);
                    justifyContentSelections.push(<MenuItem key={'justify.content.flex.end'} value={'flex-end'}> flex-end </MenuItem>);
                    justifyContentSelections.push(<MenuItem key={'justify.content.space.between'} value={'space-between'}> space-between </MenuItem>);
                    justifyContentSelections.push(<MenuItem key={'justify.content.space.around'} value={'space-around'}> space-around </MenuItem>);
                    justifyContentSelections.push(<MenuItem key={'justify.content.space.evenly'} value={'space-evenly'}> space-evenly </MenuItem>);

                    let alignItemsSelections = [];
                    alignItemsSelections.push(<MenuItem key={'select.align.items'} value={''}> Select Align Items </MenuItem>);
                    alignItemsSelections.push(<MenuItem key={'align.items.flex.start'} value={'flex-start'}> flex-start </MenuItem>);
                    alignItemsSelections.push(<MenuItem key={'align.items.center'} value={'center'}> center </MenuItem>);
                    alignItemsSelections.push(<MenuItem key={'align.items.flex.end'} value={'flex-end'}> flex-end </MenuItem>);
                    alignItemsSelections.push(<MenuItem key={'align.items.stretch'} value={'stretch'}> stretch </MenuItem>);
                    alignItemsSelections.push(<MenuItem key={'align.items.baseline'} value={'baseline'}> baseline </MenuItem>);

                    let spacingSelections = [];
                    spacingSelections.push(<MenuItem key={'spacing.0'} value={'0'}> 0 </MenuItem>);
                    spacingSelections.push(<MenuItem key={'spacing.0.5'} value={'0.5'}> 0.5 </MenuItem>);
                    spacingSelections.push(<MenuItem key={'spacing.1'} value={'1'}> 1 </MenuItem>);
                    spacingSelections.push(<MenuItem key={'spacing.2'} value={'2'}> 2 </MenuItem>);
                    spacingSelections.push(<MenuItem key={'spacing.3'} value={'3'}> 3 </MenuItem>);
                    spacingSelections.push(<MenuItem key={'spacing.4'} value={'4'}> 4 </MenuItem>);

                    let portaStatusSelections = [];
                    portaStatusSelections.push(<MenuItem key={'status.select'} value={''}> Select Status </MenuItem>);
                    portaStatusSelections.push(<MenuItem key={'status.production'} value={'Production'}> Production </MenuItem>);
                    portaStatusSelections.push(<MenuItem key={'status.review'} value={'Review'}> Review </MenuItem>);
                    portaStatusSelections.push(<MenuItem key={'status.design'} value={'Design'}> Design </MenuItem>);
                    portaStatusSelections.push(<MenuItem key={'status.archive'} value={'Archive'}> Archive </MenuItem>);

                    let portaDisplaySelections = [];
                    portaDisplaySelections.push(<MenuItem key={'display.select'} value={''}> Select Status </MenuItem>);
                    portaDisplaySelections.push(<MenuItem key={'display.plugins'} value={'plugins'}> Udicci Plugins </MenuItem>);
                    portaDisplaySelections.push(<MenuItem key={'display.focusBoard'} value={'focusBoard'}> Focus Board with AI </MenuItem>);

                    let defaultPortaElement: any = null;
                    if (defaultPorta) {
                        defaultPortaElement = (<Typography variant="body2" sx={{ margin: '8px', textAlign: 'center' }}>This is the Default Porta.</Typography>);
                    } else {
                        defaultPortaElement = (
                            <Button variant="contained" color="secondary" disabled={makingDefaultPorta} onClick={makePortaDefaultForPortal}>
                                Make this Porta the Default
                            </Button>
                        );
                    }

                    let landingPortaElement: any = null;
                    if (landingPorta) {
                        landingPortaElement = (<Typography variant="body2" sx={{ margin: '8px', textAlign: 'center' }}>This is currently the Landing Porta.</Typography>);
                    } else {
                        landingPortaElement = (
                            <Button variant="contained" color="secondary" disabled={makingLandingPorta} onClick={makePortaLandingForPortal}>
                                Make this the Landing Porta
                            </Button>
                        );
                    }

                    let duplicatePortaElement: any = null;
                    if (selectedPorta && selectedPorta) {
                        duplicatePortaElement = (
                            <Button variant="contained" color="info" onClick={duplicatePorta}>
                                Create a New Porta from a Copy of this Porta
                            </Button>
                        );
                    }

                    let iconSaveButton = (
                        <IconButton sx={{ float: 'right' }}
                                    aria-label="Apply Porta Url"
                                    size="small"
                                    color="primary"
                                    disabled={(workingPortaUrl !== portaUrl ? true : false)}
                                    onClick={(evt: any) => applyPortaUrl()}
                        >
                            <SaveIcon />
                        </IconButton>
                    );

                    let portaDetailsFormElement: any = (
                        <div>
                            <div style={{ padding: '8px', float: 'right' }}>
                                <ButtonGroup variant="contained" orientation="vertical">
                                    {duplicatePortaElement}
                                    {defaultPortaElement}
                                    {landingPortaElement}
                                </ButtonGroup>
                            </div>
                            <div style={{ padding: '8px' }}>
                                <FormControl>
                                    <Typography variant="caption" component="div">Name</Typography>
                                    <TextField id="porta.Name"
                                            type={'text'}
                                            name='Name'
                                            value={(portaName ? portaName : '')}
                                            onChange={updatePortaDetails} />
                                </FormControl>
                            </div>
                            <div style={{ padding: '8px' }}>
                                <FormControl>
                                    <Typography variant="caption" component="div">Porta Url</Typography>
                                    <TextField id="porta.settings.portaUrl"
                                            type={'text'}
                                            name='portaUrl'
                                            InputProps={{
                                                    startAdornment: <InputAdornment position="start">#</InputAdornment>,
                                                    endAdornment: <InputAdornment position="end">{iconSaveButton}</InputAdornment>
                                            }}
                                            value={(portaUrl ? portaUrl : '')}
                                            onChange={(evt: any) => updatePortaSetting(evt, 'portaUrl', '')}
                                    />
                                    <FormHelperText>
                                        You must save the Url to apply it to the Porta, then save the Porta.
                                    </FormHelperText>
                                    <FormHelperText>
                                        After saving the Porta, a page refresh is required.
                                    </FormHelperText>
                                </FormControl>
                            </div>
                            <div style={{ padding: '8px' }}>
                                <FormControl fullWidth>
                                    <Typography variant="caption" component="div">Purpose</Typography>
                                    <TextField id="porta.Purpose"
                                            type={'text'} multiline={true} minRows={3} maxRows={15}
                                            name='Purpose'
                                            value={(selectedPorta !== null ? selectedPorta.Purpose : '')}
                                            onChange={updatePortaDetails}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    );

                    let portaMenuFormElement: any = (
                        <div>
                            <div style={{ padding: '8px' }}>
                                <FormControl>
                                    <InputLabel htmlFor="porta.menu.label">
                                        <Typography variant="caption">Menu Label</Typography>
                                    </InputLabel>
                                    <Input id="porta.menu.label"
                                        type={'text'}
                                        name='menu.label'
                                        value={(portaMenuLabel ? portaMenuLabel : '')}
                                        onChange={(evt: any) => updatePortaSetting(evt, 'menu', 'label')} />
                                </FormControl>
                            </div>
                            <div style={{ padding: '8px' }}>
                                <Box sx={{ width: 300 }}>
                                    <Typography variant="caption">Menu Priority</Typography>
                                    <Slider aria-label="Menu Priority"
                                            defaultValue={1}
                                            value={portaMenuPriority}
                                            onChange={(evt: Event, newValue: number | number[]) => updatePortaSetting(evt, 'menu', 'priority', newValue)}
                                            step={1}
                                            marks
                                            min={1}
                                            max={7}
                                            valueLabelDisplay="auto"
                                    />
                                </Box>
                            </div>
                        </div>
                    );

                    let portaDisplayFormElement: any = (
                        <div>
                            {/* <div style={{ padding: '8px' }}>
                                <div>
                                    <InputLabel>
                                        <Typography variant="caption">Grid Display Direction</Typography>
                                    </InputLabel>
                                    <Select value={gridDirection} onChange={(evt: any) => updatePortaSetting(evt, 'grid', 'direction')}>
                                        {directionSelections}
                                    </Select>
                            </div>
                            </div> */}
                            <div style={{ display: 'flex' }}>
                                <div style={{ padding: '8px', float: 'left' }}>
                                    <InputLabel>
                                        <Typography variant="caption">Grid Justify Content</Typography>
                                    </InputLabel>
                                    <Select value={gridJustifyContent} onChange={(evt: any) => updatePortaSetting(evt, 'grid', 'justifyContent')}>
                                        {justifyContentSelections}
                                    </Select>
                                </div>
                                <div style={{ padding: '8px', float: 'left' }}>
                                    <InputLabel>
                                        <Typography variant="caption">Grid Align Items</Typography>
                                    </InputLabel>
                                    <Select value={gridAlignItems} onChange={(evt: any) => updatePortaSetting(evt, 'grid', 'alignItems')}>
                                        {alignItemsSelections}
                                    </Select>
                                </div>
                                <div style={{ padding: '8px', float: 'left' }}>
                                    <InputLabel>
                                        <Typography variant="caption">Grid Spacing</Typography>
                                    </InputLabel>
                                    <Select value={gridSpacing} onChange={(evt: any) => updatePortaSetting(evt, 'grid', 'spacing')}>
                                        {spacingSelections}
                                    </Select>
                            </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ padding: '8px', float: 'left' }}>
                                    <InputLabel>
                                        <Typography variant="caption">Porta Status</Typography>
                                    </InputLabel>
                                    <Select value={portaStatus} onChange={(evt: any) => updatePortaSetting(evt, 'status')}>
                                        {portaStatusSelections}
                                    </Select>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ padding: '8px', float: 'left' }}>
                                    <InputLabel>
                                        <Typography variant="caption">Porta Display</Typography>
                                    </InputLabel>
                                    <Select value={portaDisplay} disabled={defaultPorta} onChange={(evt: any) => updatePortaSetting(evt, 'display')}>
                                        {portaDisplaySelections}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    );

                    let portaOverrideElement: any = null;
                    let makePortalDesignButtonElement: any = null;
                    let designButtonElement: any = null;
                    let designPortaPaneElement: any = null;
                    if (value === 4) {
                        if (portaOverridesDesign && portaDesignSettings) {
                            makePortalDesignButtonElement = (
                                <Button onClick={makePortaDesignPortalDesign} color="success">
                                    Make Porta Design the Overall Portal Design
                                </Button>
                            );
                            designButtonElement = (
                                <Button onClick={removePortaPaneDesignOverride} color="warning">
                                    Remove Porta Design Override
                                </Button>
                            );
                            designPortaPaneElement = (
                                <DesignSettingsAdmin currentSettings={portaDesignSettings} target="porta"
                                                     onChange={(update: any, subSettingName: string = '') => updatePortaSetting(update, 'design', subSettingName)} />
                            );
                            portaOverrideElement = (
                                <Box sx={{ display: 'flow-root', alignItems: 'center', margin: '8px' }}>
                                    <Typography variant="caption">
                                        Porta Design is currently overridden.
                                    </Typography>
                                    <Box sx={{ display: 'flow-root', width: 'fit-content', marginTop: '8px' }}>
                                        <ButtonGroup variant="contained" color="secondary">
                                            {makePortalDesignButtonElement}
                                            {designButtonElement}
                                        </ButtonGroup>
                                    </Box>
                                </Box>
                            );
                        } else {
                            designButtonElement = (
                                <Button onClick={overridePortaPaneDesign} color="secondary">
                                    Start Fresh
                                </Button>
                            );
                            if (portalDesignSetting) {
                                makePortalDesignButtonElement = (
                                    <Button onClick={overridePortaPaneDesignFromPortal} color="primary">
                                        Copy Portal Design
                                    </Button>
                                );
                            }

                            designPortaPaneElement = (
                                <DesignSettingsAdmin currentSettings={portalDesignSetting} target="portal"
                                                     onChange={(update: any, subSettingName: string = '') => updatePortalSetting(update, 'design', subSettingName)} />
                            );

                            portaOverrideElement = (
                                <Box sx={{ display: 'flow-root', alignItems: 'center', margin: '8px' }}>
                                    <Typography variant="body2" component="div">
                                        Override Porta Design
                                    </Typography>
                                    <Typography variant="caption" component="div">
                                        The primary Portal Design can be overridden for an individual Porta. 
                                        In addition, Pane design can be independently overridden for Container, Text and Button settings. 
                                    </Typography>
                                    <Typography variant="caption" component="div">
                                        Designing an override can begin from a copy of the Portal's design or from a fresh 
                                        design with minimal settings.
                                    </Typography>
                                    <Box sx={{ display: 'flow-root', width: 'fit-content', marginTop: '8px' }}>
                                        <ButtonGroup variant="contained" color="secondary">
                                            {makePortalDesignButtonElement}
                                            {designButtonElement}
                                        </ButtonGroup>
                                    </Box>
                                </Box>
                            );
                        }
                    }

                    portaNameElement = (
                        <Paper sx={{ width: '100%', ...paperDefaultProps.sx }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs color="secondary" value={(value >= 0 ? value : false)} onChange={handleChange} aria-label="Porta Configuration" variant="fullWidth" allowScrollButtonsMobile={true}>
                                    <Tab value={0} label={<Typography variant="inherit">Porta Details</Typography>} id="porta-config-tab-0" />
                                    <Tab value={1} label={<Typography variant="inherit">Menu</Typography>} id="porta-config-tab-1" />
                                    <Tab value={2} label={<Typography variant="inherit">Display</Typography>} id="porta-config-tab-2" />
                                    <Tab value={3} label={<Typography variant="inherit">Access</Typography>} id="porta-config-tab-3" />
                                    <Tab value={4} label={<Typography variant="inherit">Design</Typography>} id="porta-config-tab-4" />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                {portaDetailsFormElement}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {portaMenuFormElement}
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                {portaDisplayFormElement}
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <AccessSettingsAdmin currentSettings={accessSettings}
                                                     allowRoleMenuOverride={true}
                                                     onChangeAccessSetting={(update: any, subSettingName: string = '') => updatePortaSetting(update, 'access', subSettingName)}
                                                     onChangeMenuSetting={(update: any, settingName: string = 'menu', subSettingName: string = '') => updatePortaSetting(update, settingName, subSettingName)}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                {designPortaPaneElement}
                                {portaOverrideElement}
                            </TabPanel>
                        </Paper>
                    );

                    portalDesignHeaderElement = (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <PortaPaneContainer>
                                <PaneContainer>
                                    {portaNameElement}
                                </PaneContainer>
                            </PortaPaneContainer>
                        </Grid>
                    );
                } else if (configureSolutions) {
                    // console.log('%c Porta profile: %O', 'color: maroon;', profile);

                    portaNameElement = (
                        <Paper sx={{ display: 'flow-root', width: '100%', ...paperDefaultProps.sx }}>
                            <Box sx={{ borderColor: 'divider' }}>
                                <SocialSolutionManagement />
                            </Box>
                        </Paper>
                    );

                    portalDesignHeaderElement = (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <PortaPaneContainer>
                                <PaneContainer>
                                    {portaNameElement}
                                </PaneContainer>
                            </PortaPaneContainer>
                        </Grid>
                    );
                }
            }

            if (portaPanes.length <= 0 && paneElements.length <= 0 && paneGridElements.length <= 0 && !disableDisplayPanes) {
                let paneKey: string = 'porta.pane.default.me';
                let paneContent: any = (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={paneKey}>
                        <Me {...displaySettings} onNavigation={mainMenuClicked} />
                    </Grid>
                );
                paneGridElements.push(paneContent);
            }

            let portaGridContainerElement: any = null;
            if (paneGridElements.length > 0 || portalDesignHeaderElement) {
                portaGridContainerElement = (
                    <Grid container spacing={gs} justifyContent={gridJustifyContent} alignItems={gridAlignItems}>
                        {portalDesignHeaderElement}
                        {!configureSolutions && paneGridElements}
                    </Grid>
                );
            }

            portaDisplayElement = (
                <Fragment>
                    {portaGridContainerElement}
                    {paneElements}
                </Fragment>
            );
        }
    } else if (selectedPorta && !portaPanes) {
        //  direction={gridDirection}
        portaDisplayElement = (
            <Paper elevation={7} sx={paperSx}>
                {udicciLogoElement}
            </Paper>
        );
    } else {
        //  direction={gridDirection}
        portaDisplayElement = splashElement;
        displayingSplash = true;
    }

    let designModeHeader: any = null;
    if (!configureSolutions) {
        designModeHeader = (
            <Typography variant="body2" component="div" sx={{ margin: '8px', float: 'left' }}>
                Design Mode:
            </Typography>
        );
    }
    let portalDesignerHeader: any = null;
    if (dm === 'on' && selectedProfile && selectedPorta) {
        let configureSolutionsIconButton: any = null;
        if (udicci.isCurrentUserUlysses()) {
            configureSolutionsIconButton = (
                <Tooltip title={(configureSolutions ? 'Design Mode' : 'Social Solutions')}>
                    <IconButton color="secondary" sx={{ float: 'left' }} aria-label="Configure Social Solutions" size="small" onClick={(evt: any) => { toggleConfigureSolutions(!configureSolutions); }}>
                        {(configureSolutions ? (<Icon>view_quilt</Icon>) : (<Icon>design_services</Icon>))}
                    </IconButton>
                </Tooltip>
            );
        }

        portalDesignerHeader = (
            <ConfigurePortaHeaderContainer sx={{ position: 'sticky', display: 'flow-root', top: '38px', margin: '0px', padding: '8px', borderRadius: '0px', borderBottom: '2px dotted #4C6FB1', zIndex: 10 }}>
                {configureSolutionsIconButton}
                {designModeHeader}
                {portaConfigHeaderElement}
            </ConfigurePortaHeaderContainer>
        );
    }

    let portalHeaderElement: any = null;
    // console.log('%c displayingSplash: %O', 'color: blue; font-weight: bold;', displayingSplash);
    if (profileLoaded && !stopAtLoading && !stopAtLoadingProfile && !displayingSplash && (portaDisplay === 'plugins' || (portaDisplay === 'focusBoard' && dm === 'on'))) {
        portalHeaderElement = (
            <ThemeProvider theme={headerTheme}>
                <PortalHeader onChange={mainMenuClicked} />
            </ThemeProvider>
        );
    }

    let reportIssueElement: any = null;
    if (platformIssueReport) {
        let reportIssueHelperElement: any = null;
        let reportIssuePromptElement: any = null;
        reportIssueHelperElement = ( <FloatingHelperButton title="Report .IT Issue" onHelperClicked={(evt: any) => { udicci.clearPlatformIssueReport() }} /> );
        reportIssuePromptElement = ( <ReportIssuePromptAreaContent /> );
        reportIssueElement = (
            <Fragment>
                <ReportIssueOverlayContainer>
                    {reportIssuePromptElement}
                    {reportIssueHelperElement}
                </ReportIssueOverlayContainer>
            </Fragment>
        )
    }

    let portaContainerElement: any = null;
    if (portaAIElement) {
        if (dm === 'on') {
            portaContainerElement = (
                <PortaContainer open={true}>
                    {portaAIElement}
                </PortaContainer>
            );
        } else {
            portaContainerElement = (
                <Box> {portaAIElement} </Box>
            );
        }
    } else {
        portaContainerElement = (
            <PortaContainer open={true}>
                {portaDisplayElement}
                {loginDialogElement}
            </PortaContainer>
        );
    }

    return (
        <PageLayout {...displaySettings}>
            {portalHeaderElement}
            <ThemeProvider theme={portaTheme}>
                {portalDesignerHeader}
                {portaContainerElement}
                {reportIssueElement}
            </ThemeProvider>
        </PageLayout>
    );
}