import { useState, useContext } from 'react';

import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { UdicciContext, sendPreparedRequest } from 'src/context/udicci-context';
import { CreateNewProfileRequest } from 'src/interfaces/udicci-request-interfaces';

import { udicciStyles } from 'src/theme/shared-styles';

export const CreateProfileFormContent: React.FC<any> = ({
    record, engagementAction, onClose, engagementData, onSetEngagementData, 
    // showPotentialMatches, onTogglePotentialMatches, matchingProfiles
}) => {
    // console.log('%c CreateProfileFormContent props: %O', 'color: purple;', props);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const [actionIsProcessing, toggleActionIsProcessing] = useState(false);
    const [forcedUpdateState, forceUpdate] = useState<boolean>(false);

    const udicciContext = useContext(UdicciContext);

    let { udicci } = udicciContext.state;
    // console.log('%c Me data: %O', 'color: maroon;', data);
    // console.log('%c Me udicci: %O', 'color: maroon;', udicci);

    let { selectedProfile, currentUser } = udicci;
    // console.log('%c CreateProfileFormContent selectedProfile: %O', 'color: maroon;', selectedProfile);
    // console.log('%c CreateProfileFormContent currentUser: %O', 'color: maroon;', currentUser);

    const onValueChanged = (fieldName: string, path: any, evt: any) => {
        // console.log('%c fieldName: %O', 'color: purple;', fieldName);
        // console.log('%c path: %O', 'color: purple;', path);
        // console.log('%c action: %O', 'color: purple;', action);
        // console.log('%c engagementData: %O', 'color: purple;', engagementData);
        // console.log('%c onUpdateEngagementData: %O', 'color: purple;', onUpdateEngagementData);
        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);
        // console.log('%c newValue: %O', 'color: blue;', newValue);
        let createProfile: any = {};
        if (engagementData && engagementData.createProfile) {
            createProfile = engagementData.createProfile;
        }
        // console.log('%c createProfile: %O', 'color: blue;', createProfile);
        let profRec: any = {};
        let profileData: any = {};
        if (createProfile) {
            profRec = (createProfile.profileRecord ? createProfile.profileRecord : null);
            if (profRec && profRec.data) {
                profileData = profRec.data;
            }
        }
        // console.log('%c profRec: %O', 'color: blue;', profRec);
        // console.log('%c profileData: %O', 'color: blue;', profileData);
        if (path === 'data') {
            profileData[fieldName] = newValue;
            profRec.data = profileData;;
        } else {
            profRec[fieldName] = newValue;
        }
        // console.log('%c profRec: %O', 'color: blue;', profRec);
        profRec.isDirty = true;
        createProfile.profileRecord = profRec;

        engagementData.createProfile = createProfile;

        // let subFormProps: any = {};
        // Object.assign(subFormProps, props);
        // subFormProps.engagementData = engagementData;
        // engagementData.executeActionButton = (
        //     <ExecuteActionButton {...subFormProps} />
        // );
        // console.log('%c engagementData: %O', 'color: red;', engagementData);
        // this.setState({ engagementData: engagementData });
        if (onSetEngagementData)
            onSetEngagementData(engagementData);
        forceUpdate(!forcedUpdateState);
    };

    let createProfile = null;
    // console.log('%c engagementData: %O', 'color: blue;', engagementData);
    if (engagementData && engagementData.createProfile) {
        createProfile = engagementData.createProfile;
    }
    // console.log('%c createProfile: %O', 'color: blue;', createProfile);
    let IUnderstandChecked = false;
    let profileRecord: any = null;
    let profileData: any = {};
    if (createProfile) {
        profileRecord = (createProfile && createProfile.profileRecord ? createProfile.profileRecord : null);
        // console.log('%c profileRecord: %O', 'color: blue;', profileRecord);
        if (profileRecord && profileRecord.data) {
            profileData = profileRecord.data;
            IUnderstandChecked = (profileRecord && profileRecord.IUnderstand === true ? true : false);
        }
    }
    // console.log('%c profileData: %O', 'color: blue;', profileData);
    // console.log('%c IUnderstandChecked: %O', 'color: blue;', IUnderstandChecked);
    let { DisplayName, Headline, MissionStatement, ProfileUrl } = profileData;
    // console.log('DisplayName: %O', DisplayName);
    // console.log('Headline: %O', Headline);
    // console.log('MissionStatement: %O', MissionStatement);
    // console.log('ProfileUrl: %O', ProfileUrl);
    // console.log('SocialIcon: %O', SocialIcon);
    // *************************************************************************************
    // ********  Display Name  *************************************************************
    let displayNameElement = (
        <TextField fullWidth
            label={'Enter a title for this profile.'}
            sx={udicciClasses.textField}
            value={DisplayName}
            InputLabelProps={{ shrink: true }}
            onChange={(evt: any) => onValueChanged('DisplayName', 'data', evt)}
            margin="normal"
            variant='filled' />
    );
    // ********  Display Name  *************************************************************
    // *************************************************************************************
    // *************************************************************************************
    // ********  Headline  *****************************************************************
    let headlineNameElement = (
        <TextField fullWidth
            label={'What is the headline for this profile?'}
            sx={udicciClasses.textField}
            value={Headline}
            InputLabelProps={{ shrink: true }}
            onChange={(evt: any) => onValueChanged('Headline', 'data', evt)}
            margin="normal"
            variant='filled' />
    );
    // ********  Headline  *****************************************************************
    // *************************************************************************************
    // *************************************************************************************
    // ********  Mission Statement  ********************************************************
    let missionStatementNameElement = (
        <TextField fullWidth
            label={'What is the Mission Statement for this profile?'}
            sx={udicciClasses.textField}
            value={MissionStatement}
            InputLabelProps={{ shrink: true }}
            onChange={(evt: any) => onValueChanged('MissionStatement', 'data', evt)}
            margin="normal"
            variant='filled' />
    );
    // ********  Mission Statement  ********************************************************
    // *************************************************************************************
    // *************************************************************************************
    // ********  Profile Url  **************************************************************
    let profileUrlNameElement = (
        <TextField fullWidth
            label={'Choose a Url for this profile.'}
            sx={udicciClasses.textField}
            value={ProfileUrl}
            InputLabelProps={{ shrink: true }}
            onChange={(evt: any) => onValueChanged('ProfileUrl', 'data', evt)}
            margin="normal"
            variant='filled' />
    );
    // ********  Profile Url  **************************************************************
    // *************************************************************************************
    // *************************************************************************************
    // ********  Profile Type  *************************************************************
    // ********  Profile Type  *************************************************************
    // *************************************************************************************
    // *************************************************************************************
    // ********  Default Social Solutions  *************************************************
    // ********  Default Social Solutions  *************************************************
    // *************************************************************************************
    // *************************************************************************************
    // ********  Default Roles  ************************************************************
    // ********  Default Roles  ************************************************************
    // *************************************************************************************
    let IUnderstand = 'I understand I will be creating a Udicci Profile and ';
    IUnderstand += "I Assert that I have verified a matching profile was not found ";
    IUnderstand += "by the Udicci Platform.";

    let IUnderstandElement = (
        <FormControlLabel
            label={(
                <Typography variant="caption" color="secondary">
                    {IUnderstand}
                </Typography>
            )}
            control={<Checkbox checked={IUnderstandChecked}
                onChange={(evt: any) => onValueChanged('IUnderstand', '', evt)}
                color="primary" />} />
    );

    let submitActionButtonText = 'Create Profile';
    if (engagementAction.label) {
        let actionLabel = (engagementAction.label ? engagementAction.label : null);
        // console.log('%c actionLabel: %O', 'color: hotpink;', actionLabel);
        if (actionLabel && actionLabel.text) {
            submitActionButtonText = actionLabel.text;
        }
    } else if (engagementAction.name) {
        submitActionButtonText = engagementAction.name;
    }
    if (actionIsProcessing)
        submitActionButtonText += ' ...';

    const submitAction = (evt: any) => {
        // console.log('%c submitAction actionIsProcessing: %O', 'color: red;', actionIsProcessing);
        if (actionIsProcessing === true)
            return false;
        // console.log('%c submitAction engagementAction: %O', 'color: red;', engagementAction);
        // console.log('%c submitAction engagementData: %O', 'color: red;', engagementData);
        // console.log('%c submitAction profileData: %O', 'color: red;', profileData);
        // console.log('%c submitAction actionIsProcessing: %O', 'color: red;', actionIsProcessing);
        // this.setState({ actionIsProcessing: true }, this.submitActionProcessStep1.bind(this));
        engageNewProfile();
        toggleActionIsProcessing(true);
    };

    const cancelAction = (evt: any) => {
        // console.log('%c cancelAction actionIsProcessing: %O', 'color: red;', actionIsProcessing);
        if (actionIsProcessing === true)
            return false;
        toggleActionIsProcessing(false);
        if (onClose) onClose();
    };

    const engageNewProfile = () => {
        // console.log('%c engageNewProfile engagementAction: %O', 'color: blue;', engagementAction);
        // console.log('%c engageNewProfile engagementData: %O', 'color: blue;', engagementData);
        // console.log('%c recordUpdated this.action: %O', 'color: purple;', this.action);
        // console.log('%c recordUpdated this.actionSettings: %O', 'color: purple;', this.actionSettings);
        // console.log('%c recordUpdated this.engagementData: %O', 'color: purple;', this.engagementData);
        // console.log('%c recordUpdated this.record: %O', 'color: purple;', this.record);
        // console.log('%c recordUpdated this.componentProps: %O', 'color: purple;', this.componentProps);

        // console.log('%c recordUpdated record: %O', 'color: maroon;', record);

        if (!record) return false;
        if (record.udicciMediator !== 'People' && record.udicciMediator !== 'Organizations') return false;
        // console.log('%c record: %O', 'color: blue;', record);

        let userProfileId = (currentUser && currentUser.MeUdicciProfileId ? currentUser.MeUdicciProfileId : null);
        // console.log('%c userProfileId: %O', 'color: blue;', userProfileId);
        if (!userProfileId) return false;

        // console.log('%c selectedProfile: %O', 'color: blue;', selectedProfile);
        if (!selectedProfile || !selectedProfile.recordId) return false;

        // let allowEngage = false;
        // if (!allowEngage) return false;

        let action = engagementAction;
        // console.log('%c action: %O', 'color: blue;', action);
        // console.log('%c engagementData: %O', 'color: blue;', engagementData);
        // console.log('%c engagementData: %O', 'color: blue;', engagementData);

        let profileTypes: any = (udicci && udicci.profileTypes ? udicci.profileTypes : null);
        // console.log('%c profileTypes: %O', 'color: blue;', profileTypes);
        let createProfile = (action && action.createProfile ? action.createProfile : null);
        // console.log('%c createProfile: %O', 'color: blue;', createProfile);
        let createProfileContext = (engagementData && engagementData.createProfile ? engagementData.createProfile : null);
        // console.log('%c createProfileContext: %O', 'color: blue;', createProfileContext);

        let profileRecord = (createProfileContext && createProfileContext.profileRecord ? createProfileContext.profileRecord : null);
        // console.log('%c profileRecord: %O', 'color: blue;', profileRecord);
        if (!profileRecord) return false;

        profileTypes.sort(function(a: any, b: any){
            let leftValue = (a.title ? a.title.toLowerCase() : '');
            let rightValue = (b.title ? b.title.toLowerCase() : '');
            if (leftValue < rightValue) return -1;
            if (leftValue > rightValue) return 1;
            return 0;
        })

        let createProfileTypeId = (createProfile && createProfile.profileTypeId ? createProfile.profileTypeId : 0);
        // console.log('%c createProfileTypeId: %O', 'color: red; font-weight: bold;', createProfileTypeId);
        if (createProfileContext && createProfileContext.profileTypeId) {
            createProfileTypeId = createProfileContext.profileTypeId;
            // console.log('%c createProfileTypeId: %O', 'color: red; font-weight: bold;', createProfileTypeId);
        }
        let profileType = (profileTypes ? profileTypes.find((x: any) => {
                return x.recordId === createProfileTypeId
            }) : null
        );
        // console.log('%c profileType: %O', 'color: blue;', profileType);

        if (profileRecord && createProfileTypeId) {
            let settings = (profileType && profileType.data.jsonSettings ? profileType.data.jsonSettings : null);
            // console.log('%c settings: %O', 'color: red;', settings);

            let defaultSocialSolutions = (settings && settings.defaultSocialSolutions ? settings.defaultSocialSolutions : null);
            // console.log('%c defaultSocialSolutions: %O', 'color: red;', defaultSocialSolutions);

            let defaultRoles = (settings && settings.defaultRoles ? settings.defaultRoles : null);
            // console.log('%c defaultRoles: %O', 'color: red;', defaultRoles);

            let defaultAutoStackerRules = (settings && settings.defaultAutoStackerRules ? settings.defaultAutoStackerRules : null);
            // console.log('%c defaultAutoStackerRules: %O', 'color: red;', defaultAutoStackerRules);

            let selectedProfileId = (selectedProfile && selectedProfile.recordId ? selectedProfile.recordId : 0);
            // console.log('%c selectedProfileId: %O', 'color: blue;', selectedProfileId);
            let curDate = new Date();
            let curUserId = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
            let mediationSource = {
                profileId: selectedProfileId,
                socialSolutionId: record.data.CreatedInSolutionId,
                udicciMediator: record.udicciMediator,
                recordId: record.recordId,
                mediatedByUserId: curUserId,
                mediationDate: curDate
            };
            // console.log('%c mediationSource: %O', 'color: blue;', mediationSource);

            let requestJson: CreateNewProfileRequest = {
                UdicciCommand: 'Engage New Profile',
                UdicciMediatorName: 'Udicci Profiles',
                SelectedUdicciProfileId: udicci.ulyssesDConstantineProfileId,
                UserId: curUserId,
                SocialSolutionId: udicci.socialSolutionUdicciIT,
                DisplayName: (profileRecord && profileRecord.data.DisplayName ? profileRecord.data.DisplayName : ''),
                Headline: (profileRecord && profileRecord.data.Headline ? profileRecord.data.Headline : ''),
                MissionStatement: (profileRecord && profileRecord.data.MissionStatement ? profileRecord.data.MissionStatement : ''),
                ProfileUrl: (profileRecord && profileRecord.data.ProfileUrl ? profileRecord.data.ProfileUrl : ''),
                UdicciProfileTypeId: createProfileTypeId,
                UserUdicciProfileId: userProfileId,
                MediationSource: JSON.stringify(mediationSource),
                Roles: defaultRoles,
                SocialSolutions: defaultSocialSolutions,
                AutoStackerRules: defaultAutoStackerRules
            };
            // console.log('%c engageNewProfile requestJson: %O', 'color: blue;', requestJson);

            let okToContinue = true;
            if (okToContinue) {
                sendPreparedRequest(requestJson, {
                    onSuccess: engageNewProfileSucceeded,
                    onError: engageNewProfileFailed
                });
            }
        }
    }

    const engageNewProfileSucceeded = (response: any) => {
        // console.log('%c engageNewProfileSucceeded response: %O', 'color: red;', response);
        // console.log('%c engageNewProfileSucceeded this.action: %O', 'color: purple;', this.action);
        // console.log('%c engageNewProfileSucceeded this.actionSettings: %O', 'color: purple;', this.actionSettings);
        // console.log('%c engageNewProfileSucceeded this.engagementData: %O', 'color: purple;', this.engagementData);
        // console.log('%c engageNewProfileSucceeded this.record: %O', 'color: purple;', this.record);
        toggleActionIsProcessing(false);
        if (onClose) onClose();
    }

    const engageNewProfileFailed = (response: any) => {
        // console.log('%c engageNewProfileFailed response: %O', 'color: red;', response);
        // console.log('%c engageNewProfileFailed this.action: %O', 'color: purple;', this.action);
        // console.log('%c engageNewProfileFailed this.actionSettings: %O', 'color: purple;', this.actionSettings);
        // console.log('%c engageNewProfileFailed this.engagementData: %O', 'color: purple;', this.engagementData);
        // console.log('%c engageNewProfileFailed this.record: %O', 'color: purple;', this.record);
        toggleActionIsProcessing(false);
    }

    let saveButtonDisabled = true;
    let cancelButtonDisabled = true;
    if (!actionIsProcessing) {
        cancelButtonDisabled = false;
        if (profileRecord && profileRecord.isDirty && IUnderstandChecked) {
            saveButtonDisabled = false;
        }
    }
    // console.log('%c saveButtonDisabled: %O', 'color: blue;', saveButtonDisabled);
    let submitActionButton: any = (
        <Button onClick={submitAction} disabled={saveButtonDisabled} color="primary" autoFocus>
            {submitActionButtonText}
        </Button>
    );

    let cancelActionButton: any = (
        <Button onClick={cancelAction} color="info" disabled={cancelButtonDisabled}>
            Cancel
        </Button>
    );

    return (
        <Box sx={udicciClasses.contentArea}>
            {displayNameElement}
            {headlineNameElement}
            {missionStatementNameElement}
            {profileUrlNameElement}
            {IUnderstandElement}
            {submitActionButton}
            {cancelActionButton}
        </Box>
    );
};
