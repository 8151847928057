import { Fragment, useState } from 'react';

import { useTheme, styled } from '@mui/material/styles';

import {
    Box, Typography, Icon, IconButton, Toolbar, Tooltip, AppBar, CssBaseline, // SpeedDial, SpeedDialIcon, SpeedDialAction,
    Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, // Portal
} from '@mui/material';

import { forEach } from 'underscore';

import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciContext, logoff } from 'src/context/udicci-context';
import { useFocusedContext } from './focused-context';

import { UdicciRecord } from 'src/classes/udicci-record';

import { ConfirmLogoff } from './confirm-logoff';
import { LoginFromMenu } from './login-from-menu';
  
const MobileMenuContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    // margin: '8px',
    // height: '100%',
}));

export const MenuContent: React.FC<any> = (props) => {
    const focus = useFocusedContext();
    // console.log('%c MenuContent focus: %O', 'color: blue;', focus);

    let theme = useTheme();

    const udicciHelpers = useUdicciHelpers();
    const udicciContext = useUdicciContext();
    let { udicci } = udicciContext.state;
    let { selectedProfile, selectedPorta, currentUser } = udicci;
    // console.log('%c MenuContent selectedPorta: %O', 'color: blue;', selectedPorta);

    let profileDisplayName: string = (selectedProfile && selectedProfile.data.DisplayName ? selectedProfile.data.DisplayName : '');
    // console.log('%c MenuContent profileDisplayName: %O', 'color: blue;', profileDisplayName);
    let portaName: string = (selectedPorta && selectedPorta.Name ? selectedPorta.Name : '');
    // console.log('%c MenuContent portaName: %O', 'color: blue;', portaName);
    let portaSettings: any = (selectedPorta && selectedPorta.SettingsJson ? selectedPorta.SettingsJson : null);
    // console.log('%c MenuContent portaSettings: %O', 'color: blue;', portaSettings);
    let portaFocusBoard: any = (portaSettings && portaSettings.focusBoard ? portaSettings.focusBoard : null);
    // console.log('%c MenuContent portaFocusBoard: %O', 'color: blue;', portaFocusBoard);

    let udicciFocusBoard: any = (udicci && udicci.focusBoard ? udicci.focusBoard : null);
    // console.log('%c MenuContent udicciFocusBoard: %O', 'color: blue;', udicciFocusBoard);

    let userId: number = (currentUser && currentUser.UdicciUserId ? currentUser.UdicciUserId : 0);
    // console.log('%c DisplayThePoint userId: %O', 'color: green;', userId);
    let createdByUserId: number = (selectedPorta && selectedPorta.CreatedByUserId ? selectedPorta.CreatedByUserId : 0);
    // console.log('%c DisplayThePoint createdByUserId: %O', 'color: green;', createdByUserId);

    let readonly: boolean = (userId <= 0 || createdByUserId !== userId ? true : false);
    // console.log('%c DisplayThePoint readonly: %O', 'color: green;', readonly);

    let pointsFromContext: boolean = false;
    let focusBoardPoints: any[] = [];
    let pointStructure: any = udicciHelpers.getMediatorStructure('Points');
    // console.log('%c MenuContent pointStructure: %O', 'color: green;', pointStructure);
    if (udicciFocusBoard && udicciFocusBoard.Points && udicciFocusBoard.Points.length > 0 && pointStructure) {
        forEach(udicciFocusBoard.Points, (pointRecord: any, pntIdx: number) => {
            focusBoardPoints.push(pointRecord);
        });
        pointsFromContext = true;
    } else if (portaFocusBoard && portaFocusBoard.points) {
        focusBoardPoints = portaFocusBoard.points;
    }
    // console.log('%c MenuContent focusBoardPoints: %O', 'color: blue;', focusBoardPoints);

    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [showLogoff, setShowLogoff] = useState<boolean>(false);
    const [acknowledgeMoveForward, setAcknowledgeMoveForward] = useState<boolean>(false);
    const [needAcknowledgement, setNeedAcknowledgement] = useState<boolean>(false);

    const handleSettingMenuClick = (settingName: string) => {
        setMenuOpen(false);
        if (settingName === 'ConfigurePortal') {
            udicci.toggleDesignMode();
        } else {
            if (focus.changeSettingMenu) focus.changeSettingMenu(settingName);
        }
    };

    const goToHome = () => {
        setMenuOpen(false);
        if (focus.point || focus.aiAssistantOn || focus.configureSettings) {
            focus.closeFocus();
        } else {
            udicci.goToDefaultPorta();
        }
    };

    const addNew = () => {
        setMenuOpen(!menuOpen);
        // console.log('%c addNew focus.point: %O', 'color: red;', focus.point);

        if (focus.point) {
            // there is already a point in focus.  we need to make sure we let the user know this.
            // console.log('%c addNew needAcknowledgement: %O', 'color: red;', needAcknowledgement);
            // console.log('%c addNew acknowledgeMoveForward: %O', 'color: red;', acknowledgeMoveForward);
            if (!acknowledgeMoveForward) {
                setNeedAcknowledgement(true);
            }
        } else {
            let newPoint: UdicciRecord = focus.getNewPointRecord();
            if (focus.setPointToFocus) focus.setPointToFocus(newPoint);
            setAcknowledgeMoveForward(false);
            setNeedAcknowledgement(false);
        }
    };

    const acknowledgeCancelled = () => {
        setAcknowledgeMoveForward(false);
        setNeedAcknowledgement(false);
    };

    const acknowledgeConfirmed = () => {
        setAcknowledgeMoveForward(false);
        setNeedAcknowledgement(false);
        let newPoint: UdicciRecord = focus.getNewPointRecord();
        if (focus.setPointToFocus) focus.setPointToFocus(newPoint);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handlePointItemClick = (focusBoardPoint: any) => {
        // console.log('%c handlePointItemClick focusBoardPoint: %O', 'color: blue;', focusBoardPoint);
        setMenuOpen(false);
        if (focus.setPointToFocus) focus.setPointToFocus(focusBoardPoint);
    };

    const userWantsToLoginToPlatform = () => {
        // console.log('%c userWantsToLoginToPlatform showLogin: %O', 'color: maroon;', showLogin);
        setShowLogin(true);
    }

    const logUserOffPlatform = () => {
        // console.log('%c logUserOffPlatform showLogoff: %O', 'color: maroon;', showLogoff);
        setShowLogoff(true);
    }

    const cancelLogUserOffPlatform = () => {
        // console.log('%c cancelLogUserOffPlatform showLogoff: %O', 'color: maroon;', showLogoff);
        setShowLogoff(false);
    }

    const confirmedLogUserOffPlatform = () => {
        // console.log('%c confirmedLogUserOffPlatform showLogoff: %O', 'color: maroon;', showLogoff);
        setShowLogoff(false);
        logoff();
    }

    const cancelUserWantsToLoginToPlatform = () => {
        // console.log('%c cancelUserWantsToLoginToPlatform showLogoff: %O', 'color: maroon;', showLogoff);
        setShowLogin(false);
    }

    const userLoggedInSuccessfully = () => {
        // console.log('%c userLoggedInSuccessfully showLogoff: %O', 'color: maroon;', showLogoff);
        setShowLogin(false);
    }

    let homeIconButton: any = null;
    let addIconButton: any = null;
    let userMenuIconButton: any = null;
    let settingsIconButton: any = null;
    let refreshBoardIconButton: any = null;
    let closeMenuIconButton: any = null;
    if (focus.isMobile) {
        closeMenuIconButton = (
            <IconButton color="secondary" aria-label="open menu" edge="start" onClick={toggleMenu} sx={{ mr: 1 }}>
                <Icon>navigate_before</Icon>
            </IconButton>
        );
    }

    homeIconButton = (
        <IconButton color="secondary" aria-label="open menu" edge="start" onClick={goToHome} sx={{ mr: 1 }}>
            <Icon>home</Icon>
        </IconButton>
    );

    if (udicci.userIsLoggedIn()) {
        if (focus.configureSettings) {
            settingsIconButton = (
                <IconButton disabled={focus.aiAssistantOn} color="secondary" aria-label="settings" edge="end" onClick={() => { setMenuOpen(false); focus.closeFocus(); }} sx={{ float: 'right', mr: 1 }}>
                    <Icon>close</Icon>
                </IconButton>
            );
        } else {
            userMenuIconButton = (
                <IconButton disabled={focus.aiAssistantOn} color="secondary" aria-label="logout" edge="end" onClick={() => { setMenuOpen(false); logUserOffPlatform(); }} sx={{ float: 'right' }}>
                    <Icon>logout</Icon>
                </IconButton>
            );
            if (!readonly) {
                settingsIconButton = (
                    <IconButton disabled={focus.aiAssistantOn} color="secondary" aria-label="settings" edge="end" onClick={() => { setMenuOpen(false); focus.toggleSettingsForm(); }} sx={{ float: 'right', mr: 1 }}>
                        <Icon>settings</Icon>
                    </IconButton>
                );
                addIconButton = (
                    <IconButton disabled={focus.aiAssistantOn} color="secondary" aria-label="add" edge="start" onClick={addNew} sx={{ mr: 1 }}>
                        <Icon>add</Icon>
                    </IconButton>
                );
            }
            refreshBoardIconButton = (
                <IconButton disabled={focus.aiAssistantOn} color="secondary" aria-label="settings" edge="end" onClick={() => { setMenuOpen(false); focus.refreshFocusBoard(); }} sx={{ float: 'right', mr: 1 }}>
                    <Icon>refresh</Icon>
                </IconButton>
            );
        }
    } else {
        userMenuIconButton = (
            <IconButton color="secondary" aria-label="login" edge="end" onClick={() => { setMenuOpen(false); userWantsToLoginToPlatform(); }} sx={{ float: 'right' }}>
                <Icon>login</Icon>
            </IconButton>
        );
    }

    let focusBoardTitle: any = null;
    if (portaName) {
        focusBoardTitle = (
            <Box sx={{ display: 'flow-root', width: '100%', textAlign: 'center' }}>
                <Typography variant="body2" component="span"> {portaName} </Typography>
            </Box>
        );
    }

    let drawerToolbarElement: any = null;
    drawerToolbarElement = (
        <Fragment>
            <Toolbar sx={{ background: 'rgba(255, 255, 255, 0.1)', paddingLeft: '8px !important', paddingRight: '8px !important' }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ width: '100%' }}>
                        {userMenuIconButton}
                        {closeMenuIconButton}
                        {homeIconButton}
                    </Box>
                    <Divider />
                    <Box sx={{ width: '100%' }}>
                        {focusBoardTitle}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        {refreshBoardIconButton}
                        {settingsIconButton}
                        {addIconButton}
                    </Box>
                </Box>
            </Toolbar>
            <Divider />
        </Fragment>
    );
    let appBarToolbarElement: any = null;
    if (focus.isMobile) {
        let appBarSettings: any = {
            width: {
                sm: `calc(100% - ${udicci.standardMenuDrawerWidth}px)`
            },
            ml: {
                sm: `${udicci.standardMenuDrawerWidth}px`
            },
        }
        appBarToolbarElement = (
            <Fragment>
                <CssBaseline />
                <AppBar color="primary" position="fixed" sx={appBarSettings}>
                    <MobileMenuContainer>
                        <Toolbar>
                            <IconButton color="secondary" aria-label="open menu" edge="start" onClick={toggleMenu} sx={{ mr: 2, display: { sm: 'none' } }}>
                                <Icon>menu</Icon>
                            </IconButton>
                            <Typography variant="subtitle1" noWrap component="span" sx={{ marginRight: '8px' }}> Udicci </Typography>
                            <Typography variant="caption" noWrap component="span" sx={{ marginRight: '8px', fontSize: '0.8em' }}> with  </Typography>
                            <Typography variant="subtitle2" noWrap component="span"> {profileDisplayName} </Typography>
                        </Toolbar>
                    </MobileMenuContainer>
                </AppBar>
            </Fragment>
        );
    }

    let confirmElement: any = null;
    if (needAcknowledgement && !acknowledgeMoveForward) {
        let cancelAcknowledgeIconButton: any = (
            <IconButton color="secondary" size="small" onClick={acknowledgeCancelled} sx={{ ml: 2 }}>
                <Icon fontSize="small">close</Icon>
            </IconButton>
        );
        let confirmAcknowledgeIconButton: any = (
            <IconButton color="success" size="small" onClick={acknowledgeConfirmed}>
                <Icon fontSize="small">check</Icon>
            </IconButton>
        );
    
        let confirmElementProps: any = {
            padding: '8px',
            margin: '8px',
            marginRight: 0,
            border: '1px solid rgb(234 85 8)',
            borderRight: 0,
        };
        confirmElement = (
            <Box sx={confirmElementProps}>
                <Typography variant="caption" component="div">
                    Any changes you have for the current point in focus will be lost.
                </Typography>
                <Typography variant="caption" component="div">
                    Are you sure you want to continue?
                </Typography>
                {confirmAcknowledgeIconButton}
                {cancelAcknowledgeIconButton}
            </Box>
        );
    } else if (udicci.userHasTimedOut) {
        confirmElement = (
            <Box>
                <Typography variant="caption">Your session has timed out.  Please login.</Typography>
            </Box>
        );
    }

    let logoffForm: any = null;
    if (showLogoff) {
        logoffForm = (
            <ConfirmLogoff onConfirm={confirmedLogUserOffPlatform} onCancel={cancelLogUserOffPlatform} />
        );
    } else if (showLogin) {
        logoffForm = (
            <LoginFromMenu onLoginSuccess={userLoggedInSuccessfully} onCancel={cancelUserWantsToLoginToPlatform} />
        );
    }

    let sxListItem: any = {
        border: '1px solid #ccc',
        borderRight: 0,
        borderTopLeftRadius: '4px',
        marginRight: 0,
        marginLeft: '8px',
        marginBottom: '8px',
        width: 'unset',
    };
    let chatIconSx: any = {
        backgroundColor: 'rgb(234 85 8) !important',
        maxHeight: '8px',
        minWidth: '8px',
        borderRadius: '8px',
        marginRight: '8px',
        // fontSize: 0,
    };

    var menuContentsDrawer: any = null;
    if (udicci.userIsLoggedIn()) {
        if (focus.configureSettings) {
            let highlightedBorder: any = { border: '1px solid rgb(234 85 8)' };
            let loadConfigureSettings_ConfigureFocusBoard: any = ((evt: any) => handleSettingMenuClick('FocusBoard'));
            let loadConfigureSettings_Profile: any = ((evt: any) => handleSettingMenuClick('Profile'));
            let loadConfigureSettings_ChatGPT: any = ((evt: any) => handleSettingMenuClick('ChatGPT'));
            let loadConfigureSettings_ResetPassword: any = ((evt: any) => handleSettingMenuClick('ResetPassword'));

            let configurePortalMenuElement: any = null;
            if (!focus.isMobile) {
                let configurePortal: any = ((evt: any) => handleSettingMenuClick('ConfigurePortal'));
                configurePortalMenuElement = (
                    <Fragment>
                        <Divider sx={{ marginTop: '16px' }} />
                        <ListItem disablePadding sx={{ ...sxListItem, ...{ marginTop: '16px' } }}>
                            <ListItemButton onClick={configurePortal} sx={{ paddingLeft: '8px', paddingRight: 0 }}>
                                <ListItemIcon sx={{ minWidth: '20px', marginRight: '8px' }}>
                                    <Icon color="primary" fontSize="small">settings</Icon>
                                </ListItemIcon>
                                <ListItemText primary={"Configure this Porta"} />
                            </ListItemButton>
                        </ListItem>
                    </Fragment>                    
                );
            }
            menuContentsDrawer = (
                <div>
                    {drawerToolbarElement}
                    {logoffForm}
                    {confirmElement}
                    <List dense>
                        <ListItem disablePadding sx={{...sxListItem, ...(focus.selectedSettingMenu === 'FocusBoard' ? highlightedBorder : {})}}>
                            <ListItemButton onClick={loadConfigureSettings_ConfigureFocusBoard} sx={{ paddingLeft: '8px', paddingRight: 0 }}>
                                <ListItemIcon sx={{ minWidth: '20px', marginRight: '8px' }}>
                                    <Icon color="secondary" fontSize="small">settings</Icon>
                                </ListItemIcon>
                                <ListItemText primary={"Focus Board Config"} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={{...sxListItem, ...(focus.selectedSettingMenu === 'ChatGPT' ? highlightedBorder : {})}}>
                            <ListItemButton onClick={loadConfigureSettings_ChatGPT} sx={{ paddingLeft: '8px', paddingRight: 0 }}>
                                <ListItemIcon  sx={{ minWidth: '20px', marginRight: '8px' }}>
                                    <Icon color="secondary" fontSize="small">settings</Icon>
                                </ListItemIcon>
                                <ListItemText primary={"ChatGPT AI Settings"} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={{...sxListItem, ...(focus.selectedSettingMenu === 'Profile' ? highlightedBorder : {})}}>
                            <ListItemButton onClick={loadConfigureSettings_Profile} sx={{ paddingLeft: '8px', paddingRight: 0 }}>
                                <ListItemIcon sx={{ minWidth: '20px', marginRight: '8px' }}>
                                    <Icon color="secondary" fontSize="small">settings</Icon>
                                </ListItemIcon>
                                <ListItemText primary={"Profile Settings"} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={{...sxListItem, ...(focus.selectedSettingMenu === 'ResetPassword' ? highlightedBorder : {})}}>
                            <ListItemButton onClick={loadConfigureSettings_ResetPassword} sx={{ paddingLeft: '8px', paddingRight: 0 }}>
                                <ListItemIcon sx={{ minWidth: '20px', marginRight: '8px' }}>
                                    <Icon color="secondary" fontSize="small">settings</Icon>
                                </ListItemIcon>
                                <ListItemText primary={"Reset Your Password"} />
                            </ListItemButton>
                        </ListItem>
                        {configurePortalMenuElement}
                    </List>
                </div>
            );
        } else {
            menuContentsDrawer = (
                <div>
                    {drawerToolbarElement}
                    {logoffForm}
                    {confirmElement}
                    <List dense>
                        {focusBoardPoints.map((focusBoardPoint: any, index: number) => {
                            let clickItemEvt: any = null;
                            if (!focus.aiAssistantOn) clickItemEvt = ((evt: any) => { handlePointItemClick(focusBoardPoint); });

                            let itemIconSx: any = {};
                            Object.assign(itemIconSx, chatIconSx);

                            let itemSx: any = {};
                            Object.assign(itemSx, sxListItem);

                            if (!pointsFromContext) {
                                // itemSx.background = 'rgba(0, 0, 0, 0.1)';
                                itemIconSx.backgroundColor = 'gray';
                            }
                            if (focus.aiAssistantOn) {
                                itemSx.background = 'rgba(0, 0, 0, 0.1)';
                                itemSx.border = '1px solid #000';
                                itemSx.cursor = 'default';
                            }

                            if (focus.point && focus.point.recordId === focusBoardPoint.recordId) {
                                itemSx.border = '1px solid green';
                                itemSx.borderLeft = "4px solid green";
                                itemSx.background = "rgba(255, 255, 255, 0.07)";
                            }

                            let itemButtonSx: any = {
                                paddingLeft: '8px',
                                paddingRight: '4px',
                            };
                            if (focus.aiAssistantOn) {
                                itemButtonSx.cursor = 'default';
                            }
                            let itemTextSx: any = {
                                display: 'block',
                                paddingRight: '4px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontSize: '0.70em',
                            };
                            let itemTextElement: any = (
                                <Typography variant="caption" sx={itemTextSx}>{focusBoardPoint.title}</Typography>
                            );
                            return (
                                <ListItem key={'focus.board.point.menu.item.' + focusBoardPoint.recordId} disablePadding sx={itemSx}>
                                    <ListItemButton onClick={clickItemEvt} sx={itemButtonSx} disabled={focus.aiAssistantOn}>
                                        <ListItemIcon onClick={clickItemEvt} sx={itemIconSx}>&nbsp;</ListItemIcon>
                                        <ListItemText onClick={clickItemEvt} primary={(itemTextElement)} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
            );
        }
    } else {
        menuContentsDrawer = (
            <div>
                {drawerToolbarElement}
                {logoffForm}
                {confirmElement}
            </div>
        );
    }

    let menuContainerProps: any = { component: 'nav', sx: { width: { sm: udicci.standardMenuDrawerWidth }, flexShrink: { sm: 0 } } };
    let tempDrawerProps: any = {
        variant: 'temporary',
        open: menuOpen,
        onClose: toggleMenu,
        ModalProps: { keepMounted: true },
        sx: { display: { xs: 'block', sm: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: udicci.standardMenuDrawerWidth, borderRight: '2px solid #ccc' }, }
    };
    let permDrawerProps: any = {
        variant: 'permanent',
        sx: { display: { xs: 'none', sm: 'block' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: udicci.standardMenuDrawerWidth, borderRight: '2px solid #ccc' }, }
    };
    const menuElement: any = (
        <Box {...menuContainerProps}>
            <Drawer {...tempDrawerProps}>{menuContentsDrawer}</Drawer>
            <Drawer {...permDrawerProps} open>{menuContentsDrawer}</Drawer>
        </Box>
    );

    return (<Fragment>{appBarToolbarElement}{menuElement}</Fragment>);
};
