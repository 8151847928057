import React from 'react';

import useUdicciRecord from 'src/hooks/useUdicciRecord';

const UdicciForm: React.FC<any> = (props) => {
    // console.log('%c UdicciForm props: %O', 'color: maroon;', props);
    let pEngagedAction: any = (props.engagementAction ? props.engagementAction : null);
    // let pEngagedAction: any = (props.engagementAction ? props.engagementAction : null);
    let { getFormElement } = useUdicciRecord(props.record, pEngagedAction);
    let formProps: any = {};
    Object.assign(formProps, props);
    // console.log('%c UdicciForm formProps: %O', 'color: maroon;', formProps);
    return getFormElement(formProps);
}

export default UdicciForm;
