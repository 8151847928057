import React, { useState, useContext } from 'react';

import { useTheme } from '@mui/material/styles';

// import UrlParser from 'url-parse';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { UdicciContext, saveRecord, subscribe as subscribeToUdicci } from 'src/context/udicci-context';
import { UdicciRecord } from 'src/classes/udicci-record';
import { EngagementStructure } from 'src/classes/udicci-types';
import { udicciStyles} from 'src/theme/shared-styles';

const EngagementForm: React.FC<any> = ({ onClose }) => {
    // console.log('%c EngagementForm onClose: %O', 'color: maroon;', onClose);
    const theme = useTheme();
    const udicciClasses = udicciStyles(theme);

    const [savingEngagement, toggleSavingEngagement] = useState<boolean>(false);
    const [formMessage, setFormMessage] = useState<string>('');
    const [engagementRequest, setEngagementRequest] = useState<null | EngagementStructure>(null);
    const [forcedUpdateState, forceUpdate] = useState<boolean>(false);
    // console.log('%c EngagementForm engagementRequest: %O', 'color: maroon;', engagementRequest);

    const udicciContext = useContext(UdicciContext);
    // console.log('%c udicciContext: %O', 'color: red;', udicciContext);

    let { udicci } = udicciContext.state;
    // console.log('%c EngagementForm data: %O', 'color: maroon;', data);
    // console.log('%c EngagementForm udicci: %O', 'color: maroon;', udicci);

    let { selectedProfile } = udicci;
    // console.log('%c EngagementForm selectedProfile: %O', 'color: maroon;', selectedProfile);
    // console.log('%c EngagementForm currentUser: %O', 'color: maroon;', currentUser);

    let profile: any = (selectedProfile && selectedProfile.data ? selectedProfile.data : null);
    // console.log('%c profile: %O', 'color: hotpink;', profile);

    let profileType = (profile && profile.ProfileType ? profile.ProfileType : null);
    // console.log('%c profileType: %O', 'color: hotpink;', profileType);
    let userRoleOnProfile = (profile && profile.UdicciUserRole ? profile.UdicciUserRole : null);
    // console.log('%c userRoleOnProfile: %O', 'color: hotpink;', userRoleOnProfile);
    // let userProfile = (udicciContext && udicciContext.user && udicciContext.user.profile ? udicciContext.user.profile : null);
    // console.log('%c userProfile: %O', 'color: hotpink;', userProfile);

    const engageInformationChanged = (fieldName: string, evt: any) => {
        // console.log('%c engageInformationChanged fieldName: %O', 'color: maroon;', fieldName);
        // console.log('%c engageInformationChanged evt: %O', 'color: maroon;', evt);
        // console.log('%c engageInformationChanged engagementRequest: %O', 'color: maroon;', engagementRequest);
        if (savingEngagement)
            return false;

        let engagement: EngagementStructure | null = engagementRequest;
        if (engagement === null) {
            engagement = {
                UdicciRecordId: 0,
                FirstName: '',
                LastName: '',
                EmailAddress: '',
                PhoneNumber: '',
                Organization: '',
                HowWeMet: '',
                Interest: '',
                AdditionalInfo: '',
                jsonAdditionalInfo: null,
                isDirty: false
            };
        }

        let trgt = evt.target;
        let newValue = (trgt && trgt.type === 'checkbox' ? trgt.checked : trgt.value);

        switch (fieldName) {
            case 'FirstName':
                engagement.FirstName = newValue;
                break;
            case 'LastName':
                engagement.LastName = newValue;
                break;
            case 'EmailAddress':
                engagement.EmailAddress = newValue;
                break;
            case 'PhoneNumber':
                engagement.PhoneNumber = newValue;
                break;
            case 'Organization':
                engagement.Organization = newValue;
                break;
            case 'HowWeMet':
                engagement.HowWeMet = newValue;
                break;
            case 'Interest':
                engagement.Interest = newValue;
                break;
        }

        engagement.isDirty = true;

        setEngagementRequest(engagement);
        forceUpdate(!forcedUpdateState);
    };

    const onSendRequest = () => {
        // console.log('%c currentUser: %O', 'color: purple; font-weight: bold;', currentUser);
        // console.log('%c profile: %O', 'color: purple; font-weight: bold;', profile);
        // let userProfile = (udicciContext && udicciContext.user && udicciContext.user.profile ? udicciContext.user.profile : null);

        var engagementTrackingFromStore = sessionStorage.getItem('udicci.engagementTracking');

        var engagementTracking = null;
        if (engagementTrackingFromStore) {
            engagementTracking = JSON.parse(engagementTrackingFromStore);
        }
        // console.log('%c onSendRequest engagementTracking: %O', 'color: red;', engagementTracking);
    
        let requestRecordData: any = engagementRequest;
        // console.log('%c requestRecordData: %O', 'color: purple; font-weight: bold;', requestRecordData);

        if (!requestRecordData) {
            let errMessage = 'There was an issue trying to capture your request.  Please try again later.';
            setFormMessage(errMessage);
            return false;
        }

        if (requestRecordData.FirstName.length <= 0) {
            let errMessage = 'Yep, your First Name is still required.';
            setFormMessage(errMessage);
            return false;
        }

        if (requestRecordData.EmailAddress.length <= 0 && requestRecordData.PhoneNumber.length <= 0) {
            let errMessage = 'Either your Email Address or Phone Number is required to submit this form.';
            setFormMessage(errMessage);
            return false;
        }

        if (requestRecordData.EmailAddress.length > 0 && requestRecordData.EmailAddress.length < 8) {
            let errMessage = 'We apologize, but we cannot verify that this is a valid email.';
            setFormMessage(errMessage);
            return false;
        }

        if (requestRecordData.PhoneNumber.length > 0 && requestRecordData.PhoneNumber.length < 10) {
            let errMessage = 'We prefer a 10-digit phone number.  You can add parenthesis and dashes if you want to make it look nice.';
            setFormMessage(errMessage);
            return false;
        }

        if (engagementTracking) {
            requestRecordData.jsonAdditionalInfo = {
                tracking: engagementTracking
            };
            requestRecordData.AdditionalInfo = JSON.stringify(requestRecordData.jsonAdditionalInfo);            
        }

        let newEngagementRecord: UdicciRecord = {
            recordId: 0,
            udicciMediator: 'Engagement Requests',
            title: requestRecordData.FirstName,
            description: requestRecordData.LastName,
            isDirty: false,
            isSaving: true,
            keys: { titleField: 'FirstName', descriptionField: 'LastName' },
            permissions: null,
            data: requestRecordData
        };
        // console.log('%c newEngagementRecord: %O', 'color: purple; font-weight: bold;', newEngagementRecord);

        // let selectedProfileId = profile.UdicciRecordId;
        let profileSolutions = (profile && profile.SocialSolutions ? profile.SocialSolutions : null);
        // console.log('%c profileSolutions: %O', 'color: purple; font-weight: bold;', profileSolutions);
        let socialSolutionId = udicci.socialSolutionDefaultMe;
        if (profileSolutions) {
            let targetSocialSolution = profileSolutions.find((x: any) => x.title === 'Relationship Manager');
            // console.log('%c targetSocialSolution: %O', 'color: purple; font-weight: bold;', targetSocialSolution);
            if (targetSocialSolution) socialSolutionId = targetSocialSolution.recordId;
        }

        let okToContinue = true;
        if (okToContinue) {
            saveRecord(newEngagementRecord, socialSolutionId);
            toggleSavingEngagement(true);
        }
    };

    const udicciContextUpdate = (result: any) => {
        // console.log('onSendRequestComplete result: %O', result);

        let updatedEngagement: EngagementStructure | null = engagementRequest;
        if (result && result.length > 0) {
            let engagementRecord = result[0];
            // console.log('engagementRecord: %O', engagementRecord);
            if (engagementRecord && engagementRecord.data) {
                updatedEngagement = engagementRecord.data;
                if (updatedEngagement) updatedEngagement.isDirty = false;
                // console.log('%c updatedEngagement: %O', 'color: blue; font-weight: bold;', updatedEngagement);
            }
        }
        // console.log('updatedEngagement: %O', updatedEngagement);

        toggleSavingEngagement(false);
        setFormMessage('');
        setEngagementRequest(updatedEngagement);
    };

    subscribeToUdicci('engagement.form', udicciContextUpdate);

    const onResetDisplay = () => {
        toggleSavingEngagement(false);
        setFormMessage('');
        setEngagementRequest(null);
    };

    let firstName = (engagementRequest && engagementRequest.FirstName ? engagementRequest.FirstName : '');
    let lastName = (engagementRequest && engagementRequest.LastName ? engagementRequest.LastName : '');
    let emailAddress = (engagementRequest && engagementRequest.EmailAddress ? engagementRequest.EmailAddress : '');
    let phoneNumber = (engagementRequest && engagementRequest.PhoneNumber ? engagementRequest.PhoneNumber : '');
    // let organization = (engagementRequest && engagementRequest.Organization ? engagementRequest.Organization : '');
    // let interest = (engagementRequest && engagementRequest.Interest ? engagementRequest.Interest : '');
    // let howWeMet = (engagementRequest && engagementRequest.HowWeMet ? engagementRequest.HowWeMet : '');
    let isDirty = (engagementRequest && engagementRequest.isDirty ? true : false);

    let errorMessageElement: any = null;
    if (formMessage) {
        errorMessageElement = (
            <Typography variant="body2" component="div" color="error">
                {formMessage}
            </Typography>
        );
    }

    let engageCardHeader = null;
    let displayName = (profile && profile.DisplayName ? profile.DisplayName : profile.Name);
    if (engagementRequest && engagementRequest.UdicciRecordId > 0) {
        engageCardHeader = (
            <Typography variant="subtitle1" component="div" color="textSecondary">
                {'Thank you for Engaging with ' + profileType.Name + ', ' + engagementRequest!.FirstName + '.'}
            </Typography>
        );
    } else {
        engageCardHeader = (
            <Typography variant="subtitle1" component="div" color="primary">
                {'Engage with ' + displayName}
            </Typography>
        );
    }

    let displayElement: any = null;

    let isEngaged = (engagementRequest && engagementRequest.UdicciRecordId ? true : false);
    if (isEngaged) {
        displayElement = (
            <Box>
                <Typography variant="caption" component="div">
                    Your request will be reviewed soon.
                </Typography>
                <Button onClick={onResetDisplay} color="secondary">
                    Reset Display
                </Button>
            </Box>
        );
    } else {
        if (userRoleOnProfile) {
            let roleName = userRoleOnProfile.RoleName;
            let youAreAnAdministrator = userRoleOnProfile.IsAdministratorRole;

            let administratorRoleElement = null;
            if (youAreAnAdministrator) {
                administratorRoleElement = (
                    <Typography variant="caption" component="div" color="secondary">
                        Your role gives you administrator privileges for this profile.
                    </Typography>
                );
            }
            displayElement = (
                <Box>
                    <Typography variant="body2" component="div" color="textPrimary">
                        You are currently engaged with this profile.
                    </Typography>
                    <Box>
                        <Typography variant="body2" component="span" color="textPrimary">
                            Your assigned role on this profile is
                        </Typography>
                        <Typography variant="body1" component="span" color="secondary">
                            &nbsp;{roleName}.
                        </Typography>
                    </Box>
                    {administratorRoleElement}
                </Box>
            );
        } else {
            // let orgNameElement: any = (
            //     <TextField margin="dense"
            //         label="What Organization are you with?"
            //         placeholder='Group, Charity, or Company'
            //         type="text"
            //         autoComplete='organization'
            //         value={organization}
            //         fullWidth
            //         InputLabelProps={{ shrink: true }}
            //         onChange={engageInformationChanged.bind(this, 'Organization')} />
            // );
            // let interestElement: any = (
            //     <TextField multiline
            //         margin="dense"
            //         label="Please explain your interest."
            //         placeholder='For example: Learn More, as an Agent, as a Broker, etc.'
            //         type="text"
            //         value={interest}
            //         fullWidth
            //         InputLabelProps={{ shrink: true }}
            //         onChange={engageInformationChanged.bind(this, 'Interest')} />
            // );
            // let howWeMetElement: any = (
            //     <TextField multiline
            //         margin="dense"
            //         label="How did we meet?"
            //         placeholder='Please help me to remember how we met.'
            //         type="text"
            //         value={howWeMet}
            //         fullWidth
            //         InputLabelProps={{ shrink: true }}
            //         onChange={engageInformationChanged.bind(this, 'HowWeMet')} />
            // );

            displayElement = (
                <Box>
                    <Box>
                        {errorMessageElement}
                        <TextField margin="dense" autoFocus required
                            label="First Name"
                            placeholder='What is your First Name?'
                            type="text"
                            autoComplete='given-name'
                            value={firstName}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={engageInformationChanged.bind(this, 'FirstName')} />
                        <TextField margin="dense"
                            label="Last Name"
                            placeholder='What is your Last Name?'
                            type="text"
                            autoComplete='family-name'
                            value={lastName}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={engageInformationChanged.bind(this, 'LastName')} />
                        <TextField margin="dense"
                            label="Email Address"
                            placeholder='Please share your Email Address'
                            type="text"
                            autoComplete='email'
                            value={emailAddress}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={engageInformationChanged.bind(this, 'EmailAddress')} />
                        <TextField margin="dense"
                            label="Phone Number"
                            placeholder='Your Phone Number is appreciated, but not necessary'
                            type="text"
                            autoComplete='tel'
                            value={phoneNumber}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={engageInformationChanged.bind(this, 'PhoneNumber')} />
                    </Box>
                    <Box>
                        <Button onClick={onSendRequest.bind(this)} color="secondary" disabled={!isDirty || savingEngagement}>
                            {savingEngagement ? 'submitting your request...' : 'Send Request'}
                        </Button>
                    </Box>
                </Box>
            );
        }
    }

    let cardHeaderElement = (<CardHeader title={engageCardHeader} classes={{ root: 'cardHeaderRoot' }} />);
    let cardContentElement = (
        <CardContent classes={{ root: 'cardContentRoot' }}>
            {displayElement}
        </CardContent>
    );

    let contentCards = [];
    contentCards.push(
        <Card key={'content.card'} classes={{ root: 'cardRoot' }}>
            {cardHeaderElement}
            {cardContentElement}
        </Card>
    );

    return (<Box sx={udicciClasses.generalContainerBackground}> {contentCards} </Box>);
};

export default EngagementForm;
