import { useState } from 'react';

import Pluralize from 'pluralize';

import {
    Box, Paper, Grid, List, ListItem, ListItemText, ListItemIcon, Typography, Checkbox,
    Button, Accordion, AccordionSummary, AccordionDetails, Icon, Badge
} from '@mui/material';

import useUdicciRecord from 'src/hooks/useUdicciRecord';
import useUdicciHelpers from 'src/hooks/useUdicciHelpers';
import { useUdicciListContext } from 'src/context/udicci-list-context';

import { getUdicciData } from 'src/context/udicci-context';

import { pluck, difference, unique, forEach, without } from 'underscore';
import { UdicciRecord } from 'src/classes/udicci-record';
import UdicciRecordDisplay from './udicci-record-display';

const RecordChildrenDisplay: React.FC<any> = (props) => {
    // console.log('%c RecordChildrenDisplay props: %O', 'color: purple;', props);

    let { lum, childData, selectedChildId, selectedChildMediator, parentHelpShown, settings } = props;
    // console.log('%c RecordChildrenDisplay lum: %O', 'color: purple;', lum);
    // console.log('%c RecordChildrenDisplay parentHelpShown: %O', 'color: purple;', parentHelpShown);
    // console.log('%c RecordChildrenDisplay childData: %O', 'color: purple;', childData);
    // console.log('%c RecordChildrenDisplay selectedChildId: %O', 'color: purple;', selectedChildId);
    // console.log('%c RecordChildrenDisplay selectedChildMediator: %O', 'color: purple;', selectedChildMediator);
    // console.log('%c RecordChildrenDisplay settings: %O', 'color: purple;', settings);

    const [expandedAccordion, setExpandedAccordion] = useState<boolean>(false);
    // const [childrenRecords, setChildrenRecords] = useState<any[]>([]);
    const [selectedChildRecord, setChildRecord] = useState<any>(null);
    const [showChooseChild, setShowChooseChild] = useState<boolean>(false);

    let udicciHelpers = useUdicciHelpers();
    let udicciListContext = useUdicciListContext();

    const [checkedRecords, setCheckedRecords] = useState<readonly number[]>([]);
    const [left, setLeft] = useState<readonly number[]>([]);
    const [right, setRight] = useState<readonly number[]>([]);
    // console.log('%c RecordChildrenDisplay checkedRecords: %O', 'color: blue;', checkedRecords);
    // console.log('%c RecordChildrenDisplay left: %O', 'color: blue;', left);
    // console.log('%c RecordChildrenDisplay right: %O', 'color: blue;', right);

    let { udicciRecord, saveRecord, socialSolution } = useUdicciRecord(props.parentRecord);
    // console.log('%c RecordChildrenDisplay udicciRecord: %O', 'color: purple;', udicciRecord);
    // console.log('%c RecordChildrenDisplay socialSolution: %O', 'color: purple;', socialSolution);

    if (!udicciRecord) return null;
    // console.log('%c RecordChildrenDisplay selectedChildRecord: %O', 'color: purple;', selectedChildRecord);

    let curUdicciListState = (udicciListContext && udicciListContext.state ? udicciListContext.state : null);
    // console.log('%c RecordChildrenDisplay curUdicciListState: %O', 'color: maroon;', curUdicciListState);

    let editUdicciRecord: any = (curUdicciListState && curUdicciListState.onEditRecord ? curUdicciListState.onEditRecord : null);
    // console.log('%c RecordChildrenDisplay editUdicciRecord: %O', 'color: maroon;', editUdicciRecord);

    let childMediatorStructure: any = udicciHelpers.getMediatorStructure(selectedChildMediator);
    // console.log('%c RecordChildrenDisplay childMediatorStructure: %O', 'color: hotpink;', childMediatorStructure);

    let maxAssociationsToLeft: number = (lum && lum.MaxAssociationsToLeft ? lum.MaxAssociationsToLeft : 1);
    // console.log('%c maxAssociationsToLeft: %O', 'color: maroon;', maxAssociationsToLeft);
    // let maxAssociationsToRight: number = (lum && lum.MaxAssociationsToRight ? lum.MaxAssociationsToRight : -1);
    // console.log('%c maxAssociationsToRight: %O', 'color: maroon;', maxAssociationsToRight);

    let socialSolutionId = (socialSolution && socialSolution.socialSolutionId ? socialSolution.socialSolutionId : 0);
    // console.log('%c socialSolutionId: %O', 'color: maroon;', socialSolutionId);
    // let childRecordId = (selectedChildId ? selectedChildId : 0);
    // console.log('%c childRecordId: %O', 'color: maroon;', childRecordId);
    let recordId = (udicciRecord.recordId ? udicciRecord.recordId : 0);
    // console.log('%c recordId: %O', 'color: maroon;', recordId);
    // let recContext = (udicciRecord.context ? udicciRecord.context : null);
    // console.log('%c RecordChildrenDisplay recContext: %O', 'color: purple;', recContext);
    // let relationshipChanges = (recContext && recContext.RelationshipChanges ? recContext.RelationshipChanges : null);
    // console.log('%c RecordChildrenDisplay relationshipChanges: %O', 'color: purple;', relationshipChanges);
    // if (relationshipChanges && relationshipChanges.length > 0) {
    //     let relc: any = relationshipChanges.find((x: any) => {
    //         let rval: boolean = false;
    //         if (x.RelatedMediator === selectedChildMediator) {
    //             if (x.RecordId === udicciRecord.recordId && x.RecordMediator === udicciRecord.udicciMediator) {
    //                 rval = true;
    //             }
    //         }
    //         return rval;
    //     });
    //     // console.log('%c RecordChildrenDisplay relc: %O', 'color: purple;', relc);
    //     if (relc) childRecordId = relc.RelatedRecordId;
    // }
    // console.log('%c childRecordId: %O', 'color: maroon;', childRecordId);

    let selectedChildMediatorSingular = (selectedChildMediator ? Pluralize.singular(selectedChildMediator) : selectedChildMediator);

    let lastResult: any = null;
    let childCtxData: any = null;

    let childContext: any = udicciHelpers.getMediatorContext(selectedChildMediator);
    // console.log('%c RecordChildrenDisplay childContext: %O', 'color: blue;', childContext);
    if (childContext) lastResult = childContext.lastResult;
    // console.log('%c RecordChildrenDisplay lastResult: %O', 'color: blue;', lastResult);
    let childLinkedData: any = (childContext && childContext.linkedData ? childContext.linkedData : null);
    // console.log('%c RecordChildrenDisplay childLinkedData: %O', 'color: blue;', childLinkedData);

    let childMediatorData: any = udicciHelpers.getMediatorDataForParent(selectedChildMediator, udicciRecord.udicciMediator, udicciRecord.recordId);
    // console.log('%c RecordChildrenDisplay childMediatorData: %O', 'color: hotpink;', childMediatorData);

    if (!childData || (childData && childData.length <= 0)) {
        if (childContext && !lastResult) {
            // getUdicciData({ mediator: selectedChildMediator, socialSolutionId: socialSolution?.socialSolution?.recordId });
        }

        // let childMediatorData: any = udicciHelpers.getMediatorDataForParent(selectedChildMediator, udicciRecord.udicciMediator, udicciRecord.recordId);
        // console.log('%c RecordChildrenDisplay childMediatorData: %O', 'color: hotpink;', childMediatorData);

        childCtxData = (childContext && childContext.records ? childContext.records : null);
        if (childMediatorData) childData = childMediatorData;
    }
    // console.log('%c RecordChildrenDisplay childData: %O', 'color: blue;', childData);
    // console.log('%c RecordChildrenDisplay childLinkedData: %O', 'color: purple;', childLinkedData);
    // console.log('%c RecordChildrenDisplay lastResult: %O', 'color: purple;', lastResult);

    // if (childLinkedData) {
    //     let lum: any = find(childLinkedData, function(pld: any) {
    //         let childCheck: boolean = (pld.LeftUdicciMediatorName === udicciRecord.udicciMediator ? true : false);
    //         let parentCheck: boolean = (pld.RightUdicciMediatorName === selectedChildMediator ? true : false);
    //         return (parentCheck && childCheck);
    //     });
    //     // console.log('%c RecordChildrenDisplay lum: %O', 'color: purple;', lum);

    //     let lumData: any = (lum && lum.Data ? lum.Data : null);
    //     // console.log('%c RecordChildrenDisplay lumData: %O', 'color: purple;', lumData);
    //     if (lumData && lumData.length > 0) {
    //         let filteredLumData: any = find(lumData, function(ld: any) {
    //             return ld.LeftId === udicciRecord.recordId;
    //         });
    //         // console.log('%c RecordChildrenDisplay filteredLumData: %O', 'color: purple;', filteredLumData);
    //         if (filteredLumData) {
    //             childRecordId = filteredLumData.RightId;
    //         }
    //     }
    // }
    // console.log('%c RecordChildrenDisplay childRecordId: %O', 'color: purple;', childRecordId);

    const refreshChildData = (filterByParentId: boolean | undefined = false) => {
        // console.log('%c RecordChildrenDisplay refreshChildData selectedChildMediator: %O', 'color: orange;', selectedChildMediator);
        if (selectedChildMediator) {
            // console.log('%c RecordChildrenDisplay refreshChildData socialSolution: %O', 'color: purple;', socialSolution);
            let ssId: number = (socialSolution && socialSolution.socialSolution && socialSolution.socialSolution.recordId ? socialSolution.socialSolution.recordId : 0);
            let getDataRequest: any = {
                mediator: selectedChildMediator,
                socialSolutionId: ssId,
            };
            if (filterByParentId) {
                let parentIds: number[] = [];
                if (recordId) parentIds.push(recordId);
                getDataRequest.settings = { ParentUdicciRecordIds: parentIds };
            }
            // console.log('%c RecordChildrenDisplay refreshChildData getDataRequest: %O', 'color: orange;', getDataRequest);
            getUdicciData(getDataRequest);
        }
    };

    const handleChangeAccordion = () => {
        let expandedAccordionUpdated: boolean = !expandedAccordion;
        if (expandedAccordionUpdated) {
            // console.log('%c RecordChildrenDisplay handleChangeAccordion selectedChildMediator: %O', 'color: red;', selectedChildMediator);
            // console.log('%c RecordChildrenDisplay handleChangeAccordion udicciRecord.udicciMediator: %O', 'color: red;', udicciRecord.udicciMediator);
            // console.log('%c RecordChildrenDisplay handleChangeAccordion udicciRecord: %O', 'color: red;', udicciRecord);
            // console.log('%c RecordChildrenDisplay handleChangeAccordion lum: %O', 'color: red;', lum);
            // console.log('%c RecordChildrenDisplay handleChangeAccordion childData: %O', 'color: red;', childData);
            // console.log('%c RecordChildrenDisplay handleChangeAccordion childContext: %O', 'color: red;', childContext);
            // console.log('%c RecordChildrenDisplay handleChangeAccordion childLinkedData: %O', 'color: red;', childLinkedData);
            let lumData: any = childLinkedData.find((x: any) => {
                let rval: boolean = false;
                if (x.LeftUdicciMediatorName === udicciRecord.udicciMediator && x.RightUdicciMediatorName === selectedChildMediator) {
                    rval = true;
                }
                return rval;
            });
            // console.log('%c RecordChildrenDisplay handleChangeAccordion lumData: %O', 'color: purple;', lumData);
            // check for childData that is related to the parent record
            if (!childCtxData || childCtxData.length <= 0) refreshChildData();
        }
        setExpandedAccordion(expandedAccordionUpdated);
    };

    const addChildRecord = (evt: any) => {
        // console.log('%c RecordChildrenDisplay addChildRecord selectedChildMediator: %O', 'color: purple;', selectedChildMediator);
        if (!evt) evt = window.event;

        if (evt.stopPropagation) {
            //IE9 & Other Browsers
            evt.stopPropagation();
        } else {
            //IE8 and Lower
            evt.cancelBubble = true;
        }

        let newChildRec: UdicciRecord = new UdicciRecord(selectedChildMediator, { CreatedInSolutionId: socialSolutionId }, childMediatorStructure);
        // console.log('%c addChildRecord newChildRec: %O', 'color: hotpink;', newChildRec);
        if (newChildRec.requestRelationshipUpdate) newChildRec.requestRelationshipUpdate('parent', udicciRecord.udicciMediator, udicciRecord.recordId);
        setChildRecord(newChildRec);
        setExpandedAccordion(true);
    };

    const editChildRecord = (evt: any, childRecord: UdicciRecord | null) => {
        // console.log('%c RecordChildrenDisplay editChildRecord childRecord: %O', 'color: purple;', childRecord);
        if (!evt) evt = window.event;

        if (evt.stopPropagation) {
            //IE9 & Other Browsers
            evt.stopPropagation();
        } else {
            //IE8 and Lower
            evt.cancelBubble = true;
        }

        if (editUdicciRecord) editUdicciRecord(childRecord);
        // setChildRecord(childRecord);
        setExpandedAccordion(true);
    };

    const toggleChooseChild = (evt: any) => {
        // console.log('%c RecordChildrenDisplay toggleChooseChild selectedChildMediator: %O', 'color: purple;', selectedChildMediator);
        if (!evt) evt = window.event;

        if (evt.stopPropagation) {
            //IE9 & Other Browsers
            evt.stopPropagation();
        } else {
            //IE8 and Lower
            evt.cancelBubble = true;
        }

        setShowChooseChild((!expandedAccordion ? true : !showChooseChild));
        if (!childCtxData || childCtxData.length <= 0) refreshChildData();
        setExpandedAccordion(true);
    };

    const handleToggle = (value: number) => () => {
        const currentIndex = checkedRecords.indexOf(value);
        const newChecked = [...checkedRecords];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        setCheckedRecords(newChecked);
    };

    const handleAllRight = () => {
        if (left && left.length > 0) {
            setRight(right.concat(left));
        } else {
            var allChildRecordIds: number[] = pluck(childCtxData, 'recordId');
            // console.log('%c handleAllLeft allChildRecordIds: %O', 'color: orange;', allChildRecordIds);
            setRight(unique(right.concat(allChildRecordIds)));
        }
        setLeft([]);
    };

    const handleCheckedRight = (newRight: number[], newLeft: number[], newChecked: number[]) => {
        setRight(newRight);
        setLeft(newLeft);
        setCheckedRecords(newChecked);
    };

    const handleCheckedLeft = (newLeft: number[], newRight: number[], newChecked: number[]) => {
        setLeft(newLeft);
        setRight(newRight);
        setCheckedRecords(newChecked);
    };

    const handleAllLeft = () => {
        // console.log('%c handleAllLeft childCtxData: %O', 'color: green;', childCtxData);
        if (right && right.length > 0) {
            setLeft(left.concat(right));
        } else {
            var allChildRecordIds: number[] = pluck(childCtxData, 'recordId');
            // console.log('%c handleAllLeft allChildRecordIds: %O', 'color: orange;', allChildRecordIds);
            setLeft(unique(left.concat(allChildRecordIds)));
        }
        setRight([]);
    };

    const requestRelationshipUpdate = (relatedMediatorRelationship: string, relatedMediatorName: string, relatedRecordId: number, changeAction: string | undefined = 'Add') => {
        if (!relatedMediatorRelationship) return false;
        if (!relatedMediatorName) return false;

        let rslt: boolean = false;
        if (udicciRecord.requestRelationshipUpdate) {
            rslt = udicciRecord.requestRelationshipUpdate(relatedMediatorRelationship, relatedMediatorName, relatedRecordId, changeAction);
        }
        return rslt;
    };

    const applyLinkedChildrenChanges = (evt: any) => {
        // console.log('%c applyLinkedChildrenChanges selectedChildMediator: %O', 'color: orange;', selectedChildMediator);
        // console.log('%c applyLinkedChildrenChanges childData: %O', 'color: orange;', childData);
        // console.log('%c applyLinkedChildrenChanges childCtxData: %O', 'color: orange;', childCtxData);
        if (!evt) evt = window.event;

        if (evt.stopPropagation) {
            //IE9 & Other Browsers
            evt.stopPropagation();
        } else {
            //IE8 and Lower
            evt.cancelBubble = true;
        }

        // console.log('%c applyLinkedChildrenChanges left: %O', 'color: orange;', left);
        // console.log('%c applyLinkedChildrenChanges right: %O', 'color: orange;', right);
        var linkedChildRecordIds: number[] = pluck(childData, 'recordId');
        // console.log('%c applyLinkedChildrenChanges linkedChildRecordIds: %O', 'color: orange;', linkedChildRecordIds);

        let missingIdsToRemove: number[] = unique(left);
        // console.log('%c RecordChildrenDisplay missingIdsToRemove: %O', 'color: orange;', missingIdsToRemove);
        let missingIdsToAdd: number[] = unique(right);
        // console.log('%c RecordChildrenDisplay missingIdsToAdd: %O', 'color: orange;', missingIdsToAdd);

        let removeIds: number[] = udicciHelpers.intersection(missingIdsToRemove, linkedChildRecordIds);
        // console.log('%c applyLinkedChildrenChanges removeIds: %O', 'color: orange;', removeIds);
        let addIds: number[] = without(missingIdsToAdd, ...linkedChildRecordIds);
        // console.log('%c applyLinkedChildrenChanges addIds: %O', 'color: orange;', addIds);

        let unsuccessful: number = 0;
        if (addIds.length > 0) {
            forEach(addIds, (id: number, idx: number) => {
                let rslt: boolean = requestRelationshipUpdate('child', selectedChildMediator, id);
                if (!rslt) unsuccessful++;
            });
        }

        if (removeIds.length > 0) {
            forEach(removeIds, (id: number, idx: number) => {
                let rslt: boolean = requestRelationshipUpdate('child', selectedChildMediator, id, 'Remove');
                if (!rslt) unsuccessful++;
            });
        }

        // console.log('%c applyLinkedChildrenChanges unsuccessful: %O', 'color: red; font-weight: bold;', unsuccessful);
        // console.log('%c applyLinkedChildrenChanges udicciRecord: %O', 'color: red; font-weight: bold;', udicciRecord);
        if (unsuccessful <= 0 && udicciRecord.isDirty) saveRecord({ onSaveCompleted: onSaveCompleted });
    };

    const onSaveCompleted = (response: any) => {
        // console.log('%c onSaveCompleted response: %O', 'color: blue;', response);
        refreshChildData(true);
    };

    const resetChildrenLists = (evt: any) => {
        // console.log('%c resetChildrenLists selectedChildMediator: %O', 'color: orange;', selectedChildMediator);
        if (!evt) evt = window.event;

        if (evt.stopPropagation) {
            //IE9 & Other Browsers
            evt.stopPropagation();
        } else {
            //IE8 and Lower
            evt.cancelBubble = true;
        }

        setLeft([]);
        setRight([]);
        setCheckedRecords([]);
    };

    let sortedChildData: any[] | null = null;
    if (childData) {
        sortedChildData = childData.sort((a: any,b: any) => {
            if (a.record && b.record) {
                if (a.record.title < b.record.title) return -1;  // ascending
                if (a.record.title > b.record.title) return 1;  // descending
            } else if (a.recordId !== undefined && b.recordId !== undefined) {
                if (a.title < b.title) return -1;  // ascending
                if (a.title > b.title) return 1;  // descending
            }
            return 0 //default return value (no sorting)
        });
    }
    // console.log('%c sortedChildData: %O', 'color: purple;', sortedChildData);

    // console.log('%c childMediatorStructure: %O', 'color: purple;', childMediatorStructure);
    let titleField: string = (settings && settings.titleField ? settings.titleField : udicciRecord.keys.titleField);
    let descriptionField: string = (settings && settings.descriptionField ? settings.descriptionField : '');

    let childElements: any[] = [];
    if (sortedChildData && sortedChildData.length > 0) {
        for (let [idx, pd] of Object.entries<any>(sortedChildData)) {
            // console.log('%c pd: %O', 'color: hotpink;', pd);
            let rec: any = (pd.record ? pd.record : pd);
            // console.log('%c rec: %O', 'color: hotpink;', rec);
            let recContainerSettings: any = {
                key: 'child.rec.' + rec.recordId + '.' + idx,
                sx: {
                    background: 'rgba(255, 255, 255, 0.77)',
                    borderRadius: '8px',
                    padding: '4px',
                    margin: '4px',
                },
            };

            let editRecordElement: any = null;
            if (!settings || (settings && !settings.disableEdit)) {
                editRecordElement = (
                    <Icon fontSize="small" onClick={(evt: any) => editChildRecord(evt, rec)} sx={{ float: 'right', marginRight: '16px', cursor: 'pointer' }}>
                        edit
                    </Icon>
                );
            }

            let titleElement: any = null;
            if (titleField && rec.data && rec.data[titleField] !== undefined) {
                titleElement = (
                    <Typography component="div" variant="leftFieldLabel">
                        {rec.data[titleField]}
                    </Typography>
                );
            } else {
                titleElement = (
                    <Typography component="div" variant="leftFieldLabel">
                        {(rec.title ? rec.title : 'new record')}
                    </Typography>
                );
            }

            let descriptionElement: any = null;
            if (descriptionField && rec.data && rec.data[descriptionField] !== undefined) {
                descriptionElement = (
                    <Typography component="div" variant="caption">
                        {rec.data[descriptionField].toString()}
                    </Typography>
                );
            }

            childElements.push(
                <Box {...recContainerSettings}>
                    {editRecordElement}
                    {titleElement}
                    {descriptionElement}
                </Box>
            );
        }
    } else {
        childElements.push(
            <Box key={'no.children.for.' + selectedChildMediator}>
                <Typography component="div" variant="caption">There are no related {selectedChildMediator.toLowerCase()} to show.</Typography>
            </Box>
        );
    }

    let refreshChildRecordsButton: any = (
        <Icon fontSize="small" onClick={(evt: any) => refreshChildData(false)} sx={{ marginLeft: '16px', cursor: 'pointer' }}>refresh</Icon>
    );

    let addChildRecordButton: any = null;
    if (!settings || (settings && !settings.disableAdd)) {
        addChildRecordButton = (
            <Icon fontSize="small" onClick={addChildRecord} sx={{ marginLeft: '16px', cursor: 'pointer' }}>add</Icon>
        );
    }

    let chooseChildRecordButton: any = null;
    if (maxAssociationsToLeft < 0) {
        chooseChildRecordButton = (
            <Icon fontSize="small" onClick={toggleChooseChild} sx={{ marginLeft: '16px', cursor: 'pointer' }}>storage</Icon>
        );
    }

    let childRecordFormElement: any = null;
    let chooseChildRecordFormElement: any = null;
    if (selectedChildRecord) {
        childRecordFormElement = (
            <Box sx={{ display: 'flow-root' }}>
                <UdicciRecordDisplay showForm={true} showInline={true}
                                     record={selectedChildRecord}
                                     onCloseForm={() => setChildRecord(null)}
                />
            </Box>
        );
    } else if (maxAssociationsToLeft < 0 && showChooseChild) {
        // console.log('%c RecordChildrenDisplay childData: %O', 'color: green;', childData);
        // console.log('%c RecordChildrenDisplay childCtxData: %O', 'color: green;', childCtxData);

        var linkedChildRecordIds: number[] = pluck(childData, 'recordId');
        // console.log('%c RecordChildrenDisplay linkedChildRecordIds: %O', 'color: orange;', linkedChildRecordIds);
        var allChildRecordIds: number[] = pluck(childCtxData, 'recordId');
        // console.log('%c RecordChildrenDisplay allChildRecordIds: %O', 'color: orange;', allChildRecordIds);
        let unassignedRecordIds: number[] = unique(difference(allChildRecordIds, linkedChildRecordIds));
        // console.log('%c RecordChildrenDisplay unassignedRecordIds: %O', 'color: orange;', unassignedRecordIds);

        let allChildDataSorted: any[] = [];
        if (childCtxData && childCtxData.length > 0) {
            allChildDataSorted = childCtxData.sort((a: any,b: any) => {
                if (a.record && b.record) {
                    if (a.record.title < b.record.title) return -1;  // ascending
                    if (a.record.title > b.record.title) return 1;  // descending
                } else if (a.recordId !== undefined && b.recordId !== undefined) {
                    if (a.title < b.title) return -1;  // ascending
                    if (a.title > b.title) return 1;  // descending
                }
                return 0 //default return value (no sorting)
            });
        }
        // console.log('%c RecordChildrenDisplay allChildDataSorted: %O', 'color: orange;', allChildDataSorted);

        let recordsNotLinkedElements: any[] = [];
        let recordsLinkedElements: any[] = [];
        let leftIds: number[] = [];
        let rightIds: number[] = [];
        forEach(allChildDataSorted, (childRecord: UdicciRecord, idxChildRecord: number) => {
            // console.log('%c childRecord: %O', 'color: green;', childRecord);

            let isLinked: boolean = false;
            // let isLinkedSameAsCurrent: boolean = false;
            if (right && right.length > 0) {
                isLinked = (right.indexOf(childRecord.recordId) >= 0 ? true : isLinked);
            } else {
                isLinked = (linkedChildRecordIds.indexOf(childRecord.recordId) >= 0 ? true : isLinked);
            }
            // console.log('%c isLinked: %O', 'color: green;', isLinked);

            let notLinked: boolean = false;
            if (left && left.length > 0) {
                notLinked = (left.indexOf(childRecord.recordId) >= 0 ? true : notLinked);
                if (isLinked && notLinked) isLinked = false;
            } else {
                notLinked = (unassignedRecordIds.indexOf(childRecord.recordId) >= 0 ? true : notLinked);
            }
            // console.log('%c notLinked: %O', 'color: green;', notLinked);

            let itemKey: string = 'child.record.list.item.' + childRecord.recordId.toString();
            if (isLinked) {
                itemKey += '.linked';
            } else {
                itemKey += '.unassigned';
            }
            let listElement: any = (
                <ListItem
                    key={childRecord.recordId}
                    role="listitem"
                    button
                    onClick={handleToggle(childRecord.recordId)}
                >
                    <ListItemIcon>
                        <Checkbox
                            checked={checkedRecords.indexOf(childRecord.recordId) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': 'transfer.' + itemKey }}
                        />
                    </ListItemIcon>
                    <ListItemText id={'transfer.' + itemKey} primary={childRecord.title} />
                </ListItem>
            );
            if (isLinked) {
                recordsLinkedElements.push(listElement);
                rightIds.push(childRecord.recordId);
            } else {
                recordsNotLinkedElements.push(listElement);
                leftIds.push(childRecord.recordId);
            }
        });

        let unassignedListElement: any = (
            <Paper sx={{ height: 350, overflow: 'auto' }}>
                <List dense component="div" role="list">
                    {recordsNotLinkedElements}
                </List>
            </Paper>
        );

        let linkedListElement: any = (
            <Paper sx={{ height: 350, overflow: 'auto' }}>
                <List dense component="div" role="list">
                    {recordsLinkedElements}
                </List>
            </Paper>
        );

        const _leftChecked = udicciHelpers.intersection(checkedRecords, leftIds);
        const _rightChecked = udicciHelpers.intersection(checkedRecords, rightIds);

        chooseChildRecordFormElement = (
            <Box sx={{ display: 'flow-root' }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={4}>
                        <Typography component="div" variant="caption">Select an existing {selectedChildMediatorSingular}.</Typography>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={4}>
                        <Typography component="div" variant="caption">Linked {selectedChildMediator}.</Typography>
                    </Grid>
                    <Grid item xs={4}>{unassignedListElement}</Grid>
                    <Grid item xs={1}>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllRight}
                                disabled={leftIds.length === 0}
                                aria-label="move all right"
                            >
                                ≫
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={(evt: any) => handleCheckedRight(rightIds.concat(_leftChecked), udicciHelpers.not(leftIds, _leftChecked), udicciHelpers.not(checkedRecords, _leftChecked))}
                                disabled={_leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={(evt: any) => handleCheckedLeft(leftIds.concat(_rightChecked), udicciHelpers.not(rightIds, _rightChecked), udicciHelpers.not(checkedRecords, _rightChecked))}
                                disabled={_rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllLeft}
                                disabled={rightIds.length === 0}
                                aria-label="move all left"
                            >
                                ≪
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>{linkedListElement}</Grid>
                    <Grid item xs={9} alignContent="right">
                        <Box sx={{ display: 'flow-root', marginRight: '8px', textAlign: 'right' }}>
                            <Button variant="outlined" size="small" aria-label="apply changes" onClick={applyLinkedChildrenChanges}>
                                Apply Changes
                            </Button>
                            <Button variant="outlined" size="small" aria-label="reset lists" onClick={resetChildrenLists}>
                                Reset Lists
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    let childRecordsDisplayElement: any = null;
    if (!childRecordFormElement && !chooseChildRecordFormElement && childElements.length > 0) {
        childRecordsDisplayElement = (
            <Box sx={{ display: 'flow-root' }}>
                {childElements}
            </Box>
        );
    }

    let lumDescriptionElement: any = null;
    if (parentHelpShown && descriptionField) {
        lumDescriptionElement = (
            <Typography component="div" variant="caption" sx={{ marginLeft: '8px' }}>{descriptionField}</Typography>
        );
    }

    let accordionSummarySettings: any = {
        expandIcon: (<Icon color="secondary">expand_more</Icon>),
        sx: { padding: '4px', minHeight: '16px' },
    };
    let accordionDetailsSettings: any = {
        sx: { padding: '4px', minHeight: '16px' },
    };
    let recordCount: any = (sortedChildData && sortedChildData.length > 0 ? sortedChildData.length : 0);

    let childMediatorTitle: string = (settings && settings.label ? settings.label : selectedChildMediator);
    let selectChildRecordElement: any = (
        <Accordion expanded={expandedAccordion} onChange={() => handleChangeAccordion()}>
            <AccordionSummary {...accordionSummarySettings}>
                <Box sx={{ display: 'flex', marginLeft: '16px' }}>
                    <Badge showZero badgeContent={recordCount} color="secondary" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                        <Typography component="span" variant="clickable" sx={{ marginLeft: '8px' }}>{childMediatorTitle}</Typography>
                    </Badge>
                </Box>
                {lumDescriptionElement}
                {chooseChildRecordButton}
                {addChildRecordButton}
                {refreshChildRecordsButton}
            </AccordionSummary>
            <AccordionDetails {...accordionDetailsSettings}>
                {childRecordFormElement}
                {childRecordsDisplayElement}
                {chooseChildRecordFormElement}
            </AccordionDetails>
        </Accordion>
    );

    return (
        <Box>
            {selectChildRecordElement}
        </Box>
    );
}

export { RecordChildrenDisplay };
